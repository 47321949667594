import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import QuickGlance from './QuickGlance';
import QuickSearch from './QuickSearch';
import LateJobs from './LateJobs';
import MyJobsBoard from './MyJobsBoard';
import DeleteModal from './DeleteModal';
import StopModal from './StopModal';
import * as actions from './PqtDashboard.actions';
import * as selectors from './PqtDashboard.selectors';
import * as loginSelectors from './../Login/Login.selectors';
// import * as pqtLayoutSelectors from './../PqtLayout/PqtLayout.selectors';
// import * as pqtLayoutActions from './../PqtLayout/PqtLayout.actions';
import * as chatActions from './../Chat/Chat.actions';
import PropTypes from 'prop-types';
import './PqtDashboard.scss';
import PqtLayout from './../PqtLayout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import * as dialog from '../common/dialog';

const PqtDashboard = (props) => {
    /* State to props */
    const searchFields = useSelector(selectors.getSearchFields, stringifyEqualityCheck);
    const showFinished = useSelector(selectors.getShowFinished);
    const sort = useSelector(selectors.getSort, stringifyEqualityCheck);
    const quickGlanceFilter = useSelector(selectors.getQuickGlanceFilter, stringifyEqualityCheck);
    const csrsData = useSelector(selectors.getCsrsData, stringifyEqualityCheck);
    const salesRepsData = useSelector(selectors.getSalesRepsData, stringifyEqualityCheck);
    const snapshotData = useSelector(selectors.getSnapshotData, stringifyEqualityCheck);
    const ppInvoices = useSelector(selectors.getPpInvoices, stringifyEqualityCheck);
    const departmentsData = useSelector(selectors.getDepartmentsData, stringifyEqualityCheck);
    const closeoutData = useSelector(selectors.getCloseoutData, stringifyEqualityCheck);
    const postageEscrowBalanceData = useSelector(selectors.getPostageEscrowBalanceData, stringifyEqualityCheck);
    const fetchingQuickGlance = useSelector(selectors.getFetchingQuickGlance);
    const salesRepsInformation = useSelector(selectors.getSalesRepsInformation, stringifyEqualityCheck);
    const csrsInformation = useSelector(selectors.getCsrsInformation, stringifyEqualityCheck);
    const lateJobsData = useSelector(selectors.getLateJobsData, stringifyEqualityCheck);
    const myJobsData = useSelector(selectors.getMyJobsData, stringifyEqualityCheck);
    const fetchingLateJobs = useSelector(selectors.getFetchingLateJobs);
    const fetchingMyJobs = useSelector(selectors.getFetchingMyJobs);
    const lateJobsPagination = useSelector(selectors.getLateJobsPagination, stringifyEqualityCheck);
    const myJobsPagination = useSelector(selectors.getMyJobsPagination, stringifyEqualityCheck);
    const idForDelete = useSelector(selectors.getIdForDelete, stringifyEqualityCheck);
    const isDeleting = useSelector(selectors.getIsDeleting, stringifyEqualityCheck);
    const invoicesData = useSelector(selectors.getInvoices, stringifyEqualityCheck);
    // const ws = useSelector(pqtLayoutSelectors.getWs, stringifyEqualityCheck);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    // const setWs = useCallback((field, value) => dispatch(pqtLayoutActions.setWs(field, value)), [dispatch]);
    const setQuickGlanceFilter = useCallback((field, value) => dispatch(actions.setQuickGlanceFilter(field, value)), [dispatch]);
    const setSearchField = useCallback((name, value) => dispatch(actions.setSearchField(name, value)), [dispatch]);
    const resetSearchFields = useCallback(() => dispatch(actions.resetSearchFields()), [dispatch]);
    const deleteTracking = useCallback((id, comments) => dispatch(actions.deleteTracking(id, comments)), [dispatch]);
    const setSort = useCallback((name, column, type) => dispatch(actions.setSort(name, column, type)), [dispatch]);
    const setActivePage = useCallback((name, value) => dispatch(actions.setActivePage(name, value)), [dispatch]);
    const loadChat = useCallback((type, options) => dispatch(chatActions.loadChat(type, options)), [dispatch]);
    const setChatType = useCallback((type) => dispatch(chatActions.setType(type)), [dispatch]);
    const setChatShowField = useCallback((field, value) => dispatch(chatActions.setShowField(field, value)), [dispatch]);
    const seChatElementId = useCallback((id) => dispatch(chatActions.setElementId(id)), [dispatch]);
    const setChatTitle = useCallback((title) => dispatch(chatActions.setChatTitle(title)), [dispatch]);
    const loadChats = useCallback((isWsUpdate) => dispatch(chatActions.loadChats(isWsUpdate)), [dispatch]);
    const setShowFinished = useCallback((value) => dispatch(actions.setShowFinished(value)), [dispatch]);
    const fetchData = useCallback((options, isWsUpdate) => dispatch(actions.fetchData(options, isWsUpdate)), [dispatch]);
    const setIdForDelete = useCallback((id) => dispatch(actions.setIdForDelete(id)), [dispatch]);
    const showDeleteModal = useCallback(() => dispatch(dialog.actions.showDialog('delete-modal')), [dispatch]);
    const hideDeleteModal = useCallback(() => dispatch(dialog.actions.hideDialog('delete-modal')), [dispatch]);
    const showStopModal = useCallback(() => dispatch(dialog.actions.showDialog('stop-modal')), [dispatch]);
    const hideStopModal = useCallback(() => dispatch(dialog.actions.hideDialog('stop-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        dispatch(actions.mountComponent(props.history));
    }, [dispatch, props.history]);

    const pqtType = user !== null && user.pqt_type;

    const redirectToNewTracker = () => {
        // Beatriz (31), Rocio (32), Terri (2335), Nyke (33), Randy (35), Debbie (42), Robert (59), Hugh (61), Christy (64), House (73), Kathy (76), Kellie (78), 
        // Brenda (100), April (89), Kassandra (3566)
        if ([31, 32, 33, 35, 42, 59, 61, 64, 73, 76, 78, 100, 2335, 89, 3566].includes(parseInt(user.id))) {
            props.history.push('/tracking');
        } else {
            showStopModal();
        }
    };

    const redirectToNewQuote = () => {
        props.history.push('/request-for-quote');
    };

    const actionButtons = [];

    // if (pqtType === 1) {
    if (user != null && parseInt(user.id) === 61) {     // Hugh
        actionButtons.push(
            <button className="action new-quote-button" onClick={redirectToNewQuote}>
                <i className="fas fa-plus"></i> New Quote
            </button>
        );
    }
    if ([1, 2, 3, 4, 8].includes(pqtType)) {
        actionButtons.push(
            <button className="action new-tracker-button" onClick={redirectToNewTracker}>
                <i className="fas fa-plus"></i> New Tracker
            </button>
        );
    }

    // if (ws !== null && ws.lastMessage !== null) {
    //     const jsonMessage = JSON.parse(ws.lastMessage);
    //     if (ws.uuid !== jsonMessage.uuid) {
    //         const wsTypes = ['track_created', 'track_updated', 'step_updated', 'step_deleted', 'step_closed', 'track_started',
    //             'track_closed', 'track_deleted', 'pqt_data_processing_created', 'pqt_data_processing_updated',
    //             'pqt_postal_qualification_created', 'pqt_postal_qualification_updated'];
    //         if (wsTypes.includes(jsonMessage.type)) {
    //             fetchData({
    //                 fetchQuickGlance: 1,
    //                 fetchLateJobs: 1,
    //                 fetchMyJobs: 1,
    //             }, true);
    //             setWs('lastMessage', null);
    //         } else if (jsonMessage.type === 'new_chat_message') {
    //             fetchData({
    //                 fetchLateJobs: 1,
    //                 fetchMyJobs: 1,
    //             }, true);
    //         }
    //     }
    // }

    return (
        <PqtLayout
            title="Dashboard"
            actionButtons={actionButtons}
            history={props.history}
        >
            <Helmet><title>Dashboard - Stagecoach</title></Helmet>
            <div className="pqt-dashboard-container">
                <div className="quick-container">
                    {[1, 2, 3, 4, 8].includes(pqtType) && <QuickGlance
                        csrsData={csrsData}
                        salesRepsData={salesRepsData}
                        snapshotData={snapshotData}
                        ppInvoices={ppInvoices}
                        departmentsData={departmentsData}
                        closeoutData={closeoutData}
                        postageEscrowBalanceData={postageEscrowBalanceData}
                        fetchingQuickGlance={fetchingQuickGlance}
                        history={props.history}
                        quickGlanceFilter={quickGlanceFilter}
                        invoicesData={invoicesData}
                        pqtType={pqtType}
                        user={user}
                        setQuickGlanceFilter={setQuickGlanceFilter}
                    />}
                    {[1, 2, 3, 4, 8].includes(pqtType) && <QuickSearch
                        salesRepsInformation={salesRepsInformation}
                        csrsInformation={csrsInformation}
                        searchFields={searchFields}
                        showFinished={showFinished}
                        setSearchField={setSearchField}
                        resetSearchFields={resetSearchFields}
                        fetchData={fetchData}
                    />}
                </div>
                <LateJobs
                    user={user}
                    lateJobsData={lateJobsData}
                    lateJobsPagination={lateJobsPagination}
                    fetchingLateJobs={fetchingLateJobs}
                    history={props.history}
                    sort={sort}
                    deleteTracking={deleteTracking}
                    setSort={setSort}
                    setActivePage={setActivePage}
                    loadChat={loadChat}
                    setChatType={setChatType}
                    setChatShowField={setChatShowField}
                    seChattElementId={seChatElementId}
                    setChatTitle={setChatTitle}
                    loadChats={loadChats}
                    fetchData={fetchData}
                    showDeleteModal={showDeleteModal}
                    setIdForDelete={setIdForDelete}
                />
                <MyJobsBoard
                    user={user}
                    myJobsData={myJobsData}
                    myJobsPagination={myJobsPagination}
                    fetchingMyJobs={fetchingMyJobs}
                    history={props.history}
                    showFinished={showFinished}
                    sort={sort}
                    deleteTracking={deleteTracking}
                    setShowFinished={setShowFinished}
                    setSort={setSort}
                    setActivePage={setActivePage}
                    loadChat={loadChat}
                    setChatType={setChatType}
                    setChatShowField={setChatShowField}
                    seChattElementId={seChatElementId}
                    setChatTitle={setChatTitle}
                    loadChats={loadChats}
                    fetchData={fetchData}
                    showDeleteModal={showDeleteModal}
                    setIdForDelete={setIdForDelete}
                />
                <DeleteModal
                    idForDelete={idForDelete}
                    isDeleting={isDeleting}
                    deleteTracking={deleteTracking}
                    hideDeleteModal={hideDeleteModal}
                    setIdForDelete={setIdForDelete}
                />
                <StopModal
                    hideStopModal={hideStopModal}
                />
            </div>
        </PqtLayout>
    );
};

PqtDashboard.propTypes = {
    user: PropTypes.object.isRequired
}

export default PqtDashboard;
