import React, { useState } from "react";
import numeral from "numeral";

const Accounts = React.memo((props) => {
    const [activeExtraServices, setActiveExtraServices] = useState([]);

    const handleSetField = (field, event) => {
        let value = event.target.value;
        if (event.target.type === "checkbox") {
            value = event.target.checked ? 1 : 0;
        }

        props.setField(field, value);
    };

    const handleViewExtraServices = (index, event) => {
        event.preventDefault();
        setActiveExtraServices((activeExtraServices) => {
            let newActiveExtraServices = [...activeExtraServices];

            const arrayIndex = newActiveExtraServices.findIndex(
                (service) => service === index
            );
            if (arrayIndex === -1) {
                newActiveExtraServices.push(index);
            } else {
                newActiveExtraServices.splice(arrayIndex, 1);
            }

            return newActiveExtraServices;
        });
    };

    const renderPriceResult = () => {
        if (props.priceResult == null) {
            return null;
        }

        return (
            <table className="table table-sm">
                <thead>
                    <tr>
                        {/* <th>#</th> */}
                        <th>Description</th>
                        <th className="text-right">Price/Piece</th>
                        <th className="text-right">Total Price</th>
                        <th className="text-center"></th>
                    </tr>
                </thead>
                <tbody>
                    {props.priceResult.rateOptions.map((rate, index) => {
                        const classes = ["extra-services-row"];

                        if (!activeExtraServices.includes(index)) {
                            classes.push("hidden");
                        }

                        return (
                            <>
                                <tr>
                                    {/* <td>{index + 1}</td> */}
                                    <td>
                                        {rate.rates[0].description}{" "}
                                        {rate.rates[0].priceType}
                                    </td>
                                    <td className="text-right">
                                        {numeral(rate.totalBasePrice).format(
                                            "$0,0.00"
                                        )}
                                    </td>
                                    <td className="text-right">
                                        {numeral(
                                            rate.totalBasePrice *
                                                props.fields.quantity
                                        ).format("$0,0.00")}
                                    </td>
                                    <td className="text-center">
                                        <a
                                            href="/"
                                            onClick={handleViewExtraServices.bind(
                                                this,
                                                index
                                            )}
                                        >
                                            View Extra Services
                                        </a>
                                    </td>
                                </tr>
                                <tr className={classes.join(" ")}>
                                    <td colSpan={5}>
                                        {rate.extraServices.map(
                                            (extraService) => {
                                                return (
                                                    <div>
                                                        Code:{" "}
                                                        {
                                                            extraService.extraService
                                                        }{" "}
                                                        - {extraService.name}:{" "}
                                                        {numeral(
                                                            extraService.price
                                                        ).format("$0,0.00")}
                                                    </div>
                                                );
                                            }
                                        )}
                                    </td>
                                </tr>
                            </>
                        );
                    })}
                </tbody>
            </table>
        );
    };

    const handleGetPrice = () => {
        setActiveExtraServices([]);
        props.getPrice();
    };

    return (
        <div className="calculator-container">
            <div className="calculator">
                {/* <div className="field">
                    <div className="label">Product Type</div>
                    <div className="input">
                        <select
                            value={props.fields.product_type}
                            onChange={handleSetField.bind(this, "product_type")}
                        >
                            <option value="">Select option</option>
                            <option value="1">Postcards</option>
                            <option value="2">Letters</option>
                            <option value="3">Flats</option>
                        </select>
                    </div>
                </div> */}
                <div className="field">
                    <div className="label">Quantity</div>
                    <div className="input">
                        <input
                            type="number"
                            value={props.fields.quantity}
                            onChange={handleSetField.bind(this, "quantity")}
                        />
                    </div>
                </div>
                <div className="field">
                    <div className="label">Origin Zip Code</div>
                    <div className="input">
                        <input
                            type="text"
                            value={props.fields.origin_zip}
                            onChange={handleSetField.bind(this, "origin_zip")}
                        />
                    </div>
                </div>
                <div className="field">
                    <div className="label">Destination Zip Code</div>
                    <div className="input">
                        <input
                            type="text"
                            value={props.fields.destination_zip}
                            onChange={handleSetField.bind(
                                this,
                                "destination_zip"
                            )}
                        />
                    </div>
                </div>
                <div className="field">
                    <div className="label">Weight (ounces)</div>
                    <div className="input markup">
                        <input
                            type="number"
                            value={props.fields.weight}
                            onChange={handleSetField.bind(this, "weight")}
                        />
                    </div>
                </div>
                <div className="field">
                    <div className="label">Length (inches)</div>
                    <div className="input">
                        <input
                            type="number"
                            value={props.fields.length}
                            onChange={handleSetField.bind(this, "length")}
                        />
                    </div>
                </div>
                <div className="field">
                    <div className="label">Width (inches)</div>
                    <div className="input">
                        <input
                            type="number"
                            value={props.fields.width}
                            onChange={handleSetField.bind(this, "width")}
                        />
                    </div>
                </div>
                <div className="field">
                    <div className="label">Height (inches)</div>
                    <div className="input">
                        <input
                            type="number"
                            value={props.fields.height}
                            onChange={handleSetField.bind(this, "height")}
                        />
                    </div>
                </div>
                {/* <div className="field">
                    <div className="label">Mail Class</div>
                    <div className="input">
                        <select
                            value={props.fields.mail_class}
                            onChange={handleSetField.bind(this, "mail_class")}
                        >
                            <option value="">Select option</option>
                            <option value="PARCEL_SELECT">Parcel Select</option>
                            <option value="PARCEL_SELECT_LIGHTWEIGHT">
                                Parcel Select Lightweight
                            </option>
                            <option value="PRIORITY_MAIL_EXPRESS">
                                Priority Mail Express
                            </option>
                            <option value="PRIORITY_MAIL">Priority Mail</option>
                            <option value="FIRST-CLASS_PACKAGE_SERVICE">
                                First-Class Package Service
                            </option>
                            <option value="LIBRARY_MAIL">Library Mail</option>
                            <option value="MEDIA_MAIL">Media Mail</option>
                            <option value="BOUND_PRINTED_MATTER">
                                Bound Printed Matter
                            </option>
                            <option value="USPS_CONNECT_LOCAL">
                                USPS Connect Local
                            </option>
                            <option value="USPS_CONNECT_MAIL">
                                USPS Connect Mail
                            </option>
                            <option value="USPS_CONNECT_NEXT_DAY">
                                USPS Connect Next Day
                            </option>
                            <option value="USPS_CONNECT_REGIONAL">
                                USPS Connect Regional
                            </option>
                            <option value="USPS_CONNECT_SAME_DAY">
                                USPS Connect Same Day
                            </option>
                            <option value="USPS_GROUND_ADVANTAGE">
                                USPS Ground Advantage
                            </option>
                            <option value="USPS_GROUND_ADVANTAGE_RETURN_SERVICE">
                                USPS Ground Advantage Return Service
                            </option>
                            <option value="USPS_RETAIL_GROUND">
                                USPS Retail Ground
                            </option>
                            <option value="GROUND_RETURN_SERVICE">
                                Ground Return Service
                            </option>
                            <option value="FIRST-CLASS_PACKAGE_RETURN_SERVICE">
                                First-Class Package Return Service
                            </option>
                            <option value="PRIORITY_MAIL_RETURN_SERVICE">
                                Priority Mail Return Service
                            </option>
                            <option value="PRIORITY_MAIL_EXPRESS_RETURN_SERVICE">
                                Priority Mail Express Return Service
                            </option>
                        </select>
                    </div>
                </div> */}
                {/* <div className="field">
                    <div className="label">Rate Indicator</div>
                    <div className="input">
                        <select
                            value={props.fields.rate_indicator}
                            onChange={handleSetField.bind(
                                this,
                                "rate_indicator"
                            )}
                        >
                            <option value="">Select option</option>
                            <option value="3D">3D - 3-Digit</option>

                            <option value="3N">
                                3N - 3-Digit Dimensional Rectangular
                            </option>
                            <option value="3R">
                                3R - 3-Digit Dimensional Nonrectangular
                            </option>
                            <option value="5D">5D - 5-Digit</option>
                            <option value="BA">BA - Basic</option>
                            <option value="BB">BB - Mixed NDC</option>
                            <option value="BM">BM - NDC</option>
                            <option value="C1">
                                C1 - Cubic Pricing Tier 1
                            </option>
                            <option value="C2">
                                C2 - Cubic Pricing Tier 2
                            </option>
                            <option value="C3">
                                C3 - Cubic Pricing Tier 3
                            </option>
                            <option value="C4">
                                C4 - Cubic Pricing Tier 4
                            </option>
                            <option value="C5">
                                C5 - Cubic Pricing Tier 5
                            </option>
                            <option value="CP">CP - Cubic Parcel</option>
                            <option value="CM">
                                CM - USPS Connect Local® Mail
                            </option>
                            <option value="DC">DC - NDC</option>
                            <option value="DE">DE - SCF</option>
                            <option value="DF">DF - 5-Digit</option>
                            <option value="DN">
                                DN - Dimensional Nonrectangular
                            </option>
                            <option value="DR">
                                DR - Dimensional Rectangular
                            </option>
                            <option value="E4">
                                E4 - Priority Mail Express Flat Rate Envelope -
                                Post Office To Addressee
                            </option>
                            <option value="E6">
                                E6 - Priority Mail Express Legal Flat Rate
                                Envelope
                            </option>
                            <option value="E7">
                                E7 - Priority Mail Express Legal Flat Rate
                                Envelope Sunday / Holiday
                            </option>
                            <option value="FA">
                                FA - Legal Flat Rate Envelope
                            </option>
                            <option value="FB">
                                FB - Medium Flat Rate Box/Large Flat Rate Bag
                            </option>
                            <option value="FE">FE - Flat Rate Envelope</option>
                            <option value="FP">
                                FP - Padded Flat Rate Envelope
                            </option>
                            <option value="FS">FS - Small Flat Rate Box</option>
                            <option value="LC">
                                LC - USPS Connect® Local Single Piece
                            </option>
                            <option value="LF">
                                LF - USPS Connect® Local Flat Rate Box
                            </option>
                            <option value="LL">
                                LL - USPS Connect® Local Large Flat Rate Bag
                            </option>
                            <option value="LO">
                                LO - USPS Connect® Local Oversized
                            </option>
                            <option value="LS">
                                LS - USPS Connect® Local Small Flat Rate Bag
                            </option>
                            <option value="NP">NP - Non-Presorted</option>
                            <option value="O1">O1 - Full Tray Box</option>
                            <option value="O2">O2 - Half Tray Box</option>
                            <option value="O3">O3 - EMM Tray Box</option>
                            <option value="O4">O4 - Flat Tub Tray Box</option>
                            <option value="O5">
                                O5 - Surface Transported Pallet
                            </option>
                            <option value="O6">O6 - Full Pallet Box</option>
                            <option value="O7">O7 - Half Pallet Box</option>
                            <option value="OS">OS - Oversized</option>
                            <option value="P5">
                                P5 - Cubic Soft Pack Tier 1
                            </option>
                            <option value="P6">
                                P6 - Cubic Soft Pack Tier 2
                            </option>
                            <option value="P7">
                                P7 - Cubic Soft Pack Tier 3
                            </option>
                            <option value="P8">
                                P8 - Cubic Soft Pack Tier 4
                            </option>
                            <option value="P9">
                                P9 - Cubic Soft Pack Tier 5
                            </option>
                            <option value="Q6">
                                Q6 - Cubic Soft Pack Tier 6
                            </option>
                            <option value="Q7">
                                Q7 - Cubic Soft Pack Tier 7
                            </option>
                            <option value="Q8">
                                Q8 - Cubic Soft Pack Tier 8
                            </option>
                            <option value="Q9">
                                Q9 - Cubic Soft Pack Tier 9
                            </option>
                            <option value="Q0">
                                Q0 - Cubic Soft Pack Tier 10
                            </option>
                            <option value="PA">
                                PA - Priority Mail Express Single Piece
                            </option>
                            <option value="PL">PL - Large Flat Rate Box</option>
                            <option value="PM">
                                PM - Large Flat Rate Box APO/FPO/DPO
                            </option>
                            <option value="PR">PR - Presorted</option>
                            <option value="SB">SB - Small Flat Rate Bag</option>
                            <option value="SN">
                                SN - SCF Dimensional Nonrectangular
                            </option>
                            <option value="SP">SP - Single Piece</option>
                            <option value="SR">
                                SR - SCF Dimensional Rectangular
                            </option>
                        </select>
                    </div>
                </div> */}
                {/* <div className="field">
                    <div className="label">Destination Entry Facility Type</div>
                    <div className="input">
                        <select
                            value={props.fields.destination_entry_facility_type}
                            onChange={handleSetField.bind(
                                this,
                                "destination_entry_facility_type"
                            )}
                        >
                            <option value="">Select option</option>
                            <option value="NONE">None</option>
                            <option value="DESTINATION_NETWORK_DISTRIBUTION_CENTER">
                                Destination Network Distribution Center
                            </option>
                            <option value="DESTINATION_SECTIONAL_CENTER_FACILITY">
                                Destination Sectional Center Facility
                            </option>
                            <option value="DESTINATION_DELIVERY_UNIT">
                                Destination Delivery Unit
                            </option>
                            <option value="DESTINATION_SERVICE_HUB">
                                Destination Service Hub
                            </option>
                        </select>
                    </div>
                </div> */}
                <div className="field">
                    <div className="label">Price Type</div>
                    <div className="input">
                        <select
                            value={props.fields.price_type}
                            onChange={handleSetField.bind(this, "price_type")}
                        >
                            <option value="">Select option</option>
                            <option value="RETAIL">Retail</option>
                            <option value="COMMERCIAL">Commercial</option>
                            {/* <option value="CONTRACT">Contract</option> */}
                        </select>
                    </div>
                </div>
                <div className="text-center">
                    <button onClick={handleGetPrice}>
                        {props.calculatingPrice ? (
                            <i className="fas fa-circle-notch fa-spin" />
                        ) : (
                            "Get Price"
                        )}
                    </button>
                </div>
            </div>
            <div className="results">{renderPriceResult()}</div>
        </div>
    );
});

export default Accounts;
