import React from 'react';

const ShippingTo = React.memo((props) => {
    const handleSetCustomerAddress = (field, event) => {
        props.handleSetField(field, event);

        if (field === 'shipping_to' && parseInt(event.target.value) === 1) {
            let customer = null;
            for (let i = 0; i < props.information.customers.length; i++) {
                if (parseInt(props.information.customers[i].id) === parseInt(props.estimate.customer_id)) {
                    customer = props.information.customers[i];
                    break;
                }
            }

            if (customer !== null) {
                props.setField('address', customer.display_name + "\n" + customer.shipping_address + "\n" + customer.shipping_city
                    + ', ' + customer.shipping_state + ' ' + customer.shipping_zip);
            }
            props.setField('sales_to_deliver', 0);
        } else if (field === 'shipping_to' && parseInt(event.target.value) === 2) {
            props.setField('address', '');
            props.setField('sales_to_deliver', 0);
        } else if (field === 'shipping_to' && parseInt(event.target.value) === 3) {
            props.setField('address', 'Will call pickup');
            props.setField('sales_to_deliver', 0);
        } else if (field === 'sales_to_deliver' && event.target.checked) {
            props.setField('address', 'Sales to deliver');
        }
    };

    let addressFieldDisabled = false;
    if (parseInt(props.estimate.shipping_to) === 1 || parseInt(props.estimate.shipping_to) === 3) {
        addressFieldDisabled = true;
    } else if (parseInt(props.estimate.sales_to_deliver)) {
        addressFieldDisabled = true;
    }

    return (
        <div className="column1">
            <div className="shipping-to-title">
                <div>Shipping to:</div>
                <div>
                    <input type="checkbox" checked={props.estimate.sales_to_deliver} onChange={handleSetCustomerAddress.bind(this, 'sales_to_deliver')} /> Sales to deliver
                </div>
            </div>
            <div className="fields">
                <div>
                    <input
                        type="radio"
                        name="shipping_to"
                        value="1"
                        checked={parseInt(props.estimate.shipping_to) === 1}
                        onChange={handleSetCustomerAddress.bind(this, 'shipping_to')}
                    /> Client address
                </div>
                <div>
                    <input
                        type="radio"
                        name="shipping_to"
                        value="2"
                        checked={parseInt(props.estimate.shipping_to) === 2}
                        onChange={handleSetCustomerAddress.bind(this, 'shipping_to')}
                    /> Other
                </div>
                <div>
                    <input
                        type="radio"
                        name="shipping_to"
                        value="3"
                        checked={parseInt(props.estimate.shipping_to) === 3}
                        onChange={handleSetCustomerAddress.bind(this, 'shipping_to')}
                    /> Will call pickup
                </div>
            </div>
            <div className="address-field">
                <textarea
                    value={props.estimate.address}
                    disabled={addressFieldDisabled}
                    onChange={props.handleSetField.bind(this, 'address')}
                />
            </div>
            <div className="client-notes-field">
                <b>Notes to client &amp; sales:</b>
                <textarea value={props.estimate.notes} onChange={props.handleSetField.bind(this, 'notes')} />
            </div>
        </div>
    );
});

export default ShippingTo;
