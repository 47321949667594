import { createSelector } from 'reselect';

export const getComponentState = state => state.mailingSubcategories;

export const getSubcategory = createSelector(
    getComponentState,
    (state) => state.subcategory
);
export const getSubcategories = createSelector(
    getComponentState,
    (state) => state.subcategories
);
export const getFetchingSubcategories = createSelector(
    getComponentState,
    (state) => state.fetchingSubcategories
);
export const getPagination = createSelector(
    getComponentState,
    (state) => state.pagination
);
export const getSort = createSelector(
    getComponentState,
    (state) => state.sort
);
export const getIdForDelete = createSelector(
    getComponentState,
    (state) => state.delete.id
);
export const getIsDeleting = createSelector(
    getComponentState,
    (state) => state.delete.isDeleting
);
export const getIsSaving = createSelector(
    getComponentState,
    (state) => state.isSaving
);
export const getFilterFields = createSelector(
    getComponentState,
    (state) => state.filter.fields
);
export const getCategoryId = createSelector(
    getComponentState,
    (state) => state.categoryId
);
export const getCategory = createSelector(
    getComponentState,
    (state) => state.category
);
export const getFetchingCategory = createSelector(
    getComponentState,
    (state) => state.fetchingCategory
);