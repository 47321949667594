import Immutable from 'immutable';
import * as types from './CcPaymentAuth.types';

const initialFields = {
    date: '',
    requested_by: '',
    client: '',
    job_invoice: '',
    amount: '',
    postage_amount: '',
    convenience_fee: '',
    total_amount: '',
    card_type: '',
    card_last4: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    email: '',
    phone: '',
    card_number: '',
    expiration_month: '',
    expiration_year: '',
    cvv: '',
    customer_name_on_card: '',
    company_name_on_card: '',
    is_pcard: 0,

    // date: 'date',
    // requested_by: '',
    // client: '',
    // job_invoice: '',
    // amount: '',
    // postage_amount: '',
    // convenience_fee: '',
    // total_amount: '12',
    // card_type: 'Visa',
    // card_last4: '',
    // address: 'address',
    // city: 'city',
    // state: 'state',
    // zip: 'zip',
    // email: 'email',
    // phone: '',
    // card_number: '123456',
    // expiration_month: '01',
    // expiration_year: '2025',
    // cvv: '123',
    // customer_name_on_card: '',
    // company_name_on_card: '',
    // is_pcard: 0,
};

const initialState = {
    fields: initialFields,
    savingForm: false,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.SAVE_FORM:
            return state.setIn(['savingForm'], true).toJS();

        case types.SAVE_FORM_SUCCESS:
            return state.setIn(['savingForm'], false).toJS();

        case types.SAVE_FORM_ERROR:
            return state.setIn(['savingForm'], false).toJS();

        case types.SET_FIELD:
            return state.setIn(['fields', action.payload.field], action.payload.value).toJS();

        case types.RESET_FIELDS:
            return state.setIn(['fields'], initialFields).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}