import axios from 'axios';
import * as types from './Search.types';
import * as selectors from './Search.selectors';
import { validateAuthInResponse } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const searchActive = () => (dispatch, getState) => {
    const pagination = selectors.getActivePagination(getState());
    const search = selectors.getSearch(getState());
    const showActiveJobs = selectors.getShowActiveJobs(getState());

    if (!showActiveJobs || search === '') {
        return;
    }

    let params = [];
    params.push('active-page=' + pagination.activePage);
    params.push('elements-per-page=' + pagination.elementsPerPage);
    params.push('description=' + search);

    dispatch({ type: types.SEARCH_ACTIVE, payload: null });
    axios.get(apiUrl + '/pqt-tracking/search-active' + (params.length === 0 ? '' : '?' + params.join('&')),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.SEARCH_ACTIVE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.SEARCH_ACTIVE_ERROR));
        });
}

export const searchFinished = () => (dispatch, getState) => {
    const pagination = selectors.getFinishedPagination(getState());
    const search = selectors.getSearch(getState());
    const finishedActiveJobs = selectors.getShowFinishedJobs(getState());

    if (!finishedActiveJobs || search === '') {
        return;
    }

    let params = [];
    params.push('active-page=' + pagination.activePage);
    params.push('elements-per-page=' + pagination.elementsPerPage);
    params.push('description=' + search);

    dispatch({ type: types.SEARCH_FINISHED, payload: null });
    axios.get(apiUrl + '/pqt-tracking/search-finished' + (params.length === 0 ? '' : '?' + params.join('&')),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.SEARCH_FINISHED_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.SEARCH_FINISHED_ERROR));
        });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null
    });
}

export const setActivePage = (value) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_ACTIVE_PAGE,
            payload: value
        });
        resolve();
    }).then(() => {
        dispatch(searchActive());
    });
}

export const setFinishedPage = (value) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_FINISHED_PAGE,
            payload: value
        });
        resolve();
    }).then(() => {
        dispatch(searchFinished());
    });
}

export const setSearch = (value) => (dispatch) => {
    dispatch({
        type: types.SET_SEARCH,
        payload: value,
    });
}
export const setShowActiveJobs = (value) => (dispatch) => {
    dispatch({
        type: types.SET_SHOW_ACTIVE_JOBS,
        payload: value,
    });
}
export const setShowFinishedJobs = (value) => (dispatch) => {
    dispatch({
        type: types.SET_SHOW_FINISHED_JOBS,
        payload: value,
    });
}