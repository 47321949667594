import React from 'react';
import { Dialog } from './../../common/dialog';

const UnpauseModal = (props) => {
    const hideHandler = () => {
        props.setIdModal(null);
        props.hideUnpauseModal();
    };

    const unpauseHandler = () => {
        props.unpauseDepartment();
    };

    let label = 'Unpause';
    if (props.isUnpausing) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Cancel</button>,
        <button key="approve-button" className="btn btn-light btn-sm action-button" onClick={unpauseHandler} disabled={props.isUnpausing}>{label}</button>
    ];
    return (
        <Dialog
            name="unpause-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="unpause-dialog"
        >
            <div className="unpause-modal-container">
                <h1>Unpause Department?</h1>
                <p className="notice">
                    Confirm that you want to unpause this department.
                </p>
            </div>
        </Dialog >
    );
};

export default UnpauseModal;
