import { createSelector } from 'reselect';

export const getComponentState = state => state.pqtPostageInvoice;

export const getFetching = createSelector(
    getComponentState,
    (state) => state.fetching
);

export const getInvoice = createSelector(
    getComponentState,
    (state) => state.invoice
);

export const getInformation = createSelector(
    getComponentState,
    (state) => state.information
);

export const getFetchingCustomerData = createSelector(
    getComponentState,
    (state) => state.fetchingCustomerData
);

export const getIsSaving = createSelector(
    getComponentState,
    (state) => state.isSaving
);

export const getCustomerEmail = createSelector(
    getComponentState,
    (state) => state.customerEmail
);

export const getJobDueDate = createSelector(
    getComponentState,
    (state) => state.jobDueDate
);

export const getFetchingPostageEscrow = createSelector(
    getComponentState,
    (state) => state.fetchingPostageEscrow
);

export const getPostageEscrow = createSelector(
    getComponentState,
    (state) => state.postageEscrow
);

export const getIsApproving = createSelector(
    getComponentState,
    (state) => state.isApproving
);