import axios from 'axios';
import * as types from './MailingCategories.types';
import * as selectors from './MailingCategories.selectors';
import * as dialog from '../common/dialog';
import { validateAuthInResponse, showDataDeletedNotification, empty, showNotification } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchCategories = () => (dispatch, getState) => {
    const sort = selectors.getSort(getState());
    const pagination = selectors.getPagination(getState());
    const filerFields = selectors.getFilterFields(getState());

    let params = [];
    params.push('sort=' + (sort.type === 'asc' ? '' : '-') + sort.column);
    params.push('active-page=' + pagination.activePage);
    params.push('elements-per-page=' + pagination.elementsPerPage);

    if (filerFields.length > 0) {
        let filterElements = [];
        for (let i = 0; i < filerFields.length; i++) {
            filterElements.push(filerFields[i]['name'] + '=' + filerFields[i]['value']);
        }
        params.push('filter=' + filterElements.join(','))
    } else {
        params.push('filter=-')
    }

    dispatch({ type: types.FETCH_CATEGORIES, payload: null });
    axios.get(apiUrl + '/mailing-categories' + (params.length === 0 ? '' : '?' + params.join('&')),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_CATEGORIES_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_CATEGORIES_ERROR));
        });
}

export const deleteCategory = (id) => (dispatch) => {
    dispatch({ type: types.DELETE_CATEGORY, payload: null });
    axios.delete(apiUrl + '/mailing-categories/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } }
    ).then(() => {
        dispatch({ type: types.DELETE_CATEGORY_SUCCESS, payload: null });
        showDataDeletedNotification();
        dispatch(fetchCategories());
        dispatch(dialog.actions.hideDialog('delete-modal'));
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.DELETE_CATEGORY_ERROR));
    });
}

export const saveCategory = () => (dispatch, getState) => {
    const category = selectors.getCategory(getState());
    if (empty(category.name)) {
        return showNotification('Complete Information', 'Enter the Name', 'info');
    }

    let endpoint = apiUrl + '/mailing-categories';
    let method = 'post';
    if (category.id && category.id != null) {
        endpoint = apiUrl + '/mailing-categories/' + category.id;
        method = 'put';
    }

    dispatch({ type: types.SAVE_CATEGORY, payload: null });
    axios({
        method: method,
        url: endpoint,
        data: category,
        headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
    }).then((response) => {
        dispatch({ type: types.SAVE_CATEGORY_SUCCESS, payload: response.data });
        showNotification('Category Saved', 'The Category has been saved successfully', 'success');
        dispatch(fetchCategories());
        dispatch(dialog.actions.hideDialog('form-modal'));
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.SAVE_CATEGORY_ERROR));
    });
}

export const setSort = (column, type) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_SORT,
            payload: {
                column: column,
                type: type,
            }
        });
        resolve();
    }).then(() => {
        dispatch(fetchCategories());
    });
}

export const setActivePage = (value) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_ACTIVE_PAGE,
            payload: value
        });
        resolve();
    }).then(() => {
        dispatch(fetchCategories());
    });
}

export const mountComponent = () => (dispatch) => {
    dispatch(resetState());
    dispatch(fetchCategories());
}

export const setIdForDelete = (id) => (dispatch) => {
    dispatch({
        type: types.SET_ID_FOR_DELETE,
        payload: id
    });
}

export const changeFilter = (name, value) => (dispatch) => {
    new Promise((resolve) => {
        dispatch({
            type: types.CHANGE_FILTER,
            payload: { name, value },
        });
        resolve();
    }).then(() => {
        dispatch(fetchCategories());
    });
}

export const setField = (field, value) => (dispatch) => {
    dispatch({
        type: types.SET_FIELD,
        payload: { field, value },
    });
}

export const setCategory = (value) => (dispatch) => {
    dispatch({
        type: types.SET_CATEGORY,
        payload: value,
    });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null,
    });
}