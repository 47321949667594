import React, { Fragment } from 'react';
import numeral from 'numeral';
import moment from 'moment';
import { empty } from '../../../utils';

const RfqSpecification = React.memo((props) => {
    const estimate = props.estimate;

    if (props.rfq === null) {
        return null;
    }

    if (estimate.sc_quote_request_id && estimate.sc_quote_request_id != null) {
        let processRequest = null;
        if (parseInt(props.rfq.process_request) === 1) {
            processRequest = 'Print Only';
        } else if (parseInt(props.rfq.process_request) === 2) {
            processRequest = 'Mail Only';
        } else if (parseInt(props.rfq.process_request) === 3) {
            processRequest = 'Print and Mail';
        } else if (parseInt(props.rfq.process_request) === 4) {
            processRequest = 'Indoor';
        } else if (parseInt(props.rfq.process_request) === 5) {
            processRequest = 'Promotional Products';
        } else if (parseInt(props.rfq.process_request) === 6) {
            processRequest = 'Artwork Only';
        } else if (parseInt(props.rfq.process_request) === 7) {
            processRequest = 'Self-Cover';
        } else if (parseInt(props.rfq.process_request) === 8) {
            processRequest = 'Plus-Cover';
        } else if (parseInt(props.rfq.process_request) === 9) {
            processRequest = 'Outdoor';
        }

        const quantityRow = <div><b>Quantity:</b> {numeral(props.rfq.quantity1).format('0,0')}</div>;
        const processRequestRow = <div><b>Process Request:</b> {processRequest}</div>;
        const titleRow = <div><b>Title:</b> {props.rfq.description_title}</div>;

        let flatSizeRow = null;
        let finishSizeRow = null;
        let coverPaperStockRow = null;
        let coverPaperWeightRow = null;
        let paperStockRow = null;
        let paperWeightRow = null;
        let frontColorsRow = null;
        let frontPmsRow = null;
        let frontCoatingRow = null;
        let backColorsRow = null;
        let backPmsRow = null;
        let backCoatingRow = null;
        let coverFrontColorsRow = null;
        let coverFrontPmsRow = null;
        let coverFrontCoatingRow = null;
        let coverBackColorsRow = null;
        let coverBackPmsRow = null;
        let coverBackCoatingRow = null;
        let pageCountRow = null;
        let bindingEdgeRow = null;
        let bindingTypeRow = null;
        let substrateRow = null;
        let laminateRow = null;
        let accessoriesRow = null;
        let folderTypeRow = null;
        let pocketStyleRow = null;
        let cardSlitsRow = null;
        let bcSlitStlyeRow = null;
        let envelopeTypeRow = null;
        let envelopeStyleRow = null;
        let whenFinishRow = null;
        let printLocationRow = null;
        let binderyFoldingTypeRow = null;
        let binderyPaddingRow = null;
        let binderyShrinkWrappingRow = null;
        let binderyDrillingHolesRow = null;
        let binderyHolesLocationRow = null;
        let binderyScoringRow = null;
        let binderyDieCuttingRow = null;
        let ncrSetsRow = null;
        let partsRow = null;
        let glueLocationRow = null;
        let perforateRow = null;
        let backingRow = null;
        let crashNumberRow = null;
        let padsRow = null;
        let sheetsPerPadRow = null;
        let originalsRow = null;
        let typeOfMaterialsRow = null;
        let materialArriveRow = null;
        let leftoversRow = null;

        let bindingEdge = '';
        if (parseInt(props.rfq.binding_edge) === 1) {
            bindingEdge = 'Width';
        } else if (parseInt(props.rfq.binding_edge) === 2) {
            bindingEdge = 'Height';
        }

        let bindingType = '';
        if (parseInt(props.rfq.binding_type) === 1) {
            bindingType = 'Saddle Stitch';
        } else if (parseInt(props.rfq.binding_type) === 2) {
            bindingType = 'Perfect Bind';
        } else if (parseInt(props.rfq.binding_type) === 3) {
            bindingType = 'Wire-O';
        } else if (parseInt(props.rfq.binding_type) === 4) {
            bindingType = 'GBC';
        } else if (parseInt(props.rfq.binding_type) === 5) {
            bindingType = 'Spiral';
        } else if (parseInt(props.rfq.binding_type) === 6) {
            bindingType = 'Wire';
        } else if (parseInt(props.rfq.binding_type) === 7) {
            bindingType = 'Staple';
        }

        if (props.rfq.simple_flat) {
            flatSizeRow = <div class="mt-3"><b>Flat Size:</b> {props.rfq.flat_width}x{props.rfq.flat_height}</div>;
            finishSizeRow = <div><b>Finish Size:</b> {props.rfq.finish_width}x{props.rfq.finish_height}</div>;
            paperStockRow = <div className="mt-3"><b>Paper Type:</b> {props.rfq.paper_stock_name}</div>;
            paperWeightRow = <div><b>Paper Weight:</b> {props.rfq.paper_weight}#</div>;

            frontColorsRow = <div class="mt-3"><b>Front Colors:</b> {numeral(props.rfq.front_colors).format('0,0')} ({props.getInks(props.rfq.front_inks)})</div>;
            frontCoatingRow = <div><b>Front Coating:</b> {props.getCoating(props.rfq.front_coating)}</div>;
            backColorsRow = <div><b>Back Colors:</b> {numeral(props.rfq.back_colors).format('0,0')} ({props.getInks(props.rfq.back_inks)})</div>;
            backCoatingRow = <div><b>Back Coating:</b> {props.getCoating(props.rfq.back_coating)}</div>;

            if (!empty(props.rfq.front_pms)) {
                frontPmsRow = <div><b>Front PMS#:</b> {props.rfq.front_pms}</div>;
            }
            if (!empty(props.rfq.back_pms)) {
                backPmsRow = <div><b>Back PMS#:</b> {props.rfq.back_pms}</div>;
            }
        } else if (props.rfq.self_cover) {
            pageCountRow = <div class="mt-3"><b>Page Count:</b> {props.rfq.page_count}</div>;
            bindingEdgeRow = <div><b>Binding Edge:</b> {bindingEdge}</div>;
            bindingTypeRow = <div><b>Binding Type:</b> {bindingType}</div>;

            flatSizeRow = <div class="mt-3"><b>Flat Size:</b> {props.rfq.flat_width}x{props.rfq.flat_height}</div>;
            finishSizeRow = <div><b>Finish Size:</b> {props.rfq.finish_width}x{props.rfq.finish_height}</div>;
            paperStockRow = <div className="mt-3"><b>Paper Type:</b> {props.rfq.paper_stock_name}</div>;
            paperWeightRow = <div><b>Paper Weight:</b> {props.rfq.paper_weight}#</div>;

            frontColorsRow = <div class="mt-3"><b>Interior Colors:</b> {numeral(props.rfq.front_colors).format('0,0')} ({props.getInks(props.rfq.front_inks)})</div>;
            frontCoatingRow = <div><b>Interior Coating:</b> {props.getCoating(props.rfq.front_coating)}</div>;
            if (!empty(props.rfq.front_pms)) {
                frontPmsRow = <div><b>Front PMS#:</b> {props.rfq.front_pms}</div>;
            }
        } else if (props.rfq.plus_cover) {
            pageCountRow = <div class="mt-3"><b>Page Count:</b> {props.rfq.interior_pages}</div>;
            bindingEdgeRow = <div><b>Binding Edge:</b> {bindingEdge}</div>;
            bindingTypeRow = <div><b>Binding Type:</b> {bindingType}</div>;

            flatSizeRow = <div class="mt-3"><b>Flat Size:</b> {props.rfq.flat_width}x{props.rfq.flat_height}</div>;
            finishSizeRow = <div><b>Finish Size:</b> {props.rfq.finish_width}x{props.rfq.finish_height}</div>;
            coverPaperStockRow = <div className="mt-3"><b>Cover Paper Type:</b> {props.rfq.paper_stock_name}</div>;
            coverPaperWeightRow = <div><b>Cover Paper Weight:</b> {props.rfq.paper_weight}#</div>;
            paperStockRow = <div className="mt-3"><b>Paper Type:</b> {props.rfq.interior_paper_stock_name}</div>;
            paperWeightRow = <div><b>Paper Weight:</b> {props.rfq.interior_paper_weight}#</div>;

            coverFrontColorsRow = <div class="mt-3"><b>Cover Front Colors:</b> {numeral(props.rfq.front_colors).format('0,0')} ({props.getInks(props.rfq.front_inks)})</div>;
            coverFrontCoatingRow = <div><b>Cover Front Coating:</b> {props.getCoating(props.rfq.front_coating)}</div>;
            coverBackColorsRow = <div><b>Cover Back Colors:</b> {numeral(props.rfq.back_colors).format('0,0')} ({props.getInks(props.rfq.back_inks)})</div>;
            coverBackCoatingRow = <div><b>Cover Back Coating:</b> {props.getCoating(props.rfq.back_coating)}</div>;
            if (!empty(props.rfq.front_pms)) {
                coverFrontPmsRow = <div><b>Cover Front PMS#:</b> {props.rfq.front_pms}</div>;
            }
            if (!empty(props.rfq.back_pms)) {
                coverBackPmsRow = <div><b>Cover Back PMS#:</b> {props.rfq.back_pms}</div>;
            }

            frontColorsRow = <div class="mt-3"><b>Interior Colors:</b> {numeral(props.rfq.interior_front_colors).format('0,0')} ({props.getInks(props.rfq.interior_front_inks)})</div>;
            frontCoatingRow = <div><b>Interior Coating:</b> {props.getCoating(props.rfq.interior_front_coating)}</div>;
            if (!empty(props.rfq.inerior_front_pms)) {
                frontPmsRow = <div><b>Interior PMS#:</b> {props.rfq.inerior_front_pms}</div>;
            }
        } else if (props.rfq.envelopes) {
            frontColorsRow = <div class="mt-3"><b>Front Colors:</b> {numeral(props.rfq.front_colors).format('0,0')} ({props.getInks(props.rfq.front_inks)})</div>;
            frontCoatingRow = <div><b>Front Coating:</b> {props.getCoating(props.rfq.front_coating)}</div>;
            backColorsRow = <div><b>Back Colors:</b> {numeral(props.rfq.back_colors).format('0,0')} ({props.getInks(props.rfq.back_inks)})</div>;
            backCoatingRow = <div><b>Back Coating:</b> {props.getCoating(props.rfq.back_coating)}</div>;

            envelopeTypeRow = <div class="mt-3"><b>Envelope Type:</b> {props.rfq.envelope_paper_type_name}</div>;
            envelopeStyleRow = <div><b>Envelope Style:</b> {props.rfq.envelope_paper_style}</div>;

            let whenFinish = [];
            if (!empty(props.rfq.part_another_job) && props.rfq.part_another_job) {
                whenFinish.push('Part of another job (' + props.rfq.sc_number + ')');
            }
            if (!empty(props.rfq.done_when_completed) && props.rfq.done_when_completed) {
                whenFinish.push('Done when completed');
            }
            if (!empty(props.rfq.goes_fulfillment) && props.rfq.goes_fulfillment) {
                whenFinish.push('Goes to fulfillment');
            }
            if (whenFinish.length > 0) {
                whenFinishRow = <div><b>When Finish:</b> {whenFinish.join(', ')}</div>;
            }

            let printLocation = [];
            if (!empty(props.rfq.has_return_address) && props.rfq.has_return_address) {
                printLocation.push('Return address');
            }
            if (!empty(props.rfq.has_back_flap) && props.rfq.has_back_flap) {
                printLocation.push('Back flap');
            }
            if (!empty(props.rfq.has_address_area) && props.rfq.has_address_area) {
                printLocation.push('Address area');
            }
            if (!empty(props.rfq.has_other) && props.rfq.has_other) {
                printLocation.push('Other (' + props.rfq.envelope_other + ')');
            }
            if (printLocation.length > 0) {
                printLocationRow = <div><b>Print Location:</b> {printLocation.join(', ')}</div>;
            }
        } else if (props.rfq.large_format) {
            let unit = '';
            if (parseInt(props.rfq.unit) === 1) {
                unit = 'Inches';
            } else if (parseInt(props.rfq.unit) === 2) {
                unit = 'Feet';
            }

            flatSizeRow = <div class="mt-3"><b>Size:</b> {props.rfq.width}x{props.rfq.height} ({unit})</div>;
            substrateRow = <div><b>Substrate:</b> {props.rfq.substrate_name}</div>;

            if (!empty(props.rfq.laminate_name)) {
                laminateRow = <div><b>Laminate:</b> {props.rfq.laminate_name}</div>;
            }

            if (props.rfq.accessories_names.length > 0) {
                accessoriesRow = <div><b>Accessories:</b> {props.rfq.accessories_names.join(', ')}</div>;
            }
        } else if (props.rfq.pocket_folder) {
            finishSizeRow = <div><b>Finish Size:</b> {props.rfq.finish_width}x{props.rfq.finish_height}</div>;
            paperStockRow = <div className="mt-3"><b>Paper Type:</b> {props.rfq.paper_stock_name}</div>;
            paperWeightRow = <div><b>Paper Weight:</b> {props.rfq.paper_weight}#</div>;

            frontColorsRow = <div class="mt-3"><b>Front Colors:</b> {numeral(props.rfq.front_colors).format('0,0')} ({props.getInks(props.rfq.front_inks)})</div>;
            frontCoatingRow = <div><b>Front Coating:</b> {props.getCoating(props.rfq.front_coating)}</div>;
            backColorsRow = <div><b>Back Colors:</b> {numeral(props.rfq.back_colors).format('0,0')} ({props.getInks(props.rfq.back_inks)})</div>;
            backCoatingRow = <div><b>Back Coating:</b> {props.getCoating(props.rfq.back_coating)}</div>;

            if (!empty(props.rfq.front_pms)) {
                frontPmsRow = <div><b>Front PMS#:</b> {props.rfq.front_pms}</div>;
            }
            if (!empty(props.rfq.back_pms)) {
                backPmsRow = <div><b>Back PMS#:</b> {props.rfq.back_pms}</div>;
            }

            folderTypeRow = <div class="mt-3"><b>Folder Type:</b> {props.rfq.folder_type_name}</div>;
            pocketStyleRow = <div><b>Pocket Style:</b> {props.rfq.pocket_style_name}</div>;
            cardSlitsRow = <div><b>Card Slits:</b> {props.rfq.card_slits_name}</div>;
            bcSlitStlyeRow = <div><b>Business Card Slit Style:</b> {props.rfq.bc_card_slit_style_name}</div>;
        } else if (props.rfq.is_ncr) {
            finishSizeRow = <div><b>Finish Size:</b> {props.rfq.finish_width}x{props.rfq.finish_height}</div>;
            paperStockRow = <div className="mt-3"><b>Paper Type:</b> {props.rfq.paper_stock_name}</div>;
            paperWeightRow = <div><b>Paper Weight:</b> {props.rfq.paper_weight}#</div>;

            frontColorsRow = <div class="mt-3"><b>Front Colors:</b> {numeral(props.rfq.front_colors).format('0,0')} ({props.getInks(props.rfq.front_inks)})</div>;
            frontCoatingRow = <div><b>Front Coating:</b> {props.getCoating(props.rfq.front_coating)}</div>;
            backColorsRow = <div><b>Back Colors:</b> {numeral(props.rfq.back_colors).format('0,0')} ({props.getInks(props.rfq.back_inks)})</div>;
            backCoatingRow = <div><b>Back Coating:</b> {props.getCoating(props.rfq.back_coating)}</div>;

            if (!empty(props.rfq.front_pms)) {
                frontPmsRow = <div><b>Front PMS#:</b> {props.rfq.front_pms}</div>;
            }
            if (!empty(props.rfq.back_pms)) {
                backPmsRow = <div><b>Back PMS#:</b> {props.rfq.back_pms}</div>;
            }

            let ncrParts = 'N/A';
            if (parseInt(props.rfq.ncr_parts) === 2) {
                ncrParts = '2-parts W/C';
            } else if (parseInt(props.rfq.ncr_parts) === 3) {
                ncrParts = '3-parts W/C/P';
            } else if (parseInt(props.rfq.ncr_parts) === 4) {
                ncrParts = '4-parts W/C/P/GR';
            } else if (parseInt(props.rfq.ncr_parts) === 5) {
                ncrParts = '5-parts W/G/C/P';
            }

            let glueLocation = 'N/A';
            if (parseInt(props.rfq.glue_location) === 1) {
                glueLocation = 'Top';
            } else if (parseInt(props.rfq.glue_location) === 2) {
                glueLocation = 'Left Side';
            } else if (parseInt(props.rfq.glue_location) === 3) {
                glueLocation = 'Right Side';
            }

            let perforate = 'N/A';
            if (parseInt(props.rfq.perforate) === 1) {
                perforate = 'Top';
            } else if (parseInt(props.rfq.perforate) === 2) {
                perforate = 'Side';
            } else if (parseInt(props.rfq.perforate) === 3) {
                perforate = 'No Perforate';
            }

            let backing = 'N/A';
            if (parseInt(props.rfq.backing) === 1) {
                backing = 'With Chip Board';
            } else {
                backing = 'No Chip Board';
            }

            let crashNumber = 'None';
            if (parseInt(props.rfq.use_crash_number) === 1) {
                crashNumber = 'From ' + props.rfq.crash_number_start + ' to ' + props.rfq.crash_number_end + ' (' + props.rfq.crash_number_color + ')';
            }

            ncrSetsRow = <div class="mt-3"><b>Number of Sets:</b> {props.rfq.ncr_sets}</div>;
            partsRow = <div><b>Number of Parts:</b> {ncrParts}</div>;
            glueLocationRow = <div><b>Glue Location:</b> {glueLocation}</div>;
            perforateRow = <div><b>Perforate:</b> {perforate}</div>;
            backingRow = <div><b>Backing:</b> {backing}</div>;
            crashNumberRow = <div><b>Crash Number:</b> {crashNumber}</div>;
        } else if (props.rfq.is_notepads) {
            finishSizeRow = <div><b>Finish Size:</b> {props.rfq.finish_width}x{props.rfq.finish_height}</div>;
            paperStockRow = <div className="mt-3"><b>Paper Type:</b> {props.rfq.paper_stock_name}</div>;
            paperWeightRow = <div><b>Paper Weight:</b> {props.rfq.paper_weight}#</div>;

            frontColorsRow = <div class="mt-3"><b>Front Colors:</b> {numeral(props.rfq.front_colors).format('0,0')} ({props.getInks(props.rfq.front_inks)})</div>;
            frontCoatingRow = <div><b>Front Coating:</b> {props.getCoating(props.rfq.front_coating)}</div>;
            backColorsRow = <div><b>Back Colors:</b> {numeral(props.rfq.back_colors).format('0,0')} ({props.getInks(props.rfq.back_inks)})</div>;
            backCoatingRow = <div><b>Back Coating:</b> {props.getCoating(props.rfq.back_coating)}</div>;

            if (!empty(props.rfq.front_pms)) {
                frontPmsRow = <div><b>Front PMS#:</b> {props.rfq.front_pms}</div>;
            }
            if (!empty(props.rfq.back_pms)) {
                backPmsRow = <div><b>Back PMS#:</b> {props.rfq.back_pms}</div>;
            }

            let glueLocation = 'N/A';
            if (parseInt(props.rfq.glue_location) === 1) {
                glueLocation = 'Top';
            } else if (parseInt(props.rfq.glue_location) === 2) {
                glueLocation = 'Left Side';
            } else if (parseInt(props.rfq.glue_location) === 3) {
                glueLocation = 'Right Side';
            }

            let backing = 'N/A';
            if (parseInt(props.rfq.backing) === 1) {
                backing = 'With Chip Board';
            } else {
                backing = 'No Chip Board';
            }

            let crashNumber = 'None';
            if (parseInt(props.rfq.use_crash_number) === 1) {
                crashNumber = 'From ' + props.rfq.crash_number_start + ' to ' + props.rfq.crash_number_end + ' (' + props.rfq.crash_number_color + ')';
            }

            padsRow = <div class="mt-3"><b>Number of Pads:</b> {props.rfq.pads}</div>;
            sheetsPerPadRow = <div><b>Sheets per Pad:</b> {props.rfq.sheets_per_pad}</div>;
            glueLocationRow = <div><b>Glue Location:</b> {glueLocation}</div>;
            backingRow = <div><b>Backing:</b> {backing}</div>;
            crashNumberRow = <div><b>Crash Number:</b> {crashNumber}</div>;
        } else if (props.rfq.is_mail_only) {
            finishSizeRow = <div><b>Finish Size:</b> {props.rfq.finish_width}x{props.rfq.finish_height}</div>;

            let leftovers = '';
            if (parseInt(props.rfq.leftovers_to_do) === 1) {
                leftovers = 'Hold for client to pickup';
            } else if (parseInt(props.rfq.leftovers_to_do) === 2) {
                leftovers = 'Ship back to client (chargeable)';
            } else if (parseInt(props.rfq.leftovers_to_do) === 3) {
                leftovers = 'Recycle';
            }

            originalsRow = <div><b>Originals:</b> {props.rfq.originals}</div>;
            typeOfMaterialsRow = <div><b>Type of Materials:</b> {props.rfq.type_of_materials}</div>;
            materialArriveRow = <div><b>Material arrive:</b> {moment(props.rfq.material_coming_date).format('MM/DD/YYYY')}</div>;
            leftoversRow = <div><b>Leftovers:</b> {leftovers}</div>;
        }

        if (props.rfq.bindery) {
            let folding = 'None';
            if (parseInt(props.rfq.folding_type) === 1) {
                folding = 'Half Fold';
            } else if (parseInt(props.rfq.folding_type) === 40) {
                folding = 'Tri-fold';
            } else if (parseInt(props.rfq.folding_type) === 39) {
                folding = 'Z Folding';
            } else if (parseInt(props.rfq.folding_type) === 43) {
                folding = 'Accordion';
            } else if (parseInt(props.rfq.folding_type) === 42) {
                folding = 'Double Gatefold';
            } else if (parseInt(props.rfq.folding_type) === 41) {
                folding = 'Gatefold';
            }
            binderyFoldingTypeRow = <div><b>Folding:</b> {folding}</div>;

            let padding = 'None';
            if (parseInt(props.rfq.padding) === 25) {
                padding = '25/per Pad';
            } else if (parseInt(props.rfq.padding) === 50) {
                padding = '50/per Pad';
            } else if (parseInt(props.rfq.padding) === 100) {
                padding = '100/per Pad';
            } else if (parseInt(props.rfq.padding) === 200) {
                padding = '200/per Pad';
            } else if (parseInt(props.rfq.padding) === 250) {
                padding = '250/per Pad';
            }
            binderyPaddingRow = <div><b>Padding:</b> {padding}</div>;

            let shrinkWrapping = 'None';
            if (parseInt(props.rfq.shrink_wrapping) === 25) {
                shrinkWrapping = '25/per Bundle';
            } else if (parseInt(props.rfq.shrink_wrapping) === 50) {
                shrinkWrapping = '50/per Bundle';
            } else if (parseInt(props.rfq.shrink_wrapping) === 100) {
                shrinkWrapping = '100/per Bundle';
            } else if (parseInt(props.rfq.shrink_wrapping) === 200) {
                shrinkWrapping = '200/per Bundle';
            } else if (parseInt(props.rfq.shrink_wrapping) === 250) {
                shrinkWrapping = '250/per Bundle';
            }
            binderyShrinkWrappingRow = <div><b>Shrink Wrapping:</b> {shrinkWrapping}</div>;

            let drillingHoles = 'None';
            if (parseInt(props.rfq.drilling_holes) === 1) {
                drillingHoles = '1';
            } else if (parseInt(props.rfq.drilling_holes) === 2) {
                drillingHoles = '2';
            } else if (parseInt(props.rfq.drilling_holes) === 3) {
                drillingHoles = '3';
            } else if (parseInt(props.rfq.drilling_holes) === 4) {
                drillingHoles = '4';
            } else if (parseInt(props.rfq.drilling_holes) === 5) {
                drillingHoles = '5';
            } else if (parseInt(props.rfq.drilling_holes) === 6) {
                drillingHoles = '6';
            } else if (parseInt(props.rfq.drilling_holes) === 7) {
                drillingHoles = '7';
            } else if (parseInt(props.rfq.drilling_holes) === 8) {
                drillingHoles = '8';
            }
            binderyDrillingHolesRow = <div><b>Drilling Holes:</b> {drillingHoles}</div>;

            let holesLocation = 'None';
            if (parseInt(props.rfq.holes_location) === 1) {
                holesLocation = 'Top Center';
            } else if (parseInt(props.rfq.holes_location) === 2) {
                holesLocation = 'Left';
            } else if (parseInt(props.rfq.holes_location) === 3) {
                holesLocation = 'Right';
            } else if (parseInt(props.rfq.holes_location) === 4) {
                holesLocation = 'Bottom Center';
            }
            binderyHolesLocationRow = <div><b>Holes Location:</b> {holesLocation}</div>;

            binderyScoringRow = <div><b>Scoring:</b> {props.rfq.scoring ? 'Yes' : 'No'}</div>;

            binderyDieCuttingRow = <div><b>Die-Cutting:</b> {props.rfq.die_cutting ? 'Yes' : 'No'}</div>;
        }

        let filesContainer = null;
        const files = props.rfq.files != null ? JSON.parse(props.rfq.files) : null;
        if (files != null && files.length > 0) {
            filesContainer = (
                <div className="files-container">
                    <div><b>Files:</b></div>
                    {files.map((file) => {
                        return (
                            <div className="file">
                                <a href={file.url} target="_blank" rel="noopener noreferrer">{file.name}</a>
                            </div>
                        );
                    })}
                </div>
            )
        }

        let descriptionRow = null;
        if (empty(props.estimate.sc_quote_request_id)) {
            descriptionRow = <div><b>Description:</b> {props.rfq.information_description}</div>;
        } else {
            const descriptions = [];
            if (estimate.information_description) {
                descriptions.push(<div><strong>Information:</strong> {estimate.information_description}</div>);
            }
            if (estimate.flat_description) {
                descriptions.push(<div><strong>Flat Information:</strong> {estimate.flat_description}</div>);
            }
            if (estimate.self_cover_description) {
                descriptions.push(<div><strong>Self-Cover Information:</strong> {estimate.self_cover_description}</div>);
            }
            if (estimate.plus_cover_description) {
                descriptions.push(<div><strong>Plus-Cover Information:</strong> {estimate.plus_cover_description}</div>);
            }
            if (estimate.envelopes_description) {
                descriptions.push(<div><strong>Envelopes Information:</strong> {estimate.envelopes_description}</div>);
            }
            if (estimate.bindery_description) {
                descriptions.push(<div><strong>Bindery Information:</strong> {estimate.bindery_description}</div>);
            }
            if (estimate.data_processing_description) {
                descriptions.push(<div><strong>Data Processing Information:</strong> {estimate.data_processing_description}</div>);
            }
            if (estimate.mailing_services_description) {
                descriptions.push(<div><strong>Mail Services Information:</strong> {estimate.mailing_services_description}</div>);
            }
            if (estimate.handwork_description) {
                descriptions.push(<div><strong>Handwork Information:</strong> {estimate.handwork_description}</div>);
            }
            if (estimate.large_format_description) {
                descriptions.push(<div><strong>Large Format Information:</strong> {estimate.large_format_description}</div>);
            }
            if (estimate.pocket_folder_description) {
                descriptions.push(<div><strong>Pocket Folder Information:</strong> {estimate.pocket_folder_description}</div>);
            }
            if (estimate.ncr_description) {
                descriptions.push(<div><strong>NCR Information:</strong> {estimate.ncr_description}</div>);
            }
            if (estimate.notepads_description) {
                descriptions.push(<div><strong>Notepads Information:</strong> {estimate.notepads_description}</div>);
            }
            if (estimate.mail_only_description) {
                descriptions.push(<div><strong>Mail Only Information:</strong> {estimate.mail_only_description}</div>);
            }

            descriptionRow = (
                <div className="description-container">
                    <div className="description-line">{descriptions}</div>
                </div>
            );
        }

        return (
            <Fragment>
                <div className="section-title mt-3 mb-2">RFQ Specification:</div>
                <div className="specification-container">
                    {quantityRow}
                    {processRequestRow}
                    {titleRow}
                    {descriptionRow}
                    {pageCountRow}
                    {bindingEdgeRow}
                    {bindingTypeRow}
                    {flatSizeRow}
                    {finishSizeRow}
                    {coverPaperStockRow}
                    {coverPaperWeightRow}
                    {paperStockRow}
                    {paperWeightRow}
                    {coverFrontColorsRow}
                    {coverFrontCoatingRow}
                    {coverFrontPmsRow}
                    {coverBackColorsRow}
                    {coverBackCoatingRow}
                    {coverBackPmsRow}
                    {frontColorsRow}
                    {frontPmsRow}
                    {frontCoatingRow}
                    {backColorsRow}
                    {backPmsRow}
                    {backCoatingRow}
                    {substrateRow}
                    {laminateRow}
                    {accessoriesRow}
                    {folderTypeRow}
                    {pocketStyleRow}
                    {cardSlitsRow}
                    {bcSlitStlyeRow}
                    {padsRow}
                    {sheetsPerPadRow}
                    {ncrSetsRow}
                    {partsRow}
                    {glueLocationRow}
                    {perforateRow}
                    {backingRow}
                    {crashNumberRow}
                    {envelopeTypeRow}
                    {envelopeStyleRow}
                    {whenFinishRow}
                    {printLocationRow}
                    {binderyFoldingTypeRow}
                    {binderyPaddingRow}
                    {binderyShrinkWrappingRow}
                    {binderyDrillingHolesRow}
                    {binderyHolesLocationRow}
                    {binderyScoringRow}
                    {binderyDieCuttingRow}
                    {originalsRow}
                    {typeOfMaterialsRow}
                    {materialArriveRow}
                    {leftoversRow}
                    {filesContainer}
                </div>
            </Fragment>
        );
    }

    return null;
});

export default RfqSpecification;
