import { createSelector } from 'reselect';

export const getComponentState = state => state.apItems;

export const getFetchingItems = createSelector(
    getComponentState,
    (state) => state.fetchingItems
);
export const getItems = createSelector(
    getComponentState,
    (state) => state.items
);
export const getPagination = createSelector(
    getComponentState,
    (state) => state.pagination
);
export const getSort = createSelector(
    getComponentState,
    (state) => state.sort
);
export const getIdForDelete = createSelector(
    getComponentState,
    (state) => state.delete.id
);
export const getIsDeleting = createSelector(
    getComponentState,
    (state) => state.delete.isDeleting
);
export const getFilterFields = createSelector(
    getComponentState,
    (state) => state.filter.fields
);
export const getUploadingFile = createSelector(
    getComponentState,
    (state) => state.uploadingFile
);
export const getFields = createSelector(
    getComponentState,
    (state) => state.fields
);
export const getFetchingItem = createSelector(
    getComponentState,
    (state) => state.fetchingItem
);
export const getSavingItem = createSelector(
    getComponentState,
    (state) => state.savingItem
);
export const getPushingToQb = createSelector(
    getComponentState,
    (state) => state.pushingToQb
);
export const getFile = createSelector(
    getComponentState,
    (state) => state.file
);
export const getType = createSelector(
    getComponentState,
    (state) => state.type
);
export const getProcessedData = createSelector(
    getComponentState,
    (state) => state.processedData
);
export const getInformation = createSelector(
    getComponentState,
    (state) => state.information
);
export const getFetchingInformation = createSelector(
    getComponentState,
    (state) => state.fetchingInformation
);
export const getSavingItems = createSelector(
    getComponentState,
    (state) => state.savingItems
);
export const getName = createSelector(
    getComponentState,
    (state) => state.name
);
export const getPostedDate = createSelector(
    getComponentState,
    (state) => state.postedDate
);
export const getRecordId = createSelector(
    getComponentState,
    (state) => state.recordId
);
export const getHistory = createSelector(
    getComponentState,
    (state) => state.history
);
export const getRecord = createSelector(
    getComponentState,
    (state) => state.record
);
export const getFetchingRecord = createSelector(
    getComponentState,
    (state) => state.fetchingRecord
);