export const SAVE_TRACKING = 'PQT_TRACKING/SAVE_TRACKING';
export const SAVE_TRACKING_SUCCESS = 'PQT_TRACKING/SAVE_TRACKING_SUCCESS';
export const SAVE_TRACKING_ERROR = 'PQT_TRACKING/SAVE_TRACKING_ERROR';
export const FETCH_INFORMATION = 'PQT_TRACKING/FETCH_INFORMATION';
export const FETCH_INFORMATION_SUCCESS = 'PQT_TRACKING/FETCH_INFORMATION_SUCCESS';
export const FETCH_INFORMATION_ERROR = 'PQT_TRACKING/FETCH_INFORMATION_ERROR';
export const FETCH_TRACKING = 'PQT_TRACKING/FETCH_TRACKING';
export const FETCH_TRACKING_SUCCESS = 'PQT_TRACKING/FETCH_TRACKING_SUCCESS';
export const FETCH_TRACKING_ERROR = 'PQT_TRACKING/FETCH_TRACKING_ERROR';
export const SAVE_TRACKING_STEP = 'PQT_TRACKING/SAVE_TRACKING_STEP';
export const SAVE_TRACKING_STEP_SUCCESS = 'PQT_TRACKING/SAVE_TRACKING_STEP_SUCCESS';
export const SAVE_TRACKING_STEP_ERROR = 'PQT_TRACKING/SAVE_TRACKING_STEP_ERROR';
export const DELETE_TRACKING_STEP = 'PQT_TRACKING/DELETE_TRACKING_STEP';
export const DELETE_TRACKING_STEP_SUCCESS = 'PQT_TRACKING/DELETE_TRACKING_STEP_SUCCESS';
export const DELETE_TRACKING_STEP_ERROR = 'PQT_TRACKING/SAVE_TRACKING_STEP_ERROR';
export const CLOSE_TRACKING_STEP = 'PQT_TRACKING/CLOSE_TRACKING_STEP';
export const CLOSE_TRACKING_STEP_SUCCESS = 'PQT_TRACKING/CLOSE_TRACKING_STEP_SUCCESS';
export const CLOSE_TRACKING_STEP_ERROR = 'PQT_TRACKING/CLOSE_TRACKING_STEP_ERROR';
export const START_TRACKING = 'PQT_TRACKING/START_TRACKING';
export const START_TRACKING_SUCCESS = 'PQT_TRACKING/START_TRACKING_SUCCESS';
export const START_TRACKING_ERROR = 'PQT_TRACKING/START_TRACKING_ERROR';
export const CREATE_PRELIMINARY = 'PQT_TRACKING/CREATE_PRELIMINARY';
export const CREATE_PRELIMINARY_SUCCESS = 'PQT_TRACKING/CREATE_PRELIMINARY_SUCCESS';
export const CREATE_PRELIMINARY_ERROR = 'PQT_TRACKING/CREATE_PRELIMINARY_ERROR';
export const GET_POSTAGE_ESCROW = 'PQT_TRACKING/GET_POSTAGE_ESCROW';
export const GET_POSTAGE_ESCROW_SUCCESS = 'PQT_TRACKING/GET_POSTAGE_ESCROW_SUCCESS';
export const GET_POSTAGE_ESCROW_ERROR = 'PQT_TRACKING/GET_POSTAGE_ESCROW_ERROR';
export const PAUSE_DEPARTMENT = 'PQT_TRACKING/PAUSE_DEPARTMENT';
export const PAUSE_DEPARTMENT_SUCCESS = 'PQT_TRACKING/PAUSE_DEPARTMENT_SUCCESS';
export const PAUSE_DEPARTMENT_ERROR = 'PQT_TRACKING/PAUSE_DEPARTMENT_ERROR';
export const UNPAUSE_DEPARTMENT = 'PQT_TRACKING/UNPAUSE_DEPARTMENT';
export const UNPAUSE_DEPARTMENT_SUCCESS = 'PQT_TRACKING/UNPAUSE_DEPARTMENT_SUCCESS';
export const UNPAUSE_DEPARTMENT_ERROR = 'PQT_TRACKING/UNPAUSE_DEPARTMENT_ERROR';
export const DOWNLOAD_PRESS_TICKET = 'PQT_TRACKING/DOWNLOAD_PRESS_TICKET';
export const DOWNLOAD_PRESS_TICKET_SUCCESS = 'PQT_TRACKING/DOWNLOAD_PRESS_TICKET_SUCCESS';
export const DOWNLOAD_PRESS_TICKET_ERROR = 'PQT_TRACKING/DOWNLOAD_PRESS_TICKET_ERROR';
export const SAVE_MAILING_NOTIFICATIONS_EMAILS = 'PQT_TRACKING/SAVE_MAILING_NOTIFICATIONS_EMAILS';
export const SAVE_MAILING_NOTIFICATIONS_EMAILS_SUCCESS = 'PQT_TRACKING/SAVE_MAILING_NOTIFICATIONS_EMAILS_SUCCESS';
export const SAVE_MAILING_NOTIFICATIONS_EMAILS_ERROR = 'PQT_TRACKING/SAVE_MAILING_NOTIFICATIONS_EMAILS_ERROR';
export const SAVE_SHIPPING_NOTIFICATIONS_EMAILS = 'PQT_TRACKING/SAVE_SHIPPING_NOTIFICATIONS_EMAILS';
export const SAVE_SHIPPING_NOTIFICATIONS_EMAILS_SUCCESS = 'PQT_TRACKING/SAVE_SHIPPING_NOTIFICATIONS_EMAILS_SUCCESS';
export const SAVE_SHIPPING_NOTIFICATIONS_EMAILS_ERROR = 'PQT_TRACKING/SAVE_SHIPPING_NOTIFICATIONS_EMAILS_ERROR';
export const SAVE_CLOSEOUT_NOTIFICATIONS_EMAILS = 'PQT_TRACKING/SAVE_CLOSEOUT_NOTIFICATIONS_EMAILS';
export const SAVE_CLOSEOUT_NOTIFICATIONS_EMAILS_SUCCESS = 'PQT_TRACKING/SAVE_CLOSEOUT_NOTIFICATIONS_EMAILS_SUCCESS';
export const SAVE_CLOSEOUT_NOTIFICATIONS_EMAILS_ERROR = 'PQT_TRACKING/SAVE_CLOSEOUT_NOTIFICATIONS_EMAILS_ERROR';
export const UPLOAD_FILES = 'PQT_TRACKING/UPLOAD_FILES';
export const UPLOAD_FILES_SUCCESS = 'PQT_TRACKING/UPLOAD_FILES_SUCCESS';
export const UPLOAD_FILES_ERROR = 'PQT_TRACKING/UPLOAD_FILES_ERROR';
export const DELETE_FILE = 'PQT_TRACKING/DELETE_FILE';
export const DELETE_FILE_SUCCESS = 'PQT_TRACKING/DELETE_FILE_SUCCESS';
export const DELETE_FILE_ERROR = 'PQT_TRACKING/DELETE_FILE_ERROR';
export const SET_ID = 'PQT_TRACKING/SET_ID';
export const SET_IS_EDITING = 'PQT_TRACKING/SET_IS_EDITING';
export const SET_FIELD = 'PQT_TRACKING/SET_FIELD';
export const SET_HISTORY = 'PQT_TRACKING/SET_HISTORY';
export const SET_STEP_FIELD = 'PQT_TRACKING/SET_STEP_FIELD';
export const ADD_STEP = 'PQT_TRACKING/ADD_STEP';
export const SET_STEP = 'PQT_TRACKING/SET_STEP';
export const RESET_STATE = 'PQT_TRACKING/RESET_STATE';
export const ADD_ASSIGNED_PEOPLE = 'PQT_TRACKING/ADD_ASSIGNED_PEOPLE';
export const REMOVE_ASSIGNED_PEOPLE = 'PQT_TRACKING/REMOVE_ASSIGNED_PEOPLE';
export const ADD_SUBDEPARTMENT = 'PQT_TRACKING/ADD_SUBDEPARTMENT';
export const REMOVE_SUBDEPARTMENT = 'PQT_TRACKING/REMOVE_SUBDEPARTMENT';
export const ADD_ASSIGNED_VENDOR = 'PQT_TRACKING/ADD_ASSIGNED_VENDOR';
export const REMOVE_ASSIGNED_VENDOR = 'PQT_TRACKING/REMOVE_ASSIGNED_VENDOR';
export const SET_ID_MODAL = 'PQT_TRACKING/SET_ID_MODAL';
export const ADD_FILE = 'PQT_TRACKING/ADD_FILE';
export const REMOVE_FILE = 'PQT_TRACKING/REMOVE_FILE';
export const CLEAR_FILES = 'PQT_TRACKING/CLEAR_FILES';