import React, { useState } from 'react';
import { Dialog } from './../../common/dialog';

const PauseModal = (props) => {
    const [comments, setComments] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const hideHandler = () => {
        setComments('');
        setErrorMessage('');
        props.setIdModal(null);
        props.hidePauseModal();
    };

    const pauseHandler = () => {
        if (comments === '') {
            return setErrorMessage('Enter the reason why you are pausing this department.');
        }

        props.pauseDepartment(comments);
    };

    const changeHandler = (event) => {
        setErrorMessage('');
        setComments(event.target.value);
    };

    let label = 'Pause';
    if (props.isPausing) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Cancel</button>,
        <button key="approve-button" className="btn btn-light btn-sm action-button" onClick={pauseHandler} disabled={props.isPausing}>{label}</button>
    ];
    return (
        <Dialog
            name="pause-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="pause-dialog"
        >
            <div className="pause-modal-container">
                <h1>Pause Department?</h1>
                <p className="notice">
                    Enter the reason why you are pausing this department and confirm the Pause. After pause the department 
                    the Due Date will be moved one extra day.
                </p>
                <h2>Comments:</h2>
                <div>
                    <textarea value={comments} onChange={changeHandler} placeholder="Comments" />
                </div>
                <div className="error-message">{errorMessage !== '' && errorMessage}</div>
            </div>
        </Dialog >
    );
};

export default PauseModal;
