import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from './Table';
import * as actions from './InformedVisibilityJobs.actions';
import * as selectors from './InformedVisibilityJobs.selectors';
import './InformedVisibilityJobs.scss';
import PqtLayout from './../PqtLayout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import * as loginSelectors from './../Login/Login.selectors';
import { Redirect } from 'react-router-dom';

const InformedVisibilityJobs = (props) => {
    /* State to props */
    const fetchingJobs = useSelector(selectors.getFetchingJobs);
    const jobs = useSelector(selectors.getJobs, stringifyEqualityCheck);
    const pagination = useSelector(selectors.getPagination, stringifyEqualityCheck);
    const sort = useSelector(selectors.getSort, stringifyEqualityCheck);
    const filterFields = useSelector(selectors.getFilterFields, stringifyEqualityCheck);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const fetchJobs = useCallback(() => dispatch(actions.fetchJobs()), [dispatch]);
    const setSort = useCallback((column, type) => dispatch(actions.setSort(column, type)), [dispatch]);
    const setActivePage = useCallback((name, value) => dispatch(actions.setActivePage(name, value)), [dispatch]);
    const changeFilter = useCallback((name, value, fetch) => dispatch(actions.changeFilter(name, value, fetch)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        fetchJobs();
        return () => {
            resetState();
        };
    }, [resetState, fetchJobs,]);

    if (user != null && parseInt(user.id) !== 61) {     // Hugh
        return <Redirect to="/dashboard" />;
    }

    return (
        <PqtLayout title="Informed Visibility Jobs" history={props.history}>
            <Helmet><title>Informed Visibility Jobs - Stagecoach</title></Helmet>
            <div className="informed-visibility-jobs-container">
                <Table
                    fetchingJobs={fetchingJobs}
                    jobs={jobs}
                    pagination={pagination}
                    sort={sort}
                    filterFields={filterFields}
                    setSort={setSort}
                    setActivePage={setActivePage}
                    changeFilter={changeFilter}
                />
            </div>
        </PqtLayout>
    );
};

export default InformedVisibilityJobs;
