import axios from 'axios';
import * as types from './CreditMemos.types';
import * as selectors from './CreditMemos.selectors';
import * as dialog from '../common/dialog';
import { validateAuthInResponse, showDataDeletedNotification } from './../../utils';
import fileDownload from 'js-file-download';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchCreditMemos = () => (dispatch, getState) => {
    const sort = selectors.getSort(getState());
    const pagination = selectors.getPagination(getState());
    const filerFields = selectors.getFilterFields(getState());

    let params = [];
    params.push('sort=' + (sort.type === 'asc' ? '' : '-') + sort.column);
    params.push('active-page=' + pagination.activePage);
    params.push('elements-per-page=' + pagination.elementsPerPage);

    if (filerFields.length > 0) {
        let filterElements = [];
        for (let i = 0; i < filerFields.length; i++) {
            filterElements.push(filerFields[i]['name'] + '=' + filerFields[i]['value']);
        }
        params.push('filter=' + filterElements.join(','))
    } else {
        params.push('filter=-')
    }

    dispatch({ type: types.FETCH_CREDIT_MEMOS, payload: null });
    axios.get(apiUrl + '/credit-memos' + (params.length === 0 ? '' : '?' + params.join('&')),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_CREDIT_MEMOS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_CREDIT_MEMOS_ERROR));
        });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null
    });
}

export const deleteCreditMemo = (id) => (dispatch) => {
    dispatch({ type: types.DELETE_CREDIT_MEMO, payload: null });
    axios.delete(apiUrl + '/credit-memos/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } }
    ).then(() => {
        dispatch({ type: types.DELETE_CREDIT_MEMO_SUCCESS, payload: null });
        showDataDeletedNotification();
        dispatch(fetchCreditMemos());
        dispatch(dialog.actions.hideDialog('delete-modal'));
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.DELETE_CREDIT_MEMO_ERROR));
    });
}

export const setSort = (column, type) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_SORT,
            payload: {
                column: column,
                type: type,
            }
        });
        resolve();
    }).then(() => {
        dispatch(fetchCreditMemos());
    });
}

export const setActivePage = (value) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_ACTIVE_PAGE,
            payload: value
        });
        resolve();
    }).then(() => {
        dispatch(fetchCreditMemos());
    });
}

export const mountComponent = () => (dispatch) => {
    dispatch(resetState());
    dispatch(fetchCreditMemos());
}

export const setIdForDelete = (id) => (dispatch) => {
    dispatch({
        type: types.SET_ID_FOR_DELETE,
        payload: id
    });
}

export const changeFilter = (name, value) => (dispatch) => {
    new Promise((resolve) => {
        dispatch({
            type: types.CHANGE_FILTER,
            payload: { name, value }
        });
        resolve();
    }).then(() => {
        dispatch(fetchCreditMemos());
    });
}

export const exportExcel = () => (dispatch, getState) => {
    dispatch({ type: types.EXPORT_EXCEL, payload: null });
    axios.post(apiUrl + '/credit-memo/export-excel', {},
        {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
            responseType: 'blob',
        })
        .then((response) => {
            dispatch({ type: types.EXPORT_EXCEL_SUCCESS, payload: null });
            fileDownload(response.data, 'Credit Memos.xlsx');
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.EXPORT_EXCEL_ERROR));
        });
}