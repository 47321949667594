import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Compensations from './Compensations';
import DeleteModal from './DeleteModal';
import FormModal from './FormModal';
import * as actions from './CommissionCompensations.actions';
import * as selectors from './CommissionCompensations.selectors';
import './CommissionCompensations.scss';
import PqtLayout from './../PqtLayout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import * as dialog from '../common/dialog';
import * as loginSelectors from './../Login/Login.selectors';
import { Redirect } from 'react-router-dom';

const CommissionCompensations = (props) => {
    /* State to props */
    const fetchingCompensations = useSelector(selectors.getFetchingCompensations);
    const compensations = useSelector(selectors.getCompensations, stringifyEqualityCheck);
    const pagination = useSelector(selectors.getPagination, stringifyEqualityCheck);
    const sort = useSelector(selectors.getSort, stringifyEqualityCheck);
    const idForDelete = useSelector(selectors.getIdForDelete, stringifyEqualityCheck);
    const isDeleting = useSelector(selectors.getIsDeleting, stringifyEqualityCheck);
    const filerFields = useSelector(selectors.getFilterFields, stringifyEqualityCheck);
    const fields = useSelector(selectors.getFields, stringifyEqualityCheck);
    const fetchingCompensation = useSelector(selectors.getFetchingCompensation, stringifyEqualityCheck);
    const savingCompensation = useSelector(selectors.getSavingCompensation);
    const persons = useSelector(selectors.getPersons, stringifyEqualityCheck);
    const fetchingPersons = useSelector(selectors.getFetchingPersons);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const deleteCompensation = useCallback((id, comments) => dispatch(actions.deleteCompensation(id, comments)), [dispatch]);
    const setSort = useCallback((column, type) => dispatch(actions.setSort(column, type)), [dispatch]);
    const setActivePage = useCallback((name, value) => dispatch(actions.setActivePage(name, value)), [dispatch]);
    const fetchCompensations = useCallback((options, isWsUpdate) => dispatch(actions.fetchCompensations(options, isWsUpdate)), [dispatch]);
    const setIdForDelete = useCallback((id) => dispatch(actions.setIdForDelete(id)), [dispatch]);
    const changeFilter = useCallback((name, value, fetch) => dispatch(actions.changeFilter(name, value, fetch)), [dispatch]);
    const setField = useCallback((field, value) => dispatch(actions.setField(field, value)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);
    const fetchCompensation = useCallback((id) => dispatch(actions.fetchCompensation(id)), [dispatch]);
    const clearForm = useCallback(() => dispatch(actions.clearForm()), [dispatch]);
    const saveCompensation = useCallback(() => dispatch(actions.saveCompensation()), [dispatch]);
    const fetchPersons = useCallback(() => dispatch(actions.fetchPersons()), [dispatch]);
    const showDeleteModal = useCallback(() => dispatch(dialog.actions.showDialog('delete-modal')), [dispatch]);
    const hideDeleteModal = useCallback(() => dispatch(dialog.actions.hideDialog('delete-modal')), [dispatch]);
    const showFormModal = useCallback(() => dispatch(dialog.actions.showDialog('compensation-form-modal')), [dispatch]);
    const hideFormModal = useCallback(() => dispatch(dialog.actions.hideDialog('compensation-form-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        dispatch(actions.mountComponent());
        fetchPersons();
        return () => {
            resetState();
        };
    }, [dispatch, resetState, fetchPersons]);

    if (user != null && ![61].includes(parseInt(user.id))) {     // Hugh
        return <Redirect to="/dashboard" />;
    }

    return (
        <PqtLayout title="Commission Compensations" history={props.history}>
            <Helmet><title>Commission Compensations - Stagecoach</title></Helmet>
            <div className="commission-compensations-container">
                <Compensations
                    compensations={compensations}
                    pagination={pagination}
                    fetchingCompensations={fetchingCompensations}
                    history={props.history}
                    sort={sort}
                    user={user}
                    filerFields={filerFields}
                    setSort={setSort}
                    setActivePage={setActivePage}
                    showDeleteModal={showDeleteModal}
                    setIdForDelete={setIdForDelete}
                    changeFilter={changeFilter}
                    fetchCompensations={fetchCompensations}
                    showFormModal={showFormModal}
                    fetchCompensation={fetchCompensation}
                />
                <DeleteModal
                    idForDelete={idForDelete}
                    isDeleting={isDeleting}
                    deleteCompensation={deleteCompensation}
                    hideDeleteModal={hideDeleteModal}
                    setIdForDelete={setIdForDelete}
                />
                <FormModal
                    fields={fields}
                    fetchingCompensation={fetchingCompensation}
                    savingCompensation={savingCompensation}
                    persons={persons}
                    fetchingPersons={fetchingPersons}
                    hideFormModal={hideFormModal}
                    setField={setField}
                    clearForm={clearForm}
                    saveCompensation={saveCompensation}
                />
            </div>
        </PqtLayout>
    );
};

export default CommissionCompensations;
