import React from 'react';
//import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';

const QuickSearch = React.memo((props) => {
    let csrOptions = [<option value="" key="csr-option-empty">All</option>];
    for (let i = 0; i < props.csrsInformation.length; i++) {
        let name = props.csrsInformation[i].name;
        const nameParts = name.split(' ');
        if (nameParts.length > 0) {
            name = nameParts[0];
        }

        csrOptions.push(<option value={props.csrsInformation[i].id} key={'csr-option-' + i}>{name}</option>);
    }

    let saleRepsOptions = [<option value="" key="sales-rep-empty">All</option>];
    for (let i = 0; i < props.salesRepsInformation.length; i++) {
        let name = props.salesRepsInformation[i].name;
        const nameParts = name.split(' ');
        if (nameParts.length > 0) {
            name = nameParts[0];
        }

        saleRepsOptions.push(<option value={props.salesRepsInformation[i].id} key={'csr-option-' + i}>{name}</option>);
    }

    const setFieldHandler = (name, event) => {
        props.setSearchField(name, event.target.value);
    };

    const renderTextInput = name => (
        <input type="text" name={name} value={props.searchFields[name]} onChange={setFieldHandler.bind(this, name)} />
    );

    const renderSelect = (name, options) => (
        <select name={name} value={props.searchFields[name]} onChange={setFieldHandler.bind(this, name)}>
            {options}
        </select>
    );

    const searchHandler = () => {
        props.fetchData({
            fetchLateJobs: 1,
            fetchMyJobs: 1,
        });
    };

    const setDateFieldHandler = (name, event) => {
        let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');
        props.setSearchField(name, value);
    };

    const renderDateInput = name => (
        <DayPickerInput
            formatDate={formatDate}
            parseDate={parseDate}
            placeholder=""
            className="date-picker-field"
            onDayChange={setDateFieldHandler.bind(this, name)}
            value={props.searchFields[name] === '' ? '' : moment(props.searchFields[name]).format('MM/DD/YYYY')}
        />
    );

    let resetClassName = 'reset disabled';
    if (props.searchFields.jobNumber !== '' || props.searchFields.customer !== '' || props.searchFields.date !== '' || props.searchFields.csr !== '' || props.searchFields.salesRep !== '' || props.searchFields.description !== '') {
        resetClassName = 'reset';
    }

    return (
        <div className="quick-search-container">
            <div className="title">
                Quick Search By
                <button className={resetClassName} onClick={props.resetSearchFields}><i className="fas fa-filter" /> Reset</button>
            </div>
            <div className="search-fields">
                <div className="search-field-container">
                    <div className="label">Stagecoach #:</div>
                    <div className="input">
                        {renderTextInput('jobNumber')}
                        <i className="fas fa-search" onClick={searchHandler}></i>
                    </div>
                </div>
                <div className="search-field-container">
                    <div className="label">Customer:</div>
                    <div className="input">
                        {renderTextInput('customer')}
                        <i className="fas fa-search" onClick={searchHandler}></i>
                    </div>
                </div>
                <div className="search-field-container">
                    <div className="label">Date Enter:</div>
                    <div className="input">
                        {renderDateInput('date')}
                        <i className="fas fa-search" onClick={searchHandler}></i>
                    </div>
                </div>
                <div className="search-field-container">
                    <div className="label">CSR:</div>
                    <div className="input">
                        {renderSelect('csr', csrOptions)}
                        <i className="fas fa-search" onClick={searchHandler}></i>
                    </div>
                </div>
                <div className="search-field-container">
                    <div className="label">Sales Rep:</div>
                    <div className="input">
                        {renderSelect('salesRep', saleRepsOptions)}
                        <i className="fas fa-search" onClick={searchHandler}></i>
                    </div>
                </div>
                <div className="search-field-container">
                    <div className="label">Description:</div>
                    <div className="input">
                        {renderTextInput('description')}
                        <i className="fas fa-search" onClick={searchHandler}></i>
                    </div>
                </div>
            </div>
        </div>
    );
});

QuickSearch.propTypes = {

};

export default QuickSearch;
