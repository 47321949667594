export const FETCH_DATA = 'PQT_DASHBOARD/FETCH_DATA';
export const FETCH_DATA_SUCCESS = 'PQT_DASHBOARD/FETCH_DATA_SUCCESS';
export const FETCH_DATA_ERROR = 'PQT_DASHBOARD/FETCH_DATA_ERROR';
export const FETCH_INFORMATION = 'PQT_DASHBOARD/FETCH_INFORMATION';
export const FETCH_INFORMATION_SUCCESS = 'PQT_DASHBOARD/FETCH_INFORMATION_SUCCESS';
export const FETCH_INFORMATION_ERROR = 'PQT_DASHBOARD/FETCH_INFORMATION_ERROR';
export const FETCH_QUICK_GLANCE = 'PQT_DASHBOARD/FETCH_QUICK_GLANCE';
export const FETCH_QUICK_GLANCE_SUCCESS = 'PQT_DASHBOARD/FETCH_QUICK_GLANCE_SUCCESS';
export const FETCH_QUICK_GLANCE_ERROR = 'PQT_DASHBOARD/FETCH_QUICK_GLANCE_ERROR';
export const FETCH_LATE_JOBS = 'PQT_DASHBOARD/FETCH_LATE_JOBS';
export const FETCH_LATE_JOBS_SUCCESS = 'PQT_DASHBOARD/FETCH_LATE_JOBS_SUCCESS';
export const FETCH_LATE_JOBS_ERROR = 'PQT_DASHBOARD/FETCH_LATE_JOBS_ERROR';
export const FETCH_MY_JOBS = 'PQT_DASHBOARD/FETCH_MY_JOBS';
export const FETCH_MY_JOBS_SUCCESS = 'PQT_DASHBOARD/FETCH_MY_JOBS_SUCCESS';
export const FETCH_MY_JOBS_ERROR = 'PQT_DASHBOARD/FETCH_MY_JOBS_ERROR';
export const DELETE_TRACKING = 'PQT_DASHBOARD/DELETE_TRACKING';
export const DELETE_TRACKING_SUCCESS = 'PQT_DASHBOARD/DELETE_TRACKING_SUCCESS';
export const DELETE_TRACKING_ERROR = 'PQT_DASHBOARD/DELETE_TRACKING_ERROR';
export const SET_HISTORY = 'PQT_DASHBOARD/SET_HISTORY';
export const SET_SEARCH_FIELD = 'PQT_DASHBOARD/SET_SEARCH_FIELD';
export const RESET_STATE = 'PQT_DASHBOARD/RESET_STATE';
export const SET_FILTER = 'PQT_DASHBOARD/SET_FILTER';
export const SET_SHOW_FINISHED = 'PQT_DASHBOARD/SET_SHOW_FINISHED';
export const SET_SORT = 'PQT_DASHBOARD/SET_SORT';
export const SET_QUICK_GLANCE_FILTER = 'PQT_DASHBOARD/SET_QUICK_GLANCE_FILTER';
export const SET_ACTIVE_PAGE = 'PQT_DASHBOARD/SET_ACTIVE_PAGE';
export const RESET_SEARCH_FIELDS = 'PQT_DASHBOARD/RESET_SEARCH_FIELDS';
export const SET_ID_FOR_DELETE = 'PQT_DASHBOARD/SET_ID_FOR_DELETE';