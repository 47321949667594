import { createSelector } from 'reselect';

export const getComponentState = state => state.markupCalculator;

export const getFields = createSelector(
    getComponentState,
    (state) => state.fields
);
export const getLastModifiedFields = createSelector(
    getComponentState,
    (state) => state.lastModifiedFields
);