import React from 'react';
import { Dialog } from './../../common/dialog';
import moment from 'moment-timezone';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';

const ReportModal = React.memo((props) => {
    const hideHandler = () => {
        props.hideReportModal();
    };

    const handleExportReport = () => {
        if (parseInt(props.reportType) === 1) {
            props.exportReport();
        } else {
            props.exportListPurchase();
        }
    };

    const handleSetStartDate = (event) => {
        let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');
        props.setReportStartDate(value);
    };

    const handleSetEndDate = (event) => {
        let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');
        props.setReportEndDate(value);
    };

    let title = 'Export Report';
    if (parseInt(props.reportType) === 2) {
        title = 'Export List Purchase';
    }

    let actions = [<button key="cancel-button" className="btn btn-lights btn-sm action-button-cancel" onClick={hideHandler}>Cancel</button>];
    if (parseInt(props.reportType) === 1) {
        let label = 'Export';
        if (props.isExportingReport) {
            label = <i className="fas fa-circle-notch fa-spin" />;
        }

        actions.push(<button key="report-button" className="btn btn-light btn-sm action-button" onClick={handleExportReport} disabled={props.isExportingReport}>{label}</button>);
    } else {
        let label = 'Export';
        if (props.isExportingListPurchase) {
            label = <i className="fas fa-circle-notch fa-spin" />;
        }

        actions.push(<button key="report-button" className="btn btn-light btn-sm action-button" onClick={handleExportReport} disabled={props.isExportingListPurchase}>{label}</button>);
    }

    return (
        <Dialog
            name="report-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="report-dialog"
        >
            <div className="report-container">
                <h1>{title}</h1>
                <p>Select the date range for the report (leave them blank to export ALL):</p>
                <div className="filters-container">
                    <div className="field">
                        <b>Start Date:</b>
                        <DayPickerInput
                            formatDate={formatDate}
                            parseDate={parseDate}
                            component={(props) => {
                                let value = props.value;
                                if (!props.value) {
                                    value = '';
                                }
                                return <input {...props} value={value} />;
                            }}
                            placeholder=""
                            className="date-picker-field"
                            onDayChange={handleSetStartDate}
                            value={props.reportStartDate == null || props.reportStartDate === '0000-00-00' ? null : moment(props.reportStartDate).format('MM/DD/YYYY')}
                        />
                    </div>
                    <div className="field">
                        <b>End Date:</b>
                        <DayPickerInput
                            formatDate={formatDate}
                            parseDate={parseDate}
                            component={(props) => {
                                let value = props.value;
                                if (!props.value) {
                                    value = '';
                                }
                                return <input {...props} value={value} />;
                            }}
                            placeholder=""
                            className="date-picker-field"
                            onDayChange={handleSetEndDate}
                            value={props.reportEndDate == null || props.reportEndDate === '0000-00-00' ? null : moment(props.reportEndDate).format('MM/DD/YYYY')}
                        />
                    </div>
                </div>
            </div>
        </Dialog >
    );
});

export default ReportModal;
