export const FETCH_ELEMENTS = 'PQN_PRESSES/FETCH_ELEMENTS';
export const FETCH_ELEMENTS_SUCCESS = 'PQN_PRESSES/FETCH_ELEMENTS_SUCCESS';
export const FETCH_ELEMENTS_ERROR = 'PQN_PRESSES/FETCH_ELEMENTS_ERROR';
export const FETCH_ELEMENT = 'PQN_PRESSES/FETCH_ELEMENT';
export const FETCH_ELEMENT_SUCCESS = 'PQN_PRESSES/FETCH_ELEMENT_SUCCESS';
export const FETCH_ELEMENT_ERROR = 'PQN_PRESSES/FETCH_ELEMENT_ERROR';
export const SAVE_ELEMENT = 'PQN_PRESSES/SAVE_ELEMENT';
export const SAVE_ELEMENT_SUCCESS = 'PQN_PRESSES/SAVE_ELEMENT_SUCCESS';
export const SAVE_ELEMENT_ERROR = 'PQN_PRESSES/SAVE_ELEMENT_ERROR';
export const DELETE_ELEMENT = 'PQN_PRESSES/DELETE_ELEMENT';
export const DELETE_ELEMENT_SUCCESS = 'PQN_PRESSES/DELETE_ELEMENT_SUCCESS';
export const DELETE_ELEMENT_ERROR = 'PQN_PRESSES/DELETE_ELEMENT_ERROR';
export const RESET_STATE = 'PQN_PRESSES/RESET_STATE';
export const SET_ID = 'PQN_PRESSES/SET_ID';
export const SET_SORT = 'PQN_PRESSES/SET_SORT';
export const SET_ACTIVE_PAGE = 'PQN_PRESSES/SET_ACTIVE_PAGE';
export const SET_ID_FOR_DELETE = 'PQN_PRESSES/SET_ID_FOR_DELETE';
export const CHANGE_FILTER = 'PQN_PRESSES/CHANGE_FILTER';
export const SET_FIELD = 'PQN_PRESSES/SET_FIELD';
export const RESET_FIELDS = 'PQN_PRESSES/RESET_FIELDS';