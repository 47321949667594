import React from 'react';
import { Dialog } from './../../common/dialog';

const PushModal = React.memo((props) => {
    const hideHandler = () => {
        props.hidePushModal();
    };

    const pushHandler = () => {
        props.pushToQb();
    };

    let label = 'Push';
    if (props.pushingToQb) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Cancel</button>,
        <button key="delete-button" className="btn btn-light btn-sm action-button" onClick={pushHandler} disabled={props.pushingToQb}>{label}</button>
    ];
    return (
        <Dialog
            name="push-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="push-dialog"
        >
            <div className="push-container">
                <h1>Confirm Push to QB</h1>
                <p>Confirm that you want to Push the items in this record to QB.</p>
            </div>
        </Dialog >
    );
});

export default PushModal;
