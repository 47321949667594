import Immutable from 'immutable';
import * as types from './PqtTracking.types';

const initialState = {
    id: null,
    isEditing: false,
    tracking: {
        status: 1,
        is_mail_project: 0,
        steps: [],
        auditHistory: [],
    },
    isFetchingTracking: false,
    saving: {
        tracking: false,
        start: false,
        step: false,
        stepDelete: false,
        stepClose: false,
        stepIndex: null,
        mailingEmails: false,
        shippingEmails: false,
        closeoutEmails: false,
    },
    information: {
        customers: [],
        salesReps: [],
        csrs: [],
        departments: [],
        employees: [],
        vendors: [],
        isFetching: false,
    },
    history: null,
    stepsUnsaved: [],
    creatingPreliminary: false,
    postageEscrow: 0,
    isLoadingPostageEscrow: false,
    idModal: null,
    isPausing: false,
    isUnpausing: false,
    downloadingPressTicket: false,
    files: [],
    uploadingFiles: false,
    deletingFile: false,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);

    switch (action.type) {
        case types.SET_ID:
            return state.setIn(['id'], action.payload).toJS();

        case types.SET_IS_EDITING:
            return state.setIn(['isEditing'], action.payload).toJS();

        case types.SET_FIELD:
            return state.setIn(['tracking', action.payload.name], action.payload.value).toJS();

        case types.FETCH_INFORMATION:
            return state.setIn(['information', 'isFetching'], true).toJS();

        case types.FETCH_INFORMATION_SUCCESS:
            return state.setIn(['information', 'isFetching'], false)
                .setIn(['information', 'customers'], action.payload.customers)
                .setIn(['information', 'salesReps'], action.payload.salesReps)
                .setIn(['information', 'csrs'], action.payload.csrs)
                .setIn(['information', 'departments'], action.payload.departments)
                .setIn(['information', 'employees'], action.payload.employees)
                .setIn(['information', 'vendors'], action.payload.vendors)
                .toJS();

        case types.FETCH_INFORMATION_ERROR:
            return state.setIn(['information', 'isFetching'], false).toJS();

        case types.SAVE_TRACKING:
            return state.setIn(['saving', 'tracking'], true).toJS();

        case types.SAVE_TRACKING_SUCCESS:
            return state.setIn(['saving', 'tracking'], false).toJS();

        case types.SAVE_TRACKING_ERROR:
            return state.setIn(['saving', 'tracking'], false).toJS();

        case types.SAVE_MAILING_NOTIFICATIONS_EMAILS:
            return state.setIn(['saving', 'mailingEmails'], true).toJS();

        case types.SAVE_MAILING_NOTIFICATIONS_EMAILS_SUCCESS:
            return state.setIn(['saving', 'mailingEmails'], false).toJS();

        case types.SAVE_MAILING_NOTIFICATIONS_EMAILS_ERROR:
            return state.setIn(['saving', 'mailingEmails'], false).toJS();

        case types.SAVE_SHIPPING_NOTIFICATIONS_EMAILS:
            return state.setIn(['saving', 'shippingEmails'], true).toJS();

        case types.SAVE_SHIPPING_NOTIFICATIONS_EMAILS_SUCCESS:
            return state.setIn(['saving', 'shippingEmails'], false).toJS();

        case types.SAVE_SHIPPING_NOTIFICATIONS_EMAILS_ERROR:
            return state.setIn(['saving', 'shippingEmails'], false).toJS();

        case types.SAVE_CLOSEOUT_NOTIFICATIONS_EMAILS:
            return state.setIn(['saving', 'closeoutEmails'], true).toJS();

        case types.SAVE_CLOSEOUT_NOTIFICATIONS_EMAILS_SUCCESS:
            return state.setIn(['saving', 'closeoutEmails'], false).toJS();

        case types.SAVE_CLOSEOUT_NOTIFICATIONS_EMAILS_ERROR:
            return state.setIn(['saving', 'closeoutEmails'], false).toJS();

        case types.UPLOAD_FILES:
            return state.setIn(['uploadingFiles'], true).toJS();

        case types.UPLOAD_FILES_SUCCESS:
            return state.setIn(['uploadingFiles'], false).toJS();

        case types.UPLOAD_FILES_ERROR:
            return state.setIn(['uploadingFiles'], false).toJS();

        case types.DELETE_FILE:
            return state.setIn(['deletingFile'], true).toJS();

        case types.DELETE_FILE_SUCCESS:
            return state.setIn(['deletingFile'], false).toJS();

        case types.DELETE_FILE_ERROR:
            return state.setIn(['deletingFile'], false).toJS();

        case types.PAUSE_DEPARTMENT:
            return state.setIn(['isPausing'], true).toJS();

        case types.PAUSE_DEPARTMENT_SUCCESS:
            return state.setIn(['isPausing'], false).toJS();

        case types.PAUSE_DEPARTMENT_ERROR:
            return state.setIn(['isPausing'], false).toJS();

        case types.UNPAUSE_DEPARTMENT:
            return state.setIn(['isUnpausing'], true).toJS();

        case types.UNPAUSE_DEPARTMENT_SUCCESS:
            return state.setIn(['isUnpausing'], false).toJS();

        case types.UNPAUSE_DEPARTMENT_ERROR:
            return state.setIn(['isUnpausing'], false).toJS();

        case types.SET_HISTORY:
            return state.setIn(['history'], action.payload).toJS();

        case types.FETCH_TRACKING:
            return state.setIn(['isFetchingTracking'], true).toJS();

        case types.FETCH_TRACKING_SUCCESS:
            return state.setIn(['isFetchingTracking'], false)
                .setIn(['tracking'], action.payload)
                .setIn(['files'], action.payload.files)
                .toJS();

        case types.FETCH_TRACKING_ERROR:
            return state.setIn(['isFetchingTracking'], false).toJS();

        case types.SAVE_TRACKING_STEP:
            return state.setIn(['saving', 'step'], true)
                .setIn(['saving', 'stepIndex'], action.payload)
                .toJS();

        case types.SAVE_TRACKING_STEP_SUCCESS:
            return state.setIn(['saving', 'step'], false)
                .setIn(['saving', 'stepIndex'], null)
                .setIn(['stepsUnsaved'], [])
                .toJS();

        case types.SAVE_TRACKING_STEP_ERROR:
            return state.setIn(['saving', 'step'], false)
                .setIn(['saving', 'stepIndex'], null)
                .toJS();

        case types.DOWNLOAD_PRESS_TICKET:
            return state.setIn(['downloadingPressTicket'], true).toJS();

        case types.DOWNLOAD_PRESS_TICKET_SUCCESS:
            return state.setIn(['downloadingPressTicket'], false).toJS();

        case types.DOWNLOAD_PRESS_TICKET_ERROR:
            return state.setIn(['downloadingPressTicket'], false).toJS();

        case types.SET_STEP_FIELD:
            return state.updateIn(['tracking', 'steps'], steps => {
                const step = steps.get(action.payload.index);
                if (action.payload.name === 'department_id') {
                    if (action.payload.value.includes('-')) {
                        const values = action.payload.value.split('-');
                        return steps.set(action.payload.index, step.setIn(['department_id'], values[0]).setIn(['subdepartment_id'], values[1]));
                    } else {
                        return steps.set(action.payload.index, step.setIn(['department_id'], action.payload.value).setIn(['subdepartment_id'], null));
                    }
                } else {
                    return steps.set(action.payload.index, step.setIn([action.payload.name], action.payload.value));
                }
            }).updateIn(['stepsUnsaved'], stepsUnsaved => {
                if (action.payload.name !== 'employee_id' && stepsUnsaved.indexOf(action.payload.index) === -1) {
                    return stepsUnsaved.push(action.payload.index);
                }
                return stepsUnsaved;
            }).toJS();

        case types.ADD_STEP:
            return state.updateIn(['tracking', 'steps'], steps => {
                return steps.push({
                    pqt_tracking_id: action.payload,
                    department_id: '',
                    subdepartment_id: '',
                    step_order: '',
                    notes: '',
                    status: 1,
                    employee_id: '',
                    out_on_proof: 0,
                    assigned_employees_ids: [],
                    assigned_subdepartments_ids: [],
                    subdepartments_finished: [],
                    vendor_id: '',
                    po_number: '',
                    vendor_quote: '',
                    vendor_price: '',
                    assigned_vendors_ids: [],
                    postage_type: '',
                });
            }).toJS();

        case types.SET_STEP:
            return state.updateIn(['tracking', 'steps'], steps => {
                return steps.set(action.payload.index, action.payload.step);
            }).toJS();

        case types.DELETE_TRACKING_STEP:
            return state.setIn(['saving', 'stepDelete'], true)
                .setIn(['saving', 'stepIndex'], action.payload)
                .toJS();

        case types.DELETE_TRACKING_STEP_SUCCESS:
            return state.setIn(['saving', 'stepDelete'], false)
                .setIn(['saving', 'stepIndex'], null)
                .updateIn(['tracking', 'steps'], steps => steps.delete(action.payload))
                .toJS();

        case types.DELETE_TRACKING_STEP_ERROR:
            return state.setIn(['saving', 'stepDelete'], false)
                .setIn(['saving', 'stepIndex'], null)
                .toJS();

        case types.RESET_STATE:
            return initialState;

        case types.ADD_ASSIGNED_PEOPLE:
            return state.updateIn(['tracking', 'steps'], steps => {
                const step = steps.get(action.payload.index);
                const newStep = step.updateIn(['assigned_employees_ids'], ids => ids.push(action.payload.id));
                return steps.set(action.payload.index, newStep.setIn(['employee_id'], ''));
            }).updateIn(['stepsUnsaved'], stepsUnsaved => {
                if (stepsUnsaved.indexOf(action.payload.index) === -1) {
                    return stepsUnsaved.push(action.payload.index);
                }
                return stepsUnsaved;
            }).toJS();

        case types.REMOVE_ASSIGNED_PEOPLE:
            return state.updateIn(['tracking', 'steps'], steps => {
                const step = steps.get(action.payload.stepIndex);
                const newStep = step.updateIn(['assigned_employees_ids'], ids => {
                    return ids.delete(action.payload.idIndex);
                });
                return steps.set(action.payload.stepIndex, newStep);
            }).updateIn(['stepsUnsaved'], stepsUnsaved => {
                if (stepsUnsaved.indexOf(action.payload.stepIndex) === -1) {
                    return stepsUnsaved.push(action.payload.stepIndex);
                }
                return stepsUnsaved;
            }).toJS();

        case types.ADD_SUBDEPARTMENT:
            return state.updateIn(['tracking', 'steps'], steps => {
                const step = steps.get(action.payload.index);
                const newStep = step.updateIn(['assigned_subdepartments_ids'], ids => ids.push(action.payload.id));
                return steps.set(action.payload.index, newStep.setIn(['employee_id'], '').setIn(['subdepartment_id'], ''));
            }).updateIn(['stepsUnsaved'], stepsUnsaved => {
                if (stepsUnsaved.indexOf(action.payload.index) === -1) {
                    return stepsUnsaved.push(action.payload.index);
                }
                return stepsUnsaved;
            }).toJS();

        case types.REMOVE_SUBDEPARTMENT:
            return state.updateIn(['tracking', 'steps'], steps => {
                const step = steps.get(action.payload.stepIndex);
                const newStep = step.updateIn(['assigned_subdepartments_ids'], ids => {
                    return ids.delete(action.payload.idIndex);
                });
                return steps.set(action.payload.stepIndex, newStep);
            }).updateIn(['stepsUnsaved'], stepsUnsaved => {
                if (stepsUnsaved.indexOf(action.payload.stepIndex) === -1) {
                    return stepsUnsaved.push(action.payload.stepIndex);
                }
                return stepsUnsaved;
            }).toJS();

        case types.CLOSE_TRACKING_STEP:
            return state.setIn(['saving', 'stepClose'], true)
                .setIn(['saving', 'stepIndex'], action.payload)
                .toJS();

        case types.CLOSE_TRACKING_STEP_SUCCESS:
            return state.setIn(['saving', 'stepClose'], false)
                .setIn(['saving', 'stepIndex'], null)
                .updateIn(['tracking', 'steps'], steps => steps.set(action.payload.index, action.payload.element))
                .toJS();

        case types.CLOSE_TRACKING_STEP_ERROR:
            return state.setIn(['saving', 'stepClose'], false)
                .setIn(['saving', 'stepIndex'], null)
                .toJS();

        case types.START_TRACKING:
            return state.setIn(['saving', 'start'], true)
                .toJS();

        case types.START_TRACKING_SUCCESS:
            return state.setIn(['saving', 'start'], false)
                .setIn(['tracking'], action.payload)
                .toJS();

        case types.START_TRACKING_ERROR:
            return state.setIn(['saving', 'start'], false)
                .toJS();

        case types.ADD_ASSIGNED_VENDOR:
            return state.updateIn(['tracking', 'steps'], steps => {
                const step = steps.get(action.payload.index);
                const newStep = step.updateIn(['assigned_vendors_ids'], ids => ids.push(action.payload.id));
                return steps.set(action.payload.index, newStep.setIn(['vendor_id'], ''));
            }).updateIn(['stepsUnsaved'], stepsUnsaved => {
                if (stepsUnsaved.indexOf(action.payload.index) === -1) {
                    return stepsUnsaved.push(action.payload.index);
                }
                return stepsUnsaved;
            }).toJS();

        case types.REMOVE_ASSIGNED_VENDOR:
            return state.updateIn(['tracking', 'steps'], steps => {
                const step = steps.get(action.payload.stepIndex);
                const newStep = step.updateIn(['assigned_vendors_ids'], ids => {
                    return ids.delete(action.payload.idIndex);
                });
                return steps.set(action.payload.stepIndex, newStep);
            }).updateIn(['stepsUnsaved'], stepsUnsaved => {
                if (stepsUnsaved.indexOf(action.payload.stepIndex) === -1) {
                    return stepsUnsaved.push(action.payload.stepIndex);
                }
                return stepsUnsaved;
            }).toJS();

        case types.CREATE_PRELIMINARY:
            return state.setIn(['creatingPreliminary'], true).toJS();

        case types.CREATE_PRELIMINARY_SUCCESS:
            return state.setIn(['creatingPreliminary'], false).toJS();

        case types.CREATE_PRELIMINARY_ERROR:
            return state.setIn(['creatingPreliminary'], false).toJS();

        case types.GET_POSTAGE_ESCROW:
            return state.setIn(['isLoadingPostageEscrow'], true).toJS();

        case types.GET_POSTAGE_ESCROW_SUCCESS:
            return state.setIn(['isLoadingPostageEscrow'], false)
                .setIn(['postageEscrow'], action.payload)
                .toJS();

        case types.GET_POSTAGE_ESCROW_ERROR:
            return state.setIn(['isLoadingPostageEscrow'], false).toJS();

        case types.SET_ID_MODAL:
            return state.setIn(['idModal'], action.payload).toJS();

        case types.ADD_FILE:
            return state.updateIn(['files'], (files) => {
                return files.push(action.payload);
            }).toJS();

        case types.REMOVE_FILE:
            return state.updateIn(['files'], (files) => {
                return files.delete(action.payload);
            }).toJS();

        case types.CLEAR_FILES:
            return state.updateIn(['files'], (files) => {
                return files.clear();
            }).toJS();

        default:
            return state.toJS();
    }
}