import axios from 'axios';
import * as types from './PqtDepartmentView.types';
import * as selectors from './PqtDepartmentView.selectors';
import * as loginSelectors from './../Login/Login.selectors';
import * as dialog from '../common/dialog';
import { validateAuthInResponse, validateMultiAuthInResponse, showDataDeletedNotification, sendWsMessage } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchData = (options = {}, isWsUpdate = false) => (dispatch, getState) => {
    let departmentId = selectors.getId(getState());
    const searchFields = selectors.getSearchFields(getState());
    const sort = selectors.getSort(getState());
    const pagination = selectors.getPagination(getState());
    const showJobs = selectors.getShowJobs(getState());

    if (options.departmentId) {
        departmentId = options.departmentId;
    }

    let params = ['showJobs=' + showJobs];
    if (options.fetchInformation) {
        dispatch({ type: types.FETCH_INFORMATION, payload: null });
        params.push('fetch-information=1');
    }
    if (options.fetchJobs) {
        dispatch({ type: types.FETCH_JOBS, payload: isWsUpdate });
        params.push('fetch-jobs=1');
        params.push('only-active=1');
        params.push('id=' + departmentId);
        if (searchFields.jobNumber !== '') {
            params.push('jobNumber=' + searchFields.jobNumber);
        }
        if (searchFields.date !== '') {
            params.push('date=' + searchFields.date);
        }
        if (searchFields.csr !== '') {
            params.push('csr=' + searchFields.csr);
        }
        if (searchFields.salesRep !== '') {
            params.push('salesRep=' + searchFields.salesRep);
        }
        params.push('sort=' + (sort.type === 'asc' ? '' : '-') + sort.column);
        params.push('active-page=' + pagination.jobs.activePage);
        params.push('elements-per-page=' + pagination.jobs.elementsPerPage);
    }
    if (options.fetchDepartment) {
        dispatch({ type: types.FETCH_DEPARTMENT, payload: null });
        params.push('fetch-department=1');
        params.push('department-id=' + departmentId);
    }

    dispatch({ type: types.FETCH_DATA, payload: null });
    axios.get(apiUrl + '/pqt-tracking/department-data' + (params.length === 0 ? '' : '?' + params.join('&')),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_DATA_SUCCESS, payload: null });
            if (options.fetchInformation) {
                dispatch({ type: types.FETCH_INFORMATION_SUCCESS, payload: response.data.information });
            }
            if (options.fetchJobs) {
                dispatch({ type: types.FETCH_JOBS_SUCCESS, payload: response.data.jobs });
            }
            if (options.fetchDepartment) {
                dispatch({ type: types.FETCH_DEPARTMENT_SUCCESS, payload: response.data.department });
            }
        })
        .catch((error) => {
            let errorTypes = [types.FETCH_DATA_ERROR];
            if (options.fetchInformation) {
                errorTypes.push(types.FETCH_INFORMATION_ERROR);
            }
            if (options.fetchJobs) {
                errorTypes.push(types.FETCH_JOBS_ERROR);
            }
            if (options.fetchDepartment) {
                errorTypes.push(types.FETCH_DEPARTMENT_ERROR);
            }
            dispatch(validateMultiAuthInResponse(error, errorTypes));
        });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null
    });
}

export const deleteTracking = (id, comments) => (dispatch) => {
    dispatch({ type: types.DELETE_TRACKING, payload: null });
    axios.delete(apiUrl + '/pqt-trackings/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }, data: { comments } }
    ).then(() => {
        dispatch({ type: types.DELETE_TRACKING_SUCCESS, payload: null });
        showDataDeletedNotification();
        dispatch(fetchData({
            fetchJobs: 1,
        }));
        dispatch(sendWsMessage({ id, type: 'track_deleted' }));
        dispatch(dialog.actions.hideDialog('delete-modal'));
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.DELETE_TRACKING_ERROR));
    });
}

export const setId = (id, showJobs) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_ID,
            payload: { id: parseInt(id), showJobs: parseInt(showJobs) }
        });
        resolve();
    }).then(() => {
        dispatch(fetchData({
            fetchJobs: 1,
        }));
    });
}

export const setSearchField = (name, value) => (dispatch) => {
    dispatch({
        type: types.SET_SEARCH_FIELD,
        payload: { name, value }
    });
}

export const setSort = (column, type) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_SORT,
            payload: {
                column: column,
                type: type,
            }
        });
        resolve();
    }).then(() => {
        dispatch(fetchData({
            fetchJobs: 1,
        }));
    });
}

export const setActivePage = (name, value) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_ACTIVE_PAGE,
            payload: { name, value }
        });
        resolve();
    }).then(() => {
        if (name === 'jobs') {
            dispatch(fetchData({
                fetchJobs: 1,
            }));
        }
    });
}

export const setShowJobs = (value) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_SHOW_JOBS,
            payload: value
        });
        resolve();
    }).then(() => {
        dispatch(fetchData({ fetchJobs: 1 }));
    });
}

export const mountComponent = (history, id, showJobs) => (dispatch, getState) => {
    const user = loginSelectors.getUser(getState());
    dispatch(resetState());
    const pqtType = user !== null && parseInt(user.pqt_type);
    if ([6, 7].includes(pqtType) && !user.pqt_departments.includes(parseInt(id))) {
        history.push('/pqt-department-view/' + user.pqt_departments[0]);
        dispatch(setId(user.pqt_departments[0], showJobs));
        dispatch(fetchData({
            fetchInformation: 1,
            fetchDepartment: 1,
            departmentId: user.pqt_department,
        }));
    } else if (pqtType === 6) {
        history.push('/pqt-department/' + user.pqt_department);
        dispatch(fetchData({
            fetchDepartment: 1,
            departmentId: user.pqt_department,
        }));
    } else {
        dispatch(setId(id, showJobs));
        dispatch(fetchData({
            fetchInformation: 1,
            fetchDepartment: 1,
            departmentId: id,
        }));
    }
}

export const setIdForDelete = (id) => (dispatch) => {
    dispatch({
        type: types.SET_ID_FOR_DELETE,
        payload: id
    });
}
