import { createSelector } from 'reselect';

export const getComponentState = state => state.pqtLayout;

export const getWs = createSelector(
    getComponentState,
    (state) => state.ws
);

export const getCreatingTask = createSelector(
    getComponentState,
    (state) => state.creatingTask
);