import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Calculator from "./Calculator";
import * as actions from "./PostageCalculator.actions";
import * as selectors from "./PostageCalculator.selectors";
import "./PostageCalculator.scss";
import PqtLayout from "./../PqtLayout";
import { Helmet } from "react-helmet";
import { stringifyEqualityCheck } from "./../../utils";
import * as loginSelectors from "./../Login/Login.selectors";
import { Redirect } from "react-router-dom";

const PostageCalculator = (props) => {
    /* State to props */
    const fields = useSelector(selectors.getFields, stringifyEqualityCheck);
    const calculatingPrice = useSelector(
        selectors.getCalculatingPrice,
        stringifyEqualityCheck
    );
    const priceResult = useSelector(
        selectors.getPriceResult,
        stringifyEqualityCheck
    );
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const setField = useCallback(
        (field, value) => dispatch(actions.setField(field, value)),
        [dispatch]
    );
    const getPrice = useCallback(
        () => dispatch(actions.getPrice()),
        [dispatch]
    );

    // Hugh (61)
    if (user != null && ![61].includes(parseInt(user.id))) {
        return <Redirect to="/dashboard" />;
    }

    return (
        <PqtLayout title="Postage Calculator" history={props.history}>
            <Helmet>
                <title>Postage Calculator - Stagecoach</title>
            </Helmet>
            <div className="postage-calculator-container">
                <Calculator
                    fields={fields}
                    calculatingPrice={calculatingPrice}
                    priceResult={priceResult}
                    setField={setField}
                    getPrice={getPrice}
                />
            </div>
        </PqtLayout>
    );
};

export default PostageCalculator;
