import React from 'react';
import { Dialog } from './../../common/dialog';
import Switch from "react-switch";
import FormPopover from './../Form/FormPopover';

const MailModal = React.memo((props) => {
    const handleInkjetSwitchChange = (id, field, checked) => {
        props.setInkjetItem(id, field, checked ? 1 : 0);
    };

    const handleInsertingSwitchChange = (id, field, checked) => {
        props.setInsertingItem(id, field, checked ? 1 : 0);
    };

    const handleMeteringSwitchChange = (id, field, checked) => {
        props.setMeteringItem(id, field, checked ? 1 : 0);
    };

    const renderInkjetItems = () => {
        return props.inkjetItems.map((item) => {
            return (
                <tr>
                    <td>{item.name}</td>
                    <td className="switch-column">
                        <Switch
                            onChange={handleInkjetSwitchChange.bind(this, item.id, 'checked')}
                            checked={item.checked}
                            width={42}
                            height={22}
                        />
                    </td>
                    <td className="action-column">
                        <FormPopover
                            code={item.code_ref}
                            information={props.information}
                        />
                    </td>
                </tr>
            );
        });
    };

    const renderInsertingItems = () => {
        return props.insertingItems.map((item) => {
            return (
                <tr>
                    <td>{item.name}</td>
                    <td className="switch-column">
                        <Switch
                            onChange={handleInsertingSwitchChange.bind(this, item.id, 'checked')}
                            checked={item.checked}
                            width={42}
                            height={22}
                        />
                    </td>
                    <td className="action-column">
                        <FormPopover
                            code={item.code_ref}
                            information={props.information}
                        />
                    </td>
                </tr>
            );
        });
    };

    const renderMeteringItems = () => {
        return props.meteringItems.map((item) => {
            return (
                <tr>
                    <td>{item.name}</td>
                    <td className="switch-column">
                        <Switch
                            onChange={handleMeteringSwitchChange.bind(this, item.id, 'checked')}
                            checked={item.checked}
                            width={42}
                            height={22}
                        />
                    </td>
                    <td className="action-column">
                        <FormPopover
                            code={item.code_ref}
                            information={props.information}
                        />
                    </td>
                </tr>
            );
        });
    };

    let content = null;
    if (props.mailActiveTab === 1) {
        content = (
            <div className="items">
                <table className="table table-sm table-hover table-striped">
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Yes/No</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderInkjetItems()}
                    </tbody>
                </table>
            </div>
        );
    } else if (props.mailActiveTab === 2) {
        content = (
            <div className="items">
                <table className="table table-sm table-hover table-striped">
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Yes/No</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderInsertingItems()}
                    </tbody>
                </table>
            </div>
        );
    } else if (props.mailActiveTab === 3) {
        content = (
            <div className="items">
                <table className="table table-sm table-hover table-striped">
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Yes/No</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderMeteringItems()}
                    </tbody>
                </table>
            </div>
        );
    }

    const handleAddButton = () => {
        props.addMailItems();
        props.hideMailModal();
    };

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={props.hideMailModal}>Cancel</button>,
        <button key="delete-button" className="btn btn-light btn-sm action-button" onClick={handleAddButton}>Add</button>,
    ];
    return (
        <Dialog
            name="mail-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="mail-dialog"
        >
            <div className="mail-container">
                <h1>Mailing Services Items</h1>
                <div className="items-container">
                    <div className="tabs">
                        <div className={'tab' + (props.mailActiveTab === 1 ? ' active' : '')} onClick={props.setMailActiveTab.bind(this, 1)}>
                            Inkjet
                        </div>
                        <div className={'tab' + (props.mailActiveTab === 2 ? ' active' : '')} onClick={props.setMailActiveTab.bind(this, 2)}>
                            Inserting
                        </div>
                        <div className={'tab' + (props.mailActiveTab === 3 ? ' active' : '')} onClick={props.setMailActiveTab.bind(this, 3)}>
                            Metering
                        </div>
                    </div>
                    {content}
                </div>
            </div>
        </Dialog >
    );
});

export default MailModal;
