import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import List from './List';
import DeleteModal from './DeleteModal';
import FormModal from './FormModal';
import * as actions from './HelpItems.actions';
import * as selectors from './HelpItems.selectors';
import './HelpItems.scss';
import PqtLayout from './../PqtLayout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import * as dialog from '../common/dialog';
import * as loginSelectors from './../Login/Login.selectors';
import { Redirect } from 'react-router-dom';

const HelpItems = (props) => {
    /* State to props */
    const fetchingHelpItems = useSelector(selectors.getFetchingHelpItems);
    const helpItems = useSelector(selectors.getHelpItems, stringifyEqualityCheck);
    const pagination = useSelector(selectors.getPagination, stringifyEqualityCheck);
    const sort = useSelector(selectors.getSort, stringifyEqualityCheck);
    const idForDelete = useSelector(selectors.getIdForDelete, stringifyEqualityCheck);
    const isDeleting = useSelector(selectors.getIsDeleting, stringifyEqualityCheck);
    const filerFields = useSelector(selectors.getFilterFields, stringifyEqualityCheck);
    const saving = useSelector(selectors.getSaving);
    const name = useSelector(selectors.getName);
    const codeRef = useSelector(selectors.getCodeRef);
    const description = useSelector(selectors.getDescription);
    const image = useSelector(selectors.getImage);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const deleteHelpItem = useCallback((id, comments) => dispatch(actions.deleteHelpItem(id, comments)), [dispatch]);
    const setSort = useCallback((column, type) => dispatch(actions.setSort(column, type)), [dispatch]);
    const setActivePage = useCallback((name, value) => dispatch(actions.setActivePage(name, value)), [dispatch]);
    const fetchHelpItems = useCallback(() => dispatch(actions.fetchHelpItems()), [dispatch]);
    const setIdForDelete = useCallback((id) => dispatch(actions.setIdForDelete(id)), [dispatch]);
    const changeFilter = useCallback((name, value, fetch) => dispatch(actions.changeFilter(name, value, fetch)), [dispatch]);
    const setName = useCallback((value) => dispatch(actions.setName(value)), [dispatch]);
    const setCodeRef = useCallback((value) => dispatch(actions.setCodeRef(value)), [dispatch]);
    const setDescription = useCallback((value) => dispatch(actions.setDescription(value)), [dispatch]);
    const setImage = useCallback((value) => dispatch(actions.setImage(value)), [dispatch]);
    const saveHelpItem = useCallback((acceptedFiles) => dispatch(actions.saveHelpItem(acceptedFiles)), [dispatch]);
    const clearFields = useCallback((acceptedFiles) => dispatch(actions.clearFields(acceptedFiles)), [dispatch]);
    const setActiveHelpItem = useCallback((id) => dispatch(actions.setActiveHelpItem(id)), [dispatch]);
    const showDeleteModal = useCallback(() => dispatch(dialog.actions.showDialog('delete-modal')), [dispatch]);
    const hideDeleteModal = useCallback(() => dispatch(dialog.actions.hideDialog('delete-modal')), [dispatch]);
    const showFormModal = useCallback(() => dispatch(dialog.actions.showDialog('form-modal')), [dispatch]);
    const hideFormModal = useCallback(() => dispatch(dialog.actions.hideDialog('form-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        fetchHelpItems();
    }, [dispatch, fetchHelpItems]);

    if (user != null && ![61, 63].includes(parseInt(user.id))) {
        return <Redirect to="/dashboard" />;
    }

    return (
        <PqtLayout title="Help Items" history={props.history}>
            <Helmet><title>Help Items - Stagecoach</title></Helmet>
            <div className="help-items-container">
                <List
                    helpItems={helpItems}
                    pagination={pagination}
                    fetchingHelpItems={fetchingHelpItems}
                    history={props.history}
                    sort={sort}
                    user={user}
                    filerFields={filerFields}
                    setSort={setSort}
                    setActivePage={setActivePage}
                    showDeleteModal={showDeleteModal}
                    setIdForDelete={setIdForDelete}
                    changeFilter={changeFilter}
                    fetchHelpItems={fetchHelpItems}
                    showFormModal={showFormModal}
                    setActiveHelpItem={setActiveHelpItem}
                />
                <DeleteModal
                    idForDelete={idForDelete}
                    isDeleting={isDeleting}
                    deleteHelpItem={deleteHelpItem}
                    hideDeleteModal={hideDeleteModal}
                    setIdForDelete={setIdForDelete}
                />
                <FormModal
                    idForDelete={idForDelete}
                    saving={saving}
                    name={name}
                    codeRef={codeRef}
                    description={description}
                    image={image}
                    setIdForDelete={setIdForDelete}
                    hideFormModal={hideFormModal}
                    setName={setName}
                    setCodeRef={setCodeRef}
                    setDescription={setDescription}
                    setImage={setImage}
                    saveHelpItem={saveHelpItem}
                    clearFields={clearFields}
                />
            </div>
        </PqtLayout>
    );
};

HelpItems.propTypes = {}

export default HelpItems;
