import React, { useEffect, useCallback, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Form from './Form';
import * as actions from './ChristyRfq.actions';
import * as selectors from './ChristyRfq.selectors';
import './ChristyRfq.scss';
import PqtLayout from './../PqtLayout';
import SubmittedModal from './SubmittedModal';
import SendModal from './SendModal';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import * as loginSelectors from './../Login/Login.selectors';
import { Redirect } from 'react-router-dom';
import * as dialog from '../common/dialog';

const ChristyRfq = (props) => {
    /* State to props */
    const fetchingRequest = useSelector(selectors.getFetchingRequest);
    const fields = useSelector(selectors.getFields, stringifyEqualityCheck);
    const fetchingInformation = useSelector(selectors.getFetchingInformation);
    const information = useSelector(selectors.getInformation, stringifyEqualityCheck);
    const submitting = useSelector(selectors.getSubmitting);
    const sending = useSelector(selectors.getSending);
    const saving = useSelector(selectors.getSaving);
    const id = useSelector(selectors.getId);
    const files = useSelector(selectors.getFiles, stringifyEqualityCheck);
    const submittedId = useSelector(selectors.getSubmittedId);
    const pricing = useSelector(selectors.getPricing);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const setField = useCallback((name, value) => dispatch(actions.setField(name, value)), [dispatch]);
    const fetchInformation = useCallback((id) => dispatch(actions.fetchInformation(id)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);
    const setId = useCallback((id) => dispatch(actions.setId(id)), [dispatch]);
    const setHistory = useCallback((history) => dispatch(actions.setHistory(history)), [dispatch]);
    const submitRequest = useCallback(() => dispatch(actions.submitRequest()), [dispatch]);
    const sendEstimate = useCallback(() => dispatch(actions.sendEstimate()), [dispatch]);
    const addFile = useCallback((file) => dispatch(actions.addFile(file)), [dispatch]);
    const removeFile = useCallback((index) => dispatch(actions.removeFile(index)), [dispatch]);
    const setPricingField = useCallback((index, field, value) => dispatch(actions.setPricingField(index, field, value)), [dispatch]);
    const saveRequest = useCallback(() => dispatch(actions.saveRequest()), [dispatch]);
    const showSubmittedModal = useCallback(() => dispatch(dialog.actions.showDialog('submitted-modal')), [dispatch]);
    const hideSubmittedModal = useCallback(() => dispatch(dialog.actions.hideDialog('submitted-modal')), [dispatch]);
    const showSendModal = useCallback(() => dispatch(dialog.actions.showDialog('send-modal')), [dispatch]);
    const hideSendModal = useCallback(() => dispatch(dialog.actions.hideDialog('send-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        setId(props.match.params.id);
        fetchInformation(props.match.params.id);
        setHistory(props.history);

        return () => {
            resetState();
        };
    }, [fetchInformation, setId, setHistory, resetState, props.match.params.id, props.history]);

    if (id && user && user != null && ![1, 2].includes(user.christy_rfq_type)) {
        return <Redirect to="/dashboard" />;
    }

    let submittedModal = (
        <SubmittedModal
            submittedId={submittedId}
            hideSubmittedModal={hideSubmittedModal}
        />
    );

    let sendModal = (
        <SendModal
            fields={fields}
            files={files}
            sending={sending}
            sendEstimate={sendEstimate}
            hideSendModal={hideSendModal}
        />
    );

    if (!id) {
        return (
            <Fragment>
                <Helmet><title>Quote Request - Stagecoach</title></Helmet>
                <div className="christy-rfq-container">
                    <Form
                        id={id}
                        fetchingRequest={fetchingRequest}
                        history={props.history}
                        user={user}
                        fields={fields}
                        fetchingInformation={fetchingInformation}
                        information={information}
                        submitting={submitting}
                        sending={sending}
                        files={files}
                        pricing={pricing}
                        saving={saving}
                        setField={setField}
                        submitRequest={submitRequest}
                        resetState={resetState}
                        addFile={addFile}
                        removeFile={removeFile}
                        showSubmittedModal={showSubmittedModal}
                        hideSubmittedModal={hideSubmittedModal}
                        setPricingField={setPricingField}
                        showSendModal={showSendModal}
                        hideSendModal={hideSendModal}
                        saveRequest={saveRequest}
                    />
                    {submittedModal}
                    {sendModal}
                </div>
            </Fragment>
        );
    }

    return (
        <PqtLayout title="Quote Request" history={props.history}>
            <Helmet><title>Quote Request - Stagecoach</title></Helmet>
            <div className="christy-rfq-container" style={{ marginTop: -50 }}>
                <Form
                    id={id}
                    fetchingRequest={fetchingRequest}
                    history={props.history}
                    user={user}
                    fields={fields}
                    fetchingInformation={fetchingInformation}
                    information={information}
                    submitting={submitting}
                    sending={sending}
                    files={files}
                    pricing={pricing}
                    saving={saving}
                    setField={setField}
                    submitRequest={submitRequest}
                    resetState={resetState}
                    addFile={addFile}
                    removeFile={removeFile}
                    showSubmittedModal={showSubmittedModal}
                    hideSubmittedModal={hideSubmittedModal}
                    setPricingField={setPricingField}
                    showSendModal={showSendModal}
                    hideSendModal={hideSendModal}
                    saveRequest={saveRequest}
                />
                {submittedModal}
                {sendModal}
            </div>
        </PqtLayout>
    );
};

export default ChristyRfq;
