import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { dateToTimezone, empty } from './../../../utils';
import { useDropzone } from 'react-dropzone';
import { Tooltip } from 'react-tippy';

const AuditLog = React.memo((props) => {
    const {
        // acceptedFiles,
        // fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        multiple: true,
        onDrop: (files) => {
            for (let i = 0; i < files.length; i++) {
                props.addFile(files[i]);
            }
        }
    });

    if (props.isEditing) {
        return null;
    }

    const status = parseInt(props.trackingFields.status);
    const pqtType = props.user !== null && parseInt(props.user.pqt_type);

    let startButton = null;
    if ([1, 2, 3, 4, 8].includes(pqtType) && status === 1) {
        startButton = <button onClick={props.startTracking} className="start-tracking-button"><i className="fas fa-stopwatch"></i> Start Tracking</button>;
        if (props.savingStart) {
            startButton = <button disabled className="start-tracking-button"><i className="fas fa-circle-notch fa-spin" /></button>;
        }
    }

    let addStepButton = null;
    if ([1, 2, 3, 4, 8].includes(pqtType) && status !== 4) {
        addStepButton = <button onClick={props.addStep}><i className="fas fa-calendar-plus"></i> Add Step</button>;
    }

    const exportHandler = () => {
        window.open(process.env.REACT_APP_API_URL + '/pqt-tracking/export?id=' + props.trackingFields.id);
    };

    let createPreliminaryButton = null;
    if (status > 1 && props.trackingFields.invoice_id == null && props.user != null && parseInt(props.user.id) === 61) {
        let createPreliminaryLabel = <Fragment><i className="fas fa-file-invoice" /> Create Preliminary</Fragment>;
        if (props.creatingPreliminary) {
            createPreliminaryLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        createPreliminaryButton = (
            <button onClick={props.createPreliminary} className="create-preliminary-button">
                {createPreliminaryLabel}
            </button>
        );
    }

    let invoiceIdRow = null;
    if (props.trackingFields.invoice_id != null) {
        invoiceIdRow = <div><b>Invoice #:</b> <Link to={'/invoice/' + props.trackingFields.invoice_id}>{props.trackingFields.invoice_id}</Link></div>;
    }
    if (props.trackingFields.invoice_ids && props.trackingFields.invoice_ids.length > 0) {
        let links = [];
        for (const id of props.trackingFields.invoice_ids) {
            links.push(<Link to={'/invoice/' + id}>{id}</Link>);
            links.push(', ');
        }
        links.pop();

        invoiceIdRow = <div><b>Invoices:</b> {links}</div>;
    }

    let postageInvoiceRow = null;
    if (props.trackingFields.postage_invoice_id != null) {
        postageInvoiceRow = <div><b>Postage Payment Invoice #:</b> <Link to={'/postage-invoice/' + props.trackingFields.postage_invoice_id}>{props.trackingFields.postage_invoice_id}</Link></div>;
    }


    let fileUrlRow = null;
    if (props.trackingFields.file_url != null && props.trackingFields.file_url !== '') {
        fileUrlRow = <div><b>File:</b> <a href={props.trackingFields.file_url} target="_blank" rel="noopener noreferrer">Click here to view the file</a></div>;
    }

    let partDescriptionRow = null;
    if (props.trackingFields.part_description != null && props.trackingFields.part_description !== '') {
        partDescriptionRow = <div><b>Part:</b> {props.trackingFields.part_description}</div>;
    }

    if (parseInt(props.trackingFields.status) === 5) {
        addStepButton = null;
        startButton = null;
        createPreliminaryButton = null;
    }

    let fields = props.trackingFields;
    let scEstimateRow = null;
    if (props.user != null && parseInt(props.user.id) === 61) {     // Hugh
        if (fields.sc_estimate_id != null) {
            scEstimateRow = (
                <div>
                    <b>Estimate #:</b> <Link to={'/estimate/' + fields.sc_estimate_type + '/' + fields.sc_estimate_id}>{fields.sc_estimate_id}</Link>
                </div>
            );
        }
    }

    let rfqRow = null;
    if (props.user != null && parseInt(props.user.id) === 61) {     // Hugh
        if (fields.sc_quote_request_id != null) {
            rfqRow = (
                <div>
                    <b>RFQ #:</b> <Link to={'/request-for-quote/' + fields.sc_quote_request_id}>{fields.sc_quote_request_id}</Link>
                </div>
            );
        }
    }

    let estimateFiles = null;
    if (!empty(fields.sc_estimate_files) && fields.sc_estimate_files.length > 0) {
        estimateFiles = (
            <div className="estimate-files">
                <div><strong>SC/Est Files:</strong></div>
                <ul>
                    {fields.sc_estimate_files.map((file) => {
                        return (
                            <li>
                                <a href={file.url} target="_blank" rel="noopener noreferrer">{file.name}</a>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }

    const setFieldHandler = (name, event) => {
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        }

        props.setField(name, value);
    }

    let saveCloseoutEmailsLabel = <Fragment><i className="fas fa-save"></i> Save Emails</Fragment>;
    if (props.savingCloseoutEmails) {
        saveCloseoutEmailsLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    let saveMailingEmailsLabel = <Fragment><i className="fas fa-save"></i> Save Emails</Fragment>;
    if (props.savingMailingEmails) {
        saveMailingEmailsLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    let saveShippingEmailsLabel = <Fragment><i className="fas fa-save"></i> Save Emails</Fragment>;
    if (props.savingShippingEmails) {
        saveShippingEmailsLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    let noArtworksLabel = null;
    if (parseInt(props.trackingFields.rfq_artwork_required) === 0) {
        noArtworksLabel = (
            <div className="no-arworks">NO ARTWORK REQUIRED</div>
        );
    }

    const handleDeleteFile = (id) => {
        props.setIdModal(id);
        props.showDeleteFileModal();
    };

    const renderFilesContainer = () => {
        let displayFilesContainer = false;
        for (const step of props.trackingSteps) {
            if (parseInt(step.department_id) === 59 || parseInt(step.department_id) === 69) {   // Promotional or Apparel
                displayFilesContainer = true;
                break;
            }
        }

        if (!displayFilesContainer) {
            return null;
        }

        let uploadFilesLabel = 'Upload Files';
        if (props.uploadingFiles) {
            uploadFilesLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let uploadingFiles = null;
        if (props.saving && props.files.length > 0) {
            uploadingFiles = <div className="uploading-message"><i className="fas fa-circle-notch fa-spin" /> Your files are uploading. It may take a few seconds depending on the size of the file.</div>;
        }

        return (
            <div className="files-container">
                <h3>
                    Files
                    <div {...getRootProps({ className: 'dropzone-container' })}>
                        <input {...getInputProps()} />
                        <button className="browse-files-button">Browse</button>
                    </div>
                </h3>
                <table className="table table-sm table-hover">
                    <thead>
                        <tr>
                            <th>Filename</th>
                            <th className="actions"></th>
                        </tr>
                    </thead>
                    <tbody>{renderFiles()}</tbody>
                </table>
                {uploadingFiles}
                <button className="upload-files-button" onClick={props.uploadFiles} disabled={props.uploadingFiles}>{uploadFilesLabel}</button>
            </div>
        );
    }

    const renderFiles = () => {
        if (props.files.length === 0) {
            return (
                <tr>
                    <td colSpan="2">No files selected</td>
                </tr>
            );
        }

        return props.files.map((file, index) => {
            let fileColumn = null;
            let deleteIcon = null;

            if (file.url) {
                fileColumn = <a href={file.url} target="_blank" rel="noopener noreferrer">{file.filename}</a>;
                deleteIcon = <i className="fas fa-trash" onClick={handleDeleteFile.bind(this, file.id)} />;
            } else {
                fileColumn = file.name;
                deleteIcon = <i className="fas fa-trash" onClick={props.removeFile.bind(this, index)} />;
            }

            return (
                <tr>
                    <td>{fileColumn}</td>
                    <td className="text-center actions">
                        <Tooltip title="Remove File" position="bottom" arrow size="small" className="action-tooltip">
                            {deleteIcon}
                        </Tooltip>
                    </td>
                </tr>
            );
        });
    };

    return (
        <div className="audit-log-container">
            <div className="title">
                <div>Audit Log:</div>
                <div className="job-number">Job Number: {props.trackingFields.id} <div className="barcode">*&amp;001-{props.trackingFields.id}*</div></div>
            </div>
            <div className="log">
                {props.trackingAuditHistory.map((record, index) => (
                    <div className="log-element" key={'log-element-' + index}>
                        <b className={record.is_update ? 'pink-value' : ''}>{dateToTimezone(record.created_at, 'MMMM Do YYYY, h:mm a')}:</b> <span className="label" dangerouslySetInnerHTML={{ __html: record.label }}></span>
                    </div>
                ))}
            </div>
            <div className="count-down">
                <div>
                    <div><b>Count Down:</b> <span className={props.trackingFields.countdown_class}>{props.trackingFields.countdown}</span></div>
                    <div><b>Lifecycle:</b> {props.trackingFields.lifecycle}</div>
                    {invoiceIdRow}
                    {postageInvoiceRow}
                    {rfqRow}
                    {scEstimateRow}
                    {fileUrlRow}
                    {partDescriptionRow}
                    {estimateFiles}
                    {noArtworksLabel}
                    {renderFilesContainer()}
                </div>
                <div className="buttons-container">
                    {createPreliminaryButton}
                    <button onClick={exportHandler} className="export-button"><i className="fas fa-print"></i> Print Ticket</button>
                    {addStepButton}
                    {startButton}
                    <div className="mailing-notification">
                        <div className="label">
                            Please provide the email addresses of individuals who wish to be notified upon the completion of the project by each department.
                            You can add multiple emails separated by a comma.
                        </div>
                        <input
                            type="text"
                            value={props.trackingFields.closeout_notifications_emails === undefined ? '' : props.trackingFields.closeout_notifications_emails}
                            onChange={setFieldHandler.bind(this, 'closeout_notifications_emails')}
                        />
                        <div className="save-emails-container">
                            <button onClick={props.saveCloseoutNotificationsEmails} disabled={props.savingCloseoutEmails}>{saveCloseoutEmailsLabel}</button>
                        </div>
                    </div>
                    <div className="mailing-notification">
                        <div className="label">
                            Please provide the email addresses of individuals who wish to be notified of their Postage. You can add multiple emails separated by a comma.
                        </div>
                        <input
                            type="text"
                            value={props.trackingFields.mailing_notifications_emails === undefined ? '' : props.trackingFields.mailing_notifications_emails}
                            onChange={setFieldHandler.bind(this, 'mailing_notifications_emails')}
                        />
                        <div className="save-emails-container">
                            <button onClick={props.saveMailingNotificationsEmails} disabled={props.savingMailingEmails}>{saveMailingEmailsLabel}</button>
                        </div>
                    </div>
                    <div className="shipping-notification">
                        <div className="label">
                            Please provide the email addresses of individuals who wish to be notified upon shipping of the project. You can add multiple emails separated by a comma.
                        </div>
                        <input
                            type="text"
                            value={props.trackingFields.shipping_notifications_emails === undefined ? '' : props.trackingFields.shipping_notifications_emails}
                            onChange={setFieldHandler.bind(this, 'shipping_notifications_emails')}
                        />
                        <div className="save-emails-container">
                            <button onClick={props.saveShippingNotificationsEmails} disabled={props.savingShippingEmails}>{saveShippingEmailsLabel}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default AuditLog;
