export const FETCH_REQUESTS = 'CHRISTY_RFQS/FETCH_REQUESTS';
export const FETCH_REQUESTS_SUCCESS = 'CHRISTY_RFQS/FETCH_REQUESTS_SUCCESS';
export const FETCH_REQUESTS_ERROR = 'CHRISTY_RFQS/FETCH_REQUESTS_ERROR';
export const DELETE_REQUEST = 'CHRISTY_RFQS/DELETE_REQUEST';
export const DELETE_REQUEST_SUCCESS = 'CHRISTY_RFQS/DELETE_REQUEST_SUCCESS';
export const DELETE_REQUEST_ERROR = 'CHRISTY_RFQS/DELETE_REQUEST_ERROR';
export const RESET_STATE = 'CHRISTY_RFQS/RESET_STATE';
export const SET_ID = 'CHRISTY_RFQS/SET_ID';
export const SET_SORT = 'CHRISTY_RFQS/SET_SORT';
export const SET_ACTIVE_PAGE = 'CHRISTY_RFQS/SET_ACTIVE_PAGE';
export const SET_ID_FOR_DELETE = 'CHRISTY_RFQS/SET_ID_FOR_DELETE';
export const CHANGE_FILTER = 'CHRISTY_RFQS/CHANGE_FILTER';