import axios from 'axios';
import * as types from './PurchaseOrders.types';
import * as selectors from './PurchaseOrders.selectors';
import * as dialog from '../common/dialog';
import { validateAuthInResponse, showDataDeletedNotification, empty, showNotification } from './../../utils';
import fileDownload from 'js-file-download';
import moment from 'moment';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchPurchaseOrders = () => (dispatch, getState) => {
    const sort = selectors.getSort(getState());
    const pagination = selectors.getPagination(getState());
    const filerFields = selectors.getFilterFields(getState());

    let params = [];
    params.push('sort=' + (sort.type === 'asc' ? '' : '-') + sort.column);
    params.push('active-page=' + pagination.activePage);
    params.push('elements-per-page=' + pagination.elementsPerPage);

    if (filerFields.length > 0) {
        let filterElements = [];
        for (let i = 0; i < filerFields.length; i++) {
            filterElements.push(filerFields[i]['name'] + '=' + filerFields[i]['value']);
        }
        params.push('filter=' + filterElements.join(','))
    } else {
        params.push('filter=-')
    }

    dispatch({ type: types.FETCH_PURCHASE_ORDERS, payload: null });
    axios.get(apiUrl + '/purchase-orders' + (params.length === 0 ? '' : '?' + params.join('&')),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_PURCHASE_ORDERS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_PURCHASE_ORDERS_ERROR));
        });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null
    });
}

export const deletePurchaseOrder = (id) => (dispatch) => {
    dispatch({ type: types.DELETE_PURCHASE_ORDER, payload: null });
    axios.delete(apiUrl + '/purchase-orders/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } }
    ).then(() => {
        dispatch({ type: types.DELETE_PURCHASE_ORDER_SUCCESS, payload: null });
        showDataDeletedNotification();
        dispatch(fetchPurchaseOrders());
        dispatch(dialog.actions.hideDialog('delete-modal'));
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.DELETE_PURCHASE_ORDER_ERROR));
    });
}

export const setSort = (column, type) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_SORT,
            payload: {
                column: column,
                type: type,
            }
        });
        resolve();
    }).then(() => {
        dispatch(fetchPurchaseOrders());
    });
}

export const setActivePage = (value) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_ACTIVE_PAGE,
            payload: value
        });
        resolve();
    }).then(() => {
        dispatch(fetchPurchaseOrders());
    });
}

export const mountComponent = () => (dispatch) => {
    dispatch(resetState());
    dispatch(fetchPurchaseOrders());
}

export const setIdForDelete = (id) => (dispatch) => {
    dispatch({
        type: types.SET_ID_FOR_DELETE,
        payload: id,
    });
}
export const setReportStartDate = (value) => (dispatch) => {
    dispatch({
        type: types.SET_REPORT_START_DATE,
        payload: value,
    });
}
export const setReportEndDate = (value) => (dispatch) => {
    dispatch({
        type: types.SET_REPORT_END_DATE,
        payload: value,
    });
}
export const setReportType = (value) => (dispatch) => {
    dispatch({
        type: types.SET_REPORT_TYPE,
        payload: value,
    });
}
export const setFile = (value) => (dispatch) => {
    dispatch({
        type: types.SET_FILE,
        payload: value,
    });
}

export const changeFilter = (name, value) => (dispatch) => {
    new Promise((resolve) => {
        dispatch({
            type: types.CHANGE_FILTER,
            payload: { name, value }
        });
        resolve();
    }).then(() => {
        dispatch(fetchPurchaseOrders());
    });
}

export const exportExcel = () => (dispatch, getState) => {
    dispatch({ type: types.EXPORT_EXCEL, payload: null });
    axios.post(apiUrl + '/purchase-order/export-excel', {},
        {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
            responseType: 'blob',
        })
        .then((response) => {
            dispatch({ type: types.EXPORT_EXCEL_SUCCESS, payload: null });
            fileDownload(response.data, 'Purchase Orders.xlsx');
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.EXPORT_EXCEL_ERROR));
        });
}

export const exportReport = () => (dispatch, getState) => {
    const reportStartDate = selectors.getReportStartDate(getState());
    const reportEndDate = selectors.getReportEndDate(getState());

    dispatch({ type: types.EXPORT_REPORT, payload: null });
    axios.post(apiUrl + '/purchase-order/export-report', { startDate: reportStartDate, endDate: reportEndDate },
        {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
            responseType: 'blob',
        })
        .then((response) => {
            dispatch({ type: types.EXPORT_REPORT_SUCCESS, payload: null });
            dispatch(dialog.actions.hideDialog('report-modal'));

            let filename = ['Purchase Orders Report'];

            if (!empty(reportStartDate)) {
                filename.push('From ' + moment(reportStartDate).format('MM-DD-YYYY'));
            }
            if (!empty(reportEndDate)) {
                filename.push('To ' + moment(reportEndDate).format('MM-DD-YYYY'));
            }

            fileDownload(response.data, filename.join(' - ') + '.xlsx');
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.EXPORT_REPORT_ERROR));
        });
}

export const exportListPurchase = () => (dispatch, getState) => {
    const reportStartDate = selectors.getReportStartDate(getState());
    const reportEndDate = selectors.getReportEndDate(getState());

    dispatch({ type: types.EXPORT_LIST_PURCHASE, payload: null });
    axios.post(apiUrl + '/purchase-order/export-list-purchase', { startDate: reportStartDate, endDate: reportEndDate },
        {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
            responseType: 'blob',
        })
        .then((response) => {
            dispatch({ type: types.EXPORT_LIST_PURCHASE_SUCCESS, payload: null });
            dispatch(dialog.actions.hideDialog('report-modal'));

            let filename = ['List Purchase Report'];

            if (!empty(reportStartDate)) {
                filename.push('From ' + moment(reportStartDate).format('MM-DD-YYYY'));
            }
            if (!empty(reportEndDate)) {
                filename.push('To ' + moment(reportEndDate).format('MM-DD-YYYY'));
            }

            fileDownload(response.data, filename.join(' - ') + '.xlsx');
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.EXPORT_LIST_PURCHASE_ERROR));
        });
}

export const uploadListPurchase = () => (dispatch, getState) => {
    const file = selectors.getFile(getState());

    let data = new FormData();
    data.append('file', file);

    dispatch({ type: types.UPLOAD_LIST_PURCHASE, payload: null });
    axios.post(apiUrl + '/purchase-order/upload-list-purchase', data,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.UPLOAD_LIST_PURCHASE_SUCCESS, payload: null });
            dispatch(dialog.actions.hideDialog('upload-list-purchase-modal'));
            showNotification('Success', 'The file was uploaded successfully', 'success');
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.UPLOAD_LIST_PURCHASE_ERROR));
        });
}