import React from 'react';
import { Dialog } from './../../common/dialog';

const PushModal = React.memo((props) => {
    const handleQBPush = () => {
        props.qBPush(3);
    };

    let label = 'Push';
    if (props.isPushing) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={props.hidePushModal}>Cancel</button>,
        <button key="delete-button" className="btn btn-light btn-sm action-button" onClick={handleQBPush} disabled={props.isPushing}>{label}</button>
    ];
    return (
        <Dialog
            name="push-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="push-dialog"
        >
            <div className="push-container">
                <h1>Push Credit Memo to QuickBooks</h1>
                <p>Confirm Push clicking on the "Push" Button below.</p>
            </div>
        </Dialog >
    );
});

export default PushModal;
