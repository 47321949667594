import { createSelector } from 'reselect';

export const getComponentState = state => state.pqtInvoice;

export const getFetching = createSelector(
    getComponentState,
    (state) => state.fetching
);

export const getInvoice = createSelector(
    getComponentState,
    (state) => state.invoice
);

export const getInformation = createSelector(
    getComponentState,
    (state) => state.information
);

export const getFetchingCustomerData = createSelector(
    getComponentState,
    (state) => state.fetchingCustomerData
);

export const getIsSaving = createSelector(
    getComponentState,
    (state) => state.isSaving
);

export const getIsApproving = createSelector(
    getComponentState,
    (state) => state.isApproving
);

export const getIsPushing = createSelector(
    getComponentState,
    (state) => state.isPushing
);

export const getIsReturning = createSelector(
    getComponentState,
    (state) => state.isReturning
);

export const getIsUncombining = createSelector(
    getComponentState,
    (state) => state.isUncombining
);

export const getIsSplittingInvoice = createSelector(
    getComponentState,
    (state) => state.isSplittingInvoice
);

export const getIsSynchingQbTotal = createSelector(
    getComponentState,
    (state) => state.isSynchingQbTotal
);

export const getCustomerEmail = createSelector(
    getComponentState,
    (state) => state.customerEmail
);

export const getJobDueDate = createSelector(
    getComponentState,
    (state) => state.jobDueDate
);

export const getFetchingPostageEscrow = createSelector(
    getComponentState,
    (state) => state.fetchingPostageEscrow
);

export const getPostageEscrow = createSelector(
    getComponentState,
    (state) => state.postageEscrow
);

export const getSplitPercent1 = createSelector(
    getComponentState,
    (state) => state.splitPercent1
);

export const getSplitPercent2 = createSelector(
    getComponentState,
    (state) => state.splitPercent2
);
