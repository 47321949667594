import React, { useState } from 'react';
//import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';
import { showNotification } from './../../../utils';

const PushInvoiceModal = React.memo((props) => {
    const [option, setOption] = useState('');

    const handleQBPush = () => {
        if (option === '') {
            return showNotification('Select an option', 'You need to select an option before to continue', 'info');
        }

        props.qBPush(option);
    };

    let label = 'Accept';
    if (props.isPushing) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    let customerField = null;
    if (parseInt(option) === 1 || parseInt(option) === 2) {
        customerField = (
            <div className="customer-field">
                Customer Email:
                <input type="text" value={props.customerEmail} onChange={(event) => props.setCustomerEmail(event.target.value)} />
            </div>
        );
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={props.hidePushInvoiceModal}>Cancel</button>,
        <button key="delete-button" className="btn btn-light btn-sm action-button" onClick={handleQBPush} disabled={props.isPushing}>{label}</button>
    ];
    return (
        <Dialog
            name="push-invoice-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="push-invoice-dialog"
        >
            <div className="push-invoice-container">
                <h1>Push Invoice to QuickBooks</h1>
                <p>Select an option to continue:</p>
                <select value={option} onChange={(event) => setOption(event.target.value)}>
                    <option value="">Select option</option>
                    <option value="1">Send Invoice to the Customer and Push Invoice to QB</option>
                    <option value="2">Send Invoice to the Customer only</option>
                    <option value="3">Push Invoice to QB only</option>
                </select>
                {customerField}
            </div>
        </Dialog >
    );
});

PushInvoiceModal.propTypes = {

};

export default PushInvoiceModal;
