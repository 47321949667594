import React from 'react';

const AdditionalAdjustments = React.memo((props) => {
    return (
        <div className="additional-adjustments-container">
            <h2>Additional Adjustments</h2>
            <div className="fields-container">
                <div className="comments-container">
                    <div className="field">
                        <div className="label">Comments:</div>
                        <textarea value={props.estimate.adjustments_comments} onChange={props.handleSetField.bind(this, 'adjustments_comments')} />
                    </div>
                </div>
                <div className="adjustments-container">
                    <div className="field">
                        <div className="label">Amount:</div>
                        <input type="number" value={props.estimate.amount_adjustment} onChange={props.handleSetField.bind(this, 'amount_adjustment')} />
                    </div>
                    <div className="field">
                        <div className="label">Percentage:</div>
                        <input type="number" value={props.estimate.amount_percentage} onChange={props.handleSetField.bind(this, 'amount_percentage')} />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default AdditionalAdjustments;
