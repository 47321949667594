import axios from 'axios';
import * as selectors from './MakePayment.selectors';
import * as types from './MakePayment.types';
import { validateAuthInResponse, showNotification, empty } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchPayment = (token) => (dispatch) => {
    dispatch({ type: types.FETCH_PAYMENT, payload: null });
    axios.get(apiUrl + '/payment/validate?token=' + token, {})
        .then((response) => {
            dispatch({ type: types.FETCH_PAYMENT_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_PAYMENT_ERROR));
        });
}

export const generateToken = (ref) => (dispatch, getState) => {
    const fields = selectors.getFields(getState());
    const payment = selectors.getPayment(getState());
    const recaptchaToken = selectors.getRecaptchaToken(getState());

    let data = {
        merchantKey: process.env.REACT_APP_USIO_MERCHANT_KEY,
        paymentType: fields.payment_type,
        emailAddress: fields.email,
    };

    if (fields.payment_type === 'CreditCard') {
        data.cardNumber = fields.card_number;
        data.expDate = fields.expiration_month + fields.expiration_year;
        data.CVV = fields.cvv;
    } else {
        data.RoutingNumber = fields.routing_number;
        data.AccountNumber = fields.account_number;
        data.ACHAccountType = fields.ach_account_type;
    }

    let fieldsToValidate = ['invoice_number', 'customer_id', 'total', 'email', 'payment_type', 'first_name', 'last_name', 'address1',
        'city', 'state', 'zip', 'country'];
    if (payment) {
        fieldsToValidate = ['email', 'payment_type', 'first_name', 'last_name', 'address1', 'city', 'state', 'zip', 'country'];
    }

    for (const field of fieldsToValidate) {
        if (empty(fields[field])) {
            return showNotification('Complete the information', 'Complete all the required fields', 'info');
        }
    }

    if (fields.payment_type === 'CreditCard') {
        fieldsToValidate = ['card_number', 'expiration_month', 'expiration_year', 'cvv'];
        for (const field of fieldsToValidate) {
            if (empty(fields[field])) {
                return showNotification('Complete the information', 'Complete all the required fields', 'info');
            }
        }
    } else {
        fieldsToValidate = ['ach_account_type', 'routing_number', 'account_number'];
        for (const field of fieldsToValidate) {
            if (empty(fields[field])) {
                return showNotification('Complete the information', 'Complete all the required fields', 'info');
            }
        }
    }

    if (recaptchaToken == null) {
        return showNotification('Complete the information', 'Complete the Captcha', 'info');
    }

    dispatch({ type: types.GENERATE_TOKEN, payload: null });
    axios.post('https://checkout.usiopay.com/2.0/GenerateToken', data, {})
        .then((response) => {
            dispatch({ type: types.GENERATE_TOKEN_SUCCESS, payload: response.data });
            
            if (response.data.status === 'failure') {
                const parts = response.data.message.split(':');
                showNotification('Incorrect payment information', parts[1], 'info');

                dispatch(resetFields());
                ref.current.reset();
                dispatch(setRecaptchaToken(null));
            } else {
                dispatch(setToken(response.data.token));
                dispatch(savePayment(response.data, ref));
            }
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.GENERATE_TOKEN_ERROR));

            dispatch(resetFields());
            ref.current.reset();
            dispatch(setRecaptchaToken(null));
        });
}

export const savePayment = (data, ref) => (dispatch, getState) => {
    const fields = selectors.getFields(getState());
    const payment = selectors.getPayment(getState());
    const invoiceToken = selectors.getInvoiceToken(getState());
    const recaptchaToken = selectors.getRecaptchaToken(getState());

    if (recaptchaToken == null) {
        return showNotification('Complete the information', 'Complete the Captcha', 'info');
    }

    let total = fields.total;
    let invoiceId = null;
    if (payment) {
        total = payment.total;
        invoiceId = payment.id;
        if (fields.payment_type === 'CreditCard') {
            total *= 1.04;
        }
    } else {
        total = fields.total;
        const invoiceNumber = fields.invoice_number.toLowerCase();
        if (invoiceNumber.includes('pp-') && fields.payment_type === 'CreditCard') {
            total *= 1.04;
        }
    }

    dispatch({ type: types.SAVE_PAYMENT, payload: null });
    axios.post(apiUrl + '/payment/save', {
        invoice_number: fields.invoice_number,
        customer_id: fields.customer_id,
        total: total,
        original_total: fields.total,
        email: fields.email,
        payment_type: fields.payment_type,
        first_name: fields.first_name,
        last_name: fields.last_name,
        address1: fields.address1,
        address2: fields.address2,
        city: fields.city,
        state: fields.state,
        country: fields.country,
        zip: fields.zip,
        phone: fields.phone,
        invoice_type: fields.invoice_type,
        payment_token: data.token,
        last_four: data.last4,
        invoice_id: invoiceId,
        recaptcha_token: recaptchaToken,
    }, {})
        .then((response) => {
            dispatch({ type: types.SAVE_PAYMENT_SUCCESS, payload: response.data });

            if (response.data.status) {
                showNotification('Your payment was successful!', 'Thank you for your payment. We will be in contact with more details shortly', 'success', 6000);
                if (payment) {
                    dispatch(fetchPayment(invoiceToken));
                }
            } else {
                if (response.data.errorCode) {
                    if (response.data.errorCode === '01') {
                        showNotification('Invoice not found', "Oops! It seems the invoice you're trying to pay doesn't exist or the amount is incorrect. If you need further assistance, please contact our support team.", 'info', 6000);
                    } else if (response.data.errorCode === '02') {
                        showNotification('This invoice has already been paid', 'The payment for this invoice has already been processed', 'info', 5000);
                    } else if (response.data.errorCode === '03') {
                        showNotification('Invalid Captcha', 'The captcha is invalid or it has expired', 'info', 5000);
                    }
                } else {
                    const parts = response.data.message.split(':');
                    showNotification('Incorrect payment information', parts[1], 'warning');
                }
            }

            if (ref && ref != null && ref.current != null) {
                ref.current.reset();
            }
            dispatch(resetFields());
            dispatch(setRecaptchaToken(null));
        })
        .catch((error) => {
            if (ref && ref != null && ref.current != null) {
                ref.current.reset();
            }
            dispatch(resetFields());
            dispatch(setRecaptchaToken(null));
            dispatch(validateAuthInResponse(error, types.SAVE_PAYMENT_ERROR));
        });
}

export const setToken = (token) => (dispatch) => {
    dispatch({
        type: types.SET_TOKEN,
        payload: token,
    });
}

export const setInvoiceToken = (token) => (dispatch) => {
    dispatch({
        type: types.SET_INVOICE_TOKEN,
        payload: token,
    });
}

export const setPaymentType = (token) => (dispatch) => {
    dispatch({
        type: types.SET_PAYMENT_TYPE,
        payload: token,
    });
}

export const setRecaptchaToken = (token) => (dispatch) => {
    dispatch({
        type: types.SET_RECAPTCHA_TOKEN,
        payload: token,
    });
}

export const setField = (field, value) => (dispatch) => {
    dispatch({
        type: types.SET_FIELD,
        payload: { field, value },
    });
}

export const resetFields = () => (dispatch) => {
    dispatch({
        type: types.RESET_FIELDS,
        payload: null,
    });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null,
    });
}