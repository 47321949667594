import Immutable from 'immutable';
import * as types from './PqtDepartmentView.types';

const initialState = {
    id: null,
    fetching: {
        information: false,
        jobs: false,
    },
    data: {
        jobs: [],
    },
    pagination: {
        jobs: {
            totalElements: 0,
            activePage: 1,
            totalPages: 0,
            elementsPerPage: 15,
        },
    },
    sort: {
        column: 'due_date',
        type: 'asc',
    },
    information: {
        departments: [],
        salesReps: [],
        csrs: [],
    },
    searchFields: {
        jobNumber: '',
        date: '',
        csr: '',
        salesRep: '',
    },
    history: null,
    department: null,
    showJobs: 1,
    delete: {
        id: null,
        isDeleting: false,
    },
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_DEPARTMENT:
            return state.setIn(['fetching', 'department'], true).toJS();

        case types.FETCH_DEPARTMENT_SUCCESS:
            return state.setIn(['fetching', 'department'], false)
                .setIn(['department'], action.payload)
                .toJS();

        case types.FETCH_DEPARTMENT_ERROR:
            return state.setIn(['fetching', 'department'], false).toJS();

        case types.FETCH_INFORMATION:
            return state.setIn(['fetching', 'information'], true).toJS();

        case types.FETCH_INFORMATION_SUCCESS:
            return state.setIn(['fetching', 'information'], false)
                .setIn(['information', 'departments'], action.payload.departments)
                .setIn(['information', 'salesReps'], action.payload.salesReps)
                .setIn(['information', 'csrs'], action.payload.csrs)
                .toJS();

        case types.FETCH_INFORMATION_ERROR:
            return state.setIn(['fetching', 'information'], false).toJS();

        case types.FETCH_JOBS:
            return state.setIn(['fetching', 'jobs'], !action.payload).toJS();

        case types.FETCH_JOBS_SUCCESS:
            return state.setIn(['fetching', 'jobs'], false)
                .setIn(['data', 'jobs'], action.payload.elements)
                .setIn(['pagination', 'jobs', 'totalElements'], action.payload.totalElements)
                .setIn(['pagination', 'jobs', 'activePage'], action.payload.activePage)
                .setIn(['pagination', 'jobs', 'totalPages'], action.payload.totalPages)
                .setIn(['pagination', 'jobs', 'elementsPerPage'], action.payload.elementsPerPage)
                .toJS();

        case types.FETCH_JOBS_ERROR:
            return state.setIn(['fetching', 'jobs'], false).toJS();

        case types.SET_ID:
            return state.setIn(['id'], action.payload.id)
                .setIn(['showJobs'], action.payload.showJobs).toJS();

        case types.SET_SEARCH_FIELD:
            return state.setIn(['searchFields', action.payload.name], action.payload.value).toJS();

        case types.SET_SORT:
            return state.setIn(['sort'], action.payload).toJS();

        case types.SET_ACTIVE_PAGE:
            return state.setIn(['pagination', action.payload.name, 'activePage'], action.payload.value).toJS();

        case types.SET_SHOW_JOBS:
            return state.setIn(['showJobs'], action.payload).toJS();

        case types.DELETE_TRACKING:
            return state.setIn(['delete', 'isDeleting'], true).toJS();

        case types.DELETE_TRACKING_SUCCESS:
            return state.setIn(['delete', 'isDeleting'], false)
                .setIn(['delete', 'id'], null).toJS();

        case types.DELETE_TRACKING_ERROR:
            return state.setIn(['delete', 'isDeleting'], false).toJS();

        case types.SET_ID_FOR_DELETE:
            return state.setIn(['delete', 'id'], action.payload).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}