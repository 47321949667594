import React from 'react';
import FormPopover from './FormPopover';

const PocketFolderForm = React.memo((props) => {
    return (
        <div className="pocket-folder-form-container">
            <div className="main-columns">
                <div className="main-column1">
                    <div className="line1">
                        <div className="column1">
                            <div className="originals-field">
                                <b>Originals <span className="required">*</span>:</b>
                                <FormPopover
                                    code="FLAT-001"
                                    information={props.information}
                                />
                                <input type="number" value={props.fields.originals} onChange={props.handleSetField.bind(this, 'originals')} />
                            </div>
                            <div className="product-type-container">
                                <div><b>Product Type:</b></div>
                                <div className="product-type">{props.productTypeName}</div>
                                <div><b>Process Request:</b></div>
                                <div className="process-request">{props.processRequestName}</div>
                            </div>
                        </div>
                        <div className="column2">
                            <div className="title">
                                <b>Ink <span className="required">*</span>:</b>
                                <FormPopover
                                    code="FLAT-002"
                                    information={props.information}
                                />
                            </div>
                            <div className="subline1">
                                <div className="subtitle"><b>Front</b></div>
                                <div className="colors-field">
                                    <b>Colors:</b>
                                    <input type="text" value={props.fields.front_colors} onChange={props.handleSetField.bind(this, 'front_colors')} />
                                </div>
                                <div className="ink-field">
                                    <b>Ink <span className="required">*</span>:</b>
                                    <select value={props.fields.front_inks} onChange={props.handleSetField.bind(this, 'front_inks')}>
                                        <option value="">Select option</option>
                                        <option value="1">Black only</option>
                                        <option value="2">PMS only</option>
                                        <option value="3">CMYK</option>
                                        <option value="4">CMYK + PMS</option>
                                        <option value="5">CMYK + 2 PMS</option>
                                        <option value="6">CMYK + 3 PMS</option>
                                        <option value="7">CMYK + Coating</option>
                                        <option value="9">Black + PMS</option>
                                        <option value="10">PMS + PMS</option>
                                    </select>
                                </div>
                                <div className="pms-field">
                                    <b>PMS #:</b>
                                    <input type="text" value={props.fields.front_pms} onChange={props.handleSetField.bind(this, 'front_pms')} />
                                </div>
                                <div className="coating-field">
                                    <b>Coating:</b>
                                    <FormPopover
                                        code="FLAT-003"
                                        information={props.information}
                                    />
                                    <select value={props.fields.front_coating} onChange={props.handleSetField.bind(this, 'front_coating')}>
                                        <option value="">None</option>
                                        <option value="1">AQ = Aqueous - Gloss</option>
                                        <option value="2">AQ = Aqueous - Dull/Matte</option>
                                        <option value="3">UV = Ultra Violet Gloss</option>
                                        <option value="4">Varnish - Gloss</option>
                                        <option value="5">Varnish - Dull/Matte</option>
                                    </select>
                                </div>
                            </div>
                            <div className="subline2">
                                <div className="subtitle"><b>Back</b></div>
                                <div className="colors-field">
                                    <b>Colors:</b>
                                    <input type="text" value={props.fields.back_colors} onChange={props.handleSetField.bind(this, 'back_colors')} />
                                </div>
                                <div className="ink-field">
                                    <b>Ink <span className="required">*</span>:</b>
                                    <select value={props.fields.back_inks} onChange={props.handleSetField.bind(this, 'back_inks')}>
                                        <option value="">Select option</option>
                                        <option value="0">Blank</option>
                                        <option value="1">Black only</option>
                                        <option value="2">PMS only</option>
                                        <option value="3">CMYK</option>
                                        <option value="4">CMYK + PMS</option>
                                        <option value="5">CMYK + 2 PMS</option>
                                        <option value="6">CMYK + 3 PMS</option>
                                        <option value="7">CMYK + Coating</option>
                                        <option value="9">Black + PMS</option>
                                        <option value="10">PMS + PMS</option>
                                    </select>
                                </div>
                                <div className="pms-field">
                                    <b>PMS #:</b>
                                    <input type="text" value={props.fields.back_pms} onChange={props.handleSetField.bind(this, 'back_pms')} />
                                </div>
                                <div className="coating-field">
                                    <b>Coating:</b>
                                    <FormPopover
                                        code="FLAT-003"
                                        information={props.information}
                                    />
                                    <select value={props.fields.back_coating} onChange={props.handleSetField.bind(this, 'back_coating')}>
                                        <option value="">None</option>
                                        <option value="1">AQ = Aqueous - Gloss</option>
                                        <option value="2">AQ = Aqueous - Dull/Matte</option>
                                        <option value="3">UV = Ultra Violet Gloss</option>
                                        <option value="4">Varnish - Gloss</option>
                                        <option value="5">Varnish - Dull/Matte</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="line2">
                        <div className="column1">
                            <div className="title">
                                <b>Size:</b>
                                <FormPopover
                                    code="FLAT-004"
                                    information={props.information}
                                />
                            </div>
                            <div className="finish-width-field">
                                <b>Finish Width <span className="required">*</span>:</b>
                                <input type="number" value={props.fields.finish_width} onChange={props.handleSetField.bind(this, 'finish_width')} />
                            </div>
                            <div className="x-field">X</div>
                            <div className="finish-height-field">
                                <b>Finish Height <span className="required">*</span>:</b>
                                <input type="number" value={props.fields.finish_height} onChange={props.handleSetField.bind(this, 'finish_height')} />
                            </div>
                        </div>
                        <div className="column2">
                            <div className="title">
                                <b>Stock:</b>
                                <FormPopover
                                    code="FLAT-005"
                                    information={props.information}
                                />
                            </div>
                            <div className="paper-stock-field">
                                <b>Paper Stock <span className="required">*</span>:</b>
                                <select value={props.fields.paper_stock} onChange={props.handleSetField.bind(this, 'paper_stock')}>
                                    <option value="">Select option</option>
                                    {props.paperStockOptions}
                                </select>
                            </div>
                            <div className="paper-weight-field">
                                <b>Paper Weight <span className="required">*</span>:</b>
                                <select value={props.fields.paper_weight} onChange={props.handleSetField.bind(this, 'paper_weight')}>
                                    <option value="">Select option</option>
                                    {props.paperWeightOptions}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-column2">
                    <div className="field">
                        <b>Folder Type <span className="required">*</span>:</b>
                        <select value={props.fields.folder_type} onChange={props.handleSetField.bind(this, 'folder_type')}>
                            <option value="">Select option</option>
                            <option value="1">Standard folder</option>
                            <option value="2">Vertical folder</option>
                            <option value="3">Reinforced folder</option>
                            <option value="4">Custom</option>
                        </select>
                    </div>
                    <div className="field">
                        <b>Pocket Style <span className="required">*</span>:</b>
                        <select value={props.fields.pocket_style} onChange={props.handleSetField.bind(this, 'pocket_style')}>
                            <option value="">Select option</option>
                            <option value="1">Double horizontal</option>
                            <option value="2">Horizontal open left</option>
                            <option value="3">Horizontal oepn right</option>
                            <option value="4">Double vertical</option>
                            <option value="5">Vertical open right</option>
                            <option value="6">Vertical open left</option>
                        </select>
                    </div>
                    <div className="field">
                        <b>Card Slits <span className="required">*</span>:</b>
                        <select value={props.fields.card_slits} onChange={props.handleSetField.bind(this, 'card_slits')}>
                            <option value="">Select option</option>
                            <option value="1">No Card Slits</option>
                            <option value="2">Yes - Both Pocket</option>
                            <option value="3">Yes - Inside Right</option>
                            <option value="4">Yes - Inside Left</option>
                        </select>
                    </div>
                    <div className="field">
                        <b>Business Card Slit Style <span className="required">*</span>:</b>
                        <select value={props.fields.bc_card_slit_style} onChange={props.handleSetField.bind(this, 'bc_card_slit_style')}>
                            <option value="">None</option>
                            <option value="1">Slit: Four Corner</option>
                            <option value="2">Slit: Top &amp; Bottom</option>
                            <option value="3">Slit: Upper Left &amp; Lower Right</option>
                            <option value="4">Slit: Upper Right &amp; Lower Left</option>
                            <option value="5">Circle: Four Corner</option>
                            <option value="6">Circle: Top &amp; Bottom</option>
                            <option value="7">Circle: Left &amp; Right</option>
                            <option value="8">Circle: Upper Left &amp; Lower Right</option>
                            <option value="9">Circle: Upper Right &amp; Lower Left</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="line3">
                <div className="title"><b>Please describe your project in detail here:</b></div>
                <textarea
                    placeholder="Please let us know more about your project detail"
                    value={props.fields.pocket_folder_description}
                    onChange={props.handleSetField.bind(this, 'pocket_folder_description')}
                />
            </div>
            {props.buttonsContainer}
        </div>
    );
});

export default PocketFolderForm;
