import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//import PropTypes from 'prop-types';
import ChatRoom from './ChatRoom';
import ChatIcon from './ChatIcon';
import ChatList from './ChatList';
import ChatMembers from './ChatMembers';
import * as actions from './Chat.actions';
import * as selectors from './Chat.selectors';
import * as loginSelectors from './../Login/Login.selectors';
import * as pqtLayoutSelectors from './../PqtLayout/PqtLayout.selectors';
import * as pqtLayoutActions from './../PqtLayout/PqtLayout.actions';
import { stringifyEqualityCheck } from './../../utils';
import './Chat.scss';

const Chat = (props) => {
    /* State to props */
    const chatId = useSelector(selectors.getChatId);
    const messages = useSelector(selectors.getMessages, stringifyEqualityCheck);
    const response = useSelector(selectors.getResponse);
    const showIcon = useSelector(selectors.getShowIcon);
    const showRoom = useSelector(selectors.getShowRoom);
    const showMembers = useSelector(selectors.getShowMembers);
    const firstScrollDone = useSelector(selectors.getFirstScrollDone);
    const saving = useSelector(selectors.getSaving);
    const totalMessages = useSelector(selectors.getTotalMessages);
    const totalLoadedMessages = useSelector(selectors.getTotalLoadedMessages);
    const loadingMoreMessages = useSelector(selectors.getLoadingMoreMessages);
    const isLoading = useSelector(selectors.getIsLoading);
    const trackingsChats = useSelector(selectors.getTrackingsChats, stringifyEqualityCheck);
    const contactsChats = useSelector(selectors.getContactsChats, stringifyEqualityCheck);
    const unreadChats = useSelector(selectors.getUnreadChats, stringifyEqualityCheck);
    const isLoadingChats = useSelector(selectors.getIsLoadingChats);
    const type = useSelector(selectors.getType);
    const elementId = useSelector(selectors.getElementId);
    const usersIds = useSelector(selectors.getUsersIds, stringifyEqualityCheck);
    const chatTitle = useSelector(selectors.getChatTitle);
    const isLoadingChatMembers = useSelector(selectors.getIsLoadingChatMembers);
    const chatMembers = useSelector(selectors.getChatMembers, stringifyEqualityCheck);
    const hasUnreadMessages = useSelector(selectors.getHasUnreadMessages);
    const ws = useSelector(pqtLayoutSelectors.getWs, stringifyEqualityCheck);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const saveMessage = useCallback(() => dispatch(actions.saveMessage()), [dispatch]);
    const setResponse = useCallback((response) => dispatch(actions.setResponse(response)), [dispatch]);
    const setFirstScrollDone = useCallback((value) => dispatch(actions.setFirstScrollDone(value)), [dispatch]);
    const loadMoreMessages = useCallback(() => dispatch(actions.loadMoreMessages()), [dispatch]);
    const loadChats = useCallback((isWsUpdate) => dispatch(actions.loadChats(isWsUpdate)), [dispatch]);
    const loadChat = useCallback((type, options) => dispatch(actions.loadChat(type, options)), [dispatch]);
    const setShowField = useCallback((field, value) => dispatch(actions.setShowField(field, value)), [dispatch]);
    const setElementId = useCallback((id) => dispatch(actions.setElementId(id)), [dispatch]);
    const setType = useCallback((type) => dispatch(actions.setType(type)), [dispatch]);
    const addMessageObject = useCallback((messageObject) => dispatch(actions.addMessageObject(messageObject)), [dispatch]);
    const setUsersIds = useCallback((user1Id, user2Id) => dispatch(actions.setUsersIds(user1Id, user2Id)), [dispatch]);
    const setChatTitle = useCallback((title) => dispatch(actions.setChatTitle(title)), [dispatch]);
    const loadChatMembers = useCallback(() => dispatch(actions.loadChatMembers()), [dispatch]);
    const checkUnreadMessages = useCallback(() => dispatch(actions.checkUnreadMessages()), [dispatch]);
    const setChatUnreadMessages = useCallback((type, id) => dispatch(actions.setChatUnreadMessages(type, id)), [dispatch]);
    const setWs = useCallback((field, value) => dispatch(pqtLayoutActions.setWs(field, value)), [dispatch]);

    /* componentDidMount and componentWillUnmount */
    useEffect(() => {
        dispatch(actions.checkUnreadMessages());
        return () => {
            dispatch(actions.setFirstScrollDone(false));
            dispatch(actions.setShowField('room', false));
        }
    }, [dispatch]);

    let iconClassName = 'hide';
    let roomClassName = 'hide';
    let membersClassName = 'hide';
    if (showIcon) {
        iconClassName = '';
    }
    if (showRoom) {
        roomClassName = '';
    }
    if (showMembers) {
        membersClassName = '';
    }

    if (ws !== null && ws.lastMessage !== null) {
        const json = JSON.parse(ws.lastMessage);
        const message = json.message;
        if (ws.uuid !== json.uuid && json.type === 'new_chat_message') {
            checkUnreadMessages();
            if (showRoom) {
                loadChats(true);
            }

            if (parseInt(message.chat_id) === parseInt(chatId)) {
                addMessageObject({
                    id: message.id,
                    user_name: message.created_by_name,
                    user_id: message.created_by,
                    message: message.message,
                    created_at: message.created_at,
                    type: 2,
                });
            }

            setWs('lastMessage', null);
        }
    }

    let chatIconStyle = {};
    if (props.newVersion) {
        chatIconStyle = { top: 60 };
    }

    return (
        <div className="chat-container">
            <div className={'main-chat ' + roomClassName}>
                <div className={'chat-list ' + roomClassName}>
                    <ChatList
                        user={user}
                        trackingsChats={trackingsChats}
                        contactsChats={contactsChats}
                        unreadChats={unreadChats}
                        isLoadingChats={isLoadingChats}
                        type={type}
                        elementId={elementId}
                        usersIds={usersIds}
                        loadChats={loadChats}
                        setShowField={setShowField}
                        loadChat={loadChat}
                        setElementId={setElementId}
                        setUsersIds={setUsersIds}
                        setType={setType}
                        setFirstScrollDone={setFirstScrollDone}
                        setChatTitle={setChatTitle}
                        setChatUnreadMessages={setChatUnreadMessages}
                        checkUnreadMessages={checkUnreadMessages}
                    />
                </div>
                <div className={'chat-room ' + roomClassName}>
                    <ChatRoom
                        messages={messages}
                        response={response}
                        type={type}
                        chatTitle={chatTitle}
                        showRoom={showRoom}
                        showMembers={showMembers}
                        firstScrollDone={firstScrollDone}
                        saving={saving}
                        totalMessages={totalMessages}
                        totalLoadedMessages={totalLoadedMessages}
                        loadingMoreMessages={loadingMoreMessages}
                        isLoading={isLoading}
                        setResponse={setResponse}
                        saveMessage={saveMessage}
                        setFirstScrollDone={setFirstScrollDone}
                        loadMoreMessages={loadMoreMessages}
                        setShowField={setShowField}
                        loadChatMembers={loadChatMembers}
                        checkUnreadMessages={checkUnreadMessages}
                    />
                </div>
                <div className={'chat-members ' + membersClassName}>
                    <ChatMembers
                        showMembers={showMembers}
                        isLoadingChatMembers={isLoadingChatMembers}
                        chatMembers={chatMembers}
                        setShowField={setShowField}
                    />
                </div>
            </div>
            <div className={'chat-icon ' + iconClassName} style={chatIconStyle}>
                <ChatIcon
                    hasUnreadMessages={hasUnreadMessages}
                    loadChats={loadChats}
                    setShowField={setShowField}
                    setType={setType}
                />
            </div>
        </div>
    );
};

Chat.propTypes = {

}

export default Chat;
