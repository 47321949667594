import { createSelector } from 'reselect';

export const getComponentState = state => state.pqnPresses;

export const getFetchingElements = createSelector(
    getComponentState,
    (state) => state.fetchingElements
);
export const getFetchingElement = createSelector(
    getComponentState,
    (state) => state.fetchingElement
);
export const getElements = createSelector(
    getComponentState,
    (state) => state.elements
);
export const getPagination = createSelector(
    getComponentState,
    (state) => state.pagination
);
export const getSort = createSelector(
    getComponentState,
    (state) => state.sort
);
export const getIdForDelete = createSelector(
    getComponentState,
    (state) => state.delete.id
);
export const getIsDeleting = createSelector(
    getComponentState,
    (state) => state.delete.isDeleting
);
export const getSavingElement = createSelector(
    getComponentState,
    (state) => state.savingElement
);
export const getFilterFields = createSelector(
    getComponentState,
    (state) => state.filter.fields
);
export const getFields = createSelector(
    getComponentState,
    (state) => state.fields
);