import React from 'react';
import SheetfedForm from './SheetfedForm';
import EnvelopeForm from './EnvelopeForm';
import PlusCoverForm from './PlusCoverForm';
import SelfCoverForm from './SelfCoverForm';
import DigitalForm from './DigitalForm';
import LargeFormatForm from './LargeFormatForm';
import PocketFolderForm from './PocketFolderForm';
import NcrForm from './NcrForm';
import NotepadsForm from './NotepadsForm';
import MailOnlyForm from './MailOnlyForm';
import { isEstimator, showNotification } from './../../../utils';
import numeral from 'numeral';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import { Link } from 'react-router-dom';

const Form = React.memo((props) => {
    if (props.fetchingEstimate || props.fetchingInformation || props.fetchingRfq || props.fetchingMainPart) {
        return <div className="text-center"><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
    }

    const estimate = props.estimate;

    const handleSetField = (name, event) => {
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        }

        if (name === 'markup' && parseFloat(value) < 0) {
            return showNotification('Wrong Value', 'The markup cannot be negative', 'info');
        }

        if (name === 'markup' && props.user && parseInt(props.user.pqt_type) === 5 && value < props.minMarkup) {     // Sales Rep.
            return showNotification('Wrong Value', 'You can only increase the markup but not decrease it', 'info');
        }

        props.setEstimateChanged(true);
        props.setField(name, value);

        if (name === 'outside_commission') {
            if (parseFloat(value) > parseFloat(estimate.outside_markup)) {
                return showNotification('Wrong Value', 'The Commission percentage cannot be greater than the Markup percentage', 'info');
            }
        } else if (name === 'outside_markup') {
            if (parseFloat(estimate.outside_commission) > parseFloat(value)) {
                return showNotification('Wrong Value', 'The Commission percentage cannot be greater than the Markup percentage', 'info');
            }
        } else if (name === 'paper_stock') {
            props.setField('paper_name', '');
            props.setField('paper_weight', '');
            props.setField('parent_size', '');
            props.setField('paper_color', '');
        } else if (name === 'paper_name') {
            props.setField('paper_weight', '');
            props.setField('parent_size', '');
            props.setField('paper_color', '');
        } else if (name === 'paper_weight') {
            props.setField('parent_size', '');
            props.setField('paper_color', '');
        } else if (name === 'parent_size') {
            props.setField('paper_color', '');
        } else if (name === 'cover_paper_stock') {
            props.setField('cover_paper_name', '');
            props.setField('cover_paper_weight', '');
            props.setField('cover_parent_size', '');
            props.setField('cover_paper_color', '');
        } else if (name === 'cover_paper_name') {
            props.setField('cover_paper_weight', '');
            props.setField('cover_parent_size', '');
            props.setField('cover_paper_color', '');
        } else if (name === 'cover_paper_weight') {
            props.setField('cover_parent_size', '');
            props.setField('cover_paper_color', '');
        } else if (name === 'cover_parent_size') {
            props.setField('cover_paper_color', '');
        } else if (name === 'envelope_paper_type') {
            props.setField('envelope_paper_style', '');
            props.setField('envelope_size', '');
            props.setField('envelope_paper_weight', '');
        } else if (name === 'envelope_paper_style') {
            props.setField('envelope_size', '');
            props.setField('envelope_paper_weight', '');
        } else if (name === 'envelope_size') {
            props.setField('envelope_paper_weight', '');
        } else if (name === 'output_device') {
            props.setField('substrate', '');
            props.setField('paper_stock', '');
            props.setField('paper_name', '');
            props.setField('paper_weight', '');
            props.setField('parent_size', '');
            props.setField('paper_color', '');
            props.setField('run_method', '');
            props.setField('front_inks', '');
            props.setField('back_inks', '');
        } else if (name === 'cover_output_device') {
            props.setField('cover_paper_stock', '');
            props.setField('cover_paper_name', '');
            props.setField('cover_paper_weight', '');
            props.setField('cover_parent_size', '');
            props.setField('cover_paper_color', '');
            props.setField('cover_run_method', '');
            props.setField('cover_front_inks', '');
            props.setField('cover_back_inks', '');
        } else if (name === 'substrate') {
            props.setField('laminate', '');
        } else if (name === 'folder_type') {
            props.setField('pocket_style', '');
            props.setField('card_slits', '');
        } else if (name === 'pocket_style') {
            props.setField('card_slits', '');
        }

        if (parseInt(props.type) === 5) {   // Digital
            if (name === 'output_device') {
                props.setField('paper_stock', '');
                props.setField('paper_name', '');
                props.setField('paper_weight', '');
                props.setField('parent_size', '');
                props.setField('paper_color', '');
            }
        }

        if (name === 'selected_quantity') {
            props.addEstimateData(false);
        }
        if (name === 'pads') {
            props.setField('quantity', value * estimate.sheets_per_pad);
        }
        if (name === 'sheets_per_pad') {
            props.setField('quantity', value * estimate.pads);
        }
        if (name === 'ncr_sets') {
            props.setField('quantity', value * estimate.ncr_parts);
        }
        if (name === 'ncr_parts') {
            props.setField('quantity', value * estimate.ncr_sets);
        }

        if (['output_device', 'paper_stock', 'paper_weight', 'parent_size', 'run_size_width', 'run_size_height', 'finish_size_width',
            'finish_size_height', 'run_method', 'economy_filter', 'bleed', 'front_colors', 'front_passes', 'front_inks', 'front_pms',
            'front_coating', 'back_colors', 'back_passes', 'back_inks', 'back_pms', 'back_coating', 'in_sets_of',
            'binding_edge', 'page_count', 'cover_output_device', 'cover_paper_stock', 'cover_paper_weight', 'cover_parent_size',
            'cover_run_method', 'cover_economy_filter', 'cover_front_colors', 'cover_front_passes', 'cover_front_inks', 'cover_front_pms',
            'cover_front_coating', 'cover_back_colors', 'cover_back_passes', 'cover_back_inks', 'cover_back_pms', 'cover_back_coating',
            'substrate', 'unit', 'envelope_paper_type', 'envelope_paper_style', 'envelope_size', 'envelope_paper_weight', 'folder_type',
            'pocket_style', 'card_slits', 'bc_card_slit_style', 'laminate', 'flat_size_width', 'flat_size_height', 'publication_type',
            'pads', 'sheets_per_pad', 'ncr_sets', 'ncr_parts', 'use_chip_board', 'use_crash_number', 'quantity', 'quantity2', 'quantity3',
            'paper_name', 'paper_color', 'cover_paper_name', 'cover_paper_color', 'use_ganging', 'lock_imposition', 'lock_imposition_pages'].includes(name)
        ) {
            props.setDataChanged(true);
        }

        if (name === 'press_additional_sheets' || name === 'bindery_additional_sheets') {
            props.addEstimateData(true);

            if (parseFloat(value) < 0) {
                props.setField(name, 0);
            }
        }
    };

    let handleCancel = () => {
        props.history.push('/estimates');
    };

    // <option value="1">Print Only</option>
    // <option value="2">Mail Only</option>
    // <option value="3">Print and Mail</option>
    // <option value="4">Indoor</option>
    // <option value="5">Promotional Products</option>
    // <option value="6">Artwork Only</option>
    // <option value="7">Self-Cover</option>
    // <option value="8">Plus-Cover</option>
    // <option value="9">Outdoor</option>
    let processRequestOptions = [];
    if ([1, 3, 6, 7, 13, 15].includes(parseInt(estimate.product_type))) {
        processRequestOptions.push(<option value="1">Print Only</option>);
        processRequestOptions.push(<option value="3">Print and Mail</option>);
        processRequestOptions.push(<option value="7">Self-Cover</option>);
        processRequestOptions.push(<option value="8">Plus-Cover</option>);
    } else if ([18, 28].includes(parseInt(estimate.product_type))) {
        processRequestOptions.push(<option value="1">Print Only</option>);
        processRequestOptions.push(<option value="3">Print and Mail</option>);
    } else if ([26].includes(parseInt(estimate.product_type))) {
        processRequestOptions.push(<option value="4">Indoor</option>);
        processRequestOptions.push(<option value="9">Outdoor</option>);
    } else if ([27].includes(parseInt(estimate.product_type))) {
        processRequestOptions.push(<option value="5">Promotional Products</option>);
    } else {
        processRequestOptions.push(<option value="1">Print Only</option>);
        processRequestOptions.push(<option value="3">Print and Mail</option>);
    }

    let productTypesOptions = [];
    let selectedProductType = '';
    for (let i = 0; i < props.information.productTypes.length; i++) {
        productTypesOptions.push(<option value={props.information.productTypes[i].id}>{props.information.productTypes[i].name}</option>);

        if (parseInt(props.information.productTypes[i].id) === parseInt(estimate.product_type)) {
            selectedProductType = props.information.productTypes[i].name;
        }
    }

    /*********** Regular ************/
    const getPaperStockOptions = (outputDevice) => {
        // Komori 1040 (1), Komori 6/40 with Coater (2), Ryobi (3), JPress (18), Digital Ricoh (6)
        let paperCategories = [];
        let paperCategoriesIds = [];
        for (const paper of props.information.papers) {
            const categoryId = parseInt(paper.paper_category_id);
            if (parseInt(props.type) === 8) {
                if (paper.category_name === 'Carbonless' && !paperCategoriesIds.includes(categoryId)) {
                    paperCategoriesIds.push(categoryId);
                    paperCategories.push({ id: categoryId, name: paper.category_name });
                }
            } else {
                if (
                    (([1, 2, 3].includes(parseInt(outputDevice)) && parseInt(paper.sc_komori) === 1) ||
                        ([18].includes(parseInt(outputDevice)) && parseInt(paper.sc_jpress) === 1) ||
                        ([6].includes(parseInt(outputDevice)) && parseInt(paper.sc_digital) === 1)) &&
                    !paperCategoriesIds.includes(categoryId)
                ) {
                    paperCategoriesIds.push(categoryId);
                    paperCategories.push({ id: categoryId, name: paper.category_name });
                }
            }
        }
        let paperStockOptions = [];
        paperCategories.sort((a, b) => {
            if (a.name > b.name) {
                return 1;
            }
            if (a.name < b.name) {
                return -1;
            }
            return 0;
        });
        for (const paperCategory of paperCategories) {
            paperStockOptions.push(<option value={paperCategory.id}>{paperCategory.name}</option>);
        }
        return paperStockOptions;
    };

    const getPaperNamesOptions = (paperStockId) => {
        let paperNames = [];
        let paperNamesIds = [];
        for (const paper of props.information.papers) {
            const paperId = paper.paper_name;
            if (parseInt(paper.paper_category_id) === parseInt(paperStockId) && !paperNamesIds.includes(paperId)) {
                paperNamesIds.push(paperId);
                paperNames.push({ id: paperId, name: paper.paper_name });
            }
        }
        let paperNamesOptions = [];
        paperNames.sort((a, b) => {
            if (a.name > b.name) {
                return 1;
            }
            if (a.name < b.name) {
                return -1;
            }
            return 0;
        });
        for (const paperName of paperNames) {
            paperNamesOptions.push(<option value={paperName.id}>{paperName.name}</option>);
        }
        return paperNamesOptions;
    };

    const getPaperWeightOptions = (paperStockId, paperName) => {
        let paperWeights = [];
        let paperWeightsIds = [];
        for (const paper of props.information.papers) {
            const paperWeight = parseFloat(paper.basis_weight);
            if (
                parseInt(paper.paper_category_id) === parseInt(paperStockId) &&
                paper.paper_name === paperName &&
                !paperWeightsIds.includes(paperWeight)
            ) {
                paperWeightsIds.push(paperWeight);
                paperWeights.push({ id: paperWeight, name: paperWeight + '#' });
            }
        }
        let paperWeightOptions = [];
        paperWeights.sort((a, b) => {
            if (a.id > b.id) {
                return 1;
            }
            if (a.id < b.id) {
                return -1;
            }
            return 0;
        });
        for (const weight of paperWeights) {
            paperWeightOptions.push(<option value={weight.id}>{weight.name}</option>);
        }
        return paperWeightOptions;
    };

    const getParentSizeOptions = (paperStockId, paperName, paperWeight) => {
        let paperSizes = [];
        let paperSizesIds = [];
        for (const paper of props.information.papers) {
            const size = paper.width + 'x' + paper.length;

            if (
                parseInt(paper.paper_category_id) === parseInt(paperStockId) &&
                paper.paper_name === paperName &&
                parseFloat(paper.basis_weight) === parseFloat(paperWeight) &&
                !paperSizesIds.includes(size)
            ) {
                paperSizesIds.push(size);
                paperSizes.push({ id: size, name: size });
            }
        }
        let parentSizeOptions = [];
        paperSizes.sort((a, b) => {
            if (a.name > b.name) {
                return 1;
            }
            if (a.name < b.name) {
                return -1;
            }
            return 0;
        });
        for (const paperName of paperSizes) {
            parentSizeOptions.push(<option value={paperName.id}>{paperName.name}</option>);
        }
        return parentSizeOptions;
    };

    const getPaperColorOptions = (paperStockId, paperName, paperWeight, paperSize) => {
        let paperColors = [];
        let paperColorsIds = [];
        for (const paper of props.information.papers) {
            const size = paper.width + 'x' + paper.length;

            if (
                parseInt(paper.paper_category_id) === parseInt(paperStockId) &&
                paper.paper_name === paperName &&
                parseFloat(paper.basis_weight) === parseFloat(paperWeight) &&
                size === paperSize &&
                !paperColorsIds.includes(paper.color)
            ) {
                paperColorsIds.push(paper.color);
                paperColors.push({ id: paper.color, name: paper.color });
            }
        }
        let paperColorOptions = [];
        paperColors.sort((a, b) => {
            if (a.name > b.name) {
                return 1;
            }
            if (a.name < b.name) {
                return -1;
            }
            return 0;
        });
        for (const paperColor of paperColors) {
            paperColorOptions.push(<option value={paperColor.id}>{paperColor.name}</option>);
        }
        return paperColorOptions;
    };

    const paperStockOptions = getPaperStockOptions(estimate.output_device);
    const paperNamesOptions = getPaperNamesOptions(estimate.paper_stock);
    const paperWeightOptions = getPaperWeightOptions(estimate.paper_stock, estimate.paper_name);
    const parentSizeOptions = getParentSizeOptions(estimate.paper_stock, estimate.paper_name, estimate.paper_weight);
    const paperColorOptions = getPaperColorOptions(estimate.paper_stock, estimate.paper_name, estimate.paper_weight, estimate.parent_size);

    const coverPaperStockOptions = getPaperStockOptions(estimate.cover_output_device);
    const coverPaperNamesOptions = getPaperNamesOptions(estimate.cover_paper_stock);
    const coverPaperWeightOptions = getPaperWeightOptions(estimate.cover_paper_stock, estimate.cover_paper_name);
    const coverSizeOptions = getParentSizeOptions(estimate.cover_paper_stock, estimate.cover_paper_name, estimate.cover_paper_weight);
    const coverPaperColorOptions = getPaperColorOptions(estimate.cover_paper_stock, estimate.cover_paper_name, estimate.cover_paper_weight, estimate.cover_parent_size);

    const handleShowMailServicesModal = () => {
        props.setMailServicesQuantities();
        props.showMailServicesModal();
    };

    let readyToApprove = false;
    if (parseInt(estimate.status) === 4 && estimate.result != null) {
        readyToApprove = true;
    } else if (parseInt(estimate.status) === 4 && parseInt(props.estimateType) === 10) {
        readyToApprove = true;
    }

    let readyToJob = false;
    // if (parseInt(estimate.status) === 2 || parseInt(estimate.status) === 3) {
    if (parseInt(estimate.status) === 2) {
        readyToJob = true;
    }

    const getInks = (inks) => {
        if (parseInt(inks) === 0) {
            return 'Blank';
        } else if (parseInt(inks) === 1) {
            return 'Black only';
        } else if (parseInt(inks) === 2) {
            return 'PMS only';
        } else if (parseInt(inks) === 3) {
            return 'CMYK';
        } else if (parseInt(inks) === 4) {
            return 'CMYK + PMS';
        } else if (parseInt(inks) === 5) {
            return 'CMYK + 2 PMS';
        } else if (parseInt(inks) === 6) {
            return 'CMYK + 3 PMS';
        } else if (parseInt(inks) === 7) {
            return 'CMYK + Coating';
        } else if (parseInt(inks) === 8) {
            return 'Full Color';
        } else if (parseInt(inks) === 9) {
            return 'Black + PMS';
        } else if (parseInt(inks) === 10) {
            return 'PMS + PMS';
        }
        return '';
    };

    const getCoating = (coating) => {
        if (parseInt(coating) === 1) {
            return 'AQ = Aqueous - Gloss';
        } else if (parseInt(coating) === 2) {
            return 'AQ = Aqueous - Dull/Matte';
        } else if (parseInt(coating) === 3) {
            return 'UV = Ultra Violet Gloss';
        } else if (parseInt(coating) === 4) {
            return 'Varnish - Gloss';
        } else if (parseInt(coating) === 5) {
            return 'Varnish - Dull/Matte';
        }
        return 'None';
    };

    let salesRepId = null;
    let customerName = 'N/A';
    for (let i = 0; i < props.information.customers.length; i++) {
        if (parseInt(estimate.customer_id) === parseInt(props.information.customers[i].id)) {
            salesRepId = props.information.customers[i].sales_rep_id;
            customerName = props.information.customers[i].display_name;
            break;
        }
    }

    let salesRepName = 'N/A';
    if (salesRepId != null) {
        for (let i = 0; i < props.information.salesReps.length; i++) {
            if (parseInt(salesRepId) === parseInt(props.information.salesReps[i].id)) {
                salesRepName = props.information.salesReps[i].name;
                break;
            }
        }
    }

    let csrName = 'N/A';
    for (let i = 0; i < props.information.csrs.length; i++) {
        if (parseInt(estimate.csr_id) === parseInt(props.information.csrs[i].id)) {
            csrName = props.information.csrs[i].name;
            break;
        }
    }

    const renderAdditionalServices = () => {
        let deleteColumn = null;
        let chargeColumn = null;
        if (isEstimator(props.user)) {
            deleteColumn = <th></th>;
            chargeColumn = <th className="text-right">Charge</th>;
        }

        return (
            <table className="table table-sm table-hover table-striped">
                <thead>
                    <tr>
                        <th>Description</th>
                        <th className="text-right">Quantity</th>
                        {chargeColumn}
                        {deleteColumn}
                    </tr>
                </thead>
                <tbody>
                    {renderAdditionalServicesItems()}
                    {renderAccessories()}
                </tbody>
            </table>
        );
    };

    const renderAdditionalServicesItems = () => {
        if (props.selectedAdditionalServices.length === 0 && !props.showDeliveryCharge) {
            return (
                <tr>
                    <td colSpan="4">No data</td>
                </tr>
            );
        }

        let sortedItems = props.selectedAdditionalServices.sort((a, b) => {
            if (a.position < b.position) {
                return -1;
            } else if (a.position > b.position) {
                return 1;
            } else {
                return 0;
            }
        });

        let items = sortedItems.map((item) => {
            if ([1, 3, 4, 5, 7].includes(parseInt(props.type)) && parseInt(estimate.output_device) === 18) {     // JPress
                if (parseInt(item.id) === 7) {   // Inks
                    return null;
                }
            }

            let deleteColumn = null;
            let chargeColumn = null
            if (isEstimator(props.user)) {
                deleteColumn = (
                    <td className="actions">
                        <Tooltip title="Delete" position="bottom" arrow size="small" className="action-tooltip">
                            <i className="fas fa-trash" onClick={props.deleteAdditionalServicesItem.bind(this, item.id)} />
                        </Tooltip>
                    </td>
                );
                chargeColumn = <td className="text-right">{checkEstimating(numeral(item.total).format('$0,0.00'))}</td>;
            }

            return (
                <tr>
                    <td>{item.name}</td>
                    <td className="text-right">{checkEstimating(item.quantity)}</td>
                    {chargeColumn}
                    {deleteColumn}
                </tr>
            )
        });

        if (props.showDeliveryCharge) {
            const deleteDeliveryCharge = () => {
                props.setShowDeliveryCharge(false);
                props.setEstimateChanged(true);
            };

            let deleteColumn = null;
            if (isEstimator(props.user)) {
                deleteColumn = (
                    <td className="actions">
                        <Tooltip title="Delete" position="bottom" arrow size="small" className="action-tooltip">
                            <i className="fas fa-trash" onClick={deleteDeliveryCharge} />
                        </Tooltip>
                    </td>
                );
            }

            items.push(
                <tr>
                    <td>Delivery Charge</td>
                    <td className="text-right">{checkEstimating(props.deliveryCharge.quantity)}</td>
                    <td className="text-right">{checkEstimating(numeral(props.deliveryCharge.total).format('$0,0.00'))}</td>
                    {deleteColumn}
                </tr>
            );
        }

        return items;
    };

    const renderAccessories = () => {
        if (parseInt(estimate.type) !== 6) {
            return null;
        }

        let lfResult = null;
        if (props.result && props.result != null && props.result.results.length > 0) {
            lfResult = props.result.results[0];
        }

        if (lfResult == null) {
            return null;
        }

        let accessories = [];
        for (let i = 0; i < lfResult.accessories_information.accessories.length; i++) {
            const accessory = lfResult.accessories_information.accessories[i];
            accessories.push({
                name: accessory.name,
                quantity: estimate.quantity,
                total: accessory.total_cost,
            });
        }

        return accessories.map((item) => {
            let deleteColumn = null;
            let chargeColumn = null;
            if (isEstimator(props.user)) {
                deleteColumn = <td className="actions"></td>;
                chargeColumn = <td className="text-right">{checkEstimating(numeral(item.total).format('$0,0.00'))}</td>;
            }

            return (
                <tr>
                    <td>{item.name}</td>
                    <td className="text-right">{checkEstimating(numeral(item.quantity).format('0,0'))}</td>
                    {chargeColumn}
                    {deleteColumn}
                </tr>
            )
        });
    };

    const renderMailServicesItems = () => {
        if (props.selectedMailServices.length === 0) {
            return (
                <tr>
                    <td colSpan="5">No data</td>
                </tr>
            );
        }

        return props.selectedMailServices.map((item) => {
            let name = item.name;
            if (item.is_minimum) {
                name += ' (Min. Charge)';
            }

            let deleteColumn = null;
            if (isEstimator(props.user)) {
                deleteColumn = (
                    <td className="actions">
                        <Tooltip title="Delete" position="bottom" arrow size="small" className="action-tooltip">
                            <i className="fas fa-trash" onClick={props.deleteMailServicesItem.bind(this, item.id, item.type)} />
                        </Tooltip>
                    </td>
                );
            }

            return (
                <tr>
                    <td>{name}</td>
                    <td className="text-right">{numeral(item.quantity).format('0,0')}</td>
                    <td className="text-right">{numeral(item.total_price).format('$0,0.00')}</td>
                    {deleteColumn}
                </tr>
            )
        });
    };

    const renderMailServices = () => {
        if ([1].includes(parseInt(estimate.process_request))) {
            return null;
        }

        let picker = null;
        let deleteColumn = null;
        if (isEstimator(props.user)) {
            picker = <div className="picker" onClick={handleShowMailServicesModal}><i className="fas fa-server" /> Mailing Picker</div>;
            deleteColumn = <th></th>;
        }

        return (
            <div className="column3-line3">
                <div className="add-mailing-title">
                    <div>Mailing Services</div>
                    {picker}
                </div>
                <table className="table table-sm table-hover table-striped">
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th className="text-right">Quantity</th>
                            <th className="text-right">Charge</th>
                            {deleteColumn}
                        </tr>
                    </thead>
                    <tbody>{renderMailServicesItems()}</tbody>
                </table>
            </div>
        );
    };

    const checkEstimating = (element) => {
        if (props.estimating) {
            return <i className="fas fa-circle-notch fa-spin" />;
        }
        return element;
    };

    let salesRepOptions = [];
    for (let i = 0; i < props.information.salesReps.length; i++) {
        salesRepOptions.push(<option value={props.information.salesReps[i].id}>{props.information.salesReps[i].name}</option>);
    }

    let csrOptions = [];
    for (let i = 0; i < props.information.csrs.length; i++) {
        csrOptions.push(<option value={props.information.csrs[i].id}>{props.information.csrs[i].name}</option>);
    }

    let filesContainer = null;
    if (props.rfq !== null) {
        const files = props.rfq.files != null ? JSON.parse(props.rfq.files) : null;
        if (files != null && files.length > 0) {
            filesContainer = (
                <div className="files-container">
                    <div><b>Files:</b></div>
                    {files.map((file) => {
                        return (
                            <div className="file">
                                <a href={file.url} target="_blank" rel="noopener noreferrer">{file.name}</a>
                            </div>
                        );
                    })}
                </div>
            )
        }
    }

    let form = null;
    if (parseInt(props.type) === 1) {
        form = (
            <SheetfedForm
                {...props}
                processRequestOptions={processRequestOptions}
                productTypesOptions={productTypesOptions}
                paperStockOptions={paperStockOptions}
                paperNamesOptions={paperNamesOptions}
                paperWeightOptions={paperWeightOptions}
                parentSizeOptions={parentSizeOptions}
                paperColorOptions={paperColorOptions}
                readyToApprove={readyToApprove}
                readyToJob={readyToJob}
                selectedProductType={selectedProductType}
                setField={props.setField}
                customerName={customerName}
                salesRepName={salesRepName}
                csrName={csrName}
                csrOptions={csrOptions}
                salesRepOptions={salesRepOptions}
                filesContainer={filesContainer}
                handleSetField={handleSetField}
                handleCancel={handleCancel}
                getInks={getInks}
                getCoating={getCoating}
                renderAdditionalServices={renderAdditionalServices}
                renderMailServices={renderMailServices}
                checkEstimating={checkEstimating}
            />
        );
    } else if (parseInt(props.type) === 2) {
        form = (
            <EnvelopeForm
                {...props}
                processRequestOptions={processRequestOptions}
                productTypesOptions={productTypesOptions}
                paperStockOptions={paperStockOptions}
                paperNamesOptions={paperNamesOptions}
                paperWeightOptions={paperWeightOptions}
                parentSizeOptions={parentSizeOptions}
                paperColorOptions={paperColorOptions}
                readyToApprove={readyToApprove}
                readyToJob={readyToJob}
                selectedProductType={selectedProductType}
                setField={props.setField}
                customerName={customerName}
                salesRepName={salesRepName}
                csrName={csrName}
                csrOptions={csrOptions}
                salesRepOptions={salesRepOptions}
                filesContainer={filesContainer}
                handleSetField={handleSetField}
                handleCancel={handleCancel}
                getInks={getInks}
                getCoating={getCoating}
                renderAdditionalServices={renderAdditionalServices}
                renderMailServices={renderMailServices}
                checkEstimating={checkEstimating}
            />
        );
    } else if (parseInt(props.type) === 3) {
        form = (
            <PlusCoverForm
                {...props}
                processRequestOptions={processRequestOptions}
                productTypesOptions={productTypesOptions}
                paperStockOptions={paperStockOptions}
                paperNamesOptions={paperNamesOptions}
                paperWeightOptions={paperWeightOptions}
                parentSizeOptions={parentSizeOptions}
                paperColorOptions={paperColorOptions}
                coverPaperStockOptions={coverPaperStockOptions}
                coverPaperNamesOptions={coverPaperNamesOptions}
                coverPaperWeightOptions={coverPaperWeightOptions}
                coverSizeOptions={coverSizeOptions}
                coverPaperColorOptions={coverPaperColorOptions}
                readyToApprove={readyToApprove}
                readyToJob={readyToJob}
                selectedProductType={selectedProductType}
                setField={props.setField}
                customerName={customerName}
                salesRepName={salesRepName}
                csrName={csrName}
                csrOptions={csrOptions}
                salesRepOptions={salesRepOptions}
                filesContainer={filesContainer}
                handleSetField={handleSetField}
                handleCancel={handleCancel}
                getInks={getInks}
                getCoating={getCoating}
                renderAdditionalServices={renderAdditionalServices}
                renderMailServices={renderMailServices}
                checkEstimating={checkEstimating}
            />
        );
    } else if (parseInt(props.type) === 4) {
        form = (
            <SelfCoverForm
                {...props}
                processRequestOptions={processRequestOptions}
                productTypesOptions={productTypesOptions}
                paperStockOptions={paperStockOptions}
                paperNamesOptions={paperNamesOptions}
                paperWeightOptions={paperWeightOptions}
                parentSizeOptions={parentSizeOptions}
                paperColorOptions={paperColorOptions}
                readyToApprove={readyToApprove}
                readyToJob={readyToJob}
                selectedProductType={selectedProductType}
                setField={props.setField}
                customerName={customerName}
                salesRepName={salesRepName}
                csrName={csrName}
                csrOptions={csrOptions}
                salesRepOptions={salesRepOptions}
                filesContainer={filesContainer}
                handleSetField={handleSetField}
                handleCancel={handleCancel}
                getInks={getInks}
                getCoating={getCoating}
                renderAdditionalServices={renderAdditionalServices}
                renderMailServices={renderMailServices}
                checkEstimating={checkEstimating}
            />
        );
    } else if (parseInt(props.type) === 5) {
        form = (
            <DigitalForm
                {...props}
                processRequestOptions={processRequestOptions}
                productTypesOptions={productTypesOptions}
                paperStockOptions={paperStockOptions}
                paperNamesOptions={paperNamesOptions}
                paperWeightOptions={paperWeightOptions}
                parentSizeOptions={parentSizeOptions}
                paperColorOptions={paperColorOptions}
                readyToApprove={readyToApprove}
                readyToJob={readyToJob}
                selectedProductType={selectedProductType}
                setField={props.setField}
                customerName={customerName}
                salesRepName={salesRepName}
                csrName={csrName}
                csrOptions={csrOptions}
                salesRepOptions={salesRepOptions}
                filesContainer={filesContainer}
                handleSetField={handleSetField}
                handleCancel={handleCancel}
                getInks={getInks}
                getCoating={getCoating}
                renderAdditionalServices={renderAdditionalServices}
                renderMailServices={renderMailServices}
                checkEstimating={checkEstimating}
            />
        );
    } else if (parseInt(props.type) === 6) {
        form = (
            <LargeFormatForm
                {...props}
                processRequestOptions={processRequestOptions}
                productTypesOptions={productTypesOptions}
                paperStockOptions={paperStockOptions}
                paperNamesOptions={paperNamesOptions}
                paperWeightOptions={paperWeightOptions}
                parentSizeOptions={parentSizeOptions}
                paperColorOptions={paperColorOptions}
                readyToApprove={readyToApprove}
                readyToJob={readyToJob}
                selectedProductType={selectedProductType}
                setField={props.setField}
                customerName={customerName}
                salesRepName={salesRepName}
                csrName={csrName}
                csrOptions={csrOptions}
                salesRepOptions={salesRepOptions}
                filesContainer={filesContainer}
                handleSetField={handleSetField}
                handleCancel={handleCancel}
                getInks={getInks}
                getCoating={getCoating}
                renderAdditionalServices={renderAdditionalServices}
                renderMailServices={renderMailServices}
                checkEstimating={checkEstimating}
            />
        );
    } else if (parseInt(props.type) === 7) {
        form = (
            <PocketFolderForm
                {...props}
                processRequestOptions={processRequestOptions}
                productTypesOptions={productTypesOptions}
                paperStockOptions={paperStockOptions}
                paperNamesOptions={paperNamesOptions}
                paperWeightOptions={paperWeightOptions}
                parentSizeOptions={parentSizeOptions}
                paperColorOptions={paperColorOptions}
                readyToApprove={readyToApprove}
                readyToJob={readyToJob}
                selectedProductType={selectedProductType}
                setField={props.setField}
                customerName={customerName}
                salesRepName={salesRepName}
                csrName={csrName}
                csrOptions={csrOptions}
                salesRepOptions={salesRepOptions}
                filesContainer={filesContainer}
                handleSetField={handleSetField}
                handleCancel={handleCancel}
                getInks={getInks}
                getCoating={getCoating}
                renderAdditionalServices={renderAdditionalServices}
                renderMailServices={renderMailServices}
                checkEstimating={checkEstimating}
            />
        );
    } else if (parseInt(props.type) === 8) {
        form = (
            <NcrForm
                {...props}
                processRequestOptions={processRequestOptions}
                productTypesOptions={productTypesOptions}
                paperStockOptions={paperStockOptions}
                paperNamesOptions={paperNamesOptions}
                paperWeightOptions={paperWeightOptions}
                parentSizeOptions={parentSizeOptions}
                paperColorOptions={paperColorOptions}
                readyToApprove={readyToApprove}
                readyToJob={readyToJob}
                selectedProductType={selectedProductType}
                setField={props.setField}
                customerName={customerName}
                salesRepName={salesRepName}
                csrName={csrName}
                csrOptions={csrOptions}
                salesRepOptions={salesRepOptions}
                filesContainer={filesContainer}
                handleSetField={handleSetField}
                handleCancel={handleCancel}
                getInks={getInks}
                getCoating={getCoating}
                renderAdditionalServices={renderAdditionalServices}
                renderMailServices={renderMailServices}
                checkEstimating={checkEstimating}
            />
        );
    } else if (parseInt(props.type) === 9) {
        form = (
            <NotepadsForm
                {...props}
                processRequestOptions={processRequestOptions}
                productTypesOptions={productTypesOptions}
                paperStockOptions={paperStockOptions}
                paperNamesOptions={paperNamesOptions}
                paperWeightOptions={paperWeightOptions}
                parentSizeOptions={parentSizeOptions}
                paperColorOptions={paperColorOptions}
                readyToApprove={readyToApprove}
                readyToJob={readyToJob}
                selectedProductType={selectedProductType}
                setField={props.setField}
                customerName={customerName}
                salesRepName={salesRepName}
                csrName={csrName}
                csrOptions={csrOptions}
                salesRepOptions={salesRepOptions}
                filesContainer={filesContainer}
                handleSetField={handleSetField}
                handleCancel={handleCancel}
                getInks={getInks}
                getCoating={getCoating}
                renderAdditionalServices={renderAdditionalServices}
                renderMailServices={renderMailServices}
                checkEstimating={checkEstimating}
            />
        );
    } else if (parseInt(props.type) === 10) {
        form = (
            <MailOnlyForm
                {...props}
                processRequestOptions={processRequestOptions}
                productTypesOptions={productTypesOptions}
                paperStockOptions={paperStockOptions}
                paperNamesOptions={paperNamesOptions}
                paperWeightOptions={paperWeightOptions}
                parentSizeOptions={parentSizeOptions}
                paperColorOptions={paperColorOptions}
                readyToApprove={readyToApprove}
                readyToJob={readyToJob}
                selectedProductType={selectedProductType}
                setField={props.setField}
                customerName={customerName}
                salesRepName={salesRepName}
                csrName={csrName}
                csrOptions={csrOptions}
                salesRepOptions={salesRepOptions}
                filesContainer={filesContainer}
                handleSetField={handleSetField}
                handleCancel={handleCancel}
                getInks={getInks}
                getCoating={getCoating}
                renderAdditionalServices={renderAdditionalServices}
                renderMailServices={renderMailServices}
                checkEstimating={checkEstimating}
            />
        );
    }

    let partsInfoContainer = null;
    if (props.mainPart != null) {
        let partNumber = parseInt(estimate.part_number);
        let partsTotal = parseInt(estimate.parts_total);
        if (props.history.location.pathname.includes('/add-part') && props.mainPart.parts_total == null) {
            partsTotal = 2;
            partNumber = partsTotal;
        } else if (props.history.location.pathname.includes('/add-part') && props.mainPart.parts_total != null) {
            partsTotal = parseInt(props.mainPart.parts_total) + 1;
            partNumber = partsTotal;
        }

        let prevButton = null;
        let nextButton = null;
        if (estimate.prev_part_id && estimate.prev_part_id != null) {
            prevButton = (
                <Link to={'/estimate/' + estimate.prev_part_type + '/' + estimate.prev_part_id}>
                    <button><i className="fas fa-chevron-left"></i> Previous Part</button>
                </Link>
            );
        }
        if (estimate.next_part_id && estimate.next_part_id != null) {
            nextButton = (
                <Link to={'/estimate/' + estimate.next_part_type + '/' + estimate.next_part_id}>
                    <button>Next Part <i className="fas fa-chevron-right"></i></button>
                </Link>
            );
        }

        const handleCheckUsePart = (event) => {
            props.checkUsePart(event.target.checked ? 1 : 0);
        };

        let convertTicketField = null;
        if (estimate.id) {
            convertTicketField = (
                <div className="field convert-checkbox">
                    <input
                        type="checkbox"
                        checked={estimate.use_part}
                        onClick={handleCheckUsePart}
                        disabled={parseInt(estimate.status) === 3}
                    /> Convert into a Ticket
                </div>
            );
        }

        partsInfoContainer = (
            <div className="parts-info-container">
                <div className="fields">
                    <div className="field">
                        <div className="label">Main Part ID:</div>
                        <div className="value">
                            <Link to={'/estimate/' + props.mainPart.type + '/' + props.mainPartId}>{props.mainPartId}</Link>
                        </div>
                    </div>
                    <div className="field">
                        <div className="label">Part #:</div>
                        <div className="value">{partNumber} of {partsTotal}</div>
                    </div>
                    {convertTicketField}
                </div>
                <div className="parts-buttons">
                    {prevButton}
                    {nextButton}
                </div>
            </div>
        );
    }

    return (
        <div className="form-container">
            {partsInfoContainer}
            <div className="content">
                {form}
            </div>
        </div>
    );
});

export default Form;
