import React from 'react';
import { Dialog } from './../../common/dialog';
import { useDropzone } from 'react-dropzone';
import moment from 'moment-timezone';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';

const FormModal = React.memo((props) => {
    const {
        // acceptedFiles,
        // fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        multiple: false,
        accept: '.xlsx',
        onDrop: (files) => {
            props.setFile(files[0]);
        }
    });

    const hideHandler = () => {
        props.hideFormModal();
        props.clearForm();
    };

    const uploadHandler = () => {
        props.uploadFile();
    };

    let selectedFile = null;
    if (props.file != null) {
        selectedFile = (
            <div className="selected-file">
                <b>Selected File:</b> {props.file.name}
            </div>
        );
    }

    const handleSetField = (name, event) => {
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked;
        }

        props.setField(name, value);
    };

    const setDateFieldHandler = (name, event) => {
        let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');
        props.setField(name, value);
    };

    let label = 'Submit';
    if (props.uploadingFile) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    let content = <div className="payroll-form-container text-center"><i className="fas fa-circle-notch fa-spin" /> Loading Data...</div>
    if (!props.fetchingReport) {
        content = (
            <div className="payroll-form-container">
                <h1>Payroll Report</h1>
                <div className="fields">
                    <div className="date-field">
                        <div>Payroll Date <span className="required">*</span>:</div>
                        <DayPickerInput
                            formatDate={formatDate}
                            parseDate={parseDate}
                            placeholder=""
                            className="date-picker-field"
                            onDayChange={setDateFieldHandler.bind(this, 'date')}
                            value={props.fields.date == null || props.fields.date === '0000-00-00' ? '' : moment(props.fields.date).format('MM/DD/YYYY')}
                        />
                    </div>
                    <div className="title-field">
                        <div>Title <span className="required">*</span>:</div>
                        <input type="text" value={props.fields.title} onChange={handleSetField.bind(this, 'title')} />
                    </div>
                    <div className="upload-payroll-container">
                        Upload Payroll Spreadsheet <span className="required">*</span>:
                        <div className="select-file-container">
                            <div {...getRootProps({ className: 'dropzone-container' })}>
                                <input {...getInputProps()} />
                                Click here to select file
                            </div>
                            {selectedFile}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Cancel</button>,
        <button key="upload-button" className="btn btn-light btn-sm action-button" onClick={uploadHandler} disabled={props.uploadingFile}>{label}</button>
    ];
    return (
        <Dialog
            name="payroll-form-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="payroll-form-dashboard-dialog"
        >
            {content}
        </Dialog>
    );
});

export default FormModal;
