import React from 'react';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import PqtPagination from './../../PqtPagination';
import { Link } from 'react-router-dom';
import { dateToTimezone } from './../../../utils';
import moment from 'moment-timezone';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';

const Requests = React.memo((props) => {
    const confirmHandler = (id) => {
        props.showDeleteModal();
        props.setIdForDelete(id);
    }

    const renderRequests = () => {
        if (props.fetchingRequests) {
            return (
                <tr>
                    <td colSpan="9"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.requests.length === 0) {
            return (
                <tr>
                    <td colSpan="9">No Results</td>
                </tr>
            );
        }

        return props.requests.map((request, index) => {
            let createdDate = '-';
            if (request.created_at != null) {
                createdDate = dateToTimezone(request.created_at, 'MM/DD/YYYY');
            }

            let status = null;
            if (parseInt(request.status) === 1) {
                status = 'Draft';
            } else if (parseInt(request.status) === 2) {
                status = 'Submitted';
            } else if (parseInt(request.status) === 3) {
                status = 'Approved';
            } else if (parseInt(request.status) === 4) {
                status = 'Converted to SC Job';
            } else if (parseInt(request.status) === 5) {
                status = 'Estimated';
            }

            return (
                <tr key={'request-' + index}>
                    <td>{request.id}</td>
                    <td>{createdDate}</td>
                    <td>{request.customer_name}</td>
                    <td>{request.sales_rep_name}</td>
                    <td>{request.csr_name}</td>
                    <td>{request.description_title}</td>
                    <td>{status}</td>
                    <td>{request.created_by_name}</td>
                    <td>
                        <div className="actions">
                            <Tooltip title="Edit Request" position="bottom" arrow size="small" className="action-tooltip">
                                <Link to={'/request-for-quote/' + request.id}><i className="fas fa-edit" /></Link>
                            </Tooltip>
                            <Tooltip title="Delete Request" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-trash" onClick={confirmHandler.bind(this, request.id)} />
                            </Tooltip>
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const setSortHandler = (field, value) => {
        props.setSort(field, value);
    };

    const renderHeader = (name, column, className) => {
        let sortFunc = setSortHandler.bind(this, column, 'asc');
        let sortIcon = <i className="fas fa-sort" />;
        if (props.sort.column === column) {
            if (props.sort.type === 'asc') {
                sortFunc = setSortHandler.bind(this, column, 'desc');
                sortIcon = <i className="fas fa-sort-up" />;
            } else {
                sortFunc = setSortHandler.bind(this, column, 'asc');
                sortIcon = <i className="fas fa-sort-down" />;
            }
        }

        return <th className={className} onClick={sortFunc}>{name} {sortIcon}</th>;
    };

    const handleChangeFilter = (name, fetch, event) => {
        props.changeFilter(name, event.target.value, fetch);
    };

    const handleKey = (event) => {
        if (event.key === 'Enter') {
            props.fetchRequests();
        }
    };

    const renderSearchField = (name) => {
        let value = null;
        for (let i = 0; i < props.filerFields.length; i++) {
            if (props.filerFields[i].name === name) {
                value = props.filerFields[i].value;
            }
        }

        return (
            <th className="search-th">
                <input
                    type="text"
                    name={name}
                    className="search-input"
                    value={value}
                    onKeyPress={handleKey}
                    onChange={handleChangeFilter.bind(this, name, false)}
                />
            </th>
        );
    };

    const setDateFieldHandler = (name, event) => {
        let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');
        props.changeFilter(name, value, true);
    };

    const renderSearchDateField = (name) => {
        let value = null;
        for (let i = 0; i < props.filerFields.length; i++) {
            if (props.filerFields[i].name === name) {
                value = props.filerFields[i].value;
            }
        }

        return (
            <th className="search-th">
                <DayPickerInput
                    formatDate={formatDate}
                    parseDate={parseDate}
                    placeholder=""
                    className="date-picker-field"
                    onDayChange={setDateFieldHandler.bind(this, name)}
                    value={value == null || value === '0000-00-00' ? '' : moment(value).format('MM/DD/YYYY')}
                />
            </th>
        )
    };

    const handleNewRequest = () => {
        props.history.push('/request-for-quote');
    };

    return (
        <div className="requests-container">
            <div className="actions-container">
                <button className="new-request-button" onClick={handleNewRequest}><i className="fas fa-plus-square" /> New Request</button>
            </div>
            <div className="table-container">
                <table className="table table-sm table-hover table-striped">
                    <thead>
                        <tr>
                            {renderHeader('No.', 'id', 'th-id')}
                            {renderHeader('Created At', 'created_at', 'th-date')}
                            {renderHeader('Customer', 'customer_name', 'th-csr')}
                            {renderHeader('Sales Rep', 'sales_rep_name', 'th-csr')}
                            {renderHeader('CSR', 'csr_name', 'th-csr')}
                            {renderHeader('Description', 'description_title', 'th-description')}
                            {renderHeader('Status', 'status', 'th-status')}
                            {renderHeader('Created By', 'created_by_name', 'th-created-by')}
                            <th style={{ width: 90 }}></th>
                        </tr>
                        <tr>
                            {renderSearchField('id')}
                            {renderSearchDateField('created_at')}
                            {renderSearchField('customer_name')}
                            {renderSearchField('sales_rep_name')}
                            {renderSearchField('csr_name')}
                            {renderSearchField('description_title')}
                            {renderSearchField('status')}
                            {renderSearchField('created_by_name')}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderRequests()}
                    </tbody>
                </table>
                <PqtPagination
                    pagination={props.pagination}
                    setActivePage={props.setActivePage}
                />
            </div>
        </div>
    );
});

export default Requests;
