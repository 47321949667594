import React from 'react';
import { Dialog } from './../../common/dialog';

const ConfirmSubmitModal = React.memo((props) => {
    const hideHandler = () => {
        props.hideConfirmSubmitModal();
    };

    const confirmHandler = () => {
        props.submitRequest();
    };

    let label = 'Submit';
    if (props.submitting) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Cancel</button>,
        <button key="confirm-button" className="btn btn-light btn-sm action-button" onClick={confirmHandler} disabled={props.submitting}>{label}</button>
    ];
    return (
        <Dialog
            name="confirm-submit-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="confirm-submit-dialog"
        >
            <div className="confirm-submit-container">
                <h1>Confirm Submit</h1>
                <p>Confirm that you want to Submit this estimate.</p>
            </div>
        </Dialog >
    );
});

export default ConfirmSubmitModal;
