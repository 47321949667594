import React, { useState } from 'react';
import { Dialog } from './../../common/dialog';
import { showNotification } from './../../../utils';

const PushModal = React.memo((props) => {
    const [option, setOption] = useState('');

    const handleQBPush = () => {
        if (option === '') {
            return showNotification('Select an option', 'You need to select an option before to continue', 'info');
        }

        props.qBPush(option);
    };

    let label = 'Push';
    if (props.isPushing) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    let vendorEmailField = null;
    if (parseInt(option) === 1 || parseInt(option) === 2) {
        vendorEmailField = (
            <div className="vendor-email-field">
                Vendor Email:
                <input
                    type="text"
                    placeholder="Separate emails with commas"
                    value={props.vendorEmail}
                    onChange={(event) => props.setVendorEmail(event.target.value)}
                />
            </div>
        );
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={props.hidePushModal}>Cancel</button>,
        <button key="delete-button" className="btn btn-light btn-sm action-button" onClick={handleQBPush} disabled={props.isPushing}>{label}</button>
    ];
    return (
        <Dialog
            name="push-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="push-dialog"
        >
            <div className="push-container">
                <h1>Push Purchase Order to QuickBooks</h1>
                <p>Select an option to continue:</p>
                <select value={option} onChange={(event) => setOption(event.target.value)}>
                    <option value="">Select option</option>
                    <option value="1">Send Purchase Order to the Vendor and Push it to QB</option>
                    {/* <option value="2">Send Purchase Order to the Vendor only</option> */}
                    <option value="3">Push Purchase Order to QB only</option>
                </select>
                {vendorEmailField}
            </div>
        </Dialog >
    );
});

export default PushModal;
