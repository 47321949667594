import React from 'react';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import PqtPagination from './../../PqtPagination';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import { dateToTimezone } from './../../../utils';

const Table = React.memo((props) => {
    const renderTable = () => {
        if (props.fetchingJobs) {
            return (
                <tr>
                    <td colSpan="17"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.jobs.length === 0) {
            return (
                <tr>
                    <td colSpan="17">No Results</td>
                </tr>
            );
        }

        return props.jobs.map((job, index) => {
            let datePrepared = '-';
            if (job.date_prepared != null) {
                datePrepared = dateToTimezone(job.date_prepared, 'MM/DD/YYYY');
            }

            let dpPiecesCount = 'N/A';
            if (job.dp_pieces_count && job.dp_pieces_count != null) {
                dpPiecesCount = numeral(job.dp_pieces_count).format('0,0');
            }

            let paPiecesCount = 'N/A';
            if (job.pa_pieces_count && job.pa_pieces_count != null) {
                paPiecesCount = numeral(job.pa_pieces_count).format('0,0');
            }

            return (
                <tr key={'job-' + index}>
                    <td><Link to={'/tracking/' + job.pqt_tracking_id}>{job.pqt_tracking_id}</Link></td>
                    <td>{job.job_description}</td>
                    <td className="text-right">{dpPiecesCount}</td>
                    <td className="text-right">{paPiecesCount}</td>
                    <td className="text-right">{datePrepared}</td>
                    <td className="text-right">{job.time_prepared}</td>
                    <td>
                        <div className="actions">
                            <Tooltip title="View Information" position="bottom" arrow size="small" className="action-tooltip">
                                <Link to={'/iv-job/' + job.pqt_tracking_id}><i className="fas fa-info-circle" /></Link>
                            </Tooltip>
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const setSortHandler = (field, value) => {
        props.setSort(field, value);
    };

    const renderHeader = (name, column, className) => {
        let sortFunc = setSortHandler.bind(this, column, 'asc');
        let sortIcon = <i className="fas fa-sort" />;
        if (props.sort.column === column) {
            if (props.sort.type === 'asc') {
                sortFunc = setSortHandler.bind(this, column, 'desc');
                sortIcon = <i className="fas fa-sort-up" />;
            } else {
                sortFunc = setSortHandler.bind(this, column, 'asc');
                sortIcon = <i className="fas fa-sort-down" />;
            }
        }

        return <th className={className} onClick={sortFunc}>{name} {sortIcon}</th>;
    };

    const handleChangeFilter = (name, fetch, event) => {
        props.changeFilter(name, event.target.value, fetch);
    };

    const handleKey = (event) => {
        if (event.key === 'Enter') {
            props.fetchEstimates();
        }
    };

    const renderSearchField = (name) => {
        let value = null;
        for (let i = 0; i < props.filterFields.length; i++) {
            if (props.filterFields[i].name === name) {
                value = props.filterFields[i].value;
            }
        }

        return (
            <th className="search-th">
                <input
                    type="text"
                    name={name}
                    className="search-input"
                    value={value}
                    onKeyPress={handleKey}
                    onChange={handleChangeFilter.bind(this, name, false)}
                />
            </th>
        );
    };

    // const renderSelectField = (name, options) => {
    //     let value = null;
    //     for (let i = 0; i < props.filterFields.length; i++) {
    //         if (props.filterFields[i].name === name) {
    //             value = props.filterFields[i].value;
    //         }
    //     }

    //     return (
    //         <th className="search-th">
    //             <select name={name} className="search-input" value={value} onChange={handleChangeFilter.bind(this, name, true)}>
    //                 {options.map((option) => {
    //                     return <option value={option.value}>{option.label}</option>
    //                 })}
    //             </select>
    //         </th>
    //     );
    // };

    return (
        <div className="jobs-table-container">
            <div className="table-container">
                <table className="table table-sm table-hover table-striped">
                    <thead>
                        <tr>
                            {renderHeader('SC#', 'pqt_tracking_id', 'th-id')}
                            {renderHeader('Job Description', 'job_description', '')}
                            {renderHeader('DP Pieces', 'dp_pieces_count', 'th-records')}
                            {renderHeader('PA Pieces', 'pa_pieces_count', 'th-records')}
                            <th className="text-right" style={{ width: 100 }}>Date Prepared</th>
                            <th className="text-right" style={{ width: 100 }}>Time Prepared</th>
                            <th style={{ width: 80 }}></th>
                        </tr>
                        <tr>
                            {renderSearchField('id')}
                            {renderSearchField('job_description')}
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderTable()}
                    </tbody>
                </table>
                <PqtPagination
                    pagination={props.pagination}
                    setActivePage={props.setActivePage}
                />
            </div>
        </div>
    );
});

export default Table;
