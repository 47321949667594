import React, { useRef } from 'react';
//import PropTypes from 'prop-types';
import { dateToTimezone, scrollElementToTheBottom } from './../../../utils';

const ChatRoom = React.memo((props) => {
    const lastMessageRef = useRef(null);

    if (!props.firstScrollDone) {
        setTimeout(() => {
            scrollElementToTheBottom(lastMessageRef, props.setFirstScrollDone.bind(this, true));
        }, 100);
    }

    const renderMessages = () => {
        return props.messages.map((message, index) => {
            const isMine = parseInt(message.type) === 1;
            const className = isMine ? 'mine' : 'other';

            let nameElement = null;
            if (!isMine) {
                nameElement = <div className="user-name">{message.user_name}</div>;
            }

            let ref = null;
            if (props.messages.length - 1 === index) {
                ref = lastMessageRef;
            }

            return (
                <div ref={ref} className={'message-element-container ' + className} key={'message-element-' + index}>
                    <div className={'message-element ' + className}>
                        {nameElement}
                        <div className="user-message">{message.message}</div>
                        <div className="message-date">{dateToTimezone(message.created_at, 'MM/DD/YYYY h:mma')}</div>
                    </div>
                </div>
            );
        });
    };

    const setResponseHandler = event => {
        props.setResponse(event.target.value);
    };

    const sendMessageHandler = () => {
        props.saveMessage();
    };

    const hideHandler = () => {
        props.checkUnreadMessages();
        props.setShowField('room', false);
    };

    let savingIcon = null;
    if (props.saving) {
        savingIcon = <i className="fas fa-circle-notch fa-spin" />;
    }

    const enterPressHandler = event => {
        if (event.key === 'Enter') {
            props.saveMessage();
        }
    };

    let loadMoreMessagesClassName = 'hide';
    if (props.totalMessages > props.totalLoadedMessages) {
        loadMoreMessagesClassName = '';
    }

    let loadingMoreMessagesIcon = null;
    if (props.loadingMoreMessages) {
        loadingMoreMessagesIcon = <i className="fas fa-circle-notch fa-spin" />;
    }

    let messagesContainer = (
        <div className="messages">
            <div className={'load-more-messages ' + loadMoreMessagesClassName} onClick={props.loadMoreMessages}>Load More Messages {loadingMoreMessagesIcon}</div>
            {renderMessages()}
        </div>
    );
    if (props.isLoading) {
        messagesContainer = (
            <div className="messages messages-loading">
                <i className="fas fa-circle-notch fa-spin" /> Loading messages...
            </div>
        );
    }

    let roomSizeClassName = 'large';
    if (props.showMembers) {
        roomSizeClassName = 'small'
    }

    if (props.type === null) {
        return (
            <div className={'chat-room-container ' + roomSizeClassName}>
                <div className="close"><i className="fas fa-times" onClick={hideHandler} /></div>
                <div className="select-chat">
                    <div>Select a chat on the left sidebar to see its messages...</div>
                    <div><b>Tracking</b> chats are the project chats that you belong.</div>
                    <div><b>Contacts</b> chats are direct 1 to 1 (individual) chats with other person.</div>
                </div>
            </div>
        );
    }

    const showMembersHandler = () => {
        props.loadChatMembers();
        props.setShowField('members', true);
    };

    return (
        <div className={'chat-room-container ' + roomSizeClassName}>
            <div className="title">
                <div>{props.chatTitle}</div>
                <div>
                    <i className="fas fa-user-friends" onClick={showMembersHandler} />
                    <i className="fas fa-times" onClick={hideHandler} />
                </div>
            </div>
            {messagesContainer}
            <div className="new-message">
                <input type="text" placeholder="Write a response..." name="response" value={props.response} onChange={setResponseHandler} onKeyPress={enterPressHandler} />
                {savingIcon}
                <i className="far fa-paper-plane" onClick={sendMessageHandler} />
            </div>
        </div>
    );
});

ChatRoom.propTypes = {

};

export default ChatRoom;
