import Immutable from 'immutable';
import * as types from './PurchaseOrder.types';

const initialState = {
    id: null,
    fetching: false,
    purchaseOrder: {
        use_vendor_address: 1,
        items: [],
        auditHistory: [],
    },
    information: {
        fetching: false,
        customers: [],
        vendors: [],
        classes: [],
        categories: [],
    },
    fetchingVendorData: false,
    isSaving: false,
    isPushing: false,
    vendorEmail: null,
    jobDueDate: null,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_PURCHASE_ORDER:
            return state.setIn(['fetching'], true).toJS();

        case types.FETCH_PURCHASE_ORDER_SUCCESS:
            return state.setIn(['fetching'], false)
                .setIn(['purchaseOrder'], action.payload)
                .setIn(['vendorEmail'], action.payload.vendor_email)
                .setIn(['jobDueDate'], action.payload.job_due_date)
                .toJS();

        case types.FETCH_PURCHASE_ORDER_ERROR:
            return state.setIn(['fetching'], false).toJS();

        case types.FETCH_INFORMATION:
            return state.setIn(['information', 'fetching'], true).toJS();

        case types.FETCH_INFORMATION_SUCCESS:
            return state.setIn(['information', 'fetching'], false)
                .setIn(['information', 'customers'], action.payload.customers)
                .setIn(['information', 'vendors'], action.payload.vendors)
                .setIn(['information', 'classes'], action.payload.classes)
                .setIn(['information', 'categories'], action.payload.categories)
                .toJS();

        case types.FETCH_INFORMATION_ERROR:
            return state.setIn(['information', 'fetching'], false).toJS();

        case types.FETCH_VENDOR_DATA:
            return state.setIn(['fetchingVendorData'], true).toJS();

        case types.FETCH_VENDOR_DATA_SUCCESS:
            return state.setIn(['fetchingVendorData'], false)
                .setIn(['purchaseOrder', 'use_vendor_address'], true)
                .setIn(['purchaseOrder', 'vendor_name'], action.payload.name)
                .setIn(['purchaseOrder', 'vendor_address'], action.payload.address)
                .setIn(['purchaseOrder', 'vendor_city'], action.payload.city)
                .setIn(['purchaseOrder', 'vendor_state'], action.payload.state)
                .setIn(['purchaseOrder', 'vendor_zip'], action.payload.zip)
                .toJS();

        case types.FETCH_VENDOR_DATA_ERROR:
            return state.setIn(['fetchingVendorData'], false).toJS();

        case types.SAVE_PURCHASE_ORDER:
            return state.setIn(['isSaving'], true).toJS();

        case types.SAVE_PURCHASE_ORDER_SUCCESS:
            return state.setIn(['isSaving'], false).toJS();

        case types.SAVE_PURCHASE_ORDER_ERROR:
            return state.setIn(['isSaving'], false).toJS();

        case types.QB_PUSH:
            return state.setIn(['isPushing'], true).toJS();

        case types.QB_PUSH_SUCCESS:
            return state.setIn(['isPushing'], false).toJS();

        case types.QB_PUSH_ERROR:
            return state.setIn(['isPushing'], false).toJS();

        case types.SET_FIELD:
            return state.setIn(['purchaseOrder', action.payload.field], action.payload.value).toJS();

        case types.SET_ITEM_FIELD:
            return state.updateIn(['purchaseOrder', 'items'], (items) => {
                const itemsJs = items.toJS();
                itemsJs[action.payload.index][action.payload.field] = action.payload.value;

                if (action.payload.field === 'quantity') {
                    itemsJs[action.payload.index]['amount'] = action.payload.value * itemsJs[action.payload.index]['unit_price'];
                } else if (action.payload.field === 'unit_price') {
                    itemsJs[action.payload.index]['amount'] = action.payload.value * itemsJs[action.payload.index]['quantity'];
                } else if (action.payload.field === 'amount') {
                    itemsJs[action.payload.index]['unit_price'] = action.payload.value / itemsJs[action.payload.index]['quantity'];
                }

                return itemsJs;
            }).toJS();

        case types.ADD_LINE:
            return state.updateIn(['purchaseOrder', 'items'], (items) => {
                const itemsJs = items.toJS();

                const newElement = {
                    purchase_order_category_id: '',
                    purchase_order_class_id: '',
                    description: '',
                    amount: '',
                    customer_id: '',
                    sort_position: 1,
                };
                itemsJs.push(newElement);

                for (let i = 0; i < itemsJs.length; i++) {
                    itemsJs[i].sort_position = i + 1;
                }

                return itemsJs;
            }).toJS();

        case types.REMOVE_LINE:
            return state.updateIn(['purchaseOrder', 'items'], (items) => {
                const itemsJs = items.toJS();
                itemsJs.splice(action.payload, 1);

                for (let i = 0; i < itemsJs.length; i++) {
                    itemsJs[i].sort_position = i + 1;
                }

                return itemsJs;
            }).toJS();

        case types.SET_VENDOR_EMAIL:
            return state.setIn(['vendorEmail'], action.payload).toJS();

        case types.CHANGE_SORT_POSITION:
            return state.updateIn(['purchaseOrder', 'items'], (items) => {
                const itemsJs = items.toJS();
                const removedItem = itemsJs.splice(action.payload.index, 1);

                if (action.payload.type === 1) {
                    itemsJs.splice(action.payload.index - 1, 0, removedItem[0]);
                } else {
                    itemsJs.splice(action.payload.index + 1, 0, removedItem[0]);
                }

                for (let i = 0; i < itemsJs.length; i++) {
                    itemsJs[i].sort_position = i + 1;
                }

                return itemsJs;
            }).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}