import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Customers from './Customers';
import DeleteModal from './DeleteModal';
import HoldModal from './HoldModal';
import * as actions from './OnHoldCustomers.actions';
import * as selectors from './OnHoldCustomers.selectors';
import './OnHoldCustomers.scss';
import PqtLayout from './../PqtLayout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import * as dialog from '../common/dialog';
import * as loginSelectors from './../Login/Login.selectors';
import { Redirect } from 'react-router-dom';

const OnHoldCustomers = (props) => {
    /* State to props */
    const fetchingOnHoldCustomers = useSelector(selectors.getFetchingOnHoldCustomers);
    const onHoldCustomers = useSelector(selectors.getOnHoldCustomers, stringifyEqualityCheck);
    const pagination = useSelector(selectors.getPagination, stringifyEqualityCheck);
    const sort = useSelector(selectors.getSort, stringifyEqualityCheck);
    const idForDelete = useSelector(selectors.getIdForDelete, stringifyEqualityCheck);
    const removingHold = useSelector(selectors.getRemovingHold, stringifyEqualityCheck);
    const filerFields = useSelector(selectors.getFilterFields, stringifyEqualityCheck);
    const fields = useSelector(selectors.getFields, stringifyEqualityCheck);
    const information = useSelector(selectors.getInformation, stringifyEqualityCheck);
    const addingHold = useSelector(selectors.getAddingHold);
    const customerName = useSelector(selectors.getCustomerName);
    const fetchingOnHoldCustomer = useSelector(selectors.getFetchingOnHoldCustomer);
    const downloadingReport = useSelector(selectors.getDownloadingReport);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const removeHold = useCallback((id) => dispatch(actions.removeHold(id)), [dispatch]);
    const setSort = useCallback((column, type) => dispatch(actions.setSort(column, type)), [dispatch]);
    const setActivePage = useCallback((name, value) => dispatch(actions.setActivePage(name, value)), [dispatch]);
    const fetchOnHoldCustomers = useCallback((options, isWsUpdate) => dispatch(actions.fetchOnHoldCustomers(options, isWsUpdate)), [dispatch]);
    const setIdForDelete = useCallback((id) => dispatch(actions.setIdForDelete(id)), [dispatch]);
    const changeFilter = useCallback((name, value, fetch) => dispatch(actions.changeFilter(name, value, fetch)), [dispatch]);
    const setField = useCallback((field, value) => dispatch(actions.setField(field, value)), [dispatch]);
    const addHold = useCallback(() => dispatch(actions.addHold()), [dispatch]);
    const setCustomerName = useCallback((value) => dispatch(actions.setCustomerName(value)), [dispatch]);
    const resetFields = useCallback((value) => dispatch(actions.resetFields(value)), [dispatch]);
    const fetchOnHoldCustomer = useCallback((id) => dispatch(actions.fetchOnHoldCustomer(id)), [dispatch]);
    const downloadReport = useCallback(() => dispatch(actions.downloadReport()), [dispatch]);
    const showDeleteModal = useCallback(() => dispatch(dialog.actions.showDialog('delete-modal')), [dispatch]);
    const hideDeleteModal = useCallback(() => dispatch(dialog.actions.hideDialog('delete-modal')), [dispatch]);
    const showHoldModal = useCallback(() => dispatch(dialog.actions.showDialog('hold-modal')), [dispatch]);
    const hideHoldModal = useCallback(() => dispatch(dialog.actions.hideDialog('hold-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        dispatch(actions.mountComponent());
    }, [dispatch]);

    // if (user != null && ![1, 2, 3, 4, 5, 9].includes(parseInt(user.pqt_type)) && !user.is_pqt_accounting) {
    if (user != null && parseInt(user.id) !== 61 && ![26, 63, 245, 34, 28, 2419].includes(parseInt(user.id))) {     // Hugh or Kimberly or Nichole or Norm or Lupe (34), Heather (28), Stephanie (2419)
        return <Redirect to="/dashboard" />;
    }

    return (
        <PqtLayout title="On Hold Customers" history={props.history}>
            <Helmet><title>On Hold Customers - Stagecoach</title></Helmet>
            <div className="on-hold-customers-container">
                <Customers
                    onHoldCustomers={onHoldCustomers}
                    pagination={pagination}
                    fetchingOnHoldCustomers={fetchingOnHoldCustomers}
                    history={props.history}
                    sort={sort}
                    user={user}
                    filerFields={filerFields}
                    downloadingReport={downloadingReport}
                    setSort={setSort}
                    setActivePage={setActivePage}
                    showDeleteModal={showDeleteModal}
                    setIdForDelete={setIdForDelete}
                    changeFilter={changeFilter}
                    fetchOnHoldCustomers={fetchOnHoldCustomers}
                    fetchOnHoldCustomer={fetchOnHoldCustomer}
                    showHoldModal={showHoldModal}
                    setCustomerName={setCustomerName}
                    setField={setField}
                    downloadReport={downloadReport}
                />
                <DeleteModal
                    idForDelete={idForDelete}
                    removingHold={removingHold}
                    customerName={customerName}
                    removeHold={removeHold}
                    hideDeleteModal={hideDeleteModal}
                    setIdForDelete={setIdForDelete}
                    setField={setField}
                />
                <HoldModal 
                    information={information}
                    fields={fields}
                    addingHold={addingHold}
                    fetchingOnHoldCustomer={fetchingOnHoldCustomer}
                    setField={setField}
                    hideHoldModal={hideHoldModal}
                    addHold={addHold}
                    resetFields={resetFields}
                />
            </div>
        </PqtLayout>
    );
};

export default OnHoldCustomers;
