import { createSelector } from 'reselect';

export const getComponentState = state => state.postageCalculator;

export const getFields = createSelector(
    getComponentState,
    (state) => state.fields
);
export const getCalculatingPrice = createSelector(
    getComponentState,
    (state) => state.calculatingPrice
);
export const getPriceResult = createSelector(
    getComponentState,
    (state) => state.priceResult
);