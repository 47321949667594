import React from 'react';
import { Dialog } from './../../common/dialog';
import { useDropzone } from 'react-dropzone';

const UploadListPurchaseModal = React.memo((props) => {
    const {
        // acceptedFiles,
        // fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        multiple: false,
        onDrop: (files) => {
            props.setFile(files[0]);
        }
    });

    const hideHandler = () => {
        props.hideUploadListPurchaseModal();
    };

    let label = 'Upload';
    if (props.isUploadingListPurchase) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    let selectedFile = null;
    if (props.file != null) {
        selectedFile = (
            <div className="selected-file">
                <div><b>Selected File:</b></div>
                <div>{props.file.name}</div>
            </div>
        );
    }

    let actions = [
        <button key="cancel-button" className="btn btn-lights btn-sm action-button-cancel" onClick={hideHandler}>Cancel</button>,
        <button key="report-button" className="btn btn-light btn-sm action-button" onClick={props.uploadListPurchase} disabled={props.isUploadingListPurchase}>{label}</button>,
    ];
    return (
        <Dialog
            name="upload-list-purchase-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="upload-list-purchase-dialog"
        >
            <div className="upload-list-purchase-container">
                <h1>Upload List Purchase</h1>
                <div className="select-file-container">
                    <div className="mt-1">Select a file:</div>
                    <div {...getRootProps({ className: 'dropzone-container' })}>
                        <input {...getInputProps()} />
                        Choose File
                    </div>
                    {selectedFile}
                </div>
            </div>
        </Dialog >
    );
});

export default UploadListPurchaseModal;
