import { createSelector } from 'reselect';

export const getComponentState = state => state.informedVisibilityJob;

export const getFetchingJob = createSelector(
    getComponentState,
    (state) => state.fetchingJob
);
export const getJob = createSelector(
    getComponentState,
    (state) => state.job
);
