import { createSelector } from 'reselect';

export const getComponentState = state => state.commissionCompensations;

export const getFetchingCompensations = createSelector(
    getComponentState,
    (state) => state.fetchingCompensations
);
export const getCompensations = createSelector(
    getComponentState,
    (state) => state.compensations
);
export const getPagination = createSelector(
    getComponentState,
    (state) => state.pagination
);
export const getSort = createSelector(
    getComponentState,
    (state) => state.sort
);
export const getIdForDelete = createSelector(
    getComponentState,
    (state) => state.delete.id
);
export const getIsDeleting = createSelector(
    getComponentState,
    (state) => state.delete.isDeleting
);
export const getFilterFields = createSelector(
    getComponentState,
    (state) => state.filter.fields
);
export const getFields = createSelector(
    getComponentState,
    (state) => state.fields
);
export const getFetchingCompensation = createSelector(
    getComponentState,
    (state) => state.fetchingCompensation
);
export const getSavingCompensation = createSelector(
    getComponentState,
    (state) => state.savingCompensation
);
export const getPersons = createSelector(
    getComponentState,
    (state) => state.persons
);
export const getFetchingPersons = createSelector(
    getComponentState,
    (state) => state.fetchingPersons
);