import React, { Fragment } from 'react';
import moment from 'moment-timezone';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { empty } from '../../../utils';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import { useDropzone } from 'react-dropzone';
import { validateSendFields } from './../ChristyRfq.actions';
import numeral from 'numeral';

const Form = React.memo((props) => {
    const {
        // acceptedFiles,
        // fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        multiple: true,
        onDrop: (files) => {
            for (let i = 0; i < files.length; i++) {
                props.addFile(files[i]);
            }
        }
    });

    if (props.fetchingRequest || props.fetchingInformation) {
        return <div className="text-center loading-form"><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
    }

    const handleSetField = (name, event) => {
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        }

        props.setField(name, value);

        if (name === 'paper_stock') {
            props.setField('paper_weight', '');
        } else if (name === 'interior_paper_stock') {
            props.setField('interior_paper_weight', '');
        }
    };

    const handleSetDateField = (name, event) => {
        let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');
        props.setField(name, value);
    };

    let productTypeOptions = [];
    for (let i = 0; i < props.information.productTypes.length; i++) {
        productTypeOptions.push(<option value={props.information.productTypes[i].id}>{props.information.productTypes[i].name}</option>);
    }

    let paperStockOptions = [];
    for (let i = 0; i < props.information.paperCategories.length; i++) {
        paperStockOptions.push(<option value={props.information.paperCategories[i].id}>{props.information.paperCategories[i].category_name}</option>);
    }

    let weights = [];
    let paperWeightOptions = [];
    for (let i = 0; i < props.information.papers.length; i++) {
        if (parseInt(props.information.papers[i].paper_category_id) !== parseInt(props.fields.paper_stock)) {
            continue;
        }

        if (!weights.includes(props.information.papers[i].basis_weight)) {
            weights.push(props.information.papers[i].basis_weight);
        }
    }
    weights.sort((a, b) => {
        if (parseFloat(a) > parseFloat(b)) {
            return 1;
        }
        if (parseFloat(a) < parseFloat(b)) {
            return -1;
        }
        return 0;
    });
    for (let i = 0; i < weights.length; i++) {
        paperWeightOptions.push(<option value={weights[i]}>{weights[i]}#</option>);
    }

    let estimatorOptions = [];
    for (let i = 0; i < props.information.estimators.length; i++) {
        estimatorOptions.push(<option value={props.information.estimators[i].id}>{props.information.estimators[i].name}</option>);
    }

    let handleCancel = () => {
        props.history.push('/christy-rfqs');
    };

    const getValue = (value) => {
        if (empty(value)) {
            return '';
        }

        return value;
    };

    const renderFiles = () => {
        if (props.files.length === 0) {
            return (
                <tr>
                    <td colSpan="2">No files selected</td>
                </tr>
            );
        }

        return props.files.map((file, index) => {
            let actionIcon = (
                <Tooltip title="Remove File" position="bottom" arrow size="small" className="action-tooltip">
                    <i className="fas fa-trash" onClick={props.removeFile.bind(this, index)} />
                </Tooltip>
            );
            if (props.id) {
                actionIcon = (
                    <Tooltip title="Download File" position="bottom" arrow size="small" className="action-tooltip">
                        <a href={file.url} target="_blank" rel="noopener noreferrer"><i className="fas fa-download" /></a>
                    </Tooltip>
                );
            }

            return (
                <tr>
                    <td>{file.name}</td>
                    <td className="text-center actions">
                        {actionIcon}
                    </td>
                </tr>
            );
        });
    };

    const handleSendEstimate = () => {
        if (validateSendFields(props.fields, props.pricing)) {
            props.showSendModal();
        }
    };

    let cancelButton = <button onClick={handleCancel}>Cancel</button>;
    let resetButton = <button onClick={props.resetState}>Reset</button>;

    let sendRequestButton = <button onClick={props.submitRequest} disabled={props.submitting}>Send Request</button>;
    if (props.submitting) {
        sendRequestButton = <button disabled={props.submitting}><i className="fas fa-circle-notch fa-spin" /></button>;
    }

    let sendQuoteButton = <button onClick={handleSendEstimate} disabled={props.sending}>Send Quote</button>;
    if (props.sending) {
        sendQuoteButton = <button disabled={props.sending}><i className="fas fa-circle-notch fa-spin" /></button>;
    }

    let saveButton = <button onClick={props.saveRequest} disabled={props.sending}>Save</button>;
    if (props.saving) {
        saveButton = <button disabled={props.saving}><i className="fas fa-circle-notch fa-spin" /></button>;
    }

    if (!props.id) {
        cancelButton = null;
        sendQuoteButton = null;
        saveButton = null;
    } else {
        resetButton = null;
        sendRequestButton = null;
    }

    const handleSetPricingField = (index, field, event) => {
        props.setPricingField(index, field, event.target.value);
    };

    let pricingContainer = null;
    if (!empty(props.id)) {
        pricingContainer = (
            <Fragment>
                <hr />
                <div className="column3">
                    <h3>Estimate:</h3>
                    <div className="estimate-line">
                        <div className="label"></div>
                        <div className="value1"></div>
                        <div className="additional-quantities">Additional Quantities:</div>
                    </div>
                    <div className="estimate-line">
                        <div className="label">Quantity <span className="required">*</span>:</div>
                        <div className="value1">{props.fields.quantity}</div>
                        <div className="value2">
                            <input type="number" value={props.pricing[1].quantity} onChange={handleSetPricingField.bind(this, 1, 'quantity')} />
                        </div>
                        <div className="value3">
                            <input type="number" value={props.pricing[2].quantity} onChange={handleSetPricingField.bind(this, 2, 'quantity')} />
                        </div>
                    </div>
                    <div className="estimate-line">
                        <div className="label">Price <span className="required">*</span>:</div>
                        <div className="value1">
                            <span className="dollar-sign">$</span>
                            <input type="number" className="price-field" value={props.pricing[0].price} onChange={handleSetPricingField.bind(this, 0, 'price')} />
                        </div>
                        <div className="value2">
                            <span className="dollar-sign">$</span>
                            <input type="number" className="price-field" value={props.pricing[1].price} onChange={handleSetPricingField.bind(this, 1, 'price')} />
                        </div>
                        <div className="value3">
                            <span className="dollar-sign">$</span>
                            <input type="number" className="price-field" value={props.pricing[2].price} onChange={handleSetPricingField.bind(this, 2, 'price')} />
                        </div>
                    </div>
                    <div className="estimate-line">
                        <div className="label">Outsource:</div>
                        <div className="value1">
                            <span className="dollar-sign">$</span>
                            <input type="number" className="price-field" value={props.pricing[0].outsource} onChange={handleSetPricingField.bind(this, 0, 'outsource')} />
                        </div>
                        <div className="value2">
                            <span className="dollar-sign">$</span>
                            <input type="number" className="price-field" value={props.pricing[1].outsource} onChange={handleSetPricingField.bind(this, 1, 'outsource')} />
                        </div>
                        <div className="value3">
                            <span className="dollar-sign">$</span>
                            <input type="number" className="price-field" value={props.pricing[2].outsource} onChange={handleSetPricingField.bind(this, 2, 'outsource')} />
                        </div>
                    </div>
                    <div className="estimate-line">
                        <div className="label">Commission <span className="required">*</span>:</div>
                        <div className="value1">
                            <input type="number" className="percent-field" value={props.pricing[0].commission} onChange={handleSetPricingField.bind(this, 0, 'commission')} />
                            <span className="percent-sign">%</span>
                        </div>
                        <div className="value2">
                            <input type="number" className="percent-field" value={props.pricing[1].commission} onChange={handleSetPricingField.bind(this, 1, 'commission')} />
                            <span className="percent-sign">%</span>
                        </div>
                        <div className="value3">
                            <input type="number" className="percent-field" value={props.pricing[2].commission} onChange={handleSetPricingField.bind(this, 2, 'commission')} />
                            <span className="percent-sign">%</span>
                        </div>
                    </div>
                    <div className="estimate-line">
                        <div className="label">Add. Markup:</div>
                        <div className="value1">
                            <input type="number" className="percent-field" value={props.pricing[0].add_markup} onChange={handleSetPricingField.bind(this, 0, 'add_markup')} />
                            <span className="percent-sign">%</span>
                        </div>
                        <div className="value2">
                            <input type="number" className="percent-field" value={props.pricing[1].add_markup} onChange={handleSetPricingField.bind(this, 1, 'add_markup')} />
                            <span className="percent-sign">%</span>
                        </div>
                        <div className="value3">
                            <input type="number" className="percent-field" value={props.pricing[2].add_markup} onChange={handleSetPricingField.bind(this, 2, 'add_markup')} />
                            <span className="percent-sign">%</span>
                        </div>
                    </div>
                    <div className="estimate-line">
                        <div className="label">Final Price <span className="required">*</span>:</div>
                        <div className="value1">
                            {numeral(props.pricing[0].final_price).format('$0,0.00')}
                        </div>
                        <div className="value2">
                            {numeral(props.pricing[1].final_price).format('$0,0.00')}
                        </div>
                        <div className="value3">
                            {numeral(props.pricing[2].final_price).format('$0,0.00')}
                        </div>
                    </div>
                </div>
                <div className="column4">
                    <div>Message to customer:</div>
                    <textarea value={getValue(props.fields.message_to_customer)} onChange={handleSetField.bind(this, 'message_to_customer')} />
                    <div className="field">
                        <div className="label">Estimator:</div>
                        <select className="estimator-field" value={getValue(props.fields.estimator_id)} onChange={handleSetField.bind(this, 'estimator_id')}>
                            <option value="">Select option</option>
                            {estimatorOptions}
                        </select>
                    </div>
                    <div className="field">
                        <div className="label">Email &amp; Copy:</div>
                        <input type="checkbox" checked={props.fields.cc_to_customer} onChange={handleSetField.bind(this, 'cc_to_customer')} /> Customer
                        <input type="checkbox" className="ml-3" checked={props.fields.cc_to_sales} onChange={handleSetField.bind(this, 'cc_to_sales')} /> Sales
                    </div>
                </div>
            </Fragment>
        );
    }

    let messageTitle = 'Dear Valued Client,';
    let messageText = 'Thank you for the opportunity to service you. Your request for quote is my highest priority. ' +
        'Help me get your RFQ back in the least amount of time, please provide me with your ' +
        'required information. You can also attach any visual files that you may have below.';
    if (props.id) {
        messageTitle = 'Dear Estimating,';
        messageText = 'Please take a look at this request from my client. Feel free to contact my client ' +
            'if you need any additional information to complete this quote.';
    }

    let uploadingFiles = null;
    if (props.submitting && props.files.length > 0) {
        uploadingFiles = <div><i className="fas fa-circle-notch fa-spin" /> Your files are uploading. It may take a few seconds depending on the size of the file.</div>;
    }

    let browseButton = null;
    if (!props.id) {
        browseButton = (
            <div {...getRootProps({ className: 'dropzone-container' })}>
                <input {...getInputProps()} />
                <button>Browse</button>
            </div>
        );
    }

    return (
        <div className="form-container">
            <div className="content">
                <div className="message-container">
                    <div className="photo">
                        <img src={require('./../images/hummingbird.png')} alt="Hummingbird" />
                    </div>
                    <div className="message">
                        <div>{messageTitle}</div>
                        <div className="mt-2 mb-2">
                            {messageText}
                        </div>
                        <div>Thank you, Christy Nguyen.</div>
                    </div>
                    <div className="logo">
                        <img src={require('./../images/acm-logo.jpg')} alt="ACM" />
                    </div>
                </div>
                <div className="rfq-form-container">
                    <div className="column1">
                        <h2>Request for Estimate:</h2>
                        <div className="customer-information">
                            <div className="field">
                                <div className="label">Name <span className="required">*</span>:</div>
                                <input type="text" value={getValue(props.fields.name)} onChange={handleSetField.bind(this, 'name')} />
                            </div>
                            <div className="field">
                                <div className="label">Company <span className="required">*</span>:</div>
                                <input type="text" value={getValue(props.fields.company)} onChange={handleSetField.bind(this, 'company')} />
                            </div>
                            <div className="field">
                                <div className="label">Email <span className="required">*</span>:</div>
                                <input type="text" value={getValue(props.fields.email)} onChange={handleSetField.bind(this, 'email')} />
                            </div>
                            <div className="field">
                                <div className="label">Phone <span className="required">*</span>:</div>
                                <input type="text" value={getValue(props.fields.phone)} onChange={handleSetField.bind(this, 'phone')} />
                            </div>
                            <div className="field">
                                <div className="label">Cell:</div>
                                <input type="text" value={getValue(props.fields.cell)} onChange={handleSetField.bind(this, 'cell')} />
                            </div>
                            <div className="field">
                                <div className="label">Project Title <span className="required">*</span>:</div>
                                <input type="text" value={getValue(props.fields.project_title)} onChange={handleSetField.bind(this, 'project_title')} />
                            </div>
                        </div>
                        <div className="estimate-information">
                            <div className="field">
                                <div className="label">Due Date <span className="required">*</span>:</div>
                                <DayPickerInput
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    placeholder=""
                                    className="date-picker-field"
                                    onDayChange={handleSetDateField.bind(this, 'due_date')}
                                    value={props.fields.due_date == null || props.fields.due_date === '0000-00-00' ? '' : moment(props.fields.due_date).format('MM/DD/YYYY')}
                                />
                            </div>
                            <div className="field">
                                <div className="label">Quantity <span className="required">*</span>:</div>
                                <input type="number" value={getValue(props.fields.quantity)} onChange={handleSetField.bind(this, 'quantity')} />
                            </div>
                            <div className="field">
                                <div className="label">Artwork Required <span className="required">*</span>:</div>
                                <select value={getValue(props.fields.artwork_required)} onChange={handleSetField.bind(this, 'artwork_required')}>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                            <div className="field">
                                <div className="label">Mailing Required <span className="required">*</span>:</div>
                                <select value={getValue(props.fields.mailing_required)} onChange={handleSetField.bind(this, 'mailing_required')}>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                            <div className="estimate-types">
                                <div className="label">Estimate Type:</div>
                                <div className="type-field print-checkbox">
                                    <div className="text-center">Print</div>
                                    <div className="text-center">
                                        <input type="checkbox" checked={props.fields.requires_print} onChange={handleSetField.bind(this, 'requires_print')} />
                                    </div>
                                </div>
                                <div className="type-field promotional-checkbox">
                                    <div className="text-center">Promotional</div>
                                    <div className="text-center">
                                        <input type="checkbox" checked={props.fields.requires_promotional} onChange={handleSetField.bind(this, 'requires_promotional')} />
                                    </div>
                                </div>
                                <div className="type-field apparel-checkbox">
                                    <div className="text-center">Apparel</div>
                                    <div className="text-center">
                                        <input type="checkbox" checked={props.fields.requires_apparel} onChange={handleSetField.bind(this, 'requires_apparel')} />
                                    </div>
                                </div>
                                <div className="type-field signage-checkbox">
                                    <div className="text-center">Signage</div>
                                    <div className="text-center">
                                        <input type="checkbox" checked={props.fields.requires_signage} onChange={handleSetField.bind(this, 'requires_signage')} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="description-container">
                            <div>Please describe your project in detail here:</div>
                            <textarea value={getValue(props.fields.description)} onChange={handleSetField.bind(this, 'description')} placeholder="Please let us know more about your project details." />
                        </div>
                        <div className="files-container">
                            <h3>
                                Attached Files
                                {browseButton}
                            </h3>
                            <table className="table table-sm table-hover">
                                <thead>
                                    <tr>
                                        <th>Filename</th>
                                        <th className="actions"></th>
                                    </tr>
                                </thead>
                                <tbody>{renderFiles()}</tbody>
                            </table>
                            {uploadingFiles}
                        </div>
                    </div>
                    <div className="column2">
                        <h3>Additional Information:</h3>
                        <div className="mb-2">Would help but not required</div>
                        <div className="field">
                            <div className="label">Product Type:</div>
                            <select value={getValue(props.fields.product_type)} onChange={handleSetField.bind(this, 'product_type')}>
                                <option value="">Select option</option>
                                {productTypeOptions}
                            </select>
                        </div>
                        <div className="field">
                            <div className="label">Ink Type:</div>
                            <select value={getValue(props.fields.ink_type)} onChange={handleSetField.bind(this, 'ink_type')}>
                                <option value="">Select option</option>
                                <option value="1">Color Both Sides</option>
                                <option value="2">Color One Side</option>
                                <option value="3">Black and White</option>
                            </select>
                        </div>
                        <div className="field">
                            <div className="label">Paper Stock:</div>
                            <select value={getValue(props.fields.paper_stock)} onChange={handleSetField.bind(this, 'paper_stock')}>
                                <option value="">Select option</option>
                                {paperStockOptions}
                            </select>
                        </div>
                        <div className="field">
                            <div className="label">Paper Weight:</div>
                            <select value={getValue(props.fields.paper_weight)} onChange={handleSetField.bind(this, 'paper_weight')}>
                                <option value="">Select option</option>
                                {paperWeightOptions}
                            </select>
                        </div>
                        <hr />
                        <div className="size-field">
                            <div className="label">Flat Size:</div>
                            <div className="width">
                                Width:
                                <input type="number" value={getValue(props.fields.flat_width)} onChange={handleSetField.bind(this, 'flat_width')} />
                            </div>
                            <div className="height">
                                x Height:
                                <input type="number" value={getValue(props.fields.flat_height)} onChange={handleSetField.bind(this, 'flat_height')} />
                            </div>
                        </div>
                        <div className="size-field">
                            <div className="label">Final Size:</div>
                            <div className="width">
                                Width:
                                <input type="number" value={getValue(props.fields.finish_width)} onChange={handleSetField.bind(this, 'finish_width')} />
                            </div>
                            <div className="height">
                                x Height:
                                <input type="number" value={getValue(props.fields.finish_height)} onChange={handleSetField.bind(this, 'finish_height')} />
                            </div>
                        </div>
                        <hr />
                        <h3>Bindery:</h3>
                        <div className="field">
                            <div className="label">Folding Required:</div>
                            <select value={getValue(props.fields.folding_required)} onChange={handleSetField.bind(this, 'folding_required')}>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                        <div className="field special-bindery-field">
                            <div>Special Bindery Request:</div>
                            <textarea value={getValue(props.fields.bindery_description)} onChange={handleSetField.bind(this, 'bindery_description')} />
                        </div>
                        <hr />
                        <h3>Ship To:</h3>
                        <div className="ship-to-radio">
                            <input
                                type="radio"
                                name="ship_to"
                                value="1"
                                checked={parseInt(props.fields.ship_to) === 1}
                                onChange={handleSetField.bind(this, 'ship_to')}
                            /> I will pickup when ready
                        </div>
                        <div className="ship-to-radio">
                            <input
                                type="radio"
                                name="ship_to"
                                value="2"
                                checked={parseInt(props.fields.ship_to) === 2}
                                onChange={handleSetField.bind(this, 'ship_to')}
                            /> Deliver to my office
                        </div>
                        <div className="address-field">
                            <textarea value={getValue(props.fields.address)} onChange={handleSetField.bind(this, 'address')} />
                        </div>
                    </div>
                    {pricingContainer}
                    <div className="buttons-container">
                        {cancelButton}
                        {resetButton}
                        {saveButton}
                        {sendRequestButton}
                        {sendQuoteButton}
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Form;
