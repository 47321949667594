import React, { Fragment } from 'react';
//import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';
import moment from 'moment-timezone';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';

const Form = props => {
    let saveButtonLabel = 'Save';
    if (props.saving) {
        saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    const setFieldHandler = (name, event) => {
        props.setField(name, event.target.value);
    }

    const renderTextField = (label, name, className = '', message = '') => (
        <Fragment>
            <div className={'input-label ' + className}>{label} <span className="required">*</span></div>
            <div className="input-field">
                <input
                    type="text"
                    name={name}
                    value={props.element[name] === undefined ? '' : props.element[name]}
                    onChange={setFieldHandler.bind(this, name)}
                />
                <span className="message">{message}</span>
            </div>
        </Fragment>
    );

    const renderSelectField = (label, name, options, className = '') => (
        <Fragment>
            <div className={'input-label ' + className}>{label} <span className="required">*</span></div>
            <div className="input-field">
                <select
                    name={name}
                    value={props.element[name] === undefined ? '' : props.element[name]}
                    onChange={setFieldHandler.bind(this, name)}
                >
                    {options.map((option, index) => (<option value={option.value} key={name + '-option-' + index}>{option.label}</option>))}
                </select>
            </div>
        </Fragment>
    );

    const renderRadioField = (label, name, className = '') => (
        <Fragment>
            <div className={'input-label ' + className}>{label} <span className="required">*</span></div>
            <div className="input-field">
                <input
                    type="radio"
                    name={name}
                    value="1"
                    checked={props.element[name] === undefined ? false : parseInt(props.element[name]) === 1}
                    onChange={setFieldHandler.bind(this, name)}
                /> Yes
                <input
                    type="radio"
                    value="0"
                    name={name}
                    checked={props.element[name] === undefined ? false : parseInt(props.element[name]) === 0}
                    onChange={setFieldHandler.bind(this, name)}
                /> No
            </div>
        </Fragment>
    );

    const renderTextAreaField = (label, name, className = '', message = '') => (
        <Fragment>
            <div className={'input-label ' + className}>{label} <span className="required">*</span></div>
            <div className={'input-field ' + className}>
                <textarea
                    name={name}
                    value={props.element[name] === undefined ? '' : props.element[name]}
                    onChange={setFieldHandler.bind(this, name)}
                />
                <span className="message">{message}</span>
            </div>
        </Fragment>
    );

    const setDateFieldHandler = (name, event) => {
        let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');
        props.setField(name, value);
    };

    const renderDateInput = (label, name, className = '', message = '') => (
        <Fragment>
            <div className={'input-label ' + className}>{label} <span className="required">*</span></div>
            <div className="input-field">
                <DayPickerInput
                    formatDate={formatDate}
                    parseDate={parseDate}
                    placeholder=""
                    className="date-picker-field"
                    onDayChange={setDateFieldHandler.bind(this, name)}
                    value={props.element[name] == null || props.element[name] === '0000-00-00' ? '' : moment(props.element[name]).format('MM/DD/YYYY')}
                />
                <span className="message">{message}</span>
            </div>
        </Fragment>
    );

    let showMergeFilesDescription = parseInt(props.element.merge_files) === 1;
    let showDedupeFilesBy = parseInt(props.element.dedupe_files) === 1;
    let showDedupeFilesSpecify = parseInt(props.element.dedupe_files) === 1 && parseInt(props.element.dedupe_files_by) === 4;
    let showSelectRecordsSpecify = parseInt(props.element.select_records) === 2;
    let showAddSeedsDescription = parseInt(props.element.add_seeds) === 1;
    let showChangeCaseSpecify = parseInt(props.element.change_case) === 1;
    let showCreateSalutationSpecify = parseInt(props.element.create_salutation) === 1;
    let showAddSpecialFieldDescription = parseInt(props.element.add_special_field) === 1;
    let showAddTrackingCodesSpecify = parseInt(props.element.add_tracking_codes) === 1;
    let showGenerateImagingOutputSpecify = parseInt(props.element.generate_imaging_output) === 1;
    let showGenerateFileOutputSpecify = parseInt(props.element.generate_file_output) === 1;
    let showCustomerProgrammingDescription = parseInt(props.element.custom_programming) === 1;

    let totalFilesOptions = [];
    for (let i = 1; i <= 10; i++) {
        totalFilesOptions.push({ value: i, label: i });
    }

    let content = (
        <Fragment>
            <h4>Data Processing</h4>
            {renderDateInput('Due Date', 'due_date')}
            {renderSelectField('Total Files', 'total_file', totalFilesOptions)}
            {renderTextField('File Location', 'file_location')}
            {renderTextField('Number of Address', 'number_of_address')}
            {renderRadioField('Merge Files', 'merge_files')}
            {showMergeFilesDescription && renderTextField('Name of the Files to Merge', 'merge_files_description', 'secondary-field')}
            {renderRadioField('Dedupe the Files', 'dedupe_files')}
            {showDedupeFilesBy && renderSelectField('Dedupe by', 'dedupe_files_by', [
                { value: 1, label: 'Dedupe by Address Only - 1 per HH' },
                { value: 2, label: 'Dedupe by Exact Name & Address' },
                { value: 3, label: 'Dedupe by Company / Address' },
                { value: 4, label: 'Dedupe by Key Field' },
                { value: 5, label: 'Dedupe using a Supression File' },
            ], 'secondary-field')}
            {showDedupeFilesSpecify && renderTextField('Specify', 'dedupe_files_specify', 'secondary-field')}
            {renderSelectField('Select Records', 'select_records', [
                { value: 1, label: 'Use All Records' },
                { value: 2, label: 'Specific Records' },
            ])}
            {showSelectRecordsSpecify && renderTextField('Specify', 'select_records_specify', 'secondary-field')}
            {renderSelectField('Include Foreign', 'include_foreign', [
                { value: 1, label: 'Domestic addresses ONLY' },
                { value: 2, label: 'Include Foreign Addresses' },
            ])}
            {renderRadioField('CASS/DPV/Suitelink', 'cass_dpv_suitelink')}
            {renderRadioField('NCOALink', 'ncoa_link')}
            {renderRadioField('Add Seeds', 'add_seeds')}
            {showAddSeedsDescription && renderTextField('Seeds to Add', 'add_seeds_description', 'secondary-field', '(If more than 5, please create an Excel file)')}
            {renderRadioField('Add "or Current Resident"', 'add_current_resident')}
            {renderRadioField('Change Case', 'change_case')}
            {showChangeCaseSpecify && renderSelectField('Specify', 'change_case_specify', [
                { value: 1, label: 'All Upper Case' },
                { value: 2, label: 'Upper Lower Case' },
            ], 'secondary-field')}
            {renderRadioField('Genderize', 'genderize')}
            {renderRadioField('Create a Salutation', 'create_salutation')}
            {showCreateSalutationSpecify && renderTextField('Specify', 'create_salutation_specify', 'secondary-field', '(What salutation would you like?)')}
            {renderRadioField('Add a Special Field', 'add_special_field')}
            {showAddSpecialFieldDescription && renderTextField('Be Specific', 'add_special_field_description', 'secondary-field', '(What special field?)')}
            {renderRadioField('Add TRACKING Codes', 'add_tracking_codes')}
            {showAddTrackingCodesSpecify && renderSelectField('Specify Confirmation', 'add_tracking_codes_specify', [
                { value: 1, label: 'Confirm Codes for All Records' },
                { value: 2, label: 'Confirm Codes for First and Last Of' },
                { value: 3, label: 'Confirm Codes for First and Last Tray' },
                { value: 4, label: 'Confirm Codes for First and Last Sack' },
                { value: 5, label: 'Confirm Codes for First and Last Pallet' },
            ], 'secondary-field')}
            {renderRadioField('Generate Output for INKJET', 'generate_inkjet_output')}
            {renderRadioField('Generate Output for IMAGING', 'generate_imaging_output')}
            {showGenerateImagingOutputSpecify && renderSelectField('Specify Which Fields', 'generate_imaging_output_specify', [
                { value: 1, label: 'Standard Output Layout' },
                { value: 2, label: 'Include All Fields' },
            ], 'secondary-field')}
            {renderRadioField('Generate Output for Customer FILE', 'generate_file_output')}
            {showGenerateFileOutputSpecify && renderTextField('Specify Format', 'generate_file_output_specify', 'secondary-field', '(Excel, CVS, etc.)')}
            {renderRadioField('Do you need Custom Programming?', 'custom_programming')}
            {showCustomerProgrammingDescription && renderTextField('Describe', 'custom_programming_description', 'secondary-field', '(Please be descriptive with your customer request)')}
            {renderTextAreaField('Comments', 'comments', 'comments-field')}
        </Fragment>
    );
    if (props.fetching) {
        content = <div><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>
    }

    const saveHandler = () => {
        if(props.callback) {
            props.save(props.callback.bind(this, props.id));
        } else {
            props.save();
        }
    };

    let actions = [];
    if(props.showSave) {
        actions.push(<button key="save-button" className="btn btn-light btn-sm action-button" disabled={props.isSaving} onClick={saveHandler}>{saveButtonLabel}</button>);
    }
    actions.push(<button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={props.hideForm}>Close</button>);

    return (
        <Dialog
            name="pqt-data-processing-form"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="data-processing-dialog"
        >
            <div className="pqt-data-processing-container">{content}</div>
        </Dialog>

    );
}

Form.propTypes = {

}

export default Form;
