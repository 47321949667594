export const FETCH_RECORDS = 'AP_RECORDS/FETCH_RECORDS';
export const FETCH_RECORDS_SUCCESS = 'AP_RECORDS/FETCH_RECORDS_SUCCESS';
export const FETCH_RECORDS_ERROR = 'AP_RECORDS/FETCH_RECORDS_ERROR';
export const DELETE_RECORD = 'AP_RECORDS/DELETE_RECORD';
export const DELETE_RECORD_SUCCESS = 'AP_RECORDS/DELETE_RECORD_SUCCESS';
export const DELETE_RECORD_ERROR = 'AP_RECORDS/DELETE_RECORD_ERROR';
export const FETCH_RECORD = 'AP_ITEMS/FETCH_RECORD';
export const FETCH_RECORD_SUCCESS = 'AP_ITEMS/FETCH_RECORD_SUCCESS';
export const FETCH_RECORD_ERROR = 'AP_ITEMS/FETCH_RECORD_ERROR';
export const SAVE_RECORD = 'AP_ITEMS/SAVE_RECORD';
export const SAVE_RECORD_SUCCESS = 'AP_ITEMS/SAVE_RECORD_SUCCESS';
export const SAVE_RECORD_ERROR = 'AP_ITEMS/SAVE_RECORD_ERROR';
export const RESET_STATE = 'AP_RECORDS/RESET_STATE';
export const SET_ID = 'AP_RECORDS/SET_ID';
export const SET_SORT = 'AP_RECORDS/SET_SORT';
export const SET_ACTIVE_PAGE = 'AP_RECORDS/SET_ACTIVE_PAGE';
export const SET_ID_FOR_DELETE = 'AP_RECORDS/SET_ID_FOR_DELETE';
export const CHANGE_FILTER = 'AP_RECORDS/CHANGE_FILTER';
export const SET_FIELD = 'AP_RECORDS/SET_FIELD';
export const CLEAR_FORM = 'AP_RECORDS/CLEAR_FORM';