import React from 'react';
import FormPopover from './FormPopover';

const BinderyForm = React.memo((props) => {
    let foldingType = (
        <div className="field folding-field">
            <b>Folding Type:</b>
            <FormPopover
                code="BDRY-001"
                information={props.information}
            />
            <select value={props.fields.folding_type} onChange={props.handleSetField.bind(this, 'folding_type')}>
                <option value="">None</option>
                <option value="1">Half Fold</option>
                <option value="40">Tri-fold</option>
                <option value="39">Z Folding</option>
                <option value="43">Accordion</option>
                <option value="42">Double Gatefold</option>
                <option value="41">Gatefold</option>
            </select>
        </div>
    );
    if (parseInt(props.fields.self_cover) === 1 || parseInt(props.fields.plus_cover) === 1) {
        foldingType = null;
    }

    return (
        <div className="bindery-form-container">
            <div className="line1">
                <div className="main-title"><b>Bindery</b></div>
                <div>
                    <div><b>Product Type</b></div>
                    <div>{props.productTypeName}</div>
                </div>
            </div>
            <div className="line2">
                {foldingType}
                <div className="field padding-field">
                    <b>Padding:</b>
                    <FormPopover
                        code="BDRY-002"
                        information={props.information}
                    />
                    <select value={props.fields.padding} onChange={props.handleSetField.bind(this, 'padding')}>
                        <option value="">None</option>
                        <option value="25">25/per Pad</option>
                        <option value="50">50/per Pad</option>
                        <option value="100">100/per Pad</option>
                        <option value="200">200/per Pad</option>
                        <option value="250">250/per Pad</option>
                    </select>
                </div>
                <div className="field shrink-wrapping-field">
                    <b>Shrink Wrapping:</b>
                    <FormPopover
                        code="BDRY-003"
                        information={props.information}
                    />
                    <select value={props.fields.shrink_wrapping} onChange={props.handleSetField.bind(this, 'shrink_wrapping')}>
                        <option value="">None</option>
                        <option value="25">25/per Bundle</option>
                        <option value="50">50/per Bundle</option>
                        <option value="100">100/per Bundle</option>
                        <option value="200">200/per Bundle</option>
                        <option value="250">250/per Bundle</option>
                    </select>
                </div>
                <div className="field drilling-field">
                    <b>Drilling Holes:</b>
                    <FormPopover
                        code="BDRY-004"
                        information={props.information}
                    />
                    <select value={props.fields.drilling_holes} onChange={props.handleSetField.bind(this, 'drilling_holes')}>
                        <option value="">None</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                    </select>
                </div>
                <div className="field holes-field">
                    <b>Holes Location:</b>
                    <FormPopover
                        code="BDRY-005"
                        information={props.information}
                    />
                    <select value={props.fields.holes_location} onChange={props.handleSetField.bind(this, 'holes_location')}>
                        <option value="">None</option>
                        <option value="1">Top Center</option>
                        <option value="2">Left</option>
                        <option value="3">Right</option>
                        <option value="4">Bottom Center</option>
                    </select>
                </div>
                <div className="field scoring-field">
                    <b>Scoring:</b>
                    <FormPopover
                        code="BDRY-006"
                        information={props.information}
                    />
                    <select value={props.fields.scoring} onChange={props.handleSetField.bind(this, 'scoring')}>
                        <option value="">Select option</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                </div>
                <div className="field die-cutting-field">
                    <b>Die-Cutting:</b>
                    <FormPopover
                        code="BDRY-007"
                        information={props.information}
                    />
                    <select value={props.fields.die_cutting} onChange={props.handleSetField.bind(this, 'die_cutting')}>
                        <option value="">Select option</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                </div>
            </div>
            <div className="line3">
                <div className="title"><b>Please describe your project in detail here:</b></div>
                <textarea
                    placeholder="Please let us know more about your project detail"
                    value={props.fields.bindery_description}
                    onChange={props.handleSetField.bind(this, 'bindery_description')}
                />
            </div>
            {props.buttonsContainer}
        </div>
    );
});

export default BinderyForm;
