import { createSelector } from 'reselect';

export const getComponentState = state => state.informedVisibilityJobs;

export const getFetchingJobs = createSelector(
    getComponentState,
    (state) => state.fetchingJobs
);
export const getJobs = createSelector(
    getComponentState,
    (state) => state.jobs
);
export const getPagination = createSelector(
    getComponentState,
    (state) => state.pagination
);
export const getSort = createSelector(
    getComponentState,
    (state) => state.sort
);
export const getFilterFields = createSelector(
    getComponentState,
    (state) => state.filter.fields
);