export const FETCH_DATA = 'PQT_DEPARTMENT_VIEW/FETCH_DATA';
export const FETCH_DATA_SUCCESS = 'PQT_DEPARTMENT_VIEW/FETCH_DATA_SUCCESS';
export const FETCH_DATA_ERROR = 'PQT_DEPARTMENT_VIEW/FETCH_DATA_ERROR';
export const FETCH_INFORMATION = 'PQT_DEPARTMENT_VIEW/FETCH_INFORMATION';
export const FETCH_INFORMATION_SUCCESS = 'PQT_DEPARTMENT_VIEW/FETCH_INFORMATION_SUCCESS';
export const FETCH_INFORMATION_ERROR = 'PQT_DEPARTMENT_VIEW/FETCH_INFORMATION_ERROR';
export const FETCH_JOBS = 'PQT_DEPARTMENT_VIEW/FETCH_JOBS';
export const FETCH_JOBS_SUCCESS = 'PQT_DEPARTMENT_VIEW/FETCH_JOBS_SUCCESS';
export const FETCH_JOBS_ERROR = 'PQT_DEPARTMENT_VIEW/FETCH_JOBS_ERROR';
export const DELETE_TRACKING = 'PQT_DEPARTMENT_VIEW/DELETE_TRACKING';
export const DELETE_TRACKING_SUCCESS = 'PQT_DEPARTMENT_VIEW/DELETE_TRACKING_SUCCESS';
export const DELETE_TRACKING_ERROR = 'PQT_DEPARTMENT_VIEW/DELETE_TRACKING_ERROR';
export const FETCH_DEPARTMENT = 'PQT_DEPARTMENT_VIEW/FETCH_DEPARTMENT';
export const FETCH_DEPARTMENT_SUCCESS = 'PQT_DEPARTMENT_VIEW/FETCH_DEPARTMENT_SUCCESS';
export const FETCH_DEPARTMENT_ERROR = 'PQT_DEPARTMENT_VIEW/FETCH_DEPARTMENT_ERROR';
export const RESET_STATE = 'PQT_DEPARTMENT_VIEW/RESET_STATE';
export const SET_SEARCH_FIELD = 'PQT_DEPARTMENT_VIEW/SET_SEARCH_FIELD';
export const SET_ID = 'PQT_DEPARTMENT_VIEW/SET_ID';
export const SET_SORT = 'PQT_DEPARTMENT_VIEW/SET_SORT';
export const SET_ACTIVE_PAGE = 'PQT_DEPARTMENT_VIEW/SET_ACTIVE_PAGE';
export const SET_SHOW_JOBS = 'PQT_DEPARTMENT_VIEW/SET_SHOW_JOBS';
export const SET_ID_FOR_DELETE = 'PQT_DEPARTMENT_VIEW/SET_ID_FOR_DELETE';