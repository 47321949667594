import React from 'react';
import { Dialog } from './../../common/dialog';
import { empty, showNotification } from './../../../utils';

const ChangeStatusModal = React.memo((props) => {
    const hideHandler = () => {
        props.hideChangeStatusModal();
    };

    const changeEstimateHandler = () => {
        if (empty(props.newStatus)) {
            return showNotification('Complete Information', 'Select the Status', 'info');
        }

        props.changeStatus();
    };

    const handleChange = (event) => {
        props.setNewStatus(event.target.value);
    };

    let changeButtonLabel = 'Change';
    if (props.changingStatus) {
        changeButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Cancel</button>,
        <button key="delete-button" className="btn btn-light btn-sm action-button" disabled={props.changingStatus} onClick={changeEstimateHandler}>{changeButtonLabel}</button>
    ];
    return (
        <Dialog
            name="change-status-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="change-status-dialog"
        >
            <div className="change-status-container">
                <h1>Change Status</h1>
                <div className="new-status-form-container">
                    <div className="field">
                        <div><b>Status:</b></div>
                        <select value={props.newStatus} onChange={handleChange}>
                            <option value="">Select option</option>
                            <option value="1">Draft</option>
                            <option value="4">Estimated (Unapproved)</option>
                            <option value="2">Approved</option>
                            <option value="3">Converted to SC Job</option>
                        </select>
                    </div>
                </div>
            </div>
        </Dialog >
    );
});

export default ChangeStatusModal;
