import axios from 'axios';
import * as types from './PqnPresses.types';
import * as selectors from './PqnPresses.selectors';
import { validateAuthInResponse, showNotification, empty } from './../../utils';
import * as dialog from '../common/dialog';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchElements = () => (dispatch, getState) => {
    const sort = selectors.getSort(getState());
    const pagination = selectors.getPagination(getState());
    const filerFields = selectors.getFilterFields(getState());

    let params = [];
    params.push('sort=' + (sort.type === 'asc' ? '' : '-') + sort.column);
    params.push('active-page=' + pagination.activePage);
    params.push('elements-per-page=' + pagination.elementsPerPage);

    if (filerFields.length > 0) {
        let filterElements = [];
        for (let i = 0; i < filerFields.length; i++) {
            filterElements.push(filerFields[i]['name'] + '=' + filerFields[i]['value']);
        }
        params.push('filter=' + filterElements.join(','))
    } else {
        params.push('filter=-')
    }

    dispatch({ type: types.FETCH_ELEMENTS, payload: null });
    axios.get(apiUrl + '/press/admin-index' + (params.length === 0 ? '' : '?' + params.join('&')),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_ELEMENTS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_ELEMENTS_ERROR));
        });
}

export const fetchElement = (id) => (dispatch, getState) => {
    dispatch({ type: types.FETCH_ELEMENT, payload: null });
    axios.get(apiUrl + '/presses/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_ELEMENT_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_ELEMENT_ERROR));
        });
}

export const saveElement = () => (dispatch, getState) => {
    const fields = selectors.getFields(getState());
    if (!validateForm(fields)) {
        return;
    }

    let endpoint = apiUrl + '/presses';
    let method = 'post';
    if (fields.id && fields.id != null) {
        endpoint = apiUrl + '/presses/' + fields.id;
        method = 'put';
    }

    dispatch({ type: types.SAVE_ELEMENT, payload: null });
    axios({
        method: method,
        url: endpoint,
        data: fields,
        headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
    }).then((response) => {
        dispatch({ type: types.SAVE_ELEMENT_SUCCESS, payload: response.data });
        showNotification('Element Saved', 'The Element has been saved successfully', 'success');
        dispatch(fetchElements());
        dispatch(dialog.actions.hideDialog('pqn-press-form-modal'));
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.SAVE_ELEMENT_ERROR));
    });
}

export const deleteElement = () => (dispatch, getState) => {
    const id = selectors.getIdForDelete(getState());
    dispatch({ type: types.DELETE_ELEMENT, payload: null });
    axios.delete(apiUrl + '/presses/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.DELETE_ELEMENT_SUCCESS, payload: response.data });
            dispatch(fetchElements());
            dispatch(dialog.actions.hideDialog('delete-modal'));
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.DELETE_ELEMENT_ERROR));
        });
}

export const validateForm = (fields) => {
    let fieldsToValidate = [
        'name',
        'labor_rate',
        'labor_markup_rate',
        'setup_cost',
        'plate_change_cost',
        'plate_cost',
        'ink_units',
        'gripper_margin',
        'color_bar_margin',
        'setup_time',
        'left_right_tail_trim',
        'is_perfecting',
        'is_active',
        'max_paper_width',
        'max_paper_length',
        'min_paper_width',
        'min_paper_length',
        'makeready_spoilage_flat',
        'running_spoilage_flat',
        'makeready_spoilage_wt',
        'running_spoilage_wt',
        'makeready_spoilage_sw',
        'running_spoilage_sw',
    ];
    for (let i = 0; i < fieldsToValidate.length; i++) {
        if (empty(fields[fieldsToValidate[i]])) {
            showNotification('Complete Information', 'Please, enter the information of all the required fields', 'info');
            return false;
        }
    }
    return true;
}

export const setSort = (column, type) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_SORT,
            payload: {
                column: column,
                type: type,
            }
        });
        resolve();
    }).then(() => {
        dispatch(fetchElements());
    });
}

export const setActivePage = (value) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_ACTIVE_PAGE,
            payload: value
        });
        resolve();
    }).then(() => {
        dispatch(fetchElements());
    });
}

export const setIdForDelete = (id) => (dispatch) => {
    dispatch({
        type: types.SET_ID_FOR_DELETE,
        payload: id
    });
}

export const setField = (field, value) => (dispatch) => {
    dispatch({
        type: types.SET_FIELD,
        payload: { field, value }
    });
}

export const changeFilter = (name, value, fetch = false) => (dispatch) => {
    new Promise((resolve) => {
        dispatch({
            type: types.CHANGE_FILTER,
            payload: { name, value }
        });
        resolve();
    }).then(() => {
        if (fetch) {
            dispatch(fetchElements());
        }
    });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null
    });
}

export const resetFields = () => (dispatch) => {
    dispatch({
        type: types.RESET_FIELDS,
        payload: null
    });
}