import { createSelector } from 'reselect';

export const getComponentState = state => state.pqtPostageInvoices;

/* fetching */
export const getFetching = createSelector(
    getComponentState,
    (state) => state.fetching
);
export const getFetchingInvoices = createSelector(
    getComponentState,
    (state) => state.fetching.invoices
);

/* data */
export const getInvoicesData = createSelector(
    getComponentState,
    (state) => state.data.invoices
);

/* pagination */
export const getPagination = createSelector(
    getComponentState,
    (state) => state.pagination
);

/* sort */
export const getSort = createSelector(
    getComponentState,
    (state) => state.sort
);

/* delete */
export const getIdForDelete = createSelector(
    getComponentState,
    (state) => state.delete.id
);
export const getIsDeleting = createSelector(
    getComponentState,
    (state) => state.delete.isDeleting
);

export const getShow = createSelector(
    getComponentState,
    (state) => state.show
);

export const getFilterFields = createSelector(
    getComponentState,
    (state) => state.filter.fields
);