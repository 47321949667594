import Immutable from 'immutable';
import * as types from './PqtPostageReport.types';

const initialState = {
    fetchingReport: false,
    reportData: null,
    exporting: false,
    reportType: '',
    customer: 0,
    customers: [],
    fetchingCustomers: false,
    fromDate: null,
    toDate: null,
    salesRep: 0,
    fetchingInformation: false,
    information: {
        salesReps: [],
    },
    exportingPostageReport: false,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_REPORT:
            return state.setIn(['fetchingReport'], !action.payload).toJS();

        case types.FETCH_REPORT_SUCCESS:
            return state.setIn(['fetchingReport'], false)
                .setIn(['reportData'], action.payload)
                .toJS();

        case types.FETCH_REPORT_ERROR:
            return state.setIn(['fetchingReport'], false).toJS();

        case types.EXPORT_REPORT:
            return state.setIn(['exporting'], !action.payload).toJS();

        case types.EXPORT_REPORT_SUCCESS:
            return state.setIn(['exporting'], false).toJS();

        case types.EXPORT_REPORT_ERROR:
            return state.setIn(['exporting'], false).toJS();

        case types.EXPORT_POSTAGE_REPORT:
            return state.setIn(['exportingPostageReport'], !action.payload).toJS();

        case types.EXPORT_POSTAGE_REPORT_SUCCESS:
            return state.setIn(['exportingPostageReport'], false).toJS();

        case types.EXPORT_POSTAGE_REPORT_ERROR:
            return state.setIn(['exportingPostageReport'], false).toJS();

        case types.FETCH_CUSTOMERS:
            return state.setIn(['fetchingCustomers'], true).toJS();

        case types.FETCH_CUSTOMERS_SUCCESS:
            return state.setIn(['fetchingCustomers'], false)
                .setIn(['customers'], action.payload)
                .toJS();

        case types.FETCH_CUSTOMERS_ERROR:
            return state.setIn(['fetchingCustomers'], false).toJS();

        case types.FETCH_INFORMATION:
            return state.setIn(['fetchingInformation'], !action.payload).toJS();

        case types.FETCH_INFORMATION_SUCCESS:
            return state.setIn(['fetchingInformation'], false)
                .setIn(['information', 'salesReps'], action.payload.salesReps)
                .toJS();

        case types.FETCH_INFORMATION_ERROR:
            return state.setIn(['fetchingInformation'], false).toJS();

        case types.SET_REPORT_TYPE:
            return state.setIn(['reportType'], action.payload).toJS();

        case types.SET_CUSTOMER:
            return state.setIn(['customer'], action.payload).toJS();

        case types.SET_FROM_DATE:
            return state.setIn(['fromDate'], action.payload).toJS();

        case types.SET_TO_DATE:
            return state.setIn(['toDate'], action.payload).toJS();

        case types.SET_SALES_REP:
            return state.setIn(['salesRep'], action.payload).toJS();

        default:
            return state.toJS();
    }
}