import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from './Table';
import FormModal from './FormModal';
import DeleteModal from './DeleteModal';
import * as actions from './MailingCategories.actions';
import * as selectors from './MailingCategories.selectors';
import './MailingCategories.scss';
import PqtLayout from './../PqtLayout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import * as dialog from '../common/dialog';
import * as loginSelectors from './../Login/Login.selectors';
import { Redirect } from 'react-router-dom';

const MailingCategories = (props) => {
    /* State to props */
    const category = useSelector(selectors.getCategory);
    const isSaving = useSelector(selectors.getIsSaving);
    const fetchingCategories = useSelector(selectors.getFetchingCategories);
    const categories = useSelector(selectors.getCategories, stringifyEqualityCheck);
    const pagination = useSelector(selectors.getPagination, stringifyEqualityCheck);
    const sort = useSelector(selectors.getSort, stringifyEqualityCheck);
    const idForDelete = useSelector(selectors.getIdForDelete, stringifyEqualityCheck);
    const isDeleting = useSelector(selectors.getIsDeleting, stringifyEqualityCheck);
    const filterFields = useSelector(selectors.getFilterFields, stringifyEqualityCheck);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const deleteCategory = useCallback((id) => dispatch(actions.deleteCategory(id)), [dispatch]);
    const setSort = useCallback((column, type) => dispatch(actions.setSort(column, type)), [dispatch]);
    const setActivePage = useCallback((name, value) => dispatch(actions.setActivePage(name, value)), [dispatch]);
    const setIdForDelete = useCallback((id) => dispatch(actions.setIdForDelete(id)), [dispatch]);
    const changeFilter = useCallback((name, value) => dispatch(actions.changeFilter(name, value)), [dispatch]);
    const setCategory = useCallback((value) => dispatch(actions.setCategory(value)), [dispatch]);
    const setField = useCallback((field, value) => dispatch(actions.setField(field, value)), [dispatch]);
    const saveCategory = useCallback(() => dispatch(actions.saveCategory()), [dispatch]);
    const showDeleteModal = useCallback(() => dispatch(dialog.actions.showDialog('delete-modal')), [dispatch]);
    const hideDeleteModal = useCallback(() => dispatch(dialog.actions.hideDialog('delete-modal')), [dispatch]);
    const showFormModal = useCallback(() => dispatch(dialog.actions.showDialog('form-modal')), [dispatch]);
    const hideFormModal = useCallback(() => dispatch(dialog.actions.hideDialog('form-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        dispatch(actions.mountComponent());
    }, [dispatch]);

    // Hugh (61)
    if (user != null && ![61].includes(parseInt(user.id))) {
        return <Redirect to="/dashboard" />;
    }

    return (
        <PqtLayout title="Mailing Categories" history={props.history}>
            <Helmet><title>Mailing Categories - Stagecoach</title></Helmet>
            <div className="mailing-categories-container">
                <Table
                    categories={categories}
                    pagination={pagination}
                    fetchingCategories={fetchingCategories}
                    sort={sort}
                    user={user}
                    filterFields={filterFields}
                    history={props.history}
                    setSort={setSort}
                    setCategory={setCategory}
                    setActivePage={setActivePage}
                    showDeleteModal={showDeleteModal}
                    setIdForDelete={setIdForDelete}
                    changeFilter={changeFilter}
                    showFormModal={showFormModal}
                />
                <DeleteModal
                    idForDelete={idForDelete}
                    isDeleting={isDeleting}
                    deleteCategory={deleteCategory}
                    hideDeleteModal={hideDeleteModal}
                    setIdForDelete={setIdForDelete}
                />
                <FormModal
                    categories={categories}
                    isSaving={isSaving}
                    category={category}
                    setCategory={setCategory}
                    setField={setField}
                    hideFormModal={hideFormModal}
                    saveCategory={saveCategory}
                />
            </div>
        </PqtLayout>
    );
};

export default MailingCategories;
