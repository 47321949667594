import { createSelector } from 'reselect';

export const getComponentState = state => state.pqtPostageReport;

export const getFetchingReport = createSelector(
    getComponentState,
    (state) => state.fetchingReport
);
export const getReportData = createSelector(
    getComponentState,
    (state) => state.reportData
);
export const getExporting = createSelector(
    getComponentState,
    (state) => state.exporting
);
export const getExportingPostageReport = createSelector(
    getComponentState,
    (state) => state.exportingPostageReport
);
export const getReportType = createSelector(
    getComponentState,
    (state) => state.reportType
);
export const getCustomer = createSelector(
    getComponentState,
    (state) => state.customer
);
export const getCustomers = createSelector(
    getComponentState,
    (state) => state.customers
);
export const getFetchingCustomers = createSelector(
    getComponentState,
    (state) => state.fetchingCustomers
);
export const getFromDate = createSelector(
    getComponentState,
    (state) => state.fromDate
);
export const getToDate = createSelector(
    getComponentState,
    (state) => state.toDate
);
export const getFetchingInformation = createSelector(
    getComponentState,
    (state) => state.fetchingInformation
);
export const getInformation = createSelector(
    getComponentState,
    (state) => state.information
);
export const getSalesRep = createSelector(
    getComponentState,
    (state) => state.salesRep
);