import Immutable from 'immutable';
import * as types from './MakePayment.types';

const initialFields = {
    invoice_number: '',
    customer_id: '',
    total: '',
    email: '',
    payment_type: 'CreditCard',
    card_number: '',
    expiration_month: '',
    expiration_year: '',
    cvv: '',
    routing_number: '',
    account_number: '',
    ach_account_type: 'Checking',
    first_name: '',
    last_name: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    country: '',
    zip: '',
    phone: '',

    // invoice_number: '58581',
    // customer_id: 'Test',
    // total: 100,
    // email: 'diego@acm.com',
    // payment_type: 'CreditCard',
    // card_number: '4444333322221111',
    // expiration_month: '02',
    // expiration_year: '2025',
    // cvv: '123',
    // routing_number: '555555550',
    // account_number: '987654321',
    // ach_account_type: 'Checking',
    // first_name: 'Diego',
    // last_name: 'Salazar',
    // address1: 'Ella',
    // address2: 'Blv.',
    // city: 'Houston',
    // state: 'TX',
    // country: 'US',
    // zip: '77041',
    // phone: '111-222-3333',
};

const initialState = {
    fields: initialFields,
    payment: null,
    fetchingPayment: false,
    generatingToken: false,
    savingPayment: false,
    token: null,
    invoiceToken: null,
    paymenType: null,
    recaptchaToken: null,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_PAYMENT:
            return state.setIn(['fetchingPayment'], true).toJS();

        case types.FETCH_PAYMENT_SUCCESS:
            return state.setIn(['fetchingPayment'], false)
                .setIn(['payment'], action.payload)
                .toJS();

        case types.FETCH_PAYMENT_ERROR:
            return state.setIn(['fetchingPayment'], false).toJS();

        case types.GENERATE_TOKEN:
            return state.setIn(['generatingToken'], true).toJS();

        case types.GENERATE_TOKEN_SUCCESS:
            return state.setIn(['generatingToken'], false).toJS();

        case types.GENERATE_TOKEN_ERROR:
            return state.setIn(['generatingToken'], false).toJS();

        case types.SAVE_PAYMENT:
            return state.setIn(['savingPayment'], true).toJS();

        case types.SAVE_PAYMENT_SUCCESS:
            return state.setIn(['savingPayment'], false).toJS();

        case types.SAVE_PAYMENT_ERROR:
            return state.setIn(['savingPayment'], false).toJS();

        case types.SET_TOKEN:
            return state.setIn(['token'], action.payload).toJS();

        case types.SET_INVOICE_TOKEN:
            return state.setIn(['invoice_token'], action.payload).toJS();

        case types.SET_PAYMENT_TYPE:
            return state.setIn(['paymenType'], action.payload).toJS();

        case types.SET_RECAPTCHA_TOKEN:
            return state.setIn(['recaptchaToken'], action.payload).toJS();

        case types.SET_FIELD:
            return state.setIn(['fields', action.payload.field], action.payload.value).toJS();

        case types.RESET_FIELDS:
            return state.setIn(['fields'], initialFields).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}