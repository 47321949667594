import React from 'react';
import { Dialog } from './../../common/dialog';

const SubmittedModal = React.memo((props) => {
    const hideHandler = () => {
        props.hideSubmittedModal();
    };

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Close</button>,
    ];
    return (
        <Dialog
            name="submitted-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="submitted-dialog"
        >
            <div className="submitted-container">
                <h1>Your Request for Quote has been sent</h1>
                <p>
                    Your request for quote has been saved successfully. Your Request number is <span className="number">#{props.submittedId}</span>.
                    An email has been sent to your email address with the details of your request.
                </p>
            </div>
        </Dialog >
    );
});

export default SubmittedModal;
