import React, { Component } from 'react';
import Form from './Form';
import { connect } from 'react-redux';
import * as actions from './Login.actions';
import * as selectors from './Login.selectors';
import PropTypes from 'prop-types';
import './Login.scss';
import { Redirect } from 'react-router-dom';
import Favicon from 'react-favicon';

class Login extends Component {
    render() {
        if (this.props.redirect && this.props.location.pathname === '/login') {
            this.props.setRedirect(false);

            if (this.props.user && this.props.user.is_pqt_accounting) {
                return <Redirect to={'/invoices'} />;
            } else if ([7].includes(parseInt(this.props.user && this.props.user.pqt_type))) {
                return <Redirect to={'/department/' + this.props.user.department_id} />;
            } else if ([3].includes(parseInt(this.props.user && this.props.user.pqt_type))) {
                return <Redirect to={'/dashboard'} />;
            } else if ([6].includes(parseInt(this.props.user && this.props.user.pqt_type))) {
                return <Redirect to={'/department/' + this.props.user.pqt_department} />;
            } else {
                return <Redirect to={'/dashboard'} />;
            }
        } else if (this.props.redirect && this.props.location.pathname === '/vdp-login') {
            this.props.setRedirect(false);
            return <Redirect to={'/vdp-dashboard'} />;
        }

        return (
            <div className="login-container">
                <Favicon url="https://s3.amazonaws.com/ac-pqn/favicon.ico" />
                <Form
                    form={this.props.form}
                    login={this.props.login}
                    setField={this.props.setField}
                    isLogging={this.props.isLogging}
                    restorePassword={this.props.restorePassword}
                    location={this.props.location}
                />
            </div>
        );
    }
}

Login.propTypes = {
    isLogging: PropTypes.bool.isRequired,
    setField: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    redirect: PropTypes.bool,
    setRedirect: PropTypes.func.isRequired,
    restorePassword: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    isLogging: selectors.getIsLogging(state),
    form: selectors.getForm(state),
    redirect: selectors.getRedirect(state),
    user: selectors.getUser(state)
});

const mapDispatchToProps = (dispatch) => ({
    login: loginType => dispatch(actions.login(loginType)),
    setField: (name, value) => dispatch(actions.setField(name, value)),
    setRedirect: () => dispatch(actions.setRedirect()),
    restorePassword: () => dispatch(actions.restorePassword())
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
