import React from 'react';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import PqtPagination from './../../PqtPagination';
import { dateToTimezone } from './../../../utils';
import moment from 'moment-timezone';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';

const Reports = React.memo((props) => {
    const confirmHandler = (id) => {
        props.showDeleteModal();
        props.setIdForDelete(id);
    };

    const handleShowReport = (id) => {
        props.fetchReport(id);
        props.showFormModal();
    };

    const handleShowResult = (id) => {
        props.fetchReport(id);
        props.showResultModal();
    };

    const renderReports = () => {
        if (props.fetchingReports) {
            return (
                <tr>
                    <td colSpan="8"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.reports.length === 0) {
            return (
                <tr>
                    <td colSpan="8">No Results</td>
                </tr>
            );
        }

        return props.reports.map((report, index) => {
            let payrollDate = '-';
            if (report.date != null) {
                payrollDate = dateToTimezone(report.date, 'MM/DD/YYYY');
            }

            let iconColor = 'icon-success';
            if (parseFloat(report.total_difference) !== 0) {
                iconColor = 'icon-error';
            }

            let status = '';
            if (parseInt(report.status) === 1) {
                status = 'Pending';
            } else if (parseInt(report.status) === 2) {
                status = 'Pushed to QB';
            }

            return (
                <tr key={'report-' + index}>
                    <td>{report.id}</td>
                    <td>{payrollDate}</td>
                    <td>{report.title}</td>
                    {/* <td>{report.memo}</td> */}
                    <td>{status}</td>
                    <td>
                        <div className="actions">
                            <Tooltip title="Report Result" position="bottom" arrow size="small" className="action-tooltip">
                                <i className={['fas fa-file-invoice', iconColor].join(' ')} onClick={handleShowResult.bind(this, report.id)} />
                            </Tooltip>
                            <Tooltip title="Report Details" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-file-alt" onClick={handleShowReport.bind(this, report.id)} />
                            </Tooltip>
                            <Tooltip title="Download Original Report" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-download" onClick={props.downloadReport.bind(this, report.id, 1)} />
                            </Tooltip>
                            <Tooltip title="Download Processed Report" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-file-download" onClick={props.downloadReport.bind(this, report.id, 2)} />
                            </Tooltip>
                            <Tooltip title="Delete Report" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-trash" onClick={confirmHandler.bind(this, report.id)} />
                            </Tooltip>
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const setSortHandler = (field, value) => {
        props.setSort(field, value);
    };

    const renderHeader = (name, column, className) => {
        let sortFunc = setSortHandler.bind(this, column, 'asc');
        let sortIcon = <i className="fas fa-sort" />;
        if (props.sort.column === column) {
            if (props.sort.type === 'asc') {
                sortFunc = setSortHandler.bind(this, column, 'desc');
                sortIcon = <i className="fas fa-sort-up" />;
            } else {
                sortFunc = setSortHandler.bind(this, column, 'asc');
                sortIcon = <i className="fas fa-sort-down" />;
            }
        }

        return <th className={className} onClick={sortFunc}>{name} {sortIcon}</th>;
    };

    const handleChangeFilter = (name, fetch, event) => {
        props.changeFilter(name, event.target.value, fetch);
    };

    const handleKey = (event) => {
        if (event.key === 'Enter') {
            props.fetchReports();
        }
    };

    const renderSearchField = (name) => {
        let value = null;
        for (let i = 0; i < props.filerFields.length; i++) {
            if (props.filerFields[i].name === name) {
                value = props.filerFields[i].value;
            }
        }

        return (
            <th className="search-th">
                <input
                    type="text"
                    name={name}
                    className="search-input"
                    value={value}
                    onKeyPress={handleKey}
                    onChange={handleChangeFilter.bind(this, name, false)}
                />
            </th>
        );
    };

    const setDateFieldHandler = (name, event) => {
        let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');
        props.changeFilter(name, value, true);
    };

    const renderSearchDateField = (name) => {
        let value = null;
        for (let i = 0; i < props.filerFields.length; i++) {
            if (props.filerFields[i].name === name) {
                value = props.filerFields[i].value;
            }
        }

        return (
            <th className="search-th">
                <DayPickerInput
                    formatDate={formatDate}
                    parseDate={parseDate}
                    placeholder=""
                    className="date-picker-field"
                    onDayChange={setDateFieldHandler.bind(this, name)}
                    value={value == null || value === '0000-00-00' ? '' : moment(value).format('MM/DD/YYYY')}
                />
            </th>
        )
    };

    return (
        <div className="reports-container">
            <div className="actions-container">
                <button className="upload-button" onClick={props.showFormModal}><i className="fas fa-plus-square" /> New Payroll Report</button>
            </div>
            <div className="table-container">
                <table className="table table-sm table-hover table-striped">
                    <thead>
                        <tr>
                            {renderHeader('No.', 'id', 'th-id')}
                            {renderHeader('Payroll Date', 'date', 'th-date')}
                            {renderHeader('Title', 'title', 'th-title')}
                            {/* {renderHeader('Memo', 'memo', 'th-memo')} */}
                            {renderHeader('Status', 'status', 'th-status')}
                            <th style={{ width: 160 }}></th>
                        </tr>
                        <tr>
                            {renderSearchField('id')}
                            {renderSearchDateField('date')}
                            {renderSearchField('title')}
                            {/* {renderSearchField('memo')} */}
                            {renderSearchField('status')}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderReports()}
                    </tbody>
                </table>
                <PqtPagination
                    pagination={props.pagination}
                    setActivePage={props.setActivePage}
                />
            </div>
        </div>
    );
});

export default Reports;
