import React, { Fragment } from 'react';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import numeral from 'numeral';
import { dateToTimezone, empty } from './../../../utils';
import { validateForm } from './../PqtInvoice.actions';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import TextareaAutosize from 'react-textarea-autosize';

const Form = React.memo((props) => {
    if (props.fetching || props.information.fetching || props.invoice == null) {
        return (
            <div className="invoice-form-container">
                <i className="fas fa-circle-notch fa-spin" /> Loading data...
            </div>
        );
    }

    const handleOnChange = (field, event) => {
        let value = null;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        } else {
            value = event.target.value;
        }
        props.setField(field, value);

        if (field === 'use_customer_address' && value) {
            props.setField('billing_name', props.invoice.customerAddress.display_name);
            props.setField('billing_attn', props.invoice.customerAddress.name);
            props.setField('billing_address', props.invoice.customerAddress.street);
            props.setField('billing_city', props.invoice.customerAddress.city);
            props.setField('billing_state', props.invoice.customerAddress.state);
            props.setField('billing_zip', props.invoice.customerAddress.zip);

            if (props.invoice.use_bill_address) {
                props.setField('shipping_name', props.invoice.customerAddress.display_name);
                props.setField('shipping_attn', props.invoice.customerAddress.name);
                props.setField('shipping_address', props.invoice.customerAddress.street);
                props.setField('shipping_city', props.invoice.customerAddress.city);
                props.setField('shipping_state', props.invoice.customerAddress.state);
                props.setField('shipping_zip', props.invoice.customerAddress.zip);
            }
        }

        if (field === 'use_bill_address' && value) {
            props.setField('shipping_name', props.invoice.billing_name);
            props.setField('shipping_attn', props.invoice.billing_attn);
            props.setField('shipping_address', props.invoice.billing_address);
            props.setField('shipping_city', props.invoice.billing_city);
            props.setField('shipping_state', props.invoice.billing_state);
            props.setField('shipping_zip', props.invoice.billing_zip);
        }

        if (props.invoice.use_bill_address) {
            if (field === 'billing_name') {
                props.setField('shipping_name', value);
            } else if (field === 'billing_attn') {
                props.setField('shipping_attn', value);
            } else if (field === 'billing_address') {
                props.setField('shipping_address', value);
            } else if (field === 'billing_city') {
                props.setField('shipping_city', value);
            } else if (field === 'billing_state') {
                props.setField('shipping_state', value);
            } else if (field === 'billing_zip') {
                props.setField('shipping_zip', value);
            }
        }
    };

    let stagecoachLink = 'N/A';
    if (!(props.invoice.stagecoach_number == null || props.invoice.stagecoach_number === '')) {
        stagecoachLink = <Link to={'/tracking/' + props.invoice.stagecoach_number}>{props.invoice.stagecoach_number}</Link>
    }

    const handleUpdateItem = (index, field, event) => {
        let value = null;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        } else {
            value = event.target.value;
        }

        props.setItemField(index, field, value);
    };

    const handleUpdatePrice = (index, field, invoiceClassId, event) => {
        let value = parseFloat(event.target.value);

        if (([56, 77, 80, 82].includes(parseInt(invoiceClassId))) && value > 0) {      // Prepaid Postage or Payment for Services
            value = -value;
        }

        props.setItemField(index, field, value);
    };

    const renderClassesOptions = (departmentId) => {
        return props.information.classes.map((item) => {
            if (parseInt(item.department_id) !== parseInt(departmentId)) {
                return null;
            }

            return <option key={item.id} value={item.id}>{item.name}</option>;
        });
    };

    const handleChangeDepartment = (index, event) => {
        props.setItemField(index, 'department_id', event.target.value);
    };

    const isPreliminary = parseInt(props.invoice.status) === 1;

    const handleSortUp = (index, currentPosition) => {
        currentPosition = parseInt(currentPosition);
        props.changeSortPosition(index, currentPosition - 1, 1);
    };

    const handleSortDown = (index, currentPosition) => {
        currentPosition = parseInt(currentPosition);
        props.changeSortPosition(index, currentPosition + 1, 2);
    };

    const checkIsFirstSortable = (index) => {
        for (let i = 0; i < index; i++) {
            const departmentId = parseInt(props.invoice.items[i].department_id);
            if (departmentId !== 62 && departmentId !== 56 && departmentId !== 60 && departmentId !== 70) {   // Description or Prepaid Postage or Postage or Payment for Services
                return false;
            }
        }
        return true;
    };

    const checkIsLastSortable = (index) => {
        for (let i = index + 1; i < props.invoice.items.length; i++) {
            const departmentId = parseInt(props.invoice.items[i].department_id);
            if (departmentId !== 62 && departmentId !== 56 && departmentId !== 60 && departmentId !== 70) {   // Description or Prepaid Postage or Postage or Payment for Services
                return false;
            }
        }
        return true;
    };

    const renderItems = (index) => {
        let totalShow = 0;
        for (const item of props.invoice.items) {
            if (item.show) {
                totalShow++;
            }
        }

        const itemsElements = [];
        for (let i = 0; i < props.invoice.items.length; i++) {
            let disableFields = false;
            let isDescription = false;
            const departmentId = parseInt(props.invoice.items[i].department_id);
            if (isPreliminary && ([17, 56, 60, 63, 64, 65, 66, 67, 70, 71].includes(departmentId))) {
                disableFields = true;
            } else if (departmentId === 62) {
                isDescription = true;
            }

            if (departmentId === 71 && !validateFinancialServicesPermissions(departmentId)) {
                disableFields = true;
            }

            let disableTax = false;
            // if (departmentId === 56 || departmentId === 60) {   // Prepaid Postage or Postage
            //     disableTax = true;
            // }

            let quantity = props.invoice.items[i].quantity;
            let price = props.invoice.items[i].price;
            let taxable = props.invoice.items[i].taxable;
            let amount = props.invoice.items[i].amount;
            if (isDescription) {
                quantity = '';
                price = '';
                amount = '';
                taxable = 0;
            }

            let deleteIcon = null;
            if (props.user != null && [61, 2419, 28].includes(parseInt(props.user.id))) {     // Hugh (61), Stephanie (2419), Heather (28)
                deleteIcon = (
                    <Tooltip title="Remove Line" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="fas fa-trash-alt" onClick={props.removeLine.bind(this, i)} />
                    </Tooltip>
                );
            }

            if (departmentId === 71 && validateFinancialServicesPermissions(departmentId)) {
                if (parseInt(props.user.id) === 61) {   // Hugh
                    deleteIcon = (
                        <Tooltip title="Remove Line" position="bottom" arrow size="small" className="action-tooltip">
                            <i className="fas fa-trash-alt" onClick={props.removeLine.bind(this, i)} />
                        </Tooltip>
                    );
                } else {
                    let totalPostage = 0;
                    for (let j = 0; j < props.invoice.items.length; j++) {
                        const departmentId2 = parseInt(props.invoice.items[j].department_id);
                        if (departmentId2 === 60) {     // Postage Sales
                            totalPostage += props.invoice.items[j].amount;
                        } else if (departmentId2 === 56) {     // Prepaid Postage
                            totalPostage += props.invoice.items[j].amount;
                        } else if (departmentId2 === 64) {     // Customer Permit
                            totalPostage += props.invoice.items[j].amount;
                        } else if (departmentId2 === 67) {     // ACM Paid Postage
                            totalPostage += props.invoice.items[j].amount;
                        }
                    }

                    if (totalPostage === 0) {
                        deleteIcon = (
                            <Tooltip title="Remove Line" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-trash-alt" onClick={props.removeLine.bind(this, i)} />
                            </Tooltip>
                        );
                    }
                }
            }

            // let pqtType = parseInt(props.user.pqt_type);
            // if ([4, 5].includes(pqtType) && departmentId === 17) {     // CSR (4), Sales (5), and Shipping department
            //     deleteIcon = null;
            // }

            let upArrow = <div className="sort-up"><i className="fas fa-sort-up" onClick={handleSortUp.bind(this, i, props.invoice.items[i].sort_position)} /></div>;
            let downArrow = <div className="sort-down"><i className="fas fa-sort-down" onClick={handleSortDown.bind(this, i, props.invoice.items[i].sort_position)} /></div>;
            if ([56, 60, 62, 64, 65, 66, 67, 70, 71].includes(departmentId)) {
                upArrow = <i className="fas fa-lock" />;
                downArrow = null;
            }
            if (![56, 60, 62, 64, 65, 66, 67, 70, 71].includes(departmentId)) {
                if (i === 0 || checkIsFirstSortable(i)) {
                    upArrow = <div className="sort-up"></div>;
                }
                if (i === props.invoice.items.length - 1 || checkIsLastSortable(i)) {
                    downArrow = null;
                }
            }

            const acmPrepaidDisabled = parseInt(props.invoice.items[i].department_id) === 67;

            let minimumPriceContainer = null;
            let minimumAmountContainer = null;
            let minimumPriceFieldClass = '';
            let minimumAmountFieldClass = '';
            if (props.user.id !== 61 && !empty(props.invoice.items[i]['minimum_price'])) {
                const minimumAmount = parseFloat(parseFloat(props.invoice.items[i]['minimum_amount']).toFixed(2));

                let amountFloat = 0;
                if (!isNaN(amount) && !empty(amount)) {
                    amountFloat = parseFloat(amount);
                }
                let minimumAmountClass = 'min-amount';
                if (amountFloat < minimumAmount) {
                    minimumAmountFieldClass = 'field-below-min';
                    minimumAmountClass = 'min-amount below-min';
                }
                minimumAmountContainer = <div className={minimumAmountClass}>Min: {numeral(minimumAmount).format('$0,0.00')}</div>;
            }

            itemsElements[i] = {
                departmentId: props.invoice.items[i].department_id,
                element: (
                    <tr key={i}>
                        {/* <td>{i + 1}</td> */}
                        <td>{props.invoice.items[i].sort_position}</td>
                        <td>
                            {upArrow}
                            {downArrow}
                        </td>
                        <td>
                            <select value={props.invoice.items[i].department_id} onChange={handleChangeDepartment.bind(this, i)} disabled={disableFields}>
                                <option value="" className="select-option">Select option</option>
                                <option value="62">Description</option>
                                <option value="23">Art &amp; Graphics</option>
                                <option value="8">Data Processing</option>
                                <option value="9">Digital</option>
                                <option value="1">Press</option>
                                <option value="7">Bindery</option>
                                <option value="36">Mail Services</option>
                                <option value="26">Handwork</option>
                                <option value="27">Large Format</option>
                                <option value="15">Fulfillment</option>
                                <option value="68">ACM Costs</option>
                                <option value="59">Promotional</option>
                                <option value="17">Shipping</option>
                                <option value="61">Notes</option>
                                <option value="71" className="postage-option">Financial Services</option>
                                <option value="60" className="postage-option">Postage Sales</option>
                                <option value="63" className="postage-option">Convenience Fee</option>
                                <option value="64" className="postage-option">Customer Permit</option>
                                {/* <option value="65" className="postage-option">Meter</option>
                                <option value="66" className="postage-option">Stamp</option> */}
                                <option value="56" className="postage-option">Prepaid Postage</option>
                                <option value="67" className="postage-option">ACM Paid Postage</option>
                                <option value="70" className="postage-option">Payment for Services</option>
                            </select>
                        </td>
                        <td>
                            <select value={props.invoice.items[i].invoice_class_id} onChange={handleUpdateItem.bind(this, i, 'invoice_class_id')} disabled={disableFields || acmPrepaidDisabled}>
                                <option value="">Select option</option>
                                {renderClassesOptions(props.invoice.items[i].department_id)}
                            </select>
                        </td>
                        <td>
                            <TextareaAutosize
                                className="textarea-description"
                                value={props.invoice.items[i].description}
                                onChange={handleUpdateItem.bind(this, i, 'description')}
                                disabled={disableFields}
                                maxRows={6}
                            />
                        </td>
                        <td>
                            <input
                                type="number"
                                value={quantity}
                                onChange={handleUpdateItem.bind(this, i, 'quantity')}
                                disabled={disableFields || isDescription || acmPrepaidDisabled}
                            />
                        </td>
                        <td>
                            <input
                                type="number"
                                value={price}
                                disabled={disableFields || isDescription || acmPrepaidDisabled}
                                className={minimumPriceFieldClass}
                                onChange={handleUpdatePrice.bind(this, i, 'price', props.invoice.items[i].invoice_class_id)}
                            />
                            {minimumPriceContainer}
                        </td>
                        <td>
                            <select value={props.invoice.items[i].unit} onChange={handleUpdateItem.bind(this, i, 'unit')} disabled={disableFields || isDescription || acmPrepaidDisabled}>
                                <option value="1">Ea.</option>
                                <option value="2">Lot</option>
                                <option value="3">per 100</option>
                                <option value="4">per 1000</option>
                            </select>
                        </td>
                        <td className="text-center">
                            <input
                                type="checkbox"
                                value={taxable}
                                checked={taxable}
                                disabled={disableTax || disableFields || isDescription}
                                onChange={handleUpdateItem.bind(this, i, 'taxable')}
                            />
                        </td>
                        <td className="text-center">
                            <input
                                type="checkbox"
                                value={props.invoice.items[i].show}
                                checked={props.invoice.items[i].show}
                                onChange={handleUpdateItem.bind(this, i, 'show')}
                                disabled={(props.invoice.items[i].show && totalShow <= 1) || disableFields || isDescription}
                            />
                        </td>
                        <td className="text-right">
                            <input
                                type="number"
                                value={amount}
                                className={minimumAmountFieldClass}
                                disabled={disableFields || isDescription || acmPrepaidDisabled}
                                onChange={handleUpdatePrice.bind(this, i, 'amount', props.invoice.items[i].invoice_class_id)}
                            />
                            {minimumAmountContainer}
                        </td>
                        <td className="actions">
                            {deleteIcon}
                        </td>
                    </tr>
                )
            };
        }

        let itemLines = [];
        for (let i = 0; i < itemsElements.length; i++) {
            itemLines.push(itemsElements[i].element);
        }

        return itemLines;
    };

    let subtotal = 0;
    let subtotalForTax = 0;
    let totalDiscount = 0;
    let totalTax = 0;
    let total = 0;
    for (const item of props.invoice.items) {
        if (parseInt(item.department_id) === 62 || parseInt(item.department_id) === 70) {
            continue;
        }

        if (item.quantity != null && item.quantity !== '' && item.price != null && item.price !== '') {
            subtotal += parseFloat(item.amount);

            if (item.taxable != null && item.taxable !== '' && item.taxable) {
                subtotalForTax += parseFloat(item.amount);
            }
        }
    }
    subtotal = subtotal.toFixed(5);
    subtotalForTax = subtotalForTax.toFixed(5);

    if (props.invoice.discount != null && props.invoice.discount !== '') {
        if (parseInt(props.invoice.discount_type) === 1) {
            totalDiscount = subtotal * parseFloat(props.invoice.discount) / 100;
        } else {
            totalDiscount = parseFloat(props.invoice.discount);
        }
    }

    if (props.invoice.tax != null && props.invoice.tax !== '') {
        totalTax = subtotalForTax * parseFloat(props.invoice.tax) / 100;
    }

    total = parseFloat(numeral(subtotal).format('0.00')) +
        parseFloat(numeral(totalTax).format('0.00')) -
        parseFloat(numeral(totalDiscount).format('0.00'));

    const validateApprovePermissions = () => {
        if (parseInt(props.invoice.status) === 2 && !props.invoice.combined && props.user != null) {
            let pqtType = parseInt(props.user.pqt_type);
            if ([1, 2, 5, 9].includes(pqtType)) {     // Super Admin (1), Super Manager (2), Sales (5), Accounting (9)
                return true;
            } else if (props.user.is_pqt_accounting) {      // Accounting
                return true;
            } else if (parseInt(props.user.id) === 33 || parseInt(props.user.id) === 48 || parseInt(props.user.id) === 89 || parseInt(props.user.id) === 2335 || parseInt(props.user.id) === 3566) {     // Nyke (33), Skip (48), April (89), Terri (2335), Kassandra (3566)
                return true;
            } else if (pqtType === 4 && parseInt(props.invoice.sales_rep_id) === 73) {     // CSR for House as Sales
                return true;
            }
        }

        return false;
    };

    const validateFinancialServicesPermissions = (departmentId) => {
        if (isPreliminary) {
            return false;
        }

        if ([28, 34, 61, 64, 73, 245].includes(parseInt(props.user.id))) {   // Heather (28), Lupe (34), Nichole (245), Hugh (61), Christy (64), House (73)
            return true;
        }

        return false;
    };

    let approveButton = null;
    if (validateApprovePermissions()) {
        let approveLabel = <Fragment>Approve Invoice <i className="fas fa-file-invoice-dollar" /></Fragment>;
        if (props.isApproving) {
            approveLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        approveButton = (
            <button className="action-button approve-button" onClick={props.showApproveModal} disabled={props.isApproving}>
                {approveLabel}
            </button>
        );
    }

    let qBPushLabel = <Fragment>QuickBooks Push <i className="fas fa-file-upload" /></Fragment>;
    if (props.isPushing) {
        qBPushLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    let qBPushButton = null;
    if (parseInt(props.invoice.status) === 3 && !props.invoice.combined && props.user != null && ([1, 2, 9].includes(parseInt(props.user.pqt_type)) || props.user.is_pqt_accounting)) {     // Approved
        qBPushButton = (
            <button className="action-button qb-button" onClick={props.showPushInvoiceModal} disabled={props.isPushing}>
                {qBPushLabel}
            </button>
        );
    } else if (parseInt(props.invoice.status) === 4 && props.user != null && [61, 2419].includes(parseInt(props.user.id))) {   // Hugh (61), Stephanie (2419)
        qBPushButton = (
            <button className="action-button qb-button" onClick={props.showPushInvoiceModal} disabled={props.isPushing}>
                {qBPushLabel}
            </button>
        );
    }

    let saveButtonLabel = <Fragment>Save <i className="fas fa-save" /></Fragment>;
    if (props.isSaving) {
        saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    let saveButton = null;
    let statusInt = parseInt(props.invoice.status);
    if (!props.invoice.combined && (statusInt < 3 || (statusInt === 3 && ([1, 2, 9].includes(parseInt(props.user.pqt_type)) || props.user.is_pqt_accounting)))) {     // Approve
        saveButton = <button className="action-button save-button" onClick={props.saveInvoice} disabled={props.isSaving}>{saveButtonLabel}</button>;
    }
    if (!props.invoice.combined && statusInt === 4 && props.user != null && [61, 2419].includes(parseInt(props.user.id))) {   // Hugh (61), Stephanie (2419)
        saveButton = <button className="action-button save-button" onClick={props.saveInvoice} disabled={props.isSaving}>{saveButtonLabel}</button>;
    }
    if (props.invoice.combined && (statusInt < 3 || (statusInt === 3 && ([1, 2, 9].includes(parseInt(props.user.pqt_type)) || props.user.is_pqt_accounting)))) {     // Approve
        saveButton = <button className="action-button save-button" onClick={props.saveInvoice} disabled={props.isSaving}>{saveButtonLabel}</button>;
    }

    let returnButton = null;
    let returnButtonLabel = <Fragment>Change Stage <i className="fas fa-undo-alt" /></Fragment>;
    if (props.isReturning) {
        returnButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
    }
    if (!props.invoice.combined && statusInt > 1 && props.user != null && parseInt(props.user.id) === 61) {
        returnButton = <button className="action-button return-button" onClick={props.showReturnInvoiceModal} disabled={props.isReturning}>{returnButtonLabel}</button>;
    }

    const handleMainInvoice = () => {
        props.history.push('/invoice/' + props.invoice.main_invoice_id)
    };

    let viewMainInvoiceButton = null;
    if (props.invoice.combined) {
        viewMainInvoiceButton = <button className="action-button" onClick={handleMainInvoice}>View Invoice <i className="fas fa-file" /></button>;
    }

    let customerName = 'N/A';
    for (const customer of props.information.customers) {
        if (parseInt(props.invoice.customer_id) === parseInt(customer.id)) {
            customerName = customer.display_name;
            break;
        }
    }

    let salesRepName = 'N/A';
    for (const salesRep of props.information.salesReps) {
        if (parseInt(props.invoice.sales_rep_id) === parseInt(salesRep.id)) {
            salesRepName = salesRep.name;
            break;
        }
    }

    let csrName = 'N/A';
    for (const csr of props.information.csrs) {
        if (parseInt(props.invoice.csr_id) === parseInt(csr.id)) {
            csrName = csr.name;
            break;
        }
    }

    let jobDescription = <span>{props.invoice.job_description}</span>;
    let idsCombinedContainer = null;
    let printSmithLabel = 'SC/EST';
    let poContainer = props.invoice.po_number;
    if (props.invoice.combined_ids != null) {
        printSmithLabel = 'Combined #'
        const ids = JSON.parse(props.invoice.combined_ids);
        let idsElements = [];
        for (let i = 0; i < ids.length; i++) {
            if (i === ids.length - 1) {
                idsElements.push(<Link to={'/invoice/' + ids[i]}>{ids[i]}</Link>);
            } else {
                idsElements.push(<Fragment><Link to={'/invoice/' + ids[i]}>{ids[i]}</Link>, </Fragment>);
            }
        }
        idsCombinedContainer = <div className="combined-ids">Combined invoices: {idsElements}</div>;
        jobDescription = (
            <input
                type="text"
                className="job-description-field"
                value={props.invoice.job_description}
                onChange={handleOnChange.bind(this, 'job_description')}
            />
        );
        poContainer = (
            <input
                type="text"
                value={props.invoice.po_number}
                onChange={handleOnChange.bind(this, 'po_number')}
            />
        );
    }

    let splitInvoicesContainer = null;
    if (props.invoice.split_ids != null && props.invoice.split_ids.length > 0) {
        printSmithLabel = 'Combined #'
        const ids = props.invoice.split_ids;
        let idsElements = [];
        for (let i = 0; i < ids.length; i++) {
            if (i === ids.length - 1) {
                idsElements.push(<Link to={'/invoice/' + ids[i]}>{ids[i]}</Link>);
            } else {
                idsElements.push(<Fragment><Link to={'/invoice/' + ids[i]}>{ids[i]}</Link>, </Fragment>);
            }
        }
        splitInvoicesContainer = (
            <div className="split-invoice mr-5">
                Split Invoices: {idsElements}
            </div>
        );
    }

    const handlePreview = (logo) => {
        if (!validateForm(props.invoice, false, props.user)) {
            return;
        }

        window.open(process.env.REACT_APP_API_URL + '/invoice/preview?id=' + props.invoice.id + '&logo=' + logo);
    };

    let previewButton = null;
    let noLogoPreviewButton = null;
    if (!props.invoice.combined) {
        previewButton = <button className="action-button" onClick={handlePreview.bind(this, 1)}>Preview PDF <i className="fas fa-file-pdf" /></button>;
    }
    if (!props.invoice.combined && props.user != null && (props.user.is_pqt_accounting || parseInt(props.user.id) === 61)) {
        noLogoPreviewButton = <button className="action-button preview-no-logo" onClick={handlePreview.bind(this, 0)}>Preview PDF (No Logo) <i className="fas fa-file-pdf" /></button>;
    }

    let notClosedField = null;
    if (parseInt(props.invoice.status) === 1) {
        notClosedField = <div className="no-closed">The last department has not closed out this ticket yet.  Please DO NOT enter any pricing in the  Prepaid Postage, Postage Sales and Shipping until this ticket has been completely closed out!  You can edit these field values when this ticket has been CLOSE out and is waiting for you inside the CLOSED queue.</div>;
    }

    let postagePaid = null;
    if (props.invoice.postage_invoice_id && props.invoice.postage_invoice_id != null && props.invoice.is_postage_invoice_paid) {
        postagePaid = (
            <div className="postage-paid">
                The postage for this invoice has been paid. Postage Invoice: <a href={`https://www.stagecoachview.com/postage-invoice/${props.invoice.postage_invoice_id}`} target='_blank'
                    rel="noopener noreferrer">PP-{props.invoice.stagecoach_number}</a>
            </div>
        );
    }

    const handleChangeTerms = (event) => {
        props.setField('terms', event.target.value);
    };

    let dueOnReceiptMessage = null;
    if (props.invoice.terms === 'Due on receipt') {
        dueOnReceiptMessage = <div className="due-on-receipt-message">Collect payment on Delivery</div>;
    }

    let dueDate = 'N/A';
    if (parseInt(props.invoice.status) === 4) {
        dueDate = moment(props.invoice.due_date).format('MM/DD/YYYY');
    }

    let termsField = props.invoice.customer_terms;
    if (props.invoice.customer_terms == null) {
        termsField = (
            <select value={props.invoice.terms} onChange={handleChangeTerms}>
                <option value="">Select option</option>
                <option value="Due on receipt">Due on receipt</option>
                <option value="Net 15">Net 15</option>
                <option value="Net 30">Net 30</option>
                <option value="Net 60">Net 60</option>
            </select>
        );
    }

    let billingAttn = null;
    if (props.invoice.use_customer_address) {
        if (props.invoice.billing_attn == null || props.invoice.billing_attn === '') {
            billingAttn = 'Accounts Payable';
        } else {
            billingAttn = props.invoice.billing_attn;
        }
    } else {
        billingAttn = <input type="text" value={props.invoice.billing_attn} onChange={handleOnChange.bind(this, 'billing_attn')} />;
    }

    let shippingAttn = null;
    if (props.invoice.use_bill_address) {
        if (props.invoice.shipping_attn == null || props.invoice.shipping_attn === '') {
            shippingAttn = 'Accounts Payable';
        } else {
            shippingAttn = props.invoice.shipping_attn;
        }
    } else {
        shippingAttn = <input type="text" value={props.invoice.shipping_attn} onChange={handleOnChange.bind(this, 'shipping_attn')} />;
    }

    let syncButtonLabel = <Fragment>Sync QB Total <i className="fas fa-sync-alt" /></Fragment>;
    if (props.isSynchingQbTotal) {
        syncButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    let syncButton = null;
    if (!props.invoice.combined && props.user != null && (props.user.is_pqt_accounting || parseInt(props.user.id) === 61)) {
        syncButton = <button className="action-button sync-button" onClick={props.syncQbTotal} disabled={props.isSynchingQbTotal}>{syncButtonLabel}</button>;
    }

    let balance = total;
    if (props.invoice.balance != null) {
        balance = props.invoice.balance;
    }

    let amountReceived = total - balance;
    if (amountReceived > 0) {
        amountReceived = -amountReceived;
    }
    amountReceived = amountReceived.toFixed(2);

    let isAccoutingOrHugh = props.user != null && (props.user.is_pqt_accounting || parseInt(props.user.id) === 61);
    let uncombineButton = null;
    let uncombineButtonLabel = <Fragment>Uncombine <i className="fas fa-layer-group" /></Fragment>;
    if (props.isUncombining) {
        uncombineButton = <i className="fas fa-circle-notch fa-spin" />;
    }
    if (props.invoice.combined_ids != null && statusInt < 4 && isAccoutingOrHugh) {
        uncombineButton = <button className="action-button uncombine-button" onClick={props.showUncombineInvoiceModal} disabled={props.isUncombining}>{uncombineButtonLabel}</button>;
    }

    let splitButton = null;
    let splitButtonLabel = <Fragment>Split <i className="fas fa-divide" /></Fragment>;
    if (props.isSplittingInvoice) {
        splitButton = <i className="fas fa-circle-notch fa-spin" />;
    }
    if ([2, 3].includes(parseInt(props.invoice.status)) && props.user != null && (props.user.email === 'hugh@absolutecolor.net' || props.user.email === 'robert@acmailplex.com')) {
        splitButton = <button className="action-button split-button" onClick={props.showSplitInvoiceModal} disabled={props.isSplittingInvoice}>{splitButtonLabel}</button>;
    }

    const setDateFieldHandler = (name, event) => {
        let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');
        props.setField(name, value);
    };

    let invoiceDateField = null;
    if (parseInt(props.invoice.status) >= 3) {
        invoiceDateField = (
            <div className="field">
                <div className="label">Invoice Date:</div>
                <DayPickerInput
                    formatDate={formatDate}
                    parseDate={parseDate}
                    placeholder=""
                    className="date-picker-field"
                    onDayChange={setDateFieldHandler.bind(this, 'invoice_date')}
                    value={props.invoice.invoice_date == null || props.invoice.invoice_date === '0000-00-00' ? '' : moment(props.invoice.invoice_date).format('MM/DD/YYYY')}
                />
            </div>
        );
    }

    let artDescriptionContainer = null;
    if (props.invoice.art_proofs && props.invoice.art_proofs != null && props.invoice.art_proofs !== '') {
        let proofType = null;
        if (parseInt(props.invoice.art_proof_type) === 1) {
            proofType = ', Proof Type: PDF';
        } else if (parseInt(props.invoice.art_proof_type) === 2) {
            proofType = ', Proof Type: Hardcopy';
        } else if (parseInt(props.invoice.art_proof_type) === 3) {
            proofType = ', Proof Type: Both';
        }

        artDescriptionContainer = (
            <div className="art-description">Artwork time: {props.invoice.art_days} days, {props.invoice.art_hours} hours, {props.invoice.art_minutes} minutes. Proofs: {props.invoice.art_proofs}{proofType}</div>
        );
    }

    if (props.user && props.user.username === 'grace@acmailplex.com') {
        qBPushButton = null;
        syncButton = null;
        uncombineButton = null;
    }

    if (props.user && props.user.username === 'grace@acmailplex.com' && parseInt(props.invoice.status) === 3) {
        invoiceDateField = null;
    }

    const renderPayments = () => {
        if (!isAccoutingOrHugh) {
            return null;
        }

        let payments = [];
        if (props.invoice.payments_data && props.invoice.payments_data != null) {
            payments = JSON.parse(props.invoice.payments_data);
        }

        for (let i = 0; i < props.invoice.items.length; i++) {
            const departmentId = parseInt(props.invoice.items[i].department_id);
            if (departmentId === 70) {   // Payment for Services
                payments.push({
                    date: dateToTimezone(props.invoice.first_push_datetime, 'MM/DD/YYYY'),
                    number: 'Payment for Services',
                    amount: -parseFloat(props.invoice.items[i].amount),
                });
            }
        }

        if (payments.length === 0) {
            return null;
        }

        return (
            <div className="payments-container">
                <h3>Payments</h3>
                <table>
                    <thead>
                        <tr>
                            <th className="text-center">Date</th>
                            <th className="text-center">Payment #</th>
                            <th className="text-right">Amount applied</th>
                        </tr>
                    </thead>
                    <tbody>
                        {payments.map((payment, index) => {
                            return (
                                <tr key={'payment-' + index}>
                                    <td className="text-center">{moment(payment.date).format('MM/DD/YYYY')}</td>
                                    <td className="text-center">{payment.number}</td>
                                    <td className="text-right">{numeral(payment.amount).format('$0,0.00')}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    };

    let postageEscrow = props.postageEscrow;
    if (parseFloat(postageEscrow.toFixed(2)) < 0) {
        postageEscrow = <span className="red-scrow">{numeral(parseFloat(postageEscrow.toFixed(2))).format('$0,0.00')}</span>;
    } else {
        postageEscrow = <span>{numeral(parseFloat(postageEscrow.toFixed(2))).format('$0,0.00')}</span>;
    }

    if (props.fetchingPostageEscrow) {
        postageEscrow = <span><i className="fas fa-circle-notch fa-spin" /></span>;
    }

    return (
        <div className="invoice-form-container">
            <div className="fields-section">
                <div className="customer-section">
                    <h4>Customer</h4>
                    <div className="field">
                        <div className="label">Customer:</div>
                        {customerName}
                    </div>
                    {invoiceDateField}
                    <div className="field">
                        <div className="label">Due Date:</div>
                        {dueDate}
                    </div>
                    <div className="field">
                        <div className="label">QuickBooks Invoice No.:</div>
                        {props.invoice.qb_number == null ? 'N/A' : props.invoice.qb_number}
                    </div>
                </div>
                <div className="billing-section">
                    <h4>
                        <div>Bill To</div>
                        <div className="same-address">
                            <input
                                type="checkbox"
                                checked={props.invoice.use_customer_address}
                                onChange={handleOnChange.bind(this, 'use_customer_address')}
                            /> Use Customer Address
                        </div>
                    </h4>
                    <div className="fields">
                        <div className="field">
                            <div className="label">Name:</div>
                            {
                                props.invoice.use_customer_address
                                    ? props.invoice.billing_name
                                    : <input type="text" value={props.invoice.billing_name} onChange={handleOnChange.bind(this, 'billing_name')} />
                            }
                        </div>
                        <div className="field">
                            <div className="label">Attn:</div>
                            {billingAttn}
                        </div>
                        <div className="field">
                            <div className="label">Address:</div>
                            {
                                props.invoice.use_customer_address
                                    ? props.invoice.billing_address
                                    : <input type="text" value={props.invoice.billing_address} onChange={handleOnChange.bind(this, 'billing_address')} />
                            }
                        </div>
                        <div className="field">
                            <div className="label">City:</div>
                            {
                                props.invoice.use_customer_address
                                    ? props.invoice.billing_city
                                    : <input type="text" value={props.invoice.billing_city} onChange={handleOnChange.bind(this, 'billing_city')} />
                            }
                        </div>
                        <div className="field">
                            <div className="label">State:</div>
                            {
                                props.invoice.use_customer_address
                                    ? props.invoice.billing_state
                                    : <input type="text" value={props.invoice.billing_state} onChange={handleOnChange.bind(this, 'billing_state')} />
                            }
                        </div>
                        <div className="field">
                            <div className="label">Zip:</div>
                            {
                                props.invoice.use_customer_address
                                    ? props.invoice.billing_zip
                                    : <input type="text" value={props.invoice.billing_zip} onChange={handleOnChange.bind(this, 'billing_zip')} />
                            }
                        </div>
                    </div>
                </div>
                <div className="shipping-section">
                    <h4>
                        <div>Ship To / Work For</div>
                        <div className="same-address">
                            <input
                                type="checkbox"
                                checked={props.invoice.use_bill_address}
                                onChange={handleOnChange.bind(this, 'use_bill_address')}
                            /> Use Bill Address
                        </div>
                    </h4>
                    <div className="fields">
                        <div className="field">
                            <div className="label">Name:</div>
                            {
                                props.invoice.use_bill_address
                                    ? props.invoice.shipping_name
                                    : <input type="text" value={props.invoice.shipping_name} onChange={handleOnChange.bind(this, 'shipping_name')} />
                            }
                        </div>
                        <div className="field">
                            <div className="label">Attn:</div>
                            {shippingAttn}
                        </div>
                        <div className="field">
                            <div className="label">Address:</div>
                            {
                                props.invoice.use_bill_address
                                    ? props.invoice.shipping_address
                                    : <input type="text" value={props.invoice.shipping_address} onChange={handleOnChange.bind(this, 'shipping_address')} />
                            }
                        </div>
                        <div className="field">
                            <div className="label">City:</div>
                            {
                                props.invoice.use_bill_address
                                    ? props.invoice.shipping_city
                                    : <input type="text" value={props.invoice.shipping_city} onChange={handleOnChange.bind(this, 'shipping_city')} />
                            }
                        </div>
                        <div className="field">
                            <div className="label">State:</div>
                            {
                                props.invoice.use_bill_address
                                    ? props.invoice.shipping_state
                                    : <input type="text" value={props.invoice.shipping_state} onChange={handleOnChange.bind(this, 'shipping_state')} />
                            }
                        </div>
                        <div className="field">
                            <div className="label">Zip:</div>
                            {
                                props.invoice.use_bill_address
                                    ? props.invoice.shipping_zip
                                    : <input type="text" value={props.invoice.shipping_zip} onChange={handleOnChange.bind(this, 'shipping_zip')} />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="fields-section other-fields">
                <div className="field number-field">
                    <div className="label">{printSmithLabel}:</div>
                    <input type="text" value={props.invoice.print_smith} onChange={handleOnChange.bind(this, 'print_smith')} />
                </div>
                <div className="field number-field">
                    <div className="label">Quote #:</div>
                    <input type="text" value={props.invoice.quote} onChange={handleOnChange.bind(this, 'quote')} />
                </div>
                <div className="field number-field">
                    <div className="label">P.O. Number:</div>
                    {poContainer}
                </div>
                <div className="field terms-field">
                    <div className="label">Terms:</div>
                    {termsField}
                </div>
                <div className="field name-field">
                    <div className="label">Sales Rep.:</div>
                    {salesRepName}
                </div>
                <div className="field name-field">
                    <div className="label">CSR:</div>
                    {csrName}
                </div>
                <div className="field number-field">
                    <div className="label">Stagecoach No.:</div>
                    {stagecoachLink}
                </div>
                <div className="field shipping-method">
                    <div className="label">Shipping Method:</div>
                    {props.invoice.shipping_method && props.invoice.shipping_method !== '' ? props.invoice.shipping_method : 'N/A'}
                </div>
                <div className="field tracking-field">
                    <div className="label">Tracking No.:</div>
                    {props.invoice.tracking_number && props.invoice.tracking_number !== '' ? props.invoice.tracking_number : 'N/A'}
                </div>
            </div>
            {dueOnReceiptMessage}
            <div className="items-list">
                <h4>
                    <div className="title-column">
                        <div>Items</div>
                        <div className="job-description">Job Description: {jobDescription}</div>
                        {artDescriptionContainer}
                    </div>
                    {splitInvoicesContainer}
                    {idsCombinedContainer}
                </h4>
                <table className="table table-sm table-hover">
                    <thead>
                        <tr>
                            <th className="number-th">#</th>
                            <th className="sort-th"></th>
                            <th className="department-th">Product/Service</th>
                            <th className="task-th">Class</th>
                            <th className="description-th">Description</th>
                            <th className="quantity-th">Quantity</th>
                            <th className="price-th">
                                <div className="price-th-container">
                                    <div>
                                        <input
                                            type="checkbox"
                                            className="show-unit-price-checkbox"
                                            value={props.invoice.show_unit}
                                            checked={props.invoice.show_unit}
                                            onClick={handleOnChange.bind(this, 'show_unit')}
                                        />
                                    </div>
                                    <div>Price</div>
                                </div>
                            </th>
                            <th className="unit-th">Unit</th>
                            <th className="tax-th text-center">Taxable</th>
                            <th className="show-th text-center">Show</th>
                            <th className="amount-th text-right">Amount</th>
                            <th className="actions-th"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderItems()}
                    </tbody>
                </table>
                <div className="bottom-container">
                    <div className="items-buttons-container">
                        <div className="button-field-container">
                            <div className="button-container">
                                <button className="action-button" onClick={props.addLine}>Add Line <i className="fas fa-plus" /></button>
                            </div>
                            {notClosedField}
                        </div>
                        {postagePaid}
                        <div className="audit-history-container">
                            <div className="title">Audit Log:</div>
                            <div className="log">
                                {props.invoice.auditHistory.map((record, index) => (
                                    <div className="log-element" key={'log-element-' + index}>
                                        <b className={record.is_update ? 'pink-value' : ''}>{dateToTimezone(record.created_at, 'MMMM Do YYYY, h:mm a')}:</b> <span className="label" dangerouslySetInnerHTML={{ __html: record.label }}></span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="totals-container">
                        <div className="total-item">
                            <div className="label">Subtotal:</div>
                            <div className="value">{numeral(subtotal).format('$0,0.00')}</div>
                        </div>
                        <div className="total-item discount">
                            <div className="label">
                                <select value={props.invoice.discount_type} onChange={handleOnChange.bind(this, 'discount_type')} >
                                    <option value="1">Discount percent</option>
                                    <option value="2">Discount value</option>
                                </select>
                                <input type="text" value={props.invoice.discount} onChange={handleOnChange.bind(this, 'discount')} /> &nbsp;Discount:
                            </div>
                            <div className="value">{numeral(-totalDiscount).format('$0,0.00')}</div>
                        </div>
                        <div className="total-item tax">
                            <div className="label">Sales Tax (8.25%):</div>
                            <div className="value">{numeral(totalTax).format('$0,0.00')}</div>
                        </div>
                        <div className="total-item total">
                            <div className="label">Total:</div>
                            <div className="value">{numeral(total).format('$0,0.00')}</div>
                        </div>
                        <div className="total-item total amount-received-container">
                            <div className="postage-escrow">
                                Prepaid Funds: {postageEscrow}
                            </div>
                            <div className="amount-received">
                                <div className="label">Amount received:</div>
                                <div className="value">{numeral(amountReceived).format('$0,0.00')}</div>
                            </div>
                        </div>
                        <div className="total-item total">
                            <div className="label">Balance due:</div>
                            <div className="value">{numeral(balance).format('$0,0.00')}</div>
                        </div>
                        {renderPayments()}
                        <div className="buttons-container">
                            {approveButton}
                            {qBPushButton}
                            {previewButton}
                            {noLogoPreviewButton}
                            {syncButton}
                            {saveButton}
                            {viewMainInvoiceButton}
                            {returnButton}
                            {uncombineButton}
                            {splitButton}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Form;
