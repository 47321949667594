export const LOAD_CHATS = 'PQT_TRACKING/LOAD_CHATS';
export const LOAD_CHATS_SUCCESS = 'PQT_TRACKING/LOAD_CHATS_SUCCESS';
export const LOAD_CHATS_ERROR = 'PQT_TRACKING/LOAD_CHATS_ERROR';
export const SAVE_MESSAGE = 'PQT_TRACKING/SAVE_MESSAGE';
export const SAVE_MESSAGE_SUCCESS = 'PQT_TRACKING/SAVE_MESSAGE_SUCCESS';
export const SAVE_MESSAGE_ERROR = 'PQT_TRACKING/SAVE_MESSAGE_ERROR';
export const LOAD_MORE_MESSAGES = 'PQT_TRACKING/LOAD_MORE_MESSAGES';
export const LOAD_MORE_MESSAGES_SUCCESS = 'PQT_TRACKING/LOAD_MORE_MESSAGES_SUCCESS';
export const LOAD_MORE_MESSAGES_ERROR = 'PQT_TRACKING/LOAD_MORE_MESSAGES_ERROR';
export const LOAD_CHAT = 'PQT_TRACKING/LOAD_CHAT';
export const LOAD_CHAT_SUCCESS = 'PQT_TRACKING/LOAD_CHAT_SUCCESS';
export const LOAD_CHAT_ERROR = 'PQT_TRACKING/LOAD_CHAT_ERROR';
export const LOAD_CHAT_MEMBERS = 'PQT_TRACKING/LOAD_CHAT_MEMBERS';
export const LOAD_CHAT_MEMBERS_SUCCESS = 'PQT_TRACKING/LOAD_CHAT_MEMBERS_SUCCESS';
export const LOAD_CHAT_MEMBERS_ERROR = 'PQT_TRACKING/LOAD_CHAT_MEMBERS_ERROR';
export const CHECK_UNREAD_MESSAGES = 'PQT_TRACKING/CHECK_UNREAD_MESSAGES';
export const CHECK_UNREAD_MESSAGES_SUCCESS = 'PQT_TRACKING/CHECK_UNREAD_MESSAGES_SUCCESS';
export const CHECK_UNREAD_MESSAGES_ERROR = 'PQT_TRACKING/CHECK_UNREAD_MESSAGES_ERROR';
export const SET_MESSAGES = 'CHAT/SET_MESSAGES';
export const ADD_MESSAGE = 'CHAT/ADD_MESSAGE';
export const ADD_MESSAGE_OBJECT = 'CHAT/ADD_MESSAGE_OBJECT';
export const SET_RESPONSE = 'CHAT/SET_RESPONSE';
export const RESET_STATE = 'CHAT/RESET_STATE';
export const SET_ELEMENT_ID = 'CHAT/SET_ELEMENT_ID';
export const SET_SHOW_FIELD = 'CHAT/SET_SHOW_FIELD';
export const SET_FIRST_SCROLL_DONE = 'CHAT/SET_FIRST_SCROLL_DONE';
export const SET_TOTAL_MESSAGES = 'CHAT/SET_TOTAL_MESSAGES';
export const SET_TOTAL_LOADED_MESSAGES = 'CHAT/SET_TOTAL_LOADED_MESSAGES';
export const RESET_MESSAGES_COUNTERS = 'CHAT/RESET_MESSAGES_COUNTERS';
export const SET_TYPE = 'CHAT/SET_TYPE';
export const SET_USERS_IDS = 'CHAT/SET_USERS_IDS';
export const SET_CHAT_TITLE = 'CHAT/SET_CHAT_TITLE';
export const SET_CHAT_UNREAD_MESSAGES = 'CHAT/SET_CHAT_UNREAD_MESSAGES';
export const SET_HAS_UNREAD_MESSAGES = 'CHAT/SET_HAS_UNREAD_MESSAGES';