export const FETCH_INVOICES = 'PQT_INVOICES/FETCH_INVOICES';
export const FETCH_INVOICES_SUCCESS = 'PQT_INVOICES/FETCH_INVOICES_SUCCESS';
export const FETCH_INVOICES_ERROR = 'PQT_INVOICES/FETCH_INVOICES_ERROR';
export const DELETE_INVOICE = 'PQT_INVOICES/DELETE_INVOICE';
export const DELETE_INVOICE_SUCCESS = 'PQT_INVOICES/DELETE_INVOICE_SUCCESS';
export const DELETE_INVOICE_ERROR = 'PQT_INVOICES/DELETE_INVOICE_ERROR';
export const COMBINE_INVOICES = 'PQT_INVOICES/COMBINE_INVOICES';
export const COMBINE_INVOICES_SUCCESS = 'PQT_INVOICES/COMBINE_INVOICES_SUCCESS';
export const COMBINE_INVOICES_ERROR = 'PQT_INVOICES/COMBINE_INVOICES_ERROR';
export const EXPORT_EXCEL = 'PQT_INVOICES/EXPORT_EXCEL';
export const EXPORT_EXCEL_SUCCESS = 'PQT_INVOICES/EXPORT_EXCEL_SUCCESS';
export const EXPORT_EXCEL_ERROR = 'PQT_INVOICES/EXPORT_EXCEL_ERROR';
export const RESET_STATE = 'PQT_INVOICES/RESET_STATE';
export const SET_ID = 'PQT_INVOICES/SET_ID';
export const SET_SORT = 'PQT_INVOICES/SET_SORT';
export const SET_ACTIVE_PAGE = 'PQT_INVOICES/SET_ACTIVE_PAGE';
export const SET_ID_FOR_DELETE = 'PQT_INVOICES/SET_ID_FOR_DELETE';
export const SET_SHOW = 'PQT_INVOICES/SET_SHOW';
export const CHECK_INVOICE = 'PQT_INVOICES/CHECK_INVOICE';
export const CHANGE_FILTER = 'PQT_INVOICES/CHANGE_FILTER';