import axios from 'axios';
import * as types from './PqtDashboard.types';
import * as selectors from './PqtDashboard.selectors';
import * as loginSelectors from './../Login/Login.selectors';
import * as dialog from '../common/dialog';
import { validateAuthInResponse, validateMultiAuthInResponse, showDataDeletedNotification, sendWsMessage } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchData = (options = {}, isWsUpdate = false) => (dispatch, getState) => {
    const searchFields = selectors.getSearchFields(getState());
    const showFinished = selectors.getShowFinished(getState());
    const quickGlanceFilter = selectors.getQuickGlanceFilter(getState());
    const sort = selectors.getSort(getState());
    const pagination = selectors.getPagination(getState());

    let params = [];
    if (options.fetchInformation) {
        dispatch({ type: types.FETCH_INFORMATION, payload: null });
        params.push('fetch-information=1');
    }
    if (options.fetchQuickGlance) {
        dispatch({ type: types.FETCH_QUICK_GLANCE, payload: isWsUpdate });
        params.push('fetch-quick-glance=1');
    }
    if (options.fetchLateJobs) {
        dispatch({ type: types.FETCH_LATE_JOBS, payload: isWsUpdate });
        params.push('fetch-late-jobs=1');
        if (quickGlanceFilter.field !== '') {
            params.push(quickGlanceFilter.field + '=' + quickGlanceFilter.value);
        }
        if (searchFields.jobNumber !== '') {
            params.push('jobNumber=' + searchFields.jobNumber);
        }
        if (searchFields.customer !== '') {
            params.push('customer=' + searchFields.customer);
        }
        if (searchFields.date !== '') {
            params.push('date=' + searchFields.date);
        }
        if (searchFields.csr !== '') {
            params.push('csr=' + searchFields.csr);
        }
        if (searchFields.salesRep !== '') {
            params.push('salesRep=' + searchFields.salesRep);
        }
        if (searchFields.description !== '') {
            params.push('description=' + searchFields.description);
        }
        params.push('sort=' + (sort.lateJobs.type === 'asc' ? '' : '-') + sort.lateJobs.column);
        params.push('active-page=' + pagination.lateJobs.activePage);
        params.push('elements-per-page=' + pagination.lateJobs.elementsPerPage);
    }
    if (options.fetchMyJobs) {
        dispatch({ type: types.FETCH_MY_JOBS, payload: isWsUpdate });
        params.push('fetch-my-jobs=1');
        if (quickGlanceFilter.field !== '') {
            params.push(quickGlanceFilter.field + '=' + quickGlanceFilter.value);
        }
        if (showFinished) {
            params.push('show-finished=1');
        }
        if (searchFields.jobNumber !== '') {
            params.push('jobNumber=' + searchFields.jobNumber);
        }
        if (searchFields.customer !== '') {
            params.push('customer=' + searchFields.customer);
        }
        if (searchFields.date !== '') {
            params.push('date=' + searchFields.date);
        }
        if (searchFields.csr !== '') {
            params.push('csr=' + searchFields.csr);
        }
        if (searchFields.salesRep !== '') {
            params.push('salesRep=' + searchFields.salesRep);
        }
        params.push('sort=' + (sort.myJobs.type === 'asc' ? '' : '-') + sort.myJobs.column);
        params.push('active-page=' + pagination.myJobs.activePage);
        params.push('elements-per-page=' + pagination.myJobs.elementsPerPage);
    }

    dispatch({ type: types.FETCH_DATA, payload: null });
    axios.get(apiUrl + '/pqt-tracking/dashboard-data' + (params.length === 0 ? '' : '?' + params.join('&')),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_DATA_SUCCESS, payload: null });
            if (options.fetchInformation) {
                dispatch({ type: types.FETCH_INFORMATION_SUCCESS, payload: response.data.information });
            }
            if (options.fetchQuickGlance) {
                dispatch({ type: types.FETCH_QUICK_GLANCE_SUCCESS, payload: response.data.quickGlance });
            }
            if (options.fetchLateJobs) {
                dispatch({ type: types.FETCH_LATE_JOBS_SUCCESS, payload: response.data.lateJobs });
            }
            if (options.fetchMyJobs) {
                dispatch({ type: types.FETCH_MY_JOBS_SUCCESS, payload: response.data.myJobs });
            }
        })
        .catch((error) => {
            let errorTypes = [types.FETCH_DATA_ERROR];
            if (options.fetchInformation) {
                errorTypes.push(types.FETCH_INFORMATION_ERROR);
            }
            if (options.fetchQuickGlance) {
                errorTypes.push(types.FETCH_QUICK_GLANCE_ERROR);
            }
            if (options.fetchLateJobs) {
                errorTypes.push(types.FETCH_LATE_JOBS_ERROR);
            }
            if (options.fetchMyJobs) {
                errorTypes.push(types.FETCH_MY_JOBS_ERROR);
            }
            dispatch(validateMultiAuthInResponse(error, errorTypes));
        });
}

export const setHistory = history => (dispatch) => {
    dispatch({
        type: types.SET_HISTORY,
        payload: history
    });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null
    });
}

export const deleteTracking = (id, comments) => (dispatch) => {
    dispatch({ type: types.DELETE_TRACKING, payload: null });
    axios.delete(apiUrl + '/pqt-trackings/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }, data: { comments } }
    ).then(() => {
        dispatch({ type: types.DELETE_TRACKING_SUCCESS, payload: null });
        showDataDeletedNotification();
        dispatch(fetchData({
            fetchQuickGlance: 1,
            fetchLateJobs: 1,
            fetchMyJobs: 1,
        }));
        dispatch(sendWsMessage({ id, type: 'track_deleted' }));
        dispatch(dialog.actions.hideDialog('delete-modal'));
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.DELETE_TRACKING_ERROR));
    });
}

export const setSearchField = (name, value) => (dispatch) => {
    dispatch({
        type: types.SET_SEARCH_FIELD,
        payload: { name, value }
    });
}

export const setShowFinished = value => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_SHOW_FINISHED,
            payload: value
        });
        resolve();
    }).then(() => {
        dispatch(fetchData({
            fetchMyJobs: 1,
        }));
    });
}

export const setSort = (name, column, type) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_SORT,
            payload: {
                name,
                value: {
                    column: column,
                    type: type
                }
            }
        });
        resolve();
    }).then(() => {
        if (name === 'lateJobs') {
            dispatch(fetchData({
                fetchLateJobs: 1,
            }));
        } else if (name === 'myJobs') {
            dispatch(fetchData({
                fetchMyJobs: 1,
            }));
        }
    });
}

export const setQuickGlanceFilter = (field, value) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_QUICK_GLANCE_FILTER,
            payload: { field, value }
        });
        resolve();
    }).then(() => {
        dispatch(fetchData({
            fetchLateJobs: 1,
            fetchMyJobs: 1,
        }));
    });
}

export const setActivePage = (name, value) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_ACTIVE_PAGE,
            payload: { name, value }
        });
        resolve();
    }).then(() => {
        if (name === 'myJobs') {
            dispatch(fetchData({
                fetchMyJobs: 1,
            }));
        } else if (name === 'lateJobs') {
            dispatch(fetchData({
                fetchLateJobs: 1,
            }));
        }
    });
}

export const resetSearchFields = () => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.RESET_SEARCH_FIELDS,
            payload: null
        });
        resolve();
    }).then(() => {
        dispatch(fetchData({
            fetchLateJobs: 1,
            fetchMyJobs: 1,
        }));
    });
}

export const mountComponent = (history) => (dispatch, getState) => {
    const user = loginSelectors.getUser(getState());
    const pqtType = user !== null && parseInt(user.pqt_type);
    if ([6, 7].includes(pqtType)) {
        history.push('/department/' + user.pqt_department);
    } else {
        dispatch(resetState());
        dispatch(setHistory(history));

        if (user !== null && [1, 2, 3, 4, 8].includes(pqtType)) {
            dispatch(fetchData({
                fetchInformation: 1,
                fetchQuickGlance: 1,
                fetchLateJobs: 1,
                fetchMyJobs: 1,
            }));
        } else {
            dispatch(fetchData({
                fetchLateJobs: 1,
                fetchMyJobs: 1,
            }));
        }
    }
}

export const setIdForDelete = (id) => (dispatch) => {
    dispatch({
        type: types.SET_ID_FOR_DELETE,
        payload: id
    });
}
