import axios from 'axios';
import * as types from './PqtInvoice.types';
import { validateAuthInResponse, sendWsMessage, showNotification, empty } from './../../utils';
import * as selectors from './PqtInvoice.selectors';
import * as loginSelectors from './../Login/Login.selectors';
import * as dialog from '../common/dialog';
import moment from 'moment-timezone';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchInvoice = (id) => (dispatch) => {
    dispatch({ type: types.FETCH_INVOICE, payload: null });
    axios.get(apiUrl + '/invoices/' + id + '?expand=items,auditHistory,customerAddress',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_INVOICE_SUCCESS, payload: response.data });
            dispatch(fetchPostageEscrow());
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_INVOICE_ERROR));
        });
}

export const fetchInformation = () => (dispatch) => {
    dispatch({ type: types.FETCH_INFORMATION, payload: null });
    axios.get(apiUrl + '/invoice/data',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_INFORMATION_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_INFORMATION_ERROR));
        });
}

export const fetchCustomerData = (id) => (dispatch) => {
    dispatch({ type: types.FETCH_CUSTOMER_DATA, payload: null });
    axios.get(apiUrl + '/invoice/customer-data?id=' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_CUSTOMER_DATA_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_CUSTOMER_DATA_ERROR));
        });
}

export const saveInvoice = () => (dispatch, getState) => {
    const invoice = selectors.getInvoice(getState());
    const user = loginSelectors.getUser(getState());

    if (!validateForm(invoice, true, user)) {
        return;
    }

    dispatch({ type: types.SAVE_INVOICE, payload: null });
    axios.put(apiUrl + '/invoices/' + invoice.id, invoice,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.SAVE_INVOICE_SUCCESS, payload: response.data });
            dispatch(sendWsMessage({ id: invoice.id, type: 'invoice_updated' }));
            showNotification('Invoice Saved', 'The invoice has been saved successfully', 'success');
            dispatch(fetchInvoice(invoice.id));
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.SAVE_INVOICE_ERROR));
        });
}

export const approveInvoice = () => (dispatch, getState) => {
    const invoice = selectors.getInvoice(getState());
    const user = loginSelectors.getUser(getState());

    if (!validateForm(invoice, true, user)) {
        return;
    }

    dispatch({ type: types.APPROVE_INVOICE, payload: null });
    axios.post(apiUrl + '/invoice/approve', { id: invoice.id },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.APPROVE_INVOICE_SUCCESS, payload: response.data });
            dispatch(sendWsMessage({ id: invoice.id, type: 'invoice_updated' }));
            showNotification('Invoice Saved', 'The invoice has been saved successfully', 'success');
            dispatch(fetchInvoice(invoice.id));
            dispatch(dialog.actions.hideDialog('approve-modal'));
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.APPROVE_INVOICE_ERROR));
        });
}

export const qBPush = (option) => (dispatch, getState) => {
    const invoice = selectors.getInvoice(getState());
    const customerEmail = selectors.getCustomerEmail(getState());
    const user = loginSelectors.getUser(getState());

    if (!validateForm(invoice, true, user)) {
        return;
    }

    if (parseInt(option) !== 3 && !customerEmail.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
        return showNotification('Incorrect email', 'The value entered does not have a valid email format', 'info');;
    }

    let terms = null;
    if (invoice.customer_terms != null) {
        terms = invoice.customer_terms;
    } else {
        terms = invoice.terms;
    }

    let invoiceDate = moment.tz(invoice.invoice_date, process.env.REACT_APP_TIMEZONE).tz('America/Chicago');
    if (terms === 'Net 15') {
        invoiceDate.add(15, 'days');
    } else if (terms === 'Net 30') {
        invoiceDate.add(30, 'days');
    } else if (terms === 'Net 60') {
        invoiceDate.add(60, 'days');
    }
    let dueDate = invoiceDate.format('YYYY-MM-DD');

    dispatch({ type: types.QB_PUSH, payload: null });
    axios.post(apiUrl + '/invoice/push-to-qb', { id: invoice.id, due_date: dueDate, option, email: customerEmail, invoice_date: invoice.invoice_date },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.QB_PUSH_SUCCESS, payload: response.data });

            const validateCustomerSent = (response) => {
                if (response.data.customerSent) {
                    showNotification('Invoice sent to the Customer', 'The invoice has been sent to the Customer email', 'success');
                }
            };

            const validateInvoiceSaved = (response) => {
                if (parseInt(response.data.code) === 1) {
                    dispatch(sendWsMessage({ id: invoice.id, type: 'invoice_updated' }));
                    showNotification('Invoice Saved in QB', 'The invoice has been saved successfully in QuickBooks', 'success');
                    dispatch(fetchInvoice(invoice.id));
                } else {
                    showNotification('Invoice was not saved', response.data.message, 'warning');
                }
            };

            if (parseInt(option) === 1) {
                validateCustomerSent(response);
                validateInvoiceSaved(response);
            } else if (parseInt(option) === 2) {
                validateCustomerSent(response);
            } else if (parseInt(option) === 3) {
                validateInvoiceSaved(response);
            }

            dispatch(dialog.actions.hideDialog('push-invoice-modal'));
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.QB_PUSH_ERROR));
        });
}

export const returnInvoice = (stage) => (dispatch, getState) => {
    const invoice = selectors.getInvoice(getState());

    dispatch({ type: types.RETURN_INVOICE, payload: null });
    axios.post(apiUrl + '/invoice/change-stage', { id: invoice.id, stage },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.RETURN_INVOICE_SUCCESS, payload: response.data });
            showNotification('Invoice Stage Changed', 'The invoice has been changed to another stage', 'success');
            dispatch(fetchInvoice(invoice.id));
            dispatch(dialog.actions.hideDialog('return-invoice-modal'));
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.RETURN_INVOICE_ERROR));
        });
}

export const uncombineInvoice = (history) => (dispatch, getState) => {
    const invoice = selectors.getInvoice(getState());

    dispatch({ type: types.UNCOMBINE_INVOICE, payload: null });
    axios.post(apiUrl + '/invoice/uncombine', { id: invoice.id },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.UNCOMBINE_INVOICE_SUCCESS, payload: response.data });
            showNotification('Invoice Uncombined', 'The invoice has been uncombined', 'success');
            dispatch(fetchInvoice(invoice.id));
            dispatch(dialog.actions.hideDialog('uncombine-invoice-modal'));
            history.push('/invoices');
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.UNCOMBINE_INVOICE_ERROR));
        });
}

export const splitInvoice = (history) => (dispatch, getState) => {
    const invoice = selectors.getInvoice(getState());
    const splitPercent1 = selectors.getSplitPercent1(getState());
    const splitPercent2 = selectors.getSplitPercent2(getState());

    if (empty(splitPercent1) || empty(splitPercent2)) {
        return showNotification('Complete Information', 'You must enter both percentages to split the invoice', 'info');
    }

    dispatch({ type: types.SPLIT_INVOICE, payload: null });
    axios.post(apiUrl + '/invoice/split', { id: invoice.id, splitPercent1, splitPercent2 },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.SPLIT_INVOICE_SUCCESS, payload: response.data });
            showNotification('Invoice Splited', 'The invoice has been splited successfully', 'success');
            dispatch(fetchInvoice(invoice.id));
            dispatch(dialog.actions.hideDialog('split-invoice-modal'));
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.SPLIT_INVOICE_ERROR));
        });
}

export const syncQbTotal = () => (dispatch, getState) => {
    const invoice = selectors.getInvoice(getState());
    dispatch({ type: types.SYNC_QB_TOTAL, payload: null });
    axios.post(apiUrl + '/invoice/sync-qb-total', { id: invoice.id },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.SYNC_QB_TOTAL_SUCCESS, payload: response.data });
            showNotification('Invoice Synched', 'The total balace of the invoice has been updated successfully', 'success');
            dispatch(fetchInvoice(invoice.id));
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.SYNC_QB_TOTAL_ERROR));
        });
}

export const fetchPostageEscrow = () => (dispatch, getState) => {
    const invoice = selectors.getInvoice(getState());
    dispatch({ type: types.FETCH_POSTAGE_ESCROW, payload: null });
    axios.post(apiUrl + '/pqt-tracking/get-postage-escrow', { customerId: invoice.customer_id, useSc: 0 },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_POSTAGE_ESCROW_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_POSTAGE_ESCROW_ERROR));
        });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null
    });
}

export const setField = (field, value) => (dispatch) => {
    dispatch({
        type: types.SET_FIELD,
        payload: { field, value }
    });
}

export const setItemField = (index, field, value) => (dispatch, getState) => {
    const user = loginSelectors.getUser(getState());
    dispatch({
        type: types.SET_ITEM_FIELD,
        payload: { index, field, value, user }
    });
}

export const addLine = () => (dispatch) => {
    dispatch({
        type: types.ADD_LINE,
        payload: null
    });
}

export const removeLine = (index) => (dispatch) => {
    dispatch({
        type: types.REMOVE_LINE,
        payload: index
    });
}

export const validateForm = (values, validateInvoiceDate = true, user) => {
    if (values.customer_id == null || values.customer_id === '') {
        showNotification('Complete Information', 'Select a Customer', 'info');
        return false;
    }
    if (values.due_date == null || values.due_date === '') {
        showNotification('Complete Information', 'Enter the Due Date', 'info');
        return false;
    }
    if (values.billing_name == null || values.billing_name === '') {
        showNotification('Complete Information', 'Enter the Billing name', 'info');
        return false;
    }
    if (values.billing_address == null || values.billing_address === '') {
        showNotification('Complete Information', 'Enter the Billing Address', 'info');
        return false;
    }
    if (values.billing_city == null || values.billing_city === '') {
        showNotification('Complete Information', 'Enter the Billing City', 'info');
        return false;
    }
    if (values.billing_state == null || values.billing_state === '') {
        showNotification('Complete Information', 'Enter the Billing State', 'info');
        return false;
    }
    if (values.billing_zip == null || values.billing_zip === '') {
        showNotification('Complete Information', 'Enter the Billing Zip', 'info');
        return false;
    }
    if (values.shipping_name == null || values.shipping_name === '') {
        showNotification('Complete Information', 'Enter the Shiping name', 'info');
        return false;
    }
    if (values.shipping_address == null || values.shipping_address === '') {
        showNotification('Complete Information', 'Enter the Shiping Address', 'info');
        return false;
    }
    if (values.shipping_city == null || values.shipping_city === '') {
        showNotification('Complete Information', 'Enter the Shiping City', 'info');
        return false;
    }
    if (values.shipping_state == null || values.shipping_state === '') {
        showNotification('Complete Information', 'Enter the Shiping State', 'info');
        return false;
    }
    if (values.shipping_zip == null || values.shipping_zip === '') {
        showNotification('Complete Information', 'Enter the Shiping Zip', 'info');
        return false;
    }
    if (values.sales_rep_id == null || values.sales_rep_id === '') {
        showNotification('Complete Information', 'Select a Sales Rep', 'info');
        return false;
    }
    if (values.csr_id == null || values.csr_id === '') {
        showNotification('Complete Information', 'Select a CSR', 'info');
        return false;
    }
    if (values.customer_terms == null && (values.terms == null || values.terms === '')) {
        showNotification('Complete Information', 'Select the Terms', 'info');
        return false;
    }
    if (validateInvoiceDate && parseInt(values.status) >= 3 && (values.invoice_date == null || values.invoice_date === '')) {
        showNotification('Complete Information', 'Enter the invoice date', 'info');
        return false;
    }

    const isPreliminary = parseInt(values.status) === 1;
    for (const item of values.items) {
        const departmentId = parseInt(item.department_id);

        // Prepaid Postage (56), Postage Sales (60), Shipping (17), Convenience Fee (17), Customer Permit (64), Meter (65), Stamp (66), ACM Paid Postage (67)
        if (isPreliminary && ([56, 60, 17, 63, 64, 65, 66, 67, 70].includes(departmentId))) {
            continue;
        }

        if (departmentId === 62) {      // Description (62)
            if (
                item.department_id === '' || item.department_id == null ||
                item.invoice_class_id === '' || item.invoice_class_id == null ||
                item.description === '' || item.description == null
            ) {
                showNotification('Complete Information', 'Enter the information for all the Items', 'info');
                return false;
            }
        } else {
            if (
                item.department_id === '' || item.department_id == null ||
                item.invoice_class_id === '' || item.invoice_class_id == null ||
                item.description === '' || item.description == null ||
                item.quantity === '' || item.quantity == null || isNaN(item.quantity) ||
                item.price === '' || item.price == null || isNaN(item.price) ||
                item.unit === '' || item.unit == null ||
                item.amount === '' || item.amount == null || isNaN(item.amount)
            ) {
                showNotification('Complete Information', 'Enter the information for all the Items', 'info');
                return false;
            }
        }

        if (user.id !== 61 && !empty(item['minimum_price'])) {
            const minimumAmount = parseFloat(item['minimum_amount']);

            let amountFloat = 0;
            if (!isNaN(item.amount) && !empty(item.amount)) {
                amountFloat = parseFloat(item.amount);
            }

            if (amountFloat < minimumAmount) {
                showNotification('Complete Information', 'One or more items are below the minimum price', 'info');
                return false;
            }
        }
    }

    return true;
}

export const setCustomerEmail = (value) => (dispatch) => {
    dispatch({
        type: types.SET_CUSTOMER_EMAIL,
        payload: value
    });
}

export const changeSortPosition = (index, value, type) => (dispatch) => {
    dispatch({
        type: types.CHANGE_SORT_POSITION,
        payload: { index, value, type }
    });
}

export const setSplitPercent1 = (value) => (dispatch, getState) => {
    if (value < 0) {
        value = 0;
    }
    if (value > 100) {
        value = 100;
    }

    dispatch({
        type: types.SET_SPLIT_PRECENT1,
        payload: { splitPercent1: value, splitPercent2: 100 - value },
    });
}

export const setSplitPercent2 = (value) => (dispatch, getState) => {
    if (value < 0) {
        value = 0;
    }
    if (value > 100) {
        value = 100;
    }

    dispatch({
        type: types.SET_SPLIT_PRECENT2,
        payload: { splitPercent1:  100 - value, splitPercent2: value },
    });
}
