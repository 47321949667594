import Immutable from 'immutable';
import * as types from './PqtLayout.types';
import uuid from 'react-uuid';

const initialState = {
    ws: {
        connection: null,
        connected: false,
        lastMessage: null,
        uuid: uuid(),
    },
    creatingTask: false,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.CREATE_TASK_REQUEST:
            return state.setIn(['creatingTask'], true).toJS();

        case types.CREATE_TASK_REQUEST_SUCCESS:
            return state.setIn(['creatingTask'], false).toJS();

        case types.CREATE_TASK_REQUEST_ERROR:
            return state.setIn(['creatingTask'], false).toJS();

        case types.SET_WS:
            return state.setIn(['ws', action.payload.field], action.payload.value).toJS();

        default:
            return state.toJS();
    }
}