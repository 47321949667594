import axios from 'axios';
import * as types from './QuoteRequest.types';
import * as selectors from './QuoteRequest.selectors';
import { validateAuthInResponse, showNotification, empty, showDataDeletedNotification } from './../../utils';
import * as dialog from '../common/dialog';
import fileDownload from 'js-file-download';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchRequest = (id) => (dispatch) => {
    if (id == null) {
        return;
    }

    dispatch({ type: types.FETCH_REQUEST, payload: null });
    axios.get(apiUrl + '/sc-quote-requests/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_REQUEST_SUCCESS, payload: response.data });

            if (!empty(response.data.main_part_id)) {
                dispatch(setMainPartId(response.data.main_part_id));
                dispatch(fetchMainPart(response.data.main_part_id));
            }

            const dataProcessingElements = JSON.parse(response.data.data_processing_elements);
            for (let i = 0; i < dataProcessingElements.length; i++) {
                if (parseInt(dataProcessingElements[i].type) === 1) {
                    dispatch(setDataProcessingItem(dataProcessingElements[i].id, 'checked', 1));
                }
                if (parseInt(dataProcessingElements[i].type) === 2) {
                    dispatch(setVariablePrintItem(dataProcessingElements[i].id, 'checked', 1));
                }
            }

            const mailingServicesElements = JSON.parse(response.data.mailing_services_elements);
            for (let i = 0; i < mailingServicesElements.length; i++) {
                if (parseInt(mailingServicesElements[i].type) === 3) {
                    dispatch(setInkjetItem(mailingServicesElements[i].id, 'checked', 1));
                }
                if (parseInt(mailingServicesElements[i].type) === 4) {
                    dispatch(setInsertingItem(mailingServicesElements[i].id, 'checked', 1));
                }
                if (parseInt(mailingServicesElements[i].type) === 5) {
                    dispatch(setMeteringItem(mailingServicesElements[i].id, 'checked', 1));
                }
            }

            const handworkElements = JSON.parse(response.data.handwork_elements);
            for (let i = 0; i < handworkElements.length; i++) {
                if (parseInt(mailingServicesElements[i].type) === 6) {
                    dispatch(setHandworkItem(handworkElements[i].id, 'checked', 1));
                }
            }
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_REQUEST_ERROR));
        });
}

export const fetchMainPart = (id) => (dispatch, getState) => {
    if (id == null) {
        return;
    }

    dispatch({ type: types.FETCH_MAIN_PART, payload: null });
    axios.get(apiUrl + '/sc-quote-request/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_MAIN_PART_SUCCESS, payload: response.data });
            dispatch(setField('csr_id', response.data.csr_id));
            dispatch(setField('customer_id', response.data.customer_id));
            dispatch(setField('sales_to_deliver', response.data.sales_to_deliver));
            dispatch(setField('shipping_to_type', response.data.shipping_to_type));
            dispatch(setField('address', response.data.address));
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_MAIN_PART_ERROR));
        });
}

export const fetchInformation = (id) => (dispatch) => {
    dispatch({ type: types.FETCH_INFORMATION, payload: null });
    axios.get(apiUrl + '/sc-quote-request/information',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            if (id != null) {
                dispatch(fetchRequest(id));
            }

            dispatch({ type: types.FETCH_INFORMATION_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_INFORMATION_ERROR));
        });
}

export const fetchSubstrateAccessories = (id) => (dispatch) => {
    dispatch({ type: types.FETCH_SUBSTRATE_ACCESSORIES, payload: null });
    axios.get(apiUrl + '/sc-quote-request/substrate-accessories?id=' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_SUBSTRATE_ACCESSORIES_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_SUBSTRATE_ACCESSORIES_ERROR));
        });
}

export const saveRequest = () => (dispatch, getState) => {
    const id = selectors.getId(getState());
    const history = selectors.getHistory(getState());
    const fields = selectors.getFields(getState());
    const selectedDataProcessingItems = selectors.getSelectedDataProcessingItems(getState());
    const selectedMailItems = selectors.getSelectedMailItems(getState());
    const selectedHandworkItems = selectors.getSelectedHandworkItems(getState());
    const selectedLargeFormateProducts = selectors.getSelectedLargeFormateProducts(getState());
    const selectedSubstrates = selectors.getSelectedSubstrates(getState());
    const selectedFinishingElements = selectors.getSelectedFinishingElements(getState());
    const files = selectors.getFiles(getState());
    const mainPartId = selectors.getMainPartId(getState());

    if (parseInt(fields.status) >= 3) {
        return showNotification('RFQ has been estimated', 'Your RFQ has been estimated. NO additional items can be added to your RFQ. Please see estimating managers to have your additional items added to your quote.', 'info');
    }

    let newFields = { ...fields };
    newFields.selectedDataProcessingItems = selectedDataProcessingItems;
    newFields.selectedMailItems = selectedMailItems;
    newFields.selectedHandworkItems = selectedHandworkItems;
    newFields.selectedLargeFormateProducts = selectedLargeFormateProducts;
    newFields.selectedSubstrates = selectedSubstrates;
    newFields.selectedFinishingElements = selectedFinishingElements;

    if (!validateFields(newFields)) {
        return;
    }

    var formData = new FormData();
    for (const field in newFields) {
        if (newFields[field] == null) {
            formData.append(field, newFields[field]);
            formData.delete(field);
        } else if (Array.isArray(newFields[field])) {
            formData.append(field, JSON.stringify(newFields[field]));
        } else {
            formData.append(field, newFields[field]);
        }
    }
    for (let i = 1; i <= files.length; i++) {
        if (!files[i - 1].url) {
            formData.append('file' + i, files[i - 1]);
        } else {
            formData.append('file' + i, JSON.stringify(files[i - 1]));
        }
    }
    formData.append('filesLength', files.length);

    if (!empty(mainPartId)) {
        formData.append('mainPartId', mainPartId);
    }

    let method = 'post';
    let endpoint = apiUrl + '/sc-quote-request';
    if (id != null) {
        formData.append('id', id);
        method = 'post';
        endpoint = apiUrl + '/sc-quote-request/update-post';
    }

    dispatch({ type: types.SAVE_REQUEST, payload: null });
    axios({
        method: method,
        url: endpoint,
        data: formData,
        headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
    }).then((response) => {
        dispatch({ type: types.SAVE_REQUEST_SUCCESS, payload: response.data });
        showNotification('RFQ Saved', 'The Request for Quote has been saved successfully', 'success');

        if (id != null) {
            dispatch(fetchRequest(response.data.id));
        } else {
            history.push('/request-for-quote/' + response.data.id);
        }
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.SAVE_REQUEST_ERROR));
    });
}

export const deleteRequest = (id) => (dispatch, getState) => {
    const history = selectors.getHistory(getState());
    const mainPart = selectors.getMainPart(getState());
    const fields = selectors.getFields(getState());

    dispatch({ type: types.DELETE_REQUEST, payload: null });
    axios.delete(apiUrl + '/sc-quote-request/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }, data: null }
    ).then((response) => {
        dispatch({ type: types.DELETE_REQUEST_SUCCESS, payload: null });
        showDataDeletedNotification();
        dispatch(dialog.actions.hideDialog('delete-part-modal'));

        if (fields.is_main_part) {
            history.push('/requests-for-quotes');
        } else {
            history.push('/request-for-quote/' + mainPart.id);
        }
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.DELETE_REQUEST_ERROR));
    });
}

export const submitRequest = () => (dispatch, getState) => {
    const id = selectors.getId(getState());
    const fields = selectors.getFields(getState());

    if (parseInt(fields.status) >= 3) {
        return showNotification('RFQ has been estimated', 'Your RFQ has been estimated. NO additional items can be added to your RFQ. Please see estimating managers to have your additional items added to your quote.', 'info');
    }

    dispatch({ type: types.SUBMIT_REQUEST, payload: null });
    axios({
        method: 'post',
        url: apiUrl + '/sc-quote-request/submit',
        data: { id },
        headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
    }).then((response) => {
        dispatch({ type: types.SUBMIT_REQUEST_SUCCESS, payload: response.data });
        showNotification('RFQ Submitted', 'The Request for Quote has been submitted successfully for estimate', 'success');
        dispatch(fetchRequest(id));
        dispatch(dialog.actions.hideDialog('confirm-submit-modal'))
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.SUBMIT_REQUEST_ERROR));
    });
}

export const downloadEstimate = () => (dispatch, getState) => {
    const fields = selectors.getFields(getState());

    dispatch({ type: types.DOWNLOAD_ESTIMATE, payload: null });
    axios.post(apiUrl + '/sc-estimate/download', { id: fields.sc_estimate_id },
        {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
            responseType: 'blob',
        })
        .then((response) => {
            dispatch({ type: types.DOWNLOAD_ESTIMATE_SUCCESS, payload: null });
            fileDownload(response.data, 'Estimate #' + fields.sc_estimate_id + '.pdf');
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.DOWNLOAD_ESTIMATE_ERROR));
        });
}

export const duplicatePart = () => (dispatch, getState) => {
    const fields = selectors.getFields(getState());
    const history = selectors.getHistory(getState());

    dispatch({ type: types.DUPLICATE_PART, payload: null });
    axios.post(apiUrl + '/sc-quote-request/duplicate-part', { id: fields.id },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }, data: null }
    ).then((response) => {
        dispatch({ type: types.DUPLICATE_PART_SUCCESS, payload: response.data });
        showNotification('Part Duplicated', 'The part has been duplicated successfully', 'success');
        history.push('/request-for-quote/' + response.data.id);
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.DUPLICATE_PART_ERROR));
    });
}

export const validateFields = (fields) => {
    if (empty(fields.customer_id)) {
        showNotification('Complete Information', 'Select a customer', 'info');
        return false;
    }
    if (empty(fields.csr_id)) {
        showNotification('Complete Information', 'Select a CSR', 'info');
        return false;
    }
    if (empty(fields.product_type)) {
        showNotification('Complete Information', 'Select a Product Type', 'info');
        return false;
    }
    if (empty(fields.process_request)) {
        showNotification('Complete Information', 'Select a Process Request', 'info');
        return false;
    }
    if (empty(fields.artwork_required)) {
        showNotification('Complete Information', 'Select a if the Artwork is required or not', 'info');
        return false;
    }
    if (empty(fields.description_title)) {
        showNotification('Complete Information', 'Enter the description title', 'info');
        return false;
    }
    if (empty(fields.quantity1)) {
        showNotification('Complete Information', 'Enter the quantity 1', 'info');
        return false;
    }
    if (empty(fields.address)) {
        showNotification('Complete Information', 'Enter the address', 'info');
        return false;
    }

    if (!empty(fields.simple_flat) && fields.simple_flat) {
        if (empty(fields.originals)) {
            showNotification('Complete Information', 'Enter the originals', 'info');
            return false;
        }
        if (empty(fields.front_inks)) {
            showNotification('Complete Information', 'Enter the Front Inks', 'info');
            return false;
        }
        if (empty(fields.back_inks)) {
            showNotification('Complete Information', 'Enter the Back Inks', 'info');
            return false;
        }
        if (empty(fields.flat_width)) {
            showNotification('Complete Information', 'Enter the Flat Width', 'info');
            return false;
        }
        if (empty(fields.flat_height)) {
            showNotification('Complete Information', 'Enter the Flat Height', 'info');
            return false;
        }
        if (empty(fields.finish_width)) {
            showNotification('Complete Information', 'Enter the Finish Width', 'info');
            return false;
        }
        if (empty(fields.finish_height)) {
            showNotification('Complete Information', 'Enter the Finish Height', 'info');
            return false;
        }
        if (empty(fields.paper_stock)) {
            showNotification('Complete Information', 'Select the Paper Stock', 'info');
            return false;
        }
        if (empty(fields.paper_weight)) {
            showNotification('Complete Information', 'Select the Paper Weight', 'info');
            return false;
        }
    }

    if (!empty(fields.self_cover) && fields.self_cover) {
        if (empty(fields.page_count)) {
            showNotification('Complete Information', 'Enter the Page Count', 'info');
            return false;
        }
        if (empty(fields.binding_edge)) {
            showNotification('Complete Information', 'Select the Binding Edge', 'info');
            return false;
        }
        if (empty(fields.binding_type)) {
            showNotification('Complete Information', 'Select the Binding Type', 'info');
            return false;
        }
        if (empty(fields.flat_width)) {
            showNotification('Complete Information', 'Enter the Flat Width', 'info');
            return false;
        }
        if (empty(fields.flat_height)) {
            showNotification('Complete Information', 'Enter the Flat Height', 'info');
            return false;
        }
        if (empty(fields.finish_width)) {
            showNotification('Complete Information', 'Enter the Finish Width', 'info');
            return false;
        }
        if (empty(fields.finish_height)) {
            showNotification('Complete Information', 'Enter the Finish Height', 'info');
            return false;
        }
        if (empty(fields.front_colors)) {
            showNotification('Complete Information', 'Enter the Interior Colors', 'info');
            return false;
        }
        if (empty(fields.front_inks)) {
            showNotification('Complete Information', 'Enter the Interior Inks', 'info');
            return false;
        }
        if (empty(fields.paper_stock)) {
            showNotification('Complete Information', 'Select the Paper Stock', 'info');
            return false;
        }
        if (empty(fields.paper_weight)) {
            showNotification('Complete Information', 'Select the Paper Weight', 'info');
            return false;
        }
    }

    if (!empty(fields.plus_cover) && fields.plus_cover) {
        if (empty(fields.cover_pages)) {
            showNotification('Complete Information', 'Enter the Cover Pages', 'info');
            return false;
        }
        if (empty(fields.interior_pages)) {
            showNotification('Complete Information', 'Enter the Interior Pages', 'info');
            return false;
        }
        if (empty(fields.flat_width)) {
            showNotification('Complete Information', 'Enter the Flat Width', 'info');
            return false;
        }
        if (empty(fields.flat_height)) {
            showNotification('Complete Information', 'Enter the Flat Height', 'info');
            return false;
        }
        if (empty(fields.finish_width)) {
            showNotification('Complete Information', 'Enter the Finish Width', 'info');
            return false;
        }
        if (empty(fields.finish_height)) {
            showNotification('Complete Information', 'Enter the Finish Height', 'info');
            return false;
        }
        if (empty(fields.front_inks)) {
            showNotification('Complete Information', 'Enter the Front Inks', 'info');
            return false;
        }
        if (empty(fields.back_inks)) {
            showNotification('Complete Information', 'Enter the Back Inks', 'info');
            return false;
        }
        if (empty(fields.paper_stock)) {
            showNotification('Complete Information', 'Select the Paper Stock', 'info');
            return false;
        }
        if (empty(fields.paper_weight)) {
            showNotification('Complete Information', 'Select the Paper Weight', 'info');
            return false;
        }
        if (empty(fields.interior_front_inks)) {
            showNotification('Complete Information', 'Enter the Interior Front Inks', 'info');
            return false;
        }
        if (empty(fields.interior_paper_stock)) {
            showNotification('Complete Information', 'Select the Interior Paper Stock', 'info');
            return false;
        }
        if (empty(fields.interior_paper_weight)) {
            showNotification('Complete Information', 'Select the Interior Paper Weight', 'info');
            return false;
        }
    }

    if (!empty(fields.envelopes) && fields.envelopes) {
        if (empty(fields.front_inks)) {
            showNotification('Complete Information', 'Enter the Front Inks', 'info');
            return false;
        }
        if (empty(fields.back_inks)) {
            showNotification('Complete Information', 'Enter the Back Inks', 'info');
            return false;
        }
        if (empty(fields.envelope_paper_type)) {
            showNotification('Complete Information', 'Select the Envelope Paper Type', 'info');
            return false;
        }
        if (empty(fields.envelope_paper_style)) {
            showNotification('Complete Information', 'Select the Envelope Paper Style', 'info');
            return false;
        }
        if (parseInt(fields.has_return_address) === 0 && parseInt(fields.has_back_flap) === 0 && parseInt(fields.has_address_area) === 0 && parseInt(fields.has_other) === 0) {
            showNotification('Complete Information', 'Select a Print Location', 'info');
            return false;
        }
        if (parseInt(fields.part_another_job) === 0 && parseInt(fields.done_when_completed) === 0 && parseInt(fields.goes_fulfillment) === 0) {
            showNotification('Complete Information', 'Select at least one Finish task', 'info');
            return false;
        }
    }

    if (!empty(fields.bindery) && fields.bindery) {

    }

    if (!empty(fields.data_processing) && fields.data_processing) {

    }

    if (!empty(fields.mailing_services) && fields.mailing_services) {

    }

    if (!empty(fields.handwork) && fields.handwork) {

    }

    if (!empty(fields.fulfillment) && fields.fulfillment) {

    }

    if (!empty(fields.large_format) && fields.large_format) {
        if (empty(fields.original)) {
            showNotification('Complete Information', 'Enter the Original', 'info');
            return false;
        }
        if (empty(fields.width)) {
            showNotification('Complete Information', 'Enter the Width', 'info');
            return false;
        }
        if (empty(fields.height)) {
            showNotification('Complete Information', 'Enter the Height', 'info');
            return false;
        }
        if (empty(fields.sc_large_format_product_id)) {
            showNotification('Complete Information', 'Select the Product', 'info');
            return false;
        }
        if (empty(fields.substrate_id)) {
            showNotification('Complete Information', 'Select the Substrate', 'info');
            return false;
        }
    }

    if (!empty(fields.promotional) && fields.promotional) {

    }

    if (!empty(fields.pocket_folder) && fields.pocket_folder) {
        if (empty(fields.originals)) {
            showNotification('Complete Information', 'Enter the originals', 'info');
            return false;
        }
        if (empty(fields.front_inks)) {
            showNotification('Complete Information', 'Enter the Front Inks', 'info');
            return false;
        }
        if (empty(fields.back_inks)) {
            showNotification('Complete Information', 'Enter the Back Inks', 'info');
            return false;
        }
        if (empty(fields.finish_width)) {
            showNotification('Complete Information', 'Enter the Finish Width', 'info');
            return false;
        }
        if (empty(fields.finish_height)) {
            showNotification('Complete Information', 'Enter the Finish Height', 'info');
            return false;
        }
        if (empty(fields.paper_stock)) {
            showNotification('Complete Information', 'Select the Paper Stock', 'info');
            return false;
        }
        if (empty(fields.paper_weight)) {
            showNotification('Complete Information', 'Select the Paper Weight', 'info');
            return false;
        }
        if (empty(fields.folder_type)) {
            showNotification('Complete Information', 'Select the Folder Type', 'info');
            return false;
        }
        if (empty(fields.pocket_style)) {
            showNotification('Complete Information', 'Select the Pocket Style', 'info');
            return false;
        }
        if (empty(fields.card_slits)) {
            showNotification('Complete Information', 'Select the Card Slits', 'info');
            return false;
        }
    }

    if (!empty(fields.is_ncr) && fields.is_ncr) {
        if (empty(fields.originals)) {
            showNotification('Complete Information', 'Enter the originals', 'info');
            return false;
        }
        if (empty(fields.front_inks)) {
            showNotification('Complete Information', 'Enter the Front Inks', 'info');
            return false;
        }
        if (empty(fields.back_inks)) {
            showNotification('Complete Information', 'Enter the Back Inks', 'info');
            return false;
        }
        if (empty(fields.finish_width)) {
            showNotification('Complete Information', 'Enter the Finish Width', 'info');
            return false;
        }
        if (empty(fields.finish_height)) {
            showNotification('Complete Information', 'Enter the Finish Height', 'info');
            return false;
        }
        if (empty(fields.ncr_sets)) {
            showNotification('Complete Information', 'Enter the Number of Sets', 'info');
            return false;
        }
        if (empty(fields.ncr_parts)) {
            showNotification('Complete Information', 'Select the Number of Parts', 'info');
            return false;
        }
        if (empty(fields.glue_location)) {
            showNotification('Complete Information', 'Select the Glue Location', 'info');
            return false;
        }
        if (empty(fields.perforate)) {
            showNotification('Complete Information', 'Select the Perforate', 'info');
            return false;
        }
        if (empty(fields.use_chip_board)) {
            showNotification('Complete Information', 'Select the Backing option', 'info');
            return false;
        }
        if (!empty(fields.use_crash_number) && fields.use_crash_number) {
            if (empty(fields.crash_number_start)) {
                showNotification('Complete Information', 'Enter the Start # for the Crash Number', 'info');
                return false;
            }
            if (empty(fields.crash_number_end)) {
                showNotification('Complete Information', 'Enter the End # for the Crash Number', 'info');
                return false;
            }
            if (empty(fields.crash_number_color)) {
                showNotification('Complete Information', 'Enter the Color for the Crash Number', 'info');
                return false;
            }
        }
    }

    if (!empty(fields.is_notepads) && fields.is_notepads) {
        if (empty(fields.originals)) {
            showNotification('Complete Information', 'Enter the originals', 'info');
            return false;
        }
        if (empty(fields.front_inks)) {
            showNotification('Complete Information', 'Enter the Front Inks', 'info');
            return false;
        }
        if (empty(fields.back_inks)) {
            showNotification('Complete Information', 'Enter the Back Inks', 'info');
            return false;
        }
        if (empty(fields.finish_width)) {
            showNotification('Complete Information', 'Enter the Finish Width', 'info');
            return false;
        }
        if (empty(fields.finish_height)) {
            showNotification('Complete Information', 'Enter the Finish Height', 'info');
            return false;
        }
        if (empty(fields.pads)) {
            showNotification('Complete Information', 'Enter the Number of Pads', 'info');
            return false;
        }
        if (empty(fields.sheets_per_pad)) {
            showNotification('Complete Information', 'Select the Sheets per Pad', 'info');
            return false;
        }
        if (empty(fields.paper_stock)) {
            showNotification('Complete Information', 'Select the Paper Stock', 'info');
            return false;
        }
        if (empty(fields.paper_weight)) {
            showNotification('Complete Information', 'Select the Paper Weight', 'info');
            return false;
        }
        if (empty(fields.glue_location)) {
            showNotification('Complete Information', 'Select the Glue Location', 'info');
            return false;
        }
        if (empty(fields.use_chip_board)) {
            showNotification('Complete Information', 'Select the Backing option', 'info');
            return false;
        }
        if (!empty(fields.use_crash_number) && fields.use_crash_number) {
            if (empty(fields.crash_number_start)) {
                showNotification('Complete Information', 'Enter the Start # for the Crash Number', 'info');
                return false;
            }
            if (empty(fields.crash_number_end)) {
                showNotification('Complete Information', 'Enter the End # for the Crash Number', 'info');
                return false;
            }
            if (empty(fields.crash_number_color)) {
                showNotification('Complete Information', 'Enter the Color for the Crash Number', 'info');
                return false;
            }
        }
    }

    if (!empty(fields.is_mail_only) && fields.is_mail_only) {
        if (empty(fields.originals)) {
            showNotification('Complete Information', 'Enter the originals', 'info');
            return false;
        }
        if (empty(fields.type_of_materials)) {
            showNotification('Complete Information', 'Enter the Type of Materials', 'info');
            return false;
        }
        if (empty(fields.material_coming_date)) {
            showNotification('Complete Information', 'Select when the material is coming', 'info');
            return false;
        }
        if (empty(fields.leftovers_to_do)) {
            showNotification('Complete Information', 'Select what to do with the leftovers', 'info');
            return false;
        }
        if (empty(fields.finish_width)) {
            showNotification('Complete Information', 'Enter the Material Width', 'info');
            return false;
        }
        if (empty(fields.finish_height)) {
            showNotification('Complete Information', 'Enter the Material Height', 'info');
            return false;
        }
    }

    return true;
}

export const setActiveTab = (value) => (dispatch) => {
    dispatch({
        type: types.SET_ACTIVE_TAB,
        payload: value
    });
}
export const setField = (name, value) => (dispatch) => {
    dispatch({
        type: types.SET_FIELD,
        payload: { name, value }
    });
}
export const setDataProcessingActiveTab = (value) => (dispatch) => {
    dispatch({
        type: types.SET_DATA_PROCESSING_ACTIVE_TAB,
        payload: value
    });
}
export const setDataProcessingItem = (id, field, value) => (dispatch) => {
    dispatch({
        type: types.SET_DATA_PROCESSING_ITEM,
        payload: { id, field, value }
    });
}
export const setSelectedDataProcessingItem = (id, field, type, value) => (dispatch) => {
    dispatch({
        type: types.SET_SELECTED_DATA_PROCESSING_ITEM,
        payload: { id, field, type, value }
    });
}
export const setSelectedMailItem = (id, field, type, value) => (dispatch) => {
    dispatch({
        type: types.SET_SELECTED_MAIL_ITEM,
        payload: { id, field, type, value }
    });
}
export const setSelectedHandworkItem = (id, field, type, value) => (dispatch) => {
    dispatch({
        type: types.SET_SELECTED_HANDWORK_ITEM,
        payload: { id, field, type, value }
    });
}
export const setVariablePrintItem = (id, field, value) => (dispatch) => {
    dispatch({
        type: types.SET_VARIABLE_PRINT_ITEM,
        payload: { id, field, value }
    });
}
export const calculateQuantity = (item, requestQuantity) => {
    let quantity = '';
    if (parseInt(item.unit) === 1) {    // Thousand
        quantity = requestQuantity;
    } else if (parseInt(item.unit) === 2) {    // Each
        quantity = requestQuantity;
    } else if (parseInt(item.unit) === 3) {    // Hour
        if (empty(item.quantity)) {
            quantity = 1;
        } else {
            quantity = item.quantity;
        }
    } else if (parseInt(item.unit) === 4) {    // File
        if (empty(item.quantity)) {
            quantity = 1;
        } else {
            quantity = item.quantity;
        }
    } else if (parseInt(item.unit) === 5) {    // Drop
        if (empty(item.quantity)) {
            quantity = 1;
        } else {
            quantity = item.quantity;
        }
    } else if (parseInt(item.unit) === 6) {    // Field
        if (empty(item.quantity)) {
            quantity = 1;
        } else {
            quantity = item.quantity;
        }
    } else if (parseInt(item.unit) === 7) {    // Job
        if (empty(item.quantity)) {
            quantity = 1;
        } else {
            quantity = item.quantity;
        }
    }

    return quantity;
}
export const addDataProcessingItems = () => (dispatch, getState) => {
    const dataProcessingItems = selectors.getDataProcessingItems(getState());
    const variablePrintItems = selectors.getVariablePrintItems(getState());
    const fields = selectors.getFields(getState());

    const items = [];
    for (let i = 0; i < dataProcessingItems.length; i++) {
        if (parseInt(dataProcessingItems[i].checked) === 1) {
            items.push({
                id: dataProcessingItems[i].id,
                name: dataProcessingItems[i].name,
                minimum_charge: dataProcessingItems[i].minimum_charge,
                unit: dataProcessingItems[i].unit,
                unit_price: dataProcessingItems[i].unit_price,
                type: 1,
                quantity: calculateQuantity(dataProcessingItems[i], fields.quantity1),
            });
        }
    }
    for (let i = 0; i < variablePrintItems.length; i++) {
        if (parseInt(variablePrintItems[i].checked) === 1) {
            items.push({
                id: variablePrintItems[i].id,
                name: variablePrintItems[i].name,
                minimum_charge: variablePrintItems[i].minimum_charge,
                unit: variablePrintItems[i].unit,
                unit_price: variablePrintItems[i].unit_price,
                type: 2,
                quantity: calculateQuantity(variablePrintItems[i], fields.quantity1),
            });
        }
    }

    dispatch({
        type: types.ADD_DATA_PROCESSING_ITEMS,
        payload: items,
    });
}
export const deleteDataProcessingItem = (id, type) => (dispatch) => {
    dispatch({
        type: types.DELETE_DATA_PROCESSING_ITEM,
        payload: { id, type }
    });
}
export const setMailActiveTab = (value) => (dispatch) => {
    dispatch({
        type: types.SET_MAIL_ACTIVE_TAB,
        payload: value
    });
}
export const setInkjetItem = (id, field, value) => (dispatch) => {
    dispatch({
        type: types.SET_INKJET_ITEM,
        payload: { id, field, value }
    });
}
export const setInsertingItem = (id, field, value) => (dispatch) => {
    dispatch({
        type: types.SET_INSERTING_ITEM,
        payload: { id, field, value }
    });
}
export const setMeteringItem = (id, field, value) => (dispatch) => {
    dispatch({
        type: types.SET_METERING_ITEM,
        payload: { id, field, value }
    });
}
export const addMailItems = () => (dispatch, getState) => {
    const inkjetItems = selectors.getInkjetItems(getState());
    const insertingItems = selectors.getInsertingItems(getState());
    const meteringItems = selectors.getMeteringItems(getState());
    const fields = selectors.getFields(getState());

    const items = [];
    for (let i = 0; i < inkjetItems.length; i++) {
        if (parseInt(inkjetItems[i].checked) === 1) {
            items.push({
                id: inkjetItems[i].id,
                name: inkjetItems[i].name,
                minimum_charge: inkjetItems[i].minimum_charge,
                unit: inkjetItems[i].unit,
                unit_price: inkjetItems[i].unit_price,
                type: 3,
                quantity: calculateQuantity(inkjetItems[i], fields.quantity1),
            });
        }
    }
    for (let i = 0; i < insertingItems.length; i++) {
        if (parseInt(insertingItems[i].checked) === 1) {
            items.push({
                id: insertingItems[i].id,
                name: insertingItems[i].name,
                minimum_charge: insertingItems[i].minimum_charge,
                unit: insertingItems[i].unit,
                unit_price: insertingItems[i].unit_price,
                type: 4,
                quantity: calculateQuantity(insertingItems[i], fields.quantity1),
            });
        }
    }
    for (let i = 0; i < meteringItems.length; i++) {
        if (parseInt(meteringItems[i].checked) === 1) {
            items.push({
                id: meteringItems[i].id,
                name: meteringItems[i].name,
                minimum_charge: meteringItems[i].minimum_charge,
                unit: meteringItems[i].unit,
                unit_price: meteringItems[i].unit_price,
                type: 5,
                quantity: calculateQuantity(meteringItems[i], fields.quantity1),
            });
        }
    }

    dispatch({
        type: types.ADD_MAIL_ITEMS,
        payload: items,
    });
}
export const deleteMailItem = (id, type) => (dispatch) => {
    dispatch({
        type: types.DELETE_MAIL_ITEM,
        payload: { id, type }
    });
}
export const setHandworkItem = (id, field, value) => (dispatch) => {
    dispatch({
        type: types.SET_HANDWORK_ITEM,
        payload: { id, field, value }
    });
}
export const addHandworkItems = () => (dispatch, getState) => {
    const handworkItems = selectors.getHandworkItems(getState());
    const fields = selectors.getFields(getState());

    const items = [];
    for (let i = 0; i < handworkItems.length; i++) {
        if (parseInt(handworkItems[i].checked) === 1) {
            items.push({
                id: handworkItems[i].id,
                name: handworkItems[i].name,
                minimum_charge: handworkItems[i].minimum_charge,
                unit: handworkItems[i].unit,
                unit_price: handworkItems[i].unit_price,
                type: 6,
                quantity: calculateQuantity(handworkItems[i], fields.quantity1),
            });
        }
    }

    dispatch({
        type: types.ADD_HANDWORK_ITEMS,
        payload: items,
    });
}
export const deleteHandworkItem = (id, type) => (dispatch) => {
    dispatch({
        type: types.DELETE_HANDWORK_ITEM,
        payload: { id, type }
    });
}
export const checkLargeFormatProduct = (id) => (dispatch) => {
    dispatch({
        type: types.CHECK_LARGE_FORMAT_PRODUCT,
        payload: id,
    });
}
export const checkSubstrate = (id) => (dispatch) => {
    dispatch({
        type: types.CHECK_SUBSTRATE,
        payload: id,
    });
}
export const checkFinishingElement = (id) => (dispatch) => {
    dispatch({
        type: types.CHECK_FINISHING_ELEMENT,
        payload: id,
    });
}
export const setId = (id) => (dispatch) => {
    dispatch({
        type: types.SET_ID,
        payload: id,
    });
}
export const setHistory = (history) => (dispatch) => {
    dispatch({
        type: types.SET_HISTORY,
        payload: history,
    });
}
export const resetFinishing = () => (dispatch) => {
    dispatch({
        type: types.RESET_FINISHING,
        payload: null,
    });
}
export const addFile = (file) => (dispatch) => {
    dispatch({
        type: types.ADD_FILE,
        payload: file,
    });
}
export const removeFile = (index) => (dispatch) => {
    dispatch({
        type: types.REMOVE_FILE,
        payload: index,
    });
}
export const clearFiles = (index) => (dispatch) => {
    dispatch({
        type: types.CLEAR_FILES,
        payload: index,
    });
}

export const setMainPartId = (value) => (dispatch) => {
    dispatch({
        type: types.SET_MAIN_PART_ID,
        payload: value,
    });
}

export const setIdForDelete = (id) => (dispatch) => {
    dispatch({
        type: types.SET_ID_FOR_DELETE,
        payload: id
    });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null
    });
}