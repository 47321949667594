import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from './Table';
import DeleteModal from './DeleteModal';
import ReportModal from './ReportModal';
import UploadListPurchaseModal from './UploadListPurchaseModal';
import * as actions from './PurchaseOrders.actions';
import * as selectors from './PurchaseOrders.selectors';
import './PurchaseOrders.scss';
import PqtLayout from './../PqtLayout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import * as dialog from '../common/dialog';
import * as loginSelectors from './../Login/Login.selectors';
import { Redirect } from 'react-router-dom';

const PurchaseOrders = (props) => {
    /* State to props */
    const fetchingPurchaseOrders = useSelector(selectors.getFetchingPurchaseOrders);
    const purchaseOrdersData = useSelector(selectors.getPurchaseOrdersData, stringifyEqualityCheck);
    const pagination = useSelector(selectors.getPagination, stringifyEqualityCheck);
    const sort = useSelector(selectors.getSort, stringifyEqualityCheck);
    const idForDelete = useSelector(selectors.getIdForDelete, stringifyEqualityCheck);
    const isDeleting = useSelector(selectors.getIsDeleting, stringifyEqualityCheck);
    const isExporting = useSelector(selectors.getIsExporting);
    const isExportingReport = useSelector(selectors.getIsExportingReport);
    const isExportingListPurchase = useSelector(selectors.getIsExportingListPurchase);
    const filterFields = useSelector(selectors.getFilterFields, stringifyEqualityCheck);
    const reportStartDate = useSelector(selectors.getReportStartDate, stringifyEqualityCheck);
    const reportEndDate = useSelector(selectors.getReportEndDate, stringifyEqualityCheck);
    const reportType = useSelector(selectors.getReportType);
    const file = useSelector(selectors.getFile, stringifyEqualityCheck);
    const isUploadingListPurchase = useSelector(selectors.getIsUploadingListPurchase);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const deletePurchaseOrder = useCallback((id) => dispatch(actions.deletePurchaseOrder(id)), [dispatch]);
    const setSort = useCallback((column, type) => dispatch(actions.setSort(column, type)), [dispatch]);
    const setActivePage = useCallback((name, value) => dispatch(actions.setActivePage(name, value)), [dispatch]);
    const setIdForDelete = useCallback((id) => dispatch(actions.setIdForDelete(id)), [dispatch]);
    const changeFilter = useCallback((name, value) => dispatch(actions.changeFilter(name, value)), [dispatch]);
    const exportExcel = useCallback(() => dispatch(actions.exportExcel()), [dispatch]);
    const exportReport = useCallback(() => dispatch(actions.exportReport()), [dispatch]);
    const exportListPurchase = useCallback(() => dispatch(actions.exportListPurchase()), [dispatch]);
    const setReportStartDate = useCallback((value) => dispatch(actions.setReportStartDate(value)), [dispatch]);
    const setReportEndDate = useCallback((value) => dispatch(actions.setReportEndDate(value)), [dispatch]);
    const setReportType = useCallback((value) => dispatch(actions.setReportType(value)), [dispatch]);
    const setFile = useCallback((file) => dispatch(actions.setFile(file)), [dispatch]);
    const uploadListPurchase = useCallback((value) => dispatch(actions.uploadListPurchase(value)), [dispatch]);
    const showDeleteModal = useCallback(() => dispatch(dialog.actions.showDialog('delete-modal')), [dispatch]);
    const hideDeleteModal = useCallback(() => dispatch(dialog.actions.hideDialog('delete-modal')), [dispatch]);
    const showReportModal = useCallback(() => dispatch(dialog.actions.showDialog('report-modal')), [dispatch]);
    const hideReportModal = useCallback(() => dispatch(dialog.actions.hideDialog('report-modal')), [dispatch]);
    const showUploadListPurchaseModal = useCallback(() => dispatch(dialog.actions.showDialog('upload-list-purchase-modal')), [dispatch]);
    const hideUploadListPurchaseModal = useCallback(() => dispatch(dialog.actions.hideDialog('upload-list-purchase-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        dispatch(actions.mountComponent());
    }, [dispatch]);

    // Kimberly (26), Randy (35), Debbie (42), Skip (48), John (52), Kevin Wilson (53), Gary (58), Robert (59), Hugh (61), Norm (63), 
    // Christy (64), Kellie R. (75), Brenda (100), Grace (348), AJ (571), Heather (28), Heather (34), Nichole (245), Mike (36), Kellie A. (78),
    // April (89), AP (1147), Beatriz (31), Nyke (33), Rocio (32), Terri (2335), Edward (1180), Kathy (76), Lupe (34), Stephanie (2419), Paul (3153), 
    // Kassandra (3566), Robert Duncan (3695)
    if (user != null && ![26, 35, 42, 48, 52, 53, 58, 59, 61, 63, 64, 75, 100, 348, 571, 28, 34, 245, 36, 78, 89, 1147, 31, 32, 33, 1180, 76, 2335, 34, 2419, 3153, 3566, 3695].includes(parseInt(user.id))) {
        return <Redirect to="/dashboard" />;
    }

    return (
        <PqtLayout title="Purchase Orders" history={props.history}>
            <Helmet><title>Purchase Orders - Stagecoach</title></Helmet>
            <div className="purchase-orders-container">
                <Table
                    purchaseOrdersData={purchaseOrdersData}
                    pagination={pagination}
                    fetchingPurchaseOrders={fetchingPurchaseOrders}
                    history={props.history}
                    sort={sort}
                    user={user}
                    isExporting={isExporting}
                    isExportingReport={isExportingReport}
                    isExportingListPurchase={isExportingListPurchase}
                    filterFields={filterFields}
                    setSort={setSort}
                    setActivePage={setActivePage}
                    showDeleteModal={showDeleteModal}
                    setIdForDelete={setIdForDelete}
                    changeFilter={changeFilter}
                    exportExcel={exportExcel}
                    showReportModal={showReportModal}
                    setReportType={setReportType}
                    showUploadListPurchaseModal={showUploadListPurchaseModal}
                />
                <DeleteModal
                    idForDelete={idForDelete}
                    isDeleting={isDeleting}
                    deletePurchaseOrder={deletePurchaseOrder}
                    hideDeleteModal={hideDeleteModal}
                    setIdForDelete={setIdForDelete}
                />
                <ReportModal
                    isExportingReport={isExportingReport}
                    isExportingListPurchase={isExportingListPurchase}
                    reportStartDate={reportStartDate}
                    reportEndDate={reportEndDate}
                    reportType={reportType}
                    exportReport={exportReport}
                    exportListPurchase={exportListPurchase}
                    hideReportModal={hideReportModal}
                    setReportStartDate={setReportStartDate}
                    setReportEndDate={setReportEndDate}
                />
                <UploadListPurchaseModal
                    isUploadingListPurchase={isUploadingListPurchase}
                    file={file}
                    setFile={setFile}
                    uploadListPurchase={uploadListPurchase}
                    hideUploadListPurchaseModal={hideUploadListPurchaseModal}
                />
            </div>
        </PqtLayout>
    );
};

export default PurchaseOrders;
