import axios from 'axios';
import * as types from './PqtInvoices.types';
import * as selectors from './PqtInvoices.selectors';
import * as dialog from '../common/dialog';
import { validateAuthInResponse, showDataDeletedNotification, sendWsMessage, showNotification } from './../../utils';
import fileDownload from 'js-file-download';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchInvoices = (options, isWsUpdate = false) => (dispatch, getState) => {
    const sort = selectors.getSort(getState());
    const pagination = selectors.getPagination(getState());
    const show = selectors.getShow(getState());
    const filerFields = selectors.getFilterFields(getState());

    let params = [];
    params.push('sort=' + (sort.type === 'asc' ? '' : '-') + sort.column);
    params.push('active-page=' + pagination.activePage);
    params.push('elements-per-page=' + pagination.elementsPerPage);

    if (typeof options !== 'undefined' && typeof options.showPreliminary !== 'undefined' && options.showPreliminary) {
        params.push('show-preliminary=1');
    } else if (typeof options === 'undefined' || (typeof options !== 'undefined' && typeof options.showPreliminary === 'undefined')) {
        if (show.preliminary) {
            params.push('show-preliminary=1')
        }
    }

    if (typeof options !== 'undefined' && typeof options.showClosed !== 'undefined' && options.showClosed) {
        params.push('show-closed=1');
    } else if (typeof options === 'undefined' || (typeof options !== 'undefined' && typeof options.showClosed === 'undefined')) {
        if (show.closed) {
            params.push('show-closed=1')
        }
    }

    if (typeof options !== 'undefined' && typeof options.showApproved !== 'undefined' && options.showApproved) {
        params.push('show-approved=1');
    } else if (typeof options === 'undefined' || (typeof options !== 'undefined' && typeof options.showApproved === 'undefined')) {
        if (show.approved) {
            params.push('show-approved=1')
        }
    }

    if (typeof options !== 'undefined' && typeof options.showFinal !== 'undefined' && options.showFinal) {
        params.push('show-final=1');
    } else if (typeof options === 'undefined' || (typeof options !== 'undefined' && typeof options.showFinal === 'undefined')) {
        if (show.final) {
            params.push('show-final=1')
        }
    }

    if (filerFields.length > 0) {
        let filterElements = [];
        for (let i = 0; i < filerFields.length; i++) {
            filterElements.push(filerFields[i]['name'] + '=' + filerFields[i]['value']);
        }
        params.push('filter=' + filterElements.join(','))
    } else {
        params.push('filter=-')
    }

    dispatch({ type: types.FETCH_INVOICES, payload: isWsUpdate });
    axios.get(apiUrl + '/invoice/pqt-invoices' + (params.length === 0 ? '' : '?' + params.join('&')),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_INVOICES_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_INVOICES_ERROR));
        });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null
    });
}

export const deleteInvoice = (id, comments) => (dispatch) => {
    dispatch({ type: types.DELETE_INVOICE, payload: null });
    axios.delete(apiUrl + '/invoice/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }, data: { comments } }
    ).then(() => {
        dispatch({ type: types.DELETE_INVOICE_SUCCESS, payload: null });
        showDataDeletedNotification();
        dispatch(fetchInvoices());
        dispatch(sendWsMessage({ id, type: 'invoice_deleted' }));
        dispatch(dialog.actions.hideDialog('delete-modal'));
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.DELETE_INVOICE_ERROR));
    });
}

export const setSort = (column, type) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_SORT,
            payload: {
                column: column,
                type: type,
            }
        });
        resolve();
    }).then(() => {
        dispatch(fetchInvoices());
    });
}

export const setActivePage = (value) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_ACTIVE_PAGE,
            payload: value
        });
        resolve();
    }).then(() => {
        dispatch(fetchInvoices());
    });
}

export const mountComponent = (status, type) => (dispatch, getState) => {
    const show = selectors.getShow(getState());

    let showPreliminary = show.preliminary;
    let showClosed = show.closed;
    let showApproved = show.approved;
    let showFinal = show.final;
    if (typeof status !== 'undefined') {
        if (parseInt(status) === 1) {
            showPreliminary = true;
            showClosed = false;
            showApproved = false;
            showFinal = false;
        } else if (parseInt(status) === 2) {
            showPreliminary = false;
            showClosed = true;
            showApproved = false;
            showFinal = false;
        } else if (parseInt(status) === 3) {
            showPreliminary = false;
            showClosed = false;
            showApproved = true;
            showFinal = false;
        } else if (parseInt(status) === 4) {
            showPreliminary = false;
            showClosed = false;
            showApproved = false;
            showFinal = true;
        }
    }

    let projectType = '';
    if (parseInt(status) === 2 && typeof type !== 'undefined') {
        projectType = type;
    }

    new Promise((resolve) => {
        dispatch(changeFilter('project_type', projectType));
        resolve();
    }).then(() => {
        dispatch(fetchInvoices({ showPreliminary, showClosed, showApproved, showFinal }));
        dispatch(setShow('preliminary', showPreliminary, false));
        dispatch(setShow('closed', showClosed, false));
        dispatch(setShow('approved', showApproved, false));
        dispatch(setShow('final', showFinal, false));
    });
}

export const setIdForDelete = (id) => (dispatch) => {
    dispatch({
        type: types.SET_ID_FOR_DELETE,
        payload: id
    });
}

export const setShow = (name, value, loadInvoices = true) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_SHOW,
            payload: { name, value }
        });
        resolve();
    }).then(() => {
        if (loadInvoices) {
            dispatch(fetchInvoices());
        }
    });
}

export const checkInvoice = (value, checked) => (dispatch) => {
    dispatch({
        type: types.CHECK_INVOICE,
        payload: { value, checked }
    });
}

export const combineInvoices = () => (dispatch, getState) => {
    const ids = selectors.getCombineIds(getState());

    dispatch({ type: types.COMBINE_INVOICES, payload: null });
    axios.post(apiUrl + '/invoice/combine-invoices', { ids },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } }
    ).then((response) => {
        dispatch({ type: types.COMBINE_INVOICES_SUCCESS, payload: response.data.combined });
        console.log(response.data.code)
        if (response.data.code === 2) {
            showNotification('Invoices not ready', 'All the selected invoices must be closed out to combine them.', 'warning');
        } else if (response.data.code === 3) {
            showNotification('Different customers', 'All the selected invoices must belong to the same customer to combine them.', 'warning');
        } else {
            showNotification('Invoices combined', 'The invoices has been combined successfully.', 'success');
            dispatch(fetchInvoices());
            dispatch(sendWsMessage({ type: 'invoice_updated' }));
            dispatch(dialog.actions.hideDialog('combine-modal'));
        }
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.COMBINE_INVOICES_ERROR));
    });
}

export const changeFilter = (name, value, fetch = false) => (dispatch) => {
    new Promise((resolve) => {
        dispatch({
            type: types.CHANGE_FILTER,
            payload: { name, value }
        });
        resolve();
    }).then(() => {
        if (fetch) {
            dispatch(fetchInvoices());
        }
    });
}

export const exportExcel = () => (dispatch, getState) => {
    const show = selectors.getShow(getState());

    let type = '';
    let filename = '';
    if (show.preliminary) {
        type = 1;
        filename = 'Preliminary';
    }
    if (show.closed) {
        type = 2;
        filename = 'Closed';
    }
    if (show.approved) {
        type = 3;
        filename = 'Approved';
    }
    if (show.final) {
        type = 4;
        filename = 'Final';
    }

    dispatch({ type: types.EXPORT_EXCEL, payload: null });
    axios.post(apiUrl + '/invoice/export-excel', { type },
        {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
            responseType: 'blob',
        })
        .then((response) => {
            dispatch({ type: types.EXPORT_EXCEL_SUCCESS, payload: null });
            fileDownload(response.data, filename + ' Invoices.xlsx');
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.EXPORT_EXCEL_ERROR));
        });
}