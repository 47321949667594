import React from 'react';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import PqtPagination from './../../PqtPagination';
import { dateToTimezone } from './../../../utils';
import moment from 'moment-timezone';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';

const Customers = React.memo((props) => {
    const confirmHandler = (id, name) => {
        props.showDeleteModal();
        props.setIdForDelete(id);
        props.setCustomerName(name);
    }

    const handleEdit = (id) => {
        props.fetchOnHoldCustomer(id);
        props.showHoldModal();
    }

    const renderCustomers = () => {
        if (props.fetchingOnHoldCustomers) {
            return (
                <tr>
                    <td colSpan="8"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.onHoldCustomers.length === 0) {
            return (
                <tr>
                    <td colSpan="8">No Results</td>
                </tr>
            );
        }

        return props.onHoldCustomers.map((customer, index) => {
            let createdDate = '-';
            if (customer.created_at != null) {
                createdDate = dateToTimezone(customer.created_at, 'MM/DD/YYYY');
            }

            let deleteIcon = null;
            if (props.user != null && ([1, 9].includes(parseInt(props.user.pqt_type)) || props.user.is_pqt_accounting)) {
                deleteIcon = (
                    <Tooltip title="Remove Hold" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="fas fa-trash" onClick={confirmHandler.bind(this, customer.id, customer.display_name)} />
                    </Tooltip>
                );
            }

            let status = null;
            if (parseInt(customer.on_hold_status) === 1) {
                status = 'Default';
            } else if (parseInt(customer.on_hold_status) === 2) {
                status = 'On Hold';
            } else if (parseInt(customer.on_hold_status) === 3) {
                status = 'Closed';
            } else if (parseInt(customer.on_hold_status) === 4) {
                status = 'Warning';
            }

            return (
                <tr key={'customer-' + index}>
                    <td>{createdDate}</td>
                    <td>{customer.display_name}</td>
                    <td>{customer.sales_rep_name}</td>
                    <td>{customer.csr_name}</td>
                    <td>{status}</td>
                    <td>{customer.reason}</td>
                    <td>{customer.other_reason}</td>
                    <td>
                        <div className="actions">
                            <Tooltip title="Edit" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-edit" onClick={handleEdit.bind(this, customer.id)} />
                            </Tooltip>
                            {deleteIcon}
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const setSortHandler = (field, value) => {
        props.setSort(field, value);
    };

    const renderHeader = (name, column, className) => {
        let sortFunc = setSortHandler.bind(this, column, 'asc');
        let sortIcon = <i className="fas fa-sort" />;
        if (props.sort.column === column) {
            if (props.sort.type === 'asc') {
                sortFunc = setSortHandler.bind(this, column, 'desc');
                sortIcon = <i className="fas fa-sort-up" />;
            } else {
                sortFunc = setSortHandler.bind(this, column, 'asc');
                sortIcon = <i className="fas fa-sort-down" />;
            }
        }

        return <th className={className} onClick={sortFunc}>{name} {sortIcon}</th>;
    };

    const handleChangeFilter = (name, fetch, event) => {
        props.changeFilter(name, event.target.value, fetch);
    };

    const handleKey = (event) => {
        if (event.key === 'Enter') {
            props.fetchOnHoldCustomers();
        }
    };

    const renderSearchField = (name) => {
        let value = null;
        for (let i = 0; i < props.filerFields.length; i++) {
            if (props.filerFields[i].name === name) {
                value = props.filerFields[i].value;
            }
        }

        return (
            <th className="search-th">
                <input
                    type="text"
                    name={name}
                    className="search-input"
                    value={value}
                    onKeyPress={handleKey}
                    onChange={handleChangeFilter.bind(this, name, false)}
                />
            </th>
        );
    };

    const setDateFieldHandler = (name, event) => {
        let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');
        props.changeFilter(name, value, true);
    };

    const renderSearchDateField = (name) => {
        let value = null;
        for (let i = 0; i < props.filerFields.length; i++) {
            if (props.filerFields[i].name === name) {
                value = props.filerFields[i].value;
            }
        }

        return (
            <th className="search-th">
                <DayPickerInput
                    formatDate={formatDate}
                    parseDate={parseDate}
                    placeholder=""
                    className="date-picker-field"
                    onDayChange={setDateFieldHandler.bind(this, name)}
                    value={value == null || value === '0000-00-00' ? '' : moment(value).format('MM/DD/YYYY')}
                />
            </th>
        )
    };

    let downloadButton = <button className="report-button" onClick={props.downloadReport}><i className="fas fa-download" /> Download A/R Aging Report</button>;
    if (props.downloadingReport) {
        downloadButton = <button className="report-button" disabled><i className="fas fa-circle-notch fa-spin" /></button>;
    }

    return (
        <div className="customers-container">
            <div className="actions-container">
                {downloadButton}
                <button className="new-request-button" onClick={props.showHoldModal}><i className="fas fa-plus-square" /> New On Hold</button>
            </div>
            <div className="table-container">
                <table className="table table-sm table-hover table-striped">
                    <thead>
                        <tr>
                            {renderHeader('Created At', 'created_at', 'th-date')}
                            {renderHeader('Customer', 'customer_name', 'th-csr')}
                            {renderHeader('Sales Rep', 'sales_rep_name', 'th-csr')}
                            {renderHeader('CSR', 'csr_name', 'th-csr')}
                            {renderHeader('Status', 'status', 'th-status')}
                            {renderHeader('Reason', 'reason', 'th-description')}
                            {renderHeader('Other Reason', 'other_reason', 'th-description')}
                            <th style={{ width: 90 }}></th>
                        </tr>
                        <tr>
                            {renderSearchDateField('created_at')}
                            {renderSearchField('customer_name')}
                            {renderSearchField('sales_rep_name')}
                            {renderSearchField('csr_name')}
                            {renderSearchField('status')}
                            {renderSearchField('reason')}
                            {renderSearchField('other_reason')}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderCustomers()}
                    </tbody>
                </table>
                <PqtPagination
                    pagination={props.pagination}
                    setActivePage={props.setActivePage}
                />
            </div>
        </div>
    );
});

export default Customers;
