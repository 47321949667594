import axios from 'axios';
import * as types from './CreditMemo.types';
import { validateAuthInResponse, showNotification } from './../../utils';
import * as selectors from './CreditMemo.selectors';
import * as dialog from '../common/dialog';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchCreditMemo = (id) => (dispatch) => {
    dispatch({ type: types.FETCH_CREDIT_MEMO, payload: null });
    axios.get(apiUrl + '/credit-memos/' + id + '?expand=items,auditHistory,customerAddress',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_CREDIT_MEMO_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_CREDIT_MEMO_ERROR));
        });
}

export const fetchInformation = () => (dispatch) => {
    dispatch({ type: types.FETCH_INFORMATION, payload: null });
    axios.get(apiUrl + '/credit-memo/data',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_INFORMATION_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_INFORMATION_ERROR));
        });
}

export const fetchCustomerData = (id) => (dispatch) => {
    console.log('fetchCustomerData')
    dispatch({ type: types.FETCH_CUSTOMER_DATA, payload: null });
    axios.get(apiUrl + '/credit-memo/customer-data?id=' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_CUSTOMER_DATA_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_CUSTOMER_DATA_ERROR));
        });
}

export const saveCreditMemo = (history) => (dispatch, getState) => {
    const creditMemo = selectors.getCreditMemo(getState());
    if (!validateForm(creditMemo)) {
        return;
    }

    let endpoint = apiUrl + '/credit-memos';
    let method = 'post';
    if (creditMemo.id && creditMemo.id != null) {
        endpoint = apiUrl + '/credit-memos/' + creditMemo.id;
        method = 'put';
    }

    dispatch({ type: types.SAVE_CREDIT_MEMO, payload: null });
    axios({
        method: method,
        url: endpoint,
        data: creditMemo,
        headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
    }).then((response) => {
        dispatch({ type: types.SAVE_CREDIT_MEMO_SUCCESS, payload: response.data });
        showNotification('Credit Memo Saved', 'The Credit Memo has been saved successfully', 'success');

        if (creditMemo.id && creditMemo.id != null) {
            dispatch(fetchCreditMemo(creditMemo.id));
        } else {
            history.push('/credit-memo/' + response.data.id);
        }
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.SAVE_CREDIT_MEMO_ERROR));
    });
}

export const qBPush = (option) => (dispatch, getState) => {
    const creditMemo = selectors.getCreditMemo(getState());
    const customerEmail = selectors.getCustomerEmail(getState());
    if (!validateForm(creditMemo)) {
        return;
    }

    dispatch({ type: types.QB_PUSH, payload: null });
    axios.post(apiUrl + '/credit-memo/push-to-qb', { id: creditMemo.id, option, email: customerEmail },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.QB_PUSH_SUCCESS, payload: response.data });

            const validateCustomerSent = (response) => {
                if (response.data.customerSent) {
                    showNotification('Credit Memo sent to the Customer', 'The Credit Memo has been sent to the Customer email', 'success');
                }
            };

            const validateCreditMemoSaved = (response) => {
                if (parseInt(response.data.code) === 1) {
                    showNotification('Credit Memo saved in QB', 'The Credit Memo has been saved successfully in QuickBooks', 'success');
                    dispatch(fetchCreditMemo(creditMemo.id));
                } else {
                    showNotification('Credit Memo was not saved', response.data.message, 'warning');
                }
            };

            if (parseInt(option) === 1) {
                validateCustomerSent(response);
                validateCreditMemoSaved(response);
            } else if (parseInt(option) === 2) {
                validateCustomerSent(response);
            } else if (parseInt(option) === 3) {
                validateCreditMemoSaved(response);
            }

            dispatch(dialog.actions.hideDialog('push-modal'));
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.QB_PUSH_ERROR));
        });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null
    });
}

export const setField = (field, value) => (dispatch) => {
    dispatch({
        type: types.SET_FIELD,
        payload: { field, value }
    });
}

export const setItemField = (index, field, value) => (dispatch) => {
    dispatch({
        type: types.SET_ITEM_FIELD,
        payload: { index, field, value }
    });
}

export const addLine = () => (dispatch) => {
    dispatch({
        type: types.ADD_LINE,
        payload: null
    });
}

export const removeLine = (index) => (dispatch) => {
    dispatch({
        type: types.REMOVE_LINE,
        payload: index
    });
}

export const validateForm = (values) => {
    if (values.customer_id == null || values.customer_id === '') {
        showNotification('Complete Information', 'Select a Customer', 'info');
        return false;
    }
    if (values.customer_email == null || values.customer_email === '') {
        showNotification('Complete Information', 'Enter the Customer Email', 'info');
        return false;
    }
    if (values.date == null || values.date === '') {
        showNotification('Complete Information', 'Select a Credit Memo Date', 'info');
        return false;
    }

    for (const item of values.items) {
        if (
            item.department_id === '' || item.department_id == null ||
            item.credit_memo_class_id === '' || item.credit_memo_class_id == null ||
            item.description === '' || item.description == null ||
            item.amount === '' || item.amount == null || isNaN(item.amount)
        ) {
            showNotification('Complete Information', 'Enter the information for all the Items', 'info');
            return false;
        }
    }

    return true;
}

export const setCustomerEmail = (value) => (dispatch) => {
    dispatch({
        type: types.SET_CUSTOMER_EMAIL,
        payload: value
    });
}

export const changeSortPosition = (index, value, type) => (dispatch) => {
    dispatch({
        type: types.CHANGE_SORT_POSITION,
        payload: { index, value, type }
    });
}
