export const FETCH_REQUESTS = 'QUOTE_REQUESTS/FETCH_REQUESTS';
export const FETCH_REQUESTS_SUCCESS = 'QUOTE_REQUESTS/FETCH_REQUESTS_SUCCESS';
export const FETCH_REQUESTS_ERROR = 'QUOTE_REQUESTS/FETCH_REQUESTS_ERROR';
export const DELETE_REQUEST = 'QUOTE_REQUESTS/DELETE_REQUEST';
export const DELETE_REQUEST_SUCCESS = 'QUOTE_REQUESTS/DELETE_REQUEST_SUCCESS';
export const DELETE_REQUEST_ERROR = 'QUOTE_REQUESTS/DELETE_REQUEST_ERROR';
export const RESET_STATE = 'QUOTE_REQUESTS/RESET_STATE';
export const SET_ID = 'QUOTE_REQUESTS/SET_ID';
export const SET_SORT = 'QUOTE_REQUESTS/SET_SORT';
export const SET_ACTIVE_PAGE = 'QUOTE_REQUESTS/SET_ACTIVE_PAGE';
export const SET_ID_FOR_DELETE = 'QUOTE_REQUESTS/SET_ID_FOR_DELETE';
export const CHANGE_FILTER = 'QUOTE_REQUESTS/CHANGE_FILTER';