import React, { Fragment } from 'react';
import { Dialog } from './../../common/dialog';
import { empty } from '../../../utils';
import CustomersOptions from './CustomersOptions';

const HoldModal = React.memo((props) => {
    const hideHandler = () => {
        props.hideHoldModal();
        props.resetFields();
    };

    const addHoldHandler = () => {
        props.addHold();
    };

    let label = 'Save';
    if (props.addingHold) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    const handleSetField = (field, event) => {
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        }

        props.setField(field, value);
    };

    const handleSetStatus = (value) => {
        if (parseInt(props.fields.status) === parseInt(value)) {
            props.setField('status', '');
        } else {
            props.setField('status', value);
        }
    };

    const getValue = (value) => {
        if (empty(value)) {
            return '';
        }

        return value;
    };

    let email = 'N/A';
    if (!empty(props.fields.customer_id)) {
        for (let i = 0; i < props.information.customers.length; i++) {
            const customer = props.information.customers[i];
            if (parseInt(customer.id) === parseInt(props.fields.customer_id) && !empty(customer.email)) {
                email = customer.email;
                break;
            }
        }
    }

    let content = <div className="loading-container"><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
    if (!props.fetchingOnHoldCustomer) {
        content = (
            <Fragment>
                <div className="select-customer-title">Select Customer:</div>
                <div className="status-title">Status:</div>
                <div className="customer-field">
                    <div className="label"><b>Customer:</b></div>
                    <CustomersOptions
                        customerId={getValue(props.fields.customer_id)}
                        customers={props.information.customers}
                        setField={props.setField}
                    />
                    <div className="email-container"><b>Email:</b> {email}</div>
                </div>
                <div className="warning-field">
                    <div className="label">Warning</div>
                    <div className="input">
                        <input type="checkbox" checked={props.fields.status === 4} onChange={handleSetStatus.bind(this, 4)} />
                    </div>
                </div>
                <div className="hold-field">
                    <div className="label">Hold</div>
                    <div className="input">
                        <input type="checkbox" checked={props.fields.status === 2} onChange={handleSetStatus.bind(this, 2)} />
                    </div>
                </div>
                <div className="closed-field">
                    <div className="label">Closed</div>
                    <div className="input">
                        <input type="checkbox" checked={props.fields.status === 3} onChange={handleSetStatus.bind(this, 3)} />
                    </div>
                </div>
                <div className="check-bounced-field">
                    <div className="label">Check Bounced</div>
                    <div className="input">
                        <input type="checkbox" checked={getValue(props.fields.check_bounced)} onChange={handleSetField.bind(this, 'check_bounced')} />
                        <input type="text" placeholder="Check #" value={getValue(props.fields.check)} onChange={handleSetField.bind(this, 'check')} />
                    </div>
                </div>
                <div className="limit-hold-field">
                    <div className="label">Limit hold to amount</div>
                    <div className="input">
                        <input type="checkbox" checked={getValue(props.fields.limit_hold_amount)} onChange={handleSetField.bind(this, 'limit_hold_amount')} />
                        <input type="text" placeholder="Amount" value={getValue(props.fields.hold_amount)} onChange={handleSetField.bind(this, 'hold_amount')} />
                    </div>
                </div>
                <div className="other-reason-field">
                    <div className="label">Other reason</div>
                    <div className="input">
                        <input type="text" value={getValue(props.fields.other_reason)} onChange={handleSetField.bind(this, 'other_reason')} />
                    </div>
                </div>
                <div className="reason-field">
                    <div><b>Please describe reason:</b></div>
                    <textarea value={getValue(props.fields.reason)} onChange={handleSetField.bind(this, 'reason')} />
                </div>
                <div className="email-field">
                    <div><b>Email &amp; Copy:</b></div>
                    <input type="checkbox" checked={getValue(props.fields.email_to_customer)} onChange={handleSetField.bind(this, 'email_to_customer')} /> Customer
                    <input type="checkbox" checked={getValue(props.fields.email_to_sales)} onChange={handleSetField.bind(this, 'email_to_sales')} /> Sales
                </div>
            </Fragment>
        );
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Cancel</button>,
        <button key="delete-button" className="btn btn-light btn-sm action-button" onClick={addHoldHandler} disabled={props.addingHold}>{label}</button>
    ];
    return (
        <Dialog
            name="hold-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="hold-dialog"
        >
            <div className="hold-container">
                {content}
            </div>
        </Dialog >
    );
});

export default HoldModal;
