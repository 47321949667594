import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Form from './Form';
import MailServicesModal from './MailServicesModal';
import ImpositionModal from './ImpositionModal';
import AdditionalServicesModal from './AdditionalServicesModal';
import MatrixModal from './MatrixModal';
import WrongEstimateModal from './WrongEstimateModal';
import ConfirmApproveModal from './ConfirmApproveModal';
import ConfirmSendModal from './ConfirmSendModal';
import ConfirmConvertModal from './ConfirmConvertModal';
import ChangeEstimateModal from './ChangeEstimateModal';
import ChangeStatusModal from './ChangeStatusModal';
import DeliveryChargeModal from './DeliveryChargeModal';
import NewPaperModal from './NewPaperModal';
import AddPartModal from './AddPartModal';
import DeletePartModal from './DeletePartModal';
import * as actions from './Estimate.actions';
import * as selectors from './Estimate.selectors';
import './Estimate.scss';
import PqtLayout from './../PqtLayout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import * as loginSelectors from './../Login/Login.selectors';
import { Redirect } from 'react-router-dom';
import * as dialog from '../common/dialog';

const Estimate = (props) => {
    /* State to props */
    const fetchingEstimate = useSelector(selectors.getFetchingEstimate);
    const estimate = useSelector(selectors.getEstimate, stringifyEqualityCheck);
    const fields = useSelector(selectors.getFields, stringifyEqualityCheck);
    const fetchingInformation = useSelector(selectors.getFetchingInformation);
    const information = useSelector(selectors.getInformation, stringifyEqualityCheck);
    const estimating = useSelector(selectors.getEstimating);
    const saving = useSelector(selectors.getSaving);
    const id = useSelector(selectors.getId);
    const mailServicesTab = useSelector(selectors.getMailServicesTab);
    const dataProcessingItems = useSelector(selectors.getDataProcessingItems, stringifyEqualityCheck);
    const variablePrintItems = useSelector(selectors.getVariablePrintItems, stringifyEqualityCheck);
    const inkjetItems = useSelector(selectors.getInkjetItems, stringifyEqualityCheck);
    const insertingItems = useSelector(selectors.getInsertingItems, stringifyEqualityCheck);
    const meteringItems = useSelector(selectors.getMeteringItems, stringifyEqualityCheck);
    const handworkItems = useSelector(selectors.getHandworkItems, stringifyEqualityCheck);
    const selectedMailServices = useSelector(selectors.getSelectedMailServices, stringifyEqualityCheck);
    const selectedAdditionalServices = useSelector(selectors.getSelectedAdditionalServices, stringifyEqualityCheck);
    const result1 = useSelector(selectors.getResult1, stringifyEqualityCheck);
    const result2 = useSelector(selectors.getResult2, stringifyEqualityCheck);
    const result3 = useSelector(selectors.getResult3, stringifyEqualityCheck);
    const showBreakdown = useSelector(selectors.getShowBreakdown, stringifyEqualityCheck);
    const additionalServices = useSelector(selectors.getAdditionalServices, stringifyEqualityCheck);
    const estimateType = useSelector(selectors.getEstimateType);
    const impositionType = useSelector(selectors.getImpositionType);
    const multiEstimating = useSelector(selectors.getMultiEstimating);
    const multiResults = useSelector(selectors.getMultiResults, stringifyEqualityCheck);
    const dataChanged = useSelector(selectors.getDataChanged);
    const estimateChanged = useSelector(selectors.getEstimateChanged);
    const approving = useSelector(selectors.getApproving);
    const converting = useSelector(selectors.getConverting);
    const selectedAccessories = useSelector(selectors.getSelectedAccessories, stringifyEqualityCheck);
    const fetchingSubstrateAccessories = useSelector(selectors.getFetchingSubstrateAccessories);
    const fetchingSubstrates = useSelector(selectors.getFetchingSubstrates);
    const sending = useSelector(selectors.getSending);
    const comments = useSelector(selectors.getComments);
    const files = useSelector(selectors.getFiles);
    const sendingWrongEstimate = useSelector(selectors.getSendingWrongEstimate, stringifyEqualityCheck);
    const fetchingSubstrateLaminates = useSelector(selectors.getFetchingSubstrateLaminates);
    const downloadingEstimate = useSelector(selectors.getDownloadingEstimate);
    const newEstimateType = useSelector(selectors.getNewEstimateType);
    const changingEstimateType = useSelector(selectors.getChangingEstimateType);
    const deliveryCharge = useSelector(selectors.getDeliveryCharge, stringifyEqualityCheck);
    const showDeliveryCharge = useSelector(selectors.getShowDeliveryCharge, stringifyEqualityCheck);
    const fetchingRfq = useSelector(selectors.getFetchingRfq);
    const rfq = useSelector(selectors.getRfq, stringifyEqualityCheck);
    const downloadingPressTicket = useSelector(selectors.getDownloadingPressTicket);
    const partEstimateType = useSelector(selectors.getPartEstimateType);
    const mainPartId = useSelector(selectors.getMainPartId);
    const mainPart = useSelector(selectors.getMainPart);
    const fetchingMainPart = useSelector(selectors.getFetchingMainPart);
    const idForDelete = useSelector(selectors.getIdForDelete);
    const isDeleting = useSelector(selectors.getIsDeleting);
    const minMarkup = useSelector(selectors.getMinMarkup);
    const isDuplicatingPart = useSelector(selectors.getIsDuplicatingPart);
    const paperFields = useSelector(selectors.getPaperFields);
    const isAddingPaper = useSelector(selectors.getIsAddingPaper, stringifyEqualityCheck);
    const outsideServices = useSelector(selectors.getOutsideServices, stringifyEqualityCheck);
    const newStatus = useSelector(selectors.getNewStatus);
    const changingStatus = useSelector(selectors.getChangingStatus);
    const isExportingMatrix = useSelector(selectors.getIsExportingMatrix);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const setField = useCallback((name, value) => dispatch(actions.setField(name, value)), [dispatch]);
    const fetchEstimate = useCallback((id) => dispatch(actions.fetchEstimate(id)), [dispatch]);
    const addEstimateData = useCallback((fromAdditional) => dispatch(actions.addEstimateData(fromAdditional)), [dispatch]);
    const fetchInformation = useCallback((id, customerId) => dispatch(actions.fetchInformation(id, customerId)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);
    const setId = useCallback((id) => dispatch(actions.setId(id)), [dispatch]);
    const setHistory = useCallback((history) => dispatch(actions.setHistory(history)), [dispatch]);
    const saveEstimate = useCallback(() => dispatch(actions.saveEstimate()), [dispatch]);
    const calculateEstimate = useCallback(() => dispatch(actions.calculateEstimate()), [dispatch]);
    const setMailServicesTab = useCallback((value) => dispatch(actions.setMailServicesTab(value)), [dispatch]);
    const addMailServicesItems = useCallback(() => dispatch(actions.addMailServicesItems()), [dispatch]);
    const deleteMailServicesItem = useCallback((id, type) => dispatch(actions.deleteMailServicesItem(id, type)), [dispatch]);
    const setDataProcessingItem = useCallback((id, field, value) => dispatch(actions.setDataProcessingItem(id, field, value)), [dispatch]);
    const setVariablePrintItem = useCallback((id, field, value) => dispatch(actions.setVariablePrintItem(id, field, value)), [dispatch]);
    const setInkjetItem = useCallback((id, field, value) => dispatch(actions.setInkjetItem(id, field, value)), [dispatch]);
    const setInsertingItem = useCallback((id, field, value) => dispatch(actions.setInsertingItem(id, field, value)), [dispatch]);
    const setMeteringItem = useCallback((id, field, value) => dispatch(actions.setMeteringItem(id, field, value)), [dispatch]);
    const setHandworkItem = useCallback((id, field, value) => dispatch(actions.setHandworkItem(id, field, value)), [dispatch]);
    const setMailServicesQuantities = useCallback(() => dispatch(actions.setMailServicesQuantities()), [dispatch]);
    const setEstimateType = useCallback((value) => dispatch(actions.setEstimateType(value)), [dispatch]);
    const setShowBreakdown = useCallback((name, value) => dispatch(actions.setShowBreakdown(name, value)), [dispatch]);
    const setAdditionalService = useCallback((id, field, value) => dispatch(actions.setAdditionalService(id, field, value)), [dispatch]);
    const addAdditionalServicesItems = useCallback(() => dispatch(actions.addAdditionalServicesItems()), [dispatch]);
    const deleteAdditionalServicesItem = useCallback((id) => dispatch(actions.deleteAdditionalServicesItem(id)), [dispatch]);
    const setImpositionType = useCallback((value) => dispatch(actions.setImpositionType(value)), [dispatch]);
    const matrixEstimate = useCallback(() => dispatch(actions.matrixEstimate()), [dispatch]);
    const setMultiResults = useCallback((value) => dispatch(actions.setMultiResults(value)), [dispatch]);
    const setDataChanged = useCallback((value) => dispatch(actions.setDataChanged(value)), [dispatch]);
    const setEstimateChanged = useCallback((value) => dispatch(actions.setEstimateChanged(value)), [dispatch]);
    const approve = useCallback(() => dispatch(actions.approve()), [dispatch]);
    const convertToJob = useCallback(() => dispatch(actions.convertToJob()), [dispatch]);
    const checkAccessory = useCallback((id) => dispatch(actions.checkAccessory(id)), [dispatch]);
    const fetchSubstrateAccessories = useCallback((id) => dispatch(actions.fetchSubstrateAccessories(id)), [dispatch]);
    const resetAccessories = useCallback(() => dispatch(actions.resetAccessories()), [dispatch]);
    const fetchSubstrates = useCallback((id) => dispatch(actions.fetchSubstrates(id)), [dispatch]);
    const fetchSubstrateLaminates = useCallback((id) => dispatch(actions.fetchSubstrateLaminates(id)), [dispatch]);
    const sendEstimate = useCallback(() => dispatch(actions.sendEstimate()), [dispatch]);
    const setComments = useCallback((value) => dispatch(actions.setComments(value)), [dispatch]);
    const addFile = useCallback((file) => dispatch(actions.addFile(file)), [dispatch]);
    const removeFile = useCallback((index) => dispatch(actions.removeFile(index)), [dispatch]);
    const clearFiles = useCallback(() => dispatch(actions.clearFiles()), [dispatch]);
    const sendWrongEstimate = useCallback(() => dispatch(actions.sendWrongEstimate()), [dispatch]);
    const downloadEstimate = useCallback(() => dispatch(actions.downloadEstimate()), [dispatch]);
    const changeEstimateType = useCallback(() => dispatch(actions.changeEstimateType()), [dispatch]);
    const setNewEstimateType = useCallback((value) => dispatch(actions.setNewEstimateType(value)), [dispatch]);
    const setDeliveryChargeField = useCallback((field, value) => dispatch(actions.setDeliveryChargeField(field, value)), [dispatch]);
    const downloadPressTicket = useCallback(() => dispatch(actions.downloadPressTicket()), [dispatch]);
    const setShowDeliveryCharge = useCallback((value) => dispatch(actions.setShowDeliveryCharge(value)), [dispatch]);
    const setPartEstimateType = useCallback((value) => dispatch(actions.setPartEstimateType(value)), [dispatch]);
    const setMainPartId = useCallback((value) => dispatch(actions.setMainPartId(value)), [dispatch]);
    const fetchMainPart = useCallback((id) => dispatch(actions.fetchMainPart(id)), [dispatch]);
    const deleteEstimate = useCallback((id, comments) => dispatch(actions.deleteEstimate(id, comments)), [dispatch]);
    const setIdForDelete = useCallback((id) => dispatch(actions.setIdForDelete(id)), [dispatch]);
    const duplicatePart = useCallback(() => dispatch(actions.duplicatePart()), [dispatch]);
    const setPaperField = useCallback((field, value) => dispatch(actions.setPaperField(field, value)), [dispatch]);
    const addPaper = useCallback(() => dispatch(actions.addPaper()), [dispatch]);
    const addOutsideService = useCallback(() => dispatch(actions.addOutsideService()), [dispatch]);
    const setOutsideServiceField = useCallback((index, field, value) => dispatch(actions.setOutsideServiceField(index, field, value)), [dispatch]);
    const deleteOutsideService = useCallback((index) => dispatch(actions.deleteOutsideService(index)), [dispatch]);
    const checkUsePart = useCallback((value) => dispatch(actions.checkUsePart(value)), [dispatch]);
    const setNewStatus = useCallback((value) => dispatch(actions.setNewStatus(value)), [dispatch]);
    const changeStatus = useCallback(() => dispatch(actions.changeStatus()), [dispatch]);
    const exportMatrix = useCallback(() => dispatch(actions.exportMatrix()), [dispatch]);
    const showMailServicesModal = useCallback(() => dispatch(dialog.actions.showDialog('mail-services-modal')), [dispatch]);
    const hideMailServicesModal = useCallback(() => dispatch(dialog.actions.hideDialog('mail-services-modal')), [dispatch]);
    const showImpositionModal = useCallback(() => dispatch(dialog.actions.showDialog('imposition-modal')), [dispatch]);
    const hideImpositionModal = useCallback(() => dispatch(dialog.actions.hideDialog('imposition-modal')), [dispatch]);
    const showAdditionalServicesModal = useCallback(() => dispatch(dialog.actions.showDialog('additional-services-modal')), [dispatch]);
    const hideAdditionalServicesModal = useCallback(() => dispatch(dialog.actions.hideDialog('additional-services-modal')), [dispatch]);
    const showMatrixModal = useCallback(() => dispatch(dialog.actions.showDialog('matrix-modal')), [dispatch]);
    const hideMatrixModal = useCallback(() => dispatch(dialog.actions.hideDialog('matrix-modal')), [dispatch]);
    const showWrongEstimateModal = useCallback(() => dispatch(dialog.actions.showDialog('wrong-estimate-modal')), [dispatch]);
    const hideWrongEstimateModal = useCallback(() => dispatch(dialog.actions.hideDialog('wrong-estimate-modal')), [dispatch]);
    const showConfirmApproveModal = useCallback(() => dispatch(dialog.actions.showDialog('confirm-approve-modal')), [dispatch]);
    const hideConfirmApproveModal = useCallback(() => dispatch(dialog.actions.hideDialog('confirm-approve-modal')), [dispatch]);
    const showConfirmSendModal = useCallback(() => dispatch(dialog.actions.showDialog('confirm-send-modal')), [dispatch]);
    const hideConfirmSendModal = useCallback(() => dispatch(dialog.actions.hideDialog('confirm-send-modal')), [dispatch]);
    const showConfirmConvertModal = useCallback(() => dispatch(dialog.actions.showDialog('confirm-convert-modal')), [dispatch]);
    const hideConfirmConvertModal = useCallback(() => dispatch(dialog.actions.hideDialog('confirm-convert-modal')), [dispatch]);
    const showChangeEstimateModal = useCallback(() => dispatch(dialog.actions.showDialog('change-estimate-modal')), [dispatch]);
    const hideChangeEstimateModal = useCallback(() => dispatch(dialog.actions.hideDialog('change-estimate-modal')), [dispatch]);
    const showDeliveryChargeModal = useCallback(() => dispatch(dialog.actions.showDialog('delivery-charge-modal')), [dispatch]);
    const hideDeliveryChargeModal = useCallback(() => dispatch(dialog.actions.hideDialog('delivery-charge-modal')), [dispatch]);
    const showAddPartModal = useCallback(() => dispatch(dialog.actions.showDialog('add-part-modal')), [dispatch]);
    const hideAddPartModal = useCallback(() => dispatch(dialog.actions.hideDialog('add-part-modal')), [dispatch]);
    const showDeletePartModal = useCallback(() => dispatch(dialog.actions.showDialog('delete-part-modal')), [dispatch]);
    const hideDeletePartModal = useCallback(() => dispatch(dialog.actions.hideDialog('delete-part-modal')), [dispatch]);
    const showNewPaperModal = useCallback(() => dispatch(dialog.actions.showDialog('new-paper-modal')), [dispatch]);
    const hideNewPaperModal = useCallback(() => dispatch(dialog.actions.hideDialog('new-paper-modal')), [dispatch]);
    const showChangeStatusModal = useCallback(() => dispatch(dialog.actions.showDialog('change-status-modal')), [dispatch]);
    const hideChangeStatusModal = useCallback(() => dispatch(dialog.actions.hideDialog('change-status-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        setId(props.match.params.id);
        setEstimateType(props.match.params.type);
        setNewEstimateType(props.match.params.type);
        setHistory(props.history);

        if (props.match.params.customerId) {
            setField('customer_id', props.match.params.customerId);

            if (parseInt(props.match.params.type) === 2) {
                setField('product_type', 28);
            } else if (parseInt(props.match.params.type) === 7) {
                setField('product_type', 17);
            } else if (parseInt(props.match.params.type) === 6) {
                setField('product_type', 26);
            }
        }

        if (props.match.params.mainPartId) {
            setMainPartId(props.match.params.mainPartId);
            fetchMainPart(props.match.params.mainPartId);
        }

        fetchInformation(props.match.params.id, props.match.params.customerId);

        return () => {
            resetState();
        };
    }, [fetchInformation, setId, setHistory, setEstimateType, setField, fetchEstimate, resetState, setNewEstimateType, setMainPartId,
        fetchMainPart, props.match.params.id, props.history, props.match.params.type, props.match.params.customerId,
        props.match.params.mainPartId,

    ]);

    if (user != null && ![1, 2, 3, 4, 5, 9].includes(parseInt(user.pqt_type)) && !user.is_pqt_accounting) {
        return <Redirect to="/dashboard" />;
    }

    let title = '';
    if (parseInt(props.match.params.type) === 1) {
        title = 'Estimate Window Sheetfed Interface';
    } else if (parseInt(props.match.params.type) === 2) {
        title = 'Estimate Window Envelope Interface';
    } else if (parseInt(props.match.params.type) === 3) {
        title = 'Estimate Window Booklet Plus Cover Interface';
    } else if (parseInt(props.match.params.type) === 4) {
        title = 'Estimate Window Booklet Self Cover Interface';
    } else if (parseInt(props.match.params.type) === 5) {
        title = 'Estimate Window Digital Interface';
    } else if (parseInt(props.match.params.type) === 6) {
        title = 'Estimate Window Large Format Interface';
    } else if (parseInt(props.match.params.type) === 7) {
        title = 'Estimate Window Pocket Folder Interface';
    } else if (parseInt(props.match.params.type) === 8) {
        title = 'Estimate Window NCR Interface';
    } else if (parseInt(props.match.params.type) === 9) {
        title = 'Estimate Window Notepads Interface';
    }

    return (
        <PqtLayout title={title} history={props.history}>
            <Helmet><title>{title} - Stagecoach</title></Helmet>
            <div className="estimate-container">
                <Form
                    type={props.match.params.type}
                    id={id}
                    fetchingEstimate={fetchingEstimate}
                    history={props.history}
                    user={user}
                    fields={fields}
                    fetchingInformation={fetchingInformation}
                    information={information}
                    saving={saving}
                    estimate={estimate}
                    estimating={estimating}
                    selectedMailServices={selectedMailServices}
                    selectedAdditionalServices={selectedAdditionalServices}
                    result1={result1}
                    result2={result2}
                    result3={result3}
                    showBreakdown={showBreakdown}
                    additionalServices={additionalServices}
                    estimateType={estimateType}
                    multiEstimating={multiEstimating}
                    multiResults={multiResults}
                    dataChanged={dataChanged}
                    estimateChanged={estimateChanged}
                    approving={approving}
                    converting={converting}
                    selectedAccessories={selectedAccessories}
                    fetchingSubstrateAccessories={fetchingSubstrateAccessories}
                    fetchingSubstrates={fetchingSubstrates}
                    sending={sending}
                    fetchingSubstrateLaminates={fetchingSubstrateLaminates}
                    downloadingEstimate={downloadingEstimate}
                    showDeliveryCharge={showDeliveryCharge}
                    deliveryCharge={deliveryCharge}
                    fetchingRfq={fetchingRfq}
                    rfq={rfq}
                    downloadingPressTicket={downloadingPressTicket}
                    mainPartId={mainPartId}
                    mainPart={mainPart}
                    fetchingMainPart={fetchingMainPart}
                    minMarkup={minMarkup}
                    isDuplicatingPart={isDuplicatingPart}
                    outsideServices={outsideServices}
                    setField={setField}
                    addEstimateData={addEstimateData}
                    showMailServicesModal={showMailServicesModal}
                    hideMailServicesModal={hideMailServicesModal}
                    showImpositionModal={showImpositionModal}
                    saveEstimate={saveEstimate}
                    resetState={resetState}
                    calculateEstimate={calculateEstimate}
                    setMailServicesQuantities={setMailServicesQuantities}
                    setShowBreakdown={setShowBreakdown}
                    showAdditionalServicesModal={showAdditionalServicesModal}
                    deleteAdditionalServicesItem={deleteAdditionalServicesItem}
                    deleteMailServicesItem={deleteMailServicesItem}
                    setImpositionType={setImpositionType}
                    matrixEstimate={matrixEstimate}
                    setMultiResults={setMultiResults}
                    showMatrixModal={showMatrixModal}
                    setDataChanged={setDataChanged}
                    setEstimateChanged={setEstimateChanged}
                    approve={approve}
                    convertToJob={convertToJob}
                    checkAccessory={checkAccessory}
                    fetchSubstrateAccessories={fetchSubstrateAccessories}
                    resetAccessories={resetAccessories}
                    fetchSubstrates={fetchSubstrates}
                    fetchSubstrateLaminates={fetchSubstrateLaminates}
                    sendEstimate={sendEstimate}
                    showWrongEstimateModal={showWrongEstimateModal}
                    downloadEstimate={downloadEstimate}
                    showConfirmApproveModal={showConfirmApproveModal}
                    showConfirmSendModal={showConfirmSendModal}
                    showConfirmConvertModal={showConfirmConvertModal}
                    showChangeEstimateModal={showChangeEstimateModal}
                    showDeliveryChargeModal={showDeliveryChargeModal}
                    setShowDeliveryCharge={setShowDeliveryCharge}
                    downloadPressTicket={downloadPressTicket}
                    showAddPartModal={showAddPartModal}
                    showDeletePartModal={showDeletePartModal}
                    showNewPaperModal={showNewPaperModal}
                    setIdForDelete={setIdForDelete}
                    duplicatePart={duplicatePart}
                    addOutsideService={addOutsideService}
                    deleteOutsideService={deleteOutsideService}
                    setOutsideServiceField={setOutsideServiceField}
                    checkUsePart={checkUsePart}
                    setNewStatus={setNewStatus}
                    showChangeStatusModal={showChangeStatusModal}
                />
            </div>
            <MailServicesModal
                type={props.match.params.type}
                information={information}
                mailServicesTab={mailServicesTab}
                dataProcessingItems={dataProcessingItems}
                variablePrintItems={variablePrintItems}
                inkjetItems={inkjetItems}
                insertingItems={insertingItems}
                meteringItems={meteringItems}
                handworkItems={handworkItems}
                selectedMailServices={selectedMailServices}
                estimateType={estimateType}
                setMailServicesTab={setMailServicesTab}
                hideMailServicesModal={hideMailServicesModal}
                addMailServicesItems={addMailServicesItems}
                deleteMailServicesItem={deleteMailServicesItem}
                setDataProcessingItem={setDataProcessingItem}
                setVariablePrintItem={setVariablePrintItem}
                setInkjetItem={setInkjetItem}
                setInsertingItem={setInsertingItem}
                setMeteringItem={setMeteringItem}
                setHandworkItem={setHandworkItem}
                setDataChanged={setDataChanged}
                setEstimateChanged={setEstimateChanged}
            />
            <AdditionalServicesModal
                type={props.match.params.type}
                additionalServices={additionalServices}
                estimateType={estimateType}
                estimate={estimate}
                hideAdditionalServicesModal={hideAdditionalServicesModal}
                setAdditionalService={setAdditionalService}
                addAdditionalServicesItems={addAdditionalServicesItems}
                setDataChanged={setDataChanged}
                setEstimateChanged={setEstimateChanged}
            />
            <ImpositionModal
                type={props.match.params.type}
                estimate={estimate}
                result1={result1}
                result2={result2}
                result3={result3}
                estimateType={estimateType}
                impositionType={impositionType}
                hideImpositionModal={hideImpositionModal}
                setImpositionType={setImpositionType}
            />
            <MatrixModal
                type={props.match.params.type}
                estimate={estimate}
                multiResults={multiResults}
                estimateType={estimateType}
                selectedAdditionalServices={selectedAdditionalServices}
                selectedMailServices={selectedMailServices}
                deliveryCharge={deliveryCharge}
                showDeliveryCharge={showDeliveryCharge}
                isExportingMatrix={isExportingMatrix}
                hideMatrixModal={hideMatrixModal}
                exportMatrix={exportMatrix}
            />
            <WrongEstimateModal
                comments={comments}
                files={files}
                sendingWrongEstimate={sendingWrongEstimate}
                setComments={setComments}
                addFile={addFile}
                removeFile={removeFile}
                clearFiles={clearFiles}
                hideWrongEstimateModal={hideWrongEstimateModal}
                sendWrongEstimate={sendWrongEstimate}
            />
            <ConfirmApproveModal
                approving={approving}
                approve={approve}
                hideConfirmApproveModal={hideConfirmApproveModal}
            />
            <ConfirmConvertModal
                converting={converting}
                convertToJob={convertToJob}
                hideConfirmConvertModal={hideConfirmConvertModal}
            />
            <ConfirmSendModal
                sending={sending}
                sendEstimate={sendEstimate}
                hideConfirmSendModal={hideConfirmSendModal}
            />
            <ChangeEstimateModal
                newEstimateType={newEstimateType}
                changingEstimateType={changingEstimateType}
                setNewEstimateType={setNewEstimateType}
                changeEstimateType={changeEstimateType}
                hideChangeEstimateModal={hideChangeEstimateModal}
            />
            <ChangeStatusModal
                newStatus={newStatus}
                changingStatus={changingStatus}
                setNewStatus={setNewStatus}
                hideChangeStatusModal={hideChangeStatusModal}
                changeStatus={changeStatus}
            />
            <DeliveryChargeModal
                deliveryCharge={deliveryCharge}
                showDeliveryCharge={showDeliveryCharge}
                setDeliveryChargeField={setDeliveryChargeField}
                hideDeliveryChargeModal={hideDeliveryChargeModal}
                setShowDeliveryCharge={setShowDeliveryCharge}
                setDataChanged={setDataChanged}
                setEstimateChanged={setEstimateChanged}
            />
            <AddPartModal
                history={props.history}
                estimate={estimate}
                partEstimateType={partEstimateType}
                mainPart={mainPart}
                setPartEstimateType={setPartEstimateType}
                hideAddPartModal={hideAddPartModal}
            />
            <DeletePartModal
                idForDelete={idForDelete}
                isDeleting={isDeleting}
                deleteEstimate={deleteEstimate}
                hideDeletePartModal={hideDeletePartModal}
                setIdForDelete={setIdForDelete}
            />
            <NewPaperModal
                paperFields={paperFields}
                isAddingPaper={isAddingPaper}
                information={information}
                setPaperField={setPaperField}
                hideNewPaperModal={hideNewPaperModal}
                addPaper={addPaper}
            />
        </PqtLayout>
    );
};

export default Estimate;
