import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from './Table';
import FormModal from './FormModal';
import DeleteModal from './DeleteModal';
import * as actions from './MailingSubcategories.actions';
import * as selectors from './MailingSubcategories.selectors';
import './MailingSubcategories.scss';
import PqtLayout from './../PqtLayout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import * as dialog from '../common/dialog';
import * as loginSelectors from './../Login/Login.selectors';
import { Redirect } from 'react-router-dom';

const MailingSubcategories = (props) => {
    /* State to props */
    const subcategory = useSelector(selectors.getSubcategory);
    const isSaving = useSelector(selectors.getIsSaving);
    const fetchingSubcategories = useSelector(selectors.getFetchingSubcategories);
    const subcategories = useSelector(selectors.getSubcategories, stringifyEqualityCheck);
    const pagination = useSelector(selectors.getPagination, stringifyEqualityCheck);
    const sort = useSelector(selectors.getSort, stringifyEqualityCheck);
    const idForDelete = useSelector(selectors.getIdForDelete, stringifyEqualityCheck);
    const isDeleting = useSelector(selectors.getIsDeleting, stringifyEqualityCheck);
    const filterFields = useSelector(selectors.getFilterFields, stringifyEqualityCheck);
    const category = useSelector(selectors.getCategory, stringifyEqualityCheck);
    const fetchingCategory = useSelector(selectors.getFetchingCategory);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const fetchSubcategories = useCallback(() => dispatch(actions.fetchSubcategories()), [dispatch]);
    const deleteSubcategory = useCallback((id) => dispatch(actions.deleteSubcategory(id)), [dispatch]);
    const setSort = useCallback((column, type) => dispatch(actions.setSort(column, type)), [dispatch]);
    const setActivePage = useCallback((name, value) => dispatch(actions.setActivePage(name, value)), [dispatch]);
    const setIdForDelete = useCallback((id) => dispatch(actions.setIdForDelete(id)), [dispatch]);
    const changeFilter = useCallback((name, value) => dispatch(actions.changeFilter(name, value)), [dispatch]);
    const setSubcategory = useCallback((value) => dispatch(actions.setSubcategory(value)), [dispatch]);
    const setField = useCallback((field, value) => dispatch(actions.setField(field, value)), [dispatch]);
    const saveSubcategory = useCallback(() => dispatch(actions.saveSubcategory()), [dispatch]);
    const fetchCategory = useCallback((id) => dispatch(actions.fetchCategory(id)), [dispatch]);
    const setCategoryId = useCallback((id) => dispatch(actions.setCategoryId(id)), [dispatch]);
    const showDeleteModal = useCallback(() => dispatch(dialog.actions.showDialog('delete-modal')), [dispatch]);
    const hideDeleteModal = useCallback(() => dispatch(dialog.actions.hideDialog('delete-modal')), [dispatch]);
    const showFormModal = useCallback(() => dispatch(dialog.actions.showDialog('form-modal')), [dispatch]);
    const hideFormModal = useCallback(() => dispatch(dialog.actions.hideDialog('form-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        setCategoryId(props.match.params.id);
        fetchCategory(props.match.params.id);
        fetchSubcategories();
    }, [dispatch, fetchCategory, setCategoryId, fetchSubcategories, props.match.params.id]);

    // Hugh (61)
    if (user != null && ![61].includes(parseInt(user.id))) {
        return <Redirect to="/dashboard" />;
    }

    let title = 'Mailing Subcategories';
    if (category) {
        title = 'Mailing Subcategories (' + category.name + ')';
    }

    return (
        <PqtLayout title={title} history={props.history}>
            <Helmet><title>Mailing Subcategories - Stagecoach</title></Helmet>
            <div className="mailing-subcategories-container">
                <Table
                    subcategories={subcategories}
                    pagination={pagination}
                    fetchingSubcategories={fetchingSubcategories}
                    sort={sort}
                    user={user}
                    filterFields={filterFields}
                    category={category}
                    fetchingCategory={fetchingCategory}
                    history={props.history}
                    setSort={setSort}
                    setSubcategory={setSubcategory}
                    setActivePage={setActivePage}
                    showDeleteModal={showDeleteModal}
                    setIdForDelete={setIdForDelete}
                    changeFilter={changeFilter}
                    showFormModal={showFormModal}
                />
                <DeleteModal
                    idForDelete={idForDelete}
                    isDeleting={isDeleting}
                    deleteSubcategory={deleteSubcategory}
                    hideDeleteModal={hideDeleteModal}
                    setIdForDelete={setIdForDelete}
                />
                <FormModal
                    subcategories={subcategories}
                    isSaving={isSaving}
                    subcategory={subcategory}
                    setSubcategory={setSubcategory}
                    setField={setField}
                    hideFormModal={hideFormModal}
                    saveSubcategory={saveSubcategory}
                />
            </div>
        </PqtLayout>
    );
};

export default MailingSubcategories;
