import axios from 'axios';
import * as types from './HelpItems.types';
import * as selectors from './HelpItems.selectors';
import * as dialog from '../common/dialog';
import { validateAuthInResponse, showDataDeletedNotification, showNotification } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchHelpItems = () => (dispatch, getState) => {
    const sort = selectors.getSort(getState());
    const pagination = selectors.getPagination(getState());
    const filerFields = selectors.getFilterFields(getState());

    let params = [];
    params.push('sort=' + (sort.type === 'asc' ? '' : '-') + sort.column);
    params.push('active-page=' + pagination.activePage);
    params.push('elements-per-page=' + pagination.elementsPerPage);

    if (filerFields.length > 0) {
        let filterElements = [];
        for (let i = 0; i < filerFields.length; i++) {
            filterElements.push(filerFields[i]['name'] + '=' + filerFields[i]['value']);
        }
        params.push('filter=' + filterElements.join(','))
    } else {
        params.push('filter=-')
    }

    dispatch({ type: types.FETCH_HELP_ITEMS, payload: null });
    axios.get(apiUrl + '/help-items' + (params.length === 0 ? '' : '?' + params.join('&')),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_HELP_ITEMS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_HELP_ITEMS_ERROR));
        });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null
    });
}

export const deleteHelpItem = (id, comments) => (dispatch) => {
    dispatch({ type: types.DELETE_HELP_ITEM, payload: null });
    axios.delete(apiUrl + '/help-items/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }, data: { comments } }
    ).then(() => {
        dispatch({ type: types.DELETE_HELP_ITEM_SUCCESS, payload: null });
        showDataDeletedNotification();
        dispatch(fetchHelpItems());
        dispatch(dialog.actions.hideDialog('delete-modal'));
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.DELETE_HELP_ITEM_ERROR));
    });
}

export const saveHelpItem = (acceptedFiles) => (dispatch, getState) => {
    const id = selectors.getIdForDelete(getState());
    const name = selectors.getName(getState());
    const codeRef = selectors.getCodeRef(getState());
    const description = selectors.getDescription(getState());

    if (!name || name == null || name === '') {
        return showNotification('Complete information', 'Enter the name', 'info');
    }
    if (!codeRef || codeRef == null || codeRef === '') {
        return showNotification('Complete information', 'Enter the refence code', 'info');
    }
    if (!description || description == null || description === '') {
        return showNotification('Complete information', 'Enter the description', 'info');
    }

    let data = new FormData();
    data.append('id', id);
    data.append('name', name);
    data.append('code_ref', codeRef);
    data.append('description', description);

    if (acceptedFiles.length > 0) {
        data.append('image', acceptedFiles[0]);
    }

    let endpoint = apiUrl + '/help-items';
    if (id != null && id !== '') {
        endpoint = apiUrl + '/help-item/update-post';
    }

    dispatch({ type: types.SAVE_HELP_ITEM, payload: null });
    axios.post(endpoint, data,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(() => {
            dispatch({ type: types.SAVE_HELP_ITEM_SUCCESS, payload: null });
            showNotification('Item Saved', 'The item has been saved successfully', 'success');
            dispatch(fetchHelpItems());
            dispatch(dialog.actions.hideDialog('form-modal'));
            dispatch(clearFields());
        }).catch((error) => {
            dispatch(validateAuthInResponse(error, types.SAVE_HELP_ITEM_ERROR));
        });
}

export const setSort = (column, type) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_SORT,
            payload: {
                column: column,
                type: type,
            }
        });
        resolve();
    }).then(() => {
        dispatch(fetchHelpItems());
    });
}

export const setActivePage = (value) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_ACTIVE_PAGE,
            payload: value
        });
        resolve();
    }).then(() => {
        dispatch(fetchHelpItems());
    });
}

export const setIdForDelete = (id) => (dispatch) => {
    dispatch({
        type: types.SET_ID_FOR_DELETE,
        payload: id
    });
}

export const changeFilter = (name, value, fetch = false) => (dispatch) => {
    new Promise((resolve) => {
        dispatch({
            type: types.CHANGE_FILTER,
            payload: { name, value }
        });
        resolve();
    }).then(() => {
        if (fetch) {
            dispatch(fetchHelpItems());
        }
    });
}

export const setName = (value) => (dispatch) => {
    dispatch({
        type: types.SET_NAME,
        payload: value,
    });
}
export const setCodeRef = (value) => (dispatch) => {
    dispatch({
        type: types.SET_CODE_REF,
        payload: value,
    });
}
export const setDescription = (value) => (dispatch) => {
    dispatch({
        type: types.SET_DESCRIPTION,
        payload: value,
    });
}
export const setImage = (value) => (dispatch) => {
    dispatch({
        type: types.SET_IMAGE,
        payload: value,
    });
}
export const clearFields = (value) => (dispatch) => {
    dispatch({
        type: types.CLEAR_FIELDS,
        payload: value,
    });
}
export const setActiveHelpItem = (id) => (dispatch, getState) => {
    const helpItems = selectors.getHelpItems(getState());

    let selectedItem = null;
    for (let i = 0; i < helpItems.length; i++) {
        if (parseInt(id) === parseInt(helpItems[i].id)) {
            selectedItem = helpItems[i];
            break;
        }
    }

    if (selectedItem != null) {
        dispatch({
            type: types.SET_ACTIVE_HELP_ITEM,
            payload: {
                name: selectedItem.name,
                codeRef: selectedItem.code_ref,
                description: selectedItem.description,
                image: selectedItem.image_url,
            },
        });
    }
}