import React from 'react';
import { Dialog } from './../../common/dialog';
import { empty, showNotification } from './../../../utils';

const DeliveryChargeModal = React.memo((props) => {
    const handleAddButton = () => {
        if (empty(props.deliveryCharge.description) || empty(props.deliveryCharge.quantity) || empty(props.deliveryCharge.total)) {
            return showNotification('Complete information', 'Enter all the required fields', 'info');
        }

        props.setEstimateChanged(true);
        props.setShowDeliveryCharge(true);
        props.hideDeliveryChargeModal();
    };

    const handleChange = (field, event) => {
        props.setDeliveryChargeField(field, event.target.value);
    };

    const handleHideModal = () => {
        if (empty(props.deliveryCharge.description) || empty(props.deliveryCharge.quantity) || empty(props.deliveryCharge.total)) {
            props.setEstimateChanged(true);
            props.setShowDeliveryCharge(false);
        }
        props.hideDeliveryChargeModal();
    };

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={handleHideModal}>Cancel</button>,
        <button key="delete-button" className="btn btn-light btn-sm action-button" onClick={handleAddButton}>Accept</button>,
    ];
    return (
        <Dialog
            name="delivery-charge-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="delivery-charge-dialog"
        >
            <div className="delivery-charge-container">
                <h1>Delivery Charge</h1>
                <div className="fields-container">
                    <div className="field description-field">
                        <div>Description <span className="required">*</span>:</div>
                        <input type="text" value={props.deliveryCharge.description} onChange={handleChange.bind(this, 'description')} />
                    </div>
                    <div className="field quantiy-field">
                        <div>Quantity <span className="required">*</span>:</div>
                        <input type="number" value={props.deliveryCharge.quantity} onChange={handleChange.bind(this, 'quantity')} />
                    </div>
                    <div className="field price-field">
                        <div>Price <span className="required">*</span>:</div>
                        <input type="number" value={props.deliveryCharge.total} onChange={handleChange.bind(this, 'total')} />
                    </div>
                </div>
            </div>
        </Dialog >
    );
});

export default DeliveryChargeModal;
