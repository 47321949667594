import { createSelector } from 'reselect';

export const getComponentState = state => state.estimate;

export const getFetchingEstimate = createSelector(
    getComponentState,
    (state) => state.fetchingEstimate
);
export const getEstimate = createSelector(
    getComponentState,
    (state) => state.estimate
);
export const getFields = createSelector(
    getComponentState,
    (state) => state.fields
);
export const getFetchingInformation = createSelector(
    getComponentState,
    (state) => state.fetchingInformation
);
export const getInformation = createSelector(
    getComponentState,
    (state) => state.information
);
export const getSaving = createSelector(
    getComponentState,
    (state) => state.saving
);
export const getEstimating = createSelector(
    getComponentState,
    (state) => state.estimating
);
export const getId = createSelector(
    getComponentState,
    (state) => state.id
);
export const getHistory = createSelector(
    getComponentState,
    (state) => state.history
);
export const getMailServicesTab = createSelector(
    getComponentState,
    (state) => state.mailServicesTab
);
export const getDataProcessingItems = createSelector(
    getComponentState,
    (state) => state.dataProcessingItems
);
export const getVariablePrintItems = createSelector(
    getComponentState,
    (state) => state.variablePrintItems
);
export const getInkjetItems = createSelector(
    getComponentState,
    (state) => state.inkjetItems
);
export const getInsertingItems = createSelector(
    getComponentState,
    (state) => state.insertingItems
);
export const getMeteringItems = createSelector(
    getComponentState,
    (state) => state.meteringItems
);
export const getHandworkItems = createSelector(
    getComponentState,
    (state) => state.handworkItems
);
export const getSelectedMailServices = createSelector(
    getComponentState,
    (state) => state.selectedMailServices
);
export const getSelectedAdditionalServices = createSelector(
    getComponentState,
    (state) => state.selectedAdditionalServices
);
export const getEstimateType = createSelector(
    getComponentState,
    (state) => state.estimateType
);
export const getResult1 = createSelector(
    getComponentState,
    (state) => state.result1
);
export const getResult2 = createSelector(
    getComponentState,
    (state) => state.result2
);
export const getResult3 = createSelector(
    getComponentState,
    (state) => state.result3
);
export const getShowBreakdown = createSelector(
    getComponentState,
    (state) => state.showBreakdown
);
export const getAdditionalServices = createSelector(
    getComponentState,
    (state) => state.additionalServices
);
export const getImpositionType = createSelector(
    getComponentState,
    (state) => state.impositionType
);
export const getMultiEstimating = createSelector(
    getComponentState,
    (state) => state.multiEstimating
);
export const getMultiResults = createSelector(
    getComponentState,
    (state) => state.multiResults
);
export const getDataChanged = createSelector(
    getComponentState,
    (state) => state.dataChanged
);
export const getEstimateChanged = createSelector(
    getComponentState,
    (state) => state.estimateChanged
);
export const getApproving = createSelector(
    getComponentState,
    (state) => state.approving
);
export const getConverting = createSelector(
    getComponentState,
    (state) => state.converting
);
export const getSelectedAccessories = createSelector(
    getComponentState,
    (state) => state.selectedAccessories
);
export const getFetchingSubstrateAccessories = createSelector(
    getComponentState,
    (state) => state.fetchingSubstrateAccessories
);
export const getFetchingSubstrates = createSelector(
    getComponentState,
    (state) => state.fetchingSubstrates
);
export const getSending = createSelector(
    getComponentState,
    (state) => state.sending
);
export const getComments = createSelector(
    getComponentState,
    (state) => state.comments
);
export const getFiles = createSelector(
    getComponentState,
    (state) => state.files
);
export const getSendingWrongEstimate = createSelector(
    getComponentState,
    (state) => state.sendingWrongEstimate
);
export const getFetchingSubstrateLaminates = createSelector(
    getComponentState,
    (state) => state.fetchingSubstrateLaminates
);
export const getDownloadingEstimate = createSelector(
    getComponentState,
    (state) => state.downloadingEstimate
);
export const getNewEstimateType = createSelector(
    getComponentState,
    (state) => state.newEstimateType
);
export const getChangingEstimateType = createSelector(
    getComponentState,
    (state) => state.changingEstimateType
);
export const getDeliveryCharge = createSelector(
    getComponentState,
    (state) => state.deliveryCharge
);
export const getShowDeliveryCharge = createSelector(
    getComponentState,
    (state) => state.showDeliveryCharge
);
export const getFetchingRfq = createSelector(
    getComponentState,
    (state) => state.fetchingRfq
);
export const getRfq = createSelector(
    getComponentState,
    (state) => state.rfq
);
export const getDownloadingPressTicket = createSelector(
    getComponentState,
    (state) => state.downloadingPressTicket
);
export const getPartEstimateType = createSelector(
    getComponentState,
    (state) => state.partEstimateType
);
export const getMainPartId = createSelector(
    getComponentState,
    (state) => state.mainPartId
);
export const getMainPartType = createSelector(
    getComponentState,
    (state) => state.mainPartType
);
export const getMainPart = createSelector(
    getComponentState,
    (state) => state.mainPart
);
export const getFetchingMainPart = createSelector(
    getComponentState,
    (state) => state.fetchingMainPart
);
export const getIdForDelete = createSelector(
    getComponentState,
    (state) => state.delete.id
);
export const getIsDeleting = createSelector(
    getComponentState,
    (state) => state.delete.isDeleting
);
export const getMinMarkup = createSelector(
    getComponentState,
    (state) => state.minMarkup
);
export const getIsDuplicatingPart = createSelector(
    getComponentState,
    (state) => state.isDuplicatingPart
);
export const getPaperFields = createSelector(
    getComponentState,
    (state) => state.paperFields
);
export const getIsAddingPaper = createSelector(
    getComponentState,
    (state) => state.isAddingPaper
);
export const getOutsideServices = createSelector(
    getComponentState,
    (state) => state.outsideServices
);
export const getNewStatus = createSelector(
    getComponentState,
    (state) => state.newStatus
);
export const getChangingStatus = createSelector(
    getComponentState,
    (state) => state.changingStatus
);
export const getIsExportingMatrix = createSelector(
    getComponentState,
    (state) => state.isExportingMatrix
);