import React, { Fragment } from 'react';
import InformationForm from './InformationForm';
import FlatForm from './FlatForm';
import SelfCoverForm from './SelfCoverForm';
import PlusCoverForm from './PlusCoverForm';
import EnvelopesForm from './EnvelopesForm';
import BinderyForm from './BinderyForm';
import DataProcessingForm from './DataProcessingForm';
import MailServicesForm from './MailServicesForm';
import HandworkForm from './HandworkForm';
import FulfillmentForm from './FulfillmentForm';
import LargeFormatForm from './LargeFormatForm';
import PromotionalForm from './PromotionalForm';
import PocketFolderForm from './PocketFolderForm';
import NcrForm from './NcrForm';
import NotepadsForm from './NotepadsForm';
import MailOnlyForm from './MailOnlyForm';
import { empty } from './../../../utils';
import { Link } from 'react-router-dom';

const Form = React.memo((props) => {
    if (props.fetchingRequest || props.fetchingInformation || props.fetchingMainPart) {
        return <div className="text-center"><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
    }

    const handleSetField = (name, event) => {
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        }

        props.setField(name, value);

        if (name === 'product_type') {
            props.setField('process_request', '');
        } else if (name === 'paper_stock') {
            props.setField('paper_weight', '');
        } else if (name === 'interior_paper_stock') {
            props.setField('interior_paper_weight', '');
        } else if (name === 'customer_id' && parseInt(props.fields.shipping_to_type) === 1) {
            let customer = null;
            for (let i = 0; i < props.information.customers.length; i++) {
                if (parseInt(props.information.customers[i].id) === parseInt(value)) {
                    customer = props.information.customers[i];
                    break;
                }
            }

            props.setField('address', customer.display_name + "\n" + customer.shipping_address + "\n" + customer.shipping_city
                + ', ' + customer.shipping_state + ' ' + customer.shipping_zip);
        } else if (name === 'envelope_paper_type') {
            props.setField('envelope_paper_style', '');
        } else if (name === 'shipping_to_type' && parseInt(event.target.value) === 1) {
            props.setField('sales_to_deliver', 0);

            if (!empty(props.fields.customer_id)) {
                let customer = null;
                for (let i = 0; i < props.information.customers.length; i++) {
                    if (parseInt(props.information.customers[i].id) === parseInt(props.fields.customer_id)) {
                        customer = props.information.customers[i];
                        break;
                    }
                }

                props.setField('address', customer.display_name + "\n" + customer.shipping_address + "\n" + customer.shipping_city
                    + ', ' + customer.shipping_state + ' ' + customer.shipping_zip);
            }
        } else if (name === 'shipping_to_type' && parseInt(event.target.value) === 2) {
            props.setField('address', '');
            props.setField('sales_to_deliver', 0);
        } else if (name === 'shipping_to_type' && parseInt(event.target.value) === 3) {
            props.setField('address', 'Will call pickup');
            props.setField('sales_to_deliver', 0);
        } else if (name === 'sales_to_deliver' && event.target.checked) {
            props.setField('address', 'Sales to deliver');
        } else if (name === 'process_request' && parseInt(event.target.value) === 2) {
            props.setField('is_mail_only', 1);
        } else if (name === 'process_request' && parseInt(event.target.value) !== 2) {
            props.setField('is_mail_only', 0);
        }
    };

    let productTypeName = 'N/A';
    for (let i = 0; i < props.information.productTypes.length; i++) {
        if (parseInt(props.information.productTypes[i].id) === parseInt(props.fields.product_type)) {
            productTypeName = props.information.productTypes[i].name;
            break;
        }
    }

    let processRequestName = 'N/A';
    if (parseInt(props.fields.process_request) === 1) {
        processRequestName = 'Print Only';
    } else if (parseInt(props.fields.process_request) === 2) {
        processRequestName = 'Mail Only';
    } else if (parseInt(props.fields.process_request) === 3) {
        processRequestName = 'Print and Mail';
    } else if (parseInt(props.fields.process_request) === 4) {
        processRequestName = 'Indoor / Outdoor';
    } else if (parseInt(props.fields.process_request) === 5) {
        processRequestName = 'Promotional Products';
    } else if (parseInt(props.fields.process_request) === 6) {
        processRequestName = 'Artwork Only';
    } else if (parseInt(props.fields.process_request) === 7) {
        processRequestName = 'Self-Cover';
    } else if (parseInt(props.fields.process_request) === 8) {
        processRequestName = 'Plus-Cover';
    }

    let handleCancel = () => {
        props.history.push('/requests-for-quotes');
    };

    let submitButton = null;
    if (props.fields.id) {
        submitButton = (
            <button disabled={props.submitting} onClick={props.showConfirmSubmitModal} className="submit-button">
                {props.submitting ? <i className="fas fa-circle-notch fa-spin" /> : 'Submit'}
            </button>
        );
    }

    let buttonEstimateLink = null;
    if (props.fields.sc_estimate_id) {
        buttonEstimateLink = (
            <div className="estimate-link link ml-3 mr-3">
                View Estimate #: <a href={'/estimate/' + props.fields.estimate_type + '/' + props.fields.sc_estimate_id}>{props.fields.sc_estimate_id}</a>
            </div>
        );
    }

    let downloadEstimateButton = null;
    if (parseInt(props.fields.status) > 2) {
        downloadEstimateButton = (
            <button disabled={props.downloadingEstimate} onClick={props.downloadEstimate} className="download-estimate-button">
                {props.downloadingEstimate ? <i className="fas fa-circle-notch fa-spin" /> : <Fragment><i className="fas fa-download"></i> Download Estimate</Fragment>}
            </button>
        );
    }

    let deletePartButton = null;
    if (!empty(props.fields.main_part_id) && parseInt(props.fields.status) === 1) {
        const handleDeletePart = () => {
            props.showDeletePartModal();
            props.setIdForDelete(props.fields.id);
        }

        deletePartButton = (
            <div className="delete-part-button-container">
                <button onClick={handleDeletePart}><i className="fas fa-trash" /> Delete this Part</button>
            </div>
        );
    }

    const handleAddPart = () => {
        let mainPartId = props.fields.id;
        if (props.mainPart != null) {
            mainPartId = props.mainPart.id;
        }

        props.history.push('/request-for-quote/add-part/' + mainPartId);
    };

    let addPartButton = null;
    if (parseInt(props.fields.status) === 1) {
        addPartButton = <button onClick={handleAddPart}><i className="fas fa-plus" /> Add Part</button>
    }

    let duplicateButton = null;
    if (!empty(props.fields.id) && parseInt(props.fields.status) >= 1) {
        duplicateButton = (
            <div className="add-part-container">
                <button onClick={props.duplicatePart}>
                    {props.isDuplicatingPart
                        ? <i className="fas fa-circle-notch fa-spin" />
                        : <Fragment><i className="fas fa-copy" /> Duplicate Part</Fragment>}
                </button>
            </div>
        );
    }

    let buttonsContainer = (
        <div className="buttons">
            {addPartButton}
            {duplicateButton}
            {deletePartButton}
            {buttonEstimateLink}
            {downloadEstimateButton}
            <button onClick={props.resetState}>Reset</button>
            <button onClick={handleCancel}>Cancel</button>
            <button disabled={props.saving} onClick={props.saveRequest} className="save-button">
                {props.saving ? <i className="fas fa-circle-notch fa-spin" /> : 'Save'}
            </button>
        </div>
    );
    if (parseInt(props.fields.status) === 1) {
        buttonsContainer = (
            <div className="buttons">
                {addPartButton}
                {duplicateButton}
                {deletePartButton}
                {buttonEstimateLink}
                {downloadEstimateButton}
                <button onClick={props.resetState}>Reset</button>
                <button onClick={handleCancel}>Cancel</button>
                <button disabled={props.saving} onClick={props.saveRequest} className="save-button">
                    {props.saving ? <i className="fas fa-circle-notch fa-spin" /> : 'Save'}
                </button>
                {submitButton}
            </div>
        );
    } else if (parseInt(props.fields.status) > 1) {
        buttonsContainer = (
            <div className="buttons">
                {addPartButton}
                {duplicateButton}
                {deletePartButton}
                {buttonEstimateLink}
                {downloadEstimateButton}
                <button onClick={handleCancel}>Cancel</button>
                <button disabled={props.saving} onClick={props.saveRequest} className="save-button">
                    {props.saving ? <i className="fas fa-circle-notch fa-spin" /> : 'Save'}
                </button>
                {submitButton}
            </div>
        );
    }

    let paperStockOptions = [];
    for (let i = 0; i < props.information.paperCategories.length; i++) {
        paperStockOptions.push(<option value={props.information.paperCategories[i].id}>{props.information.paperCategories[i].category_name}</option>);
    }

    /*********** Regular ************/
    let weights = [];
    let paperWeightOptions = [];
    for (let i = 0; i < props.information.papers.length; i++) {
        if (parseInt(props.information.papers[i].paper_category_id) !== parseInt(props.fields.paper_stock)) {
            continue;
        }

        if (!weights.includes(props.information.papers[i].basis_weight)) {
            weights.push(props.information.papers[i].basis_weight);
        }
    }
    weights.sort((a, b) => {
        if (parseFloat(a) > parseFloat(b)) {
            return 1;
        }
        if (parseFloat(a) < parseFloat(b)) {
            return -1;
        }
        return 0;
    });
    for (let i = 0; i < weights.length; i++) {
        paperWeightOptions.push(<option value={weights[i]}>{weights[i]}#</option>);
    }

    /*********** Interior ************/
    weights = [];
    let interiorPaperWeightOptions = [];
    for (let i = 0; i < props.information.papers.length; i++) {
        if (parseInt(props.information.papers[i].paper_category_id) !== parseInt(props.fields.interior_paper_stock)) {
            continue;
        }

        if (!weights.includes(props.information.papers[i].basis_weight)) {
            weights.push(props.information.papers[i].basis_weight);
        }
    }
    weights.sort((a, b) => {
        if (parseFloat(a) > parseFloat(b)) {
            return 1;
        }
        if (parseFloat(a) < parseFloat(b)) {
            return -1;
        }
        return 0;
    });
    for (let i = 0; i < weights.length; i++) {
        interiorPaperWeightOptions.push(<option value={weights[i]}>{weights[i]}#</option>);
    }

    let form = null;
    if (props.activeTab === 1) {
        form = (
            <InformationForm
                {...props}
                productTypeName={productTypeName}
                processRequestName={processRequestName}
                buttonsContainer={buttonsContainer}
                handleSetField={handleSetField}
                handleCancel={handleCancel}
            />
        );
    } else if (props.activeTab === 2) {
        form = (
            <FlatForm
                {...props}
                productTypeName={productTypeName}
                processRequestName={processRequestName}
                buttonsContainer={buttonsContainer}
                paperStockOptions={paperStockOptions}
                paperWeightOptions={paperWeightOptions}
                handleSetField={handleSetField}
                handleCancel={handleCancel}
            />
        );
    } else if (props.activeTab === 3) {
        form = (
            <SelfCoverForm
                {...props}
                productTypeName={productTypeName}
                processRequestName={processRequestName}
                buttonsContainer={buttonsContainer}
                paperStockOptions={paperStockOptions}
                paperWeightOptions={paperWeightOptions}
                handleSetField={handleSetField}
                handleCancel={handleCancel}
            />
        );
    } else if (props.activeTab === 4) {
        form = (
            <PlusCoverForm
                {...props}
                productTypeName={productTypeName}
                processRequestName={processRequestName}
                buttonsContainer={buttonsContainer}
                paperStockOptions={paperStockOptions}
                paperWeightOptions={paperWeightOptions}
                interiorPaperWeightOptions={interiorPaperWeightOptions}
                handleSetField={handleSetField}
                handleCancel={handleCancel}
            />
        );
    } else if (props.activeTab === 5) {
        form = (
            <EnvelopesForm
                {...props}
                productTypeName={productTypeName}
                processRequestName={processRequestName}
                buttonsContainer={buttonsContainer}
                handleSetField={handleSetField}
                handleCancel={handleCancel}
            />
        );
    } else if (props.activeTab === 6) {
        form = (
            <BinderyForm
                {...props}
                productTypeName={productTypeName}
                processRequestName={processRequestName}
                buttonsContainer={buttonsContainer}
                handleSetField={handleSetField}
                handleCancel={handleCancel}
            />
        );
    } else if (props.activeTab === 7) {
        form = (
            <DataProcessingForm
                {...props}
                productTypeName={productTypeName}
                processRequestName={processRequestName}
                buttonsContainer={buttonsContainer}
                handleSetField={handleSetField}
                handleCancel={handleCancel}
            />
        );
    } else if (props.activeTab === 8) {
        form = (
            <MailServicesForm
                {...props}
                productTypeName={productTypeName}
                processRequestName={processRequestName}
                buttonsContainer={buttonsContainer}
                handleSetField={handleSetField}
                handleCancel={handleCancel}
            />
        );
    } else if (props.activeTab === 9) {
        form = (
            <HandworkForm
                {...props}
                productTypeName={productTypeName}
                processRequestName={processRequestName}
                buttonsContainer={buttonsContainer}
                handleSetField={handleSetField}
                handleCancel={handleCancel}
            />
        );
    } else if (props.activeTab === 10) {
        form = (
            <FulfillmentForm
                {...props}
                productTypeName={productTypeName}
                processRequestName={processRequestName}
                buttonsContainer={buttonsContainer}
                handleSetField={handleSetField}
                handleCancel={handleCancel}
            />
        );
    } else if (props.activeTab === 11) {
        form = (
            <LargeFormatForm
                {...props}
                productTypeName={productTypeName}
                processRequestName={processRequestName}
                buttonsContainer={buttonsContainer}
                handleSetField={handleSetField}
                handleCancel={handleCancel}
            />
        );
    } else if (props.activeTab === 12) {
        form = (
            <PromotionalForm
                {...props}
                productTypeName={productTypeName}
                processRequestName={processRequestName}
                buttonsContainer={buttonsContainer}
                handleSetField={handleSetField}
                handleCancel={handleCancel}
            />
        );
    } else if (props.activeTab === 13) {
        form = (
            <PocketFolderForm
                {...props}
                productTypeName={productTypeName}
                processRequestName={processRequestName}
                buttonsContainer={buttonsContainer}
                paperStockOptions={paperStockOptions}
                paperWeightOptions={paperWeightOptions}
                handleSetField={handleSetField}
                handleCancel={handleCancel}
            />
        );
    } else if (props.activeTab === 14) {
        form = (
            <NcrForm
                {...props}
                productTypeName={productTypeName}
                processRequestName={processRequestName}
                buttonsContainer={buttonsContainer}
                paperStockOptions={paperStockOptions}
                paperWeightOptions={paperWeightOptions}
                handleSetField={handleSetField}
                handleCancel={handleCancel}
            />
        );
    } else if (props.activeTab === 15) {
        form = (
            <NotepadsForm
                {...props}
                productTypeName={productTypeName}
                processRequestName={processRequestName}
                buttonsContainer={buttonsContainer}
                paperStockOptions={paperStockOptions}
                paperWeightOptions={paperWeightOptions}
                handleSetField={handleSetField}
                handleCancel={handleCancel}
            />
        );
    } else if (props.activeTab === 16) {
        form = (
            <MailOnlyForm
                {...props}
                productTypeName={productTypeName}
                processRequestName={processRequestName}
                buttonsContainer={buttonsContainer}
                paperStockOptions={paperStockOptions}
                paperWeightOptions={paperWeightOptions}
                handleSetField={handleSetField}
                handleCancel={handleCancel}
            />
        );
    }

    let simpleFlatTab = null;
    if (props.fields.simple_flat) {
        simpleFlatTab = <div className={'tab' + (props.activeTab === 2 ? ' active' : '')} onClick={props.setActiveTab.bind(this, 2)}>Simple Flat</div>;
    }

    let selfCoverTab = null;
    if (props.fields.self_cover) {
        selfCoverTab = <div className={'tab' + (props.activeTab === 3 ? ' active' : '')} onClick={props.setActiveTab.bind(this, 3)}>Self-Cover</div>;
    }

    let plusCoverTab = null;
    if (props.fields.plus_cover) {
        plusCoverTab = <div className={'tab' + (props.activeTab === 4 ? ' active' : '')} onClick={props.setActiveTab.bind(this, 4)}>Plus Cover</div>;
    }

    let envelopesTab = null;
    if (props.fields.envelopes) {
        envelopesTab = <div className={'tab' + (props.activeTab === 5 ? ' active' : '')} onClick={props.setActiveTab.bind(this, 5)}>Envelopes</div>;
    }

    let binderyTab = null;
    if (props.fields.bindery) {
        binderyTab = <div className={'tab' + (props.activeTab === 6 ? ' active' : '')} onClick={props.setActiveTab.bind(this, 6)}>Bindery</div>;
    }

    let dataProcessingTab = null;
    if (props.fields.data_processing) {
        dataProcessingTab = <div className={'tab' + (props.activeTab === 7 ? ' active' : '')} onClick={props.setActiveTab.bind(this, 7)}>Data Processing</div>;
    }

    let mailingServicesTab = null;
    if (props.fields.mailing_services) {
        mailingServicesTab = <div className={'tab' + (props.activeTab === 8 ? ' active' : '')} onClick={props.setActiveTab.bind(this, 8)}>Mail Services</div>;
    }

    let handworkTab = null;
    if (props.fields.handwork) {
        handworkTab = <div className={'tab' + (props.activeTab === 9 ? ' active' : '')} onClick={props.setActiveTab.bind(this, 9)}>Handwork</div>;
    }

    // let fulfillmentTab = null;
    // if (props.fields.fulfillment) {
    //     fulfillmentTab = <div className={'tab' + (props.activeTab === 10 ? ' active' : '')} onClick={props.setActiveTab.bind(this, 10)}>Fulfillment</div>;
    // }

    let largeFormatTab = null;
    if (props.fields.large_format) {
        largeFormatTab = <div className={'tab' + (props.activeTab === 11 ? ' active' : '')} onClick={props.setActiveTab.bind(this, 11)}>Large Format</div>;
    }

    // let promotionalTab = null;
    // if (props.fields.promotional) {
    //     promotionalTab = <div className={'tab' + (props.activeTab === 12 ? ' active' : '')} onClick={props.setActiveTab.bind(this, 12)}>Promotional</div>;
    // }

    let pocketFolderTab = null;
    if (props.fields.pocket_folder) {
        pocketFolderTab = <div className={'tab' + (props.activeTab === 13 ? ' active' : '')} onClick={props.setActiveTab.bind(this, 13)}>Pocket Folder</div>;
    }

    let ncrTab = null;
    if (props.fields.is_ncr) {
        ncrTab = <div className={'tab' + (props.activeTab === 14 ? ' active' : '')} onClick={props.setActiveTab.bind(this, 14)}>NCR</div>;
    }

    let notepadsTab = null;
    if (props.fields.is_notepads) {
        notepadsTab = <div className={'tab' + (props.activeTab === 15 ? ' active' : '')} onClick={props.setActiveTab.bind(this, 15)}>Notepads</div>;
    }

    let clientProvideTab = null;
    if (props.fields.is_mail_only) {
        clientProvideTab = <div className={'tab' + (props.activeTab === 16 ? ' active' : '')} onClick={props.setActiveTab.bind(this, 16)}>Client Provide</div>;
    }

    let rfqLink = null;
    if (!empty(props.fields.id)) {
        if (!empty(props.mainPartId)) {
            rfqLink = <div className="rfq-link link">RFQ #: {props.mainPartId}</div>;
        } else {
            rfqLink = <div className="rfq-link link">RFQ #: {props.fields.id}</div>;
        }
    }

    let estimateLink = null;
    if (props.fields.sc_estimate_id) {
        estimateLink = (
            <div className="estimate-link link">
                Estimate #: <a href={'/estimate/' + props.fields.estimate_type + '/' + props.fields.sc_estimate_id}>{props.fields.sc_estimate_id}</a>
            </div>
        );
    }

    let partsInfoContainer = null;
    if (props.mainPart != null) {
        let partNumber = parseInt(props.fields.part_number);
        let partsTotal = parseInt(props.fields.parts_total);
        if (props.history.location.pathname.includes('/add-part') && props.mainPart.parts_total == null) {
            partsTotal = 2;
            partNumber = partsTotal;
        } else if (props.history.location.pathname.includes('/add-part') && props.mainPart.parts_total != null) {
            partsTotal = parseInt(props.mainPart.parts_total) + 1;
            partNumber = partsTotal;
        }

        let prevButton = null;
        let nextButton = null;
        if (props.fields.prev_part_id && props.fields.prev_part_id != null) {
            prevButton = (
                <Link to={'/request-for-quote/' + props.fields.prev_part_id}>
                    <button><i className="fas fa-chevron-left"></i> Previous Part</button>
                </Link>
            );
        }
        if (props.fields.next_part_id && props.fields.next_part_id != null) {
            nextButton = (
                <Link to={'/request-for-quote/' + props.fields.next_part_id}>
                    <button>Next Part <i className="fas fa-chevron-right"></i></button>
                </Link>
            );
        }

        partsInfoContainer = (
            <div className="parts-info-container">
                <div className="fields">
                    <div className="field">
                        <div className="label">Main Part ID:</div>
                        <div className="value">
                            <Link to={'/request-for-quote/' + props.mainPartId}>{props.mainPartId}</Link>
                        </div>
                    </div>
                    <div className="field">
                        <div className="label">Part #:</div>
                        <div className="value">{partNumber} of {partsTotal}</div>
                    </div>
                </div>
                <div className="parts-buttons">
                    {prevButton}
                    {nextButton}
                </div>
            </div>
        );
    }

    return (
        <div className="form-container">
            {partsInfoContainer}
            <div className="tabs-container">
                <div className="tabs">
                    <div className={'tab' + (props.activeTab === 1 ? ' active' : '')} onClick={props.setActiveTab.bind(this, 1)}>Information</div>
                    {simpleFlatTab}
                    {selfCoverTab}
                    {plusCoverTab}
                    {envelopesTab}
                    {binderyTab}
                    {dataProcessingTab}
                    {mailingServicesTab}
                    {handworkTab}
                    {/* fulfillmentTab */}
                    {largeFormatTab}
                    {/* promotionalTab */}
                    {pocketFolderTab}
                    {ncrTab}
                    {notepadsTab}
                    {clientProvideTab}
                </div>
                <div className="relations-container">
                    {rfqLink}
                    {estimateLink}
                </div>
            </div>
            <div className="content">
                {form}
            </div>
        </div>
    );
});

export default Form;
