import React from 'react';
import FormPopover from './FormPopover';
import { empty } from './../../../utils';

const EnvelopesForm = React.memo((props) => {
    let assignedEnvelopeTypes = [];
    let envelopeTypeOptions = [];
    let envelopeTypes = [];
    for (let i = 0; i < props.information.envelopePapers.length; i++) {
        const item = props.information.envelopePapers[i];
        const id = parseInt(item['envelope_type_id']);
        if (!assignedEnvelopeTypes.includes(id)) {
            envelopeTypes.push(item);
            assignedEnvelopeTypes.push(id);
        }
    }
    envelopeTypes = envelopeTypes.sort((a, b) => {
        if (a['envelope_type_name'] < b['envelope_type_name']) {
            return -1;
        } else if (a['envelope_type_name'] > b['envelope_type_name']) {
            return 1;
        } else {
            return 0;
        }
    });
    for (let i = 0; i < envelopeTypes.length; i++) {
        const item = envelopeTypes[i];
        const id = parseInt(item['envelope_type_id']);
        envelopeTypeOptions.push(<option value={id}>{item['envelope_type_name']}</option>);
    }

    let assignedEnvelopeStyles = [];
    let envelopeStyleOptions = [];
    let envelopeStyles = [];
    if (!empty(props.fields.envelope_paper_type)) {
        for (let i = 0; i < props.information.envelopePapers.length; i++) {
            const item = props.information.envelopePapers[i];
            const style = item['style'];
            if (!assignedEnvelopeStyles.includes(style) && parseInt(item['envelope_type_id']) === parseInt(props.fields.envelope_paper_type)) {
                envelopeStyles.push(item);
                assignedEnvelopeStyles.push(style);
            }
        }
        envelopeStyles = envelopeStyles.sort((a, b) => {
            if (a['style'] < b['style']) {
                return -1;
            } else if (a['style'] > b['style']) {
                return 1;
            } else {
                return 0;
            }
        });
        for (let i = 0; i < envelopeStyles.length; i++) {
            const item = envelopeStyles[i];
            const style = item['style'];
            envelopeStyleOptions.push(<option value={style}>{style}</option>);
        }
    }

    return (
        <div className="envelopes-form-container">
            <div className="line1">
                <div className="product-type">
                    <div><b>Product Type:</b></div>
                    <div>{props.productTypeName}</div>
                </div>
                <div className="process-request">
                    <div><b>Process Request:</b></div>
                    <div>{props.processRequestName}</div>
                </div>
            </div>
            <div className="line2">
                <div className="column1">
                    <div className="title">
                        <b>Ink:</b>
                        <FormPopover
                            code="FLAT-002"
                            information={props.information}
                        />
                    </div>
                    <div className="subline1">
                        <div className="subtitle"><b>Front</b></div>
                        <div className="colors-field">
                            <b>Colors:</b>
                            <input type="text" value={props.fields.front_colors} onChange={props.handleSetField.bind(this, 'front_colors')} />
                        </div>
                        <div className="ink-field">
                            <b>Ink <span className="required">*</span>:</b>
                            <select value={props.fields.front_inks} onChange={props.handleSetField.bind(this, 'front_inks')}>
                                <option value="">Select option</option>
                                <option value="1">Black only</option>
                                <option value="2">PMS only</option>
                                <option value="3">CMYK</option>
                                <option value="4">CMYK + PMS</option>
                                <option value="9">Black + PMS</option>
                                <option value="10">PMS + PMS</option>
                            </select>
                        </div>
                        <div className="pms-field">
                            <b>PMS #:</b>
                            <input type="text" value={props.fields.front_pms} onChange={props.handleSetField.bind(this, 'front_pms')} />
                        </div>
                    </div>
                    <div className="subline2">
                        <div className="subtitle"><b>Back</b></div>
                        <div className="colors-field">
                            <b>Colors:</b>
                            <input type="text" value={props.fields.back_colors} onChange={props.handleSetField.bind(this, 'back_colors')} />
                        </div>
                        <div className="ink-field">
                            <b>Ink <span className="required">*</span>:</b>
                            <select value={props.fields.back_inks} onChange={props.handleSetField.bind(this, 'back_inks')}>
                                <option value="">Select option</option>
                                <option value="0">Blank</option>
                                <option value="1">Black only</option>
                                <option value="2">PMS only</option>
                                <option value="3">CMYK</option>
                                <option value="4">CMYK + PMS</option>
                                <option value="9">Black + PMS</option>
                                <option value="10">PMS + PMS</option>
                            </select>
                        </div>
                        <div className="pms-field">
                            <b>PMS #:</b>
                            <input type="text" value={props.fields.back_pms} onChange={props.handleSetField.bind(this, 'back_pms')} />
                        </div>
                    </div>
                    <div className="second-title"><b>Print Location <span className="required">*</span>:</b></div>
                    <div className="checkboxes">
                        <div className="check">
                            <input type="checkbox" value={props.fields.has_return_address} checked={props.fields.has_return_address} onChange={props.handleSetField.bind(this, 'has_return_address')} /> Return address
                        </div>
                        <div className="check">
                            <input type="checkbox" value={props.fields.has_back_flap} checked={props.fields.has_back_flap} onChange={props.handleSetField.bind(this, 'has_back_flap')} /> Address area
                        </div>
                        <div className="check">
                            <input type="checkbox" value={props.fields.has_address_area} checked={props.fields.has_address_area} onChange={props.handleSetField.bind(this, 'has_address_area')} /> Back flap
                        </div>
                        <div className="check other-check">
                            <input type="checkbox" value={props.fields.has_other} checked={props.fields.has_other} onChange={props.handleSetField.bind(this, 'has_other')} /> Other
                            <input type="text" value={props.fields.envelope_other} onChange={props.handleSetField.bind(this, 'envelope_other')} />
                        </div>
                    </div>
                </div>
                <div className="column2">
                    <div className="envelope-type-field">
                        <b>Envelope Type <span className="required">*</span>:</b>
                        <select value={props.fields.envelope_paper_type} onChange={props.handleSetField.bind(this, 'envelope_paper_type')}>
                            <option value="">Select option</option>
                            {envelopeTypeOptions}
                        </select>
                    </div>
                    <div className="envelope-style-field">
                        <b>Envelope Style <span className="required">*</span>:</b>
                        <select value={props.fields.envelope_paper_style} onChange={props.handleSetField.bind(this, 'envelope_paper_style')}>
                            <option value="">Select option</option>
                            {envelopeStyleOptions}
                        </select>
                    </div>
                </div>
                <div className="column3">
                    <div><b>When finish <span className="required">*</span>:</b></div>
                    <div className="part-field">
                        <input type="checkbox" value={props.fields.part_another_job} checked={props.fields.part_another_job} onChange={props.handleSetField.bind(this, 'part_another_job')} /> <b>Part of another job</b>
                    </div>
                    <div className="sc-job-field">
                        Stagecoach #: <input type="text" value={props.fields.sc_number} checked={props.fields.sc_number} onChange={props.handleSetField.bind(this, 'sc_number')} />
                    </div>
                    <div className="check-field">
                        <input type="checkbox" value={props.fields.done_when_completed} checked={props.fields.done_when_completed} onChange={props.handleSetField.bind(this, 'done_when_completed')} /> <b>Done when completed</b>
                    </div>
                    <div className="check-field">
                        <input type="checkbox" value={props.fields.goes_fulfillment} checked={props.fields.goes_fulfillment} onChange={props.handleSetField.bind(this, 'goes_fulfillment')} /> <b>Goes to fulfillment</b>
                    </div>
                </div>
            </div>
            <div className="line3">
                <div className="title"><b>Please describe your project in detail here:</b></div>
                <textarea
                    placeholder="Please let us know more about your project detail"
                    value={props.fields.envelopes_description}
                    onChange={props.handleSetField.bind(this, 'envelopes_description')}
                />
            </div>
            {props.buttonsContainer}
        </div>
    );
});

export default EnvelopesForm;
