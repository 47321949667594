import React from 'react';
import { Dialog } from './../../common/dialog';

const StopModal = React.memo((props) => {
    const hideHandler = () => {
        props.hideStopModal();
    };

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Close</button>,
    ];
    return (
        <Dialog
            name="stop-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="dashboard-stop-dialog"
        >
            <div className="dashboard-stop-container">
                <div className="text-center">
                    <img src="https://ac-pqn.s3.amazonaws.com/css/stop-icon.png" alt="Stop" />
                </div>
                <h1>STOP</h1>
                <p className="warning">You cannot start a New Task without going through the RFQ to get an estimate first.  Please contact your CSR or Management for assistance.</p>
            </div>
        </Dialog >
    );
});

export default StopModal;
