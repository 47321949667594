import React from 'react';
import { Dialog } from './../../common/dialog';
import Switch from "react-switch";
import FormPopover from './../Form/FormPopover';

const PushModal = React.memo((props) => {
    const handleDataProcessingSwitchChange = (id, field, checked) => {
        props.setDataProcessingItem(id, field, checked ? 1 : 0);
    };

    const handleVariablePrintSwitchChange = (id, field, checked) => {
        props.setVariablePrintItem(id, field, checked ? 1 : 0);
    };

    const renderDataProcessingItems = () => {
        return props.dataProcessingItems.map((item) => {
            return (
                <tr>
                    <td>{item.name}</td>
                    <td className="switch-column">
                        <Switch
                            onChange={handleDataProcessingSwitchChange.bind(this, item.id, 'checked')}
                            checked={item.checked}
                            width={42}
                            height={22}
                        />
                    </td>
                    <td className="action-column">
                        <FormPopover
                            code={item.code_ref}
                            information={props.information}
                        />
                    </td>
                </tr>
            );
        });
    };

    const renderVariablePrintItems = () => {
        return props.variablePrintItems.map((item) => {
            return (
                <tr>
                    <td>{item.name}</td>
                    <td className="switch-column">
                        <Switch
                            onChange={handleVariablePrintSwitchChange.bind(this, item.id, 'checked')}
                            checked={item.checked}
                            width={42}
                            height={22}
                        />
                    </td>
                    <td className="action-column">
                        <FormPopover
                            code={item.code_ref}
                            information={props.information}
                        />
                    </td>
                </tr>
            );
        });
    };

    let content = null;
    if (props.dataProcessingActiveTab === 1) {
        content = (
            <div className="items">
                <table className="table table-sm table-hover table-striped">
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Yes/No</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderDataProcessingItems()}
                    </tbody>
                </table>
            </div>
        );
    } else if (props.dataProcessingActiveTab === 2) {
        content = (
            <div className="items">
                <table className="table table-sm table-hover table-striped">
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Yes/No</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderVariablePrintItems()}
                    </tbody>
                </table>
            </div>
        );
    }

    const handleAddButton = () => {
        props.addDataProcessingItems();
        props.hideDataProcessingModal();
    };

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={props.hideDataProcessingModal}>Cancel</button>,
        <button key="delete-button" className="btn btn-light btn-sm action-button" onClick={handleAddButton}>Add</button>,
    ];
    return (
        <Dialog
            name="data-processing-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="quote-request-data-processing-dialog"
        >
            <div className="quote-request-data-processing-container">
                <h1>Data Processing Items</h1>
                <div className="items-container">
                    <div className="tabs">
                        <div className={'tab' + (props.dataProcessingActiveTab === 1 ? ' active' : '')} onClick={props.setDataProcessingActiveTab.bind(this, 1)}>
                            Data Processing
                        </div>
                        <div className={'tab' + (props.dataProcessingActiveTab === 2 ? ' active' : '')} onClick={props.setDataProcessingActiveTab.bind(this, 2)}>
                            Variable Print
                        </div>
                    </div>
                    {content}
                </div>
            </div>
        </Dialog >
    );
});

export default PushModal;
