import React from 'react';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import PqtPagination from './../../PqtPagination';

const Invoices = React.memo((props) => {
    const confirmHandler = (id) => {
        props.showDeleteModal();
        props.setIdForDelete(id);
    }

    const handleShowFormModal = (id) => {
        props.setIdForDelete(id);
        props.setActiveHelpItem(id);
        props.showFormModal();
    };

    const renderHelpItems = () => {
        if (props.fetchingHelpItems) {
            return (
                <tr>
                    <td colSpan="4"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.helpItems.length === 0) {
            return (
                <tr>
                    <td colSpan="4">No Results</td>
                </tr>
            );
        }

        return props.helpItems.map((helpItem, index) => {
            let deleteIcon = null;
            if (props.user != null && parseInt(props.user.id) === 61) {
                deleteIcon = (
                    <Tooltip title="Delete Invoice" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="fas fa-trash" onClick={confirmHandler.bind(this, helpItem.id)} />
                    </Tooltip>
                );
            }

            return (
                <tr key={'late-invoice-' + index}>
                    <td>{helpItem.id}</td>
                    <td>{helpItem.name}</td>
                    <td>{helpItem.code_ref}</td>
                    <td>
                        <div className="actions">
                            <Tooltip title="Edit Help Item" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-edit" onClick={handleShowFormModal.bind(this, helpItem.id)} />
                            </Tooltip>
                            {deleteIcon}
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const setSortHandler = (field, value) => {
        props.setSort(field, value);
    };

    const renderHeader = (name, column, className) => {
        let sortFunc = setSortHandler.bind(this, column, 'asc');
        let sortIcon = <i className="fas fa-sort" />;
        if (props.sort.column === column) {
            if (props.sort.type === 'asc') {
                sortFunc = setSortHandler.bind(this, column, 'desc');
                sortIcon = <i className="fas fa-sort-up" />;
            } else {
                sortFunc = setSortHandler.bind(this, column, 'asc');
                sortIcon = <i className="fas fa-sort-down" />;
            }
        }

        return <th className={className} onClick={sortFunc}>{name} {sortIcon}</th>;
    };

    const handleChangeFilter = (name, fetch, event) => {
        props.changeFilter(name, event.target.value, fetch);
    };

    const handleKey = (event) => {
        if (event.key === 'Enter') {
            props.fetchHelpItems()
        }
    };

    const renderSearchField = (name) => {
        let value = null;
        for (let i = 0; i < props.filerFields.length; i++) {
            if (props.filerFields[i].name === name) {
                value = props.filerFields[i].value;
            }
        }

        return (
            <th className="search-th">
                <input
                    type="text"
                    name={name}
                    className="search-input"
                    value={value}
                    onKeyPress={handleKey}
                    onChange={handleChangeFilter.bind(this, name, false)}
                />
            </th>
        );
    };

    return (
        <div className="list-container">
            <div className="text-right">
                <button className="create-button" onClick={props.showFormModal}>Create</button>
            </div>
            <div className="table-container">
                <table className="table table-sm table-hover table-striped">
                    <thead>
                        <tr>
                            {renderHeader('ID', 'id', 'th-id')}
                            {renderHeader('Name', 'name', 'th-name')}
                            {renderHeader('Reference', 'code_ref', 'th-code-ref')}
                            <th style={{ width: 70 }}></th>
                        </tr>
                        <tr>
                            {renderSearchField('id')}
                            {renderSearchField('name')}
                            {renderSearchField('code_ref')}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderHelpItems()}
                    </tbody>
                </table>
                <PqtPagination
                    pagination={props.pagination}
                    setActivePage={props.setActivePage}
                />
            </div>
        </div>
    );
});

Invoices.propTypes = {

};

export default Invoices;
