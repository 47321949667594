export const FETCH_CATEGORIES = 'MAILING_CATEGORIES/FETCH_CATEGORIES';
export const FETCH_CATEGORIES_SUCCESS = 'MAILING_CATEGORIES/FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_ERROR = 'MAILING_CATEGORIES/FETCH_CATEGORIES_ERROR';
export const SAVE_CATEGORY = 'MAILING_CATEGORIES/SAVE_CATEGORY';
export const SAVE_CATEGORY_SUCCESS = 'MAILING_CATEGORIES/SAVE_CATEGORY_SUCCESS';
export const SAVE_CATEGORY_ERROR = 'MAILING_CATEGORIES/SAVE_CATEGORY_ERROR';
export const DELETE_CATEGORY = 'MAILING_CATEGORIES/DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESS = 'MAILING_CATEGORIES/DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_ERROR = 'MAILING_CATEGORIES/DELETE_CATEGORY_ERROR';
export const SET_CATEGORY = 'MAILING_CATEGORIES/SET_CATEGORY';
export const SET_ID = 'MAILING_CATEGORIES/SET_ID';
export const SET_SORT = 'MAILING_CATEGORIES/SET_SORT';
export const SET_ACTIVE_PAGE = 'MAILING_CATEGORIES/SET_ACTIVE_PAGE';
export const SET_ID_FOR_DELETE = 'MAILING_CATEGORIES/SET_ID_FOR_DELETE';
export const CHANGE_FILTER = 'MAILING_CATEGORIES/CHANGE_FILTER';
export const SET_FIELD = 'MAILING_CATEGORIES/SET_FIELD';
export const RESET_STATE = 'MAILING_CATEGORIES/RESET_STATE';