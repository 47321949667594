import React, { Fragment } from 'react';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';

const OutsideServices = React.memo((props) => {
    const handleSetField = (index, field, event) => {
        props.setOutsideServiceField(index, field, event.target.value);
        // props.setDataChanged(true);
        props.setEstimateChanged(true);
    };

    const renderVendorOptions = () => {
        return props.information.vendors.map((vendor) => {
            return (
                <option value={vendor.id}>{vendor.display_name}</option>
            );
        });
    };

    const renderOutsideServices = () => {
        return props.outsideServices.map((service, index) => {
            return (
                <Fragment>
                    <div className="vendor-field">
                        Vendor:
                        <select value={service.outside_vendor_id} onChange={handleSetField.bind(this, index, 'outside_vendor_id')}>
                            <option value="">Select Vendor</option>
                            {renderVendorOptions()}
                        </select>
                    </div>
                    <div className="quote-number-field">
                        Quote#: <input type="text" value={service.outside_quote_number} onChange={handleSetField.bind(this, index, 'outside_quote_number')} />
                    </div>
                    <div className="delete-icon">
                        <Tooltip title="Delete Service Line" position="bottom" arrow size="small" className="action-tooltip">
                            <i className="fas fa-trash" onClick={props.deleteOutsideService.bind(this, index)}></i>
                        </Tooltip>
                    </div>
                    <div className="vendor-description-field">
                        <input type="text" value={service.outside_description} onChange={handleSetField.bind(this, index, 'outside_description')} placeholder="Description" />
                    </div>
                    <div className="outside-cost-field">
                        Cost: $ <input type="number" value={service.outside_cost} onChange={handleSetField.bind(this, index, 'outside_cost')} />
                    </div>
                    <div className="outside-markup-field">
                        Markup: <input type="number" value={service.outside_markup} onChange={handleSetField.bind(this, index, 'outside_markup')} /> %
                    </div>
                    <div className="outside-commission-field">
                        Commission: <input type="number" value={service.outside_commission} onChange={handleSetField.bind(this, index, 'outside_commission')} /> %
                    </div>
                    <hr className="outside-services-hr" />
                </Fragment>
            );
        });
    };

    return (
        <Fragment>
            <div className="outside-title">
                <div>Outside Service:</div>
                <div className="full-field">
                    <input type="checkbox" checked={props.estimate.full_outside_job} onChange={props.handleSetField.bind(this, 'full_outside_job')} /> 100% Outside Job
                </div>
            </div>
            {renderOutsideServices()}
            <button onClick={props.addOutsideService} className="add-service-button">Add Service Line</button>
        </Fragment>
    );
});

export default OutsideServices;
