export const FETCH_ESTIMATE = 'ESTIMATE/FETCH_ESTIMATE';
export const FETCH_ESTIMATE_SUCCESS = 'ESTIMATE/FETCH_ESTIMATE_SUCCESS';
export const FETCH_ESTIMATE_ERROR = 'ESTIMATE/FETCH_ESTIMATE_ERROR';
export const FETCH_INFORMATION = 'ESTIMATE/FETCH_INFORMATION';
export const FETCH_INFORMATION_SUCCESS = 'ESTIMATE/FETCH_INFORMATION_SUCCESS';
export const FETCH_INFORMATION_ERROR = 'ESTIMATE/FETCH_INFORMATION_ERROR';
export const SAVE_ESTIMATE = 'ESTIMATE/SAVE_ESTIMATE';
export const SAVE_ESTIMATE_SUCCESS = 'ESTIMATE/SAVE_ESTIMATE_SUCCESS';
export const SAVE_ESTIMATE_ERROR = 'ESTIMATE/SAVE_ESTIMATE_ERROR';
export const ESTIMATE = 'ESTIMATE/ESTIMATE';
export const ESTIMATE_SUCCESS = 'ESTIMATE/ESTIMATE_SUCCESS';
export const ESTIMATE_ERROR = 'ESTIMATE/ESTIMATE_ERROR';
export const MULTI_ESTIMATE = 'ESTIMATE/MULTI_ESTIMATE';
export const MULTI_ESTIMATE_SUCCESS = 'ESTIMATE/MULTI_ESTIMATE_SUCCESS';
export const MULTI_ESTIMATE_ERROR = 'ESTIMATE/MULTI_ESTIMATE_ERROR';
export const APPROVE_ESTIMATE = 'ESTIMATE/APPROVE_ESTIMATE';
export const APPROVE_ESTIMATE_SUCCESS = 'ESTIMATE/APPROVE_ESTIMATE_SUCCESS';
export const APPROVE_ESTIMATE_ERROR = 'ESTIMATE/APPROVE_ESTIMATE_ERROR';
export const CONVERT_TO_JOB = 'ESTIMATE/CONVERT_TO_JOB';
export const CONVERT_TO_JOB_SUCCESS = 'ESTIMATE/CONVERT_TO_JOB_SUCCESS';
export const CONVERT_TO_JOB_ERROR = 'ESTIMATE/CONVERT_TO_JOB_ERROR';
export const FETCH_SUBSTRATES = 'ESTIMATE/FETCH_SUBSTRATES';
export const FETCH_SUBSTRATES_SUCCESS = 'ESTIMATE/FETCH_SUBSTRATES_SUCCESS';
export const FETCH_SUBSTRATES_ERROR = 'ESTIMATE/FETCH_SUBSTRATES_ERROR';
export const FETCH_SUBSTRATE_ACCESSORIES = 'ESTIMATE/FETCH_SUBSTRATE_ACCESSORIES';
export const FETCH_SUBSTRATE_ACCESSORIES_SUCCESS = 'ESTIMATE/FETCH_SUBSTRATE_ACCESSORIES_SUCCESS';
export const FETCH_SUBSTRATE_ACCESSORIES_ERROR = 'ESTIMATE/FETCH_SUBSTRATE_ACCESSORIES_ERROR';
export const FETCH_SUBSTRATE_LAMINATES = 'ESTIMATE/FETCH_SUBSTRATE_LAMINATES';
export const FETCH_SUBSTRATE_LAMINATES_SUCCESS = 'ESTIMATE/FETCH_SUBSTRATE_LAMINATES_SUCCESS';
export const FETCH_SUBSTRATE_LAMINATES_ERROR = 'ESTIMATE/FETCH_SUBSTRATE_LAMINATES_ERROR';
export const SEND_ESTIMATE = 'ESTIMATE/SEND_ESTIMATE';
export const SEND_ESTIMATE_SUCCESS = 'ESTIMATE/SEND_ESTIMATE_SUCCESS';
export const SEND_ESTIMATE_ERROR = 'ESTIMATE/SEND_ESTIMATE_ERROR';
export const SEND_WRONG_ESTIMATE = 'ESTIMATE/SEND_WRONG_ESTIMATE';
export const SEND_WRONG_ESTIMATE_SUCCESS = 'ESTIMATE/SEND_WRONG_ESTIMATE_SUCCESS';
export const SEND_WRONG_ESTIMATE_ERROR = 'ESTIMATE/SEND_WRONG_ESTIMATE_ERROR';
export const DOWNLOAD_ESTIMATE = 'ESTIMATE/DOWNLOAD_ESTIMATE';
export const DOWNLOAD_ESTIMATE_SUCCESS = 'ESTIMATE/DOWNLOAD_ESTIMATE_SUCCESS';
export const DOWNLOAD_ESTIMATE_ERROR = 'ESTIMATE/DOWNLOAD_ESTIMATE_ERROR';
export const CHANGE_ESTIMATE_TYPE = 'ESTIMATE/CHANGE_ESTIMATE_TYPE';
export const CHANGE_ESTIMATE_TYPE_SUCCESS = 'ESTIMATE/CHANGE_ESTIMATE_TYPE_SUCCESS';
export const CHANGE_ESTIMATE_TYPE_ERROR = 'ESTIMATE/CHANGE_ESTIMATE_TYPE_ERROR';
export const DOWNLOAD_PRESS_TICKET = 'ESTIMATE/DOWNLOAD_PRESS_TICKET';
export const DOWNLOAD_PRESS_TICKET_SUCCESS = 'ESTIMATE/DOWNLOAD_PRESS_TICKET_SUCCESS';
export const DOWNLOAD_PRESS_TICKET_ERROR = 'ESTIMATE/DOWNLOAD_PRESS_TICKET_ERROR';
export const FETCH_MAIN_PART = 'ESTIMATE/FETCH_MAIN_PART';
export const FETCH_MAIN_PART_SUCCESS = 'ESTIMATE/FETCH_MAIN_PART_SUCCESS';
export const FETCH_MAIN_PART_ERROR = 'ESTIMATE/FETCH_MAIN_PART_ERROR';
export const FETCH_RFQ = 'ESTIMATE/FETCH_RFQ';
export const FETCH_RFQ_SUCCESS = 'ESTIMATE/FETCH_RFQ_SUCCESS';
export const FETCH_RFQ_ERROR = 'ESTIMATE/FETCH_RFQ_ERROR';
export const DELETE_ESTIMATE = 'ESTIMATE/DELETE_ESTIMATE';
export const DELETE_ESTIMATE_SUCCESS = 'ESTIMATE/DELETE_ESTIMATE_SUCCESS';
export const DELETE_ESTIMATE_ERROR = 'ESTIMATE/DELETE_ESTIMATE_ERROR';
export const DUPLICATE_PART = 'ESTIMATE/DUPLICATE_PART';
export const DUPLICATE_PART_SUCCESS = 'ESTIMATE/DUPLICATE_PART_SUCCESS';
export const DUPLICATE_PART_ERROR = 'ESTIMATE/DUPLICATE_PART_ERROR';
export const ADD_PAPER = 'ESTIMATE/ADD_PAPER';
export const ADD_PAPER_SUCCESS = 'ESTIMATE/ADD_PAPER_SUCCESS';
export const ADD_PAPER_ERROR = 'ESTIMATE/ADD_PAPER_ERROR';
export const CHECK_USE_PART = 'ESTIMATE/CHECK_USE_PART';
export const CHECK_USE_PART_SUCCESS = 'ESTIMATE/CHECK_USE_PART_SUCCESS';
export const CHECK_USE_PART_ERROR = 'ESTIMATE/CHECK_USE_PART_ERROR';
export const CHANGE_STATUS = 'ESTIMATE/CHANGE_STATUS';
export const CHANGE_STATUS_SUCCESS = 'ESTIMATE/CHANGE_STATUS_SUCCESS';
export const CHANGE_STATUS_ERROR = 'ESTIMATE/CHANGE_STATUS_ERROR';
export const EXPORT_MATRIX = 'ESTIMATE/EXPORT_MATRIX';
export const EXPORT_MATRIX_SUCCESS = 'ESTIMATE/EXPORT_MATRIX_SUCCESS';
export const EXPORT_MATRIX_ERROR = 'ESTIMATE/EXPORT_MATRIX_ERROR';
export const SET_FIELD = 'ESTIMATE/SET_FIELD';
export const SET_ID = 'ESTIMATE/SET_ID';
export const SET_HISTORY = 'ESTIMATE/SET_HISTORY';
export const SET_MAIL_SERVICES_TAB = 'ESTIMATE/SET_MAIL_SERVICES_TAB';
export const ADD_MAIL_SERVICES_ITEMS = 'ESTIMATE/ADD_MAIL_SERVICES_ITEMS';
export const DELETE_MAIL_SERVICES_ITEM = 'ESTIMATE/DELETE_MAIL_SERVICES_ITEM';
export const SET_DATA_PROCESSING_ITEM = 'ESTIMATE/SET_DATA_PROCESSING_ITEM';
export const SET_VARIABLE_PRINT_ITEM = 'ESTIMATE/SET_VARIABLE_PRINT_ITEM';
export const SET_MAIL_ACTIVE_TAB = 'ESTIMATE/SET_MAIL_ACTIVE_TAB';
export const SET_INKJET_ITEM = 'ESTIMATE/SET_INKJET_ITEM';
export const SET_INSERTING_ITEM = 'ESTIMATE/SET_INSERTING_ITEM';
export const SET_METERING_ITEM = 'ESTIMATE/SET_METERING_ITEM';
export const SET_HANDWORK_ITEM = 'ESTIMATE/SET_HANDWORK_ITEM';
export const SET_MAIL_SERVICES_QUANTITIES = 'ESTIMATE/SET_MAIL_SERVICES_QUANTITIES';
export const SET_ESTIMATE_TYPE = 'ESTIMATE/SET_ESTIMATE_TYPE';
export const SET_SHOW_BREAKDOWN = 'ESTIMATE/SET_SHOW_BREAKDOWN';
export const SET_ADDITIONAL_SERVICE = 'ESTIMATE/SET_ADDITIONAL_SERVICE';
export const ADD_ADDITIONAL_SERVICES = 'ESTIMATE/ADD_ADDITIONAL_SERVICES';
export const ADD_ADDITIONAL_SERVICE = 'ESTIMATE/ADD_ADDITIONAL_SERVICE';
export const UPDATE_ADDITIONAL_SERVICE = 'ESTIMATE/UPDATE_ADDITIONAL_SERVICE';
export const DELETE_ADDITIONAL_SERVICES_ITEM = 'ESTIMATE/DELETE_ADDITIONAL_SERVICES_ITEM';
export const RESET_RESULT = 'ESTIMATE/RESET_RESULT';
export const SET_IMPOSITION_TYPE = 'ESTIMATE/SET_IMPOSITION_TYPE';
export const SET_MULTI_RESULTS = 'ESTIMATE/SET_MULTI_RESULTS';
export const SET_DATA_CHANGED = 'ESTIMATE/SET_DATA_CHANGED';
export const SET_ESTIMATE_CHANGED = 'ESTIMATE/SET_ESTIMATE_CHANGED';
export const CHECK_ACCESSORY = 'ESTIMATE/CHECK_ACCESSORY';
export const RESET_ACCESSORIES = 'ESTIMATE/RESET_ACCESSORIES';
export const ADD_FILE = 'ESTIMATE/ADD_FILE';
export const REMOVE_FILE = 'ESTIMATE/REMOVE_FILE';
export const CLEAR_FILES = 'ESTIMATE/CLEAR_FILES';
export const SET_COMMENTS = 'ESTIMATE/SET_COMMENTS';
export const SET_NEW_ESTIMATE_TYPE = 'ESTIMATE/SET_NEW_ESTIMATE_TYPE';
export const SET_NEW_STATUS = 'ESTIMATE/SET_NEW_STATUS';
export const SET_DELIVERY_CHARGE_FIELD = 'ESTIMATE/SET_DELIVERY_CHARGE_FIELD';
export const SET_SHOW_DELIVERY_CHARGE = 'ESTIMATE/SET_SHOW_DELIVERY_CHARGE';
export const SET_PART_ESTIMATE_TYPE = 'ESTIMATE/SET_PART_ESTIMATE_TYPE';
export const SET_MAIN_PART_ID = 'ESTIMATE/SET_MAIN_PART_ID';
export const SET_MAIN_PART_TYPE = 'ESTIMATE/SET_MAIN_PART_TYPE';
export const SET_ID_FOR_DELETE = 'ESTIMATE/SET_ID_FOR_DELETE';
export const SET_PAPER_FIELD = 'ESTIMATE/SET_PAPER_FIELD';
export const ADD_OUTSIDE_SERVICE = 'ESTIMATE/ADD_OUTSIDE_SERVICE';
export const SET_OUTSIDE_SERVICE_FIELD = 'ESTIMATE/SET_OUTSIDE_SERVICE_FIELD';
export const DELETE_OUTSIDE_SERVICE = 'ESTIMATE/DELETE_OUTSIDE_SERVICE';
export const RESET_STATE = 'ESTIMATE/RESET_STATE';