import { createSelector } from 'reselect';

export const getComponentState = state => state.quoteRequests;

export const getFetchingRequests = createSelector(
    getComponentState,
    (state) => state.fetchingRequests
);
export const getRequests = createSelector(
    getComponentState,
    (state) => state.requests
);
export const getPagination = createSelector(
    getComponentState,
    (state) => state.pagination
);
export const getSort = createSelector(
    getComponentState,
    (state) => state.sort
);
export const getIdForDelete = createSelector(
    getComponentState,
    (state) => state.delete.id
);
export const getIsDeleting = createSelector(
    getComponentState,
    (state) => state.delete.isDeleting
);
export const getFilterFields = createSelector(
    getComponentState,
    (state) => state.filter.fields
);