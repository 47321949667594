import React, { Fragment } from 'react';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import numeral from 'numeral';
import { dateToTimezone } from './../../../utils';
import { validateForm } from './../CreditMemo.actions';
import TextareaAutosize from 'react-textarea-autosize';
import moment from 'moment-timezone';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';

const Form = React.memo((props) => {
    if (props.fetching || props.information.fetching) {
        return (
            <div className="credit-memo-form-container">
                <i className="fas fa-circle-notch fa-spin" /> Loading data...
            </div>
        );
    }

    const handleOnChange = (field, event) => {
        let value = null;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        } else {
            value = event.target.value;
        }
        props.setField(field, value);

        if (field === 'customer_id' && value) {
            props.fetchCustomerData(value);
        }
    };

    const setDateFieldHandler = (name, event) => {
        let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');
        props.setField(name, value);
    };

    const handleUpdateItem = (index, field, event) => {
        let value = null;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        } else {
            value = event.target.value;
        }

        props.setItemField(index, field, value);
    };

    const handleUpdatePrice = (index, field, creditMemoClassId, event) => {
        let value = parseFloat(event.target.value);
        props.setItemField(index, field, value);
    };

    const renderClassesOptions = (departmentId) => {
        return props.information.classes.map((item) => {
            if (parseInt(item.department_id) !== parseInt(departmentId)) {
                return null;
            }

            return <option key={item.id} value={item.id}>{item.name}</option>;
        });
    };

    const handleSortUp = (index, currentPosition) => {
        currentPosition = parseInt(currentPosition);
        props.changeSortPosition(index, currentPosition - 1, 1);
    };

    const handleSortDown = (index, currentPosition) => {
        currentPosition = parseInt(currentPosition);
        props.changeSortPosition(index, currentPosition + 1, 2);
    };

    const renderItems = () => {
        const itemsElements = [];
        for (let i = 0; i < props.creditMemo.items.length; i++) {
            let deleteIcon = null;
            if (props.user != null && parseInt(props.user.id) === 61) {
                deleteIcon = (
                    <Tooltip title="Remove Line" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="fas fa-trash-alt" onClick={props.removeLine.bind(this, i)} />
                    </Tooltip>
                );
            }

            let upArrow = <div className="sort-up"><i className="fas fa-sort-up" onClick={handleSortUp.bind(this, i, props.creditMemo.items[i].sort_position)} /></div>;
            let downArrow = <div className="sort-down"><i className="fas fa-sort-down" onClick={handleSortDown.bind(this, i, props.creditMemo.items[i].sort_position)} /></div>;
            if (i === 0) {
                upArrow = <div className="sort-up"></div>;
            }
            if (i === props.creditMemo.items.length - 1) {
                downArrow = null;
            }

            let quantity = props.creditMemo.items[i].quantity;
            let price = props.creditMemo.items[i].price;
            // let taxable = props.creditMemo.items[i].taxable;
            let amount = props.creditMemo.items[i].amount;

            itemsElements[i] = {
                departmentId: props.creditMemo.items[i].credit_memo_category_id,
                element: (
                    <tr key={i}>
                        {/* <td>{i + 1}</td> */}
                        <td>{props.creditMemo.items[i].sort_position}</td>
                        <td>
                            {upArrow}
                            {downArrow}
                        </td>
                        <td>
                            <select value={props.creditMemo.items[i].department_id} onChange={handleUpdateItem.bind(this, i, 'department_id')}>
                                <option value="" className="select-option">Select option</option>
                                {/* <option value="62">Description</option> */}
                                <option value="23">Art &amp; Graphics</option>
                                <option value="8">Data Processing</option>
                                <option value="9">Digital</option>
                                <option value="1">Press</option>
                                <option value="7">Bindery</option>
                                <option value="36">Mail Services</option>
                                <option value="26">Handwork</option>
                                <option value="27">Large Format</option>
                                <option value="15">Fulfillment</option>
                                <option value="68">ACM Costs</option>
                                <option value="59">Promotional</option>
                                <option value="17">Shipping</option>
                                {/* <option value="61">Notes</option> */}
                                <option value="60" className="postage-option">Postage Sales</option>
                                <option value="63" className="postage-option">Convenience Fee</option>
                                <option value="64" className="postage-option">Customer Permit</option>
                                {/* <option value="65" className="postage-option">Meter</option>
                                <option value="66" className="postage-option">Stamp</option> */}
                                <option value="56" className="postage-option">Prepaid Postage</option>
                                <option value="67" className="postage-option">ACM Paid Postage</option>
                            </select>
                        </td>
                        <td>
                            <select value={props.creditMemo.items[i].credit_memo_class_id} onChange={handleUpdateItem.bind(this, i, 'credit_memo_class_id')}>
                                <option value="">Select option</option>
                                {renderClassesOptions(props.creditMemo.items[i].department_id)}
                            </select>
                        </td>
                        <td>
                            <TextareaAutosize
                                className="textarea-description"
                                value={props.creditMemo.items[i].description}
                                onChange={handleUpdateItem.bind(this, i, 'description')}
                                maxRows={6}
                            />
                        </td>
                        <td>
                            <input
                                type="number"
                                value={quantity}
                                onChange={handleUpdateItem.bind(this, i, 'quantity')}
                            />
                        </td>
                        <td>
                            <input
                                type="number"
                                value={price}
                                onChange={handleUpdatePrice.bind(this, i, 'price', props.creditMemo.items[i].credit_memo_class_id)}
                            />
                        </td>
                        {/* <td className="text-center">
                            <input
                                type="checkbox"
                                value={taxable}
                                checked={taxable}
                                onChange={handleUpdateItem.bind(this, i, 'taxable')}
                            />
                        </td> */}
                        <td className="text-right">
                            <input
                                type="number"
                                value={amount}
                                onChange={handleUpdatePrice.bind(this, i, 'amount', props.creditMemo.items[i].credit_memo_class_id)}
                            />
                        </td>
                        <td className="actions">
                            {deleteIcon}
                        </td>
                    </tr>
                )
            };
        }

        let itemLines = [];
        for (let i = 0; i < itemsElements.length; i++) {
            itemLines.push(itemsElements[i].element);
        }

        return itemLines;
    };

    let subtotal = 0;
    let subtotalForTax = 0;
    let totalDiscount = 0;
    let totalTax = 0;
    let total = 0;
    for (const item of props.creditMemo.items) {
        if (parseInt(item.department_id) === 62) {
            continue;
        }

        if (item.quantity != null && item.quantity !== '' && item.price != null && item.price !== '') {
            subtotal += parseFloat(item.amount);

            if (item.taxable != null && item.taxable !== '' && item.taxable) {
                subtotalForTax += parseFloat(item.amount);
            }
        }
    }
    subtotal = subtotal.toFixed(5);
    subtotalForTax = subtotalForTax.toFixed(5);

    if (props.creditMemo.discount != null && props.creditMemo.discount !== '') {
        if (parseInt(props.creditMemo.discount_type) === 1) {
            totalDiscount = subtotal * parseFloat(props.creditMemo.discount) / 100;
        } else {
            totalDiscount = parseFloat(props.creditMemo.discount);
        }
    }

    totalTax = parseFloat(subtotalForTax) * 0.0825;

    total = parseFloat(numeral(subtotal).format('0.00')) +
        parseFloat(numeral(totalTax).format('0.00')) -
        parseFloat(numeral(totalDiscount).format('0.00'));

    let qBPushLabel = <Fragment>QuickBooks Push <i className="fas fa-file-upload" /></Fragment>;
    if (props.isPushing) {
        qBPushLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    let qBPushButton = null;
    if (props.creditMemo != null && props.creditMemo.id != null && props.user != null &&
        ([1, 2, 9].includes(parseInt(props.user.pqt_type)) || props.user.is_pqt_accounting || parseInt(props.user.id) === 61)) {
        qBPushButton = (
            <button className="action-button qb-button" onClick={props.showPushModal} disabled={props.isPushing}>
                {qBPushLabel}
            </button>
        );
    }

    let saveButtonLabel = <Fragment>Save <i className="fas fa-save" /></Fragment>;
    if (props.isSaving) {
        saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    const handleSaveCreditMemo = () => {
        props.saveCreditMemo(props.history);
    };

    let saveButton = null;
    if ([1, 2, 9].includes(parseInt(props.user.pqt_type)) || props.user.is_pqt_accounting || parseInt(props.user.id) === 61) {     // Approve
        saveButton = <button className="action-button save-button" onClick={handleSaveCreditMemo} disabled={props.isSaving}>{saveButtonLabel}</button>;
    }

    if (props.creditMemo != null && props.creditMemo.qb_id && parseInt(props.user.pqt_type) !== 1) {
        qBPushButton = null;
        saveButton = null;
    }

    const handlePreview = () => {
        if (!validateForm(props.creditMemo)) {
            return;
        }

        window.open(process.env.REACT_APP_API_URL + '/credit-memo/preview?id=' + props.creditMemo.id);
    };

    let previewButton = null;
    if (props.creditMemo != null && props.creditMemo.id != null) {
        previewButton = <button className="action-button" onClick={handlePreview}>Preview PDF <i className="fas fa-file-pdf" /></button>;
    }

    let qbNumber = 'N/A';
    if (props.creditMemo != null && props.creditMemo.qb_number != null) {
        qbNumber = props.creditMemo.qb_number;
    }

    const renderFieldValue = (field) => {
        let value = props.creditMemo[field];
        if (!props.creditMemo[field] || props.creditMemo[field] == null) {
            value = '';
        }

        return value
    };

    return (
        <div className="credit-memo-form-container">
            <div className="fields-section">
                <div className="customer-section">
                    <h4>Customer</h4>
                    <div className="field">
                        <div className="label">Customer:</div>
                        <select value={props.creditMemo.customer_id} onChange={handleOnChange.bind(this, 'customer_id')}>
                            <option value="">Select Customer</option>
                            {props.information.customers.map((customer) => {
                                return <option value={customer.id}>{customer.display_name}</option>
                            })}
                        </select>
                    </div>
                    <div className="field">
                        <div className="label">Customer Email:</div>
                        <input
                            type="text"
                            placeholder="Separate emails with commas"
                            value={renderFieldValue('customer_email')}
                            onChange={handleOnChange.bind(this, 'customer_email')}
                        />
                        {props.fetchingCustomerData && <i className="fas fa-circle-notch fa-spin loading-customer-field" />}
                    </div>
                    <div className="field">
                        <div className="label">Credit Memo Date:</div>
                        <DayPickerInput
                            formatDate={formatDate}
                            parseDate={parseDate}
                            placeholder=""
                            className="date-picker-field"
                            onDayChange={setDateFieldHandler.bind(this, 'date')}
                            value={props.creditMemo.date == null || props.creditMemo.date === '0000-00-00' ? '' : moment(props.creditMemo.date).format('MM/DD/YYYY')}
                        />
                    </div>
                    <div className="field">
                        <div className="label">QuickBooks Credit Memo #:</div>
                        {qbNumber}
                    </div>
                </div>
                <div className="billing-section">
                    <h4>
                        <div>Billing Address</div>
                    </h4>
                    <div className="fields">
                        <div className="field address-field">
                            <div className="label">Address:</div>
                            <input type="text" value={renderFieldValue('billing_address')} onChange={handleOnChange.bind(this, 'billing_address')} />
                            {props.fetchingCustomerData && <i className="fas fa-circle-notch fa-spin loading-customer-field" />}
                        </div>
                        <div className="field">
                            <div className="label">City:</div>
                            <input type="text" value={renderFieldValue('billing_city')} onChange={handleOnChange.bind(this, 'billing_city')} />
                            {props.fetchingCustomerData && <i className="fas fa-circle-notch fa-spin loading-customer-field" />}
                        </div>
                        <div className="field">
                            <div className="label">State:</div>
                            <input type="text" value={renderFieldValue('billing_state')} onChange={handleOnChange.bind(this, 'billing_state')} />
                            {props.fetchingCustomerData && <i className="fas fa-circle-notch fa-spin loading-customer-field" />}
                        </div>
                        <div className="field">
                            <div className="label">Zip:</div>
                            <input type="text" value={renderFieldValue('billing_zip')} onChange={handleOnChange.bind(this, 'billing_zip')} />
                            {props.fetchingCustomerData && <i className="fas fa-circle-notch fa-spin loading-customer-field" />}
                        </div>
                    </div>
                </div>
                <div className="shipping-section">
                    <div className="notes-container">
                        <h4>Notes</h4>
                        <div className="field">
                            <div className="label">Message displayed on credit memo:</div>
                            <textarea value={props.creditMemo.customer_memo} onChange={handleOnChange.bind(this, 'customer_memo')} />
                        </div>
                        <div className="field">
                            <div className="label">Message displayed on statement:</div>
                            <textarea value={props.creditMemo.private_note} onChange={handleOnChange.bind(this, 'private_note')} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="items-list">
                <h4>
                    <div className="title-column">
                        <div>Items</div>
                    </div>
                </h4>
                <table className="table table-sm table-hover">
                    <thead>
                        <tr>
                            <th className="number-th">#</th>
                            <th className="sort-th"></th>
                            <th className="department-th">Category</th>
                            <th className="task-th">Class</th>
                            <th className="description-th">Description</th>
                            <th className="quantity-th">Quantity</th>
                            <th className="price-th">Price</th>
                            {/* <th className="tax-th text-center">Taxable</th> */}
                            <th className="amount-th">Amount</th>
                            <th className="actions-th"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderItems()}
                    </tbody>
                </table>
                <div className="bottom-container">
                    <div className="items-buttons-container">
                        <div className="button-field-container">
                            <div className="button-container">
                                <button className="action-button" onClick={props.addLine}>Add Line <i className="fas fa-plus" /></button>
                            </div>
                        </div>
                        <div className="audit-history-container">
                            <div className="title">Audit Log:</div>
                            <div className="log">
                                {props.creditMemo.auditHistory.map((record, index) => (
                                    <div className="log-element" key={'log-element-' + index}>
                                        <b className={record.is_update ? 'pink-value' : ''}>{dateToTimezone(record.created_at, 'MMMM Do YYYY, h:mm a')}:</b> <span className="label" dangerouslySetInnerHTML={{ __html: record.label }}></span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="totals-container">
                        <div className="total-item total">
                            <div className="label">Total:</div>
                            <div className="value">{numeral(total).format('$0,0.00')}</div>
                        </div>
                        <div className="buttons-container">
                            {qBPushButton}
                            {previewButton}
                            {saveButton}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Form;
