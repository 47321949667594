import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Form from './Form';
import * as actions from './PqtPostageInvoice.actions';
import * as selectors from './PqtPostageInvoice.selectors';
import './PqtPostageInvoice.scss';
import PqtLayout from './../PqtLayout';
import ApproveModal from './ApproveModal';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import * as loginSelectors from './../Login/Login.selectors';
import { Redirect } from 'react-router-dom';
import * as dialog from '../common/dialog';

const PqtPostageInvoice = (props) => {
    /* State to props */
    const fetching = useSelector(selectors.getFetching);
    const invoice = useSelector(selectors.getInvoice, stringifyEqualityCheck);
    const information = useSelector(selectors.getInformation, stringifyEqualityCheck);
    const fetchingCustomerData = useSelector(selectors.getFetchingCustomerData);
    const isSaving = useSelector(selectors.getIsSaving);
    const jobDueDate = useSelector(selectors.getJobDueDate);
    const fetchingPostageEscrow = useSelector(selectors.getFetchingPostageEscrow);
    const postageEscrow = useSelector(selectors.getPostageEscrow);
    const isApproving = useSelector(selectors.getIsApproving);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const fetchInvoice = useCallback((id) => dispatch(actions.fetchInvoice(id)), [dispatch]);
    const fetchInformation = useCallback(() => dispatch(actions.fetchInformation()), [dispatch]);
    const setField = useCallback((field, value) => dispatch(actions.setField(field, value)), [dispatch]);
    const fetchCustomerData = useCallback((id) => dispatch(actions.fetchCustomerData(id)), [dispatch]);
    const saveInvoice = useCallback(() => dispatch(actions.saveInvoice()), [dispatch]);
    const setItemField = useCallback((index, field, value) => dispatch(actions.setItemField(index, field, value)), [dispatch]);
    const changeSortPosition = useCallback((index, value, type) => dispatch(actions.changeSortPosition(index, value, type)), [dispatch]);
    const approveInvoice = useCallback(() => dispatch(actions.approveInvoice()), [dispatch]);
    const addLine = useCallback(() => dispatch(actions.addLine()), [dispatch]);
    const removeLine = useCallback((index) => dispatch(actions.removeLine(index)), [dispatch]);
    const showApproveModal = useCallback(() => dispatch(dialog.actions.showDialog('approve-modal')), [dispatch]);
    const hideApproveModal = useCallback(() => dispatch(dialog.actions.hideDialog('approve-modal')), [dispatch]);
    
    /* componentDidMount */
    useEffect(() => {
        fetchInvoice(props.match.params.id);
        fetchInformation();
    }, [dispatch, props.match.params.id, fetchInformation, fetchInvoice]);

    if (user != null && ![1, 2, 3, 4, 5, 9].includes(parseInt(user.pqt_type)) && !user.is_pqt_accounting) {
        return <Redirect to="/dashboard" />;
    }

    if (user != null && [76].includes(parseInt(user.id))) {
        return <Redirect to="/dashboard" />;
    }

    let invoiceNumnber = '';
    if (invoice && invoice != null) {
        invoiceNumnber = 'PP-' + invoice.stagecoach_number;
    }

    let paidTitle = '';
    if (invoice && invoice.is_postage_paid) {
        paidTitle = ' (Paid)';
    }

    return (
        <PqtLayout title={'Postage Invoice #' + invoiceNumnber + paidTitle} history={props.history}>
            <Helmet><title>Postage Invoice #{invoiceNumnber}{paidTitle} - Stagecoach</title></Helmet>
            <div className="pqt-postage-invoice-container">
                <Form
                    invoice={invoice}
                    fetching={fetching}
                    information={information}
                    fetchingCustomerData={fetchingCustomerData}
                    isSaving={isSaving}
                    user={user}
                    jobDueDate={jobDueDate}
                    fetchingPostageEscrow={fetchingPostageEscrow}
                    postageEscrow={postageEscrow}
                    isApproving={isApproving}
                    history={props.history}
                    changeSortPosition={changeSortPosition}
                    setField={setField}
                    fetchCustomerData={fetchCustomerData}
                    saveInvoice={saveInvoice}
                    setItemField={setItemField}
                    showApproveModal={showApproveModal}
                    addLine={addLine}
                    removeLine={removeLine}
                />
            </div>
            <ApproveModal
                isApproving={isApproving}
                approveInvoice={approveInvoice}
                hideApproveModal={hideApproveModal}
            />
        </PqtLayout>
    );
};

export default PqtPostageInvoice;
