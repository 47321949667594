import React from 'react';
import { Dialog } from './../../common/dialog';
import { empty } from './../../../utils';

const FormModal = React.memo((props) => {
    const hideHandler = () => {
        props.hideFormModal();
        props.clearForm();
    };

    const handleSetField = (name, event) => {
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked;
        }

        props.setField(name, value);
    };

    const getValue = (value) => {
        if (empty(value)) {
            return '';
        }

        return value;
    };

    let label = 'Save';
    if (props.savingCompensation) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    let content = <div className="compensation-form-container text-center"><i className="fas fa-circle-notch fa-spin" /> Loading Data...</div>
    if (!props.fetchingCompensation) {
        content = (
            <div className="compensation-form-container">
                <h1>Compensation</h1>
                <div className="fields">
                    <div className="field">
                        <div className="label">Person <span className="required">*</span>:</div>
                        <select value={getValue(props.fields.user_id)} onChange={handleSetField.bind(this, 'user_id')}>
                            <option value="">Select option</option>
                            {props.persons.map((person) => {
                                return <option value={person.id}>{person.name}</option>
                            })}
                        </select>
                    </div>
                    <div className="field">
                        <div className="label">0-90 <span className="required">*</span>:</div>
                        <input type="text" value={getValue(props.fields.zero_ninety)} onChange={handleSetField.bind(this, 'zero_ninety')} />
                    </div>
                    <div className="field">
                        <div className="label">91-120 <span className="required">*</span>:</div>
                        <input type="text" value={getValue(props.fields.ninetyone_onetwenty)} onChange={handleSetField.bind(this, 'ninetyone_onetwenty')} />
                    </div>
                    <div className="field">
                        <div className="label">Cycle:</div>
                        <select value={getValue(props.fields.cycle)} onChange={handleSetField.bind(this, 'cycle')}>
                            <option value="">Select option</option>
                            <option value="1">Semi Monthly</option>
                            <option value="2">Weekly</option>
                        </select>
                    </div>
                    <div className="field">
                        <div className="label">Type <span className="required">*</span>:</div>
                        <select value={getValue(props.fields.payroll)} onChange={handleSetField.bind(this, 'payroll')}>
                            <option value="">Select option</option>
                            <option value="1">Payroll</option>
                            <option value="0">1099</option>
                        </select>
                    </div>
                    <div className="field">
                        <div className="label">Compensation Type <span className="required">*</span>:</div>
                        <select value={getValue(props.fields.draw)} onChange={handleSetField.bind(this, 'draw')}>
                            <option value="">Select option</option>
                            <option value="1">Draw</option>
                            <option value="0">No Draw</option>
                        </select>
                    </div>
                    <div className="field">
                        <div className="label">Amount:</div>
                        <input type="text" value={getValue(props.fields.draw_amount)} onChange={handleSetField.bind(this, 'draw_amount')} />
                    </div>
                    <div className="field">
                        <div className="label">Total:</div>
                        <input type="text" value={getValue(props.fields.draw_total)} onChange={handleSetField.bind(this, 'draw_total')} />
                    </div>
                    <div className="field">
                        <div className="label">Notes:</div>
                        <input type="text" value={getValue(props.fields.notes)} onChange={handleSetField.bind(this, 'notes')} />
                    </div>
                </div>
            </div>
        );
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Cancel</button>,
        <button key="upload-button" className="btn btn-light btn-sm action-button" onClick={props.saveCompensation} disabled={props.savingCompensation}>{label}</button>
    ];
    return (
        <Dialog
            name="compensation-form-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="compensation-form-dashboard-dialog"
        >
            {content}
        </Dialog>
    );
});

export default FormModal;
