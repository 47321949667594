import React from 'react';
import { Dialog } from './../../common/dialog';

const FormModal = React.memo((props) => {
    const hideHandler = () => {
        props.hideFormModal();
    };

    let label = 'Save';
    if (props.isSaving) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    const handleOnChange = (field, event) => {
        let value = null;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        } else {
            value = event.target.value;
        }
        props.setField(field, value);
    };

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Cancel</button>,
        <button key="report-button" className="btn btn-light btn-sm action-button" onClick={props.saveSubcategory} disabled={props.isSaving}>{label}</button>
    ];
    return (
        <Dialog
            name="form-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="form-dialog"
        >
            <div className="form-container">
                <h1>Subcategory Information</h1>
                <div className="fields">
                    <div className="field">
                        <div><b>Name <span className="required">*</span>:</b></div>
                        <input type="text" value={props.subcategory.name} onChange={handleOnChange.bind(this, 'name')} />
                    </div>
                    <div className="field mt-2">
                        <div><b>Active <span className="required">*</span>:</b></div>
                        <select value={props.subcategory.is_active} onChange={handleOnChange.bind(this, 'is_active')}>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </div>
                </div>
            </div>
        </Dialog >
    );
});

export default FormModal;
