import axios from 'axios';
import * as types from './PqtPostageInvoice.types';
import { validateAuthInResponse, sendWsMessage, showNotification, empty } from './../../utils';
import * as selectors from './PqtPostageInvoice.selectors';
import * as loginSelectors from './../Login/Login.selectors';
import * as dialog from '../common/dialog';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchInvoice = (id) => (dispatch) => {
    dispatch({ type: types.FETCH_INVOICE, payload: null });
    axios.get(apiUrl + '/invoices/' + id + '?expand=items,auditHistory,customerAddress',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_INVOICE_SUCCESS, payload: response.data });
            dispatch(fetchPostageEscrow());
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_INVOICE_ERROR));
        });
}

export const fetchInformation = () => (dispatch) => {
    dispatch({ type: types.FETCH_INFORMATION, payload: null });
    axios.get(apiUrl + '/invoice/data',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_INFORMATION_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_INFORMATION_ERROR));
        });
}

export const fetchCustomerData = (id) => (dispatch) => {
    dispatch({ type: types.FETCH_CUSTOMER_DATA, payload: null });
    axios.get(apiUrl + '/invoice/customer-data?id=' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_CUSTOMER_DATA_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_CUSTOMER_DATA_ERROR));
        });
}

export const saveInvoice = () => (dispatch, getState) => {
    const invoice = selectors.getInvoice(getState());
    const user = loginSelectors.getUser(getState());

    if (!validateForm(invoice, true, user)) {
        return;
    }

    dispatch({ type: types.SAVE_INVOICE, payload: null });
    axios.put(apiUrl + '/invoices/' + invoice.id, invoice,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.SAVE_INVOICE_SUCCESS, payload: response.data });
            dispatch(sendWsMessage({ id: invoice.id, type: 'invoice_updated' }));
            showNotification('Invoice Saved', 'The invoice has been saved successfully', 'success');
            dispatch(fetchInvoice(invoice.id));
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.SAVE_INVOICE_ERROR));
        });
}

export const fetchPostageEscrow = () => (dispatch, getState) => {
    const invoice = selectors.getInvoice(getState());
    dispatch({ type: types.FETCH_POSTAGE_ESCROW, payload: null });
    axios.post(apiUrl + '/pqt-tracking/get-postage-escrow', { customerId: invoice.customer_id, useSc: 0 },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_POSTAGE_ESCROW_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_POSTAGE_ESCROW_ERROR));
        });
}

export const approveInvoice = () => (dispatch, getState) => {
    const invoice = selectors.getInvoice(getState());
    const user = loginSelectors.getUser(getState());

    if (!validateForm(invoice, true, user)) {
        return;
    }

    dispatch({ type: types.APPROVE_INVOICE, payload: null });
    axios.post(apiUrl + '/invoice/approve', { id: invoice.id },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.APPROVE_INVOICE_SUCCESS, payload: response.data });
            dispatch(sendWsMessage({ id: invoice.id, type: 'invoice_updated' }));
            showNotification('Invoice Saved', 'The invoice has been saved successfully', 'success');
            dispatch(fetchInvoice(invoice.id));
            dispatch(dialog.actions.hideDialog('approve-modal'));
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.APPROVE_INVOICE_ERROR));
        });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null
    });
}

export const setField = (field, value) => (dispatch) => {
    dispatch({
        type: types.SET_FIELD,
        payload: { field, value }
    });
}

export const setItemField = (index, field, value) => (dispatch, getState) => {
    const user = loginSelectors.getUser(getState());
    dispatch({
        type: types.SET_ITEM_FIELD,
        payload: { index, field, value, user }
    });
}

export const validateForm = (values, validateInvoiceDate = true, user) => {
    if (values.customer_id == null || values.customer_id === '') {
        showNotification('Complete Information', 'Select a Customer', 'info');
        return false;
    }
    if (values.due_date == null || values.due_date === '') {
        showNotification('Complete Information', 'Enter the Due Date', 'info');
        return false;
    }
    if (values.billing_name == null || values.billing_name === '') {
        showNotification('Complete Information', 'Enter the Billing name', 'info');
        return false;
    }
    if (values.billing_address == null || values.billing_address === '') {
        showNotification('Complete Information', 'Enter the Billing Address', 'info');
        return false;
    }
    if (values.billing_city == null || values.billing_city === '') {
        showNotification('Complete Information', 'Enter the Billing City', 'info');
        return false;
    }
    if (values.billing_state == null || values.billing_state === '') {
        showNotification('Complete Information', 'Enter the Billing State', 'info');
        return false;
    }
    if (values.billing_zip == null || values.billing_zip === '') {
        showNotification('Complete Information', 'Enter the Billing Zip', 'info');
        return false;
    }
    if (values.shipping_name == null || values.shipping_name === '') {
        showNotification('Complete Information', 'Enter the Shiping name', 'info');
        return false;
    }
    if (values.shipping_address == null || values.shipping_address === '') {
        showNotification('Complete Information', 'Enter the Shiping Address', 'info');
        return false;
    }
    if (values.shipping_city == null || values.shipping_city === '') {
        showNotification('Complete Information', 'Enter the Shiping City', 'info');
        return false;
    }
    if (values.shipping_state == null || values.shipping_state === '') {
        showNotification('Complete Information', 'Enter the Shiping State', 'info');
        return false;
    }
    if (values.shipping_zip == null || values.shipping_zip === '') {
        showNotification('Complete Information', 'Enter the Shiping Zip', 'info');
        return false;
    }
    if (values.sales_rep_id == null || values.sales_rep_id === '') {
        showNotification('Complete Information', 'Select a Sales Rep', 'info');
        return false;
    }
    if (values.csr_id == null || values.csr_id === '') {
        showNotification('Complete Information', 'Select a CSR', 'info');
        return false;
    }
    if (validateInvoiceDate && parseInt(values.status) >= 3 && (values.invoice_date == null || values.invoice_date === '')) {
        showNotification('Complete Information', 'Enter the invoice date', 'info');
        return false;
    }

    const isPreliminary = parseInt(values.status) === 1;
    for (const item of values.items) {
        const departmentId = parseInt(item.department_id);

        // Prepaid Postage (56), Postage Sales (60), Shipping (17), Convenience Fee (17), Customer Permit (64), Meter (65), Stamp (66), ACM Paid Postage (67)
        if (isPreliminary && ([56, 60, 17, 63, 64, 65, 66, 67, 70].includes(departmentId))) {
            continue;
        }

        if (departmentId === 62) {      // Description (62)
            if (
                item.department_id === '' || item.department_id == null ||
                item.invoice_class_id === '' || item.invoice_class_id == null ||
                item.description === '' || item.description == null
            ) {
                showNotification('Complete Information', 'Enter the information for all the Items', 'info');
                return false;
            }
        } else {
            if (
                item.department_id === '' || item.department_id == null ||
                item.invoice_class_id === '' || item.invoice_class_id == null ||
                item.description === '' || item.description == null ||
                item.quantity === '' || item.quantity == null || isNaN(item.quantity) ||
                item.price === '' || item.price == null || isNaN(item.price) ||
                item.unit === '' || item.unit == null ||
                item.amount === '' || item.amount == null || isNaN(item.amount)
            ) {
                showNotification('Complete Information', 'Enter the information for all the Items', 'info');
                return false;
            }
        }

        if (user.id !== 61 && !empty(item['minimum_price'])) {
            const minimumAmount = parseFloat(item['minimum_amount']);

            let amountFloat = 0;
            if (!isNaN(item.amount) && !empty(item.amount)) {
                amountFloat = parseFloat(item.amount);
            }

            if (amountFloat < minimumAmount) {
                showNotification('Complete Information', 'One or more items are below the minimum price', 'info');
                return false;
            }
        }
    }

    return true;
}

export const setCustomerEmail = (value) => (dispatch) => {
    dispatch({
        type: types.SET_CUSTOMER_EMAIL,
        payload: value
    });
}

export const addLine = () => (dispatch) => {
    dispatch({
        type: types.ADD_LINE,
        payload: null
    });
}

export const removeLine = (index) => (dispatch) => {
    dispatch({
        type: types.REMOVE_LINE,
        payload: index
    });
}

export const changeSortPosition = (index, value, type) => (dispatch) => {
    dispatch({
        type: types.CHANGE_SORT_POSITION,
        payload: { index, value, type }
    });
}
