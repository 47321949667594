import * as types from "./PostageCalculator.types";
import * as selectors from "./PostageCalculator.selectors";
import axios from "axios";
import { validateAuthInResponse, showNotification, empty } from "./../../utils";

const apiUrl = process.env.REACT_APP_API_URL;

export const getPrice = () => (dispatch, getState) => {
    const fields = selectors.getFields(getState());

    // if (empty(fields.product_type)) {
    //     return showNotification(
    //         "Complete Information",
    //         "Select the Product Type",
    //         "info"
    //     );
    // }
    if (empty(fields.quantity)) {
        return showNotification(
            "Complete Information",
            "Enter the Quantity",
            "info"
        );
    }
    if (empty(fields.origin_zip)) {
        return showNotification(
            "Complete Information",
            "Enter the Origin Zip",
            "info"
        );
    }
    if (empty(fields.destination_zip)) {
        return showNotification(
            "Complete Information",
            "Enter the Destination Zip",
            "info"
        );
    }
    if (empty(fields.weight)) {
        return showNotification(
            "Complete Information",
            "Enter the Weight",
            "info"
        );
    }
    if (empty(fields.length)) {
        return showNotification(
            "Complete Information",
            "Enter the Length",
            "info"
        );
    }
    if (empty(fields.width)) {
        return showNotification(
            "Complete Information",
            "Enter the Width",
            "info"
        );
    }
    if (empty(fields.height)) {
        return showNotification(
            "Complete Information",
            "Enter the Height",
            "info"
        );
    }
    // if (empty(fields.mail_class)) {
    //     return showNotification(
    //         "Complete Information",
    //         "Select the Mail Class",
    //         "info"
    //     );
    // }
    // if (empty(fields.price_type)) {
    //     return showNotification(
    //         "Complete Information",
    //         "Select the Price Type",
    //         "info"
    //     );
    // }

    dispatch({ type: types.GET_PRICE, payload: null });
    axios
        .post(apiUrl + "/invoice/get-postage-price", fields, {})
        .then((response) => {
            if (response.data.success) {
                dispatch({
                    type: types.GET_PRICE_SUCCESS,
                    payload: response.data.result,
                });
            } else {
                dispatch({ type: types.GET_PRICE_SUCCESS, payload: null });
                showNotification(
                    "Wrong Configuration",
                    response.data.result.error.errors[0].detail,
                    "warning"
                );
            }
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.GET_PRICE_ERROR));
        });
};

export const setField = (field, value) => (dispatch, getState) => {
    dispatch({
        type: types.SET_FIELD,
        payload: { field, value },
    });
};

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null,
    });
};
