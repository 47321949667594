import React from 'react';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const FormPopover = React.memo((props) => {
    let name = '';
    let description = '';
    for (let i = 0; i < props.information.helpItems.length; i++) {
        if (props.information.helpItems[i].code_ref === props.code) {
            name = props.information.helpItems[i].name;
            description = props.information.helpItems[i].description;
            break;
        }
    }

    const popover = (
        <Popover>
            <Popover.Title as="h3">{name}</Popover.Title>
            <Popover.Content>
                <div className="mb-2">{description}</div>
            </Popover.Content>
        </Popover>
    );

    return (
        <OverlayTrigger trigger="hover" placement="top" overlay={popover}>
            <i className="fas fa-question-circle" />
        </OverlayTrigger>
    );
});

export default FormPopover;
