import React, { Fragment } from 'react';
import { Dialog } from './../../common/dialog';

const FormModal = React.memo((props) => {
    const hideHandler = () => {
        props.hideFormModal();
        props.resetFields();
    };

    const saveElementHandler = () => {
        props.saveElement();
    };

    let label = 'Save';
    if (props.savingElement) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    const handleSetField = (field, event) => {
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        }

        props.setField(field, value);
    };

    const renderTextField = (label, field, required = true) => {
        let requiredSpan = null;
        if (required) {
            requiredSpan = <span className="required">*</span>;
        }
        return (
            <div className="field">
                <div className="label">{label} {requiredSpan}</div>
                <input type="text" value={props.fields[field]} onChange={handleSetField.bind(this, field)} />
            </div>
        );
    };
    
    const renderNumberField = (label, field, required = true) => {
        let requiredSpan = null;
        if (required) {
            requiredSpan = <span className="required">*</span>;
        }
        return (
            <div className="field">
                <div className="label">{label} {requiredSpan}</div>
                <input type="number" value={props.fields[field]} onChange={handleSetField.bind(this, field)} />
            </div>
        );
    };
    
    const renderSelectField = (label, field, options, required = true) => {
        let requiredSpan = null;
        if (required) {
            requiredSpan = <span className="required">*</span>;
        }
        let optionsElements = [];
        for (let i = 0; i < options.length; i++) {
            optionsElements.push(<option value={options[i].value}>{options[i].label}</option>);
        }
        return (
            <div className="field">
                <div className="label">{label} {requiredSpan}</div>
                <select value={props.fields[field]} onChange={handleSetField.bind(this, field)}>{optionsElements}</select>
            </div>
        );
    };

    let content = <div className="loading-container"><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
    if (!props.fetchingElement) {
        content = (
            <Fragment>
                <div className="title">Press Information</div>
                <div className="fields">
                    {renderTextField('Name', 'name')}
                    {renderNumberField('Labor Rate', 'labor_rate')}
                    {renderNumberField('Labor Markup', 'labor_markup_rate')}
                    {renderNumberField('Setup Cost', 'setup_cost')}
                    {renderNumberField('Plate Change Cost', 'plate_change_cost')}
                    {renderNumberField('Plate Cost', 'plate_cost')}

                    {renderTextField('Ink Units', 'ink_units')}
                    {renderNumberField('Gripper Margin', 'gripper_margin')}
                    {renderNumberField('Color Bar Margin', 'color_bar_margin')}
                    {renderNumberField('Setup Time (hrs)', 'setup_time')}
                    {renderNumberField('Left/Right/Tail Trim', 'left_right_tail_trim')}
                    {renderSelectField('Perfecting', 'is_perfecting', [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }])}
                    {renderSelectField('Active', 'is_active', [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }])}

                    {renderNumberField('Max. Paper Width', 'max_paper_width')}
                    {renderNumberField('Max. Paper Length', 'max_paper_length')}
                    {renderNumberField('Min. Paper Width', 'min_paper_width')}
                    {renderNumberField('Min. Paper Length', 'min_paper_length')}

                    {renderNumberField('Flat Makeready (sheets)', 'makeready_spoilage_flat')}
                    {renderNumberField('Flat Running (%)', 'running_spoilage_flat')}
                    {renderNumberField('W&T Makeready (sheets)', 'makeready_spoilage_wt')}
                    {renderNumberField('W&T Running (%)', 'running_spoilage_wt')}
                    {renderNumberField('SW Makeready (sheets)', 'makeready_spoilage_sw')}
                    {renderNumberField('SW Running (%)', 'running_spoilage_sw')}
                </div>
            </Fragment>
        );
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Cancel</button>,
        <button key="delete-button" className="btn btn-light btn-sm action-button" onClick={saveElementHandler} disabled={props.savingElement}>{label}</button>
    ];
    return (
        <Dialog
            name="pqn-press-form-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="pqn-press-form-dialog"
        >
            <div className="pqn-press-form-container">
                {content}
            </div>
        </Dialog >
    );
});

export default FormModal;
