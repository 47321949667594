import React from 'react';
import { Dialog } from './../../common/dialog';
import numeral from 'numeral';
import { empty } from './../../../utils';
import { isDigital } from './../Estimate.actions';

const MatrixModal = React.memo((props) => {
    if (props.multiResults == null || props.multiResults.length === 0) {
        return null;
    }

    const renderResults = () => {
        return props.multiResults.map((multiResult) => {
            let result = null;
            if (parseInt(props.estimateType) === 2) {   // Envelopes
                result = multiResult;
            } else {
                result = multiResult.results[0];
            }

            let subtotal = 0;
            let markupPrice = 0;
            let commission = 0;
            let outsidePrice = 0;
            let tax = 0;
            let total = 0;
            if (parseInt(props.estimateType) === 1 || parseInt(props.estimateType) === 7) {     // Sheetfed or Pocket Folder
                /*************************** Subtotal **************************/
                if (isDigital(props.estimate.output_device)) {
                    subtotal += result.digital_machine_information.total_cost + result.paper_information.total_cost + result.cutting_information.total_cost;
                } else {
                    subtotal += result.press_information.total_running_cost + result.press_information.total_setup_cost + result.paper_information.total_cost +
                        + result.press_information.total_plates_cost + result.ink_information.total_cost + result.cutting_information.total_cost;
                }

                for (let i = 0; i < props.selectedAdditionalServices.length; i++) {
                    const item = props.selectedAdditionalServices[i];

                    if (item.id === 1) {       // Folding
                        subtotal += result.folding_information.total_cost;
                    } else if (item.id === 2) {    // Scoring
                        subtotal += result.scoring_information.total_cost;
                    } else if (item.id === 3) {    // Drilling
                        subtotal += result.drilling_information.total_cost;
                    } else if (item.id === 4 && result.shrink_wrapping_information) {    // Shrink-Wrapping
                        subtotal += result.shrink_wrapping_information.total_cost;
                    } else if (item.id === 5) {    // Padding
                        subtotal += result.padding_information.total_cost;
                    } else if (item.id === 6) {    // Die-Cutting
                        subtotal += result.die_cutter_information.total_cost;
                    } else if (item.id === 21 && result.boxing_information) {    // Boxing
                        subtotal += result.boxing_information.total_cost;
                    }
                }
                subtotal = parseFloat(subtotal.toFixed(2));
            } else if (parseInt(props.estimateType) === 2) {     // Envelopes
                subtotal = result.envelope_press.press_cost + result.envelope_press.pre_press_cost + result.envelope_paper.cost;

                for (let i = 0; i < props.selectedAdditionalServices.length; i++) {
                    const item = props.selectedAdditionalServices[i];

                    if (item.id === 4 && result.shrink_wrapping_information) {    // Shrink-Wrapping
                        subtotal += result.shrink_wrapping_information.total_cost;
                    } else if (item.id === 21 && result.boxing_information) {    // Boxing
                        subtotal += result.boxing_information.total_cost;
                    }
                }
                subtotal = parseFloat(subtotal.toFixed(2));
            } else if (parseInt(props.estimateType) === 3) {     // Plus-Cover
                let coverResult = null;
                if (multiResult.cover_results.length > 0) {
                    coverResult = multiResult.cover_results[0];
                }

                if (isDigital(props.estimate.cover_output_device)) {
                    subtotal += coverResult.digital_machine_information.total_cost;
                } else {
                    subtotal += coverResult.press_information.total_running_cost + coverResult.press_information.total_setup_cost;
                    subtotal += coverResult.press_information.total_plates_cost;
                    subtotal += coverResult.ink_information.total_cost;
                }

                subtotal += coverResult.cutting_information.total_cost + coverResult.folding_information.total_cost
                    + coverResult.paper_information.total_cost;

                for (let i = 0; i < result.impositions_information.length; i++) {
                    const imposition = result.impositions_information[i];

                    if (isDigital(props.estimate.output_device)) {
                        subtotal += imposition.digital_machine_information.total_cost;
                    } else {
                        subtotal += imposition.press_information.total_running_cost + imposition.press_information.total_setup_cost;
                        subtotal += imposition.press_information.total_plates_cost;
                        subtotal += imposition.ink_information.total_cost;
                    }

                    subtotal += imposition.paper_information.total_cost;
                    subtotal += imposition.cutting_information.total_cost;
                    subtotal += imposition.folding_information.total_cost;
                }

                subtotal += result.binding_information.total_cost;

                for (let i = 0; i < props.selectedAdditionalServices.length; i++) {
                    const item = props.selectedAdditionalServices[i];

                    if (item.id === 4 && result.shrink_wrapping_information) {    // Shrink-Wrapping
                        subtotal += result.shrink_wrapping_information.total_cost;
                    } else if (item.id === 21 && result.boxing_information) {    // Boxing
                        subtotal += result.boxing_information.total_cost;
                    }
                }

                subtotal = parseFloat(subtotal.toFixed(2));
            } else if (parseInt(props.estimateType) === 4) {     // Self-Cover
                for (let i = 0; i < result.impositions_information.length; i++) {
                    const imposition = result.impositions_information[i];

                    if (isDigital(props.estimate.output_device)) {
                        subtotal += imposition.digital_machine_information.total_cost;
                    } else {
                        subtotal += imposition.press_information.total_running_cost + imposition.press_information.total_setup_cost;
                        subtotal += imposition.press_information.total_plates_cost;
                        subtotal += imposition.ink_information.total_cost;
                    }

                    subtotal += imposition.paper_information.total_cost;
                    subtotal += imposition.cutting_information.total_cost;
                    subtotal += imposition.folding_information.total_cost;
                }

                subtotal += result.binding_information.total_cost;

                for (let i = 0; i < props.selectedAdditionalServices.length; i++) {
                    const item = props.selectedAdditionalServices[i];

                    if (item.id === 4 && result.shrink_wrapping_information) {    // Shrink-Wrapping
                        subtotal += result.shrink_wrapping_information.total_cost;
                    } else if (item.id === 21 && result.boxing_information) {    // Boxing
                        subtotal += result.boxing_information.total_cost;
                    }
                }

                subtotal = parseFloat(subtotal.toFixed(2));
            } else if (parseInt(props.estimateType) === 5) {     // Digital
                subtotal = result.digital_machine_information.total_cost + result.digital_machine_information.total_setup_cost
                    + result.paper_information.total_cost + result.cutting_information.total_cost;

                for (let i = 0; i < props.selectedAdditionalServices.length; i++) {
                    const item = props.selectedAdditionalServices[i];

                    if (item.id === 1) {       // Folding
                        subtotal += result.folding_information.total_cost;
                    } else if (item.id === 2) {    // Scoring
                        subtotal += result.scoring_information.total_cost;
                    } else if (item.id === 3) {    // Drilling
                        subtotal += result.drilling_information.total_cost;
                    } else if (item.id === 5) {    // Padding
                        subtotal += result.padding_information.total_cost;
                    } else if (item.id === 6) {    // Die-Cutting
                        subtotal += result.die_cutter_information.total_cost;
                    } else if (item.id === 4 && result.shrink_wrapping_information) {    // Shrink-Wrapping
                        subtotal += result.shrink_wrapping_information.total_cost;
                    } else if (item.id === 21 && result.boxing_information) {    // Boxing
                        subtotal += result.boxing_information.total_cost;
                    }
                }
                subtotal = parseFloat(subtotal.toFixed(2));
            } else if (parseInt(props.estimateType) === 6) {     // Large Format
                subtotal = result.large_format_machine_information.total_cost + result.substrate_information.total_cost;

                if (result.accessories_information.accessories.length > 0) {
                    for (let i = 0; i < props.selectedAdditionalServices.length; i++) {
                        const item = props.selectedAdditionalServices[i];

                        for (let j = 0; j < result.accessories_information.accessories.length; j++) {
                            const accessory = result.accessories_information.accessories[j];
                            if (item.id === 11 && parseInt(accessory.id) === 3) {     // Grommets
                                subtotal += accessory.total_cost;
                            } else if (item.id === 12 && parseInt(accessory.id) === 17) {      // H-Stakes
                                subtotal += accessory.total_cost;
                            }
                        }
                    }
                }

                subtotal = parseFloat(subtotal.toFixed(2));
            }

            /*************************** Outside **************************/
            if (props.estimate.outside_cost) {     // Outside Cost
                outsidePrice = parseFloat(props.estimate.outside_cost);
            }

            if (props.estimate.outside_markup) {     // Outside Markup
                outsidePrice *= 1 + parseFloat(props.estimate.outside_markup) / 100;
            }

            if (props.estimate.outside_commission) {
                outsidePrice *= 1 + parseFloat(props.estimate.outside_commission) / 100;
            }

            if (props.estimate.full_outside_job) {
                if (props.showDeliveryCharge) {
                    subtotal = parseFloat(props.deliveryCharge.total);
                }

                return (
                    <tr>
                        <td><b>{numeral(multiResult.request_params.quantity).format('0,0')}</b></td>
                        <td>{numeral(subtotal).format('$0,0.00')}</td>
                        <td>{numeral(0).format('$0,0.00')}</td>
                        <td>{numeral(0).format('$0,0.00')}</td>
                        <td>{numeral(outsidePrice).format('$0,0.00')}</td>
                        <td>{numeral(0).format('$0,0.00')}</td>
                        <td><b>{numeral(subtotal + outsidePrice).format('$0,0.00')}</b></td>
                    </tr>
                );
            }

            if (props.showDeliveryCharge) {
                subtotal += parseFloat(props.deliveryCharge.total);
            }

            /*************************** Markup **************************/
            let markup = 0;
            if (!empty(props.estimate.markup)) {
                markup = parseFloat(props.estimate.markup) / 100;
            }
            markupPrice = parseFloat((subtotal * markup).toFixed(2));

            /************************** Commission ************************/
            let markupFactor = 0;
            if (parseInt(props.estimate.markup_factor) === 1) {   // Sales
                markupFactor = 0.1;
            } else if (parseInt(props.estimate.markup_factor) === 2) {   // Broker
                markupFactor = 0.3;
            } else if (parseInt(props.estimate.markup_factor) === 3) {   // Good
                markupFactor = 0.25;
            } else if (parseInt(props.estimate.markup_factor) === 4) {   // Great
                markupFactor = 0.2;
            } else if (parseInt(props.estimate.markup_factor) === 5) {   // Excellent
                markupFactor = 0.15;
            } else if (parseInt(props.estimate.markup_factor) === 6) {   // PITAA
                markupFactor = 0.45;
            }

            /*************************** Total **************************/
            //      => subtotal + markupPrice + outsidePrice + markupFactor * x = x
            //      => x = (subtotal + markupPrice + outsidePrice) / (1 - markupFactor)
            total = (subtotal + markupPrice + outsidePrice) / (1 - markupFactor);

            commission = parseFloat((total * markupFactor).toFixed(2));

            /*************************** Tax **************************/
            if (parseInt(props.estimate.use_tax) === 1) {
                tax = parseFloat((total * 0.0825).toFixed(2));
            }

            total += tax;

            return (
                <tr>
                    <td><b>{numeral(multiResult.request_params.quantity).format('0,0')}</b></td>
                    <td>{numeral(subtotal).format('$0,0.00')}</td>
                    <td>{numeral(markupPrice).format('$0,0.00')}</td>
                    <td>{numeral(commission).format('$0,0.00')}</td>
                    <td>{numeral(outsidePrice).format('$0,0.00')}</td>
                    <td>{numeral(tax).format('$0,0.00')}</td>
                    <td><b>{numeral(total).format('$0,0.00')}</b></td>
                </tr>
            );
        });
    };

    let label = 'Export';
    if (props.isExportingMatrix) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={props.hideMatrixModal}>Close</button>,
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={props.exportMatrix} disabled={props.isExportingMatrix}>{label}</button>,
    ];
    return (
        <Dialog
            name="matrix-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="matrix-dialog"
        >
            <div className="matrix-container">
                <h1>Price Matrix</h1>
                <div className="results-container">
                    <table className="table table-sm table-hover table-striped">
                        <thead>
                            <tr>
                                <th>Qty</th>
                                <th>Subtotal</th>
                                <th>Add. Price</th>
                                <th>Commission</th>
                                <th>Outside Service</th>
                                <th>Tax</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>{renderResults()}</tbody>
                    </table>
                </div>
            </div>
        </Dialog >
    );
});

export default MatrixModal;
