export const FETCH_INVOICES = 'PQT_POSTAGE_INVOICES/FETCH_INVOICES';
export const FETCH_INVOICES_SUCCESS = 'PQT_POSTAGE_INVOICES/FETCH_INVOICES_SUCCESS';
export const FETCH_INVOICES_ERROR = 'PQT_POSTAGE_INVOICES/FETCH_INVOICES_ERROR';
export const DELETE_INVOICE = 'PQT_POSTAGE_INVOICES/DELETE_INVOICE';
export const DELETE_INVOICE_SUCCESS = 'PQT_POSTAGE_INVOICES/DELETE_INVOICE_SUCCESS';
export const DELETE_INVOICE_ERROR = 'PQT_POSTAGE_INVOICES/DELETE_INVOICE_ERROR';
export const RESET_STATE = 'PQT_POSTAGE_INVOICES/RESET_STATE';
export const SET_ID = 'PQT_POSTAGE_INVOICES/SET_ID';
export const SET_SORT = 'PQT_POSTAGE_INVOICES/SET_SORT';
export const SET_ACTIVE_PAGE = 'PQT_POSTAGE_INVOICES/SET_ACTIVE_PAGE';
export const SET_ID_FOR_DELETE = 'PQT_POSTAGE_INVOICES/SET_ID_FOR_DELETE';
export const SET_SHOW = 'PQT_POSTAGE_INVOICES/SET_SHOW';
export const CHANGE_FILTER = 'PQT_POSTAGE_INVOICES/CHANGE_FILTER';