import Immutable from 'immutable';
import * as types from './ApItems.types';

const initialState = {
    id: null,
    fetchingItems: false,
    items: [],
    pagination: {
        totalElements: 0,
        activePage: 1,
        totalPages: 0,
        elementsPerPage: 100,
    },
    sort: {
        column: 'date',
        type: 'desc',
    },
    history: null,
    delete: {
        id: null,
        isDeleting: false,
    },
    filter: {
        fields: []
    },
    uploadingFile: false,
    fields: {},
    fetchingItem: false,
    savingItem: false,
    pushingToQb: false,
    file: null,
    type: null,
    processedData: null,
    information: {
        accounts: [],
    },
    fetchingInformation: false,
    savingItems: false,
    name: '',
    postedDate: null,
    recordId: null,
    record: null,
    fetchingRecord: false,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_ITEMS:
            return state.setIn(['fetchingItems'], true).toJS();

        case types.FETCH_ITEMS_SUCCESS:
            return state.setIn(['fetchingItems'], false)
                .setIn(['items'], action.payload.elements)
                .setIn(['pagination', 'totalElements'], action.payload.totalElements)
                .setIn(['pagination', 'activePage'], action.payload.activePage)
                .setIn(['pagination', 'totalPages'], action.payload.totalPages)
                .setIn(['pagination', 'elementsPerPage'], action.payload.elementsPerPage)
                .toJS();

        case types.FETCH_ITEMS_ERROR:
            return state.setIn(['fetchingItems'], false).toJS();

        case types.FETCH_ITEM:
            return state.setIn(['fetchingItem'], true).toJS();

        case types.FETCH_ITEM_SUCCESS:
            return state.setIn(['fetchingItem'], false)
                .setIn(['fields'], action.payload)
                .toJS();

        case types.FETCH_ITEM_ERROR:
            return state.setIn(['fetchingItem'], false).toJS();

        case types.SET_SORT:
            return state.setIn(['sort'], action.payload).toJS();

        case types.SET_ACTIVE_PAGE:
            return state.setIn(['pagination', 'activePage'], action.payload).toJS();

        case types.DELETE_ITEM:
            return state.setIn(['delete', 'isDeleting'], true).toJS();

        case types.DELETE_ITEM_SUCCESS:
            return state.setIn(['delete', 'isDeleting'], false)
                .setIn(['delete', 'id'], null)
                .toJS();

        case types.DELETE_ITEM_ERROR:
            return state.setIn(['delete', 'isDeleting'], false).toJS();

        case types.SAVE_ITEM:
            return state.setIn(['savingItem'], true).toJS();

        case types.SAVE_ITEM_SUCCESS:
            return state.setIn(['savingItem'], false).toJS();

        case types.SAVE_ITEM_ERROR:
            return state.setIn(['savingItem'], false).toJS();

        case types.SAVE_ITEMS:
            return state.setIn(['savingItems'], true).toJS();

        case types.SAVE_ITEMS_SUCCESS:
            return state.setIn(['savingItems'], false).toJS();

        case types.SAVE_ITEMS_ERROR:
            return state.setIn(['savingItems'], false).toJS();

        case types.UPLOAD_FILE:
            return state.setIn(['uploadingFile'], true).toJS();

        case types.UPLOAD_FILE_SUCCESS:
            return state.setIn(['uploadingFile'], false)
                .setIn(['processedData'], action.payload)
                .toJS();

        case types.UPLOAD_FILE_ERROR:
            return state.setIn(['uploadingFile'], false).toJS();

        case types.PUSH_TO_QB:
            return state.setIn(['pushingToQb'], true).toJS();

        case types.PUSH_TO_QB_SUCCESS:
            return state.setIn(['pushingToQb'], false).toJS();

        case types.PUSH_TO_QB_ERROR:
            return state.setIn(['pushingToQb'], false).toJS();

        case types.FETCH_INFORMATION:
            return state.setIn(['fetchingInformation'], true).toJS();

        case types.FETCH_INFORMATION_SUCCESS:
            return state.setIn(['fetchingInformation'], false)
                .setIn(['information', 'accounts'], action.payload.accounts)
                .toJS();

        case types.FETCH_INFORMATION_ERROR:
            return state.setIn(['fetchingInformation'], false).toJS();

        case types.FETCH_RECORD:
            return state.setIn(['fetchingRecord'], true).toJS();

        case types.FETCH_RECORD_SUCCESS:
            return state.setIn(['fetchingRecord'], false)
                .setIn(['record'], action.payload)
                .toJS();

        case types.FETCH_RECORD_ERROR:
            return state.setIn(['fetchingRecord'], false).toJS();

        case types.SET_ID_FOR_DELETE:
            return state.setIn(['delete', 'id'], action.payload).toJS();

        case types.SET_FIELD:
            return state.setIn(['fields', action.payload.field], action.payload.value).toJS();

        case types.CLEAR_FORM:
            return state.setIn(['file'], null)
                .updateIn(['fields'], (fields) => {
                    return fields.clear();
                }).toJS();

        case types.CHANGE_FILTER:
            return state.setIn(['pagination', 'activePage'], 1)
                .updateIn(['filter', 'fields'], (fields) => {
                    const index = fields.findIndex(field => field.get('name') === action.payload.name);
                    if (action.payload.value === '') {
                        return fields.delete(index);
                    } else {
                        if (index === -1) {
                            return fields.push({ name: action.payload.name, value: action.payload.value });
                        } else {
                            return fields.set(index, { name: action.payload.name, value: action.payload.value });
                        }
                    }
                })
                .toJS();

        case types.SET_FILE:
            return state.setIn(['file'], action.payload).toJS();

        case types.SET_TYPE:
            return state.setIn(['type'], action.payload).toJS();

        case types.SET_PROCESSED_DATA_FIELD:
            return state.updateIn(['processedData'], (processedData) => {
                const processedDataJs = processedData.toJS();
                processedDataJs[action.payload.index][action.payload.field] = action.payload.value;
                return processedDataJs;
            }).toJS();

        case types.SET_NAME:
            return state.setIn(['name'], action.payload).toJS();

        case types.SET_POSTED_DATE:
            return state.setIn(['postedDate'], action.payload).toJS();

        case types.SET_RECORD_ID:
            return state.setIn(['recordId'], action.payload).toJS();

        case types.SET_HISTORY:
            return state.setIn(['history'], action.payload).toJS();

        case types.UPDATE_FIELD_LOCAL:
            return state.updateIn(['items'], (items) => {
                const itemsJs = items.toJS();
                itemsJs[action.payload.index][action.payload.field] = action.payload.value;
                return itemsJs;
            }).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}