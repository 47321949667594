export const FETCH_INVOICE = 'PQT_POSTAGE_INVOICE/FETCH_INVOICE';
export const FETCH_INVOICE_SUCCESS = 'PQT_POSTAGE_INVOICE/FETCH_INVOICE_SUCCESS';
export const FETCH_INVOICE_ERROR = 'PQT_POSTAGE_INVOICE/FETCH_INVOICE_ERROR';
export const FETCH_INFORMATION = 'PQT_POSTAGE_INVOICE/FETCH_INFORMATION';
export const FETCH_INFORMATION_SUCCESS = 'PQT_POSTAGE_INVOICE/FETCH_INFORMATION_SUCCESS';
export const FETCH_INFORMATION_ERROR = 'PQT_POSTAGE_INVOICE/FETCH_INFORMATION_ERROR';
export const FETCH_CUSTOMER_DATA = 'PQT_POSTAGE_INVOICE/FETCH_CUSTOMER_DATA';
export const FETCH_CUSTOMER_DATA_SUCCESS = 'PQT_POSTAGE_INVOICE/FETCH_CUSTOMER_DATA_SUCCESS';
export const FETCH_CUSTOMER_DATA_ERROR = 'PQT_POSTAGE_INVOICE/FETCH_CUSTOMER_DATA_ERROR';
export const SAVE_INVOICE = 'PQT_POSTAGE_INVOICE/SAVE_INVOICE';
export const SAVE_INVOICE_SUCCESS = 'PQT_POSTAGE_INVOICE/SAVE_INVOICE_SUCCESS';
export const SAVE_INVOICE_ERROR = 'PQT_POSTAGE_INVOICE/SAVE_INVOICE_ERROR';
export const FETCH_POSTAGE_ESCROW = 'PQT_POSTAGE_INVOICE/FETCH_POSTAGE_ESCROW';
export const FETCH_POSTAGE_ESCROW_SUCCESS = 'PQT_POSTAGE_INVOICE/FETCH_POSTAGE_ESCROW_SUCCESS';
export const FETCH_POSTAGE_ESCROW_ERROR = 'PQT_POSTAGE_INVOICE/FETCH_POSTAGE_ESCROW_ERROR';
export const SET_FIELD = 'PQT_POSTAGE_INVOICE/SET_FIELD';
export const SET_ITEM_FIELD = 'PQT_POSTAGE_INVOICE/SET_ITEM_FIELD';
export const RESET_STATE = 'PQT_POSTAGE_INVOICE/RESET_STATE';
export const SET_CUSTOMER_EMAIL = 'PQT_POSTAGE_INVOICE/SET_CUSTOMER_EMAIL';
export const CHANGE_SORT_POSITION = 'PQT_POSTAGE_INVOICE/CHANGE_SORT_POSITION';
export const ADD_LINE = 'PQT_POSTAGE_INVOICE/ADD_LINE';
export const REMOVE_LINE = 'PQT_POSTAGE_INVOICE/REMOVE_LINE';
export const APPROVE_INVOICE = 'PQT_POSTAGE_INVOICE/APPROVE_INVOICE';
export const APPROVE_INVOICE_SUCCESS = 'PQT_POSTAGE_INVOICE/APPROVE_INVOICE_SUCCESS';
export const APPROVE_INVOICE_ERROR = 'PQT_POSTAGE_INVOICE/APPROVE_INVOICE_ERROR';