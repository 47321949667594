export const FETCH_PURCHASE_ORDERS = 'PURCHASE_ORDERS/FETCH_PURCHASE_ORDERS';
export const FETCH_PURCHASE_ORDERS_SUCCESS = 'PURCHASE_ORDERS/FETCH_PURCHASE_ORDERS_SUCCESS';
export const FETCH_PURCHASE_ORDERS_ERROR = 'PURCHASE_ORDERS/FETCH_PURCHASE_ORDERS_ERROR';
export const DELETE_PURCHASE_ORDER = 'PURCHASE_ORDERS/DELETE_PURCHASE_ORDER';
export const DELETE_PURCHASE_ORDER_SUCCESS = 'PURCHASE_ORDERS/DELETE_PURCHASE_ORDER_SUCCESS';
export const DELETE_PURCHASE_ORDER_ERROR = 'PURCHASE_ORDERS/DELETE_PURCHASE_ORDER_ERROR';
export const EXPORT_EXCEL = 'PURCHASE_ORDERS/EXPORT_EXCEL';
export const EXPORT_EXCEL_SUCCESS = 'PURCHASE_ORDERS/EXPORT_EXCEL_SUCCESS';
export const EXPORT_EXCEL_ERROR = 'PURCHASE_ORDERS/EXPORT_EXCEL_ERROR';
export const EXPORT_REPORT = 'PURCHASE_ORDERS/EXPORT_REPORT';
export const EXPORT_REPORT_SUCCESS = 'PURCHASE_ORDERS/EXPORT_REPORT_SUCCESS';
export const EXPORT_REPORT_ERROR = 'PURCHASE_ORDERS/EXPORT_REPORT_ERROR';
export const EXPORT_LIST_PURCHASE = 'PURCHASE_ORDERS/EXPORT_LIST_PURCHASE';
export const EXPORT_LIST_PURCHASE_SUCCESS = 'PURCHASE_ORDERS/EXPORT_LIST_PURCHASE_SUCCESS';
export const EXPORT_LIST_PURCHASE_ERROR = 'PURCHASE_ORDERS/EXPORT_LIST_PURCHASE_ERROR';
export const UPLOAD_LIST_PURCHASE = 'PURCHASE_ORDERS/UPLOAD_LIST_PURCHASE';
export const UPLOAD_LIST_PURCHASE_SUCCESS = 'PURCHASE_ORDERS/UPLOAD_LIST_PURCHASE_SUCCESS';
export const UPLOAD_LIST_PURCHASE_ERROR = 'PURCHASE_ORDERS/UPLOAD_LIST_PURCHASE_ERROR';
export const RESET_STATE = 'PURCHASE_ORDERS/RESET_STATE';
export const SET_ID = 'PURCHASE_ORDERS/SET_ID';
export const SET_SORT = 'PURCHASE_ORDERS/SET_SORT';
export const SET_ACTIVE_PAGE = 'PURCHASE_ORDERS/SET_ACTIVE_PAGE';
export const SET_ID_FOR_DELETE = 'PURCHASE_ORDERS/SET_ID_FOR_DELETE';
export const CHANGE_FILTER = 'PURCHASE_ORDERS/CHANGE_FILTER';
export const SET_REPORT_START_DATE = 'PURCHASE_ORDERS/SET_REPORT_START_DATE';
export const SET_REPORT_END_DATE = 'PURCHASE_ORDERS/SET_REPORT_END_DATE';
export const SET_REPORT_TYPE = 'PURCHASE_ORDERS/SET_REPORT_TYPE';
export const SET_FILE = 'PURCHASE_ORDERS/SET_FILE';