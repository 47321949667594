import React, { Fragment } from 'react';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import PqtPagination from './../../PqtPagination';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import { dateToTimezone } from './../../../utils';

const ActiveResults = React.memo((props) => {
    const pqtType = props.user !== null && parseInt(props.user.pqt_type);

    const exportTicketHandler = (id) => {
        window.open(process.env.REACT_APP_API_URL + '/pqt-tracking/export?id=' + id);
    };

    const renderResults = () => {
        if (props.fetchingActive) {
            return (
                <tr>
                    <td colSpan="14"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.activeResults.length === 0 || !props.showActiveJobs) {
            return (
                <tr>
                    <td colSpan="14">No Results</td>
                </tr>
            );
        }

        return props.activeResults.map((job, index) => {
            let box = '';
            if (job.status === 4) {
                box = 'purple-box';
            } else if (job.days_left <= 0) {
                box = 'red-box';
            } else if (job.days_left < 7) {
                box = 'yellow-box';
            } else if (job.days_left < 14) {
                box = 'blue-box';
            } else {
                box = 'green-box';
            }

            let statusBox = 'status-box';
            if (job.status === 1) {
                statusBox = 'status-box blue-box';
            } else if (job.status === 2) {
                statusBox = 'status-box green-box';
            } else if (job.status === 3) {
                statusBox = 'status-box red-box';
            } else if (job.status === 4) {
                statusBox = 'status-box purple-box';
            } else if (job.status === 5) {
                statusBox = 'status-box yellow-box';
            }

            let createdAt = '-';
            if (job.created_at != null) {
                createdAt = dateToTimezone(job.created_at, 'MM/DD/YYYY');
            }

            let csrName = job.csr_name;
            const csrNameParts = csrName.split(' ');
            if (csrNameParts.length > 0) {
                csrName = csrNameParts[0];
            }

            let salesName = job.sales_rep_name;
            const salesNameParts = salesName.split(' ');
            if (salesNameParts.length > 0) {
                salesName = salesNameParts[0];
            }

            let outOnProofContainer = null;
            if (job.out_on_proof) {
                outOnProofContainer = (<span className="out-on-proof">(Proof out - {dateToTimezone(job.out_on_proof_datetime, 'MM/DD/YYYY hh:mm a')})</span>);
            }

            let onPauseText = null;
            if (parseInt(job.status) === 5) {
                let dueDateRow = null;
                if (job.paused_due_date) {
                    dueDateRow = <div className="paused-text">Due: {dateToTimezone(job.paused_due_date, 'MM/DD/YYYY')}</div>;
                }

                onPauseText = (
                    <Fragment>
                        <div className="paused-text">Paused</div>
                        {dueDateRow}
                    </Fragment>
                );
            }

            let departments = [];
            for (let i = 0; i < job.departments.length; i++) {
                let separator = ' | ';
                if (i === job.departments.length - 1) {
                    separator = null;
                }

                let spanClass = box;
                if (parseInt(job.departments[i].status) === 4) {
                    spanClass = 'finished';
                }

                let departmentTime = null;
                if (job.departments[i].lifecycle != null) {
                    departmentTime = ' (' + job.departments[i].lifecycle + ')';
                }

                departments.push(<Fragment key={'job-department-' + i}><span className={spanClass}>{job.departments[i].name}{departmentTime}</span>{separator}</Fragment>);
            }

            let jobLink = null;
            if (pqtType === 1 || pqtType === 2 || pqtType === 4) {
                jobLink = (
                    <td>
                        <div className="actions">
                            <Tooltip title="Edit Job" position="bottom" arrow size="small" className="action-tooltip">
                                <Link to={'/tracking/' + job.id}><i className="fas fa-edit" /></Link>
                            </Tooltip>
                        </div>
                    </td>
                );
            }

            return (
                <tr key={'active-job-' + index}>
                    <td>{createdAt}</td>
                    <td className={box}>{job.id}</td>
                    <td className={box}>{job.job_number}</td>
                    <td>{csrName}</td>
                    <td>{salesName}</td>
                    <td className={box + ' text-center'}>
                        {dateToTimezone(job.due_date, 'MM/DD/YY')}
                        {onPauseText}
                    </td>
                    <td className={box + ' text-center'}>{job.days_left}</td>
                    <td>
                        <div>{job.job_description}</div>
                        <div><b>Customer:</b> {job.customer_name} {outOnProofContainer}</div>
                    </td>
                    <td className="text-right">{numeral(job.quantity).format('0,0')}</td>
                    <td className={statusBox}><i className="fas fa-dot-circle" /></td>
                    <td className="text-cent1er">{job.lifecycle}</td>
                    <td>{departments}</td>
                    <td><Link to="#" onClick={exportTicketHandler.bind(this, job.id)}>{job.id}</Link></td>
                    {jobLink}
                </tr>
            );
        });
    };

    let actionsTh = null;
    if (pqtType === 1 || pqtType === 2 || pqtType === 4) {
        actionsTh = <th style={{ width: 50 }}></th>;
    }

    return (
        <div className="active-results-container">
            <h2>Active Jobs</h2>
            <table className="table table-sm table-hover table-striped">
                <thead>
                    <tr>
                        <th>Enter</th>
                        <th>SC #</th>
                        <th>PO #</th>
                        <th>CSR</th>
                        <th>Sales</th>
                        <th className="text-center">Due</th>
                        <th className="text-center">Days Left</th>
                        <th>Description/Customer</th>
                        <th className="text-right">Qty</th>
                        <th className="text-center">Status</th>
                        <th className="text-center">Lifecycle</th>
                        <th>Required Departments</th>
                        <th>PDF Ticket</th>
                        {actionsTh}
                    </tr>
                </thead>
                <tbody>
                    {renderResults()}
                </tbody>
            </table>
            <PqtPagination
                pagination={props.activePagination}
                setActivePage={props.setActivePage}
            />
        </div>
    );
});

export default ActiveResults;
