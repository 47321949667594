import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from './Table';
import * as actions from './Users.actions';
import * as selectors from './Users.selectors';
import './Users.scss';
import PqtLayout from './../PqtLayout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import * as loginSelectors from './../Login/Login.selectors';
import { Redirect } from 'react-router-dom';

const Users = (props) => {
    /* State to props */
    const elements = useSelector(selectors.getElements, stringifyEqualityCheck);
    const fetchingElements = useSelector(selectors.getFetchingElements);
    const isLogging = useSelector(selectors.getIsLogging);
    const loggingId = useSelector(selectors.getLoggingId);
    const filter = useSelector(selectors.getFilter);
    const pagination = useSelector(selectors.getPagination, stringifyEqualityCheck);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const fetchElements = useCallback(() => dispatch(actions.fetchElements()), [dispatch]);
    const login = useCallback(() => dispatch(actions.login()), [dispatch]);
    const setFilter = useCallback((value) => dispatch(actions.setFilter(value)), [dispatch]);
    const setLoggingId = useCallback((value) => dispatch(actions.setLoggingId(value)), [dispatch]);
    const setActivePage = useCallback((value) => dispatch(actions.setActivePage(value)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        fetchElements();

        return () => {
            resetState();
        };
    }, [resetState, fetchElements]);

    if (user && user != null && parseInt(user.id) !== 61) {     // Hugh
        return <Redirect to="/dashboard" />;
    }

    return (
        <PqtLayout title="Users" history={props.history}>
            <Helmet><title>Users - Stagecoach</title></Helmet>
            <div className="users-container">
                <Table
                    elements={elements}
                    fetchingElements={fetchingElements}
                    isLogging={isLogging}
                    loggingId={loggingId}
                    filter={filter}
                    user={user}
                    pagination={pagination}
                    fetchElements={fetchElements}
                    login={login}
                    setFilter={setFilter}
                    setLoggingId={setLoggingId}
                    setActivePage={setActivePage}
                />
            </div>
        </PqtLayout>
    );
};

export default Users;
