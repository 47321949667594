import React from 'react';
import { Dialog } from './../../common/dialog';
import { empty, showNotification } from './../../../utils';

const ChangeEstimateModal = React.memo((props) => {
    const hideHandler = () => {
        props.setNewEstimateType('');
        props.hideChangeEstimateModal();
    };

    const changeEstimateHandler = () => {
        if (empty(props.newEstimateType)) {
            return showNotification('Complete Information', 'Select the Estimate Type', 'info');
        }

        props.changeEstimateType();
    };

    const handleChange = (event) => {
        props.setNewEstimateType(event.target.value);
    };

    let changeButtonLabel = 'Change';
    if (props.changingEstimateType) {
        changeButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Cancel</button>,
        <button key="delete-button" className="btn btn-light btn-sm action-button" disabled={props.changingEstimateType} onClick={changeEstimateHandler}>{changeButtonLabel}</button>
    ];
    return (
        <Dialog
            name="change-estimate-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="change-estimate-dialog"
        >
            <div className="change-estimate-container">
                <h1>Change Estimate Type</h1>
                <div className="new-estimate-form-container">
                    <div className="field">
                        <div><b>Estimate Type:</b></div>
                        <select value={props.newEstimateType} onChange={handleChange}>
                            <option value="">Select option</option>
                            <option value="1">Sheetfed</option>
                            <option value="2">Envelope</option>
                            <option value="4">Self-Cover</option>
                            <option value="3">Plus-Cover</option>
                            <option value="5">Digital</option>
                            <option value="6">Large Format</option>
                            <option value="7">Pocket Folder</option>
                        </select>
                    </div>
                </div>
            </div>
        </Dialog >
    );
});

export default ChangeEstimateModal;
