import React, { Fragment } from 'react';
import { confirmModal, dateToTimezone } from './../../../utils';
import numeral from 'numeral';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import moment from 'moment-timezone';

const Steps = React.memo((props) => {
    if (props.isEditing) {
        return null;
    }

    const pqtType = props.user !== null && props.user.pqt_type;

    let departmentOptions = [<option value="" key="department-option-empty">Select an option</option>];
    for (let i = 0; i < props.informationDepartments.length; i++) {
        departmentOptions.push(
            <option value={props.informationDepartments[i].id} key={'department-option-' + i}>
                {props.informationDepartments[i].name}
            </option>
        );
    }

    let maxStepOrder = props.trackingSteps.length;
    for (let i = 0; i < props.trackingSteps.length; i++) {
        const element = props.trackingSteps[i];
        if (element.step_order > maxStepOrder) {
            maxStepOrder = element.step_order;
        }
    }

    let stepOrderOptions = [<option value="" key="step-order-option-empty">Select an option</option>];
    for (let i = 0; i < maxStepOrder; i++) {
        stepOrderOptions.push(
            <option value={i + 1} key={'step-order-option-' + i}>
                {i + 1}
            </option>
        );
    }

    const setStepFieldHandler = (index, name, event) => {
        if (event.target.type === 'checkbox') {
            props.setStepField(index, name, event.target.checked ? 1 : 0);
        } else {
            props.setStepField(index, name, event.target.value);
        }
    };

    const confirmHandler = (index) => {
        confirmModal(() => { props.deleteTrackingStep(index) });
    };

    const setDateFieldHandler = (index, name, event) => {
        let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');
        props.setStepField(index, name, value);
    };

    const renderAssignedPeople = (ids, stepIndex) => (
        <ul className="assigned-people-list">
            {ids.map((id, index) => {
                for (let i = 0; i < props.informationEmployees.length; i++) {
                    const employee = props.informationEmployees[i];

                    let deleteIcon = null;
                    if ([1, 2].includes(pqtType)) {
                        deleteIcon = <i className="fas fa-trash" onClick={props.removeAssignedPeople.bind(this, stepIndex, index)}></i>;
                    }

                    if (parseInt(employee.id) === parseInt(id)) {
                        return (
                            <li key={'assigned-id-' + index} className="assigned-person">
                                {employee.name} {deleteIcon}
                            </li>
                        );
                    }
                }
                return null;
            })}
        </ul>
    );

    const renderAssignedVendors = (ids, stepIndex) => (
        <ul className="assigned-vendors-list">
            {ids.map((id, index) => {
                for (let i = 0; i < props.informationVendors.length; i++) {
                    const vendor = props.informationVendors[i];

                    let deleteIcon = null;
                    if ([1, 2].includes(pqtType)) {
                        deleteIcon = <i className="fas fa-trash" onClick={props.removeAssignedVendor.bind(this, stepIndex, index)}></i>;
                    }

                    if (parseInt(vendor.id) === parseInt(id)) {
                        return (
                            <li key={'assigned-vendor-id-' + index} className="assigned-vendor">
                                {vendor.display_name} {deleteIcon}
                            </li>
                        );
                    }
                }
                return null;
            })}
        </ul>
    );

    const renderSubdepartments = (ids, stepIndex, department, finishedIds) => {
        if (department == null || department.subdepartments.length === 0) {
            return null;
        }

        return (
            <ul className="assigned-subdepartments-list">
                {ids.map((id, index) => {
                    for (let i = 0; i < department.subdepartments.length; i++) {
                        const subdepartment = department.subdepartments[i];

                        let deleteIcon = null;
                        if ([1, 2, 4].includes(pqtType)) {
                            deleteIcon = <i className="fas fa-trash" onClick={props.removeSubdepartment.bind(this, stepIndex, index)}></i>;
                        }

                        let finishedClassName = '';
                        if (finishedIds.includes(id)) {
                            finishedClassName = ' finished';
                            deleteIcon = null;
                        }

                        if (parseInt(subdepartment.id) === parseInt(id)) {
                            return (
                                <li key={'assigned-subdepartment-id-' + index} className={'assigned-subdepartment' + finishedClassName}>
                                    {subdepartment.name} {deleteIcon}
                                </li>
                            );
                        }
                    }
                    return null;
                })}
            </ul>
        );
    };

    const showDataProcessingHandler = (stepId, dataProcessingId, showSave) => {
        props.resetDataProcessingState();
        props.setDataProcessingShowSave(showSave);
        props.setDataProcessingElementId(stepId);
        props.setDataProcessingType(1);
        if (dataProcessingId != null) {
            props.fetchDataProcessingElement(dataProcessingId);
        }
        props.showDataProcessing();
    }

    const showPostalQualificationHandler = (stepId, postalQualificationId, showSave) => {
        props.resetPostalQualificationState();
        props.setPostalQualificationShowSave(showSave);
        props.setPostalQualificationElementId(stepId);
        props.setPostalQualificationType(1);
        if (postalQualificationId != null) {
            props.fetchPostalQualificationElement(postalQualificationId);
        }
        props.showPostalQualification();
    }

    const handleShowPauseModal = (departmentId) => {
        props.setIdModal(departmentId);
        props.showPauseModal();
    };

    const handleShowUnpauseModal = (departmentId) => {
        props.setIdModal(departmentId);
        props.showUnpauseModal();
    };

    const renderSteps = () => props.trackingSteps.map((step, index) => {
        let managerOptions = [<option value="" key="department-option-empty">Select an option</option>];
        let selectedDepartment = null;
        for (let i = 0; i < props.informationDepartments.length; i++) {
            const department = props.informationDepartments[i];
            if (parseInt(department.id) === parseInt(step.department_id)) {
                selectedDepartment = department;
                break;
            }
        }
        if (selectedDepartment != null) {
            for (let i = 0; i < selectedDepartment.managers.length; i++) {
                managerOptions.push(
                    <option value={selectedDepartment.managers[i].id} key={'manager-option-' + i}>
                        {selectedDepartment.managers[i].name}
                    </option>
                );
            }
        }

        const unsaved = props.stepsUnsaved.includes(index);
        let saveButton = null;
        if (parseInt(step.status) !== 4) {
            let allowSave = false;
            if ([1, 2, 3, 4, 8].includes(pqtType)) {
                allowSave = true;
            } else if (pqtType === 6 && step.assigned_employees_ids.includes(parseInt(props.user.employee_id))) {
                allowSave = true;
            }

            if (allowSave) {
                saveButton = <button onClick={props.saveTrackingStep.bind(this, index)} className={unsaved ? 'unsaved' : ''}><i className="fas fa-cloud-upload-alt"></i> Save</button>;
                if (props.savingStep && props.savingStepIndex === index) {
                    saveButton = <button disabled><i className="fas fa-circle-notch fa-spin" /></button>;
                }
            }
        }

        let deleteButton = null;
        if ([1, 2, 3, 4, 8].includes(pqtType) && parseInt(step.status) !== 4) {
            deleteButton = <button onClick={confirmHandler.bind(this, index)}><i className="fas fa-times"></i> Delete</button>;
            if (props.savingStepDelete && props.savingStepIndex === index) {
                deleteButton = <button disabled><i className="fas fa-circle-notch fa-spin" /></button>;
            }
        }

        let closeButton = null;
        let pauseButton = null;
        if (parseInt(step.status) === 2) {
            let allowClose = false;
            if ([1, 2, 3, 4, 8].includes(pqtType)) {
                allowClose = true;
            } else if (pqtType === 6 && step.assigned_employees_ids.includes(parseInt(props.user.employee_id))) {
                allowClose = true;
            }

            if (allowClose) {
                closeButton = (
                    <a href={'/department-closeout/' + step.department_id + '/' + props.id}>
                        <i className="fas fa-check-circle"></i> Close
                    </a>
                );
            }

            // Super Dashboard (1), Super Manager (2), Department Manager (3)
            if (pqtType === 1 || ([2, 3].includes(pqtType) && props.user.pqt_departments.includes(parseInt(step.department_id)))) {
                pauseButton = <button onClick={handleShowPauseModal.bind(this, step.department_id)}><i className="fas fa-pause-circle"></i> Pause</button>;
            }
        }

        let pauseDateRow = null;
        let unpauseButton = null;
        let pauseComments = null;
        let pauseBanner = null;
        if (parseInt(step.status) === 5) {
            deleteButton = null;
            closeButton = null;

            const pausedDate = step.pause_date == null ? 'N/A' : dateToTimezone(step.pause_date, 'MMMM Do YYYY, h:mm a');
            pauseDateRow = <div className="paused-date date"><b>Paused:</b> {pausedDate}</div>;
            pauseComments = <div><b>Pause Comments:</b> {step.pause_comments}</div>;
            pauseBanner = <div className="pause-banner">PAUSED</div>;

            // Super Dashboard (1), Super Manager (2), Department Manager (3)
            if (pqtType === 1 || ([2, 3].includes(pqtType) && props.user.pqt_departments.includes(parseInt(step.department_id)))) {
                unpauseButton = <button onClick={handleShowUnpauseModal.bind(this, step.department_id)}><i className="fas fa-play-circle"></i> Unpause</button>;
            }
        }

        let employeeOptions = [<option value="" key="employee-option-empty">Select employee</option>];
        for (let i = 0; i < props.informationEmployees.length; i++) {
            const employee = props.informationEmployees[i];
            if (employee.departments_ids.includes(parseInt(step.department_id)) && !step.assigned_employees_ids.includes(parseInt(employee.id))) {
                employeeOptions.push(
                    <option value={employee.id} key={'employee-option-' + i}>
                        {employee.name}
                    </option>
                );
            }
        }

        let subdepartmentOptions = [<option value="" key="subdepartment-option-empty">Select task</option>];
        for (let i = 0; selectedDepartment != null && i < selectedDepartment.subdepartments.length; i++) {
            const subdepartment = selectedDepartment.subdepartments[i];
            if (!step.assigned_subdepartments_ids.includes(parseInt(subdepartment.id))) {
                subdepartmentOptions.push(
                    <option value={subdepartment.id} key={'subdepartment-option-' + i}>
                        {subdepartment.name}
                    </option>
                );
            }
        }

        let vendorOptions = [<option value="" key="vendor-option-empty">Select vendor</option>];
        for (let i = 0; i < props.informationVendors.length; i++) {
            const vendor = props.informationVendors[i];
            if (!step.assigned_vendors_ids.includes(parseInt(vendor.id))) {
                vendorOptions.push(
                    <option value={vendor.id} key={'vendor-option-' + i}>
                        {vendor.display_name}
                    </option>
                );
            }
        }

        const startedDate = step.start_date == null ? 'N/A' : dateToTimezone(step.start_date, 'MMMM Do YYYY, h:mm a');
        const finishedDate = step.finished_date == null ? 'N/A' : dateToTimezone(step.finished_date, 'MMMM Do YYYY, h:mm a');

        let assignedPeople = null;
        let assignSubdepartments = null
        let assignedVendors = null;
        if ([1, 2, 3, 4, 8].includes(pqtType)) {
            assignedPeople = (
                <Fragment>
                    <div>
                        Assign Operators: <br />
                        <select value={step.employee_id} onChange={setStepFieldHandler.bind(this, index, 'employee_id')}>
                            {employeeOptions}
                        </select>
                        <button onClick={props.addAssignedPeople.bind(this, index, step.employee_id)}><i className="fas fa-plus"></i></button>
                    </div>
                </Fragment>
            );

            assignedVendors = (
                <Fragment>
                    <div>
                        Assign Vendors: <br />
                        <select value={step.vendor_id} onChange={setStepFieldHandler.bind(this, index, 'vendor_id')}>
                            {vendorOptions}
                        </select>
                        <button onClick={props.addAssignedVendor.bind(this, index, step.vendor_id)}><i className="fas fa-plus"></i></button>
                    </div>
                </Fragment>
            );

            if (selectedDepartment != null && selectedDepartment.subdepartments.length > 0) {
                assignSubdepartments = (
                    <div className="assign-subdepartments">
                        <div>
                            Assign Tasks: <br />
                            <select value={step.subdepartment_id} onChange={setStepFieldHandler.bind(this, index, 'subdepartment_id')}>
                                {subdepartmentOptions}
                            </select>
                            <button onClick={props.addSubdepartment.bind(this, index, step.subdepartment_id)}><i className="fas fa-plus"></i></button>
                        </div>
                        {renderSubdepartments(step.assigned_subdepartments_ids, index, selectedDepartment, step.subdepartments_finished)}
                    </div>
                );
            }
        } else {
            assignedPeople = <Fragment><div>Operators: <br /></div></Fragment>;
            assignedVendors = <Fragment><div>Vendors: <br /></div></Fragment>;

            if (selectedDepartment != null && selectedDepartment.subdepartments.length > 0) {
                assignSubdepartments = (
                    <div className="assign-subdepartments">
                        <div>Tasks: <br /></div>
                        {renderSubdepartments(step.assigned_subdepartments_ids, index, selectedDepartment, step.subdepartments_finished)}
                    </div>
                );
            }
        }

        let promotionalCheckboxes = null;
        if (parseInt(step.department_id) === 59) {     // Promotional
            promotionalCheckboxes = (
                <div className="promotional-checkboxes">
                    <div><input type="checkbox" checked={step.is_items} onChange={setStepFieldHandler.bind(this, index, 'is_items')} /> Items</div>
                    <div><input type="checkbox" checked={step.is_embroidery} onChange={setStepFieldHandler.bind(this, index, 'is_embroidery')} /> Embroidery</div>
                    <div><input type="checkbox" checked={step.is_screen_printing} onChange={setStepFieldHandler.bind(this, index, 'is_screen_printing')} /> Screen Printing</div>
                </div>
            );
        }

        const handlePressTicket = (event) => {
            event.preventDefault();
            props.downloadPressTicket();
        };

        let pressRoomTicket = null;
        if (props.trackingFields.sc_estimate_id != null && [1, 9, 27].includes(parseInt(step.department_id))) {     // Press Room (1), Digital (9), Large Format (27)
            let loadingIcon = null;
            if (props.downloadingPressTicket) {
                loadingIcon = <i className="fas fa-circle-notch fa-spin" />;
            }

            pressRoomTicket = (
                <div className="pressroom-ticket">
                    <strong>Print Ticket:</strong> <a href="/" onClick={handlePressTicket}>{props.trackingFields.sc_estimate_id}</a>
                    {loadingIcon}
                </div>
            );
        }

        if (props.trackingFields.is_ac && [1, 9, 27].includes(parseInt(step.department_id))) {     // Press Room (1), Digital (9), Large Format (27)
            let loadingIcon = null;
            if (props.downloadingPressTicket) {
                loadingIcon = <i className="fas fa-circle-notch fa-spin" />;
            }

            pressRoomTicket = (
                <div className="pressroom-ticket">
                    <strong>Print Ticket:</strong> <a href="/" onClick={handlePressTicket}>Click here</a>
                    {loadingIcon}
                </div>
            );
        }

        let outOnProofContainer = null;
        let dpFileContainer = null;
        let dataPostalContainer = null;
        let dataPostalClassName = 'notes no-postal';
        let dataPostalInformationName = null;
        let dataPostalInformation = null;
        let stepPoNumber = null;
        let stepVendorQuote = null;
        let stepVendorPrice = null;
        let stepVendorDueDate = null;
        let stepApparelDueDate = null;
        let stepPromotionalDueDate = null;
        let assignedVendorsContainer = null;
        let assignedPeopleContainer = (
            <div className="assigned-people">
                {assignSubdepartments}
                <div className="assigned-to">
                    {assignedPeople}
                    {renderAssignedPeople(step.assigned_employees_ids, index)}
                </div>
                {promotionalCheckboxes}
                {pauseBanner}
                {pressRoomTicket}
            </div>
        );
        let postageRow = null;
        let postageField = null;

        if (parseInt(step.department_id) === 8) {     // Data Processing
            dataPostalContainer = (
                <div className="data-postal-container">
                    <div onClick={showDataProcessingHandler.bind(this, step.id, step.pqt_data_processing_id, parseInt(step.status) !== 4)}>Data Processing Information</div>
                </div>
            );
            dataPostalClassName = 'notes';
            dataPostalInformationName = <b>Data Processing Information:</b>;
            dataPostalInformation = step.data_processing_information === null ? 'N/A' : step.data_processing_information;

            let outOnProofDate = null;
            if (step.out_on_proof && step.out_on_proof_datetime != null) {
                outOnProofDate = '(' + dateToTimezone(step.out_on_proof_datetime, 'MMMM Do YYYY, h:mm a') + ')';
            }
            outOnProofContainer = (
                <div className="out-on-proof-container">
                    <input type="checkbox" checked={step.out_on_proof} onChange={setStepFieldHandler.bind(this, index, 'out_on_proof')} /> Out On Proof {outOnProofDate}
                </div>
            );

            dpFileContainer = (
                <div className="dp-file-container">
                    <input type="checkbox" checked={step.has_dp_file} onChange={setStepFieldHandler.bind(this, index, 'has_dp_file')} /> Received DP file from Client
                </div>
            );

            postageField = (
                <div className="step-postage-field">
                    Postage Type:
                    <select value={step.postage_type} onChange={setStepFieldHandler.bind(this, index, 'postage_type')}>
                        <option value="">Select option</option>
                        <option value="1">Money in permit</option>
                        <option value="2">ACM to deposit</option>
                        <option value="3">Hold for postage</option>
                        <option value="4">Postage On Account</option>
                    </select>
                </div>
            );
        } else if (parseInt(step.department_id) === 55) {     // Postal Qualification
            dataPostalContainer = (
                <div className="data-postal-container">
                    <div onClick={showPostalQualificationHandler.bind(this, step.id, step.pqt_postal_qualification_id, parseInt(step.status) !== 4)}>Postal Qualification Information</div>
                </div>
            );
            dataPostalClassName = 'notes';
            dataPostalInformationName = <b>Postal Qualification Information:</b>;
            dataPostalInformation = step.postal_qualification_information === null ? 'N/A' : step.postal_qualification_information;
            postageRow = (
                <div className="date"><b>Postage:</b> {step.postage == null ? 'N/A' : numeral(step.postage).format('$0,0.00')}</div>
            );
        } else if (parseInt(step.department_id) === 6 || parseInt(step.department_id) === 9 || parseInt(step.department_id) === 23 || parseInt(step.department_id) === 27) {     // Prepress, Digital, Artwork, Large Format
            let outOnProofDate = null;
            if (step.out_on_proof && step.out_on_proof_datetime != null) {
                outOnProofDate = '(' + dateToTimezone(step.out_on_proof_datetime, 'MMMM Do YYYY, h:mm a') + ')';
            }
            outOnProofContainer = (
                <div className="out-on-proof-container">
                    <input type="checkbox" checked={step.out_on_proof} onChange={setStepFieldHandler.bind(this, index, 'out_on_proof')} /> Out On Proof {outOnProofDate}
                </div>
            );
        } else if (parseInt(step.department_id) === 57) {     // Outside Services
            assignedPeopleContainer = null;
            assignedVendorsContainer = (
                <div className="assigned-vendors">
                    <div className="assigned-vendors-to">
                        {assignedVendors}
                        {renderAssignedVendors(step.assigned_vendors_ids, index)}
                    </div>
                    {pauseBanner}
                </div>
            );
            stepPoNumber = (
                <div className="step-po-number">
                    PO Number:
                    <input type="text" value={step.po_number} onChange={setStepFieldHandler.bind(this, index, 'po_number')} />
                </div>
            );
            stepVendorQuote = (
                <div className="step-vendor-quote">
                    Vendor Quote #:
                    <input type="text" value={step.vendor_quote} onChange={setStepFieldHandler.bind(this, index, 'vendor_quote')} />
                </div>
            );
            stepVendorPrice = (
                <div className="step-vendor-price">
                    Vendor Price:
                    <input type="text" value={step.vendor_price} onChange={setStepFieldHandler.bind(this, index, 'vendor_price')} />
                </div>
            );

            let dayPickerProps = {
                disabledDays: {
                    after: new Date(props.trackingFields.due_date),
                }
            };
            const fieldValue = step.vendor_due_date == null || step.vendor_due_date === '' || step.vendor_due_date === '0000-00-00' ? '' : moment(step.vendor_due_date).format('MM/DD/YYYY');
            stepVendorDueDate = (
                <div className="step-vendor-due-date">
                    Vendor Due Date:
                    <DayPickerInput
                        formatDate={formatDate}
                        parseDate={parseDate}
                        placeholder=""
                        className="date-picker-field"
                        onDayChange={setDateFieldHandler.bind(this, index, 'vendor_due_date')}
                        value={fieldValue}
                        dayPickerProps={dayPickerProps}
                        component={(props) => {
                            return <input {...props} value={props.value} readOnly />;
                        }}
                    />
                </div>
            );
        } else if (parseInt(step.department_id) === 59) {     // Promotional
            let dayPickerProps = {
                disabledDays: {
                    after: new Date(props.trackingFields.due_date),
                }
            };
            const fieldValue = step.promotional_due_date == null || step.promotional_due_date === '' || step.promotional_due_date === '0000-00-00' ? '' : moment(step.promotional_due_date).format('MM/DD/YYYY');
            stepPromotionalDueDate = (
                <div className="step-vendor-due-date">
                    Vendor Due Date:
                    <DayPickerInput
                        formatDate={formatDate}
                        parseDate={parseDate}
                        placeholder=""
                        className="date-picker-field"
                        onDayChange={setDateFieldHandler.bind(this, index, 'promotional_due_date')}
                        value={fieldValue}
                        dayPickerProps={dayPickerProps}
                        component={(props) => {
                            return <input {...props} value={props.value} readOnly />;
                        }}
                    />
                </div>
            );
        } else if (parseInt(step.department_id) === 69) {     // Apparel
            let dayPickerProps = {
                disabledDays: {
                    after: new Date(props.trackingFields.due_date),
                }
            };
            const fieldValue = step.apparel_due_date == null || step.apparel_due_date === '' || step.apparel_due_date === '0000-00-00' ? '' : moment(step.apparel_due_date).format('MM/DD/YYYY');
            stepApparelDueDate = (
                <div className="step-vendor-due-date">
                    Vendor Due Date:
                    <DayPickerInput
                        formatDate={formatDate}
                        parseDate={parseDate}
                        placeholder=""
                        className="date-picker-field"
                        onDayChange={setDateFieldHandler.bind(this, index, 'apparel_due_date')}
                        value={fieldValue}
                        dayPickerProps={dayPickerProps}
                        component={(props) => {
                            return <input {...props} value={props.value} readOnly />;
                        }}
                    />
                </div>
            );
        }

        let commentsJson = [];
        if (step.comments_json !== undefined && step.comments_json != null && step.comments_json !== '') {
            commentsJson = JSON.parse(step.comments_json);
        }

        let closeoutCommentsContainer = null;

        if (parseInt(step.status) === 4 && commentsJson.length === 0) {
            closeoutCommentsContainer = (
                <div className="date closeout-comments">
                    <b>Closeout Comments:</b> No comments left
                </div>
            );
        }

        if (commentsJson.length > 0) {
            closeoutCommentsContainer = (
                <div className="date closeout-comments">
                    <b>Closeout Comments:</b> {commentsJson.join(' | ')}
                </div>
            );
        }

        return (
            <Fragment key={'step-' + index}>
                <div className="step">
                    <div className="column1">
                        <div className="department">
                            <div className="step-department">
                                Department:
                                <select value={step.department_id} onChange={setStepFieldHandler.bind(this, index, 'department_id')}>
                                    {departmentOptions}
                                </select>
                            </div>
                            <div className="step-manager">
                                Manager:
                                <select value={step.department_manager_id} onChange={setStepFieldHandler.bind(this, index, 'department_manager_id')}>
                                    {managerOptions}
                                </select>
                            </div>
                            <div className="step-order">
                                Step Order:
                                <select value={step.step_order} onChange={setStepFieldHandler.bind(this, index, 'step_order')}>
                                    {stepOrderOptions}
                                </select>
                            </div>
                            {stepPoNumber}
                            {stepVendorQuote}
                            {stepVendorPrice}
                            {stepVendorDueDate}
                            {stepApparelDueDate}
                            {stepPromotionalDueDate}
                            {postageField}
                            {dpFileContainer}
                            {dataPostalContainer}
                            <div className="date"><b>Created:</b> {dateToTimezone(step.created_at, 'MMMM Do YYYY, h:mm a')}</div>
                            <div className="date"><b>Started:</b> {startedDate}</div>
                            <div className="date"><b>Total Time:</b> {step.lifecycle}</div>
                            <div className="date"><b>Closed:</b> {finishedDate}</div>
                            {pauseDateRow}
                            {postageRow}
                            {outOnProofContainer}
                        </div>
                        {assignedPeopleContainer}
                        {assignedVendorsContainer}
                        {closeoutCommentsContainer}
                        <div className="buttons">
                            {deleteButton}
                            {saveButton}
                            {closeButton}
                            {pauseButton}
                            {unpauseButton}
                        </div>
                    </div>
                    <div className={dataPostalClassName}>
                        <div className="title">
                            <div>Notes:</div>
                            <div className="job-number">Job Number: {props.id} <div className="barcode">*&amp;001-{props.id}*</div></div>
                        </div>
                        <textarea value={step.notes} onChange={setStepFieldHandler.bind(this, index, 'notes')} />
                        <div className="data-postal-information">
                            {dataPostalInformationName} {dataPostalInformation}
                        </div>
                        {pauseComments}
                    </div>
                </div>
                <div className="divider"></div>
            </Fragment>
        );
    });

    return (
        <div className="steps-container">
            {renderSteps()}
        </div>
    );
});

export default Steps;
