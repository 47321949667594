import Immutable from 'immutable';
import * as types from './ChristyRfq.types';
import { empty } from './../../utils';

const initialState = {
    id: null,
    fetchingRequest: false,
    fields: {
        artwork_required: 1,
        mailing_required: 1,
        requires_print: 0,
        requires_promotional: 0,
        requires_apparel: 0,
        requires_signage: 0,
        folding_required: 0,
        message_to_customer: 'Price is valid for 14 days from estimate date. All required postage cost must be paid before your project goes into full production.',
    },
    fetchingInformation: false,
    information: {
        productTypes: [],
        paperCategories: [],
        papers: [],
        estimators: [],
    },
    files: [],
    history: null,
    submitting: false,
    sending: false,
    saving: false,
    submittedId: null,
    pricing: [
        { quantity: '', price: '', outsource: '', commission: '', add_markup: '', final_price: '' },
        { quantity: '', price: '', outsource: '', commission: '', add_markup: '', final_price: '' },
        { quantity: '', price: '', outsource: '', commission: '', add_markup: '', final_price: '' },
    ],
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_REQUEST:
            return state.setIn(['fetchingRequest'], true).toJS();

        case types.FETCH_REQUEST_SUCCESS:
            return state.setIn(['fetchingRequest'], false)
                .setIn(['fields'], action.payload)
                .setIn(['files'], JSON.parse(action.payload.files))
                .toJS();

        case types.FETCH_REQUEST_ERROR:
            return state.setIn(['fetchingRequest'], false).toJS();

        case types.FETCH_INFORMATION:
            return state.setIn(['fetchingInformation'], true).toJS();

        case types.FETCH_INFORMATION_SUCCESS:
            return state.setIn(['fetchingInformation'], false)
                .setIn(['information', 'productTypes'], action.payload.productTypes)
                .setIn(['information', 'paperCategories'], action.payload.paperCategories)
                .setIn(['information', 'papers'], action.payload.papers)
                .setIn(['information', 'estimators'], action.payload.estimators)
                .toJS();

        case types.FETCH_INFORMATION_ERROR:
            return state.setIn(['fetchingInformation'], false).toJS();

        case types.SUBMIT_REQUEST:
            return state.setIn(['submitting'], true).toJS();

        case types.SUBMIT_REQUEST_SUCCESS:
            return state.setIn(['submitting'], false).toJS();

        case types.SUBMIT_REQUEST_ERROR:
            return state.setIn(['submitting'], false).toJS();

        case types.SEND_ESTIMATE:
            return state.setIn(['sending'], true).toJS();

        case types.SEND_ESTIMATE_SUCCESS:
            return state.setIn(['sending'], false).toJS();

        case types.SEND_ESTIMATE_ERROR:
            return state.setIn(['sending'], false).toJS();

        case types.SAVE_REQUEST:
            return state.setIn(['saving'], true).toJS();

        case types.SAVE_REQUEST_SUCCESS:
            return state.setIn(['saving'], false).toJS();

        case types.SAVE_REQUEST_ERROR:
            return state.setIn(['saving'], false).toJS();

        case types.SET_FIELD:
            return state.setIn(['fields', action.payload.name], action.payload.value).toJS();

        case types.SET_ID:
            return state.setIn(['id'], action.payload).toJS();

        case types.SET_HISTORY:
            return state.setIn(['history'], action.payload).toJS();

        case types.SET_SUBMITTED_ID:
            return state.setIn(['submittedId'], action.payload).toJS();

        case types.ADD_FILE:
            return state.updateIn(['files'], (files) => {
                return files.push(action.payload);
            }).toJS();

        case types.REMOVE_FILE:
            return state.updateIn(['files'], (files) => {
                return files.delete(action.payload);
            }).toJS();

        case types.CLEAR_FILES:
            return state.updateIn(['files'], (files) => {
                return files.clear();
            }).toJS();

        case types.SET_PRICING_FIELD:
            return state.updateIn(['pricing'], (pricing) => {
                const pricingNew = pricing.toJS();
                pricingNew[action.payload.index][action.payload.field] = action.payload.value;

                let finalPrice = 0;
                if (!empty(pricingNew[action.payload.index]['price'])) {
                    finalPrice += parseFloat(pricingNew[action.payload.index]['price']);
                }
                if (!empty(pricingNew[action.payload.index]['outsource'])) {
                    finalPrice += parseFloat(pricingNew[action.payload.index]['outsource']);
                }
                if (!empty(pricingNew[action.payload.index]['commission'])) {
                    finalPrice += finalPrice * parseFloat(pricingNew[action.payload.index]['commission']) / 100;
                }
                if (!empty(pricingNew[action.payload.index]['add_markup'])) {
                    finalPrice += finalPrice * parseFloat(pricingNew[action.payload.index]['add_markup']) / 100;
                }
                
                pricingNew[action.payload.index]['final_price'] = finalPrice;

                return pricingNew;
            }).toJS();

        case types.SET_PRICING:
            return state.setIn(['pricing'], action.payload).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}