import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Accounts from './Accounts';
import DeleteModal from './DeleteModal';
import FormModal from './FormModal';
import * as actions from './ApAccounts.actions';
import * as selectors from './ApAccounts.selectors';
import './ApAccounts.scss';
import PqtLayout from './../PqtLayout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import * as dialog from '../common/dialog';
import * as loginSelectors from './../Login/Login.selectors';
import { Redirect } from 'react-router-dom';

const ApAccounts = (props) => {
    /* State to props */
    const fetchingAccounts = useSelector(selectors.getFetchingAccounts);
    const accounts = useSelector(selectors.getAccounts, stringifyEqualityCheck);
    const pagination = useSelector(selectors.getPagination, stringifyEqualityCheck);
    const sort = useSelector(selectors.getSort, stringifyEqualityCheck);
    const idForDelete = useSelector(selectors.getIdForDelete, stringifyEqualityCheck);
    const filerFields = useSelector(selectors.getFilterFields, stringifyEqualityCheck);
    const fields = useSelector(selectors.getFields, stringifyEqualityCheck);
    const savingAccount = useSelector(selectors.getSavingAccount);
    const fetchingAccount = useSelector(selectors.getFetchingAccount);
    const isDeleting = useSelector(selectors.getIsDeleting);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const setSort = useCallback((column, type) => dispatch(actions.setSort(column, type)), [dispatch]);
    const setActivePage = useCallback((name, value) => dispatch(actions.setActivePage(name, value)), [dispatch]);
    const fetchAccounts = useCallback((options, isWsUpdate) => dispatch(actions.fetchAccounts(options, isWsUpdate)), [dispatch]);
    const setIdForDelete = useCallback((id) => dispatch(actions.setIdForDelete(id)), [dispatch]);
    const changeFilter = useCallback((name, value, fetch) => dispatch(actions.changeFilter(name, value, fetch)), [dispatch]);
    const setField = useCallback((field, value) => dispatch(actions.setField(field, value)), [dispatch]);
    const saveAccount = useCallback(() => dispatch(actions.saveAccount()), [dispatch]);
    const resetFields = useCallback((value) => dispatch(actions.resetFields(value)), [dispatch]);
    const fetchAccount = useCallback((id) => dispatch(actions.fetchAccount(id)), [dispatch]);
    const deleteAccount = useCallback(() => dispatch(actions.deleteAccount()), [dispatch]);
    const showDeleteModal = useCallback(() => dispatch(dialog.actions.showDialog('delete-modal')), [dispatch]);
    const hideDeleteModal = useCallback(() => dispatch(dialog.actions.hideDialog('delete-modal')), [dispatch]);
    const showFormModal = useCallback(() => dispatch(dialog.actions.showDialog('ap-account-form-modal')), [dispatch]);
    const hideFormModal = useCallback(() => dispatch(dialog.actions.hideDialog('ap-account-form-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        fetchAccounts();
    }, [fetchAccounts]);

    if (user != null && parseInt(user.id) !== 61) {     // Hugh
        return <Redirect to="/dashboard" />;
    }

    return (
        <PqtLayout title="AP Accounts" history={props.history}>
            <Helmet><title>AP Accounts - Stagecoach</title></Helmet>
            <div className="ap-accounts-container">
                <Accounts
                    accounts={accounts}
                    pagination={pagination}
                    fetchingAccounts={fetchingAccounts}
                    history={props.history}
                    sort={sort}
                    user={user}
                    filerFields={filerFields}
                    setSort={setSort}
                    setActivePage={setActivePage}
                    showDeleteModal={showDeleteModal}
                    setIdForDelete={setIdForDelete}
                    changeFilter={changeFilter}
                    fetchAccounts={fetchAccounts}
                    fetchAccount={fetchAccount}
                    showFormModal={showFormModal}
                    setField={setField}
                />
                <DeleteModal
                    idForDelete={idForDelete}
                    isDeleting={isDeleting}
                    hideDeleteModal={hideDeleteModal}
                    setIdForDelete={setIdForDelete}
                    setField={setField}
                    deleteAccount={deleteAccount}
                />
                <FormModal 
                    fields={fields}
                    savingAccount={savingAccount}
                    fetchingAccount={fetchingAccount}
                    setField={setField}
                    hideFormModal={hideFormModal}
                    saveAccount={saveAccount}
                    resetFields={resetFields}
                />
            </div>
        </PqtLayout>
    );
};

export default ApAccounts;
