import React from 'react';
import numeral from 'numeral';
import { calculateTotals } from './../Estimate.actions';
import { empty } from './../../../utils';

const QuantitiesTotals = React.memo((props) => {
    const estimate = props.estimate;

    const totals1 = calculateTotals(props, false, props.result1);
    const totals2 = calculateTotals(props, false, props.result2);
    const totals3 = calculateTotals(props, false, props.result3);

    let quantity1 = 0;
    if (parseInt(props.estimateType) === 10) {  // Mail ONly
        quantity1 = props.estimate.quantity;
    } else if (!empty(props.result1)) {
        quantity1 = props.result1.request_params.quantity;
    }

    let quantity2 = 0;
    if (parseInt(props.estimateType) === 10) {  // Mail ONly
        quantity2 = props.estimate.quantity2;
    } else if (!empty(props.result2)) {
        quantity2 = props.result2.request_params.quantity;
    }

    let quantity3 = 0;
    if (parseInt(props.estimateType) === 10) {  // Mail ONly
        quantity3 = props.estimate.quantity3;
    } else if (!empty(props.result3)) {
        quantity3 = props.result3.request_params.quantity;
    }

    const renderTotal = (quantity, total) => {
        if (empty(quantity) || parseFloat(quantity) === 0) {
            return props.checkEstimating(numeral(0).format('$0,0.00'));
        }

        return props.checkEstimating(numeral(total).format('$0,0.00'));
    };

    if (estimate.full_outside_job) {
        return (
            <div className="quantities-totals">
                <h3>Quantity Selection:</h3>
                <div className="quantity-container">
                    <div className="quantity-title">
                        <input
                            type="radio"
                            value="1"
                            checked={parseInt(props.estimate.selected_quantity) === 1}
                            onChange={props.handleSetField.bind(this, 'selected_quantity')}
                        />
                        Qty: {numeral(quantity1).format('0,0')}
                    </div>
                    <div className="total-line">
                        <div><b>Subtotal:</b></div>
                        <div className="price-div">{renderTotal(quantity1, totals1.subtotal)}</div>
                    </div>
                    <div className="total-line">
                        <div>Outside Service:</div>
                        <div className="price-div">{renderTotal(quantity1, totals1.outsidePrice)}</div>
                    </div>
                    <div className="total-line">
                        <div><b>Total:</b></div>
                        <div className="price-div"><b>{renderTotal(quantity1, totals1.total)}</b></div>
                    </div>
                </div>
                <div className="quantity-container">
                    <div className="quantity-title">
                        <input
                            type="radio"
                            value="2"
                            checked={parseInt(props.estimate.selected_quantity) === 2}
                            onChange={props.handleSetField.bind(this, 'selected_quantity')}
                        />
                        Qty: {numeral(quantity2).format('0,0')}
                    </div>
                    <div className="total-line">
                        <div>Subtotal:</div>
                        <div className="price-div">{renderTotal(quantity2, totals2.subtotal)}</div>
                    </div>
                    <div className="total-line">
                        <div>Outside Service:</div>
                        <div className="price-div">{renderTotal(quantity2, totals2.outsidePrice)}</div>
                    </div>
                    <div className="total-line">
                        <div><b>Total:</b></div>
                        <div className="price-div"><b>{renderTotal(quantity2, totals2.total)}</b></div>
                    </div>
                </div>
                <div className="quantity-container">
                    <div className="quantity-title">
                        <input
                            type="radio"
                            value="3"
                            checked={parseInt(props.estimate.selected_quantity) === 3}
                            onChange={props.handleSetField.bind(this, 'selected_quantity')}
                        />
                        Qty: {numeral(quantity3).format('0,0')}
                    </div>
                    <div className="total-line">
                        <div>Subtotal:</div>
                        <div className="price-div">{renderTotal(quantity3, totals3.subtotal)}</div>
                    </div>
                    <div className="total-line">
                        <div>Outside Service:</div>
                        <div className="price-div">{renderTotal(quantity3, totals3.outsidePrice)}</div>
                    </div>
                    <div className="total-line">
                        <div><b>Total:</b></div>
                        <div className="price-div"><b>{renderTotal(quantity3, totals3.total)}</b></div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="quantities-totals">
            <h3>Quantity Selection:</h3>
            <div className="quantity-container">
                <div className="quantity-title">
                    <input
                        type="radio"
                        value="1"
                        checked={parseInt(props.estimate.selected_quantity) === 1}
                        onChange={props.handleSetField.bind(this, 'selected_quantity')}
                    />
                    Qty: {numeral(quantity1).format('0,0')}
                </div>
                <div className="total-line">
                    <div>Subtotal:</div>
                    <div className="price-div">{renderTotal(quantity1, totals1.subtotal)}</div>
                </div>
                <div className="total-line">
                    <div>Add. Price:</div>
                    <div className="price-div">{renderTotal(quantity1, totals1.markupPrice)}</div>
                </div>
                <div className="total-line">
                    <div>Outside Service:</div>
                    <div className="price-div">{renderTotal(quantity1, totals1.outsidePrice)}</div>
                </div>
                <div className="total-line">
                    <div>Commission:</div>
                    <div className="price-div">{renderTotal(quantity1, totals1.commission)}</div>
                </div>
                <div className="total-line">
                    <div>Tax:</div>
                    <div className="price-div">{renderTotal(quantity1, totals1.tax)}</div>
                </div>
                <div className="total-line">
                    <div><b>Total:</b></div>
                    <div className="price-div"><b>{renderTotal(quantity1, totals1.total)}</b></div>
                </div>
            </div>
            <div className="quantity-container">
                <div className="quantity-title">
                    <input
                        type="radio"
                        value="2"
                        checked={parseInt(props.estimate.selected_quantity) === 2}
                        onChange={props.handleSetField.bind(this, 'selected_quantity')}
                    />
                    Qty: {numeral(quantity2).format('0,0')}
                </div>
                <div className="total-line">
                    <div>Subtotal:</div>
                    <div className="price-div">{renderTotal(quantity2, totals2.subtotal)}</div>
                </div>
                <div className="total-line">
                    <div>Add. Price:</div>
                    <div className="price-div">{renderTotal(quantity2, totals2.markupPrice)}</div>
                </div>
                <div className="total-line">
                    <div>Outside Service:</div>
                    <div className="price-div">{renderTotal(quantity2, totals2.outsidePrice)}</div>
                </div>
                <div className="total-line">
                    <div>Commission:</div>
                    <div className="price-div">{renderTotal(quantity2, totals2.commission)}</div>
                </div>
                <div className="total-line">
                    <div>Tax:</div>
                    <div className="price-div">{renderTotal(quantity2, totals2.tax)}</div>
                </div>
                <div className="total-line">
                    <div><b>Total:</b></div>
                    <div className="price-div"><b>{renderTotal(quantity2, totals2.total)}</b></div>
                </div>
            </div>
            <div className="quantity-container">
                <div className="quantity-title">
                    <input
                        type="radio"
                        value="3"
                        checked={parseInt(props.estimate.selected_quantity) === 3}
                        onChange={props.handleSetField.bind(this, 'selected_quantity')}
                    />
                    Qty: {numeral(quantity3).format('0,0')}
                </div>
                <div className="total-line">
                    <div>Subtotal:</div>
                    <div className="price-div">{renderTotal(quantity3, totals3.subtotal)}</div>
                </div>
                <div className="total-line">
                    <div>Add. Price:</div>
                    <div className="price-div">{renderTotal(quantity3, totals3.markupPrice)}</div>
                </div>
                <div className="total-line">
                    <div>Outside Service:</div>
                    <div className="price-div">{renderTotal(quantity3, totals3.outsidePrice)}</div>
                </div>
                <div className="total-line">
                    <div>Commission:</div>
                    <div className="price-div">{renderTotal(quantity3, totals3.commission)}</div>
                </div>
                <div className="total-line">
                    <div>Tax:</div>
                    <div className="price-div">{renderTotal(quantity3, totals3.tax)}</div>
                </div>
                <div className="total-line">
                    <div><b>Total:</b></div>
                    <div className="price-div"><b>{renderTotal(quantity3, totals3.total)}</b></div>
                </div>
            </div>
        </div>
    );
});

export default QuantitiesTotals;
