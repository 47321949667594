import React from 'react';

const Footer = React.memo((props) => {
    const handleClick = (event) => {
        event.preventDefault();
        props.showTermOfUseModal();
    };

    return (
        <div className="footer-container">
            <div className="powered">
                <div>Powered by <span>Stagecoach</span></div>
                <div className="version">
                    Version {process.env.REACT_APP_VERSION} | <a href="/" onClick={handleClick} className="term-of-use-link">Term of Use</a>
                </div>
            </div>
        </div>

    );
});

export default Footer;
