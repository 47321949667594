import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Form from './Form';
import ApproveModal from './ApproveModal';
import PushInvoiceModal from './PushInvoiceModal';
import ReturnInvoiceModal from './ReturnInvoiceModal';
import UncombineInvoiceModal from './UncombineInvoiceModal';
import SplitInvoiceModal from './SplitInvoiceModal';
import * as actions from './PqtInvoice.actions';
import * as selectors from './PqtInvoice.selectors';
import './PqtInvoice.scss';
import PqtLayout from './../PqtLayout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import * as dialog from '../common/dialog';
import * as loginSelectors from './../Login/Login.selectors';
import { Redirect } from 'react-router-dom';

const PqtInvoice = (props) => {
    /* State to props */
    const fetching = useSelector(selectors.getFetching);
    const invoice = useSelector(selectors.getInvoice, stringifyEqualityCheck);
    const information = useSelector(selectors.getInformation, stringifyEqualityCheck);
    const fetchingCustomerData = useSelector(selectors.getFetchingCustomerData);
    const isSaving = useSelector(selectors.getIsSaving);
    const isApproving = useSelector(selectors.getIsApproving);
    const isPushing = useSelector(selectors.getIsPushing);
    const isReturning = useSelector(selectors.getIsReturning);
    const customerEmail = useSelector(selectors.getCustomerEmail);
    const jobDueDate = useSelector(selectors.getJobDueDate);
    const isSynchingQbTotal = useSelector(selectors.getIsSynchingQbTotal);
    const isUncombining = useSelector(selectors.getIsUncombining);
    const isSplittingInvoice = useSelector(selectors.getIsSplittingInvoice);
    const fetchingPostageEscrow = useSelector(selectors.getFetchingPostageEscrow);
    const postageEscrow = useSelector(selectors.getPostageEscrow);
    const splitPercent1 = useSelector(selectors.getSplitPercent1);
    const splitPercent2 = useSelector(selectors.getSplitPercent2);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const fetchInvoice = useCallback((id) => dispatch(actions.fetchInvoice(id)), [dispatch]);
    const fetchInformation = useCallback(() => dispatch(actions.fetchInformation()), [dispatch]);
    const setField = useCallback((field, value) => dispatch(actions.setField(field, value)), [dispatch]);
    const fetchCustomerData = useCallback((id) => dispatch(actions.fetchCustomerData(id)), [dispatch]);
    const saveInvoice = useCallback(() => dispatch(actions.saveInvoice()), [dispatch]);
    const setItemField = useCallback((index, field, value) => dispatch(actions.setItemField(index, field, value)), [dispatch]);
    const addLine = useCallback(() => dispatch(actions.addLine()), [dispatch]);
    const removeLine = useCallback((index) => dispatch(actions.removeLine(index)), [dispatch]);
    const approveInvoice = useCallback(() => dispatch(actions.approveInvoice()), [dispatch]);
    const qBPush = useCallback((option) => dispatch(actions.qBPush(option)), [dispatch]);
    const returnInvoice = useCallback((stage) => dispatch(actions.returnInvoice(stage)), [dispatch]);
    const uncombineInvoice = useCallback((history) => dispatch(actions.uncombineInvoice(history)), [dispatch]);
    const splitInvoice = useCallback((history) => dispatch(actions.splitInvoice(history)), [dispatch]);
    const setCustomerEmail = useCallback((value) => dispatch(actions.setCustomerEmail(value)), [dispatch]);
    const syncQbTotal = useCallback((value) => dispatch(actions.syncQbTotal(value)), [dispatch]);
    const changeSortPosition = useCallback((index, value, type) => dispatch(actions.changeSortPosition(index, value, type)), [dispatch]);
    const setSplitPercent1 = useCallback((value) => dispatch(actions.setSplitPercent1(value)), [dispatch]);
    const setSplitPercent2 = useCallback((value) => dispatch(actions.setSplitPercent2(value)), [dispatch]);
    const showApproveModal = useCallback(() => dispatch(dialog.actions.showDialog('approve-modal')), [dispatch]);
    const hideApproveModal = useCallback(() => dispatch(dialog.actions.hideDialog('approve-modal')), [dispatch]);
    const showPushInvoiceModal = useCallback(() => dispatch(dialog.actions.showDialog('push-invoice-modal')), [dispatch]);
    const hidePushInvoiceModal = useCallback(() => dispatch(dialog.actions.hideDialog('push-invoice-modal')), [dispatch]);
    const showReturnInvoiceModal = useCallback(() => dispatch(dialog.actions.showDialog('return-invoice-modal')), [dispatch]);
    const hideReturnInvoiceModal = useCallback(() => dispatch(dialog.actions.hideDialog('return-invoice-modal')), [dispatch]);
    const showUncombineInvoiceModal = useCallback(() => dispatch(dialog.actions.showDialog('uncombine-invoice-modal')), [dispatch]);
    const hideUncombineInvoiceModal = useCallback(() => dispatch(dialog.actions.hideDialog('uncombine-invoice-modal')), [dispatch]);
    const showSplitInvoiceModal = useCallback(() => dispatch(dialog.actions.showDialog('split-invoice-modal')), [dispatch]);
    const hideSplitInvoiceModal = useCallback(() => dispatch(dialog.actions.hideDialog('split-invoice-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        fetchInvoice(props.match.params.id);
        fetchInformation();
    }, [dispatch, props.match.params.id, fetchInformation, fetchInvoice]);

    let statusName = '';
    if (invoice != null && invoice.combined) {
        statusName = ' (Combined)';
    } else if (invoice != null && parseInt(invoice.status) === 1) {
        statusName = ' (Preliminary invoice is ready)';
    } else if (invoice != null && parseInt(invoice.status) === 2) {
        statusName = ' (Closed and ready to start invoicing)';
    } else if (invoice != null && parseInt(invoice.status) === 3) {
        statusName = ' (Approved ready for accounting)';
    } else if (invoice != null && parseInt(invoice.status) === 4) {
        statusName = ' (Final & Archived)';
    }

    if (user != null && ![1, 2, 3, 4, 5, 9].includes(parseInt(user.pqt_type)) && !user.is_pqt_accounting) {
        return <Redirect to="/dashboard" />;
    }

    if (user != null && [76].includes(parseInt(user.id))) {
        return <Redirect to="/dashboard" />;
    }

    return (
        <PqtLayout title={'Invoice #' + props.match.params.id + statusName} history={props.history}>
            <Helmet><title>Invoice #{props.match.params.id} - Stagecoach</title></Helmet>
            <div className="pqt-invoice-container">
                <Form
                    invoice={invoice}
                    fetching={fetching}
                    information={information}
                    fetchingCustomerData={fetchingCustomerData}
                    isSaving={isSaving}
                    isApproving={isApproving}
                    isPushing={isPushing}
                    isReturning={isReturning}
                    user={user}
                    jobDueDate={jobDueDate}
                    isSynchingQbTotal={isSynchingQbTotal}
                    fetchingPostageEscrow={fetchingPostageEscrow}
                    postageEscrow={postageEscrow}
                    history={props.history}
                    changeSortPosition={changeSortPosition}
                    setField={setField}
                    fetchCustomerData={fetchCustomerData}
                    saveInvoice={saveInvoice}
                    setItemField={setItemField}
                    addLine={addLine}
                    removeLine={removeLine}
                    showApproveModal={showApproveModal}
                    showPushInvoiceModal={showPushInvoiceModal}
                    showReturnInvoiceModal={showReturnInvoiceModal}
                    showUncombineInvoiceModal={showUncombineInvoiceModal}
                    showSplitInvoiceModal={showSplitInvoiceModal}
                    syncQbTotal={syncQbTotal}
                />
            </div>
            <ApproveModal
                isApproving={isApproving}
                approveInvoice={approveInvoice}
                hideApproveModal={hideApproveModal}
            />
            <PushInvoiceModal
                isPushing={isPushing}
                customerEmail={customerEmail}
                qBPush={qBPush}
                hidePushInvoiceModal={hidePushInvoiceModal}
                setCustomerEmail={setCustomerEmail}
            />
            <ReturnInvoiceModal
                isReturning={isReturning}
                returnInvoice={returnInvoice}
                hideReturnInvoiceModal={hideReturnInvoiceModal}
            />
            <UncombineInvoiceModal
                isUncombining={isUncombining}
                history={props.history}
                uncombineInvoice={uncombineInvoice}
                hideUncombineInvoiceModal={hideUncombineInvoiceModal}
            />
            <SplitInvoiceModal
                isSplittingInvoice={isSplittingInvoice}
                splitPercent1={splitPercent1}
                splitPercent2={splitPercent2}
                history={props.history}
                splitInvoice={splitInvoice}
                hideSplitInvoiceModal={hideSplitInvoiceModal}
                setSplitPercent1={setSplitPercent1}
                setSplitPercent2={setSplitPercent2}
            />
        </PqtLayout>
    );
};

export default PqtInvoice;
