import axios from 'axios';
import * as types from './PurchaseOrder.types';
import { validateAuthInResponse, showNotification } from './../../utils';
import * as selectors from './PurchaseOrder.selectors';
import * as dialog from '../common/dialog';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchPurchaseOrder = (id) => (dispatch) => {
    dispatch({ type: types.FETCH_PURCHASE_ORDER, payload: null });
    axios.get(apiUrl + '/purchase-orders/' + id + '?expand=items,auditHistory,vendorAddress',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_PURCHASE_ORDER_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_PURCHASE_ORDER_ERROR));
        });
}

export const fetchInformation = () => (dispatch) => {
    dispatch({ type: types.FETCH_INFORMATION, payload: null });
    axios.get(apiUrl + '/purchase-order/data',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_INFORMATION_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_INFORMATION_ERROR));
        });
}

export const fetchVendorData = (id) => (dispatch) => {
    dispatch({ type: types.FETCH_VENDOR_DATA, payload: null });
    axios.get(apiUrl + '/purchase-order/vendor-data?id=' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_VENDOR_DATA_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_VENDOR_DATA_ERROR));
        });
}

export const savePurchaseOrder = (history) => (dispatch, getState) => {
    const purchaseOrder = selectors.getPurchaseOrder(getState());
    if (!validateForm(purchaseOrder)) {
        return;
    }

    let endpoint = apiUrl + '/purchase-orders';
    let method = 'post';
    if (purchaseOrder.id && purchaseOrder.id != null) {
        endpoint = apiUrl + '/purchase-orders/' + purchaseOrder.id;
        method = 'put';
    }

    dispatch({ type: types.SAVE_PURCHASE_ORDER, payload: null });
    axios({
        method: method,
        url: endpoint,
        data: purchaseOrder,
        headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
    }).then((response) => {
        dispatch({ type: types.SAVE_PURCHASE_ORDER_SUCCESS, payload: response.data });
        showNotification('Purchase Order Saved', 'The Purchase Order has been saved successfully', 'success');

        if (purchaseOrder.id && purchaseOrder.id != null) {
            dispatch(fetchPurchaseOrder(purchaseOrder.id));
        } else {
            history.push('/purchase-order/' + response.data.id);
        }
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.SAVE_PURCHASE_ORDER_ERROR));
    });
}

export const qBPush = (option) => (dispatch, getState) => {
    const purchaseOrder = selectors.getPurchaseOrder(getState());
    const vendorEmail = selectors.getVendorEmail(getState());
    if (!validateForm(purchaseOrder)) {
        return;
    }

    dispatch({ type: types.QB_PUSH, payload: null });
    axios.post(apiUrl + '/purchase-order/push-to-qb', { id: purchaseOrder.id, option, email: vendorEmail },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.QB_PUSH_SUCCESS, payload: response.data });

            const validateVendorSent = (response) => {
                if (response.data.vendorSent) {
                    showNotification('Purchase Order sent to the Vendor', 'The Purchase Order has been sent to the Vendor email', 'success');
                }
            };

            const validatePurchaseOrderSaved = (response) => {
                if (parseInt(response.data.code) === 1) {
                    showNotification('Purchase Order saved in QB', 'The Purchase Order has been saved successfully in QuickBooks', 'success');
                    dispatch(fetchPurchaseOrder(purchaseOrder.id));
                } else {
                    showNotification('Purchase Order was not saved', response.data.message, 'warning');
                }
            };

            if (parseInt(option) === 1) {
                validateVendorSent(response);
                validatePurchaseOrderSaved(response);
            } else if (parseInt(option) === 2) {
                validateVendorSent(response);
            } else if (parseInt(option) === 3) {
                validatePurchaseOrderSaved(response);
            }

            dispatch(dialog.actions.hideDialog('push-modal'));
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.QB_PUSH_ERROR));
        });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null
    });
}

export const setField = (field, value) => (dispatch) => {
    dispatch({
        type: types.SET_FIELD,
        payload: { field, value }
    });
}

export const setItemField = (index, field, value) => (dispatch) => {
    dispatch({
        type: types.SET_ITEM_FIELD,
        payload: { index, field, value }
    });
}

export const addLine = () => (dispatch) => {
    dispatch({
        type: types.ADD_LINE,
        payload: null
    });
}

export const removeLine = (index) => (dispatch) => {
    dispatch({
        type: types.REMOVE_LINE,
        payload: index
    });
}

export const validateForm = (values) => {
    if (values.vendor_id == null || values.vendor_id === '') {
        showNotification('Complete Information', 'Select a Vendor', 'info');
        return false;
    }
    if (values.vendor_email == null || values.vendor_email === '') {
        showNotification('Complete Information', 'Enter the Vendor Email', 'info');
        return false;
    }

    let hasMailingLists = false;
    for (const item of values.items) {
        if (
            parseInt(item.purchase_order_category_id) === 8 &&      // Mailing Lists
            parseInt(item.purchase_order_class_id) === 2            // Data
        ) {
            hasMailingLists = true;
            break;
        }
    }

    if (hasMailingLists && (values.stagecoach_number == null || values.stagecoach_number === '')) {
        showNotification('Complete Information', 'Enter the Stagecoach No.', 'info');
        return false;
    }

    for (const item of values.items) {
        if (
            parseInt(item.purchase_order_category_id) === 8 &&      // Mailing Lists
            parseInt(item.purchase_order_class_id) === 2            // Data
        ) {
            if (
                item.purchase_order_category_id === '' || item.purchase_order_category_id == null ||
                item.purchase_order_class_id === '' || item.purchase_order_class_id == null ||
                item.provider === '' || item.provider == null ||
                item.type === '' || item.type == null ||
                item.geography === '' || item.geography == null ||
                item.zip === '' || item.zip == null
            ) {
                showNotification('Complete Information', 'Enter the information for all the Items', 'info');
                return false;
            }
        } else {
            if (
                item.purchase_order_category_id === '' || item.purchase_order_category_id == null ||
                item.purchase_order_class_id === '' || item.purchase_order_class_id == null ||
                item.description === '' || item.description == null ||
                item.amount === '' || item.amount == null || isNaN(item.amount)
            ) {
                showNotification('Complete Information', 'Enter the information for all the Items', 'info');
                return false;
            }
        }
    }

    return true;
}

export const setVendorEmail = (value) => (dispatch) => {
    dispatch({
        type: types.SET_VENDOR_EMAIL,
        payload: value
    });
}

export const changeSortPosition = (index, value, type) => (dispatch) => {
    dispatch({
        type: types.CHANGE_SORT_POSITION,
        payload: { index, value, type }
    });
}
