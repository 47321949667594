import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Form from './Form';
import PushModal from './PushModal';
import * as actions from './PurchaseOrder.actions';
import * as selectors from './PurchaseOrder.selectors';
import './PurchaseOrder.scss';
import PqtLayout from './../PqtLayout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import * as dialog from '../common/dialog';
import * as loginSelectors from './../Login/Login.selectors';
import { Redirect } from 'react-router-dom';

const PurchaseOrder = (props) => {
    /* State to props */
    const fetching = useSelector(selectors.getFetching);
    const purchaseOrder = useSelector(selectors.getPurchaseOrder, stringifyEqualityCheck);
    const information = useSelector(selectors.getInformation, stringifyEqualityCheck);
    const fetchingVendorData = useSelector(selectors.getFetchingVendorData);
    const isSaving = useSelector(selectors.getIsSaving);
    const isPushing = useSelector(selectors.getIsPushing);
    const vendorEmail = useSelector(selectors.getVendorEmail);
    const jobDueDate = useSelector(selectors.getJobDueDate);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const fetchPurchaseOrder = useCallback((id) => dispatch(actions.fetchPurchaseOrder(id)), [dispatch]);
    const fetchInformation = useCallback(() => dispatch(actions.fetchInformation()), [dispatch]);
    const setField = useCallback((field, value) => dispatch(actions.setField(field, value)), [dispatch]);
    const fetchVendorData = useCallback((id) => dispatch(actions.fetchVendorData(id)), [dispatch]);
    const savePurchaseOrder = useCallback((history) => dispatch(actions.savePurchaseOrder(history)), [dispatch]);
    const setItemField = useCallback((index, field, value) => dispatch(actions.setItemField(index, field, value)), [dispatch]);
    const addLine = useCallback(() => dispatch(actions.addLine()), [dispatch]);
    const removeLine = useCallback((index) => dispatch(actions.removeLine(index)), [dispatch]);
    const qBPush = useCallback((option) => dispatch(actions.qBPush(option)), [dispatch]);
    const setVendorEmail = useCallback((value) => dispatch(actions.setVendorEmail(value)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);
    const changeSortPosition = useCallback((index, value, type) => dispatch(actions.changeSortPosition(index, value, type)), [dispatch]);
    const showPushModal = useCallback(() => dispatch(dialog.actions.showDialog('push-modal')), [dispatch]);
    const hidePushModal = useCallback(() => dispatch(dialog.actions.hideDialog('push-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        if (props.match.params.id) {
            fetchPurchaseOrder(props.match.params.id);
        }
        fetchInformation();
        return () => {
            resetState();
        }
    }, [dispatch, props.match.params.id, fetchInformation, fetchPurchaseOrder, resetState]);

    // Kimberly (26), Randy (35), Debbie (42), Skip (48), John (52), Kevin Wilson (53), Gary (58), Robert (59), Hugh (61), Norm (63), 
    // Christy (64), Kellie R. (75), Brenda (100), Grace (348), AJ (571), Heather (28), Heather (34), Nichole (245), Mike (36), Kellie A. (78),
    // April (89), AP (1147), Beatriz (31), Nyke (33), Rocio (32), Terri (2335), Edward (1180), Kathy (76), Lupe (34), Stephanie (2419), Paul (3153), 
    // Kassandra (3566), Robert Duncan (3695)
    if (user != null && ![26, 35, 42, 48, 52, 53, 58, 59, 61, 63, 64, 75, 100, 348, 571, 28, 34, 245, 36, 78, 89, 1147, 31, 32, 33, 1180, 76, 2335, 34, 2419, 3153, 3566, 3695].includes(parseInt(user.id))) {
        return <Redirect to="/dashboard" />;
    }

    let title = 'Purchase Order';
    if (purchaseOrder && purchaseOrder.qb_number) {
        title = 'Purchase Order #' + purchaseOrder.qb_number;
    }

    return (
        <PqtLayout title={title} history={props.history}>
            <Helmet><title>{title} - Stagecoach</title></Helmet>
            <div className="purchase-order-container">
                <Form
                    purchaseOrder={purchaseOrder}
                    fetching={fetching}
                    information={information}
                    fetchingVendorData={fetchingVendorData}
                    isSaving={isSaving}
                    isPushing={isPushing}
                    user={user}
                    jobDueDate={jobDueDate}
                    history={props.history}
                    changeSortPosition={changeSortPosition}
                    setField={setField}
                    fetchVendorData={fetchVendorData}
                    savePurchaseOrder={savePurchaseOrder}
                    setItemField={setItemField}
                    addLine={addLine}
                    removeLine={removeLine}
                    showPushModal={showPushModal}
                />
            </div>
            <PushModal
                isPushing={isPushing}
                vendorEmail={vendorEmail}
                qBPush={qBPush}
                hidePushModal={hidePushModal}
                setVendorEmail={setVendorEmail}
            />
        </PqtLayout>
    );
};

export default PurchaseOrder;
