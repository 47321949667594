import axios from 'axios';
import * as types from './Users.types';
import * as selectors from './Users.selectors';
import * as loginActions from './../Login/Login.actions';
import { validateAuthInResponse } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchElements = () => (dispatch, getState) => {
    const filter = selectors.getFilter(getState());
    const pagination = selectors.getPagination(getState());

    let params = [];
    params.push('filter=' + filter);
    params.push('active-page=' + pagination.activePage);
    params.push('elements-per-page=' + pagination.elementsPerPage);

    dispatch({ type: types.FETCH_ELEMENTS, payload: null });
    axios.get(apiUrl + '/user/sc-users?' + params.join('&'),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_ELEMENTS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_ELEMENTS_ERROR));
        });
}

export const login = () => (dispatch, getState) => {
    const elements = selectors.getElements(getState());
    const id = selectors.getLoggingId(getState());

    let user = null;
    for (let i = 0; i < elements.length; i++) {
        if (parseInt(elements[i].id) === parseInt(id)) {
            user = elements[i];
            break;
        }
    }

    dispatch({ type: types.LOGIN, payload: null });
    axios.post(apiUrl.replace('/v1', '') + '/oauth2/token',
        {
            grant_type: 'password',
            client_id: 'testclient',  // TODO: Add this to environment variables
            client_secret: 'testpass',  // TODO: Add this to environment variables
            username: user.email,
            password: user.password_hash,
            loginType: 'pqt-user',
        })
        .then(response => {
            dispatch({ type: types.LOGIN_SUCCESS, payload: response.data });
            dispatch(loginActions.setTokens(response.data));
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.LOGIN_ERROR));
        });
}

export const setFilter = (value) => (dispatch) => {
    dispatch({
        type: types.SET_FILTER,
        payload: value
    });
}

export const setLoggingId = (value) => (dispatch) => {
    dispatch({
        type: types.SET_LOGGING_ID,
        payload: value
    });
}

export const setActivePage = (value) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_ACTIVE_PAGE,
            payload: value
        });
        resolve();
    }).then(() => {
        dispatch(fetchElements());
    });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null
    });
}