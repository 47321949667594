export const FETCH_REQUEST = 'QUOTE_REQUEST/FETCH_REQUEST';
export const FETCH_REQUEST_SUCCESS = 'QUOTE_REQUEST/FETCH_REQUEST_SUCCESS';
export const FETCH_REQUEST_ERROR = 'QUOTE_REQUEST/FETCH_REQUEST_ERROR';
export const FETCH_INFORMATION = 'QUOTE_REQUEST/FETCH_INFORMATION';
export const FETCH_INFORMATION_SUCCESS = 'QUOTE_REQUEST/FETCH_INFORMATION_SUCCESS';
export const FETCH_INFORMATION_ERROR = 'QUOTE_REQUEST/FETCH_INFORMATION_ERROR';
export const SAVE_REQUEST = 'QUOTE_REQUEST/SAVE_REQUEST';
export const SAVE_REQUEST_SUCCESS = 'QUOTE_REQUEST/SAVE_REQUEST_SUCCESS';
export const SAVE_REQUEST_ERROR = 'QUOTE_REQUEST/SAVE_REQUEST_ERROR';
export const SUBMIT_REQUEST = 'QUOTE_REQUEST/SUBMIT_REQUEST';
export const SUBMIT_REQUEST_SUCCESS = 'QUOTE_REQUEST/SUBMIT_REQUEST_SUCCESS';
export const SUBMIT_REQUEST_ERROR = 'QUOTE_REQUEST/SUBMIT_REQUEST_ERROR';
export const FETCH_SUBSTRATE_ACCESSORIES = 'QUOTE_REQUEST/FETCH_SUBSTRATE_ACCESSORIES';
export const FETCH_SUBSTRATE_ACCESSORIES_SUCCESS = 'QUOTE_REQUEST/FETCH_SUBSTRATE_ACCESSORIES_SUCCESS';
export const FETCH_SUBSTRATE_ACCESSORIES_ERROR = 'QUOTE_REQUEST/FETCH_SUBSTRATE_ACCESSORIES_ERROR';
export const DOWNLOAD_ESTIMATE = 'QUOTE_REQUEST/DOWNLOAD_ESTIMATE';
export const DOWNLOAD_ESTIMATE_SUCCESS = 'QUOTE_REQUEST/DOWNLOAD_ESTIMATE_SUCCESS';
export const DOWNLOAD_ESTIMATE_ERROR = 'QUOTE_REQUEST/DOWNLOAD_ESTIMATE_ERROR';
export const FETCH_MAIN_PART = 'QUOTE_REQUEST/FETCH_MAIN_PART';
export const FETCH_MAIN_PART_SUCCESS = 'QUOTE_REQUEST/FETCH_MAIN_PART_SUCCESS';
export const FETCH_MAIN_PART_ERROR = 'QUOTE_REQUEST/FETCH_MAIN_PART_ERROR';
export const DELETE_REQUEST = 'QUOTE_REQUEST/DELETE_REQUEST';
export const DELETE_REQUEST_SUCCESS = 'QUOTE_REQUEST/DELETE_REQUEST_SUCCESS';
export const DELETE_REQUEST_ERROR = 'QUOTE_REQUEST/DELETE_REQUEST_ERROR';
export const DUPLICATE_PART = 'QUOTE_REQUEST/DUPLICATE_PART';
export const DUPLICATE_PART_SUCCESS = 'QUOTE_REQUEST/DUPLICATE_PART_SUCCESS';
export const DUPLICATE_PART_ERROR = 'QUOTE_REQUEST/DUPLICATE_PART_ERROR';
export const SET_ACTIVE_TAB = 'QUOTE_REQUEST/SET_ACTIVE_TAB';
export const SET_FIELD = 'QUOTE_REQUEST/SET_FIELD';
export const SET_DATA_PROCESSING_ACTIVE_TAB = 'QUOTE_REQUEST/SET_DATA_PROCESSING_ACTIVE_TAB';
export const SET_DATA_PROCESSING_ITEM = 'QUOTE_REQUEST/SET_DATA_PROCESSING_ITEM';
export const SET_SELECTED_DATA_PROCESSING_ITEM = 'QUOTE_REQUEST/SET_SELECTED_DATA_PROCESSING_ITEM';
export const SET_VARIABLE_PRINT_ITEM = 'QUOTE_REQUEST/SET_VARIABLE_PRINT_ITEM';
export const ADD_DATA_PROCESSING_ITEMS = 'QUOTE_REQUEST/ADD_DATA_PROCESSING_ITEMS';
export const DELETE_DATA_PROCESSING_ITEM = 'QUOTE_REQUEST/DELETE_DATA_PROCESSING_ITEM';
export const SET_MAIL_ACTIVE_TAB = 'QUOTE_REQUEST/SET_MAIL_ACTIVE_TAB';
export const SET_INKJET_ITEM = 'QUOTE_REQUEST/SET_INKJET_ITEM';
export const SET_INSERTING_ITEM = 'QUOTE_REQUEST/SET_INSERTING_ITEM';
export const SET_METERING_ITEM = 'QUOTE_REQUEST/SET_METERING_ITEM';
export const SET_SELECTED_MAIL_ITEM = 'QUOTE_REQUEST/SET_SELECTED_MAIL_ITEM';
export const ADD_MAIL_ITEMS = 'QUOTE_REQUEST/ADD_MAIL_ITEMS';
export const DELETE_MAIL_ITEM = 'QUOTE_REQUEST/DELETE_MAIL_ITEM';
export const SET_HANDWORK_ITEM = 'QUOTE_REQUEST/SET_HANDWORK_ITEM';
export const ADD_HANDWORK_ITEMS = 'QUOTE_REQUEST/ADD_HANDWORK_ITEMS';
export const SET_SELECTED_HANDWORK_ITEM = 'QUOTE_REQUEST/SET_SELECTED_HANDWORK_ITEM';
export const DELETE_HANDWORK_ITEM = 'QUOTE_REQUEST/DELETE_HANDWORK_ITEM';
export const CHECK_LARGE_FORMAT_PRODUCT = 'QUOTE_REQUEST/CHECK_LARGE_FORMAT_PRODUCT';
export const CHECK_SUBSTRATE = 'QUOTE_REQUEST/CHECK_SUBSTRATE';
export const CHECK_FINISHING_ELEMENT = 'QUOTE_REQUEST/CHECK_FINISHING_ELEMENT';
export const SET_ID = 'QUOTE_REQUEST/SET_ID';
export const SET_HISTORY = 'QUOTE_REQUEST/SET_HISTORY';
export const RESET_FINISHING = 'QUOTE_REQUEST/RESET_FINISHING';
export const ADD_FILE = 'QUOTE_REQUEST/ADD_FILE';
export const REMOVE_FILE = 'QUOTE_REQUEST/REMOVE_FILE';
export const CLEAR_FILES = 'QUOTE_REQUEST/CLEAR_FILES';
export const SET_MAIN_PART_ID = 'QUOTE_REQUEST/SET_MAIN_PART_ID';
export const SET_MAIN_PART_TYPE = 'QUOTE_REQUEST/SET_MAIN_PART_TYPE';
export const SET_ID_FOR_DELETE = 'QUOTE_REQUEST/SET_ID_FOR_DELETE';
export const RESET_STATE = 'QUOTE_REQUEST/RESET_STATE';