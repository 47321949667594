import React, { Fragment } from 'react';
import { dateToTimezone, empty, isEstimator } from './../../../utils';
import numeral from 'numeral';
import ButtonsContainer from './ButtonsContainer';
import ShippingTo from './ShippingTo';
import Totals from './Totals';
import RfqSpecification from './RfqSpecification';
import OutsideServices from './OutsideServices';
import QuantitiesTotals from './QuantitiesTotals';
import AdditionalAdjustments from './AdditionalAdjustments';

const EnvelopeForm = React.memo((props) => {
    const estimate = props.estimate;

    const addressDivs = [];
    if (estimate.address) {
        const addressParts = estimate.address.split("\n");
        for (let i = 0; i < addressParts.length; i++) {
            addressDivs.push(<div>{addressParts[i]}</div>);
        }
    }

    let dueDateLine = null;
    if (!empty(estimate.due_date)) {
        dueDateLine = <div className="mt-3"><b>Due Date:</b> {dateToTimezone(estimate.due_date, 'MMMM Do YYYY')}</div>;
    }

    let rfqNumberRow = null;
    if (!empty(estimate.sc_quote_request_id)) {
        rfqNumberRow = <div><b>RFQ #:</b> <a href={'/request-for-quote/' + estimate.sc_quote_request_id}>{estimate.sc_quote_request_id}</a></div>;
    }

    let estimateNumberRow = null;
    if (!empty(estimate.id)) {
        if (!empty(props.mainPartId)) {
            estimateNumberRow = <div><b>Estimate #:</b> {props.mainPartId}</div>;
        } else {
            estimateNumberRow = <div><b>Estimate #:</b> {estimate.id}</div>;
        }
    }

    let scNumberRow = null;
    if (!empty(estimate.pqt_tracking_id)) {
        scNumberRow = <div><b>SC #:</b> <a href={'/tracking/' + estimate.pqt_tracking_id}>{estimate.pqt_tracking_id}</a></div>;
    }

    let invoiceNumberRow = null;
    if (!empty(estimate.invoice_id)) {
        invoiceNumberRow = <div><b>Invoice #:</b> <a href={'/invoice/' + estimate.invoice_id}>{estimate.invoice_id}</a></div>;
    }

    let relationsContainer = null;
    let changeEstimateContainer = null;
    if (rfqNumberRow != null || estimateNumberRow != null || scNumberRow != null || invoiceNumberRow != null) {
        relationsContainer = (
            <div className="relations-information">
                {estimateNumberRow}
                {rfqNumberRow}
                {scNumberRow}
                {invoiceNumberRow}
            </div>
        );

        if (parseInt(estimate.status) === 4) {
            changeEstimateContainer = (
                <div className="change-estimate-type-container">
                    <button onClick={props.showChangeEstimateModal}><i className="fas fa-exchange-alt" /> Change Estimate Type</button>
                </div>
            );
        }
    }

    let assignedEnvelopeTypes = [];
    let envelopeTypeOptions = [];
    let envelopeTypes = [];
    let envelopeTypeName = '';
    for (let i = 0; i < props.information.envelopePapers.length; i++) {
        const item = props.information.envelopePapers[i];
        const id = parseInt(item['envelope_type_id']);
        if (!assignedEnvelopeTypes.includes(id)) {
            envelopeTypes.push(item);
            assignedEnvelopeTypes.push(id);
        }

        if (parseInt(item['envelope_type_id']) === parseInt(estimate.envelope_paper_type)) {
            envelopeTypeName = item['envelope_type_name'];
        }
    }
    envelopeTypes = envelopeTypes.sort((a, b) => {
        if (a['envelope_type_name'] < b['envelope_type_name']) {
            return -1;
        } else if (a['envelope_type_name'] > b['envelope_type_name']) {
            return 1;
        } else {
            return 0;
        }
    });
    for (let i = 0; i < envelopeTypes.length; i++) {
        const item = envelopeTypes[i];
        const id = parseInt(item['envelope_type_id']);
        envelopeTypeOptions.push(<option value={id}>{item['envelope_type_name']}</option>);
    }

    let assignedEnvelopeStyles = [];
    let envelopeStyleOptions = [];
    let envelopeStyles = [];
    if (!empty(estimate.envelope_paper_type)) {
        for (let i = 0; i < props.information.envelopePapers.length; i++) {
            const item = props.information.envelopePapers[i];
            const style = item['style'];
            if (!assignedEnvelopeStyles.includes(style) && parseInt(item['envelope_type_id']) === parseInt(estimate.envelope_paper_type)) {
                envelopeStyles.push(item);
                assignedEnvelopeStyles.push(style);
            }
        }
        envelopeStyles = envelopeStyles.sort((a, b) => {
            if (a['style'] < b['style']) {
                return -1;
            } else if (a['style'] > b['style']) {
                return 1;
            } else {
                return 0;
            }
        });
        for (let i = 0; i < envelopeStyles.length; i++) {
            const item = envelopeStyles[i];
            const style = item['style'];
            envelopeStyleOptions.push(<option value={style}>{style}</option>);
        }
    }

    let assignedEnvelopeSizes = [];
    let envelopeSizeOptions = [];
    let envelopeSizes = [];
    if (!empty(estimate.envelope_paper_style)) {
        for (let i = 0; i < props.information.envelopePapers.length; i++) {
            const item = props.information.envelopePapers[i];
            const size = item['size_width'] + 'x' + item['size_height'];
            if (
                !assignedEnvelopeSizes.includes(size) &&
                parseInt(item['envelope_type_id']) === parseInt(estimate.envelope_paper_type) &&
                item['style'] === estimate.envelope_paper_style
            ) {
                envelopeSizes.push(item);
                assignedEnvelopeSizes.push(size);
            }
        }
        envelopeSizes = envelopeSizes.sort((a, b) => {
            if (a['size_width'] < b['size_width']) {
                return -1;
            } else if (a['size_width'] > b['size_width']) {
                return 1;
            } else {
                return 0;
            }
        });
        for (let i = 0; i < envelopeSizes.length; i++) {
            const item = envelopeSizes[i];
            const size = item['size_width'] + 'x' + item['size_height'];
            envelopeSizeOptions.push(<option value={size}>{size}</option>);
        }
    }

    let assignedEnvelopeWeight = [];
    let envelopeWeightOptions = [];
    let envelopeWeights = [];
    if (!empty(estimate.envelope_size)) {
        for (let i = 0; i < props.information.envelopePapers.length; i++) {
            const item = props.information.envelopePapers[i];
            const size = item['size_width'] + 'x' + item['size_height'];
            if (
                !assignedEnvelopeWeight.includes(item['base_weight']) &&
                parseInt(item['envelope_type_id']) === parseInt(estimate.envelope_paper_type) &&
                item['style'] === estimate.envelope_paper_style &&
                size === estimate.envelope_size
            ) {
                envelopeWeights.push(item);
                assignedEnvelopeWeight.push(item['base_weight']);
            }
        }
        envelopeWeights = envelopeWeights.sort((a, b) => {
            if (a['base_weight'] < b['base_weight']) {
                return -1;
            } else if (a['base_weight'] > b['base_weight']) {
                return 1;
            } else {
                return 0;
            }
        });
        for (let i = 0; i < envelopeWeights.length; i++) {
            const item = envelopeWeights[i];
            envelopeWeightOptions.push(<option value={item['base_weight']}>{item['base_weight'] + '#'}</option>);
        }
    }

    let printLocations = [];
    if (parseInt(estimate.has_return_address) === 1) {
        printLocations.push('Return address');
    }
    if (parseInt(estimate.has_back_flap) === 1) {
        printLocations.push('Back Flap');
    }
    if (parseInt(estimate.has_address_area) === 1) {
        printLocations.push('Address area');
    }
    if (parseInt(estimate.has_other) === 1) {
        printLocations.push('Other (' + estimate.envelope_other + ')');
    }
    let printLocation = printLocations.join(', ');

    let outputDeviceName = '';
    if (parseInt(estimate.output_device) === 3) {
        outputDeviceName = 'Ryobi';
    } else if (parseInt(estimate.output_device) === 4) {
        outputDeviceName = 'Halm Jet';
    } else if (parseInt(estimate.output_device) === 5) {
        outputDeviceName = 'Xante';
    }

    let wrongEstimate = null;
    if (props.result && props.result != null) {
        wrongEstimate = (
            <div className="wrong-estimate">
                <div className="link" onClick={props.showWrongEstimateModal}>Wrong Estimate? Click here to let us know.</div>
            </div>
        );
    }

    let result = null;
    if (props.result != null) {
        result = props.result;
    }

    let wasteBreakdown = null;
    let wasteBreakdownIcon = <i className="fas fa-angle-double-right" />;
    if (props.showBreakdown.waste) {
        wasteBreakdownIcon = <i className="fas fa-angle-double-down" />;

        if (result != null && result.request_params) {
            wasteBreakdown = (
                <div className="breakdown-container">
                    <div className="breakdown-element">
                        <i className="fas fa-circle" /> Waste: {numeral(result.envelope_paper.total_quantity - result.request_params.quantity).format('0,0')}
                    </div>
                </div>
            );
        }
    }

    let runBreakdown = null;
    let runBreakdownIcon = <i className="fas fa-angle-double-right" />;
    if (props.showBreakdown.run) {
        runBreakdownIcon = <i className="fas fa-angle-double-down" />;

        if (result != null) {
            runBreakdown = (
                <div className="breakdown-container">
                    <div className="breakdown-element">
                        <i className="fas fa-circle" /> Speed: {numeral(result.envelope_press.speed).format('0,0')}
                    </div>
                </div>
            );
        }
    }

    let otherBreakdown = null;
    let otherBreakdownIcon = <i className="fas fa-angle-double-right" />;
    if (props.showBreakdown.other) {
        otherBreakdownIcon = <i className="fas fa-angle-double-down" />;

        if (result != null) {
            otherBreakdown = (
                <div className="breakdown-container"></div>
            );
        }
    }

    let specificationContainer = (
        <Fragment>
            <div className="section-title mt-3 mb-2">Specification:</div>
            <div className="specification-container">
                <div><b>Description:</b></div>
                <div>{estimate.description_title}</div>
                <div className="mt-3"><b>Quantity:</b> {numeral(estimate.quantity).format('0,0')}</div>
                <div><b>Output Device:</b> {outputDeviceName}</div>
                <div><b>Envelope Type:</b> {envelopeTypeName}</div>
                <div><b>Envelope Style:</b> {estimate.envelope_paper_style}</div>
                <div><b>Envelope Size:</b> {estimate.envelope_size}</div>
                <div><b>Envelope Weight:</b> {!empty(estimate.envelope_paper_weight) ? estimate.envelope_paper_weight + '#' : ''}</div>
                <div><b>Print Location:</b> {printLocation}</div>
                <div className="mt-3"><b>Front Inks:</b> {estimate.front_colors} ({props.getInks(estimate.front_inks)})</div>
                <div><b>Back Inks:</b> {estimate.back_colors} ({props.getInks(estimate.back_inks)})</div>
                {props.filesContainer}
            </div>
        </Fragment>
    );
    if (estimate.sc_quote_request_id && estimate.sc_quote_request_id != null) {
        specificationContainer = (
            <RfqSpecification
                {...props}
            />
        );
    }

    let deletePartButton = null;
    if (!empty(estimate.main_part_id) && parseInt(estimate.status) === 4) {
        const handleDeletePart = () => {
            props.showDeletePartModal();
            props.setIdForDelete(estimate.id);
        }

        deletePartButton = (
            <div className="delete-part-button-container">
                <button onClick={handleDeletePart}><i className="fas fa-trash" /> Delete this Part</button>
            </div>
        );
    }

    let addButton = null;
    if (!empty(estimate.id) && parseInt(estimate.status) === 4) {
        addButton = (
            <div className="add-part-container">
                <button onClick={props.showAddPartModal}><i className="fas fa-plus" /> Add Part</button>
            </div>
        );
    }

    let duplicateButton = null;
    if (!empty(estimate.id) && parseInt(estimate.status) === 4) {
        duplicateButton = (
            <div className="add-part-container">
                <button onClick={props.duplicatePart}>
                    {props.isDuplicatingPart
                        ? <i className="fas fa-circle-notch fa-spin" />
                        : <Fragment><i className="fas fa-copy" /> Duplicate Part</Fragment>}
                </button>
            </div>
        );
    }

    const renderPrintingAdditionalServices = () => {
        return (
            <Fragment>
                <div className="add-charge">
                    <div>Additional services</div>
                    <div className="picker" onClick={props.showDeliveryChargeModal}><i className="fas fa-truck" /> Delivery Charge</div>
                    <div className="picker" onClick={props.showAdditionalServicesModal}><i className="fas fa-server" /> Charges Picker</div>
                </div>
                {props.renderAdditionalServices()}
                <div className="waste-container">
                    <div className="additional-link" onClick={props.setShowBreakdown.bind(this, 'waste', !props.showBreakdown.waste)}>
                        {wasteBreakdownIcon} Waste
                    </div>
                    <div>
                        {/* <input type="checkbox" value={estimate.show_waste_on_estimate} onChange={props.handleSetField.bind(this, 'show_waste_on_estimate')} /> Show on estimate */}
                    </div>
                    {wasteBreakdown}
                </div>
                <div className="run-container">
                    <div className="additional-link" onClick={props.setShowBreakdown.bind(this, 'run', !props.showBreakdown.run)}>
                        {runBreakdownIcon} Imp / Run Speed
                    </div>
                    <div>
                        {/* <input type="checkbox" value={estimate.show_run_on_estimate} onChange={props.handleSetField.bind(this, 'show_run_on_estimate')} /> Show on estimate */}
                    </div>
                    {runBreakdown}
                </div>
                <div className="other-container">
                    <div className="additional-link" onClick={props.setShowBreakdown.bind(this, 'other', !props.showBreakdown.other)}>
                        {otherBreakdownIcon} Other Breakdown
                    </div>
                    <div>
                        {/* <input type="checkbox" value={estimate.show_other_on_estimate} onChange={props.handleSetField.bind(this, 'show_other_on_estimate')} /> Show on estimate */}
                    </div>
                    {otherBreakdown}
                </div>
            </Fragment>
        );
    };

    const renderPrintingFields = () => {
        return (
            <Fragment>
                <div className="column2-line2">
                    <div className="section-title mb-2">All Pages:</div>
                    <div className="output-devices-field">
                        <b>Output Device <span className="required">*</span>:</b>
                        <select value={estimate.output_device} onChange={props.handleSetField.bind(this, 'output_device')}>
                            <option value="">Select option</option>
                            <option value="3">Ryobi</option>
                            <option value="4">Halm Jet</option>
                            <option value="5">Xante</option>
                        </select>
                    </div>
                    <div className="envelope-type-field">
                        <b>Envelope Type <span className="required">*</span>:</b>
                        <select value={estimate.envelope_paper_type} onChange={props.handleSetField.bind(this, 'envelope_paper_type')}>
                            <option value="">Select option</option>
                            {envelopeTypeOptions}
                        </select>
                    </div>
                    <div className="envelope-style-field">
                        <b>Envelope Style <span className="required">*</span>:</b>
                        <select value={estimate.envelope_paper_style} onChange={props.handleSetField.bind(this, 'envelope_paper_style')}>
                            <option value="">Select option</option>
                            {envelopeStyleOptions}
                        </select>
                    </div>
                    <div className="envelope-size-field">
                        <b>Envelope Size <span className="required">*</span>:</b>
                        <select value={estimate.envelope_size} onChange={props.handleSetField.bind(this, 'envelope_size')}>
                            <option value="">Select option</option>
                            {envelopeSizeOptions}
                        </select>
                    </div>
                    <div className="envelope-weight-field">
                        <b>Envelope Weight <span className="required">*</span>:</b>
                        <select value={estimate.envelope_paper_weight} onChange={props.handleSetField.bind(this, 'envelope_paper_weight')}>
                            <option value="">Select option</option>
                            {envelopeWeightOptions}
                        </select>
                    </div>
                </div>
                <div className="column2-line3">
                    <div className="colors-fields">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Colors</th>
                                    <th className="ink-type">Ink Type</th>
                                    <th>PMS#</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Front:</td>
                                    <td>
                                        <input type="number" value={estimate.front_colors} onChange={props.handleSetField.bind(this, 'front_colors')} />
                                    </td>
                                    <td>
                                        <select value={estimate.front_inks} onChange={props.handleSetField.bind(this, 'front_inks')}>
                                            <option value="">Select option</option>
                                            <option value="1">Black only</option>
                                            <option value="2">PMS only</option>
                                            <option value="3">CMYK</option>
                                            <option value="4">CMYK + PMS</option>
                                            <option value="9">Black + PMS</option>
                                            <option value="10">PMS + PMS</option>
                                        </select>
                                    </td>
                                    <td>
                                        <input type="text" value={estimate.front_pms} onChange={props.handleSetField.bind(this, 'front_pms')} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Back:</td>
                                    <td>
                                        <input type="number" value={estimate.back_colors} onChange={props.handleSetField.bind(this, 'back_colors')} />
                                    </td>
                                    <td>
                                        <select value={estimate.back_inks} onChange={props.handleSetField.bind(this, 'back_inks')}>
                                            <option value="">Select option</option>
                                            <option value="0">Blank</option>
                                            <option value="1">Black only</option>
                                            <option value="2">PMS only</option>
                                            <option value="3">CMYK</option>
                                            <option value="4">CMYK + PMS</option>
                                            <option value="9">Black + PMS</option>
                                            <option value="10">PMS + PMS</option>
                                        </select>
                                    </td>
                                    <td>
                                        <input type="text" value={estimate.back_pms} onChange={props.handleSetField.bind(this, 'back_pms')} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="column2-line4">
                    <div className="section-title mb-2">Print Location <span className="required">*</span>:</div>
                    <div className="print-location-field">
                        <input type="checkbox" value={estimate.has_return_address} checked={estimate.has_return_address} onChange={props.handleSetField.bind(this, 'has_return_address')} /> Return address
                    </div>
                    <div className="print-location-field">
                        <input type="checkbox" value={estimate.has_back_flap} checked={estimate.has_back_flap} onChange={props.handleSetField.bind(this, 'has_back_flap')} /> Back Flap
                    </div>
                    <div className="print-location-field">
                        <input type="checkbox" value={estimate.has_address_area} checked={estimate.has_address_area} onChange={props.handleSetField.bind(this, 'has_address_area')} /> Address area
                    </div>
                    <div className="print-location-field">
                        <input type="checkbox" value={estimate.has_other} checked={estimate.has_other} onChange={props.handleSetField.bind(this, 'has_other')} /> Other
                        <input type="text" className="other-field" value={estimate.envelope_other} onChange={props.handleSetField.bind(this, 'envelope_other')} />
                    </div>
                </div>
            </Fragment>
        );
    };

    if (isEstimator(props.user)) {
        return (
            <div className="envelope-form-container main-form-container">
                <div className="line1">
                    <div className="column1">
                        <div className="column1-line1">
                            {relationsContainer}
                            {changeEstimateContainer}
                            {addButton}
                            {duplicateButton}
                            {deletePartButton}
                            <div className="quote-request-container">
                                <div><b>Quote request:</b></div>
                                <div className="mt-3"><b>Customer:</b> {props.customerName}</div>
                                <div><b>Sales rep:</b> {props.salesRepName}</div>
                                <div><b>CSR:</b> {props.csrName}</div>
                                <div><b>Product Type:</b> {props.selectedProductType}</div>
                                {dueDateLine}
                            </div>
                            {specificationContainer}
                        </div>
                    </div>
                    <div className="column2">
                        <div className="column2-line1">
                            <div className="csr-field">
                                <div><b>Sales Rep <span className="required">*</span>:</b></div>
                                <select value={estimate.sales_rep_id} onChange={props.handleSetField.bind(this, 'sales_rep_id')}>
                                    <option value="">Select option</option>
                                    {props.salesRepOptions}
                                </select>
                            </div>
                            <div className="csr-field">
                                <div><b>CSR <span className="required">*</span>:</b></div>
                                <select value={estimate.csr_id} onChange={props.handleSetField.bind(this, 'csr_id')}>
                                    <option value="">Select option</option>
                                    {props.csrOptions}
                                </select>
                            </div>
                            <div className="product-type-field">
                                <div><b>Product Type <span className="required">*</span>:</b></div>
                                <select value={props.estimate.product_type} onChange={props.handleSetField.bind(this, 'product_type')}>
                                    <option value="">Select option</option>
                                    {props.productTypesOptions}
                                </select>
                            </div>
                            <div className="process-request-field">
                                <div><b>Process Request <span className="required">*</span>:</b></div>
                                <select value={props.estimate.process_request} onChange={props.handleSetField.bind(this, 'process_request')}>
                                    <option value="">Select option</option>
                                    {props.processRequestOptions}
                                </select>
                            </div>
                            <div className="markup-field">
                                <div><b>Markup Factor <span className="required">*</span>:</b></div>
                                <div>
                                    <select value={estimate.markup_factor} onChange={props.handleSetField.bind(this, 'markup_factor')}>
                                        <option value="">Select option</option>
                                        <option value="1">Sales</option>
                                        <option value="2">Broker</option>
                                        <option value="3">Good</option>
                                        <option value="4">Great</option>
                                        <option value="5">Excellent</option>
                                        <option value="6">PITAA</option>
                                    </select>
                                </div>
                            </div>
                            <div className="no-field"></div>
                            <div className="description-title mt-2">
                                <b>Description <span className="required">*</span>:</b> <input type="text" value={estimate.description_title} onChange={props.handleSetField.bind(this, 'description_title')} />
                            </div>
                        </div>
                        {renderPrintingFields()}
                    </div>
                    <div className="column3">
                        <div className="column3-line1">
                            <div className="quantity-field">
                                <b>Quantity 1 <span className="required">*</span>:</b>
                                <input type="number" value={estimate.quantity} onChange={props.handleSetField.bind(this, 'quantity')} />
                            </div>
                            <div className="quantity-field">
                                <b>Quantity 2:</b>
                                <input type="number" value={estimate.quantity2} onChange={props.handleSetField.bind(this, 'quantity2')} />
                            </div>
                            <div className="quantity-field">
                                <b>Quantity 3:</b>
                                <input type="number" value={estimate.quantity3} onChange={props.handleSetField.bind(this, 'quantity3')} />
                            </div>
                            <div className="pricing-title">Pricing Grid</div>
                            <div className="start-qty-field">
                                <b>Start Qty:</b>
                                <input type="number" value={estimate.start_qty} onChange={props.handleSetField.bind(this, 'start_qty')} />
                            </div>
                            <div className="end-qty-field">
                                <b>End Qty:</b>
                                <input type="number" value={estimate.end_qty} onChange={props.handleSetField.bind(this, 'end_qty')} />
                            </div>
                            <div className="increment-field">
                                <b>Increment:</b>
                                <input type="number" value={estimate.increment} onChange={props.handleSetField.bind(this, 'increment')} />
                            </div>
                            <div className="matrix-button-container">
                                <button className="matrix-button" disabled={props.multiEstimating} onClick={props.matrixEstimate}>
                                    {props.multiEstimating
                                        ? <i className="fas fa-circle-notch fa-spin" />
                                        : <Fragment><i className="fas fa-magic" /> Price Matrix Generator</Fragment>}
                                </button>
                            </div>
                        </div>
                        <div className="column3-line2">
                            {renderPrintingAdditionalServices()}
                            <OutsideServices
                                information={props.information}
                                estimate={props.estimate}
                                outsideServices={props.outsideServices}
                                setDataChanged={props.setDataChanged}
                                setEstimateChanged={props.setEstimateChanged}
                                handleSetField={props.handleSetField}
                                addOutsideService={props.addOutsideService}
                                setOutsideServiceField={props.setOutsideServiceField}
                                deleteOutsideService={props.deleteOutsideService}
                            />
                        </div>
                        <AdditionalAdjustments
                            estimate={props.estimate}
                            handleSetField={props.handleSetField}
                        />
                        {props.renderMailServices()}
                    </div>
                </div>
                <div className="line2">
                    <ShippingTo
                        estimate={estimate}
                        information={props.information}
                        setField={props.setField}
                        handleSetField={props.handleSetField}
                    />
                    <div className="column2">
                        <QuantitiesTotals {...props} />
                    </div>
                    <div className="column3">
                        <Totals {...props} />
                        {wrongEstimate}
                        <ButtonsContainer {...props} />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="envelope-form-container main-form-container">
            <div className="line1">
                <div className="column1">
                    <div className="column1-line1">
                        {relationsContainer}
                        <div className="quote-request-container">
                            <div><b>Quote request:</b></div>
                            <div className="mt-3"><b>Customer:</b> {props.customerName}</div>
                            <div><b>Sales rep:</b> {props.salesRepName}</div>
                            <div><b>CSR:</b> {props.csrName}</div>
                            <div><b>Product Type:</b> {props.selectedProductType}</div>
                            {dueDateLine}
                        </div>
                    </div>
                </div>
                <div className="column2">
                    {specificationContainer}
                </div>
                <div className="column3">
                    <div className="column3-line2">
                        <div className="add-charge">
                            <div>Additional services</div>
                        </div>
                        {props.renderAdditionalServices()}
                    </div>
                    {props.renderMailServices()}
                </div>
            </div>
            <div className="line2">
                <ShippingTo
                    estimate={estimate}
                    information={props.information}
                    setField={props.setField}
                    handleSetField={props.handleSetField}
                />
                <div className="column2">
                    <QuantitiesTotals {...props} />
                </div>
                <div className="column3">
                    <Totals {...props} />
                    {wrongEstimate}
                    <ButtonsContainer {...props} />
                </div>
            </div>
        </div>
    );
});

export default EnvelopeForm;
