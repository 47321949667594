export const SAVE = 'PQT_POSTAL_QUALIFICATION/SAVE';
export const SAVE_SUCCESS = 'PQT_POSTAL_QUALIFICATION/SAVE_SUCCESS';
export const SAVE_ERROR = 'PQT_POSTAL_QUALIFICATION/SAVE_ERROR';
export const FETCH_ELEMENT = 'PQT_DATA_PROCESSING/FETCH_ELEMENT';
export const FETCH_ELEMENT_SUCCESS = 'PQT_DATA_PROCESSING/FETCH_ELEMENT_SUCCESS';
export const FETCH_ELEMENT_ERROR = 'PQT_DATA_PROCESSING/FETCH_ELEMENT_ERROR';
export const SET_FIELD = 'PQT_POSTAL_QUALIFICATION/SET_FIELD';
export const RESET_STATE = 'PQT_POSTAL_QUALIFICATION/RESET_STATE';
export const SET_TYPE = 'PQT_POSTAL_QUALIFICATION/SET_TYPE';
export const SET_ELEMENT_ID = 'PQT_POSTAL_QUALIFICATION/SET_ELEMENT_ID';
export const SET_FIELDS = 'PQT_POSTAL_QUALIFICATION/SET_FIELDS';
export const SET_SHOW_SAVE = 'PQT_DATA_PROCESSING/SET_SHOW_SAVE';