import { createSelector } from 'reselect';

export const getComponentState = state => state.hrpPayrollReports;

export const getFetchingReports = createSelector(
    getComponentState,
    (state) => state.fetchingReports
);
export const getReports = createSelector(
    getComponentState,
    (state) => state.reports
);
export const getPagination = createSelector(
    getComponentState,
    (state) => state.pagination
);
export const getSort = createSelector(
    getComponentState,
    (state) => state.sort
);
export const getIdForDelete = createSelector(
    getComponentState,
    (state) => state.delete.id
);
export const getIsDeleting = createSelector(
    getComponentState,
    (state) => state.delete.isDeleting
);
export const getFilterFields = createSelector(
    getComponentState,
    (state) => state.filter.fields
);
export const getUploadingFile = createSelector(
    getComponentState,
    (state) => state.uploadingFile
);
export const getFields = createSelector(
    getComponentState,
    (state) => state.fields
);
export const getFetchingReport = createSelector(
    getComponentState,
    (state) => state.fetchingReport
);
export const getSavingMemo = createSelector(
    getComponentState,
    (state) => state.savingMemo
);
export const getPushingToQb = createSelector(
    getComponentState,
    (state) => state.pushingToQb
);
export const getFile = createSelector(
    getComponentState,
    (state) => state.file
);