import Immutable from "immutable";
import * as types from "./PostageCalculator.types";

const initialState = {
    fields: {
        // quantity: "1",
        // origin_zip: "77041",
        // destination_zip: "77040",
        // product_type: "1",
        // weight: "0.55",
        // length: "9.5",
        // width: "4.125",
        // height: "0.05",
        // mail_class: "PRIORITY_MAIL",
        // rate_indicator: "SP",
        // destination_entry_facility_type: "NONE",
        // price_type: "COMMERCIAL",

        quantity: "",
        origin_zip: "",
        destination_zip: "",
        product_type: "1",
        weight: "",
        length: "",
        width: "",
        height: "",
        mail_class: "PRIORITY_MAIL",
        rate_indicator: "SP",
        destination_entry_facility_type: "NONE",
        price_type: "COMMERCIAL",
    },
    calculatingPrice: false,
    priceResult: null,
};

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.GET_PRICE:
            return state
                .setIn(["calculatingPrice"], true)
                .setIn(["priceResult"], null)
                .toJS();

        case types.GET_PRICE_SUCCESS:
            return state
                .setIn(["calculatingPrice"], false)
                .setIn(["priceResult"], action.payload)
                .toJS();

        case types.GET_PRICE_ERROR:
            return state.setIn(["calculatingPrice"], false).toJS();

        case types.SET_FIELD:
            return state
                .setIn(["fields", action.payload.field], action.payload.value)
                .toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}
