import axios from 'axios';
import * as types from './OnHoldCustomers.types';
import * as selectors from './OnHoldCustomers.selectors';
import * as dialog from '../common/dialog';
import { validateAuthInResponse, showDataDeletedNotification, empty, showNotification } from './../../utils';
import fileDownload from 'js-file-download';
import moment from 'moment';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchOnHoldCustomers = () => (dispatch, getState) => {
    const sort = selectors.getSort(getState());
    const pagination = selectors.getPagination(getState());
    const filerFields = selectors.getFilterFields(getState());

    let params = [];
    params.push('sort=' + (sort.type === 'asc' ? '' : '-') + sort.column);
    params.push('active-page=' + pagination.activePage);
    params.push('elements-per-page=' + pagination.elementsPerPage);

    if (filerFields.length > 0) {
        let filterElements = [];
        for (let i = 0; i < filerFields.length; i++) {
            filterElements.push(filerFields[i]['name'] + '=' + filerFields[i]['value']);
        }
        params.push('filter=' + filterElements.join(','))
    } else {
        params.push('filter=-')
    }

    dispatch({ type: types.FETCH_ON_HOLD_CUSTOMERS, payload: null });
    axios.get(apiUrl + '/on-hold-customer/fetch-customers' + (params.length === 0 ? '' : '?' + params.join('&')),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_ON_HOLD_CUSTOMERS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_ON_HOLD_CUSTOMERS_ERROR));
        });
}

export const fetchOnHoldCustomer = (id) => (dispatch, getState) => {
    dispatch({ type: types.FETCH_ON_HOLD_CUSTOMER, payload: null });
    axios.get(apiUrl + '/on-hold-customer/fetch-customer?id=' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_ON_HOLD_CUSTOMER_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_ON_HOLD_CUSTOMER_ERROR));
        });
}

export const fetchInformation = () => (dispatch, getState) => {
    dispatch({ type: types.FETCH_INFORMATION, payload: null });
    axios.get(apiUrl + '/on-hold-customer/information',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_INFORMATION_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_INFORMATION_ERROR));
        });
}

export const addHold = () => (dispatch, getState) => {
    const fields = selectors.getFields(getState());
    if (empty(fields.customer_id)) {
        return showNotification('Complete Information', 'Select a Customer', 'info');
    }
    if (empty(fields.status)) {
        return showNotification('Complete Information', 'Select a Status (Hold or Closed)', 'info');
    }
    if (!empty(fields.check_bounced) && parseInt(fields.check_bounced) === 1 && empty(fields.check)) {
        return showNotification('Complete Information', 'Enter the Check #', 'info');
    }
    if (!empty(fields.limit_hold_amount) && parseInt(fields.limit_hold_amount) === 1 && empty(fields.hold_amount)) {
        return showNotification('Complete Information', 'Enter the Limit Hold Amount', 'info');
    }

    dispatch({ type: types.ADD_HOLD, payload: null });
    axios.post(apiUrl + '/on-hold-customer/add', fields,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }, data: {} }
    ).then(() => {
        dispatch({ type: types.ADD_HOLD_SUCCESS, payload: null });
        showNotification('Customer On Hold', 'Your information has been saved successfully', 'success');
        dispatch(fetchOnHoldCustomers());
        dispatch(dialog.actions.hideDialog('hold-modal'));
        dispatch(resetFields());
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.ADD_HOLD_ERROR));
    });
}

export const removeHold = (id) => (dispatch) => {
    dispatch({ type: types.REMOVE_HOLD, payload: null });
    axios.delete(apiUrl + '/on-hold-customer/remove?id=' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }, data: {} }
    ).then(() => {
        dispatch({ type: types.REMOVE_HOLD_SUCCESS, payload: null });
        showDataDeletedNotification();
        dispatch(fetchOnHoldCustomers());
        dispatch(dialog.actions.hideDialog('delete-modal'));
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.REMOVE_HOLD_ERROR));
    });
}

export const downloadReport = () => (dispatch, getState) => {
    dispatch({ type: types.DOWNLOAD_REPORT, payload: null });
    axios.get(apiUrl + '/on-hold-customer/download-report',
        {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
            responseType: 'blob',
        })
        .then((response) => {
            dispatch({ type: types.DOWNLOAD_REPORT_SUCCESS, payload: response.data });
            fileDownload(response.data, 'A-R Aging Summary - ' + moment().format('MM-DD-YYYY') + '.xlsx');
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.DOWNLOAD_REPORT_ERROR));
        });
}

export const setSort = (column, type) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_SORT,
            payload: {
                column: column,
                type: type,
            }
        });
        resolve();
    }).then(() => {
        dispatch(fetchOnHoldCustomers());
    });
}

export const setActivePage = (value) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_ACTIVE_PAGE,
            payload: value
        });
        resolve();
    }).then(() => {
        dispatch(fetchOnHoldCustomers());
    });
}

export const mountComponent = () => (dispatch, getState) => {
    new Promise((resolve) => {
        resolve();
    }).then(() => {
        dispatch(fetchInformation());
        dispatch(fetchOnHoldCustomers());
    });
}

export const setIdForDelete = (id) => (dispatch) => {
    dispatch({
        type: types.SET_ID_FOR_DELETE,
        payload: id
    });
}

export const setField = (field, value) => (dispatch) => {
    dispatch({
        type: types.SET_FIELD,
        payload: { field, value }
    });
}

export const changeFilter = (name, value, fetch = false) => (dispatch) => {
    new Promise((resolve) => {
        dispatch({
            type: types.CHANGE_FILTER,
            payload: { name, value }
        });
        resolve();
    }).then(() => {
        if (fetch) {
            dispatch(fetchOnHoldCustomers());
        }
    });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null
    });
}

export const resetFields = () => (dispatch) => {
    dispatch({
        type: types.RESET_FIELDS,
        payload: null
    });
}

export const setCustomerName = (value) => (dispatch) => {
    dispatch({
        type: types.SET_CUSTOMER_NAME,
        payload: value,
    });
}