import React, { Fragment } from 'react';
import { dateToTimezone, empty, isEstimator } from './../../../utils';
import numeral from 'numeral';
import ButtonsContainer from './ButtonsContainer';
import ShippingTo from './ShippingTo';
import Totals from './Totals';
import RfqSpecification from './RfqSpecification';
import OutsideServices from './OutsideServices';
import QuantitiesTotals from './QuantitiesTotals';
import AdditionalAdjustments from './AdditionalAdjustments';
import { isDigital } from './../Estimate.actions';

const NotepadsForm = React.memo((props) => {
    const estimate = props.estimate;

    const addressDivs = [];
    if (estimate.address) {
        const addressParts = estimate.address.split("\n");
        for (let i = 0; i < addressParts.length; i++) {
            addressDivs.push(<div>{addressParts[i]}</div>);
        }
    }

    let dueDateLine = null;
    if (!empty(estimate.due_date)) {
        dueDateLine = <div className="mt-3"><b>Due Date:</b> {dateToTimezone(estimate.due_date, 'MMMM Do YYYY')}</div>;
    }

    let result = null;
    let impositionLink = null;
    if (props.result1 != null && props.result1.results && props.result1.results.length > 0) {
        result = props.result1 != null && props.result1.results[0];
        impositionLink = <div className="imposition" onClick={props.showImpositionModal}><i className="fas fa-th-large" /> View Imposition {props.checkEstimating()}</div>;
    }

    let wasteBreakdown = null;
    let wasteBreakdownIcon = <i className="fas fa-angle-double-right" />;
    if (props.showBreakdown.waste) {
        wasteBreakdownIcon = <i className="fas fa-angle-double-down" />;

        if (result != null) {
            wasteBreakdown = (
                <div className="breakdown-container">
                    <div className="breakdown-element">
                        <i className="fas fa-circle" /> Press Makeready: {numeral(result.paper_information.pss.make_ready).format('0,0')} pss
                    </div>
                    <div className="breakdown-element">
                        <i className="fas fa-circle" /> Bindery Makeready: {numeral(result.paper_information.pss.bindery_make_ready).format('0,0')} pss
                    </div>
                </div>
            );
        }
    }

    let runBreakdown = null;
    let runBreakdownIcon = <i className="fas fa-angle-double-right" />;
    if (props.showBreakdown.run) {
        runBreakdownIcon = <i className="fas fa-angle-double-down" />;

        if (result != null) {
            let speed = 0;
            if (isDigital(estimate.output_device) && result.digital_machine_information) {
                speed = result.digital_machine_information.speed;
            } else if (!isDigital(estimate.output_device) && result.press_information) {
                speed = result.press_information.speed;
            }

            runBreakdown = (
                <div className="breakdown-container">
                    <div className="breakdown-element">
                        <i className="fas fa-circle" /> Total Impressions: {numeral(result.paper_information.total_impressions).format('0,0')}
                    </div>
                    <div className="breakdown-element">
                        <i className="fas fa-circle" /> Speed: {numeral(speed).format('0,0')} pss/hour
                    </div>
                </div>
            );
        }
    }

    let otherBreakdown = null;
    let otherBreakdownIcon = <i className="fas fa-angle-double-right" />;
    if (props.showBreakdown.other) {
        otherBreakdownIcon = <i className="fas fa-angle-double-down" />;

        if (result != null) {
            let platesItem = null;
            if (!isDigital(estimate.output_device) && result.press_information) {
                platesItem = (
                    <div className="breakdown-element">
                        <i className="fas fa-circle" /> Total Plates: {numeral(result.press_information.total_plates).format('0,0')}
                    </div>
                );
            }

            otherBreakdown = (
                <div className="breakdown-container">
                    <div className="breakdown-element">
                        <i className="fas fa-circle" /> Total Forms: {numeral(result.paper_information.total_forms).format('0,0')}
                    </div>
                    {platesItem}
                    <div className="breakdown-element">
                        <i className="fas fa-circle" /> Total Parent Sheets: {numeral(result.paper_information.total_pars).format('0,0')}
                    </div>
                    <div className="breakdown-element">
                        <i className="fas fa-circle" /> Number-Up: {numeral(result.paper_information.pss.up).format('0,0')}
                    </div>
                    <div className="breakdown-element">
                        <i className="fas fa-circle" /> Number-Out: {numeral(result.paper_information.pss.out).format('0,0')}
                    </div>
                </div>
            );
        }
    }

    let runningOptions = [];
    if (parseInt(estimate.output_device) === 1) {
        runningOptions = [
            <option value="SW">Sheet Wise</option>,
            <option value="WT">Work &amp; Turn</option>,
            <option value="Perfect">Perfect</option>,
        ];
    } else {
        runningOptions = [
            <option value="SW">Sheet Wise</option>,
            <option value="WT">Work &amp; Turn</option>,
        ];
    }

    const disableCoating = (ink) => {
        if (empty(ink) || parseInt(ink) !== 7) {
            return true;
        }

        return false;
    };

    let rfqNumberRow = null;
    if (!empty(estimate.sc_quote_request_id)) {
        rfqNumberRow = <div><b>RFQ #:</b> <a href={'/request-for-quote/' + estimate.sc_quote_request_id}>{estimate.sc_quote_request_id}</a></div>;
    }

    let estimateNumberRow = null;
    if (!empty(estimate.id)) {
        if (!empty(props.mainPartId)) {
            estimateNumberRow = <div><b>Estimate #:</b> {props.mainPartId}</div>;
        } else {
            estimateNumberRow = <div><b>Estimate #:</b> {estimate.id}</div>;
        }
    }

    let scNumberRow = null;
    if (!empty(estimate.pqt_tracking_id)) {
        scNumberRow = <div><b>SC #:</b> <a href={'/tracking/' + estimate.pqt_tracking_id}>{estimate.pqt_tracking_id}</a></div>;
    }

    let invoiceNumberRow = null;
    if (!empty(estimate.invoice_id)) {
        invoiceNumberRow = <div><b>Invoice #:</b> <a href={'/invoice/' + estimate.invoice_id}>{estimate.invoice_id}</a></div>;
    }

    let relationsContainer = null;
    let changeEstimateContainer = null;
    if (rfqNumberRow != null || estimateNumberRow != null || scNumberRow != null || invoiceNumberRow != null) {
        relationsContainer = (
            <div className="relations-information">
                {estimateNumberRow}
                {rfqNumberRow}
                {scNumberRow}
                {invoiceNumberRow}
            </div>
        );

        if (parseInt(estimate.status) === 4) {
            changeEstimateContainer = (
                <div className="change-estimate-type-container">
                    <button onClick={props.showChangeEstimateModal}><i className="fas fa-exchange-alt" /> Change Estimate Type</button>
                </div>
            );
        }
    }

    let outputDeviceName = '';
    if (parseInt(estimate.output_device) === 1) {
        outputDeviceName = 'Komori 1040';
    } else if (parseInt(estimate.output_device) === 2) {
        outputDeviceName = 'Komori 6/40 with Coater';
    } else if (parseInt(estimate.output_device) === 3) {
        outputDeviceName = 'Ryobi';
    } else if (parseInt(estimate.output_device) === 6) {
        outputDeviceName = 'Digital Ricoh';
    } else if (parseInt(estimate.output_device) === 18) {
        outputDeviceName = 'JPress';
    }

    let wrongEstimate = null;
    if (props.result != null && props.result.results && props.result.results.length > 0) {
        wrongEstimate = (
            <div className="wrong-estimate">
                <div className="link" onClick={props.showWrongEstimateModal}>Wrong Estimate? Click here to let us know.</div>
            </div>
        );
    }

    let glueLocation = 'N/A';
    if (parseInt(estimate.glue_location) === 1) {
        glueLocation = 'Top';
    } else if (parseInt(estimate.glue_location) === 2) {
        glueLocation = 'Left Side';
    } else if (parseInt(estimate.glue_location) === 3) {
        glueLocation = 'Right Side';
    }

    let backing = 'N/A';
    if (parseInt(estimate.backing) === 1) {
        backing = 'With Chip Board';
    } else {
        backing = 'No Chip Board';
    }

    let crashNumber = 'None';
    if (parseInt(estimate.use_crash_number) === 1) {
        crashNumber = 'From ' + estimate.crash_number_start + ' to ' + estimate.crash_number_end + ' (' + estimate.crash_number_color + ')';
    }

    let specificationContainer = (
        <Fragment>
            <div className="section-title mt-3 mb-2">Specification:</div>
            <div className="specification-container">
                <div><b>Description:</b></div>
                <div>{estimate.description_title}</div>
                <div className="mt-3"><b>Quantity:</b> {numeral(estimate.quantity).format('0,0')}</div>
                <div><b>Output Device:</b> {outputDeviceName}</div>
                <div><b>Run Size:</b> {estimate.run_size_width}x{estimate.run_size_height}</div>
                <div><b>Finish Size:</b> {estimate.finish_size_width}x{estimate.finish_size_height}</div>
                <div className="mt-3"><b>Paper Type:</b> {estimate.paper_stock_name}</div>
                <div><b>Paper Weight:</b> {estimate.paper_weight}#</div>
                <div><b>Parent Size:</b> {estimate.parent_size}</div>
                <div className="mt-3"><b>Front Inks:</b> {estimate.front_colors} ({props.getInks(estimate.front_inks)})</div>
                <div><b>Back Inks:</b> {estimate.back_colors} ({props.getInks(estimate.back_inks)})</div>
                <div class="mt-3"><b>Number of Pads:</b> {estimate.pads}</div>
                <div><b>Sheets per Pad:</b> {estimate.sheets_per_pad}</div>
                <div><b>Glue Location:</b> {glueLocation}</div>
                <div><b>Backing:</b> {backing}</div>
                <div><b>Crash Number:</b> {crashNumber}</div>
                {props.filesContainer}
            </div>
        </Fragment>
    );
    if (estimate.sc_quote_request_id && estimate.sc_quote_request_id != null) {
        specificationContainer = (
            <RfqSpecification
                {...props}
                outputDeviceName={outputDeviceName}
            />
        );
    }

    let deletePartButton = null;
    if (!empty(estimate.main_part_id) && parseInt(estimate.status) === 4) {
        const handleDeletePart = () => {
            props.showDeletePartModal();
            props.setIdForDelete(estimate.id);
        }

        deletePartButton = (
            <div className="delete-part-button-container">
                <button onClick={handleDeletePart}><i className="fas fa-trash" /> Delete this Part</button>
            </div>
        );
    }

    let addButton = null;
    if (!empty(estimate.id) && parseInt(estimate.status) === 4) {
        addButton = (
            <div className="add-part-container">
                <button onClick={props.showAddPartModal}><i className="fas fa-plus" /> Add Part</button>
            </div>
        );
    }

    let duplicateButton = null;
    if (!empty(estimate.id) && parseInt(estimate.status) === 4) {
        duplicateButton = (
            <div className="add-part-container">
                <button onClick={props.duplicatePart}>
                    {props.isDuplicatingPart
                        ? <i className="fas fa-circle-notch fa-spin" />
                        : <Fragment><i className="fas fa-copy" /> Duplicate Part</Fragment>}
                </button>
            </div>
        );
    }

    const renderRunMethod = () => {
        if (isDigital(estimate.output_device)) {
            return <div className="no-field"></div>;
        }

        return (
            <div className="run-method-field">
                <b>Run Method <span className="required">*</span>:</b>
                <select value={estimate.run_method} onChange={props.handleSetField.bind(this, 'run_method')}>
                    <option value="">Select option</option>
                    {runningOptions}
                </select>
            </div>
        );
    };

    const renderColors = () => {
        if (isDigital(estimate.output_device)) {
            return (
                <div className="colors-fields">
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Colors</th>
                                <th className="ink-type">Ink Type</th>
                                <th>Coating</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Front:</td>
                                <td>
                                    <input type="number" value={estimate.front_colors} onChange={props.handleSetField.bind(this, 'front_colors')} />
                                </td>
                                <td>
                                    <select value={estimate.front_inks} onChange={props.handleSetField.bind(this, 'front_inks')}>
                                        <option value="">Select option</option>
                                        <option value="1">Black/White</option>
                                        <option value="8">Full Color</option>
                                    </select>
                                </td>
                                <td>
                                    <select value={estimate.front_coating} onChange={props.handleSetField.bind(this, 'front_coating')} disabled={disableCoating(estimate.front_inks)}>
                                        <option value="">None</option>
                                        <option value="1">AQ = Aqueous - Gloss</option>
                                        <option value="2">AQ = Aqueous - Dull/Matte</option>
                                        <option value="3">UV = Ultra Violet Gloss</option>
                                        <option value="4">Varnish - Gloss</option>
                                        <option value="5">Varnish - Dull/Matte</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Back:</td>
                                <td>
                                    <input type="number" value={estimate.back_colors} onChange={props.handleSetField.bind(this, 'back_colors')} />
                                </td>
                                <td>
                                    <select value={estimate.back_inks} onChange={props.handleSetField.bind(this, 'back_inks')}>
                                        <option value="">Select option</option>
                                        <option value="0">Blank</option>
                                        <option value="1">Black/White</option>
                                        <option value="8">Full Color</option>
                                    </select>
                                </td>
                                <td>
                                    <select value={estimate.back_coating} onChange={props.handleSetField.bind(this, 'back_coating')} disabled={disableCoating(estimate.back_inks)}>
                                        <option value="">None</option>
                                        <option value="1">AQ = Aqueous - Gloss</option>
                                        <option value="2">AQ = Aqueous - Dull/Matte</option>
                                        <option value="3">UV = Ultra Violet Gloss</option>
                                        <option value="4">Varnish - Gloss</option>
                                        <option value="5">Varnish - Dull/Matte</option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        }

        return (
            <div className="colors-fields">
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Colors</th>
                            <th>Passes</th>
                            <th className="ink-type">Ink Type</th>
                            <th>PMS#</th>
                            <th>Coating</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Front:</td>
                            <td>
                                <input type="number" value={estimate.front_colors} onChange={props.handleSetField.bind(this, 'front_colors')} />
                            </td>
                            <td>
                                <input type="text" value={estimate.front_passes} onChange={props.handleSetField.bind(this, 'front_passes')} />
                            </td>
                            <td>
                                <select value={estimate.front_inks} onChange={props.handleSetField.bind(this, 'front_inks')}>
                                    <option value="">Select option</option>
                                    <option value="1">Black only</option>
                                    <option value="2">PMS only</option>
                                    <option value="3">CMYK</option>
                                    <option value="4">CMYK + PMS</option>
                                    <option value="5">CMYK + 2 PMS</option>
                                    <option value="6">CMYK + 3 PMS</option>
                                    <option value="7">CMYK + Coating</option>
                                    <option value="9">Black + PMS</option>
                                    <option value="10">PMS + PMS</option>
                                </select>
                            </td>
                            <td>
                                <input type="text" value={estimate.front_pms} onChange={props.handleSetField.bind(this, 'front_pms')} />
                            </td>
                            <td>
                                <select value={estimate.front_coating} onChange={props.handleSetField.bind(this, 'front_coating')} disabled={disableCoating(estimate.front_inks)}>
                                    <option value="">None</option>
                                    <option value="1">AQ = Aqueous - Gloss</option>
                                    <option value="2">AQ = Aqueous - Dull/Matte</option>
                                    <option value="3">UV = Ultra Violet Gloss</option>
                                    <option value="4">Varnish - Gloss</option>
                                    <option value="5">Varnish - Dull/Matte</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>Back:</td>
                            <td>
                                <input type="number" value={estimate.back_colors} onChange={props.handleSetField.bind(this, 'back_colors')} />
                            </td>
                            <td>
                                <input type="text" value={estimate.back_passes} onChange={props.handleSetField.bind(this, 'back_passes')} />
                            </td>
                            <td>
                                <select value={estimate.back_inks} onChange={props.handleSetField.bind(this, 'back_inks')}>
                                    <option value="">Select option</option>
                                    <option value="0">Blank</option>
                                    <option value="1">Black only</option>
                                    <option value="2">PMS only</option>
                                    <option value="3">CMYK</option>
                                    <option value="4">CMYK + PMS</option>
                                    <option value="5">CMYK + 2 PMS</option>
                                    <option value="6">CMYK + 3 PMS</option>
                                    <option value="7">CMYK + Coating</option>
                                    <option value="9">Black + PMS</option>
                                    <option value="10">PMS + PMS</option>
                                </select>
                            </td>
                            <td>
                                <input type="text" value={estimate.back_pms} onChange={props.handleSetField.bind(this, 'back_pms')} />
                            </td>
                            <td>
                                <select value={estimate.back_coating} onChange={props.handleSetField.bind(this, 'back_coating')} disabled={disableCoating(estimate.back_inks)}>
                                    <option value="">None</option>
                                    <option value="1">AQ = Aqueous - Gloss</option>
                                    <option value="2">AQ = Aqueous - Dull/Matte</option>
                                    <option value="3">UV = Ultra Violet Gloss</option>
                                    <option value="4">Varnish - Gloss</option>
                                    <option value="5">Varnish - Dull/Matte</option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    const handleAddNewPaper = (event) => {
        event.preventDefault();
        props.showNewPaperModal();
    };

    const renderPrintingAdditionalServices = () => {
        return (
            <Fragment>
                <div className="add-charge">
                    <div>Additional services</div>
                    <div className="picker" onClick={props.showDeliveryChargeModal}><i className="fas fa-truck" /> Delivery Charge</div>
                    <div className="picker" onClick={props.showAdditionalServicesModal}><i className="fas fa-server" /> Charges Picker</div>
                </div>
                {props.renderAdditionalServices()}
                <div className="waste-container">
                    <div className="additional-link" onClick={props.setShowBreakdown.bind(this, 'waste', !props.showBreakdown.waste)}>
                        {wasteBreakdownIcon} Waste
                    </div>
                    <div>
                        {/* <input type="checkbox" value={estimate.show_waste_on_estimate} onChange={props.handleSetField.bind(this, 'show_waste_on_estimate')} /> Show on estimate */}
                    </div>
                    {wasteBreakdown}
                </div>
                <div className="run-container">
                    <div className="additional-link" onClick={props.setShowBreakdown.bind(this, 'run', !props.showBreakdown.run)}>
                        {runBreakdownIcon} Imp / Run Speed
                    </div>
                    <div>
                        {/* <input type="checkbox" value={estimate.show_run_on_estimate} onChange={props.handleSetField.bind(this, 'show_run_on_estimate')} /> Show on estimate */}
                    </div>
                    {runBreakdown}
                </div>
                <div className="other-container">
                    <div className="additional-link" onClick={props.setShowBreakdown.bind(this, 'other', !props.showBreakdown.other)}>
                        {otherBreakdownIcon} Other Breakdown
                    </div>
                    <div>
                        {/* <input type="checkbox" value={estimate.show_other_on_estimate} onChange={props.handleSetField.bind(this, 'show_other_on_estimate')} /> Show on estimate */}
                    </div>
                    {otherBreakdown}
                </div>
            </Fragment>
        );
    };

    const renderPrintingFields = () => {
        return (
            <div className="column2-line2">
                <div className="section-title mb-2 paper-stock-label-container">
                    All Pages:
                    <div className="add-paper-button">
                        <a href="/" onClick={handleAddNewPaper}><i className="fas fa-plus" /> Add Paper</a>
                    </div>
                </div>
                <div className="output-devices-field">
                    <b>Output Device <span className="required">*</span>:</b>
                    <select value={estimate.output_device} onChange={props.handleSetField.bind(this, 'output_device')}>
                        <option value="">Select option</option>
                        <option value="1">Komori 1040</option>
                        <option value="2">Komori 6/40 with Coater</option>
                        <option value="3">Ryobi</option>
                        <option value="6">Digital Ricoh</option>
                        <option value="18">JPress</option>
                    </select>
                </div>
                <div className="paper-no-field"></div>
                <div className="paper-stock-field">
                    <b>Paper Category <span className="required">*</span>:</b>
                    <select value={estimate.paper_stock} onChange={props.handleSetField.bind(this, 'paper_stock')}>
                        <option value="">Select option</option>
                        {props.paperStockOptions}
                    </select>
                </div>
                <div className="paper-name-field">
                    <b>Paper Name <span className="required">*</span>:</b>
                    <select value={estimate.paper_name} onChange={props.handleSetField.bind(this, 'paper_name')}>
                        <option value="">Select option</option>
                        {props.paperNamesOptions}
                    </select>
                </div>
                <div className="paper-weight-field">
                    <b>Paper Weight <span className="required">*</span>:</b>
                    <select value={estimate.paper_weight} onChange={props.handleSetField.bind(this, 'paper_weight')}>
                        <option value="">Select option</option>
                        {props.paperWeightOptions}
                    </select>
                </div>
                <div className="parent-size-field">
                    <b>Paper Size <span className="required">*</span>:</b>
                    <select value={estimate.parent_size} onChange={props.handleSetField.bind(this, 'parent_size')}>
                        <option value="">Select option</option>
                        {props.parentSizeOptions}
                    </select>
                </div>
                <div className="paper-color-field">
                    <b>Paper Color <span className="required">*</span>:</b>
                    <select value={estimate.paper_color} onChange={props.handleSetField.bind(this, 'paper_color')}>
                        <option value="">Select option</option>
                        {props.paperColorOptions}
                    </select>
                </div>
                <div className="paper-no-field"></div>
                <div className="pads-field">
                    <div className="total-sheets">
                        Total number of sheets: <strong>{numeral(estimate.pads * estimate.sheets_per_pad).format('0,0')}</strong>
                    </div>
                    <b>Number of Pads <span className="required">*</span>:</b>
                    <input type="number" value={estimate.pads} onChange={props.handleSetField.bind(this, 'pads')} />
                </div>
                <div className="sheets-pad-field">
                    <b>Sheets/Pad <span className="required">*</span>:</b>
                    <select value={estimate.sheets_per_pad} onChange={props.handleSetField.bind(this, 'sheets_per_pad')}>
                        <option value="">Select option</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                    </select>
                </div>
                <div className="pads-no-field"></div>
                <div className="imposition-configuration">
                    <div className="imposition-configuration-column1">
                        <div className="finish-size-width-field">
                            <b>Finish Size <br /> Width <span className="required">*</span>:</b>
                            <input type="number" value={estimate.finish_size_width} onChange={props.handleSetField.bind(this, 'finish_size_width')} />
                        </div>
                        <div className="finish-size-height-field">
                            <b>Finish Size <br /> Height <span className="required">*</span>:</b>
                            <input type="number" value={estimate.finish_size_height} onChange={props.handleSetField.bind(this, 'finish_size_height')} />
                        </div>
                        <div className="bleed-field">
                            <b>Bleed <span className="required">*</span>:</b>
                            <select value={estimate.bleed} onChange={props.handleSetField.bind(this, 'bleed')}>
                                <option value="0">No Bleed</option>
                                <option value="0.25">1/4</option>
                                <option value="0.125">1/8</option>
                            </select>
                        </div>
                        {renderRunMethod()}
                    </div>
                    <div className="imposition-configuration-column2">
                        <div className="title">Imposition:</div>
                        <div className="field">
                            <input type="checkbox" checked={estimate.use_ganging} onChange={props.handleSetField.bind(this, 'use_ganging')} /> Use Ganging
                        </div>
                    </div>
                </div>
                <div className="imposition-container">
                    {impositionLink}
                </div>
                {renderColors()}
                <div className="pads-options-container">
                    <div className="title">Padding Options:</div>
                    <div className="pads-selects">
                        <div className="glue-location-field">
                            <b>Glue Location <span className="required">*</span>:</b>
                            <select value={estimate.glue_location} onChange={props.handleSetField.bind(this, 'glue_location')}>
                                <option value="">Select option</option>
                                <option value="1">Top</option>
                                <option value="2">Left Side</option>
                                <option value="3">Right Side</option>
                            </select>
                        </div>
                    </div>
                    <div className="backing">
                        <div className="backing-field">
                            <b>Backing <span className="required">*</span>:</b>
                            <select value={estimate.use_chip_board} onChange={props.handleSetField.bind(this, 'use_chip_board')}>
                                <option value="">Select option</option>
                                <option value="1">With Chip Board</option>
                                <option value="0">No Chip Board</option>
                            </select>
                        </div>
                    </div>
                    <div className="crash-number">
                        <div><b>Numbering <span className="required">*</span>:</b></div>
                        <div>
                            <input type="checkbox" checked={estimate.use_crash_number} onChange={props.handleSetField.bind(this, 'use_crash_number')} /> Crash Number
                        </div>
                        <div className="field">
                            <div className="label">Start #:</div>
                            <input type="number" value={estimate.crash_number_start} onChange={props.handleSetField.bind(this, 'crash_number_start')} />
                        </div>
                        <div className="field">
                            <div className="label">End #:</div>
                            <input type="number" value={estimate.crash_number_end} onChange={props.handleSetField.bind(this, 'crash_number_end')} />
                        </div>
                        <div className="field">
                            <div className="label">Color:</div>
                            <input type="text" value={estimate.crash_number_color} onChange={props.handleSetField.bind(this, 'crash_number_color')} />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    if (isEstimator(props.user)) {
        return (
            <div className="notepads-form-container main-form-container">
                <div className="line1">
                    <div className="column1">
                        <div className="column1-line1">
                            {relationsContainer}
                            {changeEstimateContainer}
                            {addButton}
                            {duplicateButton}
                            {deletePartButton}
                            <div className="quote-request-container">
                                <div><b>Quote request:</b></div>
                                <div className="mt-3"><b>Customer:</b> {props.customerName}</div>
                                <div><b>Sales rep:</b> {props.salesRepName}</div>
                                <div><b>CSR:</b> {props.csrName}</div>
                                <div><b>Product Type:</b> {props.selectedProductType}</div>
                                {dueDateLine}
                            </div>
                            {specificationContainer}
                        </div>
                    </div>
                    <div className="column2">
                        <div className="column2-line1">
                            <div className="csr-field">
                                <div><b>Sales Rep <span className="required">*</span>:</b></div>
                                <select value={estimate.sales_rep_id} onChange={props.handleSetField.bind(this, 'sales_rep_id')}>
                                    <option value="">Select option</option>
                                    {props.salesRepOptions}
                                </select>
                            </div>
                            <div className="csr-field">
                                <div><b>CSR <span className="required">*</span>:</b></div>
                                <select value={estimate.csr_id} onChange={props.handleSetField.bind(this, 'csr_id')}>
                                    <option value="">Select option</option>
                                    {props.csrOptions}
                                </select>
                            </div>
                            <div className="product-type-field">
                                <div><b>Product Type <span className="required">*</span>:</b></div>
                                <select value={estimate.product_type} onChange={props.handleSetField.bind(this, 'product_type')}>
                                    <option value="">Select option</option>
                                    {props.productTypesOptions}
                                </select>
                            </div>
                            <div className="process-request-field">
                                <div><b>Process Request <span className="required">*</span>:</b></div>
                                <select value={estimate.process_request} onChange={props.handleSetField.bind(this, 'process_request')}>
                                    <option value="">Select option</option>
                                    {props.processRequestOptions}
                                </select>
                            </div>
                            <div className="markup-field">
                                <div><b>Markup Factor <span className="required">*</span>:</b></div>
                                <div>
                                    <select value={estimate.markup_factor} onChange={props.handleSetField.bind(this, 'markup_factor')}>
                                        <option value="">Select option</option>
                                        <option value="1">Sales</option>
                                        <option value="2">Broker</option>
                                        <option value="3">Good</option>
                                        <option value="4">Great</option>
                                        <option value="5">Excellent</option>
                                        <option value="6">PITAA</option>
                                    </select>
                                </div>
                            </div>
                            <div className="no-field"></div>
                            <div className="description-title mt-2">
                                <b>Description <span className="required">*</span>:</b> <input type="text" value={estimate.description_title} onChange={props.handleSetField.bind(this, 'description_title')} />
                            </div>
                        </div>
                        {renderPrintingFields()}
                    </div>
                    <div className="column3">
                        <div className="column3-line1">
                            <div className="quantity-field">
                                <b>Quantity 1 <span className="required">*</span>:</b>
                                <input type="number" value={estimate.quantity} onChange={props.handleSetField.bind(this, 'quantity')} />
                            </div>
                            <div className="quantity-field">
                                <b>Quantity 2:</b>
                                <input type="number" value={estimate.quantity2} onChange={props.handleSetField.bind(this, 'quantity2')} />
                            </div>
                            <div className="quantity-field">
                                <b>Quantity 3:</b>
                                <input type="number" value={estimate.quantity3} onChange={props.handleSetField.bind(this, 'quantity3')} />
                            </div>
                            <div className="pricing-title">Pricing Grid</div>
                            <div className="start-qty-field">
                                <b>Start Qty:</b>
                                <input type="number" value={estimate.start_qty} onChange={props.handleSetField.bind(this, 'start_qty')} />
                            </div>
                            <div className="end-qty-field">
                                <b>End Qty:</b>
                                <input type="number" value={estimate.end_qty} onChange={props.handleSetField.bind(this, 'end_qty')} />
                            </div>
                            <div className="increment-field">
                                <b>Increment:</b>
                                <input type="number" value={estimate.increment} onChange={props.handleSetField.bind(this, 'increment')} />
                            </div>
                            <div className="matrix-button-container">
                                <button className="matrix-button" disabled={props.multiEstimating} onClick={props.matrixEstimate}>
                                    {props.multiEstimating
                                        ? <i className="fas fa-circle-notch fa-spin" />
                                        : <Fragment><i className="fas fa-magic" /> Price Matrix Generator</Fragment>}
                                </button>
                            </div>
                        </div>
                        <div className="column3-line2">
                            {renderPrintingAdditionalServices()}
                            <OutsideServices
                                information={props.information}
                                estimate={props.estimate}
                                outsideServices={props.outsideServices}
                                setDataChanged={props.setDataChanged}
                                setEstimateChanged={props.setEstimateChanged}
                                handleSetField={props.handleSetField}
                                addOutsideService={props.addOutsideService}
                                setOutsideServiceField={props.setOutsideServiceField}
                                deleteOutsideService={props.deleteOutsideService}
                            />
                        </div>
                        <AdditionalAdjustments
                            estimate={props.estimate}
                            handleSetField={props.handleSetField}
                        />
                        {props.renderMailServices()}
                    </div>
                </div>
                <div className="line2">
                    <ShippingTo
                        estimate={estimate}
                        information={props.information}
                        setField={props.setField}
                        handleSetField={props.handleSetField}
                    />
                    <div className="column2">
                        <QuantitiesTotals {...props} />
                    </div>
                    <div className="column3">
                        <Totals {...props} />
                        {wrongEstimate}
                        <ButtonsContainer {...props} />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="notepads-form-container main-form-container">
            <div className="line1">
                <div className="column1">
                    <div className="column1-line1">
                        {relationsContainer}
                        <div className="quote-request-container">
                            <div><b>Quote request:</b></div>
                            <div className="mt-3"><b>Customer:</b> {props.customerName}</div>
                            <div><b>Sales rep:</b> {props.salesRepName}</div>
                            <div><b>CSR:</b> {props.csrName}</div>
                            <div><b>Product Type:</b> {props.selectedProductType}</div>
                            {dueDateLine}
                        </div>
                    </div>
                </div>
                <div className="column2">
                    {specificationContainer}
                </div>
                <div className="column3">
                    <div className="column3-line2">
                        <div className="add-charge">
                            <div>Additional services</div>
                        </div>
                        {props.renderAdditionalServices()}
                    </div>
                    {props.renderMailServices()}
                </div>
            </div>
            <div className="line2">
                <ShippingTo
                    estimate={estimate}
                    information={props.information}
                    setField={props.setField}
                    handleSetField={props.handleSetField}
                />
                <div className="column2">
                    <QuantitiesTotals {...props} />
                </div>
                <div className="column3">
                    <Totals {...props} />
                    {wrongEstimate}
                    <ButtonsContainer {...props} />
                </div>
            </div>
        </div>
    );
});

export default NotepadsForm;
