import axios from 'axios';
import * as types from './PqtDataProcessing.types';
import * as selectors from './PqtDataProcessing.selectors';
import {
    validateAuthInResponse,
    showCompleteDataNotification,
    showDataSavedNotification,
    sendWsMessage,
    showNotification
} from './../../utils';
import * as dialog from '../common/dialog';

const apiUrl = process.env.REACT_APP_API_URL;

export const save = callback => (dispatch, getState) => {
    const element = selectors.getElement(getState());
    const type = selectors.getType(getState());
    const elementId = selectors.getElementId(getState());

    let attributes = [
        'due_date',
        'total_file',
        'file_location',
        'number_of_address',
        'file_location',
        'merge_files',
        'dedupe_files',
        'select_records',
        'include_foreign',
        'cass_dpv_suitelink',
        'ncoa_link',
        'add_seeds',
        'add_current_resident',
        'change_case',
        'genderize',
        'create_salutation',
        'add_special_field',
        'add_tracking_codes',
        'generate_inkjet_output',
        'generate_imaging_output',
        'generate_file_output',
        'custom_programming',
    ];
    if(parseInt(element.merge_files) === 1) {
        attributes.push('merge_files_description');
    }
    if(parseInt(element.dedupe_files) === 1) {
        attributes.push('dedupe_files_by');
    }
    if(parseInt(element.dedupe_files) === 1 && parseInt(element.dedupe_files_by) === 4) {
        attributes.push('dedupe_files_specify');
    }
    if(parseInt(element.select_records) === 2) {
        attributes.push('select_records_specify');
    }
    if(parseInt(element.add_seeds) === 1) {
        attributes.push('add_seeds_description');
    }
    if(parseInt(element.change_case) === 1) {
        attributes.push('change_case_specify');
    }
    if(parseInt(element.create_salutation) === 1) {
        attributes.push('create_salutation_specify');
    }
    if(parseInt(element.add_special_field) === 1) {
        attributes.push('add_special_field_description');
    }
    if(parseInt(element.add_tracking_codes) === 1) {
        attributes.push('add_tracking_codes_specify');
    }
    if(parseInt(element.generate_imaging_output) === 1) {
        attributes.push('generate_imaging_output_specify');
    }
    if(parseInt(element.generate_file_output) === 1) {
        attributes.push('generate_file_output_specify');
    }
    if(parseInt(element.custom_programming) === 1) {
        attributes.push('custom_programming_description');
    }

    for (let i = 0; i < attributes.length; i++) {
        if (element[attributes[i]] === undefined || element[attributes[i]] === '') {
            showCompleteDataNotification();
            return;
        }
    }

    if (parseInt(type) === 1) {
        element.pqt_tracking_step_id = elementId;
    } else if (parseInt(type) === 2) {
        element.quote_id = elementId;
    } else if (parseInt(type) === 3) {
        element.order_id = elementId;
    } else {
        showNotification('Incomplete data', 'The data is incomplete and it cannot be saved', 'warning');
        return;
    }

    dispatch({ type: types.SAVE, payload: null });
    let promise;
    let saveType;
    if (element.id === undefined) {
        saveType = 1;
        promise = axios.post(apiUrl + '/pqt-data-processings', element, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } });
    } else {
        saveType = 2;
        promise = axios.put(apiUrl + '/pqt-data-processings/' + element.id, element, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } });
    }

    promise
        .then((response) => {
            dispatch({ type: types.SAVE_SUCCESS, payload: { element: response.data, saveType } });
            showDataSavedNotification();
            if (element.id === undefined) {
                dispatch(sendWsMessage({ id: response.data.id, type: 'pqt_data_processing_created' }));
                dispatch(dialog.actions.hideDialog('pqt-data-processing-form'));
            } else {
                dispatch(sendWsMessage({ id: response.data.id, type: 'pqt_data_processing_updated' }));
                dispatch(dialog.actions.hideDialog('pqt-data-processing-form'));
            }
            if(callback) {
                callback();
            }
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.SAVE_ERROR));
        });
}

export const setField = (name, value) => dispatch => {
    dispatch({
        type: types.SET_FIELD,
        payload: { name, value }
    });
}

export const resetState = () => dispatch => {
    dispatch({
        type: types.RESET_STATE,
        payload: null
    });
}

export const setType = type => dispatch => {
    dispatch({
        type: types.SET_TYPE,
        payload: type
    });
}

export const setElementId = id => dispatch => {
    dispatch({
        type: types.SET_ELEMENT_ID,
        payload: id
    });
}

export const fetchElement = id => dispatch => {
    dispatch({ type: types.FETCH_ELEMENT, payload: null });
    axios.get(apiUrl + '/pqt-data-processings/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_ELEMENT_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_ELEMENT_ERROR));
        });
}

export const setShowSave = value => dispatch => {
    dispatch({
        type: types.SET_SHOW_SAVE,
        payload: value
    });
}
