import React, { Fragment, useRef } from 'react';
import numeral from 'numeral';
import ReCAPTCHA from "react-google-recaptcha-enterprise";

const Form = React.memo((props) => {
    const recaptchaRef = useRef(null);

    if (props.fetchingPayment) {
        return (
            <div className="make-payment-form-container loading">
                <i className="fas fa-circle-notch fa-spin" /> Loading payment data...
            </div>
        );
    }

    const handleSetField = (field, event) => {
        props.setField(field, event.target.value);
    };

    const renderSummary = () => {
        if (props.payment) {
            let feeMessage = null;
            let total = props.payment.total;
            if (props.fields.payment_type === 'CreditCard') {
                feeMessage = <div className="summary-item credit-card-fee">A 4% Credit Card fee will apply for postage payments.</div>;
            }

            return (
                <Fragment>
                    <div className="summary-item">
                        <strong>Invoice Number:</strong> {props.payment.invoice_number}
                    </div>
                    <div className="summary-item">
                        <strong>Customer Number:</strong> {props.payment.customer_id}
                    </div>
                    <div className="summary-item">
                        <strong>Customer Name:</strong> {props.payment.customer_name}
                    </div>
                    <div className="summary-item">
                        <strong>Total Amount:</strong> {numeral(total).format('$0,0.00')}
                    </div>
                    {feeMessage}
                </Fragment>
            );
        } else {
            let feeMessage = null;
            const invoiceNumber = props.fields.invoice_number.toLowerCase();
            if (invoiceNumber.includes('pp-') && props.fields.payment_type === 'CreditCard') {
                feeMessage = <div className="summary-item credit-card-fee">A 4% Credit Card fee will apply for postage payments.</div>;
            }

            return (
                <Fragment>
                    <div className="field">
                        <div className="label">Company Name <span className="required">*</span>:</div>
                        <input
                            type="text"
                            value={props.fields.customer_id}
                            onChange={handleSetField.bind(this, 'customer_id')}
                        />
                    </div>
                    <div className="field">
                        <div className="label">Invoice Number <span className="required">*</span>:</div>
                        <input
                            type="text"
                            value={props.fields.invoice_number}
                            onChange={handleSetField.bind(this, 'invoice_number')}
                        />
                    </div>
                    <div className="field">
                        <div className="label">Total Amount <span className="required">*</span>:</div>
                        <input
                            type="number"
                            value={props.fields.total}
                            onChange={handleSetField.bind(this, 'total')}
                        />
                        {feeMessage}
                    </div>
                </Fragment>
            );
        }
    };

    const renderPaymentFields = () => {
        if (props.fields.payment_type === 'CreditCard') {
            let fullClass = '';
            if (props.paymentType) {
                fullClass = 'full-credit-card';
            }

            return (
                <Fragment>
                    <div className={`field card-number-field ${fullClass}`}>
                        <div className="label">Card Number <span className="required">*</span>:</div>
                        <input
                            type="number"
                            value={props.fields.card_number}
                            onChange={handleSetField.bind(this, 'card_number')}
                        />
                    </div>
                    <div className="field">
                        <div className="label">Expiration Month <span className="required">*</span>:</div>
                        <select value={props.fields.expiration_month} onChange={handleSetField.bind(this, 'expiration_month')}>
                            <option value="">Select</option>
                            <option value="01">January</option>
                            <option value="02">February</option>
                            <option value="03">March</option>
                            <option value="04">April</option>
                            <option value="05">May</option>
                            <option value="06">June</option>
                            <option value="07">July</option>
                            <option value="08">August</option>
                            <option value="09">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                        </select>
                    </div>
                    <div className="field">
                        <div className="label">Expiration Year <span className="required">*</span>:</div>
                        <select value={props.fields.expiration_year} onChange={handleSetField.bind(this, 'expiration_year')}>
                            <option value="">Select</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                            <option value="2031">2031</option>
                            <option value="2032">2032</option>
                            <option value="2033">2033</option>
                            <option value="2034">2034</option>
                            <option value="2035">2035</option>
                            <option value="2036">2036</option>
                            <option value="2037">2037</option>
                            <option value="2038">2038</option>
                            <option value="2039">2039</option>
                            <option value="2040">2040</option>
                        </select>
                    </div>
                    <div className="field">
                        <div className="label">CVV/CVC <span className="required">*</span>:</div>
                        <input
                            type="number"
                            value={props.fields.cvv}
                            onChange={handleSetField.bind(this, 'cvv')}
                        />
                    </div>
                    <div className="logos-container">
                        <img src="https://ac-pqn.s3.amazonaws.com/css/credit-cards-logos.png" alt="Credit Card" />
                    </div>
                </Fragment>
            );
        } else if (props.fields.payment_type === 'ACH') {
            return (
                <Fragment>
                    <div className="field">
                        <div className="label">ACH Account Type <span className="required">*</span>:</div>
                        <select value={props.fields.ach_account_type} onChange={handleSetField.bind(this, 'ach_account_type')}>
                            <option value="Savings">Savings</option>
                            <option value="Checking">Checking</option>
                        </select>
                    </div>
                    <div className="field">
                        <div className="label">Your Routing Number <span className="required">*</span>:</div>
                        <input
                            type="number"
                            value={props.fields.routing_number}
                            onChange={handleSetField.bind(this, 'routing_number')}
                        />
                    </div>
                    <div className="field">
                        <div className="label">Your Account Number <span className="required">*</span>:</div>
                        <input
                            type="number"
                            value={props.fields.account_number}
                            onChange={handleSetField.bind(this, 'account_number')}
                        />
                    </div>
                    {/* <div className="ach-information">
                        <div><strong>Bank of Houston</strong></div>
                        <div>4400 Post Oak Parkway, Ste. 2260</div>
                        <div>Houston, Texas 77027</div>
                        <div><strong>ABA:</strong> 111903591</div>
                        <div><strong>Beneficiary Name:</strong> Absolute Color Mailplex</div>
                        <div><strong>Beneficiary Account Number:</strong> 124164</div>
                    </div> */}
                </Fragment>
            );
        } else if (props.fields.payment_type === 'Paypal') {
            return null;
        }
    };

    const renderPayButton = () => {
        if (props.payment && props.payment.is_paid) {
            return <div className="paid-invoice">This invoice has already been paid.</div>;
        }

        return (
            <button onClick={props.generateToken.bind(this, recaptchaRef)} disabled={props.generatingToken || props.savingPayment}>
                {paymentLabel}
            </button>
        );
    };

    let paymentClass = 'ach-payment-container payment-container';
    if (props.fields.payment_type === 'CreditCard') {
        paymentClass = 'card-payment-container payment-container';
    }

    let paymentLabel = 'Pay';
    if (props.generatingToken || props.savingPayment) {
        paymentLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    const handleChangePaymentType = (event) => {
        if (event.target.value === 'Paypal') {
            window.location.href = 'https://acmailplex.com/make-a-paypal-payment/';
        } else {
            props.setField('payment_type', event.target.value);
        }
    };

    let paymentTypeField = (
        <div className="field payment-type-field">
            <div className="label">Payment Type <span className="required">*</span>:</div>
            <select value={props.fields.payment_type} onChange={handleChangePaymentType}>
                <option value="CreditCard">Credit/Debit Card</option>
                <option value="ACH">ACH</option>
                <option value="Paypal">Paypal</option>
            </select>
        </div>
    );
    if (props.paymentType) {
        paymentTypeField = null;
    }

    let lockClass = 'lock-icon1';
    if (props.paymentType) {
        lockClass = 'lock-icon2';
    }

    const onChange = () => {
        const value = recaptchaRef.current.getValue();
        props.setRecaptchaToken(value);
    };

    const handleSetCountry = (event) => {
        props.setField('country', event.target.value);
        props.setField('state', '');
    };

    let stateOptions = [];
    if (props.fields.country === 'US') {
        stateOptions = [
            <option value="AL">Alabama</option>,
            <option value="AK">Alaska</option>,
            <option value="AZ">Arizona</option>,
            <option value="AR">Arkansas</option>,
            <option value="CA">California</option>,
            <option value="CO">Colorado</option>,
            <option value="CT">Connecticut</option>,
            <option value="DE">Delaware</option>,
            <option value="DC">District of Columbia</option>,
            <option value="FL">Florida</option>,
            <option value="GA">Georgia</option>,
            <option value="HI">Hawaii</option>,
            <option value="ID">Idaho</option>,
            <option value="IL">Illinois</option>,
            <option value="IN">Indiana</option>,
            <option value="IA">Iowa</option>,
            <option value="KS">Kansas</option>,
            <option value="KY">Kentucky</option>,
            <option value="LA">Louisiana</option>,
            <option value="ME">Maine</option>,
            <option value="MD">Maryland</option>,
            <option value="MA">Massachusetts</option>,
            <option value="MI">Michigan</option>,
            <option value="MN">Minnesota</option>,
            <option value="MS">Mississippi</option>,
            <option value="MO">Missouri</option>,
            <option value="MT">Montana</option>,
            <option value="NE">Nebraska</option>,
            <option value="NV">Nevada</option>,
            <option value="NH">New Hampshire</option>,
            <option value="NJ">New Jersey</option>,
            <option value="NM">New Mexico</option>,
            <option value="NY">New York</option>,
            <option value="NC">North Carolina</option>,
            <option value="ND">North Dakota</option>,
            <option value="OH">Ohio</option>,
            <option value="OK">Oklahoma</option>,
            <option value="OR">Oregon</option>,
            <option value="PA">Pennsylvania</option>,
            <option value="PR">Puerto Rico</option>,
            <option value="RI">Rhode Island</option>,
            <option value="SC">South Carolina</option>,
            <option value="SD">South Dakota</option>,
            <option value="TN">Tennessee</option>,
            <option value="TX">Texas</option>,
            <option value="UT">Utah</option>,
            <option value="VT">Vermont</option>,
            <option value="VA">Virginia</option>,
            <option value="VI">Virgin Islands</option>,
            <option value="WA">Washington</option>,
            <option value="WV">West Virginia</option>,
            <option value="WI">Wisconsin</option>,
            <option value="WY">Wyoming</option>,
        ];
    } else if (props.fields.country === 'CA') {
        stateOptions = [
            <option value="AB">Alberta</option>,
            <option value="BC">British Columbia</option>,
            <option value="MB">Manitoba</option>,
            <option value="NB">New Brunswick</option>,
            <option value="NL">Newfoundland and Labrador</option>,
            <option value="NT">Northwest Territories</option>,
            <option value="NS">Nova Scotia</option>,
            <option value="NU">Nunavut</option>,
            <option value="ON">Ontario</option>,
            <option value="PE">Prince Edward Island</option>,
            <option value="QC">Quebec</option>,
            <option value="SK">Saskatchewan</option>,
            <option value="YT">Yukon</option>,
        ];
    }

    return (
        <div className="make-payment-form-container">
            <div className="logo-container">
                <img src="https://ac-pqn.s3.amazonaws.com/css/acm-logo.png" alt="ACM" />
                <div className={lockClass}>
                    <img src="https://ac-pqn.s3.amazonaws.com/css/lock.jpeg" alt="Lock" />
                </div>
            </div>
            <div className="main-container">
                <div className="title">Payment Summary:</div>
                {renderSummary()}
            </div>
            <div className={paymentClass}>
                <div className="title">Your Payment Information:</div>
                {paymentTypeField}
                {renderPaymentFields()}
                <div className="recaptcha">
                    <ReCAPTCHA
                        hl="en"
                        ref={recaptchaRef}
                        sitekey="6LerIo4pAAAAADq-MX4J5eZekUgls7mCZKHMeCTL"
                        onChange={onChange}
                    />
                </div>
            </div>
            <div className="billing-address-container">
                <div className="title">Your Billing Information:</div>
                <div className="field">
                    <div className="label">First Name <span className="required">*</span>:</div>
                    <input
                        type="text"
                        value={props.fields.first_name}
                        onChange={handleSetField.bind(this, 'first_name')}
                    />
                </div>
                <div className="field">
                    <div className="label">Last Name <span className="required">*</span>:</div>
                    <input
                        type="text"
                        value={props.fields.last_name}
                        onChange={handleSetField.bind(this, 'last_name')}
                    />
                </div>
                <div className="field email-field">
                    <div className="label">Email Address <span className="required">*</span>:</div>
                    <input
                        type="text"
                        value={props.fields.email}
                        onChange={handleSetField.bind(this, 'email')}
                    />
                </div>
                <div className="field">
                    <div className="label">Address Line 1 <span className="required">*</span>:</div>
                    <input
                        type="text"
                        value={props.fields.address1}
                        onChange={handleSetField.bind(this, 'address1')}
                    />
                </div>
                <div className="field">
                    <div className="label">Address Line 2:</div>
                    <input
                        type="text"
                        value={props.fields.address2}
                        onChange={handleSetField.bind(this, 'address2')}
                    />
                </div>
                <div className="field">
                    <div className="label">City <span className="required">*</span>:</div>
                    <input
                        type="text"
                        value={props.fields.city}
                        onChange={handleSetField.bind(this, 'city')}
                    />
                </div>
                <div className="field">
                    <div className="label">Country <span className="required">*</span>:</div>
                    <select value={props.fields.country} onChange={handleSetCountry}>
                        <option value="">Select option</option>
                        <option value="US">United States</option>
                        <option value="CA">Canada</option>
                    </select>
                </div>
                <div className="field">
                    <div className="label">State <span className="required">*</span>:</div>
                    <select value={props.fields.state} onChange={handleSetField.bind(this, 'state')}>
                        <option value="">Select option</option>
                        {stateOptions}
                    </select>
                </div>
                <div className="field">
                    <div className="label">Zip <span className="required">*</span>:</div>
                    <input
                        type="text"
                        value={props.fields.zip}
                        onChange={handleSetField.bind(this, 'zip')}
                    />
                </div>
                <div className="field">
                    <div className="label">Phone number:</div>
                    <input
                        type="text"
                        value={props.fields.phone}
                        onChange={handleSetField.bind(this, 'phone')}
                    />
                </div>
            </div>
            <div className="button-container">
                {renderPayButton()}
            </div>
        </div>
    );
});

export default Form;
