import { createSelector } from 'reselect';

export const getComponentState = state => state.search;

export const getFetchingActive = createSelector(
    getComponentState,
    (state) => state.fetchingActive
);
export const getFetchingFinished = createSelector(
    getComponentState,
    (state) => state.fetchingFinished
);
export const getActiveResults = createSelector(
    getComponentState,
    (state) => state.activeResults
);
export const getFinishedResults = createSelector(
    getComponentState,
    (state) => state.finishedResults
);
export const getActivePagination = createSelector(
    getComponentState,
    (state) => state.activePagination
);
export const getFinishedPagination = createSelector(
    getComponentState,
    (state) => state.finishedPagination
);
export const getSearch = createSelector(
    getComponentState,
    (state) => state.search
);
export const getShowActiveJobs = createSelector(
    getComponentState,
    (state) => state.showActiveJobs
);
export const getShowFinishedJobs = createSelector(
    getComponentState,
    (state) => state.showFinishedJobs
);