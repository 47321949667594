import React from 'react';
import { Dialog } from './../../common/dialog';
import { useDropzone } from 'react-dropzone';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';

const WrongEstimateModal = React.memo((props) => {
    const {
        // acceptedFiles,
        // fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        multiple: true,
        onDrop: (files) => {
            for (let i = 0; i < files.length; i++) {
                props.addFile(files[i]);
            }
        }
    });

    const renderFiles = () => {
        if (props.files.length === 0) {
            return (
                <tr>
                    <td colSpan="2">No files selected</td>
                </tr>
            );
        }

        return props.files.map((file, index) => {
            let actionIcon = (
                <Tooltip title="Remove File" position="bottom" arrow size="small" className="action-tooltip">
                    <i className="fas fa-trash" onClick={props.removeFile.bind(this, index)} />
                </Tooltip>
            );
            if (props.id) {
                actionIcon = (
                    <Tooltip title="Download File" position="bottom" arrow size="small" className="action-tooltip">
                        <a href={file.url} target="_blank" rel="noopener noreferrer"><i className="fas fa-download" /></a>
                    </Tooltip>
                );
            }

            return (
                <tr>
                    <td>{file.name}</td>
                    <td className="text-center actions">
                        {actionIcon}
                    </td>
                </tr>
            );
        });
    };

    let uploadingFiles = null;
    if (props.sendingWrongEstimate && props.files.length > 0) {
        uploadingFiles = <div><i className="fas fa-circle-notch fa-spin" /> Your files are uploading. It may take a few seconds depending on the size of the file.</div>;
    }

    let sendButtonLabel = 'Send';
    if (props.sendingWrongEstimate) {
        sendButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    const handleSetComments = (event) => {
        props.setComments(event.target.value);
    };

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={props.hideWrongEstimateModal}>Close</button>,
        <button key="send-button" className="btn btn-light btn-sm action-button" disabled={props.sendingWrongEstimate} onClick={props.sendWrongEstimate}>{sendButtonLabel}</button>,
    ];
    return (
        <Dialog
            name="wrong-estimate-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="wrong-estimate-dialog"
        >
            <div className="wrong-estimate-container">
                <h1>Send Estimate Comments</h1>
                <div className="comments-field">
                    <b>Enter your comments about the estimate:</b>
                    <textarea value={props.comments} onChange={handleSetComments} />
                </div>
                <div className="files-container">
                    <h3>
                        Attached Files
                        <div {...getRootProps({ className: 'dropzone-container' })}>
                            <input {...getInputProps()} />
                            <button>Browse</button>
                        </div>
                    </h3>
                    <table className="table table-sm table-hover">
                        <thead>
                            <tr>
                                <th>Filename</th>
                                <th className="actions"></th>
                            </tr>
                        </thead>
                        <tbody>{renderFiles()}</tbody>
                    </table>
                    {uploadingFiles}
                </div>
            </div>
        </Dialog >
    );
});

export default WrongEstimateModal;
