import React, { Fragment } from 'react';
import { useDropzone } from 'react-dropzone';
import numeral from 'numeral';
import moment from 'moment-timezone';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';

const UploadData = React.memo((props) => {
    const {
        // acceptedFiles,
        // fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        multiple: false,
        accept: '.xlsx,.csv',
        onDrop: (files) => {
            props.setFile(files[0]);
        }
    });

    let selectedFile = null;
    if (props.file != null) {
        selectedFile = (
            <div className="selected-file">
                <i>Selected File:</i> {props.file.name}
            </div>
        );
    }

    let title = '';
    if (parseInt(props.type) === 1) {
        title = 'American Express';
    } else if (parseInt(props.type) === 2) {
        title = 'Capital One Promovana';
    } else if (parseInt(props.type) === 3) {
        title = 'Capital One Paper';
    }

    let label = <Fragment><i className="fas fa-upload" /> Upload</Fragment>;
    if (props.uploadingFile) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    const handleSetProcessedDataField = (index, field, event) => {
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        }

        props.setProcessedDataField(index, field, value);
    };

    const renderProcessedData = () => {
        return props.processedData.map((element, index) => {
            if (parseInt(props.type) === 1) {
                return (
                    <tr>
                        <td>{element.date}</td>
                        <td>{element.description}</td>
                        <td>{element.card_member}</td>
                        <td>{numeral(element.amount).format('$0,0.00')}</td>
                        <td>{element.extended_details}</td>
                        <td>{element.address}</td>
                        <td>{element.city_state}</td>
                        <td>{element.zip}</td>
                        <td>{element.category}</td>
                        <td>
                            <select className="account-field" value={element.ap_account_id} onChange={handleSetProcessedDataField.bind(this, index, 'ap_account_id')}>
                                <option value="">Select account</option>
                                {props.information.accounts.map((account) => {
                                    return <option value={account.id}>{account.account} - {account.name}</option>;
                                })}
                            </select>
                        </td>
                        <td className="text-center">
                            <input type="checkbox" checked={element.create_bill} onChange={handleSetProcessedDataField.bind(this, index, 'create_bill')} />
                        </td>
                    </tr>
                );
            } else if (parseInt(props.type) === 2 || parseInt(props.type) === 3) {
                return (
                    <tr>
                        <td>{element.date}</td>
                        <td>{element.posted_date}</td>
                        <td>{element.card_number}</td>
                        <td>{element.description}</td>
                        <td>{element.category}</td>
                        <td>{numeral(element.debit).format('$0,0.00')}</td>
                        <td>{numeral(-Math.abs(element.credit)).format('$0,0.00')}</td>
                        <td>
                            <select className="account-field" value={element.ap_account_id} onChange={handleSetProcessedDataField.bind(this, index, 'ap_account_id')}>
                                <option value="">Select account</option>
                                {props.information.accounts.map((account) => {
                                    return <option value={account.id}>{account.account} - {account.name}</option>;
                                })}
                            </select>
                        </td>
                        <td className="text-center">
                            <input type="checkbox" checked={element.create_bill} onChange={handleSetProcessedDataField.bind(this, index, 'create_bill')} />
                        </td>
                    </tr>
                );
            }

            return null;
        });
    };

    let processedDataContainer = null;
    if (props.processedData) {
        let saveItemsLabel = 'Save Items';
        if (props.savingItems) {
            saveItemsLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        if (parseInt(props.type) === 1) {
            processedDataContainer = (
                <div className="processed-data-container">
                    <button className="save-items-button" onClick={props.saveItems} disabled={props.savingItems}>{saveItemsLabel}</button>
                    <table className="table table-sm table-hover table-striped">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Description</th>
                                <th>Card Member</th>
                                <th>Amount</th>
                                <th>Extended Details</th>
                                <th>Address</th>
                                <th>City/State</th>
                                <th>Zip Code</th>
                                <th>Category</th>
                                <th>GL Code</th>
                                <th>Create Bill</th>
                            </tr>
                        </thead>
                        <tbody>{renderProcessedData()}</tbody>
                    </table>
                </div>
            );
        } else if (parseInt(props.type) === 2 || parseInt(props.type) === 3) {
            processedDataContainer = (
                <div className="processed-data-container">
                    <button className="save-items-button" onClick={props.saveItems} disabled={props.savingItems}>{saveItemsLabel}</button>
                    <table className="table table-sm table-hover table-striped">
                        <thead>
                            <tr>
                                <th>Transaction Date</th>
                                <th>Posted Date</th>
                                <th>Card No.</th>
                                <th>Description</th>
                                <th>Category</th>
                                <th>Debit</th>
                                <th>Credit</th>
                                <th>GL Code</th>
                                <th>Create Bill</th>
                            </tr>
                        </thead>
                        <tbody>{renderProcessedData()}</tbody>
                    </table>
                </div>
            );
        }
    }

    const handleChangeName = (event) => {
        props.setName(event.target.value);
    };

    const handleChangePostedDate = (event) => {
        let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');
        props.setPostedDate(value);
    };

    return (
        <div className="upload-data-container">
            <div className="upload-file">
                <div className="file-container">
                    <h2>Accounts Payable</h2>
                    <div className="text-center mb-2"><b>{title}</b></div>
                    <div className="name-field">
                        <div>Name <span className="required">*</span>:</div>
                        <input type="text" value={props.name} onChange={handleChangeName} maxLength="21" />
                    </div>
                    <div className="posted-date-field">
                        <div>Posted Date <span className="required">*</span>:</div>
                        <DayPickerInput
                            formatDate={formatDate}
                            parseDate={parseDate}
                            placeholder=""
                            className="date-picker-field"
                            onDayChange={handleChangePostedDate}
                            value={props.posted_date == null || props.posted_date === '0000-00-00' ? '' : moment(props.posted_date).format('MM/DD/YYYY')}
                        />
                    </div>
                    <div className="select-file-container">
                        <div>File <span className="required">*</span>:</div>
                        <div {...getRootProps({ className: 'dropzone-container' })}>
                            <input {...getInputProps()} />
                            <i className="fas fa-file" /> Select File
                        </div>
                        {selectedFile}
                    </div>
                    <div className="buttons-container">
                        <button onClick={props.uploadFile} disabled={props.uploadingFile}>{label}</button>
                    </div>
                </div>
            </div>
            {processedDataContainer}
        </div>
    );
});

export default UploadData;
