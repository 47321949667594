import { createSelector } from 'reselect';

export const getComponentState = state => state.purchaseOrder;

export const getFetching = createSelector(
    getComponentState,
    (state) => state.fetching
);

export const getPurchaseOrder = createSelector(
    getComponentState,
    (state) => state.purchaseOrder
);

export const getInformation = createSelector(
    getComponentState,
    (state) => state.information
);

export const getFetchingVendorData = createSelector(
    getComponentState,
    (state) => state.fetchingVendorData
);

export const getIsSaving = createSelector(
    getComponentState,
    (state) => state.isSaving
);

export const getIsPushing = createSelector(
    getComponentState,
    (state) => state.isPushing
);

export const getVendorEmail = createSelector(
    getComponentState,
    (state) => state.vendorEmail
);

export const getJobDueDate = createSelector(
    getComponentState,
    (state) => state.jobDueDate
);