import React, { Fragment } from 'react';
import 'react-tippy/dist/tippy.css';
import numeral from 'numeral';
import { dateToTimezone, empty } from './../../../utils';
import { validateForm } from './../PqtPostageInvoice.actions';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import TextareaAutosize from 'react-textarea-autosize';

const Form = React.memo((props) => {
    if (props.fetching || props.information.fetching || props.invoice == null) {
        return (
            <div className="invoice-form-container">
                <i className="fas fa-circle-notch fa-spin" /> Loading data...
            </div>
        );
    }

    const handleOnChange = (field, event) => {
        let value = null;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        } else {
            value = event.target.value;
        }
        props.setField(field, value);

        if (field === 'use_customer_address' && value) {
            props.setField('billing_name', props.invoice.customerAddress.display_name);
            props.setField('billing_attn', props.invoice.customerAddress.name);
            props.setField('billing_address', props.invoice.customerAddress.street);
            props.setField('billing_city', props.invoice.customerAddress.city);
            props.setField('billing_state', props.invoice.customerAddress.state);
            props.setField('billing_zip', props.invoice.customerAddress.zip);

            if (props.invoice.use_bill_address) {
                props.setField('shipping_name', props.invoice.customerAddress.display_name);
                props.setField('shipping_attn', props.invoice.customerAddress.name);
                props.setField('shipping_address', props.invoice.customerAddress.street);
                props.setField('shipping_city', props.invoice.customerAddress.city);
                props.setField('shipping_state', props.invoice.customerAddress.state);
                props.setField('shipping_zip', props.invoice.customerAddress.zip);
            }
        }

        if (field === 'use_bill_address' && value) {
            props.setField('shipping_name', props.invoice.billing_name);
            props.setField('shipping_attn', props.invoice.billing_attn);
            props.setField('shipping_address', props.invoice.billing_address);
            props.setField('shipping_city', props.invoice.billing_city);
            props.setField('shipping_state', props.invoice.billing_state);
            props.setField('shipping_zip', props.invoice.billing_zip);
        }

        if (props.invoice.use_bill_address) {
            if (field === 'billing_name') {
                props.setField('shipping_name', value);
            } else if (field === 'billing_attn') {
                props.setField('shipping_attn', value);
            } else if (field === 'billing_address') {
                props.setField('shipping_address', value);
            } else if (field === 'billing_city') {
                props.setField('shipping_city', value);
            } else if (field === 'billing_state') {
                props.setField('shipping_state', value);
            } else if (field === 'billing_zip') {
                props.setField('shipping_zip', value);
            }
        }
    };

    let stagecoachLink = 'N/A';
    if (!(props.invoice.stagecoach_number == null || props.invoice.stagecoach_number === '')) {
        stagecoachLink = <Link to={'/tracking/' + props.invoice.stagecoach_number}>{props.invoice.stagecoach_number}</Link>
    }

    const handleUpdateItem = (index, field, event) => {
        let value = null;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        } else {
            value = event.target.value;
        }

        props.setItemField(index, field, value);
    };

    const handleUpdatePrice = (index, field, invoiceClassId, event) => {
        let value = parseFloat(event.target.value);

        if (([56, 77, 80, 82].includes(parseInt(invoiceClassId))) && value > 0) {      // Prepaid Postage or Payment for Services
            value = -value;
        }

        props.setItemField(index, field, value);
    };

    const renderClassesOptions = (departmentId) => {
        return props.information.classes.map((item) => {
            if (parseInt(item.department_id) !== parseInt(departmentId)) {
                return null;
            }

            return <option key={item.id} value={item.id}>{item.name}</option>;
        });
    };

    const handleChangeDepartment = (index, event) => {
        props.setItemField(index, 'department_id', event.target.value);
    };

    const renderItems = (index) => {
        let totalShow = 0;
        for (const item of props.invoice.items) {
            if (item.show) {
                totalShow++;
            }
        }

        const itemsElements = [];
        for (let i = 0; i < props.invoice.items.length; i++) {
            let disableFields = false;
            let isDescription = false;
            const departmentId = parseInt(props.invoice.items[i].department_id);
            if (departmentId === 62) {
                isDescription = true;
            }

            let disableTax = false;
            // if (departmentId === 56 || departmentId === 60) {   // Prepaid Postage or Postage
            //     disableTax = true;
            // }

            let quantity = props.invoice.items[i].quantity;
            let price = props.invoice.items[i].price;
            let taxable = props.invoice.items[i].taxable;
            let amount = props.invoice.items[i].amount;
            if (isDescription) {
                quantity = '';
                price = '';
                amount = '';
                taxable = 0;
            }

            const acmPrepaidDisabled = parseInt(props.invoice.items[i].department_id) === 67;

            let minimumPriceContainer = null;
            let minimumAmountContainer = null;
            let minimumPriceFieldClass = '';
            let minimumAmountFieldClass = '';
            if (props.user.id !== 61 && !empty(props.invoice.items[i]['minimum_amount'])) {
                const minimumAmount = parseFloat(parseFloat(props.invoice.items[i]['minimum_amount']).toFixed(2));

                let amountFloat = 0;
                if (!isNaN(amount) && !empty(amount)) {
                    amountFloat = parseFloat(amount);
                }
                let minimumAmountClass = 'min-amount';
                if (amountFloat < minimumAmount) {
                    minimumAmountFieldClass = 'field-below-min';
                    minimumAmountClass = 'min-amount below-min';
                }
                minimumAmountContainer = <div className={minimumAmountClass}>Min: {numeral(minimumAmount).format('$0,0.00')}</div>;
            }

            itemsElements[i] = {
                departmentId: props.invoice.items[i].department_id,
                element: (
                    <tr key={i}>
                        {/* <td>{i + 1}</td> */}
                        <td>{props.invoice.items[i].sort_position}</td>
                        <td>
                            <select value={props.invoice.items[i].department_id} onChange={handleChangeDepartment.bind(this, i)} disabled={disableFields}>
                                <option value="" className="select-option">Select option</option>
                                <option value="62">Description</option>
                                <option value="60" className="postage-option">Postage Sales</option>
                                <option value="63" className="postage-option">Convenience Fee</option>
                                <option value="56" className="postage-option">Prepaid Postage</option>
                            </select>
                        </td>
                        <td>
                            <select value={props.invoice.items[i].invoice_class_id} onChange={handleUpdateItem.bind(this, i, 'invoice_class_id')} disabled={disableFields || acmPrepaidDisabled}>
                                <option value="">Select option</option>
                                {renderClassesOptions(props.invoice.items[i].department_id)}
                            </select>
                        </td>
                        <td>
                            <TextareaAutosize
                                className="textarea-description"
                                value={props.invoice.items[i].description}
                                onChange={handleUpdateItem.bind(this, i, 'description')}
                                disabled={disableFields}
                                maxRows={6}
                            />
                        </td>
                        <td>
                            <input
                                type="number"
                                value={quantity}
                                onChange={handleUpdateItem.bind(this, i, 'quantity')}
                                disabled={disableFields || isDescription || acmPrepaidDisabled}
                            />
                        </td>
                        <td>
                            <input
                                type="number"
                                value={price}
                                disabled={disableFields || isDescription || acmPrepaidDisabled}
                                className={minimumPriceFieldClass}
                                onChange={handleUpdatePrice.bind(this, i, 'price', props.invoice.items[i].invoice_class_id)}
                            />
                            {minimumPriceContainer}
                        </td>
                        <td>
                            <select value={props.invoice.items[i].unit} onChange={handleUpdateItem.bind(this, i, 'unit')} disabled={disableFields || isDescription || acmPrepaidDisabled}>
                                <option value="1">Ea.</option>
                                <option value="2">Lot</option>
                                <option value="3">per 100</option>
                                <option value="4">per 1000</option>
                            </select>
                        </td>
                        <td className="text-center">
                            <input
                                type="checkbox"
                                value={taxable}
                                checked={taxable}
                                disabled={disableTax || disableFields || isDescription}
                                onChange={handleUpdateItem.bind(this, i, 'taxable')}
                            />
                        </td>
                        <td className="text-center">
                            <input
                                type="checkbox"
                                value={props.invoice.items[i].show}
                                checked={props.invoice.items[i].show}
                                onChange={handleUpdateItem.bind(this, i, 'show')}
                                disabled={(props.invoice.items[i].show && totalShow <= 1) || disableFields || isDescription}
                            />
                        </td>
                        <td className="text-right">
                            <input
                                type="number"
                                value={amount}
                                className={minimumAmountFieldClass}
                                disabled={disableFields || isDescription || acmPrepaidDisabled}
                                onChange={handleUpdatePrice.bind(this, i, 'amount', props.invoice.items[i].invoice_class_id)}
                            />
                            {minimumAmountContainer}
                        </td>
                        <td className="actions"></td>
                    </tr>
                )
            };
        }

        let itemLines = [];
        for (let i = 0; i < itemsElements.length; i++) {
            itemLines.push(itemsElements[i].element);
        }

        return itemLines;
    };

    let subtotal = 0;
    let subtotalForTax = 0;
    let totalDiscount = 0;
    let totalTax = 0;
    let total = 0;
    for (const item of props.invoice.items) {
        if (parseInt(item.department_id) === 62 || parseInt(item.department_id) === 70) {
            continue;
        }

        if (item.quantity != null && item.quantity !== '' && item.price != null && item.price !== '') {
            subtotal += parseFloat(item.amount);

            if (item.taxable != null && item.taxable !== '' && item.taxable) {
                subtotalForTax += parseFloat(item.amount);
            }
        }
    }
    subtotal = subtotal.toFixed(5);
    subtotalForTax = subtotalForTax.toFixed(5);

    if (props.invoice.discount != null && props.invoice.discount !== '') {
        if (parseInt(props.invoice.discount_type) === 1) {
            totalDiscount = subtotal * parseFloat(props.invoice.discount) / 100;
        } else {
            totalDiscount = parseFloat(props.invoice.discount);
        }
    }

    if (props.invoice.tax != null && props.invoice.tax !== '') {
        totalTax = subtotalForTax * parseFloat(props.invoice.tax) / 100;
    }

    total = parseFloat(numeral(subtotal).format('0.00')) +
        parseFloat(numeral(totalTax).format('0.00')) -
        parseFloat(numeral(totalDiscount).format('0.00'));

    let saveButtonLabel = <Fragment>Save <i className="fas fa-save" /></Fragment>;
    if (props.isSaving) {
        saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    let saveButton = null;
    let statusInt = parseInt(props.invoice.status);
    if (!props.invoice.combined && (statusInt < 3 || (statusInt === 3 && ([1, 2, 9].includes(parseInt(props.user.pqt_type)) || props.user.is_pqt_accounting)))) {     // Approve
        saveButton = <button className="action-button save-button" onClick={props.saveInvoice} disabled={props.isSaving}>{saveButtonLabel}</button>;
    }
    if (!props.invoice.combined && statusInt === 4 && props.user != null && [61, 2419].includes(parseInt(props.user.id))) {   // Hugh (61), Stephanie (2419)
        saveButton = <button className="action-button save-button" onClick={props.saveInvoice} disabled={props.isSaving}>{saveButtonLabel}</button>;
    }
    if (props.invoice.combined && (statusInt < 3 || (statusInt === 3 && ([1, 2, 9].includes(parseInt(props.user.pqt_type)) || props.user.is_pqt_accounting)))) {     // Approve
        saveButton = <button className="action-button save-button" onClick={props.saveInvoice} disabled={props.isSaving}>{saveButtonLabel}</button>;
    }

    let customerName = 'N/A';
    for (const customer of props.information.customers) {
        if (parseInt(props.invoice.customer_id) === parseInt(customer.id)) {
            customerName = customer.display_name;
            break;
        }
    }

    let salesRepName = 'N/A';
    for (const salesRep of props.information.salesReps) {
        if (parseInt(props.invoice.sales_rep_id) === parseInt(salesRep.id)) {
            salesRepName = salesRep.name;
            break;
        }
    }

    let csrName = 'N/A';
    for (const csr of props.information.csrs) {
        if (parseInt(props.invoice.csr_id) === parseInt(csr.id)) {
            csrName = csr.name;
            break;
        }
    }

    let jobDescription = <span>{props.invoice.job_description}</span>;
    let idsCombinedContainer = null;
    let printSmithLabel = 'SC/EST';
    let poContainer = props.invoice.po_number;
    if (props.invoice.combined_ids != null) {
        printSmithLabel = 'Combined #'
        const ids = JSON.parse(props.invoice.combined_ids);
        let idsElements = [];
        for (let i = 0; i < ids.length; i++) {
            if (i === ids.length - 1) {
                idsElements.push(<Link to={'/invoice/' + ids[i]}>{ids[i]}</Link>);
            } else {
                idsElements.push(<Fragment><Link to={'/invoice/' + ids[i]}>{ids[i]}</Link>, </Fragment>);
            }
        }
        idsCombinedContainer = <div className="combined-ids">Combined invoices: {idsElements}</div>;
        jobDescription = (
            <input
                type="text"
                className="job-description-field"
                value={props.invoice.job_description}
                onChange={handleOnChange.bind(this, 'job_description')}
            />
        );
        poContainer = (
            <input
                type="text"
                value={props.invoice.po_number}
                onChange={handleOnChange.bind(this, 'po_number')}
            />
        );
    }

    let splitInvoicesContainer = null;
    if (props.invoice.split_ids != null && props.invoice.split_ids.length > 0) {
        printSmithLabel = 'Combined #'
        const ids = props.invoice.split_ids;
        let idsElements = [];
        for (let i = 0; i < ids.length; i++) {
            if (i === ids.length - 1) {
                idsElements.push(<Link to={'/invoice/' + ids[i]}>{ids[i]}</Link>);
            } else {
                idsElements.push(<Fragment><Link to={'/invoice/' + ids[i]}>{ids[i]}</Link>, </Fragment>);
            }
        }
        splitInvoicesContainer = (
            <div className="split-invoice mr-5">
                Split Invoices: {idsElements}
            </div>
        );
    }

    const handlePreview = (logo) => {
        if (!validateForm(props.invoice, false, props.user)) {
            return;
        }

        window.open(process.env.REACT_APP_API_URL + '/invoice/preview?id=' + props.invoice.id + '&logo=' + logo);
    };

    let previewButton = null;
    if (!props.invoice.combined) {
        previewButton = <button className="action-button" onClick={handlePreview.bind(this, 1)}>Preview PDF <i className="fas fa-file-pdf" /></button>;
    }

    let billingAttn = null;
    if (props.invoice.use_customer_address) {
        if (props.invoice.billing_attn == null || props.invoice.billing_attn === '') {
            billingAttn = 'Accounts Payable';
        } else {
            billingAttn = props.invoice.billing_attn;
        }
    } else {
        billingAttn = <input type="text" value={props.invoice.billing_attn} onChange={handleOnChange.bind(this, 'billing_attn')} />;
    }

    let shippingAttn = null;
    if (props.invoice.use_bill_address) {
        if (props.invoice.shipping_attn == null || props.invoice.shipping_attn === '') {
            shippingAttn = 'Accounts Payable';
        } else {
            shippingAttn = props.invoice.shipping_attn;
        }
    } else {
        shippingAttn = <input type="text" value={props.invoice.shipping_attn} onChange={handleOnChange.bind(this, 'shipping_attn')} />;
    }

    let balance = total;
    if (props.invoice.balance != null) {
        balance = props.invoice.balance;
    }

    let amountReceived = total - balance;
    if (amountReceived > 0) {
        amountReceived = -amountReceived;
    }
    amountReceived = amountReceived.toFixed(2);

    const setDateFieldHandler = (name, event) => {
        let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');
        props.setField(name, value);
    };

    let postageEscrow = props.postageEscrow;
    if (parseFloat(postageEscrow.toFixed(2)) < 0) {
        postageEscrow = <span className="red-scrow">{numeral(parseFloat(postageEscrow.toFixed(2))).format('$0,0.00')}</span>;
    } else {
        postageEscrow = <span>{numeral(parseFloat(postageEscrow.toFixed(2))).format('$0,0.00')}</span>;
    }

    if (props.fetchingPostageEscrow) {
        postageEscrow = <span><i className="fas fa-circle-notch fa-spin" /></span>;
    }

    const validateApprovePermissions = () => {
        if (parseInt(props.invoice.status) === 1 && props.user != null) {
            if ([1, 9].includes(parseInt(props.user.pqt_type)) || props.user.is_pqt_accounting) {
                return true;
            }
        }

        return false;
    };

    let approveButton = null;
    if (validateApprovePermissions()) {
        let approveLabel = <Fragment>Approve Payment <i className="fas fa-file-invoice-dollar" /></Fragment>;
        if (props.isApproving) {
            approveLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        approveButton = (
            <button className="action-button approve-button" onClick={props.showApproveModal} disabled={props.isApproving}>
                {approveLabel}
            </button>
        );
    }

    return (
        <div className="invoice-form-container">
            <div className="fields-section">
                <div className="customer-section">
                    <h4>Customer</h4>
                    <div className="field">
                        <div className="label">Customer:</div>
                        {customerName}
                    </div>
                    <div className="field">
                        <div className="label">Invoice Date:</div>
                        <DayPickerInput
                            formatDate={formatDate}
                            parseDate={parseDate}
                            placeholder=""
                            className="date-picker-field"
                            onDayChange={setDateFieldHandler.bind(this, 'invoice_date')}
                            value={props.invoice.invoice_date == null || props.invoice.invoice_date === '0000-00-00' ? '' : moment(props.invoice.invoice_date).format('MM/DD/YYYY')}
                        />
                    </div>
                </div>
                <div className="billing-section">
                    <h4>
                        <div>Bill To</div>
                        <div className="same-address">
                            <input
                                type="checkbox"
                                checked={props.invoice.use_customer_address}
                                onChange={handleOnChange.bind(this, 'use_customer_address')}
                            /> Use Customer Address
                        </div>
                    </h4>
                    <div className="fields">
                        <div className="field">
                            <div className="label">Name:</div>
                            {
                                props.invoice.use_customer_address
                                    ? props.invoice.billing_name
                                    : <input type="text" value={props.invoice.billing_name} onChange={handleOnChange.bind(this, 'billing_name')} />
                            }
                        </div>
                        <div className="field">
                            <div className="label">Attn:</div>
                            {billingAttn}
                        </div>
                        <div className="field">
                            <div className="label">Address:</div>
                            {
                                props.invoice.use_customer_address
                                    ? props.invoice.billing_address
                                    : <input type="text" value={props.invoice.billing_address} onChange={handleOnChange.bind(this, 'billing_address')} />
                            }
                        </div>
                        <div className="field">
                            <div className="label">City:</div>
                            {
                                props.invoice.use_customer_address
                                    ? props.invoice.billing_city
                                    : <input type="text" value={props.invoice.billing_city} onChange={handleOnChange.bind(this, 'billing_city')} />
                            }
                        </div>
                        <div className="field">
                            <div className="label">State:</div>
                            {
                                props.invoice.use_customer_address
                                    ? props.invoice.billing_state
                                    : <input type="text" value={props.invoice.billing_state} onChange={handleOnChange.bind(this, 'billing_state')} />
                            }
                        </div>
                        <div className="field">
                            <div className="label">Zip:</div>
                            {
                                props.invoice.use_customer_address
                                    ? props.invoice.billing_zip
                                    : <input type="text" value={props.invoice.billing_zip} onChange={handleOnChange.bind(this, 'billing_zip')} />
                            }
                        </div>
                    </div>
                </div>
                <div className="shipping-section">
                    <h4>
                        <div>Ship To / Work For</div>
                        <div className="same-address">
                            <input
                                type="checkbox"
                                checked={props.invoice.use_bill_address}
                                onChange={handleOnChange.bind(this, 'use_bill_address')}
                            /> Use Bill Address
                        </div>
                    </h4>
                    <div className="fields">
                        <div className="field">
                            <div className="label">Name:</div>
                            {
                                props.invoice.use_bill_address
                                    ? props.invoice.shipping_name
                                    : <input type="text" value={props.invoice.shipping_name} onChange={handleOnChange.bind(this, 'shipping_name')} />
                            }
                        </div>
                        <div className="field">
                            <div className="label">Attn:</div>
                            {shippingAttn}
                        </div>
                        <div className="field">
                            <div className="label">Address:</div>
                            {
                                props.invoice.use_bill_address
                                    ? props.invoice.shipping_address
                                    : <input type="text" value={props.invoice.shipping_address} onChange={handleOnChange.bind(this, 'shipping_address')} />
                            }
                        </div>
                        <div className="field">
                            <div className="label">City:</div>
                            {
                                props.invoice.use_bill_address
                                    ? props.invoice.shipping_city
                                    : <input type="text" value={props.invoice.shipping_city} onChange={handleOnChange.bind(this, 'shipping_city')} />
                            }
                        </div>
                        <div className="field">
                            <div className="label">State:</div>
                            {
                                props.invoice.use_bill_address
                                    ? props.invoice.shipping_state
                                    : <input type="text" value={props.invoice.shipping_state} onChange={handleOnChange.bind(this, 'shipping_state')} />
                            }
                        </div>
                        <div className="field">
                            <div className="label">Zip:</div>
                            {
                                props.invoice.use_bill_address
                                    ? props.invoice.shipping_zip
                                    : <input type="text" value={props.invoice.shipping_zip} onChange={handleOnChange.bind(this, 'shipping_zip')} />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="fields-section other-fields">
                <div className="field number-field">
                    <div className="label">{printSmithLabel}:</div>
                    <input type="text" value={props.invoice.print_smith} onChange={handleOnChange.bind(this, 'print_smith')} />
                </div>
                <div className="field number-field">
                    <div className="label">Quote #:</div>
                    <input type="text" value={props.invoice.quote} onChange={handleOnChange.bind(this, 'quote')} />
                </div>
                <div className="field number-field">
                    <div className="label">P.O. Number:</div>
                    {poContainer}
                </div>
                <div className="field terms-field">
                    <div className="label">Terms:</div>
                    Due on receipt
                </div>
                <div className="field name-field">
                    <div className="label">Sales Rep.:</div>
                    {salesRepName}
                </div>
                <div className="field name-field">
                    <div className="label">CSR:</div>
                    {csrName}
                </div>
                <div className="field number-field">
                    <div className="label">Stagecoach No.:</div>
                    {stagecoachLink}
                </div>
                <div className="field shipping-method">
                    <div className="label">Shipping Method:</div>
                    {props.invoice.shipping_method && props.invoice.shipping_method !== '' ? props.invoice.shipping_method : 'N/A'}
                </div>
                <div className="field tracking-field">
                    <div className="label">Tracking No.:</div>
                    {props.invoice.tracking_number && props.invoice.tracking_number !== '' ? props.invoice.tracking_number : 'N/A'}
                </div>
            </div>
            <div className="items-list">
                <h4>
                    <div className="title-column">
                        <div>Items</div>
                        <div className="job-description">Job Description: {jobDescription}</div>
                        {/* <div className="payment-link">
                            <strong>Payment Link:</strong> <a href={`https://www.stagecoachview.com/make-payment/${props.invoice.payment_token}`} target="_blank" rel="noopener noreferrer">Click Here</a>
                        </div> */}
                    </div>
                    {splitInvoicesContainer}
                    {idsCombinedContainer}
                </h4>
                <table className="table table-sm table-hover">
                    <thead>
                        <tr>
                            <th className="number-th">#</th>
                            <th className="department-th">Product/Service</th>
                            <th className="task-th">Class</th>
                            <th className="description-th">Description</th>
                            <th className="quantity-th">Quantity</th>
                            <th className="price-th">
                                <div className="price-th-container">
                                    <div>
                                        <input
                                            type="checkbox"
                                            className="show-unit-price-checkbox"
                                            value={props.invoice.show_unit}
                                            checked={props.invoice.show_unit}
                                            onClick={handleOnChange.bind(this, 'show_unit')}
                                        />
                                    </div>
                                    <div>Price</div>
                                </div>
                            </th>
                            <th className="unit-th">Unit</th>
                            <th className="tax-th text-center">Taxable</th>
                            <th className="show-th text-center">Show</th>
                            <th className="amount-th text-right">Amount</th>
                            <th className="actions-th"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderItems()}
                    </tbody>
                </table>
                <div className="bottom-container">
                    <div className="items-buttons-container">
                        <div className="button-field-container">
                            <div className="button-container">
                                <button className="action-button" onClick={props.addLine}>Add Line <i className="fas fa-plus" /></button>
                            </div>
                        </div>
                        <div className="credit-info-container">
                            <div><strong>Postal Qualification Closeout Amount:</strong> {numeral(props.invoice.posta_qualification_postage_amount).format('$0,0.00')}</div>
                            <div><strong>Postage Accounting Closeout Amount:</strong> {numeral(props.invoice.posta_accounting_postage_amount).format('$0,0.00')}</div>
                            <div><strong>Difference:</strong> {numeral(props.invoice.posta_qualification_postage_amount - props.invoice.posta_accounting_postage_amount).format('$0,0.00')}</div>
                        </div>
                        <div className="audit-history-container">
                            <div className="title">Audit Log:</div>
                            <div className="log">
                                {props.invoice.auditHistory.map((record, index) => (
                                    <div className="log-element" key={'log-element-' + index}>
                                        <b className={record.is_update ? 'pink-value' : ''}>{dateToTimezone(record.created_at, 'MMMM Do YYYY, h:mm a')}:</b> <span className="label" dangerouslySetInnerHTML={{ __html: record.label }}></span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="totals-container">
                        <div className="total-item">
                            <div className="label">Subtotal:</div>
                            <div className="value">{numeral(subtotal).format('$0,0.00')}</div>
                        </div>
                        <div className="total-item discount">
                            <div className="label">
                                <select value={props.invoice.discount_type} onChange={handleOnChange.bind(this, 'discount_type')} >
                                    <option value="1">Discount percent</option>
                                    <option value="2">Discount value</option>
                                </select>
                                <input type="text" value={props.invoice.discount} onChange={handleOnChange.bind(this, 'discount')} /> &nbsp;Discount:
                            </div>
                            <div className="value">{numeral(-totalDiscount).format('$0,0.00')}</div>
                        </div>
                        <div className="total-item tax">
                            <div className="label">Sales Tax (8.25%):</div>
                            <div className="value">{numeral(totalTax).format('$0,0.00')}</div>
                        </div>
                        <div className="total-item total">
                            <div className="label">Total:</div>
                            <div className="value">{numeral(total).format('$0,0.00')}</div>
                        </div>
                        <div className="total-item total amount-received-container">
                            <div className="postage-escrow">
                                Prepaid Funds: {postageEscrow}
                            </div>
                            <div className="amount-received">
                                <div className="label">Amount received:</div>
                                <div className="value">{numeral(amountReceived).format('$0,0.00')}</div>
                            </div>
                        </div>
                        <div className="total-item total">
                            <div className="label">Balance due:</div>
                            <div className="value">{numeral(balance).format('$0,0.00')}</div>
                        </div>
                        <div className="buttons-container">
                            {approveButton}
                            {previewButton}
                            {saveButton}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Form;
