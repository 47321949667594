import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ActiveResults from './ActiveResults';
import FinishedResults from './FinishedResults';
import * as actions from './Search.actions';
import * as selectors from './Search.selectors';
import './Search.scss';
import PqtLayout from './../PqtLayout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck, showNotification } from './../../utils';
import * as loginSelectors from './../Login/Login.selectors';
import { Redirect } from 'react-router-dom';

const Search = (props) => {
    /* State to props */
    const fetchingActive = useSelector(selectors.getFetchingActive);
    const fetchingFinished = useSelector(selectors.getFetchingFinished);
    const activeResults = useSelector(selectors.getActiveResults, stringifyEqualityCheck);
    const finishedResults = useSelector(selectors.getFinishedResults, stringifyEqualityCheck);
    const activePagination = useSelector(selectors.getActivePagination, stringifyEqualityCheck);
    const finishedPagination = useSelector(selectors.getFinishedPagination, stringifyEqualityCheck);
    const search = useSelector(selectors.getSearch);
    const showActiveJobs = useSelector(selectors.getShowActiveJobs);
    const showFinishedJobs = useSelector(selectors.getShowFinishedJobs);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const searchActive = useCallback(() => dispatch(actions.searchActive()), [dispatch]);
    const searchFinished = useCallback(() => dispatch(actions.searchFinished()), [dispatch]);
    const setActivePage = useCallback((value) => dispatch(actions.setActivePage(value)), [dispatch]);
    const setFinishedPage = useCallback((value) => dispatch(actions.setFinishedPage(value)), [dispatch]);
    const setSearch = useCallback((value) => dispatch(actions.setSearch(value)), [dispatch]);
    const setShowActiveJobs = useCallback((value) => dispatch(actions.setShowActiveJobs(value)), [dispatch]);
    const setShowFinishedJobs = useCallback((value) => dispatch(actions.setShowFinishedJobs(value)), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        searchActive();
        searchFinished();
    }, [searchActive, searchFinished]);

    if (user != null && ![1, 2, 3, 4, 5, 9].includes(parseInt(user.pqt_type)) && !user.is_pqt_accounting) {
        return <Redirect to="/dashboard" />;
    }

    const handleSetCheckbox = (setFunc, event) => {
        setFunc(event.target.checked ? 1 : 0);
    };

    const handleSearch = () => {
        if (search === '') {
            return showNotification('Enter a value', 'Enter a text to search', 'info');
        }

        if (showActiveJobs) {
            setActivePage(1);
        }
        if (showFinishedJobs) {
            setFinishedPage(1);
        }
    };

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    const keyPressHandler = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    }

    let searchButtonLabel = 'Search';
    if (fetchingActive || fetchingFinished) {
        searchButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    return (
        <PqtLayout title="Search" history={props.history}>
            <Helmet><title>Search - Stagecoach</title></Helmet>
            <div className="search-container">
                <div className="search-field">
                    <div><b>Search Description:</b> <input type="text" value={search} onKeyPress={keyPressHandler} onChange={handleSearchChange} /></div>
                    <div><button onClick={handleSearch}>{searchButtonLabel}</button></div>
                </div>
                <div className="checkboxes">
                    <input type="checkbox" checked={showActiveJobs} onClick={handleSetCheckbox.bind(this, setShowActiveJobs)} /> <b>Active Jobs</b>
                    <input type="checkbox" checked={showFinishedJobs} onClick={handleSetCheckbox.bind(this, setShowFinishedJobs)} /> <b>Finished Jobs</b>
                </div>
                <ActiveResults
                    fetchingActive={fetchingActive}
                    activeResults={activeResults}
                    activePagination={activePagination}
                    showActiveJobs={showActiveJobs}
                    user={user}
                    searchActive={searchActive}
                    setActivePage={setActivePage}
                />
                <FinishedResults
                    fetchingFinished={fetchingFinished}
                    finishedResults={finishedResults}
                    finishedPagination={finishedPagination}
                    showFinishedJobs={showFinishedJobs}
                    user={user}
                    searchFinished={searchFinished}
                    setFinishedPage={setFinishedPage}
                />
            </div>
        </PqtLayout>
    );
};

export default Search;
