export const FETCH_ACCOUNTS = 'AP_ACCOUNTS/FETCH_ACCOUNTS';
export const FETCH_ACCOUNTS_SUCCESS = 'AP_ACCOUNTS/FETCH_ACCOUNTS_SUCCESS';
export const FETCH_ACCOUNTS_ERROR = 'AP_ACCOUNTS/FETCH_ACCOUNTS_ERROR';
export const FETCH_ACCOUNT = 'AP_ACCOUNTS/FETCH_ACCOUNT';
export const FETCH_ACCOUNT_SUCCESS = 'AP_ACCOUNTS/FETCH_ACCOUNT_SUCCESS';
export const FETCH_ACCOUNT_ERROR = 'AP_ACCOUNTS/FETCH_ACCOUNT_ERROR';
export const SAVE_ACCOUNT = 'AP_ACCOUNTS/SAVE_ACCOUNT';
export const SAVE_ACCOUNT_SUCCESS = 'AP_ACCOUNTS/SAVE_ACCOUNT_SUCCESS';
export const SAVE_ACCOUNT_ERROR = 'AP_ACCOUNTS/SAVE_ACCOUNT_ERROR';
export const DELETE_ACCOUNT = 'AP_ACCOUNTS/DELETE_ACCOUNT';
export const DELETE_ACCOUNT_SUCCESS = 'AP_ACCOUNTS/DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_ERROR = 'AP_ACCOUNTS/DELETE_ACCOUNT_ERROR';
export const RESET_STATE = 'AP_ACCOUNTS/RESET_STATE';
export const SET_ID = 'AP_ACCOUNTS/SET_ID';
export const SET_SORT = 'AP_ACCOUNTS/SET_SORT';
export const SET_ACTIVE_PAGE = 'AP_ACCOUNTS/SET_ACTIVE_PAGE';
export const SET_ID_FOR_DELETE = 'AP_ACCOUNTS/SET_ID_FOR_DELETE';
export const CHANGE_FILTER = 'AP_ACCOUNTS/CHANGE_FILTER';
export const SET_FIELD = 'AP_ACCOUNTS/SET_FIELD';
export const RESET_FIELDS = 'AP_ACCOUNTS/RESET_FIELDS';