import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { Provider } from 'react-redux';
import store from './store';
import ReactNotification from 'react-notifications-component';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import 'react-notifications-component/dist/theme.css';
import './index.scss';
import ApAccounts from './components/ApAccounts';
import ApItems from './components/ApItems';
import ApRecords from './components/ApRecords';
import CcPaymentAuth from './components/CcPaymentAuth';
import ChristyRfq from './components/ChristyRfq';
import ChristyRfqs from './components/ChristyRfqs';
import CreditMemo from './components/CreditMemo';
import CreditMemos from './components/CreditMemos';
import CommissionCompensations from './components/CommissionCompensations';
import CustomerApplication from './components/CustomerApplication';
import DeletedInvoices from './components/DeletedInvoices';
import DeletedJobs from './components/DeletedJobs';
import Estimates from './components/Estimates';
import Estimate from './components/Estimate';
import Login from './components/Login';
import HelpItems from './components/HelpItems';
import HrpPayrollReports from './components/HrpPayrollReports';
import InformedVisibilityJobs from './components/InformedVisibilityJobs';
import InformedVisibilityJob from './components/InformedVisibilityJob';
import MakePayment from './components/MakePayment';
import MarkupCalculator from './components/MarkupCalculator';
import MailingCategories from './components/MailingCategories';
import MailingMatrixes from './components/MailingMatrixes';
import MailingSubcategories from './components/MailingSubcategories';
import OnHoldCustomers from './components/OnHoldCustomers';
import PqnDigitalMachines from './components/PqnDigitalMachines';
// import PayrollReports from './components/PayrollReports';
import PostageCalculator from './components/PostageCalculator';
import PqnPresses from './components/PqnPresses';
import PqtDashboard from './components/PqtDashboard';
import PqtDepartment from './components/PqtDepartment';
import PqtDepartmentView from './components/PqtDepartmentView';
import PqtInvoice from './components/PqtInvoice';
import PqtInvoices from './components/PqtInvoices';
import PqtPostageReport from './components/PqtPostageReport';
import PqtPostageInvoice from './components/PqtPostageInvoice';
import PqtPostageInvoices from './components/PqtPostageInvoices';
import PqtTracking from './components/PqtTracking';
import PurchaseOrders from './components/PurchaseOrders';
import PurchaseOrder from './components/PurchaseOrder';
import QuoteRequests from './components/QuoteRequests';
import QuoteRequest from './components/QuoteRequest';
import Search from './components/Search';
import Users from './components/Users';
// import VdpDashboard from './components/VdpDashboard';
// import VdpEditor from './components/VdpEditor';
// import VdpProject from './components/VdpProject';
// import VdpTutorials from './components/VdpTutorials';
// import VdpUtHealthEditor from './components/VdpUtHealthEditor';
// import VdpWeekDrops from './components/VdpWeekDrops';

require('dotenv').config();

if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
}

ReactDOM.render(
    <Provider store={store}>
        <ReactNotification />
        <Router>
            <Route path="/login" exact component={Login} />
            <Route path="/" exact component={PqtDashboard} />
            <Route path="/dashboard" exact component={PqtDashboard} />
            <Route path="/department/:id" exact component={PqtDepartment} />
            <Route path="/department/:id/:showJobs" exact component={PqtDepartment} />
            <Route path="/department-closeout/:id/:jobId" exact component={PqtDepartment} />
            <Route path="/department-view/:id" exact component={PqtDepartmentView} />
            <Route path="/department-view/:id/:showJobs" exact component={PqtDepartmentView} />
            <Route path="/invoice/:id" exact component={PqtInvoice} />
            <Route path="/invoices" exact component={PqtInvoices} />
            <Route path="/invoices/:status" exact component={PqtInvoices} />
            <Route path="/invoices/:status/:type" exact component={PqtInvoices} />
            <Route path="/postage-invoice/:id" exact component={PqtPostageInvoice} />
            <Route path="/postage-invoices" exact component={PqtPostageInvoices} />
            <Route path="/tracking" exact component={PqtTracking} />
            <Route path="/tracking/:id" exact component={PqtTracking} />
            <Route path="/postage-report" exact component={PqtPostageReport} />
            <Route path="/purchase-orders" exact component={PurchaseOrders} />
            <Route path="/purchase-order" exact component={PurchaseOrder} />
            <Route path="/purchase-order/:id" exact component={PurchaseOrder} />
            <Route path="/search" exact component={Search} />
            <Route path="/search/:query" exact component={Search} />
            <Route path="/deleted-invoices" exact component={DeletedInvoices} />
            <Route path="/deleted-jobs" exact component={DeletedJobs} />
            <Route path="/credit-memos" exact component={CreditMemos} />
            <Route path="/credit-memo" exact component={CreditMemo} />
            <Route path="/credit-memo/:id" exact component={CreditMemo} />
            {/* <Route path="/payroll-reports" exact component={PayrollReports} /> */}
            <Route path="/make-payment" exact component={MakePayment} />
            <Route path="/cc-payment-authorization" exact component={CcPaymentAuth} />
            <Route path="/make-payment/:token/:type" exact component={MakePayment} />
            <Route path="/hrp-payroll-reports" exact component={HrpPayrollReports} />
            <Route path="/commission-compensations" exact component={CommissionCompensations} />
            <Route path="/ap-records" exact component={ApRecords} />
            <Route path="/ap-record/:type" exact component={ApItems} />
            <Route path="/ap-record/:type/:id" exact component={ApItems} />
            <Route path="/ap-accounts" exact component={ApAccounts} />
            <Route path="/markup-calculator" exact component={MarkupCalculator} />
            <Route path="/postage-calculator" exact component={PostageCalculator} />
            <Route path="/users" exact component={Users} />
            <Route path="/mailing-categories" exact component={MailingCategories} />
            <Route path="/mailing-subcategories/:id" exact component={MailingSubcategories} />
            <Route path="/mailing-pricing/:id" exact component={MailingMatrixes} />
            <Route path="/iv-jobs" exact component={InformedVisibilityJobs} />
            <Route path="/iv-job/:id" exact component={InformedVisibilityJob} />
            <Route path="/customer-application/:type" exact component={CustomerApplication} />

            {/* <Route path="/vdp-login" exact component={Login} />
            <Route path="/vdp-dashboard" exact component={VdpDashboard} />
            <Route path="/vdp-project/:id" exact component={VdpProject} />
            <Route path="/vdp-editor/:id/:drop" exact component={VdpEditor} />
            <Route path="/vdp-week-drops/:id/:start/:end" exact component={VdpWeekDrops} />
            <Route path="/vdp-tutorials" exact component={VdpTutorials} /> */}

            {/* <Route path="/uthealth" exact component={VdpUtHealthEditor} />
            <Route path="/utphysicians" exact component={VdpUtHealthEditor} /> */}

            <Route path="/customer-rfq" exact component={ChristyRfq} />
            <Route path="/christy-rfq" exact component={ChristyRfq} />
            <Route path="/customer-rfq/:id" exact component={ChristyRfq} />
            <Route path="/customer-rfqs" exact component={ChristyRfqs} />
            <Route path="/on-hold-customers" exact component={OnHoldCustomers} />

            <Route path="/requests-for-quotes" exact component={QuoteRequests} />
            <Route path="/estimates" exact component={Estimates} />
            <Route path="/request-for-quote" exact component={QuoteRequest} />
            <Route path="/request-for-quote/:id" exact component={QuoteRequest} />
            <Route path="/request-for-quote/add-part/:mainPartId" exact component={QuoteRequest} />
            <Route path="/estimate" exact component={Estimate} />
            <Route path="/estimate/:type" exact component={Estimate} />
            <Route path="/estimate/:type/:id" exact component={Estimate} />
            <Route path="/new-estimate/:type/:customerId" exact component={Estimate} />
            <Route path="/estimate/add-part/:type/:mainPartId/:customerId" exact component={Estimate} />
            <Route path="/rfq-estimate/:id" exact component={Estimate} />
            <Route path="/add-part/:type/:mainPartId/:customerId" exact component={Estimate} />
            <Route path="/help-items" exact component={HelpItems} />

            <Route path="/db/digital-machines" exact component={PqnDigitalMachines} />
            <Route path="/db/presses" exact component={PqnPresses} />
        </Router>
    </Provider>,
    document.getElementById('root')
);
