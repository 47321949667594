import { createSelector } from 'reselect';

export const getComponentState = state => state.chat;

export const getMessages = createSelector(
    getComponentState,
    (state) => state.messages
);

export const getResponse = createSelector(
    getComponentState,
    (state) => state.response
);

export const getElementId = createSelector(
    getComponentState,
    (state) => state.elementId
);

export const getSaving = createSelector(
    getComponentState,
    (state) => state.saving
);

/* show */
export const getShow = createSelector(
    getComponentState,
    (state) => state.show
);
export const getShowIcon = createSelector(
    getComponentState,
    (state) => state.show.icon
);
export const getShowRoom = createSelector(
    getComponentState,
    (state) => state.show.room
);
export const getShowMembers = createSelector(
    getComponentState,
    (state) => state.show.members
);

export const getFirstScrollDone = createSelector(
    getComponentState,
    (state) => state.firstScrollDone
);

export const getTotalMessages = createSelector(
    getComponentState,
    (state) => state.totalMessages
);

export const getTotalLoadedMessages = createSelector(
    getComponentState,
    (state) => state.totalLoadedMessages
);

export const getLoadingMoreMessages = createSelector(
    getComponentState,
    (state) => state.loadingMoreMessages
);

export const getIsLoading = createSelector(
    getComponentState,
    (state) => state.isLoading
);

export const getType = createSelector(
    getComponentState,
    (state) => state.type
);

/* chatList */
export const getChatList = createSelector(
    getComponentState,
    (state) => state.chatList
);
export const getTrackingsChats = createSelector(
    getComponentState,
    (state) => state.chatList.trackings
);
export const getContactsChats = createSelector(
    getComponentState,
    (state) => state.chatList.contacts
);
export const getUnreadChats = createSelector(
    getComponentState,
    (state) => state.chatList.unread
);

export const getIsLoadingChats = createSelector(
    getComponentState,
    (state) => state.isLoadingChats
);

export const getChatId = createSelector(
    getComponentState,
    (state) => state.chatId
);

export const getUsersIds = createSelector(
    getComponentState,
    (state) => state.usersIds
);

export const getChatTitle = createSelector(
    getComponentState,
    (state) => state.chatTitle
);

export const getIsLoadingChatMembers = createSelector(
    getComponentState,
    (state) => state.isLoadingChatMembers
);

export const getChatMembers = createSelector(
    getComponentState,
    (state) => state.chatMembers
);

export const getHasUnreadMessages = createSelector(
    getComponentState,
    (state) => state.hasUnreadMessages
);