import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from './PqtLayout.actions';
import * as selectors from './PqtLayout.selectors';
import * as loginActions from './../Login/Login.actions';
import * as loginSelectors from './../Login/Login.selectors';
import * as quickPostageEscrowActions from './../QuickPostageEscrow/QuickPostageEscrow.actions';
import PropTypes from 'prop-types';
import './PqtLayout.scss';
import { Redirect } from 'react-router-dom';
import Favicon from 'react-favicon';
import Header from './Header';
import Footer from './Footer';
import QuickPostageEscrow from './../QuickPostageEscrow';
import { Helmet } from 'react-helmet';
import * as dialog from '../common/dialog';
import TermOfUseModal from './TermOfUseModal';

class PqtLayout extends Component {
    componentDidMount() {
        this.connectWs(false);
        this.props.getVersion();
    }

    componentWillUnmount() {
        if (this.props.ws.connection !== null) {
            this.props.ws.connection.close();
        }
    }

    connectWs(isRetry) {
        if (this.props.ws.connection === null || isRetry) {
            let ws = new WebSocket(process.env.REACT_APP_WS_URL);
            ws.onopen = this.onOpenHandler.bind(this);
            ws.onclose = this.onCloseHandler.bind(this);
            ws.onmessage = this.onMessageHandler.bind(this);
            this.props.setWs('connection', ws);
        }
    }

    onMessageHandler = message => {
        console.log('WS data: ' + message.data);
        this.props.setWs('lastMessage', message.data);
    }

    onOpenHandler = () => {
        console.log('WS connected');
        this.props.setWs('connected', true);
    }

    onCloseHandler = () => {
        console.log('WS disconnected');
        this.props.setWs('connection', null);
        this.props.setWs('connected', false);
        this.connectWs(true);
    }

    render() {
        const pqtType = this.props.user !== null && this.props.user.pqt_type;
        if (!this.props.isAuthenticated || pqtType == null) {
            return <Redirect to="/login" />;
        }

        const logoutHandler = event => {
            event.preventDefault();
            this.props.logout();
        }

        let newVersion = this.props.version != null && this.props.version !== process.env.REACT_APP_VERSION;
        let newVersionContainer = null;
        if (newVersion) {
            newVersionContainer = (
                <div className="new-version-container">
                    <div className="label">A new version of Stagecoach is available, please refresh the page.</div>
                    <div className="button" onClick={() => window.location.reload()}>Refresh</div>
                </div>
            );
        }

        return (
            <div className="pqt-layout-container">
                <Favicon url="https://s3.amazonaws.com/ac-pqn/favicon.ico" />
                <Helmet>
                    <title>Stagecoach</title>
                    <meta name="description" content="Stagecoach" />
                </Helmet>
                {newVersionContainer}
                <Header
                    user={this.props.user}
                    logoutHandler={logoutHandler}
                    fetchCustomers={this.props.fetchCustomers}
                    setActiveTab={this.props.setActiveTab}
                    createTaskRequest={this.props.createTaskRequest}
                    title={this.props.title}
                    actionButtons={this.props.actionButtons}
                    creatingTask={this.props.creatingTask}
                    history={this.props.history}
                    showQuickPostageEscrowModal={this.props.showQuickPostageEscrowModal}
                    newVersion={newVersion}
                />
                <div className="divider"></div>
                <div className="content">
                    <section>
                        {this.props.children}
                    </section>
                </div>
                <Footer
                    showTermOfUseModal={this.props.showTermOfUseModal}
                />
                <QuickPostageEscrow />
                <TermOfUseModal
                    hideTermOfUseModal={this.props.hideTermOfUseModal}
                />
            </div>
        );
    }
}

PqtLayout.propTypes = {
    logout: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    ws: selectors.getWs(state),
    creatingTask: selectors.getCreatingTask(state),
    isAuthenticated: loginSelectors.getIsAuthenticated(state),
    user: loginSelectors.getUser(state),
    version: loginSelectors.getVersion(state),
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(loginActions.logout()),
    getVersion: () => dispatch(loginActions.getVersion()),
    setWs: (field, value) => dispatch(actions.setWs(field, value)),
    fetchCustomers: () => dispatch(quickPostageEscrowActions.fetchCustomers()),
    setActiveTab: (id) => dispatch(quickPostageEscrowActions.setActiveTab(id)),
    createTaskRequest: (id) => dispatch(actions.createTaskRequest(id)),
    showQuickPostageEscrowModal: () => dispatch(dialog.actions.showDialog('quick-postage-modal')),
    showTermOfUseModal: () => dispatch(dialog.actions.showDialog('term-of-use-modal')),
    hideTermOfUseModal: () => dispatch(dialog.actions.hideDialog('term-of-use-modal')),
});

export default connect(mapStateToProps, mapDispatchToProps)(PqtLayout);
