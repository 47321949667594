import React, { Fragment } from 'react';
//import PropTypes from 'prop-types';
import numeral from 'numeral';
import { Link } from 'react-router-dom';

const QuickGlance = React.memo((props) => {
    const getStatusBox = status => {
        if (parseInt(status) === 1) {
            return 'red-box';
        } else if (parseInt(status) === 2) {
            return 'yellow-box';
        } else if (parseInt(status) === 3) {
            return 'blue-box';
        } else if (parseInt(status) === 4) {
            return 'green-box';
        } else {
            return '';
        }
    };

    const renderCsrs = () => {
        if (props.fetchingQuickGlance) {
            return <div><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
        }

        let csrs = props.csrsData.map((element, index) => {
            let className = 'list-element';
            if (props.quickGlanceFilter.field === 'csr' && parseInt(props.quickGlanceFilter.value) === parseInt(element.id)) {
                className = 'list-element active';
            }

            let name = element.name;
            const nameParts = name.split(' ');
            if (nameParts.length > 0) {
                name = nameParts[0];
            }

            return (
                <div className={className} key={'quick-glance-csr-' + index}>
                    <div className="name" onClick={props.setQuickGlanceFilter.bind(this, 'csr', element.id)}>{name}</div>
                    <div className={'not-started ' + getStatusBox(element.status)} onClick={props.setQuickGlanceFilter.bind(this, 'csr', element.id)}>{element.count_not_started}</div>
                    <div className={'number ' + getStatusBox(element.status)} onClick={props.setQuickGlanceFilter.bind(this, 'csr', element.id)}>{element.count_started}</div>
                    <div className={'total ' + getStatusBox(element.status)} onClick={props.setQuickGlanceFilter.bind(this, 'csr', element.id)}>{element.count_not_started + element.count_started}</div>
                </div>
            );
        });

        return (
            <Fragment>
                {csrs}
                <div className={getSnapShotClass('out_on_proof') + ' out-on-proof'}>
                    <div className="name" onClick={props.setQuickGlanceFilter.bind(this, 'job', 'out_on_proof')}>Out On Proof</div>
                    <div className="total red-box" onClick={props.setQuickGlanceFilter.bind(this, 'job', 'out_on_proof')}>{props.snapshotData.out_on_proof}</div>
                </div>
                <div className={getSnapShotClass('paused') + ' paused'}>
                    <div className="name" onClick={props.setQuickGlanceFilter.bind(this, 'job', 'paused')}>Paused</div>
                    <div className="total red-box" onClick={props.setQuickGlanceFilter.bind(this, 'job', 'paused')}>{props.snapshotData.paused}</div>
                </div>
            </Fragment>
        );
    };

    const renderSalesReps = () => {
        if (props.fetchingQuickGlance) {
            return <div><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
        }

        return props.salesRepsData.map((element, index) => {
            let className = 'list-element';
            if (props.quickGlanceFilter.field === 'salesRep' && parseInt(props.quickGlanceFilter.value) === parseInt(element.id)) {
                className = 'list-element active';
            }

            let name = element.name;
            const nameParts = name.split(' ');
            if (nameParts.length > 0) {
                name = nameParts[0];
            }

            return (
                <div className={className} key={'quick-glance-sales-' + index}>
                    <div className="name" onClick={props.setQuickGlanceFilter.bind(this, 'salesRep', element.id)}>{name}</div>
                    <div className={'not-started ' + getStatusBox(element.status)} onClick={props.setQuickGlanceFilter.bind(this, 'salesRep', element.id)}>{element.count_not_started}</div>
                    <div className={'number ' + getStatusBox(element.status)} onClick={props.setQuickGlanceFilter.bind(this, 'salesRep', element.id)}>{element.count_started}</div>
                    <div className={'total ' + getStatusBox(element.status)} onClick={props.setQuickGlanceFilter.bind(this, 'salesRep', element.id)}>{element.count_not_started + element.count_started}</div>
                </div>
            )
        });
    };

    const clickHandler = (id, showJobs) => {
        if (showJobs === 1) {
            props.history.push('/department-view/' + id);
        } else {
            props.history.push('/department-view/' + id + '/' + showJobs);
        }
    };

    const renderDepartments = () => {
        if (props.fetchingQuickGlance) {
            return <div><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
        }

        return props.departmentsData.map((element, index) => (
            <div className="list-element" key={'quick-glance-csr-' + index}>
                <div className="name" onClick={clickHandler.bind(this, element.id, 1)}>{element.name}</div>
                <div className={'not-started ' + getStatusBox(element.status)} onClick={clickHandler.bind(this, element.id, 3)}>
                    {element.count_not_started}
                </div>
                <div className={'number ' + getStatusBox(element.status)} onClick={clickHandler.bind(this, element.id, 2)}>
                    {element.count_started}
                </div>
                <div className={'total ' + getStatusBox(element.status)} onClick={clickHandler.bind(this, element.id, 1)}>
                    {element.count_not_started + element.count_started}
                </div>
            </div>
        ));
    };

    const getSnapShotClass = field => {
        let className = 'list-element';
        if (props.quickGlanceFilter.field === 'job' && props.quickGlanceFilter.value === field) {
            className = 'list-element active';
        }
        return className;
    };

    const renderSnapShot = () => {
        if (props.fetchingQuickGlance) {
            return <div><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
        }

        return (
            <Fragment>
                <div className={getSnapShotClass('pass_due')}>
                    <div className="name" onClick={props.setQuickGlanceFilter.bind(this, 'job', 'pass_due')}>Past Due</div>
                    <div className="not-started red-box" onClick={props.setQuickGlanceFilter.bind(this, 'job', 'pass_due')}>{props.snapshotData.pass_due_not_started}</div>
                    <div className="number red-box" onClick={props.setQuickGlanceFilter.bind(this, 'job', 'pass_due')}>{props.snapshotData.pass_due_started}</div>
                    <div className="total red-box" onClick={props.setQuickGlanceFilter.bind(this, 'job', 'pass_due')}>{props.snapshotData.pass_due_not_started + props.snapshotData.pass_due_started}</div>
                </div>
                <div className={getSnapShotClass('today')}>
                    <div className="name" onClick={props.setQuickGlanceFilter.bind(this, 'job', 'today')}>Today</div>
                    <div className="not-started red-box" onClick={props.setQuickGlanceFilter.bind(this, 'job', 'today')}>{props.snapshotData.today_not_started}</div>
                    <div className="number red-box" onClick={props.setQuickGlanceFilter.bind(this, 'job', 'today')}>{props.snapshotData.today_started}</div>
                    <div className="total red-box" onClick={props.setQuickGlanceFilter.bind(this, 'job', 'today')}>{props.snapshotData.today_not_started + props.snapshotData.today_started}</div>
                </div>
                <div className={getSnapShotClass('tomorrow')}>
                    <div className="name" onClick={props.setQuickGlanceFilter.bind(this, 'job', 'tomorrow')}>Tomorrow</div>
                    <div className="not-started yellow-box" onClick={props.setQuickGlanceFilter.bind(this, 'job', 'tomorrow')}>{props.snapshotData.tomorrow_not_started}</div>
                    <div className="number yellow-box" onClick={props.setQuickGlanceFilter.bind(this, 'job', 'tomorrow')}>{props.snapshotData.tomorrow_started}</div>
                    <div className="total yellow-box" onClick={props.setQuickGlanceFilter.bind(this, 'job', 'tomorrow')}>{props.snapshotData.tomorrow_not_started + props.snapshotData.tomorrow_started}</div>
                </div>
                <div className={getSnapShotClass('this_week')}>
                    <div className="name" onClick={props.setQuickGlanceFilter.bind(this, 'job', 'this_week')}>This Week</div>
                    <div className="not-started yellow-box" onClick={props.setQuickGlanceFilter.bind(this, 'job', 'this_week')}>{props.snapshotData.this_week_not_started}</div>
                    <div className="number yellow-box" onClick={props.setQuickGlanceFilter.bind(this, 'job', 'this_week')}>{props.snapshotData.this_week_started}</div>
                    <div className="total yellow-box" onClick={props.setQuickGlanceFilter.bind(this, 'job', 'this_week')}>{props.snapshotData.this_week_not_started + props.snapshotData.this_week_started}</div>
                </div>
                <div className={getSnapShotClass('next_week')}>
                    <div className="name" onClick={props.setQuickGlanceFilter.bind(this, 'job', 'next_week')}>Next Week</div>
                    <div className="not-started blue-box" onClick={props.setQuickGlanceFilter.bind(this, 'job', 'next_week')}>{props.snapshotData.next_week_not_started}</div>
                    <div className="number blue-box" onClick={props.setQuickGlanceFilter.bind(this, 'job', 'next_week')}>{props.snapshotData.next_week_started}</div>
                    <div className="total blue-box" onClick={props.setQuickGlanceFilter.bind(this, 'job', 'next_week')}>{props.snapshotData.next_week_not_started + props.snapshotData.next_week_started}</div>
                </div>
                <div className="pp-invoices">
                    <div className="pp-title">Postage Invoices</div>
                    <div className="pp-row">
                        <div className="label">Preliminary</div>
                        <div className="value">{numeral(props.ppInvoices.preliminary).format('0,0')}</div>
                    </div>
                    <div className="pp-row">
                        <div className="label">Paid</div>
                        <div className="value">{numeral(props.ppInvoices.paid).format('0,0')}</div>
                    </div>
                </div>
            </Fragment>
        );
    };

    const renderCloseout = () => {
        if (props.fetchingQuickGlance) {
            return <div><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
        }

        let className = 'list-element';
        if (props.quickGlanceFilter.field === 'closeout' && parseInt(props.quickGlanceFilter.value) === 1) {
            className = 'list-element active';
        }

        return (
            <div className={className}>
                <div className="name" onClick={props.setQuickGlanceFilter.bind(this, 'closeout', 1)}>Jobs</div>
                <div className="number purple-box closeout-total" onClick={props.setQuickGlanceFilter.bind(this, 'closeout', 1)}>{numeral(props.closeoutData).format('0,0')}</div>
            </div>
        );
    };

    const renderPostageEscrow = () => {
        if (props.fetchingQuickGlance) {
            return <div><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
        }

        return (
            <div className='list-element'>
                <div className="name">Customer Balance</div>
                <div className="number purple-box closeout-total">{numeral(props.postageEscrowBalanceData).format('$0,0.00')}</div>
            </div>
        );
    };

    const renderInvoices = () => {
        if (props.fetchingQuickGlance) {
            return <div><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
        }

        return (
            <div>
                <div className="invoice-row">
                    <Link to="#">
                        <div className="name">Jobs not started yet</div>
                        <div className="number purple-box closeout-total">{numeral(props.invoicesData.notStarted).format('0,0')}</div>
                    </Link>
                </div>
                <div className="invoice-row-divider"></div>
                <div className="invoice-row">
                    <Link to="/invoices/1/1">
                        <div className="name">Preliminary Invoice Print &amp; Mail</div>
                        <div className="number purple-box closeout-total">{numeral(props.invoicesData.preliminaryPrint).format('0,0')}</div>
                    </Link>
                </div>
                <div className="invoice-row">
                    <Link to="/invoices/1/2">
                        <div className="name">Preliminary Invoice Apparel</div>
                        <div className="number purple-box closeout-total">{numeral(props.invoicesData.preliminaryApparel).format('0,0')}</div>
                    </Link>
                </div>
                <div className="invoice-row">
                    <Link to="/invoices/1/3">
                        <div className="name">Preliminary Invoice Promo</div>
                        <div className="number purple-box closeout-total">{numeral(props.invoicesData.preliminaryPromo).format('0,0')}</div>
                    </Link>
                </div>
                <div className="invoice-row-divider"></div>
                <div className="invoice-row">
                    <Link to="/invoices/2/1">
                        <div className="name">Closed ready to invoice - Regular</div>
                        <div className="number purple-box closeout-total">{numeral(props.invoicesData.closedRegular).format('0,0')}</div>
                    </Link>
                </div>
                <div className="invoice-row">
                    <Link to="/invoices/2/2">
                        <div className="name">Closed ready to invoice - Daily</div>
                        <div className="number purple-box closeout-total">{numeral(props.invoicesData.closedDaily).format('0,0')}</div>
                    </Link>
                </div>
                <div className="invoice-row">
                    <Link to="/invoices/2/3">
                        <div className="name">Closed ready to invoice - Weekly</div>
                        <div className="number purple-box closeout-total">{numeral(props.invoicesData.closedWeekly).format('0,0')}</div>
                    </Link>
                </div>
                <div className="invoice-row">
                    <Link to="/invoices/2/4">
                        <div className="name">Closed ready to invoice - Monthly</div>
                        <div className="number purple-box closeout-total">{numeral(props.invoicesData.closedMonthly).format('0,0')}</div>
                    </Link>
                </div>
                <div className="invoice-row">
                    <Link to="/invoices/2/5">
                        <div className="name">Closed ready to invoice - Partial</div>
                        <div className="number purple-box closeout-total">{numeral(props.invoicesData.closedPartial).format('0,0')}</div>
                    </Link>
                </div>
                <div className="invoice-row-divider"></div>
                <div className="invoice-row">
                    <Link to="/invoices/3">
                        <div className="name">Approved ready for accounting</div>
                        <div className="number purple-box closeout-total">{numeral(props.invoicesData.approved).format('0,0')}</div>
                    </Link>
                </div>
                <div className="invoice-row-divider"></div>
                <div className="invoice-row">
                    <Link to="/invoices/4">
                        <div className="name">Final &amp; Archived</div>
                        <div className="number purple-box closeout-total">{numeral(props.invoicesData.final).format('0,0')}</div>
                    </Link>
                </div>
            </div>
        );
    };

    let resetClassName = 'reset disabled';
    if (props.quickGlanceFilter.field !== '' && props.quickGlanceFilter.value !== '') {
        resetClassName = 'reset';
    }

    let invoicesSection = (
        <div className="quick-element my-invoices">
            <div className="title"><Link to="/invoices">Accounting Snapshot</Link></div>
            <div className="list">{renderInvoices()}</div>
        </div>
    );

    return (
        <div className="quick-glance-container">
            <div className="quick-glance-header">
                <div className="title">
                    Quick Glance
                    <button className={resetClassName} onClick={props.setQuickGlanceFilter.bind(this, '', '')}>
                        <i className="fas fa-filter"></i> Reset
                    </button>
                </div>
                <div className="legend">
                    <i className="fas fa-square red-box"></i> Past Due |&nbsp;
                    <i className="fas fa-square yellow-box" ></i> &lt; 7 |&nbsp;
                    <i className="fas fa-square blue-box"></i> &lt; 14 |&nbsp;
                    <i className="fas fa-square green-box"></i> &gt;= 14
                </div>
            </div>
            <div className="elements">
                <div className="quick-element csr-count">
                    <div className="title">
                        <div className="name">CSR Count</div>
                        <div className="blue-dot"><i className="fas fa-circle" /></div>
                        <div className="green-dot"><i className="fas fa-circle" /></div>
                        <div className="black-dot"><i className="fas fa-circle" /></div>
                    </div>
                    <div className="list">
                        {renderCsrs()}
                    </div>
                </div>
                <div className="quick-element sales-rep">
                    <div className="title">
                        <div className="name">Sales Rep</div>
                        <div className="blue-dot"><i className="fas fa-circle" /></div>
                        <div className="green-dot"><i className="fas fa-circle" /></div>
                        <div className="black-dot"><i className="fas fa-circle" /></div>
                    </div>
                    <div className="list">
                        {renderSalesReps()}
                    </div>
                </div>
                <div className="quick-element job-snap-shot">
                    <div className="title">
                        <div className="name">Job Snapshot</div>
                        <div className="blue-dot"><i className="fas fa-circle" /></div>
                        <div className="green-dot"><i className="fas fa-circle" /></div>
                        <div className="black-dot"><i className="fas fa-circle" /></div>
                    </div>
                    <div className="list">
                        {renderSnapShot()}
                    </div>
                </div>
                <div className="departmental-job-count">
                    <div className="title">
                        <div className="name">Departments Jobs</div>
                        <div className="yellow-dot"><i className="fas fa-circle" /></div>
                        <div className="green-dot"><i className="fas fa-circle" /></div>
                        <div className="black-dot"><i className="fas fa-circle" /></div>
                    </div>
                    <div className="lists">
                        <div className="list">
                            {renderDepartments()}
                        </div>
                    </div>
                </div>
                <div className="double-list">
                    <div className="quick-element my-closeout">
                        <div className="title">Closeout</div>
                        <div className="list">{renderCloseout()}</div>
                    </div>
                    <div className="quick-element escrow">
                        <div className="title">Postage Escrow</div>
                        <div className="list">{renderPostageEscrow()}</div>
                    </div>
                    {invoicesSection}
                </div>
            </div>
        </div>
    );
});

export default QuickGlance;
