export const FETCH_ON_HOLD_CUSTOMERS = 'ON_HOLD_CUSTOMERS/FETCH_ON_HOLD_CUSTOMERS';
export const FETCH_ON_HOLD_CUSTOMERS_SUCCESS = 'ON_HOLD_CUSTOMERS/FETCH_ON_HOLD_CUSTOMERS_SUCCESS';
export const FETCH_ON_HOLD_CUSTOMERS_ERROR = 'ON_HOLD_CUSTOMERS/FETCH_ON_HOLD_CUSTOMERS_ERROR';
export const FETCH_INFORMATION = 'ON_HOLD_CUSTOMERS/FETCH_INFORMATION';
export const FETCH_INFORMATION_SUCCESS = 'ON_HOLD_CUSTOMERS/FETCH_INFORMATION_SUCCESS';
export const FETCH_INFORMATION_ERROR = 'ON_HOLD_CUSTOMERS/FETCH_INFORMATION_ERROR';
export const FETCH_ON_HOLD_CUSTOMER = 'ON_HOLD_CUSTOMERS/FETCH_ON_HOLD_CUSTOMER';
export const FETCH_ON_HOLD_CUSTOMER_SUCCESS = 'ON_HOLD_CUSTOMERS/FETCH_ON_HOLD_CUSTOMER_SUCCESS';
export const FETCH_ON_HOLD_CUSTOMER_ERROR = 'ON_HOLD_CUSTOMERS/FETCH_ON_HOLD_CUSTOMER_ERROR';
export const ADD_HOLD = 'ON_HOLD_CUSTOMERS/ADD_HOLD';
export const ADD_HOLD_SUCCESS = 'ON_HOLD_CUSTOMERS/ADD_HOLD_SUCCESS';
export const ADD_HOLD_ERROR = 'ON_HOLD_CUSTOMERS/ADD_HOLD_ERROR';
export const REMOVE_HOLD = 'ON_HOLD_CUSTOMERS/REMOVE_HOLD';
export const REMOVE_HOLD_SUCCESS = 'ON_HOLD_CUSTOMERS/REMOVE_HOLD_SUCCESS';
export const REMOVE_HOLD_ERROR = 'ON_HOLD_CUSTOMERS/REMOVE_HOLD_ERROR';
export const DOWNLOAD_REPORT = 'ON_HOLD_CUSTOMERS/DOWNLOAD_REPORT';
export const DOWNLOAD_REPORT_SUCCESS = 'ON_HOLD_CUSTOMERS/DOWNLOAD_REPORT_SUCCESS';
export const DOWNLOAD_REPORT_ERROR = 'ON_HOLD_CUSTOMERS/DOWNLOAD_REPORT_ERROR';
export const RESET_STATE = 'ON_HOLD_CUSTOMERS/RESET_STATE';
export const SET_ID = 'ON_HOLD_CUSTOMERS/SET_ID';
export const SET_SORT = 'ON_HOLD_CUSTOMERS/SET_SORT';
export const SET_ACTIVE_PAGE = 'ON_HOLD_CUSTOMERS/SET_ACTIVE_PAGE';
export const SET_ID_FOR_DELETE = 'ON_HOLD_CUSTOMERS/SET_ID_FOR_DELETE';
export const CHANGE_FILTER = 'ON_HOLD_CUSTOMERS/CHANGE_FILTER';
export const SET_FIELD = 'ON_HOLD_CUSTOMERS/SET_FIELD';
export const RESET_FIELDS = 'ON_HOLD_CUSTOMERS/RESET_FIELDS';
export const SET_CUSTOMER_NAME = 'ON_HOLD_CUSTOMERS/SET_CUSTOMER_NAME';