import React, { Fragment } from 'react';
import { Dialog } from './../../common/dialog';
import { Layer, Rect, Stage } from 'react-konva';
import { empty } from '../../../utils';

const ImpositionModal = React.memo((props) => {
    if (parseInt(props.type) === 2) {
        return null;
    }

    if (props.result1 == null || !props.result1.results || props.result1.results.length === 0) {
        return null;
    }

    const multiplier = 8;
    const result = props.result1.results[0];

    const drawFlatPoints = (result) => {
        let originWidth = ((result.paper_information.pss.pss.width * multiplier - result.paper_information.pss.ps.width * multiplier) / 2) + 1;
        let originLeft = ((result.paper_information.pss.pss.length * multiplier - result.paper_information.pss.ps.length * multiplier) / 2) + 1;

        let bleed = (result.paper_information.fss_with_bleed.width - result.paper_information.fss.width) * 5;
        return result.paper_information.pss.points.map((point) => {
            let rects = [];
            rects.push(
                <Rect
                    x={point.origin[0] * multiplier + originLeft}
                    y={point.origin[1] * multiplier + originWidth}
                    width={point.rectangle[0] * multiplier}
                    height={point.rectangle[1] * multiplier}
                    fill={bleed > 0 ? '#f06292' : '#ffffff'}
                    stroke={bleed > 0 ? '#f06292' : '#000000'}
                />
            );

            if (bleed > 0) {
                rects.push(
                    <Rect
                        x={point.origin[0] * multiplier + originLeft + bleed}
                        y={point.origin[1] * multiplier + originWidth + bleed}
                        width={point.rectangle[0] * multiplier - bleed * 2}
                        height={point.rectangle[1] * multiplier - bleed * 2}
                        fill={'#ffffff'}
                        stroke={'#ffffff'}
                    />
                );
            } else {
                rects.push(
                    <Rect
                        x={point.origin[0] * multiplier + originLeft + 1}
                        y={point.origin[1] * multiplier + originWidth + 1}
                        width={point.rectangle[0] * multiplier - 2}
                        height={point.rectangle[1] * multiplier - 2}
                        fill={'#ffffff'}
                        stroke={'#ffffff'}
                    />
                );
            }

            return rects;
        });
    };

    const drawMultiPagePoints = (imposition) => {
        let originWidth = ((imposition.paper_information.pss.pss.width * multiplier - imposition.paper_information.pss.ps.width * multiplier) / 2) + 1;
        let originLeft = ((imposition.paper_information.pss.pss.length * multiplier - imposition.paper_information.pss.ps.length * multiplier) / 2) + 1;

        let simpleBleed = (imposition.paper_information.fss_with_bleed.width - imposition.paper_information.fss.width) / 2;
        let bleed = simpleBleed * 20;
        let offset = simpleBleed * multiplier;
        let hasBleed = true;
        if (bleed === 0) {
            bleed = 2;
            offset = 1;
            hasBleed = false;
        }
        return imposition.paper_information.pss.points.map((point) => {
            let rectangles = [];
            rectangles.push(
                <Rect
                    x={point.origin[0] * multiplier + originLeft}
                    y={point.origin[1] * multiplier + originWidth}
                    width={point.rectangle[0] * multiplier}
                    height={point.rectangle[1] * multiplier}
                    fill={hasBleed ? '#F48FB1' : '#ffffff'}
                    stroke={hasBleed ? '#F48FB1' : '#000000'}
                />
            );

            rectangles.push(
                <Rect
                    x={point.origin[0] * multiplier + originLeft + offset}
                    y={point.origin[1] * multiplier + originWidth + offset}
                    width={point.rectangle[0] * multiplier - bleed}
                    height={point.rectangle[1] * multiplier - bleed}
                    fill={'#ffffff'}
                    stroke={'#ffffff'}
                />
            );

            let x = point.origin[0] * multiplier + originLeft + offset - 1;
            let y = point.origin[1] * multiplier + originWidth + offset - 1;
            let width = point.rectangle[0] - simpleBleed * 2;
            let length = point.rectangle[1] - simpleBleed * 2;
            let fssWidth = props.estimate.finish_size_width;
            let fssLength = props.estimate.finish_size_height;
            let dividerWidth = null;
            let dividerLength = null;
            if (parseInt(props.estimate.binding_edge) === 1) {      // Width
                if (parseFloat(fssWidth) === parseFloat(width)) {
                    y += fssLength * multiplier;
                    dividerWidth = width * multiplier + 3;
                    dividerLength = 0.2;
                } else {
                    x += fssLength * multiplier;
                    dividerWidth = 0.2;
                    dividerLength = width * multiplier + 3;
                }
            } else {
                if (parseFloat(fssLength) === parseFloat(length)) {
                    x += fssWidth * multiplier;
                    dividerWidth = 0.2;
                    dividerLength = length * multiplier + 3;
                } else {
                    y += fssWidth * multiplier;
                    dividerWidth = length * multiplier + 3;
                    dividerLength = 0.2;
                }
            }

            rectangles.push(
                <Rect
                    x={x}
                    y={y}
                    width={dividerWidth}
                    height={dividerLength}
                    fill={'#000000'}
                    strokeEnabled={false}
                />
            );

            return rectangles;
        });
    };

    const renderMultiPageImposition = (imposition) => {
        const width = imposition.paper_information.pss.pss.width * multiplier;
        const length = imposition.paper_information.pss.pss.length * multiplier;

        return (
            <div className="imposition-container">
                <Stage width={length + 2} height={width + 2}>
                    <Layer>
                        <Rect
                            x={1}
                            y={1}
                            width={length}
                            height={width}
                            fill="#ededed"
                            stroke="#000000"
                        />
                        {drawMultiPagePoints(imposition)}
                    </Layer>
                </Stage>
            </div>
        );
    };

    let content = null;
    if (parseInt(props.estimateType) === 1) {     // Sheetfed
        if (!empty(result.paper_information)) {
            const width = result.paper_information.pss.pss.width * multiplier;
            const length = result.paper_information.pss.pss.length * multiplier;

            content = (
                <div className="imposition-container">
                    <h1>Imposition</h1>
                    <Stage width={length + 2} height={width + 2}>
                        <Layer>
                            <Rect
                                x={1}
                                y={1}
                                width={length}
                                height={width}
                                fill="#ededed"
                                stroke="#000000"
                            />
                            {drawFlatPoints(result)}
                        </Layer>
                    </Stage>
                </div>
            );
        }
    } else if (parseInt(props.estimateType) === 3 && parseInt(props.impositionType) === 1) {     // Plus-Cover (Cover)
        const coverResult = props.result1.cover_results[0];

        const width = coverResult.paper_information.pss.pss.width * multiplier;
        const length = coverResult.paper_information.pss.pss.length * multiplier;

        content = (
            <div className="imposition-container">
                <h1>Cover Imposition</h1>
                <Stage width={length + 2} height={width + 2}>
                    <Layer>
                        <Rect
                            x={1}
                            y={1}
                            width={length}
                            height={width}
                            fill="#ededed"
                            stroke="#000000"
                        />
                        {drawFlatPoints(coverResult)}
                    </Layer>
                </Stage>
            </div>
        );
    } else if (parseInt(props.estimateType) === 3 && parseInt(props.impositionType) === 2) {     // Plus-Cover (Interior)
        let impositions = [];

        for (let i = 0; i < result.impositions_information.length; i++) {
            const imposition = result.impositions_information[i];

            let impositionName = imposition.paper_information.imposition_name
                + ' (' + imposition.paper_information.imposition_out + '-Up)';
            impositions.push(
                <Fragment>
                    <h1>{impositionName}</h1>
                    {renderMultiPageImposition(imposition)}
                </Fragment>
            );
        }

        content = (
            <div className="imposition-container">
                {impositions}
            </div>
        );
    } else if (parseInt(props.estimateType) === 4) {     // Self-Cover
        let impositions = [];

        for (let i = 0; i < result.impositions_information.length; i++) {
            const imposition = result.impositions_information[i];

            let impositionName = imposition.paper_information.imposition_name
                + ' (' + imposition.paper_information.imposition_out + '-Up)';
            impositions.push(
                <Fragment>
                    <h1>{impositionName}</h1>
                    {renderMultiPageImposition(imposition)}
                </Fragment>
            );
        }

        content = (
            <div className="imposition-container">
                {impositions}
            </div>
        );
    } else if (parseInt(props.estimateType) === 5) {     // Digital
        const width = result.paper_information.pss.pss.width * multiplier;
        const length = result.paper_information.pss.pss.length * multiplier;

        content = (
            <div className="imposition-container">
                <h1>Imposition</h1>
                <Stage width={length + 2} height={width + 2}>
                    <Layer>
                        <Rect
                            x={1}
                            y={1}
                            width={length}
                            height={width}
                            fill="#ededed"
                            stroke="#000000"
                        />
                        {drawFlatPoints(result)}
                    </Layer>
                </Stage>
            </div>
        );
    } else if (parseInt(props.estimateType) === 7) {     // Pocket Folder
        const width = result.paper_information.pss.pss.width * multiplier;
        const length = result.paper_information.pss.pss.length * multiplier;

        content = (
            <div className="imposition-container">
                <h1>Imposition</h1>
                <Stage width={length + 2} height={width + 2}>
                    <Layer>
                        <Rect
                            x={1}
                            y={1}
                            width={length}
                            height={width}
                            fill="#ededed"
                            stroke="#000000"
                        />
                        {drawFlatPoints(result)}
                    </Layer>
                </Stage>
            </div>
        );
    } else if (parseInt(props.estimateType) === 8) {     // NCR
        const width = result.paper_information.pss.pss.width * multiplier;
        const length = result.paper_information.pss.pss.length * multiplier;

        content = (
            <div className="imposition-container">
                <h1>Imposition</h1>
                <Stage width={length + 2} height={width + 2}>
                    <Layer>
                        <Rect
                            x={1}
                            y={1}
                            width={length}
                            height={width}
                            fill="#ededed"
                            stroke="#000000"
                        />
                        {drawFlatPoints(result)}
                    </Layer>
                </Stage>
            </div>
        );
    } else if (parseInt(props.estimateType) === 9) {     // Notepads
        const width = result.paper_information.pss.pss.width * multiplier;
        const length = result.paper_information.pss.pss.length * multiplier;

        content = (
            <div className="imposition-container">
                <h1>Imposition</h1>
                <Stage width={length + 2} height={width + 2}>
                    <Layer>
                        <Rect
                            x={1}
                            y={1}
                            width={length}
                            height={width}
                            fill="#ededed"
                            stroke="#000000"
                        />
                        {drawFlatPoints(result)}
                    </Layer>
                </Stage>
            </div>
        );
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={props.hideImpositionModal}>Close</button>,
    ];
    return (
        <Dialog
            name="imposition-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="imposition-dialog"
        >
            {content}
        </Dialog >
    );
});

export default ImpositionModal;
