export const FETCH_SUBCATEGORIES = 'MAILING_SUBCATEGORIES/FETCH_SUBCATEGORIES';
export const FETCH_SUBCATEGORIES_SUCCESS = 'MAILING_SUBCATEGORIES/FETCH_SUBCATEGORIES_SUCCESS';
export const FETCH_SUBCATEGORIES_ERROR = 'MAILING_SUBCATEGORIES/FETCH_SUBCATEGORIES_ERROR';
export const FETCH_CATEGORY = 'MAILING_SUBCATEGORIES/FETCH_CATEGORY';
export const FETCH_CATEGORY_SUCCESS = 'MAILING_SUBCATEGORIES/FETCH_CATEGORY_SUCCESS';
export const FETCH_CATEGORY_ERROR = 'MAILING_SUBCATEGORIES/FETCH_CATEGORY_ERROR';
export const SAVE_SUBCATEGORY = 'MAILING_SUBCATEGORIES/SAVE_SUBCATEGORY';
export const SAVE_SUBCATEGORY_SUCCESS = 'MAILING_SUBCATEGORIES/SAVE_SUBCATEGORY_SUCCESS';
export const SAVE_SUBCATEGORY_ERROR = 'MAILING_SUBCATEGORIES/SAVE_SUBCATEGORY_ERROR';
export const DELETE_SUBCATEGORY = 'MAILING_SUBCATEGORIES/DELETE_SUBCATEGORY';
export const DELETE_SUBCATEGORY_SUCCESS = 'MAILING_SUBCATEGORIES/DELETE_SUBCATEGORY_SUCCESS';
export const DELETE_SUBCATEGORY_ERROR = 'MAILING_SUBCATEGORIES/DELETE_SUBCATEGORY_ERROR';
export const SET_SUBCATEGORY = 'MAILING_SUBCATEGORIES/SET_SUBCATEGORY';
export const SET_ID = 'MAILING_SUBCATEGORIES/SET_ID';
export const SET_CATEGORY_ID = 'MAILING_SUBCATEGORIES/SET_CATEGORY_ID';
export const SET_SORT = 'MAILING_SUBCATEGORIES/SET_SORT';
export const SET_ACTIVE_PAGE = 'MAILING_SUBCATEGORIES/SET_ACTIVE_PAGE';
export const SET_ID_FOR_DELETE = 'MAILING_SUBCATEGORIES/SET_ID_FOR_DELETE';
export const CHANGE_FILTER = 'MAILING_SUBCATEGORIES/CHANGE_FILTER';
export const SET_FIELD = 'MAILING_SUBCATEGORIES/SET_FIELD';
export const RESET_STATE = 'MAILING_SUBCATEGORIES/RESET_STATE';