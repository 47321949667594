import Immutable from 'immutable';
import * as types from './MailingCategories.types';

const initialState = {
    id: null,
    category: {
        is_active: 1,
        base_calculation_type: 1,
    },
    categories: [],
    fetchingCategories: false,
    pagination: {
        totalElements: 0,
        activePage: 1,
        totalPages: 0,
        elementsPerPage: 15,
    },
    sort: {
        column: 'name',
        type: 'asc',
    },
    history: null,
    delete: {
        id: null,
        isDeleting: false,
    },
    filter: {
        fields: [],
    },
    isSaving: false,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_CATEGORIES:
            return state.setIn(['fetchingCategories'], true).toJS();

        case types.FETCH_CATEGORIES_SUCCESS:
            return state.setIn(['fetchingCategories'], false)
                .setIn(['categories'], action.payload.elements)
                .setIn(['pagination', 'totalElements'], action.payload.totalElements)
                .setIn(['pagination', 'activePage'], action.payload.activePage)
                .setIn(['pagination', 'totalPages'], action.payload.totalPages)
                .setIn(['pagination', 'elementsPerPage'], action.payload.elementsPerPage)
                .toJS();

        case types.FETCH_CATEGORIES_ERROR:
            return state.setIn(['fetchingCategories'], false).toJS();

        case types.SET_SORT:
            return state.setIn(['sort'], action.payload).toJS();

        case types.SET_ACTIVE_PAGE:
            return state.setIn(['pagination', 'activePage'], action.payload).toJS();

        case types.SAVE_CATEGORY:
            return state.setIn(['isSaving'], true).toJS();

        case types.SAVE_CATEGORY_SUCCESS:
            return state.setIn(['isSaving'], false).toJS();

        case types.SAVE_CATEGORY_ERROR:
            return state.setIn(['isSaving'], false).toJS();

        case types.DELETE_CATEGORY:
            return state.setIn(['delete', 'isDeleting'], true).toJS();

        case types.DELETE_CATEGORY_SUCCESS:
            return state.setIn(['delete', 'isDeleting'], false)
                .setIn(['delete', 'id'], null)
                .toJS();

        case types.DELETE_CATEGORY_ERROR:
            return state.setIn(['delete', 'isDeleting'], false).toJS();

        case types.SET_ID_FOR_DELETE:
            return state.setIn(['delete', 'id'], action.payload).toJS();

        case types.SET_CATEGORY:
            return state.setIn(['category'], action.payload).toJS();

        case types.SET_FIELD:
            return state.setIn(['category', action.payload.field], action.payload.value).toJS();

        case types.CHANGE_FILTER:
            return state.setIn(['pagination', 'activePage'], 1)
                .updateIn(['filter', 'fields'], (fields) => {
                    const index = fields.findIndex(field => field.get('name') === action.payload.name);
                    if (action.payload.value === '') {
                        return fields.delete(index);
                    } else {
                        if (index === -1) {
                            return fields.push({ name: action.payload.name, value: action.payload.value });
                        } else {
                            return fields.set(index, { name: action.payload.name, value: action.payload.value });
                        }
                    }
                })
                .toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}