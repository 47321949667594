import React, { Fragment } from 'react';
import numeral from 'numeral';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import { dateToTimezone } from './../../../utils';
import PqtPagination from './../../PqtPagination';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';

const Jobs = React.memo(props => {
    const renderJobs = () => {
        if (props.fetching) {
            return (
                <tr>
                    <td colSpan="14"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.jobs.length === 0) {
            return (
                <tr>
                    <td colSpan="14">No Results</td>
                </tr>
            );
        }

        return props.jobs.map((job, index) => {
            let box = '';
            if (job.status === 4) {
                box = 'purple-box';
            } else if (job.days_left <= 0) {
                box = 'red-box';
            } else if (job.days_left < 7) {
                box = 'yellow-box';
            } else if (job.days_left < 14) {
                box = 'blue-box';
            } else {
                box = 'green-box';
            }

            let statusBox = 'status-box';
            if (job.status === 1) {
                statusBox = 'status-box blue-box';
            } else if (job.status === 2) {
                statusBox = 'status-box green-box';
            } else if (job.status === 3) {
                statusBox = 'status-box red-box';
            } else if (job.status === 4) {
                statusBox = 'status-box purple-box';
            } else if (job.status === 5) {
                statusBox = 'status-box yellow-box';
            }

            let departments = [];
            for (let i = 0; i < job.departments.length; i++) {
                let separator = ' | ';
                if (i === job.departments.length - 1) {
                    separator = null;
                }

                let spanClass = box;
                if (parseInt(job.departments[i].status) === 4) {
                    spanClass = 'finished';
                }

                let departmentTime = null;
                if (job.departments[i].lifecycle != null) {
                    departmentTime = ' (' + job.departments[i].lifecycle + ')';
                }

                departments.push(<Fragment key={'job-department-' + i}><span className={spanClass}>{job.departments[i].name}{departmentTime}</span>{separator}</Fragment>);
            }

            let csrName = job.csr_name;
            const csrNameParts = csrName.split(' ');
            if (csrNameParts.length > 0) {
                csrName = csrNameParts[0];
            }

            let salesName = job.sales_rep_name;
            const salesNameParts = salesName.split(' ');
            if (salesNameParts.length > 0) {
                salesName = salesNameParts[0];
            }

            let outOnProofContainer = null;
            if (job.out_on_proof) {
                outOnProofContainer = (<span className="out-on-proof">(Proof out - {dateToTimezone(job.out_on_proof_datetime, 'MM/DD/YYYY hh:mm a')})</span>);
            }

            let onPauseText = null;
            if (parseInt(job.status) === 5) {
                let dueDateRow = null;
                if (job.paused_due_date) {
                    dueDateRow = <div className="paused-text">Due: {dateToTimezone(job.paused_due_date, 'MM/DD/YYYY')}</div>;
                }

                onPauseText = (
                    <Fragment>
                        <div className="paused-text">Paused</div>
                        {dueDateRow}
                    </Fragment>
                );
            }

            let deletedDate = '-';
            if (job.deleted_datetime != null) {
                deletedDate = dateToTimezone(job.deleted_datetime, 'MM/DD/YYYY');
            }

            return (
                <tr key={'my-jobs-job-' + index}>
                    <td className={box}>{job.id}</td>
                    <td>{csrName}</td>
                    <td>{salesName}</td>
                    <td className={box + ' text-center'}>
                        {dateToTimezone(job.due_date, 'MM/DD/YY')}
                        {onPauseText}
                    </td>
                    <td>
                        <div>{job.job_description}</div>
                        <div><b>Customer:</b> {job.customer_name} {outOnProofContainer}</div>
                    </td>
                    <td className="text-right">{numeral(job.quantity).format('0,0')}</td>
                    <td className={statusBox}><i className="fas fa-dot-circle" /></td>
                    <td>{departments}</td>
                    <td>{job.delete_comments}</td>
                    <td>{deletedDate}</td>
                    <td>
                        <div className="actions">
                            <Tooltip title="Edit" position="bottom" arrow size="small" className="action-tooltip">
                                <Link to={'/tracking/' + job.id}><i className="fas fa-edit" /></Link>
                            </Tooltip>
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const setSortHandler = (field, value) => {
        props.setSort(field, value);
    };

    const renderHeader = (name, column, className) => {
        let sortFunc = setSortHandler.bind(this, column, 'asc');
        let sortIcon = <i className="fas fa-sort" />;
        if (props.sort.column === column) {
            if (props.sort.type === 'asc') {
                sortFunc = setSortHandler.bind(this, column, 'desc');
                sortIcon = <i className="fas fa-sort-up" />;
            } else {
                sortFunc = setSortHandler.bind(this, column, 'asc');
                sortIcon = <i className="fas fa-sort-down" />;
            }
        }

        return <th className={className} onClick={sortFunc}>{name} {sortIcon}</th>;
    };

    const handleChangeFilter = (name, fetch, event) => {
        props.changeFilter(name, event.target.value, fetch);
    };

    const handleKey = (event) => {
        if (event.key === 'Enter') {
            props.fetchDeletedJobs()
        }
    };

    const renderSearchField = (name) => {
        let value = null;
        for (let i = 0; i < props.filerFields.length; i++) {
            if (props.filerFields[i].name === name) {
                value = props.filerFields[i].value;
            }
        }

        return (
            <th className="search-th">
                <input
                    type="text"
                    name={name}
                    className="search-input"
                    value={value}
                    onKeyPress={handleKey}
                    onChange={handleChangeFilter.bind(this, name, false)}
                />
            </th>
        );
    };

    // const handleChangeSelectFilter = (name, event) => {
    //     props.changeFilter(name, event.target.value, true);
    // };

    // const renderSearchSelect = (name, options) => {
    //     let value = '';
    //     for (let i = 0; i < props.filerFields.length; i++) {
    //         if (props.filerFields[i].name === name) {
    //             value = props.filerFields[i].value;
    //         }
    //     }

    //     return (
    //         <th className="search-th">
    //             <select
    //                 name={name}
    //                 className="search-input"
    //                 value={value}
    //                 onChange={handleChangeSelectFilter.bind(this, name)}
    //             >
    //                 {options.map((option) => {
    //                     return <option value={option.value}>{option.label}</option>;
    //                 })}
    //             </select>
    //         </th>
    //     );
    // };

    const setDateFieldHandler = (name, event) => {
        let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');
        props.changeFilter(name, value, true);
    };

    const renderSearchDateField = (name) => {
        let value = null;
        for (let i = 0; i < props.filerFields.length; i++) {
            if (props.filerFields[i].name === name) {
                value = props.filerFields[i].value;
            }
        }

        return (
            <th className="search-th">
                <DayPickerInput
                    formatDate={formatDate}
                    parseDate={parseDate}
                    placeholder=""
                    className="date-picker-field"
                    onDayChange={setDateFieldHandler.bind(this, name)}
                    value={value == null || value === '0000-00-00' ? '' : moment(value).format('MM/DD/YYYY')}
                />
            </th>
        )
    };

    return (
        <div className="jobs-container">
            <div className="table-container">
                <table className="table table-sm table-hover table-striped">
                    <thead>
                        <tr>
                            {renderHeader('SC #', 'id')}
                            {renderHeader('CSR', 'csr_name')}
                            {renderHeader('Sales', 'sales_rep_name')}
                            {renderHeader('Due', 'due_date', 'text-center')}
                            {renderHeader('Description | Customer', 'job_description')}
                            {renderHeader('Qty', 'quantity', 'text-right')}
                            {renderHeader('Status', 'status', 'text-center')}
                            <th className="text-center">Required Departments</th>
                            <th>Deleted Comments</th>
                            {renderHeader('Daleted Date', 'deleted_datetime', 'th-status')}
                            <th style={{ width: 40 }}></th>
                        </tr>
                        <tr>
                            {renderSearchField('id')}
                            {renderSearchField('csr_name')}
                            {renderSearchField('sales_rep_name')}
                            {renderSearchDateField('due_date')}
                            {renderSearchField('job_description')}
                            {renderSearchField('quantity')}
                            <th></th>
                            <th></th>
                            {renderSearchField('delete_comments')}
                            {renderSearchDateField('deleted_datetime')}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderJobs()}
                    </tbody>
                </table>
                <PqtPagination
                    pagination={props.pagination}
                    setActivePage={props.setActivePage}
                />
            </div>
        </div>
    );
});

export default Jobs;
