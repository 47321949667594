import Immutable from 'immutable';
import * as types from './PqtPostalQualification.types';

const initialState = {
    element: {
        agent_name: 1,
        permit_holder: 1,
        permit_number: 1,
        entry_post_office: 1,
        full_job_service: 1,
        postal_payment: 1,
        mail_class: 1,
        format: 1,
        sortation: 1,
        sort_choice: 1,
        presort_by: 1,
        postcard_type: 1,
        ride_along: 1,
        crrt: 0,
        news: 0,
        nonprofit: 0,
        in_country: 0,
        ecr: 0,
        dallas: 0,
        dal_or_dml: 0,
        repositionable_notes: 0,
        customized_market_mail: 0,
        postal_payment_meter: 1,
    },
    type: null,
    elementId: null,
    saving: false,
    showSave: true,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.SET_FIELD:
            return state.setIn(['element', action.payload.name], action.payload.value).toJS();

        case types.SAVE:
            return state.setIn(['saving'], true).toJS();

        case types.SAVE_SUCCESS:
            return state.setIn(['saving'], false).toJS();

        case types.SAVE_ERROR:
            return state.setIn(['saving'], false).toJS();

        case types.SET_TYPE:
            return state.setIn(['type'], action.payload).toJS();

        case types.SET_ELEMENT_ID:
            return state.setIn(['elementId'], action.payload).toJS();

        case types.SET_FIELDS:
            return state.updateIn(['element'], element => {
                for (let [field, value] of Object.entries(action.payload)) {
                    element = element.set(field, value);
                }
                return element;
            }).toJS();

        case types.FETCH_ELEMENT:
            return state.setIn(['fetching'], true).toJS();

        case types.FETCH_ELEMENT_SUCCESS:
            return state.setIn(['fetching'], false)
                .setIn(['element'], action.payload)
                .toJS();

        case types.FETCH_ELEMENT_ERROR:
            return state.setIn(['fetching'], false).toJS();

        case types.SET_SHOW_SAVE:
            return state.setIn(['showSave'], action.payload).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}