export const FETCH_INVOICE = 'PQT_INVOICE/FETCH_INVOICE';
export const FETCH_INVOICE_SUCCESS = 'PQT_INVOICE/FETCH_INVOICE_SUCCESS';
export const FETCH_INVOICE_ERROR = 'PQT_INVOICE/FETCH_INVOICE_ERROR';
export const FETCH_INFORMATION = 'PQT_INVOICE/FETCH_INFORMATION';
export const FETCH_INFORMATION_SUCCESS = 'PQT_INVOICE/FETCH_INFORMATION_SUCCESS';
export const FETCH_INFORMATION_ERROR = 'PQT_INVOICE/FETCH_INFORMATION_ERROR';
export const FETCH_CUSTOMER_DATA = 'PQT_INVOICE/FETCH_CUSTOMER_DATA';
export const FETCH_CUSTOMER_DATA_SUCCESS = 'PQT_INVOICE/FETCH_CUSTOMER_DATA_SUCCESS';
export const FETCH_CUSTOMER_DATA_ERROR = 'PQT_INVOICE/FETCH_CUSTOMER_DATA_ERROR';
export const SAVE_INVOICE = 'PQT_INVOICE/SAVE_INVOICE';
export const SAVE_INVOICE_SUCCESS = 'PQT_INVOICE/SAVE_INVOICE_SUCCESS';
export const SAVE_INVOICE_ERROR = 'PQT_INVOICE/SAVE_INVOICE_ERROR';
export const APPROVE_INVOICE = 'PQT_INVOICE/APPROVE_INVOICE';
export const APPROVE_INVOICE_SUCCESS = 'PQT_INVOICE/APPROVE_INVOICE_SUCCESS';
export const APPROVE_INVOICE_ERROR = 'PQT_INVOICE/APPROVE_INVOICE_ERROR';
export const QB_PUSH = 'PQT_INVOICE/QB_PUSH';
export const QB_PUSH_SUCCESS = 'PQT_INVOICE/QB_PUSH_SUCCESS';
export const QB_PUSH_ERROR = 'PQT_INVOICE/QB_PUSH_ERROR';
export const RETURN_INVOICE = 'PQT_INVOICE/RETURN_INVOICE';
export const RETURN_INVOICE_SUCCESS = 'PQT_INVOICE/RETURN_INVOICE_SUCCESS';
export const RETURN_INVOICE_ERROR = 'PQT_INVOICE/RETURN_INVOICE_ERROR';
export const UNCOMBINE_INVOICE = 'PQT_INVOICE/UNCOMBINE_INVOICE';
export const UNCOMBINE_INVOICE_SUCCESS = 'PQT_INVOICE/UNCOMBINE_INVOICE_SUCCESS';
export const UNCOMBINE_INVOICE_ERROR = 'PQT_INVOICE/UNCOMBINE_INVOICE_ERROR';
export const SPLIT_INVOICE = 'PQT_INVOICE/SPLIT_INVOICE';
export const SPLIT_INVOICE_SUCCESS = 'PQT_INVOICE/SPLIT_INVOICE_SUCCESS';
export const SPLIT_INVOICE_ERROR = 'PQT_INVOICE/SPLIT_INVOICE_ERROR';
export const SYNC_QB_TOTAL = 'PQT_INVOICE/SYNC_QB_TOTAL';
export const SYNC_QB_TOTAL_SUCCESS = 'PQT_INVOICE/SYNC_QB_TOTAL_SUCCESS';
export const SYNC_QB_TOTAL_ERROR = 'PQT_INVOICE/SYNC_QB_TOTAL_ERROR';
export const FETCH_POSTAGE_ESCROW = 'PQT_INVOICE/FETCH_POSTAGE_ESCROW';
export const FETCH_POSTAGE_ESCROW_SUCCESS = 'PQT_INVOICE/FETCH_POSTAGE_ESCROW_SUCCESS';
export const FETCH_POSTAGE_ESCROW_ERROR = 'PQT_INVOICE/FETCH_POSTAGE_ESCROW_ERROR';
export const SET_FIELD = 'PQT_INVOICE/SET_FIELD';
export const SET_SPLIT_PRECENT1 = 'PQT_INVOICE/SET_SPLIT_PRECENT1';
export const SET_SPLIT_PRECENT2 = 'PQT_INVOICE/SET_SPLIT_PRECENT2';
export const SET_ITEM_FIELD = 'PQT_INVOICE/SET_ITEM_FIELD';
export const ADD_LINE = 'PQT_INVOICE/ADD_LINE';
export const REMOVE_LINE = 'PQT_INVOICE/REMOVE_LINE';
export const RESET_STATE = 'PQT_INVOICE/RESET_STATE';
export const SET_CUSTOMER_EMAIL = 'PQT_INVOICE/SET_CUSTOMER_EMAIL';
export const CHANGE_SORT_POSITION = 'PQT_INVOICE/CHANGE_SORT_POSITION';