export const FETCH_CUSTOMERS = 'QUICK_POSTAGE_ESCROW/FETCH_CUSTOMERS';
export const FETCH_CUSTOMERS_SUCCESS = 'QUICK_POSTAGE_ESCROW/FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_ERROR = 'QUICK_POSTAGE_ESCROW/FETCH_CUSTOMERS_ERROR';
export const FETCH_REPORT = 'QUICK_POSTAGE_ESCROW/FETCH_REPORT';
export const FETCH_REPORT_SUCCESS = 'QUICK_POSTAGE_ESCROW/FETCH_REPORT_SUCCESS';
export const FETCH_REPORT_ERROR = 'QUICK_POSTAGE_ESCROW/FETCH_REPORT_ERROR';
export const EXPORT_SALES_REPORT = 'QUICK_POSTAGE_ESCROW/EXPORT_SALES_REPORT';
export const EXPORT_SALES_REPORT_SUCCESS = 'QUICK_POSTAGE_ESCROW/EXPORT_SALES_REPORT_SUCCESS';
export const EXPORT_SALES_REPORT_ERROR = 'QUICK_POSTAGE_ESCROW/EXPORT_SALES_REPORT_ERROR';
export const EXPORT_AR_AGING_REPORT = 'QUICK_POSTAGE_ESCROW/EXPORT_AR_AGING_REPORT';
export const EXPORT_AR_AGING_REPORT_SUCCESS = 'QUICK_POSTAGE_ESCROW/EXPORT_AR_AGING_REPORT_SUCCESS';
export const EXPORT_AR_AGING_REPORT_ERROR = 'QUICK_POSTAGE_ESCROW/EXPORT_AR_AGING_REPORT_ERROR';
export const EXPORT_COMMISSION_REPORT = 'QUICK_POSTAGE_ESCROW/EXPORT_COMMISSION_REPORT';
export const EXPORT_COMMISSION_REPORT_SUCCESS = 'QUICK_POSTAGE_ESCROW/EXPORT_COMMISSION_REPORT_SUCCESS';
export const EXPORT_COMMISSION_REPORT_ERROR = 'QUICK_POSTAGE_ESCROW/EXPORT_COMMISSION_REPORT_ERROR';
export const FETCH_SALES_CUSTOMERS = 'QUICK_POSTAGE_ESCROW/FETCH_SALES_CUSTOMERS';
export const FETCH_SALES_CUSTOMERS_SUCCESS = 'QUICK_POSTAGE_ESCROW/FETCH_SALES_CUSTOMERS_SUCCESS';
export const FETCH_SALES_CUSTOMERS_ERROR = 'QUICK_POSTAGE_ESCROW/FETCH_SALES_CUSTOMERS_ERROR';
export const EXPORT_INVOICES_REPORT = 'QUICK_POSTAGE_ESCROW/EXPORT_INVOICES_REPORT';
export const EXPORT_INVOICES_REPORT_SUCCESS = 'QUICK_POSTAGE_ESCROW/EXPORT_INVOICES_REPORT_SUCCESS';
export const EXPORT_INVOICES_REPORT_ERROR = 'QUICK_POSTAGE_ESCROW/EXPORT_INVOICES_REPORT_ERROR';
export const EXPORT_COMBINED_REPORT = 'QUICK_POSTAGE_ESCROW/EXPORT_COMBINED_REPORT';
export const EXPORT_COMBINED_REPORT_SUCCESS = 'QUICK_POSTAGE_ESCROW/EXPORT_COMBINED_REPORT_SUCCESS';
export const EXPORT_COMBINED_REPORT_ERROR = 'QUICK_POSTAGE_ESCROW/EXPORT_COMBINED_REPORT_ERROR';
export const EXPORT_SHIPPING_REPORT = 'QUICK_POSTAGE_ESCROW/EXPORT_SHIPPING_REPORT';
export const EXPORT_SHIPPING_REPORT_SUCCESS = 'QUICK_POSTAGE_ESCROW/EXPORT_SHIPPING_REPORT_SUCCESS';
export const EXPORT_SHIPPING_REPORT_ERROR = 'QUICK_POSTAGE_ESCROW/EXPORT_SHIPPING_REPORT_ERROR';
export const EXPORT_MAILING_REPORT = 'QUICK_POSTAGE_ESCROW/EXPORT_MAILING_REPORT';
export const EXPORT_MAILING_REPORT_SUCCESS = 'QUICK_POSTAGE_ESCROW/EXPORT_MAILING_REPORT_SUCCESS';
export const EXPORT_MAILING_REPORT_ERROR = 'QUICK_POSTAGE_ESCROW/EXPORT_MAILING_REPORT_ERROR';
export const EXPORT_YEARLY_SALES_REPORT = 'QUICK_POSTAGE_ESCROW/EXPORT_YEARLY_SALES_REPORT';
export const EXPORT_YEARLY_SALES_REPORT_SUCCESS = 'QUICK_POSTAGE_ESCROW/EXPORT_YEARLY_SALES_REPORT_SUCCESS';
export const EXPORT_YEARLY_SALES_REPORT_ERROR = 'QUICK_POSTAGE_ESCROW/EXPORT_YEARLY_SALES_REPORT_ERROR';
export const EXPORT_JOB_STATUS_REPORT = 'QUICK_POSTAGE_ESCROW/EXPORT_JOB_STATUS_REPORT';
export const EXPORT_JOB_STATUS_REPORT_SUCCESS = 'QUICK_POSTAGE_ESCROW/EXPORT_JOB_STATUS_REPORT_SUCCESS';
export const EXPORT_JOB_STATUS_REPORT_ERROR = 'QUICK_POSTAGE_ESCROW/EXPORT_JOB_STATUS_REPORT_ERROR';
export const EXPORT_WEEKLY_REPORT = 'QUICK_POSTAGE_ESCROW/EXPORT_WEEKLY_REPORT';
export const EXPORT_WEEKLY_REPORT_SUCCESS = 'QUICK_POSTAGE_ESCROW/EXPORT_WEEKLY_REPORT_SUCCESS';
export const EXPORT_WEEKLY_REPORT_ERROR = 'QUICK_POSTAGE_ESCROW/EXPORT_WEEKLY_REPORT_ERROR';
export const UPLOAD_FILE = 'QUICK_POSTAGE_ESCROW/UPLOAD_FILE';
export const UPLOAD_FILE_SUCCESS = 'QUICK_POSTAGE_ESCROW/UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_ERROR = 'QUICK_POSTAGE_ESCROW/UPLOAD_FILE_ERROR';
export const UPLOAD_SHIPPING_FILE = 'QUICK_POSTAGE_ESCROW/UPLOAD_SHIPPING_FILE';
export const UPLOAD_SHIPPING_FILE_SUCCESS = 'QUICK_POSTAGE_ESCROW/UPLOAD_SHIPPING_FILE_SUCCESS';
export const UPLOAD_SHIPPING_FILE_ERROR = 'QUICK_POSTAGE_ESCROW/UPLOAD_SHIPPING_FILE_ERROR';
export const SEND_SALES_REPORT = 'QUICK_POSTAGE_ESCROW/SEND_SALES_REPORT';
export const SEND_SALES_REPORT_SUCCESS = 'QUICK_POSTAGE_ESCROW/SEND_SALES_REPORT_SUCCESS';
export const SEND_SALES_REPORT_ERROR = 'QUICK_POSTAGE_ESCROW/SEND_SALES_REPORT_ERROR';
export const SEND_SALES_NORM_REPORT = 'QUICK_POSTAGE_ESCROW/SEND_SALES_NORM_REPORT';
export const SEND_SALES_NORM_REPORT_SUCCESS = 'QUICK_POSTAGE_ESCROW/SEND_SALES_NORM_REPORT_SUCCESS';
export const SEND_SALES_NORM_REPORT_ERROR = 'QUICK_POSTAGE_ESCROW/SEND_SALES_NORM_REPORT_ERROR';
export const SET_COMPANY_ID = 'QUICK_POSTAGE_ESCROW/SET_COMPANY_ID';
export const SET_COMPANY = 'QUICK_POSTAGE_ESCROW/SET_COMPANY';
export const SET_ACTIVE_TAB = 'QUICK_POSTAGE_ESCROW/SET_ACTIVE_TAB';
export const SET_SALES_ID = 'QUICK_POSTAGE_ESCROW/SET_SALES_ID';
export const SET_FROM_DATE = 'QUICK_POSTAGE_ESCROW/SET_FROM_DATE';
export const SET_TO_DATE = 'QUICK_POSTAGE_ESCROW/SET_TO_DATE';
export const SET_PAID_DATE = 'QUICK_POSTAGE_ESCROW/SET_PAID_DATE';
export const RESET_REPORT = 'QUICK_POSTAGE_ESCROW/RESET_REPORT';
export const RESET_STATE = 'QUICK_POSTAGE_ESCROW/RESET_STATE';
export const SET_FILE = 'QUICK_POSTAGE_ESCROW/SET_FILE';
export const SET_CUSTOMER_ID = 'QUICK_POSTAGE_ESCROW/SET_CUSTOMER_ID';
export const CHECK_DEPARTMENT = 'QUICK_POSTAGE_ESCROW/CHECK_DEPARTMENT';
export const CHECK_ALL = 'QUICK_POSTAGE_ESCROW/CHECK_ALL';
export const SET_INVOICE_REPORT_TYPE = 'QUICK_POSTAGE_ESCROW/SET_INVOICE_REPORT_TYPE';
export const SET_SALES_REPORT_INFO_CHANGED = 'QUICK_POSTAGE_ESCROW/SET_SALES_REPORT_INFO_CHANGED';
export const SET_SHIPPING_METHOD = 'QUICK_POSTAGE_ESCROW/SET_SHIPPING_METHOD';
export const SET_JOBS_TYPE = 'QUICK_POSTAGE_ESCROW/SET_JOBS_TYPE';
export const SET_START_YEAR = 'QUICK_POSTAGE_ESCROW/SET_START_YEAR';
export const SET_END_YEAR = 'QUICK_POSTAGE_ESCROW/SET_END_YEAR';
