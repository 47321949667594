import { createSelector } from 'reselect';

export const getComponentState = state => state.customerApplication;

export const getFields = createSelector(
    getComponentState,
    (state) => state.fields
);
export const getSavingApplication = createSelector(
    getComponentState,
    (state) => state.savingApplication
);
export const getFile = createSelector(
    getComponentState,
    (state) => state.file
);
export const getRecaptchaToken = createSelector(
    getComponentState,
    (state) => state.recaptchaToken
);