import { createSelector } from 'reselect';

export const getComponentState = state => state.deletedInvoices;

export const getFetching = createSelector(
    getComponentState,
    (state) => state.fetching
);
export const getInvoices = createSelector(
    getComponentState,
    (state) => state.invoices
);
export const getPagination = createSelector(
    getComponentState,
    (state) => state.pagination
);
export const getSort = createSelector(
    getComponentState,
    (state) => state.sort
);
export const getFilterFields = createSelector(
    getComponentState,
    (state) => state.filter.fields
);