export const FETCH_CREDIT_MEMOS = 'CREDIT_MEMOS/FETCH_CREDIT_MEMOS';
export const FETCH_CREDIT_MEMOS_SUCCESS = 'CREDIT_MEMOS/FETCH_CREDIT_MEMOS_SUCCESS';
export const FETCH_CREDIT_MEMOS_ERROR = 'CREDIT_MEMOS/FETCH_CREDIT_MEMOS_ERROR';
export const DELETE_CREDIT_MEMO = 'CREDIT_MEMOS/DELETE_CREDIT_MEMO';
export const DELETE_CREDIT_MEMO_SUCCESS = 'CREDIT_MEMOS/DELETE_CREDIT_MEMO_SUCCESS';
export const DELETE_CREDIT_MEMO_ERROR = 'CREDIT_MEMOS/DELETE_CREDIT_MEMO_ERROR';
export const EXPORT_EXCEL = 'CREDIT_MEMOS/EXPORT_EXCEL';
export const EXPORT_EXCEL_SUCCESS = 'CREDIT_MEMOS/EXPORT_EXCEL_SUCCESS';
export const EXPORT_EXCEL_ERROR = 'CREDIT_MEMOS/EXPORT_EXCEL_ERROR';
export const RESET_STATE = 'CREDIT_MEMOS/RESET_STATE';
export const SET_ID = 'CREDIT_MEMOS/SET_ID';
export const SET_SORT = 'CREDIT_MEMOS/SET_SORT';
export const SET_ACTIVE_PAGE = 'CREDIT_MEMOS/SET_ACTIVE_PAGE';
export const SET_ID_FOR_DELETE = 'CREDIT_MEMOS/SET_ID_FOR_DELETE';
export const CHANGE_FILTER = 'CREDIT_MEMOS/CHANGE_FILTER';