import React from 'react';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import PqtPagination from './../../PqtPagination';
import moment from 'moment-timezone';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { dateToTimezone } from './../../../utils';

const Records = React.memo((props) => {
    const confirmHandler = (id) => {
        props.showDeleteModal();
        props.setIdForDelete(id);
    };

    const handleShowRecord = (id) => {
        props.fetchRecord(id);
        props.showFormModal();
    };

    const handleViewItems = (id, type) => {
        if (parseInt(type) === 1) {
            props.history.push('/ap-record/american-express/' + id);
        } else if (parseInt(type) === 2) {
            props.history.push('/ap-record/capital-one-promovana/' + id);
        } else if (parseInt(type) === 3) {
            props.history.push('/ap-record/capital-one-paper/' + id);
        }
    };

    const renderRecords = () => {
        if (props.fetchingRecords) {
            return (
                <tr>
                    <td colSpan="8"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.records.length === 0) {
            return (
                <tr>
                    <td colSpan="8">No Results</td>
                </tr>
            );
        }

        return props.records.map((record, index) => {
            let postedDate = '-';
            if (record.posted_date != null) {
                postedDate = dateToTimezone(record.posted_date, 'MM/DD/YYYY');
            }

            let type = '';
            if (parseInt(record.type) === 1) {
                type = 'American Express';
            } else if (parseInt(record.type) === 2) {
                type = 'Capital One Promovana';
            } else if (parseInt(record.type) === 3) {
                type = 'Capital One Paper';
            }

            let status = '';
            if (parseInt(record.status) === 1) {
                status = 'Pending';
            } else if (parseInt(record.status) === 2) {
                status = 'Pushed to QB';
            }

            return (
                <tr key={'record-' + index}>
                    <td>{record.id}</td>
                    <td>{postedDate}</td>
                    <td>{record.name}</td>
                    <td>{type}</td>
                    <td>{status}</td>
                    <td>
                        <div className="actions">
                            <Tooltip title="Edit Item" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-edit" onClick={handleShowRecord.bind(this, record.id)} />
                            </Tooltip>
                            <Tooltip title="View Items" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-list" onClick={handleViewItems.bind(this, record.id, record.type)} />
                            </Tooltip>
                            <Tooltip title="Delete Record" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-trash" onClick={confirmHandler.bind(this, record.id)} />
                            </Tooltip>
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const setSortHandler = (field, value) => {
        props.setSort(field, value);
    };

    const renderHeader = (name, column, className) => {
        let sortFunc = setSortHandler.bind(this, column, 'asc');
        let sortIcon = <i className="fas fa-sort" />;
        if (props.sort.column === column) {
            if (props.sort.type === 'asc') {
                sortFunc = setSortHandler.bind(this, column, 'desc');
                sortIcon = <i className="fas fa-sort-up" />;
            } else {
                sortFunc = setSortHandler.bind(this, column, 'asc');
                sortIcon = <i className="fas fa-sort-down" />;
            }
        }

        return <th className={className} onClick={sortFunc}>{name} {sortIcon}</th>;
    };

    const handleChangeFilter = (name, fetch, event) => {
        props.changeFilter(name, event.target.value, fetch);
    };

    const handleKey = (event) => {
        if (event.key === 'Enter') {
            props.fetchRecords();
        }
    };

    const renderSearchField = (name) => {
        let value = null;
        for (let i = 0; i < props.filerFields.length; i++) {
            if (props.filerFields[i].name === name) {
                value = props.filerFields[i].value;
            }
        }

        return (
            <th className="search-th">
                <input
                    type="text"
                    name={name}
                    className="search-input"
                    value={value}
                    onKeyPress={handleKey}
                    onChange={handleChangeFilter.bind(this, name, false)}
                />
            </th>
        );
    };

    const setDateFieldHandler = (name, event) => {
        let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');
        props.changeFilter(name, value, true);
    };

    const renderSearchDateField = (name) => {
        let value = null;
        for (let i = 0; i < props.filerFields.length; i++) {
            if (props.filerFields[i].name === name) {
                value = props.filerFields[i].value;
            }
        }

        return (
            <th className="search-th">
                <DayPickerInput
                    formatDate={formatDate}
                    parseDate={parseDate}
                    placeholder=""
                    className="date-picker-field"
                    onDayChange={setDateFieldHandler.bind(this, name)}
                    value={value == null || value === '0000-00-00' ? '' : moment(value).format('MM/DD/YYYY')}
                />
            </th>
        )
    };

    const handleNewAmericanExpress = () => {
        props.history.push('/ap-record/upload-american-express');
    };

    const handleNewCapitalOnePromovana = () => {
        props.history.push('/ap-record/upload-capital-one-promovana');
    };

    const handleNewCapitalOnePaper = () => {
        props.history.push('/ap-record/upload-capital-one-paper');
    };

    return (
        <div className="records-container">
            <div className="actions-container">
                <button className="upload-button" onClick={handleNewAmericanExpress}><i className="fas fa-upload" /> American Express</button>
                <button className="upload-button" onClick={handleNewCapitalOnePromovana}><i className="fas fa-upload" /> Capital One Promovana</button>
                <button className="upload-button" onClick={handleNewCapitalOnePaper}><i className="fas fa-upload" /> Capital One Paper</button>
            </div>
            <div className="table-container">
                <table className="table table-sm table-hover table-striped">
                    <thead>
                        <tr>
                            {renderHeader('No.', 'id', 'th-id')}
                            {renderHeader('Posted Date', 'posted_date', 'th-date')}
                            {renderHeader('Name', 'name', 'th-name')}
                            {renderHeader('Type', 'type', 'th-type')}
                            {renderHeader('Status', 'status', 'th-status')}
                            <th style={{ width: 110 }}></th>
                        </tr>
                        <tr>
                            {renderSearchField('id')}
                            {renderSearchDateField('posted_date')}
                            {renderSearchField('name')}
                            {renderSearchField('type')}
                            {renderSearchField('status')}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderRecords()}
                    </tbody>
                </table>
                <PqtPagination
                    pagination={props.pagination}
                    setActivePage={props.setActivePage}
                />
            </div>
        </div>
    );
});

export default Records;
