import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CloseOut from './CloseOut';
import Jobs from './Jobs';
import Tabs from './Tabs';
import * as actions from './PqtDepartment.actions';
import * as selectors from './PqtDepartment.selectors';
import * as loginSelectors from './../Login/Login.selectors';
// import * as pqtLayoutSelectors from './../PqtLayout/PqtLayout.selectors';
// import * as pqtLayoutActions from './../PqtLayout/PqtLayout.actions';
import * as chatActions from './../Chat/Chat.actions';
import './PqtDepartment.scss';
import PqtLayout from './../PqtLayout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';

const PqtDepartment = (props) => {
    /* State to props */
    const fetchingInformation = useSelector(selectors.getFetchingInformation);
    const fetchingJobs = useSelector(selectors.getFetchingJobs);
    const fetchingDepartment = useSelector(selectors.getFetchingDepartment);
    const jobsData = useSelector(selectors.getJobsData, stringifyEqualityCheck);
    const operators = useSelector(selectors.getOperatorsData, stringifyEqualityCheck);
    const jobsPagination = useSelector(selectors.getJobsPagination, stringifyEqualityCheck);
    const id = useSelector(selectors.getId);
    const closeOutFields = useSelector(selectors.getCloseOutFields, stringifyEqualityCheck);
    const barcode = useSelector(selectors.getBarcode, stringifyEqualityCheck);
    const sort = useSelector(selectors.getSort, stringifyEqualityCheck);
    const department = useSelector(selectors.getDepartment, stringifyEqualityCheck);
    const showJobs = useSelector(selectors.getShowJobs);
    const maildatFiles = useSelector(selectors.getMaildatFiles, stringifyEqualityCheck);
    const uploadingMaildatFiles = useSelector(selectors.getUploadingMaildatFiles);
    const closeJobId = useSelector(selectors.getCloseJobId);
    const jobClosed = useSelector(selectors.getJobClosed);
    // const ws = useSelector(pqtLayoutSelectors.getWs, stringifyEqualityCheck);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    // const setWs = useCallback((field, value) => dispatch(pqtLayoutActions.setWs(field, value)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);
    const deleteTracking = useCallback((id) => dispatch(actions.deleteTracking(id)), [dispatch]);
    const setId = useCallback((id, showJobs) => dispatch(actions.setId(id, showJobs)), [dispatch]);
    const closeOutJob = useCallback(() => dispatch(actions.closeOutJob()), [dispatch]);
    const savePartial = useCallback(() => dispatch(actions.savePartial()), [dispatch]);
    const saveShipping = useCallback(() => dispatch(actions.saveShipping()), [dispatch]);
    const saveMailing = useCallback(() => dispatch(actions.saveMailing()), [dispatch]);
    const setCloseOutField = useCallback((name, value) => dispatch(actions.setCloseOutField(name, value)), [dispatch]);
    const selectJob = useCallback((id) => dispatch(actions.selectJob(id)), [dispatch]);
    const setSort = useCallback((column, type) => dispatch(actions.setSort(column, type)), [dispatch]);
    const setActivePage = useCallback((name, value) => dispatch(actions.setActivePage(name, value)), [dispatch]);
    const addTask = useCallback((id) => dispatch(actions.addTask(id)), [dispatch]);
    const removeTask = useCallback((id) => dispatch(actions.removeTask(id)), [dispatch]);
    const setTaskField = useCallback((id, field, value) => dispatch(actions.setTaskField(id, field, value)), [dispatch]);
    const loadChat = useCallback((type, options) => dispatch(chatActions.loadChat(type, options)), [dispatch]);
    const setChatType = useCallback((type) => dispatch(chatActions.setType(type)), [dispatch]);
    const setChatShowField = useCallback((field, value) => dispatch(chatActions.setShowField(field, value)), [dispatch]);
    const seChattElementId = useCallback((id) => dispatch(chatActions.setElementId(id)), [dispatch]);
    const setChatTitle = useCallback((title) => dispatch(chatActions.setChatTitle(title)), [dispatch]);
    const loadChats = useCallback((isWsUpdate) => dispatch(chatActions.loadChats(isWsUpdate)), [dispatch]);
    const fetchData = useCallback((options, isWsUpdate) => dispatch(actions.fetchData(options, isWsUpdate)), [dispatch]);
    const setShowJobs = useCallback((value) => dispatch(actions.setShowJobs(value)), [dispatch]);
    const setPartialField = useCallback((index, field, value) => dispatch(actions.setPartialField(index, field, value)), [dispatch]);
    const addPartial = useCallback(() => dispatch(actions.addPartial()), [dispatch]);
    const removePartial = useCallback(() => dispatch(actions.removePartial()), [dispatch]);
    const setShippingField = useCallback((index, field, value) => dispatch(actions.setShippingField(index, field, value)), [dispatch]);
    const addShipping = useCallback(() => dispatch(actions.addShipping()), [dispatch]);
    const removeShipping = useCallback(() => dispatch(actions.removeShipping()), [dispatch]);
    const addCrewPerson = useCallback(() => dispatch(actions.addCrewPerson()), [dispatch]);
    const deleteCrewPerson = useCallback((index) => dispatch(actions.deleteCrewPerson(index)), [dispatch]);
    const setCrewPersonField = useCallback((index, field, value) => dispatch(actions.setCrewPersonField(index, field, value)), [dispatch]);
    const addJobDetailLine = useCallback(() => dispatch(actions.addJobDetailLine()), [dispatch]);
    const deleteJobDetailLine = useCallback((index) => dispatch(actions.deleteJobDetailLine(index)), [dispatch]);
    const setJobDetailLineField = useCallback((index, field, value) => dispatch(actions.setJobDetailLineField(index, field, value)), [dispatch]);
    const addMaildatFiles = useCallback((files) => dispatch(actions.addMaildatFiles(files)), [dispatch]);
    const setCloseJobId = useCallback((value) => dispatch(actions.setCloseJobId(value)), [dispatch]);

    /* componentDidMount */
    const keyDownHandler = useCallback((event) => {
        const setBarcode = (value) => dispatch(actions.setBarcode(value));
        const setBarcodeCapturing = (value) => dispatch(actions.setBarcodeCapturing(value));
        const setCloseOutField = (name, value) => dispatch(actions.setCloseOutField(name, value));
        const selectJob = (id) => dispatch(actions.selectJob(id));

        if (event.key === '&') {     // Start barcode
            setBarcode('');
            setBarcodeCapturing(true);
        } else if (event.key === 'Enter') {     // Enter (end barcode)
            setBarcodeCapturing(false);

            const barcodeParts = barcode.value.split('-');
            if (barcodeParts[0] === '001') {     // Closeout
                selectJob(barcodeParts[1]);
            } else if (barcodeParts[0] === '002') {     // Set Equipment
                setCloseOutField('equipment', barcodeParts[1]);
            }
        } else if (barcode.isCapturing) {
            setBarcode(barcode.value + event.key);
        }
    }, [dispatch, barcode]);
    useEffect(() => {
        document.addEventListener('keydown', keyDownHandler, false);
        return () => {
            document.removeEventListener('keydown', keyDownHandler, false);
        };
    }, [keyDownHandler]);
    useEffect(() => {
        let showJobsParam = 1;
        if(props.match.params.showJobs) {
            showJobsParam = props.match.params.showJobs;
        }
        dispatch(actions.mountComponent(props.history, props.match.params.id, showJobsParam, props.match.params.jobId));
    }, [dispatch, props.history, props.match.params.id, props.match.params.showJobs, props.match.params.jobId, setCloseJobId]);

    const pqtType = user !== null && parseInt(user.pqt_type);
    let content = null
    let title = null
    if (fetchingInformation || fetchingJobs || fetchingDepartment) {
        content = <div><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
    } else if (department != null) {
        title = department.name;
        content = (
            <div className="pqt-department-container">
                <Tabs
                    id={id}
                    history={props.history}
                    user={user}
                    showJobs={showJobs}
                    closeJobId={closeJobId}
                    jobClosed={jobClosed}
                    resetState={resetState}
                    setId={setId}
                    fetchData={fetchData}
                    setShowJobs={setShowJobs}
                />
                <Jobs
                    jobsData={jobsData}
                    jobsPagination={jobsPagination}
                    fetchingJobs={fetchingJobs}
                    history={props.history}
                    department={department}
                    sort={sort}
                    user={user}
                    closeJobId={closeJobId}
                    jobClosed={jobClosed}
                    deleteTracking={deleteTracking}
                    selectJob={selectJob}
                    setSort={setSort}
                    setActivePage={setActivePage}
                    loadChat={loadChat}
                    setChatType={setChatType}
                    setChatShowField={setChatShowField}
                    seChattElementId={seChattElementId}
                    setChatTitle={setChatTitle}
                    loadChats={loadChats}
                    fetchData={fetchData}
                />
                {pqtType !== 7 && <CloseOut
                    jobsData={jobsData}
                    operators={operators}
                    closeOutFields={closeOutFields}
                    department={department}
                    maildatFiles={maildatFiles}
                    uploadingMaildatFiles={uploadingMaildatFiles}
                    closeJobId={closeJobId}
                    jobClosed={jobClosed}
                    closeOutJob={closeOutJob}
                    savePartial={savePartial}
                    saveShipping={saveShipping}
                    saveMailing={saveMailing}
                    setCloseOutField={setCloseOutField}
                    addTask={addTask}
                    removeTask={removeTask}
                    setTaskField={setTaskField}
                    setPartialField={setPartialField}
                    addPartial={addPartial}
                    removePartial={removePartial}
                    setShippingField={setShippingField}
                    addShipping={addShipping}
                    removeShipping={removeShipping}
                    addCrewPerson={addCrewPerson}
                    deleteCrewPerson={deleteCrewPerson}
                    setCrewPersonField={setCrewPersonField}
                    addJobDetailLine={addJobDetailLine}
                    deleteJobDetailLine={deleteJobDetailLine}
                    setJobDetailLineField={setJobDetailLineField}
                    addMaildatFiles={addMaildatFiles}
                />}
            </div>
        );
    }

    // if (ws !== null && ws.lastMessage !== null) {
    //     const jsonMessage = JSON.parse(ws.lastMessage);
    //     if (ws.uuid !== jsonMessage.uuid) {
    //         const wsTypes = ['track_created', 'track_updated', 'step_updated', 'step_deleted', 'step_closed', 'track_started',
    //             'track_closed', 'track_deleted', 'pqt_data_processing_created', 'pqt_data_processing_updated',
    //             'pqt_postal_qualification_created', 'pqt_postal_qualification_updated'];
    //         if (wsTypes.includes(jsonMessage.type)) {
    //             fetchData({
    //                 fetchJobs: 1,
    //                 jobId: props.match.params.jobId,
    //             }, true);
    //             setWs('lastMessage', null);
    //         } else if (jsonMessage.type === 'new_chat_message') {
    //             fetchData({
    //                 fetchJobs: 1,
    //                 jobId: props.match.params.jobId,
    //             }, true);
    //         }
    //     }
    // }

    return (
        <PqtLayout title={title} history={props.history}>
            <Helmet><title>Department - Stagecoach</title></Helmet>
            {content}
        </PqtLayout>
    );
};

export default PqtDepartment;
