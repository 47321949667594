import React from 'react';
import { Dialog } from './../../common/dialog';

const DeleteModal = React.memo((props) => {
    const hideHandler = () => {
        props.setIdForDelete(null);
        props.hideDeleteModal();
    };

    const deleteHandler = () => {
        props.deleteElement();
    };

    let label = 'Delete';
    if (props.isDeleting) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Cancel</button>,
        <button key="delete-button" className="btn btn-light btn-sm action-button" onClick={deleteHandler} disabled={props.isDeleting}>{label}</button>
    ];
    return (
        <Dialog
            name="delete-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="delete-dashboard-dialog"
        >
            <div className="delete-container">
                <h1>Delete item</h1>
                <p>Confirm that you want to remove the item with the ID <span className="element-id">#{props.idForDelete}</span>.</p>
            </div>
        </Dialog >
    );
});

export default DeleteModal;
