import React, { Fragment } from 'react';
import { Dialog } from './../../common/dialog';

const FormModal = React.memo((props) => {
    const hideHandler = () => {
        props.hideFormModal();
        props.resetFields();
    };

    const saveAccountHandler = () => {
        props.saveAccount();
    };

    let label = 'Save';
    if (props.savingAccount) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    const handleSetField = (field, event) => {
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        }

        props.setField(field, value);
    };

    let content = <div className="loading-container"><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
    if (!props.fetchingAccount) {
        content = (
            <Fragment>
                <div className="title">Press Information</div>
                <div className="fields">
                    <div className="field">
                        <div className="label">Account</div>
                        <input type="text" value={props.fields.account} onChange={handleSetField.bind(this, 'account')} />
                    </div>
                    <div className="field">
                        <div className="label">Name</div>
                        <input type="text" value={props.fields.name} onChange={handleSetField.bind(this, 'name')} />
                    </div>
                    <div className="field">
                        <div className="label">Name for AP</div>
                        <input type="text" value={props.fields.name_for_ap} onChange={handleSetField.bind(this, 'name_for_ap')} />
                    </div>
                    <div className="field">
                        <div className="label">Fully Qualified Name</div>
                        <input type="text" value={props.fields.fully_qualified_name} onChange={handleSetField.bind(this, 'fully_qualified_name')} />
                    </div>
                    <div className="field">
                        <div className="label">Type</div>
                        <input type="text" value={props.fields.type} onChange={handleSetField.bind(this, 'type')} />
                    </div>
                    <div className="field">
                        <div className="label">Subtype</div>
                        <input type="text" value={props.fields.subtype} onChange={handleSetField.bind(this, 'subtype')} />
                    </div>
                    <div className="field">
                        <div className="label">Classification</div>
                        <input type="text" value={props.fields.classification} onChange={handleSetField.bind(this, 'classification')} />
                    </div>
                    <div className="field">
                        <div className="label">Active</div>
                        <select value={props.fields.active} onChange={handleSetField.bind(this, 'active')}>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </div>
                    <div className="field">
                        <div className="label">Enabled for AP</div>
                        <select value={props.fields.ap_enabled} onChange={handleSetField.bind(this, 'ap_enabled')}>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </div>
                </div>
            </Fragment>
        );
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Cancel</button>,
        <button key="delete-button" className="btn btn-light btn-sm action-button" onClick={saveAccountHandler} disabled={props.savingAccount}>{label}</button>
    ];
    return (
        <Dialog
            name="ap-account-form-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="ap-account-form-dialog"
        >
            <div className="ap-account-form-container">
                {content}
            </div>
        </Dialog >
    );
});

export default FormModal;
