import React from 'react';
//import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';

const ApproveModal = React.memo((props) => {
    const hideHandler = () => {
        props.hideApproveModal();
    };

    let label = 'Approve';
    if (props.isApproving) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Cancel</button>,
        <button key="delete-button" className="btn btn-light btn-sm action-button" onClick={props.approveInvoice} disabled={props.isApproving}>{label}</button>
    ];
    return (
        <Dialog
            name="approve-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="approve-dialog"
        >
            <div className="approve-container">
                <h1>Approve Payment?</h1>
                <p className="warning">Warning: After you approve the payment you will not be able to make any other modifications to the invoice on Stagecoach.</p>
            </div>
        </Dialog >
    );
});

ApproveModal.propTypes = {

};

export default ApproveModal;
