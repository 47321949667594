import React from 'react';
//import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';

const SplitInvoiceModal = React.memo((props) => {
    const hideHandler = () => {
        props.hideSplitInvoiceModal();
    };

    let label = 'Split';
    if (props.isSplittingInvoice) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    const handleSplitInvoice = () => {
        props.splitInvoice(props.history);
    };

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Cancel</button>,
        <button key="delete-button" className="btn btn-light btn-sm action-button" onClick={handleSplitInvoice} disabled={props.isSplittingInvoice}>{label}</button>
    ];
    return (
        <Dialog
            name="split-invoice-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="split-invoice-dialog"
        >
            <div className="split-invoice-container">
                <h1>Split this invoice?</h1>
                <p>Select the ratio or percentage to split this invoice.</p>
                <div className="inputs">
                    <input type="number" value={props.splitPercent1} onChange={(event) => props.setSplitPercent1(event.target.value)} /> %
                    <div className="divider">-</div>
                    <input type="number" value={props.splitPercent2} onChange={(event) => props.setSplitPercent2(event.target.value)} /> %
                </div>
            </div>
        </Dialog >
    );
});

export default SplitInvoiceModal;
