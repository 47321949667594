import { combineReducers } from "redux";
import * as dialog from "./components/common/dialog";
import ApAccountsReducer from "./components/ApAccounts/ApAccounts.reducer";
import ApItemsReducer from "./components/ApItems/ApItems.reducer";
import ApRecordsReducer from "./components/ApRecords/ApRecords.reducer";
import CcPaymentAuthReducer from "./components/CcPaymentAuth/CcPaymentAuth.reducer";
import ChatReducer from "./components/Chat/Chat.reducer";
import ChristyRfqReducer from "./components/ChristyRfq/ChristyRfq.reducer";
import ChristyRfqsReducer from "./components/ChristyRfqs/ChristyRfqs.reducer";
import CreditMemoReducer from "./components/CreditMemo/CreditMemo.reducer";
import CreditMemosReducer from "./components/CreditMemos/CreditMemos.reducer";
import CommissionCompensationsReducer from "./components/CommissionCompensations/CommissionCompensations.reducer";
import CustomerApplicationReducer from "./components/CustomerApplication/CustomerApplication.reducer";
import DeletedInvoicesReducer from "./components/DeletedInvoices/DeletedInvoices.reducer";
import DeletedJobsReducer from "./components/DeletedJobs/DeletedJobs.reducer";
import EstimatesReducer from "./components/Estimates/Estimates.reducer";
import EstimateReducer from "./components/Estimate/Estimate.reducer";
import HelpItemsReducer from "./components/HelpItems/HelpItems.reducer";
import HrpPayrollReportsReducer from "./components/HrpPayrollReports/HrpPayrollReports.reducer";
import InformedVisibilityJobsReducer from "./components/InformedVisibilityJobs/InformedVisibilityJobs.reducer";
import InformedVisibilityJobReducer from "./components/InformedVisibilityJob/InformedVisibilityJob.reducer";
import LoginReducer from "./components/Login/Login.reducer";
import MakePaymentReducer from "./components/MakePayment/MakePayment.reducer";
import MarkupCalculatorReducer from "./components/MarkupCalculator/MarkupCalculator.reducer";
import MailingCategoriesReducer from "./components/MailingCategories/MailingCategories.reducer";
import MailingMatrixesReducer from "./components/MailingMatrixes/MailingMatrixes.reducer";
import MailingSubcategoriesReducer from "./components/MailingSubcategories/MailingSubcategories.reducer";
import OnHoldCustomersReducer from "./components/OnHoldCustomers/OnHoldCustomers.reducer";
// import PayrollReportsReducer from './components/PayrollReports/PayrollReports.reducer';
import PostageCalculatorReducer from "./components/PostageCalculator/PostageCalculator.reducer";
import PqnDigitalMachinesReducer from "./components/PqnDigitalMachines/PqnDigitalMachines.reducer";
import PqnPressesReducer from "./components/PqnPresses/PqnPresses.reducer";
import PqtDashboardReducer from "./components/PqtDashboard/PqtDashboard.reducer";
import PqtDataProcessingReducer from "./components/PqtDataProcessing/PqtDataProcessing.reducer";
import PqtDepartmentReducer from "./components/PqtDepartment/PqtDepartment.reducer";
import PqtDepartmentViewReducer from "./components/PqtDepartmentView/PqtDepartmentView.reducer";
import PqtInvoiceReducer from "./components/PqtInvoice/PqtInvoice.reducer";
import PqtInvoicesReducer from "./components/PqtInvoices/PqtInvoices.reducer";
import PqtLayoutReducer from "./components/PqtLayout/PqtLayout.reducer";
import PqtPostageReportReducer from "./components/PqtPostageReport/PqtPostageReport.reducer";
import PqtPostalQualificationReducer from "./components/PqtPostalQualification/PqtPostalQualification.reducer";
import PqtPostageInvoiceReducer from "./components/PqtPostageInvoice/PqtPostageInvoice.reducer";
import PqtPostageInvoicesReducer from "./components/PqtPostageInvoices/PqtPostageInvoices.reducer";
import PqtTrackingReducer from "./components/PqtTracking/PqtTracking.reducer";
import PurchaseOrdersReducer from "./components/PurchaseOrders/PurchaseOrders.reducer";
import PurchaseOrderReducer from "./components/PurchaseOrder/PurchaseOrder.reducer";
import QuickPostageEscrowReducer from "./components/QuickPostageEscrow/QuickPostageEscrow.reducer";
import QuoteRequestsReducer from "./components/QuoteRequests/QuoteRequests.reducer";
import QuoteRequestReducer from "./components/QuoteRequest/QuoteRequest.reducer";
import RestorePasswordReducer from "./components/RestorePassword/reducer";
import SearchReducer from "./components/Search/Search.reducer";
import UsersReducer from "./components/Users/Users.reducer";
// import VdpDashboardReducer from './components/VdpDashboard/VdpDashboard.reducer';
// import VdpEditorReducer from './components/VdpEditor/VdpEditor.reducer';
// import VdpProjectReducer from './components/VdpProject/VdpProject.reducer';
// import VdpTutorialsReducer from './components/VdpTutorials/VdpTutorials.reducer';
// import VdpWeekDropsReducer from './components/VdpWeekDrops/VdpWeekDrops.reducer';

const reducer = combineReducers({
    apAccounts: ApAccountsReducer,
    apItems: ApItemsReducer,
    apRecords: ApRecordsReducer,
    ccPaymentAuth: CcPaymentAuthReducer,
    chat: ChatReducer,
    christyRfq: ChristyRfqReducer,
    christyRfqs: ChristyRfqsReducer,
    dialog: dialog.reducer,
    login: LoginReducer,
    creditMemo: CreditMemoReducer,
    creditMemos: CreditMemosReducer,
    commissionCompensations: CommissionCompensationsReducer,
    customerApplication: CustomerApplicationReducer,
    deletedInvoices: DeletedInvoicesReducer,
    deletedJobs: DeletedJobsReducer,
    estimates: EstimatesReducer,
    estimate: EstimateReducer,
    helpItems: HelpItemsReducer,
    hrpPayrollReports: HrpPayrollReportsReducer,
    informedVisibilityJobs: InformedVisibilityJobsReducer,
    informedVisibilityJob: InformedVisibilityJobReducer,
    makePayment: MakePaymentReducer,
    markupCalculator: MarkupCalculatorReducer,
    mailingCategories: MailingCategoriesReducer,
    mailingMatrixes: MailingMatrixesReducer,
    mailingSubcategories: MailingSubcategoriesReducer,
    onHoldCustomers: OnHoldCustomersReducer,
    // payrollReports: PayrollReportsReducer,
    postageCalculator: PostageCalculatorReducer,
    pqnDigitalMachines: PqnDigitalMachinesReducer,
    pqnPresses: PqnPressesReducer,
    pqtDashboard: PqtDashboardReducer,
    pqtDataProcessing: PqtDataProcessingReducer,
    pqtDepartment: PqtDepartmentReducer,
    pqtDepartmentView: PqtDepartmentViewReducer,
    pqtInvoice: PqtInvoiceReducer,
    pqtInvoices: PqtInvoicesReducer,
    pqtLayout: PqtLayoutReducer,
    pqtPostageReport: PqtPostageReportReducer,
    pqtPostalQualification: PqtPostalQualificationReducer,
    pqtPostageInvoice: PqtPostageInvoiceReducer,
    pqtPostageInvoices: PqtPostageInvoicesReducer,
    pqtTracking: PqtTrackingReducer,
    purchaseOrders: PurchaseOrdersReducer,
    purchaseOrder: PurchaseOrderReducer,
    quickPostageEscrow: QuickPostageEscrowReducer,
    quoteRequests: QuoteRequestsReducer,
    quoteRequest: QuoteRequestReducer,
    restorePassword: RestorePasswordReducer,
    search: SearchReducer,
    users: UsersReducer,
    // vdpDashboard: VdpDashboardReducer,
    // vdpEditor: VdpEditorReducer,
    // vdpProject: VdpProjectReducer,
    // vdpTutorials: VdpTutorialsReducer,
    // vdpWeekDrops: VdpWeekDropsReducer,
});

export default reducer;
