import React from 'react';
import numeral from 'numeral';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import FormPopover from './FormPopover';

const HandworkForm = React.memo((props) => {
    let items = [];
    let total = 0;

    const handleSetSelectedDataProcessingItem = (id, field, type, event) => {
        props.setSelectedHandworkItem(id, field, type, event.target.value);
    };

    if (props.selectedHandworkItems.length === 0) {
        items = (
            <tr>
                <td colSpan="7">No data</td>
            </tr>
        );
    } else {
        for (let i = 0; i < props.selectedHandworkItems.length; i++) {
            const item = props.selectedHandworkItems[i];

            let price = 0;
            let unit = 'N/A';
            if (parseInt(item.unit) === 1) {    // Thousand
                unit = 'Per 1000';
                price = item.quantity / 1000 * item.unit_price;
            } else if (parseInt(item.unit) === 2) {    // Each
                unit = 'Per each';
                price = item.quantity * item.unit_price;
            } else if (parseInt(item.unit) === 3) {    // Hour
                unit = 'Per hour';
                price = item.quantity * item.unit_price;
            } else if (parseInt(item.unit) === 4) {    // File
                unit = 'Per file';
                price = item.quantity * item.unit_price;
            } else if (parseInt(item.unit) === 5) {    // Drop
                unit = 'Per drop';
                price = item.quantity * item.unit_price;
            } else if (parseInt(item.unit) === 6) {    // Field
                unit = 'Per field';
                price = item.quantity * item.unit_price;
            } else if (parseInt(item.unit) === 7) {    // Job
                unit = 'Per job';
                price = item.quantity * item.unit_price;
            }

            if (price < item.minimum_charge) {
                price = item.minimum_charge;
            }
            total += parseFloat(price);

            items.push(
                <tr>
                    <td>{item.name}</td>
                    <td className="quantity">
                        <input type="number" value={item.quantity} onChange={handleSetSelectedDataProcessingItem.bind(this, item.id, 'quantity', item.type)} />
                    </td>
                    <td className="unit">{unit}</td>
                    <td className="price">{numeral(item.unit_price).format('$0,0.00')}</td>
                    <td className="price">{numeral(item.minimum_charge).format('$0,0.00')}</td>
                    <td className="price">{numeral(price).format('$0,0.00')}</td>
                    <td className="actions">
                        <Tooltip title="Delete" position="bottom" arrow size="small" className="action-tooltip">
                            <i className="fas fa-trash" onClick={props.deleteHandworkItem.bind(this, item.id, item.type)} />
                        </Tooltip>
                    </td>
                </tr>
            );
        }
    }

    return (
        <div className="handwork-form-container">
            <div className="line1">
                <div className="picker" onClick={props.showHandworkModal}>
                    <i className="fas fa-server" /> Handwork Picker
                    <FormPopover
                        code=""
                        information={props.information}
                    />
                </div>
            </div>
            <div className="line2">
                <table className="table table-sm table-hover table-striped">
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th className="quantity">Quantity</th>
                            <th className="unit">Unit</th>
                            <th className="price">Unit Price</th>
                            <th className="price">Minimum Charge</th>
                            <th className="price">Charge</th>
                            <th className="actions"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {items}
                        <tr>
                            <th colSpan="5" className="text-right">Total:</th>
                            <th className="text-right">{numeral(total).format('$0,0.00')}</th>
                            <th></th>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="line3">
                <div className="title"><b>Please describe your project in detail here:</b></div>
                <textarea
                    placeholder="Please let us know more about your project detail"
                    value={props.fields.handwork_description}
                    onChange={props.handleSetField.bind(this, 'handwork_description')}
                />
            </div>
            {props.buttonsContainer}
        </div>
    );
});

export default HandworkForm;
