export const LOGIN = 'USERS/LOGIN';
export const LOGIN_SUCCESS = 'USERS/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'USERS/LOGIN_ERROR';
export const RESTORE_PASSWORD = 'USERS/RESTORE_PASSWORD';
export const RESTORE_PASSWORD_SUCCESS = 'USERS/RESTORE_PASSWORD_SUCCESS';
export const RESTORE_PASSWORD_ERROR = 'USERS/RESTORE_PASSWORD_ERROR';
export const GET_VERSION = 'USERS/GET_VERSION';
export const GET_VERSION_SUCCESS = 'USERS/GET_VERSION_SUCCESS';
export const GET_VERSION_ERROR = 'USERS/GET_VERSION_ERROR';
export const SET_FIELD = 'USERS/SET_FIELD';
export const SET_TOKENS = 'USERS/SET_TOKENS';
export const CLEAR_TOKENS = 'USERS/CLEAR_TOKENS';
export const SET_REDIRECT = 'USERS/SET_REDIRECT';