import React from 'react';
import { Dialog } from './../../common/dialog';
import moment from 'moment-timezone';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { empty } from './../../../utils';

const FormModal = React.memo((props) => {
    const hideHandler = () => {
        props.hideFormModal();
        props.clearForm();
    };

    const handleSetField = (name, event) => {
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked;
        }

        props.setField(name, value);
    };

    const setDateFieldHandler = (name, event) => {
        let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');
        props.setField(name, value);
    };

    const getValue = (value) => {
        if (empty(value)) {
            return '';
        }

        return value;
    };

    let label = 'Save';
    if (props.savingRecord) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    let content = <div className="ap-form-container text-center"><i className="fas fa-circle-notch fa-spin" /> Loading Data...</div>
    if (!props.fetchingRecord) {
        content = (
            <div className="ap-record-form-container">
                <h1>Payroll Report</h1>
                <div className="fields">
                    <div className="field">
                        <div>Name <span className="required">*</span>:</div>
                        <input type="text" value={getValue(props.fields.name)} onChange={handleSetField.bind(this, 'name')} maxLength="21" />
                    </div>
                    <div className="field">
                        <div>Posted Date <span className="required">*</span>:</div>
                        <DayPickerInput
                            formatDate={formatDate}
                            parseDate={parseDate}
                            placeholder=""
                            className="date-picker-field"
                            onDayChange={setDateFieldHandler.bind(this, 'posted_date')}
                            value={props.fields.posted_date == null || props.fields.posted_date === '0000-00-00' ? '' : moment(props.fields.posted_date).format('MM/DD/YYYY')}
                        />
                    </div>
                </div>
            </div>
        );
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Cancel</button>,
        <button key="upload-button" className="btn btn-light btn-sm action-button" onClick={props.saveRecord} disabled={props.savingRecord}>{label}</button>
    ];
    return (
        <Dialog
            name="ap-record-form-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="ap-record-form-dialog"
        >
            {content}
        </Dialog>
    );
});

export default FormModal;
