import React from 'react';

const Form = React.memo((props) => {
    if (props.fetchingPayment) {
        return (
            <div className="make-payment-form-container loading">
                <i className="fas fa-circle-notch fa-spin" /> Loading payment data...
            </div>
        );
    }

    const handleSetField = (field, event) => {
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        }

        props.setField(field, value);
    };

    let paymentLabel = 'Send Information';
    if (props.savingForm) {
        paymentLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    const renderAddressFields = () => {
        let requiredDot = <span> <span className="required">*</span></span>;
        if (parseInt(props.fields.is_pcard)) {
            requiredDot = null;
        }

        return (
            <div className="billing-address-container">
                <div className="title">Your Address:</div>
                <div className="field">
                    <div className="label">Credit Card Mailing Address{requiredDot}:</div>
                    <input
                        type="text"
                        value={props.fields.address}
                        onChange={handleSetField.bind(this, 'address')}
                    />
                </div>
                <div className="field">
                    <div className="label">City{requiredDot}:</div>
                    <input
                        type="text"
                        value={props.fields.city}
                        onChange={handleSetField.bind(this, 'city')}
                    />
                </div>
                <div className="field">
                    <div className="label">State{requiredDot}:</div>
                    <input
                        type="text"
                        value={props.fields.state}
                        onChange={handleSetField.bind(this, 'state')}
                    />
                </div>
                <div className="field">
                    <div className="label">Zip{requiredDot}:</div>
                    <input
                        type="text"
                        value={props.fields.zip}
                        onChange={handleSetField.bind(this, 'zip')}
                    />
                </div>
                <div className="field email-field">
                    <div className="label">Email Address{requiredDot}:</div>
                    <input
                        type="text"
                        value={props.fields.email}
                        onChange={handleSetField.bind(this, 'email')}
                    />
                </div>
                <div className="field email-field">
                    <div className="label">Phone Number:</div>
                    <input
                        type="text"
                        value={props.fields.phone}
                        onChange={handleSetField.bind(this, 'phone')}
                    />
                </div>
                <div className="legend">
                    NOTE: For non-established customers, the credit card address must match the shipping address. If the addresses do not match
                    then another form of payment must be used.
                </div>
            </div>
        );
    };

    return (
        <div className="cc-payment-auth-form-container">
            <div className="logo-container">
                <img src="https://ac-pqn.s3.amazonaws.com/css/acm-logo.png" alt="ACM" />
                <div className="lock-icon">
                    <img src="https://ac-pqn.s3.amazonaws.com/css/lock.jpeg" alt="Lock" />
                </div>
            </div>
            <h1>Credit Card Payment Authorization Form</h1>
            <div className="main-container">
                <div className="title">Summary:</div>
                <div className="container-row">
                    <div className="field">
                        <div className="label">Date <span className="required">*</span>:</div>
                        <input
                            type="text"
                            value={props.fields.date}
                            onChange={handleSetField.bind(this, 'date')}
                        />
                    </div>
                    <div className="field">
                        <div className="label">Requested By:</div>
                        <input
                            type="text"
                            value={props.fields.requested_by}
                            onChange={handleSetField.bind(this, 'requested_by')}
                        />
                    </div>
                    <div className="field">
                        <div className="label">Client:</div>
                        <input
                            type="text"
                            value={props.fields.client}
                            onChange={handleSetField.bind(this, 'client')}
                        />
                    </div>
                </div>
                <div className="container-row">
                    <div className="field">
                        <div className="label">Job/Invoice Number:</div>
                        <input
                            type="text"
                            value={props.fields.job_invoice}
                            onChange={handleSetField.bind(this, 'job_invoice')}
                        />
                    </div>
                    <div className="field">
                        <div className="label">Amount:</div>
                        <input
                            type="number"
                            value={props.fields.amount}
                            onChange={handleSetField.bind(this, 'amount')}
                        />
                    </div>
                    <div className="field">
                        <div className="label">Postage Amount:</div>
                        <input
                            type="number"
                            value={props.fields.postage_amount}
                            onChange={handleSetField.bind(this, 'postage_amount')}
                        />
                    </div>
                </div>
                <div className="last-container-row">
                    <div className="field">
                        <div className="label">** Convenience Fee 4% (Add if paying for postage):</div>
                        <input
                            type="number"
                            value={props.fields.convenience_fee}
                            onChange={handleSetField.bind(this, 'convenience_fee')}
                        />
                    </div>
                    <div className="field">
                        <div className="label">Total Amount to Charge <span className="required">*</span>:</div>
                        <input
                            type="number"
                            value={props.fields.total_amount}
                            onChange={handleSetField.bind(this, 'total_amount')}
                        />
                    </div>
                </div>
            </div>
            <div className="card-payment-container">
                <div className="title">
                    <div>Your Payment Information:</div>
                    <div className="pcard-field">
                        <input type="checkbox"
                            checked={props.fields.is_pcard}
                            onChange={handleSetField.bind(this, 'is_pcard')} /> This is a P-Card
                    </div>
                </div>
                <div className="field card-number-field full-credit-card">
                    <div className="label">Card Type <span className="required">*</span>:</div>
                    <input
                        type="text"
                        value={props.fields.card_type}
                        onChange={handleSetField.bind(this, 'card_type')}
                    />
                </div>
                <div className="field card-number-field full-credit-card">
                    <div className="label">Card Number <span className="required">*</span>:</div>
                    <input
                        type="text"
                        value={props.fields.card_number}
                        onChange={handleSetField.bind(this, 'card_number')}
                    />
                </div>
                <div className="field">
                    <div className="label">Expiration Month <span className="required">*</span>:</div>
                    <select value={props.fields.expiration_month} onChange={handleSetField.bind(this, 'expiration_month')}>
                        <option value="">Select</option>
                        <option value="01">January</option>
                        <option value="02">February</option>
                        <option value="03">March</option>
                        <option value="04">April</option>
                        <option value="05">May</option>
                        <option value="06">June</option>
                        <option value="07">July</option>
                        <option value="08">August</option>
                        <option value="09">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </select>
                </div>
                <div className="field">
                    <div className="label">Expiration Year <span className="required">*</span>:</div>
                    <select value={props.fields.expiration_year} onChange={handleSetField.bind(this, 'expiration_year')}>
                        <option value="">Select</option>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                        <option value="2026">2026</option>
                        <option value="2027">2027</option>
                        <option value="2028">2028</option>
                        <option value="2029">2029</option>
                        <option value="2030">2030</option>
                        <option value="2031">2031</option>
                        <option value="2032">2032</option>
                        <option value="2033">2033</option>
                        <option value="2034">2034</option>
                        <option value="2035">2035</option>
                        <option value="2036">2036</option>
                        <option value="2037">2037</option>
                        <option value="2038">2038</option>
                        <option value="2039">2039</option>
                        <option value="2040">2040</option>
                    </select>
                </div>
                <div className="field">
                    <div className="label">Security Code <span className="required">*</span>:</div>
                    <input
                        type="number"
                        value={props.fields.cvv}
                        onChange={handleSetField.bind(this, 'cvv')}
                    />
                </div>
                <div className="field card-number-field name-on-card">
                    <div className="label">Customer Name On Card:</div>
                    <input
                        type="text"
                        value={props.fields.customer_name_on_card}
                        onChange={handleSetField.bind(this, 'customer_name_on_card')}
                    />
                </div>
                <div className="field card-number-field name-on-card">
                    <div className="label">Company Name On Card:</div>
                    <input
                        type="text"
                        value={props.fields.company_name_on_card}
                        onChange={handleSetField.bind(this, 'company_name_on_card')}
                    />
                </div>
                <div className="logos-container">
                    <img src="https://ac-pqn.s3.amazonaws.com/css/credit-cards-logos.png" alt="Credit Card" />
                </div>
            </div>
            {renderAddressFields()}
            <div className="button-container">
                <button onClick={props.saveForm} disabled={props.savingForm}>
                    {paymentLabel}
                </button>
            </div>
        </div>
    );
});

export default Form;
