import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Records from './Records';
import DeleteModal from './DeleteModal';
import FormModal from './FormModal';
import * as actions from './ApRecords.actions';
import * as selectors from './ApRecords.selectors';
import './ApRecords.scss';
import PqtLayout from './../PqtLayout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import * as dialog from '../common/dialog';
import * as loginSelectors from './../Login/Login.selectors';
import { Redirect } from 'react-router-dom';

const ApRecords = (props) => {
    /* State to props */
    const fetchingRecords = useSelector(selectors.getFetchingRecords);
    const records = useSelector(selectors.getRecords, stringifyEqualityCheck);
    const pagination = useSelector(selectors.getPagination, stringifyEqualityCheck);
    const sort = useSelector(selectors.getSort, stringifyEqualityCheck);
    const idForDelete = useSelector(selectors.getIdForDelete, stringifyEqualityCheck);
    const isDeleting = useSelector(selectors.getIsDeleting, stringifyEqualityCheck);
    const filerFields = useSelector(selectors.getFilterFields, stringifyEqualityCheck);
    const savingRecord = useSelector(selectors.getSavingRecord, stringifyEqualityCheck);
    const fields = useSelector(selectors.getFields, stringifyEqualityCheck);
    const fetchingRecord = useSelector(selectors.getFetchingRecord, stringifyEqualityCheck);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const deleteRecord = useCallback((id, comments) => dispatch(actions.deleteRecord(id, comments)), [dispatch]);
    const setSort = useCallback((column, type) => dispatch(actions.setSort(column, type)), [dispatch]);
    const setActivePage = useCallback((name, value) => dispatch(actions.setActivePage(name, value)), [dispatch]);
    const fetchRecords = useCallback((options, isWsUpdate) => dispatch(actions.fetchRecords(options, isWsUpdate)), [dispatch]);
    const setIdForDelete = useCallback((id) => dispatch(actions.setIdForDelete(id)), [dispatch]);
    const changeFilter = useCallback((name, value, fetch) => dispatch(actions.changeFilter(name, value, fetch)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);
    const fetchRecord = useCallback((id) => dispatch(actions.fetchRecord(id)), [dispatch]);
    const setField = useCallback((field, value) => dispatch(actions.setField(field, value)), [dispatch]);
    const saveRecord = useCallback(() => dispatch(actions.saveRecord()), [dispatch]);
    const clearForm = useCallback(() => dispatch(actions.clearForm()), [dispatch]);
    const showDeleteModal = useCallback(() => dispatch(dialog.actions.showDialog('delete-modal')), [dispatch]);
    const hideDeleteModal = useCallback(() => dispatch(dialog.actions.hideDialog('delete-modal')), [dispatch]);
    const showFormModal = useCallback(() => dispatch(dialog.actions.showDialog('ap-record-form-modal')), [dispatch]);
    const hideFormModal = useCallback(() => dispatch(dialog.actions.hideDialog('ap-record-form-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        dispatch(actions.mountComponent());
        return () => {
            resetState();
        };
    }, [dispatch, resetState]);

    if (user != null && ![61, 1147, 2419].includes(parseInt(user.id))) {     // Hugh (61), AP (1147), Stephanie (2419)
        return <Redirect to="/dashboard" />;
    }

    return (
        <PqtLayout title="AP Records" history={props.history}>
            <Helmet><title>AP Records - Stagecoach</title></Helmet>
            <div className="ap-records-container">
                <Records
                    records={records}
                    pagination={pagination}
                    fetchingRecords={fetchingRecords}
                    history={props.history}
                    sort={sort}
                    user={user}
                    filerFields={filerFields}
                    setSort={setSort}
                    setActivePage={setActivePage}
                    showDeleteModal={showDeleteModal}
                    setIdForDelete={setIdForDelete}
                    changeFilter={changeFilter}
                    fetchRecords={fetchRecords}
                    fetchRecord={fetchRecord}
                    showFormModal={showFormModal}
                />
                <DeleteModal
                    idForDelete={idForDelete}
                    isDeleting={isDeleting}
                    deleteRecord={deleteRecord}
                    hideDeleteModal={hideDeleteModal}
                    setIdForDelete={setIdForDelete}
                />
                <FormModal
                    savingRecord={savingRecord}
                    fields={fields}
                    fetchingRecord={fetchingRecord}
                    saveRecord={saveRecord}
                    hideFormModal={hideFormModal}
                    setField={setField}
                    clearForm={clearForm}
                />
            </div>
        </PqtLayout>
    );
};

export default ApRecords;
