import { createSelector } from 'reselect';

export const getComponentState = state => state.purchaseOrders;

/* fetching */
export const getFetching = createSelector(
    getComponentState,
    (state) => state.fetching
);
export const getFetchingPurchaseOrders = createSelector(
    getComponentState,
    (state) => state.fetching.purchaseOrders
);

/* data */
export const getPurchaseOrdersData = createSelector(
    getComponentState,
    (state) => state.data.purchaseOrders
);

/* pagination */
export const getPagination = createSelector(
    getComponentState,
    (state) => state.pagination
);

/* sort */
export const getSort = createSelector(
    getComponentState,
    (state) => state.sort
);

/* delete */
export const getIdForDelete = createSelector(
    getComponentState,
    (state) => state.delete.id
);
export const getIsDeleting = createSelector(
    getComponentState,
    (state) => state.delete.isDeleting
);

export const getShow = createSelector(
    getComponentState,
    (state) => state.show
);

export const getFilterFields = createSelector(
    getComponentState,
    (state) => state.filter.fields
);
export const getIsExporting = createSelector(
    getComponentState,
    (state) => state.isExporting
);
export const getIsExportingReport = createSelector(
    getComponentState,
    (state) => state.isExportingReport
);
export const getIsExportingListPurchase = createSelector(
    getComponentState,
    (state) => state.isExportingListPurchase
);
export const getReportStartDate = createSelector(
    getComponentState,
    (state) => state.reportStartDate
);
export const getReportEndDate = createSelector(
    getComponentState,
    (state) => state.reportEndDate
);
export const getReportType = createSelector(
    getComponentState,
    (state) => state.reportType
);
export const getFile = createSelector(
    getComponentState,
    (state) => state.file
);
export const getIsUploadingListPurchase = createSelector(
    getComponentState,
    (state) => state.isUploadingListPurchase
);