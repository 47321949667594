export const FETCH_HELP_ITEMS = 'HELP_ITEMS/FETCH_HELP_ITEMS';
export const FETCH_HELP_ITEMS_SUCCESS = 'HELP_ITEMS/FETCH_HELP_ITEMS_SUCCESS';
export const FETCH_HELP_ITEMS_ERROR = 'HELP_ITEMS/FETCH_HELP_ITEMS_ERROR';
export const DELETE_HELP_ITEM = 'HELP_ITEMS/DELETE_HELP_ITEM';
export const DELETE_HELP_ITEM_SUCCESS = 'HELP_ITEMS/DELETE_HELP_ITEM_SUCCESS';
export const DELETE_HELP_ITEM_ERROR = 'HELP_ITEMS/DELETE_HELP_ITEM_ERROR';
export const SAVE_HELP_ITEM = 'HELP_ITEMS/SAVE_HELP_ITEM';
export const SAVE_HELP_ITEM_SUCCESS = 'HELP_ITEMS/SAVE_HELP_ITEM_SUCCESS';
export const SAVE_HELP_ITEM_ERROR = 'HELP_ITEMS/SAVE_HELP_ITEM_ERROR';
export const RESET_STATE = 'HELP_ITEMS/RESET_STATE';
export const SET_SORT = 'HELP_ITEMS/SET_SORT';
export const SET_ACTIVE_PAGE = 'HELP_ITEMS/SET_ACTIVE_PAGE';
export const SET_ID_FOR_DELETE = 'HELP_ITEMS/SET_ID_FOR_DELETE';
export const CHANGE_FILTER = 'HELP_ITEMS/CHANGE_FILTER';
export const SET_NAME = 'HELP_ITEMS/SET_NAME';
export const SET_CODE_REF = 'HELP_ITEMS/SET_CODE_REF';
export const SET_DESCRIPTION = 'HELP_ITEMS/SET_DESCRIPTION';
export const SET_IMAGE = 'HELP_ITEMS/SET_IMAGE';
export const CLEAR_FIELDS = 'HELP_ITEMS/CLEAR_FIELDS';
export const SET_ACTIVE_HELP_ITEM = 'HELP_ITEMS/SET_ACTIVE_HELP_ITEM';