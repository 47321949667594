import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Information from './Information';
import * as actions from './InformedVisibilityJob.actions';
import * as selectors from './InformedVisibilityJob.selectors';
import './InformedVisibilityJob.scss';
import PqtLayout from './../PqtLayout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import * as loginSelectors from './../Login/Login.selectors';
import { Redirect } from 'react-router-dom';

const InformedVisibilityJob = (props) => {
    /* State to props */
    const fetchingJob = useSelector(selectors.getFetchingJob);
    const job = useSelector(selectors.getJob, stringifyEqualityCheck);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const fetchJob = useCallback((id) => dispatch(actions.fetchJob(id)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        fetchJob(props.match.params.id);
        return () => {
            resetState();
        };
    }, [resetState, fetchJob, props.match.params.id]);

    if (user != null && parseInt(user.id) !== 61) {     // Hugh
        return <Redirect to="/dashboard" />;
    }

    return (
        <PqtLayout title="Informed Visibility Job" history={props.history}>
            <Helmet><title>Informed Visibility Job - Stagecoach</title></Helmet>
            <div className="informed-visibility-job-container">
                <Information
                    fetchingJob={fetchingJob}
                    job={job}
                />
            </div>
        </PqtLayout>
    );
};

export default InformedVisibilityJob;
