import Immutable from 'immutable';
import * as types from './MailingMatrixes.types';
import numeral from 'numeral';

const initialState = {
    id: null,
    subcategoryId: null,
    subcategory: null,
    matrix: {
        speed_check: 0,
        labor_check: 0,
        is_active: 1,
        code: 'sc-est',
    },
    matrixes: [],
    fetchingSubcategory: false,
    fetchingMatrixes: false,
    pagination: {
        totalElements: 0,
        activePage: 1,
        totalPages: 0,
        elementsPerPage: 15,
    },
    sort: {
        column: 'name',
        type: 'asc',
    },
    history: null,
    delete: {
        id: null,
        isDeleting: false,
    },
    filter: {
        fields: [],
    },
    isSaving: false,
    matrixData: [
        { quantity: '25000', factor: '1.59', base: '' },
        { quantity: '50000', factor: '1.34', base: '' },
        { quantity: '100000', factor: '1.19', base: '' },
        { quantity: '250000', factor: '1.16', base: '' },
        { quantity: '500000', factor: '1.11', base: '' },
        { quantity: '1000000', factor: '1.04', base: '' },
        { quantity: '1500000', factor: '1.03', base: '' },
        { quantity: '2000000', factor: '1.02', base: '' },
        { quantity: '3000000', factor: '1', base: '' },
    ],
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_MATRIXES:
            return state.setIn(['fetchingMatrixes'], true).toJS();

        case types.FETCH_MATRIXES_SUCCESS:
            return state.setIn(['fetchingMatrixes'], false)
                .setIn(['matrixes'], action.payload.elements)
                .setIn(['pagination', 'totalElements'], action.payload.totalElements)
                .setIn(['pagination', 'activePage'], action.payload.activePage)
                .setIn(['pagination', 'totalPages'], action.payload.totalPages)
                .setIn(['pagination', 'elementsPerPage'], action.payload.elementsPerPage)
                .toJS();

        case types.FETCH_MATRIXES_ERROR:
            return state.setIn(['fetchingMatrixes'], false).toJS();

        case types.FETCH_SUBCATEGORY:
            return state.setIn(['fetchingSubcategory'], true).toJS();

        case types.FETCH_SUBCATEGORY_SUCCESS:
            return state.setIn(['fetchingSubcategory'], false)
                .setIn(['subcategory'], action.payload)
                .toJS();

        case types.FETCH_SUBCATEGORY_ERROR:
            return state.setIn(['fetchingSubcategory'], false).toJS();

        case types.SET_SORT:
            return state.setIn(['sort'], action.payload).toJS();

        case types.SET_ACTIVE_PAGE:
            return state.setIn(['pagination', 'activePage'], action.payload).toJS();

        case types.SAVE_MATRIX:
            return state.setIn(['isSaving'], true).toJS();

        case types.SAVE_MATRIX_SUCCESS:
            return state.setIn(['isSaving'], false).toJS();

        case types.SAVE_MATRIX_ERROR:
            return state.setIn(['isSaving'], false).toJS();

        case types.DELETE_MATRIX:
            return state.setIn(['delete', 'isDeleting'], true).toJS();

        case types.DELETE_MATRIX_SUCCESS:
            return state.setIn(['delete', 'isDeleting'], false)
                .setIn(['delete', 'id'], null)
                .toJS();

        case types.DELETE_MATRIX_ERROR:
            return state.setIn(['delete', 'isDeleting'], false).toJS();

        case types.SET_ID_FOR_DELETE:
            return state.setIn(['delete', 'id'], action.payload).toJS();

        case types.SET_MATRIX:
            return state.setIn(['matrix'], action.payload).toJS();

        case types.SET_FIELD:
            return state.setIn(['matrix', action.payload.field], action.payload.value)
                .updateIn(['matrixData'], (matrixData) => {
                    let newMatrixData = matrixData.toJS();

                    if (action.payload.field === 'base') {
                        for (let i = 0; i < newMatrixData.length; i++) {
                            newMatrixData[i]['base'] = numeral(newMatrixData[i]['factor'] * action.payload.value).format('0.[0000]');
                        }
                    }

                    return newMatrixData;
                }).toJS();

        case types.SET_SUBCATEGORY_ID:
            return state.setIn(['subcategoryId'], action.payload).toJS();

        case types.CHANGE_FILTER:
            return state.setIn(['pagination', 'activePage'], 1)
                .updateIn(['filter', 'fields'], (fields) => {
                    const index = fields.findIndex(field => field.get('name') === action.payload.name);
                    if (action.payload.value === '') {
                        return fields.delete(index);
                    } else {
                        if (index === -1) {
                            return fields.push({ name: action.payload.name, value: action.payload.value });
                        } else {
                            return fields.set(index, { name: action.payload.name, value: action.payload.value });
                        }
                    }
                })
                .toJS();

        case types.ADD_MATRIX_ROW:
            return state.updateIn(['matrixData'], (matrixData) => {
                return matrixData.push({
                    quantity: '',
                    factor: '1',
                    base: '',
                });
            }).toJS();

        case types.SET_MATRIX_FIELD:
            return state.updateIn(['matrixData'], (matrixData) => {
                let newMatrixData = matrixData.toJS();
                newMatrixData[action.payload.index][action.payload.field] = action.payload.value;

                if (action.payload.field === 'factor') {
                    newMatrixData[action.payload.index]['base'] = numeral(action.payload.value * action.payload.base).format('0.[0000]');
                }

                return newMatrixData;
            }).toJS();

        case types.REMOVE_MATRIX_ROW:
            return state.updateIn(['matrixData'], (matrixData) => {
                return matrixData.delete(action.payload);
            }).toJS();

        case types.RESET_MATRIX:
            return state.setIn(['matrixData'],
                [
                    { quantity: '25000', factor: '1.59', base: '' },
                    { quantity: '50000', factor: '1.34', base: '' },
                    { quantity: '100000', factor: '1.19', base: '' },
                    { quantity: '250000', factor: '1.16', base: '' },
                    { quantity: '500000', factor: '1.11', base: '' },
                    { quantity: '1000000', factor: '1.04', base: '' },
                    { quantity: '1500000', factor: '1.03', base: '' },
                    { quantity: '2000000', factor: '1.02', base: '' },
                    { quantity: '3000000', factor: '1', base: '' },
                ]
            ).toJS();

        case types.SET_MATRIX_DATA:
            return state.setIn(['matrixData'], action.payload).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}