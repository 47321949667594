import React from 'react';
//import PropTypes from 'prop-types';

const Tabs = React.memo((props) => {
    const pqtType = parseInt(props.user.pqt_type);

    const clickHandler = (event) => {
        if(parseInt(props.showJobs) === 1) {
            props.history.push('/department-view/' + event.target.value);
        } else {
            props.history.push('/department-view/' + event.target.value + '/' + props.showJobs);
        }

        props.resetState();
        props.setId(event.target.value, props.showJobs);
        props.fetchData({
            fetchInformation: 1,
            fetchDepartment: 1,
            departmentId: event.target.value,
        });
    };

    const renderDepartmentOptions = () => props.departmentsInformation.map((department, index) => {
        if (parseInt(department.count) === 0 || ([6, 7].includes(pqtType) && !props.user.pqt_departments.includes(parseInt(department.id)))) {
            return null;
        }
        return <option key={'department-tab-' + index} value={department.id}>{department.name}</option>;
    });

    return (
        <div className="tabs-container">
            <div className="tabs">
                Department:
                <select className="departments-select" value={props.id} onChange={clickHandler}>
                    {renderDepartmentOptions()}
                </select>
            </div>
            <div className="legend">
                Show Jobs:
                <select className="show-jobs-select" value={props.showJobs} onChange={(e) => props.setShowJobs(e.target.value)}>
                    <option value="1">All</option>
                    <option value="2">Only Active</option>
                    <option value="3">Only Upcoming</option>
                </select>
                <i className="fas fa-square red-box"></i> Past Due |&nbsp;
                <i className="fas fa-square yellow-box" ></i> &lt; 7 |&nbsp;
                <i className="fas fa-square blue-box"></i> &lt; 14 |&nbsp;
                <i className="fas fa-square green-box"></i> &gt;= 14
            </div>
        </div>
    );
});

Tabs.propTypes = {

};

export default Tabs;
