import React from 'react';

const Table = React.memo((props) => {
    if (props.fetchingJob) {
        return (
            <div className="information-container">
                <i className="fas fa-circle-notch fa-spin" /> Loading data...
            </div>
        );
    }

    return (
        <div className="information-container">
            Info
        </div>
    );
});

export default Table;
