import React from 'react';
import { isEstimator } from './../../../utils';

const ButtonsContainer = React.memo((props) => {
    const isPqtUserAllowed = [1, 2, 3, 4, 5, 9].includes(parseInt(props.user.pqt_type));

    let approveButton = null;
    if (props.readyToApprove) {
        approveButton = (
            <button disabled={props.approving} onClick={props.showConfirmApproveModal}>
                {props.approving ? <i className="fas fa-circle-notch fa-spin" /> : 'Approve'}
            </button>
        );
    }

    let convertToJobButton = null;
    if (props.readyToJob) {
        convertToJobButton = (
            <button disabled={props.converting} onClick={props.showConfirmConvertModal}>
                {props.converting ? <i className="fas fa-circle-notch fa-spin" /> : 'SC Job'}
            </button>
        );
    }

    let estimateButton = null;
    if (
        (props.user != null && isEstimator(props.user)) &&
        ([1, 4].includes(parseInt(props.estimate.status)) || !props.estimate.id || props.estimate.id === '')
    ) {
        estimateButton = (
            <button disabled={props.estimating} onClick={props.calculateEstimate} className={props.dataChanged ? 'data-changed' : ''}>
                {props.estimating ? <i className="fas fa-circle-notch fa-spin" /> : 'Estimate'}
            </button>
        );
    }

    let saveButton = null;
    if (
        (props.user != null && (isEstimator(props.user) || isPqtUserAllowed)) &&
        ([1, 4].includes(parseInt(props.estimate.status)) || !props.estimate.id || props.estimate.id === '')
    ) {
        saveButton = (
            <button disabled={props.saving} onClick={props.saveEstimate} className={props.estimateChanged ? 'save-button estimate-changed' : 'save-button'}>
                {props.saving ? <i className="fas fa-circle-notch fa-spin" /> : 'Save'}
            </button>
        );
    }

    let sendButton = null;
    if (parseInt(props.estimate.status) === 4) {
        sendButton = (
            <button disabled={props.sending} onClick={props.showConfirmSendModal} className="send-button">
                {props.sending ? <i className="fas fa-circle-notch fa-spin" /> : 'Send'}
            </button>
        );
    }

    let downloadButton = null;
    if (parseInt(props.estimate.status) > 1) {
        downloadButton = (
            <button disabled={props.downloadingEstimate} onClick={props.downloadEstimate} className="download-button">
                {props.downloadingEstimate ? <i className="fas fa-circle-notch fa-spin" /> : 'Download'}
            </button>
        );
    }

    let downloadPressTicketButton = null;
    if (parseInt(props.estimate.status) > 1 && [1, 2, 3, 4, 5, 7, 8, 9].includes(parseInt(props.estimate.type))) {
        downloadPressTicketButton = (
            <button disabled={props.downloadingPressTicket} onClick={props.downloadPressTicket} className="press-ticket-button">
                {props.downloadingPressTicket ? <i className="fas fa-circle-notch fa-spin" /> : 'Press Ticket'}
            </button>
        );
    }

    if (props.estimate.full_outside_job) {
        estimateButton = null;
    }

    if (parseInt(props.estimateType) === 10) {     // Mail Only
        estimateButton = null;
        downloadPressTicketButton = null;
    }

    const handleShowChangeStatusModal = () => {
        props.setNewStatus(props.estimate.status);
        props.showChangeStatusModal();
    };

    let changeStatusButton = null;
    if (parseInt(props.user.id) === 61) {     // Hugh
        changeStatusButton = (
            <button onClick={handleShowChangeStatusModal} className="change-status-button">
                Change Status
            </button>
        );
    }

    return (
        <div className="buttons">
            {estimateButton}
            {saveButton}
            {sendButton}
            {approveButton}
            {downloadButton}
            {downloadPressTicketButton}
            {convertToJobButton}
            {changeStatusButton}
        </div>
    );
});

export default ButtonsContainer;
