export const FETCH_REPORT = 'PQT_POSTAGE_REPORT/FETCH_REPORT';
export const FETCH_REPORT_SUCCESS = 'PQT_POSTAGE_REPORT/FETCH_REPORT_SUCCESS';
export const FETCH_REPORT_ERROR = 'PQT_POSTAGE_REPORT/FETCH_REPORT_ERROR';
export const EXPORT_REPORT = 'PQT_POSTAGE_REPORT/EXPORT_REPORT';
export const EXPORT_REPORT_SUCCESS = 'PQT_POSTAGE_REPORT/EXPORT_REPORT_SUCCESS';
export const EXPORT_REPORT_ERROR = 'PQT_POSTAGE_REPORT/EXPORT_REPORT_ERROR';
export const EXPORT_POSTAGE_REPORT = 'PQT_POSTAGE_REPORT/EXPORT_POSTAGE_REPORT';
export const EXPORT_POSTAGE_REPORT_SUCCESS = 'PQT_POSTAGE_REPORT/EXPORT_POSTAGE_REPORT_SUCCESS';
export const EXPORT_POSTAGE_REPORT_ERROR = 'PQT_POSTAGE_REPORT/EXPORT_POSTAGE_REPORT_ERROR';
export const FETCH_CUSTOMERS = 'PQT_POSTAGE_REPORT/FETCH_CUSTOMERS';
export const FETCH_CUSTOMERS_SUCCESS = 'PQT_POSTAGE_REPORT/FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_ERROR = 'PQT_POSTAGE_REPORT/FETCH_CUSTOMERS_ERROR';
export const FETCH_INFORMATION = 'PQT_POSTAGE_REPORT/FETCH_INFORMATION';
export const FETCH_INFORMATION_SUCCESS = 'PQT_POSTAGE_REPORT/FETCH_INFORMATION_SUCCESS';
export const FETCH_INFORMATION_ERROR = 'PQT_POSTAGE_REPORT/FETCH_INFORMATION_ERROR';
export const SET_REPORT_TYPE = 'PQT_POSTAGE_REPORT/SET_REPORT_TYPE';
export const SET_CUSTOMER = 'PQT_POSTAGE_REPORT/SET_CUSTOMER';
export const SET_FROM_DATE = 'PQT_POSTAGE_REPORT/SET_FROM_DATE';
export const SET_TO_DATE = 'PQT_POSTAGE_REPORT/SET_TO_DATE';
export const SET_SALES_REP = 'PQT_POSTAGE_REPORT/SET_SALES_REP';