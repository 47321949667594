import React from 'react';

const Accounts = React.memo((props) => {
    const handleSetField = (field, event) => {
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        }

        props.setField(field, value);
    };

    return (
        <div className="calculator-container">
            <div className="calculator">
                <div className="field">
                    <div className="label">Cost</div>
                    <div className="input">
                        $ <input type="number" value={props.fields.cost} onChange={handleSetField.bind(this, 'cost')} />
                    </div>
                </div>
                <div className="field">
                    <div className="label">Markup</div>
                    <div className="input markup">
                        % <input type="number" value={props.fields.markup} onChange={handleSetField.bind(this, 'markup')} />
                    </div>
                </div>
                <div className="field">
                    <div className="label">Charge</div>
                    <div className="input">
                        $ <input type="number" value={props.fields.revenue} onChange={handleSetField.bind(this, 'revenue')} />
                    </div>
                </div>
                <div className="field">
                    <div className="label">Profit</div>
                    <div className="input">
                        $ <input type="number" value={props.fields.profit} onChange={handleSetField.bind(this, 'profit')} />
                    </div>
                </div>
            </div>
            <div className="description">
                The markup calculator (alternatively spelled as "mark up calculator") is a business tool most often used to calculate 
                your sale price. Just enter the cost and markup, and the price you should charge will be computed instantly. It can 
                also be used to calculate the cost - in this case, provide your revenue and markup. If you would like a markup percentage 
                calculator, then just provide the cost and revenue. Keep on reading to find out what is markup, how to calculate markup 
                and what is the difference between margin vs markup.
            </div>
        </div>
    );
});

export default Accounts;
