import Immutable from 'immutable';
import * as types from './CustomerApplication.types';

const initialFields = {
    type: 1,
    sales_rep: '',
    customer_name: '',
    dba: '',
    mailing_address: '',
    mailing_city: '',
    mailing_state: '',
    mailing_zip: '',
    mailing_phone: '',
    mailing_cell: '',
    contact_name: '',
    contact_email_address: '',
    marketing_contact: '',
    marketing_email_address: '',
    ap_contact: '',
    ap_contact_email: '',
    billing_address: '',
    billing_city: '',
    billing_state: '',
    billing_zip: '',
    billing_phone: '',
    billing_cell: '',
    billing_fax: '',
    is_sales_tax_exempt: 0,
    industry_code: '',
    authorized_signature: '',
    title: '',
    printed_name: '',
    date: '',
    type_of_business: '',
    other: '',
    statements_required: 0,
    years_in_operation: '',
    president: '',
    bank_name_and_branch: '',
    banker: '',
    phone: '',
    reference1_name: '',
    reference1_address: '',
    reference1_phone: '',
    reference1_email_or_fax: '',
    reference2_name: '',
    reference2_address: '',
    reference2_phone: '',
    reference2_email_or_fax: '',
    reference3_name: '',
    reference3_address: '',
    reference3_phone: '',
    reference3_email_or_fax: '',
    reference4_name: '',
    reference4_address: '',
    reference4_phone: '',
    reference4_email_or_fax: '',
};

const initialState = {
    fields: initialFields,
    savingApplication: false,
    file: null,
    recaptchaToken: null,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.SAVE_APPLICATION:
            return state.setIn(['savingApplication'], true).toJS();

        case types.SAVE_APPLICATION_SUCCESS:
            return state.setIn(['savingApplication'], false).toJS();

        case types.SAVE_APPLICATION_ERROR:
            return state.setIn(['savingApplication'], false).toJS();

        case types.SET_FIELD:
            return state.setIn(['fields', action.payload.field], action.payload.value).toJS();

        case types.CLEAR_FORM:
            return state.setIn(['fields'], initialFields).toJS();

        case types.SET_FILE:
            return state.setIn(['file'], action.payload).toJS();

        case types.SET_RECAPTCHA_TOKEN:
            return state.setIn(['recaptchaToken'], action.payload).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}