export const FETCH_REPORTS = 'HRP_PAYROLL_REPORTS/FETCH_REPORTS';
export const FETCH_REPORTS_SUCCESS = 'HRP_PAYROLL_REPORTS/FETCH_REPORTS_SUCCESS';
export const FETCH_REPORTS_ERROR = 'HRP_PAYROLL_REPORTS/FETCH_REPORTS_ERROR';
export const DELETE_REPORT = 'HRP_PAYROLL_REPORTS/DELETE_REPORT';
export const DELETE_REPORT_SUCCESS = 'HRP_PAYROLL_REPORTS/DELETE_REPORT_SUCCESS';
export const DELETE_REPORT_ERROR = 'HRP_PAYROLL_REPORTS/DELETE_REPORT_ERROR';
export const UPLOAD_FILE = 'HRP_PAYROLL_REPORTS/UPLOAD_FILE';
export const UPLOAD_FILE_SUCCESS = 'HRP_PAYROLL_REPORTS/UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_ERROR = 'HRP_PAYROLL_REPORTS/UPLOAD_FILE_ERROR';
export const DOWNLOAD_REPORT = 'HRP_PAYROLL_REPORTS/DOWNLOAD_REPORT';
export const DOWNLOAD_REPORT_SUCCESS = 'HRP_PAYROLL_REPORTS/DOWNLOAD_REPORT_SUCCESS';
export const DOWNLOAD_REPORT_ERROR = 'HRP_PAYROLL_REPORTS/DOWNLOAD_REPORT_ERROR';
export const FETCH_REPORT = 'HRP_PAYROLL_REPORTS/FETCH_REPORT';
export const FETCH_REPORT_SUCCESS = 'HRP_PAYROLL_REPORTS/FETCH_REPORT_SUCCESS';
export const FETCH_REPORT_ERROR = 'HRP_PAYROLL_REPORTS/FETCH_REPORT_ERROR';
export const SAVE_MEMO = 'HRP_PAYROLL_REPORTS/SAVE_MEMO';
export const SAVE_MEMO_SUCCESS = 'HRP_PAYROLL_REPORTS/SAVE_MEMO_SUCCESS';
export const SAVE_MEMO_ERROR = 'HRP_PAYROLL_REPORTS/SAVE_MEMO_ERROR';
export const PUSH_TO_QB = 'HRP_PAYROLL_REPORTS/PUSH_TO_QB';
export const PUSH_TO_QB_SUCCESS = 'HRP_PAYROLL_REPORTS/PUSH_TO_QB_SUCCESS';
export const PUSH_TO_QB_ERROR = 'HRP_PAYROLL_REPORTS/PUSH_TO_QB_ERROR';
export const RESET_STATE = 'HRP_PAYROLL_REPORTS/RESET_STATE';
export const SET_ID = 'HRP_PAYROLL_REPORTS/SET_ID';
export const SET_SORT = 'HRP_PAYROLL_REPORTS/SET_SORT';
export const SET_ACTIVE_PAGE = 'HRP_PAYROLL_REPORTS/SET_ACTIVE_PAGE';
export const SET_ID_FOR_DELETE = 'HRP_PAYROLL_REPORTS/SET_ID_FOR_DELETE';
export const CHANGE_FILTER = 'HRP_PAYROLL_REPORTS/CHANGE_FILTER';
export const SET_FIELD = 'HRP_PAYROLL_REPORTS/SET_FIELD';
export const CLEAR_FORM = 'HRP_PAYROLL_REPORTS/CLEAR_FORM';
export const SET_FILE = 'HRP_PAYROLL_REPORTS/SET_FILE';