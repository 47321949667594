import React from 'react';
//import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';

const UncombineInvoiceModal = React.memo((props) => {
    const hideHandler = () => {
        props.hideUncombineInvoiceModal();
    };

    let label = 'Uncombine';
    if (props.isUncombining) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    const handleUncombineInvoice = () => {
        props.uncombineInvoice(props.history);
    };

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Cancel</button>,
        <button key="delete-button" className="btn btn-light btn-sm action-button" onClick={handleUncombineInvoice} disabled={props.isUncombining}>{label}</button>
    ];
    return (
        <Dialog
            name="uncombine-invoice-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="uncombine-invoice-dialog"
        >
            <div className="uncombine-invoice-container">
                <h1>Uncomnbine this invoice?</h1>
                <p className="warning">Warning: This action will remove this main invoice and re-enable its combined invoices.</p>
            </div>
        </Dialog >
    );
});

export default UncombineInvoiceModal;
