import { createSelector } from 'reselect';

export const getComponentState = state => state.users;

export const getElements = createSelector(
    getComponentState,
    (state) => state.elements
);
export const getFetchingElements = createSelector(
    getComponentState,
    (state) => state.fetchingElements
);
export const getIsLogging = createSelector(
    getComponentState,
    (state) => state.isLogging
);
export const getLoggingId = createSelector(
    getComponentState,
    (state) => state.loggingId
);
export const getFilter = createSelector(
    getComponentState,
    (state) => state.filter
);
export const getPagination = createSelector(
    getComponentState,
    (state) => state.pagination
);