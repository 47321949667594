import Immutable from 'immutable';
import * as types from './Chat.types';

const initialState = {
    chatId: null,
    chatTitle: '',
    messages: [],
    type: null,
    response: '',
    saving: false,
    elementId: null,
    usersIds: {
        id1: null,
        id2: null,
    },
    firstScrollDone: false,
    totalMessages: 0,
    totalLoadedMessages: 0,
    loadingMoreMessages: false,
    isLoading: false,
    isLoadingChats: false,
    isLoadingChatMembers: false,
    show: {
        icon: true,
        room: false,
        members: false,
    },
    chatList: {
        trackings: [],
        contacts: [],
        unread: [],
    },
    chatMembers: [],
    hasUnreadMessages: false,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.LOAD_CHATS:
            return state.setIn(['isLoadingChats'], !action.payload).toJS();

        case types.LOAD_CHATS_SUCCESS:
            return state.setIn(['isLoadingChats'], false)
                .setIn(['chatList', 'trackings'], action.payload.trackings)
                .setIn(['chatList', 'contacts'], action.payload.contacts)
                .setIn(['chatList', 'unread'], action.payload.unread)
                .toJS();

        case types.LOAD_CHATS_ERROR:
            return state.setIn(['isLoadingChats'], false).toJS();

        case types.SET_MESSAGES:
            return state.setIn(['messages'], action.payload).toJS();

        case types.ADD_MESSAGE:
            return state.updateIn(['messages'], messages => messages.push(action.payload))
                .setIn(['response'], '')
                .updateIn(['totalMessages'], totalMessages => totalMessages + 1)
                .updateIn(['totalLoadedMessages'], totalLoadedMessages => totalLoadedMessages + 1)
                .toJS();

        case types.ADD_MESSAGE_OBJECT:
            return state.updateIn(['messages'], (messages) => {
                const index = messages.findIndex((message) => parseInt(message.get('id')) === parseInt(action.payload.id));
                if (index === -1) {
                    return messages.push(action.payload);
                }
                return messages;
            }).toJS();

        case types.SET_RESPONSE:
            return state.setIn(['response'], action.payload).toJS();

        case types.SAVE_MESSAGE:
            return state.setIn(['saving'], true).toJS();

        case types.SAVE_MESSAGE_SUCCESS:
            return state.setIn(['saving'], false).toJS();

        case types.SAVE_MESSAGE_ERROR:
            return state.setIn(['saving'], false).toJS();

        case types.SET_ELEMENT_ID:
            return state.setIn(['elementId'], action.payload).toJS();

        case types.SET_USERS_IDS:
            return state.setIn(['usersIds', 'id1'], action.payload.id1)
                .setIn(['usersIds', 'id2'], action.payload.id2)
                .toJS();

        case types.SET_SHOW_FIELD:
            return state.setIn(['show', action.payload.field], action.payload.value).toJS();

        case types.SET_FIRST_SCROLL_DONE:
            return state.setIn(['firstScrollDone'], action.payload).toJS();

        case types.SET_TOTAL_MESSAGES:
            return state.setIn(['totalMessages'], action.payload).toJS();

        case types.SET_TOTAL_LOADED_MESSAGES:
            return state.setIn(['totalLoadedMessages'], action.payload).toJS();

        case types.LOAD_MORE_MESSAGES:
            return state.setIn(['loadingMoreMessages'], true).toJS();

        case types.LOAD_MORE_MESSAGES_SUCCESS:
            return state.setIn(['loadingMoreMessages'], false).toJS();

        case types.LOAD_MORE_MESSAGES_ERROR:
            return state.setIn(['loadingMoreMessages'], false).toJS();

        case types.LOAD_CHAT:
            return state.setIn(['isLoading'], true)
                .setIn(['messages'], [])
                .setIn(['totalMessages'], 0)
                .setIn(['totalLoadedMessages'], 0)
                .setIn(['chatId'], null)
                .toJS();

        case types.LOAD_CHAT_SUCCESS:
            return state.setIn(['isLoading'], false)
                .setIn(['messages'], action.payload.messages)
                .setIn(['totalMessages'], action.payload.total_messages)
                .setIn(['totalLoadedMessages'], action.payload.total_loaded_messages)
                .setIn(['chatId'], action.payload.chat_id)
                .toJS();

        case types.LOAD_CHAT_ERROR:
            return state.setIn(['isLoading'], false).toJS();

        case types.RESET_MESSAGES_COUNTERS:
            return state.setIn(['totalMessages'], 0)
                .setIn(['totalLoadedMessages'], 0).toJS();

        case types.SET_TYPE:
            return state.setIn(['type'], action.payload).toJS();

        case types.SET_CHAT_TITLE:
            return state.setIn(['chatTitle'], action.payload).toJS();

        case types.LOAD_CHAT_MEMBERS:
            return state.setIn(['isLoadingChatMembers'], true)
                .setIn(['chatMembers'], [])
                .toJS();

        case types.LOAD_CHAT_MEMBERS_SUCCESS:
            return state.setIn(['isLoadingChatMembers'], false)
                .setIn(['chatMembers'], action.payload)
                .toJS();

        case types.LOAD_CHAT_MEMBERS_ERROR:
            return state.setIn(['isLoadingChatMembers'], false).toJS();

        case types.CHECK_UNREAD_MESSAGES:
            return state.toJS();

        case types.CHECK_UNREAD_MESSAGES_SUCCESS:
            return state.setIn(['hasUnreadMessages'], action.payload).toJS();

        case types.CHECK_UNREAD_MESSAGES_ERROR:
            return state.toJS();

        case types.SET_CHAT_UNREAD_MESSAGES:
            return state.updateIn(['chatList'], chatList => {
                if (parseInt(action.payload.type) === 1) {
                    return chatList.updateIn(['trackings'], trackings => {
                        const index = trackings.findIndex(tracking => parseInt(tracking.get('id')) === parseInt(action.payload.id));
                        const tracking = trackings.get(index);
                        return trackings.set(index, tracking.setIn(['unread_messages'], 0));
                    });
                } else if (parseInt(action.payload.type) === 2) {
                    return chatList.updateIn(['contacts'], contacts => {
                        const index = contacts.findIndex(contact => parseInt(contact.get('id')) === parseInt(action.payload.id));
                        const contact = contacts.get(index);
                        return contacts.set(index, contact.setIn(['unread_messages'], 0));
                    });
                } else {
                    return chatList;
                }
            }).toJS();

        case types.SET_HAS_UNREAD_MESSAGES:
            return state.setIn(['hasUnreadMessages'], action.payload).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}