import Immutable from 'immutable';
import * as types from './Estimate.types';
import { calculateQuantity } from './Estimate.actions';

const initialPaperFields = {
    paper_name: '',
    vendor_id: '',
    paper_category_id: '',
    description: '',
    basis_weight: '',
    width: '',
    length: '',
    caliper: '',
    color: '',
    finish: '',
    grain_direction: '',
    m_weight: '',
    price_cwt: '',
    sc_digital: 1,
    sc_jpress: 1,
    sc_komori: 1,
};

const initialState = {
    id: null,
    fetchingEstimate: false,
    estimate: {
        type: 1,
        bleed: 0.25,
        cover_bleed: 0.25,
        binding_edge: 1,
        unit: 1,
        has_return_address: 0,
        has_back_flap: 0,
        has_address_area: 0,
        has_other: 0,
        full_outside_job: 0,
        use_tax: 0,
        publication_type: 1,
        selected_quantity: 1,
        use_crash_number: 0,
        material_coming_date: null,
        use_ganging: 1,
    },
    fetchingInformation: false,
    information: {
        productTypes: [],
        vendors: [],
        customers: [],
        salesReps: [],
        csrs: [],
        paperVendors: [],
        paperCategories: [],
        papers: [],
        substrates: [],
        laminates: [],
        accessories: [],
        envelopePapers: [],
    },
    history: null,
    saving: false,
    estimating: false,
    mailServicesTab: 1,
    dataProcessingItems: [],
    variablePrintItems: [],
    inkjetItems: [],
    insertingItems: [],
    meteringItems: [],
    handworkItems: [],
    selectedMailServices: [],
    additionalServices: [
        { id: 1, name: 'Folding', folding_type: '', checked: 0, position: 6, unit: '' },
        { id: 2, name: 'Scoring', checked: 0, position: 7, unit: '' },
        { id: 3, name: 'Drilling', drilling_holes: '', checked: 0, position: 8, unit: '' },
        { id: 4, name: 'Shrink-Wrapping', checked: 0, position: 9, unit: '', per_package: '' },
        { id: 5, name: 'Padding', sheets_per_pad: '', checked: 0, position: 10, unit: '' },
        { id: 6, name: 'Die-Cutting', checked: 0, position: 11, unit: '' },
        { id: 7, name: 'Plates', checked: 0, position: 3, unit: '' },
        { id: 8, name: 'Inks', checked: 0, position: 4, unit: '' },
        { id: 9, name: 'Cutting', checked: 0, position: 5, unit: '' },
        { id: 10, name: 'Laminate', checked: 0, position: 3, unit: '' },
        { id: 13, name: 'Press', checked: 0, position: 1, unit: ' pss' },
        { id: 14, name: 'Digital', checked: 0, position: 1, unit: ' pss' },
        { id: 15, name: 'Paper', checked: 0, position: 2, unit: ' pars' },
        { id: 16, name: 'Large Format', checked: 0, position: 1, unit: '' },
        { id: 17, name: 'Substrate', checked: 0, position: 2, unit: '' },
        { id: 18, name: 'Envelope Press', checked: 0, position: 1, unit: '' },
        { id: 19, name: 'Envelope Paper', checked: 0, position: 2, unit: '' },
        { id: 20, name: 'Saddle Stitching', checked: 0, position: 7, unit: '' },
        { id: 21, name: 'Boxing', checked: 0, position: 12, unit: '', per_package: '' },
        { id: 22, name: 'Perfect Binding', checked: 0, position: 7, unit: '' },
        { id: 23, name: 'Spiral Binding', checked: 0, position: 7, unit: '' },
        { id: 24, name: 'Wire Binding', checked: 0, position: 7, unit: '' },
        { id: 25, name: 'Staple Binding', checked: 0, position: 7, unit: '' },
        { id: 26, name: 'Perforating', checked: 0, position: 7, unit: '' },
        { id: 27, name: 'Business Cards Box', box: '', checked: 0, position: 12, unit: '' },
        { id: 28, name: 'Letterheads Box', box: '', checked: 0, position: 13, unit: '' },
        { id: 29, name: 'NCR Padding', box: '', checked: 0, position: 13, unit: '' },
        { id: 30, name: 'Pads Padding', box: '', checked: 0, position: 13, unit: '' },
    ],
    selectedAdditionalServices: [],
    selectedAccessories: [],
    estimateType: null,
    result1: null,
    result2: null,
    result3: null,
    showBreakdown: {
        waste: false,
        run: false,
        other: false,
    },
    impositionType: null,
    multiEstimating: false,
    multiResults: [],
    dataChanged: false,
    estimateChanged: false,
    approving: false,
    converting: false,
    fetchingSubstrateAccessories: false,
    fetchingSubstrates: false,
    sending: false,
    comments: '',
    files: [],
    sendingWrongEstimate: false,
    fetchingSubstrateLaminates: false,
    downloadingEstimate: false,
    newEstimateType: '',
    changingEstimateType: false,
    deliveryCharge: {
        description: '',
        quantity: '',
        total: '',
    },
    showDeliveryCharge: false,
    fetchingRfq: false,
    rfq: null,
    downloadingPressTicket: null,
    partEstimateType: '',
    mainPartId: null,
    mainPartType: null,
    mainPart: null,
    delete: {
        id: null,
        isDeleting: false,
    },
    minMarkup: 0,
    isDuplicatingPart: false,
    paperFields: initialPaperFields,
    isAddingPaper: false,
    outsideServices: [],
    isCheckingUsePart: false,
    newStatus: '',
    changingStatus: false,
    isExportingMatrix: false,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_ESTIMATE:
            return state.setIn(['fetchingEstimate'], true).toJS();

        case types.FETCH_ESTIMATE_SUCCESS:
            return state.setIn(['fetchingEstimate'], false)
                .setIn(['estimate'], action.payload)
                .setIn(['result1'], JSON.parse(action.payload.result))
                .setIn(['result2'], JSON.parse(action.payload.result2))
                .setIn(['result3'], JSON.parse(action.payload.result3))
                .setIn(['selectedAdditionalServices'], JSON.parse(action.payload.additional_services))
                .setIn(['selectedAccessories'], JSON.parse(action.payload.accessories))
                .setIn(['outsideServices'], JSON.parse(action.payload.outside_services))
                .setIn(['minMarkup'], action.payload.markup)
                .toJS();

        case types.FETCH_ESTIMATE_ERROR:
            return state.setIn(['fetchingEstimate'], false).toJS();

        case types.FETCH_INFORMATION:
            return state.setIn(['fetchingInformation'], true).toJS();

        case types.FETCH_INFORMATION_SUCCESS:
            return state.setIn(['fetchingInformation'], false)
                .setIn(['information', 'vendors'], action.payload.vendors)
                .setIn(['information', 'productTypes'], action.payload.productTypes)
                .setIn(['information', 'customers'], action.payload.customers)
                .setIn(['information', 'salesReps'], action.payload.salesReps)
                .setIn(['information', 'csrs'], action.payload.csrs)
                .setIn(['information', 'paperVendors'], action.payload.paperVendors)
                .setIn(['information', 'paperCategories'], action.payload.paperCategories)
                .setIn(['information', 'papers'], action.payload.papers)
                .setIn(['information', 'envelopePapers'], action.payload.envelopePapers)
                .setIn(['dataProcessingItems'], action.payload.dataProcessingElements)
                .setIn(['variablePrintItems'], action.payload.variablePrintElements)
                .setIn(['inkjetItems'], action.payload.inkjetElements)
                .setIn(['insertingItems'], action.payload.insertingElements)
                .setIn(['meteringItems'], action.payload.meteringElements)
                .setIn(['handworkItems'], action.payload.handworkElements)
                .toJS();

        case types.FETCH_INFORMATION_ERROR:
            return state.setIn(['fetchingInformation'], false).toJS();

        case types.SAVE_ESTIMATE:
            return state.setIn(['saving'], true).toJS();

        case types.SAVE_ESTIMATE_SUCCESS:
            return state.setIn(['saving'], false).toJS();

        case types.SAVE_ESTIMATE_ERROR:
            return state.setIn(['saving'], false).toJS();

        case types.CHANGE_ESTIMATE_TYPE:
            return state.setIn(['changingEstimateType'], true).toJS();

        case types.CHANGE_ESTIMATE_TYPE_SUCCESS:
            return state.setIn(['changingEstimateType'], false).toJS();

        case types.CHANGE_ESTIMATE_TYPE_ERROR:
            return state.setIn(['changingEstimateType'], false).toJS();

        case types.ESTIMATE:
            return state.setIn(['estimating'], true).toJS();

        case types.ESTIMATE_SUCCESS:
            return state.setIn(['estimating'], false)
                .setIn(['result1'], action.payload.results[0])
                .setIn(['result2'], action.payload.results[1])
                .setIn(['result3'], action.payload.results[2])
                .toJS();

        case types.ESTIMATE_ERROR:
            return state.setIn(['estimating'], false).toJS();

        case types.MULTI_ESTIMATE:
            return state.setIn(['multiEstimating'], true).toJS();

        case types.MULTI_ESTIMATE_SUCCESS:
            return state.setIn(['multiEstimating'], false).toJS();

        case types.MULTI_ESTIMATE_ERROR:
            return state.setIn(['multiEstimating'], false).toJS();

        case types.APPROVE_ESTIMATE:
            return state.setIn(['approving'], true).toJS();

        case types.APPROVE_ESTIMATE_SUCCESS:
            return state.setIn(['approving'], false).toJS();

        case types.APPROVE_ESTIMATE_ERROR:
            return state.setIn(['approving'], false).toJS();

        case types.CONVERT_TO_JOB:
            return state.setIn(['converting'], true).toJS();

        case types.CONVERT_TO_JOB_SUCCESS:
            return state.setIn(['converting'], false).toJS();

        case types.CONVERT_TO_JOB_ERROR:
            return state.setIn(['converting'], false).toJS();

        case types.CHANGE_STATUS:
            return state.setIn(['changingStatus'], true).toJS();

        case types.CHANGE_STATUS_SUCCESS:
            return state.setIn(['changingStatus'], false).toJS();

        case types.CHANGE_STATUS_ERROR:
            return state.setIn(['changingStatus'], false).toJS();

        case types.FETCH_SUBSTRATE_ACCESSORIES:
            return state.setIn(['information', 'accessories'], [])
                .setIn(['fetchingSubstrateAccessories'], true)
                .toJS();

        case types.FETCH_SUBSTRATE_ACCESSORIES_SUCCESS:
            return state.setIn(['information', 'accessories'], action.payload)
                .setIn(['fetchingSubstrateAccessories'], false)
                .toJS();

        case types.FETCH_SUBSTRATE_ACCESSORIES_ERROR:
            return state.setIn(['fetchingSubstrateAccessories'], false)
                .toJS();

        case types.FETCH_SUBSTRATES:
            return state.setIn(['fetchingSubstrates'], true)
                .setIn(['information', 'substrates'], [])
                .toJS();

        case types.FETCH_SUBSTRATES_SUCCESS:
            return state.setIn(['fetchingSubstrates'], false)
                .setIn(['information', 'substrates'], action.payload)
                .toJS();

        case types.FETCH_SUBSTRATES_ERROR:
            return state.setIn(['fetchingSubstrates'], false)
                .toJS();

        case types.FETCH_SUBSTRATE_LAMINATES:
            return state.setIn(['fetchingSubstrateLaminates'], true)
                .setIn(['information', 'laminates'], [])
                .toJS();

        case types.FETCH_SUBSTRATE_LAMINATES_SUCCESS:
            return state.setIn(['fetchingSubstrateLaminates'], false)
                .setIn(['information', 'laminates'], action.payload)
                .toJS();

        case types.FETCH_SUBSTRATE_LAMINATES_ERROR:
            return state.setIn(['fetchingSubstrateLaminates'], false)
                .toJS();

        case types.SEND_ESTIMATE:
            return state.setIn(['sending'], true).toJS();

        case types.SEND_ESTIMATE_SUCCESS:
            return state.setIn(['sending'], false).toJS();

        case types.SEND_ESTIMATE_ERROR:
            return state.setIn(['sending'], false).toJS();

        case types.SEND_WRONG_ESTIMATE:
            return state.setIn(['sendingWrongEstimate'], true).toJS();

        case types.SEND_WRONG_ESTIMATE_SUCCESS:
            return state.setIn(['files'], [])
                .setIn(['comments'], '')
                .setIn(['sendingWrongEstimate'], false)
                .toJS();

        case types.SEND_WRONG_ESTIMATE_ERROR:
            return state.setIn(['sendingWrongEstimate'], false).toJS();

        case types.DOWNLOAD_ESTIMATE:
            return state.setIn(['downloadingEstimate'], true).toJS();

        case types.DOWNLOAD_ESTIMATE_SUCCESS:
            return state.setIn(['downloadingEstimate'], false).toJS();

        case types.DOWNLOAD_ESTIMATE_ERROR:
            return state.setIn(['downloadingEstimate'], false).toJS();

        case types.DOWNLOAD_PRESS_TICKET:
            return state.setIn(['downloadingPressTicket'], true).toJS();

        case types.DOWNLOAD_PRESS_TICKET_SUCCESS:
            return state.setIn(['downloadingPressTicket'], false).toJS();

        case types.DOWNLOAD_PRESS_TICKET_ERROR:
            return state.setIn(['downloadingPressTicket'], false).toJS();

        case types.FETCH_MAIN_PART:
            return state.setIn(['fetchingMainPart'], true).toJS();

        case types.FETCH_MAIN_PART_SUCCESS:
            return state.setIn(['fetchingMainPart'], false)
                .setIn(['mainPart'], action.payload)
                .toJS();

        case types.FETCH_MAIN_PART_ERROR:
            return state.setIn(['fetchingMainPart'], false).toJS();

        case types.DELETE_ESTIMATE:
            return state.setIn(['delete', 'isDeleting'], true).toJS();

        case types.DELETE_ESTIMATE_SUCCESS:
            return state.setIn(['delete', 'isDeleting'], false)
                .setIn(['delete', 'id'], null)
                .toJS();

        case types.DELETE_ESTIMATE_ERROR:
            return state.setIn(['delete', 'isDeleting'], false).toJS();

        case types.DUPLICATE_PART:
            return state.setIn(['isDuplicatingPart'], true).toJS();

        case types.DUPLICATE_PART_SUCCESS:
            return state.setIn(['isDuplicatingPart'], false).toJS();

        case types.DUPLICATE_PART_ERROR:
            return state.setIn(['isDuplicatingPart'], false).toJS();

        case types.ADD_PAPER:
            return state.setIn(['isAddingPaper'], true).toJS();

        case types.ADD_PAPER_SUCCESS:
            return state.setIn(['isAddingPaper'], false)
                .setIn(['paperFields'], initialPaperFields)
                .toJS();

        case types.ADD_PAPER_ERROR:
            return state.setIn(['isAddingPaper'], false).toJS();

        case types.CHECK_USE_PART:
            return state.setIn(['isCheckingUsePart'], true)
                .setIn(['estimate', 'use_part'], action.payload)
                .toJS();

        case types.CHECK_USE_PART_SUCCESS:
            return state.setIn(['isCheckingUsePart'], false).toJS();

        case types.CHECK_USE_PART_ERROR:
            return state.setIn(['isCheckingUsePart'], false).toJS();

        case types.FETCH_RFQ:
            return state.setIn(['fetchingRfq'], true).toJS();

        case types.FETCH_RFQ_SUCCESS:
            return state.setIn(['fetchingRfq'], false)
                .setIn(['rfq'], action.payload)
                .toJS();

        case types.FETCH_RFQ_ERROR:
            return state.setIn(['fetchingRfq'], false).toJS();

        case types.EXPORT_MATRIX:
            return state.setIn(['isExportingMatrix'], true).toJS();

        case types.EXPORT_MATRIX_SUCCESS:
            return state.setIn(['isExportingMatrix'], false).toJS();

        case types.EXPORT_MATRIX_ERROR:
            return state.setIn(['isExportingMatrix'], false).toJS();

        case types.SET_FIELD:
            return state.setIn(['estimate', action.payload.name], action.payload.value).toJS();

        case types.SET_ID:
            return state.setIn(['id'], action.payload).toJS();

        case types.SET_HISTORY:
            return state.setIn(['history'], action.payload).toJS();

        case types.SET_MAIL_SERVICES_TAB:
            return state.setIn(['mailServicesTab'], action.payload).toJS();

        case types.ADD_MAIL_SERVICES_ITEMS:
            return state.setIn(['selectedMailServices'], action.payload).toJS();

        case types.DELETE_MAIL_SERVICES_ITEM:
            return state.updateIn(['selectedMailServices'], (selectedMailServices) => {
                const index = selectedMailServices.findIndex((item) => parseInt(item.get('id')) === parseInt(action.payload.id) && parseInt(item.get('type')) === parseInt(action.payload.type));
                return selectedMailServices.delete(index);
            }).updateIn(['dataProcessingItems'], (dataProcessingItems) => {
                const items = dataProcessingItems.toJS();
                if (parseInt(action.payload.type) !== 1) {
                    return items;
                }

                const index = items.findIndex((item) => parseInt(item.id) === parseInt(action.payload.id));
                items[index].checked = 0;
                return items;
            }).updateIn(['variablePrintItems'], (variablePrintItems) => {
                const items = variablePrintItems.toJS();
                if (parseInt(action.payload.type) !== 2) {
                    return items;
                }

                const index = items.findIndex((item) => parseInt(item.id) === parseInt(action.payload.id));
                items[index].checked = 0;
                return items;
            }).updateIn(['inkjetItems'], (inkjetItems) => {
                const items = inkjetItems.toJS();
                if (parseInt(action.payload.type) !== 3) {
                    return items;
                }

                const index = items.findIndex((item) => parseInt(item.id) === parseInt(action.payload.id));
                items[index].checked = 0;
                return items;
            }).updateIn(['insertingItems'], (insertingItems) => {
                const items = insertingItems.toJS();
                if (parseInt(action.payload.type) !== 4) {
                    return items;
                }

                const index = items.findIndex((item) => parseInt(item.id) === parseInt(action.payload.id));
                items[index].checked = 0;
                return items;
            }).updateIn(['meteringItems'], (meteringItems) => {
                const items = meteringItems.toJS();
                if (parseInt(action.payload.type) !== 5) {
                    return items;
                }

                const index = items.findIndex((item) => parseInt(item.id) === parseInt(action.payload.id));
                items[index].checked = 0;
                return items;
            }).updateIn(['handworkItems'], (handworkItems) => {
                const items = handworkItems.toJS();
                if (parseInt(action.payload.type) !== 6) {
                    return items;
                }

                const index = items.findIndex((item) => parseInt(item.id) === parseInt(action.payload.id));
                items[index].checked = 0;
                return items;
            }).toJS();

        case types.SET_DATA_PROCESSING_ITEM:
            return state.updateIn(['dataProcessingItems'], (dataProcessingItems) => {
                const items = dataProcessingItems.toJS();
                const index = items.findIndex((item) => parseInt(item.id) === parseInt(action.payload.id));
                items[index][action.payload.field] = action.payload.value;
                return items;
            }).toJS();

        case types.SET_VARIABLE_PRINT_ITEM:
            return state.updateIn(['variablePrintItems'], (variablePrintItems) => {
                const items = variablePrintItems.toJS();
                const index = items.findIndex((item) => parseInt(item.id) === parseInt(action.payload.id));
                items[index][action.payload.field] = action.payload.value;
                return items;
            }).toJS();

        case types.SET_INKJET_ITEM:
            return state.updateIn(['inkjetItems'], (inkjetItems) => {
                const items = inkjetItems.toJS();
                const index = items.findIndex((item) => parseInt(item.id) === parseInt(action.payload.id));
                items[index][action.payload.field] = action.payload.value;
                return items;
            }).toJS();

        case types.SET_INSERTING_ITEM:
            return state.updateIn(['insertingItems'], (insertingItems) => {
                const items = insertingItems.toJS();
                const index = items.findIndex((item) => parseInt(item.id) === parseInt(action.payload.id));
                items[index][action.payload.field] = action.payload.value;
                return items;
            }).toJS();

        case types.SET_METERING_ITEM:
            return state.updateIn(['meteringItems'], (meteringItems) => {
                const items = meteringItems.toJS();
                const index = items.findIndex((item) => parseInt(item.id) === parseInt(action.payload.id));
                items[index][action.payload.field] = action.payload.value;
                return items;
            }).toJS();

        case types.SET_HANDWORK_ITEM:
            return state.updateIn(['handworkItems'], (handworkItems) => {
                const items = handworkItems.toJS();
                const index = items.findIndex((item) => parseInt(item.id) === parseInt(action.payload.id));
                items[index][action.payload.field] = action.payload.value;
                return items;
            }).toJS();

        case types.SET_MAIL_SERVICES_QUANTITIES:
            return state.updateIn(['dataProcessingItems'], (dataProcessingItems) => {
                const items = dataProcessingItems.toJS();
                for (let i = 0; i < items.length; i++) {
                    if (!items[i].quantity || items[i].quantity == null || items[i].quantity === '') {
                        items[i].quantity = calculateQuantity(items[i], action.payload);
                    }
                }
                return items;
            }).updateIn(['variablePrintItems'], (variablePrintItems) => {
                const items = variablePrintItems.toJS();
                for (let i = 0; i < items.length; i++) {
                    items[i].quantity = calculateQuantity(items[i], action.payload);
                }
                return items;
            }).updateIn(['inkjetItems'], (inkjetItems) => {
                const items = inkjetItems.toJS();
                for (let i = 0; i < items.length; i++) {
                    items[i].quantity = calculateQuantity(items[i], action.payload);
                }
                return items;
            }).updateIn(['insertingItems'], (insertingItems) => {
                const items = insertingItems.toJS();
                for (let i = 0; i < items.length; i++) {
                    items[i].quantity = calculateQuantity(items[i], action.payload);
                }
                return items;
            }).updateIn(['meteringItems'], (meteringItems) => {
                const items = meteringItems.toJS();
                for (let i = 0; i < items.length; i++) {
                    items[i].quantity = calculateQuantity(items[i], action.payload);
                }
                return items;
            }).updateIn(['handworkItems'], (handworkItems) => {
                const items = handworkItems.toJS();
                for (let i = 0; i < items.length; i++) {
                    items[i].quantity = calculateQuantity(items[i], action.payload);
                }
                return items;
            }).toJS();

        case types.SET_ESTIMATE_TYPE:
            return state.setIn(['estimateType'], action.payload).toJS();

        case types.SET_SHOW_BREAKDOWN:
            return state.setIn(['showBreakdown', action.payload.name], action.payload.value).toJS();

        case types.SET_ADDITIONAL_SERVICE:
            return state.updateIn(['additionalServices'], (additionalServices) => {
                const items = additionalServices.toJS();
                const index = items.findIndex((item) => parseInt(item.id) === parseInt(action.payload.id));
                items[index][action.payload.field] = action.payload.value;
                return items;
            }).toJS();

        case types.ADD_ADDITIONAL_SERVICES:
            return state.setIn(['selectedAdditionalServices'], action.payload).toJS();

        case types.ADD_ADDITIONAL_SERVICE:
            return state.updateIn(['selectedAdditionalServices'], (selectedAdditionalServices) => {
                return selectedAdditionalServices.push(action.payload);
            }).toJS();

        case types.UPDATE_ADDITIONAL_SERVICE:
            return state.updateIn(['selectedAdditionalServices'], (selectedAdditionalServices) => {
                const items = selectedAdditionalServices.toJS();
                const index = items.findIndex((item) => parseInt(item.id) === parseInt(action.payload.id));
                if (index === -1) {
                    return items;
                }

                items[index][action.payload.field] = action.payload.value;
                return items;
            }).toJS();

        case types.DELETE_ADDITIONAL_SERVICES_ITEM:
            return state.updateIn(['selectedAdditionalServices'], (selectedAdditionalServices) => {
                const index = selectedAdditionalServices.findIndex((item) => parseInt(item.get('id')) === parseInt(action.payload));
                if (index === -1) {
                    return selectedAdditionalServices;
                }

                return selectedAdditionalServices.delete(index);
            }).updateIn(['additionalServices'], (additionalServices) => {
                const items = additionalServices.toJS();
                const index = items.findIndex((item) => parseInt(item.id) === parseInt(action.payload));
                if (index === -1) {
                    return items;
                }

                items[index].checked = 0;
                return items;
            }).toJS();

        case types.RESET_RESULT:
            return state.setIn(['estimate', 'price'], '')
                .setIn(['estimate', 'weight'], '')
                .toJS();

        case types.SET_IMPOSITION_TYPE:
            return state.setIn(['impositionType'], action.payload).toJS();

        case types.SET_MULTI_RESULTS:
            return state.setIn(['multiResults'], action.payload).toJS();

        case types.SET_DATA_CHANGED:
            return state.setIn(['dataChanged'], action.payload).toJS();

        case types.SET_ESTIMATE_CHANGED:
            return state.setIn(['estimateChanged'], action.payload).toJS();

        case types.CHECK_ACCESSORY:
            return state.setIn(['dataChanged'], true)
                .setIn(['estimateChanged'], true)
                .updateIn(['selectedAccessories'], (selectedAccessories) => {
                    const ids = selectedAccessories.toJS();
                    const index = ids.findIndex((id) => parseInt(id) === parseInt(action.payload));
                    if (index === -1) {
                        ids.push(action.payload);
                    } else {
                        ids.splice(index, 1);
                    }
                    return ids;
                }).toJS();

        case types.RESET_ACCESSORIES:
            return state.updateIn(['selectedAccessories'], (selectedAccessories) => {
                return [];
            }).toJS();

        case types.ADD_FILE:
            return state.updateIn(['files'], (files) => {
                return files.push(action.payload);
            }).toJS();

        case types.REMOVE_FILE:
            return state.updateIn(['files'], (files) => {
                return files.delete(action.payload);
            }).toJS();

        case types.CLEAR_FILES:
            return state.updateIn(['files'], (files) => {
                return files.clear();
            }).toJS();

        case types.SET_COMMENTS:
            return state.setIn(['comments'], action.payload).toJS();

        case types.SET_NEW_ESTIMATE_TYPE:
            return state.setIn(['newEstimateType'], action.payload).toJS();

        case types.SET_NEW_STATUS:
            return state.setIn(['newStatus'], action.payload).toJS();

        case types.SET_DELIVERY_CHARGE_FIELD:
            return state.setIn(['deliveryCharge', action.payload.field], action.payload.value).toJS();

        case types.SET_SHOW_DELIVERY_CHARGE:
            return state.setIn(['showDeliveryCharge'], action.payload).toJS();

        case types.SET_PART_ESTIMATE_TYPE:
            return state.setIn(['partEstimateType'], action.payload).toJS();

        case types.SET_MAIN_PART_ID:
            return state.setIn(['mainPartId'], action.payload).toJS();

        case types.SET_MAIN_PART_TYPE:
            return state.setIn(['mainPartType'], action.payload).toJS();

        case types.SET_ID_FOR_DELETE:
            return state.setIn(['delete', 'id'], action.payload).toJS();

        case types.SET_PAPER_FIELD:
            return state.setIn(['paperFields', action.payload.field], action.payload.value).toJS();

        case types.ADD_OUTSIDE_SERVICE:
            return state.updateIn(['outsideServices'], (outsideServices) => {
                return outsideServices.push({
                    outside_vendor_id: '',
                    outside_quote_number: '',
                    outside_description: '',
                    outside_cost: '',
                    outside_markup: '',
                    outside_commission: '',
                });
            }).toJS();

        case types.SET_OUTSIDE_SERVICE_FIELD:
            return state.updateIn(['outsideServices'], (outsideServices) => {
                const items = outsideServices.toJS();
                items[action.payload.index][action.payload.field] = action.payload.value;
                return items;
            }).toJS();

        case types.DELETE_OUTSIDE_SERVICE:
            return state.updateIn(['outsideServices'], (outsideServices) => {
                return outsideServices.delete(action.payload);
            }).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}