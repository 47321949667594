export const FETCH_MATRIXES = 'MAILING_MATRIXES/FETCH_MATRIXES';
export const FETCH_MATRIXES_SUCCESS = 'MAILING_MATRIXES/FETCH_MATRIXES_SUCCESS';
export const FETCH_MATRIXES_ERROR = 'MAILING_MATRIXES/FETCH_MATRIXES_ERROR';
export const FETCH_SUBCATEGORY = 'MAILING_MATRIXES/FETCH_SUBCATEGORY';
export const FETCH_SUBCATEGORY_SUCCESS = 'MAILING_MATRIXES/FETCH_SUBCATEGORY_SUCCESS';
export const FETCH_SUBCATEGORY_ERROR = 'MAILING_MATRIXES/FETCH_SUBCATEGORY_ERROR';
export const SAVE_MATRIX = 'MAILING_MATRIXES/SAVE_MATRIX';
export const SAVE_MATRIX_SUCCESS = 'MAILING_MATRIXES/SAVE_MATRIX_SUCCESS';
export const SAVE_MATRIX_ERROR = 'MAILING_MATRIXES/SAVE_MATRIX_ERROR';
export const DELETE_MATRIX = 'MAILING_MATRIXES/DELETE_MATRIX';
export const DELETE_MATRIX_SUCCESS = 'MAILING_MATRIXES/DELETE_MATRIX_SUCCESS';
export const DELETE_MATRIX_ERROR = 'MAILING_MATRIXES/DELETE_MATRIX_ERROR';
export const SET_MATRIX = 'MAILING_MATRIXES/SET_MATRIX';
export const SET_ID = 'MAILING_MATRIXES/SET_ID';
export const SET_SUBCATEGORY_ID = 'MAILING_MATRIXES/SET_SUBCATEGORY_ID';
export const SET_SORT = 'MAILING_MATRIXES/SET_SORT';
export const SET_ACTIVE_PAGE = 'MAILING_MATRIXES/SET_ACTIVE_PAGE';
export const SET_ID_FOR_DELETE = 'MAILING_MATRIXES/SET_ID_FOR_DELETE';
export const CHANGE_FILTER = 'MAILING_MATRIXES/CHANGE_FILTER';
export const SET_FIELD = 'MAILING_MATRIXES/SET_FIELD';
export const ADD_MATRIX_ROW = 'MAILING_MATRIXES/ADD_MATRIX_ROW';
export const REMOVE_MATRIX_ROW = 'MAILING_MATRIXES/REMOVE_MATRIX_ROW';
export const SET_MATRIX_FIELD = 'MAILING_MATRIXES/SET_MATRIX_FIELD';
export const SET_MATRIX_DATA = 'MAILING_MATRIXES/SET_MATRIX_DATA';
export const RESET_MATRIX = 'MAILING_MATRIXES/RESET_MATRIX';
export const RESET_STATE = 'MAILING_MATRIXES/RESET_STATE';