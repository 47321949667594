import axios from 'axios';
import * as types from './CommissionCompensations.types';
import * as selectors from './CommissionCompensations.selectors';
import * as dialog from '../common/dialog';
import { validateAuthInResponse, showDataDeletedNotification, showNotification, empty } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchCompensations = () => (dispatch, getState) => {
    const sort = selectors.getSort(getState());
    const pagination = selectors.getPagination(getState());
    const filerFields = selectors.getFilterFields(getState());

    let params = [];
    params.push('sort=' + (sort.type === 'asc' ? '' : '-') + sort.column);
    params.push('active-page=' + pagination.activePage);
    params.push('elements-per-page=' + pagination.elementsPerPage);

    if (filerFields.length > 0) {
        let filterElements = [];
        for (let i = 0; i < filerFields.length; i++) {
            filterElements.push(filerFields[i]['name'] + '=' + filerFields[i]['value']);
        }
        params.push('filter=' + filterElements.join(','))
    } else {
        params.push('filter=-')
    }

    dispatch({ type: types.FETCH_COMPENSATIONS, payload: null });
    axios.get(apiUrl + '/commission-compensation/admin-index' + (params.length === 0 ? '' : '?' + params.join('&')),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_COMPENSATIONS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_COMPENSATIONS_ERROR));
        });
}

export const fetchCompensation = (id) => (dispatch, getState) => {
    dispatch({ type: types.FETCH_COMPENSATION, payload: null });
    axios.get(apiUrl + '/commission-compensations/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_COMPENSATION_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_COMPENSATION_ERROR));
        });
}

export const saveCompensation = () => (dispatch, getState) => {
    const fields = selectors.getFields(getState());
    if (!validateForm(fields)) {
        return;
    }

    let endpoint = apiUrl + '/commission-compensations';
    let method = 'post';
    if (fields.id && fields.id != null) {
        endpoint = apiUrl + '/commission-compensations/' + fields.id;
        method = 'put';
    }

    dispatch({ type: types.SAVE_COMPENSATION, payload: null });
    axios({
        method: method,
        url: endpoint,
        data: fields,
        headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
    }).then((response) => {
        dispatch({ type: types.SAVE_COMPENSATION_SUCCESS, payload: response.data });
        showNotification('Compensation Saved', 'The Compensation has been saved successfully', 'success');
        dispatch(fetchCompensations());
        dispatch(dialog.actions.hideDialog('compensation-form-modal'));
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.SAVE_COMPENSATION_ERROR));
    });
}

export const validateForm = (fields) => {
    if (empty(fields['user_id'])) {
        showNotification('Complete Information', 'Please select a person', 'info');
        return false;
    }
    if (empty(fields['zero_ninety'])) {
        showNotification('Complete Information', 'Please enter the 0-90 value', 'info');
        return false;
    }
    if (empty(fields['ninetyone_onetwenty'])) {
        showNotification('Complete Information', 'Please enter the 91-120 value', 'info');
        return false;
    }
    if (empty(fields['payroll'])) {
        showNotification('Complete Information', 'Please select a Type', 'info');
        return false;
    }
    if (empty(fields['draw'])) {
        showNotification('Complete Information', 'Please enter the compensation type', 'info');
        return false;
    }

    return true;
}

export const fetchPersons = () => (dispatch, getState) => {
    dispatch({ type: types.FETCH_PERSONS, payload: null });
    axios.get(apiUrl + '/commission-compensation/persons',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_PERSONS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_PERSONS_ERROR));
        });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null
    });
}

export const deleteCompensation = (id, comments) => (dispatch) => {
    dispatch({ type: types.DELETE_COMPENSATION, payload: null });
    axios.delete(apiUrl + '/commission-compensations/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }, data: { comments } }
    ).then(() => {
        dispatch({ type: types.DELETE_COMPENSATION_SUCCESS, payload: null });
        showDataDeletedNotification();
        dispatch(fetchCompensations());
        dispatch(dialog.actions.hideDialog('delete-modal'));
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.DELETE_COMPENSATION_ERROR));
    });
}

export const setSort = (column, type) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_SORT,
            payload: {
                column: column,
                type: type,
            }
        });
        resolve();
    }).then(() => {
        dispatch(fetchCompensations());
    });
}

export const setActivePage = (value) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_ACTIVE_PAGE,
            payload: value
        });
        resolve();
    }).then(() => {
        dispatch(fetchCompensations());
    });
}

export const mountComponent = () => (dispatch, getState) => {
    new Promise((resolve) => {
        resolve();
    }).then(() => {
        dispatch(fetchCompensations());
    });
}

export const setIdForDelete = (id) => (dispatch) => {
    dispatch({
        type: types.SET_ID_FOR_DELETE,
        payload: id
    });
}

export const setField = (field, value) => (dispatch) => {
    dispatch({
        type: types.SET_FIELD,
        payload: { field, value }
    });
}

export const changeFilter = (name, value, fetch = false) => (dispatch) => {
    new Promise((resolve) => {
        dispatch({
            type: types.CHANGE_FILTER,
            payload: { name, value }
        });
        resolve();
    }).then(() => {
        if (fetch) {
            dispatch(fetchCompensations());
        }
    });
}

export const clearForm = () => (dispatch) => {
    dispatch({
        type: types.CLEAR_FORM,
        payload: null,
    });
}