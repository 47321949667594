import React from 'react';
import { Dialog } from './../../common/dialog';
import Switch from "react-switch";
import FormPopover from './../Form/FormPopover';

const HandworkModal = React.memo((props) => {
    const handleHandworkSwitchChange = (id, field, checked) => {
        props.setHandworkItem(id, field, checked ? 1 : 0);
    };

    const renderHandworkItems = () => {
        return props.handworkItems.map((item) => {
            return (
                <tr>
                    <td>{item.name}</td>
                    <td className="switch-column">
                        <Switch
                            onChange={handleHandworkSwitchChange.bind(this, item.id, 'checked')}
                            checked={item.checked}
                            width={42}
                            height={22}
                        />
                    </td>
                    <td className="action-column">
                        <FormPopover
                            code={item.code_ref}
                            information={props.information}
                        />
                    </td>
                </tr>
            );
        });
    };

    const handleAddButton = () => {
        props.addHandworkItems();
        props.hideHandworkModal();
    };

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={props.hideHandworkModal}>Cancel</button>,
        <button key="delete-button" className="btn btn-light btn-sm action-button" onClick={handleAddButton}>Add</button>,
    ];
    return (
        <Dialog
            name="handwork-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="handwork-dialog"
        >
            <div className="handwork-container">
                <h1>Handwork Items</h1>
                <div className="items-container">
                    <div className="tabs">
                        <div className="tab active">
                            Handwork
                        </div>
                    </div>
                    <div className="items">
                        <table className="table table-sm table-hover table-striped">
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Yes/No</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderHandworkItems()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Dialog >
    );
});

export default HandworkModal;
