import axios from 'axios';
import * as types from './ApItems.types';
import * as selectors from './ApItems.selectors';
import * as dialog from '../common/dialog';
import { validateAuthInResponse, showDataDeletedNotification, showNotification, empty } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchItems = () => (dispatch, getState) => {
    const type = selectors.getType(getState());
    const recordId = selectors.getRecordId(getState());
    const sort = selectors.getSort(getState());
    const pagination = selectors.getPagination(getState());
    const filerFields = selectors.getFilterFields(getState());

    let params = [];
    params.push('record-id=' + recordId);
    params.push('type=' + type);
    params.push('sort=' + (sort.type === 'asc' ? '' : '-') + sort.column);
    params.push('active-page=' + pagination.activePage);
    params.push('elements-per-page=' + pagination.elementsPerPage);

    if (filerFields.length > 0) {
        let filterElements = [];
        for (let i = 0; i < filerFields.length; i++) {
            filterElements.push(filerFields[i]['name'] + '=' + filerFields[i]['value']);
        }
        params.push('filter=' + filterElements.join(','))
    } else {
        params.push('filter=-')
    }

    dispatch({ type: types.FETCH_ITEMS, payload: null });
    axios.get(apiUrl + '/ap-items' + (params.length === 0 ? '' : '?' + params.join('&')),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_ITEMS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_ITEMS_ERROR));
        });
}

export const fetchItem = (id) => (dispatch, getState) => {
    dispatch({ type: types.FETCH_ITEM, payload: null });
    axios.get(apiUrl + '/ap-items/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_ITEM_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_ITEM_ERROR));
        });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null
    });
}

export const deleteItem = (id, comments) => (dispatch) => {
    dispatch({ type: types.DELETE_ITEM, payload: null });
    axios.delete(apiUrl + '/ap-items/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }, data: { comments } }
    ).then(() => {
        dispatch({ type: types.DELETE_ITEM_SUCCESS, payload: null });
        showDataDeletedNotification();
        dispatch(fetchItems());
        dispatch(dialog.actions.hideDialog('delete-modal'));
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.DELETE_ITEM_ERROR));
    });
}

export const uploadFile = () => (dispatch, getState) => {
    const type = selectors.getType(getState());
    const name = selectors.getName(getState());
    const postedDate = selectors.getPostedDate(getState());
    const file = selectors.getFile(getState());

    if (empty(name)) {
        return showNotification('Complete information', 'Enter a Name', 'info');
    }
    if (empty(postedDate)) {
        return showNotification('Complete information', 'Enter a Posted Date', 'info');
    }
    if (!file || file == null) {
        return showNotification('Complete information', 'Select a File to upload', 'info');
    }

    let data = new FormData();
    data.append('file', file);
    data.append('type', type);

    dispatch({ type: types.UPLOAD_FILE, payload: null });
    axios.post(apiUrl + '/ap-record/process-file', data,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.UPLOAD_FILE_SUCCESS, payload: response.data });
            showNotification('Success', 'The file has been uploaded successfully', 'success');
        })
        .catch((error) => {
            if (error.response.status === 400) {
                console.log(error.response.data.error.message)
                showNotification('Wrong File Format', error.response.data.error.message, 'warning');
            } else {
                dispatch(validateAuthInResponse(error, types.UPLOAD_FILE_ERROR));
            }
        });
}

export const saveItem = () => (dispatch, getState) => {
    const type = selectors.getType(getState());
    const recordId = selectors.getRecordId(getState());
    const fields = selectors.getFields(getState());

    let method = 'post';
    let endpoint = apiUrl + '/ap-items';
    if (!empty(fields.id)) {
        method = 'put';
        endpoint = apiUrl + '/ap-items/' + fields.id;
    }

    dispatch({ type: types.SAVE_ITEM, payload: null });
    axios({
        method: method,
        url: endpoint,
        data: { ...fields, type, recordId },
        headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
    }).then((response) => {
        dispatch({ type: types.SAVE_ITEM_SUCCESS, payload: response.data });
        showNotification('Item Saved', 'The item has been saved successfully', 'success');
        dispatch(fetchItems());
        dispatch(dialog.actions.hideDialog('ap-form-modal'));
        dispatch(clearForm());
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.SAVE_ITEM_ERROR));
    });
}

export const saveItems = () => (dispatch, getState) => {
    const type = selectors.getType(getState());
    const name = selectors.getName(getState());
    const postedDate = selectors.getPostedDate(getState());
    const processedData = selectors.getProcessedData(getState());
    const history = selectors.getHistory(getState());

    if (empty(name)) {
        return showNotification('Complete information', 'Enter a Name', 'info');
    }
    if (empty(postedDate)) {
        return showNotification('Complete information', 'Enter a Posted Date', 'info');
    }

    dispatch({ type: types.SAVE_ITEMS, payload: null });
    axios.post(apiUrl + '/ap-record/save-items', { type, name, postedDate, processedData },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.SAVE_ITEMS_SUCCESS, payload: response.data });
            showNotification('Success', 'The information has been saved successfully', 'success');

            if (parseInt(type) === 1) {
                history.push('/ap-record/american-express/' + response.data.id);
            } else if (parseInt(type) === 2) {
                history.push('/ap-record/capital-one-promovana/' + response.data.id);
            } else if (parseInt(type) === 3) {
                history.push('/ap-record/capital-one-paper/' + response.data.id);
            }
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.UPDATE_FIELD_ERROR));
        });
}

export const updateField = (id, data) => (dispatch, getState) => {
    dispatch({ type: types.UPDATE_FIELD, payload: null });
    axios.put(apiUrl + '/ap-item/' + id, data,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.UPDATE_FIELD_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.UPDATE_FIELD_ERROR));
        });
}

export const pushToQb = () => (dispatch, getState) => {
    const recordId = selectors.getRecordId(getState());

    dispatch({ type: types.PUSH_TO_QB, payload: null });
    axios.post(apiUrl + '/ap-record/push-to-qb', { id: recordId },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.PUSH_TO_QB_SUCCESS, payload: response.data });

            if (response.data.success) {
                showNotification('Success', 'The Items has been pushed to QB successfully', 'success');
                dispatch(dialog.actions.hideDialog('push-modal'));
                dispatch(fetchRecord());
                dispatch(fetchItems());
            } else {
                showNotification('Info', response.data.errorMessage, 'info');
            }
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.UPDATE_FIELD_ERROR));
        });
}

export const fetchInformation = () => (dispatch, getState) => {
    dispatch({ type: types.FETCH_INFORMATION, payload: null });
    axios.get(apiUrl + '/ap-record/information',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_INFORMATION_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_INFORMATION_ERROR));
        });
}

export const fetchRecord = () => (dispatch, getState) => {
    const recordId = selectors.getRecordId(getState());
    dispatch({ type: types.FETCH_RECORD, payload: null });
    axios.get(apiUrl + '/ap-record/' + recordId,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_RECORD_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_RECORD_ERROR));
        });
}

export const setSort = (column, type) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_SORT,
            payload: {
                column: column,
                type: type,
            }
        });
        resolve();
    }).then(() => {
        dispatch(fetchItems());
    });
}

export const setActivePage = (value) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_ACTIVE_PAGE,
            payload: value
        });
        resolve();
    }).then(() => {
        dispatch(fetchItems());
    });
}

export const setIdForDelete = (id) => (dispatch) => {
    dispatch({
        type: types.SET_ID_FOR_DELETE,
        payload: id
    });
}

export const setField = (field, value) => (dispatch) => {
    dispatch({
        type: types.SET_FIELD,
        payload: { field, value }
    });
}

export const changeFilter = (name, value, fetch = false) => (dispatch) => {
    new Promise((resolve) => {
        dispatch({
            type: types.CHANGE_FILTER,
            payload: { name, value }
        });
        resolve();
    }).then(() => {
        if (fetch) {
            dispatch(fetchItems());
        }
    });
}

export const clearForm = () => (dispatch) => {
    dispatch({
        type: types.CLEAR_FORM,
        payload: null,
    });
}

export const setFile = (file) => (dispatch) => {
    dispatch({
        type: types.SET_FILE,
        payload: file,
    });
}

export const setType = (value) => (dispatch) => {
    dispatch({
        type: types.SET_TYPE,
        payload: value,
    });
}

export const setProcessedDataField = (index, field, value) => (dispatch) => {
    dispatch({
        type: types.SET_PROCESSED_DATA_FIELD,
        payload: { index, field, value },
    });
}

export const setName = (value) => (dispatch) => {
    dispatch({
        type: types.SET_NAME,
        payload: value,
    });
}

export const setPostedDate = (value) => (dispatch) => {
    dispatch({
        type: types.SET_POSTED_DATE,
        payload: value,
    });
}

export const setRecordId = (value) => (dispatch) => {
    dispatch({
        type: types.SET_RECORD_ID,
        payload: value,
    });
}

export const updateFieldLocal = (index, field, value) => (dispatch) => {
    dispatch({
        type: types.UPDATE_FIELD_LOCAL,
        payload: { index, field, value },
    });
}

export const setHistory = (value) => (dispatch) => {
    dispatch({
        type: types.SET_HISTORY,
        payload: value,
    });
}