import React from 'react';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import PqtPagination from './../../PqtPagination';
import numeral from 'numeral';

const Compensations = React.memo((props) => {
    const confirmHandler = (id) => {
        props.showDeleteModal();
        props.setIdForDelete(id);
    };

    const handleShowForm = (id) => {
        props.fetchCompensation(id);
        props.showFormModal();
    };

    const renderCompensations = () => {
        if (props.fetchingCompensations) {
            return (
                <tr>
                    <td colSpan="11"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.compensations.length === 0) {
            return (
                <tr>
                    <td colSpan="11">No Results</td>
                </tr>
            );
        }

        return props.compensations.map((compensation, index) => {
            let cycle = '';
            if (parseInt(compensation.cycle) === 1) {
                cycle = 'Semi Monthly';
            } else if (parseInt(compensation.cycle) === 2) {
                cycle = 'Weekly';
            }

            let payroll = '';
            let tenNinetyNine = '';
            if (compensation.payroll) {
                payroll = 'Yes';
                tenNinetyNine = 'No';
            } else {
                payroll = 'No';
                tenNinetyNine = 'Yes';
            }

            return (
                <tr key={'compensation-' + index}>
                    <td>{compensation.user_name}</td>
                    <td>{numeral(compensation.zero_ninety).format('0.[00]%')}</td>
                    <td>{numeral(compensation.ninetyone_onetwenty).format('0.[00]%')}</td>
                    <td>{cycle}</td>
                    <td>{payroll}</td>
                    <td>{tenNinetyNine}</td>
                    <td>{compensation.draw ? 'Draw' : 'No Draw'}</td>
                    <td>{numeral(compensation.draw_amount).format('$0,0.00')}</td>
                    <td>{numeral(compensation.draw_total).format('$0,0.00')}</td>
                    <td>{compensation.notes}</td>
                    <td>
                        <div className="actions">
                            <Tooltip title="Edit" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-edit" onClick={handleShowForm.bind(this, compensation.id)} />
                            </Tooltip>
                            <Tooltip title="Delete" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-trash" onClick={confirmHandler.bind(this, compensation.id)} />
                            </Tooltip>
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const setSortHandler = (field, value) => {
        props.setSort(field, value);
    };

    const renderHeader = (name, column, className) => {
        let sortFunc = setSortHandler.bind(this, column, 'asc');
        let sortIcon = <i className="fas fa-sort" />;
        if (props.sort.column === column) {
            if (props.sort.type === 'asc') {
                sortFunc = setSortHandler.bind(this, column, 'desc');
                sortIcon = <i className="fas fa-sort-up" />;
            } else {
                sortFunc = setSortHandler.bind(this, column, 'asc');
                sortIcon = <i className="fas fa-sort-down" />;
            }
        }

        return <th className={className} onClick={sortFunc}>{name} {sortIcon}</th>;
    };

    return (
        <div className="compensations-container">
            <div className="actions-container">
                <button className="upload-button" onClick={props.showFormModal}><i className="fas fa-plus-square" /> New Compensation</button>
            </div>
            <div className="table-container">
                <table className="table table-sm table-hover table-striped">
                    <thead>
                        <tr>
                            {renderHeader('Name', 'sales_rep_name', 'th-name')}
                            {renderHeader('0-90', 'zero_ninety', 'th-zero-ninety')}
                            {renderHeader('91-120', 'ninetyone_onetwenty', 'th-ninetyone-onetwenty')}
                            {renderHeader('Cycle', 'cycle', 'th-cycle')}
                            {renderHeader('Payroll', 'payroll', 'th-payroll')}
                            {renderHeader('1099', 'payroll', 'th-payroll')}
                            {renderHeader('Compensation', 'draw', 'th-draw')}
                            {renderHeader('Amount', 'draw_amount', 'th-draw-amount')}
                            {renderHeader('Total', 'draw_total', 'th-draw-total')}
                            {renderHeader('Notes', 'notes', 'th-notes')}
                            <th style={{ width: 110 }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderCompensations()}
                    </tbody>
                </table>
                <PqtPagination
                    pagination={props.pagination}
                    setActivePage={props.setActivePage}
                />
            </div>
        </div>
    );
});

export default Compensations;
