import React from 'react';
import { Dialog } from './../../common/dialog';

const SendModal = React.memo((props) => {
    const hideHandler = () => {
        props.hideSendModal();
    };

    let label = 'Send';
    if (props.sending) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    const handleSendEstimate = () => {
        props.sendEstimate();
    };

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Close</button>,
        <button key="delete-button" className="btn btn-light btn-sm action-button" onClick={handleSendEstimate} disabled={props.sending}>{label}</button>,
    ];
    return (
        <Dialog
            name="send-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="send-dialog"
        >
            <div className="send-container">
                <h1>Confirm</h1>
                <p>Confirm that you want to send the quote to the Customer clicking on the "Send" button below.</p>
            </div>
        </Dialog >
    );
});

export default SendModal;
