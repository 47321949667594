import Immutable from 'immutable';
import * as types from './MarkupCalculator.types';
import numeral from 'numeral';

const initialState = {
    fields: {
        cost: '',
        markup: '',
        revenue: '',
        profit: '',
    },
    lastModifiedFields: [],
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.SET_FIELD:
            return state.updateIn(['fields'], (fields) => {
                const newFields = fields.toJS();
                newFields[action.payload.field] = action.payload.value;

                let assignedFields = 0;
                if (newFields.cost !== '') {
                    assignedFields++;
                }
                if (newFields.markup !== '') {
                    assignedFields++;
                }
                if (newFields.revenue !== '') {
                    assignedFields++;
                }
                if (newFields.profit !== '') {
                    assignedFields++;
                }

                if (assignedFields >= 2) {
                    const lastModifiedField = action.payload.lastModifiedFields[action.payload.lastModifiedFields.length - 2];

                    if (action.payload.field === 'cost') {
                        if (lastModifiedField === 'markup') {
                            newFields.revenue = numeral(parseFloat(newFields.cost) * (1 + parseFloat(newFields.markup) / 100)).format('0.[00]');
                            newFields.profit = numeral(parseFloat(newFields.cost) * parseFloat(newFields.markup) / 100).format('0.[00]');
                        } else if (lastModifiedField === 'revenue') {
                            newFields.profit = numeral(parseFloat(newFields.revenue) - parseFloat(newFields.cost)).format('0.[00]');
                            newFields.markup = numeral(parseFloat(newFields.profit) / parseFloat(newFields.cost) * 100).format('0.[0000]');
                        } else if (lastModifiedField === 'profit') {
                            newFields.revenue = numeral(parseFloat(newFields.cost) + parseFloat(newFields.profit)).format('0.[00]');
                            newFields.markup = numeral(parseFloat(newFields.profit) / parseFloat(newFields.cost) * 100).format('0.[0000]');
                        }
                    } else if (action.payload.field === 'markup') {
                        if (lastModifiedField === 'cost') {
                            newFields.revenue = numeral(parseFloat(newFields.cost) * (1 + parseFloat(newFields.markup) / 100)).format('0.[00]');
                            newFields.profit = numeral(parseFloat(newFields.cost) * parseFloat(newFields.markup) / 100).format('0.[00]');
                        } else if (lastModifiedField === 'revenue') {
                            newFields.cost = numeral(parseFloat(newFields.revenue) / (1 + parseFloat(newFields.markup) / 100)).format('0.[00]');
                            newFields.profit = numeral(parseFloat(newFields.revenue) - parseFloat(newFields.cost)).format('0.[00]');
                        } else if (lastModifiedField === 'profit') {
                            newFields.cost = numeral(parseFloat(newFields.profit) / (parseFloat(newFields.markup) / 100)).format('0.[00]');
                            newFields.revenue = numeral(parseFloat(newFields.cost) + parseFloat(newFields.profit)).format('0.[00]');
                        }
                    } else if (action.payload.field === 'revenue') {
                        if (lastModifiedField === 'cost') {
                            newFields.profit = numeral(parseFloat(newFields.revenue) - parseFloat(newFields.cost)).format('0.[00]');
                            newFields.markup = numeral(parseFloat(newFields.profit) / parseFloat(newFields.cost) * 100).format('0.[0000]');
                        } else if (lastModifiedField === 'markup') {
                            newFields.cost = numeral(parseFloat(newFields.revenue) / (1 + parseFloat(newFields.markup) / 100)).format('0.[00]');
                            newFields.profit = numeral(parseFloat(newFields.revenue) - parseFloat(newFields.cost)).format('0.[00]');
                        } else if (lastModifiedField === 'profit') {
                            newFields.cost = numeral(parseFloat(newFields.revenue) - parseFloat(newFields.profit)).format('0.[00]');
                            newFields.markup = numeral(parseFloat(newFields.profit) / parseFloat(newFields.cost) * 100).format('0.[0000]');
                        }
                    } else if (action.payload.field === 'profit') {
                        if (lastModifiedField === 'cost') {
                            newFields.revenue = numeral(parseFloat(newFields.cost) + parseFloat(newFields.profit)).format('0.[00]');
                            newFields.markup = numeral(parseFloat(newFields.profit) / parseFloat(newFields.cost) * 100).format('0.[0000]');
                        } else if (lastModifiedField === 'markup') {
                            newFields.cost = numeral(parseFloat(newFields.profit) / (parseFloat(newFields.markup) / 100)).format('0.[00]');
                            newFields.revenue = numeral(parseFloat(newFields.cost) + parseFloat(newFields.profit)).format('0.[00]');
                        } else if (lastModifiedField === 'revenue') {
                            newFields.cost = numeral(parseFloat(newFields.revenue) - parseFloat(newFields.profit)).format('0.[00]');
                            newFields.markup = numeral(parseFloat(newFields.profit) / parseFloat(newFields.cost) * 100).format('0.[0000]');
                        }
                    }
                }

                return newFields;
            }).updateIn(['lastModifiedFields'], (lastModifiedFields) => {
                return action.payload.lastModifiedFields;
            }).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}