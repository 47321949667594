import { createSelector } from 'reselect';

export const getComponentState = state => state.christyRfq;

export const getFetchingRequest = createSelector(
    getComponentState,
    (state) => state.fetchingRequest
);
export const getFields = createSelector(
    getComponentState,
    (state) => state.fields
);
export const getFetchingInformation = createSelector(
    getComponentState,
    (state) => state.fetchingInformation
);
export const getInformation = createSelector(
    getComponentState,
    (state) => state.information
);
export const getSubmitting = createSelector(
    getComponentState,
    (state) => state.submitting
);
export const getSending = createSelector(
    getComponentState,
    (state) => state.sending
);
export const getSaving = createSelector(
    getComponentState,
    (state) => state.saving
);
export const getId = createSelector(
    getComponentState,
    (state) => state.id
);
export const getHistory = createSelector(
    getComponentState,
    (state) => state.history
);
export const getFiles = createSelector(
    getComponentState,
    (state) => state.files
);
export const getSubmittedId = createSelector(
    getComponentState,
    (state) => state.submittedId
);
export const getPricing = createSelector(
    getComponentState,
    (state) => state.pricing
);