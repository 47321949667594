import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from './Table';
import FormModal from './FormModal';
import DeleteModal from './DeleteModal';
import * as actions from './MailingMatrixes.actions';
import * as selectors from './MailingMatrixes.selectors';
import './MailingMatrixes.scss';
import PqtLayout from './../PqtLayout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import * as dialog from '../common/dialog';
import * as loginSelectors from './../Login/Login.selectors';
import { Redirect } from 'react-router-dom';

const MailingMatrixes = (props) => {
    /* State to props */
    const matrix = useSelector(selectors.getMatrix);
    const isSaving = useSelector(selectors.getIsSaving);
    const fetchingMatrixes = useSelector(selectors.getFetchingMatrixes);
    const matrixes = useSelector(selectors.getMatrixes, stringifyEqualityCheck);
    const pagination = useSelector(selectors.getPagination, stringifyEqualityCheck);
    const sort = useSelector(selectors.getSort, stringifyEqualityCheck);
    const idForDelete = useSelector(selectors.getIdForDelete, stringifyEqualityCheck);
    const isDeleting = useSelector(selectors.getIsDeleting, stringifyEqualityCheck);
    const filterFields = useSelector(selectors.getFilterFields, stringifyEqualityCheck);
    const subcategory = useSelector(selectors.getSubcategory, stringifyEqualityCheck);
    const fetchingSubcategory = useSelector(selectors.getFetchingSubcategory);
    const matrixData = useSelector(selectors.getMatrixData);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const fetchMatrixes = useCallback(() => dispatch(actions.fetchMatrixes()), [dispatch]);
    const deleteMatrix = useCallback((id) => dispatch(actions.deleteMatrix(id)), [dispatch]);
    const setSort = useCallback((column, type) => dispatch(actions.setSort(column, type)), [dispatch]);
    const setActivePage = useCallback((name, value) => dispatch(actions.setActivePage(name, value)), [dispatch]);
    const setIdForDelete = useCallback((id) => dispatch(actions.setIdForDelete(id)), [dispatch]);
    const changeFilter = useCallback((name, value) => dispatch(actions.changeFilter(name, value)), [dispatch]);
    const setMatrix = useCallback((value) => dispatch(actions.setMatrix(value)), [dispatch]);
    const setField = useCallback((field, value) => dispatch(actions.setField(field, value)), [dispatch]);
    const saveMatrix = useCallback(() => dispatch(actions.saveMatrix()), [dispatch]);
    const fetchSubcategory = useCallback((id) => dispatch(actions.fetchSubcategory(id)), [dispatch]);
    const setSubcategoryId = useCallback((id) => dispatch(actions.setSubcategoryId(id)), [dispatch]);
    const addMatrixRow = useCallback(() => dispatch(actions.addMatrixRow()), [dispatch]);
    const removeMatrixRow = useCallback((index) => dispatch(actions.removeMatrixRow(index)), [dispatch]);
    const setMatrixField = useCallback((index, field, value) => dispatch(actions.setMatrixField(index, field, value)), [dispatch]);
    const resetMatrix = useCallback(() => dispatch(actions.resetMatrix()), [dispatch]);
    const setMatrixData = useCallback((value) => dispatch(actions.setMatrixData(value)), [dispatch]);
    const showDeleteModal = useCallback(() => dispatch(dialog.actions.showDialog('delete-modal')), [dispatch]);
    const hideDeleteModal = useCallback(() => dispatch(dialog.actions.hideDialog('delete-modal')), [dispatch]);
    const showFormModal = useCallback(() => dispatch(dialog.actions.showDialog('matrix-form-modal')), [dispatch]);
    const hideFormModal = useCallback(() => dispatch(dialog.actions.hideDialog('matrix-form-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        setSubcategoryId(props.match.params.id);
        fetchSubcategory(props.match.params.id);
        fetchMatrixes();
    }, [dispatch, fetchSubcategory, setSubcategoryId, fetchMatrixes, props.match.params.id]);

    // Hugh (61)
    if (user != null && ![61].includes(parseInt(user.id))) {
        return <Redirect to="/dashboard" />;
    }

    let title = 'Mailing Pricing';
    if (subcategory) {
        title = 'Mailing Pricing (' + subcategory.name + ')';
    }

    return (
        <PqtLayout title={title} history={props.history}>
            <Helmet><title>Mailing Pricing - Stagecoach</title></Helmet>
            <div className="mailing-matrixes-container">
                <Table
                    matrixes={matrixes}
                    pagination={pagination}
                    fetchingMatrixes={fetchingMatrixes}
                    sort={sort}
                    user={user}
                    filterFields={filterFields}
                    subcategory={subcategory}
                    fetchingSubcategory={fetchingSubcategory}
                    resetMatrix={resetMatrix}
                    history={props.history}
                    setSort={setSort}
                    setMatrix={setMatrix}
                    setActivePage={setActivePage}
                    showDeleteModal={showDeleteModal}
                    setIdForDelete={setIdForDelete}
                    changeFilter={changeFilter}
                    showFormModal={showFormModal}
                    setMatrixData={setMatrixData}
                />
                <DeleteModal
                    idForDelete={idForDelete}
                    isDeleting={isDeleting}
                    deleteMatrix={deleteMatrix}
                    hideDeleteModal={hideDeleteModal}
                    setIdForDelete={setIdForDelete}
                />
                <FormModal
                    matrixes={matrixes}
                    isSaving={isSaving}
                    matrix={matrix}
                    matrixData={matrixData}
                    subcategory={subcategory}
                    setMatrix={setMatrix}
                    setField={setField}
                    hideFormModal={hideFormModal}
                    saveMatrix={saveMatrix}
                    addMatrixRow={addMatrixRow}
                    removeMatrixRow={removeMatrixRow}
                    setMatrixField={setMatrixField}
                />
            </div>
        </PqtLayout>
    );
};

export default MailingMatrixes;
