import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Report from './Report';
import * as actions from './PqtPostageReport.actions';
import * as selectors from './PqtPostageReport.selectors';
import * as loginSelectors from './../Login/Login.selectors';
import './PqtPostageReport.scss';
import PqtLayout from './../PqtLayout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import { Redirect } from 'react-router-dom';

const PqtPostageReport = (props) => {
    /* State to props */
    const fetchingReport = useSelector(selectors.getFetchingReport);
    const exporting = useSelector(selectors.getExporting);
    const exportingPostageReport = useSelector(selectors.getExportingPostageReport);
    const reportData = useSelector(selectors.getReportData, stringifyEqualityCheck);
    const reportType = useSelector(selectors.getReportType);
    const customer = useSelector(selectors.getCustomer);
    const customers = useSelector(selectors.getCustomers, stringifyEqualityCheck);
    const fetchingCustomers = useSelector(selectors.getFetchingCustomers);
    const fromDate = useSelector(selectors.getFromDate, stringifyEqualityCheck);
    const toDate = useSelector(selectors.getToDate, stringifyEqualityCheck);
    const information = useSelector(selectors.getInformation, stringifyEqualityCheck);
    const salesRep = useSelector(selectors.getSalesRep);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const fetchReport = useCallback(() => dispatch(actions.fetchReport()), [dispatch]);
    const fetchInformation = useCallback(() => dispatch(actions.fetchInformation()), [dispatch]);
    const exportReport = useCallback(() => dispatch(actions.exportReport()), [dispatch]);
    const exportPostageReport = useCallback(() => dispatch(actions.exportPostageReport()), [dispatch]);
    const setReportType = useCallback((value) => dispatch(actions.setReportType(value)), [dispatch]);
    const setCustomer = useCallback((value) => dispatch(actions.setCustomer(value)), [dispatch]);
    const fetchCustomers = useCallback(() => dispatch(actions.fetchCustomers()), [dispatch]);
    const setFromDate = useCallback((value) => dispatch(actions.setFromDate(value)), [dispatch]);
    const setToDate = useCallback((value) => dispatch(actions.setToDate(value)), [dispatch]);
    const setSalesRep = useCallback((value) => dispatch(actions.setSalesRep(value)), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        fetchCustomers();
        fetchInformation();
    }, [fetchCustomers, fetchInformation]);

    if (user != null && ![1, 2, 4, 5, 9].includes(parseInt(user.pqt_type)) && !user.is_pqt_accounting) {
        return <Redirect to="/dashboard" />;
    }

    return (
        <PqtLayout title="Postage Report" history={props.history}>
            <Helmet><title>Postage Report - Stagecoach</title></Helmet>
            <div className="pqt-postage-report-container">
                <Report
                    reportData={reportData}
                    exporting={exporting}
                    exportingPostageReport={exportingPostageReport}
                    fetchingReport={fetchingReport}
                    reportType={reportType}
                    customer={customer}
                    customers={customers}
                    fetchingCustomers={fetchingCustomers}
                    fromDate={fromDate}
                    toDate={toDate}
                    information={information}
                    salesRep={salesRep}
                    user={user}
                    fetchReport={fetchReport}
                    exportReport={exportReport}
                    setReportType={setReportType}
                    setCustomer={setCustomer}
                    fetchCustomers={fetchCustomers}
                    setFromDate={setFromDate}
                    setToDate={setToDate}
                    setSalesRep={setSalesRep}
                    exportPostageReport={exportPostageReport}
                />
            </div>
        </PqtLayout>
    );
};

export default PqtPostageReport;
