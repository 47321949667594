import Immutable from 'immutable';
import * as types from './OnHoldCustomers.types';

const initialFields = {
    email_to_customer: 1,
    email_to_sales: 1,
    check_bounced: 0,
    limit_hold_amount: 0,
}

const initialState = {
    id: null,
    fetchingOnHoldCustomers: false,
    fetchingOnHoldCustomer: false,
    onHoldCustomers: [],
    pagination: {
        totalElements: 0,
        activePage: 1,
        totalPages: 0,
        elementsPerPage: 15,
    },
    sort: {
        column: 'on_hold_date',
        type: 'desc',
    },
    history: null,
    delete: {
        id: null,
        isDeleting: false,
    },
    filter: {
        fields: []
    },
    addingHold: false,
    removingHold: false,
    fetchingInformation: false,
    information: {
        customers: [],
    },
    fields: initialFields,
    customerName: '',
    downloadingReport: false,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_ON_HOLD_CUSTOMERS:
            return state.setIn(['fetchingOnHoldCustomers'], true).toJS();

        case types.FETCH_ON_HOLD_CUSTOMERS_SUCCESS:
            return state.setIn(['fetchingOnHoldCustomers'], false)
                .setIn(['onHoldCustomers'], action.payload.elements)
                .setIn(['pagination', 'totalElements'], action.payload.totalElements)
                .setIn(['pagination', 'activePage'], action.payload.activePage)
                .setIn(['pagination', 'totalPages'], action.payload.totalPages)
                .setIn(['pagination', 'elementsPerPage'], action.payload.elementsPerPage)
                .toJS();

        case types.FETCH_ON_HOLD_CUSTOMERS_ERROR:
            return state.setIn(['fetchingOnHoldCustomers'], false).toJS();

        case types.FETCH_ON_HOLD_CUSTOMER:
            return state.setIn(['fetchingOnHoldCustomer'], true).toJS();

        case types.FETCH_ON_HOLD_CUSTOMER_SUCCESS:
            return state.setIn(['fetchingOnHoldCustomer'], false)
                .setIn(['fields'], action.payload)
                .toJS();

        case types.FETCH_ON_HOLD_CUSTOMER_ERROR:
            return state.setIn(['fetchingOnHoldCustomer'], false).toJS();

        case types.FETCH_INFORMATION:
            return state.setIn(['fetchingInformation'], true).toJS();

        case types.FETCH_INFORMATION_SUCCESS:
            return state.setIn(['fetchingInformation'], false)
                .setIn(['information', 'customers'], action.payload.customers)
                .toJS();

        case types.FETCH_INFORMATION_ERROR:
            return state.setIn(['fetchingInformation'], false).toJS();

        case types.ADD_HOLD:
            return state.setIn(['addingHold'], true).toJS();

        case types.ADD_HOLD_SUCCESS:
            return state.setIn(['addingHold'], false)
                .setIn(['delete', 'id'], null)
                .toJS();

        case types.ADD_HOLD_ERROR:
            return state.setIn(['addingHold'], false).toJS();

        case types.REMOVE_HOLD:
            return state.setIn(['removingHold'], true).toJS();

        case types.REMOVE_HOLD_SUCCESS:
            return state.setIn(['removingHold'], false)
                .setIn(['delete', 'id'], null)
                .toJS();

        case types.REMOVE_HOLD_ERROR:
            return state.setIn(['removingHold'], false).toJS();

        case types.DOWNLOAD_REPORT:
            return state.setIn(['downloadingReport'], true).toJS();

        case types.DOWNLOAD_REPORT_SUCCESS:
            return state.setIn(['downloadingReport'], false).toJS();

        case types.DOWNLOAD_REPORT_ERROR:
            return state.setIn(['downloadingReport'], false).toJS();

        case types.SET_SORT:
            return state.setIn(['sort'], action.payload).toJS();

        case types.SET_ACTIVE_PAGE:
            return state.setIn(['pagination', 'activePage'], action.payload).toJS();

        case types.SET_ID_FOR_DELETE:
            return state.setIn(['delete', 'id'], action.payload).toJS();

        case types.CHANGE_FILTER:
            return state.setIn(['pagination', 'activePage'], 1)
                .updateIn(['filter', 'fields'], (fields) => {
                    const index = fields.findIndex(field => field.get('name') === action.payload.name);
                    if (action.payload.value === '') {
                        return fields.delete(index);
                    } else {
                        if (index === -1) {
                            return fields.push({ name: action.payload.name, value: action.payload.value });
                        } else {
                            return fields.set(index, { name: action.payload.name, value: action.payload.value });
                        }
                    }
                })
                .toJS();

        case types.SET_FIELD:
            return state.setIn(['fields', action.payload.field], action.payload.value).toJS();

        case types.RESET_FIELDS:
            return state.setIn(['fields'], initialFields).toJS();

        case types.SET_CUSTOMER_NAME:
            return state.setIn(['customerName'], action.payload).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}