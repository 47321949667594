import React, { Fragment } from 'react';
import numeral from 'numeral';
import { dateToTimezone, showNotification } from './../../../utils';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';

const Report = React.memo((props) => {
    const renderRows = () => {
        if (props.fetchingReport) {
            return (
                <tr>
                    <td colSpan="9"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.reportData == null || props.reportData.rows.length === 0) {
            return (
                <tr>
                    <td colSpan="9">No results</td>
                </tr>
            );
        }

        return props.reportData.rows.map((rowData) => {
            let name = '';
            return rowData.map((row) => {
                if (typeof row === 'object') {
                    name = row['display_name'];

                    let description = row['description'];
                    if (row.length === 10) {
                        description = <Link to={'/tracking/' + row['pqt_tracking_id']}>{row['description']}</Link>
                    }

                    return (
                        <tr>
                            <td>{dateToTimezone(row['created_at'], 'MM/DD/YYYY')}</td>
                            <td>{row['transaction_type']}</td>
                            <td>{row['num']}</td>
                            <td>{row['display_name']}</td>
                            <td>{row['sales_rep_name']}</td>
                            <td>{row['csr_name']}</td>
                            <td>{description}</td>
                            <td>{row['split']}</td>
                            <td className="price">{numeral(row['balance']).format('$0,0.00')}</td>
                        </tr>
                    );
                }

                let className = 'price';
                if (row < 0) {
                    className = 'price red';
                }

                return (
                    <tr className="totals">
                        <td className={className} colSpan="8"><b>Total for {name}:</b></td>
                        <td className={className}><b>{numeral(row).format('$0,0.00')}</b></td>
                    </tr>
                );
            });
        });
    };

    let exportButtonLabel = <Fragment>Export <i className="fas fa-file-alt" /></Fragment>;
    if (props.exporting) {
        exportButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    let reportButtonLabel = <Fragment>Load Report <i className="fas fa-file-alt" /></Fragment>;
    if (props.fetchingReport) {
        reportButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    let postageButtonLabel = <Fragment>Fronted Postage Report <i className="fas fa-file-alt" /></Fragment>;
    if (props.exportingPostageReport) {
        postageButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    const handleLoadReport = () => {
        if (props.reportType === '') {
            return showNotification('Select report type', 'Select the type of report to continue', 'info');
        }

        props.fetchReport();
    };

    const handleSetReportType = (event) => {
        props.setReportType(event.target.value);
    };

    const handleSetCustomer = (event) => {
        props.setCustomer(event.target.value);
    };

    const handleSetSalesRep = (event) => {
        props.setSalesRep(event.target.value);
    };

    let customerOptions = [];
    for (let i = 0; i < props.customers.length; i++) {
        customerOptions.push(<option value={props.customers[i].id}>{props.customers[i].display_name}</option>);
    }

    const handleSetFromDate = (event) => {
        let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');
        props.setFromDate(value);
    };

    let salesRepOptions = [];
    for (let i = 0; i < props.information.salesReps.length; i++) {
        salesRepOptions.push(<option value={props.information.salesReps[i].id}>{props.information.salesReps[i].name}</option>);
    }

    const handleSetToDate = (event) => {
        let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');
        props.setToDate(value);
    };

    let frontendPostageReport = null;
    if (props.user && parseInt(props.user.id) !== 2419) {
        frontendPostageReport = <button className="postage-button" onClick={props.exportPostageReport} disabled={props.exportingPostageReport}>{postageButtonLabel}</button>;
    }

    return (
        <div className="report-container">
            <div className="action-buttons">
                Report Type:
                <select className="select-report" value={props.reportType} onChange={handleSetReportType}>
                    <option value="">Select option</option>
                    <option value="1">QuickBooks and StageCoach</option>
                    <option value="2">QuickBooks only</option>
                    <option value="3">StageCoach only</option>
                </select>
                Customer:
                <select className="select-customer" value={props.customer} onChange={handleSetCustomer}>
                    <option value="0" style={{ fontWeight: 'bold' }}>All</option>
                    {customerOptions}
                </select>
                Sales Rep:
                <select className="select-sales-rep" value={props.salesRep} onChange={handleSetSalesRep}>
                    <option value="0" style={{ fontWeight: 'bold' }}>All</option>
                    {salesRepOptions}
                </select>
                From:
                <DayPickerInput
                    formatDate={formatDate}
                    parseDate={parseDate}
                    component={(props) => {
                        let value = props.value;
                        if (!props.value) {
                            value = '';
                        }
                        return <input {...props} value={value} />;
                    }}
                    placeholder=""
                    className="date-picker-field"
                    onDayChange={handleSetFromDate}
                    value={props.fromDate == null || props.fromDate === '0000-00-00' ? null : moment(props.fromDate).format('MM/DD/YYYY')}
                />
                <Tooltip title="Clear Date" position="bottom" arrow size="small" className="action-tooltip">
                    <i className="far fa-calendar-times" onClick={props.setFromDate.bind(this, null)} />
                </Tooltip>
                To:
                <DayPickerInput
                    formatDate={formatDate}
                    parseDate={parseDate}
                    component={(props) => {
                        let value = props.value;
                        if (!props.value) {
                            value = '';
                        }
                        return <input {...props} value={value} />;
                    }}
                    placeholder=""
                    className="date-picker-field"
                    onDayChange={handleSetToDate}
                    value={props.toDate == null || props.toDate === '0000-00-00' ? '' : moment(props.toDate).format('MM/DD/YYYY')}
                />
                <Tooltip title="Clear Date" position="bottom" arrow size="small" className="action-tooltip">
                    <i className="far fa-calendar-times" onClick={props.setToDate.bind(this, null)} />
                </Tooltip>
                <button className="report-button" onClick={handleLoadReport} disabled={props.fetchingReport}>{reportButtonLabel}</button>
                <button className="export-button" onClick={props.exportReport} disabled={props.exporting}>{exportButtonLabel}</button>
                {frontendPostageReport}
            </div>
            <table className="table table-sm">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Transaction Type</th>
                        <th>Num</th>
                        <th>Customer</th>
                        <th>Sales Rep</th>
                        <th>CSR</th>
                        <th>Memo/Description</th>
                        <th>Split</th>
                        <th className="text-right">Amount</th>
                    </tr>
                </thead>
                <tbody>{renderRows()}</tbody>
            </table>
        </div>
    );
});

export default Report;
