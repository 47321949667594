import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Invoices from './Invoices';
import DeleteModal from './DeleteModal';
import CombineModal from './CombineModal';
import * as actions from './PqtInvoices.actions';
import * as selectors from './PqtInvoices.selectors';
import * as pqtLayoutSelectors from './../PqtLayout/PqtLayout.selectors';
import * as pqtLayoutActions from './../PqtLayout/PqtLayout.actions';
import './PqtInvoices.scss';
import PqtLayout from './../PqtLayout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import * as dialog from '../common/dialog';
import * as loginSelectors from './../Login/Login.selectors';
import { Redirect } from 'react-router-dom';

const PqtInvoices = (props) => {
    /* State to props */
    const fetchingInvoices = useSelector(selectors.getFetchingInvoices);
    const invoicesData = useSelector(selectors.getInvoicesData, stringifyEqualityCheck);
    const pagination = useSelector(selectors.getPagination, stringifyEqualityCheck);
    const sort = useSelector(selectors.getSort, stringifyEqualityCheck);
    const idForDelete = useSelector(selectors.getIdForDelete, stringifyEqualityCheck);
    const isDeleting = useSelector(selectors.getIsDeleting, stringifyEqualityCheck);
    const show = useSelector(selectors.getShow, stringifyEqualityCheck);
    const combineIds = useSelector(selectors.getCombineIds, stringifyEqualityCheck);
    const isCombining = useSelector(selectors.getIsCombining);
    const isExporting = useSelector(selectors.getIsExporting);
    const filerFields = useSelector(selectors.getFilterFields, stringifyEqualityCheck);
    const ws = useSelector(pqtLayoutSelectors.getWs, stringifyEqualityCheck);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const setWs = useCallback((field, value) => dispatch(pqtLayoutActions.setWs(field, value)), [dispatch]);
    const deleteInvoice = useCallback((id, comments) => dispatch(actions.deleteInvoice(id, comments)), [dispatch]);
    const setSort = useCallback((column, type) => dispatch(actions.setSort(column, type)), [dispatch]);
    const setActivePage = useCallback((name, value) => dispatch(actions.setActivePage(name, value)), [dispatch]);
    const fetchInvoices = useCallback((options, isWsUpdate) => dispatch(actions.fetchInvoices(options, isWsUpdate)), [dispatch]);
    const setIdForDelete = useCallback((id) => dispatch(actions.setIdForDelete(id)), [dispatch]);
    const setShow = useCallback((name, value, loadInvoices) => dispatch(actions.setShow(name, value, loadInvoices)), [dispatch]);
    const checkInvoice = useCallback((value, checked) => dispatch(actions.checkInvoice(value, checked)), [dispatch]);
    const combineInvoices = useCallback(() => dispatch(actions.combineInvoices()), [dispatch]);
    const changeFilter = useCallback((name, value, fetch) => dispatch(actions.changeFilter(name, value, fetch)), [dispatch]);
    const exportExcel = useCallback(() => dispatch(actions.exportExcel()), [dispatch]);
    const showDeleteModal = useCallback(() => dispatch(dialog.actions.showDialog('delete-modal')), [dispatch]);
    const hideDeleteModal = useCallback(() => dispatch(dialog.actions.hideDialog('delete-modal')), [dispatch]);
    const showCombineModal = useCallback(() => dispatch(dialog.actions.showDialog('combine-modal')), [dispatch]);
    const hideCombineModal = useCallback(() => dispatch(dialog.actions.hideDialog('combine-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        dispatch(actions.mountComponent(props.match.params.status, props.match.params.type));
    }, [dispatch, setShow, props.match.params.status, props.match.params.type]);

    if (ws !== null && ws.lastMessage !== null) {
        const jsonMessage = JSON.parse(ws.lastMessage);
        if (ws.uuid !== jsonMessage.uuid) {
            const wsTypes = ['invoice_created', 'invoice_closed', 'invoice_updated', 'invoice_deleted'];
            if (wsTypes.includes(jsonMessage.type)) {
                fetchInvoices(undefined, true);
                setWs('lastMessage', null);
            } else if (jsonMessage.type === 'new_chat_message') {
                fetchInvoices(undefined, true);
            }
        }
    }

    if (user != null && ![1, 2, 3, 4, 5, 9].includes(parseInt(user.pqt_type)) && !user.is_pqt_accounting) {
        return <Redirect to="/dashboard" />;
    }

    if (user != null && [76].includes(parseInt(user.id))) {
        return <Redirect to="/dashboard" />;
    }

    return (
        <PqtLayout title="Invoices" history={props.history}>
            <Helmet><title>Invoices - Stagecoach</title></Helmet>
            <div className="pqt-invoices-container">
                <Invoices
                    invoicesData={invoicesData}
                    pagination={pagination}
                    fetchingInvoices={fetchingInvoices}
                    history={props.history}
                    sort={sort}
                    show={show}
                    combineIds={combineIds}
                    user={user}
                    isExporting={isExporting}
                    filerFields={filerFields}
                    setSort={setSort}
                    setActivePage={setActivePage}
                    showDeleteModal={showDeleteModal}
                    setIdForDelete={setIdForDelete}
                    setShow={setShow}
                    showCombineModal={showCombineModal}
                    checkInvoice={checkInvoice}
                    changeFilter={changeFilter}
                    exportExcel={exportExcel}
                    fetchInvoices={fetchInvoices}
                />
                <DeleteModal
                    idForDelete={idForDelete}
                    isDeleting={isDeleting}
                    deleteInvoice={deleteInvoice}
                    hideDeleteModal={hideDeleteModal}
                    setIdForDelete={setIdForDelete}
                />
                <CombineModal
                    combineIds={combineIds}
                    isCombining={isCombining}
                    combineInvoices={combineInvoices}
                    hideCombineModal={hideCombineModal}
                />
            </div>
        </PqtLayout>
    );
};

export default PqtInvoices;
