export const FETCH_REQUEST = 'CHRISTY_RFQ/FETCH_REQUEST';
export const FETCH_REQUEST_SUCCESS = 'CHRISTY_RFQ/FETCH_REQUEST_SUCCESS';
export const FETCH_REQUEST_ERROR = 'CHRISTY_RFQ/FETCH_REQUEST_ERROR';
export const FETCH_INFORMATION = 'CHRISTY_RFQ/FETCH_INFORMATION';
export const FETCH_INFORMATION_SUCCESS = 'CHRISTY_RFQ/FETCH_INFORMATION_SUCCESS';
export const FETCH_INFORMATION_ERROR = 'CHRISTY_RFQ/FETCH_INFORMATION_ERROR';
export const SUBMIT_REQUEST = 'CHRISTY_RFQ/SUBMIT_REQUEST';
export const SUBMIT_REQUEST_SUCCESS = 'CHRISTY_RFQ/SUBMIT_REQUEST_SUCCESS';
export const SUBMIT_REQUEST_ERROR = 'CHRISTY_RFQ/SUBMIT_REQUEST_ERROR';
export const SEND_ESTIMATE = 'CHRISTY_RFQ/SEND_ESTIMATE';
export const SEND_ESTIMATE_SUCCESS = 'CHRISTY_RFQ/SEND_ESTIMATE_SUCCESS';
export const SEND_ESTIMATE_ERROR = 'CHRISTY_RFQ/SEND_ESTIMATE_ERROR';
export const SAVE_REQUEST = 'CHRISTY_RFQ/SAVE_REQUEST';
export const SAVE_REQUEST_SUCCESS = 'CHRISTY_RFQ/SAVE_REQUEST_SUCCESS';
export const SAVE_REQUEST_ERROR = 'CHRISTY_RFQ/SAVE_REQUEST_ERROR';
export const SET_FIELD = 'CHRISTY_RFQ/SET_FIELD';
export const SET_ID = 'CHRISTY_RFQ/SET_ID';
export const SET_HISTORY = 'CHRISTY_RFQ/SET_HISTORY';
export const ADD_FILE = 'CHRISTY_RFQ/ADD_FILE';
export const REMOVE_FILE = 'CHRISTY_RFQ/REMOVE_FILE';
export const CLEAR_FILES = 'CHRISTY_RFQ/CLEAR_FILES';
export const SET_SUBMITTED_ID = 'CHRISTY_RFQ/SET_SUBMITTED_ID';
export const SET_PRICING_FIELD = 'CHRISTY_RFQ/SET_PRICING_FIELD';
export const SET_PRICING = 'CHRISTY_RFQ/SET_PRICING';
export const RESET_STATE = 'CHRISTY_RFQ/RESET_STATE';