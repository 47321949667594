import axios from 'axios';
import * as types from './HrpPayrollReports.types';
import * as selectors from './HrpPayrollReports.selectors';
import * as dialog from '../common/dialog';
import { validateAuthInResponse, showDataDeletedNotification, showNotification, empty } from './../../utils';
import fileDownload from 'js-file-download';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchReports = () => (dispatch, getState) => {
    const sort = selectors.getSort(getState());
    const pagination = selectors.getPagination(getState());
    const filerFields = selectors.getFilterFields(getState());

    let params = [];
    params.push('sort=' + (sort.type === 'asc' ? '' : '-') + sort.column);
    params.push('active-page=' + pagination.activePage);
    params.push('elements-per-page=' + pagination.elementsPerPage);

    if (filerFields.length > 0) {
        let filterElements = [];
        for (let i = 0; i < filerFields.length; i++) {
            filterElements.push(filerFields[i]['name'] + '=' + filerFields[i]['value']);
        }
        params.push('filter=' + filterElements.join(','))
    } else {
        params.push('filter=-')
    }

    dispatch({ type: types.FETCH_REPORTS, payload: null });
    axios.get(apiUrl + '/hrp-payroll-reports' + (params.length === 0 ? '' : '?' + params.join('&')),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_REPORTS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_REPORTS_ERROR));
        });
}

export const fetchReport = (id) => (dispatch, getState) => {
    dispatch({ type: types.FETCH_REPORT, payload: null });
    axios.get(apiUrl + '/hrp-payroll-reports/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_REPORT_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_REPORT_ERROR));
        });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null
    });
}

export const deleteReport = (id, comments) => (dispatch) => {
    dispatch({ type: types.DELETE_REPORT, payload: null });
    axios.delete(apiUrl + '/hrp-payroll-reports/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }, data: { comments } }
    ).then(() => {
        dispatch({ type: types.DELETE_REPORT_SUCCESS, payload: null });
        showDataDeletedNotification();
        dispatch(fetchReports());
        dispatch(dialog.actions.hideDialog('delete-modal'));
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.DELETE_REPORT_ERROR));
    });
}

export const uploadFile = () => (dispatch, getState) => {
    const fields = selectors.getFields(getState());
    const file = selectors.getFile(getState());

    if (empty(fields.date)) {
        return showNotification('Complete information', 'Select a date', 'info');
    }
    if (empty(fields.title)) {
        return showNotification('Complete information', 'Enter a title', 'info');
    }
    if (!file || file == null) {
        return showNotification('Complete information', 'Select a spreadsheet to upload', 'info');
    }

    let data = new FormData();
    data.append('file', file);
    for (const field in fields) {
        if (['id', 'date', 'title'].includes(field) && !empty(fields[field])) {
            data.append(field, fields[field]);
        }
    }

    dispatch({ type: types.UPLOAD_FILE, payload: null });
    axios.post(apiUrl + '/hrp-payroll-report', data,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.UPLOAD_FILE_SUCCESS, payload: response.data });
            showNotification('Success', 'The file has been uploaded successfully', 'success');
            dispatch(fetchReports());
            dispatch(clearForm());
            dispatch(dialog.actions.hideDialog('payroll-form-modal'))

            dispatch(fetchReport(response.data.id));
            dispatch(dialog.actions.showDialog('payroll-result-modal'));
        })
        .catch((error) => {
            if (error.response.status === 400) {
                console.log(error.response.data.error.message)
                showNotification('Wrong File Format', error.response.data.error.message, 'warning');
            } else {
                dispatch(validateAuthInResponse(error, types.UPLOAD_FILE_ERROR));
            }
        });
}

export const downloadReport = (id, type) => (dispatch, getState) => {
    dispatch({ type: types.DOWNLOAD_REPORT, payload: null });
    axios.post(apiUrl + '/hrp-payroll-report/download', { id, type },
        {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
            responseType: 'blob',
        })
        .then((response) => {
            dispatch({ type: types.DOWNLOAD_REPORT_SUCCESS, payload: null });
            fileDownload(response.data, 'Payroll Report.xlsx');
        })
        .catch((error) => {
            if (error.response.status === 400) {
                console.log(error.response.data.error.message)
                showNotification('Wrong File Format', error.response.data.error.message, 'warning');
            } else {
                dispatch(validateAuthInResponse(error, types.DOWNLOAD_REPORT_ERROR));
            }
        });
}

export const saveMemo = () => (dispatch, getState) => {
    const fields = selectors.getFields(getState());

    if (empty(fields.memo)) {
        return showNotification('Complete information', 'Enter a memo description', 'info');
    }

    let data = new FormData();
    data.append('id', fields.id);
    data.append('memo', fields.memo);

    dispatch({ type: types.SAVE_MEMO, payload: null });
    axios.post(apiUrl + '/hrp-payroll-report/save-memo', data,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.SAVE_MEMO_SUCCESS, payload: response.data });
            showNotification('Success', 'The information has been uploaded successfully', 'success');
            dispatch(clearForm());
            dispatch(dialog.actions.hideDialog('payroll-result-modal'))
        })
        .catch((error) => {
            if (error.response.status === 400) {
                console.log(error.response.data.error.message)
                showNotification('Wrong File Format', error.response.data.error.message, 'warning');
            } else {
                dispatch(validateAuthInResponse(error, types.SAVE_MEMO_ERROR));
            }
        });
}

export const pushToQb = () => (dispatch, getState) => {
    const fields = selectors.getFields(getState());

    let data = new FormData();
    data.append('id', fields.id);

    dispatch({ type: types.PUSH_TO_QB, payload: null });
    axios.post(apiUrl + '/hrp-payroll-report/push-to-qb', data,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.PUSH_TO_QB_SUCCESS, payload: response.data });
            showNotification('Success', 'The Payroll has been pushed successfully', 'success');
        })
        .catch((error) => {
            if (error.response.status === 400) {
                showNotification('Something went wrong', error.response.data.error.message, 'warning');
                dispatch({ type: types.PUSH_TO_QB_ERROR, payload: null });
            } else {
                dispatch(validateAuthInResponse(error, types.PUSH_TO_QB_ERROR));
            }
        });
}

export const setSort = (column, type) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_SORT,
            payload: {
                column: column,
                type: type,
            }
        });
        resolve();
    }).then(() => {
        dispatch(fetchReports());
    });
}

export const setActivePage = (value) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_ACTIVE_PAGE,
            payload: value
        });
        resolve();
    }).then(() => {
        dispatch(fetchReports());
    });
}

export const mountComponent = () => (dispatch, getState) => {
    new Promise((resolve) => {
        resolve();
    }).then(() => {
        dispatch(fetchReports());
    });
}

export const setIdForDelete = (id) => (dispatch) => {
    dispatch({
        type: types.SET_ID_FOR_DELETE,
        payload: id
    });
}

export const setField = (field, value) => (dispatch) => {
    dispatch({
        type: types.SET_FIELD,
        payload: { field, value }
    });
}

export const changeFilter = (name, value, fetch = false) => (dispatch) => {
    new Promise((resolve) => {
        dispatch({
            type: types.CHANGE_FILTER,
            payload: { name, value }
        });
        resolve();
    }).then(() => {
        if (fetch) {
            dispatch(fetchReports());
        }
    });
}

export const clearForm = () => (dispatch) => {
    dispatch({
        type: types.CLEAR_FORM,
        payload: null,
    });
}

export const setFile = (file) => (dispatch) => {
    dispatch({
        type: types.SET_FILE,
        payload: file,
    });
}