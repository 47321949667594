import React, { Fragment } from 'react';
import numeral from 'numeral';
import { calculateTotals } from './../Estimate.actions';
import { empty, isEstimator } from './../../../utils';

const Totals = React.memo((props) => {
    const estimate = props.estimate;

    let totals = {
        subtotal: props.estimate.subtotal,
        markupPrice: props.estimate.additional_price,
        commission: props.estimate.commission,
        outsidePrice: props.estimate.outside_price,
        tax: props.estimate.tax,
        total: props.estimate.total,
    };
    if (empty(props.estimate.status) || parseInt(props.estimate.status) === 1 || parseInt(props.estimate.status) === 4) {
        totals = calculateTotals(props);
    }

    if (estimate.full_outside_job) {
        let subtotalContainer = null;
        if (totals.subtotal !== 0) {
            subtotalContainer = (
                <div className="total-line">
                    <div><b>Subtotal:</b></div>
                    <div className="price-div">{props.checkEstimating(numeral(totals.subtotal).format('$0,0.00'))}</div>
                </div>
            )
        }

        return (
            <div className="totals">
                {subtotalContainer}
                <div className="total-line">
                    <div><b>Outside Service:</b></div>
                    <div className="price-div">{props.checkEstimating(numeral(totals.outsidePrice).format('$0,0.00'))}</div>
                </div>
                <div className="total-line">
                    <div><b>Total:</b></div>
                    <div className="price-div"><b>{props.checkEstimating(numeral(totals.total).format('$0,0.00'))}</b></div>
                </div>
            </div>
        );
    }

    let partTotalContainer = null;
    let grandTotalContainer = null;
    if (!empty(estimate.main_part_id) || props.mainPart != null) {
        let grandTotal = 0;
        if (!empty(estimate.main_part_id)) {
            grandTotal = estimate.grand_total_not_current;
        } else if (props.mainPart != null) {
            grandTotal = props.mainPart.grand_total;
        }

        if (parseInt(estimate.use_part) === 1) {
            grandTotal += totals.total;
        }

        partTotalContainer = <div className="part-total-title">Part Total</div>;
        grandTotalContainer = (
            <Fragment>
                <div className="grand-total-title">Grand Total</div>
                <div className="total-line grand-total-line">
                    <div><b>Grand Total:</b></div>
                    <div className="price-div"><b>{props.checkEstimating(numeral(grandTotal).format('$0,0.00'))}</b></div>
                </div>
            </Fragment>
        );
    }

    let additionalMarkup = null;
    let additionalPrice = null;
    if (isEstimator(props.user)) {
        let markupDisabled = false;
        if (empty(estimate.id) || parseInt(estimate.id) > 218185) {
            markupDisabled = props.selectedMailServices.length > 0;
        }

        additionalMarkup = (
            <div className="total-line">
                <div><b>Add. Markup:</b></div>
                <div className="price-div">
                    % <input type="text" value={estimate.markup} onChange={props.handleSetField.bind(this, 'markup')} disabled={markupDisabled} />
                </div>
            </div>
        );
        additionalPrice = (
            <div className="total-line">
                <div><b>Add. Price:</b></div>
                <div className="price-div">{props.checkEstimating(numeral(totals.markupPrice).format('$0,0.00'))}</div>
            </div>
        );
    }

    return (
        <div className="totals">
            {partTotalContainer}
            <div className="total-line">
                <div><b>Subtotal:</b></div>
                <div className="price-div">{props.checkEstimating(numeral(totals.subtotal).format('$0,0.00'))}</div>
            </div>
            {additionalMarkup}
            {additionalPrice}
            <div className="total-line">
                <div><b>Outside Service:</b></div>
                <div className="price-div">{props.checkEstimating(numeral(totals.outsidePrice).format('$0,0.00'))}</div>
            </div>
            <div className="total-line">
                <div><b>Commission:</b></div>
                <div className="price-div">{props.checkEstimating(numeral(totals.commission).format('$0,0.00'))}</div>
            </div>
            <div className="total-line">
                <div>
                    <input type="checkbox" checked={estimate.use_tax} onChange={props.handleSetField.bind(this, 'use_tax')} />
                    <b>Tax:</b>
                </div>
                <div className="price-div">{props.checkEstimating(numeral(totals.tax).format('$0,0.00'))}</div>
            </div>
            <div className="total-line">
                <div><b>Total:</b></div>
                <div className="price-div"><b>{props.checkEstimating(numeral(totals.total).format('$0,0.00'))}</b></div>
            </div>
            {grandTotalContainer}
        </div>
    );
});

export default Totals;
