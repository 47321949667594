import React, { Fragment } from 'react';
//import PropTypes from 'prop-types';
import { dateToTimezone } from './../../../utils';

const ChatList = React.memo((props) => {
    const loadTrackingChatHandler = (id, title) => {
        props.loadChat(1, { element_id: id });
        props.setShowField('members', false);
        props.setType(1);
        props.setElementId(id);
        props.setChatTitle(title);
        props.setChatUnreadMessages(1, id);
        props.checkUnreadMessages();
        props.loadChats(true);
    }

    const loadIndividualChatHandler = (id, title) => {
        props.loadChat(2, { user1_id: props.user.id, user2_id: id });
        props.setShowField('members', false);
        props.setType(2);
        props.setUsersIds(props.user.id, id);
        props.setChatTitle(title);
        props.setChatUnreadMessages(title);
        props.setChatUnreadMessages(2, id);
        props.checkUnreadMessages();
        props.loadChats(true);
    }

    const renderChats = (list, key) => {
        if (list.length === 0) {
            return (
                <div key={'chat-element-0-' + key} className={'chat-element'}>
                    <div className="name-container">
                        <div className="name">None</div>
                    </div>
                    <div className="last-message">
                        <div className={'message'}>No messages</div>
                        <div className="time"></div>
                    </div>
                </div>
            );
        }

        return list.map((element, index) => {
            let name = null;
            let onClick = null;
            if (element.type === 1) {
                name = 'trackings';
                onClick = loadTrackingChatHandler.bind(this, element.id, element.name);
            } else if (element.type === 2) {
                name = 'contacts';
                onClick = loadIndividualChatHandler.bind(this, element.id, element.name);

                if (parseInt(props.user.id) === parseInt(element.id)) {
                    return null;
                }
            }

            let className = '';
            if (element.type === 1 && parseInt(props.type) === 1 && parseInt(props.elementId) === parseInt(element.id)) {
                className = 'active';
            } else if (element.type === 2 && parseInt(props.type) === 2 && (parseInt(props.usersIds.id1) === parseInt(element.id) || parseInt(props.usersIds.id2) === parseInt(element.id))) {
                className = 'active';
            }

            let lastMessageClassName = 'no-messages';
            let lastMessage = 'No messages';
            let lastMessageTime = '';
            if (element.last_message.message != null) {
                lastMessageClassName = '';
                lastMessage = element.last_message.message;
                lastMessageTime = dateToTimezone(element.last_message.created_at, 'MM/DD/YYYY h:mma');
            }

            let unreadMessagesIcon = null;
            if (element.unread_messages) {
                unreadMessagesIcon = <div className="unread-messages"><i class="fas fa-comment-dots" /></div>;
            }

            return (
                <div key={'chat-element-' + name + index} className={'chat-element ' + className} onClick={onClick}>
                    <div className="name-container">
                        <div className="name">{element.name}</div>
                        {unreadMessagesIcon}
                    </div>
                    <div className="last-message">
                        <div className={'message ' + lastMessageClassName}>{lastMessage}</div>
                        <div className="time">{lastMessageTime}</div>
                    </div>
                </div>
            );
        });
    };

    let container = (
        <Fragment>
            <div className="subtitle">Unread Messages</div>
            {renderChats(props.unreadChats, 1)}
            <div className="subtitle">Tracking Jobs</div>
            {renderChats(props.trackingsChats, 2)}
            <div className="subtitle">Contacts</div>
            {renderChats(props.contactsChats, 3)}
        </Fragment>
    );
    if (props.isLoadingChats) {
        container = (
            <div className="loading-data">
                <i className="fas fa-circle-notch fa-spin" /> Loading data...
            </div>
        );
    }

    return (
        <div className="chat-list-container">
            <div className="title">
                <div>Chats</div>
            </div>
            <div className="chats">{container}</div>
        </div>
    );
});

ChatList.propTypes = {

};

export default ChatList;
