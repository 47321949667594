import React from 'react';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import PqtPagination from './../../PqtPagination';
import { Link } from 'react-router-dom';

const Table = React.memo((props) => {
    const editHandler = (id) => {
        for (let i = 0; i < props.subcategories.length; i++) {
            if (parseInt(props.subcategories[i].id) === parseInt(id)) {
                props.setSubcategory(props.subcategories[i]);
                break;
            }
        }

        props.showFormModal();
    }

    const deleteHandler = (id) => {
        props.showDeleteModal();
        props.setIdForDelete(id);
    }

    const renderSubCategories = () => {
        if (props.fetchingSubcategories || props.fetchingCategory) {
            return (
                <tr>
                    <td colSpan="18"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.subcategories.length === 0) {
            return (
                <tr>
                    <td colSpan="18">No Results</td>
                </tr>
            );
        }

        return props.subcategories.map((subcategory, index) => {
            return (
                <tr key={'subcategory-' + index}>
                    <td>{subcategory.id}</td>
                    <td>{subcategory.name}</td>
                    <td>{subcategory.is_active ? 'Yes' : 'No'}</td>
                    <td>
                        <div className="actions">
                            <Tooltip title="Edit Subcategory" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-edit" onClick={editHandler.bind(this, subcategory.id)} />
                            </Tooltip>
                            <Tooltip title="Manage Pricing" position="bottom" arrow size="small" className="action-tooltip">
                                <Link to={'/mailing-pricing/' + subcategory.id}><i class="fas fa-table" /></Link>
                            </Tooltip>
                            <Tooltip title="Delete Subcategory" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-trash" onClick={deleteHandler.bind(this, subcategory.id)} />
                            </Tooltip>
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const setSortHandler = (field, value) => {
        props.setSort(field, value);
    };

    const renderHeader = (name, column, className) => {
        let sortFunc = setSortHandler.bind(this, column, 'asc');
        let sortIcon = <i className="fas fa-sort" />;
        if (props.sort.column === column) {
            if (props.sort.type === 'asc') {
                sortFunc = setSortHandler.bind(this, column, 'desc');
                sortIcon = <i className="fas fa-sort-up" />;
            } else {
                sortFunc = setSortHandler.bind(this, column, 'asc');
                sortIcon = <i className="fas fa-sort-down" />;
            }
        }

        return <th className={className} onClick={sortFunc}>{name} {sortIcon}</th>;
    };

    const handleChangeFilter = (name, event) => {
        props.changeFilter(name, event.target.value);
    };

    const handleKey = (name, event) => {
        if (event.key === 'Enter') {
            handleChangeFilter(name, event);
        }
    };

    const renderSearchField = (name) => {
        return (
            <th className="search-th">
                <input type="text" name={name} className="search-input" onKeyPress={handleKey.bind(this, name)} />
            </th>
        );
    };

    const renderSearchSelect = (name) => {
        return (
            <th className="search-th">
                <select name={name} className="search-input" onChange={handleChangeFilter.bind(this, name)}>
                    <option value=""></option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
            </th>
        );
    };

    const handleCreate = () => {
        props.setSubcategory({ is_active: 1 });
        props.showFormModal();
    };

    const handleReturn = () => {
        props.history.push('/mailing-categories');
    };

    return (
        <div className="table-root-container">
            <div className="actions-container">
                <div className="show-fields">
                    <button onClick={handleReturn} className="add-button"><i className="fas fa-arrow-left"></i> Return</button>
                    <button onClick={handleCreate} className="add-button">Add <i className="fas fa-plus" /></button>
                </div>
            </div>
            <div className="table-container">
                <table className="table table-sm table-hover table-striped">
                    <thead>
                        <tr>
                            {renderHeader('ID', 'id', 'th-id')}
                            {renderHeader('Name', 'name', 'th-name')}
                            {renderHeader('Active', 'is_active', 'th-active')}
                            <th style={{ width: 90 }}></th>
                        </tr>
                        <tr>
                            {renderSearchField('id')}
                            {renderSearchField('name')}
                            {renderSearchSelect('is_active')}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderSubCategories()}
                    </tbody>
                </table>
                <PqtPagination
                    pagination={props.pagination}
                    setActivePage={props.setActivePage}
                />
            </div>
        </div>
    );
});

export default Table;
