import React from 'react';
import { Dialog } from './../../common/dialog';
import { empty, showNotification } from './../../../utils';

const NewEstimateModal = React.memo((props) => {
    const hideHandler = () => {
        props.setEstimateType('');
        props.setCustomerId('');
        props.hideNewEstimateModal();
    };

    const newEstimateHandler = () => {
        if (empty(props.estimateType)) {
            return showNotification('Complete Information', 'Select the Estimate Type', 'info');
        }
        if (empty(props.customerId)) {
            return showNotification('Complete Information', 'Select the Customer', 'info');
        }

        props.setEstimateField('customer_id', props.customerId);
        props.hideNewEstimateModal();
        props.history.push('/new-estimate/' + props.estimateType + '/' + props.customerId);
    };

    const handleChange = (setFunc, event) => {
        setFunc(event.target.value);
    };

    let salesRep = 'N/A';
    if (props.customerId !== '') {
        let selectedCustomer = null;
        for (let i = 0; i < props.customers.length; i++) {
            if (parseInt(props.customers[i].id) === parseInt(props.customerId)) {
                selectedCustomer = props.customers[i];
                break;
            }
        }

        for (let i = 0; i < props.salesReps.length; i++) {
            if (parseInt(props.salesReps[i].id) === parseInt(selectedCustomer.sales_rep_id)) {
                salesRep = props.salesReps[i].name;
                break;
            }
        }
    }

    let customerOptions = [<option value="">Select option</option>];
    for (let i = 0; i < props.customers.length; i++) {
        customerOptions.push(<option value={props.customers[i].id}>{props.customers[i].display_name}</option>);
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Cancel</button>,
        <button key="delete-button" className="btn btn-light btn-sm action-button" onClick={newEstimateHandler}>Create</button>
    ];
    return (
        <Dialog
            name="new-estimate-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="new-estimate-dialog"
        >
            <div className="new-estimate-container">
                <h1>New Estimate</h1>
                <p>Select the information for the new Estimate</p>
                <div className="new-estimate-form-container">
                    <div className="field">
                        <div><b>Estimate Type:</b></div>
                        <select value={props.estimateType} onChange={handleChange.bind(this, props.setEstimateType)}>
                            <option value="">Select option</option>
                            <option value="1">Sheetfed</option>
                            <option value="2">Envelope</option>
                            <option value="4">Self-Cover</option>
                            <option value="3">Plus-Cover</option>
                            <option value="5">Digital</option>
                            <option value="6">Large Format</option>
                            <option value="7">Pocket Folder</option>
                            <option value="8">NCR</option>
                            <option value="9">Notepads</option>
                            <option value="10">Mail Only</option>
                        </select>
                    </div>
                    <div className="field">
                        <div><b>Customer:</b></div>
                        <select value={props.customerId} onChange={handleChange.bind(this, props.setCustomerId)}>
                            {customerOptions}
                        </select>
                    </div>
                    <div className="field">
                        <b>Sales Rep:</b> {salesRep}
                    </div>
                </div>
            </div>
        </Dialog >
    );
});

export default NewEstimateModal;
