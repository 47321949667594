import React from 'react';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import PqtPagination from './../../PqtPagination';
import moment from 'moment-timezone';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import numeral from 'numeral';
import { dateToTimezone } from './../../../utils';

const Items = React.memo((props) => {
    if (props.fetchingRecord) {
        return (
            <div className="items-container text-center">
                <i className="fas fa-circle-notch fa-spin" /> Loading data...
            </div>
        );
    }

    const confirmHandler = (id) => {
        props.showDeleteModal();
        props.setIdForDelete(id);
    };

    const handleShowItem = (id) => {
        props.fetchItem(id);
        props.showFormModal();
    };

    const handleUpdateAccount = (id, index, event) => {
        props.updateField(id, { ap_account_id: event.target.value });
        props.updateFieldLocal(index, 'ap_account_id', event.target.value);
    };

    const handleUpdateCreateBill = (id, index, event) => {
        props.updateField(id, { create_bill: event.target.checked ? 1 : 0 });
        props.updateFieldLocal(index, 'create_bill', event.target.checked ? 1 : 0);
    };

    const renderItems = () => {
        if (props.fetchingItems) {
            return (
                <tr>
                    <td colSpan="13"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.items.length === 0) {
            return (
                <tr>
                    <td colSpan="13">No Results</td>
                </tr>
            );
        }

        return props.items.map((item, index) => {
            if (parseInt(props.type) === 4) {
                let creditColor = '';
                if (parseFloat(item.amount) < 0) {
                    creditColor = 'credit-red';
                }

                let date = '-';
                if (item.date != null) {
                    date = moment(item.date).format('MM/DD/YYYY');
                }

                let actions = null;
                if (parseInt(props.record.status) === 1) {
                    actions = (
                        <div className="actions">
                            <Tooltip title="Edit Item" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-edit" onClick={handleShowItem.bind(this, item.id)} />
                            </Tooltip>
                            <Tooltip title="Delete Item" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-trash" onClick={confirmHandler.bind(this, item.id)} />
                            </Tooltip>
                        </div>
                    );
                }

                return (
                    <tr key={'item-' + index}>
                        <td>{date}</td>
                        <td>{item.description}</td>
                        <td>{item.card_member}</td>
                        <td className={creditColor}>{numeral(item.amount).format('$0,0.00')}</td>
                        <td>{item.extended_details}</td>
                        <td>{item.address}</td>
                        <td>{item.city_state}</td>
                        <td>{item.zip}</td>
                        <td>{item.category}</td>
                        <td>
                            <select className="account-field" value={item.ap_account_id} onChange={handleUpdateAccount.bind(this, item.id, index)} >
                                <option value="">Select account</option>
                                {props.information.accounts.map((account) => {
                                    return <option value={account.id}>{account.account} - {account.name}</option>;
                                })}
                            </select>
                        </td>
                        <td className="text-center">
                            <input type="checkbox" checked={item.create_bill} onChange={handleUpdateCreateBill.bind(this, item.id, index)} />
                        </td>
                        <td>{actions}</td>
                    </tr>
                );
            } else if (parseInt(props.type) === 5 || parseInt(props.type) === 6) {
                let creditColor = '';
                if (parseFloat(item.credit) > 0) {
                    creditColor = 'credit-red';
                }

                let date = '-';
                if (item.date != null) {
                    date = dateToTimezone(item.date, 'MM/DD/YYYY');
                }

                let postedDate = '-';
                if (item.posted_date != null) {
                    postedDate = dateToTimezone(item.posted_date, 'MM/DD/YYYY');
                }

                return (
                    <tr key={'item-' + index}>
                        <td>{date}</td>
                        <td>{postedDate}</td>
                        <td>{item.card_number}</td>
                        <td>{item.description}</td>
                        <td>{item.category}</td>
                        <td>{numeral(item.debit).format('$0,0.00')}</td>
                        <td className={creditColor}>{numeral(-Math.abs(item.credit)).format('$0,0.00')}</td>
                        <td>
                            <select className="account-field" value={item.ap_account_id} onChange={handleUpdateAccount.bind(this, item.id, index)}>
                                <option value="">Select account</option>
                                {props.information.accounts.map((account) => {
                                    return <option value={account.id}>{account.name_for_ap}</option>;
                                })}
                            </select>
                        </td>
                        <td className="text-center">
                            <input type="checkbox" checked={item.create_bill} onChange={handleUpdateCreateBill.bind(this, item.id, index)} />
                        </td>
                        <td>
                            <div className="actions">
                                <Tooltip title="Edit Item" position="bottom" arrow size="small" className="action-tooltip">
                                    <i className="fas fa-edit" onClick={handleShowItem.bind(this, item.id)} />
                                </Tooltip>
                                <Tooltip title="Delete Item" position="bottom" arrow size="small" className="action-tooltip">
                                    <i className="fas fa-trash" onClick={confirmHandler.bind(this, item.id)} />
                                </Tooltip>
                            </div>
                        </td>
                    </tr>
                );
            }

            return null;
        });
    };

    const setSortHandler = (field, value) => {
        props.setSort(field, value);
    };

    const renderHeader = (name, column, className) => {
        let sortFunc = setSortHandler.bind(this, column, 'asc');
        let sortIcon = <i className="fas fa-sort" />;
        if (props.sort.column === column) {
            if (props.sort.type === 'asc') {
                sortFunc = setSortHandler.bind(this, column, 'desc');
                sortIcon = <i className="fas fa-sort-up" />;
            } else {
                sortFunc = setSortHandler.bind(this, column, 'asc');
                sortIcon = <i className="fas fa-sort-down" />;
            }
        }

        return <th className={className} onClick={sortFunc}>{name} {sortIcon}</th>;
    };

    const handleChangeFilter = (name, fetch, event) => {
        props.changeFilter(name, event.target.value, fetch);
    };

    const handleKey = (event) => {
        if (event.key === 'Enter') {
            props.fetchItems();
        }
    };

    const renderSearchField = (name) => {
        let value = null;
        for (let i = 0; i < props.filerFields.length; i++) {
            if (props.filerFields[i].name === name) {
                value = props.filerFields[i].value;
            }
        }

        return (
            <th className="search-th">
                <input
                    type="text"
                    name={name}
                    className="search-input"
                    value={value}
                    onKeyPress={handleKey}
                    onChange={handleChangeFilter.bind(this, name, false)}
                />
            </th>
        );
    };

    const renderSelectField = (name, options) => {
        let value = null;
        for (let i = 0; i < props.filerFields.length; i++) {
            if (props.filerFields[i].name === name) {
                value = props.filerFields[i].value;
            }
        }

        return (
            <th className="search-th">
                <select name={name} className="search-input" value={value} onChange={handleChangeFilter.bind(this, name, true)}>
                    {options.map((option) => {
                        return <option value={option.value}>{option.label}</option>
                    })}
                </select>
            </th>
        );
    };

    const setDateFieldHandler = (name, event) => {
        let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');
        props.changeFilter(name, value, true);
    };

    const renderSearchDateField = (name) => {
        let value = null;
        for (let i = 0; i < props.filerFields.length; i++) {
            if (props.filerFields[i].name === name) {
                value = props.filerFields[i].value;
            }
        }

        return (
            <th className="search-th">
                <DayPickerInput
                    formatDate={formatDate}
                    parseDate={parseDate}
                    placeholder=""
                    className="date-picker-field"
                    onDayChange={setDateFieldHandler.bind(this, name)}
                    value={value == null || value === '0000-00-00' ? '' : moment(value).format('MM/DD/YYYY')}
                />
            </th>
        )
    };

    let viewAllLabel = '';
    if (parseInt(props.type) === 4) {
        viewAllLabel = 'View All American Express Items';
    } else if (parseInt(props.type) === 5) {
        viewAllLabel = 'View All Capital One Promovana Items';
    } else if (parseInt(props.type) === 6) {
        viewAllLabel = 'View All Capital One Paper Items';
    }

    const handleViewAll = () => {
        if (parseInt(props.type) === 4) {
            props.history.push('/ap-record/american-express/all');
        } else if (parseInt(props.type) === 5) {
            props.history.push('/ap-record/capital-one-promovana/all')
        } else if (parseInt(props.type) === 6) {
            props.history.push('/ap-record/capital-one-paper/all')
        }
    };

    let table = null;
    if (parseInt(props.type) === 4) {
        table = (
            <table className="table table-sm table-hover table-striped">
                <thead>
                    <tr>
                        {renderHeader('Date', 'date', 'th-date')}
                        {renderHeader('Description', 'description', 'th-description')}
                        {renderHeader('Card Member', 'card_member', 'th-card-member')}
                        {renderHeader('Amount', 'amount', 'th-amount')}
                        {renderHeader('Extended Details', 'extended_details', 'th-extended-details')}
                        {renderHeader('Address', 'address', 'th-address')}
                        {renderHeader('City/State', 'city_state', 'th-city-state')}
                        {renderHeader('Zip Code', 'zip', 'th-zip')}
                        {renderHeader('Category', 'category', 'th-category')}
                        {renderHeader('GL Code', 'ap_account_id', 'th-gl-code')}
                        {renderHeader('Create Bill', 'create_bill', 'th-create-bill')}
                        <th style={{ width: 90 }}></th>
                    </tr>
                    <tr>
                        {renderSearchDateField('date')}
                        {renderSearchField('description')}
                        {renderSearchField('card_member')}
                        {renderSearchField('amount')}
                        {renderSearchField('extended_details')}
                        {renderSearchField('address')}
                        {renderSearchField('city_state')}
                        {renderSearchField('zip')}
                        {renderSearchField('category')}
                        {renderSearchField('ap_account_id')}
                        {renderSelectField('create_bill', [{ label: '', value: '' }, { label: 'Yes', value: 1 }, { label: 'No', value: 0 }])}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {renderItems()}
                </tbody>
            </table>
        );
    } else if (parseInt(props.type) === 5 || parseInt(props.type) === 6) {
        table = (
            <table className="table table-sm table-hover table-striped">
                <thead>
                    <tr>
                        {renderHeader('Transaction Date', 'date', 'th-date')}
                        {renderHeader('Posted Date', 'posted_date', 'th-date')}
                        {renderHeader('Card Number', 'card_number', '')}
                        {renderHeader('Description', 'description', 'th-description')}
                        {renderHeader('Category', 'category', 'th-category')}
                        {renderHeader('Debit', 'debit', '')}
                        {renderHeader('Credit', 'credit', '')}
                        {renderHeader('GL Code', 'ap_account_id', 'th-gl-code')}
                        {renderHeader('Create Bill', 'create_bill', 'th-create-bill')}
                        <th style={{ width: 90 }}></th>
                    </tr>
                    <tr>
                        {renderSearchDateField('date')}
                        {renderSearchDateField('posted_date')}
                        {renderSearchField('card_number')}
                        {renderSearchField('description')}
                        {renderSearchField('category')}
                        {renderSearchField('debit')}
                        {renderSearchField('credit')}
                        {renderSearchField('ap_account_id')}
                        {renderSelectField('create_bill', [{ label: '', value: '' }, { label: 'Yes', value: 1 }, { label: 'No', value: 0 }])}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {renderItems()}
                </tbody>
            </table>
        );
    }

    return (
        <div className="items-container">
            <div className="actions-container">
                <div className="left-column">
                    <button className="view-all-button" onClick={handleViewAll}><i className="fas fa-database" /> {viewAllLabel}</button>
                </div>
                <div className="right-column">
                    <button className="upload-button" onClick={props.showFormModal}><i className="fas fa-plus" /> New Item</button>
                    <button className="upload-button" onClick={props.showPushModal}><i className="fas fa-cloud-upload-alt" /> Push to QB</button>
                </div>
            </div>
            <div className="table-container">
                {table}
                <PqtPagination
                    pagination={props.pagination}
                    setActivePage={props.setActivePage}
                />
            </div>
        </div>
    );
});

export default Items;
