import React, { Fragment } from 'react';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import PqtPagination from './../../PqtPagination';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import { dateToTimezone } from './../../../utils';
import moment from 'moment-timezone';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';

const Table = React.memo((props) => {
    const confirmHandler = (id) => {
        props.showDeleteModal();
        props.setIdForDelete(id);
    }

    const renderCreditMemos = () => {
        if (props.fetchingCreditMemos) {
            return (
                <tr>
                    <td colSpan="8"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.creditMemosData.length === 0) {
            return (
                <tr>
                    <td colSpan="8">No Results</td>
                </tr>
            );
        }

        return props.creditMemosData.map((creditMemo, index) => {
            let deleteIcon = null;
            if (props.user != null && ([1, 9].includes(parseInt(props.user.pqt_type)) || props.user.is_pqt_accounting)) {
                deleteIcon = (
                    <Tooltip title="Delete Credit Memo" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="fas fa-trash" onClick={confirmHandler.bind(this, creditMemo.id)} />
                    </Tooltip>
                );
            }

            let total = 0;
            if (creditMemo.total != null) {
                total = creditMemo.total.toFixed(2);
            }
            
            let createdAt = '-';
            if (creditMemo.created_at != null) {
                createdAt = dateToTimezone(creditMemo.created_at, 'MM/DD/YYYY');
            }

            let pushedDate = '-';
            if (creditMemo.pushed_datetime != null) {
                pushedDate = dateToTimezone(creditMemo.pushed_datetime, 'MM/DD/YYYY');
            }

            return (
                <tr key={'credit-memo-' + index}>
                    <td>{creditMemo.qb_number ? creditMemo.qb_number : '-'}</td>
                    <td>{creditMemo.customer_name}</td>
                    <td>{creditMemo.created_by_name}</td>
                    <td>{createdAt}</td>
                    <td>{pushedDate}</td>
                    <td>{numeral(total).format('$0,0.00')}</td>
                    <td>
                        <div className="actions">
                            <Tooltip title="Edit Credit Memo" position="bottom" arrow size="small" className="action-tooltip">
                                <Link to={'/credit-memo/' + creditMemo.id}><i className="fas fa-edit" /></Link>
                            </Tooltip>
                            {deleteIcon}
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const setDateFieldHandler = (name, event) => {
        let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');
        props.changeFilter(name, value, true);
    };

    const renderSearchDateField = (name) => {
        let value = null;
        for (let i = 0; i < props.filterFields.length; i++) {
            if (props.filterFields[i].name === name) {
                value = props.filterFields[i].value;
            }
        }

        return (
            <th className="search-th">
                <DayPickerInput
                    formatDate={formatDate}
                    parseDate={parseDate}
                    placeholder=""
                    className="date-picker-field"
                    onDayChange={setDateFieldHandler.bind(this, name)}
                    value={value == null || value === '0000-00-00' ? '' : moment(value).format('MM/DD/YYYY')}
                />
            </th>
        )
    };

    const setSortHandler = (field, value) => {
        props.setSort(field, value);
    };

    const renderHeader = (name, column, className) => {
        let sortFunc = setSortHandler.bind(this, column, 'asc');
        let sortIcon = <i className="fas fa-sort" />;
        if (props.sort.column === column) {
            if (props.sort.type === 'asc') {
                sortFunc = setSortHandler.bind(this, column, 'desc');
                sortIcon = <i className="fas fa-sort-up" />;
            } else {
                sortFunc = setSortHandler.bind(this, column, 'asc');
                sortIcon = <i className="fas fa-sort-down" />;
            }
        }

        return <th className={className} onClick={sortFunc}>{name} {sortIcon}</th>;
    };

    const handleChangeFilter = (name, event) => {
        props.changeFilter(name, event.target.value);
    };

    const handleKey = (name, event) => {
        if (event.key === 'Enter') {
            handleChangeFilter(name, event);
        }
    };

    const renderSearchField = (name) => {
        return (
            <th className="search-th">
                <input type="text" name={name} className="search-input" onKeyPress={handleKey.bind(this, name)} />
            </th>
        );
    };

    const handleExport = () => {
        props.exportExcel();
    };

    let exportButton = null;
    if (props.user != null && ([1, 2].includes(parseInt(props.user.pqt_type)) || props.user.is_pqt_accounting)) {
        let exportButtonLabel = <Fragment>Export Excel <i className="fas fa-file-excel" /></Fragment>
        if (props.isExporting) {
            exportButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        exportButton = <button onClick={handleExport} className="export-button" disabled={props.isExporting}>{exportButtonLabel}</button>;
    }

    const handleCreate = () => {
        props.history.push('/credit-memo');
    };

    return (
        <div className="table-root-container">
            <div className="actions-container">
                <div className="action-buttons">
                    {exportButton}
                </div>
                <div className="show-fields">
                    <button onClick={handleCreate} className="add-button"><i className="fas fa-plus" /> Add Credit Memo</button>
                </div>
            </div>
            <div className="table-container">
                <table className="table table-sm table-hover table-striped">
                    <thead>
                        <tr>
                            {renderHeader('QB #', 'qb_number', 'th-id')}
                            {renderHeader('Customer', 'customer_name', 'th-vendor-obj-name')}
                            {renderHeader('Created By', 'created_by_name', 'th-created-by')}
                            {renderHeader('Created Date', 'created_at', 'th-created-at')}
                            {renderHeader('Pushed Date', 'pushed_datetime', 'th-pushed-datetime')}
                            <th className="th-total">Total</th>
                            <th style={{ width: 90 }}></th>
                        </tr>
                        <tr>
                            {renderSearchField('qb_number')}
                            {renderSearchField('customer_name')}
                            {renderSearchField('created_by_name')}
                            {renderSearchDateField('created_at')}
                            {renderSearchDateField('pushed_datetime')}
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderCreditMemos()}
                    </tbody>
                </table>
                <PqtPagination
                    pagination={props.pagination}
                    setActivePage={props.setActivePage}
                />
            </div>
        </div>
    );
});

export default Table;
