import React from 'react';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import PqtPagination from './../../PqtPagination';

const Accounts = React.memo((props) => {
    const confirmHandler = (id) => {
        props.showDeleteModal();
        props.setIdForDelete(id);
    }

    const handleEdit = (id) => {
        props.fetchAccount(id);
        props.showFormModal();
    }

    const renderAccounts = () => {
        if (props.fetchingAccounts) {
            return (
                <tr>
                    <td colSpan="20"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.accounts.length === 0) {
            return (
                <tr>
                    <td colSpan="20">No Results</td>
                </tr>
            );
        }

        return props.accounts.map((account, index) => {
            return (
                <tr key={'account-' + index}>
                    <td>{account.id}</td>
                    <td>{account.account}</td>
                    <td>{account.name}</td>
                    <td>{account.name_for_ap}</td>
                    <td>{account.type}</td>
                    <td>{account.subtype}</td>
                    <td>{account.active ? 'Yes' : 'No'}</td>
                    <td>{account.ap_enabled ? 'Yes' : 'No'}</td>
                    <td>
                        <div className="actions">
                            <Tooltip title="Edit" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-edit" onClick={handleEdit.bind(this, account.id)} />
                            </Tooltip>
                            <Tooltip title="Delete" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-trash" onClick={confirmHandler.bind(this, account.id)} />
                            </Tooltip>
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const setSortHandler = (field, value) => {
        props.setSort(field, value);
    };

    const renderHeader = (name, column, className) => {
        let sortFunc = setSortHandler.bind(this, column, 'asc');
        let sortIcon = <i className="fas fa-sort" />;
        if (props.sort.column === column) {
            if (props.sort.type === 'asc') {
                sortFunc = setSortHandler.bind(this, column, 'desc');
                sortIcon = <i className="fas fa-sort-up" />;
            } else {
                sortFunc = setSortHandler.bind(this, column, 'asc');
                sortIcon = <i className="fas fa-sort-down" />;
            }
        }

        return <th className={className} onClick={sortFunc}>{name} {sortIcon}</th>;
    };

    const handleChangeFilter = (name, fetch, event) => {
        props.changeFilter(name, event.target.value, fetch);
    };

    const handleKey = (event) => {
        if (event.key === 'Enter') {
            props.fetchAccounts();
        }
    };

    const renderSearchField = (name) => {
        let value = null;
        for (let i = 0; i < props.filerFields.length; i++) {
            if (props.filerFields[i].name === name) {
                value = props.filerFields[i].value;
            }
        }

        return (
            <th className="search-th">
                <input
                    type="text"
                    name={name}
                    className="search-input"
                    value={value}
                    onKeyPress={handleKey}
                    onChange={handleChangeFilter.bind(this, name, false)}
                />
            </th>
        );
    };

    const renderSelectField = (name, options) => {
        let value = null;
        for (let i = 0; i < props.filerFields.length; i++) {
            if (props.filerFields[i].name === name) {
                value = props.filerFields[i].value;
            }
        }

        return (
            <th className="search-th">
                <select name={name} className="search-input" value={value} onChange={handleChangeFilter.bind(this, name, true)}>
                    {options.map((option) => {
                        return <option value={option.value}>{option.label}</option>
                    })}
                </select>
            </th>
        );
    };

    return (
        <div className="accounts-container">
            <div className="actions-container">
                <button className="new-request-button" onClick={props.showFormModal}><i className="fas fa-plus-square" /> New Account</button>
            </div>
            <div className="table-container">
                <table className="table table-sm table-hover table-striped">
                    <thead>
                        <tr>
                            {renderHeader('ID', 'id', 'th-id')}
                            {renderHeader('Account', 'account', '')}
                            {renderHeader('Name', 'name', '')}
                            {renderHeader('Name for AP', 'name_for_ap', '')}
                            {renderHeader('Type', 'type', '')}
                            {renderHeader('Detail Type', 'subtype', '')}
                            {renderHeader('Active', 'active', '')}
                            {renderHeader('Enabled for AP', 'ap_enabled', '')}
                            <th style={{ width: 70 }}></th>
                        </tr>
                        <tr>
                            {renderSearchField('id')}
                            {renderSearchField('account')}
                            {renderSearchField('name')}
                            {renderSearchField('name_for_ap')}
                            {renderSearchField('type')}
                            {renderSearchField('subtype')}
                            {renderSelectField('active', [{ label: '', value: '' }, { label: 'Yes', value: 1 }, { label: 'No', value: 0 }])}
                            {renderSelectField('ap_enabled', [{ label: '', value: '' }, { label: 'Yes', value: 1 }, { label: 'No', value: 0 }])}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderAccounts()}
                    </tbody>
                </table>
                <PqtPagination
                    pagination={props.pagination}
                    setActivePage={props.setActivePage}
                />
            </div>
        </div>
    );
});

export default Accounts;
