import React from 'react';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import PqtPagination from './../../PqtPagination';

const Table = React.memo((props) => {
    const handleLogin = (id) => {
        props.setLoggingId(id);
        props.login();
    }

    const renderElements = () => {
        if (props.fetchingElements) {
            return (
                <tr>
                    <td colSpan="4"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.elements.length === 0) {
            return (
                <tr>
                    <td colSpan="4">No Results</td>
                </tr>
            );
        }

        return props.elements.map((user, index) => {
            return (
                <tr key={'user-' + index}>
                    <td>{user.id}</td>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td className="text-center">
                        <div className="actions">
                            <Tooltip title="Login as this user" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-sign-in-alt" onClick={handleLogin.bind(this, user.id)}></i>
                            </Tooltip>
                        </div>
                    </td>
                </tr>
            );
        });
    };

    return (
        <div className="table-container">
            <div className="table-container">
                <table className="table table-sm table-hover table-striped">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th style={{ width: 60 }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderElements()}
                    </tbody>
                </table>
                <PqtPagination
                    pagination={props.pagination}
                    setActivePage={props.setActivePage}
                />
            </div>
        </div>
    );
});

export default Table;
