import Immutable from 'immutable';
import * as types from './PqtInvoice.types';
import numeral from 'numeral';

const initialState = {
    id: null,
    fetching: false,
    invoice: null,
    information: {
        fetching: false,
        customers: [],
        salesReps: [],
        csrs: [],
        classes: [],
    },
    fetchingCustomerData: false,
    isSaving: false,
    isApproving: false,
    isPushing: false,
    isReturning: false,
    isUncombining: false,
    isSplittingInvoice: false,
    isSynchingQbTotal: false,
    customerEmail: null,
    jobDueDate: null,
    fetchingPostageEscrow: null,
    postageEscrow: 0,
    splitPercent1: 50,
    splitPercent2: 50,
}

const findDepartmentIndex = (id, itemsJs) => {
    let index = itemsJs.findIndex((item) => parseInt(item.department_id) === id);
    if (index === -1) {
        return 1000;
    }
    return index;
}

const findDepartmentLastIndex = (id, itemsJs) => {
    let lastIndex = 1000;
    for (let i = 0; i < itemsJs.length; i++) {
        if (parseInt(itemsJs[i].department_id) === id) {
            lastIndex = i;
        }
    }
    return lastIndex;
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_INVOICE:
            return state.setIn(['fetching'], true).toJS();

        case types.FETCH_INVOICE_SUCCESS:
            return state.setIn(['fetching'], false)
                .setIn(['invoice'], action.payload)
                .setIn(['customerEmail'], action.payload.customer_email)
                .setIn(['jobDueDate'], action.payload.job_due_date)
                .toJS();

        case types.FETCH_INVOICE_ERROR:
            return state.setIn(['fetching'], false).toJS();

        case types.FETCH_INFORMATION:
            return state.setIn(['information', 'fetching'], true).toJS();

        case types.FETCH_INFORMATION_SUCCESS:
            return state.setIn(['information', 'fetching'], false)
                .setIn(['information', 'customers'], action.payload.customers)
                .setIn(['information', 'salesReps'], action.payload.salesReps)
                .setIn(['information', 'csrs'], action.payload.csrs)
                .setIn(['information', 'classes'], action.payload.classes)
                .toJS();

        case types.FETCH_INFORMATION_ERROR:
            return state.setIn(['information', 'fetching'], false).toJS();

        case types.FETCH_CUSTOMER_DATA:
            return state.setIn(['fetchingCustomerData'], true).toJS();

        case types.FETCH_CUSTOMER_DATA_SUCCESS:
            return state.setIn(['fetchingCustomerData'], false)
                .setIn(['invoice', 'use_customer_address'], true)
                .setIn(['invoice', 'use_bill_address'], true)
                .setIn(['invoice', 'billing_name'], action.payload.display_name)
                .setIn(['invoice', 'billing_attn'], action.payload.name)
                .setIn(['invoice', 'billing_address'], action.payload.street)
                .setIn(['invoice', 'billing_city'], action.payload.city)
                .setIn(['invoice', 'billing_state'], action.payload.state)
                .setIn(['invoice', 'billing_zip'], action.payload.zip)
                .setIn(['invoice', 'shipping_name'], action.payload.display_name)
                .setIn(['invoice', 'shipping_attn'], action.payload.name)
                .setIn(['invoice', 'shipping_address'], action.payload.street)
                .setIn(['invoice', 'shipping_city'], action.payload.city)
                .setIn(['invoice', 'shipping_state'], action.payload.state)
                .setIn(['invoice', 'shipping_zip'], action.payload.zip)
                .toJS();

        case types.FETCH_CUSTOMER_DATA_ERROR:
            return state.setIn(['fetchingCustomerData'], false).toJS();

        case types.SAVE_INVOICE:
            return state.setIn(['isSaving'], true).toJS();

        case types.SAVE_INVOICE_SUCCESS:
            return state.setIn(['isSaving'], false).toJS();

        case types.SAVE_INVOICE_ERROR:
            return state.setIn(['isSaving'], false).toJS();

        case types.APPROVE_INVOICE:
            return state.setIn(['isApproving'], true).toJS();

        case types.APPROVE_INVOICE_SUCCESS:
            return state.setIn(['isApproving'], false).toJS();

        case types.APPROVE_INVOICE_ERROR:
            return state.setIn(['isApproving'], false).toJS();

        case types.QB_PUSH:
            return state.setIn(['isPushing'], true).toJS();

        case types.QB_PUSH_SUCCESS:
            return state.setIn(['isPushing'], false).toJS();

        case types.QB_PUSH_ERROR:
            return state.setIn(['isPushing'], false).toJS();

        case types.RETURN_INVOICE:
            return state.setIn(['isReturning'], true).toJS();

        case types.RETURN_INVOICE_SUCCESS:
            return state.setIn(['isReturning'], false).toJS();

        case types.RETURN_INVOICE_ERROR:
            return state.setIn(['isReturning'], false).toJS();

        case types.UNCOMBINE_INVOICE:
            return state.setIn(['isUncombining'], true).toJS();

        case types.UNCOMBINE_INVOICE_SUCCESS:
            return state.setIn(['isUncombining'], false).toJS();

        case types.UNCOMBINE_INVOICE_ERROR:
            return state.setIn(['isUncombining'], false).toJS();

        case types.SPLIT_INVOICE:
            return state.setIn(['isSplittingInvoice'], true).toJS();

        case types.SPLIT_INVOICE_SUCCESS:
            return state.setIn(['isSplittingInvoice'], false).toJS();

        case types.SPLIT_INVOICE_ERROR:
            return state.setIn(['isSplittingInvoice'], false).toJS();

        case types.SYNC_QB_TOTAL:
            return state.setIn(['isSynchingQbTotal'], true).toJS();

        case types.SYNC_QB_TOTAL_SUCCESS:
            return state.setIn(['isSynchingQbTotal'], false).toJS();

        case types.SYNC_QB_TOTAL_ERROR:
            return state.setIn(['isSynchingQbTotal'], false).toJS();

        case types.FETCH_POSTAGE_ESCROW:
            return state.setIn(['fetchingPostageEscrow'], true).toJS();

        case types.FETCH_POSTAGE_ESCROW_SUCCESS:
            return state.setIn(['fetchingPostageEscrow'], false)
                .setIn(['postageEscrow'], action.payload)
                .toJS();

        case types.FETCH_POSTAGE_ESCROW_ERROR:
            return state.setIn(['fetchingPostageEscrow'], false).toJS();

        case types.SET_FIELD:
            return state.setIn(['invoice', action.payload.field], action.payload.value).toJS();

        case types.SET_ITEM_FIELD:
            return state.updateIn(['invoice', 'items'], (items) => {
                const itemsJs = items.toJS();
                let unit = parseInt(itemsJs[action.payload.index]['unit']);
                let previousValue = itemsJs[action.payload.index][action.payload.field];
                itemsJs[action.payload.index][action.payload.field] = action.payload.value;

                if (action.payload.field === 'quantity') {
                    if (unit === 1) {
                        itemsJs[action.payload.index]['amount'] = numeral(itemsJs[action.payload.index]['price'] * action.payload.value).format('0.[00]');
                    } else if (unit === 2) {
                        itemsJs[action.payload.index]['amount'] = numeral(itemsJs[action.payload.index]['price']).format('0.[00]');
                    } else if (unit === 3) {
                        itemsJs[action.payload.index]['amount'] = numeral((itemsJs[action.payload.index]['price'] / 100) * action.payload.value).format('0.[00]');
                    } else if (unit === 4) {
                        itemsJs[action.payload.index]['amount'] = numeral((itemsJs[action.payload.index]['price'] / 1000) * action.payload.value).format('0.[00]');
                    }
                } else if (action.payload.field === 'price') {
                    if (unit === 1) {
                        itemsJs[action.payload.index]['amount'] = numeral(action.payload.value * itemsJs[action.payload.index]['quantity']).format('0.[00]');
                    } else if (unit === 2) {
                        itemsJs[action.payload.index]['amount'] = numeral(action.payload.value).format('0.[00]');
                    } else if (unit === 3) {
                        itemsJs[action.payload.index]['amount'] = numeral((action.payload.value / 100) * itemsJs[action.payload.index]['quantity']).format('0.[00]');
                    } else if (unit === 4) {
                        itemsJs[action.payload.index]['amount'] = numeral((action.payload.value / 1000) * itemsJs[action.payload.index]['quantity']).format('0.[00]');
                    }
                } else if (action.payload.field === 'unit') {
                    unit = parseInt(action.payload.value);
                    if (unit === 1) {
                        itemsJs[action.payload.index]['amount'] = numeral(itemsJs[action.payload.index]['price'] * itemsJs[action.payload.index]['quantity']).format('0.[00]');
                    } else if (unit === 2) {
                        itemsJs[action.payload.index]['amount'] = numeral(itemsJs[action.payload.index]['price']).format('0.[00]');
                    } else if (unit === 3) {
                        itemsJs[action.payload.index]['amount'] = numeral((itemsJs[action.payload.index]['price'] / 100) * itemsJs[action.payload.index]['quantity']).format('0.[00]');
                    } else if (unit === 4) {
                        itemsJs[action.payload.index]['amount'] = numeral((itemsJs[action.payload.index]['price'] / 1000) * itemsJs[action.payload.index]['quantity']).format('0.[00]');
                    }
                } else if (action.payload.field === 'amount') {
                    if (unit === 1) {
                        itemsJs[action.payload.index]['price'] = numeral(action.payload.value / itemsJs[action.payload.index]['quantity']).format('0.[0000000000]');
                    } else if (unit === 2) {
                        itemsJs[action.payload.index]['price'] = numeral(action.payload.value).format('0.[0000000000]');
                    } else if (unit === 3) {
                        itemsJs[action.payload.index]['price'] = numeral((action.payload.value / itemsJs[action.payload.index]['quantity']) * 100).format('0.[0000000000]');
                    } else if (unit === 4) {
                        itemsJs[action.payload.index]['price'] = numeral((action.payload.value / itemsJs[action.payload.index]['quantity']) * 1000).format('0.[0000000000]');
                    }
                } else if (action.payload.field === 'department_id') {
                    previousValue = parseInt(previousValue);
                    const departmentId = parseInt(action.payload.value);

                    itemsJs[action.payload.index].invoice_class_id = '';
                    if ([62, 60, 56, 64, 65, 66, 67, 70].includes(departmentId)) {
                        itemsJs[action.payload.index].taxable = 0;
                        if (departmentId === 62) {    // Description
                            itemsJs[action.payload.index].invoice_class_id = 68;
                        } else if (departmentId === 60) {    // Postage Sales
                            itemsJs[action.payload.index].invoice_class_id = 57;
                        } else if (departmentId === 56) {    // Prepaid Postage
                            itemsJs[action.payload.index].invoice_class_id = 56;
                            itemsJs[action.payload.index].price = 0;
                            itemsJs[action.payload.index].amount = 0;
                        } else if (departmentId === 64) {    // Customer Permit
                            itemsJs[action.payload.index].invoice_class_id = 77;
                        } else if (departmentId === 65) {    // Meter
                            itemsJs[action.payload.index].invoice_class_id = 78;
                        } else if (departmentId === 66) {    // Stamp
                            itemsJs[action.payload.index].invoice_class_id = 79;
                        } else if (departmentId === 67) {    // ACM Paid Postage
                            itemsJs[action.payload.index].invoice_class_id = 80;
                            itemsJs[action.payload.index].description = 'ACM Paid Postage';
                            itemsJs[action.payload.index].quantity = 0;
                            itemsJs[action.payload.index].price = 0;
                            itemsJs[action.payload.index].amount = 0;
                        } else if (departmentId === 70) {    // Payment for Services
                            itemsJs[action.payload.index].invoice_class_id = 82;
                            itemsJs[action.payload.index].price = 0;
                            itemsJs[action.payload.index].amount = 0;
                        }
                    }

                    // Description
                    if (departmentId === 62) {
                        const removedItem = itemsJs.splice(action.payload.index, 1);
                        itemsJs.unshift(removedItem[0]);
                    } else if (previousValue === 62) {
                        let lastIndex = findDepartmentLastIndex(62, itemsJs);
                        if (lastIndex === 1000) {
                            lastIndex = 0;
                        }
                        const removedItem = itemsJs.splice(action.payload.index, 1);
                        itemsJs.splice(lastIndex, 0, removedItem[0]);
                    }

                    // Postage
                    if (departmentId === 60) {
                        const postageIndex = findDepartmentLastIndex(60, itemsJs);
                        if (postageIndex === 1000) {
                            const removedItem = itemsJs.splice(action.payload.index, 1);
                            itemsJs.push(removedItem[0]);
                        } else {
                            const removedItem = itemsJs.splice(action.payload.index, 1);
                            itemsJs.splice(postageIndex, 0, removedItem[0]);
                        }
                    } else if (previousValue === 60) {
                        const removedItem = itemsJs.splice(action.payload.index, 1);
                        const postageIndex = findDepartmentIndex(60, itemsJs);
                        const customerPermitIndex = findDepartmentIndex(64, itemsJs);
                        const meterIndex = findDepartmentIndex(65, itemsJs);
                        const stampIndex = findDepartmentIndex(66, itemsJs);
                        const prepaidPostageIndex = findDepartmentIndex(56, itemsJs);
                        const acmPaidPostageIndex = findDepartmentIndex(67, itemsJs);
                        const paymentForServicesIndex = findDepartmentIndex(70, itemsJs);

                        const minIndex = Math.min(postageIndex, customerPermitIndex, meterIndex, stampIndex, prepaidPostageIndex, acmPaidPostageIndex, paymentForServicesIndex);
                        itemsJs.splice(minIndex, 0, removedItem[0]);
                    }

                    // Customer Permit, Meter, Stamp, Prepaid Postage or ACM Paid Postage
                    if ([56, 64, 65, 66, 67, 70].includes(departmentId)) {
                        const removedItem = itemsJs.splice(action.payload.index, 1);
                        itemsJs.push(removedItem[0]);
                    } else if ([56, 64, 65, 66, 67, 70].includes(previousValue)) {
                        const removedItem = itemsJs.splice(action.payload.index, 1);
                        const postageIndex = findDepartmentIndex(60, itemsJs);
                        const customerPermitIndex = findDepartmentIndex(64, itemsJs);
                        const meterIndex = findDepartmentIndex(65, itemsJs);
                        const stampIndex = findDepartmentIndex(66, itemsJs);
                        const prepaidPostageIndex = findDepartmentIndex(56, itemsJs);
                        const acmPaidPostageIndex = findDepartmentIndex(67, itemsJs);
                        const paymentForServicesIndex = findDepartmentIndex(70, itemsJs);

                        const minIndex = Math.min(postageIndex, customerPermitIndex, meterIndex, stampIndex, prepaidPostageIndex, acmPaidPostageIndex, paymentForServicesIndex);
                        itemsJs.splice(minIndex, 0, removedItem[0]);
                    }

                    let i = 0;
                    for (let j = 0; j < itemsJs.length; j++) {
                        if (parseInt(itemsJs[j].department_id) !== 70) {
                            itemsJs[j].sort_position = i + 1;
                            i++;
                        }
                    }

                    for (let j = 0; j < itemsJs.length; j++) {
                        if (parseInt(itemsJs[j].department_id) === 70) {
                            itemsJs[j].sort_position = i + 1;
                            i++;
                        }
                    }

                    itemsJs.sort(function (a, b) {
                        return parseInt(a.sort_position) - parseInt(b.sort_position);
                    });
                }

                return itemsJs;
            }).toJS();

        case types.ADD_LINE:
            return state.updateIn(['invoice', 'items'], (items) => {
                const itemsJs = items.toJS();

                const postageIndex = findDepartmentIndex(60, itemsJs);
                const customerPermitIndex = findDepartmentIndex(64, itemsJs);
                const meterIndex = findDepartmentIndex(65, itemsJs);
                const stampIndex = findDepartmentIndex(66, itemsJs);
                const prepaidPostageIndex = findDepartmentIndex(56, itemsJs);
                const acmPaidPostageIndex = findDepartmentIndex(67, itemsJs);
                const paymentForServicesIndex = findDepartmentIndex(70, itemsJs);

                const minIndex = Math.min(postageIndex, customerPermitIndex, meterIndex, stampIndex, prepaidPostageIndex, acmPaidPostageIndex, paymentForServicesIndex);
                const newElement = {
                    code: '',
                    department_id: '',
                    invoice_class_id: '',
                    description: '',
                    quantity: '',
                    price: '',
                    unit: 1,
                    taxable: 1,
                    show: 1,
                    amount: '',
                    sort_position: 1,
                };
                itemsJs.splice(minIndex, 0, newElement);

                for (let i = 0; i < itemsJs.length; i++) {
                    itemsJs[i].sort_position = i + 1;
                }

                return itemsJs;
            }).toJS();

        case types.REMOVE_LINE:
            return state.updateIn(['invoice', 'items'], (items) => {
                const itemsJs = items.toJS();
                itemsJs.splice(action.payload, 1);

                for (let i = 0; i < itemsJs.length; i++) {
                    itemsJs[i].sort_position = i + 1;
                }

                return itemsJs;
            }).toJS();

        case types.SET_CUSTOMER_EMAIL:
            return state.setIn(['customerEmail'], action.payload).toJS();

        case types.CHANGE_SORT_POSITION:
            return state.updateIn(['invoice', 'items'], (items) => {
                const itemsJs = items.toJS();
                const removedItem = itemsJs.splice(action.payload.index, 1);

                if (action.payload.type === 1) {
                    itemsJs.splice(action.payload.index - 1, 0, removedItem[0]);
                } else {
                    itemsJs.splice(action.payload.index + 1, 0, removedItem[0]);
                }

                for (let i = 0; i < itemsJs.length; i++) {
                    itemsJs[i].sort_position = i + 1;
                }

                return itemsJs;
            }).toJS();

        case types.SET_SPLIT_PRECENT1:
            return state.setIn(['splitPercent1'], action.payload.splitPercent1)
                .setIn(['splitPercent2'], action.payload.splitPercent2)
                .toJS();

        case types.SET_SPLIT_PRECENT2:
            return state.setIn(['splitPercent1'], action.payload.splitPercent1)
                .setIn(['splitPercent2'], action.payload.splitPercent2)
                .toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}