import React from 'react';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import PqtPagination from './../../PqtPagination';
import { Link } from 'react-router-dom';

const Table = React.memo((props) => {
    const editHandler = (id) => {
        for (let i = 0; i < props.categories.length; i++) {
            if (parseInt(props.categories[i].id) === parseInt(id)) {
                props.setCategory(props.categories[i]);
                break;
            }
        }

        props.showFormModal();
    }

    const deleteHandler = (id) => {
        props.showDeleteModal();
        props.setIdForDelete(id);
    }

    const renderCategories = () => {
        if (props.fetchingCategories) {
            return (
                <tr>
                    <td colSpan="18"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.categories.length === 0) {
            return (
                <tr>
                    <td colSpan="18">No Results</td>
                </tr>
            );
        }

        return props.categories.map((category, index) => {
            let baseCalculationType = '';
            if (parseInt(category.base_calculation_type) === 1) {
                baseCalculationType = 'No base calculation';
            } else if (parseInt(category.base_calculation_type) === 2) {
                baseCalculationType = 'Labor/Speed';
            } else if (parseInt(category.base_calculation_type) === 3) {
                baseCalculationType = 'Labor/Speed + Setup';
            }

            return (
                <tr key={'category-' + index}>
                    <td>{category.id}</td>
                    <td>{category.name}</td>
                    <td>{baseCalculationType}</td>
                    <td>{category.is_active ? 'Yes' : 'No'}</td>
                    <td>
                        <div className="actions">
                            <Tooltip title="Edit Category" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-edit" onClick={editHandler.bind(this, category.id)} />
                            </Tooltip>
                            <Tooltip title="Manage Subcategories" position="bottom" arrow size="small" className="action-tooltip">
                                <Link to={'/mailing-subcategories/' + category.id}><i className="fas fa-database" /></Link>
                            </Tooltip>
                            <Tooltip title="Delete Category" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-trash" onClick={deleteHandler.bind(this, category.id)} />
                            </Tooltip>
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const setSortHandler = (field, value) => {
        props.setSort(field, value);
    };

    const renderHeader = (name, column, className) => {
        let sortFunc = setSortHandler.bind(this, column, 'asc');
        let sortIcon = <i className="fas fa-sort" />;
        if (props.sort.column === column) {
            if (props.sort.type === 'asc') {
                sortFunc = setSortHandler.bind(this, column, 'desc');
                sortIcon = <i className="fas fa-sort-up" />;
            } else {
                sortFunc = setSortHandler.bind(this, column, 'asc');
                sortIcon = <i className="fas fa-sort-down" />;
            }
        }

        return <th className={className} onClick={sortFunc}>{name} {sortIcon}</th>;
    };

    const handleChangeFilter = (name, event) => {
        props.changeFilter(name, event.target.value);
    };

    const handleKey = (name, event) => {
        if (event.key === 'Enter') {
            handleChangeFilter(name, event);
        }
    };

    const renderSearchField = (name) => {
        return (
            <th className="search-th">
                <input type="text" name={name} className="search-input" onKeyPress={handleKey.bind(this, name)} />
            </th>
        );
    };

    const renderSearchSelect = (name, options) => {
        return (
            <th className="search-th">
                <select name={name} className="search-input" onChange={handleChangeFilter.bind(this, name)}>
                    {options.map((option) => <option value={option.value}>{option.label}</option>)}
                </select>
            </th>
        );
    };

    const handleCreate = () => {
        props.setCategory({ is_active: 1, base_calculation_type: 1 });
        props.showFormModal();
    };

    return (
        <div className="table-root-container">
            <div className="actions-container">
                <div className="show-fields">
                    <button onClick={handleCreate} className="add-button">Add <i className="fas fa-plus" /></button>
                </div>
            </div>
            <div className="table-container">
                <table className="table table-sm table-hover table-striped">
                    <thead>
                        <tr>
                            {renderHeader('ID', 'id', 'th-id')}
                            {renderHeader('Name', 'name', 'th-name')}
                            {renderHeader('Base Calculation Type', 'base_calculation_type', 'th-name')}
                            {renderHeader('Active', 'is_active', 'th-active')}
                            <th style={{ width: 90 }}></th>
                        </tr>
                        <tr>
                            {renderSearchField('id')}
                            {renderSearchField('name')}
                            {renderSearchSelect('base_calculation_type', [
                                { label: '', value: '' },
                                { label: 'No base calculation', value: '1' },
                                { label: 'Labor/Speed', value: '2' },
                                { label: 'Labor/Speed + Setup', value: '3' },
                            ])}
                            {renderSearchSelect('is_active', [{ label: '', value: '' }, { label: 'Yes', value: '1' }, { label: 'No', value: '0' }])}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderCategories()}
                    </tbody>
                </table>
                <PqtPagination
                    pagination={props.pagination}
                    setActivePage={props.setActivePage}
                />
            </div>
        </div>
    );
});

export default Table;
