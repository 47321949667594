import React from 'react';
import { Dialog } from './../../common/dialog';
import { useDropzone } from 'react-dropzone';

const DeleteModal = React.memo((props) => {
    const {
        acceptedFiles,
        // fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        multiple: false,
    });

    let label = 'Save';
    if (props.saving) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    const hideHandler = () => {
        acceptedFiles.splice(0, 1);
        props.setIdForDelete(null);
        props.hideFormModal();
        props.clearFields();

        setTimeout(() => {
            props.setName(' ');
            setTimeout(() => {
                props.setName('');
            }, 100);
        }, 100);
    };

    const saveHandler = () => {
        props.saveHelpItem(acceptedFiles);
    };

    const handleOnChange = (setFunc, event) => {
        setFunc(event.target.value);
    };

    let selectedFileName = '';
    if (acceptedFiles.length > 0) {
        selectedFileName = <div><b>Selected File:</b> {acceptedFiles[0].name}</div>;
    }

    let previewImage = null;
    if (props.image != null && props.image !== '') {
        previewImage = <div className="mt-2"><b>Preview Image:</b> <img src={props.image} alt="Description" /></div>;
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Cancel</button>,
        <button key="delete-button" className="btn btn-light btn-sm action-button" onClick={saveHandler} disabled={props.isDeleting}>{label}</button>
    ];
    return (
        <Dialog
            name="form-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="help-item-form-dialog"
        >
            <div className="form-container">
                <h1>Help Item</h1>
                <div className="fields-container">
                    <div className="field name-field">
                        <b>Name <span className="required">*</span>:</b>
                        <input type="text" value={props.name} onChange={handleOnChange.bind(this, props.setName)} />
                    </div>
                    <div className="field code-ref-field">
                        <b>Reference Code <span className="required">*</span>:</b>
                        <input type="text" value={props.codeRef} onChange={handleOnChange.bind(this, props.setCodeRef)} />
                    </div>
                    <div className="field image-field">
                        <div className="dropzone">
                            <b>Image:</b>
                            <div {...getRootProps({ className: 'dropzone-container' })}>
                                <input {...getInputProps()} />
                                Select File
                            </div>
                            {selectedFileName}
                        </div>
                        <div className="preview-image">
                            {previewImage}
                        </div>
                    </div>
                    <div className="field description-field">
                        <b>Description <span className="required">*</span>:</b>
                        <textarea value={props.description} onChange={handleOnChange.bind(this, props.setDescription)} />
                    </div>
                </div>
            </div>
        </Dialog >
    );
});

export default DeleteModal;
