import React from 'react';
import FormPopover from './FormPopover';
import numeral from 'numeral';

const FlatForm = React.memo((props) => {
    return (
        <div className="notepads-form-container">
            <div className="line1">
                <div className="column1">
                    <div className="originals-field">
                        <b>Originals <span className="required">*</span>:</b>
                        <FormPopover
                            code="FLAT-001"
                            information={props.information}
                        />
                        <input type="number" value={props.fields.originals} onChange={props.handleSetField.bind(this, 'originals')} />
                    </div>
                    <div className="product-type-container">
                        <div><b>Product Type:</b></div>
                        <div className="product-type">{props.productTypeName}</div>
                        <div><b>Process Request:</b></div>
                        <div className="process-request">{props.processRequestName}</div>
                    </div>
                </div>
                <div className="column2">
                    <div className="title">
                        <b>Ink <span className="required">*</span>:</b>
                        <FormPopover
                            code="FLAT-002"
                            information={props.information}
                        />
                    </div>
                    <div className="subline1">
                        <div className="subtitle"><b>Front</b></div>
                        <div className="colors-field">
                            <b>Colors:</b>
                            <input type="text" value={props.fields.front_colors} onChange={props.handleSetField.bind(this, 'front_colors')} />
                        </div>
                        <div className="ink-field">
                            <b>Ink <span className="required">*</span>:</b>
                            <select value={props.fields.front_inks} onChange={props.handleSetField.bind(this, 'front_inks')}>
                                <option value="">Select option</option>
                                <option value="1">Black only</option>
                                <option value="2">PMS only</option>
                                <option value="3">CMYK</option>
                                <option value="4">CMYK + PMS</option>
                                <option value="5">CMYK + 2 PMS</option>
                                <option value="6">CMYK + 3 PMS</option>
                                <option value="7">CMYK + Coating</option>
                                <option value="9">Black + PMS</option>
                                <option value="10">PMS + PMS</option>
                            </select>
                        </div>
                        <div className="pms-field">
                            <b>PMS #:</b>
                            <input type="text" value={props.fields.front_pms} onChange={props.handleSetField.bind(this, 'front_pms')} />
                        </div>
                        <div className="coating-field">
                            <b>Coating:</b>
                            <FormPopover
                                code="FLAT-003"
                                information={props.information}
                            />
                            <select value={props.fields.front_coating} onChange={props.handleSetField.bind(this, 'front_coating')}>
                                <option value="">None</option>
                                <option value="1">AQ = Aqueous - Gloss</option>
                                <option value="2">AQ = Aqueous - Dull/Matte</option>
                                <option value="3">UV = Ultra Violet Gloss</option>
                                <option value="4">Varnish - Gloss</option>
                                <option value="5">Varnish - Dull/Matte</option>
                            </select>
                        </div>
                    </div>
                    <div className="subline2">
                        <div className="subtitle"><b>Back</b></div>
                        <div className="colors-field">
                            <b>Colors:</b>
                            <input type="text" value={props.fields.back_colors} onChange={props.handleSetField.bind(this, 'back_colors')} />
                        </div>
                        <div className="ink-field">
                            <b>Ink <span className="required">*</span>:</b>
                            <select value={props.fields.back_inks} onChange={props.handleSetField.bind(this, 'back_inks')}>
                                <option value="">Select option</option>
                                <option value="0">Blank</option>
                                <option value="1">Black only</option>
                                <option value="2">PMS only</option>
                                <option value="3">CMYK</option>
                                <option value="4">CMYK + PMS</option>
                                <option value="5">CMYK + 2 PMS</option>
                                <option value="6">CMYK + 3 PMS</option>
                                <option value="7">CMYK + Coating</option>
                                <option value="9">Black + PMS</option>
                                <option value="10">PMS + PMS</option>
                            </select>
                        </div>
                        <div className="pms-field">
                            <b>PMS #:</b>
                            <input type="text" value={props.fields.back_pms} onChange={props.handleSetField.bind(this, 'back_pms')} />
                        </div>
                        <div className="coating-field">
                            <b>Coating:</b>
                            <FormPopover
                                code="FLAT-003"
                                information={props.information}
                            />
                            <select value={props.fields.back_coating} onChange={props.handleSetField.bind(this, 'back_coating')}>
                                <option value="">None</option>
                                <option value="1">AQ = Aqueous - Gloss</option>
                                <option value="2">AQ = Aqueous - Dull/Matte</option>
                                <option value="3">UV = Ultra Violet Gloss</option>
                                <option value="4">Varnish - Gloss</option>
                                <option value="5">Varnish - Dull/Matte</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="line2">
                <div className="column1">
                    <div className="title">
                        <b>Size:</b>
                        <FormPopover
                            code="FLAT-004"
                            information={props.information}
                        />
                    </div>
                    <div className="finish-width-field">
                        <b>Finish Width <span className="required">*</span>:</b>
                        <input type="number" value={props.fields.finish_width} onChange={props.handleSetField.bind(this, 'finish_width')} />
                    </div>
                    <div className="x-field">X</div>
                    <div className="finish-height-field">
                        <b>Finish Height <span className="required">*</span>:</b>
                        <input type="number" value={props.fields.finish_height} onChange={props.handleSetField.bind(this, 'finish_height')} />
                    </div>
                </div>
                <div className="column2">
                    <div className="title">
                        <b>Pads:</b>
                    </div>
                    <div className="number-sets-field">
                        <b>Number of Pads <span className="required">*</span>:</b>
                        <input type="number" value={props.fields.pads} onChange={props.handleSetField.bind(this, 'pads')} />
                    </div>
                    <div className="x-field">X</div>
                    <div className="number-parts-field">
                        <b>Sheets per Pad <span className="required">*</span>:</b>
                        <select value={props.fields.sheets_per_pad} onChange={props.handleSetField.bind(this, 'sheets_per_pad')}>
                            <option value="">Select option</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="250">250</option>
                            <option value="500">500</option>
                        </select>
                    </div>
                    <div className="total-sheets">
                        Total number of sheets: <strong>{numeral(props.fields.pads * props.fields.sheets_per_pad).format('0,0')}</strong>
                    </div>
                </div>
                <div className="column3">
                    <div className="title">
                        <b>Stock:</b>
                        <FormPopover
                            code="FLAT-005"
                            information={props.information}
                        />
                    </div>
                    <div className="paper-stock-field">
                        <b>Paper Stock <span className="required">*</span>:</b>
                        <select value={props.fields.paper_stock} onChange={props.handleSetField.bind(this, 'paper_stock')}>
                            <option value="">Select option</option>
                            {props.paperStockOptions}
                        </select>
                    </div>
                    <div className="paper-weight-field">
                        <b>Paper Weight <span className="required">*</span>:</b>
                        <select value={props.fields.paper_weight} onChange={props.handleSetField.bind(this, 'paper_weight')}>
                            <option value="">Select option</option>
                            {props.paperWeightOptions}
                        </select>
                    </div>
                </div>
            </div>
            <div className="line3">
                <div className="column1">
                    <div className="title"><b>Please describe your project in detail here:</b></div>
                    <textarea
                        placeholder="Please let us know more about your project detail"
                        value={props.fields.padding_description}
                        onChange={props.handleSetField.bind(this, 'padding_description')}
                    />
                </div>
                <div className="column2">
                    <div className="title">
                        <b>Padding Options:</b>
                    </div>
                    <div className="padding-selects">
                        <div className="glue-location-field">
                            <b>Glue Location <span className="required">*</span>:</b>
                            <select value={props.fields.glue_location} onChange={props.handleSetField.bind(this, 'glue_location')}>
                                <option value="">Select option</option>
                                <option value="1">Top</option>
                                <option value="2">Left Side</option>
                                <option value="3">Right Side</option>
                            </select>
                        </div>
                    </div>
                    <div className="backing">
                        <div className="backing-field">
                            <b>Backing <span className="required">*</span>:</b>
                            <select value={props.fields.use_chip_board} onChange={props.handleSetField.bind(this, 'use_chip_board')}>
                                <option value="">Select option</option>
                                <option value="1">With Chip Board</option>
                                <option value="0">No Chip Board</option>
                            </select>
                        </div>
                    </div>
                    <div className="crash-number">
                        <div><b>Numbering <span className="required">*</span>:</b></div>
                        <div>
                            <input type="checkbox" checked={props.fields.use_crash_number} onChange={props.handleSetField.bind(this, 'use_crash_number')} /> Crash Number
                        </div>
                        <div className="field">
                            <div className="label">Start #:</div>
                            <input type="number" value={props.fields.crash_number_start} onChange={props.handleSetField.bind(this, 'crash_number_start')} />
                        </div>
                        <div className="field">
                            <div className="label">End #:</div>
                            <input type="number" value={props.fields.crash_number_end} onChange={props.handleSetField.bind(this, 'crash_number_end')} />
                        </div>
                        <div className="field">
                            <div className="label">Color:</div>
                            <input type="text" value={props.fields.crash_number_color} onChange={props.handleSetField.bind(this, 'crash_number_color')} />
                        </div>
                    </div>
                </div>
            </div>
            {props.buttonsContainer}
        </div>
    );
});

export default FlatForm;
