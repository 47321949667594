import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Form from './Form';
import DataProcessingModal from './DataProcessingModal';
import MailModal from './MailModal';
import HandworkModal from './HandworkModal';
import ConfirmSubmitModal from './ConfirmSubmitModal';
import DeletePartModal from './DeletePartModal';
import * as actions from './QuoteRequest.actions';
import * as selectors from './QuoteRequest.selectors';
import './QuoteRequest.scss';
import PqtLayout from './../PqtLayout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import * as loginSelectors from './../Login/Login.selectors';
import { Redirect } from 'react-router-dom';
import * as dialog from '../common/dialog';

const QuoteRequest = (props) => {
    /* State to props */
    const fetchingRequest = useSelector(selectors.getFetchingRequest);
    const activeTab = useSelector(selectors.getActiveTab);
    const fields = useSelector(selectors.getFields, stringifyEqualityCheck);
    const fetchingInformation = useSelector(selectors.getFetchingInformation);
    const information = useSelector(selectors.getInformation, stringifyEqualityCheck);
    const dataProcessingActiveTab = useSelector(selectors.getDataProcessingActiveTab);
    const dataProcessingItems = useSelector(selectors.getDataProcessingItems, stringifyEqualityCheck);
    const variablePrintItems = useSelector(selectors.getVariablePrintItems, stringifyEqualityCheck);
    const selectedDataProcessingItems = useSelector(selectors.getSelectedDataProcessingItems, stringifyEqualityCheck);
    const mailActiveTab = useSelector(selectors.getMailActiveTab, stringifyEqualityCheck);
    const inkjetItems = useSelector(selectors.getInkjetItems, stringifyEqualityCheck);
    const insertingItems = useSelector(selectors.getInsertingItems, stringifyEqualityCheck);
    const meteringItems = useSelector(selectors.getMeteringItems, stringifyEqualityCheck);
    const selectedMailItems = useSelector(selectors.getSelectedMailItems, stringifyEqualityCheck);
    const handworkItems = useSelector(selectors.getHandworkItems, stringifyEqualityCheck);
    const selectedHandworkItems = useSelector(selectors.getSelectedHandworkItems, stringifyEqualityCheck);
    const selectedLargeFormateProducts = useSelector(selectors.getSelectedLargeFormateProducts, stringifyEqualityCheck);
    const selectedSubstrates = useSelector(selectors.getSelectedSubstrates, stringifyEqualityCheck);
    const selectedFinishingElements = useSelector(selectors.getSelectedFinishingElements, stringifyEqualityCheck);
    const saving = useSelector(selectors.getSaving);
    const submitting = useSelector(selectors.getSubmitting);
    const id = useSelector(selectors.getId);
    const fetchingSubstrateAccessories = useSelector(selectors.getFetchingSubstrateAccessories);
    const downloadingEstimate = useSelector(selectors.getDownloadingEstimate);
    const files = useSelector(selectors.getFiles, stringifyEqualityCheck);
    const mainPartId = useSelector(selectors.getMainPartId);
    const mainPart = useSelector(selectors.getMainPart);
    const fetchingMainPart = useSelector(selectors.getFetchingMainPart);
    const idForDelete = useSelector(selectors.getIdForDelete);
    const isDeleting = useSelector(selectors.getIsDeleting);
    const isDuplicatingPart = useSelector(selectors.getIsDuplicatingPart);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const setActiveTab = useCallback((value) => dispatch(actions.setActiveTab(value)), [dispatch]);
    const setField = useCallback((name, value) => dispatch(actions.setField(name, value)), [dispatch]);
    const fetchInformation = useCallback((id) => dispatch(actions.fetchInformation(id)), [dispatch]);
    const setDataProcessingActiveTab = useCallback((value) => dispatch(actions.setDataProcessingActiveTab(value)), [dispatch]);
    const setDataProcessingItem = useCallback((id, field, value) => dispatch(actions.setDataProcessingItem(id, field, value)), [dispatch]);
    const setSelectedDataProcessingItem = useCallback((id, field, type, value) => dispatch(actions.setSelectedDataProcessingItem(id, field, type, value)), [dispatch]);
    const setSelectedMailItem = useCallback((id, field, type, value) => dispatch(actions.setSelectedMailItem(id, field, type, value)), [dispatch]);
    const setSelectedHandworkItem = useCallback((id, field, type, value) => dispatch(actions.setSelectedHandworkItem(id, field, type, value)), [dispatch]);
    const setVariablePrintItem = useCallback((id, field, value) => dispatch(actions.setVariablePrintItem(id, field, value)), [dispatch]);
    const addDataProcessingItems = useCallback(() => dispatch(actions.addDataProcessingItems()), [dispatch]);
    const deleteDataProcessingItem = useCallback((id, type) => dispatch(actions.deleteDataProcessingItem(id, type)), [dispatch]);
    const setMailActiveTab = useCallback((value) => dispatch(actions.setMailActiveTab(value)), [dispatch]);
    const setInkjetItem = useCallback((id, field, value) => dispatch(actions.setInkjetItem(id, field, value)), [dispatch]);
    const setInsertingItem = useCallback((id, field, value) => dispatch(actions.setInsertingItem(id, field, value)), [dispatch]);
    const setMeteringItem = useCallback((id, field, value) => dispatch(actions.setMeteringItem(id, field, value)), [dispatch]);
    const addMailItems = useCallback(() => dispatch(actions.addMailItems()), [dispatch]);
    const deleteMailItem = useCallback((id, type) => dispatch(actions.deleteMailItem(id, type)), [dispatch]);
    const setHandworkItem = useCallback((id, field, value) => dispatch(actions.setHandworkItem(id, field, value)), [dispatch]);
    const addHandworkItems = useCallback(() => dispatch(actions.addHandworkItems()), [dispatch]);
    const deleteHandworkItem = useCallback((id, type) => dispatch(actions.deleteHandworkItem(id, type)), [dispatch]);
    const checkLargeFormatProduct = useCallback((id) => dispatch(actions.checkLargeFormatProduct(id)), [dispatch]);
    const checkSubstrate = useCallback((id) => dispatch(actions.checkSubstrate(id)), [dispatch]);
    const checkFinishingElement = useCallback((id) => dispatch(actions.checkFinishingElement(id)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);
    const setId = useCallback((id) => dispatch(actions.setId(id)), [dispatch]);
    const setHistory = useCallback((history) => dispatch(actions.setHistory(history)), [dispatch]);
    const saveRequest = useCallback(() => dispatch(actions.saveRequest()), [dispatch]);
    const submitRequest = useCallback(() => dispatch(actions.submitRequest()), [dispatch]);
    const resetFinishing = useCallback(() => dispatch(actions.resetFinishing()), [dispatch]);
    const fetchSubstrateAccessories = useCallback((id) => dispatch(actions.fetchSubstrateAccessories(id)), [dispatch]);
    const downloadEstimate = useCallback(() => dispatch(actions.downloadEstimate()), [dispatch]);
    const addFile = useCallback((file) => dispatch(actions.addFile(file)), [dispatch]);
    const removeFile = useCallback((index) => dispatch(actions.removeFile(index)), [dispatch]);
    const setMainPartId = useCallback((value) => dispatch(actions.setMainPartId(value)), [dispatch]);
    const fetchMainPart = useCallback((id) => dispatch(actions.fetchMainPart(id)), [dispatch]);
    const deleteRequest = useCallback((id, comments) => dispatch(actions.deleteRequest(id, comments)), [dispatch]);
    const setIdForDelete = useCallback((id) => dispatch(actions.setIdForDelete(id)), [dispatch]);
    const duplicatePart = useCallback(() => dispatch(actions.duplicatePart()), [dispatch]);
    const showDataProcessingModal = useCallback(() => dispatch(dialog.actions.showDialog('data-processing-modal')), [dispatch]);
    const hideDataProcessingModal = useCallback(() => dispatch(dialog.actions.hideDialog('data-processing-modal')), [dispatch]);
    const showMailModal = useCallback(() => dispatch(dialog.actions.showDialog('mail-modal')), [dispatch]);
    const hideMailModal = useCallback(() => dispatch(dialog.actions.hideDialog('mail-modal')), [dispatch]);
    const showHandworkModal = useCallback(() => dispatch(dialog.actions.showDialog('handwork-modal')), [dispatch]);
    const hideHandworkModal = useCallback(() => dispatch(dialog.actions.hideDialog('handwork-modal')), [dispatch]);
    const showConfirmSubmitModal = useCallback(() => dispatch(dialog.actions.showDialog('confirm-submit-modal')), [dispatch]);
    const hideConfirmSubmitModal = useCallback(() => dispatch(dialog.actions.hideDialog('confirm-submit-modal')), [dispatch]);
    const showDeletePartModal = useCallback(() => dispatch(dialog.actions.showDialog('delete-part-modal')), [dispatch]);
    const hideDeletePartModal = useCallback(() => dispatch(dialog.actions.hideDialog('delete-part-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        setId(props.match.params.id);
        fetchInformation(props.match.params.id);
        setHistory(props.history);

        if (props.match.params.mainPartId) {
            setMainPartId(props.match.params.mainPartId);
            fetchMainPart(props.match.params.mainPartId);
        }

        return () => {
            resetState();
        };
    }, [fetchInformation, setId, setHistory, setMainPartId, fetchMainPart, resetState,
        props.match.params.id, props.history, props.match.params.mainPartId]);

    if (user != null && ![1, 2, 3, 4, 5, 9].includes(parseInt(user.pqt_type)) && !user.is_pqt_accounting) {
        return <Redirect to="/dashboard" />;
    }

    let title = '';
    if (activeTab === 1) {
        title = 'Information';
    } else if (activeTab === 2) {
        title = 'Simple Flat';
    } else if (activeTab === 3) {
        title = 'Self-Cover';
    } else if (activeTab === 4) {
        title = 'Plus Cover';
    } else if (activeTab === 5) {
        title = 'Envelopes';
    } else if (activeTab === 6) {
        title = 'Bindery';
    } else if (activeTab === 7) {
        title = 'Data Processing';
    } else if (activeTab === 8) {
        title = 'Mail Services';
    } else if (activeTab === 9) {
        title = 'Handwork';
    } else if (activeTab === 10) {
        title = 'Fulfillment';
    } else if (activeTab === 11) {
        title = 'Large Format';
    } else if (activeTab === 12) {
        title = 'Promotional';
    } else if (activeTab === 13) {
        title = 'Pocket Folders';
    } else if (activeTab === 14) {
        title = 'NCR';
    } else if (activeTab === 15) {
        title = 'Notepads';
    }

    return (
        <PqtLayout title={'RFQ ' + title + ' Tab'} history={props.history}>
            <Helmet><title>RFQ {title} Tab - Stagecoach</title></Helmet>
            <div className="request-quote-container">
                <Form
                    id={id}
                    fetchingRequest={fetchingRequest}
                    history={props.history}
                    user={user}
                    activeTab={activeTab}
                    fields={fields}
                    fetchingInformation={fetchingInformation}
                    information={information}
                    selectedDataProcessingItems={selectedDataProcessingItems}
                    selectedMailItems={selectedMailItems}
                    selectedHandworkItems={selectedHandworkItems}
                    selectedLargeFormateProducts={selectedLargeFormateProducts}
                    selectedSubstrates={selectedSubstrates}
                    selectedFinishingElements={selectedFinishingElements}
                    saving={saving}
                    submitting={submitting}
                    fetchingSubstrateAccessories={fetchingSubstrateAccessories}
                    downloadingEstimate={downloadingEstimate}
                    files={files}
                    mainPartId={mainPartId}
                    mainPart={mainPart}
                    fetchingMainPart={fetchingMainPart}
                    isDuplicatingPart={isDuplicatingPart}
                    setActiveTab={setActiveTab}
                    setField={setField}
                    showDataProcessingModal={showDataProcessingModal}
                    hideDataProcessingModal={hideDataProcessingModal}
                    deleteDataProcessingItem={deleteDataProcessingItem}
                    showMailModal={showMailModal}
                    hideMailModal={hideMailModal}
                    deleteMailItem={deleteMailItem}
                    showHandworkModal={showHandworkModal}
                    hideHandworkModal={hideHandworkModal}
                    deleteHandworkItem={deleteHandworkItem}
                    checkLargeFormatProduct={checkLargeFormatProduct}
                    checkSubstrate={checkSubstrate}
                    checkFinishingElement={checkFinishingElement}
                    saveRequest={saveRequest}
                    submitRequest={submitRequest}
                    resetState={resetState}
                    setSelectedDataProcessingItem={setSelectedDataProcessingItem}
                    setSelectedMailItem={setSelectedMailItem}
                    setSelectedHandworkItem={setSelectedHandworkItem}
                    resetFinishing={resetFinishing}
                    fetchSubstrateAccessories={fetchSubstrateAccessories}
                    showConfirmSubmitModal={showConfirmSubmitModal}
                    downloadEstimate={downloadEstimate}
                    addFile={addFile}
                    removeFile={removeFile}
                    showDeletePartModal={showDeletePartModal}
                    setIdForDelete={setIdForDelete}
                    duplicatePart={duplicatePart}
                />
            </div>
            <DataProcessingModal
                dataProcessingActiveTab={dataProcessingActiveTab}
                dataProcessingItems={dataProcessingItems}
                variablePrintItems={variablePrintItems}
                information={information}
                hideDataProcessingModal={hideDataProcessingModal}
                setDataProcessingItem={setDataProcessingItem}
                setDataProcessingActiveTab={setDataProcessingActiveTab}
                setVariablePrintItem={setVariablePrintItem}
                addDataProcessingItems={addDataProcessingItems}
            />
            <MailModal
                mailActiveTab={mailActiveTab}
                inkjetItems={inkjetItems}
                insertingItems={insertingItems}
                meteringItems={meteringItems}
                information={information}
                hideMailModal={hideMailModal}
                setMailActiveTab={setMailActiveTab}
                setInkjetItem={setInkjetItem}
                setInsertingItem={setInsertingItem}
                setMeteringItem={setMeteringItem}
                addMailItems={addMailItems}
            />
            <HandworkModal
                handworkItems={handworkItems}
                information={information}
                hideHandworkModal={hideHandworkModal}
                setHandworkItem={setHandworkItem}
                addHandworkItems={addHandworkItems}
            />
            <ConfirmSubmitModal
                submitting={submitting}
                submitRequest={submitRequest}
                hideConfirmSubmitModal={hideConfirmSubmitModal}
            />
            <DeletePartModal
                idForDelete={idForDelete}
                isDeleting={isDeleting}
                deleteRequest={deleteRequest}
                hideDeletePartModal={hideDeletePartModal}
                setIdForDelete={setIdForDelete}
            />
        </PqtLayout>
    );
};

export default QuoteRequest;
