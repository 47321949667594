import React, { useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import ReCAPTCHA from "react-google-recaptcha-enterprise";

const Form = React.memo((props) => {
    const recaptchaRef = useRef(null);
    const {
        // acceptedFiles,
        // fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        multiple: false,
        onDrop: (files) => {
            props.setFile(files[0]);
        }
    });

    const onChange = () => {
        const value = recaptchaRef.current.getValue();
        props.setRecaptchaToken(value);
    };

    const handleSetField = (field, event) => {
        props.setField(field, event.target.value);
    };

    let title = 'New Customer Application Form';
    if (parseInt(props.fields.type) === 2) {
        title = 'Account Update Form';
    }

    let buttonLabel = 'Send Application';
    if (props.savingApplication) {
        buttonLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    let selectedFile = null;
    if (props.file != null) {
        selectedFile = (
            <div className="selected-file">
                <div><b>Selected File:</b></div>
                <div>{props.file.name}</div>
            </div>
        );
    }

    let fileField = null;
    if (parseInt(props.fields.is_sales_tax_exempt) === 1) {
        fileField = (
            <div className="select-file-container">
                <div className="mt-1">Attach Form:</div>
                <div {...getRootProps({ className: 'dropzone-container' })}>
                    <input {...getInputProps()} />
                    Choose File
                </div>
                {selectedFile}
            </div>
        );
    }

    return (
        <div className="customer-application-form-container">
            <div className="logo-container">
                <img src="https://ac-pqn.s3.amazonaws.com/css/acm-logo-new.jpg" alt="ACM" />
            </div>
            <div className="form">
                <div className="title">{title}</div>
                <div className="field sales-rep-field">
                    <div className="label">Sales Rep <span className="required">*</span>:</div>
                    <input
                        type="text"
                        value={props.fields.sales_rep}
                        onChange={handleSetField.bind(this, 'sales_rep')}
                    />
                </div>
                <div className="field customer-name">
                    <div className="label">Customer Name <span className="required">*</span>:</div>
                    <input
                        type="text"
                        value={props.fields.customer_name}
                        onChange={handleSetField.bind(this, 'customer_name')}
                    />
                </div>
                <div className="field dba-field">
                    <div className="label">DBA:</div>
                    <input
                        type="text"
                        value={props.fields.dba}
                        onChange={handleSetField.bind(this, 'dba')}
                    />
                </div>
                <div className="subtitle">Mailing Address:</div>
                <div className="field">
                    <div className="label">Address <span className="required">*</span>:</div>
                    <input
                        type="text"
                        value={props.fields.mailing_address}
                        onChange={handleSetField.bind(this, 'mailing_address')}
                    />
                </div>
                <div className="field">
                    <div className="label">City <span className="required">*</span>:</div>
                    <input
                        type="text"
                        value={props.fields.mailing_city}
                        onChange={handleSetField.bind(this, 'mailing_city')}
                    />
                </div>
                <div className="field">
                    <div className="label">State <span className="required">*</span>:</div>
                    <input
                        type="text"
                        value={props.fields.mailing_state}
                        onChange={handleSetField.bind(this, 'mailing_state')}
                    />
                </div>
                <div className="field">
                    <div className="label">Zip Code <span className="required">*</span>:</div>
                    <input
                        type="text"
                        value={props.fields.mailing_zip}
                        onChange={handleSetField.bind(this, 'mailing_zip')}
                    />
                </div>
                <div className="field">
                    <div className="label">Phone <span className="required">*</span>:</div>
                    <input
                        type="text"
                        value={props.fields.mailing_phone}
                        onChange={handleSetField.bind(this, 'mailing_phone')}
                    />
                </div>
                <div className="field">
                    <div className="label">Cell:</div>
                    <input
                        type="text"
                        value={props.fields.mailing_cell}
                        onChange={handleSetField.bind(this, 'mailing_cell')}
                    />
                </div>
                <div className="subtitle">Contact:</div>
                <div className="field basis-half">
                    <div className="label">Name <span className="required">*</span>:</div>
                    <input
                        type="text"
                        value={props.fields.contact_name}
                        onChange={handleSetField.bind(this, 'contact_name')}
                    />
                </div>
                <div className="field basis-half">
                    <div className="label">Email Address <span className="required">*</span>:</div>
                    <input
                        type="text"
                        value={props.fields.contact_email_address}
                        onChange={handleSetField.bind(this, 'contact_email_address')}
                    />
                </div>
                <div className="subtitle">Marketing Contact:</div>
                <div className="field basis-half">
                    <div className="label">Name:</div>
                    <input
                        type="text"
                        value={props.fields.marketing_contact}
                        onChange={handleSetField.bind(this, 'marketing_contact')}
                    />
                </div>
                <div className="field basis-half">
                    <div className="label">Email Address:</div>
                    <input
                        type="text"
                        value={props.fields.marketing_email_address}
                        onChange={handleSetField.bind(this, 'marketing_email_address')}
                    />
                </div>
                <div className="subtitle">A/P Contact:</div>
                <div className="field basis-half">
                    <div className="label">Name <span className="required">*</span>:</div>
                    <input
                        type="text"
                        value={props.fields.ap_contact}
                        onChange={handleSetField.bind(this, 'ap_contact')}
                    />
                </div>
                <div className="field basis-half">
                    <div className="label">Email Address <span className="required">*</span>:</div>
                    <input
                        type="text"
                        value={props.fields.ap_contact_email}
                        onChange={handleSetField.bind(this, 'ap_contact_email')}
                    />
                </div>
                <div className="subtitle">Billing Address:</div>
                <div className="field">
                    <div className="label">Address:</div>
                    <input
                        type="text"
                        value={props.fields.billing_address}
                        onChange={handleSetField.bind(this, 'billing_address')}
                    />
                </div>
                <div className="field">
                    <div className="label">City:</div>
                    <input
                        type="text"
                        value={props.fields.billing_city}
                        onChange={handleSetField.bind(this, 'billing_city')}
                    />
                </div>
                <div className="field">
                    <div className="label">State:</div>
                    <input
                        type="text"
                        value={props.fields.billing_state}
                        onChange={handleSetField.bind(this, 'billing_state')}
                    />
                </div>
                <div className="field">
                    <div className="label">Zip Code:</div>
                    <input
                        type="text"
                        value={props.fields.billing_zip}
                        onChange={handleSetField.bind(this, 'billing_zip')}
                    />
                </div>
                <div className="field">
                    <div className="label">Phone:</div>
                    <input
                        type="text"
                        value={props.fields.billing_phone}
                        onChange={handleSetField.bind(this, 'billing_phone')}
                    />
                </div>
                <div className="field">
                    <div className="label">Cell:</div>
                    <input
                        type="text"
                        value={props.fields.billing_cell}
                        onChange={handleSetField.bind(this, 'billing_cell')}
                    />
                </div>
                <div className="subtitle">Business Information:</div>
                <div className="field">
                    <div className="label">Sales Tax Exempt:</div>
                    <select
                        value={props.fields.is_sales_tax_exempt}
                        onChange={handleSetField.bind(this, 'is_sales_tax_exempt')}
                    >
                        <option value="">Select option</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                    {fileField}
                </div>
                <div className="field">
                    <div className="label">Industry or NAICS Code:</div>
                    <input
                        type="text"
                        value={props.fields.industry_code}
                        onChange={handleSetField.bind(this, 'industry_code')}
                    />
                </div>
                <div className="field">
                    <div className="label">Type of Business:</div>
                    <input
                        type="text"
                        value={props.fields.type_of_business}
                        onChange={handleSetField.bind(this, 'type_of_business')}
                    />
                </div>
                <div className="field">
                    <div className="label">Other:</div>
                    <input
                        type="text"
                        value={props.fields.other}
                        onChange={handleSetField.bind(this, 'other')}
                    />
                </div>
                <div className="field">
                    <div className="label">Statements Required:</div>
                    <select
                        value={props.fields.statements_required}
                        onChange={handleSetField.bind(this, 'statements_required')}
                    >
                        <option value="">Select option</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                </div>
                <div className="field">
                    <div className="label">Years in Operation:</div>
                    <input
                        type="text"
                        value={props.fields.years_in_operation}
                        onChange={handleSetField.bind(this, 'years_in_operation')}
                    />
                </div>
                <div className="field">
                    <div className="label">President:</div>
                    <input
                        type="text"
                        value={props.fields.president}
                        onChange={handleSetField.bind(this, 'president')}
                    />
                </div>
                <div className="field">
                    <div className="label">Bank Name and Branch:</div>
                    <input
                        type="text"
                        value={props.fields.bank_name_and_branch}
                        onChange={handleSetField.bind(this, 'bank_name_and_branch')}
                    />
                </div>
                <div className="field">
                    <div className="label">Banker:</div>
                    <input
                        type="text"
                        value={props.fields.banker}
                        onChange={handleSetField.bind(this, 'banker')}
                    />
                </div>
                <div className="field">
                    <div className="label">Phone:</div>
                    <input
                        type="text"
                        value={props.fields.phone}
                        onChange={handleSetField.bind(this, 'phone')}
                    />
                </div>
                <div className="field">
                    <div className="label">Terms:</div>
                    <div className="mt-2"><strong>Net 30</strong></div>
                </div>
                <div className="field"></div>
                <div className="subtitle">Business Credit References:</div>
                <div className="references-container">
                    <div className="reference">
                        <div className="reference-title">Reference 1:</div>
                        <div className="field">
                            <div className="label">Name:</div>
                            <input
                                type="text"
                                value={props.fields.reference1_name}
                                onChange={handleSetField.bind(this, 'reference1_name')}
                            />
                        </div>
                        <div className="field">
                            <div className="label">Address:</div>
                            <input
                                type="text"
                                value={props.fields.reference1_address}
                                onChange={handleSetField.bind(this, 'reference1_address')}
                            />
                        </div>
                        <div className="field">
                            <div className="label">Phone:</div>
                            <input
                                type="text"
                                value={props.fields.reference1_phone}
                                onChange={handleSetField.bind(this, 'reference1_phone')}
                            />
                        </div>
                        <div className="field">
                            <div className="label">Fax or Email:</div>
                            <input
                                type="text"
                                value={props.fields.reference1_email_or_fax}
                                onChange={handleSetField.bind(this, 'reference1_email_or_fax')}
                            />
                        </div>
                    </div>
                    <div className="reference">
                        <div className="reference-title">Reference 2:</div>
                        <div className="field">
                            <div className="label">Name:</div>
                            <input
                                type="text"
                                value={props.fields.reference2_name}
                                onChange={handleSetField.bind(this, 'reference2_name')}
                            />
                        </div>
                        <div className="field">
                            <div className="label">Address:</div>
                            <input
                                type="text"
                                value={props.fields.reference2_address}
                                onChange={handleSetField.bind(this, 'reference2_address')}
                            />
                        </div>
                        <div className="field">
                            <div className="label">Phone:</div>
                            <input
                                type="text"
                                value={props.fields.reference2_phone}
                                onChange={handleSetField.bind(this, 'reference2_phone')}
                            />
                        </div>
                        <div className="field">
                            <div className="label">Fax or Email:</div>
                            <input
                                type="text"
                                value={props.fields.reference2_email_or_fax}
                                onChange={handleSetField.bind(this, 'reference2_email_or_fax')}
                            />
                        </div>
                    </div>
                    <div className="reference">
                        <div className="reference-title">Reference 3:</div>
                        <div className="field">
                            <div className="label">Name:</div>
                            <input
                                type="text"
                                value={props.fields.reference3_name}
                                onChange={handleSetField.bind(this, 'reference3_name')}
                            />
                        </div>
                        <div className="field">
                            <div className="label">Address:</div>
                            <input
                                type="text"
                                value={props.fields.reference3_address}
                                onChange={handleSetField.bind(this, 'reference3_address')}
                            />
                        </div>
                        <div className="field">
                            <div className="label">Phone:</div>
                            <input
                                type="text"
                                value={props.fields.reference3_phone}
                                onChange={handleSetField.bind(this, 'reference3_phone')}
                            />
                        </div>
                        <div className="field">
                            <div className="label">Fax or Email:</div>
                            <input
                                type="text"
                                value={props.fields.reference3_email_or_fax}
                                onChange={handleSetField.bind(this, 'reference3_email_or_fax')}
                            />
                        </div>
                    </div>
                    <div className="reference">
                        <div className="reference-title">Reference 4:</div>
                        <div className="field">
                            <div className="label">Name:</div>
                            <input
                                type="text"
                                value={props.fields.reference4_name}
                                onChange={handleSetField.bind(this, 'reference4_name')}
                            />
                        </div>
                        <div className="field">
                            <div className="label">Address:</div>
                            <input
                                type="text"
                                value={props.fields.reference4_address}
                                onChange={handleSetField.bind(this, 'reference4_address')}
                            />
                        </div>
                        <div className="field">
                            <div className="label">Phone:</div>
                            <input
                                type="text"
                                value={props.fields.reference4_phone}
                                onChange={handleSetField.bind(this, 'reference4_phone')}
                            />
                        </div>
                        <div className="field">
                            <div className="label">Fax or Email:</div>
                            <input
                                type="text"
                                value={props.fields.reference4_email_or_fax}
                                onChange={handleSetField.bind(this, 'reference4_email_or_fax')}
                            />
                        </div>
                    </div>
                </div>
                <div className="disclaimer">
                    The undersigned certifies that 1) he/she is duly authorized to sign this Customer Credit Application/Agreement on behalf
                    of the company/person herein represented and applying for credit (the "Buyer"); 2) everything stated in this Customer
                    Credit Application/Agreement is true, accurate and complete; 3) Buyer agrees to pay each invoice within 30 days of invoice
                    date unless otherwise stated on the invoice. Past due invoice amounts are subject to 1.5% per month of interest, or the
                    maximum amount provided by law (whichever is less) 4) Should Buyer default in any such payment(s) and/or should Seller
                    seek to enforce this agreement against Buyer, Buyer agrees to pay reasonable collection charges, attorney(s) fees, court
                    costs and other expenses, incurred by Premier IMS, Inc. (Seller), whether or not suit is filed, and whether or not incurred
                    in connection with issues of federal bankruptcy law By signing this Customer Credit Application/Agreement the Buyer grants
                    permission for credit information to be verified by companies and financial institutions the Buyer has specified on this
                    document and others that the Seller becomes aware of during the credit review.
                </div>
                <div className="field signature-field">
                    <div className="label">Authorized Signature <span className="required">*</span>:</div>
                    <input
                        type="text"
                        value={props.fields.authorized_signature}
                        onChange={handleSetField.bind(this, 'authorized_signature')}
                    />
                </div>
                <div className="field signature-field">
                    <div className="label">Title:</div>
                    <input
                        type="text"
                        value={props.fields.title}
                        onChange={handleSetField.bind(this, 'title')}
                    />
                </div>
                <div className="field signature-field">
                    <div className="label">Printed Name:</div>
                    <input
                        type="text"
                        value={props.fields.printed_name}
                        onChange={handleSetField.bind(this, 'printed_name')}
                    />
                </div>
                <div className="field signature-field">
                    <div className="label">Date:</div>
                    <input
                        type="text"
                        value={props.fields.date}
                        onChange={handleSetField.bind(this, 'date')}
                    />
                </div>
                <div className="recaptcha-container">
                    <ReCAPTCHA
                        hl="en"
                        ref={recaptchaRef}
                        sitekey="6LerIo4pAAAAADq-MX4J5eZekUgls7mCZKHMeCTL"
                        onChange={onChange}
                    />
                </div>
                <div className="button-container">
                    <button onClick={props.saveApplication.bind(this, recaptchaRef)} disabled={props.savingApplication}>
                        {buttonLabel}
                    </button>
                </div>
            </div>
        </div>
    );
});

export default Form;
