import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Requests from './Requests';
import DeleteModal from './DeleteModal';
import * as actions from './ChristyRfqs.actions';
import * as selectors from './ChristyRfqs.selectors';
import './ChristyRfqs.scss';
import PqtLayout from './../PqtLayout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import * as dialog from '../common/dialog';
import * as loginSelectors from './../Login/Login.selectors';
import { Redirect } from 'react-router-dom';

const ChristyRfqs = (props) => {
    /* State to props */
    const fetchingRequests = useSelector(selectors.getFetchingRequests);
    const requests = useSelector(selectors.getRequests, stringifyEqualityCheck);
    const pagination = useSelector(selectors.getPagination, stringifyEqualityCheck);
    const sort = useSelector(selectors.getSort, stringifyEqualityCheck);
    const idForDelete = useSelector(selectors.getIdForDelete, stringifyEqualityCheck);
    const isDeleting = useSelector(selectors.getIsDeleting, stringifyEqualityCheck);
    const filerFields = useSelector(selectors.getFilterFields, stringifyEqualityCheck);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const deleteRequest = useCallback((id, comments) => dispatch(actions.deleteRequest(id, comments)), [dispatch]);
    const setSort = useCallback((column, type) => dispatch(actions.setSort(column, type)), [dispatch]);
    const setActivePage = useCallback((name, value) => dispatch(actions.setActivePage(name, value)), [dispatch]);
    const fetchRequests = useCallback((options, isWsUpdate) => dispatch(actions.fetchRequests(options, isWsUpdate)), [dispatch]);
    const setIdForDelete = useCallback((id) => dispatch(actions.setIdForDelete(id)), [dispatch]);
    const changeFilter = useCallback((name, value, fetch) => dispatch(actions.changeFilter(name, value, fetch)), [dispatch]);
    const showDeleteModal = useCallback(() => dispatch(dialog.actions.showDialog('delete-modal')), [dispatch]);
    const hideDeleteModal = useCallback(() => dispatch(dialog.actions.hideDialog('delete-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        dispatch(actions.mountComponent());
    }, [dispatch]);

    if (user && user != null && ![1, 2].includes(user.christy_rfq_type)) {
        return <Redirect to="/dashboard" />;
    }

    return (
        <PqtLayout title="Customer RFQs" history={props.history}>
            <Helmet><title>Customer RFQs - Stagecoach</title></Helmet>
            <div className="quote-requests-container">
                <Requests
                    requests={requests}
                    pagination={pagination}
                    fetchingRequests={fetchingRequests}
                    history={props.history}
                    sort={sort}
                    user={user}
                    filerFields={filerFields}
                    setSort={setSort}
                    setActivePage={setActivePage}
                    showDeleteModal={showDeleteModal}
                    setIdForDelete={setIdForDelete}
                    changeFilter={changeFilter}
                    fetchRequests={fetchRequests}
                />
                <DeleteModal
                    idForDelete={idForDelete}
                    isDeleting={isDeleting}
                    deleteRequest={deleteRequest}
                    hideDeleteModal={hideDeleteModal}
                    setIdForDelete={setIdForDelete}
                />
            </div>
        </PqtLayout>
    );
};

export default ChristyRfqs;
