export const FETCH_CREDIT_MEMO = 'CREDIT_MEMO/FETCH_CREDIT_MEMO';
export const FETCH_CREDIT_MEMO_SUCCESS = 'CREDIT_MEMO/FETCH_CREDIT_MEMO_SUCCESS';
export const FETCH_CREDIT_MEMO_ERROR = 'CREDIT_MEMO/FETCH_CREDIT_MEMO_ERROR';
export const FETCH_INFORMATION = 'CREDIT_MEMO/FETCH_INFORMATION';
export const FETCH_INFORMATION_SUCCESS = 'CREDIT_MEMO/FETCH_INFORMATION_SUCCESS';
export const FETCH_INFORMATION_ERROR = 'CREDIT_MEMO/FETCH_INFORMATION_ERROR';
export const FETCH_CUSTOMER_DATA = 'CREDIT_MEMO/FETCH_CUSTOMER_DATA';
export const FETCH_CUSTOMER_DATA_SUCCESS = 'CREDIT_MEMO/FETCH_CUSTOMER_DATA_SUCCESS';
export const FETCH_CUSTOMER_DATA_ERROR = 'CREDIT_MEMO/FETCH_CUSTOMER_DATA_ERROR';
export const SAVE_CREDIT_MEMO = 'CREDIT_MEMO/SAVE_CREDIT_MEMO';
export const SAVE_CREDIT_MEMO_SUCCESS = 'CREDIT_MEMO/SAVE_CREDIT_MEMO_SUCCESS';
export const SAVE_CREDIT_MEMO_ERROR = 'CREDIT_MEMO/SAVE_CREDIT_MEMO_ERROR';
export const QB_PUSH = 'CREDIT_MEMO/QB_PUSH';
export const QB_PUSH_SUCCESS = 'CREDIT_MEMO/QB_PUSH_SUCCESS';
export const QB_PUSH_ERROR = 'CREDIT_MEMO/QB_PUSH_ERROR';
export const SET_FIELD = 'CREDIT_MEMO/SET_FIELD';
export const SET_ITEM_FIELD = 'CREDIT_MEMO/SET_ITEM_FIELD';
export const ADD_LINE = 'CREDIT_MEMO/ADD_LINE';
export const REMOVE_LINE = 'CREDIT_MEMO/REMOVE_LINE';
export const RESET_STATE = 'CREDIT_MEMO/RESET_STATE';
export const SET_CUSTOMER_EMAIL = 'CREDIT_MEMO/SET_CUSTOMER_EMAIL';
export const CHANGE_SORT_POSITION = 'CREDIT_MEMO/CHANGE_SORT_POSITION';