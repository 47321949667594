import axios from 'axios';
import * as types from './PqtPostalQualification.types';
import * as selectors from './PqtPostalQualification.selectors';
import {
    validateAuthInResponse,
    showCompleteDataNotification,
    showDataSavedNotification,
    sendWsMessage,
    showNotification,
} from './../../utils';
import * as dialog from '../common/dialog';

const apiUrl = process.env.REACT_APP_API_URL;

export const save = callback => (dispatch, getState) => {
    const element = selectors.getElement(getState());
    const type = selectors.getType(getState());
    const elementId = selectors.getElementId(getState());

    let attributes = [
        'mail_owner_description',
    ];

    if(parseInt(element.agent_name) === 2) {
        attributes.push('agent_name_who');
    }
    if(parseInt(element.permit_holder) === 2) {
        attributes.push('permit_holder_other');
    }
    if(parseInt(element.permit_number) === 5) {
        attributes.push('permit_number_other');
    }
    if(parseInt(element.entry_post_office) === 6) {
        attributes.push('entry_post_office_other');
    }
    if(parseInt(element.full_job_service) === 1) {
        attributes.push('full_job_service_agent_crid');
        attributes.push('full_job_service_prepared_crid');
        attributes.push('full_job_service_permit_crid');
    }
    if(parseInt(element.postal_payment) === 3 && parseInt(element.postal_payment_meter) === 3) {
        attributes.push('postal_payment_meter_specific');
    }
    if(parseInt(element.mail_class) === 1) {
        if(parseInt(element.sort_choice) === 3) {
            attributes.push('sort_by_specific');
        }
    } else if(parseInt(element.mail_class) === 2) {
        attributes.push('publication_number');
        attributes.push('edition');
        attributes.push('issue_date');
        attributes.push('percentage_advertising');
    } else if(parseInt(element.mail_class) === 4) {
        if(parseInt(element.presort_by) === 9) {
            attributes.push('sort_by_specific');
        }
    }

    for (let i = 0; i < attributes.length; i++) {
        if (element[attributes[i]] === undefined || element[attributes[i]] === '') {
            showCompleteDataNotification();
            return;
        }
    }

    if (parseInt(type) === 1) {
        element.pqt_tracking_step_id = elementId;
    } else if (parseInt(type) === 2) {
        element.quote_id = elementId;
    } else if (parseInt(type) === 3) {
        element.order_id = elementId;
    } else {
        showNotification('Incomplete data', 'The data is incomplete and it cannot be saved', 'warning');
        return;
    }

    dispatch({ type: types.SAVE, payload: null });
    let promise;
    let saveType;
    if (element.id === undefined) {
        saveType = 1;
        promise = axios.post(apiUrl + '/pqt-postal-qualifications', element, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } });
    } else {
        saveType = 2;
        promise = axios.put(apiUrl + '/pqt-postal-qualifications/' + element.id, element, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } });
    }

    promise
        .then((response) => {
            dispatch({ type: types.SAVE_SUCCESS, payload: { element: response.data, saveType } });
            showDataSavedNotification();
            if (element.id === undefined) {
                dispatch(sendWsMessage({ id: response.data.id, type: 'pqt_postal_qualification_created' }));
                dispatch(dialog.actions.hideDialog('pqt-postal-qualification-form'));
            } else {
                dispatch(sendWsMessage({ id: response.data.id, type: 'pqt_postal_qualification_updated' }));
                dispatch(dialog.actions.hideDialog('pqt-postal-qualification-form'));
            }
            if(callback) {
                callback();
            }
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.SAVE_ERROR));
        });
}

export const setField = (name, value) => dispatch => {
    dispatch({
        type: types.SET_FIELD,
        payload: { name, value }
    });
}

export const resetState = () => dispatch => {
    dispatch({
        type: types.RESET_STATE,
        payload: null
    });
}

export const setType = type => dispatch => {
    dispatch({
        type: types.SET_TYPE,
        payload: type
    });
}

export const setElementId = id => dispatch => {
    dispatch({
        type: types.SET_ELEMENT_ID,
        payload: id
    });
}

export function setFields(fields) {
    return {
        type: types.SET_FIELDS,
        payload: fields
    };
}

export const fetchElement = id => dispatch => {
    dispatch({ type: types.FETCH_ELEMENT, payload: null });
    axios.get(apiUrl + '/pqt-postal-qualifications/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_ELEMENT_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_ELEMENT_ERROR));
        });
}

export const setShowSave = value => dispatch => {
    dispatch({
        type: types.SET_SHOW_SAVE,
        payload: value
    });
}
