import Immutable from 'immutable';
import * as types from './PqtDataProcessing.types';

const initialState = {
    element: {
        total_file: 1,
        merge_files: 0,
        dedupe_files: 0,
        dedupe_files_by: 1,
        select_records: 1,
        include_foreign: 1,
        cass_dpv_suitelink: 1,
        ncoa_link: 1,
        add_seeds: 0,
        add_current_resident: 0,
        change_case: 0,
        change_case_specify: 1,
        genderize: 0,
        create_salutation: 0,
        add_special_field: 0,
        add_tracking_codes: 0,
        add_tracking_codes_specify: 1,
        generate_inkjet_output: 0,
        generate_imaging_output: 0,
        generate_imaging_output_specify: 1,
        generate_file_output: 0,
        custom_programming: 0,
    },
    type: null,
    elementId: null,
    saving: false,
    fetching: false,
    showSave: true,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.SET_FIELD:
            return state.setIn(['element', action.payload.name], action.payload.value).toJS();

        case types.SAVE:
            return state.setIn(['saving'], true).toJS();

        case types.SAVE_SUCCESS:
            return state.setIn(['saving'], false).toJS();

        case types.SAVE_ERROR:
            return state.setIn(['saving'], false).toJS();

        case types.SET_TYPE:
            return state.setIn(['type'], action.payload).toJS();

        case types.SET_ELEMENT_ID:
            return state.setIn(['elementId'], action.payload).toJS();

        case types.FETCH_ELEMENT:
            return state.setIn(['fetching'], true).toJS();

        case types.FETCH_ELEMENT_SUCCESS:
            return state.setIn(['fetching'], false)
                .setIn(['element'], action.payload)
                .toJS();

        case types.FETCH_ELEMENT_ERROR:
            return state.setIn(['fetching'], false).toJS();

        case types.SET_SHOW_SAVE:
            return state.setIn(['showSave'], action.payload).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}