import React from 'react';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import PqtPagination from './../../PqtPagination';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import moment from 'moment-timezone';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { dateToTimezone } from './../../../utils';

const Invoices = React.memo((props) => {
    const renderInvoices = () => {
        if (props.fetching) {
            return (
                <tr>
                    <td colSpan="17"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.invoices.length === 0) {
            return (
                <tr>
                    <td colSpan="17">No Results</td>
                </tr>
            );
        }

        return props.invoices.map((invoice, index) => {
            let csrName = invoice.csr_name;
            const csrNameParts = csrName.split(' ');
            if (csrNameParts.length > 0) {
                csrName = csrNameParts[0];
            }

            let salesName = invoice.sales_rep_name;
            const salesNameParts = salesName.split(' ');
            if (salesNameParts.length > 0) {
                salesName = salesNameParts[0];
            }

            let status = 'N/A';
            if (parseInt(invoice.status) === 1) {
                status = 'Preliminary invoice is ready';
            } else if (parseInt(invoice.status) === 2) {
                status = 'Closed and ready to start invoicing';
            } else if (parseInt(invoice.status) === 3) {
                status = 'Approved ready for accounting';
            } else if (parseInt(invoice.status) === 4) {
                status = 'Final & Archived';
            }

            let stagecoachNumber = 'N/A';
            if (invoice.stagecoach_number != null && invoice.stagecoach_number !== '') {
                stagecoachNumber = invoice.stagecoach_number;
            }

            let combinedLabel = null;
            if (invoice.combined_ids !== null) {
                combinedLabel = <span className="combined">(Combined)</span>;
            }

            let total = 0;
            if (invoice.total != null) {
                total = invoice.total.toFixed(2);
            }

            let projectType = '-';
            if (parseInt(invoice.project_type) === 1) {
                projectType = 'Regular';
            } else if (parseInt(invoice.project_type) === 2) {
                projectType = 'Daily';
            } else if (parseInt(invoice.project_type) === 3) {
                projectType = 'Weekly';
            } else if (parseInt(invoice.project_type) === 4) {
                projectType = 'Monthly';
            } else if (parseInt(invoice.project_type) === 5) {
                projectType = 'Partial';
            }

            let deletedDate = '-';
            if (invoice.deleted_datetime != null) {
                deletedDate = dateToTimezone(invoice.deleted_datetime, 'MM/DD/YYYY');
            }

            return (
                <tr key={'invoice-' + index}>
                    <td>{invoice.id}</td>
                    <td><Link to={'/tracking/' + invoice.stagecoach_number}>{stagecoachNumber}</Link></td>
                    <td>{invoice.customer_name} {combinedLabel}</td>
                    <td>{csrName}</td>
                    <td>{salesName}</td>
                    <td>{status}</td>
                    <td>{projectType}</td>
                    <td>{deletedDate}</td>
                    <td>{numeral(total).format('$0,0.00')}</td>
                    <td>{invoice.delete_comments}</td>
                    <td>
                        <div className="actions">
                            <Tooltip title="View Invoice" position="bottom" arrow size="small" className="action-tooltip">
                                <Link to={'/invoice/' + invoice.id}><i className="fas fa-edit" /></Link>
                            </Tooltip>
                            <Tooltip title="View Job" position="bottom" arrow size="small" className="action-tooltip">
                                <Link to={'/tracking/' + invoice.stagecoach_number}><i className="fas fa-info-circle" /></Link>
                            </Tooltip>
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const setSortHandler = (field, value) => {
        props.setSort(field, value);
    };

    const renderHeader = (name, column, className) => {
        let sortFunc = setSortHandler.bind(this, column, 'asc');
        let sortIcon = <i className="fas fa-sort" />;
        if (props.sort.column === column) {
            if (props.sort.type === 'asc') {
                sortFunc = setSortHandler.bind(this, column, 'desc');
                sortIcon = <i className="fas fa-sort-up" />;
            } else {
                sortFunc = setSortHandler.bind(this, column, 'asc');
                sortIcon = <i className="fas fa-sort-down" />;
            }
        }

        return <th className={className} onClick={sortFunc}>{name} {sortIcon}</th>;
    };

    const handleChangeFilter = (name, fetch, event) => {
        props.changeFilter(name, event.target.value, fetch);
    };

    const handleKey = (event) => {
        if (event.key === 'Enter') {
            props.fetchDeletedInvoices()
        }
    };

    const renderSearchField = (name) => {
        let value = null;
        for (let i = 0; i < props.filerFields.length; i++) {
            if (props.filerFields[i].name === name) {
                value = props.filerFields[i].value;
            }
        }

        return (
            <th className="search-th">
                <input
                    type="text"
                    name={name}
                    className="search-input"
                    value={value}
                    onKeyPress={handleKey}
                    onChange={handleChangeFilter.bind(this, name, false)}
                />
            </th>
        );
    };

    const handleChangeSelectFilter = (name, event) => {
        props.changeFilter(name, event.target.value, true);
    };

    const renderSearchSelect = (name, options) => {
        let value = '';
        for (let i = 0; i < props.filerFields.length; i++) {
            if (props.filerFields[i].name === name) {
                value = props.filerFields[i].value;
            }
        }

        return (
            <th className="search-th">
                <select
                    name={name}
                    className="search-input"
                    value={value}
                    onChange={handleChangeSelectFilter.bind(this, name)}
                >
                    {options.map((option) => {
                        return <option value={option.value}>{option.label}</option>;
                    })}
                </select>
            </th>
        );
    };

    const setDateFieldHandler = (name, event) => {
        let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');
        props.changeFilter(name, value, true);
    };

    const renderSearchDateField = (name) => {
        let value = null;
        for (let i = 0; i < props.filerFields.length; i++) {
            if (props.filerFields[i].name === name) {
                value = props.filerFields[i].value;
            }
        }

        return (
            <th className="search-th">
                <DayPickerInput
                    formatDate={formatDate}
                    parseDate={parseDate}
                    placeholder=""
                    className="date-picker-field"
                    onDayChange={setDateFieldHandler.bind(this, name)}
                    value={value == null || value === '0000-00-00' ? '' : moment(value).format('MM/DD/YYYY')}
                />
            </th>
        )
    };

    return (
        <div className="invoices-container">
            <div className="table-container">
                <table className="table table-sm table-hover table-striped">
                    <thead>
                        <tr>
                            {renderHeader('No.', 'id', 'th-id')}
                            {renderHeader('SC #', 'stagecoach_number', 'th-stagecoach-number')}
                            {renderHeader('Customer', 'customer_name', 'th-customer-name')}
                            {renderHeader('CSR', 'csr_name', 'th-csr')}
                            {renderHeader('Sales', 'sales_rep_name', 'th-sales-rep')}
                            {renderHeader('Status', 'status', 'th-status')}
                            {renderHeader('Project Type', 'project_type', 'th-status')}
                            {renderHeader('Daleted Date', 'deleted_datetime', 'th-status')}
                            <th className="th-total">Total</th>
                            {renderHeader('Delete Comments', 'delete_comments', 'th-status')}
                            <th style={{ width: 70 }}></th>
                        </tr>
                        <tr>
                            {renderSearchField('id')}
                            {renderSearchField('stagecoach_number')}
                            {renderSearchField('customer_name')}
                            {renderSearchField('csr_name')}
                            {renderSearchField('sales_rep_name')}
                            {renderSearchSelect('status', [
                                { value: '', label: '' },
                                { value: 1, label: 'Preliminary invoice is ready' },
                                { value: 2, label: 'Closed and ready to start invoicing' },
                                { value: 3, label: 'Approved ready for accounting' },
                                { value: 4, label: 'Final & Archived' },
                            ])}
                            {renderSearchSelect('project_type', [
                                { value: '', label: '' },
                                { value: 1, label: 'Regular' },
                                { value: 2, label: 'Daily' },
                                { value: 3, label: 'Weekly' },
                                { value: 4, label: 'Monthly' },
                                { value: 5, label: 'Partial' },
                            ])}
                            {renderSearchDateField('deleted_datetime')}
                            <th className="th-total">Total</th>
                            {renderSearchField('delete_comments')}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderInvoices()}
                    </tbody>
                </table>
                <PqtPagination
                    pagination={props.pagination}
                    setActivePage={props.setActivePage}
                />
            </div>
        </div>
    );
});

Invoices.propTypes = {

};

export default Invoices;
