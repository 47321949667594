import React, { useState } from 'react';
//import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';

const ReturnInvoiceModal = React.memo((props) => {
    const [stage, setStage] = useState('');

    const hideHandler = () => {
        props.hideReturnInvoiceModal();
    };

    let label = 'Accept';
    if (props.isReturning) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    const handleReturnInvoice = () => {
        props.returnInvoice(stage);
    };

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Cancel</button>,
        <button key="delete-button" className="btn btn-light btn-sm action-button" onClick={handleReturnInvoice} disabled={props.isReturning}>{label}</button>
    ];
    return (
        <Dialog
            name="return-invoice-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="return-invoice-dialog"
        >
            <div className="return-invoice-container">
                <h1>Move Invoice to another stage?</h1>
                <p className="warning">Warning: This action will move this invoice to another stage.</p>
                <div className="field">
                    <div className="label">Stage:</div>
                    <select value={stage} onChange={(event) => setStage(event.target.value)}>
                        <option value="">Select Stage</option>
                        <option value="2">Closed Out</option>
                        <option value="3">Approved</option>
                        <option value="4">Final and Archived</option>
                    </select>
                </div>
            </div>
        </Dialog >
    );
});

ReturnInvoiceModal.propTypes = {

};

export default ReturnInvoiceModal;
