import { createSelector } from 'reselect';

export const getComponentState = state => state.apRecords;

export const getFetchingRecords = createSelector(
    getComponentState,
    (state) => state.fetchingRecords
);
export const getRecords = createSelector(
    getComponentState,
    (state) => state.records
);
export const getPagination = createSelector(
    getComponentState,
    (state) => state.pagination
);
export const getSort = createSelector(
    getComponentState,
    (state) => state.sort
);
export const getIdForDelete = createSelector(
    getComponentState,
    (state) => state.delete.id
);
export const getIsDeleting = createSelector(
    getComponentState,
    (state) => state.delete.isDeleting
);
export const getFilterFields = createSelector(
    getComponentState,
    (state) => state.filter.fields
);
export const getFields = createSelector(
    getComponentState,
    (state) => state.fields
);
export const getFetchingRecord = createSelector(
    getComponentState,
    (state) => state.fetchingRecord
);
export const getSavingRecord = createSelector(
    getComponentState,
    (state) => state.savingRecord
);
