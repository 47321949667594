import React, { useState } from 'react';
import { Dialog } from './../../common/dialog';

const DeleteModal = React.memo((props) => {
    const [confirmValue, setConfirmValue] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [comments, setComments] = useState('');
    const [commentsErrorMessage, setCommentsErrorMessage] = useState('');

    const hideHandler = () => {
        setConfirmValue('');
        setErrorMessage('');
        setComments('');
        setCommentsErrorMessage('');
        props.setIdForDelete(null);
        props.hideDeleteModal();
    };

    const deleteHandler = () => {
        let continueToDelete = true;
        if (confirmValue === '') {
            setErrorMessage('The Request # is empty');
            continueToDelete = false;
        }

        if (parseInt(props.idForDelete) !== parseInt(confirmValue)) {
            setErrorMessage('The Request # is incorrect');
            continueToDelete = false;
        }

        if (comments === '') {
            setCommentsErrorMessage('The comments are empty');
            continueToDelete = false;
        }

        if (!continueToDelete) {
            return;
        }

        props.deleteRequest(props.idForDelete, comments);
        setConfirmValue('');
        setComments('');
        setErrorMessage('');
        setCommentsErrorMessage('');
    };

    const handleChangeConfirmValue = (event) => {
        setErrorMessage('');
        setConfirmValue(event.target.value);
    };

    const handleChangeComments = (event) => {
        setCommentsErrorMessage('');
        setComments(event.target.value);
    };

    let label = 'Delete';
    if (props.isDeleting) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Cancel</button>,
        <button key="delete-button" className="btn btn-light btn-sm action-button" onClick={deleteHandler} disabled={props.isDeleting}>{label}</button>
    ];
    return (
        <Dialog
            name="delete-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="delete-dashboard-dialog"
        >
            <div className="delete-container">
                <h1>Delete Request?</h1>
                <p className="warning">Warning: This action cannot be undone.</p>
                <p>Confirm the Request # that you want to delete to continue:</p>
                <h2>Request #:</h2>
                <div>
                    <input type="text" value={confirmValue} onChange={handleChangeConfirmValue} placeholder="Confirm Request #" />
                </div>
                <div className="error-message">{errorMessage !== '' && errorMessage}</div>
                <h2 className="comments-header">Comments:</h2>
                <div>
                    <textarea value={comments} onChange={handleChangeComments} placeholder="Enter your comments about this action" />
                </div>
                <div className="error-message">{commentsErrorMessage !== '' && commentsErrorMessage}</div>
            </div>
        </Dialog >
    );
});

DeleteModal.propTypes = {

};

export default DeleteModal;
