export const FETCH_PURCHASE_ORDER = 'PURCHASE_ORDER/FETCH_PURCHASE_ORDER';
export const FETCH_PURCHASE_ORDER_SUCCESS = 'PURCHASE_ORDER/FETCH_PURCHASE_ORDER_SUCCESS';
export const FETCH_PURCHASE_ORDER_ERROR = 'PURCHASE_ORDER/FETCH_PURCHASE_ORDER_ERROR';
export const FETCH_INFORMATION = 'PURCHASE_ORDER/FETCH_INFORMATION';
export const FETCH_INFORMATION_SUCCESS = 'PURCHASE_ORDER/FETCH_INFORMATION_SUCCESS';
export const FETCH_INFORMATION_ERROR = 'PURCHASE_ORDER/FETCH_INFORMATION_ERROR';
export const FETCH_VENDOR_DATA = 'PURCHASE_ORDER/FETCH_VENDOR_DATA';
export const FETCH_VENDOR_DATA_SUCCESS = 'PURCHASE_ORDER/FETCH_VENDOR_DATA_SUCCESS';
export const FETCH_VENDOR_DATA_ERROR = 'PURCHASE_ORDER/FETCH_VENDOR_DATA_ERROR';
export const SAVE_PURCHASE_ORDER = 'PURCHASE_ORDER/SAVE_PURCHASE_ORDER';
export const SAVE_PURCHASE_ORDER_SUCCESS = 'PURCHASE_ORDER/SAVE_PURCHASE_ORDER_SUCCESS';
export const SAVE_PURCHASE_ORDER_ERROR = 'PURCHASE_ORDER/SAVE_PURCHASE_ORDER_ERROR';
export const QB_PUSH = 'PURCHASE_ORDER/QB_PUSH';
export const QB_PUSH_SUCCESS = 'PURCHASE_ORDER/QB_PUSH_SUCCESS';
export const QB_PUSH_ERROR = 'PURCHASE_ORDER/QB_PUSH_ERROR';
export const SET_FIELD = 'PURCHASE_ORDER/SET_FIELD';
export const SET_ITEM_FIELD = 'PURCHASE_ORDER/SET_ITEM_FIELD';
export const ADD_LINE = 'PURCHASE_ORDER/ADD_LINE';
export const REMOVE_LINE = 'PURCHASE_ORDER/REMOVE_LINE';
export const RESET_STATE = 'PURCHASE_ORDER/RESET_STATE';
export const SET_VENDOR_EMAIL = 'PURCHASE_ORDER/SET_VENDOR_EMAIL';
export const CHANGE_SORT_POSITION = 'PURCHASE_ORDER/CHANGE_SORT_POSITION';