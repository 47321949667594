import { createSelector } from 'reselect';

export const getComponentState = state => state.makePayment;

export const getFields = createSelector(
    getComponentState,
    (state) => state.fields
);
export const getPayment = createSelector(
    getComponentState,
    (state) => state.payment
);
export const getFetchingPayment = createSelector(
    getComponentState,
    (state) => state.fetchingPayment
);
export const getGeneratingToken = createSelector(
    getComponentState,
    (state) => state.generatingToken
);
export const getSavingPayment = createSelector(
    getComponentState,
    (state) => state.savingPayment
);
export const getToken = createSelector(
    getComponentState,
    (state) => state.token
);
export const getInvoiceToken = createSelector(
    getComponentState,
    (state) => state.invoice_token
);
export const getPaymenType = createSelector(
    getComponentState,
    (state) => state.paymenType
);
export const getRecaptchaToken = createSelector(
    getComponentState,
    (state) => state.recaptchaToken
);
