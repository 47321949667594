import axios from 'axios';
import * as types from './Estimate.types';
import * as selectors from './Estimate.selectors';
import { validateAuthInResponse, showNotification, empty, showDataDeletedNotification, isEstimator } from './../../utils';
import * as dialog from '../common/dialog';
import numeral from 'numeral';
import fileDownload from 'js-file-download';
import * as loginSelectors from './../Login/Login.selectors';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchEstimate = (id) => (dispatch, getState) => {
    const dataProcessingItems = selectors.getDataProcessingItems(getState());
    const variablePrintItems = selectors.getVariablePrintItems(getState());
    const inkjetItems = selectors.getInkjetItems(getState());
    const insertingItems = selectors.getInsertingItems(getState());
    const meteringItems = selectors.getMeteringItems(getState());
    const handworkItems = selectors.getHandworkItems(getState());

    if (id == null) {
        return;
    }

    dispatch({ type: types.FETCH_ESTIMATE, payload: null });
    axios.get(apiUrl + '/sc-estimates/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_ESTIMATE_SUCCESS, payload: response.data });
            dispatch(addEstimateData(true));

            if (!empty(response.data.main_part_id)) {
                dispatch(setMainPartId(response.data.main_part_id));
                dispatch(fetchMainPart(response.data.main_part_id));
            }

            // Estimate just created from RFQ
            if (parseInt(response.data.status) === 1 && response.data.sc_quote_request_id != null
                && response.data.sc_quote_request_id !== '' && response.data.result == null) {
                dispatch(setDataChanged(true));
                dispatch(setEstimateChanged(true));
            }

            let mailServices = JSON.parse(response.data.mail_services);

            dispatch(setMailItems(mailServices, dataProcessingItems, 1, setDataProcessingItem));
            dispatch(setMailItems(mailServices, variablePrintItems, 2, setVariablePrintItem));
            dispatch(setMailItems(mailServices, inkjetItems, 3, setInkjetItem));
            dispatch(setMailItems(mailServices, insertingItems, 4, setInsertingItem));
            dispatch(setMailItems(mailServices, meteringItems, 5, setMeteringItem));
            dispatch(setMailItems(mailServices, handworkItems, 6, setHandworkItem));

            dispatch(addMailServicesItems());

            if (parseInt(response.data.type) === 6) {   // Large Format
                dispatch(fetchSubstrates(response.data.output_device));
                dispatch(fetchSubstrateAccessories(response.data.substrate));
                dispatch(fetchSubstrateLaminates(response.data.substrate));
            }

            if (!empty(response.data.delivery_charge)) {
                const deliveryCharge = JSON.parse(response.data.delivery_charge);
                dispatch(setDeliveryChargeField('description', deliveryCharge.description));
                dispatch(setDeliveryChargeField('quantity', deliveryCharge.quantity));
                dispatch(setDeliveryChargeField('total', deliveryCharge.total));
                dispatch(setShowDeliveryCharge(true));
            }

            if (response.data.sc_quote_request_id && response.data.sc_quote_request_id != null) {
                dispatch(fetchRfq(response.data.sc_quote_request_id));
            }
        })
        .catch((error) => {
            console.log(error)
            dispatch(validateAuthInResponse(error, types.FETCH_ESTIMATE_ERROR));
        });
}

export const fetchMainPart = (id) => (dispatch, getState) => {
    if (id == null) {
        return;
    }

    dispatch({ type: types.FETCH_MAIN_PART, payload: null });
    axios.get(apiUrl + '/sc-estimates/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_MAIN_PART_SUCCESS, payload: response.data });
            dispatch(setField('csr_id', response.data.csr_id));
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_MAIN_PART_ERROR));
        });
}

export const fetchRfq = (id) => (dispatch, getState) => {
    if (id == null) {
        return;
    }

    dispatch({ type: types.FETCH_RFQ, payload: null });
    axios.get(apiUrl + '/sc-quote-requests/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_RFQ_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_RFQ_ERROR));
        });
}

export const setMailItems = (mailServices, items, type, setFunc) => (dispatch) => {
    for (let i = 0; i < mailServices.length; i++) {
        for (let j = 0; j < items.length; j++) {
            if (parseInt(mailServices[i].type) === type && parseInt(mailServices[i].id) === parseInt(items[j].id)) {
                dispatch(setFunc(items[j].id, 'checked', 1));
                dispatch(setFunc(items[j].id, 'quantity', mailServices[i].quantity));
            }
        }
    }
}

export const fetchInformation = (id = null, customerId = null) => (dispatch) => {
    dispatch({ type: types.FETCH_INFORMATION, payload: null });
    axios.get(apiUrl + '/sc-estimate/information',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_INFORMATION_SUCCESS, payload: response.data });

            if (id != null) {
                dispatch(fetchEstimate(id));
            }
            if (customerId != null) {
                let customer = null;
                for (let i = 0; i < response.data.customers.length; i++) {
                    if (parseInt(response.data.customers[i].id) === parseInt(customerId)) {
                        customer = response.data.customers[i];
                        break;
                    }
                }

                dispatch(setField('address', customer.display_name + "\n" + customer.shipping_address + "\n" + customer.shipping_city
                    + ', ' + customer.shipping_state + ' ' + customer.shipping_zip));
            }
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_INFORMATION_ERROR));
        });
}

export const fetchSubstrates = (id) => (dispatch) => {
    dispatch({ type: types.FETCH_SUBSTRATES, payload: null });
    axios.get(apiUrl + '/sc-estimate/substrates?id=' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_SUBSTRATES_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_SUBSTRATES_ERROR));
        });
}

export const fetchSubstrateAccessories = (id) => (dispatch) => {
    dispatch({ type: types.FETCH_SUBSTRATE_ACCESSORIES, payload: null });
    axios.get(apiUrl + '/sc-quote-request/substrate-accessories?id=' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_SUBSTRATE_ACCESSORIES_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_SUBSTRATE_ACCESSORIES_ERROR));
        });
}

export const fetchSubstrateLaminates = (id) => (dispatch) => {
    dispatch({ type: types.FETCH_SUBSTRATE_LAMINATES, payload: null });
    axios.get(apiUrl + '/sc-quote-request/substrate-laminates?id=' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_SUBSTRATE_LAMINATES_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_SUBSTRATE_LAMINATES_ERROR));
        });
}

export const matrixEstimate = () => (dispatch, getState) => {
    const estimate = selectors.getEstimate(getState());
    const estimateType = selectors.getEstimateType(getState());
    const selectedAdditionalServices = selectors.getSelectedAdditionalServices(getState());

    if (!validateFields(estimate, estimateType)) {
        return;
    }
    if (empty(estimate.start_qty)) {
        return showNotification('Complete Information', 'enter the Start Quantity', 'info');
    }
    if (empty(estimate.end_qty)) {
        return showNotification('Complete Information', 'Enter the Enter Quantity', 'info');
    }
    if (empty(estimate.increment)) {
        return showNotification('Complete Information', 'Enter the Increment', 'info');
    }

    dispatch({ type: types.MULTI_ESTIMATE, payload: null });
    axios.post(apiUrl + '/sc-estimate/estimate', { additionalServices: selectedAdditionalServices, ...estimate, type: estimateType, multi: 1 },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.MULTI_ESTIMATE_SUCCESS, payload: response.data });

            if (response.data.success && response.data.results.length > 0) {
                dispatch(setMultiResults(response.data.results));
                dispatch(dialog.actions.showDialog('matrix-modal'));
            } else {
                dispatch(setMultiResults([]));
                showNotification('No Estimates', 'No estimations found for the provided information', 'info');
            }
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.MULTI_ESTIMATE_ERROR));
        });
}

export const calculateEstimate = (fromAdditional = false) => (dispatch, getState) => {
    const estimate = selectors.getEstimate(getState());
    const estimateType = selectors.getEstimateType(getState());
    const selectedAdditionalServices = selectors.getSelectedAdditionalServices(getState());
    const selectedAccessories = selectors.getSelectedAccessories(getState());

    if (!validateFields(estimate, estimateType)) {
        return;
    }

    dispatch({ type: types.ESTIMATE, payload: null });
    axios.post(apiUrl + '/sc-estimate/estimate',
        {
            additionalServices: selectedAdditionalServices,
            ...estimate,
            type: estimateType,
            accessories: selectedAccessories,
        },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.ESTIMATE_SUCCESS, payload: response.data });
            dispatch(setDataChanged(false));

            const resultIndex = estimate.selected_quantity - 1;

            let result = null;
            if (parseInt(estimateType) === 2) {   // Envelopes
                if (response.data.success && response.data.totals) {
                    result = response.data.result;
                } else if (response.data.success && response.data.results[resultIndex].results.length > 0) {
                    result = response.data.results[resultIndex].results[0];
                }
            } else {
                if (response.data.success && response.data.results[resultIndex].results.length > 0) {
                    result = response.data.results[resultIndex].results[0];
                }
            }

            if (result != null) {
                dispatch(addEstimateData(fromAdditional));
                showNotification('Estimate ready', 'The estimate has been calculated', 'success');
            } else {
                dispatch(resetResult());

                for (let i = 0; i < selectedAdditionalServices.length; i++) {
                    const item = selectedAdditionalServices[i];
                    dispatch(updateAdditionalService(item.id, 'quantity', null));
                    dispatch(updateAdditionalService(item.id, 'total', null));
                }

                showNotification('No Estimate', 'No estimation found for the provided information', 'info');
            }
        })
        .catch((error) => {
            console.log(error)
            dispatch(validateAuthInResponse(error, types.ESTIMATE_ERROR));
        });
}

export const approve = () => (dispatch, getState) => {
    const id = selectors.getId(getState());
    dispatch({ type: types.APPROVE_ESTIMATE, payload: null });
    axios.post(apiUrl + '/sc-estimate/approve', { id },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.APPROVE_ESTIMATE_SUCCESS, payload: response.data });
            showNotification('Estimate Approved', 'The Estimate has been approved', 'success');
            dispatch(fetchEstimate(id));
            dispatch(dialog.actions.hideDialog('confirm-approve-modal'));
        })
        .catch((error) => {
            if (error.response && error.response.status === 406) {
                showNotification('Parts not ready', 'Not all the parts have been estimated. Please Estimate and Save all the parts before to proceed.', 'info');
                dispatch({ type: types.APPROVE_ESTIMATE_ERROR, payload: null });
            } else if (error.response && error.response.status === 401) {
                dispatch(validateAuthInResponse(error, types.APPROVE_ESTIMATE_ERROR));
            }
        });
}

export const convertToJob = () => (dispatch, getState) => {
    const id = selectors.getId(getState());
    dispatch({ type: types.CONVERT_TO_JOB, payload: null });
    axios.post(apiUrl + '/sc-estimate/convert-to-job', { id },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.CONVERT_TO_JOB_SUCCESS, payload: response.data });
            showNotification('SC Job Created', 'A SC Job has been created for this Estimate', 'success');
            dispatch(fetchEstimate(id));
            dispatch(dialog.actions.hideDialog('confirm-convert-modal'));
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.CONVERT_TO_JOB_ERROR));
        });
}

export const sendEstimate = () => (dispatch, getState) => {
    const id = selectors.getId(getState());
    dispatch({ type: types.SEND_ESTIMATE, payload: null });
    axios.post(apiUrl + '/sc-estimate/send', { id },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.SEND_ESTIMATE_SUCCESS, payload: response.data });
            showNotification('Estimate Sent', 'The Estimate has been sent successfully to the Sales Rep and CSR', 'success');
            dispatch(dialog.actions.hideDialog('confirm-send-modal'));
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.SEND_ESTIMATE_ERROR));
        });
}

export const sendWrongEstimate = () => (dispatch, getState) => {
    const estimate = selectors.getEstimate(getState());
    const estimateChanged = selectors.getEstimateChanged(getState());
    const comments = selectors.getComments(getState());
    const files = selectors.getFiles(getState());

    if (empty(comments)) {
        return showNotification('Complete Information', 'Enter your comments', 'info');
    }
    if (estimateChanged) {
        return showNotification('Complete Information', 'There are unsaved changes', 'info');
    }

    var formData = new FormData();
    formData.append('id', estimate.id);
    formData.append('comments', comments);
    for (let i = 1; i <= files.length; i++) {
        formData.append('file' + i, files[i - 1]);
    }
    formData.append('filesLength', files.length);

    dispatch({ type: types.SEND_WRONG_ESTIMATE, payload: null });
    axios({
        method: 'post',
        url: apiUrl + '/sc-estimate/save-wrong-estimate',
        data: formData,
        headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
    }).then((response) => {
        dispatch({ type: types.SEND_WRONG_ESTIMATE_SUCCESS, payload: response.data });
        showNotification('Comments Sent', 'Your comments has been sent successfully, we will review them. Thank you!', 'success');
        dispatch(dialog.actions.hideDialog('wrong-estimate-modal'));
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.SEND_WRONG_ESTIMATE_ERROR));
    });
}

export const downloadEstimate = () => (dispatch, getState) => {
    const estimate = selectors.getEstimate(getState());

    dispatch({ type: types.DOWNLOAD_ESTIMATE, payload: null });
    axios.post(apiUrl + '/sc-estimate/download', { id: estimate.id },
        {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
            responseType: 'blob',
        })
        .then((response) => {
            dispatch({ type: types.DOWNLOAD_ESTIMATE_SUCCESS, payload: null });
            fileDownload(response.data, 'Estimate #' + estimate.id + '.pdf');
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.DOWNLOAD_ESTIMATE_ERROR));
        });
}

export const downloadPressTicket = () => (dispatch, getState) => {
    const estimate = selectors.getEstimate(getState());

    dispatch({ type: types.DOWNLOAD_PRESS_TICKET, payload: null });
    axios.post(apiUrl + '/sc-estimate/download-press-ticket', { id: estimate.id },
        {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
            responseType: 'blob',
        })
        .then((response) => {
            dispatch({ type: types.DOWNLOAD_PRESS_TICKET_SUCCESS, payload: null });
            fileDownload(response.data, 'Press Ticket #' + estimate.id + '.pdf');
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.DOWNLOAD_PRESS_TICKET_ERROR));
        });
}

export const exportMatrix = () => (dispatch, getState) => {
    const estimate = selectors.getEstimate(getState());
    const multiResults = selectors.getMultiResults(getState());
    const estimateType = selectors.getEstimateType(getState());
    const selectedAdditionalServices = selectors.getSelectedAdditionalServices(getState());
    const showDeliveryCharge = selectors.getShowDeliveryCharge(getState());
    const deliveryCharge = selectors.getDeliveryCharge(getState());

    const resultsData = [];

    multiResults.map((multiResult) => {
        let result = null;
        if (parseInt(estimateType) === 2) {   // Envelopes
            result = multiResult;
        } else {
            result = multiResult.results[0];
        }

        let subtotal = 0;
        let markupPrice = 0;
        let commission = 0;
        let outsidePrice = 0;
        let tax = 0;
        let total = 0;
        if (parseInt(estimateType) === 1 || parseInt(estimateType) === 7) {     // Sheetfed or Pocket Folder
            /*************************** Subtotal **************************/
            if (isDigital(estimate.output_device)) {
                subtotal += result.digital_machine_information.total_cost + result.paper_information.total_cost + result.cutting_information.total_cost;
            } else {
                subtotal += result.press_information.total_running_cost + result.press_information.total_setup_cost + result.paper_information.total_cost +
                    + result.press_information.total_plates_cost + result.ink_information.total_cost + result.cutting_information.total_cost;
            }

            for (let i = 0; i < selectedAdditionalServices.length; i++) {
                const item = selectedAdditionalServices[i];

                if (item.id === 1) {       // Folding
                    subtotal += result.folding_information.total_cost;
                } else if (item.id === 2) {    // Scoring
                    subtotal += result.scoring_information.total_cost;
                } else if (item.id === 3) {    // Drilling
                    subtotal += result.drilling_information.total_cost;
                } else if (item.id === 4 && result.shrink_wrapping_information) {    // Shrink-Wrapping
                    subtotal += result.shrink_wrapping_information.total_cost;
                } else if (item.id === 5) {    // Padding
                    subtotal += result.padding_information.total_cost;
                } else if (item.id === 6) {    // Die-Cutting
                    subtotal += result.die_cutter_information.total_cost;
                } else if (item.id === 21 && result.boxing_information) {    // Boxing
                    subtotal += result.boxing_information.total_cost;
                }
            }
            subtotal = parseFloat(subtotal.toFixed(2));
        } else if (parseInt(estimateType) === 2) {     // Envelopes
            subtotal = result.envelope_press.press_cost + result.envelope_press.pre_press_cost + result.envelope_paper.cost;

            for (let i = 0; i < selectedAdditionalServices.length; i++) {
                const item = selectedAdditionalServices[i];

                if (item.id === 4 && result.shrink_wrapping_information) {    // Shrink-Wrapping
                    subtotal += result.shrink_wrapping_information.total_cost;
                } else if (item.id === 21 && result.boxing_information) {    // Boxing
                    subtotal += result.boxing_information.total_cost;
                }
            }
            subtotal = parseFloat(subtotal.toFixed(2));
        } else if (parseInt(estimateType) === 3) {     // Plus-Cover
            let coverResult = null;
            if (multiResult.cover_results.length > 0) {
                coverResult = multiResult.cover_results[0];
            }

            if (isDigital(estimate.cover_output_device)) {
                subtotal += coverResult.digital_machine_information.total_cost;
            } else {
                subtotal += coverResult.press_information.total_running_cost + coverResult.press_information.total_setup_cost;
                subtotal += coverResult.press_information.total_plates_cost;
                subtotal += coverResult.ink_information.total_cost;
            }

            subtotal += coverResult.cutting_information.total_cost + coverResult.folding_information.total_cost
                + coverResult.paper_information.total_cost;

            for (let i = 0; i < result.impositions_information.length; i++) {
                const imposition = result.impositions_information[i];

                if (isDigital(estimate.output_device)) {
                    subtotal += imposition.digital_machine_information.total_cost;
                } else {
                    subtotal += imposition.press_information.total_running_cost + imposition.press_information.total_setup_cost;
                    subtotal += imposition.press_information.total_plates_cost;
                    subtotal += imposition.ink_information.total_cost;
                }

                subtotal += imposition.paper_information.total_cost;
                subtotal += imposition.cutting_information.total_cost;
                subtotal += imposition.folding_information.total_cost;
            }

            subtotal += result.binding_information.total_cost;

            for (let i = 0; i < selectedAdditionalServices.length; i++) {
                const item = selectedAdditionalServices[i];

                if (item.id === 4 && result.shrink_wrapping_information) {    // Shrink-Wrapping
                    subtotal += result.shrink_wrapping_information.total_cost;
                } else if (item.id === 21 && result.boxing_information) {    // Boxing
                    subtotal += result.boxing_information.total_cost;
                }
            }

            subtotal = parseFloat(subtotal.toFixed(2));
        } else if (parseInt(estimateType) === 4) {     // Self-Cover
            for (let i = 0; i < result.impositions_information.length; i++) {
                const imposition = result.impositions_information[i];

                if (isDigital(estimate.output_device)) {
                    subtotal += imposition.digital_machine_information.total_cost;
                } else {
                    subtotal += imposition.press_information.total_running_cost + imposition.press_information.total_setup_cost;
                    subtotal += imposition.press_information.total_plates_cost;
                    subtotal += imposition.ink_information.total_cost;
                }

                subtotal += imposition.paper_information.total_cost;
                subtotal += imposition.cutting_information.total_cost;
                subtotal += imposition.folding_information.total_cost;
            }

            subtotal += result.binding_information.total_cost;

            for (let i = 0; i < selectedAdditionalServices.length; i++) {
                const item = selectedAdditionalServices[i];

                if (item.id === 4 && result.shrink_wrapping_information) {    // Shrink-Wrapping
                    subtotal += result.shrink_wrapping_information.total_cost;
                } else if (item.id === 21 && result.boxing_information) {    // Boxing
                    subtotal += result.boxing_information.total_cost;
                }
            }

            subtotal = parseFloat(subtotal.toFixed(2));
        } else if (parseInt(estimateType) === 5) {     // Digital
            subtotal = result.digital_machine_information.total_cost + result.digital_machine_information.total_setup_cost
                + result.paper_information.total_cost + result.cutting_information.total_cost;

            for (let i = 0; i < selectedAdditionalServices.length; i++) {
                const item = selectedAdditionalServices[i];

                if (item.id === 1) {       // Folding
                    subtotal += result.folding_information.total_cost;
                } else if (item.id === 2) {    // Scoring
                    subtotal += result.scoring_information.total_cost;
                } else if (item.id === 3) {    // Drilling
                    subtotal += result.drilling_information.total_cost;
                } else if (item.id === 5) {    // Padding
                    subtotal += result.padding_information.total_cost;
                } else if (item.id === 6) {    // Die-Cutting
                    subtotal += result.die_cutter_information.total_cost;
                } else if (item.id === 4 && result.shrink_wrapping_information) {    // Shrink-Wrapping
                    subtotal += result.shrink_wrapping_information.total_cost;
                } else if (item.id === 21 && result.boxing_information) {    // Boxing
                    subtotal += result.boxing_information.total_cost;
                }
            }
            subtotal = parseFloat(subtotal.toFixed(2));
        } else if (parseInt(estimateType) === 6) {     // Large Format
            subtotal = result.large_format_machine_information.total_cost + result.substrate_information.total_cost;

            if (result.accessories_information.accessories.length > 0) {
                for (let i = 0; i < selectedAdditionalServices.length; i++) {
                    const item = selectedAdditionalServices[i];

                    for (let j = 0; j < result.accessories_information.accessories.length; j++) {
                        const accessory = result.accessories_information.accessories[j];
                        if (item.id === 11 && parseInt(accessory.id) === 3) {     // Grommets
                            subtotal += accessory.total_cost;
                        } else if (item.id === 12 && parseInt(accessory.id) === 17) {      // H-Stakes
                            subtotal += accessory.total_cost;
                        }
                    }
                }
            }

            subtotal = parseFloat(subtotal.toFixed(2));
        }

        /*************************** Outside **************************/
        if (estimate.outside_cost) {     // Outside Cost
            outsidePrice = parseFloat(estimate.outside_cost);
        }

        if (estimate.outside_markup) {     // Outside Markup
            outsidePrice *= 1 + parseFloat(estimate.outside_markup) / 100;
        }

        if (estimate.outside_commission) {
            outsidePrice *= 1 + parseFloat(estimate.outside_commission) / 100;
        }

        if (estimate.full_outside_job) {
            if (showDeliveryCharge) {
                subtotal = parseFloat(deliveryCharge.total);
            }

            resultsData.push({
                quantity: multiResult.request_params.quantity,
                subtotal: subtotal,
                markupPrice: 0,
                commission: 0,
                outsidePrice: outsidePrice,
                tax: 0,
                total: subtotal + outsidePrice,
            });
            return null;;
        }

        if (showDeliveryCharge) {
            subtotal += parseFloat(deliveryCharge.total);
        }

        /*************************** Markup **************************/
        let markup = 0;
        if (!empty(estimate.markup)) {
            markup = parseFloat(estimate.markup) / 100;
        }
        markupPrice = parseFloat((subtotal * markup).toFixed(2));

        /************************** Commission ************************/
        let markupFactor = 0;
        if (parseInt(estimate.markup_factor) === 1) {   // Sales
            markupFactor = 0.1;
        } else if (parseInt(estimate.markup_factor) === 2) {   // Broker
            markupFactor = 0.3;
        } else if (parseInt(estimate.markup_factor) === 3) {   // Good
            markupFactor = 0.25;
        } else if (parseInt(estimate.markup_factor) === 4) {   // Great
            markupFactor = 0.2;
        } else if (parseInt(estimate.markup_factor) === 5) {   // Excellent
            markupFactor = 0.15;
        } else if (parseInt(estimate.markup_factor) === 6) {   // PITAA
            markupFactor = 0.45;
        }

        /*************************** Total **************************/
        //      => subtotal + markupPrice + outsidePrice + markupFactor * x = x
        //      => x = (subtotal + markupPrice + outsidePrice) / (1 - markupFactor)
        total = (subtotal + markupPrice + outsidePrice) / (1 - markupFactor);

        commission = parseFloat((total * markupFactor).toFixed(2));

        /*************************** Tax **************************/
        if (parseInt(estimate.use_tax) === 1) {
            tax = parseFloat((total * 0.0825).toFixed(2));
        }

        total += tax;

        resultsData.push({
            quantity: multiResult.request_params.quantity,
            subtotal: subtotal,
            markupPrice: markupPrice,
            commission: commission,
            outsidePrice: outsidePrice,
            tax: tax,
            total: total,
        });
        return null;
    });

    dispatch({ type: types.EXPORT_MATRIX, payload: null });
    axios.post(apiUrl + '/sc-estimate/export-matrix', { id: estimate.id, resultsData: resultsData },
        {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
            responseType: 'blob',
        })
        .then((response) => {
            dispatch({ type: types.EXPORT_MATRIX_SUCCESS, payload: null });
            fileDownload(response.data, 'Estimate #' + estimate.id + ' Price Matrix.xlsx');
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.EXPORT_MATRIX_ERROR));
        });
}

export const addPaper = () => (dispatch, getState) => {
    const paperFields = selectors.getPaperFields(getState());

    let fields = [
        { field: 'paper_name', message: 'Enter the Name' },
        { field: 'vendor_id', message: 'Select the Vendor' },
        { field: 'paper_category_id', message: 'Select the Paper Category' },
        { field: 'description', message: 'Enter the Description' },
        { field: 'basis_weight', message: 'Enter the Basis Weight' },
        { field: 'width', message: 'Enter the Width' },
        { field: 'length', message: 'Enter the Length' },
        { field: 'caliper', message: 'Enter the Caliper' },
        { field: 'color', message: 'Enter the Color' },
        { field: 'finish', message: 'Enter the Finish' },
        { field: 'grain_direction', message: 'Enter the Grain Direction' },
        { field: 'm_weight', message: 'Enter the M/Weight' },
        { field: 'price_cwt', message: 'Enter the Price/CWT' },
    ];

    for (let i = 0; i < fields.length; i++) {
        if (empty(paperFields[fields[i].field])) {
            return showNotification('Complete Information', fields[i].message, 'info');
        }
    }

    dispatch({ type: types.ADD_PAPER, payload: null });
    axios.post(apiUrl + '/sc-estimate/add-paper', paperFields,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.ADD_PAPER_SUCCESS, payload: response.data });
            showNotification('Paper Added', 'The Paper has been added successfully', 'success');
            dispatch(fetchInformation());
            dispatch(dialog.actions.hideDialog('new-paper-modal'));
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.ADD_PAPER_ERROR));
        });
}

export const checkUsePart = (value) => (dispatch, getState) => {
    const estimate = selectors.getEstimate(getState());

    dispatch({ type: types.CHECK_USE_PART, payload: value });
    axios.post(apiUrl + '/sc-estimate/check-use-part', { id: estimate.id, value },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.CHECK_USE_PART_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.CHECK_USE_PART_ERROR));
        });
}

export const addEstimateData = (fromAdditional = false) => (dispatch, getState) => {
    const estimate = selectors.getEstimate(getState());
    const estimateType = selectors.getEstimateType(getState());
    const selectedAdditionalServices = selectors.getSelectedAdditionalServices(getState());
    const result1Obj = selectors.getResult1(getState());
    const result2Obj = selectors.getResult2(getState());
    const result3Obj = selectors.getResult3(getState());

    let resultObj = null;
    if (parseInt(estimate.selected_quantity) === 1) {
        resultObj = result1Obj;
    } else if (parseInt(estimate.selected_quantity) === 2) {
        resultObj = result2Obj;
    } else if (parseInt(estimate.selected_quantity) === 3) {
        resultObj = result3Obj;
    }

    let result = null;
    if (parseInt(estimateType) === 2) {   // Envelopes
        if (resultObj && resultObj != null && resultObj.totals) {
            result = resultObj;
        } else if (resultObj && resultObj != null && resultObj.results.length > 0) {
            result = resultObj.results[0];
        }
    } else {
        if (resultObj && resultObj != null && resultObj.results.length > 0) {
            result = resultObj.results[0];
        }
    }

    if (result == null) {
        for (let i = 0; i < selectedAdditionalServices.length; i++) {
            dispatch(updateAdditionalService(selectedAdditionalServices[i].id, 'quantity', ''));
            dispatch(updateAdditionalService(selectedAdditionalServices[i].id, 'total', 0));
        }
        return;
    }

    const estimateTypeInt = parseInt(estimateType);
    if ([1, 7, 8, 9].includes(estimateTypeInt)) {   // Sheetfed, Pocket Folder, NCR, Notepads
        if (isDigital(estimate.output_device)) {
            dispatch(addDigitalEstimateData(result, estimate, estimateType, selectedAdditionalServices, fromAdditional));
        } else {
            dispatch(addSheetfedEstimateData(result, estimate, estimateType, selectedAdditionalServices, fromAdditional));
        }
    } else if (estimateTypeInt === 2) {   // Envelopes
        dispatch(addEnvelopesEstimateData(result, selectedAdditionalServices, fromAdditional));
    } else if (estimateTypeInt === 3) {   // Plus-Cover
        dispatch(addPlusCoverEstimateData(resultObj, result, estimate, selectedAdditionalServices, fromAdditional));
    } else if (estimateTypeInt === 4) {   // Self-Cover
        dispatch(addSelfCoverEstimateData(result, estimate, selectedAdditionalServices, fromAdditional));
    } else if (estimateTypeInt === 5) {   // Digital
        dispatch(addDigitalEstimateData(result, estimate, estimateType, selectedAdditionalServices, fromAdditional));
    } else if (estimateTypeInt === 6) {   // Large Format
        dispatch(addLargeFormatEstimateData(estimate, result, selectedAdditionalServices, fromAdditional));
    }
}

export const getPaperTotals = (result, estimate, estimateType) => {
    if (empty(result.paper_information)) {
        return { quantity: 0, total: 0 };
    }

    let quantity = result.paper_information.total_pars;
    let total = result.paper_information.total_cost;

    const costPerSheet = result.paper_information.total_cost / result.paper_information.total_pars;
    if (!empty(estimate.press_additional_sheets)) {
        quantity += parseInt(estimate.press_additional_sheets);
        total += parseInt(estimate.press_additional_sheets) * costPerSheet;
    }
    if (!empty(estimate.bindery_additional_sheets)) {
        quantity += parseInt(estimate.bindery_additional_sheets);
        total += parseInt(estimate.bindery_additional_sheets) * costPerSheet;
    }

    return { quantity, total };
}

export const getPressTotals = (result, estimate) => {
    if (empty(result.press_information)) {
        return { quantity: 0, total: 0 };
    }

    let quantity = result.press_information.quantity;
    let total = result.press_information.total_running_cost + result.press_information.total_setup_cost;

    const costPerSheet = total / result.press_information.quantity;
    if (!empty(estimate.press_additional_sheets)) {
        quantity += parseInt(estimate.press_additional_sheets);
        total += parseInt(estimate.press_additional_sheets) * costPerSheet;
    }
    if (!empty(estimate.bindery_additional_sheets)) {
        quantity += parseInt(estimate.bindery_additional_sheets);
        total += parseInt(estimate.bindery_additional_sheets) * costPerSheet;
    }

    return { quantity, total };
}

export const getDigitalMachineTotals = (result, estimate) => {
    let quantity = result.digital_machine_information.quantity;
    let total = result.digital_machine_information.total_cost;

    const costPerSheet = total / quantity;
    if (!empty(estimate.press_additional_sheets)) {
        quantity += parseInt(estimate.press_additional_sheets);
        total += parseInt(estimate.press_additional_sheets) * costPerSheet;
    }
    if (!empty(estimate.bindery_additional_sheets)) {
        quantity += parseInt(estimate.bindery_additional_sheets);
        total += parseInt(estimate.bindery_additional_sheets) * costPerSheet;
    }

    return { quantity, total };
}

export const addSheetfedEstimateData = (result, estimate, estimateType, selectedAdditionalServices, fromAdditional) => (dispatch, getState) => {
    let quantity = null;
    let total = null;

    dispatch(deleteAdditionalServicesItem(14));     // Remove Digital in case it exists

    const pressTotals = getPressTotals(result, estimate);
    dispatch(addAdditionalFromEstimate(13, pressTotals.quantity, pressTotals.total, fromAdditional));   // Press

    const paperTotals = getPaperTotals(result, estimate, estimateType);
    dispatch(addAdditionalFromEstimate(15, paperTotals.quantity, paperTotals.total, fromAdditional));   // Paper

    if (!empty(result.press_information)) {
        quantity = result.press_information.total_plates;
        total = result.press_information.total_plates_cost;
    }
    dispatch(addAdditionalFromEstimate(7, quantity, total, fromAdditional));    // Plates

    if (!empty(result.ink_information)) {
        quantity = result.ink_information.total_front_colors + result.ink_information.total_back_colors;
        total = result.ink_information.total_cost;
    }
    dispatch(addAdditionalFromEstimate(8, quantity, total, fromAdditional));    // Inks

    if (!empty(result.cutting_information)) {
        quantity = result.cutting_information.quantity * result.paper_information.pss.up;
        total = result.cutting_information.total_cost;
    }
    dispatch(addAdditionalFromEstimate(9, quantity, total, fromAdditional));    // Cutting

    if (parseInt(estimateType) === 7) {     // Pocket Folder
        quantity = result.die_cutter_information.total_pieces;
        total = result.die_cutter_information.total_cost;
        dispatch(addAdditionalFromEstimate(6, quantity, total, fromAdditional));    // Die-Cutting
    } else if (parseInt(estimateType) === 8) {     // NCR
        quantity = result.ncr_information.sets;
        total = result.ncr_information.total_cost;
        dispatch(addAdditionalFromEstimate(29, quantity, total, fromAdditional));    // NCR Padding
    } else if (parseInt(estimateType) === 9) {     // Notepads
        quantity = result.pads_information.pads;
        total = result.pads_information.total_cost;
        dispatch(addAdditionalFromEstimate(30, quantity, total, fromAdditional));    // Pads Padding
    }

    if (result.shrink_wrapping_information && result.shrink_wrapping_information.total_packages > 0) {
        quantity = result.shrink_wrapping_information.total_packages;
        total = result.shrink_wrapping_information.total_cost;
        dispatch(addAdditionalFromEstimate(4, quantity, total, fromAdditional));    // Shrink-Wrapping
    }

    if (result.boxing_information && result.boxing_information.total_packages > 0) {
        quantity = result.boxing_information.total_packages;
        total = result.boxing_information.total_cost;
        dispatch(addAdditionalFromEstimate(21, quantity, total, fromAdditional));    // Boxing
    }

    for (let i = 0; i < selectedAdditionalServices.length; i++) {
        const item = selectedAdditionalServices[i];

        if (item.id === 13) {    // Press
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(pressTotals.quantity).format('0,0') + ' pss'));
            dispatch(updateAdditionalService(item.id, 'total', pressTotals.total));
        } else if (item.id === 15) {    // Paper
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(paperTotals.quantity).format('0,0') + ' pars'));
            dispatch(updateAdditionalService(item.id, 'total', paperTotals.total));
        } else if (item.id === 1) {       // Folding
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.folding_information.quantity).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.folding_information.total_cost));
            dispatch(setAdditionalService(item.id, 'folding_type', item.folding_type));
        } else if (item.id === 2) {    // Scoring
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.scoring_information.total_pieces).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.scoring_information.total_cost));
        } else if (item.id === 3) {    // Drilling
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.drilling_information.quantity).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.drilling_information.total_cost));
            dispatch(setAdditionalService(item.id, 'drilling_holes', item.drilling_holes));
        } else if (item.id === 4 && result.shrink_wrapping_information) {    // Shrink-Wrapping
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.shrink_wrapping_information.total_packages).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.shrink_wrapping_information.total_cost));
            dispatch(setAdditionalService(item.id, 'per_package', item.per_package));
        } else if (item.id === 5) {    // Padding
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.padding_information.quantity).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.padding_information.total_cost));
            dispatch(setAdditionalService(item.id, 'sheets_per_pad', item.sheets_per_pad));
        } else if (item.id === 6) {    // Die-Cutting
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.die_cutter_information.total_pieces).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.die_cutter_information.total_cost));
        } else if (item.id === 7) {    // Plates
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.press_information.total_plates).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.press_information.total_plates_cost));
        } else if (item.id === 8) {    // Inks
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.ink_information.total_front_colors + result.ink_information.total_back_colors).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.ink_information.total_cost));
        } else if (item.id === 9) {    // Cutting
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.cutting_information.quantity * result.paper_information.pss.up).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.cutting_information.total_cost));
        } else if (item.id === 21 && result.boxing_information) {    // Boxing
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.boxing_information.total_packages).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.boxing_information.total_cost));
            dispatch(setAdditionalService(item.id, 'per_package', item.per_package));
        } else if (item.id === 26 && result.perforating_information) {    // Perforating
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.perforating_information.quantity).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.perforating_information.total_cost));
        } else if (item.id === 27) {    // Business Cards Box
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.bc_box_information.quantity).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.bc_box_information.total_cost));
            dispatch(setAdditionalService(item.id, 'box', item.box));
        } else if (item.id === 28) {    // Letterheads Box
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.letterhead_box_information.quantity).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.letterhead_box_information.total_cost));
            dispatch(setAdditionalService(item.id, 'box', item.box));
        } else if (item.id === 29) {    // NCR Padding
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.ncr_information.sets).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.ncr_information.total_cost));
        } else if (item.id === 30) {    // Pads Padding
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.pads_information.pads).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.pads_information.total_cost));
        }
        dispatch(setAdditionalService(item.id, 'checked', 1));
    }
}

export const addEnvelopesEstimateData = (result, selectedAdditionalServices, fromAdditional) => (dispatch, getState) => {
    let quantity = null;
    let total = null;

    quantity = result.envelope_press.total_quantity;
    total = result.envelope_press.press_cost + result.envelope_press.pre_press_cost;
    dispatch(addAdditionalFromEstimate(18, quantity, total, fromAdditional));   // Envelope Press

    quantity = result.envelope_paper.total_quantity;
    total = result.envelope_paper.cost;
    dispatch(addAdditionalFromEstimate(19, quantity, total, fromAdditional));   // Envelope Paper

    if (result.shrink_wrapping_information && result.shrink_wrapping_information.total_packages > 0) {
        quantity = result.shrink_wrapping_information.total_packages;
        total = result.shrink_wrapping_information.total_cost;
        dispatch(addAdditionalFromEstimate(4, quantity, total, fromAdditional));    // Shrink-Wrapping
    }

    if (result.boxing_information && result.boxing_information.total_packages > 0) {
        quantity = result.boxing_information.total_packages;
        total = result.boxing_information.total_cost;
        dispatch(addAdditionalFromEstimate(21, quantity, total, fromAdditional));    // Boxing
    }

    for (let i = 0; i < selectedAdditionalServices.length; i++) {
        const item = selectedAdditionalServices[i];

        if (item.id === 18) {    // Envelope Press
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.envelope_press.total_quantity).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.envelope_press.press_cost + result.envelope_press.pre_press_cost));
        } else if (item.id === 19) {    // Envelope Paper
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.envelope_paper.total_quantity).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.envelope_paper.cost));
        } else if (item.id === 4 && result.shrink_wrapping_information) {    // Shrink-Wrapping
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.shrink_wrapping_information.total_packages).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.shrink_wrapping_information.total_cost));
            dispatch(setAdditionalService(item.id, 'per_package', item.per_package));
        } else if (item.id === 21 && result.boxing_information) {    // Boxing
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.boxing_information.total_packages).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.boxing_information.total_cost));
            dispatch(setAdditionalService(item.id, 'per_package', item.per_package));
        } else if (item.id === 27) {    // Business Cards Box
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.bc_box_information.quantity).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.bc_box_information.total_cost));
            dispatch(setAdditionalService(item.id, 'box', item.box));
        } else if (item.id === 28) {    // Letterheads Box
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.letterhead_box_information.quantity).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.letterhead_box_information.total_cost));
            dispatch(setAdditionalService(item.id, 'box', item.box));
        }
        dispatch(setAdditionalService(item.id, 'checked', 1));
    }
}

export const addSelfCoverEstimateData = (result, estimate, selectedAdditionalServices, fromAdditional) => (dispatch, getState) => {
    let pressQuantity = 0;
    let pressTotal = 0;
    let digitalMachineQuantity = 0;
    let digitalMachineTotal = 0;
    let paperQuantity = 0;
    let paperTotal = 0;
    let platesQuantity = 0;
    let platesTotal = 0;
    let inksQuantity = 0;
    let inksTotal = 0;
    let cuttingQuantity = 0;
    let cuttingTotal = 0;
    let foldingQuantity = 0;
    let foldingTotal = 0;

    for (let i = 0; i < result.impositions_information.length; i++) {
        const imposition = result.impositions_information[i];

        if (isDigital(estimate.output_device)) {
            digitalMachineQuantity += imposition.digital_machine_information.quantity;
            digitalMachineTotal += imposition.digital_machine_information.total_cost;
        } else {
            pressQuantity += imposition.press_information.quantity;
            pressTotal += imposition.press_information.total_running_cost + imposition.press_information.total_setup_cost;
        }

        paperQuantity += imposition.paper_information.total_pars;
        paperTotal += imposition.paper_information.total_cost;

        platesQuantity += imposition.press_information.total_plates;
        platesTotal += imposition.press_information.total_plates_cost;

        inksQuantity += imposition.ink_information.total_front_colors + imposition.ink_information.total_back_colors;
        inksTotal += imposition.ink_information.total_cost;

        cuttingQuantity += imposition.cutting_information.quantity * imposition.paper_information.imposition_out;
        cuttingTotal += imposition.cutting_information.total_cost;

        foldingQuantity += imposition.folding_information.quantity;
        foldingTotal += imposition.folding_information.total_cost;
    }

    let costPerSheet = 0;
    if (isDigital(estimate.output_device)) {
        costPerSheet = digitalMachineTotal / digitalMachineQuantity;
        if (!empty(estimate.press_additional_sheets)) {
            digitalMachineQuantity += parseInt(estimate.press_additional_sheets);
            digitalMachineTotal += parseInt(estimate.press_additional_sheets) * costPerSheet;
        }
        if (!empty(estimate.bindery_additional_sheets)) {
            digitalMachineQuantity += parseInt(estimate.bindery_additional_sheets);
            digitalMachineTotal += parseInt(estimate.bindery_additional_sheets) * costPerSheet;
        }

        dispatch(addAdditionalFromEstimate(14, digitalMachineQuantity, digitalMachineTotal, fromAdditional));

        dispatch(deleteAdditionalServicesItem(13));
        dispatch(deleteAdditionalServicesItem(7));
        dispatch(deleteAdditionalServicesItem(8));
    } else {
        costPerSheet = pressTotal / pressQuantity;
        if (!empty(estimate.press_additional_sheets)) {
            pressQuantity += parseInt(estimate.press_additional_sheets);
            pressTotal += parseInt(estimate.press_additional_sheets) * costPerSheet;
        }
        if (!empty(estimate.bindery_additional_sheets)) {
            pressQuantity += parseInt(estimate.bindery_additional_sheets);
            pressTotal += parseInt(estimate.bindery_additional_sheets) * costPerSheet;
        }

        dispatch(addAdditionalFromEstimate(13, pressQuantity, pressTotal, fromAdditional));
        dispatch(addAdditionalFromEstimate(7, platesQuantity, platesTotal, fromAdditional));
        dispatch(addAdditionalFromEstimate(8, inksQuantity, inksTotal, fromAdditional));

        dispatch(deleteAdditionalServicesItem(14));
    }

    costPerSheet = paperTotal / paperQuantity;
    if (!empty(estimate.press_additional_sheets)) {
        paperQuantity += parseInt(estimate.press_additional_sheets);
        paperTotal += parseInt(estimate.press_additional_sheets) * costPerSheet;
    }
    if (!empty(estimate.bindery_additional_sheets)) {
        paperQuantity += parseInt(estimate.bindery_additional_sheets);
        paperTotal += parseInt(estimate.bindery_additional_sheets) * costPerSheet;
    }

    dispatch(addAdditionalFromEstimate(15, paperQuantity, paperTotal, fromAdditional));
    dispatch(addAdditionalFromEstimate(9, cuttingQuantity, cuttingTotal, fromAdditional));

    if (parseInt(estimate.publication_type) === 1) {
        dispatch(addAdditionalFromEstimate(1, foldingQuantity, foldingTotal, fromAdditional));
        dispatch(deleteAdditionalServicesItem(22));
        dispatch(deleteAdditionalServicesItem(23));
        dispatch(deleteAdditionalServicesItem(24));
        dispatch(deleteAdditionalServicesItem(25));

        let quantity = result.binding_information.books;
        let total = result.binding_information.total_cost;
        dispatch(addAdditionalFromEstimate(20, quantity, total, fromAdditional));    // Saddle Stitching
    } else {
        dispatch(deleteAdditionalServicesItem(1));
        dispatch(deleteAdditionalServicesItem(20));

        let quantity = result.binding_information.books;
        let total = result.binding_information.total_cost;

        if (parseInt(estimate.publication_type) === 3) {    // Perfect Binding
            dispatch(deleteAdditionalServicesItem(23));
            dispatch(deleteAdditionalServicesItem(24));
            dispatch(deleteAdditionalServicesItem(25));

            dispatch(addAdditionalFromEstimate(22, quantity, total, fromAdditional));
        } else if (parseInt(estimate.publication_type) === 7) {    // Spiral Binding
            dispatch(deleteAdditionalServicesItem(22));
            dispatch(deleteAdditionalServicesItem(24));
            dispatch(deleteAdditionalServicesItem(25));

            dispatch(addAdditionalFromEstimate(23, quantity, total, fromAdditional));
        } else if (parseInt(estimate.publication_type) === 6) {    // Wire Binding
            dispatch(deleteAdditionalServicesItem(22));
            dispatch(deleteAdditionalServicesItem(23));
            dispatch(deleteAdditionalServicesItem(25));

            dispatch(addAdditionalFromEstimate(24, quantity, total, fromAdditional));
        } else if (parseInt(estimate.publication_type) === 5) {    // Staple Binding
            dispatch(deleteAdditionalServicesItem(22));
            dispatch(deleteAdditionalServicesItem(23));
            dispatch(deleteAdditionalServicesItem(24));

            dispatch(addAdditionalFromEstimate(25, quantity, total, fromAdditional));
        }
    }

    if (result.shrink_wrapping_information && result.shrink_wrapping_information.total_packages > 0) {
        let quantity = result.shrink_wrapping_information.total_packages;
        let total = result.shrink_wrapping_information.total_cost;
        dispatch(addAdditionalFromEstimate(4, quantity, total, fromAdditional));    // Shrink-Wrapping
    }

    if (result.boxing_information && result.boxing_information.total_packages > 0) {
        let quantity = result.boxing_information.total_packages;
        let total = result.boxing_information.total_cost;
        dispatch(addAdditionalFromEstimate(21, quantity, total, fromAdditional));    // Boxing
    }

    for (let i = 0; i < selectedAdditionalServices.length; i++) {
        const item = selectedAdditionalServices[i];

        if (item.id === 13) {       // Press
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(pressQuantity).format('0,0') + ' pss'));
            dispatch(updateAdditionalService(item.id, 'total', pressTotal));
        } else if (item.id === 14) {    // Digital
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(digitalMachineQuantity).format('0,0') + ' pss'));
            dispatch(updateAdditionalService(item.id, 'total', digitalMachineTotal));
        } else if (item.id === 15) {       // Paper
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(paperQuantity).format('0,0') + ' pars'));
            dispatch(updateAdditionalService(item.id, 'total', paperTotal));
        } else if (item.id === 1) {       // Folding
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(foldingQuantity).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', foldingTotal));
        } else if (item.id === 7) {    // Plates
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(platesQuantity).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', platesTotal));
        } else if (item.id === 8) {    // Inks
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(inksQuantity).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', inksTotal));
        } else if (item.id === 9) {    // Cutting
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(cuttingQuantity).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', cuttingTotal));
        } else if (item.id === 20) {    // Saddle Stitching
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.binding_information.books).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.binding_information.total_cost));
        } else if (item.id === 22) {    // Perfect Binding
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.binding_information.books).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.binding_information.total_cost));
        } else if (item.id === 23) {    // Spiral Binding
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.binding_information.books).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.binding_information.total_cost));
        } else if (item.id === 24) {    // Wire Binding
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.binding_information.books).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.binding_information.total_cost));
        } else if (item.id === 25) {    // Staple Binding
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.binding_information.books).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.binding_information.total_cost));
        } else if (item.id === 4 && result.shrink_wrapping_information) {    // Shrink-Wrapping
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.shrink_wrapping_information.total_packages).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.shrink_wrapping_information.total_cost));
            dispatch(setAdditionalService(item.id, 'per_package', item.per_package));
        } else if (item.id === 21 && result.boxing_information) {    // Boxing
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.boxing_information.total_packages).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.boxing_information.total_cost));
            dispatch(setAdditionalService(item.id, 'per_package', item.per_package));
        } else if (item.id === 27) {    // Business Cards Box
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.bc_box_information.quantity).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.bc_box_information.total_cost));
            dispatch(setAdditionalService(item.id, 'box', item.box));
        } else if (item.id === 28) {    // Letterheads Box
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.letterhead_box_information.quantity).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.letterhead_box_information.total_cost));
            dispatch(setAdditionalService(item.id, 'box', item.box));
        }
        dispatch(setAdditionalService(item.id, 'checked', 1));
    }
}

export const addPlusCoverEstimateData = (resultObj, result, estimate, selectedAdditionalServices, fromAdditional) => (dispatch, getState) => {
    let coverResult = null;
    if (resultObj && resultObj.cover_results.length > 0) {
        coverResult = resultObj.cover_results[0];
    }

    let pressQuantity = 0;
    let pressTotal = 0;
    let digitalMachineQuantity = 0;
    let digitalMachineTotal = 0;
    let paperQuantity = 0;
    let paperTotal = 0;
    let platesQuantity = 0;
    let platesTotal = 0;
    let inksQuantity = 0;
    let inksTotal = 0;
    let cuttingQuantity = 0;
    let cuttingTotal = 0;
    let foldingQuantity = 0;
    let foldingTotal = 0;

    /*********************** Cover **********************/
    if (isDigital(estimate.cover_output_device)) {
        digitalMachineQuantity += coverResult.digital_machine_information.quantity;
        digitalMachineTotal += coverResult.digital_machine_information.total_cost;
    } else {
        pressQuantity += coverResult.press_information.quantity;
        pressTotal += coverResult.press_information.total_running_cost + coverResult.press_information.total_setup_cost;

        platesQuantity += coverResult.press_information.total_plates;
        platesTotal += coverResult.press_information.total_plates_cost;

        inksQuantity += coverResult.ink_information.total_front_colors + coverResult.ink_information.total_back_colors;
        inksTotal += coverResult.ink_information.total_cost;
    }

    paperQuantity += coverResult.paper_information.total_pars;
    paperTotal += coverResult.paper_information.total_cost;

    cuttingQuantity += coverResult.cutting_information.quantity * coverResult.paper_information.pss.up;
    cuttingTotal += coverResult.cutting_information.total_cost;

    foldingQuantity += coverResult.folding_information.quantity;
    foldingTotal += coverResult.folding_information.total_cost;

    /********************** Interior **********************/
    for (let i = 0; i < result.impositions_information.length; i++) {
        const imposition = result.impositions_information[i];

        if (isDigital(estimate.output_device)) {
            digitalMachineQuantity += imposition.digital_machine_information.quantity;
            digitalMachineTotal += imposition.digital_machine_information.total_cost;
        } else {
            pressQuantity += imposition.press_information.quantity;
            pressTotal += imposition.press_information.total_running_cost + imposition.press_information.total_setup_cost;

            platesQuantity += imposition.press_information.total_plates;
            platesTotal += imposition.press_information.total_plates_cost;

            inksQuantity += imposition.ink_information.total_front_colors + imposition.ink_information.total_back_colors;
            inksTotal += imposition.ink_information.total_cost;
        }

        paperQuantity += imposition.paper_information.total_pars;
        paperTotal += imposition.paper_information.total_cost;

        cuttingQuantity += imposition.cutting_information.quantity * imposition.paper_information.imposition_out;
        cuttingTotal += imposition.cutting_information.total_cost;

        foldingQuantity += imposition.folding_information.quantity;
        foldingTotal += imposition.folding_information.total_cost;
    }

    let costPerSheet = 0;
    if (isDigital(estimate.output_device) && isDigital(estimate.cover_output_device)) {
        costPerSheet = digitalMachineTotal / digitalMachineQuantity;
        if (!empty(estimate.press_additional_sheets)) {
            digitalMachineQuantity += parseInt(estimate.press_additional_sheets);
            digitalMachineTotal += parseInt(estimate.press_additional_sheets) * costPerSheet;
        }
        if (!empty(estimate.bindery_additional_sheets)) {
            digitalMachineQuantity += parseInt(estimate.bindery_additional_sheets);
            digitalMachineTotal += parseInt(estimate.bindery_additional_sheets) * costPerSheet;
        }

        dispatch(addAdditionalFromEstimate(14, digitalMachineQuantity, digitalMachineTotal, fromAdditional));

        dispatch(deleteAdditionalServicesItem(13));
        dispatch(deleteAdditionalServicesItem(7));
        dispatch(deleteAdditionalServicesItem(8));
    } else if (isDigital(estimate.output_device) || isDigital(estimate.cover_output_device)) {
        let costPerSheet = digitalMachineTotal / digitalMachineQuantity;
        if (!empty(estimate.press_additional_sheets)) {
            digitalMachineQuantity += parseInt(estimate.press_additional_sheets);
            digitalMachineTotal += parseInt(estimate.press_additional_sheets) * costPerSheet;
        }
        if (!empty(estimate.bindery_additional_sheets)) {
            digitalMachineQuantity += parseInt(estimate.bindery_additional_sheets);
            digitalMachineTotal += parseInt(estimate.bindery_additional_sheets) * costPerSheet;
        }

        costPerSheet = pressTotal / pressQuantity;
        if (!empty(estimate.press_additional_sheets)) {
            pressQuantity += parseInt(estimate.press_additional_sheets);
            pressTotal += parseInt(estimate.press_additional_sheets) * costPerSheet;
        }
        if (!empty(estimate.bindery_additional_sheets)) {
            pressQuantity += parseInt(estimate.bindery_additional_sheets);
            pressTotal += parseInt(estimate.bindery_additional_sheets) * costPerSheet;
        }

        dispatch(addAdditionalFromEstimate(14, digitalMachineQuantity, digitalMachineTotal, fromAdditional));
        dispatch(addAdditionalFromEstimate(13, pressQuantity, pressTotal, fromAdditional));
        dispatch(addAdditionalFromEstimate(7, platesQuantity, platesTotal, fromAdditional));
        dispatch(addAdditionalFromEstimate(8, inksQuantity, inksTotal, fromAdditional));
    } else {
        costPerSheet = pressTotal / pressQuantity;
        if (!empty(estimate.press_additional_sheets)) {
            pressQuantity += parseInt(estimate.press_additional_sheets);
            pressTotal += parseInt(estimate.press_additional_sheets) * costPerSheet;
        }
        if (!empty(estimate.bindery_additional_sheets)) {
            pressQuantity += parseInt(estimate.bindery_additional_sheets);
            pressTotal += parseInt(estimate.bindery_additional_sheets) * costPerSheet;
        }

        dispatch(addAdditionalFromEstimate(13, pressQuantity, pressTotal, fromAdditional));
        dispatch(addAdditionalFromEstimate(7, platesQuantity, platesTotal, fromAdditional));
        dispatch(addAdditionalFromEstimate(8, inksQuantity, inksTotal, fromAdditional));

        dispatch(deleteAdditionalServicesItem(14));
    }

    costPerSheet = paperTotal / paperQuantity;
    if (!empty(estimate.press_additional_sheets)) {
        paperQuantity += parseInt(estimate.press_additional_sheets);
        paperTotal += parseInt(estimate.press_additional_sheets) * costPerSheet;
    }
    if (!empty(estimate.bindery_additional_sheets)) {
        paperQuantity += parseInt(estimate.bindery_additional_sheets);
        paperTotal += parseInt(estimate.bindery_additional_sheets) * costPerSheet;
    }

    dispatch(addAdditionalFromEstimate(15, paperQuantity, paperTotal, fromAdditional));
    dispatch(addAdditionalFromEstimate(9, cuttingQuantity, cuttingTotal, fromAdditional));

    if (parseInt(estimate.publication_type) === 1) {
        dispatch(addAdditionalFromEstimate(1, foldingQuantity, foldingTotal, fromAdditional));
        dispatch(deleteAdditionalServicesItem(22));
        dispatch(deleteAdditionalServicesItem(23));
        dispatch(deleteAdditionalServicesItem(24));
        dispatch(deleteAdditionalServicesItem(25));

        let quantity = result.binding_information.books;
        let total = result.binding_information.total_cost;
        dispatch(addAdditionalFromEstimate(20, quantity, total, fromAdditional));    // Saddle Stitching
    } else {
        dispatch(deleteAdditionalServicesItem(1));
        dispatch(deleteAdditionalServicesItem(20));

        let quantity = result.binding_information.books;
        let total = result.binding_information.total_cost;

        if (parseInt(estimate.publication_type) === 3) {    // Perfect Binding
            dispatch(deleteAdditionalServicesItem(23));
            dispatch(deleteAdditionalServicesItem(24));
            dispatch(deleteAdditionalServicesItem(25));

            dispatch(addAdditionalFromEstimate(22, quantity, total, fromAdditional));
        } else if (parseInt(estimate.publication_type) === 7) {    // Spiral Binding
            dispatch(deleteAdditionalServicesItem(22));
            dispatch(deleteAdditionalServicesItem(24));
            dispatch(deleteAdditionalServicesItem(25));

            dispatch(addAdditionalFromEstimate(23, quantity, total, fromAdditional));
        } else if (parseInt(estimate.publication_type) === 6) {    // Wire Binding
            dispatch(deleteAdditionalServicesItem(22));
            dispatch(deleteAdditionalServicesItem(23));
            dispatch(deleteAdditionalServicesItem(25));

            dispatch(addAdditionalFromEstimate(24, quantity, total, fromAdditional));
        } else if (parseInt(estimate.publication_type) === 5) {    // Staple Binding
            dispatch(deleteAdditionalServicesItem(22));
            dispatch(deleteAdditionalServicesItem(23));
            dispatch(deleteAdditionalServicesItem(24));

            dispatch(addAdditionalFromEstimate(25, quantity, total, fromAdditional));
        }
    }

    if (result.shrink_wrapping_information && result.shrink_wrapping_information.total_packages > 0) {
        let quantity = result.shrink_wrapping_information.total_packages;
        let total = result.shrink_wrapping_information.total_cost;
        dispatch(addAdditionalFromEstimate(4, quantity, total, fromAdditional));    // Shrink-Wrapping
    }

    if (result.boxing_information && result.boxing_information.total_packages > 0) {
        let quantity = result.boxing_information.total_packages;
        let total = result.boxing_information.total_cost;
        dispatch(addAdditionalFromEstimate(21, quantity, total, fromAdditional));    // Boxing
    }

    for (let i = 0; i < selectedAdditionalServices.length; i++) {
        const item = selectedAdditionalServices[i];

        if (item.id === 13) {       // Press
            dispatch(updateAdditionalService(13, 'quantity', numeral(pressQuantity).format('0,0') + ' pss'));
            dispatch(updateAdditionalService(13, 'total', pressTotal));
        } else if (item.id === 14) {    // Digital
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(digitalMachineQuantity).format('0,0') + ' pss'));
            dispatch(updateAdditionalService(item.id, 'total', digitalMachineTotal));
        } else if (item.id === 15) {       // Paper
            dispatch(updateAdditionalService(15, 'quantity', numeral(paperQuantity).format('0,0') + ' pars'));
            dispatch(updateAdditionalService(15, 'total', paperTotal));
        } else if (item.id === 1) {       // Folding
            dispatch(updateAdditionalService(1, 'quantity', numeral(foldingQuantity).format('0,0')));
            dispatch(updateAdditionalService(1, 'total', foldingTotal));
        } else if (item.id === 7) {    // Plates
            dispatch(updateAdditionalService(7, 'quantity', numeral(platesQuantity).format('0,0')));
            dispatch(updateAdditionalService(7, 'total', platesTotal));
        } else if (item.id === 8) {    // Inks
            dispatch(updateAdditionalService(8, 'quantity', numeral(inksQuantity).format('0,0')));
            dispatch(updateAdditionalService(8, 'total', inksTotal));
        } else if (item.id === 9) {    // Cutting
            dispatch(updateAdditionalService(9, 'quantity', numeral(cuttingQuantity).format('0,0')));
            dispatch(updateAdditionalService(9, 'total', cuttingTotal));
        } else if (item.id === 20) {    // Saddle Stitching
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.binding_information.books).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.binding_information.total_cost));
        } else if (item.id === 22) {    // Perfect Binding
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.binding_information.books).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.binding_information.total_cost));
        } else if (item.id === 23) {    // Spiral Binding
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.binding_information.books).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.binding_information.total_cost));
        } else if (item.id === 24) {    // Wire Binding
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.binding_information.books).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.binding_information.total_cost));
        } else if (item.id === 25) {    // Staple Binding
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.binding_information.books).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.binding_information.total_cost));
        } else if (item.id === 4 && result.shrink_wrapping_information) {    // Shrink-Wrapping
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.shrink_wrapping_information.total_packages).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.shrink_wrapping_information.total_cost));
            dispatch(setAdditionalService(item.id, 'per_package', item.per_package));
        } else if (item.id === 21 && result.boxing_information) {    // Boxing
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.boxing_information.total_packages).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.boxing_information.total_cost));
            dispatch(setAdditionalService(item.id, 'per_package', item.per_package));
        } else if (item.id === 27) {    // Business Cards Box
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.bc_box_information.quantity).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.bc_box_information.total_cost));
            dispatch(setAdditionalService(item.id, 'box', item.box));
        } else if (item.id === 28) {    // Letterheads Box
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.letterhead_box_information.quantity).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.letterhead_box_information.total_cost));
            dispatch(setAdditionalService(item.id, 'box', item.box));
        }
        dispatch(setAdditionalService(item.id, 'checked', 1));
    }
}

export const addDigitalEstimateData = (result, estimate, estimateType, selectedAdditionalServices, fromAdditional) => (dispatch, getState) => {
    let quantity = null;
    let total = null;

    dispatch(deleteAdditionalServicesItem(13));     // Remove Press
    dispatch(deleteAdditionalServicesItem(7));      // Remove Plates
    dispatch(deleteAdditionalServicesItem(8));      // Remove Inks

    const digitalMachineTotals = getDigitalMachineTotals(result, estimate);
    dispatch(addAdditionalFromEstimate(14, digitalMachineTotals.quantity, digitalMachineTotals.total, fromAdditional));

    const paperTotals = getPaperTotals(result, estimate, estimateType);
    dispatch(addAdditionalFromEstimate(15, paperTotals.quantity, paperTotals.total, fromAdditional));

    quantity = result.cutting_information.quantity * result.paper_information.pss.up;
    total = result.cutting_information.total_cost;
    dispatch(addAdditionalFromEstimate(9, quantity, total, fromAdditional));

    if (parseInt(estimateType) === 7) {     // Pocket Folder
        quantity = result.die_cutter_information.total_pieces;
        total = result.die_cutter_information.total_cost;
        dispatch(addAdditionalFromEstimate(6, quantity, total, fromAdditional));    // Die-Cutting
    } else if (parseInt(estimateType) === 8) {     // NCR
        quantity = result.ncr_information.sets;
        total = result.ncr_information.total_cost;
        dispatch(addAdditionalFromEstimate(29, quantity, total, fromAdditional));    // NCR Padding
    } else if (parseInt(estimateType) === 9) {     // Notepads
        quantity = result.pads_information.pads;
        total = result.pads_information.total_cost;
        dispatch(addAdditionalFromEstimate(30, quantity, total, fromAdditional));    // Pads Padding
    }

    if (result.shrink_wrapping_information && result.shrink_wrapping_information.total_packages > 0) {
        quantity = result.shrink_wrapping_information.total_packages;
        total = result.shrink_wrapping_information.total_cost;
        dispatch(addAdditionalFromEstimate(4, quantity, total, fromAdditional));    // Shrink-Wrapping
    }

    if (result.boxing_information && result.boxing_information.total_packages > 0) {
        quantity = result.boxing_information.total_packages;
        total = result.boxing_information.total_cost;
        dispatch(addAdditionalFromEstimate(21, quantity, total, fromAdditional));    // Boxing
    }

    for (let i = 0; i < selectedAdditionalServices.length; i++) {
        const item = selectedAdditionalServices[i];

        if (item.id === 14) {    // Digital
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(digitalMachineTotals.quantity).format('0,0') + ' pss'));
            dispatch(updateAdditionalService(item.id, 'total', digitalMachineTotals.total));
        } else if (item.id === 15) {    // Paper
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(paperTotals.quantity).format('0,0') + ' pars'));
            dispatch(updateAdditionalService(item.id, 'total', paperTotals.total));
        } else if (item.id === 1) {       // Folding
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.folding_information.quantity).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.folding_information.total_cost));
            dispatch(setAdditionalService(item.id, 'folding_type', item.folding_type));
        } else if (item.id === 2) {    // Scoring
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.scoring_information.total_pieces).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.scoring_information.total_cost));
        } else if (item.id === 3) {    // Drilling
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.drilling_information.quantity).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.drilling_information.total_cost));
        } else if (item.id === 4 && result.shrink_wrapping_information) {    // Shrink-Wrapping
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.shrink_wrapping_information.total_packages).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.shrink_wrapping_information.total_cost));
            dispatch(setAdditionalService(item.id, 'per_package', item.per_package));
        } else if (item.id === 5) {    // Padding
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.padding_information.quantity).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.padding_information.total_cost));
        } else if (item.id === 6) {    // Die-Cutting
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.die_cutter_information.total_pieces).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.die_cutter_information.total_cost));
        } else if (item.id === 9) {    // Cutting
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.cutting_information.quantity * result.paper_information.pss.up).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.cutting_information.total_cost));
        } else if (item.id === 21 && result.boxing_information) {    // Boxing
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.boxing_information.total_packages).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.boxing_information.total_cost));
            dispatch(setAdditionalService(item.id, 'per_package', item.per_package));
        } else if (item.id === 26 && result.perforating_information) {    // Perforating
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.perforating_information.quantity).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.perforating_information.total_cost));
        } else if (item.id === 27) {    // Business Cards Box
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.bc_box_information.quantity).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.bc_box_information.total_cost));
            dispatch(setAdditionalService(item.id, 'box', item.box));
        } else if (item.id === 28) {    // Letterheads Box
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.letterhead_box_information.quantity).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.letterhead_box_information.total_cost));
            dispatch(setAdditionalService(item.id, 'box', item.box));
        } else if (item.id === 29) {    // NCR Padding
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.ncr_information.sets).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.ncr_information.total_cost));
        } else if (item.id === 30) {    // Pads Padding
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(result.pads_information.pads).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.pads_information.total_cost));
        }
        dispatch(setAdditionalService(item.id, 'checked', 1));
    }
}

export const addLargeFormatEstimateData = (estimate, result, selectedAdditionalServices, fromAdditional) => (dispatch, getState) => {
    let quantity = null;
    let total = null;

    quantity = estimate.quantity;
    total = result.large_format_machine_information.total_cost;
    dispatch(addAdditionalFromEstimate(16, quantity, total, fromAdditional));

    quantity = estimate.quantity;
    total = result.substrate_information.total_cost;
    dispatch(addAdditionalFromEstimate(17, quantity, total, fromAdditional));

    if (result.laminate_information !== null) {
        quantity = estimate.quantity;
        total = result.laminate_information.total_cost;
        dispatch(addAdditionalFromEstimate(10, quantity, total, fromAdditional));
    }

    for (let i = 0; i < selectedAdditionalServices.length; i++) {
        const item = selectedAdditionalServices[i];

        if (item.id === 16) {    // Large Format
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(estimate.quantity).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.large_format_machine_information.total_cost));
        } else if (item.id === 17) {    // Substrate
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(estimate.quantity).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.substrate_information.total_cost));
        } else if (item.id === 10 && result.laminate_information !== null) {    // Laminate
            dispatch(updateAdditionalService(item.id, 'quantity', numeral(estimate.quantity).format('0,0')));
            dispatch(updateAdditionalService(item.id, 'total', result.laminate_information.total_cost));
        }
        dispatch(setAdditionalService(item.id, 'checked', 1));
    }

    if (!fromAdditional && result.accessories_information.accessories.length > 0) {
        quantity = estimate.quantity;

        for (let i = 0; i < selectedAdditionalServices.length; i++) {
            const item = selectedAdditionalServices[i];

            for (let j = 0; j < result.accessories_information.accessories.length; j++) {
                const accessory = result.accessories_information.accessories[j];
                if (item.id === 11 && parseInt(accessory.id) === 3) {     // Grommets
                    dispatch(addAdditionalFromEstimate(11, quantity, accessory.total_cost, false));
                } else if (item.id === 12 && parseInt(accessory.id) === 17) {      // H-Stakes
                    dispatch(addAdditionalFromEstimate(12, quantity, accessory.total_cost, false));
                }
            }
        }
    }

    if (result.accessories_information.accessories.length > 0) {
        quantity = estimate.quantity;

        for (let i = 0; i < selectedAdditionalServices.length; i++) {
            const item = selectedAdditionalServices[i];

            for (let j = 0; j < result.accessories_information.accessories.length; j++) {
                const accessory = result.accessories_information.accessories[j];
                if (item.id === 11 && parseInt(accessory.id) === 3) {     // Grommets
                    dispatch(updateAdditionalService(item.id, 'quantity', quantity));
                    dispatch(updateAdditionalService(item.id, 'total', accessory.total_cost));
                } else if (item.id === 12 && parseInt(accessory.id) === 17) {      // H-Stakes
                    dispatch(updateAdditionalService(item.id, 'quantity', quantity));
                    dispatch(updateAdditionalService(item.id, 'total', accessory.total_cost));
                }
                dispatch(setAdditionalService(item.id, 'checked', 1));
            }
        }
    }
}

export const addAdditionalFromEstimate = (id, quantity, total, fromAdditional = false) => (dispatch, getState) => {
    const additionalServices = selectors.getAdditionalServices(getState());

    let name = '';
    let unit = '';
    let position = 1;
    for (let i = 0; i < additionalServices.length; i++) {
        if (additionalServices[i].id === id) {
            name = additionalServices[i].name;
            unit = additionalServices[i].unit;
            position = additionalServices[i].position;
            break;
        }
    }

    if (!fromAdditional) {
        dispatch(addAdditionalService({
            id: id,
            name: name,
            quantity: numeral(quantity).format('0,0') + unit,
            total: total,
            position: position,
        }));
        dispatch(setAdditionalService(id, 'checked', 1));
    }
}

export const saveEstimate = () => (dispatch, getState) => {
    const history = selectors.getHistory(getState());
    const estimate = selectors.getEstimate(getState());
    const result1 = selectors.getResult1(getState());
    const result2 = selectors.getResult2(getState());
    const result3 = selectors.getResult3(getState());
    const estimateType = selectors.getEstimateType(getState());
    const selectedAdditionalServices = selectors.getSelectedAdditionalServices(getState());
    const selectedMailServices = selectors.getSelectedMailServices(getState());
    const selectedAccessories = selectors.getSelectedAccessories(getState());
    const dataChanged = selectors.getDataChanged(getState());
    const deliveryCharge = selectors.getDeliveryCharge(getState());
    const showDeliveryCharge = selectors.getShowDeliveryCharge(getState());
    const mainPartId = selectors.getMainPartId(getState());
    const outsideServices = selectors.getOutsideServices(getState());
    const user = loginSelectors.getUser(getState());

    let result = null;
    if (parseInt(estimate.selected_quantity) === 1) {
        result = result1;
    } else if (parseInt(estimate.selected_quantity) === 2) {
        result = result2;
    } else if (parseInt(estimate.selected_quantity) === 3) {
        result = result3;
    }

    if (parseInt(estimateType) !== 10 && !estimate.full_outside_job && dataChanged) {
        if (isEstimator(user)) {
            return showNotification('Complete Information', 'The estimate configuration has changed, please hit on the Estimate button to update the estimation before to be able to save it', 'info');
        } else {
            return showNotification('Complete Information', 'The estimate is not ready, please contact the Estimator before to proceed', 'info');
        }
    }

    if (!validateFields(estimate, estimateType)) {
        return;
    }

    if (parseInt(estimateType) !== 10 && !estimate.full_outside_job && result == null) {
        return showNotification('Complete Information', 'Please, make an estimate first to save it', 'info');
    }

    if (estimate.full_outside_job && !validateOutsideFields(outsideServices)) {
        return;
    }

    let endpoint = apiUrl + '/sc-estimates';
    let method = 'post';
    if (estimate.id && estimate.id != null) {
        endpoint = apiUrl + '/sc-estimates/' + estimate.id;
        method = 'put';
    }

    dispatch({ type: types.SAVE_ESTIMATE, payload: null });
    axios({
        method: method,
        url: endpoint,
        data: {
            additionalServices: selectedAdditionalServices,
            mailServices: selectedMailServices,
            resultObj: result,
            result1Obj: result1,
            result2Obj: result2,
            result3Obj: result3,
            ...estimate,
            type: estimateType,
            accessories: selectedAccessories,
            deliveryCharge: showDeliveryCharge ? deliveryCharge : null,
            mainPartId: mainPartId,
            outsideServices: outsideServices,
        },
        headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
    }).then((response) => {
        dispatch({ type: types.SAVE_ESTIMATE_SUCCESS, payload: response.data });
        dispatch(setEstimateChanged(false));
        showNotification('Estimate Saved', 'The estimate has been saved successfully', 'success');
        history.push('/estimate/' + response.data.type + '/' + response.data.id);

        if (estimate.id && estimate.id != null) {
            dispatch(fetchEstimate(estimate.id));
        }
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.SAVE_ESTIMATE_ERROR));
    });
}

export const duplicatePart = () => (dispatch, getState) => {
    const estimate = selectors.getEstimate(getState());
    const history = selectors.getHistory(getState());

    dispatch({ type: types.DUPLICATE_PART, payload: null });
    axios.post(apiUrl + '/sc-estimate/duplicate-part', { id: estimate.id },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }, data: null }
    ).then((response) => {
        dispatch({ type: types.DUPLICATE_PART_SUCCESS, payload: response.data });
        showNotification('Part Duplicated', 'The part has been duplicated successfully', 'success');
        history.push('/estimate/' + response.data.type + '/' + response.data.id);
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.DUPLICATE_PART_ERROR));
    });
}

export const deleteEstimate = (id) => (dispatch, getState) => {
    const history = selectors.getHistory(getState());
    const mainPart = selectors.getMainPart(getState());
    const estimate = selectors.getEstimate(getState());

    dispatch({ type: types.DELETE_ESTIMATE, payload: null });
    axios.delete(apiUrl + '/sc-estimates/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }, data: null }
    ).then((response) => {
        dispatch({ type: types.DELETE_ESTIMATE_SUCCESS, payload: null });
        showDataDeletedNotification();
        dispatch(dialog.actions.hideDialog('delete-part-modal'));

        if (estimate.is_main_part) {
            history.push('/estimates');
        } else {
            history.push('/estimate/' + mainPart.type + '/' + mainPart.id);
        }
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.DELETE_ESTIMATE_ERROR));
    });
}

export const validateFields = (estimate, estimateType, displayNotification = true) => {
    if (parseInt(estimateType) === 1 || parseInt(estimateType) === 7) {     // Sheetfed or Pocket Folder
        let fields = [
            { field: 'csr_id', message: 'Select a CSR' },
            { field: 'product_type', message: 'Select a Product Type' },
            { field: 'process_request', message: 'Select a Process Request' },
            { field: 'markup_factor', message: 'Select a Markup Factor' },
            { field: 'description_title', message: 'Enter a Description Title' },
            { field: 'quantity', message: 'Enter the quantity' },
            { field: 'output_device', message: 'Select the Output Device' },
            { field: 'paper_stock', message: 'Select the Paper Category' },
            { field: 'paper_name', message: 'Select the Paper Name' },
            { field: 'paper_weight', message: 'Select the Paper Weight' },
            { field: 'parent_size', message: 'Select the Paper Size' },
            { field: 'paper_color', message: 'Select the Paper Color' },
            { field: 'run_size_width', message: 'Enter the Run Size (Width)' },
            { field: 'run_size_height', message: 'Enter the Run Size (Height)' },
            { field: 'flat_size_width', message: 'Enter the Flat Size (Width)' },
            { field: 'flat_size_height', message: 'Enter the Flat Size (Height)' },
            { field: 'finish_size_width', message: 'Enter the Finish Size (Width)' },
            { field: 'finish_size_height', message: 'Enter the Finish Size (Height)' },
            { field: 'front_colors', message: 'Enter the Front Colors' },
            { field: 'front_inks', message: 'Select the Front Ink Type' },
            { field: 'back_colors', message: 'Enter the Back Colors' },
            { field: 'back_inks', message: 'Select the Back Ink Type' },
        ];
        if (!isDigital(estimate.output_device)) {
            fields.push({ field: 'run_method', message: 'Select the Run Method' });
        }

        if (parseInt(estimateType) === 7) {     // Pocket Folder
            fields.push({ field: 'folder_type', message: 'Select the Folder Type' });
            fields.push({ field: 'pocket_style', message: 'Select the Pocket Style' });
            fields.push({ field: 'card_slits', message: 'Select the Card Slits' });
        }

        for (let i = 0; i < fields.length; i++) {
            if (empty(estimate[fields[i].field])) {
                displayNotification && showNotification('Complete Information', fields[i].message, 'info');
                return false;
            }
        }
    } else if (parseInt(estimateType) === 2) {     // Envelopes
        let fields = [
            { field: 'csr_id', message: 'Select a CSR' },
            { field: 'product_type', message: 'Select a Product Type' },
            { field: 'process_request', message: 'Select a Process Request' },
            { field: 'markup_factor', message: 'Select a Markup Factor' },
            { field: 'description_title', message: 'Enter a Description Title' },
            { field: 'quantity', message: 'Enter the quantity' },
            { field: 'output_device', message: 'Select the Output Device for the Interior' },
            { field: 'envelope_paper_type', message: 'Select the Envelope Type' },
            { field: 'envelope_paper_style', message: 'Select the Envelope Style' },
            { field: 'envelope_size', message: 'Select the Envelope Size' },
            { field: 'envelope_paper_weight', message: 'Select the Envelope Weight' },
            { field: 'front_colors', message: 'Enter the Front Colors' },
            { field: 'front_inks', message: 'Select the Front Ink Type' },
            { field: 'back_colors', message: 'Enter the Back Colors' },
            { field: 'back_inks', message: 'Select the Back Ink Type' },
        ];

        for (let i = 0; i < fields.length; i++) {
            if (empty(estimate[fields[i].field])) {
                displayNotification && showNotification('Complete Information', fields[i].message, 'info');
                return false;
            }
        }

        if (parseInt(estimate.has_return_address) === 0 && parseInt(estimate.has_back_flap) === 0 && parseInt(estimate.has_address_area) === 0 && parseInt(estimate.has_other) === 0) {
            displayNotification && showNotification('Complete Information', 'Select a Print Location', 'info');
            return false;
        }
    } else if (parseInt(estimateType) === 3) {     // Plus-Cover
        let fields = [
            { field: 'csr_id', message: 'Select a CSR' },
            { field: 'product_type', message: 'Select a Product Type' },
            { field: 'process_request', message: 'Select a Process Request' },
            { field: 'markup_factor', message: 'Select a Markup Factor' },
            { field: 'description_title', message: 'Enter a Description Title' },
            { field: 'quantity', message: 'Enter the quantity' },
            { field: 'output_device', message: 'Select the Output Device for the Interior' },
            { field: 'paper_stock', message: 'Select the Paper Category' },
            { field: 'paper_name', message: 'Select the Paper Name' },
            { field: 'paper_weight', message: 'Select the Paper Weight' },
            { field: 'parent_size', message: 'Select the Paper Size' },
            { field: 'paper_color', message: 'Select the Paper Color' },
            { field: 'run_size_width', message: 'Enter the Run Size (Width)' },
            { field: 'run_size_height', message: 'Enter the Run Size (Height)' },
            { field: 'flat_size_width', message: 'Enter the Flat Size (Width)' },
            { field: 'flat_size_height', message: 'Enter the Flat Size (Height)' },
            { field: 'finish_size_width', message: 'Enter the Finish Size (Width)' },
            { field: 'finish_size_height', message: 'Enter the Finish Size (Height)' },
            { field: 'page_count', message: 'Enter the Page Count' },
            { field: 'binding_edge', message: 'Select the Binding Edge' },
            { field: 'front_colors', message: 'Enter the Interior Colors' },
            { field: 'front_inks', message: 'Select the Interior Ink Type' },
            { field: 'cover_output_device', message: 'Select the Output Device for the Cover' },
            { field: 'cover_paper_stock', message: 'Select the Paper Category for the Cover' },
            { field: 'cover_paper_name', message: 'Select the Paper Name for the Cover' },
            { field: 'cover_paper_weight', message: 'Select the Paper Weight for the Cover' },
            { field: 'cover_parent_size', message: 'Select the Paper Size for the Cover' },
            { field: 'cover_paper_color', message: 'Select the Paper Color for the Cover' },
            { field: 'cover_front_colors', message: 'Enter the Front Colors for the Cover' },
            { field: 'cover_front_inks', message: 'Select the Front Ink Type for the Cover' },
            { field: 'cover_back_colors', message: 'Enter the Back Colors for the Cover' },
            { field: 'cover_back_inks', message: 'Select the Back Ink Type for the Cover' },
            { field: 'cover_run_size_width', message: 'Enter the Cover Run Size (Width)' },
            { field: 'cover_run_size_height', message: 'Enter the Cover Run Size (Height)' },
            { field: 'publication_type', message: 'Select the Binding Type' },
        ];
        if (!isDigital(estimate.cover_output_device)) {
            fields.push({ field: 'cover_run_method', message: 'Select the Run Method for the Cover' });
        }

        if (!isDigital(estimate.output_device)) {
            fields.push({ field: 'run_method', message: 'Select the Run Method' });
        }

        for (let i = 0; i < fields.length; i++) {
            if (empty(estimate[fields[i].field])) {
                displayNotification && showNotification('Complete Information', fields[i].message, 'info');
                return false;
            }
        }

        if (parseInt(estimate.publication_type) === 1 && parseInt(estimate.page_count) > 96) {
            displayNotification && showNotification('Complete Information', 'The Page count cannot be greater than 96', 'info');
            return false;
        }

        if (parseInt(estimate.publication_type) === 1 && parseInt(estimate.page_count) % 4 !== 0) {
            displayNotification && showNotification('Complete Information', 'The Page count must be divisible by 4', 'info');
            return false;
        }

        if (parseInt(estimate.lock_imposition) === 1 && empty(estimate.lock_imposition_pages)) {
            displayNotification && showNotification('Complete Information', 'Select the Lock Imposition pages', 'info');
            return false;
        }
    } else if (parseInt(estimateType) === 4) {     // Self-Cover
        let fields = [
            { field: 'csr_id', message: 'Select a CSR' },
            { field: 'product_type', message: 'Select a Product Type' },
            { field: 'process_request', message: 'Select a Process Request' },
            { field: 'markup_factor', message: 'Select a Markup Factor' },
            { field: 'description_title', message: 'Enter a Description Title' },
            { field: 'quantity', message: 'Enter the quantity' },
            { field: 'output_device', message: 'Select the Output Device' },
            { field: 'paper_stock', message: 'Select the Paper Category' },
            { field: 'paper_name', message: 'Select the Paper Name' },
            { field: 'paper_weight', message: 'Select the Paper Weight' },
            { field: 'parent_size', message: 'Select the Paper Size' },
            { field: 'paper_color', message: 'Select the Paper Color' },
            { field: 'run_size_width', message: 'Enter the Run Size (Width)' },
            { field: 'run_size_height', message: 'Enter the Run Size (Height)' },
            { field: 'flat_size_width', message: 'Enter the Flat Size (Width)' },
            { field: 'flat_size_height', message: 'Enter the Flat Size (Height)' },
            { field: 'finish_size_width', message: 'Enter the Finish Size (Width)' },
            { field: 'finish_size_height', message: 'Enter the Finish Size (Height)' },
            { field: 'page_count', message: 'Enter the Page Count' },
            { field: 'binding_edge', message: 'Select the Binding Edge' },
            { field: 'front_colors', message: 'Enter the Front Colors' },
            { field: 'front_inks', message: 'Select the Front Ink Type' },
            { field: 'publication_type', message: 'Select the Binding Type' },
        ];
        if (!isDigital(estimate.output_device)) {
            fields.push({ field: 'run_method', message: 'Select the Run Method' });
        }

        for (let i = 0; i < fields.length; i++) {
            if (empty(estimate[fields[i].field])) {
                displayNotification && showNotification('Complete Information', fields[i].message, 'info');
                return false;
            }
        }

        if (parseInt(estimate.publication_type) === 1 && parseInt(estimate.page_count) > 96) {
            displayNotification && showNotification('Complete Information', 'The Page count cannot be greater than 96', 'info');
            return false;
        }

        if (parseInt(estimate.publication_type) === 1 && parseInt(estimate.page_count) % 4 !== 0) {
            displayNotification && showNotification('Complete Information', 'The Page count must be divisible by 4', 'info');
            return false;
        }

        if (parseInt(estimate.lock_imposition) === 1 && empty(estimate.lock_imposition_pages)) {
            displayNotification && showNotification('Complete Information', 'Select the Lock Imposition pages', 'info');
            return false;
        }
    } else if (parseInt(estimateType) === 5) {     // Digital
        let fields = [
            { field: 'csr_id', message: 'Select a CSR' },
            { field: 'product_type', message: 'Select a Product Type' },
            { field: 'process_request', message: 'Select a Process Request' },
            { field: 'markup_factor', message: 'Select a Markup Factor' },
            { field: 'description_title', message: 'Enter a Description Title' },
            { field: 'quantity', message: 'Enter the quantity' },
            { field: 'output_device', message: 'Select the Output Device' },
            { field: 'paper_stock', message: 'Select the Paper Category' },
            { field: 'paper_name', message: 'Select the Paper Name' },
            { field: 'paper_weight', message: 'Select the Paper Weight' },
            { field: 'parent_size', message: 'Select the Paper Size' },
            { field: 'paper_color', message: 'Select the Paper Color' },
            { field: 'run_size_width', message: 'Enter the Run Size (Width)' },
            { field: 'run_size_height', message: 'Enter the Run Size (Height)' },
            { field: 'flat_size_width', message: 'Enter the Flat Size (Width)' },
            { field: 'flat_size_height', message: 'Enter the Flat Size (Height)' },
            { field: 'finish_size_width', message: 'Enter the Finish Size (Width)' },
            { field: 'finish_size_height', message: 'Enter the Finish Size (Height)' },
            { field: 'front_colors', message: 'Enter the Front Colors' },
            { field: 'front_inks', message: 'Select the Front Ink Type' },
            { field: 'back_colors', message: 'Enter the Back Colors' },
            { field: 'back_inks', message: 'Select the Back Ink Type' },
        ];

        for (let i = 0; i < fields.length; i++) {
            if (empty(estimate[fields[i].field])) {
                displayNotification && showNotification('Complete Information', fields[i].message, 'info');
                return false;
            }
        }
    } else if (parseInt(estimateType) === 6) {     // Large Format
        let fields = [
            { field: 'csr_id', message: 'Select a CSR' },
            { field: 'product_type', message: 'Select a Product Type' },
            { field: 'process_request', message: 'Select a Process Request' },
            { field: 'markup_factor', message: 'Select a Markup Factor' },
            { field: 'description_title', message: 'Enter a Description Title' },
            { field: 'output_device', message: 'Select the Output Device' },
            { field: 'substrate', message: 'Select the Substrate' },
            { field: 'finish_size_width', message: 'Enter the Finish Size (Width)' },
            { field: 'finish_size_height', message: 'Enter the Finish Size (Height)' },
            { field: 'front_inks', message: 'Select the Ink' },
            { field: 'quantity', message: 'Enter the quantity' },
        ];
        for (let i = 0; i < fields.length; i++) {
            if (empty(estimate[fields[i].field])) {
                displayNotification && showNotification('Complete Information', fields[i].message, 'info');
                return false;
            }
        }
    } else if (parseInt(estimateType) === 8) {     // NCR
        let fields = [
            { field: 'csr_id', message: 'Select a CSR' },
            { field: 'product_type', message: 'Select a Product Type' },
            { field: 'process_request', message: 'Select a Process Request' },
            { field: 'markup_factor', message: 'Select a Markup Factor' },
            { field: 'description_title', message: 'Enter a Description Title' },
            { field: 'quantity', message: 'Enter the quantity' },
            { field: 'output_device', message: 'Select the Output Device' },
            { field: 'paper_stock', message: 'Select the Paper Category' },
            { field: 'paper_name', message: 'Select the Paper Name' },
            { field: 'paper_weight', message: 'Select the Paper Weight' },
            { field: 'parent_size', message: 'Select the Paper Size' },
            { field: 'paper_color', message: 'Select the Paper Color' },
            { field: 'ncr_sets', message: 'Enter the Number of Sets' },
            { field: 'ncr_parts', message: 'Select the Number of Parts' },
            { field: 'finish_size_width', message: 'Enter the Finish Size (Width)' },
            { field: 'finish_size_height', message: 'Enter the Finish Size (Height)' },
            { field: 'front_colors', message: 'Enter the Front Colors' },
            { field: 'front_inks', message: 'Select the Front Ink Type' },
            { field: 'back_colors', message: 'Enter the Back Colors' },
            { field: 'back_inks', message: 'Select the Back Ink Type' },
            { field: 'glue_location', message: 'Select the Glue Location' },
            { field: 'perforate', message: 'Select the Perforate' },
            { field: 'use_chip_board', message: 'Select the Backing' },
        ];

        if (!isDigital(estimate.output_device)) {
            fields.push({ field: 'run_method', message: 'Select the Run Method' });
        }

        if (parseInt(estimate.use_crash_number)) {
            fields.push({ field: 'crash_number_start', message: 'Enter the Start # for the Crash Number' });
            fields.push({ field: 'crash_number_end', message: 'Enter the End # for the Crash Number' });
            fields.push({ field: 'crash_number_color', message: 'Enter the Color for the Crash Number' });
        }

        for (let i = 0; i < fields.length; i++) {
            if (empty(estimate[fields[i].field])) {
                displayNotification && showNotification('Complete Information', fields[i].message, 'info');
                return false;
            }
        }
    } else if (parseInt(estimateType) === 9) {     // Notepads
        let fields = [
            { field: 'csr_id', message: 'Select a CSR' },
            { field: 'product_type', message: 'Select a Product Type' },
            { field: 'process_request', message: 'Select a Process Request' },
            { field: 'markup_factor', message: 'Select a Markup Factor' },
            { field: 'description_title', message: 'Enter a Description Title' },
            { field: 'quantity', message: 'Enter the quantity' },
            { field: 'output_device', message: 'Select the Output Device' },
            { field: 'paper_stock', message: 'Select the Paper Category' },
            { field: 'paper_name', message: 'Select the Paper Name' },
            { field: 'paper_weight', message: 'Select the Paper Weight' },
            { field: 'parent_size', message: 'Select the Paper Size' },
            { field: 'paper_color', message: 'Select the Paper Color' },
            { field: 'pads', message: 'Enter the Number of Pads' },
            { field: 'sheets_per_pad', message: 'Select the Sheets per Pad' },
            { field: 'finish_size_width', message: 'Enter the Finish Size (Width)' },
            { field: 'finish_size_height', message: 'Enter the Finish Size (Height)' },
            { field: 'front_colors', message: 'Enter the Front Colors' },
            { field: 'front_inks', message: 'Select the Front Ink Type' },
            { field: 'back_colors', message: 'Enter the Back Colors' },
            { field: 'back_inks', message: 'Select the Back Ink Type' },
            { field: 'glue_location', message: 'Select the Glue Location' },
            { field: 'use_chip_board', message: 'Select the Backing' },
        ];

        if (!isDigital(estimate.output_device)) {
            fields.push({ field: 'run_method', message: 'Select the Run Method' });
        }

        if (parseInt(estimate.use_crash_number)) {
            fields.push({ field: 'crash_number_start', message: 'Enter the Start # for the Crash Number' });
            fields.push({ field: 'crash_number_end', message: 'Enter the End # for the Crash Number' });
            fields.push({ field: 'crash_number_color', message: 'Enter the Color for the Crash Number' });
        }

        for (let i = 0; i < fields.length; i++) {
            if (empty(estimate[fields[i].field])) {
                displayNotification && showNotification('Complete Information', fields[i].message, 'info');
                return false;
            }
        }
    } else if (parseInt(estimateType) === 10) {     // Mail Only
        let fields = [
            { field: 'csr_id', message: 'Select a CSR' },
            { field: 'product_type', message: 'Select a Product Type' },
            { field: 'process_request', message: 'Select a Process Request' },
            { field: 'markup_factor', message: 'Select a Markup Factor' },
            { field: 'description_title', message: 'Enter a Description Title' },
            { field: 'originals', message: 'Enter the Originals' },
            { field: 'type_of_materials', message: 'Enter the Type of Materials' },
            { field: 'material_coming_date', message: 'Select the Date for Material Arrive' },
            { field: 'leftovers_to_do', message: 'Select what to do with leftovers' },
            { field: 'finish_size_width', message: 'Enter the Finish Size (Width)' },
            { field: 'finish_size_height', message: 'Enter the Finish Size (Height)' },
            { field: 'quantity', message: 'Enter the quantity' },
        ];

        for (let i = 0; i < fields.length; i++) {
            if (empty(estimate[fields[i].field])) {
                displayNotification && showNotification('Complete Information', fields[i].message, 'info');
                return false;
            }
        }
    }

    return true;
}

export const validateOutsideFields = (outsideServices, displayNotification = true) => {
    if (outsideServices.length === 0) {
        displayNotification && showNotification('Complete Information', 'Enter at least one Outside Vendor', 'info');
        return false;
    }
    if (empty(outsideServices[0].outside_vendor_id)) {
        displayNotification && showNotification('Complete Information', 'Select an Outside Vendor', 'info');
        return false;
    }
    if (empty(outsideServices[0].outside_cost)) {
        displayNotification && showNotification('Complete Information', 'Enter the Outside Cost', 'info');
        return false;
    }

    return true;
}

export const setField = (name, value) => (dispatch) => {
    dispatch({
        type: types.SET_FIELD,
        payload: { name, value }
    });
}
export const setId = (id) => (dispatch) => {
    dispatch({
        type: types.SET_ID,
        payload: id,
    });
}
export const setHistory = (history) => (dispatch) => {
    dispatch({
        type: types.SET_HISTORY,
        payload: history,
    });
}
export const setMailServicesTab = (value) => (dispatch) => {
    dispatch({
        type: types.SET_MAIL_SERVICES_TAB,
        payload: value,
    });
}
export const calculateTotals = (props, isMain = true, resultObj) => {
    const estimateType = props.type;
    const estimate = props.estimate;
    const deliveryCharge = props.deliveryCharge;

    let subtotal = 0;
    let markupPrice = 0;
    let commission = 0;
    let outsidePrice = 0;
    let tax = 0;
    let total = 0;

    /*************************** Outside **************************/
    for (let i = 0; i < props.outsideServices.length; i++) {
        const service = props.outsideServices[i];
        let servicePrice = 0;
        if (service.outside_cost) {     // Outside Cost
            servicePrice = parseFloat(service.outside_cost);
        }

        if (service.outside_markup) {     // Outside Markup
            servicePrice *= 1 + parseFloat(service.outside_markup) / 100;
        }

        if (service.outside_commission) {
            servicePrice *= 1 + parseFloat(service.outside_commission) / 100;
        }

        outsidePrice += servicePrice;
    }

    if (estimate.full_outside_job) {
        if (props.showDeliveryCharge) {
            subtotal = parseFloat(deliveryCharge.total);
        }

        return {
            subtotal: subtotal,
            outsidePrice: outsidePrice,
            total: subtotal + outsidePrice,
        };
    }

    /*************************** Subtotal **************************/
    let result = null;
    if (isMain) {
        for (let i = 0; i < props.selectedAdditionalServices.length; i++) {
            const item = props.selectedAdditionalServices[i];
            const totalPrice = parseFloat(item.total);
            subtotal += parseFloat(totalPrice.toFixed(2));
        }
    } else if (!empty(resultObj)) {

        if (parseInt(estimateType) === 2) {   // Envelopes
            if (resultObj && resultObj != null && resultObj.totals) {
                result = resultObj;
            } else if (resultObj && resultObj != null && resultObj.results.length > 0) {
                result = resultObj.results[0];
            }
        } else {
            if (resultObj && resultObj != null && resultObj.results.length > 0) {
                result = resultObj.results[0];
            }
        }

        let coverResult = null;
        if (parseInt(estimateType) === 3 && resultObj && resultObj.cover_results.length > 0) {
            coverResult = resultObj.cover_results[0];
        }

        for (let i = 0; i < props.selectedAdditionalServices.length && !empty(result); i++) {
            const item = props.selectedAdditionalServices[i];

            if (parseInt(item.id) === 1) {  // Folding
                const amount = getFoldingAmount(estimateType, result, coverResult);
                subtotal += parseFloat(amount.total.toFixed(2));
            } else if (parseInt(item.id) === 2) {  // Scoring
                total = result.scoring_information.total_cost;
                subtotal += parseFloat(total.toFixed(2));
            } else if (parseInt(item.id) === 3) {  // Drilling
                total = result.drilling_information.total_cost;
                subtotal += parseFloat(total.toFixed(2));
            } else if (parseInt(item.id) === 4) {  // Shrink-Wrapping
                total = result.shrink_wrapping_information.total_cost;
                subtotal += parseFloat(total.toFixed(2));
            } else if (parseInt(item.id) === 5) {  // Padding
                total = result.padding_information.total_cost;
                subtotal += parseFloat(total.toFixed(2));
            } else if (parseInt(item.id) === 6) {  // Die-Cutting
                total = result.die_cutter_information.total_cost;
                subtotal += parseFloat(total.toFixed(2));
            } else if (parseInt(item.id) === 7) {  // Plates
                const amount = getPlatesAmount(estimateType, result, coverResult);
                subtotal += parseFloat(amount.total.toFixed(2));
            } else if (parseInt(item.id) === 8) {  // Inks
                const amount = getInksAmount(estimateType, result, coverResult);
                subtotal += parseFloat(amount.total.toFixed(2));
            } else if (parseInt(item.id) === 9) {  // Cutting
                const amount = getCuttingAmount(estimateType, result, coverResult);
                subtotal += parseFloat(amount.total.toFixed(2));
            } else if (parseInt(item.id) === 10) {  // Laminate
                total = result.laminate_information.total_cost;
                subtotal += parseFloat(total.toFixed(2));
            } else if (parseInt(item.id) === 13) {  // Press
                let amount = getPressAmount(estimateType, result, coverResult);

                const costPerSheet = amount.total / amount.quantity;
                if (!empty(estimate.press_additional_sheets)) {
                    // quantity += parseInt(estimate.press_additional_sheets);
                    amount.total += parseInt(estimate.press_additional_sheets) * costPerSheet;
                }
                if (!empty(estimate.bindery_additional_sheets)) {
                    // quantity += parseInt(estimate.bindery_additional_sheets);
                    amount.total += parseInt(estimate.bindery_additional_sheets) * costPerSheet;
                }

                subtotal += parseFloat(amount.total.toFixed(2));
            } else if (parseInt(item.id) === 14) {  // Digital
                let amount = getDigitalMachineAmount(estimateType, result, coverResult);

                const costPerSheet = amount.total / amount.quantity;
                if (!empty(estimate.press_additional_sheets)) {
                    // quantity += parseInt(estimate.press_additional_sheets);
                    amount.total += parseInt(estimate.press_additional_sheets) * costPerSheet;
                }
                if (!empty(estimate.bindery_additional_sheets)) {
                    // quantity += parseInt(estimate.bindery_additional_sheets);
                    amount.total += parseInt(estimate.bindery_additional_sheets) * costPerSheet;
                }

                subtotal += parseFloat(amount.total.toFixed(2));
            } else if (parseInt(item.id) === 15) {  // Paper
                let amount = getPaperAmount(estimateType, result, coverResult);

                const costPerSheet = amount.total / amount.quantity;
                if (!empty(estimate.press_additional_sheets)) {
                    // quantity += parseInt(estimate.press_additional_sheets);
                    amount.total += parseInt(estimate.press_additional_sheets) * costPerSheet;
                }
                if (!empty(estimate.bindery_additional_sheets)) {
                    // quantity += parseInt(estimate.bindery_additional_sheets);
                    amount.total += parseInt(estimate.bindery_additional_sheets) * costPerSheet;
                }

                subtotal += parseFloat(amount.total.toFixed(2));
            } else if (parseInt(item.id) === 16) {  // Large Format
                total = result.large_format_machine_information.total_cost;
                subtotal += parseFloat(total.toFixed(2));
            } else if (parseInt(item.id) === 17) {  // Substrate
                total = result.substrate_information.total_cost;
                subtotal += parseFloat(total.toFixed(2));
            } else if (parseInt(item.id) === 18) {  // Envelope Press
                total = result.envelope_press.press_cost + result.envelope_press.pre_press_cost;
                subtotal += parseFloat(total.toFixed(2));
            } else if (parseInt(item.id) === 19) {  // Envelope Paper
                total = result.envelope_paper.cost;
                subtotal += parseFloat(total.toFixed(2));
            } else if (parseInt(item.id) === 20) {  // Saddle Stitching
                total = result.binding_information.total_cost;
                subtotal += parseFloat(total.toFixed(2));
            } else if (parseInt(item.id) === 21) {  // Boxing
                total = result.boxing_information.total_cost;
                subtotal += parseFloat(total.toFixed(2));
            } else if (parseInt(item.id) === 22) {  // Perfect Binding
                total = result.binding_information.total_cost;
                subtotal += parseFloat(total.toFixed(2));
            } else if (parseInt(item.id) === 23) {  // Spiral Binding
                total = result.binding_information.total_cost;
                subtotal += parseFloat(total.toFixed(2));
            } else if (parseInt(item.id) === 24) {  // Wire Binding
                total = result.binding_information.total_cost;
                subtotal += parseFloat(total.toFixed(2));
            } else if (parseInt(item.id) === 25) {  // Staple Binding
                total = result.binding_information.total_cost;
                subtotal += parseFloat(total.toFixed(2));
            } else if (parseInt(item.id) === 26 && result.perforating_information) {  // Perforating
                total = result.perforating_information.total_cost;
                subtotal += parseFloat(total.toFixed(2));
            } else if (parseInt(item.id) === 27) {  // Business Cards Box
                total = result.bc_box_information.total_cost;
                subtotal += parseFloat(total.toFixed(2));
            } else if (parseInt(item.id) === 28) {  // Letterheads Box
                total = result.letterhead_box_information.total_cost;
                subtotal += parseFloat(total.toFixed(2));
            } else if (item.id === 29) {    // NCR Padding
                total = result.ncr_information.total_cost;
                subtotal += parseFloat(total.toFixed(2));
            } else if (item.id === 30) {    // Pads Padding
                total = result.pads_information.total_cost;
                subtotal += parseFloat(total.toFixed(2));
            }
        }
    }

    let subtotalMailing = 0;
    for (let i = 0; i < props.selectedMailServices.length; i++) {
        const item = props.selectedMailServices[i];
        const totalPrice = parseFloat(item.total_price);
        subtotalMailing += parseFloat(totalPrice.toFixed(2));
    }

    if (props.showDeliveryCharge) {
        subtotal += parseFloat(deliveryCharge.total);
    }

    if (parseInt(estimateType) === 6) {
        if (isMain) {
            let resultObj = null;
            if (parseInt(estimate.selected_quantity) === 1) {
                resultObj = props.result1;
            } else if (parseInt(estimate.selected_quantity) === 2) {
                resultObj = props.result2;
            } else if (parseInt(estimate.selected_quantity) === 3) {
                resultObj = props.result3;
            }

            let lfResult = null;
            if (resultObj && resultObj != null && resultObj.results.length > 0) {
                lfResult = resultObj.results[0];
            }
            if (lfResult != null) {
                for (let i = 0; i < lfResult.accessories_information.accessories.length; i++) {
                    const accessory = lfResult.accessories_information.accessories[i];
                    const totalCost = parseFloat(accessory.total_cost);
                    subtotal += parseFloat(totalCost.toFixed(2));
                }
            }
        } else {
            let lfResult = null;
            if (resultObj && resultObj != null && resultObj.results.length > 0) {
                lfResult = resultObj.results[0];
            }
            if (lfResult != null) {
                for (let i = 0; i < lfResult.accessories_information.accessories.length; i++) {
                    const accessory = lfResult.accessories_information.accessories[i];
                    const totalCost = parseFloat(accessory.total_cost);
                    subtotal += parseFloat(totalCost.toFixed(2));
                }
            }
        }
    }

    subtotal = parseFloat(subtotal.toFixed(2));
    subtotalMailing = parseFloat(subtotalMailing.toFixed(2));

    /******************** Additional Adjustments *******************/
    if (!empty(props.estimate.amount_adjustment)) {
        subtotal += parseFloat(props.estimate.amount_adjustment);
    }
    if (!empty(props.estimate.amount_percentage)) {
        subtotal *= 1 + parseFloat(props.estimate.amount_percentage) / 100;
    }

    /*************************** Markup **************************/
    let markup = 0;
    if (!empty(estimate.markup)) {
        markup = parseFloat(estimate.markup) / 100;
    }
    markupPrice = parseFloat((subtotal * markup).toFixed(2));

    /************************** Commission ************************/
    let markupFactor = 0;
    if (parseInt(estimate.markup_factor) === 1) {   // Sales
        markupFactor = 0.1;
    } else if (parseInt(estimate.markup_factor) === 2) {   // Broker
        markupFactor = 0.3;
    } else if (parseInt(estimate.markup_factor) === 3) {   // Good
        markupFactor = 0.25;
    } else if (parseInt(estimate.markup_factor) === 4) {   // Great
        markupFactor = 0.2;
    } else if (parseInt(estimate.markup_factor) === 5) {   // Excellent
        markupFactor = 0.15;
    } else if (parseInt(estimate.markup_factor) === 6) {   // PITAA
        markupFactor = 0.45;
    }

    subtotal += subtotalMailing;

    /*************************** Total **************************/
    //      => subtotal + markupPrice + outsidePrice + markupFactor * x = x
    //      => x = (subtotal + markupPrice + outsidePrice) / (1 - markupFactor)
    total = (subtotal + markupPrice + outsidePrice) / (1 - markupFactor);

    commission = parseFloat((total * markupFactor).toFixed(2));

    /*************************** Tax **************************/
    if (parseInt(estimate.use_tax) === 1) {
        tax = parseFloat((total * 0.0825).toFixed(2));
    }

    total += tax;

    if (!isEstimator(props.user)) {
        subtotal += markupPrice;
    }

    return {
        subtotal: subtotal,
        markupPrice: markupPrice,
        commission: commission,
        outsidePrice: outsidePrice,
        tax: tax,
        total: total,
    };
}

export const getPressAmount = (estimateType, result, coverResult) => {
    let quantity = 0;
    let total = 0;

    if (parseInt(estimateType) === 3) {   // Plus-Cover
        if (coverResult.press_information) {
            quantity = coverResult.press_information.quantity;
            total = coverResult.press_information.total_running_cost + coverResult.press_information.total_setup_cost;
        }

        for (let i = 0; result.impositions_information && i < result.impositions_information.length; i++) {
            const imposition = result.impositions_information[i];
            if (imposition.press_information) {
                quantity += imposition.press_information.quantity;
                total += imposition.press_information.total_running_cost + imposition.press_information.total_setup_cost;
            }
        }
    } else if (parseInt(estimateType) === 4) {   // Self-Cover
        for (let i = 0; result.impositions_information && i < result.impositions_information.length; i++) {
            const imposition = result.impositions_information[i];
            if (imposition.press_information) {
                quantity += imposition.press_information.quantity;
                total += imposition.press_information.total_running_cost + imposition.press_information.total_setup_cost;
            }
        }
    } else {
        if (result.press_information) {
            quantity = result.press_information.quantity;
            total = result.press_information.total_running_cost + result.press_information.total_setup_cost;
        }
    }

    return { quantity, total };
}

export const getPlatesAmount = (estimateType, result, coverResult) => {
    let quantity = 0;
    let total = 0;

    if (parseInt(estimateType) === 3) {   // Plus-Cover
        if (coverResult.press_information) {
            quantity = coverResult.press_information.total_plates;
            total = coverResult.press_information.total_plates_cost;
        }

        for (let i = 0; result.impositions_information && i < result.impositions_information.length; i++) {
            const imposition = result.impositions_information[i];
            if (imposition.press_information) {
                quantity += imposition.press_information.total_plates;
                total += imposition.press_information.total_plates_cost;
            }
        }
    } else if (parseInt(estimateType) === 4) {   // Self-Cover
        total = 0;
        for (let i = 0; result.impositions_information && i < result.impositions_information.length; i++) {
            const imposition = result.impositions_information[i];
            if (imposition.press_information) {
                quantity += imposition.press_information.total_plates;
                total += imposition.press_information.total_plates_cost;
            }
        }
    } else {
        if (result.press_information) {
            quantity = result.press_information.total_plates;
            total = result.press_information.total_plates_cost;
        }
    }

    return { quantity, total };
}

export const getDigitalMachineAmount = (estimateType, result, coverResult) => {
    let quantity = 0;
    let total = 0;

    if (parseInt(estimateType) === 3) {   // Plus-Cover
        if (coverResult.digital_machine_information) {
            quantity = coverResult.digital_machine_information.quantity;
            total = coverResult.digital_machine_information.total_cost;
        }

        for (let i = 0; result.impositions_information && i < result.impositions_information.length; i++) {
            const imposition = result.impositions_information[i];
            if (imposition.digital_machine_information) {
                quantity += imposition.digital_machine_information.quantity;
                total += imposition.digital_machine_information.total_cost;
            }
        }
    } else if (parseInt(estimateType) === 4) {   // Self-Cover
        for (let i = 0; result.impositions_information && i < result.impositions_information.length; i++) {
            const imposition = result.impositions_information[i];
            if (imposition.digital_machine_information) {
                quantity += imposition.digital_machine_information.quantity;
                total += imposition.digital_machine_information.total_cost;
            }
        }
    } else {
        if (result.digital_machine_information) {
            quantity = result.digital_machine_information.quantity;
            total = result.digital_machine_information.total_cost;
        }
    }

    return { quantity, total };
}

export const getPaperAmount = (estimateType, result, coverResult) => {
    let quantity = 0;
    let total = 0;

    if (parseInt(estimateType) === 3) {   // Plus-Cover
        if (coverResult.paper_information) {
            quantity = coverResult.paper_information.total_pars;
            total = coverResult.paper_information.total_cost;
        }

        for (let i = 0; result.impositions_information && i < result.impositions_information.length; i++) {
            const imposition = result.impositions_information[i];
            if (imposition.paper_information) {
                quantity += imposition.paper_information.total_pars;
                total += imposition.paper_information.total_cost;
            }
        }
    } else if (parseInt(estimateType) === 4) {   // Self-Cover
        total = 0;
        for (let i = 0; result.impositions_information && i < result.impositions_information.length; i++) {
            const imposition = result.impositions_information[i];
            if (imposition.paper_information) {
                quantity += imposition.paper_information.total_pars;
                total += imposition.paper_information.total_cost;
            }
        }
    } else {
        if (result.paper_information) {
            quantity = result.paper_information.total_pars;
            total = result.paper_information.total_cost;
        }
    }

    return { quantity, total };
}

export const getCuttingAmount = (estimateType, result, coverResult) => {
    let quantity = 0;
    let total = 0;

    if (parseInt(estimateType) === 3) {   // Plus-Cover
        if (coverResult.cutting_information) {
            quantity = coverResult.cutting_information.quantity * coverResult.paper_information.pss.up;
            total = coverResult.cutting_information.total_cost;
        }

        for (let i = 0; result.impositions_information && i < result.impositions_information.length; i++) {
            const imposition = result.impositions_information[i];
            if (imposition.cutting_information) {
                quantity += imposition.cutting_information.quantity * imposition.paper_information.pss.up;
                total += imposition.cutting_information.total_cost;
            }
        }
    } else if (parseInt(estimateType) === 4) {   // Self-Cover
        total = 0;
        for (let i = 0; result.impositions_information && i < result.impositions_information.length; i++) {
            const imposition = result.impositions_information[i];
            if (imposition.cutting_information) {
                quantity += imposition.cutting_information.quantity * imposition.paper_information.pss.up;
                total += imposition.cutting_information.total_cost;
            }
        }
    } else {
        if (result.cutting_information) {
            quantity = result.cutting_information.quantity * result.paper_information.pss.up;
            total = result.cutting_information.total_cost;
        }
    }

    return { quantity, total };
}

export const getInksAmount = (estimateType, result, coverResult) => {
    let quantity = 0;
    let total = 0;

    if (parseInt(estimateType) === 3) {   // Plus-Cover
        if (coverResult.ink_information) {
            quantity = coverResult.ink_information.total_front_colors + coverResult.ink_information.total_back_colors;
            total = coverResult.ink_information.total_cost;
        }

        for (let i = 0; result.impositions_information && i < result.impositions_information.length; i++) {
            const imposition = result.impositions_information[i];
            if (imposition.ink_information) {
                quantity += imposition.ink_information.total_front_colors + imposition.ink_information.total_back_colors;
                total += imposition.ink_information.total_cost;
            }
        }
    } else if (parseInt(estimateType) === 4) {   // Self-Cover
        total = 0;
        for (let i = 0; result.impositions_information && i < result.impositions_information.length; i++) {
            const imposition = result.impositions_information[i];
            if (imposition.ink_information) {
                quantity += imposition.ink_information.total_front_colors + imposition.ink_information.total_back_colors;
                total += imposition.ink_information.total_cost;
            }
        }
    } else {
        if (result.ink_information) {
            quantity = result.ink_information.total_front_colors + result.ink_information.total_back_colors;
            total = result.ink_information.total_cost;
        }
    }

    return { quantity, total };
}

export const getFoldingAmount = (estimateType, result, coverResult) => {
    let quantity = 0;
    let total = 0;

    if (parseInt(estimateType) === 3) {   // Plus-Cover
        if (coverResult.folding_information) {
            quantity = coverResult.folding_information.quantity;
            total = coverResult.folding_information.total_cost;
        }

        for (let i = 0; result.impositions_information && i < result.impositions_information.length; i++) {
            const imposition = result.impositions_information[i];
            if (imposition.folding_information) {
                quantity += imposition.folding_information.quantity;
                total += imposition.folding_information.total_cost;
            }
        }
    } else if (parseInt(estimateType) === 4) {   // Self-Cover
        total = 0;
        for (let i = 0; result.impositions_information && i < result.impositions_information.length; i++) {
            const imposition = result.impositions_information[i];
            if (imposition.folding_information) {
                quantity += imposition.folding_information.quantity;
                total += imposition.folding_information.total_cost;
            }
        }
    } else {
        if (result.folding_information) {
            quantity = result.folding_information.quantity;
            total = result.folding_information.total_cost;
        }
    }

    return { quantity, total };
}

export const calculateQuantity = (item, estimateQuantity) => {
    let quantity = '';
    if (parseInt(item.unit) === 1) {    // Thousand
        quantity = estimateQuantity;
    } else if (parseInt(item.unit) === 2) {    // Each
        quantity = estimateQuantity;
    } else if (parseInt(item.unit) === 3) {    // Hour
        if (empty(item.quantity)) {
            quantity = 1;
        } else {
            quantity = item.quantity;
        }
    } else if (parseInt(item.unit) === 4) {    // File
        if (empty(item.quantity)) {
            quantity = 1;
        } else {
            quantity = item.quantity;
        }
    } else if (parseInt(item.unit) === 5) {    // Drop
        if (empty(item.quantity)) {
            quantity = 1;
        } else {
            quantity = item.quantity;
        }
    } else if (parseInt(item.unit) === 6) {    // Field
        if (empty(item.quantity)) {
            quantity = 1;
        } else {
            quantity = item.quantity;
        }
    } else if (parseInt(item.unit) === 7) {    // Job
        if (empty(item.quantity)) {
            quantity = 1;
        } else {
            quantity = item.quantity;
        }
    }

    return quantity;
}
export const calculateUnitName = (item) => {
    let unit = 'N/A';
    if (parseInt(item.unit) === 1) {    // Thousand
        unit = 'Per 1000';
    } else if (parseInt(item.unit) === 2) {    // Each
        unit = 'Per each';
    } else if (parseInt(item.unit) === 3) {    // Hour
        unit = 'Per hour';
    } else if (parseInt(item.unit) === 4) {    // File
        unit = 'Per file';
    } else if (parseInt(item.unit) === 5) {    // Drop
        unit = 'Per drop';
    } else if (parseInt(item.unit) === 6) {    // Field
        unit = 'Per field';
    } else if (parseInt(item.unit) === 7) {    // Job
        unit = 'Per job';
    }

    return unit;
}
export const calculateTotalPrice = (item, quantity) => {
    let total = 0;
    if (parseInt(item.unit) === 1) {    // Thousand
        total = quantity / 1000 * item.unit_price;
    } else if (parseInt(item.unit) === 2) {    // Each
        total = quantity * item.unit_price;
    } else if (parseInt(item.unit) === 3) {    // Hour
        total = quantity * item.unit_price;
    } else if (parseInt(item.unit) === 4) {    // File
        total = quantity * item.unit_price;
    } else if (parseInt(item.unit) === 5) {    // Drop
        total = quantity * item.unit_price;
    } else if (parseInt(item.unit) === 6) {    // Field
        total = quantity * item.unit_price;
    } else if (parseInt(item.unit) === 7) {    // Job

    }

    let isMinimum = 0;
    if (total < item.minimum_charge) {
        total = item.minimum_charge;
        isMinimum = 1;
    }

    return { total, isMinimum };
}
export const createNewMailItem = (estimate, item, type) => {
    let quantity = item.quantity;
    if (empty(item.quantity)) {
        quantity = calculateQuantity(item, estimate.quantity);
    }

    const price = calculateTotalPrice(item, quantity);
    return {
        id: item.id,
        name: item.name,
        minimum_charge: item.minimum_charge,
        unit: item.unit,
        unit_name: calculateUnitName(item),
        unit_price: item.unit_price,
        quantity: quantity,
        total_price: price.total,
        is_minimum: price.isMinimum,
        type: type,
    };
}
export const addMailServicesItems = () => (dispatch, getState) => {
    const dataProcessingItems = selectors.getDataProcessingItems(getState());
    const variablePrintItems = selectors.getVariablePrintItems(getState());
    const inkjetItems = selectors.getInkjetItems(getState());
    const insertingItems = selectors.getInsertingItems(getState());
    const meteringItems = selectors.getMeteringItems(getState());
    const handworkItems = selectors.getHandworkItems(getState());
    const estimate = selectors.getEstimate(getState());

    const items = [];
    for (let i = 0; i < dataProcessingItems.length; i++) {
        if (parseInt(dataProcessingItems[i].checked) === 1) {
            if (empty(dataProcessingItems[i].quantity)) {
                return showNotification('Complete Information', 'Enter the quantity for the selected Data Processing elements', 'info');
            }

            items.push(createNewMailItem(estimate, dataProcessingItems[i], 1));
        }
    }
    for (let i = 0; i < variablePrintItems.length; i++) {
        if (parseInt(variablePrintItems[i].checked) === 1) {
            if (empty(variablePrintItems[i].quantity)) {
                return showNotification('Complete Information', 'Enter the quantity for the selected Variable Print elements', 'info');
            }

            items.push(createNewMailItem(estimate, variablePrintItems[i], 2));
        }
    }
    for (let i = 0; i < inkjetItems.length; i++) {
        if (parseInt(inkjetItems[i].checked) === 1) {
            if (empty(inkjetItems[i].quantity)) {
                return showNotification('Complete Information', 'Enter the quantity for the selected Inkjet elements', 'info');
            }

            items.push(createNewMailItem(estimate, inkjetItems[i], 3));
        }
    }
    for (let i = 0; i < insertingItems.length; i++) {
        if (parseInt(insertingItems[i].checked) === 1) {
            if (empty(insertingItems[i].quantity)) {
                return showNotification('Complete Information', 'Enter the quantity for the selected Inserting elements', 'info');
            }

            items.push(createNewMailItem(estimate, insertingItems[i], 4));
        }
    }
    for (let i = 0; i < meteringItems.length; i++) {
        if (parseInt(meteringItems[i].checked) === 1) {
            if (empty(meteringItems[i].quantity)) {
                return showNotification('Complete Information', 'Enter the quantity for the selected Metering elements', 'info');
            }

            items.push(createNewMailItem(estimate, meteringItems[i], 5));
        }
    }
    for (let i = 0; i < handworkItems.length; i++) {
        if (parseInt(handworkItems[i].checked) === 1) {
            if (empty(handworkItems[i].quantity)) {
                return showNotification('Complete Information', 'Enter the quantity for the selected Handwork elements', 'info');
            }

            items.push(createNewMailItem(estimate, handworkItems[i], 6));
        }
    }

    dispatch(dialog.actions.hideDialog('mail-services-modal'));

    if (items.length > 0 && (empty(estimate.id) || parseInt(estimate.id) > 218185)) {
        dispatch(setField('markup', 31));
    }

    dispatch({
        type: types.ADD_MAIL_SERVICES_ITEMS,
        payload: items,
    });
}
export const deleteMailServicesItem = (id, type) => (dispatch) => {
    dispatch({
        type: types.DELETE_MAIL_SERVICES_ITEM,
        payload: { id, type }
    });
}
export const setDataProcessingItem = (id, field, value) => (dispatch) => {
    dispatch({
        type: types.SET_DATA_PROCESSING_ITEM,
        payload: { id, field, value }
    });
}
export const setVariablePrintItem = (id, field, value) => (dispatch) => {
    dispatch({
        type: types.SET_VARIABLE_PRINT_ITEM,
        payload: { id, field, value }
    });
}
export const setInkjetItem = (id, field, value) => (dispatch) => {
    dispatch({
        type: types.SET_INKJET_ITEM,
        payload: { id, field, value }
    });
}
export const setInsertingItem = (id, field, value) => (dispatch) => {
    dispatch({
        type: types.SET_INSERTING_ITEM,
        payload: { id, field, value }
    });
}
export const setMeteringItem = (id, field, value) => (dispatch) => {
    dispatch({
        type: types.SET_METERING_ITEM,
        payload: { id, field, value }
    });
}
export const setHandworkItem = (id, field, value) => (dispatch) => {
    dispatch({
        type: types.SET_HANDWORK_ITEM,
        payload: { id, field, value }
    });
}
export const setMailServicesQuantities = () => (dispatch, getState) => {
    const estimate = selectors.getEstimate(getState());
    dispatch({
        type: types.SET_MAIL_SERVICES_QUANTITIES,
        payload: estimate.quantity,
    });
}
export const setEstimateType = (value) => (dispatch, getState) => {
    dispatch({
        type: types.SET_ESTIMATE_TYPE,
        payload: value,
    });
}
export const setShowBreakdown = (name, value) => (dispatch, getState) => {
    dispatch({
        type: types.SET_SHOW_BREAKDOWN,
        payload: { name, value },
    });
}
export const setAdditionalService = (id, field, value) => (dispatch) => {
    dispatch({
        type: types.SET_ADDITIONAL_SERVICE,
        payload: { id, field, value }
    });
}
export const updateAdditionalService = (id, field, value) => (dispatch) => {
    dispatch({
        type: types.UPDATE_ADDITIONAL_SERVICE,
        payload: { id, field, value }
    });
}
export const addAdditionalServicesItems = () => (dispatch, getState) => {
    const additionalServices = selectors.getAdditionalServices(getState());
    const selectedAdditionalServices = selectors.getSelectedAdditionalServices(getState());
    const result1Obj = selectors.getResult1(getState());
    const result2Obj = selectors.getResult2(getState());
    const result3Obj = selectors.getResult3(getState());
    const estimate = selectors.getEstimate(getState());
    const estimateType = selectors.getEstimateType(getState());

    let resultObj = null;
    if (parseInt(estimate.selected_quantity) === 1) {
        resultObj = result1Obj;
    } else if (parseInt(estimate.selected_quantity) === 2) {
        resultObj = result2Obj;
    } else if (parseInt(estimate.selected_quantity) === 3) {
        resultObj = result3Obj;
    }

    for (let i = 0; i < additionalServices.length; i++) {
        if (parseInt(additionalServices[i].checked) === 1) {
            if (parseInt(estimateType) === 1 && additionalServices[i].id === 1 && additionalServices[i].folding_type === '') {
                return showNotification('Complete Information', 'Select Folding Type', 'info');
            } else if (additionalServices[i].id === 3 && additionalServices[i].drilling_holes === '') {
                return showNotification('Complete Information', 'Select the Drilling Holes', 'info');
            } else if (additionalServices[i].id === 4 && additionalServices[i].per_package === '') {     // Shrink-Wrapping
                return showNotification('Complete Information', 'Enter the Qty per Package', 'info');
            } else if (additionalServices[i].id === 5 && additionalServices[i].sheets_per_pad === '') {
                return showNotification('Complete Information', 'Enter the Sheets per Pad', 'info');
            } else if (additionalServices[i].id === 21 && additionalServices[i].per_package === '') {     // Shrink-Wrapping
                return showNotification('Complete Information', 'Enter the Qty per Package', 'info');
            } else if (additionalServices[i].id === 27 && additionalServices[i].box === '') {     // Business Cards Box
                return showNotification('Complete Information', 'Select the Box', 'info');
            } else if (additionalServices[i].id === 28 && additionalServices[i].box === '') {     // Letterheads Box
                return showNotification('Complete Information', 'Select the Box', 'info');
            }
        }
    }

    for (let i = 0; i < selectedAdditionalServices.length; i++) {
        dispatch(deleteAdditionalServicesItem(selectedAdditionalServices[i].id));
    }

    for (let i = 0; i < additionalServices.length; i++) {
        if (parseInt(additionalServices[i].checked) === 1) {
            let result = null;
            if (resultObj && resultObj != null && resultObj.results.length > 0) {
                result = resultObj.results[0];
            }

            let quantity = null;
            let total = null;
            if (result != null) {
                if ([1, 7, 8, 9].includes(parseInt(estimateType))) {     // Sheetfed, Pocket Folders, NCR, Notepads
                    if (additionalServices[i].id === 13) {       // Press
                        quantity = numeral(result.press_information.quantity).format('0,0') + ' pss';
                        total = result.press_information.total_running_cost + result.press_information.total_setup_cost;
                    } else if (additionalServices[i].id === 15) {       // Paper
                        quantity = numeral(result.paper_information.total_pars).format('0,0') + ' pars';
                        total = result.paper_information.total_cost;
                    } else if (additionalServices[i].id === 1) {       // Folding
                        quantity = numeral(result.folding_information.quantity).format('0,0');
                        total = result.folding_information.total_cost;
                    } else if (additionalServices[i].id === 2) {    // Scoring
                        quantity = numeral(result.scoring_information.total_pieces).format('0,0');
                        total = result.scoring_information.total_cost;
                    } else if (additionalServices[i].id === 3) {    // Drilling
                        quantity = numeral(result.drilling_information.quantity).format('0,0');
                        total = result.drilling_information.total_cost;
                    } else if (additionalServices[i].id === 4 && result.shrink_wrapping_information) {    // Shrink-Wrapping
                        quantity = numeral(result.shrink_wrapping_information.total_packages).format('0,0');
                        total = result.shrink_wrapping_information.total_cost;
                    } else if (additionalServices[i].id === 5) {    // Padding
                        quantity = numeral(result.padding_information.quantity).format('0,0');
                        total = result.padding_information.total_cost;
                    } else if (additionalServices[i].id === 6) {    // Die-Cutting
                        quantity = numeral(result.die_cutter_information.total_pieces).format('0,0');
                        total = result.die_cutter_information.total_cost;
                    } else if (additionalServices[i].id === 7) {    // Plates
                        quantity = numeral(result.press_information.total_plates).format('0,0');
                        total = result.press_information.total_plates_cost;
                    } else if (additionalServices[i].id === 8) {    // Inks
                        quantity = numeral(result.ink_information.total_front_colors + result.ink_information.total_back_colors).format('0,0');
                        total = result.ink_information.total_cost;
                    } else if (additionalServices[i].id === 9) {    // Cutting
                        quantity = numeral(result.cutting_information.quantity * result.paper_information.pss.up).format('0,0');
                        total = result.cutting_information.total_cost;
                    } else if (additionalServices[i].id === 21 && result.boxing_information) {    // Boxing
                        quantity = numeral(result.boxing_information.total_packages).format('0,0');
                        total = result.boxing_information.total_cost;
                    } else if (additionalServices[i].id === 26) {    // perforating
                        quantity = numeral(result.scoring_information.total_pieces).format('0,0');
                        total = result.scoring_information.perforating_cost;
                    } else if (additionalServices[i].id === 27 && result.bc_box_information) {       // Business Cards Box
                        quantity = numeral(result.bc_box_information.quantity).format('0,0');
                        total = result.bc_box_information.total_cost;
                    } else if (additionalServices[i].id === 28 && result.letterhead_box_information) {       // Letterheads Box
                        quantity = numeral(result.letterhead_box_information.quantity).format('0,0');
                        total = result.letterhead_box_information.total_cost;
                    }
                } else if (parseInt(estimateType) === 2) {     // Envelopes
                    if (additionalServices[i].id === 18) {       // Envelope Press
                        quantity = numeral(result.envelope_press.total_quantity).format('0,0');
                        total = result.envelope_press.press_cost + result.envelope_press.pre_press_cost;
                    } else if (additionalServices[i].id === 19) {       // Envelope Paper
                        quantity = numeral(result.envelope_paper.total_quantity).format('0,0');
                        total = result.envelope_paper.cost;
                    } else if (additionalServices[i].id === 4 && result.shrink_wrapping_information) {    // Shrink-Wrapping
                        quantity = numeral(result.shrink_wrapping_information.total_packages).format('0,0');
                        total = result.shrink_wrapping_information.total_cost;
                    } else if (additionalServices[i].id === 21 && result.boxing_information) {    // Boxing
                        quantity = numeral(result.boxing_information.total_packages).format('0,0');
                        total = result.boxing_information.total_cost;
                    } else if (additionalServices[i].id === 27 && result.bc_box_information) {       // Business Cards Box
                        quantity = numeral(result.bc_box_information.quantity).format('0,0');
                        total = result.bc_box_information.total_cost;
                    } else if (additionalServices[i].id === 28 && result.letterhead_box_information) {       // Letterheads Box
                        quantity = numeral(result.letterhead_box_information.quantity).format('0,0');
                        total = result.letterhead_box_information.total_cost;
                    }
                } else if (parseInt(estimateType) === 3) {      // Plus-Cover
                    let coverResult = null;
                    if (resultObj != null && resultObj.cover_results.length > 0) {
                        coverResult = resultObj.cover_results[0];
                    }

                    let pressQuantity = 0;
                    let pressTotal = 0;
                    let digitalMachineQuantity = 0;
                    let digitalMachineTotal = 0;
                    let paperQuantity = 0;
                    let paperTotal = 0;
                    let platesQuantity = 0;
                    let platesTotal = 0;
                    let inksQuantity = 0;
                    let inksTotal = 0;
                    let cuttingQuantity = 0;
                    let cuttingTotal = 0;
                    let foldingQuantity = 0;
                    let foldingTotal = 0;

                    /*********************** Cover **********************/
                    if (isDigital(estimate.cover_output_device)) {
                        digitalMachineQuantity += coverResult.digital_machine_information.quantity;
                        digitalMachineTotal += coverResult.digital_machine_information.total_cost;
                    } else {
                        pressQuantity += coverResult.press_information.quantity;
                        pressTotal += coverResult.press_information.total_running_cost + coverResult.press_information.total_setup_cost;

                        platesQuantity += coverResult.press_information.total_plates;
                        platesTotal += coverResult.press_information.total_plates_cost;

                        inksQuantity += coverResult.ink_information.total_front_colors + coverResult.ink_information.total_back_colors;
                        inksTotal += coverResult.ink_information.total_cost;
                    }

                    paperQuantity += coverResult.paper_information.total_pars;
                    paperTotal += coverResult.paper_information.total_cost;

                    cuttingQuantity += coverResult.cutting_information.quantity * coverResult.paper_information.pss.up;
                    cuttingTotal += coverResult.cutting_information.total_cost;

                    foldingQuantity += coverResult.folding_information.quantity;
                    foldingTotal += coverResult.folding_information.total_cost;

                    /********************** Interior **********************/
                    for (let i = 0; i < result.impositions_information.length; i++) {
                        const imposition = result.impositions_information[i];

                        if (isDigital(estimate.output_device)) {
                            digitalMachineQuantity += imposition.digital_machine_information.quantity;
                            digitalMachineTotal += imposition.digital_machine_information.total_cost;
                        } else {
                            pressQuantity += imposition.press_information.quantity;
                            pressTotal += imposition.press_information.total_running_cost + imposition.press_information.total_setup_cost;

                            platesQuantity += imposition.press_information.total_plates;
                            platesTotal += imposition.press_information.total_plates_cost;

                            inksQuantity += imposition.ink_information.total_front_colors + imposition.ink_information.total_back_colors;
                            inksTotal += imposition.ink_information.total_cost;
                        }

                        paperQuantity += imposition.paper_information.total_pars;
                        paperTotal += imposition.paper_information.total_cost;

                        cuttingQuantity = imposition.cutting_information.quantity * imposition.paper_information.imposition_out;
                        cuttingTotal = imposition.cutting_information.total_cost;

                        foldingQuantity = imposition.folding_information.quantity;
                        foldingTotal = imposition.folding_information.total_cost;
                    }

                    if (additionalServices[i].id === 13) {       // Press
                        quantity = numeral(pressQuantity).format('0,0') + ' pss';
                        total = pressTotal;
                    } else if (additionalServices[i].id === 14) {       // Digital
                        quantity = numeral(digitalMachineQuantity).format('0,0') + ' pss';
                        total = digitalMachineTotal;
                    } else if (additionalServices[i].id === 15) {       // Paper
                        quantity = numeral(paperQuantity).format('0,0') + ' pars';
                        total = paperTotal;
                    } else if (additionalServices[i].id === 1) {       // Folding
                        quantity = numeral(foldingQuantity).format('0,0');
                        total = foldingTotal;
                    } else if (additionalServices[i].id === 7) {    // Plates
                        quantity = numeral(platesQuantity).format('0,0');
                        total = platesTotal;
                    } else if (additionalServices[i].id === 8) {    // Inks
                        quantity = numeral(inksQuantity).format('0,0');
                        total = inksTotal;
                    } else if (additionalServices[i].id === 9) {    // Cutting
                        quantity = numeral(cuttingQuantity).format('0,0');
                        total = cuttingTotal;
                    } else if (additionalServices[i].id === 20) {    // Saddle Stitching
                        quantity = numeral(result.binding_information.books).format('0,0');
                        total = result.binding_information.total_cost;
                    } else if (additionalServices[i].id === 22) {    // Perfect Binding
                        quantity = numeral(result.binding_information.books).format('0,0');
                        total = result.binding_information.total_cost;
                    } else if (additionalServices[i].id === 23) {    // Spiral Binding
                        quantity = numeral(result.binding_information.books).format('0,0');
                        total = result.binding_information.total_cost;
                    } else if (additionalServices[i].id === 24) {    // Wire Binding
                        quantity = numeral(result.binding_information.books).format('0,0');
                        total = result.binding_information.total_cost;
                    } else if (additionalServices[i].id === 25) {    // Staple Binding
                        quantity = numeral(result.binding_information.books).format('0,0');
                        total = result.binding_information.total_cost;
                    } else if (additionalServices[i].id === 4 && result.shrink_wrapping_information) {    // Shrink-Wrapping
                        quantity = numeral(result.shrink_wrapping_information.total_packages).format('0,0');
                        total = result.shrink_wrapping_information.total_cost;
                    } else if (additionalServices[i].id === 12 && result.boxing_information) {    // Boxing
                        quantity = numeral(result.boxing_information.total_packages).format('0,0');
                        total = result.boxing_information.total_cost;
                    } else if (additionalServices[i].id === 27 && result.bc_box_information) {       // Business Cards Box
                        quantity = numeral(result.bc_box_information.quantity).format('0,0');
                        total = result.bc_box_information.total_cost;
                    } else if (additionalServices[i].id === 28 && result.letterhead_box_information) {       // Letterheads Box
                        quantity = numeral(result.letterhead_box_information.quantity).format('0,0');
                        total = result.letterhead_box_information.total_cost;
                    }
                } else if (parseInt(estimateType) === 4) {      // Self-Cover
                    let pressQuantity = 0;
                    let pressTotal = 0;
                    let digitalMachineQuantity = 0;
                    let digitalMachineTotal = 0;
                    let paperQuantity = 0;
                    let paperTotal = 0;
                    let platesQuantity = 0;
                    let platesTotal = 0;
                    let inksQuantity = 0;
                    let inksTotal = 0;
                    let cuttingQuantity = 0;
                    let cuttingTotal = 0;
                    let foldingQuantity = 0;
                    let foldingTotal = 0;
                    for (let i = 0; i < result.impositions_information.length; i++) {
                        const imposition = result.impositions_information[i];

                        if (isDigital(estimate.output_device)) {
                            digitalMachineQuantity += imposition.digital_machine_information.quantity;
                            digitalMachineTotal += imposition.digital_machine_information.total_cost;
                        } else {
                            pressQuantity += imposition.press_information.quantity;
                            pressTotal += imposition.press_information.total_running_cost + imposition.press_information.total_setup_cost;
                        }

                        paperQuantity += imposition.paper_information.total_pars;
                        paperTotal += imposition.paper_information.total_cost;

                        platesQuantity += imposition.press_information.total_plates;
                        platesTotal += imposition.press_information.total_plates_cost;

                        inksQuantity += imposition.ink_information.total_front_colors + imposition.ink_information.total_back_colors;
                        inksTotal += imposition.ink_information.total_cost;

                        cuttingQuantity += imposition.cutting_information.quantity * imposition.paper_information.imposition_out;
                        cuttingTotal = imposition.cutting_information.total_cost;

                        foldingQuantity += imposition.folding_information.quantity;
                        foldingTotal += imposition.folding_information.total_cost;
                    }

                    if (additionalServices[i].id === 13) {       // Press
                        quantity = numeral(pressQuantity).format('0,0') + ' pss';
                        total = pressTotal;
                    } else if (additionalServices[i].id === 14) {       // Digital
                        quantity = numeral(digitalMachineQuantity).format('0,0') + ' pss';
                        total = digitalMachineTotal;
                    } else if (additionalServices[i].id === 15) {       // Paper
                        quantity = numeral(paperQuantity).format('0,0') + ' pars';
                        total = paperTotal;
                    } else if (additionalServices[i].id === 1) {       // Folding
                        quantity = numeral(foldingQuantity).format('0,0');
                        total = foldingTotal;
                    } else if (additionalServices[i].id === 7) {    // Plates
                        quantity = numeral(platesQuantity).format('0,0');
                        total = platesTotal;
                    } else if (additionalServices[i].id === 8) {    // Inks
                        quantity = numeral(inksQuantity).format('0,0');
                        total = inksTotal;
                    } else if (additionalServices[i].id === 9) {    // Cutting
                        quantity = numeral(cuttingQuantity).format('0,0');
                        total = cuttingTotal;
                    } else if (additionalServices[i].id === 20) {    // Saddle Stitching
                        quantity = numeral(result.binding_information.books).format('0,0');
                        total = result.binding_information.total_cost;
                    } else if (additionalServices[i].id === 22) {    // Perfect Binding
                        quantity = numeral(result.binding_information.books).format('0,0');
                        total = result.binding_information.total_cost;
                    } else if (additionalServices[i].id === 23) {    // Spiral Binding
                        quantity = numeral(result.binding_information.books).format('0,0');
                        total = result.binding_information.total_cost;
                    } else if (additionalServices[i].id === 24) {    // Wire Binding
                        quantity = numeral(result.binding_information.books).format('0,0');
                        total = result.binding_information.total_cost;
                    } else if (additionalServices[i].id === 25) {    // Staple Binding
                        quantity = numeral(result.binding_information.books).format('0,0');
                        total = result.binding_information.total_cost;
                    } else if (additionalServices[i].id === 4 && result.shrink_wrapping_information) {    // Shrink-Wrapping
                        quantity = numeral(result.shrink_wrapping_information.total_packages).format('0,0');
                        total = result.shrink_wrapping_information.total_cost;
                    } else if (additionalServices[i].id === 12 && result.boxing_information) {    // Boxing
                        quantity = numeral(result.boxing_information.total_packages).format('0,0');
                        total = result.boxing_information.total_cost;
                    } else if (additionalServices[i].id === 27 && result.bc_box_information) {       // Business Cards Box
                        quantity = numeral(result.bc_box_information.quantity).format('0,0');
                        total = result.bc_box_information.total_cost;
                    } else if (additionalServices[i].id === 28 && result.letterhead_box_information) {       // Letterheads Box
                        quantity = numeral(result.letterhead_box_information.quantity).format('0,0');
                        total = result.letterhead_box_information.total_cost;
                    }
                } else if (parseInt(estimateType) === 5) {     // Digital
                    if (additionalServices[i].id === 14) {       // Digital
                        quantity = numeral(result.digital_machine_information.quantity).format('0,0') + ' pss';
                        total = result.digital_machine_information.total_cost;
                    } else if (additionalServices[i].id === 15) {       // Paper
                        quantity = numeral(result.paper_information.total_pars).format('0,0') + ' pars';
                        total = result.paper_information.total_cost;
                    } else if (additionalServices[i].id === 1) {       // Folding
                        quantity = numeral(result.folding_information.quantity).format('0,0');
                        total = result.folding_information.total_cost;
                    } else if (additionalServices[i].id === 2) {    // Scoring
                        quantity = numeral(result.scoring_information.total_pieces).format('0,0');
                        total = result.scoring_information.total_cost;
                    } else if (additionalServices[i].id === 3) {    // Drilling
                        quantity = numeral(result.drilling_information.quantity).format('0,0');
                        total = result.drilling_information.total_cost;
                    } else if (additionalServices[i].id === 4 && result.shrink_wrapping_information) {    // Shrink-Wrapping
                        quantity = numeral(result.shrink_wrapping_information.total_packages).format('0,0');
                        total = result.shrink_wrapping_information.total_cost;
                    } else if (additionalServices[i].id === 5) {    // Padding
                        quantity = numeral(result.padding_information.quantity).format('0,0');
                        total = result.padding_information.total_cost;
                    } else if (additionalServices[i].id === 6) {    // Die-Cutting
                        quantity = numeral(result.die_cutter_information.total_pieces).format('0,0');
                        total = result.die_cutter_information.total_cost;
                    } else if (additionalServices[i].id === 7) {    // Plates
                        quantity = numeral(result.press_information.total_plates).format('0,0');
                        total = result.press_information.total_plates_cost;
                    } else if (additionalServices[i].id === 8) {    // Inks
                        quantity = numeral(result.ink_information.total_front_colors + result.ink_information.total_back_colors).format('0,0');
                        total = result.ink_information.total_cost;
                    } else if (additionalServices[i].id === 9) {    // Cutting
                        quantity = numeral(result.cutting_information.quantity * result.paper_information.pss.up).format('0,0');
                        total = result.cutting_information.total_cost;
                    } else if (additionalServices[i].id === 21 && result.boxing_information) {    // Boxing
                        quantity = numeral(result.boxing_information.total_packages).format('0,0');
                        total = result.boxing_information.total_cost;
                    } else if (additionalServices[i].id === 26 && result.perforating_information) {    // Perforating
                        quantity = numeral(result.perforating_information.quantity).format('0,0');
                        total = result.perforating_information.total_cost;
                    } else if (additionalServices[i].id === 27 && result.bc_box_information) {       // Business Cards Box
                        quantity = numeral(result.bc_box_information.quantity).format('0,0');
                        total = result.bc_box_information.total_cost;
                    } else if (additionalServices[i].id === 28 && result.letterhead_box_information) {       // Letterheads Box
                        quantity = numeral(result.letterhead_box_information.quantity).format('0,0');
                        total = result.letterhead_box_information.total_cost;
                    }
                } else if (parseInt(estimateType) === 5) {     // Large Format
                    if (additionalServices[i].id === 16) {       // Large Format
                        quantity = numeral(estimate.quantity).format('0,0');
                        total = result.large_format_machine_information.total_cost;
                    } else if (additionalServices[i].id === 17) {       // Substrate
                        quantity = numeral(estimate.quantity).format('0,0');
                        total = result.substrate_information.total_cost;
                    } else if (additionalServices[i].id === 10 && result.laminate_information !== null) {       // Laminate
                        quantity = numeral(estimate.quantity).format('0,0');
                        total = result.laminate_information.total_cost;
                    }
                }
            }

            dispatch(addAdditionalService({
                id: additionalServices[i].id,
                name: additionalServices[i].name,
                folding_type: additionalServices[i].folding_type,
                drilling_holes: additionalServices[i].drilling_holes,
                sheets_per_pad: additionalServices[i].sheets_per_pad,
                per_package: additionalServices[i].per_package,
                box: additionalServices[i].box,
                quantity: quantity,
                total: total,
                position: additionalServices[i].position,
            }));
        }
    }

    dispatch(dialog.actions.hideDialog('additional-services-modal'));

    if (resultObj != null && validateFields(estimate, estimateType, false)) {
        dispatch(calculateEstimate(true));
    }
}
export const addAdditionalService = (service) => (dispatch, getState) => {
    const selectedAdditionalServices = selectors.getSelectedAdditionalServices(getState());

    const index = selectedAdditionalServices.findIndex((selectedService) => parseInt(selectedService.id) === parseInt(service.id));
    if (index !== -1) {
        return;
    }

    dispatch({
        type: types.ADD_ADDITIONAL_SERVICE,
        payload: service,
    });
}
export const deleteAdditionalServicesItem = (id) => (dispatch) => {
    dispatch({
        type: types.DELETE_ADDITIONAL_SERVICES_ITEM,
        payload: id,
    });
}
export const resetResult = () => (dispatch) => {
    dispatch({
        type: types.RESET_RESULT,
        payload: null,
    });
}
export const setImpositionType = (value) => (dispatch) => {
    dispatch({
        type: types.SET_IMPOSITION_TYPE,
        payload: value,
    });
}
export const setMultiResults = (value) => (dispatch) => {
    dispatch({
        type: types.SET_MULTI_RESULTS,
        payload: value,
    });
}
export const setDataChanged = (value) => (dispatch) => {
    dispatch({
        type: types.SET_DATA_CHANGED,
        payload: value,
    });
}
export const setEstimateChanged = (value) => (dispatch) => {
    dispatch({
        type: types.SET_ESTIMATE_CHANGED,
        payload: value,
    });
}
export const checkAccessory = (id) => (dispatch) => {
    dispatch({
        type: types.CHECK_ACCESSORY,
        payload: id,
    });
}
export const resetAccessories = () => (dispatch) => {
    dispatch({
        type: types.RESET_ACCESSORIES,
        payload: null,
    });
}

export const addFile = (file) => (dispatch) => {
    dispatch({
        type: types.ADD_FILE,
        payload: file,
    });
}

export const removeFile = (index) => (dispatch) => {
    dispatch({
        type: types.REMOVE_FILE,
        payload: index,
    });
}

export const clearFiles = (index) => (dispatch) => {
    dispatch({
        type: types.CLEAR_FILES,
        payload: index,
    });
}

export const setComments = (value) => (dispatch) => {
    dispatch({
        type: types.SET_COMMENTS,
        payload: value,
    });
}

export const setNewEstimateType = (value) => (dispatch) => {
    dispatch({
        type: types.SET_NEW_ESTIMATE_TYPE,
        payload: value,
    });
}

export const setNewStatus = (value) => (dispatch) => {
    dispatch({
        type: types.SET_NEW_STATUS,
        payload: value,
    });
}

export const setDeliveryChargeField = (field, value) => (dispatch) => {
    dispatch({
        type: types.SET_DELIVERY_CHARGE_FIELD,
        payload: { field, value },
    });
}

export const setShowDeliveryCharge = (value) => (dispatch) => {
    dispatch({
        type: types.SET_SHOW_DELIVERY_CHARGE,
        payload: value,
    });
}

export const setPartEstimateType = (value) => (dispatch) => {
    dispatch({
        type: types.SET_PART_ESTIMATE_TYPE,
        payload: value,
    });
}

export const setMainPartId = (value) => (dispatch) => {
    dispatch({
        type: types.SET_MAIN_PART_ID,
        payload: value,
    });
}

export const setMainPartType = (value) => (dispatch) => {
    dispatch({
        type: types.SET_MAIN_PART_TYPE,
        payload: value,
    });
}

export const setIdForDelete = (id) => (dispatch) => {
    dispatch({
        type: types.SET_ID_FOR_DELETE,
        payload: id
    });
}

export const setPaperField = (field, value) => (dispatch) => {
    dispatch({
        type: types.SET_PAPER_FIELD,
        payload: { field, value }
    });
}

export const addOutsideService = () => (dispatch) => {
    dispatch({
        type: types.ADD_OUTSIDE_SERVICE,
        payload: null,
    });
}

export const setOutsideServiceField = (index, field, value) => (dispatch) => {
    dispatch({
        type: types.SET_OUTSIDE_SERVICE_FIELD,
        payload: { index, field, value },
    });
}

export const deleteOutsideService = (index) => (dispatch) => {
    dispatch({
        type: types.DELETE_OUTSIDE_SERVICE,
        payload: index,
    });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null
    });
}

export const changeEstimateType = () => (dispatch, getState) => {
    const newEstimateType = selectors.getNewEstimateType(getState());
    const estimate = selectors.getEstimate(getState());
    const history = selectors.getHistory(getState());

    dispatch({ type: types.CHANGE_ESTIMATE_TYPE, payload: null });
    axios.post(apiUrl + '/sc-estimate/change-type', { id: estimate.id, newEstimateType },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.CHANGE_ESTIMATE_TYPE_SUCCESS, payload: response.data });
            dispatch(dialog.actions.hideDialog('change-estimate-modal'));
            history.push('/estimate/' + newEstimateType + '/' + estimate.id);
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.CHANGE_ESTIMATE_TYPE_ERROR));
        });
}

export const changeStatus = () => (dispatch, getState) => {
    const newStatus = selectors.getNewStatus(getState());
    const estimate = selectors.getEstimate(getState());

    dispatch({ type: types.CHANGE_STATUS, payload: null });
    axios.post(apiUrl + '/sc-estimate/change-status', { id: estimate.id, newStatus },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.CHANGE_STATUS_SUCCESS, payload: response.data });
            dispatch(fetchEstimate(estimate.id));
            dispatch(dialog.actions.hideDialog('change-status-modal'));
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.CHANGE_STATUS_ERROR));
        });
}

export const isDigital = (outputDevice) => {
    return parseInt(outputDevice) === 6 || parseInt(outputDevice) === 18;   // Ricoh or JPress
}