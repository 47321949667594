import axios from 'axios';
import * as types from './ChristyRfq.types';
import * as selectors from './ChristyRfq.selectors';
import { validateAuthInResponse, showNotification, empty } from './../../utils';
import * as dialog from './../common/dialog';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchRequest = (id) => (dispatch) => {
    if (id == null) {
        return;
    }

    dispatch({ type: types.FETCH_REQUEST, payload: null });
    axios.get(apiUrl + '/christy-rfqs/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_REQUEST_SUCCESS, payload: response.data });

            if (!empty(response.data.estimates)) {
                console.log(response.data.estimates)
                dispatch(setPricing(JSON.parse(response.data.estimates)));
            }
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_REQUEST_ERROR));
        });
}

export const fetchInformation = (id) => (dispatch) => {
    dispatch({ type: types.FETCH_INFORMATION, payload: null });
    axios.get(apiUrl + '/christy-rfq/information',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            if (id != null) {
                dispatch(fetchRequest(id));
            }

            dispatch({ type: types.FETCH_INFORMATION_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_INFORMATION_ERROR));
        });
}

export const submitRequest = () => (dispatch, getState) => {
    const fields = selectors.getFields(getState());
    const files = selectors.getFiles(getState());

    if (!validateSubmitFields(fields)) {
        return;
    }

    var formData = new FormData();
    for (const field in fields) {
        formData.append(field, fields[field]);
    }
    for (let i = 1; i <= files.length; i++) {
        formData.append('file' + i, files[i - 1]);
    }
    formData.append('filesLength', files.length);

    dispatch({ type: types.SUBMIT_REQUEST, payload: null });
    axios({
        method: 'post',
        url: apiUrl + '/christy-rfq',
        data: formData,
        headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
    }).then((response) => {
        dispatch({ type: types.SUBMIT_REQUEST_SUCCESS, payload: response.data });
        dispatch(dialog.actions.showDialog('submitted-modal'));
        dispatch(resetState());
        dispatch(clearFiles());
        dispatch(setSubmittedId(response.data.id));
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.SUBMIT_REQUEST_ERROR));
    });
}

export const saveRequest = () => (dispatch, getState) => {
    const fields = selectors.getFields(getState());
    const pricing = selectors.getPricing(getState());

    if (!validateSaveFields(fields, pricing)) {
        return;
    }

    dispatch({ type: types.SAVE_REQUEST, payload: null });
    axios({
        method: 'put',
        url: apiUrl + '/christy-rfqs/' + fields.id,
        data: { fields, pricing },
        headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
    }).then((response) => {
        dispatch({ type: types.SAVE_REQUEST_SUCCESS, payload: response.data });
        showNotification('Request Saved', 'The Request has been saved sucessfully', 'success');
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.SAVE_REQUEST_ERROR));
    });
}

export const sendEstimate = () => (dispatch, getState) => {
    const fields = selectors.getFields(getState());
    const pricing = selectors.getPricing(getState());

    if (!validateSendFields(fields, pricing)) {
        return;
    }

    dispatch({ type: types.SEND_ESTIMATE, payload: null });
    axios({
        method: 'post',
        url: apiUrl + '/christy-rfq/send-estimate',
        data: { fields, pricing },
        headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
    }).then((response) => {
        dispatch({ type: types.SEND_ESTIMATE_SUCCESS, payload: response.data });
        showNotification('Quote Sent', 'The Quote has been sent to the customer sucessfully', 'success');
        dispatch(dialog.actions.hideDialog('send-modal'));
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.SEND_ESTIMATE_ERROR));
    });
}

export const validateSubmitFields = (fields) => {
    if (empty(fields.name)) {
        showNotification('Complete Information', 'Enter the Name', 'info');
        return false;
    }
    if (empty(fields.company)) {
        showNotification('Complete Information', 'Enter the Company', 'info');
        return false;
    }
    if (empty(fields.email)) {
        showNotification('Complete Information', 'Enter the Email', 'info');
        return false;
    }
    if (empty(fields.phone)) {
        showNotification('Complete Information', 'Enter the Phone', 'info');
        return false;
    }
    if (empty(fields.project_title)) {
        showNotification('Complete Information', 'Enter the Project Title', 'info');
        return false;
    }
    if (empty(fields.due_date)) {
        showNotification('Complete Information', 'Enter the Due Date', 'info');
        return false;
    }
    if (empty(fields.quantity)) {
        showNotification('Complete Information', 'Enter the Quantity', 'info');
        return false;
    }
    if (empty(fields.artwork_required)) {
        showNotification('Complete Information', 'Select if the Artwork is required', 'info');
        return false;
    }
    if (empty(fields.mailing_required)) {
        showNotification('Complete Information', 'Select if Mailing is required', 'info');
        return false;
    }
    if (parseInt(fields.requires_print) === 0 && parseInt(fields.requires_promotional) === 0 &&
        parseInt(fields.requires_apparel) === 0 && parseInt(fields.requires_signage) === 0) {
        showNotification('Complete Information', 'Choose at least one Estimate Type', 'info');
        return false;
    }

    return true;
}

export const validateSaveFields = (fields) => {
    if (empty(fields.name)) {
        showNotification('Complete Information', 'Enter the Name', 'info');
        return false;
    }
    if (empty(fields.company)) {
        showNotification('Complete Information', 'Enter the Company', 'info');
        return false;
    }
    if (empty(fields.email)) {
        showNotification('Complete Information', 'Enter the Email', 'info');
        return false;
    }
    if (empty(fields.phone)) {
        showNotification('Complete Information', 'Enter the Phone', 'info');
        return false;
    }
    if (empty(fields.project_title)) {
        showNotification('Complete Information', 'Enter the Project Title', 'info');
        return false;
    }
    if (empty(fields.due_date)) {
        showNotification('Complete Information', 'Enter the Due Date', 'info');
        return false;
    }
    if (empty(fields.quantity)) {
        showNotification('Complete Information', 'Enter the Quantity', 'info');
        return false;
    }
    if (empty(fields.artwork_required)) {
        showNotification('Complete Information', 'Select if the Artwork is required', 'info');
        return false;
    }
    if (empty(fields.mailing_required)) {
        showNotification('Complete Information', 'Select if Mailing is required', 'info');
        return false;
    }
    if (parseInt(fields.requires_print) === 0 && parseInt(fields.requires_promotional) === 0 &&
        parseInt(fields.requires_apparel) === 0 && parseInt(fields.requires_signage) === 0) {
        showNotification('Complete Information', 'Choose at least one Estimate Type', 'info');
        return false;
    }

    return true;
}

export const validateSendFields = (fields, pricing) => {
    if (empty(pricing[0].price)) {
        showNotification('Complete Information', 'Enter the Price for the first quantity', 'info');
        return false;
    }
    if (empty(pricing[0].commission)) {
        showNotification('Complete Information', 'Enter the Commission for the first quantity', 'info');
        return false;
    }

    let requiresSecondQty = false;
    if (
        !empty(pricing[1].quantity) || !empty(pricing[1].price) || !empty(pricing[1].outsource) ||
        !empty(pricing[1].commission) || !empty(pricing[1].add_markup) || !empty(pricing[1].final_price)
    ) {
        requiresSecondQty = true;
    }
    if (requiresSecondQty) {
        if (empty(pricing[1].quantity)) {
            showNotification('Complete Information', 'Enter the Quantity for the second quantity', 'info');
            return false;
        }
        if (empty(pricing[1].price)) {
            showNotification('Complete Information', 'Enter the Price for the second quantity', 'info');
            return false;
        }
        if (empty(pricing[1].commission)) {
            showNotification('Complete Information', 'Enter the Commission for the second quantity', 'info');
            return false;
        }
    }

    let requiresThirdQty = false;
    if (
        !empty(pricing[2].quantity) || !empty(pricing[2].price) || !empty(pricing[2].outsource) ||
        !empty(pricing[2].commission) || !empty(pricing[2].add_markup) || !empty(pricing[2].final_price)
    ) {
        requiresThirdQty = true;
    }
    if (requiresThirdQty) {
        if (empty(pricing[2].quantity)) {
            showNotification('Complete Information', 'Enter the Quantity for the third quantity', 'info');
            return false;
        }
        if (empty(pricing[2].price)) {
            showNotification('Complete Information', 'Enter the Price for the third quantity', 'info');
            return false;
        }
        if (empty(pricing[2].commission)) {
            showNotification('Complete Information', 'Enter the Commission for the third quantity', 'info');
            return false;
        }
    }

    if (empty(fields.message_to_customer)) {
        showNotification('Complete Information', 'Enter the Message to the Customer', 'info');
        return false;
    }
    if (empty(fields.estimator_id)) {
        showNotification('Complete Information', 'Select an Estimator', 'info');
        return false;
    }

    return true;
}

export const setField = (name, value) => (dispatch) => {
    dispatch({
        type: types.SET_FIELD,
        payload: { name, value }
    });
}

export const setId = (id) => (dispatch) => {
    dispatch({
        type: types.SET_ID,
        payload: id,
    });
}

export const setHistory = (history) => (dispatch) => {
    dispatch({
        type: types.SET_HISTORY,
        payload: history,
    });
}

export const addFile = (file) => (dispatch) => {
    dispatch({
        type: types.ADD_FILE,
        payload: file,
    });
}

export const removeFile = (index) => (dispatch) => {
    dispatch({
        type: types.REMOVE_FILE,
        payload: index,
    });
}

export const clearFiles = (index) => (dispatch) => {
    dispatch({
        type: types.CLEAR_FILES,
        payload: index,
    });
}

export const setSubmittedId = (id) => (dispatch) => {
    dispatch({
        type: types.SET_SUBMITTED_ID,
        payload: id,
    });
}

export const setPricingField = (index, field, value) => (dispatch) => {
    dispatch({
        type: types.SET_PRICING_FIELD,
        payload: { index, field, value },
    });
}

export const setPricing = (pricing) => (dispatch) => {
    dispatch({
        type: types.SET_PRICING,
        payload: pricing,
    });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null
    });
}