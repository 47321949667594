import React, { Fragment } from 'react';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import PqtPagination from './../../PqtPagination';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import { showNotification, dateToTimezone } from './../../../utils';
import moment from 'moment-timezone';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';

const Invoices = React.memo((props) => {
    const confirmHandler = (id) => {
        props.showDeleteModal();
        props.setIdForDelete(id);
    }

    const handleCheckInvoice = (id, event) => {
        props.checkInvoice(id, event.target.checked);
    }

    const renderInvoices = () => {
        if (props.fetchingInvoices) {
            return (
                <tr>
                    <td colSpan="17"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.invoicesData.length === 0) {
            return (
                <tr>
                    <td colSpan="17">No Results</td>
                </tr>
            );
        }

        return props.invoicesData.map((invoice, index) => {
            let csrName = invoice.csr_name;
            const csrNameParts = csrName.split(' ');
            if (csrNameParts.length > 0) {
                csrName = csrNameParts[0];
            }

            let salesName = invoice.sales_rep_name;
            const salesNameParts = salesName.split(' ');
            if (salesNameParts.length > 0) {
                salesName = salesNameParts[0];
            }

            let status = 'N/A';
            if (parseInt(invoice.status) === 1) {
                status = 'Preliminary invoice is ready';
            } else if (parseInt(invoice.status) === 2) {
                status = 'Closed and ready to start invoicing';
            } else if (parseInt(invoice.status) === 3) {
                status = 'Approved ready for accounting';
            } else if (parseInt(invoice.status) === 4) {
                status = 'Final & Archived';
            }

            let preliminaryDate = '-';
            if (invoice.preliminary_datetime != null) {
                preliminaryDate = dateToTimezone(invoice.preliminary_datetime, 'MM/DD/YYYY');
            }

            let closedDate = '-';
            if (invoice.closed_datetime != null) {
                closedDate = dateToTimezone(invoice.closed_datetime, 'MM/DD/YYYY');
            }

            let approvedDate = '-';
            if (invoice.approved_datetime != null) {
                approvedDate = dateToTimezone(invoice.approved_datetime, 'MM/DD/YYYY');
            }

            let finalDate = '-';
            if (invoice.final_datetime != null) {
                finalDate = dateToTimezone(invoice.final_datetime, 'MM/DD/YYYY');
            }

            let invoiceDate = '-';
            if (invoice.final_datetime != null) {
                invoiceDate = moment.tz(invoice.invoice_date, 'America/Chicago').format('MM/DD/YYYY');
            }

            let stagecoachNumber = 'N/A';
            if (invoice.stagecoach_number != null && invoice.stagecoach_number !== '') {
                stagecoachNumber = invoice.stagecoach_number;
            }

            let poNumber = 'N/A';
            if (invoice.po_number != null && invoice.po_number !== '') {
                poNumber = invoice.po_number;
            }

            let qbNumber = 'N/A';
            if (invoice.qb_number != null && invoice.qb_number !== '') {
                qbNumber = invoice.qb_number;
            }

            let combinedLabel = null;
            if (invoice.combined_ids !== null) {
                combinedLabel = <span className="combined">(Combined)</span>;
            }

            let deleteIcon = null;
            if (props.user != null && ([1, 9].includes(parseInt(props.user.pqt_type)) || props.user.is_pqt_accounting)) {
                deleteIcon = (
                    <Tooltip title="Delete Invoice" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="fas fa-trash" onClick={confirmHandler.bind(this, invoice.id)} />
                    </Tooltip>
                );
            }

            let total = 0;
            if (invoice.total != null) {
                total = invoice.total.toFixed(2);
            }

            let projectType = '-';
            if (parseInt(invoice.project_type) === 1) {
                projectType = 'Regular';
            } else if (parseInt(invoice.project_type) === 2) {
                projectType = 'Daily';
            } else if (parseInt(invoice.project_type) === 3) {
                projectType = 'Weekly';
            } else if (parseInt(invoice.project_type) === 4) {
                projectType = 'Monthly';
            } else if (parseInt(invoice.project_type) === 5) {
                projectType = 'Partial';
            }

            return (
                <tr key={'late-invoice-' + index}>
                    <td className="text-center">
                        <input
                            type="checkbox"
                            checked={props.combineIds.includes(invoice.id)}
                            onChange={handleCheckInvoice.bind(this, invoice.id)}
                        />
                    </td>
                    <td>{invoice.id}</td>
                    <td><Link to={'/tracking/' + invoice.stagecoach_number}>{stagecoachNumber}</Link></td>
                    <td>{poNumber}</td>
                    <td>{qbNumber}</td>
                    <td>{preliminaryDate}</td>
                    <td>{closedDate}</td>
                    <td>{approvedDate}</td>
                    <td>{finalDate}</td>
                    <td>{invoiceDate}</td>
                    <td>{invoice.job_description}</td>
                    <td>{invoice.customer_name} {combinedLabel}</td>
                    <td>{csrName}</td>
                    <td>{salesName}</td>
                    <td>{status}</td>
                    <td>{projectType}</td>
                    <td>{numeral(total).format('$0,0.00')}</td>
                    <td>
                        <div className="actions">
                            <Tooltip title="Edit Invoice" position="bottom" arrow size="small" className="action-tooltip">
                                <Link to={'/invoice/' + invoice.id}><i className="fas fa-edit" /></Link>
                            </Tooltip>
                            <Tooltip title="View Job" position="bottom" arrow size="small" className="action-tooltip">
                                <Link to={'/tracking/' + invoice.stagecoach_number}><i className="fas fa-info-circle" /></Link>
                            </Tooltip>
                            {deleteIcon}
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const setSortHandler = (field, value) => {
        props.setSort(field, value);
    };

    const renderHeader = (name, column, className) => {
        let sortFunc = setSortHandler.bind(this, column, 'asc');
        let sortIcon = <i className="fas fa-sort" />;
        if (props.sort.column === column) {
            if (props.sort.type === 'asc') {
                sortFunc = setSortHandler.bind(this, column, 'desc');
                sortIcon = <i className="fas fa-sort-up" />;
            } else {
                sortFunc = setSortHandler.bind(this, column, 'asc');
                sortIcon = <i className="fas fa-sort-down" />;
            }
        }

        return <th className={className} onClick={sortFunc}>{name} {sortIcon}</th>;
    };

    const handleSetShow = (name, event) => {
        props.setShow(name, event.target.checked);
    };

    const handleCombineInvoices = () => {
        if (props.combineIds.length < 2) {
            showNotification('No invoices selected', 'You must select at least two invoices to combine them', 'info');
            return;
        }

        props.showCombineModal();
    };

    const handleChangeFilter = (name, fetch, event) => {
        props.changeFilter(name, event.target.value, fetch);
    };

    const handleKey = (event) => {
        if (event.key === 'Enter') {
            props.fetchInvoices({ 
                showPreliminary: props.show.preliminary, 
                showClosed: props.show.closed, 
                showApproved: props.show.approved, 
                showFinal: props.show.final, 
            })
        }
    };

    const renderSearchField = (name) => {
        let value = null;
        for (let i = 0; i < props.filerFields.length; i++) {
            if (props.filerFields[i].name === name) {
                value = props.filerFields[i].value;
            }
        }

        return (
            <th className="search-th">
                <input
                    type="text"
                    name={name}
                    className="search-input"
                    value={value}
                    onKeyPress={handleKey}
                    onChange={handleChangeFilter.bind(this, name, false)}
                />
            </th>
        );
    };

    const handleChangeSelectFilter = (name, event) => {
        props.changeFilter(name, event.target.value, true);
    };

    const renderSearchSelect = (name, options) => {
        let value = '';
        for (let i = 0; i < props.filerFields.length; i++) {
            if (props.filerFields[i].name === name) {
                value = props.filerFields[i].value;
            }
        }

        return (
            <th className="search-th">
                <select
                    name={name}
                    className="search-input"
                    value={value}
                    onChange={handleChangeSelectFilter.bind(this, name)}
                >
                    {options.map((option) => {
                        return <option value={option.value}>{option.label}</option>;
                    })}
                </select>
            </th>
        );
    };

    const setDateFieldHandler = (name, event) => {
        let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');
        props.changeFilter(name, value, true);
    };

    const renderSearchDateField = (name) => {
        let value = null;
        for (let i = 0; i < props.filerFields.length; i++) {
            if (props.filerFields[i].name === name) {
                value = props.filerFields[i].value;
            }
        }

        return (
            <th className="search-th">
                <DayPickerInput
                    formatDate={formatDate}
                    parseDate={parseDate}
                    placeholder=""
                    className="date-picker-field"
                    onDayChange={setDateFieldHandler.bind(this, name)}
                    value={value == null || value === '0000-00-00' ? '' : moment(value).format('MM/DD/YYYY')}
                />
            </th>
        )
    };

    const handleExport = () => {
        let count = 0;
        if (props.show.preliminary) {
            count++;
        }
        if (props.show.closed) {
            count++;
        }
        if (props.show.approved) {
            count++;
        }
        if (props.show.final) {
            count++;
        }

        if (count !== 1) {
            return showNotification('Select only one status', 'You must select only one type of invoice to export it (Preliminary, Closed, Approved or Final).', 'info');
        }

        props.exportExcel();
    };

    let exportButton = null;
    if (props.user != null && ([1, 2].includes(parseInt(props.user.pqt_type)) || props.user.is_pqt_accounting)) {
        let exportButtonLabel = <Fragment>Export Excel <i className="fas fa-file-excel" /></Fragment>
        if (props.isExporting) {
            exportButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        exportButton = <button onClick={handleExport} className="export-button" disabled={props.isExporting}>{exportButtonLabel}</button>;
    }

    return (
        <div className="invoices-container">
            <div className="actions-container">
                <div className="action-buttons">
                    <button onClick={handleCombineInvoices} className="combine-button">Combine Invoices <i className="fas fa-folder" /></button>
                    <Link to="/postage-report"><button className="report-button">Postage Report <i className="fas fa-file-alt" /></button></Link>
                    {exportButton}
                </div>
                <div className="show-fields">
                    <div className="field"><input type="checkbox" checked={props.show.preliminary} onClick={handleSetShow.bind(this, 'preliminary')} /> Show Preliminary invoice is ready</div>
                    <div className="field"><input type="checkbox" checked={props.show.closed} onClick={handleSetShow.bind(this, 'closed')} /> Show Closed and ready to start invoicing</div>
                    <div className="field"><input type="checkbox" checked={props.show.approved} onClick={handleSetShow.bind(this, 'approved')} /> Show Approved ready for accounting</div>
                    <div className="field"><input type="checkbox" checked={props.show.final} onClick={handleSetShow.bind(this, 'final')} /> Show Final &amp; Archived</div>
                </div>
            </div>
            <div className="table-container">
                <table className="table table-sm table-hover table-striped">
                    <thead>
                        <tr>
                            <th style={{ width: 35 }}></th>
                            {renderHeader('No.', 'id', 'th-id')}
                            {renderHeader('SC #', 'stagecoach_number', 'th-stagecoach-number')}
                            {renderHeader('PO #', 'po_number', 'th-po-number')}
                            {renderHeader('QB #', 'qb_number', 'th-qb-number')}
                            {renderHeader('Created At', 'preliminary_datetime', 'th-due-date')}
                            {renderHeader('Closed At', 'closed_datetime', 'th-due-date')}
                            {renderHeader('Approved At', 'approved_datetime', 'th-due-date')}
                            {renderHeader('Pushed At', 'final_datetime', 'th-due-date')}
                            {renderHeader('Invoice Date', 'invoice_date', 'th-due-date')}
                            {renderHeader('Job Description', 'job_description', 'th-customer-name')}
                            {renderHeader('Customer', 'customer_name', 'th-customer-name')}
                            {renderHeader('CSR', 'csr_name', 'th-csr')}
                            {renderHeader('Sales', 'sales_rep_name', 'th-sales-rep')}
                            {renderHeader('Status', 'status', 'th-status')}
                            {renderHeader('Project Type', 'project_type', 'th-status')}
                            <th className="th-total">Total</th>
                            <th style={{ width: 90 }}></th>
                        </tr>
                        <tr>
                            <th></th>
                            {renderSearchField('id')}
                            {renderSearchField('stagecoach_number')}
                            {renderSearchField('po_number')}
                            {renderSearchField('qb_number')}
                            {renderSearchDateField('preliminary_datetime')}
                            {renderSearchDateField('closed_datetime')}
                            {renderSearchDateField('approved_datetime')}
                            {renderSearchDateField('final_datetime')}
                            {renderSearchDateField('invoice_date')}
                            {renderSearchField('job_description')}
                            {renderSearchField('customer_name')}
                            {renderSearchField('csr_name')}
                            {renderSearchField('sales_rep_name')}
                            <th></th>
                            {renderSearchSelect('project_type', [
                                {value: '', label: ''},
                                {value: 1, label: 'Regular'},
                                {value: 2, label: 'Daily'},
                                {value: 3, label: 'Weekly'},
                                {value: 4, label: 'Monthly'},
                                {value: 5, label: 'Partial'},
                            ])}
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderInvoices()}
                    </tbody>
                </table>
                <PqtPagination
                    pagination={props.pagination}
                    setActivePage={props.setActivePage}
                />
            </div>
        </div>
    );
});

Invoices.propTypes = {

};

export default Invoices;
