import React from 'react';
import { Dialog } from './../../common/dialog';
import Switch from "react-switch";

const AdditionalServicesModal = React.memo((props) => {
    const handleSwitchChange = (id, field, setFunc, checked) => {
        setFunc(id, field, checked ? 1 : 0);
    };

    const handleChange = (id, field, setFunc, event) => {
        setFunc(id, field, event.target.value);
    };

    const renderItems = () => {
        const services = [...props.additionalServices];

        services.sort((a, b) => {
            return parseInt(a.position) - parseInt(b.position);
        });

        return services.map((item) => {
            if (parseInt(props.estimateType) === 1 && ![1, 2, 3, 5, 6, 7, 8, 9, 13, 14, 15, 4, 21, 26, 27, 28].includes(item.id)) {   // Sheetfed
                return null;
            } else if (parseInt(props.estimateType) === 2 && ![18, 19, 4, 21, 27, 28].includes(item.id)) {   // Envelopes
                return null;
            } else if (parseInt(props.estimateType) === 3 && ![1, 7, 8, 9, 13, 14, 15, 20, 4, 21, 22, 23, 24, 25].includes(item.id)) {   // Plus-Cover
                return null;
            } else if (parseInt(props.estimateType) === 4 && ![1, 7, 8, 9, 13, 14, 15, 20, 4, 21, 22, 23, 24, 25].includes(item.id)) {   // Self-Cover
                return null;
            } else if (parseInt(props.estimateType) === 5 && ![1, 2, 3, 5, 6, 9, 14, 15, 4, 21, 26, 27, 28].includes(item.id)) {   // Digital
                return null;
            } else if (parseInt(props.estimateType) === 6 && ![10, 11, 12, 16, 17].includes(item.id)) {   // Large Format
                return null;
            } else if (parseInt(props.estimateType) === 7 && ![2, 6, 7, 8, 9, 13, 14, 15, 4, 21, 26, 27, 28].includes(item.id)) {   // Pocket Folders
                return null;
            } else if (parseInt(props.estimateType) === 8 && ![1, 2, 3, 6, 7, 8, 9, 13, 14, 15, 4, 21, 26, 27, 28, 29].includes(item.id)) {   // NCR
                return null;
            } else if (parseInt(props.estimateType) === 9 && ![1, 2, 3, 6, 7, 8, 9, 13, 14, 15, 4, 21, 26, 27, 28, 30].includes(item.id)) {   // Notepads
                return null;
            }

            let extraField = null;
            if (item.id === 1 && (parseInt(props.estimateType) === 1 || parseInt(props.estimateType) === 5)) {   // Sheetfed or Digital
                extraField = (
                    <div>
                        Fold Type:
                        <select value={item.folding_type} onChange={handleChange.bind(this, item.id, 'folding_type', props.setAdditionalService)}>
                            <option value="">Select option</option>
                            <option value="1">Half Fold</option>
                            <option value="40">Tri-fold</option>
                            <option value="39">Z Folding</option>
                            <option value="43">Accordion</option>
                            <option value="42">Double Gatefold</option>
                            <option value="41">Gatefold</option>
                        </select>
                    </div>
                );
            } else if (item.id === 3) {
                extraField = (
                    <div>
                        Holes:
                        <select value={item.drilling_holes} onChange={handleChange.bind(this, item.id, 'drilling_holes', props.setAdditionalService)}>
                            <option value="">Select option</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                        </select>
                    </div>
                );
            } else if (item.id === 4) {
                extraField = (
                    <div>
                        Qty per Package:
                        <input type="number" value={item.per_package} onChange={handleChange.bind(this, item.id, 'per_package', props.setAdditionalService)} />
                    </div>
                );
            } else if (item.id === 21) {
                extraField = (
                    <div>
                        Qty per Package:
                        <input type="number" value={item.per_package} onChange={handleChange.bind(this, item.id, 'per_package', props.setAdditionalService)} />
                    </div>
                );
            } else if (item.id === 27) {
                extraField = (
                    <div>
                        Box:
                        <select value={item.box} onChange={handleChange.bind(this, item.id, 'box', props.setAdditionalService)}>
                            <option value="">Select option</option>
                            <option value="250">250 Business Cards</option>
                            <option value="500">500 Business Cards</option>
                            <option value="1000">1000 Business Cards</option>
                        </select>
                    </div>
                );
            } else if (item.id === 28) {
                extraField = (
                    <div>
                        Box:
                        <select value={item.box} onChange={handleChange.bind(this, item.id, 'box', props.setAdditionalService)}>
                            <option value="">Select option</option>
                            <option value="500">500 Letterheads</option>
                        </select>
                    </div>
                );
            }

            return (
                <tr>
                    <td>{item.name}</td>
                    <td className="extra-column">{extraField}</td>
                    <td className="switch-column">
                        <Switch
                            onChange={handleSwitchChange.bind(this, item.id, 'checked', props.setAdditionalService)}
                            checked={item.checked}
                            width={42}
                            height={22}
                        />
                    </td>
                </tr>
            );
        });
    };

    const handleAddButton = () => {
        props.setDataChanged(true);
        props.addAdditionalServicesItems();
    };

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={props.hideAdditionalServicesModal}>Cancel</button>,
        <button key="delete-button" className="btn btn-light btn-sm action-button" onClick={handleAddButton}>Add</button>,
    ];
    return (
        <Dialog
            name="additional-services-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="additional-services-dialog"
        >
            <div className="additional-services-container">
                <h1>Additional Services</h1>
                <div className="items-container">
                    <div className="tabs">
                        <div className="tab active">
                            Services
                        </div>
                    </div>
                    <div className="items">
                        <table className="table table-sm table-hover table-striped">
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th></th>
                                    <th>Yes/No</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderItems()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Dialog >
    );
});

export default AdditionalServicesModal;
