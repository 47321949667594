import React from 'react';

const FulfillmentForm = React.memo((props) => {
    return (
        <div className="fulfillment-form-container">
            
            {props.buttonsContainer}
        </div>
    );
});

export default FulfillmentForm;
