import React from 'react';
import FormPopover from './FormPopover';

const SelfCoverForm = React.memo((props) => {
    return (
        <div className="self-cover-form-container">
            <div className="line1">
                <div className="column1">
                    <div className="page-count-field">
                        <b>Page Count <span className="required">*</span>:</b>
                        <FormPopover
                            code="SC-001"
                            information={props.information}
                        />
                        <input type="number" value={props.fields.page_count} onChange={props.handleSetField.bind(this, 'page_count')} />
                    </div>
                    <div className="product-type-container">
                        <div><b>Product Type:</b></div>
                        <div className="product-type">{props.productTypeName}</div>
                        <div><b>Process Request:</b></div>
                        <div className="process-request">{props.processRequestName}</div>
                    </div>
                </div>
                <div className="column2">
                    <div>
                        <b>Binding Edge <span className="required">*</span>:</b>
                        <FormPopover
                            code="SC-002"
                            information={props.information}
                        />
                    </div>
                    <select value={props.fields.binding_edge} onChange={props.handleSetField.bind(this, 'binding_edge')}>
                        <option value="">Select option</option>
                        <option value="1">Width</option>
                        <option value="2">Height</option>
                    </select>
                    <div>
                        <b>Binding Type <span className="required">*</span>:</b>
                        <FormPopover
                            code="SC-003"
                            information={props.information}
                        />
                    </div>
                    <select value={props.fields.binding_type} onChange={props.handleSetField.bind(this, 'binding_type')}>
                        <option value="">Select option</option>
                        <option value="1">Saddle Stitch</option>
                        <option value="2">Perfect Bind</option>
                        {/* <option value="3">Wire-O</option>
                        <option value="4">GBC</option> */}
                        <option value="5">Spiral</option>
                        <option value="6">Wire</option>
                        <option value="7">Staple</option>
                    </select>
                </div>
                <div className="column3">
                    <div className="title">
                        <b>Size:</b>
                        <FormPopover
                            code="FLAT-004"
                            information={props.information}
                        />
                    </div>
                    <div className="flat-width-field">
                        <b>Flat Width <span className="required">*</span>:</b>
                        <input type="number" value={props.fields.flat_width} onChange={props.handleSetField.bind(this, 'flat_width')} />
                    </div>
                    <div className="x-field">X</div>
                    <div className="flat-height-field">
                        <b>Flat Height <span className="required">*</span>:</b>
                        <input type="number" value={props.fields.flat_height} onChange={props.handleSetField.bind(this, 'flat_height')} />
                    </div>
                    <div className="arrows-field"><i className="fas fa-angle-double-right" /></div>
                    <div className="finish-width-field">
                        <b>Finish Width <span className="required">*</span>:</b>
                        <input type="number" value={props.fields.finish_width} onChange={props.handleSetField.bind(this, 'finish_width')} />
                    </div>
                    <div className="x-field">X</div>
                    <div className="finish-height-field">
                        <b>Finish Height <span className="required">*</span>:</b>
                        <input type="number" value={props.fields.finish_height} onChange={props.handleSetField.bind(this, 'finish_height')} />
                    </div>
                </div>
            </div>
            <div className="line2">
                <div className="column1">
                    <div className="title">
                        <b>Ink:</b>
                        <FormPopover
                            code="FLAT-002"
                            information={props.information}
                        />
                    </div>
                    <div className="subline1">
                        <div className="subtitle"><b>Interior</b></div>
                        <div className="colors-field">
                            <b>Colors:</b>
                            <input type="text" value={props.fields.front_colors} onChange={props.handleSetField.bind(this, 'front_colors')} />
                        </div>
                        <div className="ink-field">
                            <b>Ink <span className="required">*</span>:</b>
                            <select value={props.fields.front_inks} onChange={props.handleSetField.bind(this, 'front_inks')}>
                                <option value="">Select option</option>
                                <option value="1">Black only</option>
                                <option value="2">PMS only</option>
                                <option value="3">CMYK</option>
                                <option value="4">CMYK + PMS</option>
                                <option value="5">CMYK + 2 PMS</option>
                                <option value="6">CMYK + 3 PMS</option>
                                <option value="7">CMYK + Coating</option>
                                <option value="9">Black + PMS</option>
                                <option value="10">PMS + PMS</option>
                            </select>
                        </div>
                        <div className="pms-field">
                            <b>PMS #:</b>
                            <input type="text" value={props.fields.front_pms} onChange={props.handleSetField.bind(this, 'front_pms')} />
                        </div>
                        <div className="coating-field">
                            <b>Coating:</b>
                            <FormPopover
                                code="FLAT-003"
                                information={props.information}
                            />
                            <select value={props.fields.front_coating} onChange={props.handleSetField.bind(this, 'front_coating')}>
                                <option value="">None</option>
                                <option value="1">AQ = Aqueous - Gloss</option>
                                <option value="2">AQ = Aqueous - Dull/Matte</option>
                                <option value="3">UV = Ultra Violet Gloss</option>
                                <option value="4">Varnish - Gloss</option>
                                <option value="5">Varnish - Dull/Matte</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="column2">
                    <div className="title">
                        <b>Stock:</b>
                        <FormPopover
                            code="FLAT-005"
                            information={props.information}
                        />
                    </div>
                    <div className="paper-stock-field">
                        <b>Paper Stock <span className="required">*</span>:</b>
                        <select value={props.fields.paper_stock} onChange={props.handleSetField.bind(this, 'paper_stock')}>
                            <option value="">Select option</option>
                            {props.paperStockOptions}
                        </select>
                    </div>
                    <div className="paper-weight-field">
                        <b>Paper Weight <span className="required">*</span>:</b>
                        <select value={props.fields.paper_weight} onChange={props.handleSetField.bind(this, 'paper_weight')}>
                            <option value="">Select option</option>
                            {props.paperWeightOptions}
                        </select>
                    </div>
                </div>
            </div>
            <div className="line3">
                <div className="column1">
                    <div className="title"><b>Please describe your project in detail here:</b></div>
                    <textarea
                        placeholder="Please let us know more about your project detail"
                        value={props.fields.self_cover_description}
                        onChange={props.handleSetField.bind(this, 'self_cover_description')}
                    />
                </div>
                <div className="column2">
                    <div><b>Important:</b></div>
                    <div>For saddle stitched projects. Page count must be divisible by 4.</div>
                </div>
            </div>
            {props.buttonsContainer}
        </div>
    );
});

export default SelfCoverForm;
