import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Form from './Form';
import * as actions from './CcPaymentAuth.actions';
import * as selectors from './CcPaymentAuth.selectors';
import './CcPaymentAuth.scss';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';

const CcPaymentAuth = (props) => {
    /* State to props */
    const fields = useSelector(selectors.getFields, stringifyEqualityCheck);
    const savingForm = useSelector(selectors.getSavingForm);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const saveForm = useCallback(() => dispatch(actions.saveForm()), [dispatch]);
    const setField = useCallback((field, value) => dispatch(actions.setField(field, value)), [dispatch]);

    return (
        <div>
            <Helmet><title>Credit Card Payment Authorization Form - Stagecoach</title></Helmet>
            <div className="cc-payment-auth-container">
                <Form
                    fields={fields}
                    savingForm={savingForm}
                    setField={setField}
                    saveForm={saveForm}
                />
            </div>
        </div>
    );
};

export default CcPaymentAuth;
