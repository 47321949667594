import React from 'react';
//import PropTypes from 'prop-types';

const ChatIcon = React.memo((props) => {
    const showHandler = () => {
        props.loadChats();
        props.setShowField('room', true);
        props.setShowField('members', false);
        props.setType(null);
    };

    let unreadMessagesIcon = null;
    if (props.hasUnreadMessages) {
        unreadMessagesIcon = (
            <div className="unread-messages">
                <span className="fa-stack" onClick={showHandler}>
                    <i className="fas fa-circle fa-stack-2x" />
                    <i className="fas fa-comment-dots fa-stack-1x fa-inverse" />
                </span>
            </div>
        );
    }

    return (
        <div className="chat-icon-container">
            <span className="fa-stack" onClick={showHandler}>
                <i className="fas fa-circle fa-stack-2x" />
                <i className="fas fa-comments fa-stack-1x fa-inverse" />
            </span>
            {unreadMessagesIcon}
        </div>
    );
});

ChatIcon.propTypes = {

};

export default ChatIcon;
