import axios from 'axios';
import * as types from './CustomerApplication.types';
import * as selectors from './CustomerApplication.selectors';
import { showNotification, empty } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const saveApplication = (ref) => (dispatch, getState) => {
    const fields = selectors.getFields(getState());
    const file = selectors.getFile(getState());
    const recaptchaToken = selectors.getRecaptchaToken(getState());

    if (!validateForm(fields)) {
        return;
    }

    if (parseInt(fields.is_sales_tax_exempt) === 1 && file == null) {
        return showNotification('Complete Information', 'Please attach form for tax exempt', 'info');
    }

    if (recaptchaToken == null) {
        return showNotification('Complete Information', 'Please complete the Captcha', 'info');
    }

    let data = new FormData();
    data.append('recaptcha_token', recaptchaToken);

    for (const field in fields) {
        data.append(field, fields[field]);
    }

    if (file && file != null) {
        data.append('file', file);
    }

    dispatch({ type: types.SAVE_APPLICATION, payload: null });
    axios({
        method: 'post',
        url: apiUrl + '/customer-application',
        data: data,
        headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
    }).then((response) => {
        dispatch({ type: types.SAVE_APPLICATION_SUCCESS, payload: response.data });
        showNotification('Application submitted successfully', 'Thank you for submitting your application! Our team will contact you if we need further details.', 'success');

        if (ref && ref != null && ref.current != null) {
            ref.current.reset();
        }
        dispatch(clearForm());
        dispatch(setRecaptchaToken(null));
    }).catch((error) => {
        dispatch({ type: types.SAVE_APPLICATION_ERROR, payload: null });

        if (error.response && error.response.status === 406) {
            showNotification('Invalid Captcha', 'The captcha is invalid or expired', 'info');
        } else {
            showNotification('Error', 'An error has occurred!', 'danger');
        }

        if (ref && ref != null && ref.current != null) {
            ref.current.reset();
        }
        dispatch(clearForm());
        dispatch(setRecaptchaToken(null));
    });
}

export const validateForm = (fields) => {
    if (empty(fields['sales_rep'])) {
        showNotification('Complete Information', 'Please enter the Sales Rep', 'info');
        return false;
    }
    if (empty(fields['customer_name'])) {
        showNotification('Complete Information', 'Please enter the Customer Name', 'info');
        return false;
    }
    if (empty(fields['mailing_address'])) {
        showNotification('Complete Information', 'Please enter the Mailing Address', 'info');
        return false;
    }
    if (empty(fields['mailing_city'])) {
        showNotification('Complete Information', 'Please enter the Mailing City', 'info');
        return false;
    }
    if (empty(fields['mailing_state'])) {
        showNotification('Complete Information', 'Please enter the Mailing State', 'info');
        return false;
    }
    if (empty(fields['mailing_zip'])) {
        showNotification('Complete Information', 'Please enter the Mailing Zip', 'info');
        return false;
    }
    if (empty(fields['mailing_phone'])) {
        showNotification('Complete Information', 'Please enter the Mailing Phone', 'info');
        return false;
    }
    if (empty(fields['contact_name'])) {
        showNotification('Complete Information', 'Please enter the Contact Name', 'info');
        return false;
    }
    if (empty(fields['contact_email_address'])) {
        showNotification('Complete Information', 'Please enter the Contact Email Address', 'info');
        return false;
    }
    if (empty(fields['ap_contact'])) {
        showNotification('Complete Information', 'Please enter the A/P Contact Name', 'info');
        return false;
    }
    if (empty(fields['ap_contact_email'])) {
        showNotification('Complete Information', 'Please enter the A/P Contact Email Address', 'info');
        return false;
    }
    if (empty(fields['authorized_signature'])) {
        showNotification('Complete Information', 'Please enter the Authorized Signature', 'info');
        return false;
    }

    return true;
}

export const setRecaptchaToken = (token) => (dispatch) => {
    dispatch({
        type: types.SET_RECAPTCHA_TOKEN,
        payload: token,
    });
}

export const clearForm = () => (dispatch) => {
    dispatch({
        type: types.CLEAR_FORM,
        payload: null,
    });
}

export const setField = (field, value) => (dispatch) => {
    dispatch({
        type: types.SET_FIELD,
        payload: { field, value },
    });
}

export const setFile = (file) => (dispatch) => {
    dispatch({
        type: types.SET_FILE,
        payload: file,
    });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null,
    });
}
