import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from './Table';
import DeleteModal from './DeleteModal';
import NewEstimateModal from './NewEstimateModal';
import * as actions from './Estimates.actions';
import * as selectors from './Estimates.selectors';
import * as estimateActions from './../Estimate/Estimate.actions';
import './Estimates.scss';
import PqtLayout from './../PqtLayout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import * as dialog from '../common/dialog';
import * as loginSelectors from './../Login/Login.selectors';
import { Redirect } from 'react-router-dom';

const Estimates = (props) => {
    /* State to props */
    const fetchingEstimates = useSelector(selectors.getFetchingEstimates);
    const estimates = useSelector(selectors.getEstimates, stringifyEqualityCheck);
    const pagination = useSelector(selectors.getPagination, stringifyEqualityCheck);
    const sort = useSelector(selectors.getSort, stringifyEqualityCheck);
    const idForDelete = useSelector(selectors.getIdForDelete, stringifyEqualityCheck);
    const isDeleting = useSelector(selectors.getIsDeleting, stringifyEqualityCheck);
    const filerFields = useSelector(selectors.getFilterFields, stringifyEqualityCheck);
    const estimateType = useSelector(selectors.getEstimateType, stringifyEqualityCheck);
    const customerId = useSelector(selectors.getCustomerId, stringifyEqualityCheck);
    const customers = useSelector(selectors.getCustomers, stringifyEqualityCheck);
    const salesReps = useSelector(selectors.getSalesReps, stringifyEqualityCheck);
    const fetchingInformation = useSelector(selectors.getFetchingInformation, stringifyEqualityCheck);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const deleteEstimate = useCallback((id, comments) => dispatch(actions.deleteEstimate(id, comments)), [dispatch]);
    const setSort = useCallback((column, type) => dispatch(actions.setSort(column, type)), [dispatch]);
    const setActivePage = useCallback((name, value) => dispatch(actions.setActivePage(name, value)), [dispatch]);
    const fetchEstimates = useCallback((options, isWsUpdate) => dispatch(actions.fetchEstimates(options, isWsUpdate)), [dispatch]);
    const setIdForDelete = useCallback((id) => dispatch(actions.setIdForDelete(id)), [dispatch]);
    const changeFilter = useCallback((name, value, fetch) => dispatch(actions.changeFilter(name, value, fetch)), [dispatch]);
    const setEstimateType = useCallback((value) => dispatch(actions.setEstimateType(value)), [dispatch]);
    const setCustomerId = useCallback((value) => dispatch(actions.setCustomerId(value)), [dispatch]);
    const fetchCustomers = useCallback(() => dispatch(actions.fetchCustomers()), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);
    const setEstimateField = useCallback((name, value) => dispatch(estimateActions.setField(name, value)), [dispatch]);
    const showDeleteModal = useCallback(() => dispatch(dialog.actions.showDialog('delete-modal')), [dispatch]);
    const hideDeleteModal = useCallback(() => dispatch(dialog.actions.hideDialog('delete-modal')), [dispatch]);
    const showNewEstimateModal = useCallback(() => dispatch(dialog.actions.showDialog('new-estimate-modal')), [dispatch]);
    const hideNewEstimateModal = useCallback(() => dispatch(dialog.actions.hideDialog('new-estimate-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        fetchEstimates();
        fetchCustomers();
        return () => {
            resetState();
        };
    }, [fetchEstimates, fetchCustomers, resetState]);

    if (user != null && ![1, 2, 3, 4, 5, 9].includes(parseInt(user.pqt_type)) && !user.is_pqt_accounting) {
        return <Redirect to="/dashboard" />;
    }

    return (
        <PqtLayout title="Estimates" history={props.history}>
            <Helmet><title>Estimates - Stagecoach</title></Helmet>
            <div className="estimates-container">
                <Table
                    estimates={estimates}
                    pagination={pagination}
                    fetchingEstimates={fetchingEstimates}
                    history={props.history}
                    sort={sort}
                    user={user}
                    filerFields={filerFields}
                    setSort={setSort}
                    setActivePage={setActivePage}
                    showDeleteModal={showDeleteModal}
                    setIdForDelete={setIdForDelete}
                    changeFilter={changeFilter}
                    fetchEstimates={fetchEstimates}
                    showNewEstimateModal={showNewEstimateModal}
                />
                <DeleteModal
                    idForDelete={idForDelete}
                    isDeleting={isDeleting}
                    deleteEstimate={deleteEstimate}
                    hideDeleteModal={hideDeleteModal}
                    setIdForDelete={setIdForDelete}
                />
            </div>
            <NewEstimateModal
                history={props.history}
                estimateType={estimateType}
                customerId={customerId}
                customers={customers}
                salesReps={salesReps}
                fetchingInformation={fetchingInformation}
                setEstimateType={setEstimateType}
                setCustomerId={setCustomerId}
                hideNewEstimateModal={hideNewEstimateModal}
                setEstimateField={setEstimateField}
            />
        </PqtLayout>
    );
};

export default Estimates;
