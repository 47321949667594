import axios from 'axios';
import * as types from './ApRecords.types';
import * as selectors from './ApRecords.selectors';
import * as dialog from '../common/dialog';
import { validateAuthInResponse, showDataDeletedNotification, showNotification, empty } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchRecords = () => (dispatch, getState) => {
    const sort = selectors.getSort(getState());
    const pagination = selectors.getPagination(getState());
    const filerFields = selectors.getFilterFields(getState());

    let params = [];
    params.push('sort=' + (sort.type === 'asc' ? '' : '-') + sort.column);
    params.push('active-page=' + pagination.activePage);
    params.push('elements-per-page=' + pagination.elementsPerPage);

    if (filerFields.length > 0) {
        let filterElements = [];
        for (let i = 0; i < filerFields.length; i++) {
            filterElements.push(filerFields[i]['name'] + '=' + filerFields[i]['value']);
        }
        params.push('filter=' + filterElements.join(','))
    } else {
        params.push('filter=-')
    }

    dispatch({ type: types.FETCH_RECORDS, payload: null });
    axios.get(apiUrl + '/ap-records' + (params.length === 0 ? '' : '?' + params.join('&')),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_RECORDS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_RECORDS_ERROR));
        });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null
    });
}

export const deleteRecord = (id, comments) => (dispatch) => {
    dispatch({ type: types.DELETE_RECORD, payload: null });
    axios.delete(apiUrl + '/ap-records/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }, data: { comments } }
    ).then(() => {
        dispatch({ type: types.DELETE_RECORD_SUCCESS, payload: null });
        showDataDeletedNotification();
        dispatch(fetchRecords());
        dispatch(dialog.actions.hideDialog('delete-modal'));
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.DELETE_RECORD_ERROR));
    });
}

export const setSort = (column, type) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_SORT,
            payload: {
                column: column,
                type: type,
            }
        });
        resolve();
    }).then(() => {
        dispatch(fetchRecords());
    });
}

export const setActivePage = (value) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_ACTIVE_PAGE,
            payload: value
        });
        resolve();
    }).then(() => {
        dispatch(fetchRecords());
    });
}

export const mountComponent = () => (dispatch, getState) => {
    new Promise((resolve) => {
        resolve();
    }).then(() => {
        dispatch(fetchRecords());
    });
}

export const setIdForDelete = (id) => (dispatch) => {
    dispatch({
        type: types.SET_ID_FOR_DELETE,
        payload: id
    });
}

export const setField = (field, value) => (dispatch) => {
    dispatch({
        type: types.SET_FIELD,
        payload: { field, value }
    });
}

export const changeFilter = (name, value, fetch = false) => (dispatch) => {
    new Promise((resolve) => {
        dispatch({
            type: types.CHANGE_FILTER,
            payload: { name, value }
        });
        resolve();
    }).then(() => {
        if (fetch) {
            dispatch(fetchRecords());
        }
    });
}

export const clearForm = () => (dispatch) => {
    dispatch({
        type: types.CLEAR_FORM,
        payload: null,
    });
}

export const fetchRecord = (id) => (dispatch, getState) => {
    dispatch({ type: types.FETCH_RECORD, payload: null });
    axios.get(apiUrl + '/ap-record/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_RECORD_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_RECORD_ERROR));
        });
}

export const saveRecord = () => (dispatch, getState) => {
    const fields = selectors.getFields(getState());

    let method = 'post';
    let endpoint = apiUrl + '/ap-records';
    if (!empty(fields.id)) {
        method = 'put';
        endpoint = apiUrl + '/ap-records/' + fields.id;
    }

    dispatch({ type: types.SAVE_RECORD, payload: null });
    axios({
        method: method,
        url: endpoint,
        data: fields,
        headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
    }).then((response) => {
        dispatch({ type: types.SAVE_RECORD_SUCCESS, payload: response.data });
        showNotification('Record Saved', 'The record has been saved successfully', 'success');
        dispatch(fetchRecords());
        dispatch(dialog.actions.hideDialog('ap-record-form-modal'));
        dispatch(clearForm());
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.SAVE_RECORD_ERROR));
    });
}