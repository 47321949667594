import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Reports from './Reports';
import DeleteModal from './DeleteModal';
import FormModal from './FormModal';
import ResultModal from './ResultModal';
import * as actions from './HrpPayrollReports.actions';
import * as selectors from './HrpPayrollReports.selectors';
import './HrpPayrollReports.scss';
import PqtLayout from './../PqtLayout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import * as dialog from '../common/dialog';
import * as loginSelectors from './../Login/Login.selectors';
import { Redirect } from 'react-router-dom';

const HrpPayrollReports = (props) => {
    /* State to props */
    const fetchingReports = useSelector(selectors.getFetchingReports);
    const reports = useSelector(selectors.getReports, stringifyEqualityCheck);
    const pagination = useSelector(selectors.getPagination, stringifyEqualityCheck);
    const sort = useSelector(selectors.getSort, stringifyEqualityCheck);
    const idForDelete = useSelector(selectors.getIdForDelete, stringifyEqualityCheck);
    const isDeleting = useSelector(selectors.getIsDeleting, stringifyEqualityCheck);
    const filerFields = useSelector(selectors.getFilterFields, stringifyEqualityCheck);
    const uploadingFile = useSelector(selectors.getUploadingFile, stringifyEqualityCheck);
    const fields = useSelector(selectors.getFields, stringifyEqualityCheck);
    const fetchingReport = useSelector(selectors.getFetchingReport, stringifyEqualityCheck);
    const savingMemo = useSelector(selectors.getSavingMemo);
    const pushingToQb = useSelector(selectors.getPushingToQb);
    const file = useSelector(selectors.getFile, stringifyEqualityCheck);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const deleteReport = useCallback((id, comments) => dispatch(actions.deleteReport(id, comments)), [dispatch]);
    const setSort = useCallback((column, type) => dispatch(actions.setSort(column, type)), [dispatch]);
    const setActivePage = useCallback((name, value) => dispatch(actions.setActivePage(name, value)), [dispatch]);
    const fetchReports = useCallback((options, isWsUpdate) => dispatch(actions.fetchReports(options, isWsUpdate)), [dispatch]);
    const setIdForDelete = useCallback((id) => dispatch(actions.setIdForDelete(id)), [dispatch]);
    const changeFilter = useCallback((name, value, fetch) => dispatch(actions.changeFilter(name, value, fetch)), [dispatch]);
    const uploadFile = useCallback(() => dispatch(actions.uploadFile()), [dispatch]);
    const downloadReport = useCallback((id, type) => dispatch(actions.downloadReport(id, type)), [dispatch]);
    const setField = useCallback((field, value) => dispatch(actions.setField(field, value)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);
    const fetchReport = useCallback((id) => dispatch(actions.fetchReport(id)), [dispatch]);
    const clearForm = useCallback(() => dispatch(actions.clearForm()), [dispatch]);
    const saveMemo = useCallback(() => dispatch(actions.saveMemo()), [dispatch]);
    const pushToQb = useCallback(() => dispatch(actions.pushToQb()), [dispatch]);
    const setFile = useCallback((file) => dispatch(actions.setFile(file)), [dispatch]);
    const showDeleteModal = useCallback(() => dispatch(dialog.actions.showDialog('delete-modal')), [dispatch]);
    const hideDeleteModal = useCallback(() => dispatch(dialog.actions.hideDialog('delete-modal')), [dispatch]);
    const showFormModal = useCallback(() => dispatch(dialog.actions.showDialog('payroll-form-modal')), [dispatch]);
    const hideFormModal = useCallback(() => dispatch(dialog.actions.hideDialog('payroll-form-modal')), [dispatch]);
    const showResultModal = useCallback(() => dispatch(dialog.actions.showDialog('payroll-result-modal')), [dispatch]);
    const hideResultModal = useCallback(() => dispatch(dialog.actions.hideDialog('payroll-result-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        dispatch(actions.mountComponent());
        return () => {
            resetState();
        };
    }, [dispatch, resetState]);

    if (user != null && ![61, 245, 401, 2419].includes(parseInt(user.id))) {     // Hugh (61), Nichole (245), Sandy (401), Stephanie (2419)
        return <Redirect to="/dashboard" />;
    }

    return (
        <PqtLayout title="HRP Payroll Reports" history={props.history}>
            <Helmet><title>HRP Payroll Reports - Stagecoach</title></Helmet>
            <div className="hrp-payroll-reports-container">
                <Reports
                    reports={reports}
                    pagination={pagination}
                    fetchingReports={fetchingReports}
                    history={props.history}
                    sort={sort}
                    user={user}
                    filerFields={filerFields}
                    uploadingFile={uploadingFile}
                    setSort={setSort}
                    setActivePage={setActivePage}
                    showDeleteModal={showDeleteModal}
                    setIdForDelete={setIdForDelete}
                    changeFilter={changeFilter}
                    fetchReports={fetchReports}
                    uploadFile={uploadFile}
                    downloadReport={downloadReport}
                    showFormModal={showFormModal}
                    fetchReport={fetchReport}
                    showResultModal={showResultModal}
                />
                <DeleteModal
                    idForDelete={idForDelete}
                    isDeleting={isDeleting}
                    deleteReport={deleteReport}
                    hideDeleteModal={hideDeleteModal}
                    setIdForDelete={setIdForDelete}
                />
                <FormModal
                    uploadingFile={uploadingFile}
                    fields={fields}
                    fetchingReport={fetchingReport}
                    file={file}
                    uploadFile={uploadFile}
                    hideFormModal={hideFormModal}
                    setField={setField}
                    clearForm={clearForm}
                    setFile={setFile}
                />
                <ResultModal
                    fields={fields}
                    fetchingReport={fetchingReport}
                    savingMemo={savingMemo}
                    pushingToQb={pushingToQb}
                    setField={setField}
                    hideResultModal={hideResultModal}
                    saveMemo={saveMemo}
                    clearForm={clearForm}
                    pushToQb={pushToQb}
                />
            </div>
        </PqtLayout>
    );
};

export default HrpPayrollReports;
