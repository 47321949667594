import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Jobs from './Jobs';
import * as actions from './DeletedJobs.actions';
import * as selectors from './DeletedJobs.selectors';
import './DeletedJobs.scss';
import PqtLayout from './../PqtLayout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import * as loginSelectors from './../Login/Login.selectors';
import { Redirect } from 'react-router-dom';

const DeletedJobs = (props) => {
    /* State to props */
    const fetching = useSelector(selectors.getFetching);
    const jobs = useSelector(selectors.getJobs, stringifyEqualityCheck);
    const pagination = useSelector(selectors.getPagination, stringifyEqualityCheck);
    const filerFields = useSelector(selectors.getFilterFields, stringifyEqualityCheck);
    const sort = useSelector(selectors.getSort, stringifyEqualityCheck);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const setActivePage = useCallback((name, value) => dispatch(actions.setActivePage(name, value)), [dispatch]);
    const setSort = useCallback((column, type) => dispatch(actions.setSort(column, type)), [dispatch]);
    const changeFilter = useCallback((name, value, fetch) => dispatch(actions.changeFilter(name, value, fetch)), [dispatch]);
    const fetchDeletedJobs = useCallback(() => dispatch(actions.fetchDeletedJobs()), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        fetchDeletedJobs();
    }, [fetchDeletedJobs]);

    if (user == null && parseInt(user.id) !== 61) {     // Hugh
        return <Redirect to="/dashboard" />;
    }

    return (
        <PqtLayout title="Deleted Jobs" history={props.history}>
            <Helmet><title>Deleted Jobs - Stagecoach</title></Helmet>
            <div className="deleted-jobs-container">
                <Jobs
                    jobs={jobs}
                    pagination={pagination}
                    fetching={fetching}
                    filerFields={filerFields}
                    sort={sort}
                    history={props.history}
                    user={user}
                    setActivePage={setActivePage}
                    fetchDeletedJobs={fetchDeletedJobs}
                    setSort={setSort}
                    changeFilter={changeFilter}
                />
            </div>
        </PqtLayout>
    );
};

export default DeletedJobs;
