import React, { Fragment } from 'react';
import FormPopover from './FormPopover';
import moment from 'moment-timezone';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { useDropzone } from 'react-dropzone';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';

const InformationForm = React.memo((props) => {
    const {
        // acceptedFiles,
        // fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        multiple: true,
        onDrop: (files) => {
            for (let i = 0; i < files.length; i++) {
                props.addFile(files[i]);
            }
        }
    });

    // <option value="1">Print Only</option>
    // <option value="2">Mail Only</option>
    // <option value="3">Print and Mail</option>
    // <option value="4">Indoor</option>
    // <option value="5">Promotional Products</option>
    // <option value="6">Artwork Only</option>
    // <option value="7">Self-Cover</option>
    // <option value="8">Plus-Cover</option>
    // <option value="9">Outdoor</option>
    // <option value="10">Mail Only</option>
    let processRequestOptions = [];
    if ([1, 3, 6, 7, 13, 15].includes(parseInt(props.fields.product_type))) {
        processRequestOptions.push(<option value="1">Print Only</option>);
        processRequestOptions.push(<option value="2">Mail Only</option>);
        processRequestOptions.push(<option value="3">Print and Mail</option>);
        processRequestOptions.push(<option value="7">Self-Cover</option>);
        processRequestOptions.push(<option value="8">Plus-Cover</option>);
    } else if ([18].includes(parseInt(props.fields.product_type))) {
        processRequestOptions.push(<option value="1">Print Only</option>);
        processRequestOptions.push(<option value="2">Mail Only</option>);
        processRequestOptions.push(<option value="3">Print and Mail</option>);
    } else if ([26].includes(parseInt(props.fields.product_type))) {
        processRequestOptions.push(<option value="4">Indoor</option>);
        processRequestOptions.push(<option value="9">Outdoor</option>);
    } else if ([27].includes(parseInt(props.fields.product_type))) {
        processRequestOptions.push(<option value="5">Promotional Products</option>);
    } else if ([2, 4, 5, 8, 9, 10, 11, 12, 14, 16, 17, 19, 20, 21, 22, 23, 24, 25].includes(parseInt(props.fields.product_type))) {
        processRequestOptions.push(<option value="1">Print Only</option>);
        processRequestOptions.push(<option value="2">Mail Only</option>);
        processRequestOptions.push(<option value="3">Print and Mail</option>);
    } else {
        processRequestOptions.push(<option value="1">Print Only</option>);
        processRequestOptions.push(<option value="2">Mail Only</option>);
        processRequestOptions.push(<option value="3">Print and Mail</option>);
    }

    let customerOptions = [];
    for (let i = 0; i < props.information.customers.length; i++) {
        customerOptions.push(<option value={props.information.customers[i].id}>{props.information.customers[i].display_name}</option>);
    }

    let csrsOptions = [];
    for (let i = 0; i < props.information.csrs.length; i++) {
        csrsOptions.push(<option value={props.information.csrs[i].id}>{props.information.csrs[i].name}</option>);
    }

    let productTypesOptions = [];
    for (let i = 0; i < props.information.productTypes.length; i++) {
        productTypesOptions.push(<option value={props.information.productTypes[i].id}>{props.information.productTypes[i].name}</option>);
    }

    const setDateFieldHandler = (name, event) => {
        let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');
        props.setField(name, value);
    };

    let mailCheckboxes = null;
    if (parseInt(props.fields.process_request) !== 1) {
        mailCheckboxes = (
            <Fragment>
                <div className="check-field">
                    <input type="checkbox" checked={props.fields.data_processing} onChange={props.handleSetField.bind(this, 'data_processing')} /> Data Processing
                </div>
                <div className="check-field">
                    <input type="checkbox" checked={props.fields.mailing_services} onChange={props.handleSetField.bind(this, 'mailing_services')} /> Mailing Services
                </div>
                <div className="check-field">
                    <input type="checkbox" checked={props.fields.handwork} onChange={props.handleSetField.bind(this, 'handwork')} /> Handwork
                </div>
            </Fragment>
        );
    }

    let addressFieldDisabled = false;
    if (parseInt(props.fields.shipping_to_type) === 1 || parseInt(props.fields.shipping_to_type) === 3) {
        addressFieldDisabled = true;
    } else if (parseInt(props.fields.sales_to_deliver)) {
        addressFieldDisabled = true;
    }

    const renderFiles = () => {
        if (props.files.length === 0) {
            return (
                <tr>
                    <td colSpan="2">No files selected</td>
                </tr>
            );
        }

        return props.files.map((file, index) => {
            return (
                <tr>
                    <td>
                        <a href={file.url} target="_blank" rel="noopener noreferrer">{file.name}</a>
                    </td>
                    <td className="text-center actions">
                        <Tooltip title="Remove File" position="bottom" arrow size="small" className="action-tooltip">
                            <i className="fas fa-trash" onClick={props.removeFile.bind(this, index)} />
                        </Tooltip>
                    </td>
                </tr>
            );
        });
    };

    let uploadingFiles = null;
    if (props.saving && props.files.length > 0) {
        uploadingFiles = <div><i className="fas fa-circle-notch fa-spin" /> Your files are uploading. It may take a few seconds depending on the size of the file.</div>;
    }

    return (
        <div className="information-form-container">
            <div className="line1">
                <div className="field customer-field">
                    <b>Customer <span className="required">*</span>:</b>
                    <select value={props.fields.customer_id} onChange={props.handleSetField.bind(this, 'customer_id')}>
                        <option value="">Select option</option>
                        {customerOptions}
                    </select>
                </div>
                <div className="field csr-field">
                    <b>CSR <span className="required">*</span>:</b>
                    <select value={props.fields.csr_id} onChange={props.handleSetField.bind(this, 'csr_id')}>
                        <option value="">Select option</option>
                        {csrsOptions}
                    </select>
                </div>
                <div className="field due-date-field">
                    <b>Due Date:</b>
                    <DayPickerInput
                        formatDate={formatDate}
                        parseDate={parseDate}
                        placeholder=""
                        className="date-picker-field"
                        onDayChange={setDateFieldHandler.bind(this, 'due_date')}
                        value={props.fields.due_date == null || props.fields.due_date === '0000-00-00' ? '' : moment(props.fields.due_date).format('MM/DD/YYYY')}
                    />
                </div>
                <div className="field product-type-field">
                    <b>Product Type <span className="required">*</span>:</b>
                    <FormPopover
                        code="INFO-001"
                        information={props.information}
                    />
                    <select value={props.fields.product_type} onChange={props.handleSetField.bind(this, 'product_type')}>
                        <option value="">Select option</option>
                        {productTypesOptions}
                    </select>
                </div>
                <div className="field process-request-field">
                    <b>Process Request <span className="required">*</span>:</b>
                    <FormPopover
                        code="INFO-002"
                        information={props.information}
                    />
                    <select value={props.fields.process_request} onChange={props.handleSetField.bind(this, 'process_request')}>
                        <option value="">Select option</option>
                        {processRequestOptions}
                    </select>
                </div>
                <div className="field artwork-required-field">
                    <b>Artwork Required <span className="required">*</span>:</b>
                    <FormPopover
                        code="INFO-003"
                        information={props.information}
                    />
                    <select value={props.fields.artwork_required} onChange={props.handleSetField.bind(this, 'artwork_required')}>
                        <option value="">Select option</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                </div>
            </div>
            <div className="line2">
                <div className="column1">
                    <div className="title">
                        <b>Quote Requirements <span className="required">*</span>:</b>
                        <FormPopover
                            code="INFO-004"
                            information={props.information}
                        />
                    </div>
                    <div className="checkboxes">
                        <div className="check-field">
                            <input type="checkbox" checked={props.fields.simple_flat} onChange={props.handleSetField.bind(this, 'simple_flat')} /> Simple Flat
                        </div>
                        <div className="check-field">
                            <input type="checkbox" checked={props.fields.self_cover} onChange={props.handleSetField.bind(this, 'self_cover')} /> Self-Cover
                        </div>
                        <div className="check-field">
                            <input type="checkbox" checked={props.fields.plus_cover} onChange={props.handleSetField.bind(this, 'plus_cover')} /> Plus-Cover
                        </div>
                        <div className="check-field">
                            <input type="checkbox" checked={props.fields.envelopes} onChange={props.handleSetField.bind(this, 'envelopes')} /> Envelopes
                        </div>
                        <div className="check-field">
                            <input type="checkbox" checked={props.fields.bindery} onChange={props.handleSetField.bind(this, 'bindery')} /> Bindery
                        </div>
                        {mailCheckboxes}
                        {/* <div className="check-field">
                            <input type="checkbox" checked={props.fields.fulfillment} onChange={props.handleSetField.bind(this, 'fulfillment')} /> Fulfillment
                        </div> */}
                        <div className="check-field">
                            <input type="checkbox" checked={props.fields.large_format} onChange={props.handleSetField.bind(this, 'large_format')} /> Large Format
                        </div>
                        {/* <div className="check-field">
                            <input type="checkbox" checked={props.fields.promotional} onChange={props.handleSetField.bind(this, 'promotional')} /> Promotional
                        </div> */}
                        <div className="check-field">
                            <input type="checkbox" checked={props.fields.pocket_folder} onChange={props.handleSetField.bind(this, 'pocket_folder')} /> Pocket Folder
                        </div>
                        <div className="check-field">
                            <input type="checkbox" checked={props.fields.is_ncr} onChange={props.handleSetField.bind(this, 'is_ncr')} /> NCR
                        </div>
                        <div className="check-field">
                            <input type="checkbox" checked={props.fields.is_notepads} onChange={props.handleSetField.bind(this, 'is_notepads')} /> Notepads
                        </div>
                    </div>
                </div>
                <div className="column2">
                    <div className="title"><b>Quote Type <span className="required">*</span>:</b>
                        <FormPopover
                            code="INFO-005"
                            information={props.information}
                        />
                    </div>
                    <div className="checkboxes">
                        <div className="check-field">
                            <input
                                type="radio"
                                name="quote_type"
                                value="1"
                                checked={parseInt(props.fields.quote_type) === 1}
                                onChange={props.handleSetField.bind(this, 'quote_type')}
                            /> New
                        </div>
                        <div className="check-field">
                            <input
                                type="radio"
                                name="quote_type"
                                value="2"
                                checked={parseInt(props.fields.quote_type) === 2}
                                onChange={props.handleSetField.bind(this, 'quote_type')}
                            /> Daily
                        </div>
                        <div className="check-field repeat-field">
                            <input
                                type="radio"
                                name="quote_type"
                                value="3"
                                checked={parseInt(props.fields.quote_type) === 3}
                                onChange={props.handleSetField.bind(this, 'quote_type')}
                            /> Repeat Stagecoach <input type="text" value={props.fields.sc_number} onChange={props.handleSetField.bind(this, 'sc_number')} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="line3">
                <div className="column1">
                    <b>Description Title <span className="required">*</span>:
                        <FormPopover
                            code="INFO-006"
                            information={props.information}
                        />
                    </b>
                    <input type="text" value={props.fields.description_title} onChange={props.handleSetField.bind(this, 'description_title')} />
                </div>
                <div className="column2">
                    <div className="quantity-field">
                        <b>Quantity <span className="required">*</span>:</b>
                        <input type="number" value={props.fields.quantity1} onChange={props.handleSetField.bind(this, 'quantity1')} />
                    </div>
                    <div className="quantity-field">
                        <b>Quantity:</b>
                        <input type="number" value={props.fields.quantity2} onChange={props.handleSetField.bind(this, 'quantity2')} />
                    </div>
                    <div className="quantity-field">
                        <b>Quantity:</b>
                        <input type="number" value={props.fields.quantity3} onChange={props.handleSetField.bind(this, 'quantity3')} />
                    </div>
                </div>
            </div>
            <div className="line4">
                <div className="column1">
                    <b>Please describe your project in detail here:</b>
                    <textarea
                        placeholder="Please let us know more about your project detail."
                        value={props.fields.information_description}
                        onChange={props.handleSetField.bind(this, 'information_description')}
                    />
                </div>
                <div className="files-container">
                    <h3>
                        Attached Files
                        <div {...getRootProps({ className: 'dropzone-container' })}>
                            <input {...getInputProps()} />
                            <button className="browse-files-button">Browse</button>
                        </div>
                    </h3>
                    <table className="table table-sm table-hover">
                        <thead>
                            <tr>
                                <th>Filename</th>
                                <th className="actions"></th>
                            </tr>
                        </thead>
                        <tbody>{renderFiles()}</tbody>
                    </table>
                    {uploadingFiles}
                </div>
                <div className="column2">
                    <div className="title">
                        <div><b>Shipping to <span className="required">*</span>:</b></div>
                        <div>
                            <input
                                type="checkbox"
                                checked={props.fields.sales_to_deliver}
                                onChange={props.handleSetField.bind(this, 'sales_to_deliver')}
                            /> Sales to deliver
                        </div>
                    </div>
                    <div className="options">
                        <div className="radio-field">
                            <input
                                type="radio"
                                name="shipping_to_type"
                                value="1"
                                checked={parseInt(props.fields.shipping_to_type) === 1}
                                onChange={props.handleSetField.bind(this, 'shipping_to_type')}
                            /> Client address
                        </div>
                        <div className="radio-field">
                            <input
                                type="radio"
                                name="shipping_to_type"
                                value="2"
                                checked={parseInt(props.fields.shipping_to_type) === 2}
                                onChange={props.handleSetField.bind(this, 'shipping_to_type')}
                            /> Other
                        </div>
                        <div className="radio-field">
                            <input
                                type="radio"
                                name="shipping_to_type"
                                value="3"
                                checked={parseInt(props.fields.shipping_to_type) === 3}
                                onChange={props.handleSetField.bind(this, 'shipping_to_type')}
                            /> Will call pickup
                        </div>
                    </div>
                    <textarea
                        value={props.fields.address}
                        onChange={props.handleSetField.bind(this, 'address')}
                        placeholder="Enter address"
                        disabled={addressFieldDisabled}
                    />
                </div>
            </div>
            {props.buttonsContainer}
        </div>
    );
});

export default InformationForm;
