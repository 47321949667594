import { createSelector } from 'reselect';

export const getComponentState = state => state.quoteRequest;

export const getFetchingRequest = createSelector(
    getComponentState,
    (state) => state.fetchingRequest
);
export const getActiveTab = createSelector(
    getComponentState,
    (state) => state.activeTab
);
export const getFields = createSelector(
    getComponentState,
    (state) => state.fields
);
export const getFetchingInformation = createSelector(
    getComponentState,
    (state) => state.fetchingInformation
);
export const getInformation = createSelector(
    getComponentState,
    (state) => state.information
);
export const getDataProcessingActiveTab = createSelector(
    getComponentState,
    (state) => state.dataProcessingActiveTab
);
export const getDataProcessingItems = createSelector(
    getComponentState,
    (state) => state.dataProcessingItems
);
export const getVariablePrintItems = createSelector(
    getComponentState,
    (state) => state.variablePrintItems
);
export const getSelectedDataProcessingItems = createSelector(
    getComponentState,
    (state) => state.selectedDataProcessingItems
);
export const getMailActiveTab = createSelector(
    getComponentState,
    (state) => state.mailActiveTab
);
export const getInkjetItems = createSelector(
    getComponentState,
    (state) => state.inkjetItems
);
export const getInsertingItems = createSelector(
    getComponentState,
    (state) => state.insertingItems
);
export const getMeteringItems = createSelector(
    getComponentState,
    (state) => state.meteringItems
);
export const getSelectedMailItems = createSelector(
    getComponentState,
    (state) => state.selectedMailItems
);
export const getHandworkItems = createSelector(
    getComponentState,
    (state) => state.handworkItems
);
export const getSelectedHandworkItems = createSelector(
    getComponentState,
    (state) => state.selectedHandworkItems
);
export const getSelectedLargeFormateProducts = createSelector(
    getComponentState,
    (state) => state.selectedLargeFormateProducts
);
export const getSelectedSubstrates = createSelector(
    getComponentState,
    (state) => state.selectedSubstrates
);
export const getSelectedFinishingElements = createSelector(
    getComponentState,
    (state) => state.selectedFinishingElements
);
export const getSaving = createSelector(
    getComponentState,
    (state) => state.saving
);
export const getSubmitting = createSelector(
    getComponentState,
    (state) => state.submitting
);
export const getId = createSelector(
    getComponentState,
    (state) => state.id
);
export const getHistory = createSelector(
    getComponentState,
    (state) => state.history
);
export const getFetchingSubstrateAccessories = createSelector(
    getComponentState,
    (state) => state.fetchingSubstrateAccessories
);
export const getDownloadingEstimate = createSelector(
    getComponentState,
    (state) => state.downloadingEstimate
);
export const getFiles = createSelector(
    getComponentState,
    (state) => state.files
);
export const getMainPartId = createSelector(
    getComponentState,
    (state) => state.mainPartId
);
export const getMainPart = createSelector(
    getComponentState,
    (state) => state.mainPart
);
export const getFetchingMainPart = createSelector(
    getComponentState,
    (state) => state.fetchingMainPart
);
export const getIdForDelete = createSelector(
    getComponentState,
    (state) => state.delete.id
);
export const getIsDeleting = createSelector(
    getComponentState,
    (state) => state.delete.isDeleting
);
export const getIsDuplicatingPart = createSelector(
    getComponentState,
    (state) => state.isDuplicatingPart
);