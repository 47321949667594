import axios from 'axios';
import * as types from './MailingMatrixes.types';
import * as selectors from './MailingMatrixes.selectors';
import * as dialog from '../common/dialog';
import { validateAuthInResponse, showDataDeletedNotification, empty, showNotification } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchMatrixes = () => (dispatch, getState) => {
    const sort = selectors.getSort(getState());
    const pagination = selectors.getPagination(getState());
    const filerFields = selectors.getFilterFields(getState());
    const subcategoryId = selectors.getSubcategoryId(getState());

    let params = [];
    params.push('sort=' + (sort.type === 'asc' ? '' : '-') + sort.column);
    params.push('active-page=' + pagination.activePage);
    params.push('elements-per-page=' + pagination.elementsPerPage);
    params.push('subcategory-id=' + subcategoryId);

    if (filerFields.length > 0) {
        let filterElements = [];
        for (let i = 0; i < filerFields.length; i++) {
            filterElements.push(filerFields[i]['name'] + '=' + filerFields[i]['value']);
        }
        params.push('filter=' + filterElements.join(','))
    } else {
        params.push('filter=-')
    }

    dispatch({ type: types.FETCH_MATRIXES, payload: null });
    axios.get(apiUrl + '/mailing-matrix' + (params.length === 0 ? '' : '?' + params.join('&')),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_MATRIXES_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_MATRIXES_ERROR));
        });
}

export const fetchSubcategory = (id) => (dispatch, getState) => {
    dispatch({ type: types.FETCH_SUBCATEGORY, payload: null });
    axios.get(apiUrl + '/mailing-subcategories/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_SUBCATEGORY_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_SUBCATEGORY_ERROR));
        });
}

export const deleteMatrix = (id) => (dispatch) => {
    dispatch({ type: types.DELETE_MATRIX, payload: null });
    axios.delete(apiUrl + '/mailing-matrix/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } }
    ).then(() => {
        dispatch({ type: types.DELETE_MATRIX_SUCCESS, payload: null });
        showDataDeletedNotification();
        dispatch(fetchMatrixes());
        dispatch(dialog.actions.hideDialog('delete-modal'));
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.DELETE_MATRIX_ERROR));
    });
}

export const saveMatrix = () => (dispatch, getState) => {
    const matrix = selectors.getMatrix(getState());
    const matrixData = selectors.getMatrixData(getState());
    const subcategoryId = selectors.getSubcategoryId(getState());
    const subcategory = selectors.getSubcategory(getState());

    if (empty(matrix.name)) {
        return showNotification('Complete Information', 'Enter the Title', 'info');
    }
    if (empty(matrix.minimum)) {
        return showNotification('Complete Information', 'Enter the Minimum', 'info');
    }
    if (empty(matrix.code)) {
        return showNotification('Complete Information', 'Enter the Code', 'info');
    }

    const baseCalculationType = parseInt(subcategory.category_base_calculation_type);
    if (baseCalculationType === 1) {    // No base calculation
        if (empty(matrix.base)) {
            return showNotification('Complete Information', 'Enter the Base', 'info');
        }
    } else if (baseCalculationType === 2) {    // Labor/Speed
        if (empty(matrix.labor)) {
            return showNotification('Complete Information', 'Enter the Labor', 'info');
        }
        if (empty(matrix.speed)) {
            return showNotification('Complete Information', 'Enter the Speed', 'info');
        }
    } else if (baseCalculationType === 3) {    // Labor/Speed + Setup
        if (empty(matrix.labor)) {
            return showNotification('Complete Information', 'Enter the Labor', 'info');
        }
        if (empty(matrix.speed)) {
            return showNotification('Complete Information', 'Enter the Speed', 'info');
        }
        if (empty(matrix.setup_time)) {
            return showNotification('Complete Information', 'Enter the Setup Hrs', 'info');
        }
        if (empty(matrix.setup_cost)) {
            return showNotification('Complete Information', 'Enter the Setup Cost', 'info');
        }
    }

    let endpoint = apiUrl + '/mailing-matrix';
    let method = 'post';
    if (matrix.id && matrix.id != null) {
        endpoint = apiUrl + '/mailing-matrix/' + matrix.id;
        method = 'put';
    }

    matrix.mailing_subcategory_id = subcategoryId;
    matrix.matrixData = matrixData;

    dispatch({ type: types.SAVE_MATRIX, payload: null });
    axios({
        method: method,
        url: endpoint,
        data: matrix,
        headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
    }).then((response) => {
        dispatch({ type: types.SAVE_MATRIX_SUCCESS, payload: response.data });
        showNotification('Pricing Saved', 'The Pricing has been saved successfully', 'success');
        dispatch(fetchMatrixes());
        dispatch(dialog.actions.hideDialog('matrix-form-modal'));
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.SAVE_MATRIX_ERROR));
    });
}

export const setSort = (column, type) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_SORT,
            payload: {
                column: column,
                type: type,
            }
        });
        resolve();
    }).then(() => {
        dispatch(fetchMatrixes());
    });
}

export const setActivePage = (value) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_ACTIVE_PAGE,
            payload: value
        });
        resolve();
    }).then(() => {
        dispatch(fetchMatrixes());
    });
}

export const mountComponent = () => (dispatch) => {
    dispatch(resetState());
    dispatch(fetchMatrixes());
}

export const setIdForDelete = (id) => (dispatch) => {
    dispatch({
        type: types.SET_ID_FOR_DELETE,
        payload: id
    });
}

export const changeFilter = (name, value) => (dispatch) => {
    new Promise((resolve) => {
        dispatch({
            type: types.CHANGE_FILTER,
            payload: { name, value },
        });
        resolve();
    }).then(() => {
        dispatch(fetchMatrixes());
    });
}

export const setField = (field, value) => (dispatch) => {
    dispatch({
        type: types.SET_FIELD,
        payload: { field, value },
    });
}

export const setMatrix = (value) => (dispatch) => {
    dispatch({
        type: types.SET_MATRIX,
        payload: value,
    });
}

export const setSubcategoryId = (value) => (dispatch) => {
    dispatch({
        type: types.SET_SUBCATEGORY_ID,
        payload: value,
    });
}

export const addMatrixRow = () => (dispatch) => {
    dispatch({
        type: types.ADD_MATRIX_ROW,
        payload: null,
    });
}

export const removeMatrixRow = (index) => (dispatch) => {
    dispatch({
        type: types.REMOVE_MATRIX_ROW,
        payload: index,
    });
}

export const setMatrixField = (index, field, value) => (dispatch, getState) => {
    const matrix = selectors.getMatrix(getState());

    dispatch({
        type: types.SET_MATRIX_FIELD,
        payload: { index, field, value, base: matrix.base },
    });
}

export const resetMatrix = () => (dispatch) => {
    dispatch({
        type: types.RESET_MATRIX,
        payload: null,
    });
}

export const setMatrixData = (value) => (dispatch) => {
    dispatch({
        type: types.SET_MATRIX_DATA,
        payload: value,
    });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null,
    });
}