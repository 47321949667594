import React from 'react';
//import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';

const QuickSearch = React.memo((props) => {
    let csrOptions = [<option value="" key="csr-option-empty">All</option>];
    for (let i = 0; i < props.csrsInformation.length; i++) {
        let name = props.csrsInformation[i].name;
        const nameParts = name.split(' ');
        if (nameParts.length > 0) {
            name = nameParts[0];
        }

        csrOptions.push(<option value={props.csrsInformation[i].id} key={'csr-option-' + i}>{name}</option>);
    }

    let saleRepsOptions = [<option value="" key={'sales-rep-option-empty'}>All</option>];
    for (let i = 0; i < props.salesRepsInformation.length; i++) {
        let name = props.salesRepsInformation[i].name;
        const nameParts = name.split(' ');
        if (nameParts.length > 0) {
            name = nameParts[0];
        }

        saleRepsOptions.push(<option value={props.salesRepsInformation[i].id} key={'sales-rep-option-' + i}>{name}</option>);
    }

    const setFieldHandler = (name, event) => {
        props.setSearchField(name, event.target.value);
    }

    const renderTextInput = name => (
        <input type="text" name={name} value={props.setSearchField[name]} onChange={setFieldHandler.bind(this, name)} />
    );

    const renderSelect = (name, options) => (
        <select name={name} value={props.setSearchField[name]} onChange={setFieldHandler.bind(this, name)}>
            {options}
        </select>
    );

    const searchHandler = name => {
        props.fetchData({
            fetchJobs: 1,
        });
    }

    const setDateFieldHandler = (name, event) => {
        let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');
        props.setSearchField(name, value);
    }
    
    const renderDateInput = name => (
        <DayPickerInput
            formatDate={formatDate}
            parseDate={parseDate}
            placeholder=""
            className="date-picker-field"
            onDayChange={setDateFieldHandler.bind(this, name)}
            value={props.searchFields[name] === '' ? '' : moment(props.searchFields[name]).format('MM/DD/YYYY')}
        />
    );

    return (
        <div className="quick-search-container">
            <div className="title">Quick Search By</div>
            <div className="search-fields">
                <div className="search-field">
                    Stagecoach Number: {renderTextInput('jobNumber')} <i className="fas fa-search" onClick={searchHandler.bind(this, 'jobNumber')}></i>
                </div>
                <div className="search-field">
                    Date Enter: {renderDateInput('date')} <i className="fas fa-search" onClick={searchHandler.bind(this, 'date')}></i>
                </div>
                <div className="search-field">
                    CSR: {renderSelect('csr', csrOptions)} <i className="fas fa-search" onClick={searchHandler.bind(this, 'csr')}></i>
                </div>
                <div className="search-field">
                    Sales Rep: {renderSelect('salesRep', saleRepsOptions)} <i className="fas fa-search" onClick={searchHandler.bind(this, 'salesRep')}></i>
                </div>
            </div>
        </div>
    );
});

QuickSearch.propTypes = {

};

export default QuickSearch;
