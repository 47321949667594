import Immutable from 'immutable';
import * as types from './Users.types';

const initialState = {
    elements: [],
    fetchingElements: false,
    isLogging: false,
    filter: '',
    loggingId: false,
    pagination: {
        totalElements: 0,
        activePage: 1,
        totalPages: 0,
        elementsPerPage: 20,
    },
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_ELEMENTS:
            return state.setIn(['fetchingElements'], !action.payload).toJS();

        case types.FETCH_ELEMENTS_SUCCESS:
            return state.setIn(['fetchingElements'], false)
                .setIn(['elements'], action.payload.elements)
                .setIn(['pagination', 'totalElements'], action.payload.totalElements)
                .setIn(['pagination', 'activePage'], action.payload.activePage)
                .setIn(['pagination', 'totalPages'], action.payload.totalPages)
                .setIn(['pagination', 'elementsPerPage'], action.payload.elementsPerPage)
                .toJS();

        case types.FETCH_ELEMENTS_ERROR:
            return state.setIn(['fetchingElements'], false).toJS();

        case types.LOGIN:
            return state.setIn(['isLogging'], true).toJS();

        case types.LOGIN_SUCCESS:
            return state.setIn(['isLogging'], false).toJS();

        case types.LOGIN_ERROR:
            return state.setIn(['isLogging'], false).toJS();

        case types.SET_FILTER:
            return state.setIn(['filter'], false).toJS();

        case types.SET_LOGGING_ID:
            return state.setIn(['loggingId'], action.payload).toJS();

        case types.SET_ACTIVE_PAGE:
            return state.setIn(['pagination', 'activePage'], action.payload).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}