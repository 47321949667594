import Immutable from 'immutable';
import * as types from './HrpPayrollReports.types';

const initialState = {
    id: null,
    fetchingReports: false,
    reports: [],
    pagination: {
        totalElements: 0,
        activePage: 1,
        totalPages: 0,
        elementsPerPage: 15,
    },
    sort: {
        column: 'id',
        type: 'desc',
    },
    history: null,
    delete: {
        id: null,
        isDeleting: false,
    },
    filter: {
        fields: []
    },
    uploadingFile: false,
    fields: {},
    fetchingReport: false,
    savingMemo: false,
    pushingToQb: false,
    file: null,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_REPORTS:
            return state.setIn(['fetchingReports'], true).toJS();

        case types.FETCH_REPORTS_SUCCESS:
            return state.setIn(['fetchingReports'], false)
                .setIn(['reports'], action.payload.elements)
                .setIn(['pagination', 'totalElements'], action.payload.totalElements)
                .setIn(['pagination', 'activePage'], action.payload.activePage)
                .setIn(['pagination', 'totalPages'], action.payload.totalPages)
                .setIn(['pagination', 'elementsPerPage'], action.payload.elementsPerPage)
                .toJS();

        case types.FETCH_REPORTS_ERROR:
            return state.setIn(['fetchingReports'], false).toJS();

        case types.FETCH_REPORT:
            return state.setIn(['fetchingReport'], true).toJS();

        case types.FETCH_REPORT_SUCCESS:
            return state.setIn(['fetchingReport'], false)
                .setIn(['fields'], action.payload)
                .toJS();

        case types.FETCH_REPORT_ERROR:
            return state.setIn(['fetchingReport'], false).toJS();

        case types.SET_SORT:
            return state.setIn(['sort'], action.payload).toJS();

        case types.SET_ACTIVE_PAGE:
            return state.setIn(['pagination', 'activePage'], action.payload).toJS();

        case types.DELETE_REPORT:
            return state.setIn(['delete', 'isDeleting'], true).toJS();

        case types.DELETE_REPORT_SUCCESS:
            return state.setIn(['delete', 'isDeleting'], false)
                .setIn(['delete', 'id'], null)
                .toJS();

        case types.DELETE_REPORT_ERROR:
            return state.setIn(['delete', 'isDeleting'], false).toJS();

        case types.SAVE_MEMO:
            return state.setIn(['savingMemo'], true).toJS();

        case types.SAVE_MEMO_SUCCESS:
            return state.setIn(['savingMemo'], false).toJS();

        case types.SAVE_MEMO_ERROR:
            return state.setIn(['savingMemo'], false).toJS();

        case types.UPLOAD_FILE:
            return state.setIn(['uploadingFile'], true).toJS();

        case types.UPLOAD_FILE_SUCCESS:
            return state.setIn(['uploadingFile'], false)
                .setIn(['fields'], {})
                .setIn(['file'], null)
                .toJS();

        case types.UPLOAD_FILE_ERROR:
            return state.setIn(['uploadingFile'], false).toJS();

        case types.PUSH_TO_QB:
            return state.setIn(['pushingToQb'], true).toJS();

        case types.PUSH_TO_QB_SUCCESS:
            return state.setIn(['pushingToQb'], false).toJS();

        case types.PUSH_TO_QB_ERROR:
            return state.setIn(['pushingToQb'], false).toJS();

        case types.SET_ID_FOR_DELETE:
            return state.setIn(['delete', 'id'], action.payload).toJS();

        case types.SET_FIELD:
            return state.setIn(['fields', action.payload.field], action.payload.value).toJS();

        case types.CLEAR_FORM:
            return state.setIn(['file'], null)
                .updateIn(['fields'], (fields) => {
                    return fields.clear();
                }).toJS();

        case types.CHANGE_FILTER:
            return state.setIn(['pagination', 'activePage'], 1)
                .updateIn(['filter', 'fields'], (fields) => {
                    const index = fields.findIndex(field => field.get('name') === action.payload.name);
                    if (action.payload.value === '') {
                        return fields.delete(index);
                    } else {
                        if (index === -1) {
                            return fields.push({ name: action.payload.name, value: action.payload.value });
                        } else {
                            return fields.set(index, { name: action.payload.name, value: action.payload.value });
                        }
                    }
                })
                .toJS();

        case types.SET_FILE:
            return state.setIn(['file'], action.payload).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}