import { createSelector } from 'reselect';

export const getComponentState = state => state.mailingMatrixes;

export const getMatrix = createSelector(
    getComponentState,
    (state) => state.matrix
);
export const getMatrixes = createSelector(
    getComponentState,
    (state) => state.matrixes
);
export const getFetchingMatrixes = createSelector(
    getComponentState,
    (state) => state.fetchingMatrixes
);
export const getPagination = createSelector(
    getComponentState,
    (state) => state.pagination
);
export const getSort = createSelector(
    getComponentState,
    (state) => state.sort
);
export const getIdForDelete = createSelector(
    getComponentState,
    (state) => state.delete.id
);
export const getIsDeleting = createSelector(
    getComponentState,
    (state) => state.delete.isDeleting
);
export const getIsSaving = createSelector(
    getComponentState,
    (state) => state.isSaving
);
export const getFilterFields = createSelector(
    getComponentState,
    (state) => state.filter.fields
);
export const getSubcategoryId = createSelector(
    getComponentState,
    (state) => state.subcategoryId
);
export const getSubcategory = createSelector(
    getComponentState,
    (state) => state.subcategory
);
export const getFetchingSubcategory = createSelector(
    getComponentState,
    (state) => state.fetchingSubcategory
);
export const getMatrixData = createSelector(
    getComponentState,
    (state) => state.matrixData
);