export const SEARCH_ACTIVE = 'SEARCH/SEARCH_ACTIVE';
export const SEARCH_ACTIVE_SUCCESS = 'SEARCH/SEARCH_ACTIVE_SUCCESS';
export const SEARCH_ACTIVE_ERROR = 'SEARCH/SEARCH_ACTIVE_ERROR';
export const SEARCH_FINISHED = 'SEARCH/SEARCH_FINISHED';
export const SEARCH_FINISHED_SUCCESS = 'SEARCH/SEARCH_FINISHED_SUCCESS';
export const SEARCH_FINISHED_ERROR = 'SEARCH/SEARCH_FINISHED_ERROR';
export const SET_ACTIVE_PAGE = 'SEARCH/SET_ACTIVE_PAGE';
export const SET_FINISHED_PAGE = 'SEARCH/SET_FINISHED_PAGE';
export const SET_SEARCH = 'SEARCH/SET_SEARCH';
export const SET_SHOW_ACTIVE_JOBS = 'SEARCH/SET_SHOW_ACTIVE_JOBS';
export const SET_SHOW_FINISHED_JOBS = 'SEARCH/SET_SHOW_FINISHED_JOBS';
export const RESET_STATE = 'SEARCH/RESET_STATE';