import React from 'react';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import PqtPagination from './../../PqtPagination';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import { empty, dateToTimezone } from '../../../utils';

const Table = React.memo((props) => {
    const confirmHandler = (id) => {
        props.showDeleteModal();
        props.setIdForDelete(id);
    }

    const renderTable = () => {
        if (props.fetchingEstimates) {
            return (
                <tr>
                    <td colSpan="17"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.estimates.length === 0) {
            return (
                <tr>
                    <td colSpan="17">No Results</td>
                </tr>
            );
        }

        return props.estimates.map((estimate, index) => {
            let status = null;
            if (parseInt(estimate.status) === 1) {
                if (estimate.sc_quote_request_id != null && estimate.sc_quote_request_id !== '') {
                    status = 'Draft from RFQ';
                } else {
                    status = 'Draft';
                }
            } else if (parseInt(estimate.status) === 2) {
                status = 'Approved';
            } else if (parseInt(estimate.status) === 3) {
                status = 'Converted to SC Job';
            } else if (parseInt(estimate.status) === 4) {
                status = 'Estimated';
            }

            let quoteRequestLink = 'N/A';
            if (!empty(estimate.sc_quote_request_id)) {
                quoteRequestLink = (
                    <a href={'/request-for-quote/' + estimate.sc_quote_request_id}>
                        {estimate.sc_quote_request_id}
                    </a>
                );
            }

            let lastEstimateDate = '-';
            if (estimate.last_estimate_date != null) {
                lastEstimateDate = dateToTimezone(estimate.last_estimate_date, 'MM/DD/YYYY');
            }

            let partsLabel = '';
            let total = estimate.total;
            if (estimate.main_part_id && estimate.main_part_id != null) {
                partsLabel = <b>(Multipart)</b>;
                total = estimate.grand_total;
            }

            return (
                <tr key={'estimate-' + index}>
                    <td>{estimate.id}</td>
                    <td>{quoteRequestLink}</td>
                    <td>{estimate.customer_name}</td>
                    <td>{estimate.sales_rep_name}</td>
                    <td>{estimate.csr_name}</td>
                    <td>{estimate.estimate_type_name} {partsLabel}</td>
                    <td>{estimate.output_devices.length === 0 ? '-' : estimate.output_devices.join(', ')}</td>
                    <td>{estimate.has_mailing_services ? 'Yes' : 'No'}</td>
                    <td>{estimate.has_outsource ? 'Yes' : 'No'}</td>
                    <td>{estimate.description_title}</td>
                    <td>{numeral(total).format('$0,0.00')}</td>
                    <td>{status}</td>
                    <td>{estimate.created_by_name}</td>
                    <td>{estimate.estimated_by_name}</td>
                    <td>{lastEstimateDate}</td>
                    <td>{estimate.converted_by_name}</td>
                    <td>
                        <div className="actions">
                            <Tooltip title="Edit Estimate" position="bottom" arrow size="small" className="action-tooltip">
                                <Link to={'/estimate/' + estimate.type + '/' + estimate.id}><i className="fas fa-edit" /></Link>
                            </Tooltip>
                            <Tooltip title="Delete Estimate" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-trash" onClick={confirmHandler.bind(this, estimate.id)} />
                            </Tooltip>
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const setSortHandler = (field, value) => {
        props.setSort(field, value);
    };

    const renderHeader = (name, column, className) => {
        let sortFunc = setSortHandler.bind(this, column, 'asc');
        let sortIcon = <i className="fas fa-sort" />;
        if (props.sort.column === column) {
            if (props.sort.type === 'asc') {
                sortFunc = setSortHandler.bind(this, column, 'desc');
                sortIcon = <i className="fas fa-sort-up" />;
            } else {
                sortFunc = setSortHandler.bind(this, column, 'asc');
                sortIcon = <i className="fas fa-sort-down" />;
            }
        }

        return <th className={className} onClick={sortFunc}>{name} {sortIcon}</th>;
    };

    const handleChangeFilter = (name, fetch, event) => {
        props.changeFilter(name, event.target.value, fetch);
    };

    const handleKey = (event) => {
        if (event.key === 'Enter') {
            props.fetchEstimates();
        }
    };

    const renderSearchField = (name) => {
        let value = null;
        for (let i = 0; i < props.filerFields.length; i++) {
            if (props.filerFields[i].name === name) {
                value = props.filerFields[i].value;
            }
        }

        return (
            <th className="search-th">
                <input
                    type="text"
                    name={name}
                    className="search-input"
                    value={value}
                    onKeyPress={handleKey}
                    onChange={handleChangeFilter.bind(this, name, false)}
                />
            </th>
        );
    };

    const renderSelectField = (name, options) => {
        let value = null;
        for (let i = 0; i < props.filerFields.length; i++) {
            if (props.filerFields[i].name === name) {
                value = props.filerFields[i].value;
            }
        }

        return (
            <th className="search-th">
                <select name={name} className="search-input" value={value} onChange={handleChangeFilter.bind(this, name, true)}>
                    {options.map((option) => {
                        return <option value={option.value}>{option.label}</option>
                    })}
                </select>
            </th>
        );
    };

    const handleNewEstimate = () => {
        props.showNewEstimateModal();
    };

    return (
        <div className="estimates-table-container">
            <div className="actions-container">
                <button className="new-estimate-button" onClick={handleNewEstimate}><i className="fas fa-plus-square" /> New Estimate</button>
            </div>
            <div className="table-container">
                <table className="table table-sm table-hover table-striped">
                    <thead>
                        <tr>
                            {renderHeader('Estimate #', 'id', 'th-id')}
                            {renderHeader('Quote #', 'sc_quote_request_id', 'th-quote-id')}
                            {renderHeader('Customer', 'customer_name', 'th-csr')}
                            {renderHeader('Sales Rep', 'sales_rep_name', 'th-csr')}
                            {renderHeader('CSR', 'csr_name', 'th-csr')}
                            {renderHeader('Estimate Type', 'estimate_type_name', 'th-csr')}
                            {renderHeader('Output Device', 'output_devices', 'th-csr')}
                            {renderHeader('Mailing Services', 'has_mailing_services', 'th-csr')}
                            {renderHeader('Outsource', 'has_outsource', 'th-csr')}
                            {renderHeader('Description', 'description_title', 'th-description')}
                            {renderHeader('Total', 'total', 'th-total')}
                            {renderHeader('Status', 'status', 'th-status')}
                            {renderHeader('Created By', 'created_by_name', 'th-created-by')}
                            {renderHeader('Estimated By', 'estimated_by_name', 'th-estimated-by')}
                            <th>Estimate Date</th>
                            {renderHeader('Converted By', 'converted_by_name', 'th-converted-by')}
                            <th style={{ width: 90 }}></th>
                        </tr>
                        <tr>
                            {renderSearchField('id')}
                            {renderSearchField('sc_quote_request_id')}
                            {renderSearchField('customer_name')}
                            {renderSearchField('sales_rep_name')}
                            {renderSearchField('csr_name')}
                            {renderSearchField('estimate_type_name')}
                            {renderSearchField('output_devices')}
                            {renderSelectField('has_mailing_services', [{ value: '', label: '' }, { value: '1', label: 'Yes' }, { value: '0', label: 'No' }])}
                            {renderSelectField('has_outsource', [{ value: '', label: '' }, { value: '1', label: 'Yes' }, { value: '0', label: 'No' }])}
                            {renderSearchField('description_title')}
                            {renderSearchField('total')}
                            {renderSearchField('status')}
                            {renderSearchField('created_by_name')}
                            {renderSearchField('estimated_by_name')}
                            <th></th>
                            {renderSearchField('converted_by_name')}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderTable()}
                    </tbody>
                </table>
                <PqtPagination
                    pagination={props.pagination}
                    setActivePage={props.setActivePage}
                />
            </div>
        </div>
    );
});

export default Table;
