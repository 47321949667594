import React from 'react';
import { Dialog } from './../../common/dialog';
import Switch from "react-switch";
import numeral from 'numeral';
import { empty } from '../../../utils';

const MailServicesModal = React.memo((props) => {
    const handleSwitchChange = (id, field, setFunc, checked) => {
        setFunc(id, field, checked ? 1 : 0);
    };

    const handleQuantityChange = (id, setFunc, event) => {
        setFunc(id, 'quantity', event.target.value);
    };

    const renderItems = (items, setFunc) => {
        return items.map((item) => {
            let unit = 'N/A';
            if (parseInt(item.unit) === 1) {    // Thousand
                unit = 'Per 1000';
            } else if (parseInt(item.unit) === 2) {    // Each
                unit = 'Per each';
            } else if (parseInt(item.unit) === 3) {    // Hour
                unit = 'Per hour';
            } else if (parseInt(item.unit) === 4) {    // File
                unit = 'Per file';
            } else if (parseInt(item.unit) === 5) {    // Drop
                unit = 'Per drop';
            } else if (parseInt(item.unit) === 6) {    // Field
                unit = 'Per field';
            } else if (parseInt(item.unit) === 7) {    // Job
                unit = 'Per job';
            }

            let quantity = item.quantity;
            if (empty(item.quantity)) {
                quantity = '';
            }

            return (
                <tr>
                    <td>{item.name}</td>
                    <td className="quantity-column">
                        <input type="number" value={quantity} onChange={handleQuantityChange.bind(this, item.id, setFunc)} />
                    </td>
                    <td className="unit-price-column">{numeral(item.unit_price).format('$0,0.00')}</td>
                    <td className="unit-column">{unit}</td>
                    <td className="minimum-column">{numeral(item.minimum_charge).format('$0,0.00')}</td>
                    <td className="switch-column">
                        <Switch
                            onChange={handleSwitchChange.bind(this, item.id, 'checked', setFunc)}
                            checked={item.checked}
                            width={42}
                            height={22}
                        />
                    </td>
                </tr>
            );
        });
    };

    let items = null;
    if (props.mailServicesTab === 1) {
        items = renderItems(props.dataProcessingItems, props.setDataProcessingItem);
    } else if (props.mailServicesTab === 2) {
        items = renderItems(props.variablePrintItems, props.setVariablePrintItem);
    } else if (props.mailServicesTab === 3) {
        items = renderItems(props.inkjetItems, props.setInkjetItem);
    } else if (props.mailServicesTab === 4) {
        items = renderItems(props.insertingItems, props.setInsertingItem);
    } else if (props.mailServicesTab === 5) {
        items = renderItems(props.meteringItems, props.setMeteringItem);
    } else if (props.mailServicesTab === 6) {
        items = renderItems(props.handworkItems, props.setHandworkItem);
    }

    const handleAddButton = () => {
        props.setEstimateChanged(true);
        props.addMailServicesItems();
    };

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={props.hideMailServicesModal}>Cancel</button>,
        <button key="delete-button" className="btn btn-light btn-sm action-button" onClick={handleAddButton}>Add</button>,
    ];
    return (
        <Dialog
            name="mail-services-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="mail-services-dialog"
        >
            <div className="mail-services-container">
                <h1>Mail Services</h1>
                <div className="items-container">
                    <div className="tabs">
                        <div className={'tab' + (props.mailServicesTab === 1 ? ' active' : '')} onClick={props.setMailServicesTab.bind(this, 1)}>
                            Data Processing
                        </div>
                        <div className={'tab' + (props.mailServicesTab === 2 ? ' active' : '')} onClick={props.setMailServicesTab.bind(this, 2)}>
                            Variable Print
                        </div>
                        <div className={'tab' + (props.mailServicesTab === 3 ? ' active' : '')} onClick={props.setMailServicesTab.bind(this, 3)}>
                            Inkjet
                        </div>
                        <div className={'tab' + (props.mailServicesTab === 4 ? ' active' : '')} onClick={props.setMailServicesTab.bind(this, 4)}>
                            Inserting
                        </div>
                        <div className={'tab' + (props.mailServicesTab === 5 ? ' active' : '')} onClick={props.setMailServicesTab.bind(this, 5)}>
                            Metering
                        </div>
                        <div className={'tab' + (props.mailServicesTab === 6 ? ' active' : '')} onClick={props.setMailServicesTab.bind(this, 6)}>
                            Handwork
                        </div>
                    </div>
                    <div className="items">
                        <table className="table table-sm table-hover table-striped">
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Quantity</th>
                                    <th>Unit Price</th>
                                    <th>Unit</th>
                                    <th>Minimum Charge</th>
                                    <th>Yes/No</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Dialog >
    );
});

export default MailServicesModal;
