import React from 'react';
import FormPopover from './FormPopover';
import { empty } from './../../../utils';

const LargeFormatForm = React.memo((props) => {
    const renderLargeFormatProducts = () => {
        return props.information.largeFormatProducts.map((product) => {
            return (
                <div className="check-field">
                    <FormPopover
                        code={product.code_ref}
                        information={props.information}
                    />
                    <input
                        type="radio"
                        value={product.id}
                        checked={parseInt(product.id) === parseInt(props.fields.sc_large_format_product_id)}
                        onClick={props.handleSetField.bind(this, 'sc_large_format_product_id')}
                    /> {product.name}
                </div>
            );
        });
    };

    const renderSubstrates = () => {
        return props.information.substrates.map((substrate) => {
            return (
                <option value={substrate.id}>{substrate.name}</option>
            );
        });
    };

    const renderFinishingElements = () => {
        if (props.fetchingSubstrateAccessories) {
            return <div><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
        }

        if (empty(props.fields.substrate_id)) {
            return <div>Select a Substrate to see the Finishing options available.</div>;
        }

        if (props.information.finishingElements.length === 0) {
            return <div>No elements found.</div>;
        }

        return props.information.finishingElements.map((element) => {
            let index = props.selectedFinishingElements.findIndex((id) => parseInt(id) === parseInt(element.id));
            return (
                <div className="check-field">
                    <FormPopover
                        code={element.code_ref}
                        information={props.information}
                    /> <input type="checkbox" checked={index !== -1} onClick={props.checkFinishingElement.bind(this, element.id)} /> {element.name}
                </div>
            );
        });
    };

    const handleChangeSubstrate = (event) => {
        props.fetchSubstrateAccessories(event.target.value);
        props.resetFinishing();
        props.handleSetField('substrate_id', event);
    };

    return (
        <div className="large-format-form-container">
            <div className="line1">
                <div className="column1">
                    <div className="titles">
                        <div className="product-type">
                            <div><b>Product Type:</b></div>
                            <div>{props.productTypeName}</div>
                        </div>
                        <div className="process-request">
                            <div><b>Process Request:</b></div>
                            <div>{props.processRequestName}</div>
                        </div>
                    </div>
                    <div className="sublines">
                        <div className="subcolumn1">
                            <div className="application-line1">
                                <div className="application-column1">
                                    <b>Original <span className="required">*</span>:</b>
                                    <FormPopover
                                        code="LF-001"
                                        information={props.information}
                                    />
                                    <input type="number" value={props.fields.original} onChange={props.handleSetField.bind(this, 'original')} />
                                </div>
                                <div className="application-column2">
                                    <div className="title"><b>Application:</b></div>
                                    <div className="environment-fields">
                                        <div>Environment <span className="required">*</span>:</div>
                                        <div>
                                            <input
                                                type="radio"
                                                name="environment_type"
                                                value="1"
                                                checked={parseInt(props.fields.environment_type) === 1}
                                                onChange={props.handleSetField.bind(this, 'environment_type')}
                                            /> Outdoor
                                        </div>
                                        <div>
                                            <input
                                                type="radio"
                                                name="environment_type"
                                                value="2"
                                                checked={parseInt(props.fields.environment_type) === 2}
                                                onChange={props.handleSetField.bind(this, 'environment_type')}
                                            /> Indoor
                                        </div>
                                    </div>
                                    <div>
                                        <div>Installation <span className="required">*</span>:</div>
                                        <div>
                                            <input
                                                type="radio"
                                                name="installation_type"
                                                value="1"
                                                checked={parseInt(props.fields.installation_type) === 1}
                                                onChange={props.handleSetField.bind(this, 'installation_type')}
                                            /> Need Installation
                                        </div>
                                        <div>
                                            <input
                                                type="radio"
                                                name="installation_type"
                                                value="2"
                                                checked={parseInt(props.fields.installation_type) === 2}
                                                onChange={props.handleSetField.bind(this, 'installation_type')}
                                            /> Client to install
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="application-line2">
                                <div className="application-column1">
                                    <div className="title"><b>Color:</b></div>
                                    <div>
                                        <input
                                            type="radio"
                                            name="color_type"
                                            value="1"
                                            checked={parseInt(props.fields.color_type) === 1}
                                            onChange={props.handleSetField.bind(this, 'color_type')}
                                        /> One side
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            name="color_type"
                                            value="2"
                                            checked={parseInt(props.fields.color_type) === 2}
                                            onChange={props.handleSetField.bind(this, 'color_type')}
                                        /> Double sided
                                    </div>
                                </div>
                                <div className="application-column2">
                                    <div className="title"><b>Size:</b></div>
                                    <div className="size-container">
                                        <div className="size-field">
                                            Width <span className="required">*</span>:
                                            <input type="number" value={props.fields.width} onChange={props.handleSetField.bind(this, 'width')} />
                                        </div>
                                        <div className="size-field">
                                            Height <span className="required">*</span>:
                                            <input type="number" value={props.fields.height} onChange={props.handleSetField.bind(this, 'height')} />
                                        </div>
                                        <div className="size-field">
                                            Unit <span className="required">*</span>:
                                            <select value={props.fields.unit} onChange={props.handleSetField.bind(this, 'unit')}>
                                                <option value="1">Inches</option>
                                                <option value="2">Feet</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="notes">
                            <div className="title"><b>Please describe your project in detail here:</b></div>
                            <textarea
                                placeholder="Please let us know more about your project detail"
                                value={props.fields.large_format_description}
                                onChange={props.handleSetField.bind(this, 'large_format_description')}
                            />
                        </div>
                    </div>
                </div>
                <div className="column2">
                    <div className="note">
                        If you don't know the Substrates or Finishing requirements please do not check the boxes
                    </div>
                    <div className="products">
                        <div className="title"><b>Products <span className="required">*</span>:</b></div>
                        {renderLargeFormatProducts()}
                    </div>
                    <div className="substrates">
                        <div className="title">Substrate <span className="required">*</span>:</div>
                        <FormPopover
                            code=""
                            information={props.information}
                        />
                        <select value={props.fields.substrate_id} onChange={handleChangeSubstrate}>
                            <option value="">Select option</option>
                            {renderSubstrates()}
                        </select>
                    </div>
                    <div className="finishing">
                        <div className="title">Finishing:</div>
                        {renderFinishingElements()}
                    </div>
                </div>
            </div>
            {props.buttonsContainer}
        </div>
    );
});

export default LargeFormatForm;
