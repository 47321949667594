import Immutable from 'immutable';
import * as types from './InformedVisibilityJob.types';

const initialState = {
    fetchingJob: false,
    jobs: null,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_JOB:
            return state.setIn(['fetchingJob'], true).toJS();

        case types.FETCH_JOB_SUCCESS:
            return state.setIn(['fetchingJob'], false)
                .setIn(['job'], action.payload)
                .toJS();

        case types.FETCH_JOB_ERROR:
            return state.setIn(['fetchingJob'], false).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}