import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Invoices from './Invoices';
import * as actions from './DeletedInvoices.actions';
import * as selectors from './DeletedInvoices.selectors';
import './DeletedInvoices.scss';
import PqtLayout from './../PqtLayout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import * as loginSelectors from './../Login/Login.selectors';
import { Redirect } from 'react-router-dom';

const DeletedInvoices = (props) => {
    /* State to props */
    const fetching = useSelector(selectors.getFetching);
    const invoices = useSelector(selectors.getInvoices, stringifyEqualityCheck);
    const pagination = useSelector(selectors.getPagination, stringifyEqualityCheck);
    const filerFields = useSelector(selectors.getFilterFields, stringifyEqualityCheck);
    const sort = useSelector(selectors.getSort, stringifyEqualityCheck);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const setActivePage = useCallback((name, value) => dispatch(actions.setActivePage(name, value)), [dispatch]);
    const setSort = useCallback((column, type) => dispatch(actions.setSort(column, type)), [dispatch]);
    const changeFilter = useCallback((name, value, fetch) => dispatch(actions.changeFilter(name, value, fetch)), [dispatch]);
    const fetchDeletedInvoices = useCallback(() => dispatch(actions.fetchDeletedInvoices()), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        fetchDeletedInvoices();
    }, [fetchDeletedInvoices]);

    if (user == null && parseInt(user.id) !== 61) {     // Hugh
        return <Redirect to="/dashboard" />;
    }

    return (
        <PqtLayout title="Deleted Invoices" history={props.history}>
            <Helmet><title>Deleted Invoices - Stagecoach</title></Helmet>
            <div className="pqt-invoices-container">
                <Invoices
                    invoices={invoices}
                    pagination={pagination}
                    fetching={fetching}
                    filerFields={filerFields}
                    sort={sort}
                    history={props.history}
                    user={user}
                    setActivePage={setActivePage}
                    fetchDeletedInvoices={fetchDeletedInvoices}
                    setSort={setSort}
                    changeFilter={changeFilter}
                />
            </div>
        </PqtLayout>
    );
};

export default DeletedInvoices;
