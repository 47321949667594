export const FETCH_ITEMS = 'AP_ITEMS/FETCH_ITEMS';
export const FETCH_ITEMS_SUCCESS = 'AP_ITEMS/FETCH_ITEMS_SUCCESS';
export const FETCH_ITEMS_ERROR = 'AP_ITEMS/FETCH_ITEMS_ERROR';
export const DELETE_ITEM = 'AP_ITEMS/DELETE_ITEM';
export const DELETE_ITEM_SUCCESS = 'AP_ITEMS/DELETE_ITEM_SUCCESS';
export const DELETE_ITEM_ERROR = 'AP_ITEMS/DELETE_ITEM_ERROR';
export const UPLOAD_FILE = 'AP_ITEMS/UPLOAD_FILE';
export const UPLOAD_FILE_SUCCESS = 'AP_ITEMS/UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_ERROR = 'AP_ITEMS/UPLOAD_FILE_ERROR';
export const FETCH_ITEM = 'AP_ITEMS/FETCH_ITEM';
export const FETCH_ITEM_SUCCESS = 'AP_ITEMS/FETCH_ITEM_SUCCESS';
export const FETCH_ITEM_ERROR = 'AP_ITEMS/FETCH_ITEM_ERROR';
export const SAVE_ITEM = 'AP_ITEMS/SAVE_ITEM';
export const SAVE_ITEM_SUCCESS = 'AP_ITEMS/SAVE_ITEM_SUCCESS';
export const SAVE_ITEM_ERROR = 'AP_ITEMS/SAVE_ITEM_ERROR';
export const SAVE_ITEMS = 'AP_ITEMS/SAVE_ITEMS';
export const SAVE_ITEMS_SUCCESS = 'AP_ITEMS/SAVE_ITEMS_SUCCESS';
export const SAVE_ITEMS_ERROR = 'AP_ITEMS/SAVE_ITEMS_ERROR';
export const PUSH_TO_QB = 'AP_ITEMS/PUSH_TO_QB';
export const PUSH_TO_QB_SUCCESS = 'AP_ITEMS/PUSH_TO_QB_SUCCESS';
export const PUSH_TO_QB_ERROR = 'AP_ITEMS/PUSH_TO_QB_ERROR';
export const FETCH_INFORMATION = 'AP_ITEMS/FETCH_INFORMATION';
export const FETCH_INFORMATION_SUCCESS = 'AP_ITEMS/FETCH_INFORMATION_SUCCESS';
export const FETCH_INFORMATION_ERROR = 'AP_ITEMS/FETCH_INFORMATION_ERROR';
export const UPDATE_FIELD = 'AP_ITEMS/UPDATE_FIELD';
export const UPDATE_FIELD_SUCCESS = 'AP_ITEMS/UPDATE_FIELD_SUCCESS';
export const UPDATE_FIELD_ERROR = 'AP_ITEMS/UPDATE_FIELD_ERROR';
export const FETCH_RECORD = 'AP_ITEMS/FETCH_RECORD';
export const FETCH_RECORD_SUCCESS = 'AP_ITEMS/FETCH_RECORD_SUCCESS';
export const FETCH_RECORD_ERROR = 'AP_ITEMS/FETCH_RECORD_ERROR';
export const RESET_STATE = 'AP_ITEMS/RESET_STATE';
export const SET_ID = 'AP_ITEMS/SET_ID';
export const SET_SORT = 'AP_ITEMS/SET_SORT';
export const SET_ACTIVE_PAGE = 'AP_ITEMS/SET_ACTIVE_PAGE';
export const SET_ID_FOR_DELETE = 'AP_ITEMS/SET_ID_FOR_DELETE';
export const CHANGE_FILTER = 'AP_ITEMS/CHANGE_FILTER';
export const SET_FIELD = 'AP_ITEMS/SET_FIELD';
export const CLEAR_FORM = 'AP_ITEMS/CLEAR_FORM';
export const SET_FILE = 'AP_ITEMS/SET_FILE';
export const SET_TYPE = 'AP_ITEMS/SET_TYPE';
export const SET_PROCESSED_DATA_FIELD = 'AP_ITEMS/SET_PROCESSED_DATA_FIELD';
export const SET_NAME = 'AP_ITEMS/SET_NAME';
export const SET_POSTED_DATE = 'AP_ITEMS/SET_POSTED_DATE';
export const SET_RECORD_ID = 'AP_ITEMS/SET_RECORD_ID';
export const UPDATE_FIELD_LOCAL = 'AP_ITEMS/UPDATE_FIELD_LOCAL';
export const SET_HISTORY = 'AP_ITEMS/SET_HISTORY';