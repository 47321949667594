import { createSelector } from 'reselect';

export const getComponentState = state => state.pqtTracking;

export const getId = createSelector(
    getComponentState,
    (state) => state.id
);

export const getIsEditing = createSelector(
    getComponentState,
    (state) => state.isEditing
);

/* tracking */
export const getTracking = createSelector(
    getComponentState,
    (state) => state.tracking
);
export const getTrackingSteps = createSelector(
    getComponentState,
    (state) => state.tracking.steps
);
export const getTrackingAuditHistory = createSelector(
    getComponentState,
    (state) => state.tracking.auditHistory
);
export const getTrackingFields = createSelector(
    getComponentState,
    (state) => {
        let tracking = { ...state.tracking };
        delete tracking.steps;
        delete tracking.auditHistory;
        return tracking;
    }
);

/* information */
export const getInformation = createSelector(
    getComponentState,
    (state) => state.information
);
export const getInformationCustomers = createSelector(
    getComponentState,
    (state) => state.information.customers
);
export const getInformationSalesReps = createSelector(
    getComponentState,
    (state) => state.information.salesReps
);
export const getInformationCsrs = createSelector(
    getComponentState,
    (state) => state.information.csrs
);
export const getInformationDepartments = createSelector(
    getComponentState,
    (state) => state.information.departments
);
export const getInformationEmployees = createSelector(
    getComponentState,
    (state) => state.information.employees
);
export const getInformationVendors = createSelector(
    getComponentState,
    (state) => state.information.vendors
);
export const getIsFetchingInformation = createSelector(
    getComponentState,
    (state) => state.information.isFetching
);

/* saving */
export const getSaving = createSelector(
    getComponentState,
    (state) => state.saving
);
export const getSavingTracking = createSelector(
    getComponentState,
    (state) => state.saving.tracking
);
export const getSavingStart = createSelector(
    getComponentState,
    (state) => state.saving.start
);
export const getSavingStep = createSelector(
    getComponentState,
    (state) => state.saving.step
);
export const getSavingStepDelete = createSelector(
    getComponentState,
    (state) => state.saving.stepDelete
);
export const getSavingStepClose = createSelector(
    getComponentState,
    (state) => state.saving.stepClose
);
export const getSavingStepIndex = createSelector(
    getComponentState,
    (state) => state.saving.stepIndex
);

export const getHistory = createSelector(
    getComponentState,
    (state) => state.history
);

export const getIsFetchingTracking = createSelector(
    getComponentState,
    (state) => state.isFetchingTracking
);

export const getStepsUnsaved = createSelector(
    getComponentState,
    (state) => state.stepsUnsaved
);

export const getCreatingPreliminary = createSelector(
    getComponentState,
    (state) => state.creatingPreliminary
);

export const getPostageEscrow = createSelector(
    getComponentState,
    (state) => state.postageEscrow
);

export const getIsLoadingPostageEscrow = createSelector(
    getComponentState,
    (state) => state.isLoadingPostageEscrow
);

export const getIdModal = createSelector(
    getComponentState,
    (state) => state.idModal
);
export const getIsPausing = createSelector(
    getComponentState,
    (state) => state.isPausing
);
export const getIsUnpausing = createSelector(
    getComponentState,
    (state) => state.isUnpausing
);
export const getDownloadingPressTicket = createSelector(
    getComponentState,
    (state) => state.downloadingPressTicket
);
export const getSavingMailingEmails = createSelector(
    getComponentState,
    (state) => state.saving.mailingEmails
);
export const getSavingShippingEmails = createSelector(
    getComponentState,
    (state) => state.saving.shippingEmails
);
export const getSavingCloseoutEmails = createSelector(
    getComponentState,
    (state) => state.saving.closeoutEmails
);
export const getFiles = createSelector(
    getComponentState,
    (state) => state.files
);
export const getUploadingFiles = createSelector(
    getComponentState,
    (state) => state.uploadingFiles
);
export const getDeletingFile = createSelector(
    getComponentState,
    (state) => state.deletingFile
);
