import { createSelector } from 'reselect';

export const getComponentState = state => state.pqtDashboard;

/* information */
export const getInformation = createSelector(
    getComponentState,
    (state) => state.information
);
export const getSalesRepsInformation = createSelector(
    getComponentState,
    (state) => state.information.salesReps
);
export const getCsrsInformation = createSelector(
    getComponentState,
    (state) => state.information.csrs
);

export const getHistory = createSelector(
    getComponentState,
    (state) => state.history
);

/* fetching */
export const getFetching = createSelector(
    getComponentState,
    (state) => state.fetching
);
export const getFetchingQuickGlance = createSelector(
    getComponentState,
    (state) => state.fetching.quickGlance
);
export const getFetchingLateJobs = createSelector(
    getComponentState,
    (state) => state.fetching.lateJobs
);
export const getFetchingMyJobs = createSelector(
    getComponentState,
    (state) => state.fetching.myJobs
);
export const getFetchingInformation = createSelector(
    getComponentState,
    (state) => state.fetching.information
);

/* data */
export const getData = createSelector(
    getComponentState,
    (state) => state.data
);
export const getCsrsData = createSelector(
    getComponentState,
    (state) => state.data.quickGlance.csrs
);
export const getSalesRepsData = createSelector(
    getComponentState,
    (state) => state.data.quickGlance.salesReps
);
export const getSnapshotData = createSelector(
    getComponentState,
    (state) => state.data.quickGlance.snapshot
);
export const getDepartmentsData = createSelector(
    getComponentState,
    (state) => state.data.quickGlance.departments
);
export const getShiftCountData = createSelector(
    getComponentState,
    (state) => state.data.quickGlance.shiftCount
);
export const getCloseoutData = createSelector(
    getComponentState,
    (state) => state.data.quickGlance.closeout
);
export const getPostageEscrowBalanceData = createSelector(
    getComponentState,
    (state) => state.data.quickGlance.postageEscrowBalance
);
export const getInvoices = createSelector(
    getComponentState,
    (state) => state.data.quickGlance.invoices
);
export const getPpInvoices = createSelector(
    getComponentState,
    (state) => state.data.quickGlance.ppInvoices
);
export const getLateJobsData = createSelector(
    getComponentState,
    (state) => state.data.lateJobs
);
export const getMyJobsData = createSelector(
    getComponentState,
    (state) => state.data.myJobs
);

/* pagination */
export const getPagination = createSelector(
    getComponentState,
    (state) => state.pagination
);
export const getLateJobsPagination = createSelector(
    getComponentState,
    (state) => state.pagination.lateJobs
);
export const getMyJobsPagination = createSelector(
    getComponentState,
    (state) => state.pagination.myJobs
);

export const getSort = createSelector(
    getComponentState,
    (state) => state.sort
);

export const getSearchFields = createSelector(
    getComponentState,
    (state) => state.searchFields
);

export const getShowFinished = createSelector(
    getComponentState,
    (state) => state.showFinished
);

export const getQuickGlanceFilter = createSelector(
    getComponentState,
    (state) => state.quickGlanceFilter
);

/* delete */
export const getIdForDelete = createSelector(
    getComponentState,
    (state) => state.delete.id
);
export const getIsDeleting = createSelector(
    getComponentState,
    (state) => state.delete.isDeleting
);
