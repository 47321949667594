import axios from 'axios';
import * as types from './Chat.types';
import * as selectors from './Chat.selectors';
import * as loginSelectors from './../Login/Login.selectors';
import {
    showNotification,
    validateAuthInResponse,
    sendWsMessage,
} from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const setMessages = messages => dispatch => {
    dispatch({
        type: types.SET_MESSAGES,
        payload: messages
    });
}

export const addMessage = () => (dispatch, getState) => {
    dispatch({
        type: types.ADD_MESSAGE,
        payload: { message: selectors.getResponse(getState()), type: 1 },
    });
}

export const addMessageObject = messageObject => dispatch => {
    dispatch({
        type: types.ADD_MESSAGE_OBJECT,
        payload: messageObject,
    });
}

export const setResponse = response => dispatch => {
    dispatch({
        type: types.SET_RESPONSE,
        payload: response,
    });
}

export const setElementId = id => dispatch => {
    dispatch({
        type: types.SET_ELEMENT_ID,
        payload: id,
    });
}

export const resetState = () => dispatch => {
    dispatch({
        type: types.RESET_STATE,
        payload: null,
    });
}

export const saveMessage = () => (dispatch, getState) => {
    const chatId = selectors.getChatId(getState());
    const type = selectors.getType(getState());
    const message = selectors.getResponse(getState());
    const elementId = selectors.getElementId(getState());
    const usersIds = selectors.getUsersIds(getState());

    if (!type || type == null || type === '') {
        showNotification('Cannot save message', 'Cannot save message. Incorrect chat type.', 'warning');
        return;
    }

    if (message === '') {
        showNotification('Enter a message', 'Enter the message to send', 'info');
        return;
    }

    if (parseInt(type) === 1 && elementId === null) {
        showNotification('Missing Information', 'Error trying to send the message', 'warning');
        return;
    }

    dispatch({ type: types.SAVE_MESSAGE, payload: null });
    axios.post(apiUrl + '/chat-message/send-message',
        { chatId, message, type, elementId, id1: usersIds.id1, id2: usersIds.id2 },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } }
    ).then((response) => {
        dispatch({ type: types.SAVE_MESSAGE_SUCCESS, payload: null });
        dispatch(sendWsMessage({ message: response.data, type: 'new_chat_message' }));
        dispatch(addMessageObject({
            id: response.data.id,
            user_name: response.data.created_by_name,
            user_id: response.data.created_by,
            message: response.data.message,
            created_at: response.data.created_at,
            type: 1,
        }));
        dispatch(setResponse(''));
        dispatch(setFirstScrollDone(false));
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.SAVE_MESSAGE_ERROR));
    });
}

export const setShowField = (field, value) => (dispatch, getState) => {
    /*const show = selectors.getShow(getState());
    if((field === 'room' && value) || show.room) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'unset';
    }*/

    dispatch({
        type: types.SET_SHOW_FIELD,
        payload: { field, value },
    });
}

export const setFirstScrollDone = value => dispatch => {
    dispatch({
        type: types.SET_FIRST_SCROLL_DONE,
        payload: value,
    });
}

export const setTotalMessages = value => dispatch => {
    dispatch({
        type: types.SET_TOTAL_MESSAGES,
        payload: value,
    });
}

export const setTotalLoadedMessages = value => dispatch => {
    dispatch({
        type: types.SET_TOTAL_LOADED_MESSAGES,
        payload: value,
    });
}

export const loadMoreMessages = () => (dispatch, getState) => {
    const type = selectors.getType(getState());
    const elementId = selectors.getElementId(getState());
    const usersIds = selectors.getUsersIds(getState());
    const totalMessages = selectors.getTotalMessages(getState());
    const totalLoadedMessages = selectors.getTotalLoadedMessages(getState());
    const messages = selectors.getMessages(getState());

    let url = null;
    if (parseInt(type) === 1) {
        url = apiUrl + '/chat/load-messages?type=1&total-messages=' + totalMessages + '&total-loaded-messages='
            + totalLoadedMessages + '&element-id=' + elementId;
    } else if (parseInt(type) === 2) {
        url = apiUrl + '/chat/load-messages?type=2&total-messages=' + totalMessages + '&total-loaded-messages='
            + totalLoadedMessages + '&id1=' + usersIds.id1 + '&id2=' + usersIds.id2;
    }

    dispatch({ type: types.LOAD_MORE_MESSAGES, payload: null });
    axios.get(url,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } }
    ).then((response) => {
        dispatch({ type: types.LOAD_MORE_MESSAGES_SUCCESS, payload: null });
        dispatch(setMessages(response.data.concat(messages)));
        dispatch(setTotalLoadedMessages(totalLoadedMessages + 15));
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.LOAD_MORE_MESSAGES_ERROR));
    });
}

export const resetMessagesCounters = () => dispatch => {
    dispatch({
        type: types.RESET_MESSAGES_COUNTERS,
        payload: null,
    });
}

export const setType = type => dispatch => {
    dispatch({
        type: types.SET_TYPE,
        payload: type,
    });
}

export const setUsersIds = (id1, id2) => dispatch => {
    dispatch({
        type: types.SET_USERS_IDS,
        payload: { id1, id2 },
    });
}

export const loadChat = (type, options) => (dispatch, getState) => {
    const totalMessages = selectors.getTotalMessages(getState());
    const totalLoadedMessages = selectors.getTotalLoadedMessages(getState());

    let extraFields = '';
    if (parseInt(type) === 1) {    // Tracking
        extraFields = '&element_id=' + options.element_id;
    } else if (parseInt(type) === 2) {    // Individual
        extraFields = '&user1_id=' + options.user1_id + '&user2_id=' + options.user2_id;
    }

    dispatch({ type: types.LOAD_CHAT, payload: null });
    axios.get(apiUrl + '/chat/load?type=' + type + '&total-messages=' + totalMessages
        + '&total-loaded-messages=' + totalLoadedMessages + extraFields,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } }
    ).then((response) => {
        dispatch({ type: types.LOAD_CHAT_SUCCESS, payload: response.data });
        dispatch(setFirstScrollDone(false));
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.LOAD_CHAT_ERROR));
    });
}

export const loadChats = (isWsUpdate = false) => dispatch => {
    dispatch({ type: types.LOAD_CHATS, payload: isWsUpdate });
    axios.get(apiUrl + '/chat/load-chats',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } }
    ).then((response) => {
        dispatch({ type: types.LOAD_CHATS_SUCCESS, payload: response.data });
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.LOAD_CHATS_ERROR));
    });
}

export const setChatTitle = title => dispatch => {
    dispatch({
        type: types.SET_CHAT_TITLE,
        payload: title,
    });
}

export const loadChatMembers = () => (dispatch, getState) => {
    const type = selectors.getType(getState());
    const elementId = selectors.getElementId(getState());
    const usersIds = selectors.getUsersIds(getState());
    const user = loginSelectors.getUser(getState());

    let extraFields = '';
    if (parseInt(type) === 1) {    // Tracking
        extraFields = '&element_id=' + elementId;
    } else if (parseInt(type) === 2) {    // Individual
        extraFields = '&user1_id=' + usersIds.id1 + '&user2_id=' + user.id;
    }

    dispatch({ type: types.LOAD_CHAT_MEMBERS, payload: null });
    axios.get(apiUrl + '/chat/load-members?type=' + type + extraFields,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } }
    ).then((response) => {
        dispatch({ type: types.LOAD_CHAT_MEMBERS_SUCCESS, payload: response.data });
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.LOAD_CHAT_MEMBERS_ERROR));
    });
}

export const checkUnreadMessages = () => dispatch => {
    dispatch({ type: types.CHECK_UNREAD_MESSAGES, payload: null });
    axios.get(apiUrl + '/chat/check-unread-messages',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } }
    ).then((response) => {
        dispatch({ type: types.CHECK_UNREAD_MESSAGES_SUCCESS, payload: response.data });
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.CHECK_UNREAD_MESSAGES_ERROR));
    });
}

export const setChatUnreadMessages = (type, id) => dispatch => {
    dispatch({
        type: types.SET_CHAT_UNREAD_MESSAGES,
        payload: { type, id },
    });
}

export const setHasUnreadMessages = value => dispatch => {
    dispatch({
        type: types.SET_HAS_UNREAD_MESSAGES,
        payload: value,
    });
}
