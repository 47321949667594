import { createSelector } from 'reselect';

export const getComponentState = state => state.helpItems;

export const getFetching = createSelector(
    getComponentState,
    (state) => state.fetching
);
export const getFetchingHelpItems = createSelector(
    getComponentState,
    (state) => state.fetching.helpItems
);
export const getHelpItems = createSelector(
    getComponentState,
    (state) => state.data.helpItems
);
export const getPagination = createSelector(
    getComponentState,
    (state) => state.pagination
);
export const getSort = createSelector(
    getComponentState,
    (state) => state.sort
);
export const getIdForDelete = createSelector(
    getComponentState,
    (state) => state.delete.id
);
export const getIsDeleting = createSelector(
    getComponentState,
    (state) => state.delete.isDeleting
);
export const getFilterFields = createSelector(
    getComponentState,
    (state) => state.filter.fields
);
export const getSaving = createSelector(
    getComponentState,
    (state) => state.saving
);
export const getName = createSelector(
    getComponentState,
    (state) => state.fields.name
);
export const getCodeRef = createSelector(
    getComponentState,
    (state) => state.fields.code_ref
);
export const getDescription = createSelector(
    getComponentState,
    (state) => state.fields.description
);
export const getImage = createSelector(
    getComponentState,
    (state) => state.fields.image
);