import Immutable from 'immutable';
import * as types from './QuoteRequest.types';

const initialState = {
    id: null,
    fetchingRequest: false,
    activeTab: 1,
    fields: {
        quote_type: 1,
        shipping_to_type: 1,
        environment_type: 1,
        installation_type: 1,
        color_type: 1,
        unit: 1,
        has_return_address: 0,
        has_back_flap: 0,
        has_address_area: 0,
        has_other: 0,
        part_another_job: 0,
        done_when_completed: 0,
        goes_fulfillment: 0,
        use_crash_number: 0,
        material_coming_date: null,
    },
    dataProcessingActiveTab: 1,
    dataProcessingItems: [],
    variablePrintItems: [],
    selectedDataProcessingItems: [],
    mailActiveTab: 1,
    inkjetItems: [],
    insertingItems: [],
    meteringItems: [],
    selectedMailItems: [],
    handworkItems: [],
    selectedHandworkItems: [],
    selectedLargeFormateProducts: [],
    selectedSubstrates: [],
    selectedFinishingElements: [],
    fetchingInformation: false,
    information: {
        customers: [],
        csrs: [],
        productTypes: [],
        helpItems: [],
        largeFormatProducts: [],
        substrates: [],
        finishingElements: [],
        paperCategories: [],
        papers: [],
        envelopePapers: [],
    },
    history: null,
    saving: false,
    submitting: false,
    fetchingSubstrateAccessories: false,
    downloadingEstimate: false,
    files: [],
    mainPartId: null,
    mainPart: null,
    delete: {
        id: null,
        isDeleting: false,
    },
    isDuplicatingPart: false,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_REQUEST:
            return state.setIn(['fetchingRequest'], true).toJS();

        case types.FETCH_REQUEST_SUCCESS:
            return state.setIn(['fetchingRequest'], false)
                .setIn(['fields'], action.payload)
                .setIn(['selectedLargeFormateProducts'], JSON.parse(action.payload.large_format_products))
                .setIn(['selectedSubstrates'], JSON.parse(action.payload.substrates))
                .setIn(['selectedFinishingElements'], JSON.parse(action.payload.finishing_elements))
                .setIn(['selectedDataProcessingItems'], JSON.parse(action.payload.data_processing_elements))
                .setIn(['selectedMailItems'], JSON.parse(action.payload.mailing_services_elements))
                .setIn(['selectedHandworkItems'], JSON.parse(action.payload.handwork_elements))
                .setIn(['files'], action.payload.files !== null ? JSON.parse(action.payload.files) : [])
                .toJS();

        case types.FETCH_REQUEST_ERROR:
            return state.setIn(['fetchingRequest'], false).toJS();

        case types.FETCH_INFORMATION:
            return state.setIn(['fetchingInformation'], true).toJS();

        case types.FETCH_INFORMATION_SUCCESS:
            return state.setIn(['fetchingInformation'], false)
                .setIn(['information', 'customers'], action.payload.customers)
                .setIn(['information', 'csrs'], action.payload.csrs)
                .setIn(['information', 'productTypes'], action.payload.productTypes)
                .setIn(['information', 'helpItems'], action.payload.helpItems)
                .setIn(['information', 'largeFormatProducts'], action.payload.largeFormatProducts)
                .setIn(['information', 'substrates'], action.payload.substrates)
                .setIn(['information', 'finishingElements'], action.payload.finishingElements)
                .setIn(['information', 'paperCategories'], action.payload.paperCategories)
                .setIn(['information', 'papers'], action.payload.papers)
                .setIn(['information', 'envelopePapers'], action.payload.envelopePapers)
                .setIn(['dataProcessingItems'], action.payload.dataProcessingElements)
                .setIn(['variablePrintItems'], action.payload.variablePrintElements)
                .setIn(['inkjetItems'], action.payload.inkjetElements)
                .setIn(['insertingItems'], action.payload.insertingElements)
                .setIn(['meteringItems'], action.payload.meteringElements)
                .setIn(['handworkItems'], action.payload.handworkElements)
                .toJS();

        case types.FETCH_INFORMATION_ERROR:
            return state.setIn(['fetchingInformation'], false).toJS();

        case types.SAVE_REQUEST:
            return state.setIn(['saving'], true).toJS();

        case types.SAVE_REQUEST_SUCCESS:
            return state.setIn(['saving'], false).toJS();

        case types.SAVE_REQUEST_ERROR:
            return state.setIn(['saving'], false).toJS();

        case types.SUBMIT_REQUEST:
            return state.setIn(['submitting'], true).toJS();

        case types.SUBMIT_REQUEST_SUCCESS:
            return state.setIn(['submitting'], false).toJS();

        case types.SUBMIT_REQUEST_ERROR:
            return state.setIn(['submitting'], false).toJS();

        case types.FETCH_SUBSTRATE_ACCESSORIES:
            return state.setIn(['information', 'finishingElements'], [])
                .setIn(['fetchingSubstrateAccessories'], true)
                .toJS();

        case types.FETCH_SUBSTRATE_ACCESSORIES_SUCCESS:
            return state.setIn(['information', 'finishingElements'], action.payload)
                .setIn(['fetchingSubstrateAccessories'], false)
                .toJS();

        case types.FETCH_SUBSTRATE_ACCESSORIES_ERROR:
            return state.setIn(['fetchingSubstrateAccessories'], false)
                .toJS();

        case types.DOWNLOAD_ESTIMATE:
            return state.setIn(['downloadingEstimate'], true).toJS();

        case types.DOWNLOAD_ESTIMATE_SUCCESS:
            return state.setIn(['downloadingEstimate'], false).toJS();

        case types.DOWNLOAD_ESTIMATE_ERROR:
            return state.setIn(['downloadingEstimate'], false).toJS();

        case types.DUPLICATE_PART:
            return state.setIn(['isDuplicatingPart'], true).toJS();

        case types.DUPLICATE_PART_SUCCESS:
            return state.setIn(['isDuplicatingPart'], false).toJS();

        case types.DUPLICATE_PART_ERROR:
            return state.setIn(['isDuplicatingPart'], false).toJS();

        case types.SET_ACTIVE_TAB:
            return state.setIn(['activeTab'], action.payload).toJS();

        case types.SET_FIELD:
            return state.setIn(['fields', action.payload.name], action.payload.value).toJS();

        case types.SET_DATA_PROCESSING_ACTIVE_TAB:
            return state.setIn(['dataProcessingActiveTab'], action.payload).toJS();

        case types.SET_DATA_PROCESSING_ITEM:
            return state.updateIn(['dataProcessingItems'], (dataProcessingItems) => {
                const items = dataProcessingItems.toJS();
                const index = items.findIndex((item) => parseInt(item.id) === parseInt(action.payload.id));
                items[index][action.payload.field] = action.payload.value;
                return items;
            }).toJS();

        case types.SET_VARIABLE_PRINT_ITEM:
            return state.updateIn(['variablePrintItems'], (variablePrintItems) => {
                const items = variablePrintItems.toJS();
                const index = items.findIndex((item) => parseInt(item.id) === parseInt(action.payload.id));
                items[index][action.payload.field] = action.payload.value;
                return items;
            }).toJS();

        case types.ADD_DATA_PROCESSING_ITEMS:
            return state.setIn(['selectedDataProcessingItems'], action.payload).toJS();

        case types.DELETE_DATA_PROCESSING_ITEM:
            return state.updateIn(['selectedDataProcessingItems'], (selectedDataProcessingItems) => {
                const index = selectedDataProcessingItems.findIndex((item) => parseInt(item.get('id')) === parseInt(action.payload.id) && parseInt(item.get('type')) === parseInt(action.payload.type));
                return selectedDataProcessingItems.delete(index);
            }).updateIn(['dataProcessingItems'], (dataProcessingItems) => {
                const items = dataProcessingItems.toJS();
                if (parseInt(action.payload.type) !== 1) {
                    return items;
                }

                const index = items.findIndex((item) => parseInt(item.id) === parseInt(action.payload.id));
                items[index].checked = 0;
                return items;
            }).updateIn(['variablePrintItems'], (variablePrintItems) => {
                const items = variablePrintItems.toJS();
                if (parseInt(action.payload.type) !== 2) {
                    return items;
                }

                const index = items.findIndex((item) => parseInt(item.id) === parseInt(action.payload.id));
                items[index].checked = 0;
                return items;
            }).toJS();

        case types.SET_MAIL_ACTIVE_TAB:
            return state.setIn(['mailActiveTab'], action.payload).toJS();

        case types.SET_INKJET_ITEM:
            return state.updateIn(['inkjetItems'], (inkjetItems) => {
                const items = inkjetItems.toJS();
                const index = items.findIndex((item) => parseInt(item.id) === parseInt(action.payload.id));
                items[index][action.payload.field] = action.payload.value;
                return items;
            }).toJS();

        case types.SET_INSERTING_ITEM:
            return state.updateIn(['insertingItems'], (insertingItems) => {
                const items = insertingItems.toJS();
                const index = items.findIndex((item) => parseInt(item.id) === parseInt(action.payload.id));
                items[index][action.payload.field] = action.payload.value;
                return items;
            }).toJS();

        case types.SET_METERING_ITEM:
            return state.updateIn(['meteringItems'], (meteringItems) => {
                const items = meteringItems.toJS();
                const index = items.findIndex((item) => parseInt(item.id) === parseInt(action.payload.id));
                items[index][action.payload.field] = action.payload.value;
                return items;
            }).toJS();

        case types.ADD_MAIL_ITEMS:
            return state.setIn(['selectedMailItems'], action.payload).toJS();

        case types.DELETE_MAIL_ITEM:
            return state.updateIn(['selectedMailItems'], (selectedMailItems) => {
                const index = selectedMailItems.findIndex((item) => parseInt(item.get('id')) === parseInt(action.payload.id) && parseInt(item.get('type')) === parseInt(action.payload.type));
                return selectedMailItems.delete(index);
            }).updateIn(['inkjetItems'], (inkjetItems) => {
                const items = inkjetItems.toJS();
                if (parseInt(action.payload.type) !== 3) {
                    return items;
                }

                const index = items.findIndex((item) => parseInt(item.id) === parseInt(action.payload.id));
                items[index].checked = 0;
                return items;
            }).updateIn(['insertingItems'], (insertingItems) => {
                const items = insertingItems.toJS();
                if (parseInt(action.payload.type) !== 4) {
                    return items;
                }

                const index = items.findIndex((item) => parseInt(item.id) === parseInt(action.payload.id));
                items[index].checked = 0;
                return items;
            }).updateIn(['meteringItems'], (meteringItems) => {
                const items = meteringItems.toJS();
                if (parseInt(action.payload.type) !== 5) {
                    return items;
                }

                const index = items.findIndex((item) => parseInt(item.id) === parseInt(action.payload.id));
                items[index].checked = 0;
                return items;
            }).toJS();

        case types.SET_HANDWORK_ITEM:
            return state.updateIn(['handworkItems'], (handworkItems) => {
                const items = handworkItems.toJS();
                const index = items.findIndex((item) => parseInt(item.id) === parseInt(action.payload.id));
                items[index][action.payload.field] = action.payload.value;
                return items;
            }).toJS();

        case types.ADD_HANDWORK_ITEMS:
            return state.setIn(['selectedHandworkItems'], action.payload).toJS();

        case types.DELETE_HANDWORK_ITEM:
            return state.updateIn(['selectedHandworkItems'], (selectedHandworkItems) => {
                const index = selectedHandworkItems.findIndex((item) => parseInt(item.get('id')) === parseInt(action.payload.id) && parseInt(item.get('type')) === parseInt(action.payload.type));
                return selectedHandworkItems.delete(index);
            }).updateIn(['handworkItems'], (handworkItems) => {
                const items = handworkItems.toJS();
                if (parseInt(action.payload.type) !== 6) {
                    return items;
                }

                const index = items.findIndex((item) => parseInt(item.id) === parseInt(action.payload.id));
                items[index].checked = 0;
                return items;
            }).toJS();

        case types.CHECK_LARGE_FORMAT_PRODUCT:
            return state.updateIn(['selectedLargeFormateProducts'], (selectedLargeFormateProducts) => {
                const ids = selectedLargeFormateProducts.toJS();
                const index = ids.findIndex((id) => parseInt(id) === parseInt(action.payload));
                if (index === -1) {
                    ids.push(action.payload);
                } else {
                    ids.splice(index, 1);
                }
                return ids;
            }).toJS();

        case types.CHECK_SUBSTRATE:
            return state.updateIn(['selectedSubstrates'], (selectedSubstrates) => {
                const ids = selectedSubstrates.toJS();
                const index = ids.findIndex((id) => parseInt(id) === parseInt(action.payload));
                if (index === -1) {
                    ids.push(action.payload);
                } else {
                    ids.splice(index, 1);
                }
                return ids;
            }).toJS();

        case types.CHECK_FINISHING_ELEMENT:
            return state.updateIn(['selectedFinishingElements'], (selectedFinishingElements) => {
                const ids = selectedFinishingElements.toJS();
                const index = ids.findIndex((id) => parseInt(id) === parseInt(action.payload));
                if (index === -1) {
                    ids.push(action.payload);
                } else {
                    ids.splice(index, 1);
                }
                return ids;
            }).toJS();

        case types.SET_ID:
            return state.setIn(['id'], action.payload).toJS();

        case types.SET_HISTORY:
            return state.setIn(['history'], action.payload).toJS();

        case types.SET_SELECTED_DATA_PROCESSING_ITEM:
            return state.updateIn(['selectedDataProcessingItems'], (selectedDataProcessingItems) => {
                const items = selectedDataProcessingItems.toJS();
                const index = items.findIndex((item) => parseInt(item.id) === parseInt(action.payload.id) && parseInt(item.type) === parseInt(action.payload.type));
                items[index][action.payload.field] = action.payload.value;
                return items;
            }).toJS();

        case types.SET_SELECTED_MAIL_ITEM:
            return state.updateIn(['selectedMailItems'], (selectedMailItems) => {
                const items = selectedMailItems.toJS();
                const index = items.findIndex((item) => parseInt(item.id) === parseInt(action.payload.id) && parseInt(item.type) === parseInt(action.payload.type));
                items[index][action.payload.field] = action.payload.value;
                return items;
            }).toJS();

        case types.SET_SELECTED_HANDWORK_ITEM:
            return state.updateIn(['selectedHandworkItems'], (selectedHandworkItems) => {
                const items = selectedHandworkItems.toJS();
                const index = items.findIndex((item) => parseInt(item.id) === parseInt(action.payload.id) && parseInt(item.type) === parseInt(action.payload.type));
                items[index][action.payload.field] = action.payload.value;
                return items;
            }).toJS();

        case types.RESET_FINISHING:
            return state.updateIn(['selectedFinishingElements'], (selectedFinishingElements) => {
                return [];
            }).toJS();

        case types.ADD_FILE:
            return state.updateIn(['files'], (files) => {
                return files.push(action.payload);
            }).toJS();

        case types.REMOVE_FILE:
            return state.updateIn(['files'], (files) => {
                return files.delete(action.payload);
            }).toJS();

        case types.CLEAR_FILES:
            return state.updateIn(['files'], (files) => {
                return files.clear();
            }).toJS();

        case types.FETCH_MAIN_PART:
            return state.setIn(['fetchingMainPart'], true).toJS();

        case types.FETCH_MAIN_PART_SUCCESS:
            return state.setIn(['fetchingMainPart'], false)
                .setIn(['mainPart'], action.payload)
                .toJS();

        case types.FETCH_MAIN_PART_ERROR:
            return state.setIn(['fetchingMainPart'], false).toJS();

        case types.DELETE_REQUEST:
            return state.setIn(['delete', 'isDeleting'], true).toJS();

        case types.DELETE_REQUEST_SUCCESS:
            return state.setIn(['delete', 'isDeleting'], false)
                .setIn(['delete', 'id'], null)
                .toJS();

        case types.DELETE_REQUEST_ERROR:
            return state.setIn(['delete', 'isDeleting'], false).toJS();

        case types.SET_MAIN_PART_ID:
            return state.setIn(['mainPartId'], action.payload).toJS();

        case types.SET_ID_FOR_DELETE:
            return state.setIn(['delete', 'id'], action.payload).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}