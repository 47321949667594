import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Calculator from './Calculator';
import * as actions from './MarkupCalculator.actions';
import * as selectors from './MarkupCalculator.selectors';
import './MarkupCalculator.scss';
import PqtLayout from './../PqtLayout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';

const MarkupCalculator = (props) => {
    /* State to props */
    const fields = useSelector(selectors.getFields, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const setField = useCallback((field, value) => dispatch(actions.setField(field, value)), [dispatch]);

    return (
        <PqtLayout title="Markup Calculator" history={props.history}>
            <Helmet><title>Markup Calculator - Stagecoach</title></Helmet>
            <div className="markup-calculator-container">
                <Calculator
                    fields={fields}
                    setField={setField}
                />
            </div>
        </PqtLayout>
    );
};

export default MarkupCalculator;
