import React from 'react';
import { empty } from './../../../utils';
import { Dialog } from './../../common/dialog';
import numeral from 'numeral';

const NewPaperModal = React.memo((props) => {
    const handleSetPaperField = (field, event) => {
        props.setPaperField(field, event.target.value);
    };

    let mPrice = null;
    if (!empty(props.paperFields.m_weight) && !empty(props.paperFields.price_cwt)) {
        mPrice = props.paperFields.m_weight * props.paperFields.price_cwt / 100;
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={props.hideNewPaperModal}>Cancel</button>,
        <button key="delete-button" className="btn btn-light btn-sm action-button" onClick={props.addPaper}>
            {props.isAddingPaper ? <i className="fas fa-circle-notch fa-spin" /> : 'Add Paper'}
        </button>,
    ];
    return (
        <Dialog
            name="new-paper-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="new-paper-dialog"
        >
            <div className="new-paper-container">
                <h1>Add New Paper</h1>
                <div className="form-container">
                    <div className="field">
                        <div className="label">Name <span className="required">*</span>:</div>
                        <input type="text" value={props.paperFields.paper_name} onChange={handleSetPaperField.bind(this, 'paper_name')} />
                    </div>
                    <div className="field">
                        <div className="label">Vendor <span className="required">*</span>:</div>
                        <select value={props.paperFields.vendor_id} onChange={handleSetPaperField.bind(this, 'vendor_id')}>
                            <option value="">Select option</option>
                            {props.information.paperVendors.map((vendor) => {
                                return <option value={vendor.id}>{vendor.name}</option>
                            })}
                        </select>
                    </div>
                    <div className="field">
                        <div className="label">Category <span className="required">*</span>:</div>
                        <select value={props.paperFields.paper_category_id} onChange={handleSetPaperField.bind(this, 'paper_category_id')}>
                            <option value="">Select option</option>
                            {props.information.paperCategories.map((category) => {
                                return <option value={category.id}>{category.category_name}</option>
                            })}
                        </select>
                    </div>
                    <div className="field">
                        <div className="label">Description <span className="required">*</span>:</div>
                        <input type="text" value={props.paperFields.description} onChange={handleSetPaperField.bind(this, 'description')} />
                    </div>
                    <div className="field">
                        <div className="label">Basis Weight <span className="required">*</span>:</div>
                        <input type="text" value={props.paperFields.basis_weight} onChange={handleSetPaperField.bind(this, 'basis_weight')} />
                    </div>
                    <div className="field">
                        <div className="label">Width <span className="required">*</span>:</div>
                        <input type="text" value={props.paperFields.width} onChange={handleSetPaperField.bind(this, 'width')} />
                    </div>
                    <div className="field">
                        <div className="label">Length <span className="required">*</span>:</div>
                        <input type="text" value={props.paperFields.length} onChange={handleSetPaperField.bind(this, 'length')} />
                    </div>
                    <div className="field">
                        <div className="label">Caliper <span className="required">*</span>:</div>
                        <input type="text" value={props.paperFields.caliper} onChange={handleSetPaperField.bind(this, 'caliper')} />
                    </div>
                    <div className="field">
                        <div className="label">Color <span className="required">*</span>:</div>
                        <input type="text" value={props.paperFields.color} onChange={handleSetPaperField.bind(this, 'color')} />
                    </div>
                    <div className="field">
                        <div className="label">Finish <span className="required">*</span>:</div>
                        <input type="text" value={props.paperFields.finish} onChange={handleSetPaperField.bind(this, 'finish')} />
                    </div>
                    <div className="field">
                        <div className="label">Grain Direction <span className="required">*</span>:</div>
                        <input type="text" value={props.paperFields.grain_direction} onChange={handleSetPaperField.bind(this, 'grain_direction')} />
                    </div>
                    <div className="field">
                        <div className="label">M-Weight <span className="required">*</span>:</div>
                        <input type="text" value={props.paperFields.m_weight} onChange={handleSetPaperField.bind(this, 'm_weight')} />
                    </div>
                    <div className="field">
                        <div className="label">Price/CWT <span className="required">*</span>:</div>
                        <input type="text" value={props.paperFields.price_cwt} onChange={handleSetPaperField.bind(this, 'price_cwt')} />
                    </div>
                    <div className="field m-price-field">
                        <div className="label">Price/M-Sheets:</div>
                        {mPrice == null ? '' : numeral(mPrice).format('$0,0.00')}
                    </div>
                    <div className="field">
                        <div className="label">Digital <span className="required">*</span>:</div>
                        <select value={props.paperFields.sc_digital} onChange={handleSetPaperField.bind(this, 'sc_digital')}>
                            <option value="">Select option</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </div>
                    <div className="field">
                        <div className="label">JPress <span className="required">*</span>:</div>
                        <select value={props.paperFields.sc_jpress} onChange={handleSetPaperField.bind(this, 'sc_jpress')}>
                            <option value="">Select option</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </div>
                    <div className="field">
                        <div className="label">Komori <span className="required">*</span>:</div>
                        <select value={props.paperFields.sc_komori} onChange={handleSetPaperField.bind(this, 'sc_komori')}>
                            <option value="">Select option</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </div>
                </div>
            </div>
        </Dialog >
    );
});

export default NewPaperModal;
