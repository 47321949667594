import * as types from './MarkupCalculator.types';
import * as selectors from './MarkupCalculator.selectors';

export const setField = (field, value) => (dispatch, getState) => {
    const lastModifiedFields = selectors.getLastModifiedFields(getState());

    dispatch({
        type: types.SET_FIELD,
        payload: { field, value, lastModifiedFields: calculateLastModifiedFields(lastModifiedFields, field) }
    });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null
    });
}

export const calculateLastModifiedFields = (lastModifiedFields, field) => {
    const newLastModifiedFields = [...lastModifiedFields];
    if (newLastModifiedFields.length === 0) {
        newLastModifiedFields.push(field);
    } else if (newLastModifiedFields[newLastModifiedFields.length - 1] !== field) {
        newLastModifiedFields.push(field);
    }
    return newLastModifiedFields;
}