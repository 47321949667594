import { createSelector } from 'reselect';

export const getComponentState = state => state.estimates;

export const getFetchingEstimates = createSelector(
    getComponentState,
    (state) => state.fetchingEstimates
);
export const getEstimates = createSelector(
    getComponentState,
    (state) => state.estimates
);
export const getPagination = createSelector(
    getComponentState,
    (state) => state.pagination
);
export const getSort = createSelector(
    getComponentState,
    (state) => state.sort
);
export const getIdForDelete = createSelector(
    getComponentState,
    (state) => state.delete.id
);
export const getIsDeleting = createSelector(
    getComponentState,
    (state) => state.delete.isDeleting
);
export const getFilterFields = createSelector(
    getComponentState,
    (state) => state.filter.fields
);
export const getEstimateType = createSelector(
    getComponentState,
    (state) => state.estimateType
);
export const getCustomerId = createSelector(
    getComponentState,
    (state) => state.customerId
);
export const getCustomers = createSelector(
    getComponentState,
    (state) => state.information.customers
);
export const getSalesReps = createSelector(
    getComponentState,
    (state) => state.information.salesReps
);
export const getFetchingInformation = createSelector(
    getComponentState,
    (state) => state.fetchingInformation
);