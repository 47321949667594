import Immutable from 'immutable';
import * as types from './CreditMemo.types';
import numeral from 'numeral';

const initialState = {
    id: null,
    fetching: false,
    creditMemo: {
        items: [],
        auditHistory: [],
        discount_type: 1,
    },
    information: {
        fetching: false,
        customers: [],
        vendors: [],
        classes: [],
        categories: [],
    },
    fetchingCustomerData: false,
    isSaving: false,
    isPushing: false,
    customerEmail: null,
    jobDueDate: null,
}

const findDepartmentIndex = (id, itemsJs) => {
    let index = itemsJs.findIndex((item) => parseInt(item.department_id) === id);
    if (index === -1) {
        return 1000;
    }
    return index;
}

const findDepartmentLastIndex = (id, itemsJs) => {
    let lastIndex = 1000;
    for (let i = 0; i < itemsJs.length; i++) {
        if (parseInt(itemsJs[i].department_id) === id) {
            lastIndex = i;
        }
    }
    return lastIndex;
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_CREDIT_MEMO:
            return state.setIn(['fetching'], true).toJS();

        case types.FETCH_CREDIT_MEMO_SUCCESS:
            return state.setIn(['fetching'], false)
                .setIn(['creditMemo'], action.payload)
                .toJS();

        case types.FETCH_CREDIT_MEMO_ERROR:
            return state.setIn(['fetching'], false).toJS();

        case types.FETCH_INFORMATION:
            return state.setIn(['information', 'fetching'], true).toJS();

        case types.FETCH_INFORMATION_SUCCESS:
            return state.setIn(['information', 'fetching'], false)
                .setIn(['information', 'customers'], action.payload.customers)
                .setIn(['information', 'classes'], action.payload.classes)
                .setIn(['information', 'categories'], action.payload.categories)
                .toJS();

        case types.FETCH_INFORMATION_ERROR:
            return state.setIn(['information', 'fetching'], false).toJS();

        case types.FETCH_CUSTOMER_DATA:
            return state.setIn(['fetchingCustomerData'], true).toJS();

        case types.FETCH_CUSTOMER_DATA_SUCCESS:
            return state.setIn(['fetchingCustomerData'], false)
                .setIn(['creditMemo', 'customer_email'], action.payload.customer_email)
                .setIn(['creditMemo', 'billing_address'], action.payload.billing_address)
                .setIn(['creditMemo', 'billing_city'], action.payload.billing_city)
                .setIn(['creditMemo', 'billing_state'], action.payload.billing_state)
                .setIn(['creditMemo', 'billing_zip'], action.payload.billing_zip)
                .setIn(['creditMemo', 'shipping_address'], action.payload.shipping_address)
                .setIn(['creditMemo', 'shipping_city'], action.payload.shipping_city)
                .setIn(['creditMemo', 'shipping_state'], action.payload.shipping_state)
                .setIn(['creditMemo', 'shipping_zip'], action.payload.shipping_zip)
                .toJS();

        case types.FETCH_CUSTOMER_DATA_ERROR:
            return state.setIn(['fetchingCustomerData'], false).toJS();

        case types.SAVE_CREDIT_MEMO:
            return state.setIn(['isSaving'], true).toJS();

        case types.SAVE_CREDIT_MEMO_SUCCESS:
            return state.setIn(['isSaving'], false).toJS();

        case types.SAVE_CREDIT_MEMO_ERROR:
            return state.setIn(['isSaving'], false).toJS();

        case types.QB_PUSH:
            return state.setIn(['isPushing'], true).toJS();

        case types.QB_PUSH_SUCCESS:
            return state.setIn(['isPushing'], false).toJS();

        case types.QB_PUSH_ERROR:
            return state.setIn(['isPushing'], false).toJS();

        case types.SET_FIELD:
            return state.setIn(['creditMemo', action.payload.field], action.payload.value).toJS();

        case types.SET_ITEM_FIELD:
            return state.updateIn(['creditMemo', 'items'], (items) => {
                const itemsJs = items.toJS();
                let previousValue = itemsJs[action.payload.index][action.payload.field];
                itemsJs[action.payload.index][action.payload.field] = action.payload.value;

                if (action.payload.field === 'quantity') {
                    itemsJs[action.payload.index]['amount'] = numeral(itemsJs[action.payload.index]['price'] * action.payload.value).format('0.[00]');
                } else if (action.payload.field === 'price') {
                    itemsJs[action.payload.index]['amount'] = numeral(action.payload.value * itemsJs[action.payload.index]['quantity']).format('0.[00]');
                } else if (action.payload.field === 'amount') {
                    itemsJs[action.payload.index]['price'] = numeral(action.payload.value / itemsJs[action.payload.index]['quantity']).format('0.[0000000000]');
                } else if (action.payload.field === 'department_id') {
                    previousValue = parseInt(previousValue);
                    const departmentId = parseInt(action.payload.value);

                    itemsJs[action.payload.index].invoice_class_id = '';
                    if ([62, 60, 56, 64, 65, 66, 67].includes(departmentId)) {
                        itemsJs[action.payload.index].taxable = 0;
                        if (departmentId === 62) {    // Description
                            itemsJs[action.payload.index].invoice_class_id = 68;
                        } else if (departmentId === 60) {    // Postage Sales
                            itemsJs[action.payload.index].invoice_class_id = 57;
                        } else if (departmentId === 56) {    // Prepaid Postage
                            itemsJs[action.payload.index].invoice_class_id = 56;
                            itemsJs[action.payload.index].price = 0;
                            itemsJs[action.payload.index].amount = 0;
                        } else if (departmentId === 64) {    // Customer Permit
                            itemsJs[action.payload.index].invoice_class_id = 77;
                        } else if (departmentId === 65) {    // Meter
                            itemsJs[action.payload.index].invoice_class_id = 78;
                        } else if (departmentId === 66) {    // Stamp
                            itemsJs[action.payload.index].invoice_class_id = 79;
                        } else if (departmentId === 67) {    // ACM Paid Postage
                            itemsJs[action.payload.index].invoice_class_id = 80;
                            itemsJs[action.payload.index].description = 'ACM Paid Postage';
                            itemsJs[action.payload.index].quantity = 0;
                            itemsJs[action.payload.index].price = 0;
                            itemsJs[action.payload.index].amount = 0;
                        }
                    }

                    // Description
                    if (departmentId === 62) {
                        const removedItem = itemsJs.splice(action.payload.index, 1);
                        itemsJs.unshift(removedItem[0]);
                    } else if (previousValue === 62) {
                        let lastIndex = findDepartmentLastIndex(62, itemsJs);
                        if (lastIndex === 1000) {
                            lastIndex = 0;
                        }
                        const removedItem = itemsJs.splice(action.payload.index, 1);
                        itemsJs.splice(lastIndex, 0, removedItem[0]);
                    }

                    // Postage
                    if (departmentId === 60) {
                        const postageIndex = findDepartmentLastIndex(60, itemsJs);
                        if (postageIndex === 1000) {
                            const removedItem = itemsJs.splice(action.payload.index, 1);
                            itemsJs.push(removedItem[0]);
                        } else {
                            const removedItem = itemsJs.splice(action.payload.index, 1);
                            itemsJs.splice(postageIndex, 0, removedItem[0]);
                        }
                    } else if (previousValue === 60) {
                        const removedItem = itemsJs.splice(action.payload.index, 1);
                        const postageIndex = findDepartmentIndex(60, itemsJs);
                        const customerPermitIndex = findDepartmentIndex(64, itemsJs);
                        const meterIndex = findDepartmentIndex(65, itemsJs);
                        const stampIndex = findDepartmentIndex(66, itemsJs);
                        const prepaidPostageIndex = findDepartmentIndex(56, itemsJs);
                        const acmPaidPostageIndex = findDepartmentIndex(67, itemsJs);

                        const minIndex = Math.min(postageIndex, customerPermitIndex, meterIndex, stampIndex, prepaidPostageIndex, acmPaidPostageIndex);
                        itemsJs.splice(minIndex, 0, removedItem[0]);
                    }

                    // Customer Permit, Meter, Stamp, Prepaid Postage or ACM Paid Postage
                    if ([56, 64, 65, 66, 67].includes(departmentId)) {
                        const removedItem = itemsJs.splice(action.payload.index, 1);
                        itemsJs.push(removedItem[0]);
                    } else if ([56, 64, 65, 66, 67].includes(previousValue)) {
                        const removedItem = itemsJs.splice(action.payload.index, 1);
                        const postageIndex = findDepartmentIndex(60, itemsJs);
                        const customerPermitIndex = findDepartmentIndex(64, itemsJs);
                        const meterIndex = findDepartmentIndex(65, itemsJs);
                        const stampIndex = findDepartmentIndex(66, itemsJs);
                        const prepaidPostageIndex = findDepartmentIndex(56, itemsJs);
                        const acmPaidPostageIndex = findDepartmentIndex(67, itemsJs);

                        const minIndex = Math.min(postageIndex, customerPermitIndex, meterIndex, stampIndex, prepaidPostageIndex, acmPaidPostageIndex);
                        itemsJs.splice(minIndex, 0, removedItem[0]);
                    }

                    for (let i = 0; i < itemsJs.length; i++) {
                        itemsJs[i].sort_position = i + 1;
                    }
                }

                return itemsJs;
            }).toJS();

        case types.ADD_LINE:
            return state.updateIn(['creditMemo', 'items'], (items) => {
                const itemsJs = items.toJS();

                const postageIndex = findDepartmentIndex(60, itemsJs);
                const customerPermitIndex = findDepartmentIndex(64, itemsJs);
                const meterIndex = findDepartmentIndex(65, itemsJs);
                const stampIndex = findDepartmentIndex(66, itemsJs);
                const prepaidPostageIndex = findDepartmentIndex(56, itemsJs);
                const acmPaidPostageIndex = findDepartmentIndex(67, itemsJs);

                const minIndex = Math.min(postageIndex, customerPermitIndex, meterIndex, stampIndex, prepaidPostageIndex, acmPaidPostageIndex);
                const newElement = {
                    code: '',
                    department_id: '',
                    credit_memo_class_id: '',
                    description: '',
                    quantity: '',
                    price: '',
                    taxable: 0,
                    amount: '',
                    sort_position: 1,
                };
                itemsJs.splice(minIndex, 0, newElement);

                for (let i = 0; i < itemsJs.length; i++) {
                    itemsJs[i].sort_position = i + 1;
                }

                return itemsJs;
            }).toJS();

        case types.REMOVE_LINE:
            return state.updateIn(['creditMemo', 'items'], (items) => {
                const itemsJs = items.toJS();
                itemsJs.splice(action.payload, 1);

                for (let i = 0; i < itemsJs.length; i++) {
                    itemsJs[i].sort_position = i + 1;
                }

                return itemsJs;
            }).toJS();

        case types.SET_CUSTOMER_EMAIL:
            return state.setIn(['customerEmail'], action.payload).toJS();

        case types.CHANGE_SORT_POSITION:
            return state.updateIn(['creditMemo', 'items'], (items) => {
                const itemsJs = items.toJS();
                const removedItem = itemsJs.splice(action.payload.index, 1);

                if (action.payload.type === 1) {
                    itemsJs.splice(action.payload.index - 1, 0, removedItem[0]);
                } else {
                    itemsJs.splice(action.payload.index + 1, 0, removedItem[0]);
                }

                for (let i = 0; i < itemsJs.length; i++) {
                    itemsJs[i].sort_position = i + 1;
                }

                return itemsJs;
            }).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}