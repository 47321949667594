import React from 'react';
//import PropTypes from 'prop-types';

const ChatMembers = React.memo((props) => {
    const hideMembersHandler = () => {
        props.setShowField('members', false);
    };

    let className = 'show-members';
    if (props.showMembers) {
        className = 'hide-members'
    }

    const renderMembers = () => props.chatMembers.map((member, index) => (
        <div className="member-element" key={'member-element-' + index}><i className="fas fa-dot-circle" /> {member.name}</div>
    ));

    let members = <div className="members">{renderMembers()}</div>;
    if (props.isLoadingChatMembers) {
        members = (
            <div className="members">
                <i className="fas fa-circle-notch fa-spin" /> Loading members...
            </div>
        );
    }

    return (
        <div className={'chat-members-container ' + className}>
            <div className="title">Chat Members</div>
            {members}
            <div className="close-button" onClick={hideMembersHandler}>Hide</div>
        </div>
    );
});

ChatMembers.propTypes = {

};

export default ChatMembers;
