import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Form from './Form';
import * as actions from './MakePayment.actions';
import * as selectors from './MakePayment.selectors';
import './MakePayment.scss';
import { Helmet } from 'react-helmet';
import Favicon from 'react-favicon';
import { stringifyEqualityCheck } from './../../utils';

const MakePayment = (props) => {
    /* State to props */
    const payment = useSelector(selectors.getPayment, stringifyEqualityCheck);
    const fields = useSelector(selectors.getFields, stringifyEqualityCheck);
    const fetchingPayment = useSelector(selectors.getFetchingPayment);
    const generatingToken = useSelector(selectors.getGeneratingToken);
    const savingPayment = useSelector(selectors.getSavingPayment);
    const paymentType = useSelector(selectors.getPaymenType);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const fetchPayment = useCallback((id) => dispatch(actions.fetchPayment(id)), [dispatch]);
    const setField = useCallback((field, value) => dispatch(actions.setField(field, value)), [dispatch]);
    const generateToken = useCallback((ref) => dispatch(actions.generateToken(ref)), [dispatch]);
    const setInvoiceToken = useCallback((token) => dispatch(actions.setInvoiceToken(token)), [dispatch]);
    const setPaymentType = useCallback((type) => dispatch(actions.setPaymentType(type)), [dispatch]);
    const setRecaptchaToken = useCallback((token) => dispatch(actions.setRecaptchaToken(token)), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        if (props.match.params.token) {
            fetchPayment(props.match.params.token);
            setInvoiceToken(props.match.params.token);
        }

        if (props.match.params.type) {
            setPaymentType(props.match.params.type);
            if (parseInt(props.match.params.type) === 1) {
                setField('payment_type', 'CreditCard');
            } else {
                setField('payment_type', 'ACH');
            }
        }
    }, [dispatch, props.match.params.token, props.match.params.type, fetchPayment, setInvoiceToken, setPaymentType, setField]);

    return (
        <div>
            <Helmet><title>Make a Payment - Stagecoach</title></Helmet>
            <Favicon url="https://s3.amazonaws.com/ac-pqn/favicon.ico" />
            <div className="make-payment-container">
                <Form
                    payment={payment}
                    fields={fields}
                    fetchingPayment={fetchingPayment}
                    generatingToken={generatingToken}
                    savingPayment={savingPayment}
                    paymentType={paymentType}
                    fetchPayment={fetchPayment}
                    setField={setField}
                    generateToken={generateToken}
                    setRecaptchaToken={setRecaptchaToken}
                />
            </div>
        </div>
    );
};

export default MakePayment;
