import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import QuickSearch from './QuickSearch';
import Jobs from './Jobs';
import Tabs from './Tabs';
import DeleteModal from './DeleteModal';
import * as actions from './PqtDepartmentView.actions';
import * as selectors from './PqtDepartmentView.selectors';
import * as loginSelectors from './../Login/Login.selectors';
import * as pqtLayoutSelectors from './../PqtLayout/PqtLayout.selectors';
import * as pqtLayoutActions from './../PqtLayout/PqtLayout.actions';
import * as chatActions from './../Chat/Chat.actions';
import PropTypes from 'prop-types';
import './PqtDepartmentView.scss';
import PqtLayout from './../PqtLayout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import * as dialog from '../common/dialog';

const PqtDepartmentView = (props) => {
    /* State to props */
    const salesRepsInformation = useSelector(selectors.getSalesRepsInformation, stringifyEqualityCheck);
    const csrsInformation = useSelector(selectors.getCsrsInformation, stringifyEqualityCheck);
    const departmentsInformation = useSelector(selectors.getDepartmentsInformation, stringifyEqualityCheck);
    const fetchingInformation = useSelector(selectors.getFetchingInformation);
    const fetchingJobs = useSelector(selectors.getFetchingJobs);
    const jobsData = useSelector(selectors.getJobsData, stringifyEqualityCheck);
    const jobsPagination = useSelector(selectors.getJobsPagination, stringifyEqualityCheck);
    const id = useSelector(selectors.getId);
    const searchFields = useSelector(selectors.getSearchFields, stringifyEqualityCheck);
    const sort = useSelector(selectors.getSort, stringifyEqualityCheck);
    const department = useSelector(selectors.getDepartment, stringifyEqualityCheck);
    const showJobs = useSelector(selectors.getShowJobs);
    const idForDelete = useSelector(selectors.getIdForDelete, stringifyEqualityCheck);
    const isDeleting = useSelector(selectors.getIsDeleting, stringifyEqualityCheck);
    const ws = useSelector(pqtLayoutSelectors.getWs, stringifyEqualityCheck);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const setWs = useCallback((field, value) => dispatch(pqtLayoutActions.setWs(field, value)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);
    const deleteTracking = useCallback((id, comments) => dispatch(actions.deleteTracking(id, comments)), [dispatch]);
    const setId = useCallback((id, showJobs) => dispatch(actions.setId(id, showJobs)), [dispatch]);
    const setSearchField = useCallback((name, value) => dispatch(actions.setSearchField(name, value)), [dispatch]);
    const setSort = useCallback((column, type) => dispatch(actions.setSort(column, type)), [dispatch]);
    const setActivePage = useCallback((name, value) => dispatch(actions.setActivePage(name, value)), [dispatch]);
    const loadChat = useCallback((type, options) => dispatch(chatActions.loadChat(type, options)), [dispatch]);
    const setChatType = useCallback((type) => dispatch(chatActions.setType(type)), [dispatch]);
    const setChatShowField = useCallback((field, value) => dispatch(chatActions.setShowField(field, value)), [dispatch]);
    const seChattElementId = useCallback((id) => dispatch(chatActions.setElementId(id)), [dispatch]);
    const setChatTitle = useCallback((title) => dispatch(chatActions.setChatTitle(title)), [dispatch]);
    const loadChats = useCallback((isWsUpdate) => dispatch(chatActions.loadChats(isWsUpdate)), [dispatch]);
    const fetchData = useCallback((options, isWsUpdate) => dispatch(actions.fetchData(options, isWsUpdate)), [dispatch]);
    const setShowJobs = useCallback((value) => dispatch(actions.setShowJobs(value)), [dispatch]);
    const setIdForDelete = useCallback((id) => dispatch(actions.setIdForDelete(id)), [dispatch]);
    const showDeleteModal = useCallback(() => dispatch(dialog.actions.showDialog('delete-modal')), [dispatch]);
    const hideDeleteModal = useCallback(() => dispatch(dialog.actions.hideDialog('delete-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        let showJobsParam = 1;
        if(props.match.params.showJobs) {
            showJobsParam = props.match.params.showJobs;
        }
        dispatch(actions.mountComponent(props.history, props.match.params.id, showJobsParam));
    }, [dispatch, props.history, props.match.params.id, props.match.params.showJobs]);

    let content = null
    if (fetchingInformation) {
        content = <div><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
    } else if (department != null) {
        content = (
            <div className="pqt-department-view-container">
                <Tabs
                    id={id}
                    departmentsInformation={departmentsInformation}
                    history={props.history}
                    user={user}
                    showJobs={showJobs}
                    resetState={resetState}
                    setId={setId}
                    fetchData={fetchData}
                    setShowJobs={setShowJobs}
                />
                <Jobs
                    id={id}
                    jobsData={jobsData}
                    jobsPagination={jobsPagination}
                    fetchingJobs={fetchingJobs}
                    history={props.history}
                    department={department}
                    sort={sort}
                    user={user}
                    deleteTracking={deleteTracking}
                    setSort={setSort}
                    setActivePage={setActivePage}
                    loadChat={loadChat}
                    setChatType={setChatType}
                    setChatShowField={setChatShowField}
                    seChattElementId={seChattElementId}
                    setChatTitle={setChatTitle}
                    loadChats={loadChats}
                    fetchData={fetchData}
                    showDeleteModal={showDeleteModal}
                    setIdForDelete={setIdForDelete}
                />
                <QuickSearch
                    salesRepsInformation={salesRepsInformation}
                    csrsInformation={csrsInformation}
                    searchFields={searchFields}
                    setSearchField={setSearchField}
                    fetchData={fetchData}
                />
                <DeleteModal
                    idForDelete={idForDelete}
                    isDeleting={isDeleting}
                    deleteTracking={deleteTracking}
                    hideDeleteModal={hideDeleteModal}
                    setIdForDelete={setIdForDelete}
                />
            </div>
        );
    }

    if (ws !== null && ws.lastMessage !== null) {
        const jsonMessage = JSON.parse(ws.lastMessage);
        if (ws.uuid !== jsonMessage.uuid) {
            const wsTypes = ['track_created', 'track_updated', 'step_updated', 'step_deleted', 'step_closed', 'track_started',
                'track_closed', 'track_deleted', 'pqt_data_processing_created', 'pqt_data_processing_updated',
                'pqt_postal_qualification_created', 'pqt_postal_qualification_updated'];
            if (wsTypes.includes(jsonMessage.type)) {
                fetchData({
                    fetchJobs: 1,
                }, true);
                setWs('lastMessage', null);
            } else if (jsonMessage.type === 'new_chat_message') {
                fetchData({
                    fetchJobs: 1,
                }, true);
            }
        }
    }

    return (
        <PqtLayout title="Departmental View" history={props.history}>
            <Helmet><title>Department View - Stagecoach</title></Helmet>
            {content}
        </PqtLayout>
    );
};

PqtDepartmentView.propTypes = {
    user: PropTypes.object.isRequired
}

export default PqtDepartmentView;
