import React from 'react';
import { Dialog } from './../../common/dialog';
import { empty, showNotification } from './../../../utils';

const AddPartModal = React.memo((props) => {
    const hideHandler = () => {
        props.setPartEstimateType('');
        props.hideAddPartModal();
    };

    const newEstimateHandler = () => {
        if (empty(props.partEstimateType)) {
            return showNotification('Complete Information', 'Select the Estimate Type', 'info');
        }

        props.hideAddPartModal();

        let mainPartId = props.estimate.id;
        if (props.mainPart != null) {
            mainPartId = props.mainPart.id;
        }

        props.history.push('/add-part/' + props.partEstimateType + '/' + mainPartId + '/' + props.estimate.customer_id);
    };

    const handleChange = (setFunc, event) => {
        setFunc(event.target.value);
    };

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Cancel</button>,
        <button key="delete-button" className="btn btn-light btn-sm action-button" onClick={newEstimateHandler}>Add</button>
    ];
    return (
        <Dialog
            name="add-part-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="add-part-dialog"
        >
            <div className="add-part-modal-container">
                <h1>Add Part</h1>
                <p>Select the information for the new Part</p>
                <div className="add-part-modal-form-container">
                    <div className="field">
                        <div><b>Estimate Type:</b></div>
                        <select value={props.partEstimateType} onChange={handleChange.bind(this, props.setPartEstimateType)}>
                            <option value="">Select option</option>
                            <option value="1">Sheetfed</option>
                            <option value="2">Envelope</option>
                            <option value="4">Self-Cover</option>
                            <option value="3">Plus-Cover</option>
                            <option value="5">Digital</option>
                            <option value="6">Large Format</option>
                            <option value="7">Pocket Folder</option>
                            <option value="8">NCR</option>
                            <option value="9">Notepads</option>
                            <option value="10">Mail Only</option>
                        </select>
                    </div>
                </div>
            </div>
        </Dialog >
    );
});

export default AddPartModal;
