import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TrackerInformation from './TrackerInformation';
import AuditLog from './AuditLog';
import Steps from './Steps';
import Form from './Form';
import PauseModal from './PauseModal';
import UnpauseModal from './UnpauseModal';
import DeleteFileModal from './DeleteFileModal';
import * as actions from './PqtTracking.actions';
import * as selectors from './PqtTracking.selectors';
import * as loginSelectors from './../Login/Login.selectors';
import * as pqtLayoutSelectors from './../PqtLayout/PqtLayout.selectors';
import * as pqtLayoutActions from './../PqtLayout/PqtLayout.actions';
import * as pqtDataProcessingActions from './../PqtDataProcessing/PqtDataProcessing.actions';
import * as pqtPostalQualificationActions from './../PqtPostalQualification/PqtPostalQualification.actions';
import PropTypes from 'prop-types';
import './PqtTracking.scss';
import PqtLayout from './../PqtLayout';
import { showNotification } from './../../utils';
import * as dialog from '../common/dialog';
import PqtDataProcessing from './../PqtDataProcessing';
import PqtPostalQualification from './../PqtPostalQualification';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
// import { Redirect } from 'react-router-dom';

const PqtTracking = (props) => {
    /* State to props */
    const id = useSelector(selectors.getId);
    const isEditing = useSelector(selectors.getIsEditing);
    const trackingSteps = useSelector(selectors.getTrackingSteps, stringifyEqualityCheck);
    const trackingAuditHistory = useSelector(selectors.getTrackingAuditHistory, stringifyEqualityCheck);
    const trackingFields = useSelector(selectors.getTrackingFields, stringifyEqualityCheck);
    const savingTracking = useSelector(selectors.getSavingTracking);
    const savingStart = useSelector(selectors.getSavingStart);
    const savingStep = useSelector(selectors.getSavingStep);
    const savingStepDelete = useSelector(selectors.getSavingStepDelete);
    const savingStepIndex = useSelector(selectors.getSavingStepIndex);
    const informationCustomers = useSelector(selectors.getInformationCustomers, stringifyEqualityCheck);
    const informationSalesReps = useSelector(selectors.getInformationSalesReps, stringifyEqualityCheck);
    const informationCsrs = useSelector(selectors.getInformationCsrs, stringifyEqualityCheck);
    const informationDepartments = useSelector(selectors.getInformationDepartments, stringifyEqualityCheck);
    const informationEmployees = useSelector(selectors.getInformationEmployees, stringifyEqualityCheck);
    const informationVendors = useSelector(selectors.getInformationVendors, stringifyEqualityCheck);
    const isFetchingInformation = useSelector(selectors.getIsFetchingInformation, stringifyEqualityCheck);
    const isFetchingTracking = useSelector(selectors.getIsFetchingTracking);
    const stepsUnsaved = useSelector(selectors.getStepsUnsaved, stringifyEqualityCheck);
    const creatingPreliminary = useSelector(selectors.getCreatingPreliminary, stringifyEqualityCheck);
    const postageEscrow = useSelector(selectors.getPostageEscrow);
    const isLoadingPostageEscrow = useSelector(selectors.getIsLoadingPostageEscrow);
    const isPausing = useSelector(selectors.getIsPausing);
    const isUnpausing = useSelector(selectors.getIsUnpausing);
    const idModal = useSelector(selectors.getIdModal);
    const downloadingPressTicket = useSelector(selectors.getDownloadingPressTicket);
    const savingMailingEmails = useSelector(selectors.getSavingMailingEmails);
    const savingShippingEmails = useSelector(selectors.getSavingShippingEmails);
    const savingCloseoutEmails = useSelector(selectors.getSavingCloseoutEmails);
    const files = useSelector(selectors.getFiles, stringifyEqualityCheck);
    const uploadingFiles = useSelector(selectors.getUploadingFiles);
    const deletingFile = useSelector(selectors.getDeletingFile);
    const ws = useSelector(pqtLayoutSelectors.getWs, stringifyEqualityCheck);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const setIsEditing = useCallback((value) => dispatch(actions.setIsEditing(value)), [dispatch]);
    const setField = useCallback((name, value) => dispatch(actions.setField(name, value)), [dispatch]);
    const saveTracking = useCallback(() => dispatch(actions.saveTracking()), [dispatch]);
    const fetchInformation = useCallback(() => dispatch(actions.fetchInformation()), [dispatch]);
    const fetchTracking = useCallback((id) => dispatch(actions.fetchTracking(id)), [dispatch]);
    const setStepField = useCallback((index, name, value) => dispatch(actions.setStepField(index, name, value)), [dispatch]);
    const saveTrackingStep = useCallback((index) => dispatch(actions.saveTrackingStep(index)), [dispatch]);
    const deleteTrackingStep = useCallback((index) => dispatch(actions.deleteTrackingStep(index)), [dispatch]);
    const addStep = useCallback(() => dispatch(actions.addStep()), [dispatch]);
    const addAssignedPeople = useCallback((index, id) => dispatch(actions.addAssignedPeople(index, id)), [dispatch]);
    const removeAssignedPeople = useCallback((stepIndex, idIndex) => dispatch(actions.removeAssignedPeople(stepIndex, idIndex)), [dispatch]);
    const addSubdepartment = useCallback((index, id) => dispatch(actions.addSubdepartment(index, id)), [dispatch]);
    const removeSubdepartment = useCallback((stepIndex, idIndex) => dispatch(actions.removeSubdepartment(stepIndex, idIndex)), [dispatch]);
    const addAssignedVendor = useCallback((index, id) => dispatch(actions.addAssignedVendor(index, id)), [dispatch]);
    const removeAssignedVendor = useCallback((stepIndex, idIndex) => dispatch(actions.removeAssignedVendor(stepIndex, idIndex)), [dispatch]);
    const startTracking = useCallback(() => dispatch(actions.startTracking()), [dispatch]);
    const createPreliminary = useCallback(() => dispatch(actions.createPreliminary()), [dispatch]);
    const setIdModal = useCallback((id) => dispatch(actions.setIdModal(id)), [dispatch]);
    const pauseDepartment = useCallback((comments) => dispatch(actions.pauseDepartment(comments)), [dispatch]);
    const unpauseDepartment = useCallback(() => dispatch(actions.unpauseDepartment()), [dispatch]);
    const getPostageEscrow = useCallback((id, isCustomer) => dispatch(actions.getPostageEscrow(id, isCustomer)), [dispatch]);
    const downloadPressTicket = useCallback(() => dispatch(actions.downloadPressTicket()), [dispatch]);
    const saveMailingNotificationsEmails = useCallback(() => dispatch(actions.saveMailingNotificationsEmails()), [dispatch]);
    const saveShippingNotificationsEmails = useCallback(() => dispatch(actions.saveShippingNotificationsEmails()), [dispatch]);
    const saveCloseoutNotificationsEmails = useCallback(() => dispatch(actions.saveCloseoutNotificationsEmails()), [dispatch]);
    const setWs = useCallback((field, value) => dispatch(pqtLayoutActions.setWs(field, value)), [dispatch]);
    const showDataProcessing = useCallback(() => dispatch(dialog.actions.showDialog('pqt-data-processing-form')), [dispatch]);
    const showPostalQualification = useCallback(() => dispatch(dialog.actions.showDialog('pqt-postal-qualification-form')), [dispatch]);
    const setDataProcessingType = useCallback((type) => dispatch(pqtDataProcessingActions.setType(type)), [dispatch]);
    const setDataProcessingElementId = useCallback((id) => dispatch(pqtDataProcessingActions.setElementId(id)), [dispatch]);
    const fetchDataProcessingElement = useCallback((id) => dispatch(pqtDataProcessingActions.fetchElement(id)), [dispatch]);
    const resetDataProcessingState = useCallback(() => dispatch(pqtDataProcessingActions.resetState()), [dispatch]);
    const setDataProcessingShowSave = useCallback((value) => dispatch(pqtDataProcessingActions.setShowSave(value)), [dispatch]);
    const setPostalQualificationType = useCallback((type) => dispatch(pqtPostalQualificationActions.setType(type)), [dispatch]);
    const setPostalQualificationElementId = useCallback((id) => dispatch(pqtPostalQualificationActions.setElementId(id)), [dispatch]);
    const fetchPostalQualificationElement = useCallback((id) => dispatch(pqtPostalQualificationActions.fetchElement(id)), [dispatch]);
    const resetPostalQualificationState = useCallback(() => dispatch(pqtPostalQualificationActions.resetState()), [dispatch]);
    const setPostalQualificationShowSave = useCallback((value) => dispatch(pqtPostalQualificationActions.setShowSave(value)), [dispatch]);
    const addFile = useCallback((file) => dispatch(actions.addFile(file)), [dispatch]);
    const removeFile = useCallback((index) => dispatch(actions.removeFile(index)), [dispatch]);
    const uploadFiles = useCallback(() => dispatch(actions.uploadFiles()), [dispatch]);
    const deleteFile = useCallback((id) => dispatch(actions.deleteFile(id)), [dispatch]);
    const showPauseModal = useCallback(() => dispatch(dialog.actions.showDialog('pause-modal')), [dispatch]);
    const hidePauseModal = useCallback(() => dispatch(dialog.actions.hideDialog('pause-modal')), [dispatch]);
    const showUnpauseModal = useCallback(() => dispatch(dialog.actions.showDialog('unpause-modal')), [dispatch]);
    const hideUnpauseModal = useCallback(() => dispatch(dialog.actions.hideDialog('unpause-modal')), [dispatch]);
    const showDeleteFileModal = useCallback(() => dispatch(dialog.actions.showDialog('delete-file-modal')), [dispatch]);
    const hideDeleteFileModal = useCallback(() => dispatch(dialog.actions.hideDialog('delete-file-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        dispatch(actions.mountComponent(props.history, props.match.params.id));

        if (props.match.params.id) {
            getPostageEscrow(props.match.params.id, false);
        }
    }, [dispatch, getPostageEscrow, props.history, props.match.params.id]);

    // Beatriz (31), Rocio (32), Terri (2335), Nyke (33), Randy (35), Debbie (42), Robert (59), Hugh (61), Christy (64), House (73), Kathy (76), Kellie (78), Brenda (100)
    // if (user && ![31, 32, 33, 35, 42, 59, 61, 64, 73, 76, 78, 100, 2335].includes(parseInt(user.id))) {
    //     return <Redirect to="/" />;
    // }

    if (isFetchingTracking) {
        return (
            <PqtLayout title="Tracking" history={props.history}>
                <div className="text-center"><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>
            </PqtLayout>
        );
    }

    if (ws !== null && ws.lastMessage !== null) {
        const jsonMessage = JSON.parse(ws.lastMessage);
        if (ws.uuid !== jsonMessage.uuid && parseInt(id) === parseInt(jsonMessage.id)) {
            const wsTypes = ['track_created', 'track_updated', 'step_updated', 'step_deleted', 'step_closed', 'track_started',
                'track_closed', 'track_deleted', 'pqt_data_processing_created', 'pqt_data_processing_updated',
                'pqt_postal_qualification_created', 'pqt_postal_qualification_updated'];
            if (wsTypes.includes(jsonMessage.type)) {
                showNotification(
                    'Data Changed',
                    'This item was updated, please refresh the page to see the most updated data.',
                    'info',
                    0,
                );
                setWs('lastMessage', null);
            }
        }
    }

    return (
        <PqtLayout title="Tracking" history={props.history}>
            <Helmet><title>Tracking - Stagecoach</title></Helmet>
            <div className="pqt-tracking-container">
                <Form
                    id={id}
                    isEditing={isEditing}
                    trackingFields={trackingFields}
                    informationCustomers={informationCustomers}
                    informationSalesReps={informationSalesReps}
                    informationCsrs={informationCsrs}
                    savingTracking={savingTracking}
                    isFetchingTracking={isFetchingTracking}
                    isFetchingInformation={isFetchingInformation}
                    user={user}
                    postageEscrow={postageEscrow}
                    isLoadingPostageEscrow={isLoadingPostageEscrow}
                    setField={setField}
                    saveTracking={saveTracking}
                    setIsEditing={setIsEditing}
                    getPostageEscrow={getPostageEscrow}
                />
                <TrackerInformation
                    trackingFields={trackingFields}
                    isEditing={isEditing}
                    user={user}
                    postageEscrow={postageEscrow}
                    isLoadingPostageEscrow={isLoadingPostageEscrow}
                    setIsEditing={setIsEditing}
                    fetchInformation={fetchInformation}
                />
                <AuditLog
                    isEditing={isEditing}
                    trackingFields={trackingFields}
                    trackingAuditHistory={trackingAuditHistory}
                    savingStart={savingStart}
                    user={user}
                    creatingPreliminary={creatingPreliminary}
                    savingMailingEmails={savingMailingEmails}
                    savingShippingEmails={savingShippingEmails}
                    savingCloseoutEmails={savingCloseoutEmails}
                    files={files}
                    uploadingFiles={uploadingFiles}
                    deletingFile={deletingFile}
                    trackingSteps={trackingSteps}
                    addStep={addStep}
                    startTracking={startTracking}
                    createPreliminary={createPreliminary}
                    setField={setField}
                    saveMailingNotificationsEmails={saveMailingNotificationsEmails}
                    saveShippingNotificationsEmails={saveShippingNotificationsEmails}
                    saveCloseoutNotificationsEmails={saveCloseoutNotificationsEmails}
                    addFile={addFile}
                    removeFile={removeFile}
                    uploadFiles={uploadFiles}
                    showDeleteFileModal={showDeleteFileModal}
                    setIdModal={setIdModal}
                />
                <div className="divider"></div>
                <Steps
                    id={id}
                    trackingFields={trackingFields}
                    informationDepartments={informationDepartments}
                    informationEmployees={informationEmployees}
                    informationVendors={informationVendors}
                    isEditing={isEditing}
                    trackingSteps={trackingSteps}
                    history={props.history}
                    stepsUnsaved={stepsUnsaved}
                    user={user}
                    savingStep={savingStep}
                    savingStepIndex={savingStepIndex}
                    savingStepDelete={savingStepDelete}
                    downloadingPressTicket={downloadingPressTicket}
                    setStepField={setStepField}
                    saveTrackingStep={saveTrackingStep}
                    deleteTrackingStep={deleteTrackingStep}
                    addAssignedPeople={addAssignedPeople}
                    removeAssignedPeople={removeAssignedPeople}
                    addSubdepartment={addSubdepartment}
                    removeSubdepartment={removeSubdepartment}
                    addAssignedVendor={addAssignedVendor}
                    removeAssignedVendor={removeAssignedVendor}
                    showDataProcessing={showDataProcessing}
                    showPostalQualification={showPostalQualification}
                    setDataProcessingElementId={setDataProcessingElementId}
                    setPostalQualificationElementId={setPostalQualificationElementId}
                    fetchDataProcessingElement={fetchDataProcessingElement}
                    fetchPostalQualificationElement={fetchPostalQualificationElement}
                    resetDataProcessingState={resetDataProcessingState}
                    resetPostalQualificationState={resetPostalQualificationState}
                    setDataProcessingType={setDataProcessingType}
                    setPostalQualificationType={setPostalQualificationType}
                    setDataProcessingShowSave={setDataProcessingShowSave}
                    setPostalQualificationShowSave={setPostalQualificationShowSave}
                    showPauseModal={showPauseModal}
                    showUnpauseModal={showUnpauseModal}
                    setIdModal={setIdModal}
                    downloadPressTicket={downloadPressTicket}
                />
                <PqtDataProcessing
                    id={id}
                    callback={fetchTracking}
                />
                <PqtPostalQualification
                    id={id}
                    callback={fetchTracking}
                />
                <PauseModal
                    pauseDepartment={pauseDepartment}
                    isPausing={isPausing}
                    idModal={idModal}
                    hidePauseModal={hidePauseModal}
                    setIdModal={setIdModal}
                />
                <UnpauseModal
                    unpauseDepartment={unpauseDepartment}
                    isUnpausing={isUnpausing}
                    idModal={idModal}
                    hideUnpauseModal={hideUnpauseModal}
                    setIdModal={setIdModal}
                />
                <DeleteFileModal
                    deleteFile={deleteFile}
                    deletingFile={deletingFile}
                    idModal={idModal}
                    hideDeleteFileModal={hideDeleteFileModal}
                    setIdModal={setIdModal}
                />
            </div>
        </PqtLayout>
    );
};

PqtTracking.propTypes = {
    id: PropTypes.number,
    isEditing: PropTypes.bool.isRequired,
    information: PropTypes.object.isRequired,
    saving: PropTypes.object.isRequired,
    isFetchingTracking: PropTypes.bool.isRequired,
    setId: PropTypes.func.isRequired,
    setIsEditing: PropTypes.func.isRequired,
    fetchInformation: PropTypes.func.isRequired,
    setField: PropTypes.func.isRequired,
    saveTracking: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
};

export default PqtTracking;
