import React, { useState, useEffect } from 'react';

const CustomersOptions = React.memo((props) => {
    const [customerId, setCustomerId] = useState('');

    useEffect(() => {
        setCustomerId(props.customerId);
    }, [props.customerId]);

    const handleSetField = (event) => {
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        }

        props.setField('customer_id', value);
        setCustomerId(value);
    };

    return (
        <select value={customerId} onChange={handleSetField}>
            <option value="">Select customer</option>
            {props.customers.map((customer) => {
                return (
                    <option value={customer.id}>{customer.display_name}</option>
                );
            })}
        </select>
    );
});

export default CustomersOptions;
