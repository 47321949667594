import React from 'react';
import PqtPagination from './../../PqtPagination';
import { Link } from 'react-router-dom';
import { dateToTimezone } from './../../../utils';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';

const FinishedResults = React.memo((props) => {
    const pqtType = props.user !== null && parseInt(props.user.pqt_type);

    const exportTicketHandler = (id) => {
        window.open(process.env.REACT_APP_API_URL + '/pqt-tracking/export?id=' + id);
    };

    const exportInvoiceHandler = (id) => {
        window.open(process.env.REACT_APP_API_URL + '/invoice/preview?id=' + id);
    };

    const renderResults = () => {
        if (props.fetchingFinished) {
            return (
                <tr>
                    <td colSpan="11"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.finishedResults.length === 0 || !props.showFinishedJobs) {
            return (
                <tr>
                    <td colSpan="11">No Results</td>
                </tr>
            );
        }

        return props.finishedResults.map((invoice, index) => {
            let createdAt = '-';
            if (invoice.created_at != null) {
                createdAt = dateToTimezone(invoice.created_at, 'MM/DD/YYYY');
            }

            let csrName = invoice.csr_name;
            const csrNameParts = csrName.split(' ');
            if (csrNameParts.length > 0) {
                csrName = csrNameParts[0];
            }

            let salesName = invoice.sales_rep_name;
            const salesNameParts = salesName.split(' ');
            if (salesNameParts.length > 0) {
                salesName = salesNameParts[0];
            }

            let stagecoachNumber = 'N/A';
            if (invoice.stagecoach_number != null && invoice.stagecoach_number !== '') {
                stagecoachNumber = invoice.stagecoach_number;
            }

            let poNumber = 'N/A';
            if (invoice.po_number != null && invoice.po_number !== '') {
                poNumber = invoice.po_number;
            }

            let status = 'N/A';
            if (parseInt(invoice.status) === 1) {
                status = 'Preliminary invoice is ready';
            } else if (parseInt(invoice.status) === 2) {
                status = 'Closed and ready to start invoicing';
            } else if (parseInt(invoice.status) === 3) {
                status = 'Approved ready for accounting';
            } else if (parseInt(invoice.status) === 4) {
                status = 'Final & Archived';
            }

            let scLink = 'N/A';
            if (stagecoachNumber !== 'N/A') {
                scLink = <Link to="#" onClick={exportTicketHandler.bind(this, stagecoachNumber)}>{stagecoachNumber}</Link>;
            }

            let invoiceLink = null;
            if (pqtType === 1 || pqtType === 2) {
                invoiceLink = (
                    <td>
                        <div className="actions">
                            <Tooltip title="Edit Invoice" position="bottom" arrow size="small" className="action-tooltip">
                                <Link to={'/invoice/' + invoice.id}><i className="fas fa-edit" /></Link>
                            </Tooltip>
                        </div>
                    </td>
                );
            }

            return (
                <tr key={'finished-invoice-' + index}>
                    <td>{createdAt}</td>
                    <td>{stagecoachNumber}</td>
                    <td>{poNumber}</td>
                    <td>{csrName}</td>
                    <td>{salesName}</td>
                    <td>{invoice.job_description}</td>
                    <td>{status}</td>
                    <td>{scLink}</td>
                    <td><Link to="#" onClick={exportInvoiceHandler.bind(this, invoice.id)}>{invoice.id}</Link></td>
                    {invoiceLink}
                </tr>
            );
        });
    };

    let actionsTh = null;
    if (pqtType === 1 || pqtType === 2) {
        actionsTh = <th style={{ width: 50 }}></th>;
    }

    return (
        <div className="finished-results-container">
            <h2>Finished/Closed Results</h2>
            <table className="table table-sm table-hover table-striped">
                <thead>
                    <tr>
                        <th>Enter</th>
                        <th>SC #</th>
                        <th>PO #</th>
                        <th>CSR</th>
                        <th>Sales</th>
                        <th>Description</th>
                        <th>Status</th>
                        <th>PDF Ticket</th>
                        <th>PDF Invoice</th>
                        {actionsTh}
                    </tr>
                </thead>
                <tbody>
                    {renderResults()}
                </tbody>
            </table>
            <PqtPagination
                pagination={props.finishedPagination}
                setActivePage={props.setFinishedPage}
            />
        </div>
    );
});

export default FinishedResults;
