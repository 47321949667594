export const FETCH_COMPENSATIONS = 'COMMISSION_COMPENSATION/FETCH_COMPENSATIONS';
export const FETCH_COMPENSATIONS_SUCCESS = 'COMMISSION_COMPENSATION/FETCH_COMPENSATIONS_SUCCESS';
export const FETCH_COMPENSATIONS_ERROR = 'COMMISSION_COMPENSATION/FETCH_COMPENSATIONS_ERROR';
export const DELETE_COMPENSATION = 'COMMISSION_COMPENSATION/DELETE_COMPENSATION';
export const DELETE_COMPENSATION_SUCCESS = 'COMMISSION_COMPENSATION/DELETE_COMPENSATION_SUCCESS';
export const DELETE_COMPENSATION_ERROR = 'COMMISSION_COMPENSATION/DELETE_COMPENSATION_ERROR';
export const FETCH_COMPENSATION = 'COMMISSION_COMPENSATION/FETCH_COMPENSATION';
export const FETCH_COMPENSATION_SUCCESS = 'COMMISSION_COMPENSATION/FETCH_COMPENSATION_SUCCESS';
export const FETCH_COMPENSATION_ERROR = 'COMMISSION_COMPENSATION/FETCH_COMPENSATION_ERROR';
export const SAVE_COMPENSATION = 'COMMISSION_COMPENSATION/SAVE_COMPENSATION';
export const SAVE_COMPENSATION_SUCCESS = 'COMMISSION_COMPENSATION/SAVE_COMPENSATION_SUCCESS';
export const SAVE_COMPENSATION_ERROR = 'COMMISSION_COMPENSATION/SAVE_COMPENSATION_ERROR';
export const FETCH_PERSONS = 'COMMISSION_COMPENSATION/FETCH_PERSONS';
export const FETCH_PERSONS_SUCCESS = 'COMMISSION_COMPENSATION/FETCH_PERSONS_SUCCESS';
export const FETCH_PERSONS_ERROR = 'COMMISSION_COMPENSATION/FETCH_PERSONS_ERROR';
export const RESET_STATE = 'COMMISSION_COMPENSATION/RESET_STATE';
export const SET_ID = 'COMMISSION_COMPENSATION/SET_ID';
export const SET_SORT = 'COMMISSION_COMPENSATION/SET_SORT';
export const SET_ACTIVE_PAGE = 'COMMISSION_COMPENSATION/SET_ACTIVE_PAGE';
export const SET_ID_FOR_DELETE = 'COMMISSION_COMPENSATION/SET_ID_FOR_DELETE';
export const CHANGE_FILTER = 'COMMISSION_COMPENSATION/CHANGE_FILTER';
export const SET_FIELD = 'COMMISSION_COMPENSATION/SET_FIELD';
export const CLEAR_FORM = 'COMMISSION_COMPENSATION/CLEAR_FORM';