import axios from 'axios';
import * as types from './PqtLayout.types';
// import * as selectors from './PqtLayout.selectors';
import { validateAuthInResponse } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const createTaskRequest = () => (dispatch, getState) => {
    dispatch({ type: types.CREATE_TASK_REQUEST, payload: null });
    axios.post(apiUrl + '/gdp-request/create-from-sc', {},
        {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
        })
        .then((response) => {
            dispatch({ type: types.CREATE_TASK_REQUEST_SUCCESS, payload: null });
            window.open('https://task-request.corporatestorefront.com/login/' + response.data.email + '/' + response.data.login_token, '_blank');
            // window.open('http://localhost:3000/login/' + response.data.email + '/' + response.data.login_token, '_blank');
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.CREATE_TASK_REQUEST_ERROR));
        });
}

export const setWs = (field, value) => dispatch => {
    dispatch({
        type: types.SET_WS,
        payload: { field, value }
    });
}