export const FETCH_PAYMENT = 'MAKE_PAYMENT/FETCH_PAYMENT';
export const FETCH_PAYMENT_SUCCESS = 'MAKE_PAYMENT/FETCH_PAYMENT_SUCCESS';
export const FETCH_PAYMENT_ERROR = 'MAKE_PAYMENT/FETCH_PAYMENT_ERROR';
export const GENERATE_TOKEN = 'MAKE_PAYMENT/GENERATE_TOKEN';
export const GENERATE_TOKEN_SUCCESS = 'MAKE_PAYMENT/GENERATE_TOKEN_SUCCESS';
export const GENERATE_TOKEN_ERROR = 'MAKE_PAYMENT/GENERATE_TOKEN_ERROR';
export const SAVE_PAYMENT = 'MAKE_PAYMENT/SAVE_PAYMENT';
export const SAVE_PAYMENT_SUCCESS = 'MAKE_PAYMENT/SAVE_PAYMENT_SUCCESS';
export const SAVE_PAYMENT_ERROR = 'MAKE_PAYMENT/SAVE_PAYMENT_ERROR';
export const SET_PAYMENT_TYPE = 'MAKE_PAYMENT/SET_PAYMENT_TYPE';
export const SET_INVOICE_TOKEN = 'MAKE_PAYMENT/SET_INVOICE_TOKEN';
export const SET_TOKEN = 'MAKE_PAYMENT/SET_TOKEN';
export const SET_FIELD = 'MAKE_PAYMENT/SET_FIELD';
export const SET_RECAPTCHA_TOKEN = 'MAKE_PAYMENT/SET_RECAPTCHA_TOKEN';
export const RESET_FIELDS = 'MAKE_PAYMENT/RESET_FIELDS';
export const RESET_STATE = 'MAKE_PAYMENT/RESET_STATE';