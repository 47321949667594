import axios from 'axios';
import * as types from './PqtPostageReport.types';
import * as selectors from './PqtPostageReport.selectors';
import { validateAuthInResponse, showNotification } from './../../utils';
import fileDownload from 'js-file-download';
import moment from 'moment';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchReport = () => (dispatch, getState) => {
    const type = selectors.getReportType(getState());
    const customer = selectors.getCustomer(getState());
    const fromDate = selectors.getFromDate(getState());
    const toDate = selectors.getToDate(getState());
    const salesRep = selectors.getSalesRep(getState());

    dispatch({ type: types.FETCH_REPORT, payload: null });
    axios.get(apiUrl + '/invoice/postage-report?type=' + type + '&customerId=' + customer + '&fromDate=' + fromDate + '&toDate=' + toDate + '&salesRep=' + salesRep,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_REPORT_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_REPORT_ERROR));
        });
}

export const fetchCustomers = () => (dispatch, getState) => {
    dispatch({ type: types.FETCH_CUSTOMERS, payload: null });
    axios.get(apiUrl + '/invoice/postage-customers',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_CUSTOMERS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_CUSTOMERS_ERROR));
        });
}

export const fetchInformation = () => (dispatch, getState) => {
    dispatch({ type: types.FETCH_INFORMATION, payload: null });
    axios.get(apiUrl + '/invoice/postage-information',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_INFORMATION_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_INFORMATION_ERROR));
        });
}

export const exportReport = () => (dispatch, getState) => {
    const type = selectors.getReportType(getState());
    const customer = selectors.getCustomer(getState());
    const fromDate = selectors.getFromDate(getState());
    const toDate = selectors.getToDate(getState());
    const salesRep = selectors.getSalesRep(getState());

    dispatch({ type: types.EXPORT_REPORT, payload: null });
    axios.post(apiUrl + '/invoice/export-postage-report', { type, customerId: customer, fromDate, toDate, salesRep },
        {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
            responseType: 'blob',
        })
        .then((response) => {
            dispatch({ type: types.EXPORT_REPORT_SUCCESS, payload: null });
            fileDownload(response.data, 'Postage Report.xlsx');
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.EXPORT_REPORT_ERROR));
        });
}

export const exportPostageReport = () => (dispatch, getState) => {
    const type = selectors.getReportType(getState());
    const customer = selectors.getCustomer(getState());
    const fromDate = selectors.getFromDate(getState());
    const toDate = selectors.getToDate(getState());
    const salesRep = selectors.getSalesRep(getState());

    if (fromDate == null || fromDate === '') {
        showNotification('Complete Information', 'Enter the From Date', 'info');
        return;
    }
    if (toDate == null || toDate === '') {
        showNotification('Complete Information', 'Enter the To Date', 'info');
        return;
    }

    dispatch({ type: types.EXPORT_POSTAGE_REPORT, payload: null });
    axios.post(apiUrl + '/invoice/st-postage-report', { type, customerId: customer, fromDate, toDate, salesRep },
        {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
            responseType: 'blob',
        })
        .then((response) => {
            dispatch({ type: types.EXPORT_POSTAGE_REPORT_SUCCESS, payload: null });
            fileDownload(response.data, 'Postage Due (From ' + moment(fromDate).format('MM-DD-YYYY') + ' to ' + moment(toDate).format('MM-DD-YYYY') +').xlsx');
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.EXPORT_POSTAGE_REPORT_ERROR));
        });
}

export const setReportType = (value) => (dispatch) => {
    dispatch({
        type: types.SET_REPORT_TYPE,
        payload: value,
    });
}

export const setCustomer = (value) => (dispatch) => {
    dispatch({
        type: types.SET_CUSTOMER,
        payload: value,
    });
}

export const setFromDate = (value) => (dispatch) => {
    dispatch({
        type: types.SET_FROM_DATE,
        payload: value,
    });
}

export const setToDate = (value) => (dispatch) => {
    dispatch({
        type: types.SET_TO_DATE,
        payload: value,
    });
}

export const setSalesRep = (value) => (dispatch) => {
    dispatch({
        type: types.SET_SALES_REP,
        payload: value,
    });
}
