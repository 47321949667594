import React from 'react';
import { Dialog } from './../../common/dialog';
import moment from 'moment-timezone';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { empty } from './../../../utils';

const FormModal = React.memo((props) => {
    const hideHandler = () => {
        props.hideFormModal();
        props.clearForm();
    };

    const handleSetField = (name, event) => {
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked;
        }

        props.setField(name, value);
    };

    const setDateFieldHandler = (name, event) => {
        let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');
        props.setField(name, value);
    };

    const getValue = (value) => {
        if (empty(value)) {
            return '';
        }

        return value;
    };

    let label = 'Save';
    if (props.savingItem) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    let content = <div className="ap-form-container text-center"><i className="fas fa-circle-notch fa-spin" /> Loading Data...</div>
    if (!props.fetchingItem) {
        if (parseInt(props.type) === 4) {
            content = (
                <div className="ap-form-container">
                    <h1>Payroll Report</h1>
                    <div className="fields">
                        <div className="field">
                            <div>Date:</div>
                            <DayPickerInput
                                formatDate={formatDate}
                                parseDate={parseDate}
                                placeholder=""
                                className="date-picker-field"
                                onDayChange={setDateFieldHandler.bind(this, 'date')}
                                value={props.fields.date == null || props.fields.date === '0000-00-00' ? '' : moment(props.fields.date).format('MM/DD/YYYY')}
                            />
                        </div>
                        <div className="field">
                            <div>Description:</div>
                            <input type="text" value={getValue(props.fields.description)} onChange={handleSetField.bind(this, 'description')} />
                        </div>
                        <div className="field">
                            <div>Receipt:</div>
                            <input type="text" value={getValue(props.fields.receipt)} onChange={handleSetField.bind(this, 'receipt')} />
                        </div>
                        <div className="field">
                            <div>Card Member:</div>
                            <input type="text" value={getValue(props.fields.card_member)} onChange={handleSetField.bind(this, 'card_member')} />
                        </div>
                        <div className="field">
                            <div>Account #:</div>
                            <input type="text" value={getValue(props.fields.account)} onChange={handleSetField.bind(this, 'account')} />
                        </div>
                        <div className="field">
                            <div>Amount:</div>
                            <input type="text" value={getValue(props.fields.amount)} onChange={handleSetField.bind(this, 'amount')} />
                        </div>
                        <div className="field">
                            <div>Appears As:</div>
                            <input type="text" value={getValue(props.fields.appears_as)} onChange={handleSetField.bind(this, 'appears_as')} />
                        </div>
                        <div className="field">
                            <div>Address:</div>
                            <input type="text" value={getValue(props.fields.address)} onChange={handleSetField.bind(this, 'address')} />
                        </div>
                        <div className="field">
                            <div>City/State:</div>
                            <input type="text" value={getValue(props.fields.city_state)} onChange={handleSetField.bind(this, 'city_state')} />
                        </div>
                        <div className="field">
                            <div>Zip:</div>
                            <input type="text" value={getValue(props.fields.zip)} onChange={handleSetField.bind(this, 'zip')} />
                        </div>
                        <div className="field">
                            <div>Country:</div>
                            <input type="text" value={getValue(props.fields.country)} onChange={handleSetField.bind(this, 'country')} />
                        </div>
                        <div className="field">
                            <div>Reference:</div>
                            <input type="text" value={getValue(props.fields.reference)} onChange={handleSetField.bind(this, 'reference')} />
                        </div>
                        <div className="field">
                            <div>Category:</div>
                            <input type="text" value={getValue(props.fields.category)} onChange={handleSetField.bind(this, 'category')} />
                        </div>
                        <div className="field">
                            <div>Account:</div>
                            <select value={getValue(props.fields.ap_account_id)} onChange={handleSetField.bind(this, 'ap_account_id')}>
                                <option value="">Select account</option>
                                {props.information.accounts.map((account) => {
                                    return <option value={account.id}>{account.account} - {account.name}</option>;
                                })}
                            </select>
                        </div>
                        <div className="field">
                            <div>Create Bill:</div>
                            <input type="checkbox" checked={props.fields.create_bill} onChange={handleSetField.bind(this, 'create_bill')} />
                        </div>
                    </div>
                </div>
            );
        } else if (parseInt(props.type) === 5 || parseInt(props.type) === 6) {
            content = (
                <div className="ap-form-container">
                    <h1>Payroll Report</h1>
                    <div className="fields">
                        <div className="field">
                            <div>Transaction Date:</div>
                            <DayPickerInput
                                formatDate={formatDate}
                                parseDate={parseDate}
                                placeholder=""
                                className="date-picker-field"
                                onDayChange={setDateFieldHandler.bind(this, 'date')}
                                value={props.fields.date == null || props.fields.date === '0000-00-00' ? '' : moment(props.fields.date).format('MM/DD/YYYY')}
                            />
                        </div>
                        <div className="field">
                            <div>Posted Date:</div>
                            <DayPickerInput
                                formatDate={formatDate}
                                parseDate={parseDate}
                                placeholder=""
                                className="date-picker-field"
                                onDayChange={setDateFieldHandler.bind(this, 'posted_date')}
                                value={props.fields.posted_date == null || props.fields.posted_date === '0000-00-00' ? '' : moment(props.fields.posted_date).format('MM/DD/YYYY')}
                            />
                        </div>
                        <div className="field">
                            <div>Description:</div>
                            <input type="text" value={getValue(props.fields.description)} onChange={handleSetField.bind(this, 'description')} />
                        </div>
                        <div className="field">
                            <div>Card Number:</div>
                            <input type="text" value={getValue(props.fields.card_number)} onChange={handleSetField.bind(this, 'card_number')} />
                        </div>
                        <div className="field">
                            <div>Category:</div>
                            <input type="text" value={getValue(props.fields.category)} onChange={handleSetField.bind(this, 'category')} />
                        </div>
                        <div className="field">
                            <div>Debit:</div>
                            <input type="text" value={getValue(props.fields.debit)} onChange={handleSetField.bind(this, 'debit')} />
                        </div>
                        <div className="field">
                            <div>Credit:</div>
                            <input type="text" value={getValue(props.fields.credit)} onChange={handleSetField.bind(this, 'credit')} />
                        </div>
                        <div className="field">
                            <div>Account:</div>
                            <select value={getValue(props.fields.ap_account_id)} onChange={handleSetField.bind(this, 'ap_account_id')}>
                                <option value="">Select account</option>
                                {props.information.accounts.map((account) => {
                                    return <option value={account.id}>{account.account} - {account.name}</option>;
                                })}
                            </select>
                        </div>
                        <div className="field">
                            <div>Create Bill:</div>
                            <input type="checkbox" checked={props.fields.create_bill} onChange={handleSetField.bind(this, 'create_bill')} />
                        </div>
                    </div>
                </div>
            );
        }
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Cancel</button>,
        <button key="upload-button" className="btn btn-light btn-sm action-button" onClick={props.saveItem} disabled={props.savingItem}>{label}</button>
    ];
    return (
        <Dialog
            name="ap-form-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="ap-form-dashboard-dialog"
        >
            {content}
        </Dialog>
    );
});

export default FormModal;
