export const FETCH_ESTIMATES = 'ESTIMATES/FETCH_ESTIMATES';
export const FETCH_ESTIMATES_SUCCESS = 'ESTIMATES/FETCH_ESTIMATES_SUCCESS';
export const FETCH_ESTIMATES_ERROR = 'ESTIMATES/FETCH_ESTIMATES_ERROR';
export const DELETE_ESTIMATE = 'ESTIMATES/DELETE_ESTIMATE';
export const DELETE_ESTIMATE_SUCCESS = 'ESTIMATES/DELETE_ESTIMATE_SUCCESS';
export const DELETE_ESTIMATE_ERROR = 'ESTIMATES/DELETE_ESTIMATE_ERROR';
export const FETCH_CUSTOMERS = 'ESTIMATES/FETCH_CUSTOMERS';
export const FETCH_CUSTOMERS_SUCCESS = 'ESTIMATES/FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_ERROR = 'ESTIMATES/FETCH_CUSTOMERS_ERROR';
export const RESET_STATE = 'ESTIMATES/RESET_STATE';
export const SET_ID = 'ESTIMATES/SET_ID';
export const SET_SORT = 'ESTIMATES/SET_SORT';
export const SET_ACTIVE_PAGE = 'ESTIMATES/SET_ACTIVE_PAGE';
export const SET_ID_FOR_DELETE = 'ESTIMATES/SET_ID_FOR_DELETE';
export const CHANGE_FILTER = 'ESTIMATES/CHANGE_FILTER';
export const SET_ESTIMATE_TYPE = 'ESTIMATES/SET_ESTIMATE_TYPE';
export const SET_CUSTOMER_ID = 'ESTIMATES/SET_CUSTOMER_ID';