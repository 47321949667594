import { createSelector } from 'reselect';

export const getComponentState = state => state.ccPaymentAuth;

export const getFields = createSelector(
    getComponentState,
    (state) => state.fields
);
export const getSavingForm = createSelector(
    getComponentState,
    (state) => state.savingForm
);