import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from './Table';
import DeleteModal from './DeleteModal';
import * as actions from './CreditMemos.actions';
import * as selectors from './CreditMemos.selectors';
import './CreditMemos.scss';
import PqtLayout from './../PqtLayout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import * as dialog from '../common/dialog';
import * as loginSelectors from './../Login/Login.selectors';
import { Redirect } from 'react-router-dom';

const CreditMemos = (props) => {
    /* State to props */
    const fetchingCreditMemos = useSelector(selectors.getFetchingCreditMemos);
    const creditMemosData = useSelector(selectors.getCreditMemosData, stringifyEqualityCheck);
    const pagination = useSelector(selectors.getPagination, stringifyEqualityCheck);
    const sort = useSelector(selectors.getSort, stringifyEqualityCheck);
    const idForDelete = useSelector(selectors.getIdForDelete, stringifyEqualityCheck);
    const isDeleting = useSelector(selectors.getIsDeleting, stringifyEqualityCheck);
    const isExporting = useSelector(selectors.getIsExporting);
    const filterFields = useSelector(selectors.getFilterFields, stringifyEqualityCheck);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const deleteCreditMemo = useCallback((id) => dispatch(actions.deleteCreditMemo(id)), [dispatch]);
    const setSort = useCallback((column, type) => dispatch(actions.setSort(column, type)), [dispatch]);
    const setActivePage = useCallback((name, value) => dispatch(actions.setActivePage(name, value)), [dispatch]);
    const setIdForDelete = useCallback((id) => dispatch(actions.setIdForDelete(id)), [dispatch]);
    const changeFilter = useCallback((name, value) => dispatch(actions.changeFilter(name, value)), [dispatch]);
    const exportExcel = useCallback(() => dispatch(actions.exportExcel()), [dispatch]);
    const showDeleteModal = useCallback(() => dispatch(dialog.actions.showDialog('delete-modal')), [dispatch]);
    const hideDeleteModal = useCallback(() => dispatch(dialog.actions.hideDialog('delete-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        dispatch(actions.mountComponent());
    }, [dispatch]);

    // Kimberly (26), Heather (28),, Randy (35), Debbie (42), Skip (48), John (52), Kevin Wilson (53), Gary (58), Robert (59), Hugh (61), Norm (63), 
    // Christy (64), Kellie (75), Brenda (100), Grace (348), AJ (571), Lupe (34), April (89), Stephanie (2419), Kassandra (3566)
    if (user != null && ![26, 28, 35, 42, 48, 52, 53, 58, 59, 61, 63, 64, 75, 100, 348, 571, 34, 89, 2419, 3566].includes(parseInt(user.id))) {
        return <Redirect to="/dashboard" />;
    }

    return (
        <PqtLayout title="Credit Memos" history={props.history}>
            <Helmet><title>Credit Memos - Stagecoach</title></Helmet>
            <div className="credit-memos-container">
                <Table
                    creditMemosData={creditMemosData}
                    pagination={pagination}
                    fetchingCreditMemos={fetchingCreditMemos}
                    history={props.history}
                    sort={sort}
                    user={user}
                    isExporting={isExporting}
                    filterFields={filterFields}
                    setSort={setSort}
                    setActivePage={setActivePage}
                    showDeleteModal={showDeleteModal}
                    setIdForDelete={setIdForDelete}
                    changeFilter={changeFilter}
                    exportExcel={exportExcel}
                />
                <DeleteModal
                    idForDelete={idForDelete}
                    isDeleting={isDeleting}
                    deleteCreditMemo={deleteCreditMemo}
                    hideDeleteModal={hideDeleteModal}
                    setIdForDelete={setIdForDelete}
                />
            </div>
        </PqtLayout>
    );
};

CreditMemos.propTypes = {}

export default CreditMemos;
