import Immutable from 'immutable';
import * as types from './Search.types';

const initialState = {
    fetchingActive: false,
    fetchingFinished: false,
    activeResults: [],
    finishedResults: [],
    activePagination: {
        totalElements: 0,
        activePage: 1,
        totalPages: 0,
        elementsPerPage: 10,
    },
    finishedPagination: {
        totalElements: 0,
        activePage: 1,
        totalPages: 0,
        elementsPerPage: 10,
    },
    search: '',
    showActiveJobs: 1,
    showFinishedJobs: 1,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.SEARCH_ACTIVE:
            return state.setIn(['fetchingActive'], !action.payload).toJS();

        case types.SEARCH_ACTIVE_SUCCESS:
            return state.setIn(['fetchingActive'], false)
                .setIn(['activeResults'], action.payload.elements)
                .setIn(['activePagination', 'totalElements'], action.payload.totalElements)
                .setIn(['activePagination', 'activePage'], action.payload.activePage)
                .setIn(['activePagination', 'totalPages'], action.payload.totalPages)
                .setIn(['activePagination', 'elementsPerPage'], action.payload.elementsPerPage)
                .toJS();

        case types.SEARCH_ACTIVE_ERROR:
            return state.setIn(['fetchingActive'], false).toJS();

        case types.SEARCH_FINISHED:
            return state.setIn(['fetchingFinished'], !action.payload).toJS();

        case types.SEARCH_FINISHED_SUCCESS:
            return state.setIn(['fetchingFinished'], false)
                .setIn(['finishedResults'], action.payload.elements)
                .setIn(['finishedPagination', 'totalElements'], action.payload.totalElements)
                .setIn(['finishedPagination', 'activePage'], action.payload.activePage)
                .setIn(['finishedPagination', 'totalPages'], action.payload.totalPages)
                .setIn(['finishedPagination', 'elementsPerPage'], action.payload.elementsPerPage)
                .toJS();

        case types.SEARCH_FINISHED_ERROR:
            return state.setIn(['fetchingFinished'], false).toJS();

        case types.SET_ACTIVE_PAGE:
            return state.setIn(['activePagination', 'activePage'], action.payload).toJS();

        case types.SET_FINISHED_PAGE:
            return state.setIn(['finishedPagination', 'activePage'], action.payload).toJS();

        case types.SET_SEARCH:
            return state.setIn(['search'], action.payload).toJS();

        case types.SET_SHOW_ACTIVE_JOBS:
            return state.setIn(['showActiveJobs'], action.payload).toJS();

        case types.SET_SHOW_FINISHED_JOBS:
            return state.setIn(['showFinishedJobs'], action.payload).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}