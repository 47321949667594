import { createSelector } from 'reselect';

export const getComponentState = state => state.apAccounts;

export const getFetchingAccounts = createSelector(
    getComponentState,
    (state) => state.fetchingAccounts
);
export const getFetchingAccount = createSelector(
    getComponentState,
    (state) => state.fetchingAccount
);
export const getAccounts = createSelector(
    getComponentState,
    (state) => state.accounts
);
export const getPagination = createSelector(
    getComponentState,
    (state) => state.pagination
);
export const getSort = createSelector(
    getComponentState,
    (state) => state.sort
);
export const getIdForDelete = createSelector(
    getComponentState,
    (state) => state.delete.id
);
export const getIsDeleting = createSelector(
    getComponentState,
    (state) => state.delete.isDeleting
);
export const getSavingAccount = createSelector(
    getComponentState,
    (state) => state.savingAccount
);
export const getFilterFields = createSelector(
    getComponentState,
    (state) => state.filter.fields
);
export const getFields = createSelector(
    getComponentState,
    (state) => state.fields
);