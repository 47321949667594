import axios from 'axios';
import * as types from './PqtTracking.types';
import * as selectors from './PqtTracking.selectors';
import * as pqtDataProcessingActions from './../PqtDataProcessing/PqtDataProcessing.actions';
import * as pqtPostalQualificationActions from './../PqtPostalQualification/PqtPostalQualification.actions';
import * as dialog from '../common/dialog';
import {
    showDataSavedNotification,
    showCompleteDataNotification,
    showDataDeletedNotification,
    validateAuthInResponse,
    sendWsMessage,
    showNotification,
} from './../../utils';
import fileDownload from 'js-file-download';

const apiUrl = process.env.REACT_APP_API_URL;

export const setId = id => dispatch => {
    dispatch({
        type: types.SET_ID,
        payload: id
    });
}

export const setIsEditing = value => dispatch => {
    dispatch({
        type: types.SET_IS_EDITING,
        payload: value
    });
}

export const setField = (name, value) => dispatch => {
    dispatch({
        type: types.SET_FIELD,
        payload: { name, value }
    });
}

export const setStepField = (index, name, value) => dispatch => {
    dispatch({
        type: types.SET_STEP_FIELD,
        payload: { index, name, value }
    });
}

export const setHistory = history => dispatch => {
    dispatch({
        type: types.SET_HISTORY,
        payload: history
    });
}

export const saveTracking = () => (dispatch, getState) => {
    const tracking = selectors.getTracking(getState());

    let attributes = [
        'customer_id',
        'sales_rep_id',
        'csr_id',
        'due_date',
        'job_description',
        'quantity',
        'project_type',
    ];
    for (let i = 0; i < attributes.length; i++) {
        if (tracking[attributes[i]] === undefined || tracking[attributes[i]] === '') {
            return showCompleteDataNotification();
        }
    }

    if (tracking.is_mail_project) {
        if (tracking.project_mail === undefined || tracking.project_mail === '') {
            return showCompleteDataNotification();
        }
        if (tracking.mail_type === undefined || tracking.mail_type === '') {
            return showCompleteDataNotification();
        }
        if (tracking.post_office === undefined || tracking.post_office === '') {
            return showCompleteDataNotification();
        }

        let projectMail = parseInt(tracking.project_mail);
        if (projectMail !== 3 && projectMail !== 4 && (tracking.permit_number === undefined || tracking.permit_number === '')) {
            return showCompleteDataNotification();
        }
    }
    if (tracking.is_mail_project_2) {
        if (tracking.project_mail_2 === undefined || tracking.project_mail_2 === '') {
            return showCompleteDataNotification();
        }
        if (tracking.mail_type_2 === undefined || tracking.mail_type_2 === '') {
            return showCompleteDataNotification();
        }
        if (tracking.post_office_2 === undefined || tracking.post_office_2 === '') {
            return showCompleteDataNotification();
        }

        let projectMail = parseInt(tracking.project_mail_2);
        if (projectMail !== 3 && projectMail !== 4 && (tracking.permit_number_2 === undefined || tracking.permit_number_2 === '')) {
            return showCompleteDataNotification();
        }
    }

    tracking.due_date += ' 12:00:00';

    dispatch({ type: types.SAVE_TRACKING, payload: null });
    let promise;
    let saveType;
    if (tracking.id === undefined) {
        saveType = 1;
        promise = axios.post(apiUrl + '/pqt-trackings', tracking, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } });
    } else {
        saveType = 2;
        promise = axios.put(apiUrl + '/pqt-trackings/' + tracking.id, tracking, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } });
    }

    promise
        .then((response) => {
            dispatch({ type: types.SAVE_TRACKING_SUCCESS, payload: { tracking: response.data, saveType } });
            showDataSavedNotification();
            const history = selectors.getHistory(getState());
            if (tracking.id === undefined) {
                dispatch(sendWsMessage({ id: response.data.id, type: 'track_created' }));
                history.push('/tracking/' + response.data.id);
            } else {
                dispatch(setIsEditing(false));
                dispatch(fetchTracking(parseInt(tracking.id)));
                dispatch(sendWsMessage({ id: response.data.id, type: 'track_updated' }));
            }
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.SAVE_TRACKING_ERROR));
        });
}

export const fetchInformation = () => dispatch => {
    dispatch({ type: types.FETCH_INFORMATION, payload: null });
    axios.get(apiUrl + '/pqt-tracking/information',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_INFORMATION_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_INFORMATION_ERROR));
        });
}

export const fetchTracking = id => dispatch => {
    const fields = [
        'id',
        'customer_id',
        'customer_name',
        'sales_rep_id',
        'sales_rep_name',
        'csr_id',
        'csr_name',
        'due_date',
        'start_date',
        'finished_date',
        'job_number',
        'job_description',
        'status',
        'quantity',
        'dp_time',
        'tp_time',
        'order_life_cycle',
        'lifecycle',
        'countdown',
        'countdown_class',
        'created_at',
        'invoice_id',
        'postage_invoice_id',
        'project_type',
        'project_type_name',
        'project_mail',
        'project_mail_name',
        'permit_number',
        'mail_type',
        'mail_type_name',
        'post_office',
        'is_mail_project',
        'project_mail_2',
        'project_mail_name_2',
        'permit_number_2',
        'mail_type_2',
        'mail_type_name_2',
        'post_office_2',
        'is_mail_project_2',
        'file_url',
        'part_description',
        'last_paused_due_date',
        'quote_number',
        'sc_estimate_id',
        'sc_estimate_type',
        'sc_quote_request_id',
        'on_hold_status',
        'check_bounced',
        'sc_estimate_files',
        'is_uth_ticket',
        'mailing_notifications_emails',
        'shipping_notifications_emails',
        'closeout_notifications_emails',
        'is_ac',
        'rfq_artwork_required',
        'invoice_ids',
        'dp_mail_dat_filename',
        'dp_mail_dat_file_url',
        'pa_mail_dat_filename',
        'pa_mail_dat_file_url',
        'steps.id',
        'steps.department_id',
        'steps.subdepartment_id',
        'steps.step_order',
        'steps.notes',
        'steps.status',
        'steps.start_date',
        'steps.finished_date',
        'steps.created_at',
        'steps.employee_id',
        'steps.assigned_employees_ids',
        'steps.assigned_subdepartments_ids',
        'steps.assigned_vendors_ids',
        'steps.pqt_data_processing_id',
        'steps.pqt_postal_qualification_id',
        'steps.department_manager_id',
        'steps.data_processing_information',
        'steps.postal_qualification_information',
        'steps.out_on_proof',
        'steps.out_on_proof_datetime',
        'steps.subdepartments_finished',
        'steps.po_number',
        'steps.vendor_quote',
        'steps.vendor_price',
        'steps.shipping_method',
        'steps.tracking_number',
        'steps.is_items',
        'steps.is_embroidery',
        'steps.is_screen_printing',
        'steps.postage',
        'steps.pause_date',
        'steps.comments_json',
        'files.id',
        'files.filename',
        'files.url',
    ];
    const extraFields = ['steps', 'auditHistory', 'files'];
    dispatch({ type: types.FETCH_TRACKING, payload: null });
    axios.get(apiUrl + '/pqt-tracking/' + id + '?fields=' + fields.join(',') + '&expand=' + extraFields.join(','),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_TRACKING_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            console.log(error)
            dispatch(validateAuthInResponse(error, types.FETCH_TRACKING_ERROR));
        });
}

export const saveTrackingStep = (index) => (dispatch, getState) => {
    const tracking = selectors.getTracking(getState());
    const information = selectors.getInformation(getState());

    let attributes = [
        'department_id',
        'step_order',
    ];
    for (let i = 0; i < attributes.length; i++) {
        if (tracking.steps[index][attributes[i]] === undefined || tracking.steps[index][attributes[i]] === '') {
            showCompleteDataNotification();
            return;
        }
    }

    let departmentIds = [];
    for (let i = 0; i < tracking.steps.length; i++) {
        const step = tracking.steps[i];
        const departmentId = parseInt(step.department_id);
        if (departmentIds.includes(departmentId)) {
            showNotification(
                'Duplicated Department',
                'You have selected the same department in two different steps. Please, remove one of them.',
                'info'
            );
            return;
        } else {
            departmentIds.push(departmentId);
        }
    }

    let selectedDepartment = null;
    for (let i = 0; i < information.departments.length; i++) {
        const department = information.departments[i];
        if (parseInt(department.id) === parseInt(tracking.steps[index].department_id)) {
            selectedDepartment = department;
            break;
        }
    }

    if (selectedDepartment.subdepartments.length > 0 && tracking.steps[index].assigned_subdepartments_ids.length === 0) {
        showNotification(
            'Complete Department Information',
            'The department "' + selectedDepartment.name + '" does not have assigned tasks.',
            'info'
        );
        return;
    }

    dispatch({ type: types.SAVE_TRACKING_STEP, payload: index });
    let promise;
    let saveType;
    if (tracking.steps[index].id === undefined) {
        saveType = 1;
        promise = axios.post(apiUrl + '/pqt-tracking-steps',
            tracking.steps[index],
            { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } }
        );
    } else {
        saveType = 2;
        promise = axios.put(apiUrl + '/pqt-tracking-steps/' + tracking.steps[index].id,
            tracking.steps[index],
            { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } }
        );
    }

    promise
        .then((response) => {
            dispatch({ type: types.SAVE_TRACKING_STEP_SUCCESS, payload: { step: response.data, saveType } });
            dispatch(setStep(index, response.data));
            showDataSavedNotification();
            dispatch(fetchTracking(parseInt(tracking.id)));
            dispatch(sendWsMessage({ id: tracking.id, type: 'step_updated' }));
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.SAVE_TRACKING_STEP_ERROR));
        });
}

export const uploadFiles = () => (dispatch, getState) => {
    const tracking = selectors.getTracking(getState());
    const files = selectors.getFiles(getState());

    if (files.length === 0) {
        return showNotification('No Files', 'There are no files to upload', 'info');
    }

    var formData = new FormData();
    formData.append('id', tracking.id);
    for (let i = 1; i <= files.length; i++) {
        if (!files[i - 1].url) {
            formData.append('file' + i, files[i - 1]);
        } else {
            formData.append('file' + i, JSON.stringify(files[i - 1]));
        }
    }
    formData.append('filesLength', files.length);

    dispatch({ type: types.UPLOAD_FILES, payload: null });
    axios({
        method: 'post',
        url: apiUrl + '/pqt-tracking/upload-files',
        data: formData,
        headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
    }).then((response) => {
        dispatch({ type: types.UPLOAD_FILES_SUCCESS, payload: response.data });
        showNotification('Files Saved', 'The files has been saved successfully', 'success');
        dispatch(fetchTracking(parseInt(tracking.id)));
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.UPLOAD_FILES_ERROR));
    });
}

export const deleteFile = (id) => (dispatch, getState) => {
    const tracking = selectors.getTracking(getState());

    dispatch({ type: types.DELETE_FILE, payload: null });
    axios.delete(apiUrl + '/pqt-tracking/delete-file?id=' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } }
    ).then(() => {
        dispatch({ type: types.DELETE_FILE_SUCCESS, payload: null });
        showNotification('File Deleted', 'The file has been deleted successfully', 'success');
        dispatch(fetchTracking(parseInt(tracking.id)));
        dispatch(dialog.actions.hideDialog('delete-file-modal'));
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.DELETE_FILE_ERROR));
    });
}

export const addStep = () => (dispatch, getState) => {
    const tracking = selectors.getTracking(getState());
    dispatch({
        type: types.ADD_STEP,
        payload: tracking.id
    });
}

export const setStep = (index, step) => dispatch => {
    dispatch({
        type: types.SET_STEP,
        payload: { index, step }
    });
}

export const resetState = () => dispatch => {
    dispatch({
        type: types.RESET_STATE,
        payload: null
    });
}

export const deleteTrackingStep = (index) => (dispatch, getState) => {
    const tracking = selectors.getTracking(getState());

    if (!tracking.steps[index].id) {
        dispatch(fetchTracking(parseInt(tracking.id)));
        return;
    }

    dispatch({ type: types.DELETE_TRACKING_STEP, payload: index });
    axios.delete(apiUrl + '/pqt-tracking-steps/' + tracking.steps[index].id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } }
    ).then(() => {
        dispatch({ type: types.DELETE_TRACKING_STEP_SUCCESS, payload: index });
        showDataDeletedNotification();
        dispatch(fetchTracking(parseInt(tracking.id)));
        dispatch(sendWsMessage({ id: tracking.id, type: 'step_deleted' }));
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.DELETE_TRACKING_STEP_ERROR));
    });
}

export const addAssignedPeople = (index, id) => dispatch => {
    if (id === undefined || id === null || id === '') {
        showNotification(
            'Select Operator',
            'Select the operator that will work on this step',
            'info'
        );
        return;
    }

    dispatch({
        type: types.ADD_ASSIGNED_PEOPLE,
        payload: { index, id: parseInt(id) }
    });
}

export const removeAssignedPeople = (stepIndex, idIndex) => dispatch => {
    dispatch({
        type: types.REMOVE_ASSIGNED_PEOPLE,
        payload: { stepIndex, idIndex }
    });
}

export const addAssignedVendor = (index, id) => dispatch => {
    if (id === undefined || id === null || id === '') {
        showNotification(
            'Select Vendor',
            'Select the vendor that will work on this step',
            'info'
        );
        return;
    }

    dispatch({
        type: types.ADD_ASSIGNED_VENDOR,
        payload: { index, id: parseInt(id) }
    });
}

export const removeAssignedVendor = (stepIndex, idIndex) => dispatch => {
    dispatch({
        type: types.REMOVE_ASSIGNED_VENDOR,
        payload: { stepIndex, idIndex }
    });
}

export const addSubdepartment = (index, id) => dispatch => {
    if (id === undefined || id === null || id === '') {
        showNotification(
            'Select Task',
            'Select a tas that needs to be completed on this step',
            'info'
        );
        return;
    }

    dispatch({
        type: types.ADD_SUBDEPARTMENT,
        payload: { index, id: parseInt(id) }
    });
}

export const removeSubdepartment = (stepIndex, idIndex) => dispatch => {
    dispatch({
        type: types.REMOVE_SUBDEPARTMENT,
        payload: { stepIndex, idIndex }
    });
}

export const closeTrackingStep = index => (dispatch, getState) => {
    const tracking = selectors.getTracking(getState());
    dispatch({ type: types.CLOSE_TRACKING_STEP, payload: index });
    axios.post(apiUrl + '/pqt-tracking-step/close-out',
        { id: tracking.steps[index].id, stepOrder: tracking.steps[index].step_order },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } }
    ).then((response) => {
        dispatch({ type: types.CLOSE_TRACKING_STEP_SUCCESS, payload: { element: response.data, index } });
        dispatch(fetchTracking(tracking.id))
        showDataSavedNotification();
        dispatch(sendWsMessage({ id: tracking.id, type: 'step_closed' }));
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.CLOSE_TRACKING_STEP_ERROR));
    });
}

export const startTracking = () => (dispatch, getState) => {
    const fields = [
        'id',
        'customer_id',
        'customer_name',
        'sales_rep_id',
        'sales_rep_name',
        'csr_id',
        'csr_name',
        'due_date',
        'start_date',
        'finished_date',
        'job_number',
        'job_description',
        'status',
        'quantity',
        'dp_time',
        'tp_time',
        'order_life_cycle',
        'lifecycle',
        'countdown',
        'countdown_class',
        'created_at',
        'invoice_id',
        'postage_invoice_id',
        'project_type',
        'project_type_name',
        'project_mail',
        'project_mail_name',
        'permit_number',
        'mail_type',
        'mail_type_name',
        'post_office',
        'is_mail_project',
        'project_mail_2',
        'project_mail_name_2',
        'permit_number_2',
        'mail_type_2',
        'mail_type_name_2',
        'post_office_2',
        'is_mail_project_2',
        'file_url',
        'part_description',
        'last_paused_due_date',
        'quote_number',
        'sc_estimate_id',
        'sc_estimate_type',
        'sc_quote_request_id',
        'on_hold_status',
        'check_bounced',
        'sc_estimate_files',
        'is_uth_ticket',
        'mailing_notifications_emails',
        'shipping_notifications_emails',
        'closeout_notifications_emails',
        'is_ac',
        'rfq_artwork_required',
        'invoice_ids',
        'dp_mail_dat_filename',
        'dp_mail_dat_file_url',
        'pa_mail_dat_filename',
        'pa_mail_dat_file_url',
        'steps.id',
        'steps.department_id',
        'steps.subdepartment_id',
        'steps.step_order',
        'steps.notes',
        'steps.status',
        'steps.start_date',
        'steps.finished_date',
        'steps.created_at',
        'steps.employee_id',
        'steps.assigned_employees_ids',
        'steps.assigned_subdepartments_ids',
        'steps.assigned_vendors_ids',
        'steps.pqt_data_processing_id',
        'steps.pqt_postal_qualification_id',
        'steps.department_manager_id',
        'steps.data_processing_information',
        'steps.postal_qualification_information',
        'steps.out_on_proof',
        'steps.out_on_proof_datetime',
        'steps.subdepartments_finished',
        'steps.shipping_method',
        'steps.tracking_number',
        'steps.is_items',
        'steps.is_embroidery',
        'steps.is_screen_printing',
        'steps.postage',
        'steps.pause_date',
        'steps.comments_json',
        'files.id',
        'files.filename',
        'files.url',
    ];
    const extraFields = ['steps', 'auditHistory', 'files'];

    const tracking = selectors.getTracking(getState());
    dispatch({ type: types.START_TRACKING, payload: null });
    axios.post(apiUrl + '/pqt-tracking/start?fields=' + fields.join(',') + '&expand=' + extraFields.join(','),
        { id: tracking.id },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } }
    ).then((response) => {
        dispatch({ type: types.START_TRACKING_SUCCESS, payload: response.data });
        showDataSavedNotification();
        dispatch(fetchTracking(parseInt(tracking.id)));
        dispatch(sendWsMessage({ id: tracking.id, type: 'track_started' }));
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.START_TRACKING_ERROR));
    });
}

export const mountComponent = (history, id) => (dispatch, getState) => {
    dispatch(resetState());
    dispatch(setHistory(history));
    dispatch(fetchInformation());

    if (id) {
        dispatch(setIsEditing(false));
        dispatch(setId(parseInt(id)));
        dispatch(fetchTracking(parseInt(id)));

        dispatch(pqtDataProcessingActions.setElementId(id));
        dispatch(pqtPostalQualificationActions.setElementId(id));
    } else {
        dispatch(setIsEditing(true));
    }
}

export const createPreliminary = () => (dispatch, getState) => {
    const fields = [
        'id',
        'customer_id',
        'customer_name',
        'sales_rep_id',
        'sales_rep_name',
        'csr_id',
        'csr_name',
        'due_date',
        'start_date',
        'finished_date',
        'job_number',
        'job_description',
        'status',
        'quantity',
        'dp_time',
        'tp_time',
        'order_life_cycle',
        'lifecycle',
        'countdown',
        'countdown_class',
        'created_at',
        'invoice_id',
        'postage_invoice_id',
        'project_type',
        'project_type_name',
        'project_mail',
        'project_mail_name',
        'permit_number',
        'mail_type',
        'mail_type_name',
        'post_office',
        'is_mail_project',
        'project_mail_2',
        'project_mail_name_2',
        'permit_number_2',
        'mail_type_2',
        'mail_type_name_2',
        'post_office_2',
        'is_mail_project_2',
        'file_url',
        'part_description',
        'last_paused_due_date',
        'quote_number',
        'sc_estimate_id',
        'sc_estimate_type',
        'sc_quote_request_id',
        'on_hold_status',
        'check_bounced',
        'sc_estimate_files',
        'is_uth_ticket',
        'mailing_notifications_emails',
        'shipping_notifications_emails',
        'closeout_notifications_emails',
        'is_ac',
        'rfq_artwork_required',
        'invoice_ids',
        'dp_mail_dat_filename',
        'dp_mail_dat_file_url',
        'pa_mail_dat_filename',
        'pa_mail_dat_file_url',
        'steps.id',
        'steps.department_id',
        'steps.subdepartment_id',
        'steps.step_order',
        'steps.notes',
        'steps.status',
        'steps.start_date',
        'steps.finished_date',
        'steps.created_at',
        'steps.employee_id',
        'steps.assigned_employees_ids',
        'steps.assigned_subdepartments_ids',
        'steps.assigned_vendors_ids',
        'steps.pqt_data_processing_id',
        'steps.pqt_postal_qualification_id',
        'steps.department_manager_id',
        'steps.data_processing_information',
        'steps.postal_qualification_information',
        'steps.out_on_proof',
        'steps.out_on_proof_datetime',
        'steps.subdepartments_finished',
        'steps.shipping_method',
        'steps.tracking_number',
        'steps.is_items',
        'steps.is_embroidery',
        'steps.is_screen_printing',
        'steps.postage',
        'steps.pause_date',
        'steps.comments_json',
        'files.id',
        'files.filename',
        'files.url',
    ];
    const extraFields = ['steps', 'auditHistory', 'files'];

    const tracking = selectors.getTracking(getState());
    dispatch({ type: types.CREATE_PRELIMINARY, payload: null });
    axios.post(apiUrl + '/pqt-tracking/create-preliminary?fields=' + fields.join(',') + '&expand=' + extraFields.join(','),
        { id: tracking.id },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } }
    ).then((response) => {
        dispatch({ type: types.CREATE_PRELIMINARY_SUCCESS, payload: response.data });
        showNotification(
            'Invoice Created!',
            'The invoice has been created successfully!',
            'success'
        );
        dispatch(fetchTracking(parseInt(tracking.id)));
        dispatch(sendWsMessage({ type: 'invoice_created' }));
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.CREATE_PRELIMINARY_ERROR));
    });
}

export const getPostageEscrow = (id, isCustomer) => (dispatch) => {
    let data = null;
    if (isCustomer) {
        data = { customerId: id, useSc: 0 };
    } else {
        data = { jobId: id, useSc: 0 };
    }

    dispatch({ type: types.GET_POSTAGE_ESCROW, payload: null });
    axios.post(apiUrl + '/pqt-tracking/get-postage-escrow', data,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } }
    ).then((response) => {
        dispatch({ type: types.GET_POSTAGE_ESCROW_SUCCESS, payload: response.data });
    }).catch((error) => {
        showNotification(
            'Could not fetch Postage Escrow',
            'We could not fetch the Postage Escrow for this customer, please contact your administrator',
            'warning'
        );
        dispatch({ type: types.GET_POSTAGE_ESCROW_ERROR, payload: null });
    });
}

export const pauseDepartment = (comments) => (dispatch, getState) => {
    const tracking = selectors.getTracking(getState());
    const idModal = selectors.getIdModal(getState());

    dispatch({ type: types.PAUSE_DEPARTMENT, payload: null });
    axios.post(apiUrl + '/pqt-tracking/pause-department',
        { id: tracking.id, departmentId: idModal, comments },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } }
    ).then((response) => {
        dispatch({ type: types.PAUSE_DEPARTMENT_SUCCESS, payload: response.data });
        showNotification(
            'Department Paused',
            'The department has been paused successfully!',
            'success'
        );
        dispatch(fetchTracking(parseInt(tracking.id)));
        dispatch(dialog.actions.hideDialog('pause-modal'));
        dispatch(sendWsMessage({ id: tracking.id, type: 'step_updated' }));
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.PAUSE_DEPARTMENT_ERROR));
    });
}

export const unpauseDepartment = () => (dispatch, getState) => {
    const tracking = selectors.getTracking(getState());
    const idModal = selectors.getIdModal(getState());

    dispatch({ type: types.UNPAUSE_DEPARTMENT, payload: null });
    axios.post(apiUrl + '/pqt-tracking/unpause-department',
        { id: tracking.id, departmentId: idModal },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } }
    ).then((response) => {
        dispatch({ type: types.UNPAUSE_DEPARTMENT_SUCCESS, payload: response.data });
        showNotification(
            'Department Paused',
            'The department has been paused successfully!',
            'success'
        );
        dispatch(fetchTracking(parseInt(tracking.id)));
        dispatch(dialog.actions.hideDialog('unpause-modal'));
        dispatch(sendWsMessage({ id: tracking.id, type: 'step_updated' }));
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.UNPAUSE_DEPARTMENT_ERROR));
    });
}

export const setIdModal = (id) => (dispatch) => {
    dispatch({
        type: types.SET_ID_MODAL,
        payload: id
    });
}

export const downloadPressTicket = () => (dispatch, getState) => {
    const trackingFields = selectors.getTrackingFields(getState());

    dispatch({ type: types.DOWNLOAD_PRESS_TICKET, payload: null });
    axios.post(apiUrl + '/sc-estimate/download-press-ticket', { id: trackingFields.sc_estimate_id, pqt_tracking_id: trackingFields.id },
        {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
            responseType: 'blob',
        })
        .then((response) => {
            dispatch({ type: types.DOWNLOAD_PRESS_TICKET_SUCCESS, payload: null });

            if (trackingFields.sc_estimate_id) {
                fileDownload(response.data, 'Press Ticket #' + trackingFields.sc_estimate_id + '.pdf');
            } else {
                fileDownload(response.data, 'Press Ticket.pdf');
            }
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.DOWNLOAD_PRESS_TICKET_ERROR));
        });
}

export const saveMailingNotificationsEmails = () => (dispatch, getState) => {
    const trackingFields = selectors.getTrackingFields(getState());

    dispatch({ type: types.SAVE_MAILING_NOTIFICATIONS_EMAILS, payload: null });
    axios.post(apiUrl + '/pqt-tracking/save-mailing-notifications-emails', trackingFields,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.SAVE_MAILING_NOTIFICATIONS_EMAILS_SUCCESS, payload: null });
            showNotification(
                'Emails Saved',
                'The Email Addresses have been saved successfully.',
                'success'
            );
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.SAVE_MAILING_NOTIFICATIONS_EMAILS_ERROR));
        });
}

export const saveShippingNotificationsEmails = () => (dispatch, getState) => {
    const trackingFields = selectors.getTrackingFields(getState());

    dispatch({ type: types.SAVE_SHIPPING_NOTIFICATIONS_EMAILS, payload: null });
    axios.post(apiUrl + '/pqt-tracking/save-shipping-notifications-emails', trackingFields,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.SAVE_SHIPPING_NOTIFICATIONS_EMAILS_SUCCESS, payload: null });
            showNotification(
                'Emails Saved',
                'The Email Addresses have been saved successfully.',
                'success'
            );
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.SAVE_SHIPPING_NOTIFICATIONS_EMAILS_ERROR));
        });
}

export const saveCloseoutNotificationsEmails = () => (dispatch, getState) => {
    const trackingFields = selectors.getTrackingFields(getState());

    dispatch({ type: types.SAVE_CLOSEOUT_NOTIFICATIONS_EMAILS, payload: null });
    axios.post(apiUrl + '/pqt-tracking/save-closeout-notifications-emails', trackingFields,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.SAVE_CLOSEOUT_NOTIFICATIONS_EMAILS_SUCCESS, payload: null });
            showNotification(
                'Emails Saved',
                'The Email Addresses have been saved successfully.',
                'success'
            );
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.SAVE_CLOSEOUT_NOTIFICATIONS_EMAILS_ERROR));
        });
}

export const addFile = (file) => (dispatch) => {
    dispatch({
        type: types.ADD_FILE,
        payload: file,
    });
}
export const removeFile = (index) => (dispatch) => {
    dispatch({
        type: types.REMOVE_FILE,
        payload: index,
    });
}
export const clearFiles = (index) => (dispatch) => {
    dispatch({
        type: types.CLEAR_FILES,
        payload: index,
    });
}