import { createSelector } from 'reselect';

export const getComponentState = state => state.creditMemo;

export const getFetching = createSelector(
    getComponentState,
    (state) => state.fetching
);

export const getCreditMemo = createSelector(
    getComponentState,
    (state) => state.creditMemo
);

export const getInformation = createSelector(
    getComponentState,
    (state) => state.information
);

export const getFetchingCustomerData = createSelector(
    getComponentState,
    (state) => state.fetchingCustomerData
);

export const getIsSaving = createSelector(
    getComponentState,
    (state) => state.isSaving
);

export const getIsPushing = createSelector(
    getComponentState,
    (state) => state.isPushing
);

export const getCustomerEmail = createSelector(
    getComponentState,
    (state) => state.customerEmail
);

export const getJobDueDate = createSelector(
    getComponentState,
    (state) => state.jobDueDate
);