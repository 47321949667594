import React from 'react';
import FormPopover from './FormPopover';
import moment from 'moment-timezone';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';

const MailOnlyForm = React.memo((props) => {
    const setDateFieldHandler = (name, event) => {
        let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');
        props.setField(name, value);
    };

    return (
        <div className="mail-only-form-container">
            <div className="line1">
                <div className="column1">
                    <div className="originals-field">
                        <b>Originals <span className="required">*</span>:</b>
                        <FormPopover
                            code="FLAT-001"
                            information={props.information}
                        />
                        <input type="number" value={props.fields.originals} onChange={props.handleSetField.bind(this, 'originals')} />
                    </div>
                    <div className="product-type-container">
                        <div><b>Product Type:</b></div>
                        <div className="product-type">{props.productTypeName}</div>
                        <div><b>Process Request:</b></div>
                        <div className="process-request">{props.processRequestName}</div>
                    </div>
                </div>
                <div className="column2">
                    <div className="title">
                        <b>Receiving:</b>
                    </div>
                    <div className="type-materials-field">
                        <b>Type of Materials <span className="required">*</span>:</b>
                        <input type="text" value={props.fields.type_of_materials} onChange={props.handleSetField.bind(this, 'type_of_materials')} />
                    </div>
                    <div className="material-coming-date-field">
                        <b>When are the material coming? <span className="required">*</span>:</b>
                        <DayPickerInput
                            formatDate={formatDate}
                            parseDate={parseDate}
                            placeholder=""
                            className="date-picker-field"
                            onDayChange={setDateFieldHandler.bind(this, 'material_coming_date')}
                            value={props.fields.material_coming_date == null || props.fields.material_coming_date === '0000-00-00' ? '' : moment(props.fields.material_coming_date).format('MM/DD/YYYY')}
                        />
                    </div>
                </div>
                <div className="column3">
                    <div className="title">
                        <b>Leftovers:</b>
                    </div>
                    <div className="leftovers-todo-field">
                        <b>What do do?:</b>
                        <select type="text" value={props.fields.leftovers_to_do} onChange={props.handleSetField.bind(this, 'leftovers_to_do')}>
                            <option value="">Select option</option>
                            <option value="1">Hold for client to pickup</option>
                            <option value="2">Ship back to client (chargeable)</option>
                            <option value="3">Recycle</option>
                        </select>
                    </div>
                </div>
                <div className="column4">
                    <div className="title">
                        <b>Size:</b>
                        <FormPopover
                            code="FLAT-004"
                            information={props.information}
                        />
                    </div>
                    <div className="finish-width-field">
                        <b>Finish Width <span className="required">*</span>:</b>
                        <input type="number" value={props.fields.finish_width} onChange={props.handleSetField.bind(this, 'finish_width')} />
                    </div>
                    <div className="x-field">X</div>
                    <div className="finish-height-field">
                        <b>Finish Height <span className="required">*</span>:</b>
                        <input type="number" value={props.fields.finish_height} onChange={props.handleSetField.bind(this, 'finish_height')} />
                    </div>
                </div>
            </div>
            <div className="line2">
                <div className="column1">
                    <div className="title"><b>Please describe your project in detail here:</b></div>
                    <textarea
                        placeholder="Please let us know more about your project detail"
                        value={props.fields.mail_only_description}
                        onChange={props.handleSetField.bind(this, 'mail_only_description')}
                    />
                </div>
            </div>
            {props.buttonsContainer}
        </div>
    );
});

export default MailOnlyForm;
