import React from 'react';
import { Dialog } from './../../common/dialog';
import numeral from 'numeral';

const ResultModal = React.memo((props) => {
    const hideHandler = () => {
        props.hideResultModal();
        props.clearForm();
    };

    // const handleSetField = (name, event) => {
    //     let value = event.target.value;
    //     if (event.target.type === 'checkbox') {
    //         value = event.target.checked;
    //     }

    //     props.setField(name, value);
    // };

    const saveHandler = () => {
        props.saveMemo();
    };

    let pushButtonLabel = 'Push To QB';
    if (props.pushingToQb) {
        pushButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    let pushButton = null;
    let content = <div className="payroll-result-container text-center"><i className="fas fa-circle-notch fa-spin" /> Loading Data...</div>
    if (!props.fetchingReport) {
        pushButton = <button key="push-button" className="btn btn-light btn-sm action-button" onClick={props.pushToQb} disabled={props.pushingToQb}>{pushButtonLabel}</button>;

        let errorClass = 'success';
        let icon = <div className="icon"><i className="fas fa-check-square" /></div>;
        let result = <div className="result result-success">You are good to go!</div>;
        if (parseFloat(props.fields.total_difference) !== 0) {
            errorClass = 'error';
            icon = <div className="icon"><i className="fas fa-exclamation-triangle" /></div>;
            result = <div className="result result-error">Oops! Something went wrong!</div>;
            pushButton = null;
        }

        content = (
            <div className="payroll-result-container">
                <div className="title-container">
                    <div className="title"><b>Title: {props.fields.title}</b></div>
                    {icon}
                </div>
                <div className="result-container">
                    {result}
                    <div className="error"><b>Error:</b> <span className={errorClass}>{numeral(props.fields.total_difference).format('$0,0.00')}</span></div>
                </div>
                {/* <div className="memo-field">
                    <div>Memo:</div>
                    <textarea value={props.fields.memo} onChange={handleSetField.bind(this, 'memo')} />
                </div> */}
            </div>
        );
    }

    let label = 'Save';
    if (props.savingMemo) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Cancel</button>,
        <button key="upload-button" className="btn btn-light btn-sm action-button" onClick={saveHandler} disabled={props.savingMemo}>{label}</button>,
        pushButton
    ];
    return (
        <Dialog
            name="payroll-result-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="payroll-result-dialog"
        >
            {content}
        </Dialog>
    );
});

export default ResultModal;
