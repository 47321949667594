import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Elements from './Elements';
import DeleteModal from './DeleteModal';
import FormModal from './FormModal';
import * as actions from './PqnPresses.actions';
import * as selectors from './PqnPresses.selectors';
import './PqnPresses.scss';
import PqtLayout from './../PqtLayout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import * as dialog from '../common/dialog';
import * as loginSelectors from './../Login/Login.selectors';
import { Redirect } from 'react-router-dom';

const PqnPresses = (props) => {
    /* State to props */
    const fetchingElements = useSelector(selectors.getFetchingElements);
    const elements = useSelector(selectors.getElements, stringifyEqualityCheck);
    const pagination = useSelector(selectors.getPagination, stringifyEqualityCheck);
    const sort = useSelector(selectors.getSort, stringifyEqualityCheck);
    const idForDelete = useSelector(selectors.getIdForDelete, stringifyEqualityCheck);
    const filerFields = useSelector(selectors.getFilterFields, stringifyEqualityCheck);
    const fields = useSelector(selectors.getFields, stringifyEqualityCheck);
    const savingElement = useSelector(selectors.getSavingElement);
    const fetchingElement = useSelector(selectors.getFetchingElement);
    const isDeleting = useSelector(selectors.getIsDeleting);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const setSort = useCallback((column, type) => dispatch(actions.setSort(column, type)), [dispatch]);
    const setActivePage = useCallback((name, value) => dispatch(actions.setActivePage(name, value)), [dispatch]);
    const fetchElements = useCallback((options, isWsUpdate) => dispatch(actions.fetchElements(options, isWsUpdate)), [dispatch]);
    const setIdForDelete = useCallback((id) => dispatch(actions.setIdForDelete(id)), [dispatch]);
    const changeFilter = useCallback((name, value, fetch) => dispatch(actions.changeFilter(name, value, fetch)), [dispatch]);
    const setField = useCallback((field, value) => dispatch(actions.setField(field, value)), [dispatch]);
    const saveElement = useCallback(() => dispatch(actions.saveElement()), [dispatch]);
    const resetFields = useCallback((value) => dispatch(actions.resetFields(value)), [dispatch]);
    const fetchElement = useCallback((id) => dispatch(actions.fetchElement(id)), [dispatch]);
    const deleteElement = useCallback(() => dispatch(actions.deleteElement()), [dispatch]);
    const showDeleteModal = useCallback(() => dispatch(dialog.actions.showDialog('delete-modal')), [dispatch]);
    const hideDeleteModal = useCallback(() => dispatch(dialog.actions.hideDialog('delete-modal')), [dispatch]);
    const showFormModal = useCallback(() => dispatch(dialog.actions.showDialog('pqn-press-form-modal')), [dispatch]);
    const hideFormModal = useCallback(() => dispatch(dialog.actions.hideDialog('pqn-press-form-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        fetchElements();
    }, [fetchElements]);

    if (user != null && parseInt(user.id) !== 61) {     // Hugh
        return <Redirect to="/dashboard" />;
    }

    return (
        <PqtLayout title="Presses" history={props.history}>
            <Helmet><title>Presses - Stagecoach</title></Helmet>
            <div className="pqn-presses-container">
                <Elements
                    elements={elements}
                    pagination={pagination}
                    fetchingElements={fetchingElements}
                    history={props.history}
                    sort={sort}
                    user={user}
                    filerFields={filerFields}
                    setSort={setSort}
                    setActivePage={setActivePage}
                    showDeleteModal={showDeleteModal}
                    setIdForDelete={setIdForDelete}
                    changeFilter={changeFilter}
                    fetchElements={fetchElements}
                    fetchElement={fetchElement}
                    showFormModal={showFormModal}
                    setField={setField}
                />
                <DeleteModal
                    idForDelete={idForDelete}
                    isDeleting={isDeleting}
                    hideDeleteModal={hideDeleteModal}
                    setIdForDelete={setIdForDelete}
                    setField={setField}
                    deleteElement={deleteElement}
                />
                <FormModal 
                    fields={fields}
                    savingElement={savingElement}
                    fetchingElement={fetchingElement}
                    setField={setField}
                    hideFormModal={hideFormModal}
                    saveElement={saveElement}
                    resetFields={resetFields}
                />
            </div>
        </PqtLayout>
    );
};

export default PqnPresses;
