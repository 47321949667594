export const SAVE = 'PQT_DATA_PROCESSING/SAVE';
export const SAVE_SUCCESS = 'PQT_DATA_PROCESSING/SAVE_SUCCESS';
export const SAVE_ERROR = 'PQT_DATA_PROCESSING/SAVE_ERROR';
export const FETCH_ELEMENT = 'PQT_DATA_PROCESSING/FETCH_ELEMENT';
export const FETCH_ELEMENT_SUCCESS = 'PQT_DATA_PROCESSING/FETCH_ELEMENT_SUCCESS';
export const FETCH_ELEMENT_ERROR = 'PQT_DATA_PROCESSING/FETCH_ELEMENT_ERROR';
export const SET_FIELD = 'PQT_DATA_PROCESSING/SET_FIELD';
export const RESET_STATE = 'PQT_DATA_PROCESSING/RESET_STATE';
export const SET_TYPE = 'PQT_DATA_PROCESSING/SET_TYPE';
export const SET_ELEMENT_ID = 'PQT_DATA_PROCESSING/SET_ELEMENT_ID';
export const SET_SHOW_SAVE = 'PQT_DATA_PROCESSING/SET_SHOW_SAVE';