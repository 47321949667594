import Immutable from 'immutable';
import * as types from './PqtDashboard.types';

const initialState = {
    fetching: {
        information: false,
        quickGlance: false,
        lateJobs: false,
        myJobs: false,
    },
    data: {
        quickGlance: {
            csrs: [],
            salesReps: [],
            snapshot: [],
            departments: [],
            shiftCount: [],
            ppInvoices: [],
            closeout: 0,
            postageEscrowBalance: 0,
            invoices: [],
        },
        lateJobs: [],
        myJobs: [],
    },
    pagination: {
        lateJobs: {
            totalElements: 0,
            activePage: 1,
            totalPages: 0,
            elementsPerPage: 10,
        },
        myJobs: {
            totalElements: 0,
            activePage: 1,
            totalPages: 0,
            elementsPerPage: 15,
        },
    },
    sort: {
        lateJobs: {
            column: 'due_date',
            type: 'asc',
        },
        myJobs: {
            column: 'due_date',
            type: 'asc',
        },
    },
    information: {
        salesReps: [],
        csrs: [],
    },
    searchFields: {
        jobNumber: '',
        customer: '',
        date: '',
        csr: '',
        salesRep: '',
        description: '',
    },
    history: null,
    showFinished: false,
    quickGlanceFilter: {
        field: '',
        value: '',
    },
    delete: {
        id: null,
        isDeleting: false,
    },
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_INFORMATION:
            return state.setIn(['fetching', 'information'], true).toJS();

        case types.FETCH_INFORMATION_SUCCESS:
            return state.setIn(['fetching', 'information'], false)
                .setIn(['information', 'salesReps'], action.payload.salesReps)
                .setIn(['information', 'csrs'], action.payload.csrs)
                .toJS();

        case types.FETCH_INFORMATION_ERROR:
            return state.setIn(['fetching', 'information'], false).toJS();

        case types.FETCH_QUICK_GLANCE:
            return state.setIn(['fetching', 'quickGlance'], !action.payload).toJS();

        case types.FETCH_QUICK_GLANCE_SUCCESS:
            return state.setIn(['fetching', 'quickGlance'], false)
                .setIn(['data', 'quickGlance'], action.payload)
                .toJS();

        case types.FETCH_QUICK_GLANCE_ERROR:
            return state.setIn(['fetching', 'quickGlance'], false).toJS();

        case types.FETCH_LATE_JOBS:
            return state.setIn(['fetching', 'lateJobs'], !action.payload).toJS();

        case types.FETCH_LATE_JOBS_SUCCESS:
            return state.setIn(['fetching', 'lateJobs'], false)
                .setIn(['data', 'lateJobs'], action.payload.elements)
                .setIn(['pagination', 'lateJobs', 'totalElements'], action.payload.totalElements)
                .setIn(['pagination', 'lateJobs', 'activePage'], action.payload.activePage)
                .setIn(['pagination', 'lateJobs', 'totalPages'], action.payload.totalPages)
                .setIn(['pagination', 'lateJobs', 'elementsPerPage'], action.payload.elementsPerPage)
                .toJS();

        case types.FETCH_LATE_JOBS_ERROR:
            return state.setIn(['fetching', 'lateJobs'], false).toJS();

        case types.FETCH_MY_JOBS:
            return state.setIn(['fetching', 'myJobs'], !action.payload).toJS();

        case types.FETCH_MY_JOBS_SUCCESS:
            return state.setIn(['fetching', 'myJobs'], false)
                .setIn(['data', 'myJobs'], action.payload.elements)
                .setIn(['pagination', 'myJobs', 'totalElements'], action.payload.totalElements)
                .setIn(['pagination', 'myJobs', 'activePage'], action.payload.activePage)
                .setIn(['pagination', 'myJobs', 'totalPages'], action.payload.totalPages)
                .setIn(['pagination', 'myJobs', 'elementsPerPage'], action.payload.elementsPerPage)
                .toJS();

        case types.FETCH_MY_JOBS_ERROR:
            return state.setIn(['fetching', 'myJobs'], false).toJS();

        case types.DELETE_TRACKING:
            return state.setIn(['delete', 'isDeleting'], true).toJS();

        case types.DELETE_TRACKING_SUCCESS:
            return state.setIn(['delete', 'isDeleting'], false)
                .setIn(['delete', 'id'], null).toJS();

        case types.DELETE_TRACKING_ERROR:
            return state.setIn(['delete', 'isDeleting'], false).toJS();

        case types.SET_HISTORY:
            return state.setIn(['history'], action.payload).toJS();

        case types.SET_SEARCH_FIELD:
            return state.setIn(['searchFields', action.payload.name], action.payload.value).toJS();

        case types.SET_SHOW_FINISHED:
            return state.setIn(['showFinished'], action.payload).toJS();

        case types.SET_SORT:
            return state.setIn(['sort', action.payload.name], action.payload.value).toJS();

        case types.SET_QUICK_GLANCE_FILTER:
            return state.setIn(['quickGlanceFilter'], action.payload)
                .setIn(['pagination', 'lateJobs', 'activePage'], 1)
                .setIn(['pagination', 'myJobs', 'activePage'], 1)
                .toJS();

        case types.SET_ACTIVE_PAGE:
            return state.setIn(['pagination', action.payload.name, 'activePage'], action.payload.value).toJS();

        case types.RESET_SEARCH_FIELDS:
            return state.setIn(['searchFields', 'jobNumber'], '')
                .setIn(['searchFields', 'customer'], '')
                .setIn(['searchFields', 'date'], '')
                .setIn(['searchFields', 'csr'], '')
                .setIn(['searchFields', 'salesRep'], '')
                .setIn(['searchFields', 'description'], '')
                .toJS();

        case types.SET_ID_FOR_DELETE:
            return state.setIn(['delete', 'id'], action.payload).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}