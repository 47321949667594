import { createSelector } from 'reselect';

export const getComponentState = state => state.onHoldCustomers;

export const getFetchingOnHoldCustomers = createSelector(
    getComponentState,
    (state) => state.fetchingOnHoldCustomers
);
export const getFetchingOnHoldCustomer = createSelector(
    getComponentState,
    (state) => state.fetchingOnHoldCustomer
);
export const getOnHoldCustomers = createSelector(
    getComponentState,
    (state) => state.onHoldCustomers
);
export const getPagination = createSelector(
    getComponentState,
    (state) => state.pagination
);
export const getSort = createSelector(
    getComponentState,
    (state) => state.sort
);
export const getIdForDelete = createSelector(
    getComponentState,
    (state) => state.delete.id
);
export const getIsDeleting = createSelector(
    getComponentState,
    (state) => state.delete.isDeleting
);
export const getAddingHold = createSelector(
    getComponentState,
    (state) => state.addingHold
);
export const getFetchingInformation = createSelector(
    getComponentState,
    (state) => state.fetchingInformation
);
export const getInformation = createSelector(
    getComponentState,
    (state) => state.information
);
export const getFilterFields = createSelector(
    getComponentState,
    (state) => state.filter.fields
);
export const getRemovingHold = createSelector(
    getComponentState,
    (state) => state.removingHold
);
export const getFields = createSelector(
    getComponentState,
    (state) => state.fields
);
export const getCustomerName = createSelector(
    getComponentState,
    (state) => state.customerName
);
export const getDownloadingReport = createSelector(
    getComponentState,
    (state) => state.downloadingReport
);