import { createSelector } from 'reselect';

export const getComponentState = state => state.pqtDataProcessing;

export const getElement = createSelector(
    getComponentState,
    (state) => state.element
);

export const getSaving = createSelector(
    getComponentState,
    (state) => state.saving
);

export const getType = createSelector(
    getComponentState,
    (state) => state.type
);

export const getElementId = createSelector(
    getComponentState,
    (state) => state.elementId
);

export const getFetching = createSelector(
    getComponentState,
    (state) => state.fetching
);

export const getShowSave = createSelector(
    getComponentState,
    (state) => state.showSave
);
