import React from 'react';
import { Dialog } from './../../common/dialog';
import numeral from 'numeral';
import { empty } from './../../../utils';

const FormModal = React.memo((props) => {
    let baseCalculationType = 0;
    if (props.subcategory) {
        baseCalculationType = parseInt(props.subcategory.category_base_calculation_type);
    }

    const hideHandler = () => {
        props.hideFormModal();
    };

    let label = 'Save';
    if (props.isSaving) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    const handleOnChange = (field, event) => {
        let value = null;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        } else {
            value = event.target.value;
        }
        props.setField(field, value);

        if (baseCalculationType === 2 && field === 'speed') {    // Labor/Speed
            props.setField('base', numeral(props.matrix.labor / value).format('0.[0000]'));
        } else if (baseCalculationType === 2 && field === 'labor') {    // Labor/Speed
            props.setField('base', numeral(value / props.matrix.speed).format('0.[0000]'));
        } else if (baseCalculationType === 3) {    // Labor/Speed + Setup
            let lastQuantity = 0;
            for (let i = props.matrixData.length - 1; i >= 0; i--) {
                if (!empty(props.matrixData[i])) {
                    lastQuantity = props.matrixData[i].quantity;
                    break;
                }
            }
            lastQuantity /= 1000;

            if (field === 'speed') {
                props.setField('base', props.matrix.labor / value + props.matrix.setup_time * props.matrix.setup_cost / lastQuantity);
            } else if (field === 'labor') {
                props.setField('base', value / props.matrix.speed + props.matrix.setup_time * props.matrix.setup_cost / lastQuantity);
            } else if (field === 'setup_time') {
                props.setField('base', props.matrix.labor / props.matrix.speed + value * props.matrix.setup_cost / lastQuantity);
            } else if (field === 'setup_cost') {
                props.setField('base', props.matrix.labor / props.matrix.speed + props.matrix.setup_time * value / lastQuantity);
            }
        }
    };

    const handleMatrixOnChange = (field, index, event) => {
        props.setMatrixField(index, field, event.target.value);

        if (baseCalculationType === 3 && field === 'quantity' && index === props.matrixData.length - 1) {    // Labor/Speed + Setup
            const lastQuantity = event.target.value / 1000;
            props.setField('base', props.matrix.labor / props.matrix.speed + props.matrix.setup_time * props.matrix.setup_cost / lastQuantity);
        }
    };

    const handleRemoveMatrixRow = (index) => {
        props.removeMatrixRow(index);

        if (baseCalculationType === 3 && index === props.matrixData.length - 1) {    // Labor/Speed + Setup
            let lastQuantity = 0;
            for (let i = props.matrixData.length - 1; i >= 0; i--) {
                if (!empty(props.matrixData[i]) & index !== i) {
                    lastQuantity = props.matrixData[i].quantity;
                    break;
                }
            }
            lastQuantity /= 1000;

            props.setField('base', props.matrix.labor / props.matrix.speed + props.matrix.setup_time * props.matrix.setup_cost / lastQuantity);
        }
    };

    const renderMatrixData = () => {
        return props.matrixData.map((element, index) => {
            return (
                <tr>
                    <td>
                        <input
                            type="number"
                            value={element.quantity}
                            onChange={handleMatrixOnChange.bind(this, 'quantity', index)}
                        />
                    </td>
                    <td>
                        <input
                            type="number"
                            value={element.factor}
                            onChange={handleMatrixOnChange.bind(this, 'factor', index)}
                        />
                    </td>
                    <td>
                        <input
                            type="number"
                            value={element.base}
                            onChange={handleMatrixOnChange.bind(this, 'base', index)}
                            disabled
                        />
                    </td>
                    <td>
                        <i className="fas fa-trash" onClick={handleRemoveMatrixRow.bind(this, index)} />
                    </td>
                </tr>
            );
        });
    };

    let baseField = (
        <div className="field">
            <div className="label">
                <div><b>Base <span className="required">*</span>:</b></div>
            </div>
            <input
                type="number"
                value={props.matrix.base}
                onChange={handleOnChange.bind(this, 'base')}
            />
        </div>
    );

    let speedField = (
        <div className="field">
            <div className="label">
                <div><b>Speed <span className="required">*</span>:</b></div>
            </div>
            <input
                type="number"
                value={props.matrix.speed}
                onChange={handleOnChange.bind(this, 'speed')}
            />
        </div>
    );

    let laborField = (
        <div className="field">
            <div className="label">
                <div><b>Labor <span className="required">*</span>:</b></div>
            </div>
            <input
                type="number"
                value={props.matrix.labor}
                onChange={handleOnChange.bind(this, 'labor')}
            />
        </div>
    );

    let setupHrsField = (
        <div className="field">
            <div className="label">
                <div><b>Setup Hrs <span className="required">*</span>:</b></div>
            </div>
            <input
                type="number"
                value={props.matrix.setup_time}
                onChange={handleOnChange.bind(this, 'setup_time')}
            />
        </div>
    );

    let setupCostField = (
        <div className="field">
            <div className="label">
                <div><b>Setup Cost <span className="required">*</span>:</b></div>
            </div>
            <input
                type="number"
                value={props.matrix.setup_cost}
                onChange={handleOnChange.bind(this, 'setup_cost')}
            />
        </div>
    );

    if (baseCalculationType === 1) {    // No base calculation
        speedField = null;
        laborField = null;
        setupHrsField = null;
        setupCostField = null;
    } else if (baseCalculationType === 2) {    // Labor/Speed
        baseField = null;
        setupHrsField = null;
        setupCostField = null;
    } else if (baseCalculationType === 3) {    // Labor/Speed + Setup
        baseField = null;
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Cancel</button>,
        <button key="report-button" className="btn btn-light btn-sm action-button" onClick={props.saveMatrix} disabled={props.isSaving}>{label}</button>
    ];
    return (
        <Dialog
            name="matrix-form-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="matrix-form-dialog"
        >
            <div className="matrix-form-container">
                <h1>Pricing Information</h1>
                <div className="fields">
                    <div className="field title-field">
                        <div><b>Title <span className="required">*</span>:</b></div>
                        <input type="text" value={props.matrix.name} onChange={handleOnChange.bind(this, 'name')} />
                    </div>
                    <div className="field minimum-field">
                        <div><b>Minimum <span className="required">*</span>:</b></div>
                        <input type="number" value={props.matrix.minimum} onChange={handleOnChange.bind(this, 'minimum')} />
                    </div>
                </div>
                <div className="matrix">
                    <div className="global-fields">
                        <div className="field">
                            <div className="label">
                                <div><b>Code <span className="required">*</span>:</b></div>
                            </div>
                            <input
                                type="text"
                                value={props.matrix.code}
                                onChange={handleOnChange.bind(this, 'code')}
                            />
                        </div>
                        <div className="field">
                            <div><b>Active <span className="required">*</span>:</b></div>
                            <select value={props.matrix.is_active} onChange={handleOnChange.bind(this, 'is_active')}>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                        {baseField}
                        {speedField}
                        {laborField}
                        {setupHrsField}
                        {setupCostField}
                    </div>
                    <table className="table table-sm table-hover table-striped">
                        <thead>
                            <tr>
                                <th>Quantity</th>
                                <th>Factor</th>
                                <th>Based</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderMatrixData()}
                        </tbody>
                    </table>
                    <div className="add-row-button">
                        <button onClick={props.addMatrixRow}><i className="fas fa-plus" /> Add</button>
                    </div>
                </div>
            </div>
        </Dialog >
    );
});

export default FormModal;
