import React, { Fragment } from 'react';
import numeral from 'numeral'
import { dateToTimezone } from './../../../utils';

const TrackerInformation = React.memo((props) => {
    if (props.isEditing) {
        return null;
    }

    const pqtType = props.user !== null && props.user.pqt_type;

    const editHandler = () => {
        props.setIsEditing(true);
        props.fetchInformation();
    }

    let dueDate = null;
    if (props.trackingFields.due_date !== undefined && props.trackingFields.due_date !== '' && props.trackingFields.due_date !== '0000-00-00') {
        dueDate = dateToTimezone(props.trackingFields.due_date, 'MMMM Do YYYY');
    }

    let editButton = null;
    if ([1, 2, 3, 4, 5, 8].includes(pqtType)) {
        editButton = <button onClick={editHandler}><i className="fas fa-edit"></i> Edit</button>;
    }

    let postageEscrowRow = null;
    let postageEscrowClassName = '';
    if (props.postageEscrow < 0) {
        postageEscrowClassName = 'postage-red';
    }

    let postageEscrow = numeral(props.postageEscrow.toFixed(2)).format('$0,0.00');
    if (props.isLoadingPostageEscrow) {
        postageEscrow = <i className="fas fa-circle-notch fa-spin" />;
    }

    postageEscrowRow = <Fragment><b>Postage Escrow:</b> <span className={postageEscrowClassName}>{postageEscrow}</span> <br /></Fragment>;

    let projectMailRow = null;
    let permitNumberRow = null;
    let mailTypeRow = null;
    let postOfficeRow = null;
    let projectMailRow2 = null;
    let permitNumberRow2 = null;
    let mailTypeRow2 = null;
    let postOfficeRow2 = null;
    if (props.trackingFields.is_mail_project) {
        projectMailRow = <Fragment><b>Project Mail 1:</b> {props.trackingFields.project_mail_name} <br /></Fragment>;
        permitNumberRow = <Fragment><b>Permit Number 1:</b> {props.trackingFields.permit_number} <br /></Fragment>;
        mailTypeRow = <Fragment><b>Mail Type 1:</b> {props.trackingFields.mail_type_name} <br /></Fragment>;
        postOfficeRow = <Fragment><b>Post Office 1:</b> {props.trackingFields.post_office} <br /></Fragment>;

        if (props.trackingFields.project_mail_2) {
            projectMailRow2 = <Fragment><b>Project Mail 2:</b> {props.trackingFields.project_mail_name_2} <br /></Fragment>;
        }
        if (props.trackingFields.permit_number_2) {
            permitNumberRow2 = <Fragment><b>Permit Number 2:</b> {props.trackingFields.permit_number_2} <br /></Fragment>;
        }
        if (props.trackingFields.mail_type_2) {
            mailTypeRow2 = <Fragment><b>Mail Type 2:</b> {props.trackingFields.mail_type_name_2} <br /></Fragment>;
        }
        if (props.trackingFields.post_office_2) {
            postOfficeRow2 = <Fragment><b>Post Office 2:</b> {props.trackingFields.post_office_2} <br /></Fragment>;
        }
    }

    let quantity = numeral(props.trackingFields.quantity).format('0,0');
    if (props.trackingFields.is_uth_ticket) {
        quantity = 'See Job Details';
    }

    return (
        <div className="tracker-information-container">
            <div className="title">Tracker Information</div>
            <div className="information">
                <b>Customer:</b> {props.trackingFields.customer_name} <br />
                {postageEscrowRow}
                <b>Creation Date:</b> {dateToTimezone(props.trackingFields.created_at, 'MMMM Do YYYY, h:mm a')} <br />
                <b>Started Date:</b> {props.trackingFields.start_date == null ? 'N/A' : dateToTimezone(props.trackingFields.start_date, 'MMMM Do YYYY, h:mm a')} <br />
                <b>Due Date:</b> {dueDate} <br />
                <b>Sales Rep:</b> {props.trackingFields.sales_rep_name} <br />
                <b>CSR:</b> {props.trackingFields.csr_name} <br />
                <b>Job Description:</b> {props.trackingFields.job_description} <br />
                <b>PO Number:</b> {props.trackingFields.job_number} <br />
                <b>Quote #:</b> {props.trackingFields.quote_number} <br />
                <b>Quantity:</b> {quantity} <br />
                <b>Project Type:</b> {props.trackingFields.project_type_name} <br />
                {projectMailRow}
                {permitNumberRow}
                {mailTypeRow}
                {postOfficeRow}
                {projectMailRow2}
                {permitNumberRow2}
                {mailTypeRow2}
                {postOfficeRow2}
                <div className="edit-button">
                    {editButton}
                </div>
            </div>
        </div>
    );
});

TrackerInformation.propTypes = {

};

export default TrackerInformation;
