import React, { Component } from 'react';
import Form from './Form';
import { connect } from 'react-redux';
import * as actions from './PqtDataProcessing.actions';
import * as selectors from './PqtDataProcessing.selectors';
import PropTypes from 'prop-types';
import './PqtDataProcessing.scss';
import * as dialog from '../common/dialog';

class PqtDataProcessing extends Component {
    render() {
        return (
            <div className="pqt-data-processing-container">
                <Form
                    element={this.props.element}
                    saving={this.props.saving}
                    save={this.props.save}
                    setField={this.props.setField}
                    hideForm={this.props.hideForm}
                    fetching={this.props.fetching}
                    callback={this.props.callback}
                    id={this.props.id}
                    showSave={this.props.showSave}
                />
            </div>
        );
    }
}

PqtDataProcessing.propTypes = {
    user: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    element: selectors.getElement(state),
    saving: selectors.getSaving(state),
    fetching: selectors.getFetching(state),
    showSave: selectors.getShowSave(state),
});

const mapDispatchToProps = (dispatch) => ({
    setField: (name, value) => dispatch(actions.setField(name, value)),
    save: callback => dispatch(actions.save(callback)),
    resetState: () => dispatch(actions.resetState()),
    hideForm: () => dispatch(dialog.actions.hideDialog('pqt-data-processing-form')),
});

export default connect(mapStateToProps, mapDispatchToProps)(PqtDataProcessing);
