import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Form from './Form';
import * as actions from './CustomerApplication.actions';
import * as selectors from './CustomerApplication.selectors';
import './CustomerApplication.scss';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';

const CustomerApplication = (props) => {
    /* State to props */
    const fields = useSelector(selectors.getFields, stringifyEqualityCheck);
    const file = useSelector(selectors.getFile, stringifyEqualityCheck);
    const savingApplication = useSelector(selectors.getSavingApplication);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const saveApplication = useCallback((ref) => dispatch(actions.saveApplication(ref)), [dispatch]);
    const setField = useCallback((field, value) => dispatch(actions.setField(field, value)), [dispatch]);
    const clearForm = useCallback(() => dispatch(actions.clearForm()), [dispatch]);
    const setFile = useCallback((file) => dispatch(actions.setFile(file)), [dispatch]);
    const setRecaptchaToken = useCallback((file) => dispatch(actions.setRecaptchaToken(file)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        if (props.match.params.type === 'new') {
            setField('type', 1);
        } else {
            setField('type', 2);
        }

        return () => {
            resetState();
        };
    }, [dispatch, setField, resetState, props.match.params.type]);

    return (
        <div className="customer-application-container">
            <Helmet><title>Customer Application - Stagecoach</title></Helmet>
            <Form
                file={file}
                fields={fields}
                savingApplication={savingApplication}
                history={props.history}
                saveApplication={saveApplication}
                setField={setField}
                clearForm={clearForm}
                setFile={setFile}
                setRecaptchaToken={setRecaptchaToken}
            />
        </div>
    );
};

export default CustomerApplication;
