import React, { Fragment } from 'react';
//import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';

const Form = props => {
    let saveButtonLabel = 'Save';
    if (props.saving) {
        saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    const setFieldHandler = (name, event) => {
        const value = event.target.value;
        props.setField(name, value);

        let fields = {};
        if (name === 'mail_class' && parseInt(value) === 1) {
            fields.format = 1;
            fields.sortation = 1;
            fields.sort_choice = 1;
        } else if (name === 'mail_class' && parseInt(value) === 2) {
            fields.format = 1;
            fields.presort_by = 1;
        } else if (name === 'mail_class' && parseInt(value) === 3) {
            fields.format = 6;
            fields.presort_by = 1;
        } else if (name === 'mail_class' && parseInt(value) === 4) {
            fields.format = 3;
            fields.sortation = 1;
            fields.presort_by = 7;
        } else if (name === 'mail_class' && parseInt(value) === 5) {
            fields.format = 8;
            fields.presort_by = 4;
        } else if (name === 'mail_class' && parseInt(value) === 6) {
            fields.format = 5;
            fields.presort_by = 4;
        }

        if (parseInt(props.element.mail_class) === 1) {
            if (name === 'format' && [1, 3, 4].includes(parseInt(value))) {
                fields.sortation = 1;
                fields.sort_choice = 1;
            } else if (name === 'format' && parseInt(value) === 2) {
                fields.sortation = 1;
                fields.postcard_type = 1;
                fields.sort_choice = 1;
            } else if (name === 'sortation' && parseInt(value) === 2) {
                fields.presort_by = 1;
            }
        } else if (parseInt(props.element.mail_class) === 2) {
            if (name === 'format' && parseInt(value) === 1) {
                fields.presort_by = 1;
            } else if (name === 'format' && parseInt(value) === 2) {
                fields.presort_by = 4;
            } else if (name === 'format' && parseInt(value) === 5) {
                fields.presort_by = 6;
            }
        } else if (parseInt(props.element.mail_class) === 3) {
            if (name === 'format' && [6, 3, 1].includes(parseInt(value))) {
                fields.presort_by = 1;
            } else if (name === 'format' && parseInt(value) === 7) {
                fields.presort_by = 4;
            } else if (name === 'format' && parseInt(value) === 5) {
                fields.presort_by = 6;
            }
        } else if (parseInt(props.element.mail_class) === 4) {
            if (name === 'format' && parseInt(props.element.sortation) === 1) {
                fields.presort_by = 7;
            } else if (name === 'format' && parseInt(props.element.sortation) === 2) {
                fields.presort_by = 4;
            } else if (name === 'sortation' && parseInt(value) === 1) {
                fields.presort_by = 7;
            } else if (name === 'sortation' && parseInt(value) === 2) {
                fields.presort_by = 4;
            }
        } else if (parseInt(props.element.mail_class) === 5) {
            if (name === 'format' && parseInt(value) === 8) {
                fields.presort_by = 4;
            } else if (name === 'format' && parseInt(value) === 9) {
                fields.presort_by = 6;
            }
        } else if (parseInt(props.element.mail_class) === 6) {
            if (name === 'format' && [5, 8].includes(parseInt(value))) {
                fields.presort_by = 4;
            }
        }

        props.setFields(fields);
    }

    const renderTextField = (label, name, className = '', message = '') => (
        <Fragment>
            <div className={'input-label ' + className}>{label} <span className="required">*</span></div>
            <div className={'input-field ' + className}>
                <input
                    type="text"
                    name={name}
                    value={props.element[name] === undefined ? '' : props.element[name]}
                    onChange={setFieldHandler.bind(this, name)}
                />
                <span className="message">{message}</span>
            </div>
        </Fragment>
    );

    const renderSelectField = (label, name, options, className = '') => (
        <Fragment>
            <div className={'input-label ' + className}>{label} <span className="required">*</span></div>
            <div className={'input-field ' + className}>
                <select
                    name={name}
                    value={props.element[name] === undefined ? '' : props.element[name]}
                    onChange={setFieldHandler.bind(this, name)}
                >
                    {options.map((option, index) => (<option value={option.value} key={name + '-option-' + index}>{option.label}</option>))}
                </select>
            </div>
        </Fragment>
    );

    const renderRadioField = (label, name, className = '') => (
        <Fragment>
            <div className={'input-label ' + className}>{label} <span className="required">*</span></div>
            <div className={'input-field ' + className}>
                <input
                    type="radio"
                    name={name}
                    value="1"
                    checked={props.element[name] === undefined ? false : parseInt(props.element[name]) === 1}
                    onChange={setFieldHandler.bind(this, name)}
                /> Yes
                <input
                    type="radio"
                    value="0"
                    name={name}
                    checked={props.element[name] === undefined ? false : parseInt(props.element[name]) === 0}
                    onChange={setFieldHandler.bind(this, name)}
                /> No
            </div>
        </Fragment>
    );

    const renderTextAreaField = (label, name, className = '', message = '') => (
        <Fragment>
            <div className={'input-label ' + className}>{label} <span className="required">*</span></div>
            <div className={'input-field ' + className}>
                <textarea
                    name={name}
                    value={props.element[name] === undefined ? '' : props.element[name]}
                    onChange={setFieldHandler.bind(this, name)}
                />
                <span className="message">{message}</span>
            </div>
        </Fragment>
    );

    const renderTextFieldAlone = (name) => (
        <div className="select-field">
            <input
                type="text"
                name={name}
                value={props.element[name] === undefined ? '' : props.element[name]}
                onChange={setFieldHandler.bind(this, name)}
            />
        </div>
    );

    const renderSelectFieldAlone = (name, options) => (
        <div className="select-field">
            <select
                name={name}
                value={props.element[name] === undefined ? '' : props.element[name]}
                onChange={setFieldHandler.bind(this, name)}
            >
                {options.map((option, index) => (<option value={option.value} key={name + '-option-' + index}>{option.label}</option>))}
            </select>
        </div>
    );

    const renderRadioFieldAlone = (label, name, value) => (
        <div className="radio-field">
            <input
                type="radio"
                name={name}
                value={value}
                checked={props.element[name] === undefined ? false : parseInt(props.element[name]) === value}
                onChange={setFieldHandler.bind(this, name)}
            /> {label}
        </div>
    );

    let showAgentNameWho = parseInt(props.element.agent_name) === 2;
    let showPermitHolderOther = parseInt(props.element.permit_holder) === 2;
    let showPermitNumberOther = parseInt(props.element.permit_number) === 5;
    let showEntryPostOfficeOther = parseInt(props.element.entry_post_office) === 6;
    let showFullService = parseInt(props.element.full_job_service) === 1;
    let showPostalPaymentMeter = parseInt(props.element.postal_payment) === 3;
    let showPostalPaymentMeterSpecific = parseInt(props.element.postal_payment) === 3 && parseInt(props.element.postal_payment_meter) === 3;

    const renderFormat = () => {
        /**
         * Options:
         *      1: Letter
         *      2: Postcard
         *      3: Flat
         *      4: Parcel
         *      5: Irregular Parcel
         *      6: Catalog
         *      7: Marketing Parcel
         *      8: Machinable Parcel
         *      9: Non-Machinable Parcel
         */
        if (parseInt(props.element.mail_class) === 1) {
            return (
                <div className="section-container">
                    <div className="section-label">Format:</div>
                    <div className="section-fields">
                        {renderRadioFieldAlone('Letter', 'format', 1)}
                        {renderRadioFieldAlone('Postcard', 'format', 2)}
                        {renderRadioFieldAlone('Flat', 'format', 3)}
                        {renderRadioFieldAlone('Parcel', 'format', 4)}
                    </div>
                </div>
            );
        } else if (parseInt(props.element.mail_class) === 2) {
            return (
                <div className="section-container">
                    <div className="section-label">Format:</div>
                    <div className="section-fields">
                        {renderRadioFieldAlone('Letter', 'format', 1)}
                        {renderRadioFieldAlone('Postcard', 'format', 2)}
                        {renderRadioFieldAlone('Irregular Parcel', 'format', 5)}
                        {renderRadioFieldAlone('Flat', 'format', 3)}
                    </div>
                </div>
            );
        } else if (parseInt(props.element.mail_class) === 3) {
            return (
                <div className="section-container">
                    <div className="section-label">Format:</div>
                    <div className="section-fields">
                        {renderRadioFieldAlone('Catalog', 'format', 6)}
                        {renderRadioFieldAlone('Flat', 'format', 3)}
                        {renderRadioFieldAlone('Marketing Parcel', 'format', 7)}
                        {renderRadioFieldAlone('Letter', 'format', 1)}
                        {renderRadioFieldAlone('Irregular Parcel', 'format', 5)}
                    </div>
                </div>
            );
        } else if (parseInt(props.element.mail_class) === 4) {
            return (
                <div className="section-container">
                    <div className="section-label">Format:</div>
                    <div className="section-fields">
                        {renderRadioFieldAlone('Flat', 'format', 3)}
                        {renderRadioFieldAlone('Machinable Parcel', 'format', 8)}
                        {renderRadioFieldAlone('Irregular Parcel', 'format', 5)}
                    </div>
                </div>
            );
        } else if (parseInt(props.element.mail_class) === 5) {
            return (
                <div className="section-container">
                    <div className="section-label">Format:</div>
                    <div className="section-fields">
                        {renderRadioFieldAlone('Machinable Parcel', 'format', 8)}
                        {renderRadioFieldAlone('Non-Machinable Parcel', 'format', 9)}
                    </div>
                </div>
            );
        } else if (parseInt(props.element.mail_class) === 6) {
            return (
                <div className="section-container">
                    <div className="section-label">Format:</div>
                    <div className="section-fields">
                        {renderRadioFieldAlone('Irregular Parcel', 'format', 5)}
                        {renderRadioFieldAlone('Machinable Parcel', 'format', 8)}
                    </div>
                </div>
            );
        }

        return null;
    };

    const renderSortation = () => {
        /**
         * Options:
         *      1: Single Piece
         *      2: Presort
         */
        if ([1, 4].includes(parseInt(props.element.mail_class))) {
            return (
                <div className="section-container">
                    <div className="section-label">Sortation:</div>
                    <div className="section-fields">
                        {renderRadioFieldAlone('Single Piece', 'sortation', 1)}
                        {renderRadioFieldAlone('Presort', 'sortation', 2)}
                    </div>
                </div>
            );
        }

        return null;
    };

    const renderPresortBy = () => {
        /**
         * Options:
         *      1: Automation
         *      2: Machinable
         *      3: Non-Machinable
         *      4: Non-Automation
         *      5: Barcoded
         *      6: Presort Non-Automation
         *      7: Maintain Original Record Order
         *      8: Zip Order
         *      9: Specific
         */
        if (parseInt(props.element.mail_class) === 1 && parseInt(props.element.sortation) === 2) {
            let options = [];
            if ([1, 2, 4].includes(parseInt(props.element.format))) {
                options = [
                    { value: 1, label: 'Automation' },
                    { value: 2, label: 'Machinable' },
                    { value: 3, label: 'Non-Machinable' },
                    { value: 4, label: 'Non-Automation' },
                ];
            } else if ([3].includes(parseInt(props.element.format))) {
                options = [
                    { value: 1, label: 'Automation' },
                    { value: 4, label: 'Non-Automation' },
                ];
            }

            return (
                <div className="section-container">
                    <div className="section-label">Presort By:</div>
                    <div className="section-fields">
                        {renderSelectFieldAlone('presort_by', options)}
                    </div>
                </div>
            );
        } else if (parseInt(props.element.mail_class) === 2) {
            let options = [];
            if (parseInt(props.element.format) === 1) {
                options = [
                    { value: 1, label: 'Automation' },
                    { value: 4, label: 'Non-Automation' },
                ];
            } else if (parseInt(props.element.format) === 2 || parseInt(props.element.format) === 3) {
                options = [
                    { value: 4, label: 'Non-Automation' },
                    { value: 5, label: 'Barcoded' },
                ];
            } else if (parseInt(props.element.format) === 5) {
                options = [
                    { value: 6, label: 'Presort Non-Automation' },
                ];
            }

            return (
                <div className="section-container">
                    <div className="section-label">Presort By:</div>
                    <div className="section-fields">
                        {renderSelectFieldAlone('presort_by', options)}
                    </div>
                </div>
            );
        } else if (parseInt(props.element.mail_class) === 3) {
            let options = [];
            if ([6, 3].includes(parseInt(props.element.format))) {
                options = [
                    { value: 1, label: 'Automation' },
                    { value: 4, label: 'Non-Automation' },
                ];
            } else if (parseInt(props.element.format) === 7) {
                options = [
                    { value: 4, label: 'Non-Automation' },
                    { value: 5, label: 'Barcoded' },
                ];
            } else if (parseInt(props.element.format) === 1) {
                options = [
                    { value: 1, label: 'Automation' },
                    { value: 2, label: 'Machinable' },
                    { value: 3, label: 'Non-Machinable' },
                    { value: 4, label: 'Non-Automation' },
                ];
            } else if (parseInt(props.element.format) === 5) {
                options = [
                    { value: 6, label: 'Presort Non-Automation' },
                ];
            }

            return (
                <div className="section-container">
                    <div className="section-label">Presort By:</div>
                    <div className="section-fields">
                        {renderSelectFieldAlone('presort_by', options)}
                    </div>
                </div>
            );
        } else if (parseInt(props.element.mail_class) === 4) {
            let options = [];
            let extraField = null;
            if (parseInt(props.element.sortation) === 1) {
                options = [
                    { value: 7, label: 'Maintain Original Record Order' },
                    { value: 8, label: 'Zip Order' },
                    { value: 9, label: 'Specific' },
                ];
            } else if (parseInt(props.element.format) === 5 && parseInt(props.element.sortation) === 2) {
                options = [
                    { value: 4, label: 'Non-Automation' },
                ];
            } else if (parseInt(props.element.sortation) === 2) {
                options = [
                    { value: 4, label: 'Non-Automation' },
                    { value: 5, label: 'Barcoded' },
                ];
            }

            if (parseInt(props.element.sortation) === 1 && parseInt(props.element.presort_by) === 9) {
                extraField = (
                    <div className="sort-by-specify-field">
                        Specify:
                            <input
                            type="text"
                            name="sort_by_specific"
                            value={props.element['sort_by_specific'] === undefined ? '' : props.element['sort_by_specific']}
                            onChange={setFieldHandler.bind(this, 'sort_by_specific')}
                        />
                    </div>
                );
            } else if (parseInt(props.element.sortation) === 2) {
                extraField = (
                    <div className="sort-by-crrt-field">
                        CRRT:
                        {renderRadioFieldAlone('Yes', 'crrt', 1)}
                        {renderRadioFieldAlone('No', 'crrt', 0)}
                    </div>
                );
            }

            return (
                <div className="section-container">
                    <div className="section-label">Sort By:</div>
                    <div className="section-fields">
                        {renderSelectFieldAlone('presort_by', options)}
                        {extraField}
                    </div>
                </div>
            );
        } else if (parseInt(props.element.mail_class) === 5) {
            let options = [];
            if (parseInt(props.element.format) === 8) {
                options = [
                    { value: 4, label: 'Non-Automation' },
                    { value: 5, label: 'Barcoded' },
                ];
            } else if (parseInt(props.element.format) === 9) {
                options = [
                    { value: 6, label: 'Presort Non-Automation' },
                ];
            }

            return (
                <div className="section-container">
                    <div className="section-label">Sortation:</div>
                    <div className="section-fields">
                        {renderSelectFieldAlone('presort_by', options)}
                    </div>
                </div>
            );
        } else if (parseInt(props.element.mail_class) === 6) {
            return (
                <div className="section-container">
                    <div className="section-label">Sortation:</div>
                    <div className="section-fields">
                        {renderSelectFieldAlone('presort_by', [
                            { value: 4, label: 'Non-Automation' },
                            { value: 5, label: 'Barcoded' },
                        ])}
                    </div>
                </div>
            );
        }

        return null;
    };

    const renderPostcardType = () => {
        if ([1].includes(parseInt(props.element.mail_class)) && parseInt(props.element.format) === 2) {
            return (
                <div className="section-container">
                    <div className="section-label">Type of Postcard:</div>
                    <div className="section-fields">
                        {renderRadioFieldAlone('Postcard', 'postcard_type', 1)}
                        {renderRadioFieldAlone('Double Postcard', 'postcard_type', 2)}
                    </div>
                </div>
            );
        }

        return null;
    };

    const renderSortChoice = () => {
        if ([2, 3, 4, 5, 6].includes(parseInt(props.element.mail_class))) {
            return null;
        }

        return (
            <div className="section-container">
                <div className="section-label">Sort Choice:</div>
                <div className="section-fields">
                    {renderSelectFieldAlone('sort_choice', [
                        { value: 1, label: 'Main original record order' },
                        { value: 2, label: 'Zip order' },
                        { value: 3, label: 'Specified' },
                    ])}
                </div>
            </div>
        );
    };

    const renderSortBy = () => {
        if (parseInt(props.element.mail_class) === 1 && parseInt(props.element.sort_choice) === 3) {
            return (
                <div className="section-container">
                    <div className="section-label">Sort By:</div>
                    <div className="section-fields">
                        {renderTextFieldAlone('sort_by_specific')}
                    </div>
                </div>
            );
        }

        return null;
    };

    const renderPublication = () => {
        if ([2].includes(parseInt(props.element.mail_class))) {
            return (
                <div className="section-container publication-container">
                    <div className="column1">
                        <div className="label">Publication number:</div>
                        <div className="field">{renderTextFieldAlone('publication_number')}</div>
                        <div className="label">Edition:</div>
                        <div className="field">{renderTextFieldAlone('edition')}</div>
                        <div className="label">Issue Date:</div>
                        <div className="field">{renderTextFieldAlone('issue_date')}</div>
                    </div>
                    <div className="column2">
                        <div className="ride-along-label">Ride-Along:</div>
                        <div className="ride-along-field">
                            {renderRadioFieldAlone('Yes', 'ride_along', 1)}
                            {renderRadioFieldAlone('No', 'ride_along', 0)}
                        </div>
                        <div className="percentage-label">Percentage Advertising:</div>
                        <div className="percentage-field">{renderTextFieldAlone('percentage_advertising')}</div>
                    </div>
                </div>
            );
        }

        return null;
    };

    const renderOther = () => {
        if (parseInt(props.element.mail_class) === 2 && [1, 2, 3].includes(parseInt(props.element.format))) {
            return (
                <div className="section-container other-container">
                    <div className="other-element">
                        <div className="label">CRRT:</div>
                        <div className="field">
                            {renderRadioFieldAlone('Yes', 'crrt', 1)}
                            {renderRadioFieldAlone('No', 'crrt', 0)}
                        </div>
                    </div>
                    <div className="other-element">
                        <div className="label">News:</div>
                        <div className="field">
                            {renderRadioFieldAlone('Yes', 'news', 1)}
                            {renderRadioFieldAlone('No', 'news', 0)}
                        </div>
                    </div>
                    <div className="other-element">
                        <div className="label">Nonprofit:</div>
                        <div className="field">
                            {renderRadioFieldAlone('Yes', 'nonprofit', 1)}
                            {renderRadioFieldAlone('No', 'nonprofit', 0)}
                        </div>
                    </div>
                    <div className="other-element">
                        <div className="label">In-County:</div>
                        <div className="field">
                            {renderRadioFieldAlone('Yes', 'in_country', 1)}
                            {renderRadioFieldAlone('No', 'in_country', 0)}
                        </div>
                    </div>
                </div>
            );
        } else if (parseInt(props.element.mail_class) === 3) {
            return (
                <div className="section-container other-container">
                    <div className="other-element standard-element">
                        <div className="label repositionable-notes">Repositionable Notes:</div>
                        <div className="field">
                            {renderRadioFieldAlone('Yes', 'repositionable_notes', 1)}
                            {renderRadioFieldAlone('No', 'repositionable_notes', 0)}
                        </div>
                    </div>
                    <div className="other-element standard-element">
                        <div className="label">Customized Market Mail:</div>
                        <div className="field">
                            {renderRadioFieldAlone('Yes', 'customized_market_mail', 1)}
                            {renderRadioFieldAlone('No', 'customized_market_mail', 0)}
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    };

    const renderEcr = () => {
        if (parseInt(props.element.mail_class) === 3) {
            return (
                <div className="section-container ecr-container">
                    <div className="ecr-element">
                        <div className="label">ECR:</div>
                        <div className="field">
                            {renderRadioFieldAlone('Yes', 'ecr', 1)}
                            {renderRadioFieldAlone('No', 'ecr', 0)}
                        </div>
                    </div>
                    <div className="ecr-element">
                        <div className="label">Nonprofit:</div>
                        <div className="field">
                            {renderRadioFieldAlone('Yes', 'nonprofit', 1)}
                            {renderRadioFieldAlone('No', 'nonprofit', 0)}
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }

    const renderDallas = () => {
        if (parseInt(props.element.mail_class) === 3) {
            let dalOrDml = null;
            if (parseInt(props.element.dallas) === 1) {
                dalOrDml = (
                    <div className="ecr-element">
                        <div className="label">DAL or MDL:</div>
                        <div className="field">
                            {renderSelectFieldAlone('dal_or_dml', [
                                { value: 1, label: 'Detached address label' },
                                { value: 2, label: 'Detached marketing label' },
                            ])}
                        </div>
                    </div>
                );
            }

            return (
                <div className="section-container ecr-container">
                    <div className="ecr-element">
                        <div className="label">Dallas:</div>
                        <div className="field">
                            {renderRadioFieldAlone('Yes', 'dallas', 1)}
                            {renderRadioFieldAlone('No', 'dallas', 0)}
                        </div>
                    </div>
                    {dalOrDml}
                </div>
            );
        }

        return null;
    }

    let content = (
        <Fragment>
            <div className="postal-qualification">
                <h4>Postal Qualification</h4>
                {renderSelectField('Name & address of the mailing agent', 'agent_name', [
                    { value: 1, label: 'Absolute Color Mailplex' },
                    { value: 2, label: 'Other Mailing Agent' },
                ], 'agent-name')}
                {showAgentNameWho && renderTextField('Who?', 'agent_name_who', 'agent-name-who')}
                {renderTextField('Who is the Mail OWNER Name/Address/CRID', 'mail_owner_description', 'mail-owner-description')}
                {renderSelectField('Who is the permit holder?', 'permit_holder', [
                    { value: 1, label: 'Absolute Color Mailplex' },
                    { value: 2, label: 'Other Permit Holder' },
                ], 'permit-holder')}
                {showPermitHolderOther && renderTextField('Other Permit Holder', 'permit_holder_other', 'permit-holder-other')}
                {renderSelectField('What permit number will be used?', 'permit_number', [
                    { value: 1, label: 'Permit #1 (indicia)' },
                    { value: 2, label: 'Permit #600 (indicia)' },
                    { value: 3, label: 'Permit #7127 (indicia)' },
                    { value: 4, label: 'Permit #120 (meter)' },
                    { value: 6, label: 'STAMPS' },
                    { value: 5, label: 'Other permit number' },
                ], 'permit-number')}
                {showPermitNumberOther && renderTextField('Other Permit Number', 'permit_number_other', 'permit-number-other')}
                {renderSelectField('What entry post office?', 'entry_post_office', [
                    { value: 1, label: 'North Houston post office' },
                    { value: 2, label: 'Sugarland post office' },
                    { value: 3, label: 'Katy post office' },
                    { value: 4, label: 'Spring post office' },
                    { value: 5, label: 'Customer pickup' },
                    { value: 6, label: 'Other entry point' },
                ], 'entry-post-office')}
                {showEntryPostOfficeOther && renderTextField('Other Entry Point', 'entry_post_office_other', 'entry-post-office-other')}
                {renderRadioField('Is this full service job?', 'full_job_service', 'full-job-service')}
                {showFullService && renderTextField('Mailing Agent CRID', 'full_job_service_agent_crid', 'full-job-service-agent-crid')}
                {showFullService && renderTextField('Prepared for CRID', 'full_job_service_prepared_crid', 'full-job-service-prepared-crid')}
                {showFullService && renderTextField('Permit Holder CRID', 'full_job_service_permit_crid', 'full-job-service-permit-crid')}
                <div className="input-label postal-payment">Postal payment is made how? <span className="required">*</span></div>
                <div className="input-field postal-payment">
                    {renderRadioFieldAlone('Permit Imprint', 'postal_payment', 1)}
                    {renderRadioFieldAlone('Stamp', 'postal_payment', 2)}
                    {renderRadioFieldAlone('Meter', 'postal_payment', 3)}
                </div>
                {showPostalPaymentMeter && renderSelectField('Meter', 'postal_payment_meter', [
                    { value: 1, label: 'Correct' },
                    { value: 2, label: 'Lowest' },
                    { value: 3, label: 'Specific' },
                ], 'postal-payment-meter')}
                {showPostalPaymentMeterSpecific && renderTextField('At', 'postal_payment_meter_specific', 'postal-payment-meter-specific')}
                {renderTextAreaField('Comments', 'comments', 'comments-field')}
            </div>
            <div className="mail-class">
                <h4>What CLASS of mail?</h4>
                <div className="mail-class-container">
                    {renderRadioFieldAlone('First Class', 'mail_class', 1)}
                    {renderRadioFieldAlone('Periodical', 'mail_class', 2)}
                    {renderRadioFieldAlone('Standard', 'mail_class', 3)}
                    {renderRadioFieldAlone('Bound Printed Matter', 'mail_class', 4)}
                    {renderRadioFieldAlone('Parcel Select', 'mail_class', 5)}
                    {renderRadioFieldAlone('Parcel Select Lightweight', 'mail_class', 6)}
                </div>
                {renderPublication()}
                {renderFormat()}
                {renderPostcardType()}
                {renderSortation()}
                {renderPresortBy()}
                {renderEcr()}
                {renderDallas()}
                {renderSortChoice()}
                {renderSortBy()}
                {renderOther()}
            </div>
        </Fragment>
    );
    if (props.fetching) {
        content = <div><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>
    }

    const saveHandler = () => {
        if(props.callback) {
            props.save(props.callback.bind(this, props.id));
        } else {
            props.save();
        }
    };

    let actions = [];
    if(props.showSave) {
        actions.push(<button key="save-button" className="btn btn-light btn-sm action-button" disabled={props.isSaving} onClick={saveHandler}>{saveButtonLabel}</button>);
    }
    actions.push(<button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={props.hideForm}>Close</button>);
    
    return (
        <Dialog
            name="pqt-postal-qualification-form"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="postal-qualification-dialog"
        >
            <div className="pqt-postal-qualification-container">{content}</div>
        </Dialog>
    );
}

Form.propTypes = {

}

export default Form;
