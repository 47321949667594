import React, { Fragment } from 'react';
//import PropTypes from 'prop-types';
import numeral from 'numeral';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import { dateToTimezone } from './../../../utils';
import PqtPagination from './../../PqtPagination';
import { Link } from 'react-router-dom';

const Jobs = React.memo((props) => {
    const showCloseOutHandler = () => {
        props.history.push('/department/' + props.id);
    };

    const confirmHandler = (id) => {
        props.showDeleteModal();
        props.setIdForDelete(id);
    }

    const openChatHandler = (id, title) => {
        props.loadChats();
        props.loadChat(1, { element_id: id });
        props.setChatShowField('room', true);
        props.setChatShowField('members', false);
        props.setChatType(1);
        props.seChattElementId(id);
        props.setChatTitle(title);
        props.fetchData({
            fetchJobs: 1,
        }, true);
    };

    const renderJobs = () => {
        if (props.fetchingJobs) {
            return (
                <tr>
                    <td colSpan="15"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.jobsData.length === 0) {
            return (
                <tr>
                    <td colSpan="15">No Results</td>
                </tr>
            );
        }

        return props.jobsData.map((job, index) => {
            let box = '';
            if (job.status === 4) {
                box = 'purple-box';
            } else if (job.days_left <= 0) {
                box = 'red-box';
            } else if (job.days_left < 7) {
                box = 'yellow-box';
            } else if (job.days_left < 14) {
                box = 'blue-box';
            } else {
                box = 'green-box';
            }

            let statusBox = 'status-box';
            if (job.status === 1) {
                statusBox = 'status-box blue-box';
            } else if (job.status === 2) {
                statusBox = 'status-box green-box';
            } else if (job.status === 3) {
                statusBox = 'status-box red-box';
            } else if (job.status === 4) {
                statusBox = 'status-box purple-box';
            } else if (job.status === 5) {
                statusBox = 'status-box yellow-box';
            }

            let dpTimeTd = <td className="text-center">N/A</td>;
            if (job.dp_time != null) {
                dpTimeTd = <td className="text-center">{job.dp_time}</td>;
            }

            let currentDepartments = [];
            for (let i = 0; i < job.current_departments.length; i++) {
                currentDepartments.push(job.current_departments[i]);
            }

            let closeOutIcon = null;
            let jobClassName = 'not-ready';
            if (currentDepartments.includes(parseInt(props.id))) {
                closeOutIcon = (
                    <Tooltip title="Closeout" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="fas fa-check-circle" onClick={showCloseOutHandler} />
                    </Tooltip>
                );
                jobClassName = '';
            }

            let chatTitle = 'SC-' + job.id + ' (' + job.job_description + ')';
            let chatClassName = 'chat';
            if (job.has_unread_messages) {
                chatClassName = 'chat unread';
            }

            let csrName = job.csr_name;
            const csrNameParts = csrName.split(' ');
            if (csrNameParts.length > 0) {
                csrName = csrNameParts[0];
            }

            let salesName = job.sales_rep_name;
            const salesNameParts = salesName.split(' ');
            if (salesNameParts.length > 0) {
                salesName = salesNameParts[0];
            }

            let outOnProofContainer = null;
            if (job.out_on_proof) {
                outOnProofContainer = (<span className="out-on-proof">(Proof out - {dateToTimezone(job.out_on_proof_datetime, 'MM/DD/YYYY hh:mm a')})</span>);
            }

            let requiredSubdepartmentsTd = null;
            if (props.department != null && props.department.subdepartments.length > 0) {
                let subdepartmentNames = [];
                for (let i = 0; i < job.allowed_steps.length; i++) {
                    const step = job.allowed_steps[i];
                    if (parseInt(step.department_id) === parseInt(props.department.id)) {
                        for (let j = 0; j < step.subdepartments.length; j++) {
                            let separator = ' | ';
                            if (j === step.subdepartments.length - 1) {
                                separator = '';
                            }

                            if (step.subdepartments[j].finished) {
                                subdepartmentNames.push(<Fragment><span className="finished">{step.subdepartments[j].name}</span>{separator}</Fragment>);
                            } else {
                                subdepartmentNames.push(<Fragment>{step.subdepartments[j].name}{separator}</Fragment>);
                            }
                        }
                    }
                }

                requiredSubdepartmentsTd = <td>{subdepartmentNames}</td>
            }

            let onPauseText = null;
            for (let i = 0; i < job.allowed_steps.length; i++) {
                const step = job.allowed_steps[i];
                if (parseInt(step.department_id) === parseInt(props.department.id) && parseInt(step.status) === 5) {
                    closeOutIcon = null;

                    let dueDateRow = null;
                    if (job.paused_due_date) {
                        dueDateRow = <div className="paused-text">Due: {dateToTimezone(job.paused_due_date, 'MM/DD/YYYY')}</div>;
                    }

                    onPauseText = (
                        <Fragment>
                            <div className="paused-text">Paused</div>
                            {dueDateRow}
                        </Fragment>
                    );

                    break;
                }
            }

            let deleteIcon = null;
            if (props.user && [61, 64, 73, 2419].includes(parseInt(props.user.id))) {     // Hugh (61), Christy.acm (64), Christy.net (73), Stephanie (2419)
                deleteIcon = (
                    <Tooltip title="Delete" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="fas fa-trash" onClick={confirmHandler.bind(this, job.id)} />
                    </Tooltip>
                );
            }

            return (
                <tr key={'late-job-' + index} className={jobClassName}>
                    <td className="large-desktop-only">{dateToTimezone(job.created_at, 'MM/DD/YY')}</td>
                    <td className={box}>{job.id}</td>
                    <td className={box}>{job.job_number}</td>
                    <td>{csrName}</td>
                    <td>{salesName}</td>
                    <td className={chatClassName}><i class="fas fa-comment-dots" onClick={openChatHandler.bind(this, job.id, chatTitle)} /></td>
                    <td className={box + ' text-center'}>
                        {dateToTimezone(job.due_date, 'MM/DD/YY')}
                        {onPauseText}
                    </td>
                    <td className={box + ' text-center'}>{job.days_left}</td>
                    <td>
                        <div>{job.job_description}</div>
                        <div><b>Customer:</b> {job.customer_name} {outOnProofContainer}</div>
                    </td>
                    <td className="text-right">{numeral(job.quantity).format('0,0')}</td>
                    <td className={statusBox}><i className="fas fa-dot-circle" /></td>
                    {dpTimeTd}
                    <td className="text-cent1er">{job.lifecycle}</td>
                    {requiredSubdepartmentsTd}
                    <td>
                        <div className="actions">
                            <Tooltip title="Edit" position="bottom" arrow size="small" className="action-tooltip">
                                <Link to={'/tracking/' + job.id}><i className="fas fa-edit" /></Link>
                            </Tooltip>
                            {closeOutIcon}
                            {deleteIcon}
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const setSortHandler = (field, value) => {
        props.setSort(field, value);
    };

    const renderHeader = (name, column, className) => {
        let sortFunc = setSortHandler.bind(this, column, 'asc');
        let sortIcon = <i className="fas fa-sort" />;
        if (props.sort.column === column) {
            if (props.sort.type === 'asc') {
                sortFunc = setSortHandler.bind(this, column, 'desc');
                sortIcon = <i className="fas fa-sort-up" />;
            } else {
                sortFunc = setSortHandler.bind(this, column, 'asc');
                sortIcon = <i className="fas fa-sort-down" />;
            }
        }

        return <th className={className} onClick={sortFunc}>{name} {sortIcon}</th>;
    };

    let requiredSubdepartmentsTh = null;
    if (props.department != null && props.department.subdepartments.length > 0) {
        requiredSubdepartmentsTh = <th>Required Tasks</th>
    }

    return (
        <div className="jobs-container">
            <div className="table-container">
                <table className="table table-sm table-hover table-striped">
                    <thead>
                        <tr>
                            {renderHeader('Enter', 'created_at', 'large-desktop-only')}
                            {renderHeader('SC #', 'id')}
                            {renderHeader('PO #', 'job_number')}
                            {renderHeader('CSR', 'csr_name')}
                            {renderHeader('Sales', 'sales_rep_name')}
                            <th className="text-center">Chat</th>
                            {renderHeader('Due', 'due_date', 'text-center')}
                            {renderHeader('Days Left', 'days_left', 'text-center')}
                            {renderHeader('Description | Customer', 'job_description')}
                            {renderHeader('Qty', 'quantity', 'text-right')}
                            {renderHeader('Status', 'status', 'text-center')}
                            {renderHeader('DP/Time', 'dp_time', 'text-center')}
                            {renderHeader('Lifecycle', 'lifecycle', 'text-center')}
                            {requiredSubdepartmentsTh}
                            <th style={{ width: 80 }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderJobs()}
                    </tbody>
                </table>
                <PqtPagination
                    pagination={props.jobsPagination}
                    setActivePage={props.setActivePage.bind(this, 'jobs')}
                />
            </div>
        </div>
    );
});

Jobs.propTypes = {

};

export default Jobs;
