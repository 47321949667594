import axios from 'axios';
import * as selectors from './CcPaymentAuth.selectors';
import * as types from './CcPaymentAuth.types';
import { validateAuthInResponse, showNotification, empty } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const saveForm = () => (dispatch, getState) => {
    const fields = selectors.getFields(getState());

    let fieldsToValidate = ['date', 'total_amount', 'card_type', 'card_number', 'expiration_month', 'expiration_year', 'cvv', 'address',
        'city', 'state', 'zip', 'email'];
    if (parseInt(fields.is_pcard)) {
        fieldsToValidate = ['date', 'total_amount', 'card_type', 'card_number', 'expiration_month', 'expiration_year', 'cvv'];
    }

    for (const field of fieldsToValidate) {
        if (empty(fields[field])) {
            return showNotification('Complete the information', 'Complete all the required fields', 'info');
        }
    }

    dispatch({ type: types.SAVE_FORM, payload: null });
    axios.post(apiUrl + '/cc-payment-auth/save', fields, {})
        .then((response) => {
            dispatch({ type: types.SAVE_FORM_SUCCESS, payload: response.data });
            showNotification('Your information has been sent', 'Thank you! We have received your information.', 'success', 6000);
            dispatch(resetFields());
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.SAVE_FORM_ERROR));
        });
}

export const setField = (field, value) => (dispatch) => {
    dispatch({
        type: types.SET_FIELD,
        payload: { field, value },
    });
}

export const resetFields = () => (dispatch) => {
    dispatch({
        type: types.RESET_FIELDS,
        payload: null,
    });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null,
    });
}