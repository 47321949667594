import React, { Fragment } from 'react';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import PqtPagination from './../../PqtPagination';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import { dateToTimezone } from './../../../utils';
import moment from 'moment-timezone';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';

const Table = React.memo((props) => {
    const confirmHandler = (id) => {
        props.showDeleteModal();
        props.setIdForDelete(id);
    }

    const renderPurchaseOrders = () => {
        if (props.fetchingPurchaseOrders) {
            return (
                <tr>
                    <td colSpan="18"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.purchaseOrdersData.length === 0) {
            return (
                <tr>
                    <td colSpan="18">No Results</td>
                </tr>
            );
        }

        return props.purchaseOrdersData.map((purchaseOrder, index) => {
            let deleteIcon = null;
            if (props.user != null && ([1, 9].includes(parseInt(props.user.pqt_type)) || props.user.is_pqt_accounting)) {
                deleteIcon = (
                    <Tooltip title="Delete Purchase Order" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="fas fa-trash" onClick={confirmHandler.bind(this, purchaseOrder.id)} />
                    </Tooltip>
                );
            }

            let total = 0;
            if (purchaseOrder.total != null) {
                total = purchaseOrder.total.toFixed(2);
            }

            let scLink = '-';
            if (purchaseOrder.stagecoach_number != null && purchaseOrder.stagecoach_number !== '') {
                scLink = <Link to={'/tracking/' + purchaseOrder.stagecoach_number}>{purchaseOrder.stagecoach_number}</Link>;
            }

            let createdAt = '-';
            if (purchaseOrder.created_at != null) {
                createdAt = dateToTimezone(purchaseOrder.created_at, 'MM/DD/YYYY');
            }

            let pushedDate = '-';
            if (purchaseOrder.pushed_datetime != null) {
                pushedDate = dateToTimezone(purchaseOrder.pushed_datetime, 'MM/DD/YYYY');
            }

            return (
                <tr key={'purchase-order-' + index}>
                    <td>{purchaseOrder.qb_number ? purchaseOrder.qb_number : '-'}</td>
                    <td>{purchaseOrder.vendor_obj_name}</td>
                    <td>{scLink}</td>
                    <td>{purchaseOrder.created_by_name}</td>
                    <td>{createdAt}</td>
                    <td>{pushedDate}</td>
                    <td>{purchaseOrder.descriptions}</td>
                    <td className="text-right">{numeral(purchaseOrder.total_quantity).format('0,0') }</td>
                    <td className="text-right">{numeral(purchaseOrder.total_rate).format('$0,0.00') }</td>
                    <td className="text-right">{numeral(total).format('$0,0.00')}</td>
                    <td>{purchaseOrder.customers}</td>
                    <td>
                        <div className="actions">
                            <Tooltip title="Edit Purchase Order" position="bottom" arrow size="small" className="action-tooltip">
                                <Link to={'/purchase-order/' + purchaseOrder.id}><i className="fas fa-edit" /></Link>
                            </Tooltip>
                            {deleteIcon}
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const setDateFieldHandler = (name, event) => {
        let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');
        props.changeFilter(name, value, true);
    };

    const renderSearchDateField = (name) => {
        let value = null;
        for (let i = 0; i < props.filterFields.length; i++) {
            if (props.filterFields[i].name === name) {
                value = props.filterFields[i].value;
            }
        }

        return (
            <th className="search-th">
                <DayPickerInput
                    formatDate={formatDate}
                    parseDate={parseDate}
                    placeholder=""
                    className="date-picker-field"
                    onDayChange={setDateFieldHandler.bind(this, name)}
                    value={value == null || value === '0000-00-00' ? '' : moment(value).format('MM/DD/YYYY')}
                />
            </th>
        )
    };

    const setSortHandler = (field, value) => {
        props.setSort(field, value);
    };

    const renderHeader = (name, column, className) => {
        let sortFunc = setSortHandler.bind(this, column, 'asc');
        let sortIcon = <i className="fas fa-sort" />;
        if (props.sort.column === column) {
            if (props.sort.type === 'asc') {
                sortFunc = setSortHandler.bind(this, column, 'desc');
                sortIcon = <i className="fas fa-sort-up" />;
            } else {
                sortFunc = setSortHandler.bind(this, column, 'asc');
                sortIcon = <i className="fas fa-sort-down" />;
            }
        }

        return <th className={className} onClick={sortFunc}>{name} {sortIcon}</th>;
    };

    const handleChangeFilter = (name, event) => {
        props.changeFilter(name, event.target.value);
    };

    const handleKey = (name, event) => {
        if (event.key === 'Enter') {
            handleChangeFilter(name, event);
        }
    };

    const renderSearchField = (name) => {
        return (
            <th className="search-th">
                <input type="text" name={name} className="search-input" onKeyPress={handleKey.bind(this, name)} />
            </th>
        );
    };

    const handleExport = () => {
        props.exportExcel();
    };

    let exportButton = null;
    if (props.user != null && [1, 2].includes(parseInt(props.user.id))) {
        let exportButtonLabel = <Fragment>Export Excel <i className="fas fa-file-excel" /></Fragment>
        if (props.isExporting) {
            exportButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        exportButton = <button onClick={handleExport} className="export-button" disabled={props.isExporting}>{exportButtonLabel}</button>;
    }
    exportButton = null;    // TODO: Enable exporting

    const handleReport = (type) => {
        props.setReportType(type);
        props.showReportModal();
    };

    let reportButton = null;
    // if (props.user != null && ([1, 2].includes(parseInt(props.user.pqt_type)) || props.user.is_pqt_accounting)) {
    if (props.user != null && [61, 75, 1147].includes(parseInt(props.user.id))) {
        let reportButtonLabel = <Fragment>Report <i className="fas fa-file-excel" /></Fragment>
        if (props.isExportingReport) {
            reportButtonLabel = <i className="fas fa-file-excel" />;
        }

        reportButton = <button onClick={handleReport.bind(this, 1)} className="report-button" disabled={props.isExportingReport}>{reportButtonLabel}</button>;
    }

    let listPurchaseButton = null;
    // if (props.user != null && ([1, 2].includes(parseInt(props.user.pqt_type)) || props.user.is_pqt_accounting)) {
    if (props.user != null && [61, 75, 1147].includes(parseInt(props.user.id))) {
        let listPurchaseButtonLabel = <Fragment>List Purchase <i className="fas fa-file-excel" /></Fragment>
        if (props.isExportingListPurchase) {
            listPurchaseButtonLabel = <i className="fas fa-file-excel" />;
        }

        listPurchaseButton = <button onClick={handleReport.bind(this, 2)} className="report-button" disabled={props.isExportingListPurchase}>{listPurchaseButtonLabel}</button>;
    }

    let uploadListPurchaseButton = null;
    // if (props.user != null && ([1, 2].includes(parseInt(props.user.pqt_type)) || props.user.is_pqt_accounting)) {
    if (props.user != null && [61, 75, 1147].includes(parseInt(props.user.id))) {
        let uploadListPurchaseButtonLabel = <Fragment>Upload List Purchase <i className="fas fa-file-upload"></i></Fragment>
        if (props.isUploadingListPurchase) {
            uploadListPurchaseButtonLabel = <i className="fas fa-file-excel" />;
        }

        uploadListPurchaseButton = <button onClick={props.showUploadListPurchaseModal} className="upload-button" disabled={props.isUploadingListPurchase}>{uploadListPurchaseButtonLabel}</button>;
    }

    const handleCreate = () => {
        props.history.push('/purchase-order');
    };

    return (
        <div className="table-root-container">
            <div className="actions-container">
                <div className="action-buttons">
                    {exportButton}
                    {reportButton}
                    {listPurchaseButton}
                    {uploadListPurchaseButton}
                </div>
                <div className="show-fields">
                    <button onClick={handleCreate} className="add-button">Create PO <i className="fas fa-plus" /></button>
                </div>
            </div>
            <div className="table-container">
                <table className="table table-sm table-hover table-striped">
                    <thead>
                        <tr>
                            {renderHeader('PO #', 'qb_number', 'th-id')}
                            {renderHeader('Vendor', 'vendor_obj_name', 'th-vendor-obj-name')}
                            {renderHeader('SC #', 'stagecoach_number', 'th-stagecoach-number')}
                            {renderHeader('Created By', 'created_by_name', 'th-created-by')}
                            {renderHeader('Created Date', 'created_at', 'th-created-at')}
                            {renderHeader('Pushed Date', 'pushed_datetime', 'th-pushed-datetime')}
                            <th>Description</th>
                            <th className="text-right">Qty</th>
                            <th className="text-right">Rate</th>
                            <th className="th-total text-right">Total</th>
                            <th>Customer(s)</th>
                            <th style={{ width: 90 }}></th>
                        </tr>
                        <tr>
                            {renderSearchField('qb_number')}
                            {renderSearchField('vendor_obj_name')}
                            {renderSearchField('stagecoach_number')}
                            {renderSearchField('created_by_name')}
                            {renderSearchDateField('created_at')}
                            {renderSearchDateField('pushed_datetime')}
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderPurchaseOrders()}
                    </tbody>
                </table>
                <PqtPagination
                    pagination={props.pagination}
                    setActivePage={props.setActivePage}
                />
            </div>
        </div>
    );
});

export default Table;
