import axios from 'axios';
import * as types from './ApAccounts.types';
import * as selectors from './ApAccounts.selectors';
import { validateAuthInResponse, showNotification, empty } from './../../utils';
import * as dialog from '../common/dialog';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchAccounts = () => (dispatch, getState) => {
    const sort = selectors.getSort(getState());
    const pagination = selectors.getPagination(getState());
    const filerFields = selectors.getFilterFields(getState());

    let params = [];
    params.push('sort=' + (sort.type === 'asc' ? '' : '-') + sort.column);
    params.push('active-page=' + pagination.activePage);
    params.push('elements-per-page=' + pagination.elementsPerPage);

    if (filerFields.length > 0) {
        let filterAccounts = [];
        for (let i = 0; i < filerFields.length; i++) {
            filterAccounts.push(filerFields[i]['name'] + '=' + filerFields[i]['value']);
        }
        params.push('filter=' + filterAccounts.join(','))
    } else {
        params.push('filter=-')
    }

    dispatch({ type: types.FETCH_ACCOUNTS, payload: null });
    axios.get(apiUrl + '/ap-accounts' + (params.length === 0 ? '' : '?' + params.join('&')),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_ACCOUNTS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_ACCOUNTS_ERROR));
        });
}

export const fetchAccount = (id) => (dispatch, getState) => {
    dispatch({ type: types.FETCH_ACCOUNT, payload: null });
    axios.get(apiUrl + '/ap-accounts/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_ACCOUNT_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_ACCOUNT_ERROR));
        });
}

export const saveAccount = () => (dispatch, getState) => {
    const fields = selectors.getFields(getState());
    if (!validateForm(fields)) {
        return;
    }

    let endpoint = apiUrl + '/ap-accounts';
    let method = 'post';
    if (fields.id && fields.id != null) {
        endpoint = apiUrl + '/ap-accounts/' + fields.id;
        method = 'put';
    }

    dispatch({ type: types.SAVE_ACCOUNT, payload: null });
    axios({
        method: method,
        url: endpoint,
        data: fields,
        headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
    }).then((response) => {
        dispatch({ type: types.SAVE_ACCOUNT_SUCCESS, payload: response.data });
        showNotification('Account Saved', 'The Account has been saved successfully', 'success');
        dispatch(fetchAccounts());
        dispatch(dialog.actions.hideDialog('ap-account-form-modal'));
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.SAVE_ACCOUNT_ERROR));
    });
}

export const deleteAccount = () => (dispatch, getState) => {
    const id = selectors.getIdForDelete(getState());
    dispatch({ type: types.DELETE_ACCOUNT, payload: null });
    axios.delete(apiUrl + '/ap-accounts/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.DELETE_ACCOUNT_SUCCESS, payload: response.data });
            dispatch(fetchAccounts());
            dispatch(dialog.actions.hideDialog('delete-modal'));
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.DELETE_ACCOUNT_ERROR));
        });
}

export const validateForm = (fields) => {
    let fieldsToValidate = [];
    for (let i = 0; i < fieldsToValidate.length; i++) {
        if (empty(fields[fieldsToValidate[i]])) {
            showNotification('Complete Information', 'Please, enter the information of all the required fields', 'info');
            return false;
        }
    }
    return true;
}

export const setSort = (column, type) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_SORT,
            payload: {
                column: column,
                type: type,
            }
        });
        resolve();
    }).then(() => {
        dispatch(fetchAccounts());
    });
}

export const setActivePage = (value) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_ACTIVE_PAGE,
            payload: value
        });
        resolve();
    }).then(() => {
        dispatch(fetchAccounts());
    });
}

export const setIdForDelete = (id) => (dispatch) => {
    dispatch({
        type: types.SET_ID_FOR_DELETE,
        payload: id
    });
}

export const setField = (field, value) => (dispatch) => {
    dispatch({
        type: types.SET_FIELD,
        payload: { field, value }
    });
}

export const changeFilter = (name, value, fetch = false) => (dispatch) => {
    new Promise((resolve) => {
        dispatch({
            type: types.CHANGE_FILTER,
            payload: { name, value }
        });
        resolve();
    }).then(() => {
        if (fetch) {
            dispatch(fetchAccounts());
        }
    });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null
    });
}

export const resetFields = () => (dispatch) => {
    dispatch({
        type: types.RESET_FIELDS,
        payload: null
    });
}