import React, { Fragment } from 'react';
import { dateToTimezone, empty, isEstimator } from './../../../utils';
import numeral from 'numeral';
import ButtonsContainer from './ButtonsContainer';
import ShippingTo from './ShippingTo';
import Totals from './Totals';
import RfqSpecification from './RfqSpecification';
import OutsideServices from './OutsideServices';
import QuantitiesTotals from './QuantitiesTotals';
import AdditionalAdjustments from './AdditionalAdjustments';

const LargeFormatForm = React.memo((props) => {
    const estimate = props.estimate;

    const addressDivs = [];
    if (estimate.address) {
        const addressParts = estimate.address.split("\n");
        for (let i = 0; i < addressParts.length; i++) {
            addressDivs.push(<div>{addressParts[i]}</div>);
        }
    }

    let dueDateLine = null;
    if (!empty(estimate.due_date)) {
        dueDateLine = <div className="mt-3"><b>Due Date:</b> {dateToTimezone(estimate.due_date, 'MMMM Do YYYY')}</div>;
    }

    let rfqNumberRow = null;
    if (!empty(estimate.sc_quote_request_id)) {
        rfqNumberRow = <div><b>RFQ #:</b> <a href={'/request-for-quote/' + estimate.sc_quote_request_id}>{estimate.sc_quote_request_id}</a></div>;
    }

    let estimateNumberRow = null;
    if (!empty(estimate.id)) {
        if (!empty(props.mainPartId)) {
            estimateNumberRow = <div><b>Estimate #:</b> {props.mainPartId}</div>;
        } else {
            estimateNumberRow = <div><b>Estimate #:</b> {estimate.id}</div>;
        }
    }

    let scNumberRow = null;
    if (!empty(estimate.pqt_tracking_id)) {
        scNumberRow = <div><b>SC #:</b> <a href={'/tracking/' + estimate.pqt_tracking_id}>{estimate.pqt_tracking_id}</a></div>;
    }

    let invoiceNumberRow = null;
    if (!empty(estimate.invoice_id)) {
        invoiceNumberRow = <div><b>Invoice #:</b> <a href={'/invoice/' + estimate.invoice_id}>{estimate.invoice_id}</a></div>;
    }

    let relationsContainer = null;
    let changeEstimateContainer = null;
    if (rfqNumberRow != null || estimateNumberRow != null || scNumberRow != null || invoiceNumberRow != null) {
        relationsContainer = (
            <div className="relations-information">
                {estimateNumberRow}
                {rfqNumberRow}
                {scNumberRow}
                {invoiceNumberRow}
            </div>
        );

        if (parseInt(estimate.status) === 4) {
            changeEstimateContainer = (
                <div className="change-estimate-type-container">
                    <button onClick={props.showChangeEstimateModal}><i className="fas fa-exchange-alt" /> Change Estimate Type</button>
                </div>
            );
        }
    }

    const handleChangeSubstrate = (event) => {
        props.fetchSubstrateAccessories(event.target.value);
        props.fetchSubstrateLaminates(event.target.value);
        props.resetAccessories();
        props.handleSetField('substrate', event);
    };

    const renderAccessories = () => {
        if (props.fetchingSubstrateAccessories) {
            return <div><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
        }

        if (empty(estimate.substrate)) {
            return <div>Select a Substrate to see the Finishing options available.</div>;
        }

        if (props.information.accessories.length === 0) {
            return <div>No elements found.</div>;
        }

        return props.information.accessories.map((accessory) => {
            let index = props.selectedAccessories.findIndex((id) => parseInt(id) === parseInt(accessory.id));
            return (
                <div className="check-field">
                    <input type="checkbox" checked={index !== -1} onClick={props.checkAccessory.bind(this, accessory.id)} /> {accessory.name}
                </div>
            );
        });
    };

    let substrateName = '';
    for (let i = 0; i < props.information.substrates.length; i++) {
        if (parseInt(props.information.substrates[i].id) === parseInt(estimate.substrate)) {
            substrateName = props.information.substrates[i].name;
            break;
        }
    }

    const renderSubstrates = () => {
        if (empty(estimate.output_device)) {
            return null;
        }

        return props.information.substrates.map((substrate) => {
            return <option value={substrate.id}>{substrate.name}</option>;
        })
    };

    const renderLaminates = () => {
        if (empty(estimate.substrate)) {
            return null;
        }

        return props.information.laminates.map((laminate) => {
            return <option value={laminate.id}>{laminate.name}</option>;
        })
    };

    const handleChangeOutputDevice = (event) => {
        if (event.target.value !== '') {
            props.fetchSubstrates(event.target.value);
        }
        props.handleSetField('output_device', event);
    };

    let outputDeviceName = '';
    if (parseInt(estimate.output_device) === 1) {
        outputDeviceName = 'Fuji Acuity Advance 4\' x 8\' Flatbed';
    } else if (parseInt(estimate.output_device) === 2) {
        outputDeviceName = 'Fuji Acuity LED II Hybrid';
    } else if (parseInt(estimate.output_device) === 3) {
        outputDeviceName = 'HP Latex 360';
    } else if (parseInt(estimate.output_device) === 4) {
        outputDeviceName = 'Epson 9890';
    } else if (parseInt(estimate.output_device) === 5) {
        outputDeviceName = 'Printer A';
    } else if (parseInt(estimate.output_device) === 6) {
        outputDeviceName = 'Graphtec Plotter';
    }

    let accessories = [];
    for (let i = 0; i < props.information.accessories.length; i++) {
        const accessory = props.information.accessories[i];
        let index = props.selectedAccessories.findIndex((id) => parseInt(id) === parseInt(accessory.id));
        if (index !== -1) {
            accessories.push(accessory.name);
        }
    }

    let wrongEstimate = null;
    if (props.result != null && props.result.results && props.result.results.length > 0) {
        wrongEstimate = (
            <div className="wrong-estimate">
                <div className="link" onClick={props.showWrongEstimateModal}>Wrong Estimate? Click here to let us know.</div>
            </div>
        );
    }

    let specificationContainer = (
        <Fragment>
            <div className="section-title mt-3 mb-2">Specification:</div>
            <div className="specification-container">
                <div><b>Description:</b></div>
                <div>{estimate.description_title}</div>
                <div className="mt-3"><b>Quantity:</b> {numeral(estimate.quantity).format('0,0')}</div>
                <div><b>Output Device:</b> {outputDeviceName}</div>
                <div><b>Size:</b> {estimate.finish_size_width}x{estimate.finish_size_height}</div>
                <div className="mt-3"><b>Substrate:</b> {substrateName}</div>
                <div className="mt-3"><b>Accessories:</b> {accessories.join(', ')}</div>
                {props.filesContainer}
            </div>
        </Fragment>
    );
    if (estimate.sc_quote_request_id && estimate.sc_quote_request_id != null) {
        specificationContainer = (
            <RfqSpecification
                {...props}
            />
        );
    }

    let deletePartButton = null;
    if (!empty(estimate.main_part_id) && parseInt(estimate.status) === 4) {
        const handleDeletePart = () => {
            props.showDeletePartModal();
            props.setIdForDelete(estimate.id);
        }

        deletePartButton = (
            <div className="delete-part-button-container">
                <button onClick={handleDeletePart}><i className="fas fa-trash" /> Delete this Part</button>
            </div>
        );
    }

    let addButton = null;
    if (!empty(estimate.id) && parseInt(estimate.status) === 4) {
        addButton = (
            <div className="add-part-container">
                <button onClick={props.showAddPartModal}><i className="fas fa-plus" /> Add Part</button>
            </div>
        );
    }

    let duplicateButton = null;
    if (!empty(estimate.id) && parseInt(estimate.status) === 4) {
        duplicateButton = (
            <div className="add-part-container">
                <button onClick={props.duplicatePart}>
                    {props.isDuplicatingPart
                        ? <i className="fas fa-circle-notch fa-spin" />
                        : <Fragment><i className="fas fa-copy" /> Duplicate Part</Fragment>}
                </button>
            </div>
        );
    }

    if (isEstimator(props.user)) {
        return (
            <div className="large-format-form-container main-form-container">
                <div className="line1">
                    <div className="column1">
                        <div className="column1-line1">
                            {relationsContainer}
                            {changeEstimateContainer}
                            {addButton}
                            {duplicateButton}
                            {deletePartButton}
                            <div className="quote-request-container">
                                <div><b>Quote request:</b></div>
                                <div className="mt-3"><b>Customer:</b> {props.customerName}</div>
                                <div><b>Sales rep:</b> {props.salesRepName}</div>
                                <div><b>CSR:</b> {props.csrName}</div>
                                <div><b>Product Type:</b> {props.selectedProductType}</div>
                                {dueDateLine}
                            </div>
                            {specificationContainer}
                        </div>
                    </div>
                    <div className="column2">
                        <div className="column2-line1">
                            <div className="csr-field">
                                <div><b>Sales Rep <span className="required">*</span>:</b></div>
                                <select value={estimate.sales_rep_id} onChange={props.handleSetField.bind(this, 'sales_rep_id')}>
                                    <option value="">Select option</option>
                                    {props.salesRepOptions}
                                </select>
                            </div>
                            <div className="csr-field">
                                <div><b>CSR <span className="required">*</span>:</b></div>
                                <select value={estimate.csr_id} onChange={props.handleSetField.bind(this, 'csr_id')}>
                                    <option value="">Select option</option>
                                    {props.csrOptions}
                                </select>
                            </div>
                            <div className="product-type-field">
                                <div><b>Product Type <span className="required">*</span>:</b></div>
                                <select value={estimate.product_type} onChange={props.handleSetField.bind(this, 'product_type')}>
                                    <option value="">Select option</option>
                                    {props.productTypesOptions}
                                </select>
                            </div>
                            <div className="process-request-field">
                                <div><b>Process Request <span className="required">*</span>:</b></div>
                                <select value={estimate.process_request} onChange={props.handleSetField.bind(this, 'process_request')}>
                                    <option value="">Select option</option>
                                    {props.processRequestOptions}
                                </select>
                            </div>
                            <div className="markup-field">
                                <div><b>Markup Factor <span className="required">*</span>:</b></div>
                                <div>
                                    <select value={estimate.markup_factor} onChange={props.handleSetField.bind(this, 'markup_factor')}>
                                        <option value="">Select option</option>
                                        <option value="1">Sales</option>
                                        <option value="2">Broker</option>
                                        <option value="3">Good</option>
                                        <option value="4">Great</option>
                                        <option value="5">Excellent</option>
                                        <option value="6">PITAA</option>
                                    </select>
                                </div>
                            </div>
                            <div className="no-field"></div>
                            <div className="description-title mt-2">
                                <b>Description <span className="required">*</span>:</b> <input type="text" value={estimate.description_title} onChange={props.handleSetField.bind(this, 'description_title')} />
                            </div>
                        </div>
                        <div className="column2-line2">
                            <div className="section-title">All Pages:</div>
                            <div className="output-devices-field">
                                <b>Output Device <span className="required">*</span>:</b>
                                <select value={estimate.output_device} onChange={handleChangeOutputDevice}>
                                    <option value="">Select option</option>
                                    <option value="1">Fuji Acuity Advance 4' x 8' Flatbed</option>
                                    <option value="2">Fuji Acuity LED II Hybrid</option>
                                    <option value="3">HP Latex 360</option>
                                    <option value="4">Epson 9890</option>
                                    <option value="5">Printer A</option>
                                    <option value="6">Graphtec Plotter</option>
                                </select>
                            </div>
                            <div className="substrate-field">
                                <b>Substrate <span className="required">*</span>:</b>
                                <select value={estimate.substrate} onChange={handleChangeSubstrate}>
                                    <option value="">Select option</option>
                                    {renderSubstrates()}
                                </select>
                                {props.fetchingSubstrates && <i className="fas fa-circle-notch fa-spin" />}
                            </div>
                            <div className="laminate-field">
                                <b>Laminate:</b>
                                <select value={estimate.laminate} onChange={props.handleSetField.bind(this, 'laminate')}>
                                    <option value="">Select option</option>
                                    {renderLaminates()}
                                </select>
                                {props.fetchingSubstrateLaminates && <i className="fas fa-circle-notch fa-spin" />}
                            </div>
                            <div className="finish-size-width-field">
                                <b>Width <span className="required">*</span>:</b>
                                <input type="number" value={estimate.finish_size_width} onChange={props.handleSetField.bind(this, 'finish_size_width')} />
                            </div>
                            <div className="finish-size-height-field">
                                <b>Height <span className="required">*</span>:</b>
                                <input type="number" value={estimate.finish_size_height} onChange={props.handleSetField.bind(this, 'finish_size_height')} />
                            </div>
                            <div className="unit-field">
                                <b>Unit <span className="required">*</span>:</b>
                                <select value={estimate.unit} onChange={props.handleSetField.bind(this, 'unit')}>
                                    <option value="1">Inches</option>
                                    <option value="2">Feet</option>
                                </select>
                            </div>
                            <div className="ink-type">
                                <b>Ink <span className="required">*</span>:</b>
                                <select value={estimate.front_inks} onChange={props.handleSetField.bind(this, 'front_inks')}>
                                    <option value="">Select option</option>
                                    <option value="1">Color One Side</option>
                                    <option value="2">Color Double Side</option>
                                </select>
                            </div>
                            <div className="no-field"></div>
                        </div>
                        <div className="column2-line3">
                            <div className="section-title mb-2">Finishing:</div>
                            <div className="accessories-container">
                                {renderAccessories()}
                            </div>
                        </div>
                    </div>
                    <div className="column3">
                        <div className="column3-line1">
                            <div className="quantity-field">
                                <b>Quantity 1 <span className="required">*</span>:</b>
                                <input type="number" value={estimate.quantity} onChange={props.handleSetField.bind(this, 'quantity')} />
                            </div>
                            <div className="quantity-field">
                                <b>Quantity 2:</b>
                                <input type="number" value={estimate.quantity2} onChange={props.handleSetField.bind(this, 'quantity2')} />
                            </div>
                            <div className="quantity-field">
                                <b>Quantity 3:</b>
                                <input type="number" value={estimate.quantity3} onChange={props.handleSetField.bind(this, 'quantity3')} />
                            </div>
                            <div className="pricing-title">Pricing Grid</div>
                            <div className="start-qty-field">
                                <b>Start Qty:</b>
                                <input type="number" value={estimate.start_qty} onChange={props.handleSetField.bind(this, 'start_qty')} />
                            </div>
                            <div className="end-qty-field">
                                <b>End Qty:</b>
                                <input type="number" value={estimate.end_qty} onChange={props.handleSetField.bind(this, 'end_qty')} />
                            </div>
                            <div className="increment-field">
                                <b>Increment:</b>
                                <input type="number" value={estimate.increment} onChange={props.handleSetField.bind(this, 'increment')} />
                            </div>
                            <div className="matrix-button-container">
                                <button className="matrix-button" disabled={props.multiEstimating} onClick={props.matrixEstimate}>
                                    {props.multiEstimating
                                        ? <i className="fas fa-circle-notch fa-spin" />
                                        : <Fragment><i className="fas fa-magic" /> Price Matrix Generator</Fragment>}
                                </button>
                            </div>
                        </div>
                        <div className="column3-line2">
                            <div className="add-charge">
                                <div>Additional services</div>
                                <div className="picker" onClick={props.showDeliveryChargeModal}><i className="fas fa-truck" /> Delivery Charge</div>
                                <div className="picker" onClick={props.showAdditionalServicesModal}><i className="fas fa-server" /> Charges Picker</div>
                            </div>
                            {props.renderAdditionalServices()}
                            <OutsideServices
                                information={props.information}
                                estimate={props.estimate}
                                outsideServices={props.outsideServices}
                                setDataChanged={props.setDataChanged}
                                setEstimateChanged={props.setEstimateChanged}
                                handleSetField={props.handleSetField}
                                addOutsideService={props.addOutsideService}
                                setOutsideServiceField={props.setOutsideServiceField}
                                deleteOutsideService={props.deleteOutsideService}
                            />
                        </div>
                        <AdditionalAdjustments
                            estimate={props.estimate}
                            handleSetField={props.handleSetField}
                        />
                    </div>
                </div>
                <div className="line2">
                    <ShippingTo
                        estimate={estimate}
                        information={props.information}
                        setField={props.setField}
                        handleSetField={props.handleSetField}
                    />
                    <div className="column2">
                        <QuantitiesTotals {...props} />
                    </div>
                    <div className="column3">
                        <Totals {...props} />
                        {wrongEstimate}
                        <ButtonsContainer {...props} />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="large-format-form-container main-form-container">
            <div className="line1">
                <div className="column1">
                    <div className="column1-line1">
                        {relationsContainer}
                        <div className="quote-request-container">
                            <div><b>Quote request:</b></div>
                            <div className="mt-3"><b>Customer:</b> {props.customerName}</div>
                            <div><b>Sales rep:</b> {props.salesRepName}</div>
                            <div><b>CSR:</b> {props.csrName}</div>
                            <div><b>Product Type:</b> {props.selectedProductType}</div>
                            {dueDateLine}
                        </div>
                    </div>
                </div>
                <div className="column2">
                    {specificationContainer}
                </div>
                <div className="column3">
                    <div className="column3-line2">
                        <div className="add-charge">
                            <div>Additional services</div>
                        </div>
                        {props.renderAdditionalServices()}
                    </div>
                </div>
            </div>
            <div className="line2">
                <ShippingTo
                    estimate={estimate}
                    information={props.information}
                    setField={props.setField}
                    handleSetField={props.handleSetField}
                />
                <div className="column2">
                    <QuantitiesTotals {...props} />
                </div>
                <div className="column3">
                    <Totals {...props} />
                    {wrongEstimate}
                    <ButtonsContainer {...props} />
                </div>
            </div>
        </div>
    );
});

export default LargeFormatForm;
