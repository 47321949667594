import axios from 'axios';
import * as types from './InformedVisibilityJob.types';
// import * as selectors from './InformedVisibilityJob.selectors';
import { validateAuthInResponse } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchJob = (id) => (dispatch, getState) => {
    dispatch({ type: types.FETCH_JOB, payload: null });
    axios.get(apiUrl + '/pqt-tracking/iv-job?id=' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_JOB_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_JOB_ERROR));
        });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null,
    });
}